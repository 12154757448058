<!--
 * @Editor: chenqy
 * @Description: 发送短信
 * @Date: 2021-06-09 17:05:35
 * @LastEditors: Chenqy
 * @LastEditTime: 2022-04-18 14:29:58
-->
<template>
  <div class="teas-message ds-control">
    <div class="teas-message-header">
      <div class="teas-header-title">发短信</div>
      <div class="teas-header-send">
        <div
          class="teas-header-check"
          :class="{ 'teas-header-check-true': !isRightNowSend }"
          @click="handleSend(false)"
        >
          <i v-show="!isRightNowSend" class="el-icon-check"></i>
        </div>
        <div class="teas-header-text">定时发送</div>
        <div class="teas-header-timer">
          <el-date-picker
            v-model="sendDate"
            v-show="!isRightNowSend"
            type="datetime"
            placeholder="请选择"
          >
          </el-date-picker>
        </div>
        <!-- <div
          class="teas-header-check"
          :class="{ 'teas-header-check-true': isRightNowSend }"
          @click="handleSend(true)"
        >
          <i v-show="isRightNowSend" class="el-icon-check"></i>
        </div>
        <div class="teas-header-text" style="margin-right: 40px">立即发送</div> -->
        <div class="teas-header-btn" @click="handleSendMessage('send')">
          <i class="el-icon-position"></i>发送
        </div>
        <div class="teas-header-btn" @click="handleSendMessage('draft')">
          保存至草稿箱
        </div>
        <el-upload
          class="teas-header-btn"
          slot="append"
          :headers="dsf.getDefaultHttpHeader()"
          :accept="accept"
          :action="action"
          :show-file-list="false"
          :on-progress="uploadProgress"
          :on-success="uploadSuccess"
          :on-error="uploadError"
        >
          <div class="teas-header-btn-text">导入手机号</div>
        </el-upload>
        <div class="teas-header-btn" @click="downloadMode">模版下载</div>
      </div>
    </div>
    <div class="teas-message-body">
      <div
        class="teas-body-left"
        :style="{ width: allNavList.length > 4 ? '535px' : '480px' }"
      >
        <div class="teas-body-nav">
          <div class="teas-body-nav-box">
            <div
              class="teas-body-nav-really"
              :style="{ transform: 'translateX(' + navMargin + 'px)' }"
            >
              <div
                class="teas-body-nav-item"
                :class="{ 'teas-item-active': item.name === navActive }"
                v-for="(item, index) in allNavList"
                :key="index"
                @click="handleChangeNav(item.name)"
              >
                {{ item.title }}
              </div>
            </div>
          </div>
          <div class="teas-body-btn" v-if="allNavList.length > 4">
            <i
              class="el-icon-arrow-left"
              :class="{ 'arrow-disabled': navMargin === 0 }"
              @click="handleMoveNav(true)"
            ></i>
            <i
              class="el-icon-arrow-right"
              :class="{
                'arrow-disabled':
                  Math.abs(this.navMargin) >=
                  Math.ceil(this.allNavList.length / 4 - 1) * 480,
              }"
              @click="handleMoveNav(false)"
            ></i>
          </div>
        </div>
        <!-- 搜索树 -->
        <div
          class="teas-body-tree-main"
          v-for="(item, index) in defaultNavList"
          :key="'treelist' + index"
          v-show="navActive === item.name"
        >
          <template v-if="!item.haschild || item.child.length == 0">
            <div class="teas-body-tree-c">
              <messageTree
                class="teas-body-tree"
                :name="item.name"
                :ref="item.name"
                :apiAddress="item.url"
                @handleAdd="handleAddUser"
              ></messageTree>
            </div>
          </template>
          <template v-else>
            <div class="sendmessage-twolevel-div">
              <div class="sendmessage-twoleveltab-div">
                <div
                  class="sendmessage-twoleveltab-item"
                  v-for="(tempItem, tempIndex) in item.child"
                  :key="'twoleveltab' + tempIndex"
                  @click="clickTwoTab(index, tempItem)"
                >
                  <div
                    :class="
                      tempIndex == 0
                        ? 'item-content-noborder'
                        : 'item-content-normal'
                    "
                  >
                    <div
                      :class="
                        tempItem.name == item.active
                          ? 'item-content-select'
                          : 'item-content-notselect'
                      "
                    >
                      {{ tempItem.title || "" }}
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="sendmessage-twoleveltree-div"
                v-for="(tempItem, tempIndex) in item.child"
                :key="'twoleveltree' + tempIndex"
                v-show="tempItem.name == item.active"
              >
                <div class="teas-body-tree-c">
                  <messageTree
                    class="teas-body-tree"
                    :name="tempItem.name"
                    :ref="tempItem.name"
                    :apiAddress="tempItem.url"
                    @handleAdd="handleAddUser"
                  ></messageTree>
                </div>
              </div>
            </div>
          </template>
        </div>
        <!-- 手动添加 -->
        <!-- <div class="teas-body-sd" v-show="navActive === 'manual'">
          <el-form
            label-position="top"
            label-width="80px"
            :model="formLabelAlign"
            class="teas-body-sd-form"
            ref="form"
          >
            <el-form-item
              label="姓名"
              prop="name"
              :rules="[
                { required: true, message: '姓名不能为空', trigger: 'blur' },
              ]"
            >
              <el-input
                v-model="formLabelAlign.name"
                :validate-event="false"
                placeholder="请输入姓名"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="手机号码"
              prop="phoneNumber"
              :rules="[
                {
                  required: true,
                  message: '手机号码不能为空',
                  trigger: 'blur',
                },
                {
                  type: 'phone',
                  validator: phoneValidate,
                  trigger: ['blur', 'change'],
                },
              ]"
            >
              <el-input
                v-model="formLabelAlign.phoneNumber"
                :validate-event="false"
                placeholder="请输入手机号码"
              ></el-input>
            </el-form-item>
            <el-button
              class="teas-body-add"
              @click="handleAddPeo"
            >添加</el-button
            >
          </el-form>
        </div> -->
        <!-- 额外增加的选项 -->
        <!-- <div
          class="teas-other-content"
          v-for="(item, index) in slots"
          v-show="item.name === navActive"
          :key="index"
          :name="item.name"
        >
          <component
            :is="item.type"
            :ref="item.name"
            class="teas-body-tree"
            :name="item.name"
            :apiAddress="item.apiAddress"
            @handleAdd="handleAddUser"
          ></component>
        </div> -->
      </div>
      <div class="teas-body-right">
        <div class="teas-body-receive">
          <div class="teas-body-title">
            <div>收件人</div>
            <div class="template" @click="handleClearAllPeo">
              <span class="iconfont icon-icon_del1"></span>清空
            </div>
          </div>
          <div class="teas-body-title-peo" @click.self="clickReceiveView">
            <!-- 系统人员 -->
            <template v-for="(item, key) in receives">
              <div
                v-for="(value, i) in item"
                :key="i + key"
                class="teas-body-receive-item"
              >
                {{ value.name }}（{{ value.phoneNumber }}）<i
                  class="el-icon-close"
                  @click="handleClearPeo(i, key)"
                ></i>
              </div>
            </template>
            <div class="teas-body-receive-item-input">
              <el-autocomplete
                v-model="inputPeopleString"
                :fetch-suggestions="querySearchAsync"
                placeholder=""
                ref="receiveInput"
                @select="handleSelect"
                @blur="inputBlur"
                @focus="inputFocus"
              ></el-autocomplete>
            </div>
            <!-- 手动添加人员 -->
            <!-- <div
              class="teas-body-receive-item"
              v-for="(item, index) in handleList"
              :key="index"
            >
              {{ item.name }}（{{ item.phoneNumber }}）<i
                class="el-icon-close"
                @click="handleClearPeo(index, 'handle')"
              ></i>
            </div> -->
          </div>
          <div class="teas-body-title-foot">
            共<span>{{ sendUser }}</span
            >人
          </div>
        </div>
        <div class="teas-body-content">
          <div class="teas-body-title">
            <span>短信内容</span>
            <div class="teas-body-title-right-button-div">
              <span class="template-div" @click="handleSuffix">短信后缀</span>
              <span class="template-div" @click="handleTemplate">短信模板</span>
            </div>
          </div>
          <div class="teas-body-title-peo">
            <el-input
              class="textarea"
              type="textarea"
              :rows="8"
              :max="contentLength"
              placeholder="请输入短信内容"
              v-model="messageContent"
              resize="none"
            >
            </el-input>
          </div>
          <div class="teas-body-title-content-last">
            {{
              messageContentLastObject.text
                ? "(" + messageContentLastObject.text + ")"
                : ""
            }}
          </div>
          <div class="teas-body-title-foot">
            合计字数<span>{{
              messageContentLastObject.text
                ? messageContent.length + messageContentLastObject.text.length
                : messageContent.length
            }}</span>
            ，请控制在{{ contentLength }}字以内
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import messageTree from "./sendMessageTree.vue";
export default dsf.component({
  name: "DsfPlatformTeasWirte",
  ctrlCaption: "短信首页",
  mixins: [$mixins.control],
  components: { messageTree },
  props: {
    contentLength: {
      type: Number,
      default: 300,
    },
    // 标签配置
    tabConfig: {
      type: Array,
      default: () => [
        {
          title: "校内成员",
          name: "schoolperson",
          haschild: false,
          // hide: false,
          // lazy: false,
          url: "/sms/sms-info/tree/person",
          child: [],
        },
      ],
    },
    activeTab: {
      type: String,
      default: "schoolperson",
    },
  },
  created() {
    // 获取再次编辑数据
    // dialog && location
    let tempArray = this.tabConfig || [];
    this.defaultNavList = tempArray.map((item) => {
      let tempItem = item;
      if (tempItem.child.length > 0 && tempItem.haschild) {
        tempItem.active = tempItem.child[0].name || "";
      }
      return tempItem;
    });
    this.navActive = this.activeTab || "";
    let { messageId, type } = this.$route.query.messageId
      ? this.$route.query
      : this.queryString;
    if (messageId) {
      this.messageId = messageId;
      this.$http
        .get("/sms/sms-info/message", { messageId, type })
        .done((re) => {
          if (re.success) {
            this.messageContent = re.data.content;
            this.sendDate = re.data.sendTime;
            this.$set(this.receives, this.handlerAddName, re.data.receivers);
            this.isRightNowSend = re.data.sendNow;
            this.smsMessageId = re.data.smsMessageId;
            let suffixUser = re.data.suffixUser;
            let suffix = re.data.suffix;
            if (suffixUser && !suffix) {
              this.messageContentLastObject = { text: "自己", value: "-2" };
            } else {
              this.messageContentLastObject = suffix;
            }
          } else {
            dsf.layer.message(re.message, false);
          }
        });
    }
  },
  data() {
    return {
      messageId: "", // 再次编辑页面ID
      isRightNowSend: true, // 是否为立即发送
      sendDate: "", // 发送日期
      receives: {}, // 系统短信接受人
      inputPeopleString: "",
      handlerAddName: "handlerAdd",
      messageContentLastObject: {},
      inputIsFocus: false,
      receivesList: [],
      handleList: [], // 手动添加人员
      defaultProps: { children: "children", label: "name" },
      messageContent: "", // 短信内容
      navActive: "", // 搜索树导航激活菜单
      navMargin: 0, // 菜单移动距离
      formLabelAlign: {}, // 手动添加
      defaultNavList: [], // 导航菜单
      treeData: [], // 树数据
      accept: ".xlsx,.xls",
      action: "/sms/sms-info/preimport/excel",
    };
  },
  computed: {
    allNavList() {
      return [...this.defaultNavList];
    },
    sendUser() {
      let all = 0;
      Object.keys(this.receives).map((re) => {
        all += this.receives[re].length;
      });
      all += this.handleList.length;
      return all;
    },
  },
  methods: {
    phoneValidate(rule, value, callback) {
      if (value && !/^1[0-9]{10}$/.test(value)) {
        callback(new Error("手机号码格式错误"));
      } else {
        callback();
      }
    },
    /**
     * type (string) 事件类型
     */
    sendMessage(type) {
      let receives = [];
      let { contentLength } = this.props;
      Object.keys(this.receives).map((re) => {
        receives.push(...this.receives[re]);
      });
      // validate
      if (!this.messageContent) {
        dsf.layer.message("请维护收件人或者短信内容", false);
        return;
      }
      let messageStringLength = this.messageContentLastObject.text
        ? this.messageContent.length + this.messageContentLastObject.text.length
        : this.messageContent.length;
      if (messageStringLength > contentLength) {
        dsf.layer.message(`短信内容不能超过${contentLength}字`, false);
        return;
      }
      if (!this.isRightNowSend && !this.sendDate) {
        dsf.layer.message("请选择短信发送时间", false);
        return;
      }
      if (this.sendUser === 0) {
        dsf.layer.message("请填写短信收件人", false);
        return;
      }
      let formatter = {
        content: this.messageContent,
        sendNow: this.isRightNowSend,
        sendTime: dsf.date.format(this.sendDate, "yyyy-mm-dd hh:ii:ss"),
        receivers: [...receives],
        type,
      };
      if (this.messageContentLastObject.value) {
        if (this.messageContentLastObject.value.length > 0) {
          if (this.messageContentLastObject.value == "-2") {
            formatter.suffixUser = dsf.getCookie("userId") || "";
          } else if (this.messageContentLastObject.value == "-1") {
            console.log(11);
          } else {
            formatter.suffix = this.messageContentLastObject;
          }
        }
      }
      if (this.smsMessageId) {
        formatter["smsMessageId"] = this.smsMessageId;
      }
      this.$http
        .post(`/sms/sms-info/save`, JSON.stringify(formatter), {
          headers: { "Content-Type": "application/json;charset=UTF-8" },
        })
        .done((res) => {
          // 清空之前的信息
          this.sendDate = "";
          this.handleList = [];
          this.messageContent = "";
          this.messageContentLastObject = {};
          this.formLabelAlign = {};
          Object.keys(this.receives).map((re) => {
            this.receives[re] = [];
            if (!re.includes("list")) {
              let parentVm = this.$refs[re];
              if (
                Object.prototype.toString.call(parentVm) === "[object Object]"
              ) {
                parentVm.$refs.tree.setCheckedNodes([]);
              } else if (
                Object.prototype.toString.call(parentVm) === "[object Array]"
              ) {
                parentVm[0].$refs.tree.setCheckedNodes([]);
              }
            }
          });
          if (res.success) {
            dsf.layer.message(res.message);
          } else {
            // 发送短信部分失败情况
            if (type === "send" && res.data.type === "confirm") {
              dsf.layer
                .confirm(`${res.message}`)
                .then((re) => {
                  let path =
                    "#/pc/sms/sent/senddetails?smsMessageId=" + res.data.id;
                  dsf.layer.openDialog({
                    title: "发送详情",
                    width: "80vw",
                    height: "70vh",
                    showClose: true,
                    params: {
                      path: path,
                    },
                    btns: [
                      {
                        text: "关闭",
                      },
                    ],
                  });
                })
                .catch((e) => e);
            } else {
              dsf.layer.message(res.message, false);
            }
          }
        });
    },
    // 发送短信
    handleSendMessage(type) {
      this.sendMessage(type);
    },
    // 切换发送方式
    handleSend() {
      this.isRightNowSend = !this.isRightNowSend;
    },
    // 选择导航树
    handleChangeNav(name) {
      this.navActive = name;
    },
    // 左、右移动导航栏
    handleMoveNav(isLeft) {
      if (isLeft) {
        if (this.navMargin < 0) {
          this.navMargin += 480;
        }
      } else {
        if (
          Math.abs(this.navMargin) <
          Math.ceil(this.allNavList.length / 4 - 1) * 480
        ) {
          this.navMargin -= 480;
        }
      }
    },
    // 从树/列表 组件添加选中用户信息
    handleAddUser(name, data) {
      this.$set(this.receives, name, data);
    },
    // 删除选中的人
    handleClearPeo(index, type) {
      if (type === "handle") {
        this.handleList.splice(index, 1);
      } else {
        let data = this.receives[type];
        data.splice(index, 1);
        let parentVm = this.$refs[type];
        if (Object.prototype.toString.call(parentVm) === "[object Object]") {
          parentVm.$refs.tree.setCheckedNodes(data);
        } else if (
          Object.prototype.toString.call(parentVm) === "[object Array]"
        ) {
          parentVm[0].$refs.tree.setCheckedNodes(data);
        } else {
          return;
        }
        this.$set(this.receives, type, data);
      }
    },
    // 清空所有人
    handleClearAllPeo() {
      let keyArr = Object.keys(this.receives);
      keyArr.forEach((type) => {
        if (type != this.handlerAddName) {
          let parentVm = this.$refs[type];
          if (Object.prototype.toString.call(parentVm) === "[object Object]") {
            parentVm.$refs.tree.setCheckedNodes([]);
          } else if (
            Object.prototype.toString.call(parentVm) === "[object Array]"
          ) {
            parentVm[0].$refs.tree.setCheckedNodes([]);
          } else {
            return;
          }
        }
        this.$set(this.receives, type, []);
      });
    },
    // 手动添加联系人
    handleAddPeo() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.handleList.push(Object.assign({}, this.formLabelAlign));
          this.formLabelAlign = {};
        } else {
          return false;
        }
      });
    },
    // 短信模板
    handleTemplate() {
      let _this = this;
      this.$openDialog({
        title: "短信模版",
        width: "1000px",
        height: "60vh",
        params: {
          path: "#/pc/sms/temp/list?isView=1",
        },
        btns: [
          {
            text: "确定",
            handler(res) {
              let tempDic =
                res.$refs.view.$refs.datagrid1.multipleSelection || {};
              if (!tempDic["_id"]) {
                dsf.layer.message("请选择短信模版", false);
                return;
              } else {
                _this.messageContent =
                  _this.messageContent + tempDic["sms_temp.content"];
              }
            },
          },
          {
            text: "取消",
          },
        ],
      });
    },
    handleSuffix() {
      let _this = this;
      this.$openDialog({
        title: "短信后缀",
        width: "800px",
        height: "60vh",
        params: {
          path: "#/pc/sms/suffix/suffixSelect",
        },
        btns: [
          {
            text: "确定",
            handler(res) {
              _this.messageContentLastObject = res.$refs.view.chooseGroup || {};
            },
          },
          {
            text: "取消",
          },
        ],
      });
    },
    clickTwoTab(index, tempItem) {
      let tempOneItem = this.defaultNavList[index];
      if (tempOneItem.active == tempItem.name) {
        return;
      }
      tempOneItem.active = tempItem.name || "";
      this.$set(this.defaultNavList, index, tempOneItem);
    },
    //输入框检索
    querySearchAsync(serchString, callback) {
      this.$http
        .get("/sms/sms-info/person/search", {
          searchValue: serchString || "",
          pageNum: 1,
          pageSize: 100,
        })
        .done((re) => {
          if (re.success) {
            let tempData = re.data || [];
            tempData.map((item) => {
              let tempItem = item;
              tempItem.value = item.name + "（" + item.phoneNumber + "）";
              return tempItem;
            });
            callback(tempData);
          } else {
            callback([]);
            // dsf.layer.message(re.message);
          }
        })
        .error((re) => {
          callback([]);
        });
    },
    //输入框选择
    handleSelect(res) {
      let tempArray = this.receives[this.handlerAddName] || [];
      let tempObject = {
        id: res.id,
        name: res.name,
        phoneNumber: res.phoneNumber,
      };
      tempArray.push(tempObject);
      this.$set(this.receives, this.handlerAddName, tempArray);
      this.inputPeopleString = "";
    },
    inputBlur() {
      setTimeout(() => {
        this.inputIsFocus = false;
        if (this.inputPeopleString.length == 0) {
          return;
        }
        let phoneString = this.selectPhoneNumber(this.inputPeopleString);
        if (phoneString.length > 0) {
          // if (dsf.validate("isMobileOrTelPhone", phoneString)) {
          let tempStringArr = this.inputPeopleString.split(phoneString);
          let tempUserName = "";
          if (tempStringArr.length > 0) {
            tempUserName = tempStringArr[0] || "";
          }
          let tempObject = {
            id: "",
            name: tempUserName,
            phoneNumber: phoneString,
          };
          let tempPeopleArray = this.receives[this.handlerAddName] || [];
          tempPeopleArray.push(tempObject);
          this.$set(this.receives, this.handlerAddName, tempPeopleArray);
          this.inputPeopleString = "";
          // } else {
          //   dsf.layer.message("请输入正确的手机号", false);
          //   this.$refs.receiveInput.focus();
          //   return;
          // }
        } else {
          dsf.layer.message("请输入正确的手机号", false);
          this.$refs.receiveInput.focus();
          return;
        }
      }, 200);
    },
    inputFocus() {
      this.inputIsFocus = true;
    },
    selectPhoneNumber(str) {
      var regx = /(^1[\d]{10}|0[\d]{2,3}-[\d]{7,8}|400[-]?[\d]{3}[-]?[\d]{4})/g;
      var phoneNums = str.match(regx);
      if (phoneNums) {
        if (phoneNums.length > 0) {
          return phoneNums[0];
        } else {
          return "";
        }
      } else {
        return "";
      }
    },
    clickReceiveView() {
      if (!this.inputIsFocus) {
        this.$refs.receiveInput.focus();
      }
    },
    handleImportPhone(idString) {
      //导入手机号
      let _this = this;
      this.$openDialog({
        title: "预导入文件编辑",
        width: "80%",
        height: "500px",
        center: true,
        params: {
          path: "#/pc/sms/send/import/edit?id=" + idString,
        },
        btns: [
          {
            text: "确定",
            async handler(res) {
              if (res && res.yes && !(await res.yes())) return false;
              _this.$http
                .get("/sms/sms-info/preimport/clean", {
                  id: idString,
                })
                .done((re) => {})
                .error((re) => {});
              let uploadData =
                res.$refs.view.$viewData["sms_send_import_user"] || [];
              let tempPeopleArray = _this.receives[_this.handlerAddName] || [];
              uploadData.forEach((item) => {
                let tempItem = {
                  id: "",
                  name: item["sms_send_import_user.name"] || "",
                  phoneNumber: item["sms_send_import_user.phone"] || "",
                };
                tempPeopleArray.push(tempItem);
              });
              _this.$set(_this.receives, _this.handlerAddName, tempPeopleArray);
            },
          },
          {
            text: "关闭",
          },
        ],
      });
    },
    downloadMode() {
      //下载模版
      let tempButtons = _.keyBy(dsf.buttons.getPcButtons(), "actionName");
      let tempButton = tempButtons["system_template_download"];
      if (tempButton) {
        tempButton.handler.call(this, {
          owner: this,
          params: [
            "e52ee56f7f1b4d85a74832bccd0f45d9",
            "短信手机号导入模板.xlsx",
          ],
        });
      }
    },
    uploadProgress() {
      this.loading = dsf.layer.loading();
    },
    uploadSuccess(args) {
      dsf.layer.closeLoading(this.loading);
      this.loading = undefined;
      let idString = args.data || "";
      this.handleImportPhone(idString);
    },
    uploadError() {
      dsf.layer.closeLoading(this.loading);
      this.loading = undefined;
      dsf.layer.message("上传失败", false);
    },
  },
});
</script>