<template>
  <div class="send-flow-show-result">
    <div class="userbox" :class="{ batch: height > 0 }">
      <template v-if="list && list.length > 0">
        <span class="ds-button--icon-text" v-for="(item, index) in list" :key="index">{{ item }}</span>
      </template>
      <template v-if="msg">
        <span class="ds-button--icon-text">{{ msg }}</span>
      </template>
    </div>
    <div v-show="showCountDown" class="count-down">
      <span>页面将在</span>
      <span class="ds-button--icon-text">{{ time }}</span>
      <span>秒钟后自动关闭</span>
    </div>
  </div>
</template>
<script>
export default {
  name: "SendFlowDialog",
  props: {
    list: {
      type: Array,
      default() {
        return [];
      },
    },
    msg: {
      type: String,
      default() {
        return "";
      },
    },
    countDownTime: {
      type: String,
      default() {
        return "";
      },
    },
    showCountDown: {
      type: Boolean,
      default() {
        return true;
      },
    },
    height: {
      type: Number,
      default() {
        return 0;
      },
    },
  },
  data() {
    return {
      time: this.countDownTime || dsf.config.setting_flow_count_down_time || 3,
      timer: null,
    };
  },
  mounted() {
    if (this.showCountDown) {
      window.sendFlowTimer = this.timer = setTimeout(() => {
        this.countDown();
      }, 1000);
    }
  },
  methods: {
    yes() {
      return true;
    },
    countDown() {
      if (this.time > 0) {
        this.time--;
      }
      if (this.time <= 0) {
        if (dsf.global.$isMobile) {
          this.$router && this.$router.go(-1);
          return true;
        }
        document.querySelector(".sendFlow")?.click();
        if (window.opener) {
          // window.close();
        }
      } else {
        window.sendFlowTimer = this.timer = setTimeout(() => {
          this.countDown();
        }, 1000);
        this.$once("hook:beforeDestroy", () => {
          clearTimeout(this.timer);
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.send-flow-show-result {
  min-height: 120px;
  max-height: 300px;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  height: 100%;
  .userbox {
    text-align: center;
    margin-top: 20px;
    padding: 0 10px;
    span {
      margin-right: 10px;
      margin-bottom: 15px;
      display: inline-block;
      margin-left: 0;
      line-height: normal;
      max-width: max-content;
    }
    &.batch {
      text-align: left;
      margin-top: 20px;
      padding: 0 30px;
      span {
        display: inline-block;
        margin-right: 36px;
        min-width: 120px;
        margin-bottom: 30px;
      }
    }
  }
  .count-down {
    text-align: center;
    margin-top: 20px;
    color: rgb(170, 170, 170);
    text-align: center;
    width: 100%;
    .ds-button--icon-text {
      min-width: 20px;
    }
  }
}
</style>
