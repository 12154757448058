var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ds-control ds-form-item ds-select", class: _vm.getCss },
    [
      _vm.simple && _vm.readOnly && !_vm.isDesign
        ? [
            _c(
              "DsfTextProxy",
              _vm._b(
                {
                  model: {
                    value: _vm.value,
                    callback: function ($$v) {
                      _vm.value = $$v
                    },
                    expression: "value",
                  },
                },
                "DsfTextProxy",
                _vm._self.$props,
                false
              )
            ),
            _vm._t("error", function () {
              return [
                _vm.errors.length > 0
                  ? _c("div", { staticClass: "ds-error-text" }, [
                      _vm._v(" " + _vm._s(_vm.errorsMsg) + " "),
                    ])
                  : _vm._e(),
              ]
            }),
          ]
        : [
            _vm.showLabel
              ? _c(
                  "DsfFieldLabel",
                  {
                    style: _vm.getLabelWidthStyle(),
                    attrs: {
                      mode: _vm.showDataCaptionMode,
                      "show-icon": _vm.showDataCaptionIcon,
                      trigger: _vm.showDataCaptionTrigger,
                      "data-caption": _vm.dataCapion,
                      "is-design": _vm.isDesign,
                      owner: _vm._self,
                    },
                  },
                  [_vm._v(" " + _vm._s(_vm.$t(_vm.label)) + " ")]
                )
              : _vm._e(),
            _c(
              "div",
              {
                staticClass: "ds-form-block",
                style: _vm.getFormItemBlockMarginLeft(),
              },
              [
                !_vm.readOnly
                  ? _c(
                      "el-select",
                      {
                        ref: "select",
                        class: { "ds-error-border": _vm.errors.length > 0 },
                        attrs: {
                          disabled: _vm.disabled,
                          placeholder: _vm.placeholder,
                          "collapse-tags": _vm.collapseTags,
                          "multiple-limit": _vm.multipleLimit,
                          multiple: _vm.multiple,
                          filterable: _vm.filterable || _vm.allowCreate,
                          "filter-method": _vm.filter,
                          "allow-create": _vm.allowCreate,
                          loading: _vm.loading,
                        },
                        on: {
                          "visible-change": _vm.dropDownShow,
                          change: _vm.selectChange,
                          focus: function ($event) {
                            _vm.isFocus = true
                          },
                        },
                        model: {
                          value: _vm.selectValue,
                          callback: function ($$v) {
                            _vm.selectValue = $$v
                          },
                          expression: "selectValue",
                        },
                      },
                      [
                        _c(
                          "el-option",
                          {
                            attrs: {
                              label: "",
                              value: "",
                              show: !_vm.searchKey,
                            },
                          },
                          [
                            _c("span", { staticStyle: { color: "#c0c0c0" } }, [
                              _vm._v("请选择"),
                            ]),
                          ]
                        ),
                        _vm.isDesign
                          ? [
                              _c("el-option", {
                                attrs: { label: "数据1", value: "1" },
                              }),
                              _c("el-option", {
                                attrs: { label: "数据2", value: "2" },
                              }),
                              _c("el-option", {
                                attrs: { label: "数据3", value: "3" },
                              }),
                              _c("el-option", {
                                attrs: { label: "数据4", value: "4" },
                              }),
                              _c("el-option", {
                                attrs: { label: "数据5", value: "5" },
                              }),
                            ]
                          : _vm.isFocus
                          ? _vm._l(_vm.realItems, function (option) {
                              return _c("select-item", {
                                key: option[_vm.valueField],
                                attrs: {
                                  show: _vm.optionVisable(option),
                                  item: option,
                                },
                              })
                            })
                          : _vm.multiple && _vm.value
                          ? _vm._l(_vm.value, function (option) {
                              return _c(
                                "el-option",
                                {
                                  key: option.value,
                                  attrs: {
                                    show: _vm.optionVisable(option),
                                    label: _vm._formatSelectedText(option),
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm._formatOptionText(option)) +
                                      " "
                                  ),
                                ]
                              )
                            })
                          : !_vm.multiple &&
                            _vm.value &&
                            _vm.value.value &&
                            _vm.value.text
                          ? [
                              _c(
                                "el-option",
                                {
                                  attrs: {
                                    label: _vm._formatSelectedText(_vm.option),
                                    value: _vm.value.value,
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._formatOptionText(_vm.option)
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ]
                          : _vm._e(),
                      ],
                      2
                    )
                  : _vm.readOnly
                  ? _c(
                      "div",
                      { staticClass: "ds-form-readonly" },
                      [
                        _c(
                          "DsfTextProxy",
                          _vm._b(
                            {
                              attrs: { "show-label": false },
                              model: {
                                value: _vm.value,
                                callback: function ($$v) {
                                  _vm.value = $$v
                                },
                                expression: "value",
                              },
                            },
                            "DsfTextProxy",
                            _vm._self.$props,
                            false
                          )
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._t("error", function () {
                  return [
                    _vm.errors.length > 0
                      ? _c("div", { staticClass: "ds-error-text" }, [
                          _vm._v(" " + _vm._s(_vm.errorsMsg) + " "),
                        ])
                      : _vm._e(),
                  ]
                }),
              ],
              2
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }