var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.loadComplete
    ? _c("div", { staticClass: "ds-tab-panel-setting" }, [
        _vm._m(0),
        _c(
          "div",
          {
            staticClass: "ds-tab-panel-setting-warp",
            attrs: { type: "select" },
          },
          [
            _c(
              "draggable",
              _vm._b(
                {
                  attrs: { value: _vm.selectTabs },
                  on: { update: _vm.update },
                },
                "draggable",
                _vm.options,
                false
              ),
              [
                _vm._l(_vm.selectTabs, function (tab, i) {
                  return [
                    _c(
                      "el-tag",
                      {
                        key: tab.name,
                        attrs: { closable: "" },
                        on: {
                          close: function ($event) {
                            return _vm.removeTag(tab, i)
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(tab.title) + " ")]
                    ),
                  ]
                }),
              ],
              2
            ),
          ],
          1
        ),
        _vm._m(1),
        _c(
          "div",
          { staticClass: "ds-tab-panel-setting-warp" },
          [
            _c(
              "el-checkbox-group",
              {
                model: {
                  value: _vm.selectTabValues,
                  callback: function ($$v) {
                    _vm.selectTabValues = $$v
                  },
                  expression: "selectTabValues",
                },
              },
              [
                _vm._l(_vm.tabs, function (tab) {
                  return [
                    _c(
                      "el-checkbox",
                      {
                        key: tab.name,
                        attrs: { label: tab.name, size: "medium", border: "" },
                      },
                      [_vm._v(" " + _vm._s(tab.title) + " ")]
                    ),
                  ]
                }),
              ],
              2
            ),
          ],
          1
        ),
        _c("div", {}),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "ds-tab-panel-setting-title" }, [
      _c("div", { staticClass: "ds-tab-panel-setting-title-text" }, [
        _vm._v("已选标签"),
      ]),
      _c("div", { staticClass: "ds-tab-panel-setting-title-sbu" }, [
        _vm._v("（长按标签可拖拽排序）"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "ds-tab-panel-setting-title" }, [
      _c("div", { staticClass: "ds-tab-panel-setting-title-text" }, [
        _vm._v("可选标签"),
      ]),
      _c("div", { staticClass: "ds-tab-panel-setting-title-sbu" }, [
        _vm._v("（都不选时，显示所有）"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }