var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isDesign
    ? _c("div", { staticClass: "ds-platform-card-item-by-course" }, [
        _c("div", { staticClass: "cover" }, [_c("dsf-image")], 1),
        _vm._m(0),
        _vm._m(1),
      ])
    : _c(
        "div",
        {
          staticClass: "ds-platform-card-item-by-course",
          attrs: { title: _vm.data["title"] },
          on: {
            click: function ($event) {
              return _vm._clickBefore(_vm.click)
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "cover" },
            [
              _c("dsf-image", {
                attrs: {
                  src: _vm._f("imgFormat")(_vm.data["cover"]),
                  "error-src": _vm.errorSrc,
                },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "content" }, [
            _c("div", { staticClass: "title ellipsis-2" }, [
              _vm.data["isNew"]
                ? _c(
                    "span",
                    {
                      staticStyle: {
                        color: "#FF3600",
                        "font-weight": "bold",
                        "margin-right": "5px",
                      },
                    },
                    [_vm._v("NEW")]
                  )
                : _vm._e(),
              _c("span", [_vm._v(_vm._s(_vm.data["title"]))]),
            ]),
          ]),
          _c("div", { staticClass: "footer" }, [
            _c(
              "span",
              {
                staticClass: "ellipsis",
                attrs: { title: _vm.data["teacher"] },
              },
              [
                _vm._v(
                  _vm._s(
                    _vm.data["teacher"]
                      ? _vm.data["teacher"].replace(/\^/g, ",")
                      : ""
                  )
                ),
              ]
            ),
            _c("span", [
              _c("i", {
                staticClass: "iconfont icon-guankanbeifen4",
                staticStyle: { "font-size": ".9em" },
              }),
              _vm._v(" " + _vm._s(_vm.data["views"] || 0)),
            ]),
          ]),
        ]
      )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "content" }, [
      _c("div", { staticClass: "title ellipsis-2" }, [
        _c(
          "span",
          {
            staticStyle: {
              color: "#FF3600",
              "font-weight": "bold",
              "margin-right": "5px",
            },
          },
          [_vm._v("NEW")]
        ),
        _c("span", [
          _vm._v(
            "课题课题课题课题课题课题课题课题课题课题课题课题课题课题课题课题课题"
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "footer" }, [
      _c("span", [_vm._v("张三")]),
      _c("span", [
        _c("i", {
          staticClass: "iconfont icon-guankanbeifen4",
          staticStyle: { "font-size": ".9em" },
        }),
        _vm._v(" 1234"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }