<template>
  <div class="ds-control ds-datagrid" :class="getCss">
    <DsfPanel class="ds-datagrid-panel" :style="getListStyle" :has-title="showTitle" :has-header="showHead" :background-config="backgroundConfig" title-border>
      <template v-slot:title>
        {{ label }}
      </template>
      <template v-slot:header-left>
        <slot name="header-left">
          <div class="ds-datagrid-header-left">
            <span class="ds-datagrid-header-left-showType">
              <i v-for="item in layoutInfoCopy" :key="item.id" :class="['iconfont', item.icon, { active: currentShowLayout.id == item.id }]" @click="currentShowLayout = item"></i>
            </span>
          </div>
        </slot>
      </template>
      <template v-slot:header>
        <slot name="header">
          <!--列表头部 start-->
          <div class="ds-datagrid-header">
            <div class="ds-datagrid-header-right">
              <div v-if="checkboxColumn.show" class="head-right-item" style="margin-left: 10px">
                <span v-if="!isSingleChecked" class="ds-datagrid-checkednumber ds_font5">
                  <i class="icon-093info iconfont icon-color ds_icon_4" style="margin-right: 5px"></i>已选：
                  <font style="margin: 0 5px">
                    {{ multipleSelection.length}}
                  </font>条
                </span>
                <span v-else-if="multipleSelection" class="ds-datagrid-checkednumber ds_font5">
                  <i class="icon-093info iconfont icon-color ds_icon_4" style="margin-right: 5px"></i>已选
                </span>
              </div>
              <div v-if="showSearch" class="head-right-item" style="margin-left: 10px">
                <form>
                  <el-input v-model="searchStr" :placeholder="searchPlaceholder" class="search-input"></el-input>
                  <button class="search-button">
                    <i class="iconfont icon-fangdajing"></i>
                  </button>
                </form>
              </div>

              <div v-if="buttons.length" class="head-right-item" style="margin-left: 10px">
                <DsfButtonBar
                  :is-design="isDesign"
                  ref="buttonbar"
                  :more-button-style="buttonsMoreStyle"
                  :more-button-text="buttonsMoreText"
                  :max-number="buttonsMaxNumber"
                  :buttons="buttons"
                  :render-button="renderButton"
                  :local="local"
                  :class="{ 'ds-no-padding': true }"></DsfButtonBar>
              </div>
              <div class="head-right-item" slot-name="header-attach">
                <slot name="header-attach"></slot>
              </div>
            </div>
          </div>
          <!--列表头部 end-->
        </slot>
      </template>
      <template v-slot:default>
        <div class="ds-datagrid-box">
          <div class="ds-datagrid-search-wrap" :class="{'extend':superSearchExtend}">
            <slot name="search-bar">
              <div v-if="superSearchInfo.length > 0">
                <form @submit.stop.prevent="search">
                  <div style="float: left; min-height: 1px" :style="{ width: '100%' }">
                    <!--高级检索-->
                    <DsfSuperSearch
                      ref="superSearchBar"
                      :super-search-row-border="superSearchRowBorder"
                      :show-fitler="showSuperSearchFilter"
                      :search-item-setting="superSearchInfo"
                      :owner="_self"
                      :default-rows-number="superSearchDefaultRowsNumber"
                      :value-change-trigger="!superSearchButtonTrigger"
                      @change-height="superSearchChangeHeight"
                      @extend="superSearchExtendHandler"
                      @loaded="superSearchLoadedHandler">
                    </DsfSuperSearch>
                  </div>
                </form>
              </div>
            </slot>
            <slot name="custom-order" :custom-order="customerOrder">
              <dsf-custom-order v-if="customerOrder.length && currentShowLayout.type == 'cardList'" :custom-order="customerOrder">
                <slot name="custom-order-right" :data="data" :count="pageCount"></slot>
              </dsf-custom-order>
            </slot>
          </div>
          <!--设计状态-->
          <template v-if="isDesign">
            <template v-for="item in layoutInfoCopy">
              <div v-if="item.type == 'table'" :key="item.id" v-show="currentShowLayout.id == item.id" class="ds-datagrid-content-wrap" :style="getContentWrapStyle(item)">
                <dsf-static-list-by-table
                  v-if="!refreshTable"
                  ref="table"
                  :is-design="isDesign"
                  :is-editable="isEditable"
                  :columns="columns"
                  :checkbox-column="checkboxColumn"
                  :index-column="indexColumn"
                  :operate-column="operateColumn"
                  :row-buttons="rowButtons"
                  :render-row-Button="renderRowButton"
                  :is-single-checked="isSingleChecked"
                  :border="true"
                  :fit="true"
                  :header-cell-class-name="getHeaderClass"
                  :data="staticValue"
                  :height="tableHeight"
                  :customer-order="customerOrder"
                  :is-re-draw-hander="isReDrawHander"
                  :row-buttons-max-number="rowButtonsMaxNumber"
                  :row-buttons-more-style="rowButtonsMoreStyle"
                  :row-buttons-more-text="rowButtonsMoreText">
                  <template v-slot:[column.columnId]="scope" v-for="column in getLastColumns()">
                    <slot :name="column.columnId" v-bind="scope"></slot>
                  </template>
                </dsf-static-list-by-table>
              </div>
              <div v-else-if="item.type == 'cardList'" :key="item.id" v-show="currentShowLayout.id == item.id" class="ds-datagrid-content-wrap" :style="getContentWrapStyle(item)">
                <dsf-static-list-by-card
                  :data="staticValue"
                  :column-number="cardListColumnNumber"
                  :column-width="cardListColumnWidth"
                  :margin-top="cardListMarginTop"
                  :checkbox="checkboxColumn.show"
                  :max-check-num="isSingleChecked ? 1 : 0"
                  :checkbox-style="cardListCheckboxStyle"
                  :checkbox-size="cardListCheckboxSize"
                  :checkbox-position="cardListCheckboxPosition"
                  is-design>
                  <template v-slot:default="scope">
                    <div class="ds-datagrid-slot" slot-name="default">
                      <slot name="default" v-bind="scope"></slot>
                    </div>
                  </template>
                </dsf-static-list-by-card>
              </div>
            </template>
          </template>
          <!--非设计状态-->
          <template v-else>
            <template v-for="item in layoutInfoCopy">
              <div v-if="item.type == 'table'" :key="item.id" v-show="currentShowLayout.id == item.id" class="ds-datagrid-content-wrap" :style="getContentWrapStyle(item)">
                <dsf-static-list-by-table
                  ref="table"
                  :columns.sync="columns"
                  :data="currentPageData"
                  :height="tableHeight"
                  :checkbox-column="checkboxColumn"
                  :index-column="indexColumn"
                  :operate-column="operateColumn"
                  :row-buttons="rowButtons"
                  :is-single-checked="isSingleChecked"
                  :header-cell-class-name="getHeaderClass"
                  :customer-order="customerOrder"
                  :is-re-draw-hander="isReDrawHander"
                  :row-buttons-max-number="rowButtonsMaxNumber"
                  :row-buttons-more-style="rowButtonsMoreStyle"
                  :row-buttons-more-text="rowButtonsMoreText"
                  :is-design="false"
                  border
                  stripe
                  fit
                  @table-column-resize="tableColumnResize">
                  <slot></slot>
                  <template v-slot:[column.columnId]="scope" v-for="column in getLastColumns()">
                    <slot :name="column.columnId" v-bind="scope"></slot>
                  </template>
                </dsf-static-list-by-table>
              </div>
              <div v-else-if="item.type == 'cardList'" :key="item.id" v-show="currentShowLayout.id == item.id" class="ds-datagrid-content-wrap" :style="getContentWrapStyle(item)">
                <dsf-static-list-by-card
                  ref="cardBox"
                  :data="currentPageData"
                  :column-number="cardListColumnNumber"
                  :column-width="cardListColumnWidth"
                  :margin-top="cardListMarginTop"
                  :checkbox="checkboxColumn.show"
                  :max-check-num="isSingleChecked ? 1 : 0"
                  :checkbox-style="cardListCheckboxStyle"
                  :checkbox-size="cardListCheckboxSize"
                  :checkbox-position="cardListCheckboxPosition">
                  <template v-slot:default="scope">
                    <slot name="default" v-bind="scope"></slot>
                  </template>
                </dsf-static-list-by-card>
              </div>
            </template>
          </template>
          <template v-if="isDesign">
            <div class="ds-datagrid-pager" :style="{ 'text-align': paginationPosition }">
              <div v-if="currentShowLayout.type == 'table'" class="dsf-static-list-by-table-button-group">
                <DsfButton :text="isEditable ? '完成编辑' : '编辑模式'" size="small" @click="isEditable = !isEditable"></DsfButton>
                <template v-if="isEditable">
                  <DsfButton size="small" text="新增行" @click="addRowByTable"></DsfButton>
                  <DsfButton size="small" text="批量删除" @click="removeRowsByTable"></DsfButton>
                </template>
              </div>
              <el-pagination
                v-if="showPagination"
                ref="pagination"
                :class="[paginationStyle]"
                :hide-on-single-page="false"
                :total="pageCount"
                :page-size="pageSize"
                :page-sizes="limits"
                :current-page.sync="currentPage"
                :layout="'slot,'+pagerLayout"
                @size-change="pageSizeChange">
                <span v-if="currentShowLayout.type == 'table'" style="margin-right:10px">
                  <a title="还原列表" class="ds-datagrid-pager-button" @click="headerReset()">
                    <DsfIcon name="chehuisekuai"></DsfIcon>
                  </a>
                  <a title="刷新" class="ds-datagrid-pager-button">
                    <DsfIcon name="shuaxin"></DsfIcon>
                  </a>
                </span>
              </el-pagination>
            </div>
          </template>
          <div v-else-if="showPagination" class="ds-datagrid-pager" :style="{ 'text-align': paginationPosition }">
            <el-pagination
              ref="pagination"
              :class="[paginationStyle]"
              :hide-on-single-page="false"
              :total="pageCount"
              :page-size="pageSize"
              :page-sizes="limits"
              :current-page.sync="currentPage"
              :layout="'slot,'+pagerLayout"
              @size-change="pageSizeChange">
              <span v-if="currentShowLayout.type == 'table'" style="margin-right:10px">
                <a title="还原列表" class="ds-datagrid-pager-button" @click="headerReset()">
                  <DsfIcon name="chehuisekuai"></DsfIcon>
                </a>
                <a title="刷新" class="ds-datagrid-pager-button">
                  <DsfIcon name="shuaxin"></DsfIcon>
                </a>
              </span>
            </el-pagination>
          </div>
        </div>
      </template>
    </DsfPanel>
  </div>
</template>

<script>
import DsfStaticListByTable from "./staticListByTable";
import DsfStaticListByCard from "./staticListByCard";
import DsfSuperSearch from "./superSearch";
import { createDesignDefaultColumns, createFilterResult, getLastColumns, headerSetting, updateWidthToFilterColumns } from "./datagridHelper";

const key = "_id";

export default dsf.component({
  name: "DsfStaticList",
  ctrlCaption: "静态列表",
  mixins: [$mixins.control, $mixins.list, $mixins.dataResult],
  components: { DsfSuperSearch, DsfStaticListByTable, DsfStaticListByCard },
  props: {
    //静态值，用户原型设计
    staticValue: {
      type: Array,
      default() {
        return [
          {
            _id: dsf.uuid(8)
          }
        ];
      }
    },
    /********基础属性*********/
    // 标题
    label: {
      type: String,
      default: "静态列表"
    },
    // 检索框提示文字
    searchPlaceholder: {
      type: String,
      default: "请输入检索关键字"
    },
    // 高度是否自适应
    fit: {
      type: Boolean,
      default: true
    },
    renderButton: {
      type: Function,
      default: null
    },
    // 按钮栏设置
    buttons: {
      type: Array,
      default() {
        return [];
      }
    },
    // 最大按钮数量
    buttonsMaxNumber: {
      type: Number,
      default: 4
    },
    // 按钮栏更多按钮形态
    buttonsMoreStyle: {
      type: String,
      default: ""
    },
    // 按钮栏更多按钮文字
    buttonsMoreText: {
      type: String,
      default: "更多"
    },
    // 按钮栏位置
    buttonBarPosition: {
      type: String,
      default: "head"
    },
    // 按钮高级检索右侧显示模式
    buttonBarPositionMode: {
      type: String,
      default: "normal"
    },
    // 是否显示头部
    showHead: {
      type: Boolean,
      default: true
    },
    // 是否显示标题
    showTitle: {
      type: Boolean,
      default: true
    },
    // 是否显示检索框
    showSearch: {
      type: Boolean,
      default: true
    },
    // 是否显示checkbox列
    checkboxColumn: {
      type: Object,
      default() {
        return {
          show: true,
          width: dsf.config.list.checkColumn.width
        };
      }
    },
    // 是否仅单选
    isSingleChecked: {
      type: Boolean,
      default: false
    },

    /********数据查询属性*********/
    // 高级查询设置
    superSearchInfo: {
      type: Array,
      default() {
        return [];
      }
    },
    // 高级查询行间隔线
    superSearchRowBorder: {
      type: Boolean,
      default: true
    },
    // 高级查询默认初始化行数
    superSearchDefaultRowsNumber: {
      type: Number,
      default: 2
    },
    // 自定义排序
    customerOrder: {
      type: Array,
      default() {
        return [];
      }
    },
    // 是否显示分页(最多显示100条)
    showPagination: {
      type: Boolean,
      default: true
    },
    // 分页位置
    paginationPosition: {
      type: String,
      default: "right"
    },
    // 分页样式
    paginationStyle: {
      type: String,
      default: "style1"
    },
    // 每页显示条数(逗号分隔)
    limits: {
      type: Array,
      default() {
        return [15, 20, 30, 40, 50, 100];
      }
    },
    // 分页组建的布局,自定义分页排版(逗号分隔)
    pagerLayout: {
      type: String,
      default: "total,prev,pager,next,jumper,sizes"
    },
    scrollPageSize: {
      type: Number,
      default: 15
    },

    /********通用属性*********/
    // 数据展示方式
    layoutInfo: {
      type: Array,
      default() {
        return [
          {
            id: "default",
            name: "表格",
            icon: "icon-biaodanzujian-biaoge",
            type: "table",
            typeName: "表格",
            backgroundColor: "#fff"
          }
        ];
      }
    },
    // 数据行按钮设置
    rowButtons: {
      type: Array,
      default() {
        return [];
      }
    },
    renderRowButton: {
      type: Function,
      default: null
    },
    // 数据行按钮最大数量
    rowButtonsMaxNumber: {
      type: Number,
      default: 4
    },
    // 数据行更多按钮形态
    rowButtonsMoreStyle: {
      type: String,
      default: "icon-text"
    },
    // 数据行更多按钮文字
    rowButtonsMoreText: {
      type: String,
      default: "更多"
    },

    /********table属性*********/
    // 是否显示索引列
    indexColumn: {
      type: Object,
      default() {
        return {
          show: true,
          width: 60
        };
      }
    },
    // 是否显示操作按钮
    operateColumn: {
      type: Object,
      default() {
        return {
          show: true,
          width: 100
        };
      }
    },
    // 表头设置
    columns: {
      type: Array,
      default() {
        return [];
      }
    },
    /********卡片列表属性*********/
    // 一行N列
    cardListColumnNumber: {
      type: Number,
      default: 3
    },
    // 列宽
    cardListColumnWidth: {
      type: String,
      default: "30%"
    },
    // 上下间隙
    cardListMarginTop: {
      type: String,
      default: "15px"
    },
    // 选择框尺寸
    cardListCheckboxStyle: {
      type: String,
      default: "style1"
    },
    // 选择框尺寸  big, medium, small
    cardListCheckboxSize: {
      type: String,
      default: "medium"
    },
    // 选择框位置
    cardListCheckboxPosition: {
      type: String,
      default: "top right"
    },

    /********其他*********/
    // 高级检索是否显示筛选栏
    showSuperSearchFilter: {
      type: Boolean,
      default: false
    },
    // 分页页码按钮最多显示个数
    pagerCount: {
      type: Number,
      default: 5
    },
    // 一页显示多少条记录
    pageSize: {
      type: Number,
      default: 15
    },
    slots: {
      type: Array,
      default() {
        return [
          {
            name: "default",
            controls: []
          }
        ];
      }
    },
    contentWrapMargin: {
      type: String,
      default: "0px"
    },
    isTreeTable: {
      type: Boolean,
      default: false
    },
    extendDefaultAll: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      refreshTable: false,
      currentPage: 1,
      isReDrawHander: false,
      searchStr: "",
      removeTable: false,
      // 多选选中值
      multipleSelectionCopy: [],
      //高级检索高度
      superSearchHeight: 0,
      superSearchExtend: false,
      superValue: {},
      // 默认的展示形式
      currentShowLayout: {},
      elTable: null,
      // 设计器中，对于表格是否启用编辑状态
      isEditable: false
    };
  },
  computed: {
    treeProps() {
      if (this.isTreeTable) {
        return { children: "children" };
      } else {
        return { children: "" };
      }
    },
    pageCount() {
      return this.isDesign ? 1 : this.staticValue.length;
    },
    currentPageData() {
      let { staticValue, pageSize } = this;
      let currentPage = this.currentPage - 1;
      currentPage = currentPage < 0 ? 0 : currentPage;
      let index = currentPage * pageSize;
      return staticValue.slice(index, index + pageSize);
    },
    $errors() {
      return this.$data._errors;
    },
    // 当前页数据所有的id
    dataIds() {
      return this.staticValue.map(({ _id }) => _id);
    },
    // 多选/单选选中值
    multipleSelection: {
      get() {
        let sel = this.multipleSelectionCopy;
        if (this.isSingleChecked) {
          return (sel && sel.length && sel[0]) || null;
        } else {
          return sel || [];
        }
      },
      set(to) {
        if (this.isSingleChecked) {
          if (dsf.type(to) == "array") {
            this.multipleSelectionCopy = to.length ? [to[0]] : [];
          } else {
            this.multipleSelectionCopy = to ? [to] : [];
          }
        } else {
          this.multipleSelectionCopy = to || [];
        }
      }
    },
    // 是否禁用滚动加载
    infiniteScrollDisabled() {
      if (!this.hasInfiniteScroll) {
        return true;
      }
      return this.staticValue.length >= this.pageCount;
    },
    layoutInfoCopy() {
      if (this.isDesign || this.layoutInfo.length < 2) {
        return this.layoutInfo;
      }
      return this.layoutInfo.filter((info) => {
        return info.type != "table" || (!this.hasInfiniteScroll && !info.hide);
      });
    },
    contentWrapHeight() {
      if (this.fit) {
        let paginationHeight = this.showPagination || this.isDesign ? 57 : 0;
        let customOrderHeight = 0;
        if (this.currentShowLayout.type == "cardList") {
          if (this.customerOrder.length) {
            customOrderHeight = 41;
          }
          if (paginationHeight) {
            paginationHeight += 15;
          }
        }
        return `calc(100% - ${this.tableMarginTop}px - ${paginationHeight + customOrderHeight}px - ${this.contentWrapMargin})`;
      }
      return "auto";
    },
    tableMarginTop() {
      return this.superSearchHeight > 0 ? this.superSearchHeight : 0;
    },
    tableHeight() {
      let fit = this.fit;
      if (fit) {
        return "100%";
      }
      return "auto";
    },
    panelHeight() {
      let fit = this.fit;
      if (this.isDesign) {
        return "100%";
      } else {
        return fit ? "100%" : "auto";
      }
    },
    getCss() {
      return [
        this.$getClass(),
        {
          fit: this.fit,
          "has-pagination": this.showPagination,
          "has-checkbox": this.checkboxColumn.show,
          "card-model": this.currentShowLayout.type == "cardList"
        }
      ];
    },
    getListStyle() {
      return {
        height: this.panelHeight
      };
    },
    checkedNumber() {
      if (!checkboxColumn.show) {
        return 0;
      }
      if (!isSingleChecked) {
        return this.multipleSelection.length;
      }
      return this.multipleSelection ? 1 : 0;
    },
    superSearchBar() {
      return this.$refs.superSearchBar;
    },
    metadataDict() {
      let dict = this?.$vm?.metadataDict;
      if (dict) {
        let arr = {};
        _.each(dict, (it, k) => {
          let cm = this.childMetaDataCodes;
          let index = _.findIndex(cm, (it1) => it1 == it.id);
          if (index >= 0) {
            arr[k] = it;
          }
        });
        return arr;
      }
      return {};
    },
    searchWarpStyle() {
      let res = {
        height: this.superSearchHeight + "px"
      };
      if (this.currentShowLayout.type == "cardList" && this.customerOrder.length) {
        res["height"] = this.superSearchHeight + 41 + "px";
      }
      return res;
    }
  },
  created() {
    if (this.checkboxColumn && dsf.isUnDef(this.checkboxColumn.width)) {
      this.checkboxColumn.width = dsf.config.list.checkColumn.width;
    }

    createDesignDefaultColumns.call(this);
    let pSize = _.find(this.limits, (s) => s == this.pageSize);
    if (!pSize) {
      this.$emit("update:pageSize", this.limits[0]);
    }
    //监听当前页面和页码发生变化后数据
    this.watchCurrentPageAndPageSize();

    this.currentShowLayout = this.layoutInfo[0];
  },
  beforeMount() {},
  mounted() {
    this.elTable = this.getElTable();
  },
  updated() {
    this.doLayout();
  },
  beforeDestroy() {
    this.unwatch && this.unwatch();
    if (this.$refs.pagination) {
      this.$refs.pagination.$destroy();
    }
    this.staticValue.splice(0, this.staticValue.length);
  },
  destroyed() {},
  methods: {
    
    getElTable() {
      return this.$refs.table?.[0];
    },
    //监听页码和显示行数变化
    watchCurrentPageAndPageSize() {
      function fn() {
        return this.currentPage + "," + this.pageSize;
      }
      if (!this.hasInfiniteScroll) {
        this.unwatch = this.$watch(fn, (v, ov) => {
          let pageNum = Math.ceil(this.pageCount / this.pageSize);
          if (this.currentPage > pageNum) {
            this.currentPage = pageNum <= 0 ? 1 : pageNum;
            return;
          }
        });
      }
    },
    //获取columns的最底层列
    getLastColumns(column) {
      return getLastColumns.call(this, column);
    },
    reset() {
      this.clearSelect();
      // this.pageCount = 0;
      this.currentPage = 1;
    },
    //更新索引
    indexMethod(index) {
      return (this.currentPage - 1) * this.pageSize + index + 1;
    },
    resize() {
      if (this.currentShowLayout.id == "default") {
        this.doLayout();
      } else if (!this.isDesign) {
        let $layoutBox = this.$refs["layout-" + this.currentShowLayout.id];
        if ($layoutBox) {
          $layoutBox = $layoutBox.length ? $layoutBox[0] : $layoutBox;
          let $layoutItem = $layoutBox?.querySelector(".ds-datagrid-layout-item");
          if ($layoutItem && $layoutItem.offsetWidth > 0) {
            let columns = Math.round(window.innerWidth / $layoutItem.offsetWidth);
            if (!isNaN(columns) && columns > 2 && (this.currentShowLayout.alternateNum || 0) < columns - 1) {
              this.$set(this.currentShowLayout, "alternateNum", columns - 1);
            }
          }
        }
      }
      if (this.superSearchBar) {
        this.superSearchBar.resize();
      }
    },
    pageSizeChange(evt) {
      if (this.isSingleChecked) {
        this.multipleSelection = null;
      }
      this.$emit("update:pageSize", evt);
    },
    // 某些时候el-table行高计算不会被触发，导致其内部行错位，此时调用此方法触发其内部的行高计算
    doLayout() {
      let table = this.getElTable();
      if (table && table.doLayout) {
        table.doLayout();
      }
    },
    updateSubControls(ctrlProps, ctrl) {
      return ctrlProps;
    },
    getSelectedRows() {
      return this.multipleSelection;
    },
    getHeaderClass({ row, column, rowIndex, columnIndex }) {
      let classList = [];
      if (column.columnKey) {
        let result = _.filter(this.customerOrder, (order) => order.column == column.columnKey);
        if (result.length > 0) {
          let order = result[0];
          if (order.sortType) {
            let sort = order.sortType + "ending";
            column.order = sort;
            classList.push(sort);
          } else {
            column.order = null;
          }
        }
      }
      return classList.join(" ");
    },
    getReturnValue() {
      return this.getSelectedRows();
    },
    headerReset() {
      this.$emit("update:tableFilterResult", []);
      this.$clearCustom(this.caption);
      this.elTable.headerReset();
    },
    //表格列尺寸拖动事件
    tableColumnResize(realFilterColumns) {
      let filterColumns = this.tableFilterResult;
      // 如果this.tableFilterResult不存在，则生成一份这样的数据
      if (!filterColumns?.length) {
        let columnsTree = this.$refs.table[0].columnsTree;
        filterColumns = createFilterResult(columnsTree);
      }
      // 将宽度从realFilterColumns中赋值到filterColumns
      updateWidthToFilterColumns(realFilterColumns, filterColumns);
      this.$addCustom(this.caption, {
        tableFilterResult: filterColumns
      });
    },
    getContentWrapStyle(item) {
      let res = {
        height: this.contentWrapHeight,
        "background-color": item.backgroundColor
      };
      if (this.currentShowLayout.type == "cardList") {
        res["padding"] = this.fit ? "20px 20px 0" : "20px 0 0";
      }
      return res;
    },
    /**高级检索相关事件 start */
    superSearchLoadedHandler(v) {
      if (!this.isDesign) {
        this.superValue = v;
        this.currentPage = 1;
        this.$nextTick(() => {
          if (this.superSearchBar) {
            this.superSearchBar.emitHeight();
          }
        });
      }
    },
    //高级检索区域高度发生变化
    superSearchChangeHeight(v) {
      if (!this.superSearchExtend) {
        this.superSearchHeight = v;
      }
    },
    //高级检索展开
    superSearchExtendHandler(v) {
      this.superSearchExtend = v;
    },
    /**高级检索相关事件 end */
    // 制造假的元数据
    $getChildMetadataList() {
      if (this.isDesign) {
        return childMetadataList;
      }
    },
    /*****表格编辑模式 start****/
    // 新增行
    addRowByTable() {
      this.staticValue.push({
        _id: dsf.uuid(8)
      });
    },
    // 批量删除
    removeRowsByTable() {
      let table = this.getElTable();
      let eltable = table.$refs.table;
      let selection = eltable.selection;
      if (!selection.length) {
        dsf.layer.message("请至少选择一条数据", false);
        return;
      }

      _.forEach(selection, (it) => table.removeRow(it));
      eltable.selection = [];
    }
    /*****表格编辑模式 end****/
  },
  watch: {
    isTreeTable: {
      handler(v) {
        this.refreshTable = true;
        this.$nextTick(() => {
          this.refreshTable = false;
        });
      }
    },
    columns: {
      handler(v, ov) {
        if (this.isDesign) {
          this.isReDrawHander = true;
          this.$nextTick(() => {
            this.isReDrawHander = false;
          });
        }
      },
      deep: true
    },
    staticValue: {
      handler(v) {
        if (!v || v.length == 0) {
          this.$emit("update:staticValue", [
            {
              _id: dsf.uuid(8)
            }
          ]);
          return;
        }
        for (let i = 0; i < v.length; i++) {
          !("$editing" in v[i]) && this.$set(v[i], "$editing", false);
          !("$hash" in v[i]) && this.$set(v[i], "$hash", dsf.uuid());
          if ("$order" in v[i]) {
            this.$set(v[i], "$order", i);
          } else {
            v[i]["$order"] = i;
          }
          v[i].$index = i;
        }
        this.$nextTick(() => {
          this.resize();
        });
      },
      deep: false
    },
    tableMarginTop() {
      this.$nextTick(() => {
        this.doLayout();
      });
    },
    superSearchInfo(v) {
      if (v.length == 0) {
        this.superSearchHeight = 0;
      }
    },
    "currentShowLayout.id"(to) {
      if (to) {
        this.$nextTick(() => {
          this.resize();
        });
      }
    }
  },
  design: {
    isMask: false,
    fit: true,
    settingMenus: [
      ...($mixins.list.design.settingMenus || []),
      {
        text: "表头设置",
        command: "header-setting",
        handler: headerSetting
      }
    ]
  }
});

// 假元数据
const childMetadataList = [
  {
    key: "dsfa_rm.name",
    metadata: {
      name: "名称",
      at: "dsfa.rm",
      code: "name",
      valueAttributes: [
        {
          name: "文本",
          code: "text"
        }
      ]
    }
  },
  {
    key: "dsfa_rm.ID",
    metadata: {
      name: "标识",
      at: "dsfa.rm",
      code: "ID",
      valueAttributes: [
        {
          name: "文本",
          code: "text"
        }
      ]
    }
  },
  {
    key: "dsfa_rm.type",
    metadata: {
      name: "类型",
      at: "dsfa.rm",
      code: "type",
      valueAttributes: [
        {
          name: "文本",
          code: "text"
        },
        {
          name: "值",
          code: "value"
        }
      ]
    }
  },
  {
    key: "dsfa_rm.code",
    metadata: {
      name: "标识",
      at: "dsfa.rm",
      code: "code",
      valueAttributes: [
        {
          name: "文本",
          code: "text"
        }
      ]
    }
  },
  {
    key: "dsfa_rm.path",
    metadata: {
      name: "标识",
      at: "dsfa.rm",
      code: "path",
      valueAttributes: [
        {
          name: "文本",
          code: "text"
        }
      ]
    }
  },
  {
    key: "dsfa_rm.treeinfo",
    metadata: {
      name: "树信息",
      at: "dsfa.rm",
      code: "treeinfo",
      valueAttributes: [
        {
          name: "tree节点类型",
          code: "type"
        },
        {
          name: "tree节点父标识",
          code: "pid"
        },
        {
          name: "tree节点图标",
          code: "icon"
        },
        {
          name: "tree全局标识",
          code: "globalid"
        },
        {
          name: "tree节点等级",
          code: "level"
        }
      ]
    }
  },
  {
    key: "dsfa_rm.ds_update_user_name",
    metadata: {
      name: "修改人",
      at: "dsfa.rm",
      code: "ds_update_user_name",
      valueAttributes: [
        {
          name: "修改人姓名",
          code: "ds_update_user_name"
        }
      ]
    }
  },
  {
    key: "dsfa_rm.ds_update_time",
    metadata: {
      name: "修改时间",
      at: "dsfa.rm",
      code: "ds_update_time",
      valueAttributes: [
        {
          name: "修改时间",
          code: "ds_update_time"
        }
      ]
    }
  },
  {
    key: "dsfa_rm.ds_order",
    metadata: {
      name: "排序码",
      at: "dsfa.rm",
      code: "ds_order",
      valueAttributes: [
        {
          name: "排序码",
          code: "ds_order"
        }
      ]
    }
  }
];
</script>