export default {
  name: "DsfIcon",
  functional: true,
  props: {
    name: {
      type: String,
      default() {
        return "";
      }
    }
  },
  render(h, _vm) {
    var _c = _vm._c || h;
    let cls = _vm.data.class;
    if (cls) {
      if (dsf.type(cls) == 'string') {
        cls = [cls];
      } else if (dsf.type(cls) == 'object') {
        cls = _.map(cls, (v, k) => { return k })
      }
    } else {
      cls = [];
    }
    // let on = dsf.mix({}, _vm.listeners, _vm.data.nativeOn)
    let staticClass = [];
    if (_vm.data.staticClass) {
      if (dsf.type(_vm.data.staticClass) == 'array') {
        staticClass.push(..._vm.data.staticClass);
      }
      else {
        staticClass.push(_vm.data.staticClass);
      }
    }
    return _c("i", {
      key: _vm.data.key,
      attrs:_vm.data.attrs,
      staticStyle: _vm.data.staticStyle,
      staticClass: [...(staticClass), "iconfont"].join(" "),
      style: _vm.data.style,
      class: [(_vm.props.name ? 'icon-' + _vm.props.name : ''), ...cls],
      directives: _vm.data.directives,
      on: _vm._g(_vm.listeners),
    })
  }
};
