import DsfTemplate1HomeHeader from './homeHeader';
import DsfTemplate1HomeMenu from './homeMenu';

export {
  DsfTemplate1HomeHeader,
  DsfTemplate1HomeMenu
};

export default {
  install (Vue) {
    Vue.component(DsfTemplate1HomeHeader.name, DsfTemplate1HomeHeader);
    Vue.component(DsfTemplate1HomeMenu.name, DsfTemplate1HomeMenu);
  }
};