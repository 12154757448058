/*
 * @Descripttion: 资源目录操作组件
 * @Author: zhanghang
 * @Date: 2021-08-03 17:00:09
 * @LastEditors: zhanghang
 * @LastEditTime: 2021-08-04 10:05:17
 */

import DsfCatalogueOptions from "./catalogue";
function install(Vue){
  Vue.component(DsfCatalogueOptions.name, DsfCatalogueOptions);
}

export default {
  install
}