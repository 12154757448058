const addButton = dsf.buttons.addButton;
import handler from './handler';

//打开窗口
addButton({
  actionName: "system_open_window",
  caption: "打开窗口",
  text: "打开窗口",
  title: "打开窗口",
  icon: "",
  params: [
    { value: "", desc: "打开页面的地址", default: "", selector: 'url' },
    { value: "", desc: "窗口名称(只会同时存在一个名称相同的页面)。", default: "" }
  ],
  moduleName: "基础平台",
  groupName: "公共",
  handler: handler.system_open_window
});

//关闭窗口
addButton({
  actionName: "system_close_window",
  caption: "关闭窗口",
  text: "关闭",
  title: "关闭",
  icon: "icon-guanbi2",
  params: [
    { value: "false", desc: "关闭时是否要刷新父页面", default: "false" },
  ],
  moduleName: "基础平台",
  groupName: "公共",
  handler: handler.system_close_window
});

//模板下载
addButton({
  actionName: "system_template_download",
  caption: "模板下载",
  text: "模板下载",
  title: "模板下载",
  icon: "icon-yunduanxiazai",
  isTemplateDownload: true,
  params: [
    { value: "", desc: "文件ID", default: "" },
    { value: "", desc: "文件名称", default: "" },
  ],
  moduleName: "基础平台",
  groupName: "公共",
  handler: handler.system_template_download
});

//弹出模态框
addButton({
  actionName: "system_pop_modal",
  caption: "弹出模态框",
  text: "弹出模态框",
  title: "弹出模态框",
  icon: "icon-hao",
  params: [
    { value: "", desc: "模态框标题", default: "对话框" },
    { value: "", desc: "打开页面的地址", default: "无", selector: 'url' },
    { value: "", desc: "模态框宽,高（单位%或px或vh或vw  用,分割 高度请使用vh代替%）", default: "80%,500px" },
    { value: "", desc: "模态框保存按钮会抛出的事件, 方便二次开发", default: "system-dialog-save" },
    { value: "", desc: "$openDialog配置项，可以覆盖上面所有的参数（需用函数编写，返回一个配置项对象）", default: "无" },
    { value: "", desc: "按钮位置：left(居左)、center(居中)、right(居右)", default: "right" },
    { value: "", desc: "是否在顶层窗口弹出(如果触发弹出页面是iframe中加载，则会在顶层窗体上弹出)", default: "false" },
  ],
  moduleName: "基础平台",
  groupName: "公共",
  handler: handler.system_pop_modal
})

//关闭模态框
addButton({
  caption: "关闭模态框",
  text: "关闭模态框",
  title: "关闭模态框",
  actionName: "system_close_dialog",
  icon: "icon-guanbi2",
  params: [
    { value: "false", desc: "关闭时是否要刷新父页面", default: "false" },
  ],
  moduleName: "基础平台",
  groupName: "公共",
  handler: handler.system_close_dialog
});

//接口调用
addButton({
  caption: "接口调用",
  text: "接口调用",
  title: "接口调用",
  actionName: "system_interface_exec",
  icon: "icon-shuaxin1",
  params: [
    { value: "", desc: "接口地址", default: "无" },
    { value: "", desc: "提交类型", default: "post" },
    { value: "", desc: "提交参数", default: "无" },
    { value: "", desc: "调用成功后提示信息", default: "操作成功" },
    { value: "", desc: "调用失败后提示信息", default: "操作失败" },
    {
      value: "",
      desc: "调用成功后回调页面二次开发的函数名称",
      default: "无",
    },
  ],
  moduleName: "基础平台",
  groupName: "公共",
  handler: handler.system_interface_exec
})

//表单保存
addButton({
  caption: "保存",
  text: "保存",
  title: "保存",
  actionName: "system_form_save",
  icon: "icon-baocun",
  params: [
    { value: "", desc: "是否要刷新父页面", default: "true" },
    {
      value: "",
      desc: "保存时附加的参数多用于二次开发判断区分",
      default: "",
    },
    {
      value: "",
      desc: "如果弹出窗口打开表单,保存后是否关闭窗口",
      default: "false",
    },
    {
      value: "",
      desc: "保存成功的提示信息",
      default: "保存成功",
    },
    {
      value: "",
      desc: "保存失败的提示信息",
      default: "保存失败",
    },
  ],
  moduleName: "基础平台",
  groupName: "表单",
  handler: handler.system_form_save
})

// //返回当前表单数据
// addButton({
//   caption: "返回数据",
//   text: "返回数据",
//   title: "返回数据",
//   actionName: "system_form_data",
//   icon: "icon-baocun",
//   params: [],
//   moduleName: "基础平台",
//   groupName: "表单",
//   handler: handler.system_form_data
// })

//单行删除
addButton({
  caption: "单行删除",
  text: "单行删除",
  title: "单行删除",
  actionName: "system_datagrid_delete",
  icon: "icon-shanchu",
  params: [
    { value: "", desc: "删除依赖的唯一标识字段", default: "_id" },
    { value: "", desc: "是否级联删除树形结构下级数据", default: "false" },
    { value: "", desc: "执行完成后回调函数名称", default: "" }
  ],
  moduleName: "基础平台",
  groupName: "数据列表",
  handler: handler.system_datagrid_delete
});

//批量删除
addButton({
  caption: "批量删除",
  text: "批量删除",
  title: "批量删除",
  actionName: "system_datagrid_batch_delete",
  icon: "icon-shanchu",
  params: [
    { value: "", desc: "删除依赖的唯一标识字段", default: "_id" },
    { value: "", desc: "是否级联删除树形结构下级数据", default: "false" },
    { value: "", desc: "执行完成后回调函数名称", default: "" },
  ],
  moduleName: "基础平台",
  groupName: "数据列表",
  handler: handler.system_datagrid_batch_delete
});

//清空删除
addButton({
  caption: "清空条件",
  text: "清空条件",
  title: "清空条件",
  actionName: "system_datagrid_clear_condition",
  icon: "icon-shezhibeifen5",
  params: [],
  moduleName: "基础平台",
  groupName: "数据列表",
  handler: handler.system_datagrid_clear_condition
});

//导出
addButton({
  caption: "导出",
  text: "导出",
  title: "导出",
  actionName: "system_datagrid_export",
  icon: "icon-yunduanxiazai",
  params: [
    { value: "", desc: "是否弹出导出设置弹出框(不弹出用户选择导出配置对话框)", default: "false" },
    { value: "", desc: "导出文件类型，格式['描述|文件扩展名|icon(可选)|颜色(可选)|是否选中']", default: "['Excel|xlsx|icon-excel|#0F773D|1']" },
    { value: "", desc: "导出的文件名(如果“是否弹出导出设置弹出框”配置为false时，需要写全文件后缀)", default: "数据导出" },
    { value: "", desc: "导出规则代码", default: "rule-1" },
    { value: "", desc: "导出接口地址", default: "/office/excel/export/meta" },
    { value: "", desc: "导出接口额外参数，格式为a=1&b=2", default: "" },
    { value: "", desc: "true全部导出,false则在导出时如果列表中后勾选项会进行提示询问", default: "true" },
    { value: "", desc: "导出的文件类型为Excel时，是否带序号列", default: "true" }
  ],
  moduleName: "基础平台",
  groupName: "数据列表",
  handler: handler.system_datagrid_export
});

//导入
addButton({
  caption: "导入",
  text: "导入",
  title: "导入",
  actionName: "system_datagrid_import",
  icon: "icon-shangchuan",
  isUpload: true,
  params: [
    { value: "", desc: "导入接口地址", default: "/file/upload" },
    { value: "", desc: "导入参数", default: "" },
    { value: "", desc: "可上传的文件类型", default: "xlsx|xls" },
    { value: "", desc: "上传完成回调importComplete", default: "importComplete" },
  ],
  moduleName: "基础平台",
  groupName: "数据列表",
  handler: handler.system_datagrid_import
});

//列表行上传
addButton({
  caption: "上传",
  text: "上传",
  title: "上传",
  actionName: "system_datagrid_row_upload",
  icon: "icon-shangchuan",
  isUpload: true,
  params: [
    { value: "", desc: "上传接口地址", default: "/file/upload" },
    { value: "", desc: "上传参数", default: "" },
    { value: "", desc: "可上传的文件类型", default: "xlsx|xls" },
    { value: "", desc: "命名空间namespace", default: "" },
    { value: "", desc: "页名称pageName", default: "" },
    { value: "", desc: "主键列字段名字在列表行数据中的名字 rowData", default: "" },
    { value: "", desc: "文件字段在表单中的名字fileColumnName", default: "" },
    { value: "false", desc: "开启多选上传", default: "false" },
    { value: "", desc: "上传完成二次开发事件", default: "" },
  ],
  moduleName: "基础平台",
  groupName: "数据列表",
  handler: handler.system_datagrid_row_upload
});

// //列表检索
// addButton({
//   caption: "检索",
//   text: "检索",
//   title: "检索",
//   actionName: "system_datagrid_search",
//   icon: "icon-fangdajing",
//   params: [],
//   moduleName: "基础平台",
//   groupName: "数据列表",
//   handler: handler.system_datagrid_search
// });

//列表列设置
addButton({
  caption: "列设置",
  text: "列设置",
  title: "列设置",
  actionName: "system_datagrid_columns_filter",
  icon: "icon-fangdajing",
  params: [],
  moduleName: "基础平台",
  groupName: "数据列表",
  handler: handler.system_datagrid_columns_filter
})

//预导入
addButton({
  caption: "预导入",
  text: "预导入",
  title: "预导入",
  actionName: "system_datagrid_preimport",
  icon: "icon-shangchuan",
  isUpload: true,
  params: [
    { value: "", desc: "导入接口地址", default: "/file/upload" },
    { value: "", desc: "导入参数", default: "" },
    { value: "", desc: "可上传的文件类型", default: "xlsx|xls" },
    { value: "", desc: "弹出框页面地址", default: "", selector: 'url' },
    { value: "", desc: "确定上传的接口地址", default: "" },
    { value: "", desc: "确认上传的回调函数，若弹出框内页面提供了此函数，将会被调用", default: "submitCallBack" }
  ],
  moduleName: "基础平台",
  groupName: "数据列表",
  handler: handler.system_datagrid_preimport
})

//预导入
addButton({
  caption: "通用预导入",
  text: "通用预导入",
  title: "通用预导入",
  actionName: "system_datagrid_common_import",
  icon: "icon-shangchuan",
  isUpload: false,
  params: [
    { value: "", desc: "导入数据库表名", default: "" },
    { value: "", desc: "解析的sheetIndex", default: "" },
    { value: "", desc: "解析的开始行index", default: "" },
    { value: "", desc: "解析的开始列index", default: "" },
    { value: "", desc: "上传成功的回调函数，若弹出框内页面提供了此函数，将会被调用", default: "" }
  ],
  moduleName: "基础平台",
  groupName: "数据列表",
  handler: handler.system_datagrid_common_import
})

//子表新增
addButton({
  caption: "新增",
  text: "单行新增",
  title: "单行新增",
  actionName: "system_subtable_insert",
  icon: "icon-hao",
  params: [
    { value: "", desc: "总在第一行添加", default: "false" },
  ],
  moduleName: "基础平台",
  groupName: "子表",
  handler: handler.system_subtable_insert
})
//子表清空
addButton({
  caption: "清空",
  text: "清空",
  title: "清空",
  actionName: "system_subtable_clear",
  icon: "icon-shanchu",
  params: [],
  moduleName: "基础平台",
  groupName: "子表",
  handler: handler.system_subtable_clear
})

//子表选择
addButton({
  caption: "选择",
  text: "选择",
  title: "选择",
  actionName: "system_subtable_datachoice",
  icon: "icon-Magnifier",
  params: [
    { value: "", desc: "打开页面的地址", default: "无", selector: 'url' },
    { value: "", desc: "弹窗默认标题", default: "子表选择" },
    {
      value: "",
      desc: "弹窗宽高设置（使用/分开）",
      default: "1000px/70vh",
    },
    { value: "", desc: "传递默认选中值的方法名", default: "无" },
  ],
  moduleName: "基础平台",
  groupName: "子表",
  handler: handler.system_subtable_datachoice
})

//子表导入
addButton({
  caption: "导入",
  text: "导入",
  title: "导入",
  actionName: "system_subtable_import",
  icon: "icon-shangchuan",
  isUpload: true,
  params: [
    { value: "", desc: "导入接口地址", default: "/file/upload/excel" },
    { value: "", desc: "导入参数", default: "" },
    { value: "", desc: "可上传的文件类型", default: "xlsx|xls" },
    { value: "", desc: "值1(覆盖原有数据)、值2(追加到原有数据)、其他任意值(弹框提示追加还是覆盖)", default: "" },
    { value: "", desc: "导入成功后事件", default: "" },
  ],
  moduleName: "基础平台",
  groupName: "子表",
  handler: handler.system_subtable_import
});


//流程发送
addButton({
  caption: "发送",
  text: "发送",
  title: "发送",
  actionName: "system_flow_send",
  icon: "",
  params: [],
  moduleName: "基础平台",
  groupName: "流程",
  handler: handler.system_flow_send
});

//流程批量发送
addButton({
  caption: "批量发送",
  text: "批量发送",
  title: "批量发送",
  actionName: "system_batch_flow_send",
  icon: "",
  params: [
    {
      value: "",
      desc: "tableName",
      default: "tableName 若有几张表格，则以','分割",
    },
    {
      value: "",
      desc: "ObjectID",
      default: "ObjectID 若有几张表格，则以','分割",
    },
    {
      value: "",
      desc: "ObjectName",
      default: "ObjectName 若有几张表格，则以','分割",
    },
    {
      value: "",
      desc: "Size 发送到人宽,高（单位px/vw或vh 用,分割）",
      default: "720px,420px",
    }
  ],
  moduleName: "基础平台",
  groupName: "流程",
  handler: handler.system_batch_flow_send
});


//弹出流程意见框
addButton({
  caption: "弹出流程意见框",
  text: "弹出流程意见框",
  title: "弹出流程意见框",
  actionName: "system_flow_openDialog",
  icon: "icon-hao",
  params: [
    { value: "", desc: "模态框标题", default: "对话框" },
    { value: "", desc: "打开页面的地址", default: "无", selector: 'url' },
    {
      value: "",
      desc: "id",
      default: "id",
    },
    {
      value: "",
      desc: "sCurLinkID",
      default: "sCurLinkID",
    },
    {
      value: "",
      desc: "sPID",
      default: "sPID",
    },
    {
      value: "",
      desc: "模态框宽,高（单位%或px  用,分割）",
      default: "80%,500px",
    },
    {
      value: "",
      desc: "模态框保存按钮会抛出的事件, 方便二次开发",
      default: "system-dialog-save",
    },
  ],
  moduleName: "基础平台",
  groupName: "流程",
  handler: handler.system_flow_openDialog
});

addButton({
  caption: "销毁",
  text: "销毁",
  title: "销毁",
  actionName: "system_flow_destroy",
  icon: "",
  params: [
    {
      value: "",
      desc: "sPID",
      default: "sPID",
    }
  ],
  moduleName: "基础平台",
  groupName: "流程",
  handler: handler.system_flow_destroy
});



addButton({
  caption: "强制办结",
  text: "强制办结",
  title: "强制办结",
  actionName: "system_flow_forceClose",
  icon: "",
  params: [
    { value: "@[url('sPID')]", desc: "sPID", default: "" },
    { value: "@[url('sCurLinkID')]", desc: "sCurLinkID", default: "" },
  ],
  moduleName: "基础平台",
  groupName: "流程",
  handler: handler.system_flow_recovery //handler.system_flow_recovery
});

//退回
addButton({
  caption: "退回",
  text: "退回",
  title: "退回",
  actionName: "system_flow_sendBack",
  icon: "",
  if: "@[url('sPID')!=undefined]",
  params: [
    { value: "@[url('sPID')]", desc: "sPID", default: "" },
    { value: "@[url('sCurLinkID')]", desc: "sCurLinkID", default: "" },
  ],
  moduleName: "基础平台",
  groupName: "流程",
  handler: handler.system_flow_sendBack
})

//回收
addButton({
  caption: "回收",
  text: "回收",
  title: "回收",
  actionName: "system_flow_recovery",
  icon: "",
  params: [
    { value: "@[url('sPID')]", desc: "sPID", default: "" },
    { value: "@[url('sCurLinkID')]", desc: "sCurLinkID", default: "" },
  ],
  moduleName: "基础平台",
  groupName: "流程",
  handler: handler.system_flow_recovery //handler.system_flow_send_test
})

//强制回收
addButton({
  caption: "强制回收",
  text: "强制回收",
  title: "强制回收",
  actionName: "system_flow_forceRecovery",
  icon: "",
  params: [
    { value: "@[url('sPID')]", desc: "sPID", default: "" },
    { value: "@[url('sCurLinkID')]", desc: "sCurLinkID", default: "" },
  ],
  moduleName: "基础平台",
  groupName: "流程",
  handler: handler.system_flow_recovery //handler.system_flow_forceRecovery
})

//移交
addButton({
  caption: "移交",
  text: "移交",
  title: "移交",
  actionName: "system_flow_send_test",
  icon: "",
  params: [],
  moduleName: "基础平台",
  groupName: "流程",
  handler: dsf.noop //handler.system_flow_send_test
})

//填写处理意见
addButton({
  caption: "填写处理意见",
  text: "填写处理意见",
  title: "填写处理意见",
  actionName: "system_flow_add_comment",
  icon: "",
  params: [],
  moduleName: "基础平台",
  groupName: "流程",
  handler: handler.system_flow_add_comment //handler.system_flow_add_comment
})

addButton({
  caption: "删除节点",
  text: "删除节点",
  title: "删除节点",
  actionName: "system_navtree_delete",
  icon: "icon-shanchu",
  params: [
    { value: "", desc: "删除依赖的唯一标识字段", default: "_id" },
    { value: "", desc: "是否级联删除树形结构下级数据", default: "true" },
    { value: "", desc: "执行完成后回调函数名称", default: "" }
  ],
  moduleName: "基础平台",
  groupName: "导航树",
  handler: handler.system_navtree_delete //handler.system_flow_add_comment
})