<template>
  <div class="flow-file-list ds_uploadFile ds-upload">
    <table class="layui-table fileContainer upload-table" width="100%">
      <tbody>
        <tr v-for="(file, i) in Attachments" :key="i" class="item">
          <td class="textLeft">
            <div class="fileName">
              <i class="icon" :class="file.suffix"></i>
              <span>{{ file.originalFileName }}</span>
            </div>
          </td>
          <td class="blank fontpostion" width="100">
            {{ computeFileSize(file.size) }}
          </td>
          <td class="operate fontpostion" width="100">
            <span
              class="previewBtn iconfont"
              title="预览"
              @click="preview(file)"
            >&#xeb8d;</span
            >
            <span class="downLoad iconfont downLoadBtn">
              <i
                class="iconfont"
                title="下载"
                @click="downLoadFile(file)"
              >&#xec22;</i
              >
            </span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
export default {
  name: "DsfFlowFileList",
  props: {
    Attachments: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {};
  },
  created() {},
  methods: {
    preview(file) {
      let root = dsf.url.getWebPath("preview/file");
      const preUrl = dsf.config.setting_public_file_host;
      const relativePath = file.relativePath;
      let url = `${root}?url=${preUrl}${relativePath}`;
      window.open(url);
    },
    downLoadFile(row) {
      var url = dsf.url.getWebPath("file/download");
      $(
        `<form action="${url}" method="get">
          <input type="text" name="files" value="${row.id}"/>
          <input type="text" name="zipName" value="${row.fileName || ""}"/>
        </form>`
      )
        .appendTo("body")
        .submit()
        .remove();
    },
    computeFileSize(fileSize) {
      let filesizeUnit = "";
      if (parseInt(fileSize) > 1050000) {
        filesizeUnit = (fileSize / Math.pow(1024, 2)).toFixed(2) + "M";
      } else {
        filesizeUnit = (fileSize / Math.pow(1024, 1)).toFixed(2) + "K";
      }
      return filesizeUnit;
    },
  },
};
</script>
