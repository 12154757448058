var render = function (_h, _vm) {
  var _c = _vm._c
  return _c(
    _vm.parent.innerHeightAuto ? "div" : "DsfVirtualScroll",
    { tag: "component", staticClass: "dsf-convenient-menu-group" },
    _vm._l(_vm.props.group, function (row, index) {
      return _c(
        "div",
        { key: index, staticClass: "dsf-convenient-menu-row" },
        _vm._l(row, function (item, index2) {
          return _c(
            "div",
            {
              key: item._id,
              staticClass: "dsf-convenient-menu-col",
              style: _vm.parent.colStyle,
            },
            [
              !item._hide
                ? _c(
                    "div",
                    {
                      staticClass: "dsf-convenient-menu-box",
                      style: _vm.parent.boxStyle,
                      on: {
                        click: function ($event) {
                          return _vm.parent._clickBefore(
                            _vm.parent.onClick,
                            item
                          )
                        },
                      },
                    },
                    [
                      _vm._t("default", null, {
                        item: item,
                        rowIndex: index,
                        colIndex: index2,
                      }),
                    ],
                    2
                  )
                : _vm._e(),
            ]
          )
        }),
        0
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }