<!--
 * @Descripttion:复制移动导出选择组件
 * @Author: zhanghang
 * @Date: 2021-08-04 09:34:02
 * @LastEditors: zhanghang
 * @LastEditTime: 2022-01-17 14:23:28
-->
<template>
  <div class="catalogue-options">
    <div class="catalogue-options-center">
      <div class="catalogue-options-center-left">
        <el-scrollbar class="catalogue-options-scroll">
          <el-tree
            ref="tree"
            show-checkbox
            :props="defaultProps"
            :empty-text="loading ? '数据加载中...' : '暂无数据'"
            :highlight-current="true"
            :data="Treedata"
            node-key="_id"
            :default-checked-keys="checkedKeys"
            :default-expanded-keys="expandedKeys"
            :expand-on-click-node="false"
            @check="checkKeys"
            @current-change="currNodeChange"
          >
            <span class="custom-tree-node" slot-scope="{ node, data }">
              <i
                class="iconfont"
                :class="node.expanded ? 'icon-dakai' : 'icon-wenjianjia'"
                style="color: #c78511"
              ></i>
              &nbsp;
              <span v-html="dsf.safe.xss(data[defaultProps.label])"></span>
            </span>
          </el-tree>
        </el-scrollbar>
      </div>
      <div class="catalogue-options-center-right">
        <div class="catalogue-options-out-box">
          <el-table
            size="small"
            ref="multipleTable"
            @select="selectTable"
            @select-all="selectTable"
            class="catalogue-options-table"
            height="100%"
            :data="tableData"
            border
          >
            <el-table-column type="selection" width="40"></el-table-column>
            <el-table-column label="名称" width="180">
              <template slot-scope="scope">
                <span>{{ scope.row["_name"] }}</span>
              </template>
            </el-table-column>
            <el-table-column label="类型" width="120">
              <template slot-scope="scope">
                <span>{{ scope.row["typeText"] }}</span>
              </template>
            </el-table-column>
            <el-table-column label="标识简称">
              <template slot-scope="scope">
                <span>{{ scope.row["code"] }}</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <div class="catalogue-options-footer">
      <div class="catalogue-options-footer-left">
        <template v-if="operateType !== 'export'">
          {{operaType}}到目录下方&nbsp;&nbsp;
          <el-cascader
            size="small"
            ref="cascader"
            class="cascader"
            :options="data"
            @change="change"
            :props="{ ...defaultProps, checkStrictly: true }"
            clearable
          ></el-cascader>
        </template>
      </div>
      <div>
        <template v-if="operateType === 'copy' || operateType === 'move'">
          <DsfButton @click="nextStep">下一步</DsfButton>
        </template>
        <template v-else>
          <DsfButton @click="exportData">导出</DsfButton>
        </template>
        <DsfButton class="plain" @click="cancel">取消</DsfButton>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "selectList",
  /*operateType [操作类型[copy]复制 [move]移动 [export]导出 [import] 导入]
   *selectKeys 选中操作的目录
   */
  inject: ["operateType", "selectKeys"],
  data() {
    return {
      tableData: [],
      data: [],
      Treedata:[],
      defaultProps: {
        children: "children",
        label: "_name",
        key: "_id",
        value: "_id",
      },
      loading: false,
      //当前选中节点
      currentNode: "",
      //所有选中节点
      checkedKeys: [],
      // 默认展开
      expandedKeys: [],
    };
  },

  created() {
    this.initLoadData('left');
    if(this.operateType!=='export'){
      this.initLoadData('bottom');
    }
  },
  computed:{
    operaType(){
      let s = ''
      switch(this.operateType){
      case 'copy':
        s='复制'
        break;
      case 'move':
        s='移动'
        break;
      case 'import':
        s='导入'
        break;
      }
      return s
    }
  },
  mounted() {
    // 选中keys
    this.checkedKeys = this.$refs.tree.getCheckedKeys();
  },
  watch: {
    checkedKeys: {
      handler(v) {
        let key = this.defaultProps.key;
        this.tableData.forEach((item) => {
          this.$refs.multipleTable.toggleRowSelection(item,v.includes(item[key])
          );
        });
      },
      deep: true,
    },
  },
  methods: {
    change(){
      this.$refs.cascader.toggleDropDownVisible(false)
    },
    // 点击下一步
    nextStep() {
      // 选中的节点
      const selectNodes = this.$refs.tree.getCheckedNodes(false, true);
      //选中复制目录
      const selectCopyNodes = this.$refs.cascader.getCheckedNodes(true);
      if (selectNodes.length === 0) {
        dsf.layer.message('请选择内容',false);
        return false
      }
      if (selectCopyNodes.length === 0) {
        dsf.layer.message('请选择目录',false);
        return false
      }
      const rmIds = selectNodes.map(v=>v._id)
      const targetId = selectCopyNodes[0].data._id
      let loading = dsf.layer.loading();
      this.$http.post("rm/moveRmData", JSON.stringify({rmIds,targetId}), {
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
      })
        .done((res) => {
          if (res.success) {
            this.$emit("changeStep", {step:2,data:{
              importData:res.data,
              targetKeys:targetId
            }});
          } else {
            dsf.layer.message(res.message, false);
          }
        })
        .always(() => {
          dsf.layer.closeLoading(loading);
        });
    },
    //导出数据
    exportData() {
      const rmIds = this.$refs.tree.getCheckedNodes(false, true).map(v => v._id);
      if (rmIds.length === 0) {
        dsf.layer.message("请选择导出内容", false);
        return false;
      }
      let url = 'rm/exportRm';
      let fromHTML = `<form method="post">
                        <input type="text" name="rmIdStr"/>
                    </form>`;
      let formElement = $(fromHTML);
      formElement.find("input[type=text]").val(JSON.stringify(rmIds));
      formElement.attr("action", url);
      formElement.appendTo("body").submit().remove();
    },
    // 初始化目录 type='bottom'初始化下面目录  type='left'初始化左侧目录
    initLoadData(type) {
      let loading = dsf.layer.loading();

      let rmIds = this.selectKeys.length?this.selectKeys.join(','):'';
      let params = {}
      //下面目录
      if(type==='bottom'){
        params = {
          excludes:rmIds 
        }
      }else {
        params = {
          rmIds
        }
      }
      this.$http.get("rm/getRmTree", params)
        .done((res) => {
          if (res.success) {
            let key = this.defaultProps.key;
            let list = [];
            if (!Array.isArray(res.data)) {
              list = [res.data];
            } else {
              list = res.data;
            }
            if(type==='bottom'){
              this.data = list;
            }else {
              this.Treedata = list
              
              let _selectkeys = []
              let  dsf = (data)=>{
                data.forEach((v)=>{
                  _selectkeys.push(v[key])
                  v.children&&dsf(v.children)
                })
             
              }
              dsf(this.Treedata)
              this.$refs.tree.setCheckedKeys(_selectkeys);
              //默认展开第一级
              this.expandedKeys = list.map((v) => v[key]);
            } 
          } else {
            dsf.layer.message(res.message, false);
          }
        })
        .error((res) => {
          dsf.layer.message(
            res.message || res.error?.message || "查询目录失败",
            false
          );
        })
        .always(() => {
          this.loading = false;
          dsf.layer.closeLoading(loading);
        });
    },
    // 点击节点
    async currNodeChange(data, node) {
      let key = this.defaultProps.key;
      if (this.currentNode && this.currentNode[key] === data[key]) return false;
      this.currentNode = data;
      // 将其子节点设为选中
      this.$refs.tree.setChecked(data[key], true, true);

      // 右侧table开始查询
      await this.initTable();
      this.checkedKeys = this.$refs.tree.getCheckedKeys();
    },
    //复选框改变事件
    checkKeys(data, node) {
      let keys = this.$refs.tree.getCheckedKeys();
      this.checkedKeys = keys;
    },
    // 初始化右侧表格
    initTable() {
      // 广度优先遍历
      let stack = [...this.Treedata];

      while (stack.length) {
        // 出队列
        let head = stack.shift();
        // 推入队列
        head.children?.forEach((item) => {
          stack.push(item);
        });
        // 如果当前节点id等于选中id 清空队列
        if (head._id === this.currentNode._id) {
          this.tableData = head.children || [];
          //清空队列
          stack = [];
        }
      }
    },
    // 表格手动勾选
    selectTable(selection) {
      let key = this.defaultProps.key;
      //过滤出没选中的id
      let keys = this.tableData
        .filter((v) => !selection.some((d) => d[key] === v[key]))
        .map((v) => v[key]);
      //选中id
      let selectkeys = selection.map((v) => v[key]);
      keys.forEach((v) => this.$refs.tree.setChecked(v, false, true));
      selectkeys.forEach((v) => this.$refs.tree.setChecked(v, true, true));

      let _keys = this.$refs.tree.getCheckedKeys();
      this.checkedKeys = _keys;
    },
    cancel() {
      this.$emit("close");
    },
  },
};
</script>
