var render = function (_h, _vm) {
  var _c = _vm._c
  return _c(
    "div",
    {
      staticClass: "ds-nav-menu-item",
      class: { hide: _vm.props.item.hide },
      style: _vm.parent.itemStyle,
    },
    [
      !_vm.props.item.hide
        ? _c(
            "div",
            {
              staticClass: "ds-nav-menu-item-bt",
              style: _vm.parent.itemBtStyle,
              attrs: { title: _vm.props.item.name || "" },
              on: {
                click: function ($event) {
                  _vm.props.item.hide ||
                    _vm.parent._clickBefore(_vm.parent.onClick, _vm.props.item)
                },
              },
            },
            [
              _c(
                "el-image",
                {
                  style: [
                    _vm.parent.itemBgStyle,
                    _vm.parent.getItemStyle(_vm.props.item),
                  ],
                  attrs: {
                    src: _vm._f("imgFormat")(
                      _vm.parent.getImgUrl(_vm.props.item)
                    ),
                  },
                },
                [
                  _c("template", { slot: "error" }, [
                    _c("i", {
                      staticClass: "iconfont",
                      class: [_vm.parent.getIcon(_vm.props.item.icon)],
                      style: [
                        _vm.parent.itemIconStyle,
                        _vm.parent.getIconStyle(_vm.props.item),
                      ],
                    }),
                  ]),
                ],
                2
              ),
              _c(
                "div",
                {
                  staticClass: "ds-nav-menu-item-name",
                  style: _vm.parent.itemTextStyle || {},
                },
                [
                  _c("div", { staticClass: "ellipsis" }, [
                    _vm._v(_vm._s(_vm.props.item.name || "")),
                  ]),
                ]
              ),
              _vm._t("default"),
            ],
            2
          )
        : _c("div", {
            staticClass: "ds-nav-menu-item-bt",
            style: _vm.parent.itemBtStyle,
          }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }