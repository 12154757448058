var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "ds-control ds-design-img",
      style: { "text-align": _vm.align },
      on: { click: _vm.clickHandler },
    },
    [
      _vm.url
        ? _c(
            "el-image",
            {
              style: {
                width: _vm.width,
                height: _vm.height,
                display: "inline-block",
              },
              attrs: { src: _vm.url },
            },
            [
              _c(
                "div",
                {
                  staticClass: "image-slot",
                  staticStyle: { "text-align": "center", "font-size": "30px" },
                  attrs: { slot: "error" },
                  slot: "error",
                },
                [_c("i", { staticClass: "el-icon-picture-outline" })]
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }