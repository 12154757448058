var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.isDesign || _vm.visible,
          expression: "isDesign || visible",
        },
      ],
      staticClass: "ds-control",
      style: _vm.controlStyle,
    },
    [
      _c(
        "div",
        {
          staticClass: "ds-home-user-center",
          style: _vm.panelStyle,
          attrs: { "is-design": _vm.isDesign },
        },
        [
          _vm.info
            ? _c("el-image", {
                staticClass: "ds-home-user-center-head-box",
                style: _vm.headStyle,
                attrs: {
                  src: _vm._f("imgFormat")(
                    _vm.info.photo ||
                      _vm.$replace(
                        _vm.dsf.config.setting_public_user_default_header
                      )
                  ),
                  fit: "cover",
                },
              })
            : _vm._e(),
          _vm.info
            ? _c(
                "div",
                { staticClass: "ds-home-user-center-info" },
                [
                  _c("div", { staticClass: "ds-home-user-center-name" }, [
                    _vm._v(_vm._s(_vm.info.name || "")),
                  ]),
                  _vm._l(_vm.info.exts || [], function (row, index) {
                    return [
                      _c(
                        "div",
                        { key: index, staticClass: "ds-home-user-center-row" },
                        [
                          _c("DsfIcon", {
                            staticClass: "ds-home-user-center-row-icon",
                            attrs: { name: _vm.extsIconsList[index] },
                          }),
                          _c(
                            "div",
                            { staticClass: "ds-home-user-center-row-label" },
                            [_vm._v(" " + _vm._s(row.text || "") + "： ")]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "ds-home-user-center-row-value ellipsis",
                              attrs: { title: row.value || "" },
                            },
                            [_vm._v(" " + _vm._s(row.value || "") + " ")]
                          ),
                        ],
                        1
                      ),
                    ]
                  }),
                ],
                2
              )
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }