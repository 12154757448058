var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "flow-file-list ds_uploadFile ds-upload" }, [
    _c(
      "table",
      {
        staticClass: "layui-table fileContainer upload-table",
        attrs: { width: "100%" },
      },
      [
        _c(
          "tbody",
          _vm._l(_vm.Attachments, function (file, i) {
            return _c("tr", { key: i, staticClass: "item" }, [
              _c("td", { staticClass: "textLeft" }, [
                _c("div", { staticClass: "fileName" }, [
                  _c("i", { staticClass: "icon", class: file.suffix }),
                  _c("span", [_vm._v(_vm._s(file.originalFileName))]),
                ]),
              ]),
              _c(
                "td",
                { staticClass: "blank fontpostion", attrs: { width: "100" } },
                [_vm._v(" " + _vm._s(_vm.computeFileSize(file.size)) + " ")]
              ),
              _c(
                "td",
                { staticClass: "operate fontpostion", attrs: { width: "100" } },
                [
                  _c(
                    "span",
                    {
                      staticClass: "previewBtn iconfont",
                      attrs: { title: "预览" },
                      on: {
                        click: function ($event) {
                          return _vm.preview(file)
                        },
                      },
                    },
                    [_vm._v("")]
                  ),
                  _c("span", { staticClass: "downLoad iconfont downLoadBtn" }, [
                    _c(
                      "i",
                      {
                        staticClass: "iconfont",
                        attrs: { title: "下载" },
                        on: {
                          click: function ($event) {
                            return _vm.downLoadFile(file)
                          },
                        },
                      },
                      [_vm._v("")]
                    ),
                  ]),
                ]
              ),
            ])
          }),
          0
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }