<template>
  <div class="ds-control ds-form-item ds-system" :class="getCss">
    <template v-if="simple && !isDesign">
      {{ getValueString | proxy(formatter) }}
    </template>
    <template v-else>
      <label v-if="showLabel" class="ds-form-label" :style="getLabelWidthStyle()">{{ label }}</label>
      <div class="ds-form-block" :style="getFormItemBlockMarginLeft()">
        <template v-if="isDesign">
          [创建时间]
        </template>
        <template v-else>
          {{ getValueString | proxy(formatter) }}
        </template>
      </div>
    </template>
  </div>
</template>

<script>
export default dsf.component({
  name: "DsfCreateTime",
  mixins: [$mixins.formControl],
  ctrlCaption: "创建时间",
  props: {
    caption: {
      type: String,
      default: dsf.config.kw.createtime,
    },
    label: {
      type: String,
      default: "创建时间",
    },
    simple: {
      type: Boolean,
      default: false,
    },
    formatter: {
      type: String,
      default: "dateTime^yyyy-mm-dd hh:ii",
    },
    showLabel: {
      type: Boolean,
      default: true,
    },
    visible: {
      type: Boolean,
      default: true,
    },
    allowPost:{
      type:Boolean,
      default:false
    },
    metadata: {
      type: Object,
      default() {
        return dsf.metadata.get("system-meta-data", {
          code: dsf.config.kw.createtime,
          valueAttributes: [
            {
              name: "创建时间",
              code: dsf.config.kw.createtime,
              type: dsf.metadata.getDataType("date"),
              length: "",
              defaultValue: null,
              unit: null,
            },
          ],
        });
      },
    },
  },
  computed: {
    getValueString: {
      get() {
        return this.value;
      },
      set(v) {
        this.emitValueChange(v);
      },
    },
  },
});
</script>
