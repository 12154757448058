import { render, staticRenderFns } from "./listStatus.vue?vue&type=template&id=7a0ae681&"
import script from "./listStatus.vue?vue&type=script&lang=js&"
export * from "./listStatus.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

component.options.__file = "pc/bhc/list/listStatus.vue"
export default component.exports