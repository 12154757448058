<template>
  <div class="ds-control ds-form-item ds-textbox" :class="getCss">
    <template v-if="simple && readOnly && !isDesign">
      {{ value }}
      <slot name="error">
        <div v-if="errors.length > 0" class="ds-error-text">
          {{ errorsMsg }}
        </div>
      </slot>
    </template>
    <template v-else>
      <DsfFieldLabel
        v-if="showLabel"
        :mode="showDataCaptionMode"
        :style="getLabelWidthStyle()"
        :show-icon="showDataCaptionIcon" 
        :trigger="showDataCaptionTrigger"
        :data-caption="dataCapion"
        :is-design="isDesign"
        :owner="_self">
        {{ $t(label) }}
      </DsfFieldLabel>
      <!-- <label v-if="showLabel" class="ds-form-label" :style="getLabelWidthStyle()">
        {{ label }}
        <i v-if="dataCapion" class="iconfont icon-bangzhuzhongxin ds-control-helper" @click="showHelper"></i>
      </label> -->
      <div class="ds-form-block" :style="getFormItemBlockMarginLeft()">
        <el-autocomplete
          ref="auto"
          v-if="!readOnly"
          v-model="inputValue"
          :fetch-suggestions="querySearchAsync"
          :placeholder="placeholder"
          :disabled="disabled"
          :trigger-on-focus="triggerOnFocus"
          :debounce="delay"
          :class="{ 'ds-error-border': errors.length > 0 }"
          @select="handleSelect"
          @blur="handleBlur"
          @change="handleChange"
          @input="handleInput">
          <template slot-scope="{ item }">
            <slot v-bind="{ item: item }">
              {{ item[itemShowKey]||item[valueKey] }}
            </slot>
          </template>
        </el-autocomplete>

        <!--只读状态只显示div-->
        <div v-else-if="readOnly" class="ds-form-readonly">
          <span>{{ value }}</span>
        </div>
        <slot name="error">
          <div v-if="errors.length > 0" class="ds-error-text">
            {{ errorsMsg }}
          </div>
        </slot>
      </div>
    </template>
  </div>
</template>
<script>
export default dsf.component({
  name: "DsfAutoComplete",
  mixins: [$mixins.formControl],
  ctrlType: "autocomplete",
  ctrlCaption: "自动填充",
  props: {
    openInputGrade: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: "请输入"
    },
    metadata: {
      type: Object,
      default() {
        return dsf.metadata.get("text-meta-data");
      }
    },
    metaDataType: {
      type: String,
      default: "0"
    },
    delay: {
      type: Number,
      default: 300
    },
    chooseMetaData: {
      type: Object,
      default() {
        return {};
      }
    },
    source: {
      type: String,
      default: ""
    },
    maxItemsNumber: {
      type: Number,
      default: 20
    },
    autoSwitch: {
      type: Boolean,
      default: true
    },
    onlyInsertByItems: {
      type: Boolean,
      default: false
    },
    popperWid: {
      type: String,
      default: "auto"
    },
    triggerOnFocus: {
      type: Boolean,
      default: false
    },
    //选中的值字段
    valueKey: {
      type: String,
      default: "label"
    },
    //列表枚举显示字段
    itemShowKey: {
      type: String,
      default: "label"
    },
    items: []
  },
  data() {
    return {
      oldValue: null, //之前已选择的select下拉项的value
      valueChangeBySelect: false, //value的改变是否由点击下拉项引起
      gridRowChangeKey: "select-complete"
    };
  },
  computed: {
    inputValue: {
      get() {
        return this.value;
      },
      set(v) {
        this.emitValueChange(v);
      }
    }
  },
  created() {
    this.oldValue = this.value;
  },
  methods: {
    querySearchAsync(v, cb) {
      const self = this;
      let obj =this.$expressParams(this.local);
      const url = this.$replace(self.source, obj);
      self.$http
        .get(url, { q: v })
        .done((res) => {
          let result = [];
          if (!res.success) {
            dsf.layer.message(res.message || "获取数据出现错误", false);
            return;
          }
          _.forEach(res.data.slice(0, self.maxItemsNumber + 1), (item) => {
            item[self.valueKey] = item[self.valueKey] || item.label || item.value;
            if (item[self.valueKey]) {
              result.push(item);
            }
          });
          this.items = result;
          cb(result);
        })
        .error((err) => {
          dsf.layer.message("查询输入建议报错", false);
        });
      // cb([
      //   { label: "link_add", value: "link_add" },
      //   { label: "1", value: "1" },
      //   { label: "2", value: "2" },
      //   { label: "3", value: "3" },
      //   { label: "4", value: "4" },
      //   { label: "5", value: "5" }
      // ]);
    },
    handleBlur() {
      //若用户设置了只能从下拉项里选择数据
      if (this.onlyInsertByItems) {
        setTimeout(() => {
          if (!this.valueChangeBySelect) {
            // let result = _.filter(this.items, (it) => {
            //   return this.inputValue == it[this.valueKey];
            // });
            this.inputValue = this.oldValue;
          }
        }, 100);
      }
      this.$dispatch("blur");
      this.$nextTick(() => {
        this.emitFormValidate(this.value);
      });
    },
    handleSelect(v) {
      this.inputValue = v[this.valueKey];
      this.oldValue = v[this.valueKey];
      this.valueChangeBySelect = true;
      //如果组件的select事件被指定则有二次开发自己完成赋值动作
      if (this.$listeners["select"]) {
        this.$dispatch("select", v);
        // return;
      } else {
        //填充数据到其它控件
        if (this.$vm && this.autoSwitch) {
          let dict = this.$vm.getScopeMetaDataDict(this);
          if (!this.local) {
            return;
          }
          _.forOwn(dict, (dictVal, dictKey) => {
            let arr = dictKey.split(".");
            let smallKey = arr[1] ? arr[1] : dictKey;
            if (dictVal.fieldsLength === 1) {
              let val = dsf.isDef(v[dictKey]) ? v[dictKey] : v[smallKey];
              if (dsf.isDef(val)) {
                this.$vm.$set(this.local, dictKey, val);
              }
            } else if (dictVal.fieldsLength > 1) {
              let val = dsf.isDef(v[dictKey]) ? v[dictKey] : v[smallKey];
              if (dsf.isDef(val) && (dsf.isObject(val) || dsf.isArray(val))) {
                this.$vm.$set(this.local, dictKey, val);
              } else {
                let isarr = false;
                let dataVal = null;
                //处理返回结果种可能是 xxxx_text或者xxxx_value的情况
                _.each(dictVal.valueAttributes, (el, i) => {
                  let val = dsf.isDef(v[dictKey + "_" + el]) ? v[dictKey + "_" + el] : v[smallKey + "_" + el];
                  if (dsf.isDef(val)) {
                    if (val.indexOf("^") >= 0) {
                      if (!dataVal) {
                        dataVal = [];
                      }
                      let sp = val.split("^");
                      _.each(sp, (s, i) => {
                        if (!dataVal[i]) {
                          dataVal[i] = {};
                        }
                        dataVal[i][el] = s;
                      });
                    } else {
                      if (!dataVal) {
                        dataVal = {};
                      }
                      dataVal[el] = val;
                    }
                  }
                });
                if (dsf.isDef(dataVal)) {
                  this.$vm.$set(this.local, dictKey, dataVal);
                }
              }
            }
          });
        }
      }

      //验证v.value
      this.$nextTick(() => {
        this.emitFormValidate(v[this.valueKey]);
        this.$nextTick(() => {
          this.$dispatch("select-complete", v);
        });
      });
    },
    handleChange(v) {
      this.inputValue = v;
      this.valueChangeBySelect = false;
    },
    handleInput() {
      if (this.popperWid && this.popperWid !== "auto") {
        this.$refs.auto.$watch("suggestionVisible", () => {
          this.$refs.auto.$children[1].dropdownWidth = this.popperWid;
        });
      }
    }
  }
});
</script>
