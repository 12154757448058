<!--
 * @Descripttion: 
 * @Author: zhanghang
 * @Date: 2021-09-14 10:58:10
 * @LastEditors: zhanghang
 * @LastEditTime: 2021-09-15 18:05:29
-->
<template>
  <div class="ds-control ds-home-banner">
    <div class="arrow-left" v-if="showList.length>1">
      <i @click="prev" class="iconfont icon-fanyezuobeifen8 arrow" />
    </div>
    <el-carousel
      ref="carousel"
      :height="h"
      class="carousel"
      :loop="true"
      arrow="never"
      indicator-position="none"
      :autoplay="false"
    >
      <el-carousel-item v-for="(item,i) in showList" :key="i">
        <div class="carousel-list">
          <div v-for="(_item) in item" :key="_item.text">
            <p>
              <i :class="_item.icon" class="iconfont" style="font-size: 30px" />
            </p>
            <p>
              <span>{{_item.text}}</span>
              <span>--</span>
            </p>
          </div>
        </div>
      </el-carousel-item>
    </el-carousel>
    <div class="arrow-right" v-if="showList.length>1">
      <i @click="next" class="iconfont icon-fanyezuobeifen5 arrow" />
    </div>
  </div>
</template>
<script>
export default dsf.component({
  name: "DsfIndicatorPanel",
  ctrlCaption: "指标面板",
  props: {
    maxPanel: {
      type: [Number,String],
      default: 4,
    },
    list: {
      type: Array,
      default() {
        return [
          {
            icon: "fenzuguanli",
            text: "我要查",
          },
          {
            icon: "fenzuguanli",
            text: "我要办",
          },
          {
            icon: "fenzuguanli",
            text: "我要看",
          },
        ];
      },
    },
  },
  computed: {
    showList() {
      let list = this.list.map(v=>{
        v.icon = v.icon.startsWith('icon-')?v.icon:`icon-${v.icon}` 
        return v
      });
      let result = [];
      let maxPanel = this.maxPanel;
      for (var i = 0; i < list.length; i += maxPanel) {
        result.push(list.slice(i, i + maxPanel));
      }
      return result;
    },
  },
  mixins: [$mixins.control],
  data() {
    return {
      h: "100%",
    };
  },
  mounted() {
    if (this.isDesign) {
      this.h = "100px";
    }
  },
  methods: {
    prev() {
      this.$refs.carousel.prev();
    },
    next() {
      this.$refs.carousel.next();
    },
  },
});
</script>
