var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "ds-tree-select",
      class: [_vm.theme],
      style: { "padding-top": _vm.searchControl ? "50px" : "0" },
    },
    [
      _vm.searchControl
        ? _c(
            "div",
            { staticClass: "ds-tree-select-search-box" },
            [
              _c(
                "el-input",
                {
                  attrs: { placeholder: "输入关键词（多个关键词空格隔开）" },
                  model: {
                    value: _vm.keyword,
                    callback: function ($$v) {
                      _vm.keyword = $$v
                    },
                    expression: "keyword",
                  },
                },
                [
                  _c("i", {
                    staticClass: "iconfont icon-Magnifier",
                    attrs: { slot: "suffix" },
                    slot: "suffix",
                  }),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "ds-tree-select-tree-box" },
        [
          _c(
            "dsf-virtual-scroll",
            { attrs: { "scroll-x": "" } },
            [
              _vm.multiple
                ? _c("el-tree", {
                    ref: "tree",
                    attrs: {
                      "default-expand-all": "",
                      data: _vm.treeData,
                      "node-key": _vm.nodeKey,
                      "empty-text": _vm.loading
                        ? "数据加载中..."
                        : _vm.emptyText,
                      "show-checkbox": _vm.multiple,
                      "default-checked-keys": _vm.defaultCheckedKeys,
                      "filter-node-method": _vm.filterNode,
                    },
                    on: { check: _vm.check, "node-click": _vm.nodeClick },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (ref) {
                            var node = ref.node
                            var data = ref.data
                            return _c(
                              "div",
                              {
                                staticClass: "custom-tree-node",
                                attrs: {
                                  "tree-id": ["key_" + data[_vm.nodeKey]],
                                },
                              },
                              [
                                data.children !== undefined
                                  ? [
                                      _c("i", {
                                        staticClass: "iconfont",
                                        class: [
                                          node.expanded
                                            ? "icon-dakai"
                                            : "icon-wenjianjia",
                                        ],
                                      }),
                                      _c("span", [
                                        _vm._v(_vm._s(data[_vm.nodeName])),
                                      ]),
                                    ]
                                  : [
                                      _c("i", {
                                        staticClass:
                                          "iconfont icon-shiyongwendang",
                                      }),
                                      _c("span", [
                                        _vm._v(_vm._s(data[_vm.nodeName])),
                                      ]),
                                    ],
                              ],
                              2
                            )
                          },
                        },
                      ],
                      null,
                      false,
                      2856812968
                    ),
                  })
                : _c("el-tree", {
                    ref: "tree",
                    attrs: {
                      "default-expand-all": "",
                      data: _vm.treeData,
                      "node-key": _vm.nodeKey,
                      "empty-text": _vm.loading
                        ? "数据加载中..."
                        : _vm.emptyText,
                      "show-checkbox": _vm.multiple,
                      "default-checked-keys": _vm.defaultCheckedKeys,
                      "filter-node-method": _vm.filterNode,
                    },
                    on: { check: _vm.check, "node-click": _vm.nodeClick },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var node = ref.node
                          var data = ref.data
                          return _c(
                            "div",
                            {
                              staticClass: "custom-tree-node",
                              attrs: {
                                "tree-id": ["key_" + data[_vm.nodeKey]],
                              },
                            },
                            [
                              _vm.getNoCheck(data)
                                ? [
                                    _c("i", {
                                      staticClass: "iconfont",
                                      class: [
                                        node.expanded
                                          ? "icon-dakai"
                                          : "icon-wenjianjia",
                                      ],
                                    }),
                                    _c("span", [
                                      _vm._v(_vm._s(data[_vm.nodeName])),
                                    ]),
                                  ]
                                : _c(
                                    "el-radio",
                                    {
                                      attrs: {
                                        label: data[_vm.nodeKey],
                                        disabled: data.nocheck,
                                      },
                                      model: {
                                        value: _vm.radioId,
                                        callback: function ($$v) {
                                          _vm.radioId = $$v
                                        },
                                        expression: "radioId",
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass:
                                          "iconfont icon-shiyongwendang",
                                      }),
                                      _c("span", [
                                        _vm._v(_vm._s(data[_vm.nodeName])),
                                      ]),
                                    ]
                                  ),
                            ],
                            2
                          )
                        },
                      },
                    ]),
                  }),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }