<template>
  <div class="ds-control ds-form-item ds-select" :class="getCss">
    <template v-if="simple && readOnly && !isDesign">
      <DsfTextProxy v-model="value" v-bind="_self.$props"></DsfTextProxy>
      <slot name="error">
        <div v-if="errors.length > 0" class="ds-error-text">
          {{ errorsMsg }}
        </div>
      </slot>
    </template>
    <template v-else>
      <!-- <label v-if="showLabel" class="ds-form-label" :style="getLabelWidthStyle()">
        {{ label }}
        <i v-if="dataCapion" class="iconfont icon-bangzhuzhongxin ds-control-helper" @click="showHelper"></i>
      </label> -->
      <DsfFieldLabel
        v-if="showLabel"
        :mode="showDataCaptionMode"
        :style="getLabelWidthStyle()"
        :show-icon="showDataCaptionIcon" 
        :trigger="showDataCaptionTrigger"
        :data-caption="dataCapion"
        :is-design="isDesign"
        :owner="_self">
        {{ $t(label) }}
      </DsfFieldLabel>
      <div class="ds-form-block" :style="getFormItemBlockMarginLeft()">
        <el-select
          ref="select"
          v-if="!readOnly"
          v-model="selectValue"
          :disabled="disabled"
          :placeholder="placeholder"
          :collapse-tags="collapseTags"
          :multiple-limit="multipleLimit"
          :multiple="multiple"
          :filterable="filterable || allowCreate"
          :filter-method="filter"
          :allow-create="allowCreate"
          :class="{ 'ds-error-border': errors.length > 0 }"
          :loading="loading"
          @visible-change="dropDownShow"
          @change="selectChange"
          @focus="isFocus = true">
          <el-option label="" value="" :show="!searchKey">
            <span style="color: #c0c0c0">请选择</span>
          </el-option>
          <template v-if="isDesign">
            <el-option label="数据1" value="1"></el-option>
            <el-option label="数据2" value="2"></el-option>
            <el-option label="数据3" value="3"></el-option>
            <el-option label="数据4" value="4"></el-option>
            <el-option label="数据5" value="5"></el-option>
          </template>
          <template v-else-if="isFocus">
            <select-item v-for="option in realItems" :show="optionVisable(option)" :key="option[valueField]" :item="option" />
          </template>
          <!-- 此处是为了防止 当isFocus为false时，选项没有加载而value有数据时能正常显示 start-->
          <template v-else-if="multiple && value">
            <el-option v-for="option in value" :show="optionVisable(option)" :key="option.value" :label="_formatSelectedText(option)">
              {{_formatOptionText(option)}}
            </el-option>
          </template>
          <template v-else-if="!multiple && value && value.value && value.text">
            <el-option :label="_formatSelectedText(option)" :value="value.value">
              {{_formatOptionText(option)}}
            </el-option>
          </template>
          <!-- 此处是为了防止 当isFocus为false时，选项没有加载而value有数据时能正常显示 end-->
        </el-select>
        <!--只读状态只显示div-->
        <div v-else-if="readOnly" class="ds-form-readonly">
          <DsfTextProxy v-model="value" v-bind="_self.$props" :show-label="false"></DsfTextProxy>
        </div>
        <slot name="error">
          <div v-if="errors.length > 0" class="ds-error-text">
            {{ errorsMsg }}
          </div>
        </slot>
      </div>
    </template>
  </div>
</template>

<script>
import selectItem from "./items/selectItem.js";

export default dsf.component({
  name: "DsfSelect",
  mixins: [$mixins.formControl, $mixins.itemsMixins],
  components: {
    selectItem
  },
  ctrlCaption: "下拉框",
  props: {
    simple: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: "请选择"
    },
    filterable: {
      type: Boolean,
      default: false,
      desc: "是否可搜索"
    },
    filterByValue: {
      type: Boolean,
      default: false,
      desc: "是否支持value搜索"
    },
    multiple: {
      type: Boolean,
      default: false,
      desc: "是否多选"
    },
    allowCreate: {
      type: Boolean,
      default: false,
      desc: "是否可创建条目"
    },
    maxChooseNum: {
      type: Number,
      default: 0,
      desc: "多选时，选择上限，0为不限制"
    },
    collapseTags: {
      type: Boolean,
      default: false,
      desc: "多选时，选中值是否以合并展示"
    },
    optionRenderTime: {
      type: Number,
      default: 0,
      desc: "选项渲染时间"
    },
    loading: {
      type: Boolean,
      default: false
    },
    formatOptionText: {
      type: Function,
      required: false
    }
  },
  data() {
    return {
      isFocus: false,
      searchKey: "",
      filterResult: [],
      showOptions: {}
    };
  },
  computed: {
    multipleLimit() {
      return this.maxChooseNum > 0 ? this.maxChooseNum : 0;
    }
  },
  watch: {
    multiple(to, from) {
      if (!to && from) {
        this.selectValue = this.valueCopy.length ? this.valueCopy[0] : [];
      }
    }
  },
  created() {
    if (this.optionRenderTime === 0) {
      this.isFocus = true;
    }
  },
  methods: {
    dropDownShow(evt) {
      if (evt) {
        this.searchKey = null;
        this.filterOptions();
      }
      this.$dispatch("visible-change", evt);
    },
    _formatOptionText(item) {
      if (this.formatOptionText) {
        return this.formatOptionText(item);
      }
      return item[this.optionsTextField];
    },
    _formatSelectedText(item) {
      return item[this.selectedTextField];
    },
    filter(key) {
      this.searchKey = key;
      this.filterOptions();
    },
    filterOptions() {
      let searchKey = this.searchKey || "";
      this.showOptions = {};
      let filterResult = [];
      if (searchKey) {
        _.each(this.realItems, (item) => {
          // let c = item.text?.indexOf(searchKey) > -1 || item.py?.indexOf(searchKey) === 0 || item.pinyin?.indexOf(searchKey) === 0;
          let c = false;
          if (this.filterByValue) {
            c = item.text?.indexOf(searchKey) > -1 || item.py?.indexOf(searchKey) === 0 || item.pinyin?.indexOf(searchKey) === 0 || item.value?.indexOf(searchKey) === 0;
          }else {
            c = item.text?.indexOf(searchKey) > -1 || item.py?.indexOf(searchKey) === 0 || item.pinyin?.indexOf(searchKey) === 0
          }
          this.$set(this.showOptions, item.value, c);
          if (c) {
            filterResult.push(c);
          }
        });
      } else {
        filterResult = this.realItems.length;
      }
      //此处必须加入这段代码，否则如果检索无法匹配目标时不显示“未找到匹配项的文本”
      this.$refs.select.filteredOptionsCount = filterResult.length;
    },
    optionVisable(item) {
      return this.showOptions[item.value] === false ? false : true;
    }
  }
});
</script>
