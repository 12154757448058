export default (dsf) => {
  let addAPI = dsf.api.addAPI;
  //数据源接口
  addAPI("dbSourceInterface", function (params, ns, options, baseURL) {
    let path = ns;
    if (path.startsWith("/")) {
      path = path.substr(1);
    }
    let url = "/dbsource/interface/" + path;
    return this.$http.get(url, params, options, baseURL);
  });

  addAPI("getInitData", function (params, options, baseURL) {
    let url = "/meta/init";
    return this.$http.get(url, params, options, baseURL)
  });

  //获取表单数据
  addAPI("getFormData", function (params, options, baseURL) {
    let url = "/meta/data"
    return this.$http.get(url, params, options, baseURL);
  });

  //批量查询数据字典
  //params:{'dicts':[{key:"任意的唯一标识",'dictId':数据字典ID},...]}
  addAPI("getBatchDict", function (params, options, baseURL) {
    params.dicts = JSON.stringify(params.dicts);
    return this.$http.get("/dict/data", params, options, baseURL);
  });

  //保存布局
  addAPI("pageBuild", function (params, options, baseURL) {
    return this.$http.post("/meta/build", params, options, baseURL);
  });

  //保存模板
  addAPI("pageSaveTemplate", function (params, options, baseURL) {
    // return this.$http.post("/meta/saveTemp", params, options, baseURL);
    return this.$http.post("/template/saveTemp", params, options, baseURL);
  });

  //表单数据保存
  addAPI("formSave", function (params, options, baseURL) {
    let qs = dsf.url.queryStringStringify(this.queryString);
    let url = `/meta/persistData${qs ? "?" + qs : ""}`;
    return this.$http.post(url, params, options, baseURL);
  });

  //获取设计器按钮栏数据
  addAPI("getDesignerButtons", function (params, options, baseURL) {
    return this.$http.get("/meta/temp/button", params, options, baseURL).done((data) => {
      return data;
    });
  });
  //查询设计器按钮以及业务类型权限
  addAPI("selectButtonsAndBusiness", function (params, options, baseURL) {
    return this.$http.get("/template/initPageTemplatePermission", params, options, baseURL).done((data) => {
      return data;
    });
  });
  //获取可用组件组
  addAPI("getComponents", function (params, options, baseURL) {
    // let ids = params.join(",");
    let strIds = params.strIds.join(",");
    let terminal = params.terminal;
    if (strIds.length > 0) {
      return this.$http.get("component/findComponents", { strIds: strIds, 'terminal': terminal, type: 0 }, options, baseURL).then(({ data }) => {
        return data;
      });
    }
    else {
      return new Promise((resolve, reject) => {
        resolve({ data: [] });
      })
    }
  });
  //获取指定组中的业务组件集合
  addAPI("getBusinessComponets", function (params, options, baseURL) {
    let strIds = params.strIds.join(",");
    let terminal = params.terminal;
    // return this.$http.get("/meta/bc/list", { ids: ids }, options, baseURL).then(({ data }) => {
    //   return data;
    // });
    if (strIds.length > 0) {
      return this.$http.get("component/findComponents", { strIds: strIds, 'terminal': terminal, type: 1 }, options, baseURL).then(({ data }) => {
        return data;
      });
    }
    else {
      return new Promise((resolve, reject) => {
        resolve({ data: [] });
      })
    }
  });
  //获取列表
  addAPI("getMetaListData", function (params, options, baseURL) {
    let realParams = {
      pageNum: 1,
      pageSize: 10,
      query: JSON.stringify({
        searchValue: "", //关键字
      }),
      order: JSON.stringify([{
        code: "", //排序字段 gkcs ：点击量 evaluate ：评价
        sort: ""  //排序方式 asc或desc
      }]),
      namespace: "",
      pageName: "",
    }
    Object.assign(realParams, params)
    return this.$http.get("/meta/list/data", realParams, options, baseURL).then(({ data }) => {
      return data;
    });
  });
  //获取元数据树结构
  addAPI("getMetaDataFieldsTree", function (params, hasValueAttributes, options, baseURL) {
    let tree = [];
    function fn(data) {
      if (data.metadata) {
        let list = _.map(data.metadata, (m, i) => {
          let node = dsf.mix(true, {}, m);
          let vas = node.valueAttributes;
          if (hasValueAttributes !== false) {
            if (vas.length > 1) {
              node.children = vas;
              _.each(vas, (v) => {
                v.fieldCaption = node.name + "(" + v.name + ")";
                v.field = node.code + "_" + v.code; //m.at.replace(/\./g, "_") + "." + m.code + "_" + v.code;
                v.id = node.at.replace(/\./g, "_") + "." + node.code + "_" + v.code;
                v.metadata = node;
              });
            } else {
              node.id = node.at.replace(/\./g, "_") + "." + node.code
              node.fieldCaption = node.name;
              node.field = node.code; // m.at.replace(/\./g, "_") + "." + m.code;
              node.metadata = node;
            }
          } else {
            node.id = node.id.replace(/\./g, "_")
            node.fieldCaption = node.name;
            node.field = node.code;
            node.metadata = node;
          }
          return node;
        });
        list = list.sort((v1, v2) => {
          return v1.order < v2.order ? -1 : 1;
        });
        data.children = list;
        delete data.metadata;
      }
    }
    return this.$http.get("/rm/list/meta", params, options, baseURL).then(({ data }) => {
      let metadatas = data.data;
      for (let i = 0; i < metadatas.length; i++) {
        let data = dsf.mix({}, metadatas[i]);
        fn(data);
        tree.push(data);
      }
      if (params.namespace) {
        tree = _.filter(tree, (v) => {
          return v.ID == params.namespace;
        });
      }
      return tree;
    });
  });
  //获取元数据下拉结构
  addAPI("getMetaDataFieldsSelect", function (params, hasValueAttributes, options, baseURL) {
    let tree = [];
    function fn(data) {
      if (data.metadata) {
        let list = [];
        _.each(data.metadata, (m, i) => {
          let node = dsf.mix(true, {}, m);
          let vas = node.valueAttributes;
          if (hasValueAttributes !== false) {
            if (vas.length > 1) {
              _.each(vas, (v) => {
                v.fieldCaption = node.name + "(" + v.name + ")";
                v.field = node.code + "_" + v.code; //m.at.replace(/\./g, "_") + "." + m.code + "_" + v.code;
                v.id = node.at.replace(/\./g, "_") + "." + node.code + "_" + v.code;
                v.metadata = node;
                v.order = node.order;
              });
              list.push(...vas);
            } else {
              node.id = node.at.replace(/\./g, "_") + "." + node.code
              node.fieldCaption = node.name;
              node.field = node.code; // m.at.replace(/\./g, "_") + "." + m.code;
              node.metadata = node;
              list.push(node);
            }
          } else {
            node.id = node.id.replace(/\./g, "_")
            node.fieldCaption = node.name;
            node.field = node.code;
            node.metadata = node;
            list.push(node);
          }
        });
        list = list.sort((v1, v2) => {
          return v1.order < v2.order ? -1 : 1;
        });
        return list;
      }
      return []
    }
    return this.$http.get("/rm/list/meta", params).then(({ data }) => {
      const metadatas = data.data;
      for (let i = 0; i < metadatas.length; i++) {
        const data = dsf.mix({}, metadatas[i]);
        const list = fn(data);
        if (!params.namespace || params.namespace && metadatas[i].ID === params.namespace) {
          tree.push(...list);
        }
      }
      return tree;
    });
  });
  //获取数据源树结构
  addAPI("getDbSourceFieldsTree", function (params, hasValueAttributes, options, baseURL) {
    let tree = [];

    function fn(data) {
      if (data.metadata) {
        let list = _.map(data.metadata, (m, i) => {
          let node = dsf.mix(true, {}, m);
          let vas = node.valueAttributes;
          if (hasValueAttributes !== false) {
            if (vas.length > 1) {
              node.children = vas;
              _.each(vas, (v) => {
                v.fieldCaption = node.name + "(" + v.name + ")";
                v.field = node.code + "_" + v.code; //m.at.replace(/\./g, "_") + "." + m.code + "_" + v.code;
                v.id = node.at.replace(/\./g, "_") + "." + node.code + "_" + v.code;
                v.metadata = node;
              });
            } else {
              node.id = node.at.replace(/\./g, "_") + "." + node.code;
              node.fieldCaption = node.name;
              node.field = node.code; // m.at.replace(/\./g, "_") + "." + m.code;
              node.metadata = node;
            }
          } else {
            node.id = node.id.replace(/\./g, "_");
            node.fieldCaption = node.name;
            node.field = node.code;
            node.metadata = node;
          }
          return node;
        });
        list = list.sort((v1, v2) => {
          return v1.order < v2.order ? -1 : 1;
        });
        data.children = list;
        delete data.metadata;
      }
    }
    return this.$http.get("/dbsource/rmId", params, options, baseURL).then(({ data }) => {
      let metadatas = data.data;
      for (let i = 0; i < metadatas.length; i++) {
        let data = dsf.mix({}, metadatas[i]);
        fn(data);
        tree.push(data);
      }
      if (params.namespace) {
        tree = _.filter(tree, (v) => {
          return v.ID == params.namespace;
        });
      }
      return tree;
    });
  });
  //获取系统角色树
  addAPI("getOuaRulesTree", function (params = {}, options, baseURL) {
    return this.$http.get("/oua/getChooseTreeRole", params, options, baseURL).done((res) => {
      return res;
    });
  });
  //获取当前用户所有角色
  addAPI("getCurrentUserRoles", function (params = {}, options, baseURL) {
    return this.$http.get("/oua2/current/user/role", params, options, baseURL).done((res) => {
      return res;
    });
  });
  //登录
  addAPI("login", function (params, options, baseURL) {
    let url = dsf.config.setting_public_loginin || 'login/login';
    return this.$http.post(url, params, options, baseURL).then(({ data }) => {
      //登陆时清空掉菜单缓存
      dsf.storage.session.remove("homeMenu");
      return data;
    });
  });
  //登出
  addAPI("loginOut", function (params = {}, options, baseURL) {
    let url = dsf.config.setting_public_loginout || 'login/loginOut';
    return dsf.http.post(url, params, options, baseURL).then(({ data }) => {
      return data;
    });
  });

  addAPI("appInit", function (code, options, baseURL) {
    if (code) {
      return dsf.http.get(`mobile/navigation/btn/${code}`, {}, options, baseURL)
    }
    else {
      return new Promise((resolve, reject) => {
        resolve()
      })
    }
  });
}