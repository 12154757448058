<template>
  <div class="ds-control ds-form-item ds-date-picker" :class="getCss">
    <template v-if="simple && readOnly && !isDesign">
      <span :title="getShowContent() | proxy(formatter)">{{
        getShowContent() | proxy(formatter)
      }}</span>
      <slot name="error">
        <div v-if="errors.length > 0" class="ds-error-text">
          {{ errorsMsg }}
        </div>
      </slot>
    </template>
    <template v-else>
      <!-- <label v-if="showLabel" class="ds-form-label" :style="getLabelWidthStyle()">
        {{ label }}
        <i v-if="dataCapion" class="iconfont icon-bangzhuzhongxin ds-control-helper" @click="showHelper"></i>
      </label> -->
      <DsfFieldLabel
        v-if="showLabel"
        :mode="showDataCaptionMode"
        :style="getLabelWidthStyle()"
        :show-icon="showDataCaptionIcon" 
        :trigger="showDataCaptionTrigger"
        :data-caption="dataCapion"
        :is-design="isDesign"
        :owner="_self">
        {{ $t(label) }}
      </DsfFieldLabel>
      <div class="ds-form-block" :class="{ 'ds-error-border': errors.length > 0 }" :style="getFormItemBlockMarginLeft()">
        <el-date-picker
          v-if="!readOnly"
          ref="datePicker"
          v-model="value2"
          :disabled="disabled"
          :type="$chooseType"
          :editable="isInput"
          :format="$timeFormat"
          :clearable="clearable"
          :placeholder="placeholder"
          :picker-options="mPicker || pickerOptions"
          :value-format="valueFormat"
          @focus="pickerShow"
          @change="dateChange" />
        <!--只读状态只显示div-->
        <div v-else-if="readOnly" class="ds-form-readonly">
          <span :title="getShowContent() | proxy(formatter)">{{
            getShowContent() | proxy(formatter)
          }}</span>
        </div>
        <slot name="error">
          <div v-if="errors.length > 0" class="ds-error-text">
            {{ errorsMsg }}
          </div>
        </slot>
      </div>
    </template>
  </div>
</template>

<script>
import dateMixin from "./dateMixin";
export default dsf.component({
  // inheritAttrs: false,
  name: "DsfDatePicker",
  mixins: [$mixins.formControl, dateMixin],
  ctrlCaption: "日期",
  props: {
    simple: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ""
    },
    value: {
      type: String,
      default: ""
    },
    label: {
      type: String,
      default: "日期选择"
    },
    // 时间显示格式
    timeFormat: {
      type: String,
      default: "yyyy-MM-dd"
    },
    // 日期选择器类型
    chooseType: {
      type: String,
      default: "date"
    },
    // 是否显示清除按钮
    clearable: {
      type: Boolean,
      default: true
    },
    // 是否以当前日期为起始日期
    // startIsNow: {
    //   type: Boolean,
    //   default: false
    // },
    // // 是否以当前日期为结束日期
    // endIsNow: {
    //   type: Boolean,
    //   default: false
    // },
    // 是否可输入日期
    isInput: {
      type: Boolean,
      default: false
    },
    // 显示小时段
    timeInter: {
      type: Array,
      default() {
        return ["00:00:00", "23:59:59"];
      }
    },
    // 时间选择步骤
    // 默认直接选择日
    pickerStepType: {
      type: Number,
      default: 0
    },
    // 弹框初始时间
    pickerInitDate: {
      type: Number,
      default: null
    },

    metadata: {
      type: Object,
      default() {
        return dsf.metadata.get("date-picker-meta-data");
      }
    },

    // 公式对象
    formula: {
      type: Object,
      default() {
        return {
          //公式
          formula: "",
          //自定义函数
          func: ""
        };
      }
    },
    //用于二次开发的 pickerOptions
    mPicker: {
      type: Object,
      required: false
    }
  },
  data() {
    return {};
  },
  watch: {
    value(to, ov) {
      this.initValue(to);
    }
  },
  computed: {
    value2: {
      get() {
        return this.value;
      },
      set(nv) {
        let v = nv || null;
        this.emitValueChange(v);
      }
    },
    $chooseType() {
      return this.chooseType === "datetime_s" ? "datetime" : this.chooseType;
    },

    $timeFormat() {
      if (this.chooseType === "datetime") {
        return this.timeFormat + " HH:mm";
      }
      if (this.chooseType === "datetime_s") {
        return this.timeFormat + " HH:mm:ss";
      }
      return this.timeFormat;
    },
    valueFormat() {
      return "yyyy-MM-dd HH:mm:ss";
    }
  },
  beforeCreate() {},
  created() {
    this.initValue(this.value);
  },
  methods: {
    initValue(v) {
      if (v) {
        this.value2 = dsf.date.format(v.replace(/-/g, "/"), "yyyy-mm-dd hh:ii:ss");
      } else {
        this.value2 = null;
      }
    },
    dateChange() {
      this.$nextTick(() => {
        this.$dispatch("change", this.value);
        this.emitFormValidate(this.value);
      });
    },
    // this.picker为当前时间控件的弹框
    // picker只有在控件第一次获得焦点时，才会被实例化
    pickerShow() {
      if (!this.picker) {
        this.$nextTick(() => {
          this.picker = this.$refs.datePicker.picker;
          // 隐藏此刻按钮
          if (this.timeInter && (this.timeInter[0] !== "00:00:00" || this.timeInter[1] !== "23:59:59")) {
            let bts = this.picker.$el.querySelector(".el-picker-panel__footer");
            if (bts.children.length > 1) {
              bts.children[0].style.display = "none";
            }
          }
          if (this.pickerInitDate !== null) {
            this.picker.date = new Date(this.pickerInitDate);
          }
          this.pickerStepType && this.initPicker();
        });
      } else if (this.pickerStepType) {
        this.$nextTick(() => {
          this.initPicker();
        });
      }
    },
    // 控制弹框选择时间的步骤
    initPicker() {
      this.picker.currentView = "year";
    }
  }
});
</script>