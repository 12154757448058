<template>
  <div class="ds-control ds-gridlayout ds-no-padding" :style="getStyle">
    <div v-if="isDesign" class="ds-gridlayout-tab">
      <template v-for="(obj, key) in layout">
        <div :key="key" :class="{active: active === key}" @click="active = key">{{ key }}</div>
      </template>
    </div>
    <div class="ds-gridlayout-box" ref="box" :style="boxStyle">
      <div class="ds-gridlayout-box-inner" ref="boxInner" :style="{'height':unitSize(height)}">
        <div class="ds-gridlayout-cols" ref="cols">
          <div v-for="(col,index) in realWidths" :key="index" ref="col" :style="{width:unitSize(realWidths[index])}"></div>
        </div>
        <template v-if="loaded">
          <div v-for="slot in slots" :key="slot.name" :slot-name="slot.name" class="ds-gridlayout-area">
            <slot :name="slot.name"></slot>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
export default dsf.component({
  name: "DsfGridLayout",
  ctrlCaption: "网格布局",
  mixins: [$mixins.layout],
  data() {
    return {
      containerHeight: 0,
      realHeight: 0,
      active: ">=1920",
      realWidths: [],
      loaded: false,
      areaStyle: {},
      clientWidth: 0,
      clientHeight: 0
      // hideScroll: false
    };
  },
  props: {
    gap: {
      type: [String, Number],
      default: "10"
    },
    height: {
      type: String,
      default: "100%"
    },
    //当前布局
    layout: {
      type: Object,
      default() {
        return {
          ">=1200": {
            columns: ["auto", "auto", "auto", "auto"],
            matrix: [
              //1
              {
                height: "10%",
                areas: ["A", "A", "B", "C"]
              },
              //2
              {
                height: "10%",
                areas: ["A", "A", "B", "C"]
              },
              //3
              {
                height: "10%",
                areas: ["A", "A", "B", "C"]
              },
              //4
              {
                height: "10%",
                areas: ["A", "A", "B", "C"]
              },
              //5
              {
                height: "10%",
                areas: ["A", "A", "B", "C"]
              },
              //6
              {
                height: "10%",
                areas: ["A", "A", "D", "E"]
              },
              //7
              {
                height: "10%",
                areas: ["A", "A", "D", "E"]
              },
              //8
              {
                height: "10%",
                areas: ["F", "G", "D", "E"]
              },
              //9
              {
                height: "10%",
                areas: ["F", "G", "D", "E"]
              },
              //10
              {
                height: "10%",
                areas: ["F", "G", "D", "E"]
              }
            ]
          },
          ">=768": {
            columns: ["auto"],
            matrix: [
              //1
              {
                height: "50%",
                areas: ["A"]
              },
              //2
              {
                height: "50%",
                areas: ["B"]
              },
              //3
              {
                height: "50%",
                areas: ["C"]
              },
              //4
              {
                height: "50%",
                areas: ["D"]
              },
              //5
              {
                height: "50%",
                areas: ["E"]
              },
              //6
              {
                height: "50%",
                areas: ["F"]
              },
              //7
              {
                height: "50%",
                areas: ["G"]
              }
            ]
          },
          "<768": {
            columns: ["auto"],
            matrix: [
              //1
              {
                height: "50%",
                areas: ["A"]
              },
              //2
              {
                height: "50%",
                areas: ["B"]
              },
              //3
              {
                height: "50%",
                areas: ["C"]
              },
              //4
              {
                height: "50%",
                areas: ["D"]
              },
              //5
              {
                height: "50%",
                areas: ["E"]
              },
              //6
              {
                height: "50%",
                areas: ["F"]
              },
              //7
              {
                height: "50%",
                areas: ["G"]
              }
            ]
          }
        };
      }
    },
    //插槽
    slots: {
      type: Array,
      default() {
        return [
          { name: "area-A", controls: [] },
          { name: "area-B", controls: [] },
          { name: "area-C", controls: [] },
          { name: "area-D", controls: [] },
          { name: "area-E", controls: [] },
          { name: "area-F", controls: [] },
          { name: "area-G", controls: [] }
        ];
      }
    },
    //模板
    templates: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  computed: {
    getStyle() {
      let style = {};
      if (this.isDesign) {
        style["height"] = "100%";
      }
      // else {
      //   style["height"] = this.unitSize(this.realHeight);
      // }
      // if (this.hideScroll) {
      //   style["overflow"] = "hidden";
      // }
      return style;
    },
    boxStyle() {
      let style = {};
      if (!this.isDesign) {
        let p = parseFloat(this.gap) / 2;
        style["top"] = this.unitSize(p);
        style["left"] = this.unitSize(p);
        style["bottom"] = this.unitSize(p);
        style["right"] = this.unitSize(p);
      } else {
        style.height = "100%";
        // style.width = "100%";
        style.position = "relative";
      }
      return style;
    }
  },
  beforeCreate() {},
  created() {
    this.active = Object.keys(this.layout)?.[0] || null;
  },
  beforeDestroy() {
    this._watch?.();
  },
  mounted() {
    if (this.isDesign) {
      this.loaded = true;
      this._watch = this.$watch(
        "active",
        (to) => {
          this.$nextTick(() => {
            this.$resize();
          });
        },
        {
          immediate: true
        }
      );
    } else {
      this.loaded = true;
      this.$nextTick(() => {
        this.resize();
      });
    }
  },
  methods: {
    $resize() {
      function fn() {
        this.setContainerHeight();
        this.setRealWidth();
        this.setAreaStyle();
        this.setRealHeight();
      }
      function renderLayout(resolve) {
        //当第一次执行变化后，有可能页面会出现纵向滚动条导致宽度导致宽度计算不正确
        let cw = this.clientWidth;
        let ch = this.clientHeight;
        //变化当前布局下，定位尺寸、宽高
        fn.call(this);
        // window.setTimeout(()=>{
          
        // },100)
        this.$nextTick(()=>{
          let box = this.$refs.box;
          if (box.offsetWidth != cw || box.offsetHeight != ch) {
            fn.call(this);
            this.$nextTick(() => {
              fn.call(this);
              _.each(this.$children||[],(it)=>{
                dsf.resizeComponent(it);
              })
              resolve();
            });
          }
        })
      }
      return new Promise((resolve) => {
        //第一次执行变化,判断分辨率适配哪种布局
        let currentActive = this.active;
        this.updateActive();
        if (currentActive == this.active) {
          // renderLayout.call(this,resolve);
          renderLayout.call(this,resolve);
        } else {
          this.$nextTick(() => {
            renderLayout.call(this,resolve);
          });
        }
      });
    },
    async resize() {
      await this.$resize();
    },
    setRealWidth() {
      this.realWidths = [];
      let width = this.$refs.box.offsetWidth;
      let totalWidth = width;
      this.clientWidth = totalWidth;
      let columns = this.layout[this.active].columns;
      let autoWidthColumns = [];
      let temp = [];
      for (let i = 0; i < columns.length; i++) {
        let width = columns[i];
        let realWidth = 0;
        // if (width == "" || width == "auto") {
        //   autoWidthColumns.push(i);
        // } else {
        //   temp[i] = width;
        // }
        if (dsf.isString(width)) {
          if (width.endsWith("%")) {
            realWidth = totalWidth * (parseFloat(width) / 100);
            temp[i] = realWidth;
            // this.realWidths[i] = realWidth;
          } else if (width == "auto") {
            autoWidthColumns.push(i);
          } else {
            realWidth = parseFloat(width);
            temp[i] = realWidth;
            // temp += realWidth;
            // this.realWidths[i] = realWidth;
          }
        } else if (dsf.isNumber(width)) {
          realWidth = parseFloat(width);
          temp += realWidth;
          this.realWidths[i] = realWidth;
        }
      }
      if (autoWidthColumns.length > 0) {
        // let realWidth = 0;
        // if (temp.length) {
        //   realWidth = `calc((100% -(${temp.join(" + ")})) / ${autoWidthColumns.length} )`;
        // } else {
        //   realWidth = `calc(100% / ${autoWidthColumns.length} )`;
        // }
        // for (let i = 0; i < autoWidthColumns.length; i++) {
        //   temp[autoWidthColumns[i]] = realWidth;
        // }
        let offsetWidth = totalWidth - temp;
        let realWidth = offsetWidth / autoWidthColumns.length;
        _.each(autoWidthColumns, (colIndex) => {
          temp[colIndex] = realWidth;
        });
      }
      this.realWidths = temp;
    },
    setAreaStyle() {
      // if (!this.$refs.col) {
      //   return;
      // }
      this.areaStyle = {};
      for (let i = 0; i < this.slots.length; i++) {
        let area = this.slots[i].name;
        let p = parseFloat(this.gap) / 2;
        // style['padding']=this.unitSize([p,p,p,p])
        let areaName = area.replace("area-", "");
        let matrix = this.layout[this.active].matrix;
        let columns = this.layout[this.active].columns;
        let firstCell = null;
        let lastCell = null;
        let width = 0,
          height = 0,
          top = 0,
          left = 0;
        // this.setRealWidth();
        let realWidths = this.realWidths;
        _.each(matrix, (row, rowIndex) => {
          _.each(row.areas, (cell, cellIndex) => {
            if (cell == areaName) {
              if (!firstCell) {
                firstCell = [rowIndex, cellIndex];
              }
              lastCell = [rowIndex, cellIndex];
            }
          });
        });
        // if (firstCell) {
        //   for(let i)
        //   left = this.$refs.col[firstCell[1]].offsetWidth;
        // }
        if (firstCell && lastCell) {
          for (let i = firstCell[1]; i <= lastCell[1]; i++) {
            let w = this.realWidths[i];
            width += parseFloat(w);
          }
          for (let i = 0; i < firstCell[1]; i++) {
            let w = this.realWidths[i];
            left += w;
          }
          for (let i = firstCell[0]; i <= lastCell[0]; i++) {
            // height += parseFloat(matrix[i].height);
            height += this.containerHeight * (parseFloat(matrix[i].height) / 100);
          }
          for (let i = 0; i < firstCell[0]; i++) {
            let h = this.containerHeight * (parseFloat(matrix[i].height) / 100); //parseFloat(matrix[i].height);
            top += h;
          }
        }
        // if (firstCell && lastCell) {
        //   for (let i = firstCell[0]; i <= lastCell[0]; i++) {
        //     // height += parseFloat(matrix[i].height);
        //     height += this.containerHeight * (parseFloat(matrix[i].height) / 100);
        //   }
        //   for (let i = firstCell[1]; i <= lastCell[1]; i++) {
        //     let w = this.realWidths[i];
        //     width += parseFloat(w);
        //   }
        //   for (let i = 0; i < firstCell[1]; i++) {
        //     let w = this.realWidths[i];
        //     left += w;
        //   }
        //   for (let i = 0; i < firstCell[0]; i++) {
        //     let h = this.containerHeight * (parseFloat(matrix[i].height) / 100); //parseFloat(matrix[i].height);
        //     top += h;
        //   }
        // }
        let areaEl = $(".ds-gridlayout-area[slot-name='" + area + "']");
        let style = {
          height: this.unitSize(height),
          width: this.unitSize(width),
          left: this.unitSize(left),
          top: this.unitSize(top),
          padding: !this.isDesign ? this.unitSize([p, p, p, p]).join(" ") : this.unitSize([0, 0, 0, 0]).join(" ")
        };
        areaEl.css(style);
        // this.areaStyle[area] = style;
      }

      // return style;
    },
    updateActive() {
      if (this.isDesign) {
        return;
      }
      let w = document.documentElement.offsetWidth;
      let active = "";
      let layout = this.layout;
      let array = Object.keys(layout);
      if (w >= 1920 && layout[">=1920"]) {
        active = ">=1920";
      }
      if (!active && layout[">=1200"] && w >= 1200) {
        active = ">=1200";
      }
      if (!active && layout[">=992"] && w >= 992) {
        active = ">=992";
      }
      if (!active && layout[">=768"] && w >= 768) {
        active = ">=768";
      }
      if (!active && layout["<768"]) {
        active = "<768";
      }
      // let active = "";
      // let array = [">=1920", ">=1200", ">=992", ">=768", "<768"];
      // for (let i = array.length - 1; i >= 0; i--) {
      //   let it = array[i];
      //   if (it == "<768") {
      //     if (w < 768) {
      //       active = "<768";
      //     }
      //   } else if (it == ">=768") {
      //     if (w >= 768) {
      //       active = ">=768";
      //     }
      //   } else if (it == ">=992") {
      //     if (w >= 992) {
      //       active = ">=992";
      //     }
      //   } else if (it == ">=1200") {
      //     if (w >= 1200) {
      //       active = ">=1200";
      //     }
      //   } else if (it == ">=1920") {
      //     if (w >= 1920) {
      //       active = ">=1920";
      //       break;
      //     }
      //   }
      // }
      if (active) {
        let index = _.findIndex(array, (it) => it == active);
        if (index >= 0) {
          for (let i = index; i >= 0; i--) {
            let a = array[i];
            if (this.layout[a]) {
              this.active = a;
              break;
            }
          }
        } else {
          this.active = array[0];
        }
      } else {
        this.active = array[0];
      }
    },
    setContainerHeight() {
      let height = this.height;
      if (!this.isDesign) {
        if (height.endsWith("%")) {
          this.containerHeight = this.$el.parentNode.offsetHeight;
        } else {
          this.containerHeight = parseFloat(this.height);
        }
        this.containerHeight -= this.gap;
      } else {
        if (height.endsWith("%")) {
          this.containerHeight = this.$refs.box.offsetHeight;
        } else {
          this.containerHeight = parseFloat(this.height);
        }
      }
    },
    setRealHeight() {
      if (!this.isDesign) {
        let matrix = this.layout[this.active].matrix;
        let arrs = [];
        _.each(matrix, (row, rowIndex) => {
          _.each(row.areas, (cell, cellIndex) => {
            if (!arrs[cellIndex]) {
              arrs[cellIndex] = [];
            }
            if (cell) {
              dsf.array.ensure(arrs[cellIndex], cell);
            }
          });
        });
        let maxHeight = 0;
        _.each(arrs, (col) => {
          let colHeight = 0;
          let colCount = 0;
          _.each(col, (it) => {
            let areaEl = $(".ds-gridlayout-area[slot-name='area-" + it + "']");
            if (areaEl.length) {
              colHeight += areaEl.outerHeight();
              colCount++;
            }
          });
          // colHeight += (colCount - 1) * this.gap;
          if (colHeight > maxHeight) {
            maxHeight = colHeight;
          }
          // debugger;
        });
        this.realHeight = maxHeight + parseFloat(this.gap);
        this.clientHeight = this.$refs.box.scrollHeight;
        $(this.$el).css("height", this.unitSize(this.realHeight));
      }
    }
  },
  watch: {
    height: {
      handler(v) {
        if (this.isDesign) {
          let height = v;
          if (!v) {
            height = "auto";
          }
          if (dsf.isNumber(height)) {
            height += "px";
          }
          this.$dispatch("design-height-change", height);
        }
      },
      immediate: true
    },
    layout: {
      handler(v) {
        if (this.isDesign) {
          this.$resize();
        }
      }
    }
  }
});
</script>