<template>
  <div class="ds-control ds-list-status">
    <div v-if="isDesign" style="text-align:center;background-color:#fff;font-style:italic;color:#c0c0c0">列表状态</div>
    <template v-else-if="setHandleData.length > 0">
      <p v-for="(item, index) in setHandleData" :key="index">
        <template v-if="listStatus[item.value]">
          <i :class="['iconfont', listStatus[item.value].icon ]" :style="{ color: listStatus[item.value].color }"></i>&nbsp;{{ item.text }}
        </template>
        <template v-else>{{ item.text }}</template>
      </p>
    </template>
  </div>
</template>
<script>
export default dsf.component({
  name: 'DsfListStatus',
  ctrlCaption: '列表状态',
  mixins: [$mixins.control],
  props: {
    listStatus: {
      type: Object,
      default() {
        return {};
      }
    },
    handleData: {
      type: [Object, Array],
      default: null
    }
  },
  computed: {
    setHandleData () {
      return _.isArray(this.handleData) ? this.handleData : []
    }
  }
});
</script>