<template>
  <div class="ds-data-grid-columns-filter">
    <div class="left">
      <div class="title">可选列</div>
      <dsf-virtual-scroll>
        <el-tree ref="tree" :data="columns" :default-checked-keys="defaultCheckedKeys" node-key="id" show-checkbox default-expand-all @check-change="treeChange">
          <span slot-scope="{ node, data }">
            <span>{{ node.label }}</span>
            <span v-if="!node.parent.label && data.fixed" style="margin-left: 5px;">[锁定]</span>
          </span>
        </el-tree>
      </dsf-virtual-scroll>
    </div>
    <div class="right">
      <div class="title">
        已选列
        <span class="tips">拖动列可排序</span>
      </div>
      <dsf-virtual-scroll>
        <el-tree :data="selectedFields" node-key="id" empty-text="请在左侧选择列" draggable default-expand-all :allow-drop="allowDrop">
          <span slot-scope="{ node, data }">
            <span>{{ node.label }}</span>
            <span v-if="!node.parent.level && data.fixed" style="margin-left: 5px;">[锁定]</span>
            <i class="closeBt iconfont icon-guanbi2" @click.stop="close(node)"></i>
          </span>
        </el-tree>
      </dsf-virtual-scroll>
    </div>
  </div>
</template>

<script>
export default {
  name: "DsfDataGridColumnsFilter",
  props: {
    columns: {
      type: Array,
      default() {
        return [];
      }
    },
    filter: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      defaultCheckedKeys: [],
      options: {
        animation: 300,
        forceFallback: true,
        tag: "div",
        draggable: ".tr",
        ghostClass: "ghost",
        chosenClass: "active",
        filter: ".closeBt"
      },
      form: {},
      checkAll: false,
      selectedFields: []
    };
  },
  created() {},
  mounted() {
    if (!this.filter || this.filter.length == 0) {
      dsf.deepForEach(this.columns, (it) => {
        this.treeChange(it, true);
      });
    } else {
      this.selectedFields = dsf.mix(true, [], this.filter);
    }
    let columnsMap = {};
    let arr = [];
    dsf.deepForEach(this.columns, (it) => {
      columnsMap[it.id] = it;
    });
    dsf.deepForEach(this.selectedFields, (it) => {
      let col = columnsMap[it.id];
      if (col && !col.isLast) {
        let schildren = it.children || [];
        let cchildren = col.children || [];
        if (schildren.length == cchildren.length) {
          arr.push(it.id);
        }
      } else {
        arr.push(it.id);
      }
    });

    // dsf.deepForEach(this.selectedFields, ({ id }) => {
    //   arr.push(id);
    // });
    this.defaultCheckedKeys = arr;
  },
  methods: {
    // 树选择事件
    treeChange(item, isCheck) {
      if (item.isLast) {
        let newData = this.getSelectObject(item);
        if (isCheck) {
          this.add(this.selectedFields, newData);
        } else {
          this.remove(this.selectedFields, newData);
        }
      }
    },
    getSelectObject(item) {
      if (item.isLast) {
        let node = this.$refs.tree.getNode(item.id);
        let newData = null;
        dsf.deepForEach(
          node,
          ({ data: { id, columnId, width, fixed }, label }) => {
            if (!label) return;
            if (newData) {
              newData = { id, columnId, label, width, fixed, children: [newData] };
            } else {
              newData = { id, columnId, label, width, fixed };
            }
          },
          {
            children: "parent"
          }
        );
        return newData;
      }
    },
    add(data, source) {
      let obj = data.find((d) => d.id === source.id);
      if (!obj && !source.fixed) {
        data.push(source);
      } else if (!obj && source.fixed) {
        let index = data.findIndex((d) => !d.fixed);
        if (index === -1) {
          index = data.length;
        }
        data.splice(index, 0, source);
      } else if (source.children?.length) {
        if (!obj.children) {
          obj.children = source.children;
        } else {
          source.children.forEach((c) => {
            this.add(obj.children, c);
          });
        }
      }
    },
    remove(data, source) {
      let index = data.findIndex((d) => d.id === source.id);
      let obj = index > -1 ? data[index] : null;
      if (!obj) {
        return;
      }
      if (!source.children) {
        data.splice(index, 1);
        return;
      }
      if (source.children.length) {
        source.children.forEach((c) => {
          this.remove(obj.children, c);
        });
        if (!obj.children.length) {
          data.splice(index, 1);
        }
      }
    },
    close(node) {
      this.$refs.tree.setChecked(node.key, false, true);
    },
    // 拖拽是否能放置的判断
    allowDrop(draggingNode, dropNode, type) {
      if (type === "prev" || type === "next") {
        if (draggingNode.parent !== dropNode.parent) {
          return false;
        }
        if (draggingNode.level === 1) {
          let index = this.selectedFields.findIndex((col) => col.id === draggingNode.data.id);
          let index2 = this.selectedFields.findIndex((col) => col.id === dropNode.data.id);
          let dropPrevData = null,
            dropNextData = null;
          if (index < index2) {
            dropPrevData = dropNode.data;
            dropNextData = index + 1 === this.selectedFields.length ? { fixed: false } : this.selectedFields[index + 1];
          } else {
            dropPrevData = !index2 ? { fixed: true } : this.selectedFields[index2 - 1];
            dropNextData = dropNode.data;
          }
          if (draggingNode.data.fixed && !dropPrevData.fixed) {
            return false;
          }
          if (!draggingNode.data.fixed && dropNextData.fixed) {
            return false;
          }
        }
        return true;
      }
      return false;
    },
    yes() {
      let arr1 = [],
        arr2 = [];
      dsf.deepForEach(this.columns, (it) => {
        arr1.push(it.id);
      });
      dsf.deepForEach(this.selectedFields, (it) => {
        arr2.push(it.id);
      });
      if (arr1.length != arr2.length) {
        return this.selectedFields;
      } else {
        let isEqual = true;
        for (let i = 0; i < arr1.length; i++) {
          let it = arr1[i];
          let sitem = arr2[i];
          if (it != sitem) {
            isEqual = false;
            break;
          } else {
            continue;
          }
        }
        if (!isEqual) {
          return this.selectedFields;
        } else {
          return "all"
        }
      }
    }
  }
};
</script>