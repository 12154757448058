var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "ds-control ds-html" }, [
    _vm.isDesign
      ? _c(
          "div",
          {
            staticStyle: {
              "text-align": "center",
              "background-color": "#fff",
              padding: "3px 0px",
              "font-style": "italic",
              color: "#c0c0c0",
            },
          },
          [_vm._v("html片段")]
        )
      : _c(
          "div",
          {
            staticStyle: {
              "text-align": "center",
              "background-color": "#fff",
              padding: "20px 0px",
              "font-style": "italic",
              color: "#c0c0c0",
            },
          },
          [_vm._v(" html组件只能在正式运行下查看 ")]
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }