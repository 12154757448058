import { render, staticRenderFns } from "./Alpha.vue?vue&type=template&id=461a4f6a&"
import script from "./Alpha.vue?vue&type=script&lang=js&"
export * from "./Alpha.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

component.options.__file = "pc/common/utils/colorSelect/Alpha.vue"
export default component.exports