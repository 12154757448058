var render, staticRenderFns
import script from "./multiRouteView.vue?vue&type=script&lang=js&"
export * from "./multiRouteView.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

component.options.__file = "pc/bhc/layout/multiRouteView.vue"
export default component.exports