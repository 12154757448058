var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-home-default-aside", class: { mini: _vm.mini } },
    [
      _c(
        "DsfVirtualScroll",
        { staticClass: "content", attrs: { width: "100%", "scroll-y": "" } },
        [
          _c("dsf-default-home-menu", {
            ref: "menu",
            attrs: { "menu-list": _vm.menuList },
            on: { mouseenter: _vm.mouseenter, mouseleave: _vm.mouseleave },
          }),
        ],
        1
      ),
      _vm.mini ? _c("home-aside-menu-children", { ref: "children" }) : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }