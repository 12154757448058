<!--
 * @Descripttion: 权限设置
 * @Author: zhanghang
 * @Date: 2021-08-27 16:00:06
 * @LastEditors: zhanghang
 * @LastEditTime: 2021-10-20 10:40:29
-->
<template>
  <div class="permission-setting">
    <div class="permission-setting-title">模板名称：{{ name }}</div>
    <template v-if="!isPartSetting">
      <el-tabs class="permission-setting-content" v-model="activeName">
        <el-tab-pane
          v-for="(item, index) in data"
          :key="item.name"
          :label="item.name"
          :name="index + ''"
        >
          <div class="permission-setting-item">
            <div v-for="(btns, i) in item.list" :key="i">
              <template v-if="btns.selector === 'checkbox'">
                <h3>{{ btns.name }}</h3>
                <el-checkbox-group v-model="btns.detailList[0].checkList">
                  <el-checkbox
                    v-for="checkItem in filterTplSelections(type[btns.type].subDictVO.dict,1)"
                    :key="checkItem.text"
                    :label="checkItem.value"
                  >
                    {{ checkItem.text }}
                  </el-checkbox>
                </el-checkbox-group>
              </template>
              <template v-else>
                <h3>{{ btns.name }}</h3>
                <div
                  class="permission-setting-radio-group"
                  v-for="radioItem in filterTplSelections(btns.detailList,2)"
                  :key="radioItem.text"
                >
                  <label>{{ radioItem.subTypeName }}</label>
                  <el-radio-group v-model="radioItem.auth" fill="#FFFFFF">
                    <el-radio
                      @click.native.prevent="change('1', radioItem)"
                      label="1"
                    >
                      全部
                    </el-radio>
                    <el-radio
                      @click.native.prevent="change('0', radioItem)"
                      label="0"
                    >
                      禁用
                    </el-radio>
                    <el-radio
                      v-if="radioItem.subType != '10'"
                      @click.native.prevent="change('2', radioItem)"
                      label="2"
                    >
                      部分
                    </el-radio>
                  </el-radio-group>
                </div>
              </template>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </template>
    <!-- 部分选择 -->
    <template v-else>
      <MetaDataSelectItem
        ref="MetaDataSelectItem"
        nameKey="_name"
        idKey="_id"
        :url="url"
        :defaultCheckedKey="defaultCheckedKey"
      />
    </template>
  </div>
</template>
<script>
import MetaDataSelectItem from "./metaDataSelectItem";
export default dsf.component({
  name: "DsfPlatformPermissionSetting",
  ctrlCaption: "权限设置",
  components: { MetaDataSelectItem },
  mixins: [$mixins.control],
  props: {
    //是否是部分设置
    isPartSetting: {
      type: Boolean,
      default: false,
    },
    //模板id
    _id: {
      type: String,
      default: "",
    },
    // 模板类型
    tplType: {
      type: String,
      default: "",
    },
    //模板名字
    name: {
      type: String,
      default: "",
    },
    //查询组件接口
    url: {
      type: String,
      default: "",
    },
    //是否展示成功提示
    isTip:{
      type: Boolean,
      default: true,
    },
    // 默认选中的节点，多选为Array，单选为String或Number
    defaultCheckedKey: {
      type: [Array, String, Number],
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      data: [],
      //所有类型对象
      type: {},
      activeName: "0",
    };
  },
  created() {
    if (!this.isPartSetting) {
      //初始化数据
      this.initData();
    }
  },
  methods: {
    // 过滤出 模板需要的选项
    filterTplSelections(list,type) {
      // 过滤功能按钮
      if(type===1){
        list = this.tplType!=3?list.filter(v=>!['9','10'].includes(v.value)):list.filter(v=>!['3','4','6','7'].includes(v.value))
      //过滤组件权限
      }else{
        list = this.tplType!=3?list.filter(v=>['1','2','10'].includes(v.subType+'')):list.filter(v=>['3','4'].includes(v.subType+''))
      }
    
      return list;
    },
    initData() {
      let loading = dsf.layer.loading();
      this.$http
        .get(`template/findTemplatePermission?templateId=${this._id}`)
        .done(({ success, data, message }) => {
          if (success) {
            let templete = data.templatePermissionDictVOS;
            let templatePermissionBaseVOS = data.templatePermissionBaseVOS;
            //筛选出nav
            let nav = templete.find((v) => v.key === "dictRoles").dict;

            //过滤出不是nav 数据
            let filterNav = templete.filter((v) => v.key !== "dictRoles");

            // 动态绑定类型数据
            filterNav.forEach((v) => {
              this.$set(this.type, v.type, v);
            });

            //初始化接口，构造数据
            if (!templatePermissionBaseVOS) {
              //构造组件数组
              nav.forEach((v) => {
                //构造list
                let list = [];
                Object.keys(this.type).forEach((key) => {
                  // 需要构造一个对象
                  let detailList = [];
                  if (this.type[key].selector === "checkbox") {
                    detailList.push({
                      roleCode: v.value,
                      roleName: v.text,
                      typeName: this.type[key].name,
                      type: this.type[key].type,
                      subTypeName: this.type[key].subDictVO.name,
                      subType: this.type[key].subDictVO.type,
                      authExt: "",
                      checkList: this.type[key].subDictVO?.dict.map(
                        (v) => v.value
                      ),
                      auth: this.type[key].subDictVO?.dict
                        .map((v) => v.value)
                        .join("^"), //全选
                    });
                    //如果是单选
                  } else {
                    this.type[key].dict.forEach((_v) => {
                      let o = {
                        roleCode: v.value,
                        roleName: v.text,
                        typeName: this.type[key].name,
                        type: this.type[key].type,
                        subTypeName: _v.text,
                        subType: _v.value,
                        authExt: "",
                        auth: "1",
                      };
                      detailList.push(o);
                    });
                  }
                  let o = {
                    name: this.type[key].name,
                    type: this.type[key].type,
                    selector: this.type[key].selector,
                    detailList,
                  };
                  list.push(o);
                });
                this.data.push({ name: v.text, type: v.value, list });
              });
            } else {
              // 如果存在保存过后的
              templatePermissionBaseVOS.forEach((v) => {
                v.list.forEach((_v) => {
                  _v.selector = this.type[_v.type].selector;
                  _v.detailList.forEach((d) => {
                    //说明是checkbox
                    if (_v.selector === "checkbox") {
                      if (d.auth.indexOf("^") > -1) {
                        d.checkList = d.auth.split("^");
                      } else {
                        d.checkList = [];
                      }
                    }
                  });
                });
              });
              this.data = templatePermissionBaseVOS;
              return false;
            }
          } else {
            this.$message(message || "请求异常", false);
          }
        })
        .error((err) => {
          dsf.error(err);
          this.$message(err?.message || "请求异常", false);
        })
        .always(() => {
          dsf.layer.closeLoading(loading);
        });
    },
    //save 方法
    yes(fn) {
      let arr = [];
      this.data.forEach((v) => {
        v.list.forEach((_v) => {
          _v.detailList.forEach((d) => {
            d.dsOrder = d.dsOrder ? d.dsOrder : arr.length;
            d.templateId = d.templateId ? d.templateId : this._id;
            arr.push(d);
            // 是checkbox
            if (d.checkList) {
              d.auth = d.checkList.join("^");
            }
          });
        });
      });
      if (arr.length) {
        let loading = dsf.layer.loading();
        this.$http
          .post("template/saveTemplatePermission", {
            permissions: JSON.stringify(arr),
          })
          .done((res) => {
            if (res.success) {
              (this.isTip&&dsf.layer.message("保存成功"))

              this.$dialog?.close();

              typeof fn === "function" && fn();
              return;
            }
            dsf.layer.message("权限保存失败", false);
          })
          .always(() => {
            dsf.layer.closeLoading(loading);
          });
      }
    },
    change(val, radioItem) {
      radioItem.auth = val;
      if (val === "2") {
        // 获取默认
        let authExt = radioItem.authExt;

        let defaultCheckedKey = [];
        if (authExt) {
          defaultCheckedKey = authExt.split(",");
        }
        this.$openDialog({
          title: "权限设置",
          content: `DsfPlatformPermissionSetting`,
          width: "500px",
          height: "50vh",
          params: {
            isPartSetting: true,
            name: this.name,
            url:
              "template/loadTemplateComponentGroupTree?type=" +
              (Number(radioItem.subType) + 10),
            defaultCheckedKey: defaultCheckedKey,
          },
          btns: [
            {
              text: "保存",
              handler(res) {
                let selectList = res.$refs.MetaDataSelectItem.yes();
                if (selectList.length === 0) {
                  radioItem.auth = "1";
                  radioItem.authExt = "";
                } else {
                  radioItem.authExt = selectList.map((v) => v._id).join(",");
                }
              },
            },
            {
              text: "取消",
              handler(res) {
                //没有选择部分就默认全部
                if (!authExt) {
                  radioItem.auth = "1";
                  radioItem.authExt = "";
                }
              },
            },
          ],
        });
      }
    },
  },
});
</script>
