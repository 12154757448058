var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isDesign
    ? _c("div", { staticClass: "ds-platform-card-item-by-teacher" }, [
        _c(
          "div",
          { staticClass: "avatar" },
          [_c("dsf-image", { staticClass: "avatar-pic" })],
          1
        ),
        _vm._m(0),
      ])
    : _c(
        "div",
        {
          staticClass: "ds-platform-card-item-by-teacher",
          on: {
            click: function ($event) {
              return _vm._clickBefore(_vm.itemClick)
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "avatar" },
            [
              _c("dsf-image", {
                staticClass: "avatar-pic",
                attrs: {
                  src: _vm._f("imgFormat")(_vm.data["avatar"]),
                  "error-src": _vm.errorSrc,
                },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "info" }, [
            _c(
              "p",
              { staticClass: "name", attrs: { title: _vm.data["name"] } },
              [_vm._v(_vm._s(_vm.data["name"]))]
            ),
            _c("div", { staticClass: "middle" }, [
              _c(
                "p",
                { staticClass: "line-1", attrs: { title: _vm.data["unit"] } },
                [_vm._v(_vm._s(_vm.data["unit"]))]
              ),
              _c(
                "p",
                {
                  staticClass: "line-2",
                  attrs: { title: _vm.data["position"] },
                },
                [_vm._v(_vm._s(_vm.data["position"]))]
              ),
            ]),
            _c(
              "p",
              {
                staticClass: "course",
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm._clickBefore(_vm.courseClick)
                  },
                },
              },
              [_vm._v(_vm._s(_vm.data["courseNum"]) + "门精品课程 >>")]
            ),
          ]),
        ]
      )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "info" }, [
      _c("p", { staticClass: "name" }, [_vm._v("张三")]),
      _c("div", { staticClass: "middle" }, [
        _c("p", { staticClass: "line-2" }, [
          _vm._v("中央党校（国家行政学院）"),
        ]),
        _c("p", [_vm._v("副校长")]),
      ]),
      _c("p", { staticClass: "course" }, [_vm._v("15门精品课程 >>")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }