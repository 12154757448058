var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("el-tree", {
    ref: "tree",
    staticClass: "super-search-downdrop-tree",
    attrs: {
      "node-key": "_id",
      data: _vm.options,
      "show-checkbox": _vm.multipe,
      "expand-on-click-node": _vm.multipe,
      "highlight-current": "",
      props: {
        label: "_name",
        children: "children",
      },
    },
    on: { "node-click": _vm.nodeClickHandler },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function (ref) {
          var node = ref.node
          var data = ref.data
          return _c("span", { staticClass: "custom-tree-node" }, [
            _c("span", { class: { "node-disabled": _vm.nodeDisabled(data) } }, [
              _vm._v(_vm._s(node.label)),
            ]),
          ])
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }