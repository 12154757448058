<template>
  <div class="ds-designer-mobile-setting" style="width:100%;height:100%;padding-top:50px;box-sizing: border-box;">
    <div class="ds-designer-phone">
      <DsfDemoBrowserNavBar class="browser-nav-bar" :show-back="false" :title="title"></DsfDemoBrowserNavBar>
      <iframe name="appDemo" :src="dsf.url.getWebPath('~/designer.html#/pc/preview?mobile=1')"></iframe>
    </div>
  </div>
</template>
<script>
export default {
  name: "DsfMobilePreView",
  props: {},
  data() {
    return {
      title: ""
    };
  },
  created() {
    window.addEventListener("message", this.onMessage);
    // let context = dsf.storage.session.get("layoutContent");
  },
  beforeDestroy() {
    window.removeEventListener("message", this.onMessage);
  },
  methods: {
    onMessage(e) {
      if (e.data.type == "postTitle") {
        this.title = e.title;
      }
    }
  }
};
</script>