<template>
  <div class="ds-control ds-form-item ds-system" :class="getCss">
    <template v-if="simple && !isDesign">{{ value }}</template>
    <template v-else>
      <!-- <label v-if="showLabel" class="ds-form-label" :style="getLabelWidthStyle()">{{ label }}</label> -->
      <DsfFieldLabel
        v-if="showLabel"
        :mode="showDataCaptionMode"
        :style="getLabelWidthStyle()"
        :show-icon="showDataCaptionIcon" 
        :trigger="showDataCaptionTrigger"
        :data-caption="dataCapion"
        :is-design="isDesign"
        :owner="_self">
        {{ $t(label) }}
      </DsfFieldLabel>
      <div class="ds-form-block" :style="getFormItemBlockMarginLeft()">
        <template v-if="isDesign">
          [当前用户id]
        </template>
        <template v-else>
          {{ value }}
        </template>
      </div>
    </template>
  </div>
</template>

<script>
export default dsf.component({
  name: "DsfCreateUserId",
  mixins: [$mixins.formControl],
  ctrlCaption: "创建人ID",
  props: {
    caption: {
      type: String,
      default: dsf.config.kw.createuserid,
    },
    label: {
      type: String,
      default: "创建人ID",
    },
    simple: {
      type: Boolean,
      default: false,
    },
    showLabel: {
      type: Boolean,
      default: true,
    },
    allowPost:{
      type:Boolean,
      default:false
    },
    metadata: {
      type: Object,
      default() {
        return dsf.metadata.get("system-meta-data", {
          code: dsf.config.kw.createuserid,
          valueAttributes: [
            {
              name: "创建人ID",
              code: dsf.config.kw.createuserid,
              type: dsf.metadata.getDataType("string"),
              length: "50",
              defaultValue: null,
              unit: null,
            },
          ],
        });
      },
    },
  },
});
</script>
