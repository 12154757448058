<template>
  <div v-if="isDesign || (sPID && sCurLinkID)" class="ds-control ds-form-item ds-textbox" :class="getCss">
    <!-- <label v-if="showLabel" class="ds-form-label" :style="getLabelWidthStyle()">
      {{ label }}
      <i v-if="dataCapion" class="iconfont icon-bangzhuzhongxin ds-control-helper" @click="showHelper"></i>
    </label> -->
    <DsfFieldLabel
      v-if="showLabel"
      :mode="showDataCaptionMode"
      :style="getLabelWidthStyle()"
      :show-icon="showDataCaptionIcon" 
      :trigger="showDataCaptionTrigger"
      :data-caption="dataCapion"
      :is-design="isDesign"
      :owner="_self">
      {{ $t(label) }}
    </DsfFieldLabel>
    <div class="ds-form-block" :style="getFormItemBlockMarginLeft()">
      <template v-for="(item, index) in data">
        <p :key="index + '0'" class="form-comment-box-value">
          <img class="icon" :src="require(`_platform/assets/styles/themes/workFlow/ry1.png`)" />
          <span class="name">{{ item.rusername }}</span>
          <span class="content">{{ item.opinionatts && item.opinionatts.Content }}</span>
          <span class="time">{{ item.receivetime }}</span>
        </p>
        <DsfFlowFileList
          :key="index + '1'"
          v-if="item.opinionatts && item.opinionatts.Attachments && item.opinionatts.Attachments.length > 0"
          :attachments="item.opinionatts.Attachments"
        ></DsfFlowFileList>
      </template>
      <p v-if="isShowAdd" class="form-comment-add">
        <DsfButton @click="addComment">{{ addCommentText }}</DsfButton>
      </p>
    </div>
  </div>
</template>
<script>
import DsfFlowFileList from "./flowFileList";
export default dsf.component({
  name: "DsfFormCommentBox",
  mixins: [$mixins.formControl, $mixins.formCommentBox],
  ctrlCaption: "表单意见框",
  components: {
    DsfFlowFileList,
  },
});
</script>
