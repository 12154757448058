import { render, staticRenderFns } from "./homeMenu.vue?vue&type=template&id=695410f4&"
import script from "./homeMenu.vue?vue&type=script&lang=js&"
export * from "./homeMenu.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

component.options.__file = "pc/home/menu/template1/homeMenu.vue"
export default component.exports