var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "ds-control ds-chart",
      style: { width: _vm.width, height: _vm.height },
    },
    [_c("div", { ref: "chart", staticClass: "chart" }, [_vm._v("我是雷达图")])]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }