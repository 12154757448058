var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "ds-picture-cut-dialog",
      attrs: {
        title: "裁剪图片",
        visible: _vm.dialogVisible,
        "before-close": _vm.closeDialog,
        "close-on-click-modal": false,
        width: "60%",
        "append-to-body": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
        opened: _vm.dialogOpened,
      },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function () {
            return [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.cutImg } },
                [_vm._v("确 定")]
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { staticStyle: { height: "500px" }, attrs: { span: 16 } },
            [
              _c("vue-picture-cut", {
                ref: "pictureCut",
                attrs: {
                  mskOption: {
                    width: _vm.width,
                    height: _vm.height,
                    resize: false,
                  },
                  "menu-thickness": 0,
                  "max-pixel": Math.min(Math.max(_vm.width, _vm.height), 1920),
                  src: _vm.src,
                },
              }),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 7, offset: 1 } },
            [
              _c(
                "el-form",
                { attrs: { "label-width": "90px", size: "small" } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "裁剪形状：" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.cut.shape,
                            callback: function ($$v) {
                              _vm.$set(_vm.cut, "shape", $$v)
                            },
                            expression: "cut.shape",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: "rect" } }, [
                            _vm._v("矩形"),
                          ]),
                          _c("el-radio", { attrs: { label: "circle" } }, [
                            _vm._v("椭圆"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "翻转镜像：" } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { size: "mini", type: "info", round: "" },
                          on: { click: _vm.reverLevel },
                        },
                        [
                          _c("i", { staticClass: "el-icon-sort vertial" }),
                          _vm._v(" 水平 "),
                        ]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { size: "mini", type: "info", round: "" },
                          on: { click: _vm.reverVertical },
                        },
                        [
                          _c("i", { staticClass: "el-icon-sort" }),
                          _vm._v(" 垂直 "),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "裁剪比例：" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "ratio-select",
                          on: { change: _vm.ratioTypeChange },
                          model: {
                            value: _vm.cut.ratioType,
                            callback: function ($$v) {
                              _vm.$set(_vm.cut, "ratioType", $$v)
                            },
                            expression: "cut.ratioType",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "拖动", value: "drag" },
                          }),
                          _c("el-option", {
                            attrs: { label: "输入", value: "custom" },
                          }),
                          _c("el-option", {
                            attrs: { label: "自适应", value: "original" },
                          }),
                          _c("el-option", {
                            attrs: { label: "1:1", value: "1:1" },
                          }),
                          _c("el-option", {
                            attrs: { label: "4:3", value: "4:3" },
                          }),
                          _c("el-option", {
                            attrs: { label: "3:4", value: "3:4" },
                          }),
                          _c("el-option", {
                            attrs: { label: "16:9", value: "16:9" },
                          }),
                          _c("el-option", {
                            attrs: { label: "9:16", value: "9:16" },
                          }),
                          _c("el-option", {
                            attrs: { label: "3:2", value: "3:2" },
                          }),
                          _c("el-option", {
                            attrs: { label: "2:3", value: "2:3" },
                          }),
                        ],
                        1
                      ),
                      _vm.cut.ratioType == "custom"
                        ? _c(
                            "div",
                            { staticClass: "ratio-input" },
                            [
                              _c("el-input-number", {
                                attrs: {
                                  controls: false,
                                  min: 1,
                                  placeholder: "宽",
                                },
                                on: { change: _vm.changeRatio },
                                model: {
                                  value: _vm.cut.ratio[0],
                                  callback: function ($$v) {
                                    _vm.$set(_vm.cut.ratio, 0, $$v)
                                  },
                                  expression: "cut.ratio[0]",
                                },
                              }),
                              _c("span", [_vm._v("/")]),
                              _c("el-input-number", {
                                attrs: {
                                  controls: false,
                                  min: 1,
                                  placeholder: "高",
                                },
                                on: { change: _vm.changeRatio },
                                model: {
                                  value: _vm.cut.ratio[1],
                                  callback: function ($$v) {
                                    _vm.$set(_vm.cut.ratio, 1, $$v)
                                  },
                                  expression: "cut.ratio[1]",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "旋转角度：" } },
                    [
                      _c("el-input-number", {
                        staticClass: "angle-number",
                        attrs: {
                          min: -180,
                          max: 180,
                          "step-strictly": "",
                          size: "small",
                        },
                        on: { change: _vm.angleBlur },
                        model: {
                          value: _vm.cut.angle,
                          callback: function ($$v) {
                            _vm.$set(_vm.cut, "angle", $$v)
                          },
                          expression: "cut.angle",
                        },
                      }),
                      _c("el-button", {
                        staticClass: "angle-button",
                        attrs: { icon: "el-icon-refresh-left", circle: "" },
                        on: { click: _vm.angleReset },
                      }),
                      _c("el-slider", {
                        staticClass: "angle-slider",
                        attrs: { min: -180, max: 180, "show-tooltip": false },
                        on: { input: _vm.angleBlur },
                        model: {
                          value: _vm.cut.angle,
                          callback: function ($$v) {
                            _vm.$set(_vm.cut, "angle", $$v)
                          },
                          expression: "cut.angle",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }