var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "ds-virtual-list ds-control ds-no-padding",
      style: _vm.controlStyle,
    },
    [
      _c(
        "div",
        _vm._b(
          {
            staticClass: "ds-virtual-list-warp",
            style: _vm.panelStyle,
            attrs: { "is-design": _vm.isDesign, pointer: _vm.pointer },
          },
          "div",
          {
            hoverBright: _vm.styleAttrs.hoverBright,
            dividerLine: _vm.styleAttrs.dividerLine,
          },
          false
        ),
        [
          _vm.isDesign
            ? [
                _c(
                  "div",
                  {
                    staticClass: "ds-virtual-list-design",
                    attrs: { "slot-name": "list-row" },
                  },
                  [
                    _vm._t("list-row", null, null, {
                      row: {},
                      $index: 0,
                      $hash: 1,
                    }),
                  ],
                  2
                ),
              ]
            : [
                _vm.loaded && (!_vm.listData || !_vm.listData.length)
                  ? _c("dsf-empty-data")
                  : [
                      _vm._l(_vm.fliterList, function (row, index) {
                        return [
                          _c(
                            "div",
                            {
                              key: index,
                              staticClass: "ds-virtual-list-row",
                              style: {
                                padding: _vm.rowPadding.join("px ") + "px",
                                margin: _vm.rowMargin.join("px ") + "px",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.rowClick({
                                    row: row,
                                    $index: index,
                                    $hash: row.$hash,
                                  })
                                },
                              },
                            },
                            [
                              _vm._t("list-row", null, null, {
                                row: row,
                                $index: index,
                                $hash: row.$hash,
                              }),
                            ],
                            2
                          ),
                        ]
                      }),
                    ],
              ],
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }