<template>
  <div class="ds-control">
    <div
      v-if="isDesign || sPID"
      class="ds-control ds-form-item ds-flowprocess"
      :class="{
        hideYj: isDesign ? hideYj : isHideYj,
        hideNode: isDesign ? hideNode : isHideNode,
      }"
    >
      <div class="head">
        <div class="title">
          <label handler="title" class="ds-font-3">办理情况</label>
        </div>
        <div class="set" v-if="!hideCheckbox">
          <el-checkbox
            v-if="isDesign"
            v-model="hideNode"
            label="隐藏异常环节"
          ></el-checkbox>
          <el-checkbox
            v-if="isDesign"
            v-model="hideYj"
            label="隐藏无意见环节"
          ></el-checkbox>
          <el-checkbox
            v-if="!isDesign"
            v-model="isHideNode"
            label="隐藏异常环节"
          ></el-checkbox>
          <el-checkbox
            v-if="!isDesign"
            v-model="isHideYj"
            label="隐藏无意见环节"
          ></el-checkbox>
        </div>
      </div>
      <div class="content" handler="content">
        <div
          v-for="(item, index) in data"
          :key="index"
          class="item"
          :class="{
            unusual: item.STATUS == '-4',
            noyj:
              !item.opinionatts ||
              (item.opinionatts &&
                !item.opinionatts.Content &&
                !item.opinionatts.Attachments),
          }"
        >
          <div class="fl-box">
            <span>{{ item.reachtime }}</span>
            <img
              class="tx"
              :src="
                require('_platform/assets/styles/themes/workFlow/head_logo.png')
              "
            />
            <img
              class="icon"
              :src="
                require(`_platform/assets/styles/themes/workFlow/status${getStatus(
                  item.STATUS
                )}.png`)
              "
            />
          </div>
          <div class="fr-box" :class="{ end: item.nodetype === '299' }">
            <div class="des">
              <span>处理环节：{{ item.nodename }}</span>
              <span
                v-if="item.nodetype !== '299'"
              >处理人：{{ item.rusername }}</span
              >
              <span
                v-if="item.nodetype !== '299'"
              >签收时间：{{ item.receivetime }}</span
              >
            </div>
            <div v-if="showResult && item.nodetype !== '299'" class="result">
              处理结果：
              {{ results[item.EXSTATUS] }}
            </div>
            <!-- <div v-if="item.EXSTATUS != '-8' && item.EXSTATUS != '-9' && item.nodetype !== '201' && item.nodetype !== '299'" class="yj"> -->
            <div v-if="showOpinion && showOpinion(item)" class="yj">
              处理意见：
              {{
                (item.opinionatts && item.opinionatts.Content) ||
                  processingOpinion
              }}
            </div>
            <DsfFlowFileList
              v-if="
                item.opinionatts &&
                  item.opinionatts.Attachments &&
                  item.opinionatts.Attachments.length > 0
              "
              :attachments="item.opinionatts.Attachments"
            ></DsfFlowFileList>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DsfFlowFileList from "./flowFileList";
export default dsf.component({
  name: "DsfFlowProcess",
  mixins: [$mixins.control],
  ctrlCaption: "办理情况",
  components: {
    DsfFlowFileList,
  },
  props: {
    caption: {
      type: String,
      default() {
        return "wfprocess";
      },
    },
    hideCheckbox: {
      type: Boolean,
      default() {
        return false;
      },
    },
    hideNode: {
      type: Boolean,
      default() {
        return true;
      },
    },
    hideYj: {
      type: Boolean,
      default() {
        return true;
      },
    },
    showResult: {
      type: Boolean,
      default() {
        return false;
      },
    },
    orderDes: {
      type: Boolean,
      default() {
        return false;
      },
    },
    processingOpinion: {
      type: String,
      default() {
        return "无数据";
      },
    },
    showOpinion: {
      type: Function,
      default: (item) => {
        if (item && (item.STATUS === 0 || item.STATUS === 1)) {
          return (
            (item.isCurrentUser) &&
            (item?.opinionatts?.Content ||
              item?.opinionatts?.Attachments?.length > 0)
          );
        }
        return (
          item &&
          (item.STATUS === 2 || item.STATUS === -4) &&
          item.nodetype !== "201" &&
          item.nodetype !== "299"
        );
      },
    },
  },
  data() {
    return {
      sPID: null,
      sCurLinkID: null,
      isHideNode: this.hideNode,
      isHideYj: this.hideYj,
      // data: [],
      results: {
        "-9": "提交", // 初始化提交
        "-8": "提交", // 修改后提交
        0: "审核通过", // 环节通过
        1: "审核通过", // 流程通过
        "-1": "不通过", // 流程不通过
        "-2": "返回修改", // 环节返回修改
      },
    };
  },
  computed: {
    data() {
      if (this.isDesign || !this.sPID) {
        const data = [
          {
            nodename: "开始",
            EXSTATUS: -9,
            STATUS: 0,
            linkid: "faedda583f7742948b0714f0f17d1085",
            opinionatts: {
              Content: "同意",
            },
            ruserid: "",
            pid: "af60e455757d48dd801d07b1538eb806",
            rusername: "平台管理员",
            rdeptname: "平台管理组",
            reachtime: "2020年01月06日 18:20:21",
            receivetime: "2020年01月06日 18:20:21",
          },
          {
            nodename: "审批",
            EXSTATUS: -1,
            STATUS: 1,
            linkid: "saedda583f7742948b0714f0f17d1085",
            opinionatts: {
              Content: "不同意",
            },
            ruserid: "",
            pid: "sf60e455757d48dd801d07b1538eb806",
            rusername: "平台管理员",
            rdeptname: "平台管理组",
            reachtime: "2020年01月08日 18:21:25",
            receivetime: "2020年01月08日 18:22:30",
          },
          {
            nodename: "结束",
            EXSTATUS: 2,
            STATUS: 1,
            linkid: "caedda583f7742948b0714f0f17d1085",
            opinionatts: {
              Content: "",
            },
            ruserid: "",
            pid: "cf60e455757d48dd801d07b1538eb806",
            rusername: "平台管理员",
            rdeptname: "平台管理组",
            reachtime: "2020年01月09日 18:23:21",
            receivetime: "2020年01月09日 20:23:21",
          },
        ];
        if (this.orderDes) {
          return data.reverse();
        }
        return data;
      }
      return this.$vm?.flows?.flowProcess
        ? this.formatData(_.cloneDeep(this.$vm.flows.flowProcess))
        : [];
    },
  },
  mounted() {
    const data =
      (this.$dialog &&
        this.$dialog.dialogArgs &&
        this.$dialog.dialogArgs.data &&
        this.$dialog.dialogArgs.data[0]) ||
      null;
    this.sPID = data
      ? data.sPID
      : this.$route.query.sPID || this.queryString.sPID;
    this.sCurLinkID = data
      ? data.sCurLinkID
      : this.$route.query.sCurLinkID || this.queryString.sCurLinkID;
    if (this.sPID) {
      this.init();
    }
  },
  methods: {
    init() {
      if (this.$vm?.flows?.loadFlowProcess) {
        return;
      }
      this.$vm.flows.loadFlowProcess = true;
      this.$http
        .get("wfr/getProcessInfoByPID", {
          sPID: this.sPID,
        })
        .then((res) => {
          if (res.data.success) {
            let params;
            if (this.$vm?.flows) {
              this.$set(this.$vm.flows, "flowProcess", res.data.data);
              this.$vm.flows?.flowProcess.forEach((item) => {
                if (this.sCurLinkID === item.linkid && item.nodetype != "299") {
                  this.$vm.flows.sOpinion = JSON.parse(item.opinionatts);
                }
              });
            }
            res.data.data.forEach((item) => {
              if (item.linkid === this.sCurLinkID && item.pid === this.sPID) {
                if (item.STATUS != 0 && item.STATUS != 1) {
                  params = dsf.mix({}, this.queryString, {
                    isHide: true,
                  });
                }
              }
            });
            //更新url添加id参数
            if (params) {
              if (this?.$frame?.independent) {
                this.$frame.urlChange({
                  pageName: this.$form.pageName,
                  nameSpace: this.$form.nameSpace,
                  query: params,
                }, true)
              }
              //如果页面在dialog中不更新url中的id参数
              else if (this.$dialog) {
                this.$dialog.currentView && this.$dialog.currentView.urlChange({
                  pageName: this.$form.pageName,
                  nameSpace: this.$form.nameSpace,
                  query: params,
                }, true)
              } else {
                this.$router.replace({
                  path: this.$route.path,
                  query: params,
                });
              }
            }
          }
        });
    },
    getStatus(status) {
      let index;
      if (status == "-31") {
        index = "-3";
      } else if (status == "-1") {
        index = "-2";
      } else {
        index = status;
      }
      return index;
    },
    formatData(list) {
      let data = [];
      list.forEach((item) => {
        item.opinionatts = JSON.parse(item.opinionatts);
        data.push(item);
      });
      if (this.orderDes) {
        return data.reverse();
      }
      return data;
    },
  },
});
</script>
