<template>
  <div :style="controlStyle" class="ds-control">
    <div class="ds-announcemen-mail" :style="panelStyle">
      <DsfVirtualScroll>
        <DsfVirtualList
          ref="vlist"
          :isDesign="isDesign"
          :url="dataUrl"
          v-bind="{
            rowPadding,
            rowMargin,
            urlQuery
          }"
          @list-change="listChangeFn"
          @row-click="rowClick"
        >
          <template v-if="isDesign" v-slot:list-row>
            <div class="ds-list-col2-row" :style="{ lineHeight }">
              <div class="ds-list-col2-item ellipsis" title="标题" type="name">
                标题
              </div>
              <div class="ds-list-col2-item" type="time">时间</div>
            </div>
          </template>
          <template v-else v-slot:list-row="scope">
            <div
              class="ds-list-col2-row"
              :read="scope.row.state == 1"
              :style="{ lineHeight }"
            >
              <img
                v-if="scope.row.isTop == 1"
                class="ds-list-col2-item"
                type="top"
                :src="dsf.url.getWebPath('/static/img/home/top.png')"
              />
              <div
                class="ds-list-col2-item ellipsis"
                :title="scope.row.title || ''"
                type="name"
              >
                {{ scope.row.title || "" }}
              </div>
              <div class="ds-list-col2-item" type="time">
                {{ scope.row.createTime | time }}
              </div>
            </div>
          </template>
        </DsfVirtualList>
      </DsfVirtualScroll>
    </div>
  </div>
</template>
<script>
import listMixin from "./mixin.js";
export default dsf.component({
  name: "DsfUniteHomeAnnouncemenMail",
  ctrlCaption: "首页公告邮件列表",
  mixins: [listMixin],
  props: {
    dataUrl: {
      type: String,
      default: "/email/receive/queryUserReceiveList?pageNum=1",
    },
  },
});
</script>
