<template>
  <div v-loading="loading" class="ds-control ds-form-item ds-upload" :class="getCss" :style="{ padding: hideBtns ? '0' : '' }">
    <!-- 解决选择下拉切换的问题 -->
    <template v-if="simple && readOnly && !isDesign && !textProxy">
      <div v-if="multipleFiles && multipleFiles.length > 0" class="simple-table-file">
        <p v-for="(data, index) in multipleFiles" :key="data.id" :class="{ 'hide-more': index > defaultRowsNumber }">
          <span class="file-name" :title="data.originalFileName" @click="handleColumnClick('view', data, index)">{{ substringFileName(data) }}</span>
          <i @click="handleColumnClick('download', data, index)" class="operation-icon el-icon-download"></i>
        </p>
        <span class="more" @click="extendHandler(multipleFiles)">{{
          multipleFiles.length > 3 && !isShowMore ? "展开更多" : isShowMore ? "收起更多" : ""
        }}</span>
      </div>
    </template>
    <template v-else>
      <!-- <label v-if="showLabel && !hideBtns" class="ds-form-label" :style="getLabelWidthStyle()">
        {{ label }}
        <i v-if="dataCapion" class="iconfont icon-bangzhuzhongxin ds-control-helper" @click="showHelper"></i>
      </label> -->
      <DsfFieldLabel v-if="showLabel&& !hideBtns" :mode="showDataCaptionMode" :style="getLabelWidthStyle()" :show-icon="showDataCaptionIcon" :trigger="showDataCaptionTrigger" :data-caption="dataCapion" :is-design="isDesign" :owner="_self">
        {{ $t(label) }}
      </DsfFieldLabel>
      <div class="ds-form-block" :class="{ 'no-ds-form-label': hideBtns ? true : false }" :style="getFormItemBlockMarginLeft()">
        <el-upload
          ref="upload"
          :class="[
            'upload-demo',
            {
              'upload-one-file-button': uploadOneFile,
              'simple-file-style': simpleFileStyle,
            },
          ]"
          :accept="acceptMime"
          :multiple="multiple"
          :headers="headersParam"
          :drag="isDragable"
          :limit="parseInt(limitNumber())"
          :action="setRequestUrl"
          :show-file-list="false"
          :before-upload="beforeUpload"
          :on-success="handleSuccess"
          :on-error="handleError" 
          :on-exceed="handleExceed"
          :on-progress="handleProgress"
          :auto-upload="autoUpload"
          :style="{ display: hideBtns || readOnly ? 'none' : 'block' }">
          <template v-if="isDragable">
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">点击上传，或将文件拖拽到此处</div>
          </template>
          <template v-else>
            <template v-if="uploadOneFile">
              <div class="one-file-button">
                {{ showBtnName ? btnName : "" }}
              </div>
              <div>
                <span class="disabled-icon">
                  <i class="operation-icon el-icon-view"></i>
                  <i class="operation-icon el-icon-download"></i>
                  <i v-if="!readOnly" class="operation-icon el-icon-delete"></i>
                </span>
                <i v-if="!readOnly" class="operation-icon el-icon-upload2"></i>
              </div>
            </template>
            <template v-else-if="simpleFileStyle">
              <div v-if="simple && !readOnly" class="scientific-research-file">
                {{ showBtnName ? btnName : "" }}
              </div>
            </template>
            <dsf-button v-else icon="yunduanshangchuan">
              <!-- <i class="el-icon-upload el-icon--left"></i> -->
              {{ showBtnName ? btnName : "" }}
            </dsf-button>
          </template>
        </el-upload>
        <dsf-button v-if="multipleFiles && multipleFiles.length > 0 && !uploadOneFile && !readOnly && !simpleFileStyle" class="ds-button download-button" :style="{ display: hideBtns ? 'none' : 'block' }" icon="yunduanxiazai" @click="downLoadFn">
          全部下载
        </dsf-button>
        <el-progress v-if="showProgress && percentage > 0" :text-inside="true" :color="customColor" :stroke-width="parseInt(strokeWidth)" :percentage="percentage" />
        <!-- 简易模式在table里显示的内容 -->
        <template v-if="simple && !isDesign">
          <div v-if="multipleFiles.length" class="simple-table-file">
            <p v-for="(data, index) in multipleFiles" :key="data.id" :class="{ 'hide-more': index > defaultRowsNumber }">
              <span class="file-name" :title="data.originalFileName" @click="handleColumnClick('download', data, index)">{{
                substringFileName(data)
              }}</span>
              <i v-if="simple && !readOnly" :title="'删除' + data.originalFileName" class="operation-icon el-icon-error" @click="handleColumnClick('delete', data, index)"></i>
            </p>
            <span class="more" @click="extendHandler(multipleFiles)">{{
              multipleFiles.length > 3 && !isShowMore ? "展开更多" : isShowMore ? "收起更多" : ""
            }}</span>
          </div>
          <div v-else class="simple-table-file"></div>
        </template>
        <div v-if="uploadOneFile && multipleFiles && multipleFiles.length > 0" class="one-file-style">
          <template v-for="item in multipleFiles">
            <div :key="item.id" class="file-item upload-table">
              <div><i class="icon" :class="item.suffix"></i>{{ item.name }}.{{ item.suffix }}</div>
              <div v-if="showFileDate">
                {{ dsf.date.format(item.uploadDate, "yyyy-mm-dd hh:ii") }}
              </div>
              <div v-if="showFileSize">
                {{ computeFileSize(item.size) }}
              </div>
              <div>
                <i class="operation-icon el-icon-view" title="预览" @click="handleColumnClick('view', item, 0)"></i>
                <i class="operation-icon el-icon-download" title="下载" @click="handleColumnClick('download', item, 0)"></i>
                <i v-if="!readOnly" class="operation-icon el-icon-delete" title="删除" @click="handleColumnClick('delete', item, 0)"></i>
                <i v-if="showOnlineEdit(item)" class="operation-icon el-icon-edit" title="在线编辑" @click="handleColumnClick('edit', item, 0)"></i>
                <el-upload
                  v-if="!readOnly"
                  class="list-upload"
                  :accept="acceptMime"
                  :headers="headersParam"
                  :limit="parseInt(limitNumber())"
                  :action="setRequestUrl"
                  :show-file-list="false"
                  :before-upload="beforeUpload"
                  :on-success="handleSuccess"
                  :on-error="handleError"
                  :on-exceed="handleExceed"
                  :auto-upload="autoUpload">
                  <i title="上传" class="operation-icon el-icon-upload2" @click="handleColumnClick('upload2', item, 0)"></i>
                </el-upload>
                <i v-if="item.suffix.toLowerCase() === 'mp4'" title="切片" :data-success="isCutSuccess" class="operation-icon el-icon-video-camera-solid" @click="cutFile(item)"></i>
              </div>
            </div>
          </template>
        </div>
        <el-table v-if="multipleFiles && multipleFiles.length > 0 && !uploadOneFile && !simple" class="upload-table" :data="multipleFiles" :show-header="false" :empty-text="'暂无选择'">
          <el-table-column type="index" width="40"></el-table-column>
          <el-table-column>
            <template slot-scope="scope">
              <div :class="{ 'view-name': clickPreview }" @click="clickPreview && handleColumnClick('view', scope.row, scope.$index)">
                <i class="icon" :class="scope.row.suffix"></i>{{ scope.row.name }}.{{ scope.row.suffix }}
              </div>
            </template>
          </el-table-column>
          <el-table-column width="160" v-if="showFileDate">
            <template slot-scope="scope">
              {{ dsf.date.format(scope.row.uploadDate, "yyyy.mm.dd") }}
            </template>
          </el-table-column>
          <el-table-column width="100" v-if="showFileSize">
            <template slot-scope="scope">
              {{ computeFileSize(scope.row.size) }}
            </template>
          </el-table-column>
          <el-table-column v-if="!readOnly" label="操作" width="260" align="right">
            <template slot-scope="scope">
              <div class="handle-table">
                <span v-for="(item, key) of handleList" :key="key" style="flex: 0">
                  <i v-if="key !== 'upload2' && key !== 'video' && (key !== 'edit' || showOnlineEdit(scope.row))" :title="item" :class="['operation-icon', `el-icon-${key}`]" @click="handleColumnClick(key, scope.row, scope.$index)"></i>
                  <el-upload
                    v-if="key === 'upload2'"
                    class="list-upload"
                    :accept="acceptMime"
                    :headers="headersParam"
                    :limit="parseInt(limitNumber())"
                    :action="setRequestUrl"
                    :show-file-list="false"
                    :before-upload="beforeUpload"
                    :on-success="handleSuccess"
                    :on-error="handleError"
                    :on-exceed="handleExceed"
                    :auto-upload="autoUpload"
                    style="width: auto">
                    <i :title="item" class="operation-icon el-icon-upload2" @click="handleColumnClick(key, scope.row, scope.$index)"></i>
                  </el-upload>
                  <i v-if="key === 'video' && scope.row.suffix.toLowerCase() === 'mp4'" :data-success="isCutSuccess" class="operation-icon el-icon-video-camera-solid" :title="item" @click="cutFile(scope.row)"></i>
                </span>
              </div>
            </template>
          </el-table-column>
          <el-table-column v-else label="操作" width="90" align="left">
            <template slot-scope="scope">
              <i class="operation-icon el-icon-view" @click="handleColumnClick('view', scope.row, scope.$index)"></i>
              <i class="operation-icon el-icon-download" @click="handleColumnClick('download', scope.row, scope.$index)"></i>
            </template>
          </el-table-column>
        </el-table>
        <div v-if="errors.length > 0 && multipleFiles.length <= 0" class="ds-error-text">
          {{ errorsMsg }}
        </div>
      </div>
    </template>
    <el-dialog title="修改文件名称" :visible.sync="editFileName" :close-on-click-modal="false">
      <el-form :model="form">
        <el-form-item label="请输入新的名称" label-width="120px">
          <el-input v-model="form.name" autocomplete="off" maxlength="30"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <dsf-button @click="editFileName = false">取 消</dsf-button>
        <dsf-button type="primary" @click="editFile"> 确 定 </dsf-button>
      </div>
    </el-dialog>
    <el-image v-if="srcList && srcList.length" ref="fileView" class="file-image-view" :preview-src-list="srcList" />
  </div>
</template>

<script>
const imglist = ["png", "jpg", "jpeg", "bmp", "gif"];
let globalImgs = [];
export default dsf.component({
  name: "DsfUploadFile",
  mixins: [$mixins.formControl],
  ctrlCaption: "文件上传",
  props: {
    simple: {
      type: Boolean,
      default: false
    },
    simpleFileStyle: {
      type: Boolean,
      default: false
    },
    formatter: {
      type: String,
      default: "multipleFiles^originalFileName"
    },
    label: {
      type: String,
      default: "文件上传"
    },
    btnName: {
      type: String,
      default: "文件上传"
    },
    showBtnName: {
      type: Boolean,
      default: true
    },
    multiple: {
      type: Boolean,
      default: true
    },
    uploadFileNum: {
      type: [Number, String],
      default: 5
    },
    uploadOneFile: {
      type: Boolean,
      default: false
    },
    maxFileSize: {
      type: String,
      default: ""
    },
    zipFileName: {
      type: String,
      default: ""
    },
    required: {
      type: Boolean,
      default: false
    },
    showProgress: {
      type: Boolean,
      default: false
    },
    strokeWidth: {
      type: [Number, String],
      default: 20
    },
    customColor: {
      type: String,
      default: ""
    },
    isDragable: {
      type: Boolean,
      default: false
    },
    requestUrl: {
      type: String,
      default: ""
    },
    extsSetting: {
      type: Object,
      default() {
        return {};
      }
    },
    metadata: {
      type: Object,
      default() {
        return dsf.metadata.get("file-meta-data");
      }
    },
    hideBtns: {
      type: Boolean,
      default() {
        return false;
      }
    },
    files: {
      type: Array,
      default() {
        return [];
      }
    },
    clickPreview: {
      type: Boolean, // 文件名可以点击预览
      default: false
    },
    canOnlineEdit: {
      type: Boolean, //是否开启在线编辑
      default: false
    },
    showRevisionMode: {
      type: Boolean,
      default: false, //在线编辑是否开启留痕
    },
    showFileDate: {
      type: Boolean,
      default: true, //是否显示文件日期
    },
    showFileSize: {
      type: Boolean,
      default: true, //是否显示文件大小
    }
  },
  data() {
    return {
      loading: false,
      autoUpload: true,
      isShowDownloadBtn: false, // 是否显示下载按钮
      // multipleFiles: [], // 上传文件数组
      uploadFileType: "file", //默认上传类型
      uploadFileExts: "", //允许上传后缀名
      acceptMime: "", // 弹窗筛选条件MiMe类型
      headersParam: {
        "X-XSRF-TOKEN": dsf.getCookie("XSRF-TOKEN") || "",
        authorization_token: dsf.getToken()
      },
      _flowUploadClass: "ds_disposeIdea_uploadFile",
      handleList: {
        top: "上移",
        bottom: "下移",
        view: "预览",
        download: "下载",
        delete: "删除",
        upload2: "重新上传",
        // edit: "修改名称",
        video: "视频切片",
        edit: "在线编辑"
      },
      isCutSuccess: false, // 视频切片
      editFileName: false,
      form: {
        name: "",
        index: 0
      },
      uploadFile: {
        status: false, // 当前为重新上传
        index: 0 // 当前索引
      },
      defaultRowsNumber: 2, // 默认显示2条数据
      isShowMore: false,
      percentage: 0,
      canOnlineEditOfSuffix: ["xls", "xlsx", "doc", "docx"],
      srcList: []
    };
  },
  computed: {
    setRequestUrl() {
      return dsf.url.getWebPath(this.requestUrl || "file/upload/");
    },
    multipleFiles: {
      get() {
        let files = [];
        if (this.value !== "" && dsf.isDef(this.value) && typeof this.value === "string") {
          files = JSON.parse(this.value);
        } else if (this.files !== "" && dsf.isDef(this.files) && (typeof this.files === "string" || _.isArray(this.files))) {
          files = this.files;
        }
        return files;
      },
      set(v) {
        this.emitValueChange(JSON.stringify(v));
      }
    }
  },
  created() {
    this.rebuildDialogData();
    globalImgs.push(this);
  },
  beforeDestroy() {
    dsf.array.remove(globalImgs, this);
  },
  methods: {
    limitNumber() {
      if (this.uploadOneFile) {
        this.multiple = false;
        this.uploadFileNum = 100;
        this.btnName = "请点击上传文件";
        return 100;
      } else {
        this.multiple = true;
        return this.multiple ? this.uploadFileNum : 1;
      }
    },
    beforeUpload(file) {
      // 如果当前上传文件数量等于已上传数量
      if (this.uploadFileNum == this.multipleFiles.length && !this.uploadFile.status) {
        dsf.layer.message(`当前限制选择最多 ${this.uploadFileNum} 个文件`, false);
        return false;
      }
      // 判断文件格式
      if (this.uploadFileExts) {
        let dotIndex = file.name.lastIndexOf(".");
        let testmsg = "";
        if (dotIndex > 0) {
          testmsg = file.name.substring(dotIndex).toLowerCase();
        }
        let arr = _.filter(this?.uploadFileExts?.split?.(",") || [], (it) => it);
        //兼容一下出现了没有.开头的文件后缀名
        arr = _.map(arr, (it) => {
          if (!it.startsWith(".")) {
            return "." + it;
          }
          return it;
        });
        if (!~arr.indexOf(testmsg)) {
          dsf.layer.message(`选择文件中包含不支持的文件格式,请选择包含这些格式的文件：${this.uploadFileExts}`, false);
          return false;
        }
      }
      // 判断文件大小
      let maxSize = this.maxFileSize ? this.getFileSize(this.maxFileSize) : "";
      let isMaxSize = maxSize ? file.size / 1024 < maxSize : true;
      if (!isMaxSize) {
        dsf.layer.message(`文件大小不能超过${this.maxFileSize}`, false);
        return false;
      }
      // 是否有重名
      if (_.some(this.multipleFiles, ["originalFileName", file.name])) {
        setTimeout(() => {
          dsf.layer.message(`已存在文件【${file.name}】，请修改后重新上传`, false);
        }, 0);
        return false;
      }
    },
    handleProgress(response, file) {
      this.loading = true;
      // console.log("进度条", file.percentage.toFixed(0));
      this.percentage = parseFloat(file.percentage.toFixed(0));
    },
    //上传成功
    handleSuccess(response, file, fileList) {
      this.loading = false;
      if (response.success && response.state == 20000) {
        let files = response.data[0];
        // 添加切片按钮
        if (files.suffix.toLowerCase() == "mp4") {
          this.isCutSuccess = this.videoIsCut(files); //后台是否切片成功
          this.handleList.video = this.isCutSuccess ? "切片成功" : "切片";
        }
        // 如果是单文件上传 也是替换当前文件
        // if(this.uploadOneFile){
        //   this.multipleFiles.splice(0, 1, files);
        //   this.emitValueChange(JSON.stringify(this.multipleFiles));
        // }else {
        // 重新上传 替换当前文件
        if (this.uploadFile.status) {
          this.multipleFiles.splice(this.uploadFile.index, 1, files);
          this.uploadFile.status = false;
          this.emitValueChange(JSON.stringify(this.multipleFiles));
        } else {
          if (this.uploadFileNum == this.multipleFiles.length) {
            dsf.layer.message(`当前限制选择最多 ${this.uploadFileNum} 个文件`, false);
            this.multipleFiles.slice(0, this.uploadFileNum);
          } else {
            this.multipleFiles.push(...response.data);
          }
          this.emitValueChange(JSON.stringify(this.multipleFiles));
        }
        this.percentage = 100;
        // 上传完成对外抛出当前数据
        this.$nextTick(() => {
          this.$dispatch("upload-file-complete", this.multipleFiles);
        });
        // }
      } else {
        // 如是单个文件  并且上传了疑似非安全文件类型
        if (response.state == 20104 && this.uploadFileNum == 1) {
          this.clearAll();
        } else {
          var index = this.multipleFiles.length;
          // 删除重新再上传？ this.$refs.upload
          this.$refs.upload?.uploadFiles.splice(index, 1);
          // // 删除行
          this.multipleFiles.splice(index, 1);
          this.emitValueChange(JSON.stringify(this.multipleFiles));
          this.extendHandler(this.multipleFiles);
        }
        dsf.layer.message(response.message, false);
      }
    },
    //上传失败
    handleError(err, file, fileList) {
      this.loading = false;
      let errData = JSON.parse(JSON.stringify(err));
      if (errData.status == "404") {
        dsf.layer.message("服务器异常", false);
      } else {
        dsf.layer.message("上传异常", false);
      }
      this.percentage = 0;
    },
    handleExceed(files, fileList) {
      // 同时最多只能上传数量只能为
      dsf.layer.message(`当前限制选择最多 ${this.uploadFileNum} 个文件`, false);
    },
    //获取文件大小
    getFileSize(data) {
      let fileSize = parseInt(data);
      if (data.indexOf("M") > -1 || data.indexOf("m") > -1) {
        fileSize = parseInt(data) * 1024;
      }
      return fileSize;
    },
    handleColumnClick(type, row, index) {
      // console.log("row", row, index);
      const handler = {
        top: () => {
          if (index !== 0) {
            let upDate = this.multipleFiles[index - 1];
            this.multipleFiles.splice(index - 1, 1);
            this.multipleFiles.splice(index, 0, upDate);
            this.emitValueChange(JSON.stringify(this.multipleFiles));
          }
        },
        bottom: () => {
          if (index + 1 !== this.multipleFiles.length) {
            let downDate = this.multipleFiles[index + 1];
            this.multipleFiles.splice(index + 1, 1);
            this.multipleFiles.splice(index, 0, downDate);
            this.emitValueChange(JSON.stringify(this.multipleFiles));
          }
        },
        view: () => {
          // let root = dsf.url.getWebPath("preview/file");
          const preUrl = dsf.config.setting_public_file_host;
          const relativePath = row.relativePath;
          let url = `/preview/file?url=${preUrl}${relativePath}`;
          if (this.$listeners["file-view"]) {
            this.$dispatch("file-view", row);
          } else {
            // 进行图片匹配
            let result = imglist.find((item) => item === row.suffix.toLocaleLowerCase());
            if (result && globalImgs.length) {
              this.imagesView(relativePath);
            } else {
              this.$openWindow({
                url: url,
                name: row.id
              });
            }
          }
        },
        delete: () => {
          dsf.layer
            .confirm(`确定要删除【${row.originalFileName}】文件吗?`)
            .then(() => {
              // 删除重新再上传？ this.$refs.upload
              this.$refs.upload?.uploadFiles.splice(index, 1);
              // 删除行
              this.multipleFiles.splice(index, 1);
              this.emitValueChange(JSON.stringify(this.multipleFiles));
              this.extendHandler(this.multipleFiles);
              // 上传完成对外抛出当前数据
              this.$nextTick(() => {
                this.$dispatch("upload-file-complete", this.multipleFiles);
              });
              if (!this.multipleFiles.length) {
                this.percentage = 0;
              }
            })
            .catch((e) => e);
        },
        download: () => {
          if (this.$listeners["file-download"]) {
            this.$dispatch("file-download", row);
          } else {
            let url = dsf.url.getWebPath("/file/download");
            $(
              '<form action="' +
                url +
                '" method="get">' + // action请求路径及推送方法
                '<input type="text" name="files" value="' +
                row.id +
                '"/>' + // id^fileName
                '<input type="text" name="zipName" value="' +
                (row.fileName || "") +
                '"/>' + // 压缩包名称
                "</form>"
            )
              .appendTo("body")
              .submit()
              .remove();
          }
        },
        upload2: () => {
          this.uploadFile = {
            status: true, // 当前为重新上传
            index: index // 当前索引
          };
        },
        // edit: () => {
        //   // 编辑名称方法-现在只对class为._flowUploadClass的上传文件开放
        //   this.editFileName = true;
        //   this.form = {
        //     name: row.name,
        //     index: index,
        //   };
        // },
        edit: () => {
          this.wpsOnlineEdit(row);
        }
      };
      handler[type] && handler[type]();
    },
    imagesView(relativePath) {
      let result = null;
      let selectImages = [];
      _.forEach(globalImgs, (file) => {
        if (file.multipleFiles.length) {
          _.forEach(file.multipleFiles, (v) => {
            result = imglist.find((item) => item === v.suffix.toLocaleLowerCase());
            if (result) {
              selectImages.push(dsf.url.getWebPath(v.relativePath));
            }
          });
        }
      });
      let index = _.indexOf(selectImages, relativePath);
      if (index > 0) {
        let first = selectImages.splice(index);
        selectImages = _.concat(first, selectImages);
      }
      this.srcList = selectImages;
      this.$nextTick(() => {
        this.$refs.fileView.showViewer = true;
      });
    },
    editFile() {
      if (this.form.name) {
        let name = _.trim(this.form.name),
          files = this.multipleFiles[this.form.index];
        files.name = name;
        files.originalFileName = name + "." + files.suffix;
        this.emitValueChange(JSON.stringify(this.multipleFiles));
        this.editFileName = false;
      } else {
        dsf.layer.message("名称不能为空", false);
      }
    },
    computeFileSize(fileSize) {
      let filesizeUnit = "";
      //1048576
      if (parseInt(fileSize) > 1050000) {
        filesizeUnit = (fileSize / Math.pow(1024, 2)).toFixed(2) + "M";
      } else {
        filesizeUnit = (fileSize / Math.pow(1024, 1)).toFixed(2) + "K";
      }
      return filesizeUnit;
    },
    // 判断上传的视频是否切片
    videoIsCut(data) {
      // let url = dsf.url.getWebPath("slice/video");
      let url = `/slice/video/${data.id}`;
      let isCut = null;
      if (this.$listeners["video-cut"]) {
        this.$dispatch("video-cut", data);
      } else {
        this.$http
          .get(url)
          .done((result) => {
            if (result.success) {
              // 获取标清切片文件
              let sdName = result.data.sd.name;
              let suffixIdx = sdName.lastIndexOf(".");
              let suffix = sdName.substring(suffixIdx + 1); //后缀
              if (suffix.toLowerCase() != "m3u8") {
                //不等于m3u8,则表示后台自动切片失败
                isCut = false;
              } else {
                isCut = true;
              }
            } else {
              dsf.layer.message("处理mp4文件" + result.message, false);
            }
            return isCut;
          })
          .error((res) => {
            dsf.layer.message(res.message, false);
          })
          .always(() => {
            return isCut;
          });
      }
    },
    // 视频切片功能
    cutFile(data) {
      // let url = dsf.url.getWebPath("slice/manual/video");
      let url = `/slice/manual/video/${data.id}`;
      if (this.$listeners["cut-file"]) {
        this.$dispatch("cut-file", data);
      } else {
        this.$http
          .get(url)
          .done((res) => {
            if (res.success) {
              this.isCutSuccess = true;
              dsf.layer.message("视频切片发起成功！");
              // el-icon-video-camera
            } else {
              dsf.layer.message("视频切片失败，请稍后再试", false);
            }
          })
          .error(() => {})
          .always(() => {});
      }
    },
    downLoadFn() {
      if (this.$listeners["file-all-download"]) {
        this.$dispatch("file-all-download", this);
      } else {
        let fileIds = [];
        let paramID = "";
        for (let i = 0; i < this.multipleFiles.length; i++) {
          let id = this.multipleFiles[i].id;
          let name = this.multipleFiles[i].name;
          let file = id + "^" + name;
          fileIds.push(file);
        }
        paramID = fileIds.join(",");
        let url = dsf.url.getWebPath("file/download");
        $(
          "<form action=" +
            url +
            ' method="get">' + // action请求路径及推送方法
            '<input type="text" name="files" value="' +
            paramID +
            '"/>' + // 文件id
            '<input type="text" name="zipName" value="' +
            this.zipFileName +
            '"/>' + // 文件名称
            "</form>"
        )
          .appendTo("body")
          .submit()
          .remove();
      }
    },
    rebuildDialogData() {
      if (this.extsSetting.extStr) {
        let obj = this.extsSetting;
        // this.uploadFileType = 'file';//默认上传类型
        // this.uploadFileExts=""//允许上传后缀名
        // this.acceptMime =""//弹窗筛选条件MiMe类型
        this.uploadFileType = obj.fileType;
        this.isLinkeMine = obj.isLinkeMine;
        this.uploadFileExts = obj.extStr;
        this.acceptMime = obj.mineStr;
        // console.log("this.uploadFileExts", this.uploadFileExts);
        // console.log("this.acceptMime", this.acceptMime);
      }
    },
    openSelectFile() {
      this.$refs.upload.$refs["upload-inner"].handleClick();
    },
    substringFileName(data) {
      if (data.name.length > 10) {
        return data.name.substring(0, 10) + "..." + "." + data.suffix;
      } else {
        return data.name + "." + data.suffix;
      }
    },
    extendHandler(files) {
      if (files.length > 3 && !this.isShowMore) {
        this.defaultRowsNumber = files.length + 1;
        this.isShowMore = true;
      } else {
        this.isShowMore = false;
        this.defaultRowsNumber = 2;
      }
    },
    clearAll() {
      this.$refs.upload?.clearFiles();
      this.multipleFiles.splice(0);
      this.emitValueChange(JSON.stringify(this.multipleFiles));
      this.extendHandler(this.multipleFiles);
    },
    showOnlineEdit(file) {
      if (!this.canOnlineEdit) {
        return false;
      }
      if (this.readonly) {
        return false;
      }
      if (this.canOnlineEditOfSuffix.indexOf(file.suffix) > 0) {
        return true;
      }
      return false;
    },
    wpsOnlineEdit(file) {
      if (window.openOffice) {
        this.onlineEdit(file);
      } else {
        const plugins = [
          dsf.url.getWebPath("$/js/libs/wpsOnlineEdit/static/js/json2.js"),
          dsf.url.getWebPath("$/js/libs/wpsOnlineEdit/static/js/wps_sdk.js"),
          dsf.url.getWebPath("$/js/libs/wpsOnlineEdit/static/js/wpsjsrpcsdk.js"),
          dsf.url.getWebPath("$/js/libs/wpsOnlineEdit/static/js/wps-new.js"),
          dsf.url.getWebPath("$/js/libs/wpsOnlineEdit/static/js/office-edit-wps.js")
        ];
        this.$http
          .importFiles(plugins)
          .then(() => {
            this.onlineEdit(file);
          })
          .catch((err) => {
            dsf.$message("加载在线编辑插件出错", false);
          });
      }
    },
    getWebPath(url) {
      let location = window.location,
        origin = location.origin;
      if (!origin) {
        origin = location.protocol + "//" + location.hostname + (location.port ? ":" + location.port : "");
      }
      return origin + "/" + url;
    },
    onlineEdit(file) {
      if (window.openOffice) {
        // https://open.wps.cn/docs/client/wpsLoad
        let type = ["xls", "xlsx"].includes(file.suffix) ? "excel" : "word",
          fileId = file.id,
          fileName = file.name,
          username = dsf.cookies.get("user_name"),
          userId = dsf.cookies.get("userId") || dsf.uuid(),
          showRevisionMode = this.showRevisionMode;
        let data = {
          userName: username, //用户名
          userId: userId,
          saveAsFileName: fileName, //附件另存为名称
          newFileName: fileName,
          realFileName: fileName,
          fileName: this.getWebPath("file/download?files=" + fileId), //文件下载地址
          closeUrl: this.getWebPath("office/closeOffice"), //文件关闭回调地址
          uploadPath: this.getWebPath("office/saveOffice?fileId=" + fileId), //文件上传地址
          printWatermark: false,
          buttonGroups: "",
          docId: fileId,
          disableBtns: "ExportToPDF,FileSaveAsPicture,SaveAsPicture,ReviewTrackChangesMenu,FileMenuSendMail,ExportToOFD,FileNew,FileOpen",
          funcs: [
            {
              OnOpenRevisionClickedFromWeb: {
                bShowRevision: showRevisionMode, //是否显示痕迹
                bOpenRevision: showRevisionMode //是否打开留痕
              }
            },
            {
              OnClearRevDocClicked: {
                bShowRevision: showRevisionMode, //是否显示痕迹
                bOpenRevision: showRevisionMode //是否打开留痕
              }
            },
            {
              setOpenType: {
                password: "123**",
                readonly: 2, //1:只读不能编辑 2：只读能编辑
                protectType: 3,
                showRevisionMode: showRevisionMode
              }
            }
          ],
          isOA: 1
        };
        openOffice(data, type);
      }
    }
  },
  watch: {
    extsSetting(to) {
      this.rebuildDialogData();
    }
  }
});
</script>
