var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ds-control ds-home-banner" },
    [
      _vm.showList.length > 1
        ? _c("div", { staticClass: "arrow-left" }, [
            _c("i", {
              staticClass: "iconfont icon-fanyezuobeifen8 arrow",
              on: { click: _vm.prev },
            }),
          ])
        : _vm._e(),
      _c(
        "el-carousel",
        {
          ref: "carousel",
          staticClass: "carousel",
          attrs: {
            height: _vm.h,
            loop: true,
            arrow: "never",
            "indicator-position": "none",
            autoplay: false,
          },
        },
        _vm._l(_vm.showList, function (item, i) {
          return _c("el-carousel-item", { key: i }, [
            _c(
              "div",
              { staticClass: "carousel-list" },
              _vm._l(item, function (_item) {
                return _c("div", { key: _item.text }, [
                  _c("p", [
                    _c("i", {
                      staticClass: "iconfont",
                      class: _item.icon,
                      staticStyle: { "font-size": "30px" },
                    }),
                  ]),
                  _c("p", [
                    _c("span", [_vm._v(_vm._s(_item.text))]),
                    _c("span", [_vm._v("--")]),
                  ]),
                ])
              }),
              0
            ),
          ])
        }),
        1
      ),
      _vm.showList.length > 1
        ? _c("div", { staticClass: "arrow-right" }, [
            _c("i", {
              staticClass: "iconfont icon-fanyezuobeifen5 arrow",
              on: { click: _vm.next },
            }),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }