var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.item.isHide
    ? _c(
        "div",
        {
          staticClass: "ds-nav-catalogue-item",
          class: { choose: !_vm.item._isHide },
          attrs: { level: _vm.item.level },
        },
        [
          _c(
            "div",
            {
              staticClass: "label",
              class: { required: _vm.item.required },
              attrs: {
                active:
                  _vm.catalogueRoot.active === _vm.item ||
                  _vm.catalogueRoot.rootNode === _vm.item,
                title: _vm.item.required ? "必选项" : "",
              },
              on: {
                click: function ($event) {
                  return _vm.catalogueRoot.hideClick(_vm.item)
                },
              },
            },
            [
              _c("span", { staticClass: "ellipsis" }, [
                _vm._v(_vm._s(_vm.item["_name"])),
              ]),
              _c("i", {
                staticClass: "iconfont icon-order",
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                  },
                },
              }),
            ]
          ),
          _vm.item["children"] && _vm.item["children"].length
            ? _c(
                "div",
                { staticClass: "children" },
                [
                  _c(
                    "draggable",
                    _vm._b(
                      {
                        staticClass: "dragArea",
                        model: {
                          value: _vm.item["children"],
                          callback: function ($$v) {
                            _vm.$set(_vm.item, "children", $$v)
                          },
                          expression: "item['children']",
                        },
                      },
                      "draggable",
                      _vm.catalogueRoot.dragOptions,
                      false
                    ),
                    _vm._l(_vm.item["children"], function (item2) {
                      return _c("nav-catalogue-item-for-editor", {
                        key: item2._id,
                        attrs: { item: item2 },
                      })
                    }),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }