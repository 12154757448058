<template functional>
  <component :is="_vm.parent.innerHeightAuto ? 'div' : 'DsfVirtualScroll'" class="dsf-convenient-menu-group">
    <div v-for="(row, index) in props.group" :key="index" class="dsf-convenient-menu-row">
      <div v-for="(item, index2) in row" :key="item._id" class="dsf-convenient-menu-col" :style="_vm.parent.colStyle">
        <div v-if="!item._hide" class="dsf-convenient-menu-box" :style="_vm.parent.boxStyle" @click="_vm.parent._clickBefore(_vm.parent.onClick, item)">
          <slot :item="item" :row-index="index" :col-index="index2" />
        </div>
      </div>
    </div>
  </component>
</template>

<script>
export default {
  name: "DsfConvenientMenuGroup",
  props: {
    group: {
      type: Array,
      default() {
        return []
      }
    }
  }
}
</script>