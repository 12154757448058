<template>
  <div class="ds-page-home" :style="{'min-width': minWidth}">
    <div v-if="hasHeader" :style="{height: position.top}" class="ds-page-home-header">
      <slot name="header"></slot>
    </div>
    <div class="ds-page-home-body">
      <div v-if="hasLeft" :style="{width: position.left}" class="ds-page-home-left">
        <slot name="left"></slot>
      </div>
      <div class="ds-page-home-main">
        <div v-if="hasBreadcrumb" :style="{height: position.breadcrumb}" class="ds-page-home-breadcrumb">
          <slot name="breadcrumb"></slot>
        </div>
        <!-- <dsf-virtual-scroll v-if="viewLoaded" class="ds-page-home-view" scroll-y scroll-x>
          <slot></slot>
        </dsf-virtual-scroll> -->
        <div v-if="viewLoaded" class="ds-page-home-view">
          <slot></slot>
        </div>
      </div>
      <div v-if="hasRight" :style="{width: position.right}" class="ds-page-home-right">
        <slot name="right"></slot>
      </div>
    </div>
    <div v-if="hasFooter" :style="{height: position.bottom}" class="ds-page-home-footer">
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import menuUtils from "./utils";

const locationPath = location.href.replace(location.hash, '');

export default {
  name: "DsfHome",
  provide() {
    return {
      homeRoot: this
    };
  },
  props: {
    menuList: {
      type: Array,
      default() {
        return [];
      }
    },
    minWidth: {
      type: String,
      default: 'auto'
    },
    // 角标样式。
    // text 显示文字; dot 显示点; 其他 隐藏图片；
    badgeStyle: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      hasHeader: false,
      hasLeft: false,
      hasRight: false,
      hasFooter: false,
      hasBreadcrumb: false,
      position: {
        top: null,
        left: null,
        right: null,
        bottom: null,
        breadcrumb: null
      },
      menuAction: [],
      menuIndex: [],
      menuKey: [],
      // 视图载入状态
      viewLoaded: true,
      // 是否对菜单包裹一层虚拟节点
      // 对于默认导航栏有用
      isEncircleMenu: false
    };
  },
  computed: {
    ...mapState("ws", ["menuSubscript"]),
    $menuList() {
      return this.isEncircleMenu ? [{
        menu_id: 'root',
        menu_name: '',
        menu_icon: '',
        children: this.menuList
      }] : this.menuList;
    }
  },
  watch: {
    $route() {
      this.initMenu();
    },
    'menuList.length'(to, from) {
      if (!from && to) {
        this.initMenu();
      }
    },
    $menuList(to, from) {
      if (!from.length && (!window.__multiPageVm__ || this.menuKey.join("") === "")) {
        this.initMenu();
      }
    }
  },
  created() {
    this.hasHeader = !!this.$slots.header;
    this.hasLeft = !!this.$slots.left;
    this.hasRight = !!this.$slots.right;
    this.hasFooter = !!this.$slots.footer;
    this.hasBreadcrumb = !!this.$slots.breadcrumb;
    if (!window.__multiPageVm__ && location.hash == '#' + this.$route.fullPath) {
      this.initMenu();
    }
  },
  updated() {
    this.hasHeader = !!this.$slots.header;
    this.hasLeft = !!this.$slots.left;
    this.hasRight = !!this.$slots.right;
    this.hasFooter = !!this.$slots.footer;
    this.hasBreadcrumb = !!this.$slots.breadcrumb;
    if (!this.hasHeader) this.setPosition("top", null);
    if (!this.hasLeft) this.setPosition("left", null);
    if (!this.hasRight) this.setPosition("right", null);
    if (!this.hasFooter) this.setPosition("bottom", null);
    if (!this.hasBreadcrumb) this.setPosition("breadcrumb", null);
  },
  methods: {
    setPosition(key, value) {
      this.$set(this.position, key, value);
    },
    initMenu(url) {
      if (!url) {
        url = location.href;
      } else if (/^#/.test(url)) {
        url = locationPath + url;
      }
      let currentUrl = dsf.url.parse(url);
      let menuAction = [];
      let menuIndex = [];
      let menuKey = [];
      this.filterMenu(menuAction, menuIndex, menuKey, this.$menuList, currentUrl, 0);
      if (menuKey.join("") !== this.menuKey.join("")) {
        this.menuAction = menuAction;
        this.menuIndex = menuIndex;
        this.menuKey = menuKey;
      } else if (this.isEncircleMenu && currentUrl.hash !== '#/') {
        this.menuAction = menuAction = [this.$menuList[0], {
          menu_id: dsf.uuid(),
          menu_name: '',
          menu_url: './page.html' + currentUrl.hash
        }];
        this.menuIndex = menuIndex = [0];
        this.menuKey = menuKey = ['root'];
      }
      // 如果检测到当前为空路由，即currentUrl.hash等于'#/'
      // 则跳转到第一个菜单去
      if (menuKey.join("") === "" && currentUrl.hash === "#/" && this.$menuList.length) {
        menuUtils.openPage.call(this, menuUtils.findMenu(this.$menuList), true);
      }
    },
    /**
     * 判断当前选中的菜单
     * @param menuAction
     * @param menuIndex
     * @param menuKey
     * @param menuList  菜单列表
     * @param currentUrl     currentUrl
     * @param n         递归层级
     */
    filterMenu(menuAction, menuIndex, menuKey, menuList, currentUrl, n) {
      menuAction[n] = {};
      menuIndex[n] = -1;
      menuKey[n] = "";
      for (let i = 0; i < menuList.length; i++) {
        const item = menuList[i];
        if ((item.param || item.menu_url) && menuUtils.urlsIsSame(item, currentUrl)) {
          menuAction[n] = item;
          menuIndex[n] = i;
          menuKey[n] = item.menu_id;
          if (!item.children || !item.children.length) {
            break;
          }
        }
        if (item.children && item.children.length) {
          this.filterMenu(menuAction, menuIndex, menuKey, item.children, currentUrl, n + 1);
          if (menuIndex[n + 1] !== -1) {
            menuAction[n] = item;
            menuIndex[n] = i;
            menuKey[n] = item.menu_id;
            break;
          }
        }
      }
    }
  }
};
</script>