var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isDesign
    ? _c(
        "div",
        {
          staticClass: "ds-control ds-form-item ds-design-hide ds-textbox",
          class: _vm.getCss,
        },
        [
          [
            _vm.showLabel
              ? _c("label", { staticClass: "ds-form-label" }, [
                  _vm._v("流程信息"),
                ])
              : _vm._e(),
            _c(
              "div",
              { staticClass: "ds-form-block" },
              [
                _c("el-input", {
                  attrs: { type: "text", disabled: true },
                  model: {
                    value: _vm.chooseFlow.name,
                    callback: function ($$v) {
                      _vm.$set(_vm.chooseFlow, "name", $$v)
                    },
                    expression: "chooseFlow.name",
                  },
                }),
              ],
              1
            ),
          ],
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }