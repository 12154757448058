<!--
 * @Descripttion: 资源目录操作
 * @Author: zhanghang
 * @Date: 2021-08-03 16:56:48
 * @LastEditors: zhanghang
 * @LastEditTime: 2021-08-23 18:06:23
-->
<template>
  <div class="catalogue-warp">
    <template v-if="step === 1">
      <SelectList
        @close="cancel"
        :selectKeys="selectKeys"
        :operateType="operateType"
        @changeStep="changeStep"
      />
    </template>
    <template v-if="step === 2">
      <OperateList
        @close="cancel"
        :importData="importDataUpdate"
        :targetKeys="targetKeysUpdate"
        @changeStep="changeStep"
      />
    </template>
  </div>
</template>
<script>
import SelectList from "./selectList.vue";
import OperateList from "./operateList.vue";
export default dsf.component({
  name: "DsfCatalogueOptions",
  ctrlCaption: "资源目录操作",
  mixins: [$mixins.control],
  components: { SelectList, OperateList },
  provide () {
    return {
      operateType: this.operateType,
      selectKeys: this.selectKeys,
    }
  },
  computed: {
    _importData(){
      return this.importData
    }
  },
  props: {
    //操作类型 [copy]复制 [move]移动 [export]导出 [import] 导入
    operateType: {
      type: String,
      default: "copy",
    },
    //选中操作的目录
    selectKeys: {
      type: Array,
      default() {
        return [];
      },
    },
    //导入数据
    importData: {
      type: Object,
      default() {
        return {};
      },
    },
    //移动、复制、导入、目标key
    targetKeys: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      importDataUpdate:this.importData,
      targetKeysUpdate:this.targetKeys,
      step: this.operateType !== "import" ? 1 : 2,
    };
  },
  watch: {
    step(v) {
      switch (v) {
      case 1:
        if (this.operateType === "copy") this.alterTitle("选择复制内容");
        if (this.operateType === "move") this.alterTitle("选择移动内容");
        break;
      case 2:
        if (this.operateType === "copy" || this.operateType === "move")
          this.alterTitle("已选数据");
        if (this.operateType === "export") this.alterTitle("导出");
        if (this.operateType === "import") this.alterTitle("导入");
        break;
      }
    },
  },
  methods: {

    alterTitle(name) {
      if (this.$dialog) {
        $(this.$dialog.$el).find(".el-dialog__title").text(name);
      }
    },
    cancel(refresh) {
      this.$dialog?.close();
      if(refresh) this.$dialog?.openerVm?.reloadData()
    },
    changeStep({step,data}) {
      if(step===2){
        this.importDataUpdate = data.importData
        this.targetKeysUpdate = data.targetKeys
      }
      this.$nextTick(()=>{
        this.step = step;
      })
    },
  },
});
</script>
