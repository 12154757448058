<template>
  <div v-if="loadComplete" class="ds-tab-panel-setting">
    <div class="ds-tab-panel-setting-title">
      <div class="ds-tab-panel-setting-title-text">已选标签</div>
      <div class="ds-tab-panel-setting-title-sbu">（长按标签可拖拽排序）</div>
    </div>
    <div class="ds-tab-panel-setting-warp" type="select">
      <draggable :value="selectTabs" v-bind="options" @update="update">
        <template v-for="(tab, i) in selectTabs">
          <el-tag :key="tab.name" closable @close="removeTag(tab, i)">
            {{ tab.title }}
          </el-tag>
        </template>
      </draggable>
    </div>
    <div class="ds-tab-panel-setting-title">
      <div class="ds-tab-panel-setting-title-text">可选标签</div>
      <div class="ds-tab-panel-setting-title-sbu">（都不选时，显示所有）</div>
    </div>
    <div class="ds-tab-panel-setting-warp">
      <el-checkbox-group v-model="selectTabValues">
        <template v-for="tab in tabs">
          <el-checkbox :key="tab.name" :label="tab.name" size="medium" border>
            {{ tab.title }}
          </el-checkbox>
        </template>
      </el-checkbox-group>
    </div>
    <div></div>
  </div>
</template>

<script>
export default {
  name: "DsfTabPanelSetting",
  mixins: [$mixins.tabPanelSetting],
};
</script>
