var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "ds-control ds-form-item ds-date-picker",
      class: _vm.getCss,
    },
    [
      _vm.simple && _vm.readOnly && !_vm.isDesign
        ? [
            _c("span", { attrs: { title: _vm.readOnlyText } }, [
              _vm._v(_vm._s(_vm.readOnlyText)),
            ]),
            _vm._t("error", function () {
              return [
                _vm.errors.length > 0
                  ? _c("div", { staticClass: "ds-error-text" }, [
                      _vm._v(" " + _vm._s(_vm.errorsMsg) + " "),
                    ])
                  : _vm._e(),
              ]
            }),
          ]
        : [
            _vm.showLabel
              ? _c(
                  "DsfFieldLabel",
                  {
                    style: _vm.getLabelWidthStyle(),
                    attrs: {
                      mode: _vm.showDataCaptionMode,
                      "show-icon": _vm.showDataCaptionIcon,
                      trigger: _vm.showDataCaptionTrigger,
                      "data-caption": _vm.dataCapion,
                      "is-design": _vm.isDesign,
                      owner: _vm._self,
                    },
                  },
                  [_vm._v(" " + _vm._s(_vm.$t(_vm.label)) + " ")]
                )
              : _vm._e(),
            _c(
              "div",
              {
                staticClass: "ds-form-block",
                class: { "ds-error-border": _vm.errors.length > 0 },
                style: _vm.getFormItemBlockMarginLeft(),
              },
              [
                !_vm.readOnly
                  ? _c("el-time-picker", {
                      attrs: {
                        disabled: _vm.disabled,
                        editable: false,
                        clearable: _vm.clearable,
                        "range-separator": _vm.rangeSeparator,
                        "value-format": _vm.valueFormat,
                        "picker-options": _vm.pickerOptions,
                        "arrow-control": _vm.arrowControl,
                        "start-placeholder": _vm.startPlaceholder,
                        "end-placeholder": _vm.endPlaceholder,
                        "is-range": "",
                      },
                      on: { change: _vm.changeHandler },
                      model: {
                        value: _vm.timeValue,
                        callback: function ($$v) {
                          _vm.timeValue = $$v
                        },
                        expression: "timeValue",
                      },
                    })
                  : _vm.readOnly
                  ? _c("div", { staticClass: "ds-form-readonly" }, [
                      _c("span", { attrs: { title: _vm.readOnlyText } }, [
                        _vm._v(_vm._s(_vm.readOnlyText)),
                      ]),
                    ])
                  : _vm._e(),
                _vm._t("error", function () {
                  return [
                    _vm.errors.length > 0
                      ? _c("div", { staticClass: "ds-error-text" }, [
                          _vm._v(" " + _vm._s(_vm.errorsMsg) + " "),
                        ])
                      : _vm._e(),
                  ]
                }),
              ],
              2
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }