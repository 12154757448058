<template>
  <div class="ds-control ds-form-item ds-platform-baidu-map" :class="getCss">
    <template v-if="simple && readOnly && !isDesign">
      <DsfTextProxy v-model="value" v-bind="_self.$props" :show-label="false"></DsfTextProxy>
      <slot name="error">
        <div v-if="errors.length>0" class="ds-error-text">{{ errorsMsg }}</div>
      </slot>
    </template>
    <template v-else>
      <!-- <label v-if="showLabel" class="ds-form-label" :style="getLabelWidthStyle()">
        {{ label }}
        <i v-if="dataCapion" class="iconfont icon-bangzhuzhongxin ds-control-helper" @click="showHelper"></i>
      </label> -->
      <DsfFieldLabel
        v-if="showLabel"
        :mode="showDataCaptionMode"
        :style="getLabelWidthStyle()"
        :show-icon="showDataCaptionIcon" 
        :trigger="showDataCaptionTrigger"
        :data-caption="dataCapion"
        :is-design="isDesign"
        :owner="_self">
        {{ $t(label) }}
      </DsfFieldLabel>
      <div class="ds-form-block" :class="{border: border || isButton}" :style="getFormItemBlockMarginLeft()">
        <div v-loading="loading" class="ds-platform-baidu-map-box" :style="{width: width, height: height}">
          <el-input
            v-show="!readOnly"
            v-model="searchText"
            class="ds-platform-baidu-map-search"
            :validate-event="false"
            :disabled="disabled"
            size="mini"
            placeholder="请输入需要搜索的地名"
          >
            <el-button slot="append" type="primary" @click="doSearch()">搜索</el-button>
          </el-input>
          <div v-if="pointValue" v-show="!loading" class="ds-platform-baidu-map-info">
            <div><i class="el-icon-location"/> {{ pointValue.address }}</div>
            <div>纬度：{{ pointValue.lat }}</div>
            <div>经度：{{ pointValue.lng }}</div>
          </div>
          <div ref="bdMapMain" id="bdMapMain" class="ds-platform-baidu-map-main"/>
          <div class="ds-platform-baidu-map-near" v-show="!unedited && (isDesign || nearPois.length)">
            <dsf-virtual-scroll>
              <template v-for="(poi, index) in nearPois">
                <div class="ds-platform-baidu-map-near-poi" :key="index" @click="setPoi(poi)">
                  <div class="ds-platform-baidu-map-near-poi-name"><span>{{index + 1}}</span>{{poi.title}}</div>
                  <div v-if="poi.Yi" class="ds-platform-baidu-map-near-poi-info"><span>类型：</span>{{poi.Yi}}</div>
                  <div class="ds-platform-baidu-map-near-poi-info"><span>地址：</span>{{poi.address}}</div>
                  <div class="ds-platform-baidu-map-near-poi-info"><span>经度：</span>{{poi.point.lng}}</div>
                  <div class="ds-platform-baidu-map-near-poi-info"><span>纬度：</span>{{poi.point.lat}}</div>
                </div>
              </template>
            </dsf-virtual-scroll>
          </div>
        </div>
        <slot name="error">
          <div v-if="errors.length>0" class="ds-error-text">{{ errorsMsg }}</div>
        </slot>
      </div>
    </template>
  </div>
</template>

<script>
export default dsf.component({
  name: 'DsfBaiduMap',
  mixins: [$mixins.formControl],
  ctrlCaption: "百度地图选点",
  props: {
    value: {
      type: Object,
      default: null
    },
    width: {
      type: String,
      default: '500px'
    },
    height: {
      type: String,
      default: '300px'
    },
    // 秘钥
    appkey: {
      type: String,
      default: 'y0bwGfZAfzZnPjfkXDe8FHN7nzb5KHah'
    },
    formatter: {
      type: String,
      default: 'textValue^address'
    },
    metadata: {
      type: Object,
      default() {
        return dsf.metadata.get("map-meta-data");
      },
    }
  },
  data() {
    return {
      loading: false,
      searchText: '',
      nearPois: [],
    }
  },
  computed: {
    pointValue() {
      if (!this.value) return null;
      let { title, address, lng, lat } = this.value;
      if (!title && !address && !lng && !lat) return null;
      return { title, address, lng, lat }
    },
    unedited() {
      return this.readOnly || this.disabled;
    }
  },
  watch: {
    'unedited'(to) {
      to ? this.removeEvent() : this.initEvent();
    },
    'pointValue': {
      handler(to) {
        if (this.map) {
          if (to) {
            let {lng, lat} = to;
            this.map.panTo(new BMap.Point(lng, lat));
            this.updateMaker(to);
          } else {
            this.updateMaker(null);
          }
        }
      },
      deep: true
    }
  },
  mounted () {
    if (!this.isDesign) {
      if (!window.BMap) {
        this.loading = true;
        let wFn = document.write;
        document.write = str => {
          this.$http
            .importFiles([[$(str).get(0).src, '.js']])
            .then(() => {
              this.init();
              this.unedited || this.initEvent();
            })
            .catch((err) => {
              dsf.layer.message("加载百度地图api文件失败", false);
              dsf.error(err);
            });
        }
        this.$http
          .importFiles([[`${window.location.protocol}//api.map.baidu.com/api?v=3.0&ak=${this.appkey}`, '.js']])
          .then(() => {
            document.write = wFn;
          })
          .catch((err) => {
            dsf.layer.message("加载百度地图api文件失败", false);
            dsf.error(err);
          });
      } else {
        this.init();
        this.unedited || this.initEvent();
      }
    }
  },
  methods: {
    // 初始化
    init () {
      // 创建地图实例
      let map = new BMap.Map(this.$refs.bdMapMain, {
        enableMapClick: false
      });
      this.map = map;
      // 初始化地图,设置中心点坐标和地图级别
      if (this.pointValue) {
        let {lng, lat} = this.pointValue;
        map.centerAndZoom(new BMap.Point(lng, lat), 15);
      } else {
        map.centerAndZoom(new BMap.Point(116.404, 39.915), 15);
      }
      // 开启鼠标滚轮缩放
      map.enableScrollWheelZoom(true);
      // 添加比例尺控件
      map.addControl(new BMap.ScaleControl());
      // 添加平移缩放控件
      map.addControl(new BMap.NavigationControl());
      // 添加地图类型控件
      map.addControl(new BMap.MapTypeControl({
        mapTypes: [
          BMAP_NORMAL_MAP,
          BMAP_SATELLITE_MAP
        ]
      }));
      // 创建定位控件
      map.addControl(new BMap.GeolocationControl({
        // 控件的停靠位置: 右下角
        anchor: BMAP_ANCHOR_BOTTOM_RIGHT,
        // 偏移
        offset: new BMap.Size(10, 10),
        // 是否显示定位信息面板
        showAddressBar: false
      }));
      // 定位
      // this.geolocation();
      if (this.pointValue) {
        this.updateMaker(this.pointValue);
      }
      this.loading = false;
    },
    // 地图绑定事件
    initEvent() {
      if (!this.map) return;
      this.map.addEventListener('click', this.mapClick);
      // 搜索服务
      this.LocalSearch = new BMap.LocalSearch(this.map, {
        pageCapacity: 20,
        onSearchComplete: this.searchResult
      });
      // 地址解析服务
      this.Geocoder = new BMap.Geocoder({extensions_town: true});
    },
    // 地图解绑事件
    removeEvent() {
      this.map?.removeEventListener('click', this.mapClick);
      this.LocalSearch = null;
      this.geocoder = null;
    },
    // 调用精准定位
    geolocation() {
      this.loading = true;
      let geolocation = new BMap.Geolocation();
      geolocation.getCurrentPosition(r => {
        if(geolocation.getStatus() == BMAP_STATUS_SUCCESS){
          let mk = new BBMap.Marker(r.point);
          this.map.addOverlay(mk);
          this.map.panTo(r.point);
          alert('您的位置：'+r.point.lng+','+r.point.lat);
        }
        else {
          alert('failed'+this.getStatus());
        }
        this.loading = false;
      });
    },
    // ip模糊定位
    ipLocation () {
      this.loading = true;
      let myCity = new BMap.LocalCity();
      myCity.get(res => {
        this.map.centerAndZoom(
          new BMap.Point(res.center.lng, res.center.lat),
          15
        );
        console.log("当前定位城市:", res);
        this.loading = false;
      });
    },
    // 更新标记
    updateMaker (res) {
      if (!res) {
        this.map.removeOverlay(this.marker);
        this.marker = null;
        return;
      }
      let { lng, lat, title, address } = res;
      let point = new BMap.Point(lng, lat);
      let tips = address;
      if (title) {
        tips = `${title}\n地址：${address}`;
      }
      // 标注
      if (this.marker) {
        this.marker.setPosition(point);
        this.marker.setTitle(tips);
      } else {
        this.marker = new BMap.Marker(point, {
          title: tips
        });
        this.map.addOverlay(this.marker);
      }
    },
    // 地图点击事件
    mapClick ({ point }) {
      this.nearPois = [];
      this.updateValue(null);
      this.point2address(point)
    },
    // 开始搜索
    doSearch() {
      if (this.searchText.length === 0) {
        dsf.layer.message('搜索地址不能为空', false);
        return;
      }
      this.loading = true;
      this.LocalSearch.search(this.searchText);
    },
    // 搜索回调
    searchResult (res) {
      // 判断状态是否正确
      if (this.LocalSearch.getStatus() == BMAP_STATUS_SUCCESS){
        if (res.getCurrentNumPois() === 0) {
          this.nearPois = [];
          if (dsf.type(res.keyword) === 'string') {
            this.updateMaker(null);
            this.updateValue(null);
          }
        } else {
          this.setPoi(res.getPoi(0));
        }
      } else {
        dsf.layer.message('未搜索到地点信息', false);
      }
      this.loading = false;
    },
    // 坐标=>地址
    point2address (point) {
      this.loading = true;
      this.Geocoder.getLocation(point, res => {
        if (res){
          let { address, surroundingPois } = res;
          this.searchText = address;
          this.nearPois = surroundingPois;
          this.setPoi({ address, point });
        } else {
          dsf.layer.message('坐标解析失败', false);
        }
        this.loading = false;
      }, {
        numPois: 30,
        poiRadius: 2000
      });
    },
    // 地址=>坐标
    address2point (city, address) {
      this.Geocoder.getPoint(address, ({ lng, lat }) => {
        if (point) {
          this.updateMaker({ lng, lat, address });
          this.updateValue({ lng, lat, address });
        } else {
          dsf.layer.message('地址解析失败', false);
        }
      }, city);
    },
    // 设置点
    setPoi ({ title = '', address, point: { lng, lat } }) {
      this.map.panTo(new BMap.Point(lng, lat));
      this.updateMaker({ lng, lat, title, address });
      this.updateValue({ title, lng, lat, address });
    },
    updateValue(opt) {
      let value = null;
      if (opt) {
        let { title = '', address, lng, lat } = opt;
        value = {title, address, lng, lat};
        this.emitValueChange(value);
      } else {
        this.updateMaker(null);
        this.emitValueChange(null);
      }
      this.$nextTick(() => {
        this.emitFormValidate(value);
      })
    }
  }
});
</script>