import { render, staticRenderFns } from "./headerUser.vue?vue&type=template&id=1fd0bef6&"
import script from "./headerUser.vue?vue&type=script&lang=js&"
export * from "./headerUser.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

component.options.__file = "pc/home/menu/headerUser.vue"
export default component.exports