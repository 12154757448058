import ContextMenu from './ContextMenu.vue';
let __contextMenu = null;

/**
 * 鼠标右键菜单
 *  v-context-menu="{
 *    // 附加数据
 *    params: 111,
 *    // 菜单列表
 *    data: [
 *      { icon: 'icon-emoji', name: '复制', color: '#d33', disabled: true, hide: false, handler: () => {} },
 *      { name: '复制', disabled: () => true, hide: () => true, handler: () => {} },
 *      { icon: 'icon-emoji', name: '复制', children: [
 *        { icon: 'icon-emoji', name: '复制', handler: () => {} },
 *        { icon: 'icon-emoji', name: '复制', handler: () => {} }
 *      ]}
 *    ]
 * @param icon {String} 图标
 * @param name {String} 文字
 * @param disabled {Boolean | Function} 是否禁用
 * @param hide {Boolean | Function} 是否隐藏
 * @param handler {Function} 单击处理
 * @param children {Array<Object>} 下级菜单
 * @param upload {Object} 上传配置 { action: '/file/upload', data: '', accept: 'xlsx|xls' }
 * @param upload.action {String} 上传地址
 * @param upload.accept {String | Array} 文件类型
 * @param upload.data {Function | String | Object} 额外参数。function时接收参数menu
 * @param upload.beforeHandler {Function} 上传前校验，接收参数1 menu、参数2 file，返回false终止上传
 */
export default {
  install(Vue) {
    Vue.directive("contextMenu", {
      inserted(el, { value: {params, data} }) {
        el.params = params;
        el.data = data;
        el.__menuEvent = function(e) {
          e.stopPropagation();
          e.preventDefault();
          contextMenuClick({
            x: e.clientX,
            y: e.clientY
          }, el.params, el.data, el, e);
          return false;
        }
        el.addEventListener('contextmenu', el.__menuEvent);
      },
      update(el, { value: {params, data} }) {
        el.params = params;
        el.data = data;
      },
      componentUpdated(el, { value: {params, data} }) {
        el.params = params;
        el.data = data;
      },
      unbind(el) {
        el.removeEventListener('contextmenu', el.__menuEvent);
      }
    });
  }
}

export function contextMenuClick(point, params, data, el, e) {
  if (dsf.type(data) !== 'array') {
    return;
  }
  if (!__contextMenu) {
    let Vm = Vue.extend(ContextMenu);
    __contextMenu = new Vm().$mount();
    document.body.appendChild(__contextMenu.$el);
  }
  __contextMenu.open(point, params, data, el, e);
}