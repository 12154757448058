var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "ds-component-info" }, [
    _vm.info.caption
      ? _c("p", [
          _c("label", [_vm._v("控件类型：")]),
          _vm._v(_vm._s(_vm.info.caption)),
        ])
      : _vm._e(),
    _vm.info.id
      ? _c("p", [
          _c("label", [_vm._v("元数据标识：")]),
          _vm._v(_vm._s(_vm.info.id)),
        ])
      : _vm._e(),
    _vm.info.name
      ? _c("p", [
          _c("label", [_vm._v("元数据名称：")]),
          _vm._v(_vm._s(_vm.info.name)),
        ])
      : _vm._e(),
    _vm.info.typeText || _vm.info.typeValue
      ? _c("p", [
          _c("label", [_vm._v("元数据类型：")]),
          _vm._v(
            _vm._s(_vm.info.typeText || "") +
              " | " +
              _vm._s(_vm.info.typeValue || "")
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }