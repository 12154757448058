<template>
  <home-main ref="main" />
</template>

<script>
import HomeMain from "./homeMain.vue";
import utils from "../utils";

export default {
  name: "DsfMultiPageHome",
  components: {
    HomeMain
  },
  data() {
    return {
      chain:[]
    };
  },
  created() {
    window.__multiPageVm__ = this;
  },
  methods: {
    // 点击菜单
    menuItemClick(menu) {
      let item = utils.findMenu(menu);
      if (item) {
        let openType = item.openType || item.opentype;
        switch (openType) {
        // 对话框打开
        case '3':
          dsf.layer.openDialog({
            title: item.menu_name,
            width: '950px',
            height: '540px',
            params: {
              path: url,
            }
          });
          break;
          // 当前窗口打开
        default:
            this.$refs.main?.openTag(item);
        }
      }
    },
    // 打开新标签页
    openPage({name, url, opener, dialogArgs, openerVm, callback}) {
      this.$refs.main?.openTag({
        menu_id: name,
        menu_url: url,
        opener,
        dialogArgs,
        openerVm,
        callback
      });
    },
    // 关闭标签页
    closePage(name) {
      this.$refs.main?.removeById(name);
    },
    addChain(chain){
      this.chain.push(chain)
    }
  },
};
</script>
