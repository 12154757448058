var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ds-control ds-form-item ds-system", class: _vm.getCss },
    [
      _vm.simple && _vm.readOnly && !_vm.isDesign
        ? [
            _vm._v(" " + _vm._s(_vm.inputValue) + " "),
            _vm._t("error", function () {
              return [
                _vm.errors.length > 0
                  ? _c("div", { staticClass: "ds-error-text" }, [
                      _vm._v(_vm._s(_vm.errorsMsg)),
                    ])
                  : _vm._e(),
              ]
            }),
          ]
        : [
            _vm.showLabel
              ? _c(
                  "DsfFieldLabel",
                  {
                    style: _vm.getLabelWidthStyle(),
                    attrs: {
                      mode: _vm.showDataCaptionMode,
                      "show-icon": _vm.showDataCaptionIcon,
                      trigger: _vm.showDataCaptionTrigger,
                      "data-caption": _vm.dataCapion,
                      "is-design": _vm.isDesign,
                      owner: _vm._self,
                    },
                  },
                  [_vm._v(" " + _vm._s(_vm.$t(_vm.label)) + " ")]
                )
              : _vm._e(),
            _c(
              "div",
              {
                staticClass: "ds-form-block",
                style: _vm.getFormItemBlockMarginLeft(),
              },
              [
                !_vm.readOnly
                  ? _c("el-input", {
                      class: { "ds-error-border": _vm.errors.length > 0 },
                      attrs: {
                        placeholder: _vm.placeholder,
                        type: "text",
                        disabled: _vm.disabled,
                      },
                      on: {
                        change: _vm.inputChangeHandler,
                        blur: _vm.inputBlurHandler,
                      },
                      model: {
                        value: _vm.inputValue,
                        callback: function ($$v) {
                          _vm.inputValue = $$v
                        },
                        expression: "inputValue",
                      },
                    })
                  : _vm.readOnly
                  ? _c("span", [_vm._v(_vm._s(_vm.value))])
                  : _vm._e(),
                _vm._t("error", function () {
                  return [
                    _vm.errors.length > 0
                      ? _c("div", { staticClass: "ds-error-text" }, [
                          _vm._v(_vm._s(_vm.errorsMsg)),
                        ])
                      : _vm._e(),
                  ]
                }),
              ],
              2
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }