<template>
  <header class="dsf-college-home-header" :style="{'min-width': mainWidth + 20 + 'px'}">
    <div class="dsf-college-home-header-main" :style="{'width': mainWidth + 'px'}">
      <div class="dsf-college-home-header-site">
        <div class="logoBox">
          <a :href="indexUrl">
            <img class="logo" :src="$logo | imgFormat" alt="" />
          </a>
        </div>
        <div class="header-user">
          <home-search :placeholder="collegeRoot.searchPlaceholder" :search="collegeRoot.doSearch" @search="collegeRoot.$emit('search', $event)" />
          <template v-if="isLogin">
            <div class="header-user-icon" :style="{'background-image': `url(${$replace(defaultHeader)})`}">
              <img v-show="showIcon" :src="headIcon | imgFormat" @error="headLoadError" @load="headLoadSuccess" />
            </div>
            <div v-if="!dropdownConfig.length" class="username">
              欢迎您,{{ dsf.cookies.get("user_name") }}
              <i v-if="dropdownConfig.length" :class="[showDropdown ? 'icon-shangjiantou' : 'icon-xiajiantou']" class="iconfont" />
            </div>
            <el-dropdown v-else :show-timeout="0" placement="bottom" @visible-change="showDropdown = $event" @command="handleCommand">
              <div class="username">
                欢迎您,{{ dsf.cookies.get("user_name") }}
                <i v-if="dropdownConfig.length" :class="[showDropdown ? 'icon-shangjiantou' : 'icon-xiajiantou']" class="iconfont" />
              </div>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item v-for="(item, index) in dropdownConfig" :key="(item.key || '') + '__' + index" :command="index">
                  {{ item.name }}
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
          <template v-else>
            <template v-for="(bt, index) in customBts">
              <i :key="index" v-if="index" class="iconfont icon-vertical_line"></i>
              <a :key="index" class="link" @click="bt.handler()">{{ bt.name }}</a>
            </template>
          </template>
        </div>
      </div>
      <div v-show="showMenu" class="dsf-college-home-header-menu">
        <div class="dsf-college-home-header-menu-bar" :style="barStyle"></div>
        <div class="dsf-college-home-header-menu-list">
          <template v-for="(menu, index) in menuList">
            <div ref="menu" class="dsf-college-home-header-menu-item" :key="menu.menu_id" :class="{active: activeMenuIndex === index}" @click="collegeRoot._clickBefore(menuItemClick, menu, index)">
              {{ menu.menu_name }}
            </div>
          </template>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import menuUtils from "../utils";
import HomeSearch from "./homeSearch";

export default {
  name: "DsfCollegeHomeHeader",
  inject: ["homeRoot", "collegeRoot"],
  components: {
    HomeSearch
  },
  props: {
    mainWidth: {
      type: Number,
      default: 1200
    },
    dropdownConfig: {
      type: Array,
      default() {
        return [];
      }
    },
    // 是否只响应最后一级菜单的点击事件
    lastLevelClick: {
      type: Boolean,
      default: false
    },
    // 未登录状态下自定义按钮
    customBts: {
      type: Array,
      default() {
        return [];
      }
    },
    // 未匹配到菜单时，是否自动隐藏菜单
    isAutoHideMenu: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isLogin: dsf.cookies.get("isLogin") == 1,
      headIcon: "",
      defaultHeader: dsf.config.setting_public_user_default_header,
      showIcon: false,
      showDropdown: false,
      /************/
      activeMenuIndex: -1,
      barStyle: { display: "none" }
    };
  },
  computed: {
    indexUrl() {
      return location.href.replace(/#.*/, "");
    },
    $logo() {
      return this.$replace(this.collegeRoot.logoUrl);
    },
    menuList() {
      return this.homeRoot.$menuList;
    },
    menuIndex() {
      return this.homeRoot.menuIndex || [];
    },
    showMenu() {
      if (!this.isAutoHideMenu) return true;
      return this.activeMenuIndex > -1 && this.activeMenuIndex < this.menuList.length;
    }
  },
  watch: {
    menuIndex: {
      handler(to) {
        let index = to.length ? to[0] : -1;
        if (!this.isMounted) {
          this._activeMenuIndex = index;
        } else {
          this.activeMenuIndex = index;
          this.updateBarStyle();
        }
      },
      deep: true
    }
  },
  created() {
    this.initHeadIcon();
  },
  mounted() {
    this.isMounted = true;
    if (this._activeMenuIndex !== undefined) {
      this.activeMenuIndex = this._activeMenuIndex;
      this.updateBarStyle();
    }
  },
  methods: {
    // 初始化头像，从cookie中获取
    initHeadIcon() {
      const headIcon = dsf.getCookie("photoRelativePath");
      if (headIcon) {
        this.headIcon = decodeURIComponent(headIcon);
      }
    },
    // 头像加载成功时
    headLoadSuccess() {
      this.showIcon = true;
    },
    // 头像加载失败时
    headLoadError() {
      this.showIcon = false;
    },
    // 用户头像下的菜单点击
    handleCommand(index) {
      const item = this.dropdownConfig[+index];
      this.$emit("dropdown-click", item);
    },
    updateBarStyle() {
      let l = this.menuList.length;
      if (this.activeMenuIndex < 0 || this.activeMenuIndex >= l) {
        this.barStyle = { display: "none" };
        return;
      }
      this.$nextTick(() => {
        let activeMenu = this.$refs.menu?.[this.activeMenuIndex];
        if (!activeMenu) {
          this.barStyle = { display: "none" };
        } else {
          let left = activeMenu.offsetLeft + activeMenu.offsetWidth / 2;
          this.barStyle = {
            transform: `translate(calc(${left}px - 50%), 0) scale(.5)`
          };
        }
      });
    },
    menuItemClick(item, index) {
      if (item.menu_opentype_value != 2) {
        this.activeMenuIndex = index;
      }
      if (this.lastLevelClick) {
        if ((!item.children || !item.children.length) && item.menu_url) {
          menuUtils.openPage.call(this.homeRoot, item);
        }
      } else {
        menuUtils.openPage.call(this.homeRoot, menuUtils.findMenu(item));
      }
    }
  }
};
</script>