var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    { staticClass: "ds-aside-menu", class: ["level" + _vm.level] },
    [
      _vm._l(_vm.menuList, function (item, index) {
        return [
          _c("dsf-default-home-menu-item", {
            key: item.menu_id,
            attrs: {
              menu: item,
              index: index,
              level: _vm.level,
              "has-icon": _vm.hasIcon,
            },
            on: { mouseenter: _vm.mouseenter, mouseleave: _vm.mouseleave },
          }),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }