/*
 * @Editor: chenqy
 * @Description: 统一可视区域比列问题 （采用2 : 1）
 * @Date: 2021-11-06 18:38:07
 * @LastEditors: Chenqy
 * @LastEditTime: 2021-11-15 10:37:23
 */
export default {
  mounted() {
    let dom = document.getElementById("syncScreen");
    let { width, height } = document
      .querySelector(".dsf-sync-content")
      .getBoundingClientRect();

    let scale = 1;
    if (width / 2 <= height) {
      scale = width / 1694;
    } else {
      scale = height / 847
    }

    dom.style.transform = `scale(${scale}) translate(-50%, -50%)`
  },
};
