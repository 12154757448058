<!--
 * @Descripttion: 饼图控件
 * @Author: zhanghang
 * @Date: 2021-07-28 13:44:13
 * @LastEditors: zhanghang
 * @LastEditTime: 2022-03-11 11:15:26
-->
<template>
  <div class="ds-control ds-chart" :style="{ width, height }">
    <div class="chart" ref="chart">我是饼图</div>
    <template v-if="isDesign||(!isDesign&&slots[0].controls.length>0)">
      <div
        slot-name="default"
        class="ds-chart-slot"
      >
        <slot name="default"></slot>
      </div>
    </template>
  </div>
</template>
<script>
import defaultOptions from "../../output/chartDefault/pie.js";
// 需要处理legend data
defaultOptions.legend.data = defaultOptions.series
  .reduce((arr, item) => [...arr, ...item.data], [])
  .map((item) => item.name);

const cloneDefault = _.cloneDeep(defaultOptions);
export default dsf.component({
  name: "DsfPlatformPie",
  ctrlCaption: "饼状图表",
  mixins: [$mixins.layout, $mixins.chart],
  props: {
    //自定义配置总览
    chart: {
      type: Object,
      default() {
        return defaultOptions;
      },
    },
  },
  data(){
    return {
      cloneDefault,
    }
  },
  methods: {
    //数据源分析
    dataSourceAnalysis(data){
      this.analysis(data)
    },
    // 解析数据
    analysis(data) {
      //多环版本
      if(Array.isArray(data[0])){
        let series = _.cloneDeep(this.chart.series);
        let arr =[]
        data.forEach(it => {
          arr = [...arr,...it.map((item) => item.name)]
        });
        this.chart.legend.data = arr;

        for(let i =0;i<data.length;i++) {
          let seriesItem = series[i]
          //如果配置项目存在，如果不存在
          if(seriesItem){
            Object.assign(seriesItem.data,data[i])
            this.$set(this.chart.series, i, seriesItem);
          //复制第一条
          }else {
            seriesItem = series[0]
            seriesItem.data = data[i]
            this.$set(this.chart.series, i, seriesItem);
          }
        }
      
      //单环(兼容老版本)
      }else {
        let series = _.cloneDeep(this.chart.series[0]);
        const arr = data.map((item) => item.name);
        this.chart.legend.data = arr;
        Object.assign(series.data,data)
        this.$set(this.chart.series, 0, series);
      }
    },
  },
});
</script>

