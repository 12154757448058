<template>
  <div class="ds-control ds-tree">
    <el-tree
      v-show="filterText !== '' && searchSqlUrlReal && searchLoading > 0"
      ref="tree2"
      class="filter-tree"
      :class="['showCheckBox' + showCheckBox]"
      :empty-text="searchLoading === '2' ? '暂无匹配数据' : '搜索中...'"
      :props="searchDefaultProps"
      :default-expand-all="true"
      :check-on-click-node="clickTextFun==='expand'?false:true"
      :data="searchData"
      :show-checkbox="true"
      :check-strictly="true"
      :expand-on-click-node="clickTextFun==='expand'?true:false"
      :node-key="nodeKey"
      :render-after-expand="false"
      @check="checkSearch"
      @check-change="checkSearchChange"
    >
      <span
        class="el-tree-node__label"
        :class="{
          is_disabled: data._isDisabled,
          is_excluded: data._isExcluded,
        }"
        :nodeKey="data[nodeKey]"
        slot-scope="{ data }"
      >
        {{ data._name }}
      </span>
    </el-tree>
    <el-tree
      v-show="!filterText || filterText == '' || !searchSqlUrlReal"
      ref="tree"
      class="filter-tree"
      :class="['showCheckBox' + showCheckBox]"
      :empty-text="
        loading === '1' ? '数据加载中...' : loading === '2' ? '暂无数据' : ''
      "
      :props="defaultProps"
      :default-expand-all="isDesign ? true : false"
      :check-on-click-node="clickTextFun==='expand'?false:true"
      :lazy="!!(async && !isDesign)"
      :load="lazyLoad"
      :data="data"
      :default-expanded-keys="expandedKeys"
      :highlight-current="showCheckBox == '0' ? true : false"
      :filter-node-method="filterNode"
      :show-checkbox="showCheckBox == '0' ? false : true"
      :check-strictly="parseInt(maxNum)==1? false : chooseOneNode == '1' ? false : true"
      :expand-on-click-node="clickTextFun==='expand'?true:false"
      :node-key="nodeKey"
      :render-after-expand="false"
      @check="check"
      @node-click="nodeClick"
      @check-change="checkChange"
    >
      <span
        class="el-tree-node__label"
        :class="{
          is_disabled: data._isDisabled,
          is_excluded: data._isExcluded,
        }"
        :nodeKey="data[nodeKey]"
        slot-scope="{ data }"
      >
        <el-button v-if="data.isBtn" type="text" :loading="data.loading">{{
          data._name
        }}</el-button>
        <span v-else>{{ data._name }}</span>
      </span>
    </el-tree>
  </div>
</template>

<script>
export default dsf.component({
  name: "DsfTree",
  ctrlCaption: "树",
  mixins: [$mixins.control],
  props: {
    tabIndex: {
      //区分是哪一个tab
      type: [Number, String],
      default: 0,
    },
    showCheckBox: {
      //是否启用复选框：1启用-符合选择条件项/2启用-全部/0不启用
      type: String,
      default: "0",
    },
    types: {
      //选择条件
      type: String,
      default: "",
    },
    chooseOneNode: {
      //父子级是否关联：0不关联 1子父级相互关联 2子级关联父级不关联
      type: String,
      default: "1",
    },
    defaultOpenSeries: {
      //默认展开到第几级
      type: [String, Number],
      default: "",
    },
    metaDataType: {
      //数据类型:0接口/1元数据组/2本部门/3本单位/4组织架构
      type: String,
      default: "0",
    },
    chooseMetaData: {
      //数据类型选择元数据组后 的后续选择数据
      type: Object,
      default() {
        return {};
      },
    },
    sqlUrl: {
      //数据类型选择除元数据组后 的实际接口地址
      type: String,
      default: "",
    },
    asyncSqlUrl: {
      //异步请求地址
      type: String,
      default: "",
    },
    searchSqlUrl: {
      //搜索地址
      type: String,
      default: "",
    },
    isAsync: {
      //是否异步
      type: String,
      default: "0",
    },
    showType: {
      //人员部门选择的 显示类型 user、dept
      type: String,
      default: "dept",
    },
    showLevel: {
      //部门人员类型的组织构架类型 显示层级 (0为子机构，不填则显示全部)
      type: String,
      default: "0",
    },
    isDesign: {
      type: Boolean,
      default: true,
    },
    nodeKey: {
      //数据标识
      type: String,
      default: "_id",
    },
    excludeIds: {
      //排除项，可能是已经选择过不让选择的节点
      type: Array,
      default: function () {
        return [];
      },
    },
    rightIstree: {
      //选择后的展示区域是否是一个树结构
      type: Boolean,
      default: false,
    },
    defaultSelect: {
      //默认选中的数据
      type: Array,
      default: function () {
        return [];
      },
    },
    valueNodeKey: {
      //返回的value对应的节点标识
      type: String,
      default: "_id",
    },
    maxNum: {
      //能选择的最大数据数量
      type: Number,
      default: 0,
    },
    pagination: {
      //是否开启分页
      type: String,
      default: "0",
    },
    pageSize: {
      //每次分页请求条数
      type: Number,
      default: 1,
    },
    paginationSql: {
      //分页接口地址
      type: String,
      default: "",
    },
    filterKeyInfo: {
      // 查询条件
      type: Array,
      default() {
        return [
          {
            code: "_name",
            operation: "like",
          },
        ];
      },
    },
    chooseOneNodeSpecial: {
      // 选中父级隐藏子级：
      type: String,
      default: "0",
    },
    clickTextFun: {
      type: String,
      default: "check",
    }
  },
  data() {
    return {
      filterText: "", //查询关键词（多个关键词空格隔开）
      defaultProps: {
        label: "_name",
        children: "children",
        disabled: this.setNodeDisable,
        isLeaf: "isLeaf",
      },
      data: [],
      loading: 0,
      resolve: null,
      sqlUrls: [
        {
          user: "oua/getUserByCurDept",
          dept: "oua/getUserByCurDept",
          userSearch: "oua/searchUserByCurDept",
          deptSearch: "oua/searchDeptByCurDept",
        }, // 本部门 2
        {
          user: "oua/getUserByCurUnit",
          dept: "oua/getDeptByCurUnit",
          userSearch: "oua/searchUserByCurUnit",
          deptSearch: "oua/searchDeptByCurUnit",
        }, // 本单位 3
        {
          user: "oua/getUserByOrg",
          dept: "oua/getDeptByOrg",
          userSearch: "oua/searchUserByOrg",
          deptSearch: "oua/searchDeptByOrg",
        }, //组织架构 4
      ],
      _sqlUrl: this.sqlUrls,
      params: {},
      async: false,
      asyncSql: "",
      expandedKeys: [],
      searchSqlUrlReal: null,
      searchData: [],
      searchLoading: 0,
      searchTimeout: null,
      searchCheckedNodes: [],
      searchDefaultProps: {
        label: "_name",
        children: "children",
        disabled: this.setNodeDisableSearch,
      },
      pageNumObj: {},
      _paginationSql: this.paginationSql,
    };
  },
  watch: {
    filterText(val) {
      this.filter(val);
    },
  },
  created() {
    this.loadData();
  },
  methods: {
    reloadData() {
      this.loadData();
    },
    loadData() {
      if (this.isDesign) {
        let label = this.defaultProps.label;
        let children = this.defaultProps.children;
        let nodeKey = this.nodeKey;
        let data = [
          {
            [label]: "一级 1",
            [nodeKey]: 1,
            [children]: [
              {
                [label]: "二级 1-1",
                [nodeKey]: 4,
                [children]: [
                  {
                    [label]: "三级 1-1-1",
                    icon: "el-icon-info",
                    [nodeKey]: 9,
                  },
                  {
                    [label]: "三级 1-1-2",
                    icon: "el-icon-info",
                    [nodeKey]: 10,
                  },
                ],
              },
            ],
          },
          {
            [label]: "一级 2",
            [nodeKey]: 2,
            [children]: [
              {
                [label]: "二级 2-1",
                [nodeKey]: 5,
              },
              {
                [label]: "二级 2-2",
                [nodeKey]: 6,
              },
            ],
          },
          {
            [label]: "一级 3",
            [nodeKey]: 3,
            [children]: [
              {
                [label]: "二级 3-1",
                [nodeKey]: 7,
              },
              {
                [label]: "二级 3-2",
                [nodeKey]: 8,
              },
            ],
          },
        ];
        if (this.openWebroot && !!this.webrootName) {
          data = [
            {
              [label]: this.webrootName,
              [children]: data,
              [nodeKey]: 0,
            },
          ];
        }
        this.data = data;
        for (let i = 0; i < this.data.length; i++) {
          this.initExpand(this.data[i]);
        }
        this.$nextTick(() => {
          this.$dispatch("set-treeobj", this);
        });
      } else {
        this.setRequestConfig();
      }
    },
    setRequestConfig() {
      let index = this.metaDataType * 1;
      this.params = {};
      if (index > 1) {
        // 本部门2、本单位3、组织架构4
        let sqlObj = this.sqlUrls[index - 2];
        let type = this.showType;
        this._paginationSql = "/oua/getOrgMoreData";
        this._sqlUrl = sqlObj[type];
        this.searchSqlUrlReal = sqlObj[type + "Search"];
        this.async = true;
        this.params = {
          isAsy: true,
        };
        this.asyncSql = this._sqlUrl;
      } else {
        this.async = this.isAsync * 1;
        if (this.async) {
          this.searchSqlUrlReal = this.searchSqlUrl;
        }
      }
      if (index == 4) {
        // 部门人员的组织架构-显示层级
        this.params["sExplanLevel"] = this.showLevel;
        if (this.showType == "user") {
          this.asyncSql = "oua/getUserByCurUnit";
        } else {
          this.asyncSql = "oua/getDeptByCurUnit";
        }
      } else if (index == 0) {
        // 自定义接口
        this._sqlUrl = this.sqlUrl;
      } else if (index == 1) {
        // 元数据组
        let chooseMetaData = this.chooseMetaData || {};
        if (chooseMetaData.type === "meta") {
          // 元数据
          this._sqlUrl = "meta/tree/byName";
          this.params = {
            name: chooseMetaData.code.id,
            namespace: this.$vm ? this.$vm.nameSpace : "", //dsfa.rm
            pageName: this.$vm ? this.$vm.pageName : "", //main
            order: "[]",
          };
        } else if (chooseMetaData.type === "ds") {
          // 数据源
          this._sqlUrl = "meta/tree/dbSource";
          this.params = {
            rmId: chooseMetaData.code,
          };
        }
      }
      if (!this.async) {
        // 如果不是异步的时候，手动掉加载数据方法。是异步的时候会自动触发
        this.initLoadData();
      }
    },
    initLoadData(resolve, searchSqlUrl, keyWord) {
      const self = this;
      dsf.mix(self.params, self.queryString);
      let params = self.params;
      if (searchSqlUrl) {
        params.keyWord = keyWord;
        self.searchLoading = "1";
      } else {
        self.loading = "1";
      }
      let loader = dsf.layer.loading();
      if (self.pagination * 1) {
        params.pageSize = self.pageSize;
      }
      self.$http
        .get(
          self.$replace(
            searchSqlUrl || self._sqlUrl,
            self.$expressParams(self)
          ),
          params,
          self.metaDataType == 1
        )
        .done((res) => {
          console.time();
          if (res.success) {
            let list = res.data,
              temp1 = {},
              tree = [];
            if (!Array.isArray(list)) {
              list = [list];
            }
            for (let i = 0; i < list.length; i++) {
              // 平级必须按照顺序返回
              let node = list[i];
              temp1[node._id] = node;
              if (temp1[node.treeinfo_pid]) {
                if (!temp1[node.treeinfo_pid]["children"]) {
                  temp1[node.treeinfo_pid]["children"] = [];
                }
                temp1[node.treeinfo_pid]["children"].push(node);
              } else {
                tree.push(node);
              }
              if (!searchSqlUrl) {
                // 展开层级
                if (i == 0) {
                  self.expandedKeys = [];
                }
                if (self.defaultOpenSeries !== 0) {
                  self.initExpand(node,0);
                }
              }
            }
            self.addMoreBtn(undefined, tree);
            if (!searchSqlUrl) {
              self.data = tree;
              self.$dispatch("data-loaded", tree);
              self.$nextTick(() => {
                self.setNodeClass();
              });
              if (resolve) {
                return resolve(tree);
              }
            } else {
              self.searchData = tree;
              self.$nextTick(() => {
                self.$refs.tree2.setCheckedKeys(
                  [].concat(
                    self.$refs.tree.getCheckedKeys(),
                    _.map(self.searchCheckedNodes, (d) => {
                      return d[self.nodeKey];
                    })
                  )
                );
              });
            }
          } else {
            dsf.layer.message(res.message, false);
          }
        })
        .error((res) => {
          dsf.layer.message(res?.message || res?.error?.message, false);
        })
        .always(() => {
          if (!searchSqlUrl) {
            self.loading = "2";
            self.filter(self.filterText);
            self.$dispatch("set-treeobj", self);
          } else {
            self.searchLoading = "2";
            this.$nextTick(()=>{
              this.$dispatch("filterState");
            })
          }
          dsf.layer.closeLoading(loader);
          self.$nextTick(()=>{
            self.$nextTick(()=>{
              console.timeEnd();
            })
          })
        });
    },
    initExpand(node, initLevel) {
      // 初始化展开层级处理
      let children = this.defaultProps.children;
      if (this.defaultOpenSeries != "" && this.defaultOpenSeries >= 0) {
        if (initLevel * 1 < this.defaultOpenSeries * 1) {
          this.expandedKeys.push(node[this.nodeKey]);
        }
      } else {
        this.expandedKeys.push(node[this.nodeKey]);
      }
      if (node[children] && node[children].length > 0) {
        initLevel = initLevel * 1 + 1;
        for (let i = 0; i < node[children].length; i++) {
          this.initExpand(node[children][i], initLevel);
        }
      }
    },
    setNodeDisable(data, node) {
      if (this.metaDataType * 1 > 1) {
        // 部门人员选择特殊处理
        if (
          !data.children?.length &&
          (this.showType === "dept"
            ? !data.DeptCount
            : this.showType === "user" &&
              (data.type === 1 || (!data.DeptCount && !data.UserCount)))
        ) {
          node.isLeaf = true;
        }
      }
      data["_isDisabled"] = false;
      if (this.types && !this.$eval(this.types, data)) {
        // 选择条件
        data["_isDisabled"] = true;
      }
      if (this.excludeIds.includes(data[this.valueNodeKey])) {
        // 排除节点
        data["_isDisabled"] = true;
        data["_isExcluded"] = true;
      }
      return false;
    },
    setNodeClass() {
      this.$nextTick(() => {
        let nodeIsDis = this.$el.querySelectorAll(
          ".el-tree-node__content:not(.is_disabled)>.el-tree-node__label.is_disabled"
        );
        _.each(nodeIsDis, (el) => {
          let pE = el.parentElement;
          pE.setAttribute("class", pE.getAttribute("class") + " is_disabled");
        });
        let nodeIsExc = this.$el.querySelectorAll(
          ".el-tree-node__content:not(.is_excluded)>.el-tree-node__label.is_excluded"
        );
        _.each(nodeIsExc, (el) => {
          let pE = el.parentElement;
          pE.setAttribute("class", pE.getAttribute("class") + " is_excluded");
        });
      });
    },
    asyncLoadData(node, resolve, loadMoreSql) {
      const self = this;
      let sql = null;
      let params = {
        isAsy: true,
        sFID: node?.data?._id,
      };
      if (loadMoreSql) {
        sql = loadMoreSql;
        params.sFID = node.parentId;
        params.pageNum = this.pageNumObj[params.sFID];
        if (this.metaDataType * 1 > 1) {
          params.withUser = this.showType === "user";
        }
      } else if (this.metaDataType * 1 <= 1) {
        sql = self.asyncSqlUrl;
      } else {
        sql = self.asyncSql;
      }
      if (sql) {
        if (self.pagination * 1) {
          params.pageSize = self.pageSize;
        }

        self.$http
          .get(
            self.$replace(
              sql,
              self.$expressParams(self, node.data)
            ),
            params,
            self.metaDataType == 1
          )
          .done((res) => {
            if (res.success) {
              let list = res.data,
                temp1 = {},
                tree = [];
              if (!Array.isArray(list)) {
                list = list.children;
              }
              for (let i = 0; i < list.length; i++) {
                let node = list[i];
                temp1[node._id] = node;
                if (temp1[node.treeinfo_pid]) {
                  if (!temp1[node.treeinfo_pid]["children"]) {
                    temp1[node.treeinfo_pid]["children"] = [];
                  }
                  temp1[node.treeinfo_pid]["children"].push(node);
                } else {
                  tree.push(node);
                }
              }
              self.$dispatch("data-loaded", tree);
              if (!loadMoreSql) {
                // 第一次异步加载
                self.addMoreBtn(params.sFID, tree);
              } else {
                resolve(tree);
              }
              self.$nextTick(() => {
                if (node.checked) {
                  // 如果加载异步的节点已经被选择，子级将会收到影响
                  self.$dispatch("set-selecteddata", {
                    data: self.getSelectedData(
                      self.$refs.tree.getCheckedNodes()
                    ),
                    tabIndex: self.tabIndex,
                  });
                }
                self.setNodeClass();
                // 搜索的值
                self.$refs.tree.setCheckedKeys(
                  [].concat(
                    self.$refs.tree.getCheckedKeys(),
                    _.map(self.searchCheckedNodes, (d) => {
                      return d[self.nodeKey];
                    })
                  )
                );
              });
              if (resolve && !loadMoreSql) {
                return resolve(tree);
              }
            } else {
              dsf.layer.message(res.message, false);
              if (resolve && loadMoreSql) {
                return resolve([]);
              }
            }
          })
          .error((res) => {
            dsf.layer.message(res?.message || res?.error?.message, false);
          });
      } else if (resolve && loadMoreSql) {
        return resolve([]);
      }
    },
    lazyLoad(node, resolve) {
      this.resolve = resolve;
      if (node.level === 0) {
        this.initLoadData(resolve);
      } else if (
        this.metaDataType * 1 > 1 && this.showType == "user"
          ? node?.data?.UserCount || node?.data?.DeptCount
          : node?.data?.DeptCount
      ) {
        this.asyncLoadData(node, resolve);
      } else if (this.metaDataType * 1 > 1 && node.data.children) {
        this.asyncLoadData(node, resolve);
      } else if (this.metaDataType * 1 <= 1) {
        this.asyncLoadData(node, resolve);
      } else {
        resolve([]);
      }
    },
    filter(v) {
      this.searchLoading = 0;
      this.searchData = [];
      let _this = this;
      if (v != this.filterText) {
        this.filterText = v;
      }
      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout);
      }
      _this.searchTimeout = setTimeout(() => {
        if (_this.searchSqlUrlReal && v != "") {
          _this.initLoadData(undefined, _this.searchSqlUrlReal, v);
        } else {
          _this.$refs?.tree.filter(v);
          this.$nextTick(()=>{
            _this.$dispatch("filterState");
          })
        }
      }, 400);
    },
    filterNode(value, data, node) {
      let keywords = value.trim();
      if (keywords === "") {
        keywords = [];
      } else {
        keywords = keywords.split(" ");
      }

      if (!keywords.length) {
        return true;
      }

      for (let i = 0; i < keywords.length; i++) {
        let key = keywords[i]?.toLowerCase();
        for (let j = 0; j < this.filterKeyInfo.length; j++) {
          let state = false;
          let obj = this.filterKeyInfo[j];
          let val = data[obj.code];

          if (!dsf.isUnDef(val)) {
            val = (val + "").toLowerCase();
            let index = val.indexOf(key);
            let op = obj.operation;
            switch (op) {
            case "like": //模糊匹配
              state = index > -1;
              break;
            case "slike": //开头匹配
              state = index == 0;
              break;
            case "elike": //结尾匹配
              state = index + key.length == (val + "").length;
              break;
            case "eq": //等于
              state = val == key;
              break;
            case "noteq": //不等于
              state = val != key;
              break;
            case "lt": //小于
              state = val < key;
              break;
            case "gt": //大于
              state = val > key;
              break;
            case "lteq": //小于等于
              state = val <= key;
              break;
            case "gteq": //大于等于
              state = val >= key;
              break;
            }
          }
          if (state) return true;
        }
      }
      return false;
    },
    checkChange(nodeData, state, child, type) {
      if (nodeData.isBtn || this.rightIstree) {
        return;
      } else {
        if (!state) {
          this.$dispatch("delete-selectvalue", nodeData);
        }
        if (this.maxNum*1!==1 && !type && this.chooseOneNode == "2" && nodeData?.children?.length) {
          // 关联（不带父级） = 不关联 + 子级
          _.forEach(nodeData.children, (_nodeData) => {
            let _id = _nodeData._id;
            let attr =
              "[nodekey" +
              (this.nodeKey == "globalId" ? "$" : "") +
              "='" +
              _id +
              "']";
            let nodes = this.$el.querySelectorAll(attr);
            _.each(nodes, (el) => {
              let nodeEl = el.closest(".el-tree-node");
              let node = nodeEl ? nodeEl.__vue__ : null;
              if (node && node.node.checked != state) {
                this.$refs.tree.setChecked(node.node, state, true);
              }
            });
          });
        }
        // 同数据标识值处理
        if (!type) type = "tree";
        let _id = nodeData._id;
        let attr =
          "[nodekey" +
          (this.nodeKey == "globalId" ? "$" : "") +
          "='" +
          _id +
          "']";
        let nodes = this.$el.querySelectorAll(attr);
        _.each(nodes, (el) => {
          let nodeEl = el.closest(".el-tree-node");
          let node = nodeEl ? nodeEl.__vue__ : null;
          if (node && node.node.checked != state) {
            this.$refs[type].setChecked(
              node.node,
              state,
              this.maxNum*1===1 || this.chooseOneNode == "0" ? false : true
            );
            if (this.maxNum*1!==1 || this.chooseOneNode == "2" && nodeData?.children?.length) {
              _.forEach(nodeData.children, (_nodeData) => {
                let _id = _nodeData._id;
                let attr =
                  "[nodekey" +
                  (this.nodeKey == "globalId" ? "$" : "") +
                  "='" +
                  _id +
                  "']";
                let nodes = this.$el.querySelectorAll(attr);
                _.each(nodes, (el) => {
                  let nodeEl = el.closest(".el-tree-node");
                  let node = nodeEl ? nodeEl.__vue__ : null;
                  if (node && node.node.checked != state) {
                    this.$refs[type].setChecked(node.node, state, true);
                  }
                });
              });
            }
          }
        });
      }
    },
    check(node, data) {
      data["checkedKeys"] = data["checkedKeys"].concat(
        _.map(this.searchCheckedNodes, (d) => {
          return d[this.nodeKey];
        })
      );
      data["checkedNodes"] = data["checkedNodes"].concat(
        this.searchCheckedNodes
      );

      this.$dispatch("check-node", { node, data });

      if (this.rightIstree) return;
      if (this.$refs.tree.getNode(node)?.checked && this.maxNum * 1 === 1) {
        let datas1 = this.$refs.tree.getCheckedKeys();
        let datas2 = this.$refs.tree2?.getCheckedKeys() || [];
        //请求搜索的和原来的
        _.forEach(datas1,(d)=>{
          if(d!=node[this.nodeKey]){
            this.$refs.tree.setChecked(d,false)
          }
        })
        _.forEach(datas2,(d)=>{
          if(d!=node[this.nodeKey]){
            this.$refs.tree2.setChecked(d,false)
          }
        })
        // 处理只能选择一个节点时的值
        this.$refs.tree.setChecked(node[this.nodeKey],true,false);
        this.$dispatch("set-selecteddata", {
          data: this.getSelectedData([node]),
          tabIndex: this.tabIndex,
        });
      } else {
        this.$dispatch("set-selecteddata", {
          data: this.getSelectedData(data.checkedNodes),
          tabIndex: this.tabIndex,
        });
      }
    },
    deleteSearchCheckedNodes(id) {
      let index = -1;
      _.forEach(this.searchCheckedNodes, (d, i) => {
        if (d[this.nodeKey] == id) {
          index = i;
          return true;
        }
      });
      if (index != -1) {
        this.searchCheckedNodes.splice(index, 1);
      }
    },
    getSelectedData() {
      let self = this;
      let datas = [];
      if(this.maxNum*1 === 1 && this.searchSqlUrlReal){
        if(this.filterText){
          datas = this.searchCheckedNodes;
        }else{
          datas = this.$refs.tree.getCheckedNodes();
          this.searchCheckedNodes = [];
        }
      }else{
        datas = [].concat(
          this.$refs.tree.getCheckedNodes(),
          this.searchCheckedNodes
        );
      }
      // 去重
      datas = _.unionBy(datas, "_id");
      let chooseOneNodeSpecial = this.chooseOneNodeSpecial === "1";
      _.forEachRight(datas, (item, i) => {
        item.tabIndex = this.tabIndex;
        if (item._isDisabled || item._isExcluded) {
          // 过滤（不符合选择条件的）
          datas.splice(i, 1);
        } else if (
          chooseOneNodeSpecial &&
          self.$refs.tree.getNode(item[self.nodeKey])?.parent?.checked
        ) {
          datas.splice(i, 1);
        }
      });
      return datas;
    },
    nodeClick(node, data) {
      if (node.isBtn) {
        this.loadMore(node, data);
      } else if(this.clickTextFun==='expand'){
        return 
      } else if (this.rightIstree && !node._isDisabled) {
        this.$dispatch("set-selecteddata", {
          data: data,
          tabIndex: this.tabIndex,
        });
        return;
      }
    },
    setNodeDisableSearch(data, node) {
      this.setNodeDisable(data, node);
    },
    checkSearchChange(nodeData, state, child) {
      this.checkChange(nodeData, state, child, "tree2");
    },
    checkSearch(node) {
      let Node = this.$refs.tree2.getNode(node);
      let id = node[this.nodeKey];
      let checkState = Node.checked;
      if (checkState) {
        node.isSearch = true;
        if (this.maxNum * 1 === 1) {
          let datas1 = this.$refs.tree.getCheckedKeys();
          let datas2 = this.$refs.tree2.getCheckedKeys();
          //请求搜索的和原来的
          _.forEach(datas1,(d)=>{
            if(d!=node[this.nodeKey]){
              this.$refs.tree.setChecked(d,false)
            }
          })
          _.forEach(datas2,(d)=>{
            if(d!=node[this.nodeKey]){
              this.$refs.tree2.setChecked(d,false)
            }
          })
          this.searchCheckedNodes = [node];
        } else {
          this.searchCheckedNodes.push(node);
        }
      } else {
        this.deleteSearchCheckedNodes(id);
      }

      this.$refs.tree.setChecked(id, checkState);
      this.check(node, {
        checkedKeys: this.$refs.tree.getCheckedKeys(),
        checkedNodes: this.$refs.tree.getCheckedNodes(),
        halfCheckedKeys: [],
        halfCheckedNodes: [],
      });
    },
    addMoreBtn(key, tree) {
      // 加载更多的按钮初始化
      if (this.pagination * 1 && tree.length == this.pageSize) {
        this.pageNumObj[key] = 2;
        tree.push({
          [this.defaultProps.label]: "点击加载更多",
          [this.nodeKey]: "更多按钮" + key,
          _isDisabled: true,
          _isExcluded: true,
          isLeaf: true,
          isBtn: true,
          parentId: key,
        });
      }
    },
    loadMore(node, data) {
      let self = this;
      this.$set(node, "loading", true);
      this.asyncLoadData(
        node,
        function (list) {
          // 加载更多完成
          let treeRef = self.$refs.tree;
          if (!list.length) {
            treeRef.remove(data);
            return;
          }
          let _children = data.parent.data._children;
          if (!_children) {
            _children = dsf.mix([], data.parent.data?.children || []);
          }
          data.parent.data.children = [];
          let key = node[self.nodeKey];
          _.forEach(list, (item) => {
            treeRef.insertBefore(item, key);
          });
          data.parent.data._children = _children.concat(list);
          self.$nextTick(() => {
            self.$set(node, "loading", false);
            self.pageNumObj[node.parentId]++;
            if (list.length < self.pageSize) {
              treeRef.remove(data);
            }
          });
        },
        self._paginationSql
      );
    },
  },
  design: {
    //设计器是是否在组件上产生遮罩
    isMask: false,
    fit: true,
  },
});
</script>
