<template>
  <div class="ds-tree-select" :class="[theme]" :style="{ 'padding-top': searchControl ? '50px' : '0'}">
    <div v-if="searchControl" class="ds-tree-select-search-box">
      <el-input v-model="keyword" placeholder="输入关键词（多个关键词空格隔开）">
        <i slot="suffix" class="iconfont icon-Magnifier"></i>
      </el-input>
    </div>
    <div class="ds-tree-select-tree-box">
      <dsf-virtual-scroll scroll-x>
        <el-tree
          v-if="multiple"
          ref="tree"
          default-expand-all
          :data="treeData"
          :node-key="nodeKey"
          :empty-text="loading ? '数据加载中...' : emptyText"
          :show-checkbox="multiple"
          :default-checked-keys="defaultCheckedKeys"
          :filter-node-method="filterNode"
          @check="check"
          @node-click="nodeClick"
        >
          <div slot-scope="{ node, data }" class="custom-tree-node" :tree-id="['key_' + data[nodeKey]]">
            <template v-if="data.children !== undefined">
              <i class="iconfont" :class="[node.expanded ? 'icon-dakai' : 'icon-wenjianjia']" />
              <span>{{ data[nodeName] }}</span>
            </template>
            <template v-else>
              <i class="iconfont icon-shiyongwendang" />
              <span>{{ data[nodeName] }}</span>
            </template>
          </div>
        </el-tree>
        <el-tree
          v-else
          ref="tree"
          default-expand-all
          :data="treeData"
          :node-key="nodeKey"
          :empty-text="loading ? '数据加载中...' : emptyText"
          :show-checkbox="multiple"
          :default-checked-keys="defaultCheckedKeys"
          :filter-node-method="filterNode"
          @check="check"
          @node-click="nodeClick"
        >
          <div slot-scope="{ node, data }" class="custom-tree-node" :tree-id="['key_' + data[nodeKey]]">
            <template v-if="getNoCheck(data)">
              <i class="iconfont" :class="[node.expanded ? 'icon-dakai' : 'icon-wenjianjia']" />
              <span>{{ data[nodeName] }}</span>
            </template>
            <!--<el-radio v-else v-model="radioId" :label="data[nodeKey]" :disabled="data.nocheck">-->
            <el-radio v-else v-model="radioId" :label="data[nodeKey]" :disabled="data.nocheck">
              <i class="iconfont icon-shiyongwendang" />
              <span>{{ data[nodeName] }}</span>
            </el-radio>
          </div>
        </el-tree>
      </dsf-virtual-scroll>
    </div>
  </div>
</template>

<script>
/**
 * 事件
 *
 * @node-click(data, node, v)
 *  同el-tree的node-click
 *
 * @choose-node(data)
 *  选中值变化时
 *  参数data为选中的节点
 *    多选时：为object数组
 *    单选时：为object（radioCanCancel为true时可为null）
 *
 * @choose-key(key)
 *  选中值变化时
 *  参数key为选中的节点的nodeKey
 *    多选时：为string数组
 *    单选时：为string（radioCanCancel为true时可为null）
 */
let timeout = 0;
export default {
  name: "DsfTreeSelect",
  props: {
    // 主题 theme1，theme2
    theme: {
      type: String,
      default: "theme1"
    },
    // 是否显示筛选框
    searchControl: {
      type: Boolean,
      default: false
    },
    // 是否多选
    multiple: {
      type: Boolean,
      default: false
    },
    // 单选是否可取消
    radioCanCancel: {
      type: Boolean,
      default: false
    },
    // 是否加载状态
    loading: {
      type: Boolean,
      default: false
    },
    // 内容为空的时候展示的文本
    emptyText: {
      type: String,
      default: "暂无数据"
    },
    // 每个树节点用来作为唯一标识的属性，整棵树应该是唯一的
    nodeKey: {
      type: String,
      default: ""
    },
    // 节点展示的名称对应的字段key
    nodeName: {
      type: String,
      default: "label"
    },
    // 是否默认展开所有节点
    defaultExpandAll: {
      type: Boolean,
      default: false
    },
    // 默认展开的节点的 key 的数组
    defaultExpandedKeys: {
      type: Array,
      default() {
        return [];
      }
    },
    // 默认选中的节点，多选为Array，单选为String或Number
    defaultCheckedKey: {
      type: [Array, String, Number],
      default() {
        return [];
      }
    },
    // 展示数据
    treeData: {
      type: Array,
      default() {
        return [];
      }
    },
    fileType: {
      type: [String, Number],
      default: ''
    },
    getNoCheck: {
      type: Function,
      default: function (data) {
        if (this.fileType) {
          return data.type != this.fileType ? true : false;
        }
        return data.children && data.children.length > 0;
      }
    }
  },
  data() {
    return {
      keyword: "",
      radioId: "",
      defaultCheckedKeys: []
    };
  },
  computed: {
    keywords() {
      const keyword = this.keyword.trim();
      if (keyword === "") {
        return [];
      }
      return keyword.split(" ");
    }
  },
  watch: {
    keyword(to) {
      this.$refs.tree.filter(to);
    },
    defaultCheckedKey(to) {
      this.initDefaultCheckedKeys(to);
    },
    loading(to) {
      if (!to && this.treeData.length) {
        let key;
        if (this.multiple) {
          key = this.defaultCheckedKey?.[0] || null;
          this.handleCheckChange();
        } else {
          key = this.defaultCheckedKey || null;
          const node = this.$refs.tree.getNode(this.radioId);
          if (node) {
            this.$emit("choose-node", node.data);
            this.$emit("choose-key", this.radioId);
          }
        }
        if (key) {
          // 跳转到选中位置
          this.$nextTick(() => {
            let el = this.$refs.tree.$el.querySelector(`.custom-tree-node[tree-id="key_${key}"]`);
            el?.scrollIntoView({block: "end"});
          });
        }
      }
    }
  },
  created() {
    this.initDefaultCheckedKeys(this.defaultCheckedKey);
  },
  mounted() {
    if (!this.loading) return;
    if (this.multiple) {
      this.treeData.length && this.handleCheckChange();
    } else {
      const node = this.$refs.tree.getNode(this.radioId);
      if (node) {
        this.$emit("choose-node", node.data);
        this.$emit("choose-key", this.radioId);
      }
    }
  },
  methods: {
    filterNode(value, data, node) {
      if (!this.keywords.length) {
        return true;
      }
      // if (!node.parent) {
      //   node.filterText = null;
      // } else if (node.parent.visible && node.parent.filterText === value) {
      //   node.filterText = value;
      //   return true;
      // }
      // for (let i = 0; i < this.keywords.length; i++) {
      //   if (data[this.nodeName].indexOf(this.keywords[i]) === -1) {
      //     node.filterText = value;
      //     return false;
      //   }
      // }
      // node.filterText = value;
      // return true;
      if (!value) return true;
      for (let i = 0; i < this.keywords.length; i++) {
        if (data[this.nodeName]?.indexOf(this.keywords[i]) > -1) {
          return true;
        }
      }
      return false;
    },
    check() {
      this.handleCheckChange();
    },
    initDefaultCheckedKeys(to) {
      if (this.multiple) {
        if (dsf.type(to) !== "array") {
          this.defaultCheckedKeys = [to];
        } else {
          this.defaultCheckedKeys = to;
        }
      } else {
        if (dsf.type(to) === "array") {
          this.radioId = to.length ? to[0] : "";
        } else {
          this.radioId = to;
        }
      }
    },
    // 节点被点击时的回调
    nodeClick(data, ...params) {
      if (!this.multiple && !this.getNoCheck(data)) {
        let value = null,
          key = null;
        if (data[this.nodeKey] !== this.radioId) {
          value = data;
          key = data[this.nodeKey];
        } else if (this.radioCanCancel) {
          setTimeout(() => {
            this.radioId = "";
          }, 0);
        }
        clearTimeout(timeout);
        timeout = setTimeout(() => {
          this.$emit("choose-node", value);
          this.$emit("choose-key", key);
        }, 50);
      }
      this.$emit("node-click", data, ...params);
    },
    handleCheckChange() {
      this.$emit("choose-node", this.$refs.tree.getCheckedNodes(true));
      this.$emit("choose-key", this.$refs.tree.getCheckedKeys(true));
    },
    getNode(key) {
      return this.$refs.tree.getNode(key);
    }
  }
};
</script>