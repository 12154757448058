var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "header",
    {
      staticClass: "dsf-college-home-header",
      style: { "min-width": _vm.mainWidth + 20 + "px" },
    },
    [
      _c(
        "div",
        {
          staticClass: "dsf-college-home-header-main",
          style: { width: _vm.mainWidth + "px" },
        },
        [
          _c("div", { staticClass: "dsf-college-home-header-site" }, [
            _c("div", { staticClass: "logoBox" }, [
              _c("a", { attrs: { href: _vm.indexUrl } }, [
                _c("img", {
                  staticClass: "logo",
                  attrs: { src: _vm._f("imgFormat")(_vm.$logo), alt: "" },
                }),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "header-user" },
              [
                _c("home-search", {
                  attrs: {
                    placeholder: _vm.collegeRoot.searchPlaceholder,
                    search: _vm.collegeRoot.doSearch,
                  },
                  on: {
                    search: function ($event) {
                      return _vm.collegeRoot.$emit("search", $event)
                    },
                  },
                }),
                _vm.isLogin
                  ? [
                      _c(
                        "div",
                        {
                          staticClass: "header-user-icon",
                          style: {
                            "background-image":
                              "url(" + _vm.$replace(_vm.defaultHeader) + ")",
                          },
                        },
                        [
                          _c("img", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.showIcon,
                                expression: "showIcon",
                              },
                            ],
                            attrs: { src: _vm._f("imgFormat")(_vm.headIcon) },
                            on: {
                              error: _vm.headLoadError,
                              load: _vm.headLoadSuccess,
                            },
                          }),
                        ]
                      ),
                      !_vm.dropdownConfig.length
                        ? _c("div", { staticClass: "username" }, [
                            _vm._v(
                              " 欢迎您," +
                                _vm._s(_vm.dsf.cookies.get("user_name")) +
                                " "
                            ),
                            _vm.dropdownConfig.length
                              ? _c("i", {
                                  staticClass: "iconfont",
                                  class: [
                                    _vm.showDropdown
                                      ? "icon-shangjiantou"
                                      : "icon-xiajiantou",
                                  ],
                                })
                              : _vm._e(),
                          ])
                        : _c(
                            "el-dropdown",
                            {
                              attrs: { "show-timeout": 0, placement: "bottom" },
                              on: {
                                "visible-change": function ($event) {
                                  _vm.showDropdown = $event
                                },
                                command: _vm.handleCommand,
                              },
                            },
                            [
                              _c("div", { staticClass: "username" }, [
                                _vm._v(
                                  " 欢迎您," +
                                    _vm._s(_vm.dsf.cookies.get("user_name")) +
                                    " "
                                ),
                                _vm.dropdownConfig.length
                                  ? _c("i", {
                                      staticClass: "iconfont",
                                      class: [
                                        _vm.showDropdown
                                          ? "icon-shangjiantou"
                                          : "icon-xiajiantou",
                                      ],
                                    })
                                  : _vm._e(),
                              ]),
                              _c(
                                "el-dropdown-menu",
                                {
                                  attrs: { slot: "dropdown" },
                                  slot: "dropdown",
                                },
                                _vm._l(
                                  _vm.dropdownConfig,
                                  function (item, index) {
                                    return _c(
                                      "el-dropdown-item",
                                      {
                                        key: (item.key || "") + "__" + index,
                                        attrs: { command: index },
                                      },
                                      [_vm._v(" " + _vm._s(item.name) + " ")]
                                    )
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                    ]
                  : [
                      _vm._l(_vm.customBts, function (bt, index) {
                        return [
                          index
                            ? _c("i", {
                                key: index,
                                staticClass: "iconfont icon-vertical_line",
                              })
                            : _vm._e(),
                          _c(
                            "a",
                            {
                              key: index,
                              staticClass: "link",
                              on: {
                                click: function ($event) {
                                  return bt.handler()
                                },
                              },
                            },
                            [_vm._v(_vm._s(bt.name))]
                          ),
                        ]
                      }),
                    ],
              ],
              2
            ),
          ]),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showMenu,
                  expression: "showMenu",
                },
              ],
              staticClass: "dsf-college-home-header-menu",
            },
            [
              _c("div", {
                staticClass: "dsf-college-home-header-menu-bar",
                style: _vm.barStyle,
              }),
              _c(
                "div",
                { staticClass: "dsf-college-home-header-menu-list" },
                [
                  _vm._l(_vm.menuList, function (menu, index) {
                    return [
                      _c(
                        "div",
                        {
                          key: menu.menu_id,
                          ref: "menu",
                          refInFor: true,
                          staticClass: "dsf-college-home-header-menu-item",
                          class: { active: _vm.activeMenuIndex === index },
                          on: {
                            click: function ($event) {
                              return _vm.collegeRoot._clickBefore(
                                _vm.menuItemClick,
                                menu,
                                index
                              )
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(menu.menu_name) + " ")]
                      ),
                    ]
                  }),
                ],
                2
              ),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }