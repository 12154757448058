<template>
  <div
    v-show="show"
    class="ds-context-menu"
    :style="rootStyle"
    tabindex="0"
    @blur="blur"
    @contextmenu.prevent.stop
  >
    <dsf-context-menu-group v-if="show" :list="list" />
  </div>
</template>

<script>
import DsfContextMenuGroup from "./ContextMenuGroup";

export default {
  name: "DsfContextMenu",
  components: {
    DsfContextMenuGroup
  },
  provide() {
    return {
      contextMenuRoot: this
    };
  },
  data() {
    return {
      show: false,
      point: {
        x: 0,
        y: 0
      },
      list: []
    }
  },
  computed: {
    rootStyle() {
      let { x, y } = this.point;
      return {
        left: x + 'px',
        top: y + 'px'
      }
    }
  },
  methods: {
    open(point, params, data, el, e) {
      this.list = data;
      this.point = point;
      this.__params = params || null;
      this.__el = el;
      this.__e = e;
      el.classList.add("__context-active")
      this.show = true;
      this.$nextTick(() => {
        this.$el.focus();
      });
    },
    blur() {
      this.__el?.classList.remove("__context-active");
      this.list = [];
      this.__params = null;
      this.__el = null;
      this.__e = null;
      this.show = false;
    },
    inputChange() {
      let menu = this.uploadInput.menu;
      let res = this.uploadInput.res;
      let local = res?.params?.data
      const file = this.uploadInput.files[0];
      let loading = dsf.layer.loading();
      let {action, data, beforeHandler} = menu.upload;
      if (dsf.isFunction(beforeHandler)) {
        let res = beforeHandler(menu, file);
        if (res === false) return;
      }
      if (dsf.isFunction(data)) {
        data = data(menu);
      }
      if (dsf.isString(data)) {
        //在这处理表达式
        let _data =  this.$replace(data, local||this) ;
        //处理完转成的对象
        data = dsf.url.queryStringParse(_data)

      } else if (!dsf.isPlainObject(data)) {
        data = {};
      }
      dsf.http.upload(action, {
        ...data,
        file
      }).then(response => {
        menu.handler?.({
          response,
          menu,
          ...res
        });
      }).error(err => {
        dsf.error(err);
        dsf.layer.message(err?.message || '上传文件异常', false);
      }).always(() => {
        dsf.layer.closeLoading(loading);
      });
    },
    itemClick(menu){
      if (menu.children?.length || this.isDisabled(menu)) return;

      if (menu.upload) {
        let {accept} = menu.upload;
        if (dsf.isString(accept)) {
          accept = accept.replace(/\|/g, ',').split(',');
        }
        if (dsf.isArray(accept)) {
          accept = _.map(accept, (el) => {
            el = el.toLowerCase();
            return dsf.mime[el] || el;
          }).join(',');
        }
        let res = {
          params: this.__params,
          el: this.__el,
          e: this.__e
        };
        let uploadInput = document.createElement('input');
        uploadInput.type = 'file';
        uploadInput.accept = accept;
        uploadInput.menu = menu;
        uploadInput.res = res;
        uploadInput.onchange = this.inputChange;
        uploadInput.click();
        this.uploadInput = uploadInput;
      } else {
        menu.handler?.({
          menu,
          params: this.__params,
          el: this.__el,
          e: this.__e
        });
        this.$el.blur();
      }
    },
    isDisabled(menu) {
      let disabled = menu.disabled || false;
      if (dsf.type(disabled) === 'function') {
        let params = this.__params;
        return disabled({params, menu,el: this.__el,e: this.__e});
      }
      return !!disabled;
    },
    isHide(menu) {
      let hide = menu.hide || false;
      if (dsf.type(hide) === 'function') {
        let params = this.__params;
        return hide({params, menu,el: this.__el,e: this.__e});
      }
      return !!hide;
    }
  }
}
</script>