export default {
  install(Vue){
    Vue.filter('htmlLink',function(v){
      if (dsf.isDef(v)) {
        return `<a title="${v}" href="javascript:void(0)" class="ds-link text-proxy-link">${v}</a>`
      } else {
        return "";
      }
    })
  }
}