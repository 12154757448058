<!--
 * @Editor: chenqy
 * @Description: 操作栏
 * @Date: 2021-09-08 09:30:46
 * @LastEditors: Chenqy
 * @LastEditTime: 2022-01-14 16:20:33
-->
<template>
  <div class="bald-sync-screen-nav">
    <div class="bald-sync-screen-nav--operations">
      <div
        class="operation-item"
        :class="{ 'operation-item-tightness': item.type === 'tightness' }"
        v-for="(item, index) in operationBtns"
        :key="index"
      >
        <div class="color-btn" v-if="item.actionType === 'colorPanel'">
          <div class="color-btn-show">
            <div class="operation-item-icon">
              <i class="icon iconfont" :class="item.icon"></i>
            </div>
            <div class="operation-item-title">{{ item.name }}</div>
          </div>
          <el-color-picker
            class="color-btn-hide"
            v-model="color"
            show-alpha
            :predefine="predefineColors"
            @change="handleColor(item)"
          >
          </el-color-picker>
        </div>
        <div v-else-if="item.actionType === 'file'" class="file-btn">
          <div class="file-btn-show">
            <div class="operation-item-icon">
              <i class="icon iconfont" :class="item.icon"></i>
            </div>
            <div class="operation-item-title">{{ item.name }}</div>
          </div>
          <div class="file-btn-hide">
            <input type="file" @change="handleEvent(item, $event)" />
          </div>
        </div>
        <div
          class="normal-brn"
          v-else
          @click="handleEvent(item, item.params, index)"
        >
          <div
            class="operation-item-icon"
            :class="{ 'operation-active': item.active }"
          >
            <i class="icon iconfont" :class="item.icon"></i>
          </div>
          <div class="operation-item-title">{{ item.name }}</div>
        </div>
      </div>
      <div class="operation-item" v-if="!isTeacher" @click="handleQuit">
        <div class="normal-brn">
          <div class="operation-item-icon">
            <i class="icon iconfont icon-nav-exit"></i>
          </div>
          <div class="operation-item-title">退出主讲</div>
        </div>
      </div>
    </div>
    <!-- <div class="operation-question">
      <div class="operation-question-btn">学生提问</div>
      <div class="operation-question-btn">老师提问</div>
      <div v-if="!isTeacher && !isSperker" class="operation-question-btn">
        申请主讲
      </div>
      <div v-if="!isTeacher && isSperker" class="operation-question-btn">
        退出主讲
      </div>
    </div> -->
    <el-dialog
      :visible.sync="show"
      title="素材库"
      width="12rem"
      class="material-dialog"
    >
      <div class="material">
        <div class="material-header">
          <el-checkbox
            v-model="checkedAll"
            @change="handleCheckedAll"
            style="margin-top:3px"
          >全选</el-checkbox
          >
          <div v-show="showLoading">文件正在上传···<i class="el-icon-loading"></i></div>
          <el-upload
            class="upload-demo"
            action="/syncscreen/source/upload"
            multiple
            ref="fileForm"
            :limit="5"
            :before-upload="handleBeforeUpload"
            :on-success="handleSuccessUpload"
            :on-exceed="handleExceed"
            :data="uploadParams"
            :show-file-list="false"
          >
            <el-button size="small" type="primary">上传素材</el-button>
          </el-upload>
        </div>
        <div class="material-content">
          <div class="material-content-box">
            <div
              class="material-content-item"
              v-for="(item, index) in fileList"
              :key="index"
            >
              <div class="material-content-file">
                <el-checkbox v-model="item.checked" :key="index"></el-checkbox>
                <div class="material-content-file-icon">
                  <svg
                    v-if="item.fileType == 'jpg'"
                    t="1634698530199"
                    class="icon"
                    viewBox="0 0 1024 1024"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    p-id="57279"
                    width="200"
                    height="200"
                  >
                    <path
                      d="M202.625 62h450l225 225v618.75a56.25 56.25 0 0 1-56.25 56.25H202.625a56.25 56.25 0 0 1-56.25-56.25V118.25a56.25 56.25 0 0 1 56.25-56.25z"
                      fill="#F6AD00"
                      p-id="57280"
                    ></path>
                    <path
                      d="M289.221875 714.21875l82.0125-120.54375a12.740625 12.740625 0 0 1 19.490625-1.9125l54.196875 53.1 118.9125-186.384375a12.740625 12.740625 0 0 1 21.796875 0.478125l147.740625 256.05a12.740625 12.740625 0 0 1-11.053125 19.125H299.76875a12.740625 12.740625 0 0 1-10.546875-19.9125z"
                      fill="#FFF7F7"
                      p-id="57281"
                    ></path>
                    <path
                      d="M313.775 441.29375a64.29375 64.29375 0 1 0 128.5875 0 64.29375 64.29375 0 0 0-128.5875 0z"
                      fill="#FFFFFF"
                      p-id="57282"
                    ></path>
                    <path
                      d="M652.625 62l225 225h-168.75a56.25 56.25 0 0 1-56.25-56.25V62z"
                      fill="#FBDE99"
                      p-id="57283"
                    ></path>
                  </svg>
                  <svg
                    v-else-if="item.fileType == 'pptx'"
                    t="1634698589463"
                    class="icon"
                    viewBox="0 0 1024 1024"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    p-id="57409"
                    width="200"
                    height="200"
                  >
                    <path
                      d="M202.625 62h450l225 225v618.75a56.25 56.25 0 0 1-56.25 56.25H202.625a56.25 56.25 0 0 1-56.25-56.25V118.25a56.25 56.25 0 0 1 56.25-56.25z"
                      fill="#FF9333"
                      p-id="57410"
                    ></path>
                    <path
                      d="M438.0875 737v-138.0375h98.325C613.5875 598.9625 652.625 565.4375 652.625 498.78125 652.625 432.575 614.0375 399.5 536.890625 399.5H399.5v337.5h38.5875z m96.46875-171.5625h-96.46875v-132.384375h96.46875c26.803125 0 46.546875 5.203125 59.7375 16.565625 13.1625 9.9 19.74375 26.4375 19.74375 49.1625 0 22.66875-6.58125 39.20625-19.29375 50.090625-13.1625 10.85625-32.90625 16.5375-60.1875 16.5375z"
                      fill="#FFFFFF"
                      p-id="57411"
                    ></path>
                    <path
                      d="M652.625 62l225 225h-168.75a56.25 56.25 0 0 1-56.25-56.25V62z"
                      fill="#FFD3AD"
                      p-id="57412"
                    ></path>
                  </svg>
                  <svg
                    v-else-if="item.fileType == 'xls'"
                    t="1634698600847"
                    class="icon"
                    viewBox="0 0 1024 1024"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    p-id="57538"
                    width="200"
                    height="200"
                  >
                    <path
                      d="M202.625 62h450l225 225v618.75a56.25 56.25 0 0 1-56.25 56.25H202.625a56.25 56.25 0 0 1-56.25-56.25V118.25a56.25 56.25 0 0 1 56.25-56.25z"
                      fill="#4CB050"
                      p-id="57539"
                    ></path>
                    <path
                      d="M413.7875 737l100.771875-144.646875L615.865625 737H663.875l-126-175.359375L654.7625 399.5H606.78125l-92.221875 131.4L422.7875 399.5h-48.009375l116.015625 162.140625L365.75 737z"
                      fill="#FFFFFF"
                      p-id="57540"
                    ></path>
                    <path
                      d="M652.625 62l225 225h-168.75a56.25 56.25 0 0 1-56.25-56.25V62z"
                      fill="#B7DFB9"
                      p-id="57541"
                    ></path>
                  </svg>
                  <svg
                    v-else-if="item.fileType == 'docx'"
                    t="1634698607245"
                    class="icon"
                    viewBox="0 0 1024 1024"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    p-id="57667"
                    width="200"
                    height="200"
                  >
                    <path
                      d="M202.625 62h450l225 225v618.75a56.25 56.25 0 0 1-56.25 56.25H202.625a56.25 56.25 0 0 1-56.25-56.25V118.25a56.25 56.25 0 0 1 56.25-56.25z"
                      fill="#4876F9"
                      p-id="57668"
                    ></path>
                    <path
                      d="M438.2 737l67.78125-248.428125h1.2375L574.55 737h37.85625l85.21875-298.125h-38.25l-65.25 250.115625h-1.6875L525.078125 438.875H488.09375l-67.78125 250.115625h-1.6875L353.375 438.875H315.125L400.34375 737z"
                      fill="#FFFFFF"
                      p-id="57669"
                    ></path>
                    <path
                      d="M652.625 62l225 225h-168.75a56.25 56.25 0 0 1-56.25-56.25V62z"
                      fill="#B5C8FC"
                      p-id="57670"
                    ></path>
                  </svg>
                  <svg
                    v-else-if="item.fileType == 'video'"
                    t="1634698615315"
                    class="icon"
                    viewBox="0 0 1024 1024"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    p-id="57796"
                    width="200"
                    height="200"
                  >
                    <path
                      d="M202.625 62h450l225 225v618.75a56.25 56.25 0 0 1-56.25 56.25H202.625a56.25 56.25 0 0 1-56.25-56.25V118.25a56.25 56.25 0 0 1 56.25-56.25z"
                      fill="#7C8EEE"
                      p-id="57797"
                    ></path>
                    <path
                      d="M679.259375 571.146875l-262.40625 156.43125a26.325 26.325 0 0 1-39.853125-22.725V391.990625c0-20.08125 22.303125-32.765625 39.853125-22.725l262.40625 156.43125c16.9875 10.575 16.9875 35.409375 0 45.45z"
                      fill="#FFFFFF"
                      p-id="57798"
                    ></path>
                    <path
                      d="M652.625 62l225 225h-168.75a56.25 56.25 0 0 1-56.25-56.25V62z"
                      fill="#CAD1F8"
                      p-id="57799"
                    ></path>
                  </svg>
                  <svg
                    v-else-if="item.fileType == 'pdf'"
                    t="1634698623082"
                    class="icon"
                    viewBox="0 0 1024 1024"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    p-id="57925"
                    width="200"
                    height="200"
                  >
                    <path
                      d="M202.625 62h450l225 225v618.75a56.25 56.25 0 0 1-56.25 56.25H202.625a56.25 56.25 0 0 1-56.25-56.25V118.25a56.25 56.25 0 0 1 56.25-56.25z"
                      fill="#FA4E4E"
                      p-id="57926"
                    ></path>
                    <path
                      d="M658.165625 668.121875a143.325 143.325 0 0 1-83.25-33.834375c-46.096875 10.125-89.971875 24.80625-133.81875 42.8625-34.875 62.015625-67.5 93.6-95.625 93.6-5.625 0-12.375-1.125-16.875-4.5-12.375-5.625-19.096875-18.05625-19.096875-30.459375 0-10.153125 2.25-38.334375 109.096875-84.6 24.75-45.1125 43.875-91.35 59.625-139.8375-13.5-27.05625-42.75-93.6-22.5-127.434375 6.75-12.403125 20.25-19.18125 34.875-18.05625 11.25 0 22.5 5.625 29.25 14.68125 14.625 20.278125 13.5 63.140625-5.625 126.28125a340.03125 340.03125 0 0 0 69.721875 90.253125c23.625-4.5 47.25-7.903125 70.846875-7.903125 52.875 1.125 60.75 25.93125 59.625 40.6125 0 38.334375-37.125 38.334375-56.25 38.334375zM343.25 738.040625l3.375-1.125c15.75-5.625 28.125-16.903125 37.125-31.584375-16.875 6.75-30.375 18.05625-40.5 32.709375z m149.56875-338.34375h-3.375c-1.125 0-3.375 0-4.5 1.125-4.5 19.18125-1.125 39.4875 6.75 57.515625 6.75-19.153125 6.75-39.459375 1.125-58.640625z m7.875 163.546875l-1.125 2.25-1.125-1.125a932.90625 932.90625 0 0 1-33.75 76.696875l2.25-1.125v2.25a653.625 653.625 0 0 1 76.5-22.55625l-1.125-1.125h3.375c-16.875-16.93125-32.625-36.1125-45-55.265625z m152.971875 59.765625c-10.125 0-19.125 0-29.25 2.25 11.25 5.625 22.5 7.875 33.75 9.028125 7.875 1.125 15.75 0 22.5-2.25 0-3.375-4.5-9.028125-27-9.028125z"
                      fill="#FFFFFF"
                      p-id="57927"
                    ></path>
                    <path
                      d="M652.625 62l225 225h-168.75a56.25 56.25 0 0 1-56.25-56.25V62z"
                      fill="#FDB8B8"
                      p-id="57928"
                    ></path>
                  </svg>
                  <svg
                    v-else
                    t="1634698631267"
                    class="icon"
                    viewBox="0 0 1024 1024"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    p-id="58054"
                    width="200"
                    height="200"
                  >
                    <path
                      d="M202.625 62h450l225 225v618.75a56.25 56.25 0 0 1-56.25 56.25H202.625a56.25 56.25 0 0 1-56.25-56.25V118.25a56.25 56.25 0 0 1 56.25-56.25z"
                      fill="#576A95"
                      p-id="58055"
                    ></path>
                    <path
                      d="M573.875 615.05v122.23125a30.515625 30.515625 0 0 1-30.628125 30.54375H480.78125A30.65625 30.65625 0 0 1 450.125 737.28125v-122.23125h123.75z m-30.628125 70.03125H480.78125v61.36875h62.49375v-61.36875z m29.615625-192.234375V554.1875h-61.509375v-61.36875h61.509375zM512.1125 62v61.875H573.875v61.875h-61.7625v57.290625H573.875v61.875h-61.734375v61.115625H573.875v61.115625h-61.734375v61.115625H450.125v-61.115625h61.734375V366.03125H450.125V304.915625h61.734375v-61.875H450.125v-61.875h61.734375V123.875H450.125V62h62.015625z"
                      fill="#FFFFFF"
                      p-id="58056"
                    ></path>
                    <path
                      d="M652.625 62l225 225h-168.75a56.25 56.25 0 0 1-56.25-56.25V62z"
                      fill="#BBC3D4"
                      p-id="58057"
                    ></path>
                  </svg>
                </div>
                <div class="material-content-file-name">
                  <div>{{ item.name }}</div>
                  <!-- <div>08-27</div> -->
                </div>
              </div>
              <div class="metarial-content-operation">
                <span @click="handleFileView(item, item.fileType)">查看</span>
                <span @click="handleFileDown(item.id)">下载</span>
              </div>
            </div>
          </div>
        </div>
        <div class="material-footer">
          <div
            class="material-footer-btn material-footer-btn--normal"
            @click="handleFileDel"
          >
            批量删除
          </div>
          <div
            class="material-footer-btn material-footer-btn--active"
            @click="handleFileDown"
          >
            批量下载
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
// import operateListVue from "../../catalogue/operateList.vue";
import canvasDraw from "./canvasDraw";
export default {
  props: {
    // 是否为教师端工具栏
    isTeacher: {
      type: Boolean,
      default: true,
    },
    isSperker: {
      type: Boolean,
      default: false,
    },
    fileViewObj: {
      type: Object,
    },
  },
  data() {
    return {
      operationBtns: [
        {
          name: "撤销",
          icon: "icon-nav-revoke",
          action: "back",
          actionType: "btn",
          type: "tightness",
        },
        {
          name: "还原",
          icon: "icon-nav-reduction",
          action: "restore",
          actionType: "btn",
          type: "tightness",
        },
        {
          name: "画笔",
          icon: "icon-nav-brush",
          action: "pencil",
          actionType: "btn",
          type: "loose",
        },
        {
          name: "颜色",
          icon: "icon-nav-colour",
          action: "colorPanel",
          actionType: "colorPanel",
          type: "loose",
        },
        {
          name: "粗细",
          icon: "icon-nav-thickness",
          action: "width",
          actionType: "btn",
          type: "loose",
        },
        {
          name: "文字",
          icon: "icon-nav-word",
          action: "font",
          actionType: "btn",
          type: "loose",
        },
        {
          name: "圆",
          icon: "icon-nav-round",
          action: "circle",
          actionType: "btn",
          type: "loose",
        },
        {
          name: "方框",
          icon: "icon-nav-Whiteboard",
          action: "rect",
          actionType: "btn",
          type: "loose",
        },
        // {
        //   name: "表格",
        //   icon: "icon-nav-sheet",
        //   action: "table",
        //   actionType: "btn",
        //   type: "loose",
        // },
        // {
        //   name: "图片",
        //   icon: "icon-nav-img",
        //   action: "picture",
        //   actionType: "file",
        //   type: "loose",
        // },
        {
          name: "隐藏",
          icon: "icon-nav-hide",
          action: "hide",
          actionType: "btn",
          type: "loose",
        },
        {
          name: "显示",
          icon: "icon-nav-show",
          action: "show",
          active: true,
          actionType: "btn",
          type: "loose",
        },
        {
          name: "清除",
          icon: "icon-nav-clean",
          action: "clear",
          actionType: "btn",
          params: {
            isBtnClear: true,
          },
          type: "loose",
        },
        {
          name: "保存",
          icon: "icon-nav-save",
          action: "save",
          actionType: "btn",
          type: "loose",
        },
        {
          name: "记录",
          icon: "icon-nav-record",
          action: "record",
          actionType: "ptbtn",
          type: "loose",
        },
        {
          name: "素材库",
          icon: "icon-nav-materialLibrary",
          action: "material",
          actionType: "dialog",
          type: "loose",
        },
        {
          name: "会议白板",
          icon: "icon-nav-Whiteboard",
          action: "whiteBoard",
          actionType: "btn",
          type: "loose",
        },
      ],
      operationBtnsCopy: [],
      operationCanvas: null,
      fileCanvas: null, // 文件展示画板
      color: "#fff",
      predefineColors: [
        "#ff4500",
        "#ff8c00",
        "#ffd700",
        "#90ee90",
        "#00ced1",
        "#1e90ff",
        "#c71585",
        "rgba(255, 69, 0, 0.68)",
        "rgb(255, 120, 0)",
        "hsv(51, 100, 98)",
        "hsva(120, 40, 94, 0.5)",
        "hsl(181, 100%, 37%)",
        "hsla(209, 100%, 56%, 0.73)",
        "#c7158577",
      ],
      show: false,
      fileList: [], // 资源列表
      uploadParams: {},
      statusList: [], // 菜单栏状态 0网页 1文件(文件展示和被同屏网页各自有自己的状态)
      socket: null,
      showLoading: false
    };
  },
  computed: {
    checkedAll: {
      get() {
        if (this.fileList.length) {
          let t = true;
          this.fileList.forEach((ele) => {
            if (!ele.checked) {
              t = false;
            }
          });
          return t;
        } else {
          return false;
        }
      },
      set(newValue) {
        return newValue;
      },
    },
  },
  created() {
    this.getSourceList();
    let { roomId } = this.$route.query;
    this.uploadParams.roomId = roomId;
    this.uploadParams.sourceType = "0";
    this.operationBtnsCopy = _.cloneDeep(this.operationBtns);
  },
  mounted() {
    let self = this
    this.operationCanvas = new canvasDraw("syncScreen");
    window.canvasDraw = this.operationCanvas;
    this.socket = dsf.ws();
    this.socket.$on("syncscreen/ws/source", this.$route.query, function(data) {
      self.fileList.unshift(...data.data)
    })
  },
  methods: {
    handleEvent(data, param, index) {
      // 导航栏样式控制
      let withoutStyle = ["back", "restore", "save", "material", "clear"];
      let keepStyle = ["record", "width"];
      let checkOneStyle = ["show", "hide"];
      let copy = _.cloneDeep(this.operationBtns);
      if (checkOneStyle.includes(copy[index].action)) {
        copy[index].active = !copy[index].active;
        if (copy[index].action === "hide") {
          copy[index + 1].active = false;
        } else {
          copy[index - 1].active = false;
        }
      } else if (keepStyle.includes(copy[index].action)) {
        copy[index].active = copy[index].active ? false : true;
      } else if (withoutStyle.includes(copy[index].action)) {
        // TODO 暂时还没有想到要做啥
      } else {
        for (let i = 0; i < copy.length; i++) {
          if (i === index) {
            copy[i].active = !copy[i].active;
          } else {
            if (
              keepStyle.includes(copy[i].action) ||
              withoutStyle.includes(copy[i].action) ||
              checkOneStyle.includes(copy[i].action)
            ) {
              continue;
            }
            copy[i].active = false;
          }
        }
      }

      this.$set(this, "operationBtns", copy);
      if (data.actionType === "btn" || data.actionType === "file") {
        // 判断当前是否为文件展示
        let fileMode = document
          .querySelector("#syncScreen")
          .contentWindow.document.querySelector(".dsf-pre").style.opacity;
        if (fileMode != 1) {
          this.operationCanvas.handleEventDistribute(data.action, param);
        } else if (this.fileCanvas && fileMode == 1) {
          this.fileCanvas.handleEventDistribute(data.action, param);
        }
      } else if (data.actionType === "ptbtn") {
        let action = `handle${data.action}`;
        this.$parent[action] && this.$parent[action](copy[index].active);
      } else if (data.actionType === "dialog") {
        this.show = true;
      }
    },
    handleColor(data) {
      if (this.fileCanvas) {
        this.fileCanvas.handleEventDistribute(data.action, this.color);
      }
      this.operationCanvas.handleEventDistribute(data.action, this.color);
    },
    // 退出主讲
    handleQuit() {
      this.$parent.handleBeMain("esc");
    },
    // 获取资源列表
    getSourceList() {
      let { roomId } = this.$route.query;
      this.$http
        .get("syncscreen/source/list", { roomId: roomId })
        .done((re) => {
          if (re.success) {
            let items = re.data.map((item) => {
              item.checked = false;
              return item;
            });
            this.fileList = items;
          } else {
            dsf.layer.message(re.message, false);
          }
        });
    },
    // 选择全部
    handleCheckedAll(e) {
      this.fileList.forEach((element) => {
        element.checked = e;
      });
    },
    // 查看
    async handleFileView(data, type, pathIndex) {
      if (data) {
        await this.fileViewObj.renderContent(data.path, "", { type });
        this.show = false;
      }
      if (type !== "mp4" && type !== "video") {
        this.fileCanvas = new canvasDraw(
          "syncScreen",
          this.fileViewObj.drawCanvas,
          pathIndex
        );
        window.fileCanvasDraw = this.fileCanvas;
        // 重置菜单状态
        this.statusList[0] = this.operationBtns;
        this.operationBtns = this.operationBtnsCopy;
      }
    },
    // 删除
    handleFileDel() {
      let checked = [];
      this.fileList.forEach((ele) => {
        if (ele.checked) {
          checked.push(ele.id);
        }
      });
      this.$http
        .post("/syncscreen/source/delete", { sourceIds: checked.join(",") })
        .done((re) => {
          this.getSourceList();
        });
    },
    // 下载
    handleFileDown(id) {
      let checked = [];
      if (id) {
        checked = [id];
      } else {
        this.fileList.forEach((ele) => {
          if (ele.checked) {
            checked.push(ele.id);
          }
        });
      }
      this.$http
        .get(
          "/syncscreen/source/download",
          { sourceIds: checked.join(",") },
          {
            headers: { "Content-Type": "multipart/form-data" },
            responseType: "blob",
          }
        )
        .done((re) => {
          if (re.success) {
            let blob = new Blob([re.data], {});
            var url = URL.createObjectURL(blob);
            let a = document.createElement("a");
            a.href = url;
            a.click();
          }
        });
    },
    // 上传文件
    handleBeforeUpload(file) {
      const standerd = [
        "application/pdf",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/vnd.openxmlformats-officedocument.presentationml.presentation",
        "video/mp4",
      ];
      if (standerd.includes(file.type)) {
        this.showLoading = true
        return true;
      } else {
        dsf.layer.message(
          "上传文件只能是mp4,doc,docx,ppt,pptx,xls,xlsx格式",
          false
        );
      }
    },
    handleSuccessUpload(response) {
      this.showLoading = false
      if (!response.success) {
        dsf.layer.message(response.message, false);
      }
    },
    handleExceed() {
      dsf.layer.message(`上传文件数必须最多5个`, false);
    },
    handleFileChange(file, fileList) {
      console.log(file, fileList);
    },
    // 改变菜单状态
    handleChangeMenuStatus() {
      if (this.fileCanvas) {
        this.operationBtns = this.statusList[0];
      }
    }
  },
};
</script>