<template>
  <div v-if="isDesign" class="ds-platform-card-item-by-teacher">
    <div class="avatar">
      <dsf-image class="avatar-pic"></dsf-image>
    </div>
    <div class="info">
      <p class="name">张三</p>
      <div class="middle">
        <p class="line-2">中央党校（国家行政学院）</p>
        <p>副校长</p>
      </div>
      <p class="course">15门精品课程 >></p>
    </div>
  </div>
  <div v-else class="ds-platform-card-item-by-teacher" @click="_clickBefore(itemClick)">
    <div class="avatar">
      <dsf-image class="avatar-pic" :src="data['avatar'] | imgFormat" :error-src="errorSrc" />
    </div>
    <div class="info">
      <p class="name" :title="data['name']">{{ data['name'] }}</p>
      <div class="middle">
        <p class="line-1" :title="data['unit']">{{ data['unit'] }}</p>
        <p class="line-2" :title="data['position']">{{ data['position'] }}</p>
      </div>
      <p class="course" @click.stop="_clickBefore(courseClick)">{{ data['courseNum'] }}门精品课程 >></p>
    </div>
  </div>
</template>

<script>
export default dsf.component({
  name: "DsfPlatformCardItemByTeacher",
  ctrlCaption: "教师卡片",
  mixins: [$mixins.card],
  props: {
    keysMap: {
      type: Array,
      default() {
        return [
          { from: 'avatar', to: 'avatar', type: 'text', desc: '头像' },
          { from: 'name', to: 'name', type: 'text', desc: '名称' },
          { from: 'unit', to: 'unit', type: 'text', desc: '任职单位' },
          { from: 'position', to: 'position', type: 'text', desc: '职位' },
          { from: 'courseNum', to: 'courseNum', type: 'text|number', desc: '课程数量' }
        ];
      }
    },
    // 点击课程跳转设置
    toCourseConfig: {
      type: Object,
      default() {
        return {};
      }
    },
    // 详情跳转链接
    toDetailConfig: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  methods: {
    itemClick() {
      const { toDetailConfig, local } = this
      if (this.$listeners.itemClick) {
        this.$dispath('itemClick', local)
      } else {
        this.$open({...toDetailConfig, url: this.$replace(toDetailConfig.url, local)});
      }
      this.clickAfter();
    },
    courseClick() {
      const { toCourseConfig, local } = this
      if (this.$listeners.courseClick) {
        this.$dispath('courseClick',local)
      } else {
        this.$open({...toCourseConfig, url: this.$replace(toCourseConfig.url, local)});
      }
      this.clickAfter();
    }
  }
});
</script>