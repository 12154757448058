var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.isDesign
    ? _c(
        "div",
        {
          staticClass: "ds-control ds-viewpart ds-no-padding",
          class: _vm.getCss,
          style: { "background-color": _vm.backgroundColor },
          attrs: { path: _vm.getPath() },
        },
        [
          _vm.componentName
            ? _c(
                _vm.componentName,
                _vm._b(
                  {
                    ref: "view",
                    tag: "component",
                    attrs: {
                      "name-space": _vm.nameSpace,
                      "page-name": _vm.pageName,
                      "local-params": _vm.localParams,
                      "query-string": _vm.params,
                      "page-props": _vm.$pageProps,
                    },
                    on: { ready: _vm.viewReady, yes: _vm.yes },
                  },
                  "component",
                  _vm.getProps,
                  false
                )
              )
            : _vm._e(),
        ],
        1
      )
    : _c(
        "div",
        {
          staticClass: "ds-control ds-viewpart ds-no-padding",
          class: _vm.getCss,
        },
        [
          _c("div", { staticClass: "ds-viewparent-desgine-panel" }, [
            _c("span", [_vm._v(_vm._s(_vm.path) + "页面片段")]),
          ]),
        ]
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }