<template>
  <div style="width:100%">
    <template v-if="!isBetween">
      <div class="ds-supersearch-input">
        <el-input ref="input" v-model="value" :placeholder="$placeholder||'请输入'" @change="change(item)"></el-input>
      </div>
    </template>
    <template v-else>
      <div class="ds-supersearch-input">
        <div :class="{'ds-supersearch-input-range':isBetween,'ds-supersearch-input-focus':isFocus}">
          <el-input ref="sinput" @focus="inputFocus" @blur="inputBlur" v-model="value[0]" :placeholder="$placeholder[0]||'请输入'" @mousedown.native="clickHandler" @change="change(item)" />
          <span @click.stop.prevent @mousedown.stop.prevent>至</span>
          <el-input ref="einput" @focus="inputFocus" @blur="inputBlur" v-model="value[1]" :placeholder="$placeholder[1]||'请输入'" @mousedown.native="clickHandler" @change="change(item)" />
        </div>
      </div>
    </template>

  </div>
</template>
<script>
export default {
  name: "DsfSuperSearchInput",
  props: {
    values: {
      type: [Array, String],
      default() {
        return [];
      }
    },
    item: {
      type: Object,
      default() {
        return {};
      }
    },
    placeholder: {
      type: [String,Array],
      default() {
        return ["请输入"];
      }
    }
  },
  data() {
    return {
      value: null,
      isFocus: false,
      isActive: false
    };
  },
  computed: {
    // value: {
    //   get() {
    //     return this.values;
    //   },
    //   set(v) {
    //     if (this.isBetween) {
    //       this.$set(this.values, 0, v[0]);
    //       this.$set(this.values, 1, v[1]);
    //     } else {
    //       this.values=v
    //     }
    //   }
    // },
    $values() {
      let v = this.values;
      if (this.isBetween) {
        return dsf.type(v) == "array" ? v : [v];
      } else {
        return dsf.type(v) == "array" ? v.join("") : v;
      }
    },
    $placeholder() {
      let p = this.placeholder;
      if (this.isBetween) {
        p = dsf.type(p) == "array" ? p : p.split(";");
        if (this.isBetween) {
          if (!p[1]) {
            p[1] = p[0];
          }
        }
        return p;
      }
      return this.placeholder;
    },
    isBetween() {
      return this.item.operator == "between";
    }
  },
  beforeCreate() {},
  created() {
    this.value = this.$values;
  },
  beforeMount() {},
  mounted() {},
  beforeDestroy() {},
  destroyed() {},
  methods: {
    clickHandler() {
      this.isActive = true;
    },
    inputFocus() {
      this.isFocus = true;
      this.isActive = true;
    },
    inputBlur() {
      this.isFocus = false;
      this.isActive = false;
    },
    change() {
      this.$emit("update:values", this.value);
      window.setTimeout(() => {
        if (this.isBetween) {
          if (this.isActive) {
            return;
          }
        }
        this.$nextTick(() => {
          this.$emit("change");
        });
      }, 100);
    }
  },
  watch: {
    values(v, ov) {
      this.value = this.$values;
    }
  }
};
</script>