<template>
  <div class="ds-control ds-no-padding el-col" :class="getCss" :style="getStyle" slot-name="default">
    <slot></slot>
  </div>
</template>
<script>
/**
 * @class DsfCol
 * @augments Layout
 * @description 栅格列
 * @example
 * <DsfCol></DsfCol>
 */
export default dsf.component({
  name: "DsfCol",
  ctrlCaption: "栅格列",
  mixins: [$mixins.layout],
  inject: {
    parentRow: {
      default: null
    }
  },
  /**
   * @memberof DsfCol
   * @name Props
   * @property {Number} [span = 24] 分成多少份
   * @property {Number} [xs = null] 小于768px
   * @property {Number} [sm = null] 大于等于768px
   * @property {Number} [md = null] 大于等于992px
   * @property {Number} [xl = null] 大于等于1200px
   * @property {Number} [lg = null] 大于等于1920px
   * @property {Number} [offset = null] 偏移距离
   * @property {Array} [slots = '[{ name: "default", controls: [] }]'] 插槽
   * @property {Strinng|Number} [height = auto] 高度
   */
  /**
   * @namespace DsfColSlot
   * @property {String} [name = default] 对应的slot name
   * @property {Array} [controls = []] 对应的slot内容
   */
  props: {
    span: {
      type: Number,
      default: 24
    },
    xs: {
      type: Number,
      default: null
    },
    //≥768px
    sm: {
      type: Number,
      default: null
    },
    //≥992px
    md: {
      type: Number,
      default: null
    },
    //≥1200px
    xl: {
      type: Number,
      default: undefined
    },
    //≥1920px
    lg: {
      type: Number,
      default: null
    },
    offset: {
      type: Number,
      default: null
    },
    slots: {
      type: Array,
      default() {
        return [{ name: "default", controls: [] }];
      }
    },
    height: {
      type: [String, Number],
      default: "auto"
    }
  },
  computed: {
    getSpanNum() {
      let span = this.span;
      let xs = this.xs;
      let sm = this.sm;
      let md = this.md;
      let xl = this.xl;
      let lg = this.lg;
      let offset = this.offset;
      let classList = [this.span ? "el-col-" + span : "", this.xs ? "el-col-xs-" + xs : "", this.sm ? "el-col-sm-" + sm : "", this.md ? "el-col-md-" + md : "", this.xl ? "el-col-xl-" + xl : "", this.lg ? "el-col-lg-" + lg : "", this.offset ? "el-col-offset-" + offset : ""];
      return classList;
    },
    gutter() {
      let row = this.parentRow;
      if (row) {
        return row.gutter;
      }
      return 0;
    },
    // parentRow() {
    //   let row = this.$parent;
    //   let count = 0;
    //   while (row && row.$options.name != "DsfRow") {
    //     row = row.$parent;
    //     count++;
    //     if (count > 10) {
    //       row = null;
    //       return;
    //     }
    //   }
    //   return row;
    // },
    getCss() {
      return [...this.$getClass(), ...this.getSpanNum];
    },
    getStyle() {
      let height = this.height;
      height = this.unitSize(this.height);
      let style = {
        height: height,
        "padding-left": this.unitSize(this.gutter / 2),
        "padding-right": this.unitSize(this.gutter / 2)
      };
      if (!this.isDesign) {
        if (this.parentRow.minHeight) {
          style["min-height"] = this.unitSize(this.parentRow.minHeight);
        }
      }
      return style;
    }
  },
  created() {
    if (!this.isDesign) {
      if (this.$vm && this.local) {
        this.$parent.cols.push(this);
      }
    }
  },
  mounted() {}
});
</script>
