<template>
  <div class="ds-control ds-form-item ds-radio-box" :class="getCss">
    <template v-if="simple && readOnly && !isDesign">
      <DsfTextProxy v-model="value" v-bind="_self.$props" :show-label="false" />
      <slot name="error">
        <div v-if="errors.length" class="ds-error-text">{{ errorsMsg }}</div>
      </slot>
    </template>
    <template v-else>
      <!-- <label v-if="showLabel" class="ds-form-label" :style="getLabelWidthStyle()">
        {{ label }}
        <i v-if="dataCapion" class="iconfont icon-bangzhuzhongxin ds-control-helper" @click="showHelper"></i>
      </label> -->
      <DsfFieldLabel
        v-if="showLabel"
        :mode="showDataCaptionMode"
        :style="getLabelWidthStyle()"
        :show-icon="showDataCaptionIcon" 
        :trigger="showDataCaptionTrigger"
        :data-caption="dataCapion"
        :is-design="isDesign"
        :owner="_self">
        {{ $t(label) }}
      </DsfFieldLabel>
      <div class="ds-form-block" :class="{border: border || isButton}" :style="getFormItemBlockMarginLeft()">
        <!--只读状态只显示div-->
        <div v-if="readOnly" class="ds-form-readonly">
          <DsfTextProxy v-model="value" v-bind="_self.$props" :show-label="false" />
        </div>
        <el-radio-group v-else-if="isButton" v-model="selectValue" :disabled="disabled" @change="selectChange">
          <template v-for="(item, index) in realItems">
            <el-radio-button :key="index" ref="radio" :label="item[valueField]" :disabled="item.disabled || disabledItemValues.indexOf(item[valueField]) > -1" @click.native.stop="itemClick(item, index)">
              {{ item[optionsTextField] }}
            </el-radio-button>
          </template>
        </el-radio-group>
        <el-radio-group v-else-if="!attachItemValues.length" v-model="selectValue" :disabled="disabled" @change="selectChange">
          <template v-for="(item, index) in realItems">
            <el-radio :key="index" ref="radio" :label="item[valueField]" :border="border" :disabled="item.disabled || disabledItemValues.indexOf(item[valueField]) > -1" @click.native.stop="itemClick(item, index)">
              {{ item[optionsTextField] }}
            </el-radio>
          </template>
        </el-radio-group>
        <el-radio-group v-else v-model="selectValue" :disabled="disabled" @change="selectChange">
          <template v-for="(item, index) in realItems">
            <div :key="index" class="ds-radio-item">
              <el-radio
                ref="radio"
                :label="item[valueField]"
                :border="border"
                :disabled="item.disabled || disabledItemValues.indexOf(item[valueField]) > -1"
                @click.native.stop="itemClick(item, index)">
                {{ item[optionsTextField] }}
              </el-radio>
              <slot name="append-input">
                <el-input
                  v-if="attachOptions[item[valueField]] !== void 0"
                  :value="attachOptions[item[valueField]]"
                  size="small"
                  class="ds-radio-input"
                  :class="{'is-bordered': border}"
                  :style="{width: attachWidth}"
                  placeholder="请输入内容"
                  @input="setAttach(item[valueField], $event)"
                />
              </slot>
            </div>
          </template>
        </el-radio-group>
        <slot name="error">
          <div v-if="errors.length" class="ds-error-text">{{ errorsMsg }}</div>
        </slot>
      </div>
    </template>
  </div>
</template>

<script>

let missItem = { value: "", text: "" };

export default dsf.component({
  name: "DsfRadioBox",
  mixins: [$mixins.formControl, $mixins.itemsMixins],
  ctrlCaption: "单选",
  props: {
    simple: {
      type: Boolean,
      default: false
    },
    // 需要禁用选项的value值得集合
    disabledItemValues: {
      type: Array,
      default() {
        return [];
      }
    },
    type: {
      type: Number,
      validator: function (value) {
        // 0: 普通；1：带边框；2：按钮
        return [0, 1, 2].indexOf(parseInt(value)) !== -1;
      },
      default: 0
    },
    // 是否可取消
    cancel: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      multiple: false
    };
  },
  computed: {
    border() {
      return this.type === 1;
    },
    isButton() {
      return this.type === 2;
    },
  },
  methods: {
    itemClick(item, index) {
      let $radio = this.$refs.radio[index].$el;
      if (!this.cancel || this.disabled || item.disabled) {
        $radio.removeAttribute("data-same");
        return;
      }
      let isSame = this.selectValue == item.value;
      let dataSame = $radio.getAttribute("data-same");
      if (dataSame == null) {
        $radio.setAttribute("data-same", isSame ? "1" : "0");
        return;
      }
      if (isSame && dataSame == "1") {
        this.selectValue = null;
      }
      $radio.removeAttribute("data-same");
    }
  }
});
</script>
