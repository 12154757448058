var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ds-control ds-table-layout", class: _vm.getCss },
    [
      _c("div", { staticClass: "ds-table-layout-box" }, [
        _c(
          "table",
          {
            directives: [
              {
                name: "resize",
                rawName: "v-resize.x.y",
                value: {
                  colList: "columnsInfo",
                  rowList: "rows",
                  onChangeCol: _vm.onChangeCol,
                  onChangeRow: _vm.onChangeRow,
                },
                expression:
                  "{\n        colList: 'columnsInfo',\n        rowList: 'rows',\n        onChangeCol: onChangeCol,\n        onChangeRow: onChangeRow,\n      }",
                modifiers: { x: true, y: true },
              },
            ],
            ref: "table",
          },
          [
            _c("thead", [
              _vm.columnsInfo && _vm.rows && _vm.rows.length > 0
                ? _c(
                    "tr",
                    _vm._l(_vm.columnsInfo, function (col, $index) {
                      return _c("th", {
                        key: $index,
                        style: { width: col.width || "auto" },
                      })
                    }),
                    0
                  )
                : _vm._e(),
            ]),
            _c(
              "tbody",
              [_vm._t("prepend"), _vm._t("default"), _vm._t("append")],
              2
            ),
          ]
        ),
        _vm.isDesign
          ? _c("div", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.rangeWrap.show,
                  expression: "rangeWrap.show",
                },
              ],
              staticClass: "selected-range",
              style: {
                height: _vm.rangeWrap.height + "px",
                width: _vm.rangeWrap.width + "px",
                top: _vm.rangeWrap.top + "px",
                left: _vm.rangeWrap.left + "px",
              },
            })
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }