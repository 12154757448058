<template>
  <div class="ds-page-home-header-user">
    <div class="user-icon" :style="{'background-image': `url(${defaultUserIcon})`}">
      <img v-show="showIcon" src="/user/getUserPhoto" alt="" @error="headLoadError" @load="headLoadSuccess" />
    </div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "DsfPageHomeHeaderUser",
  props: {

  },
  data() {
    return {
      // 是否显示头像
      showIcon: false,
      // 用户头像
      // 用户名
      userName: dsf.cookies.get("user_name"),
    }
  },
  computed: {
    // 用户默认头像
    defaultUserIcon() {
      let url = dsf.config.setting_public_user_default_header;
      return this.$replace(url);
    }
  },
  created() {},
  mounted() {

  },
  methods: {
    // 头像加载成功时
    headLoadSuccess() {
      this.showIcon = true;
    },
    // 头像加载失败时
    headLoadError() {
      this.showIcon = false;
    },
  }
}
</script>