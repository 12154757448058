<template>
  <div v-loading="loading" class="dsf-control ds-schedule">
    <div v-if="classInfo" class="className">{{ classInfo.name || "" }}</div>
    <dsf-calendar-nav
      :is-design="isDesign"
      :url="url1"
      :to-day="toDay"
      :is-switch="isSwitch"
      :data-loaded-fun="dataLoadedFun"
      :is-parameter="isParameter"
      :type="settingConfig.type"
      @change="scheduleChange"
    ></dsf-calendar-nav>
    <div class="ds-schedule-content">
      <dsf-empty-data v-if="!loading && !list.length"></dsf-empty-data>
      <slot name="list" :list="list">
        <template v-for="(item, index) in list">
          <div class="ds-schedule-item-slot" :key="index">
            <slot :item="item" :index="index">
              <div class="ds-schedule-item">
                <slot>
                  <div
                    class="ds-schedule-item-title"
                    :class="{ ellipsis: settingConfig.ellipsis }"
                  >
                    {{ item.theme || "" }}
                  </div>
                  <div class="ds-schedule-item-info">
                    <span class="ds-schedule-item-time">
                      {{ item.startTime || "" }} - {{ item.endTime || "" }}
                    </span>
                    <span v-if="!classInfo" class="ds-schedule-item-className">
                      {{ item.addressText || "" }}
                    </span>
                  </div>
                </slot>
              </div>
            </slot>
          </div>
        </template>
      </slot>
      <slot name="list-bottom"></slot>
    </div>
  </div>
</template>

<script>
export default dsf.component({
  name: "DsfScheduleListItem",
  mixins: [$mixins.control],
  ctrlCaption: "日程列表组件",
  design: {
    isMask: false,
  },
  props: {
    url: {
      type: String,
      default: null,
    },
    classInfo: {
      type: [Object, String],
      default: null,
    },
    isParameter: {
      type: Boolean,
      default: false,
    },
    // 是否允许切换周
    isSwitch: {
      type: Boolean,
      default: false,
    },
    // 时间
    toDay: {
      type: [String, Date],
      default() {
        return new Date();
      },
    },
    // 个性化配置
    settingConfig: {
      type: Object,
      default() {
        return {
          pageSize: 5,
          type: "week",
          ellipsis: true,
        };
      },
    },
  },
  data() {
    return {
      // 请求次数
      postNum: 0,
      loading: false,
      list: [],
      url2: "",
    };
  },
  computed: {
    realList() {
      return this.list.slice(0, this.settingConfig.pageSize);
    },
    classIdParam() {
      return this.classInfo ? "?classId=" + this.classInfo.id : "";
    },
    url1() {
      if (!this.url || this.url == "") {
        return null;
      }
      return "/agenda/info/queryUserAgendaNumByDate";
    },
  },
  created() {
    if (this.isDesign) {
      for (var i = 0; i < 10; i++) {
        this.list.push({
          theme: "20200118jinhy排课测试课程",
          startTime: "24日14:30",
          endTime: "24日17:00",
          addressText: "金皓宇-知新101",
        });
      }
    }
  },
  mounted() {
    if (this.isDesign) {
      this.$dispatch("design-height-change", "100%");
    }
  },
  methods: {
    reloadData() {
      this.postData();
    },
    dataLoadedFun(data, vm) {
      let days = [];
      _.forEach(data, (n, d) => {
        if (n > 0) {
          days.push(d);
        }
      });
      if (days.length && days.indexOf(vm.active) === -1) {
        days.sort();
        vm.active = _.find(vm.range, (r) => r.id === days[0]);
      }
    },
    scheduleChange({ args: { id } }) {
      if (!this.url || this.url == "") {
        this.url2 = null;
      } else {
        this.url2 =
          this.url + "?startDate=" + id + "&endDate=" + id + this.classIdParam;
      }
      this.postData();
    },
    postData() {
      if (this.isDesign) return;
      this.postNum++;
      if (!this.url2 || this.url2 == "") {
        this.list = [];
        return;
      }
      this.loading = true;
      this.$http
        .get(this.url2)
        .done(({ success, data, message }) => {
          if (success) {
            let list = Object.values(data);
            this.list = list.length ? list[0] : [];
          } else {
            dsf.layer.message(message, false);
          }
        })
        .error((err) => {
          dsf.error(err);
          dsf.layer.message(err.message || "请求异常", false);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
});
</script>
