var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "DsfHomeTabPanel",
    _vm._b(
      { attrs: { borderRadius: _vm.borderRadius, isDesign: _vm.isDesign } },
      "DsfHomeTabPanel",
      _vm.daibanPanelConfig,
      false,
      true
    ),
    [
      _vm._l(_vm.daibanPanelConfig.slots, function (slot, index) {
        return _c(
          "template",
          { slot: slot.name },
          [
            _c(
              "DsfHomePendingProcess",
              _vm._g(
                _vm._b(
                  {
                    key: index,
                    attrs: {
                      toConfig: slot.rowClick
                        ? slot.rowClick
                        : _vm.daibanPanelContentToConfig,
                      nodeNameShow: _vm.daibanPanelConfig.nodeNameShow,
                      url: _vm.buildUrl(slot.url),
                      isDesign: _vm.isDesign,
                      pageSize: _vm.pageSize,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "operating",
                          fn: function (scope) {
                            return [_vm._t("operating", null, null, scope)]
                          },
                        },
                        {
                          key: "row",
                          fn: function (scope) {
                            return [_vm._t("row", null, null, scope)]
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  },
                  "DsfHomePendingProcess",
                  _vm.daibanPanelContentConfig[slot.name],
                  false,
                  true
                ),
                _vm.$listeners
              )
            ),
          ],
          1
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }