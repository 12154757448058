var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "ds-control ds-form-item ds-area-select",
      class: _vm.getCss,
    },
    [
      _vm.simple && _vm.readOnly && !_vm.isDesign
        ? [
            _c(
              "DsfTextProxy",
              _vm._b(
                {
                  model: {
                    value: _vm.value,
                    callback: function ($$v) {
                      _vm.value = $$v
                    },
                    expression: "value",
                  },
                },
                "DsfTextProxy",
                _vm._self.$props,
                false
              )
            ),
            _vm._t("error", function () {
              return [
                _vm.errors.length > 0
                  ? _c("div", { staticClass: "ds-error-text" }, [
                      _vm._v(_vm._s(_vm.errorsMsg)),
                    ])
                  : _vm._e(),
              ]
            }),
          ]
        : [
            _vm.showLabel
              ? _c(
                  "DsfFieldLabel",
                  {
                    style: _vm.getLabelWidthStyle(),
                    attrs: {
                      mode: _vm.showDataCaptionMode,
                      "show-icon": _vm.showDataCaptionIcon,
                      trigger: _vm.showDataCaptionTrigger,
                      "data-caption": _vm.dataCapion,
                      "is-design": _vm.isDesign,
                      owner: _vm._self,
                    },
                  },
                  [_vm._v(" " + _vm._s(_vm.$t(_vm.label)) + " ")]
                )
              : _vm._e(),
            _c(
              "div",
              {
                staticClass: "ds-form-block",
                class: { "ds-error-border": _vm.errors.length > 0 },
                style: _vm.getFormItemBlockMarginLeft(),
              },
              [
                _vm.readOnly
                  ? _c(
                      "div",
                      { staticClass: "ds-form-readonly" },
                      [
                        _c(
                          "DsfTextProxy",
                          _vm._b(
                            {
                              attrs: { "show-label": false },
                              model: {
                                value: _vm.value,
                                callback: function ($$v) {
                                  _vm.value = $$v
                                },
                                expression: "value",
                              },
                            },
                            "DsfTextProxy",
                            _vm._self.$props,
                            false
                          )
                        ),
                      ],
                      1
                    )
                  : _vm.showCascader
                  ? _c("el-cascader", {
                      ref: "cascader",
                      staticClass: "ds-area-select-cascader",
                      attrs: {
                        placeholder: _vm.placeholder,
                        props: _vm.options,
                        options: _vm.items,
                        "show-all-levels": _vm.showAllLevels,
                        clearable: "",
                        filterable: "",
                      },
                      on: { change: _vm.changeHandler },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (ref) {
                            var node = ref.node
                            var data = ref.data
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    data[_vm.optionsTextField] || data.name
                                  ) +
                                  " "
                              ),
                            ]
                          },
                        },
                      ]),
                      model: {
                        value: _vm.cascaderValue,
                        callback: function ($$v) {
                          _vm.cascaderValue = $$v
                        },
                        expression: "cascaderValue",
                      },
                    })
                  : _vm._e(),
                _vm._t("error", function () {
                  return [
                    _vm.errors.length > 0
                      ? _c("div", { staticClass: "ds-error-text" }, [
                          _vm._v(_vm._s(_vm.errorsMsg)),
                        ])
                      : _vm._e(),
                  ]
                }),
              ],
              2
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }