<template>
  <div class="ds-control ds-form-item ds-switch" :class="getCss">
    <template v-if="simple && readOnly && !isDesign">
      <DsfTextProxy v-model="value" v-bind="_self.$props" :show-label="false"/>
      <slot name="error">
        <div v-if="errors.length > 0" class="ds-error-text">
          {{ errorsMsg }}
        </div>
      </slot>
    </template>
    <template v-else>
      <!--<label v-if="showLabel" class="ds-form-label" :style="getLabelWidthStyle()">
        {{ label }}
        <i v-if="dataCapion" class="iconfont icon-bangzhuzhongxin ds-control-helper" @click="showHelper"></i>
      </label>-->
      <DsfFieldLabel
        v-if="showLabel"
        :mode="showDataCaptionMode"
        :style="getLabelWidthStyle()"
        :show-icon="showDataCaptionIcon"
        :trigger="showDataCaptionTrigger"
        :data-caption="dataCapion"
        :is-design="isDesign"
        :owner="_self">
        {{ $t(label) }}
      </DsfFieldLabel>
      <div class="ds-form-block" :style="getFormItemBlockMarginLeft()">
        <!--只读状态只显示div-->
        <div v-if="readOnly" class="ds-form-readonly">
          <DsfTextProxy v-model="value" v-bind="_self.$props" :show-label="false"></DsfTextProxy>
        </div>
        <el-switch v-else v-model="switchValue" v-bind="bindAttr" :class="{inset: showText && insetText}" @change="selectChange" />
        <slot name="error">
          <div v-if="errors.length > 0" class="ds-error-text">
            {{ errorsMsg }}
          </div>
        </slot>
      </div>
    </template>
  </div>
</template>

<script>
const missItem = { value: 0, text: "否" };

export default dsf.component({
  name: "DsfSwitch",
  mixins: [$mixins.formControl],
  ctrlCaption: "开关",
  props: {
    simple: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [Object, String, Number, Boolean],
      default() {
        return missItem;
      },
    },
    // 是否显示描述文字
    showText: {
      type: Boolean,
      default: true
    },
    // 文字是否显示在内部
    insetText: {
      type: Boolean,
      default: false
    },
    // 开关打开时的描述文字
    activeText: {
      type: String,
      default: "是",
    },
    // 开关关闭时的描述文字
    inactiveText: {
      type: String,
      default: "否",
    },
    // 开关打开时的开关颜色
    activeColor: {
      type: String,
      default: null,
    },
    // 开关关闭时的开关颜色
    inactiveColor: {
      type: String,
      default: null,
    },
    formatter: {
      type: String,
      default: "textValue^text",
    },
    metadata: {
      type: Object,
      default() {
        return dsf.metadata.get("boolean-meta-data");
      },
    },
  },
  data() {
    return {};
  },
  computed: {
    bindAttr() {
      let res = {
        disabled: this.disabled,
        activeValue: 1,
        inactiveValue: 0
      };
      if (this.showText) {
        res['activeText'] = this.activeText;
        res['inactiveText'] = this.inactiveText;
      }
      if (this.activeColor) {
        res['activeColor'] = this.activeColor;
      }
      if (this.inactiveColor) {
        res['inactiveColor'] = this.inactiveColor;
      }
      return res;
    },
    switchValue: {
      get() {
        let v = this.value?.value;
        return v && v != '0' ? 1 : 0;
      },
      set(v) {
        let newValue = this.getValue(v);
        this.emitValueChange(newValue);
        this.$nextTick(() => {
          this.emitFormValidate(newValue);
        });
      },
    }
  },
  watch: {
    // 监听值
    value: {
      handler(v) {
        let newValue = missItem;
        if (dsf.isString(v) || dsf.isNumber(v) || dsf.isBoolean(v)) {
          newValue = this.getValue(v && v != '0');
        } else if (dsf.isObject(v) && (v.value == 0 || v.value == 1)) {
          newValue = this.getValue(v.value);
        }
        if (v?.text !== newValue.text || v?.value !== newValue.value) {
          this.emitValueChange(newValue);
        }
      },
      immediate: true,
      deep: true
    },
  },
  methods: {
    selectChange() {
      this.$nextTick(()=> this.$dispatch("change", this.value))
    },
    getValue(v) {
      if (v && v != '0') {
        return {value: 1, text: this.activeText};
      }
      return {value: 0, text: this.inactiveText};
    }
  },
  design: {
    layout: {
      //#开头表示该属性在预览模式下会被保留
      exclude: ["value"],
    }
  },
});
</script>
