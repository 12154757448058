var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "tr",
    {
      class: _vm.getCss,
      style: { height: _vm.height },
      attrs: { "slot-name": _vm.slots[0].name, "r-index": _vm.rowIndex },
    },
    [_vm._t(_vm.slots[0].name)],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }