<!--
 * @Descripttion: 
 * @Author: zhanghang
 * @Date: 2021-09-01 14:12:39
 * @LastEditors: zhanghang
 * @LastEditTime: 2021-10-22 14:52:17
-->
<template>
  <div class="ds-control ds-form-item ds-textbox" :class="getCss">
    <template v-if="simple && readOnly && !isDesign">{{ value }}</template>
    <template v-else>
      <!-- <label
        v-if="showLabel"
        class="ds-form-label"
        :style="getLabelWidthStyle()"
      >
        {{ label }}
        <i
          v-if="dataCapion"
          class="iconfont icon-bangzhuzhongxin ds-control-helper"
          @click="showHelper"
        ></i>
      </label> -->
      <DsfFieldLabel
        v-if="showLabel"
        :mode="showDataCaptionMode"
        :style="getLabelWidthStyle()"
        :show-icon="showDataCaptionIcon" 
        :trigger="showDataCaptionTrigger"
        :data-caption="dataCapion"
        :is-design="isDesign"
        :owner="_self">
        {{ $t(label) }}
      </DsfFieldLabel>
      <div class="ds-form-block" :style="getFormItemBlockMarginLeft()">
        <el-input
          v-if="!readOnly"
          v-model="inputValue"
          :placeholder="placeholder"
          :rows="rows"
          type="textarea"
          :disabled="disabled"
          @blur="inputBlurHandler"
          @change="inputChangeHandler"
          :resize="isTextAreaResize"
          :maxlength="maxlength ? maxlength : null"
          :show-word-limit="maxlength ? showWordLimit : null"
        />
        <!--只读状态只显示div-->
        <div v-else-if="readOnly" class="ds-form-readonly">
          <DsfTextProxy
            v-model="value"
            v-bind="_self.$props"
            :show-label="false"
          ></DsfTextProxy>
        </div>
        <slot name="error">
          <div v-if="errors.length > 0" class="ds-error-text">
            {{ errorsMsg }}
          </div>
        </slot>
      </div>
    </template>
  </div>
</template>

<script>
export default dsf.component({
  name: "DsfTextarea",
  mixins: [$mixins.formControl],
  ctrlCaption: "多行文本",
  props: {
    simple: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "请输入",
    },
    rows: {
      type: Number,
      default: 4,
    },
    // 公式对象
    formula:{
      type:Object,
      default(){
        return {
          //公式
          formula:'',
          //自定义函数
          func:'',
        }
      }
    },
    metadata: {
      type: Object,
      default() {
        let mdata = dsf.metadata.get("text-meta-data");
        dsf.mix(mdata.valueAttributes[0], { length: 1000 });
        return mdata;
      },
    },
    isTextAreaResize: {
      default: "both",
    },
    isPre:{
      type:Boolean,
      default:true
    },
    showWordLimit: {
      // 显示字数统计
      default: false,
    },
    maxlength: {
      // 最大输入长度
      default: 0,
    },
  },
  computed: {
    inputValue: {
      get() {
        return this.value;
      },
      set(v) {
        this.emitValueChange(v);
      },
    },
  },
  methods: {
    inputBlurHandler() {
      this.$dispatch("blur");
    },
    inputChangeHandler() {
      this.$nextTick(() => {
        this.emitFormValidate(this.inputValue);
        this.$dispatch("change", this.inputValue);
      });
    }
  },
});
</script>