<template>
  <div class="ds-control ds-steps-nav" :class="[position, {click: isClick}]" :style="{height: ctrlHeight}">
    <div class="ds-steps-nav-head" :style="headStyle">
      <el-steps
        :active="$heightLightActive"
        :space="space"
        :simple="simple"
        :direction="direction"
        :align-center="simple ? false : alignCenter"
        :finish-status="finishStatus"
      >
        <template v-for="(item, index) in $items">
          <el-step :key="item.value" :class="{active: index == $heightLightActive}" :status="getStatus(index)">
            <div slot="icon" :class="{active: index == $active}" class="ds-steps-nav-item-icon" @click="stepClick(item, index)">
              <slot name="icon" :data="{item: item, index: index}">
                <div v-if="!simple" class="el-step__icon-inner">{{ index + 1 }}</div>
              </slot>
            </div>
            <template slot="title">
              <slot name="title" :data="{item: item, index: index}">
                <div slot="title" class="ds-steps-nav-item-title" @click="stepClick(item, index)">{{ item.text }}</div>
              </slot>
            </template>
            <template slot="description">
              <slot name="description" :data="{item: item, index: index}">
                <div slot="title" class="ds-steps-nav-item-description">{{ item.description }}</div>
              </slot>
            </template>
          </el-step>
        </template>
      </el-steps>
    </div>
    <div class="ds-steps-nav-body">
      <template v-for="(item, index) in $items">
        <div v-if="isDesign || !lazy || loadedItems[index]" :key="item.value" :slot-name="'default' + index" :class="{active: index == $active}" class="ds-steps-nav-item ds-no-padding">
          <slot :name="'default' + index" :index="index"></slot>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default dsf.component({
  name: "DsfStepsNav",
  mixins: [$mixins.layout, $mixins.formControl],
  ctrlCaption: "步骤导航",
  design: {
    isMask: false,
  },
  props: {
    value: {
      type: [Number, Object],
      default: 0
    },
    // 是否简洁风格
    simple: {
      type: Boolean,
      default: false
    },
    // 组件高度
    height: {
      type: String,
      default: "auto"
    },
    // 步骤条高度(水平有效)
    headHeight: {
      type: String,
      default: "auto"
    },
    // 步骤条宽度(垂直有效)
    headWidth: {
      type: String,
      default: "auto"
    },
    // 间距
    space: {
      type: String,
      default: ""
    },
    // 显示位置
    position: {
      type: String,
      default: "top"
    },
    // 居中对齐
    alignCenter: {
      type: Boolean,
      default: false
    },
    // 当前步骤的状态
    finishStatus: {
      type: String,
      default: "success"
    },
    // 高亮依据
    heightLightBasis: {
      type: String,
      default: "current"
    },
    // 是否可点击
    isClick: {
      type: [String, Boolean],
      default: "false"
    },
    // 是否懒加载
    lazy: {
      type: Boolean,
      default: false
    },
    // 选项
    items: {
      type: Array,
      default() {
        return [];
      }
    },
    slots: {
      type: Array,
      default: function() {
        return []
      }
    },
    metadata: {
      type: Object,
      default() {
        return dsf.metadata.get("steps-meta-data");
      },
    },
  },
  data () {
    return {
      loadedItems: {},
      active: 1,
      status: 'finish',
      max: 1
    }
  },
  computed: {
    $max() {
      return this.max - 1;
    },
    $active() {
      return this.active - 1;
    },
    $heightLightActive() {
      switch (this.heightLightBasis) {
      case "current":
        return this.$active;
      case "max":
        return this.$max;
      default:
        return this.$active;
      }
    },
    direction() {
      if (this.position == 'left' || this.position == 'right') {
        return 'vertical';
      }
      return 'horizontal';
    },
    headStyle() {
      if (this.direction == 'vertical') {
        return {width: this.headWidth};
      }
      return {height: this.headHeight};
    },
    $items() {
      let res = []
      if (dsf.type(this.items) == 'array') res = this.items;
      if (this.isDesign && !res.length) {
        res = [
          {text: "step1", value: "step1"},
          {text: "step2", value: "step2"},
          {text: "step3", value: "step3"},
          {text: "step4", value: "step4"}
        ]
      }
      return res;
    },
    ctrlHeight() {
      if (this.isDesign) {
        this.$dispatch("design-height-change", this.height);
        return "100%";
      }
      return this.height;
    }
  },
  watch: {
    'value' (to) {
      this.__valueLock || this.initValue(to);
    },
    '$active': {
      handler (to) {
        this.$set(this.loadedItems, to, true);
      },
      immediate: true
    },
    '$items.length' (to) {
      if (this.active < 1) {
        this.initValue(1);
      } else if (this.active > to) {
        this.initValue(to);
      }
      if (this.slots.length > to) {
        this.slots.length = to;
      } else if (this.slots.length < to) {
        let v = [];
        for (let i = this.slots.length; i < to; i++) {
          v.push({
            name: 'default' + i,
            controls: []
          });
        }
        this.slots = this.slots.concat(v);
      }
    }
  },
  created() {
    // window.aaa = this;
    if (this.isDesign && this.slots.length == 0) {
      let v = [];
      for (let i = 0; i < this.$items.length; i++) {
        v.push({
          name: 'default' + i,
          controls: []
        });
      }
      this.slots = v;
    }
    this.initValue(this.value, true);
  },
  methods: {
    initValue (v, isCreate) {
      if (dsf.type(v) != 'object') {
        this.formatValue(v, isCreate);
      } else if (v.active != this.active || v.status != this.status || v.max != this.max) {
        this.formatValue(v, isCreate);
      }
      this.$nextTick(() => {
        this.$page && dsf.resizeComponent(this.$page);
      })
    },
    formatValue(to, isCreate) {
      let active = this.setValue(to, isCreate);
      let max = this.max;
      if (dsf.type(to) == 'object') {
        active =this.setValue(to.active, isCreate);
        this.status = to.status || 'finish';
        max = to.max || max;
      }
      this.max = max < this.active ? this.active : max;
      this.emitValueChange({
        active: active,
        status: this.status,
        max: this.max
      });
    },
    setValue(v, isCreate) {
      if (dsf.isUnDef(v)) {
        v = 0;
      }
      if (dsf.type(v) == 'string') {
        v = parseInt(v);
      }
      if (isNaN(v)) {
        v = 0;
      }
      if (dsf.type(v) == 'number') {
        let active = parseInt(isCreate ? v + 1 : v);
        if (this.$items.length < active) {
          this.active = this.$items.length;
        } else if (v <= 1) {
          this.active = 1;
        } else if (!this.__valueLock) {
          this.active = active;
        } else {
          return active;
        }
      }
      return this.active;
    },
    getStatus(index) {
      if (this.$heightLightActive == index) {
        return this.status;
      }
      if (this.$heightLightActive > index) {
        return "finish";
      }
      if (this.$heightLightActive < index) {
        return "wait";
      }
    },
    stepClick(item, index) {
      if (this.isDesign) {
        this.initValue(index + 1);
      } else if (this.isClick === true || this.isClick == 'all') {
        this.initValue(index + 1);
      } else if (this.isClick == 'old' && index < this.max) {
        this.initValue(index + 1);
      }
      this.$dispatch("item-click", { item, index });
    },
    // 表单提交前执行
    postBefore() {
      this.__valueLock = true;
      this.initValue(this.active + 1);
    },
    // 表单提交成功后执行
    postAfter(){
      this.__valueLock = false;
    },
    // 表单提交报错后
    postError(){
      return new Promise((resolve) => {
        this.__valueLock = false;
        this.emitValueChange({
          active: this.active,
          status: this.status,
          max: this.max
        });
        resolve();
      });
    }
  }
});
</script>