<template>
  <div class="ds-control ds-nav-catalogue ds-no-padding">
    <div v-if="canEditor" class="header" :class="['header-' + editorBtAlign]">
      <slot name="buttons" :owner="owner">
        <div v-if="isEditor" class="bts">
          <slot name="button" :owner="owner">
            <dsf-button text="取消" size="small" type="plain" @click="endEditor" />
            <dsf-button text="重置" size="small" @click="resetEditor" />
            <dsf-button text="保存" size="small" @click="saveEditor" />
          </slot>
        </div>
        <div v-else class="bts">
          <dsf-button :text="editorBtText" size="small" @click="startEditor" />
        </div>
      </slot>
    </div>
    <dsf-virtual-scroll height="0">
      <div v-if="isDesign && dataSource === 'http'">
        数据由接口返回
      </div>
      <div v-else-if="!isEditor" class="ds-nav-catalogue-list">
        <nav-catalogue-item v-for="item in realList" :key="item._id" :item="item" />
      </div>
      <div v-else-if="scriptLoaded" class="ds-nav-catalogue-list editor">
        <draggable v-model="listCopy" class="dragArea" v-bind="dragOptions">
          <nav-catalogue-item-for-editor v-for="item in listCopy" :key="item._id" :item="item" />
        </draggable>
      </div>
    </dsf-virtual-scroll>
  </div>
</template>

<script>
import NavCatalogueItem from './items/navCatalogueItem';
import NavCatalogueItemForEditor from './items/navCatalogueItemForEditor';

export default dsf.component({
  name: "DsfNavCatalogue",
  ctrlCaption: "目录",
  provide() {
    return {
      catalogueRoot: this
    };
  },
  design: {
    fit: true,
    isMask: false,
  },
  components: {
    NavCatalogueItem,
    NavCatalogueItemForEditor
  },
  mixins: [$mixins.control],
  props: {
    // 跳转视图标识
    targetIframeName: {
      type: String,
      default: ""
    },
    // 数据来源
    dataSource: {
      type: String,
      default: "static"
    },
    // 数据请求地址
    dataSourceUrl: {
      type: String,
      default: ""
    },
    // 数据配置
    dataSourceList: {
      type: Array,
      default() {
        return [];
      }
    },
    // 是否能编辑
    canEditor: {
      type: Boolean,
      default: true
    },
    // 启用编辑按钮文字
    editorBtText: {
      type: String,
      default: "编辑信息项"
    },
    editorBtAlign: {
      type: String,
      default: "center"
    },
    // 保存编辑数据接口
    editorSaveInterface: {
      type: String,
      default: ""
    },
    // 获取编辑数据接口
    editorInitInterface: {
      type: String,
      default: ""
    },
    // 判断是否隐藏
    hideHeader: {
      type: Function,
      required: false
    },
    // 判断是否禁用
    disabledHeader: {
      type: Function,
      required: false
    }
  },
  data() {
    return {
      owner: this,
      // 是否初始化完成
      isInit: false,
      // 当前页面对应的节点
      page: null,
      // 被激活节点对应的第一层的节点
      rootNode: null,
      // 当前被激活的节点
      active: null,
      // 当前页面中所有的锚点
      currentAnchorList: [],
      // 目录原始数据(来源于后端)
      dataList: [],
      // 用户自定义的目录（原始数据）
      customList: null,
      // 真实现实目录（处理过的）
      realList: [],
      // 编辑模式的目录（realList的拷贝）
      listCopy: [],
      // 是否编辑模式
      isEditor: false,
      // 是否已经加载了脚本
      scriptLoaded: false,
      // 拖动配制项
      dragOptions: {
        animation: 200,
        forceFallback: true,
        draggable: ".ds-nav-catalogue-item",
        handle: ".icon-order",
        ghostClass: "ghost",
        chosenClass: "active"
      }
    }
  },
  computed: {
    // 所有页面节点的平铺一维数组
    pageList() {
      let list = [];
      dsf.deepForEach(this.realList, (item) => {
        if (item.type === 1) list.push(item);
      });
      return list;
    },
    frames() {
      return this.$vm?.frames?.[this.targetIframeName];
    }
  },
  watch: {
    dataSourceUrl() {
      this.init();
    },
    editorInitInterface() {
      this.init();
    },
    dataSourceList() {
      this.init();
    }
  },
  created() {
    if (!this.isDesign) {
      this.init = _.throttle(() => {
        this.getEditorData(this.reloadData);
      }, 300, {
        leading: false,
        trailing: true
      });
      this.init();
      this.canEditor && this.loadScript(() => {
        this.scriptLoaded = true;
      });
      this.$vm?.$on(this.targetIframeName + "-ready", v => {
        this.onViewScroll();
        if (this.__queueFn) {
          this.__queueFn();
          this.__queueFn = undefined;
        } else {
          this._onScroll();
        }
      });
    } else {
      this.reloadData();
    }
  },
  mounted() {
    if (this.realList.length) {
      this.realList.length && this.itemClick(this.realList[0]);
    }
  },
  beforeDestroy() {
    clearTimeout(this.__timeout);
    this._scrollBox?.removeEventListener('scroll', this.__onScroll);
  },
  methods: {
    reloadData() {
      if (this.dataSource === 'static') {
        this.realList = this.setRealList(true);
        this.emitInitEvent();
        this.realList.length && this.itemClick(this.realList[0]);
      } else if (this.dataSource === 'http') {
        this.dataSourcePost(() => {
          this.realList.length && this.itemClick(this.realList[0]);
        });
      }
    },
    // 请求数据
    dataSourcePost(callback) {
      if (!this.dataSourceUrl) {
        dsf.error('数据接口配置错误');
        this.dataList = [];
        this.realList = [];
        this.emitInitEvent();
        return;
      }
      let loading = dsf.layer.loading();
      let url = this.$replace(this.dataSourceUrl);
      this.$http.get(url)
        .done(({success, data, message}) => {
          if (success) {
            this.dataList = data;
            this.realList = this.setRealList(true);
            this.emitInitEvent();
            callback?.();
          } else {
            dsf.layer.message(message || '请求异常', false);
          }
        })
        .error((err) => {
          dsf.layer.message(err?.message || '请求异常', false);
        })
        .always(() => {
          dsf.layer.closeLoading(loading);
        });
    },
    // 比对数据，同步数据，保留个性化设置
    comparisonData(isComparison) {
      let list = _.cloneDeep(this.dataSource === 'static' ? this.dataSourceList : this.dataList);
      if (!this.customList?.length) {
        return list;
      }
      if (!isComparison) {
        return this.customList;
      }
      let map1 = {}, map2 = {};
      dsf.deepForEach(list, (it, level, index, parent) => {
        let pid = parent?._id || null;
        if (!map1[pid]) {
          map1[pid] = {};
        }
        map1[pid][it['_id']] = it;
      });
      dsf.deepForEach(this.customList, (it, level, index, parent) => {
        let pid = parent?._id || null;
        if (!map2[pid]) {
          map2[pid] = {};
        }
        map2[it['_id']] = {};
        map2[pid][it['_id']] = it;
        it.parent = parent;
      });
      for (let key in map1) {
        let it1 = map1[key], it2 = map2[key];
        if (it2 === undefined) {
          it2 = it1;
        } else {
          Object.keys(it1).forEach(id => {
            if (it2[id]) {
              it2[id]['_name'] = it1[id]['_name'];
              it2[id]['treeId'] = it1[id]['treeId'];
              it2[id]['type'] = it1[id]['type'];
              it2[id]['url'] = it1[id]['url'];
              it2[id]['anchor'] = it1[id]['anchor'];
              it2[id]['hide'] = it1[id]['hide'];
              it2[id]['disabled'] = it1[id]['disabled'];
              it2[id]['required'] = it1[id]['required'];
              if (!it2[id]['children']) {
                it2[id]['children'] = [];
              }
              if (!it2[id]['children'].length) {
                it2[id]['children'] = it1[id]['children'] || [];
              }
            } else if (it2['parent']) {
              it2['parent']['children'].push(it1[id]);
            } else {
              this.customList.push(it1[id]);
            }
          });
          Object.keys(it2).forEach(id => {
            if (!it1[id] && it2[id]['parent']?.['children']) {
              dsf.array.remove(it2[id]['parent']['children'], it2[id]);
            }
          });
        }
      }
      return list;
    },
    // 计算真实的列表
    setRealList(isComparison) {
      let list = this.comparisonData(isComparison);
      let disabledList = [];
      let pagePath = '';
      dsf.deepForEach(list, (item, level, index, parent) => {
        if (!level) {
          if (item.type === 1) {
            pagePath = item.url;
          } else if (!item.url) {
            item.url = pagePath;
          }
        }
        let pc = parent ? parent.children : list;
        item.level = level;
        item.deepLevel = parent ? parent.deepLevel + '-' + index : index;
        item.parentNode = parent;
        item.nextNode = index < pc.length - 1 ? pc[index + 1] : null;
        item.preNode = index ? pc?.[index - 1] : null;
        item.rootNode = parent?.rootNode || item;
        // 判断是否隐藏
        let isHide = parent?.isHide || false;
        if (!isHide) {
          if (this.hideHeader) {
            isHide = this.hideHeader(item);
          } else if (item.hide) {
            isHide = this.$replace(item.hide);
          }
        }
        item.isHide = isHide;
        // 判断是否禁用
        let isDisabled = parent?.isDisabled || false;
        if (!isDisabled) {
          if (this.disabledHeader) {
            isDisabled = this.disabledHeader(item);
          } else if (item.disabled) {
            isDisabled = this.$replace(item.disabled);
          }
        }
        item.isDisabled = isDisabled;
        // 判断是否必显项
        if (parent?.required) {
          item.required = true;
        }
        if (isDisabled) {
          disabledList.push(item);
        }
      });
      _.forEach(disabledList, it => {
        let parent = it.parentNode;
        while (parent) {
          this.$set(parent, '_isHide', false);
          parent = parent.parentNode;
        }
      });
      return list;
    },
    // 抛出事件，通知隐藏项、禁用项、必显项
    emitInitEvent() {
      if (this.isDesign) return;
      let hideList = [], disabledList = [], requiredList = [];
      dsf.deepForEach(this.realList, item => {
        if (item.isHide || item._isHide) {
          hideList.push(item);
        }
        if (item.isDisabled) {
          disabledList.push(item);
        }
        if (item.required) {
          requiredList.push(item);
        }
      });
      this.$dispatch('init', {
        tree: this.realList,
        hideList,
        disabledList,
        requiredList
      });
    },
    // 监听视窗滚动
    onViewScroll() {
      let vm = this.frames?.$refs?.view;
      if (vm) {
        // 查找页面下所有锚点
        let currentAnchorList = this.findAnchor(this.page);
        // 监听滚动
        this._scrollBox?.removeEventListener('scroll', this._onScroll);
        let scrollBox = document.body;
        if (currentAnchorList.length) {
          scrollBox = dsf.findScrollEl(currentAnchorList[0]);
        }
        this._scrollBox = scrollBox;
        scrollBox?.addEventListener('scroll', this._onScroll, { passive: true });
      }
    },
    _onScroll() {
      if (this.__lock) return;
      let scrollBox = this._scrollBox;
      let currentAnchorList = this.findAnchor(this.page);
      let boxR = {top: 0, bottom: window.innerHeight}
      if (window !== scrollBox) {
        boxR = scrollBox.getBoundingClientRect();
      }
      // 控制高亮
      let items = _.filter(currentAnchorList, el => this.elIsShow(el, boxR));
      if (items.length) {
        let item = _.minBy(items, el => el.getBoundingClientRect().top);
        this.active = item.__anchorInfo;
        this.rootNode = item.__anchorInfo.rootNode;
      }
    },
    // 判断el是否在可视区域
    elIsShow(el, boxR) {
      let elR = el.getBoundingClientRect();
      return (elR.bottom > boxR.top && elR.top < boxR.bottom);
    },
    // 查找锚点
    findAnchor(page, item) {
      let vm = this.frames?.$refs?.view;
      if (!vm?.$el?.querySelectorAll) {
        return item ? null : [];
      }
      let domList = vm.$el.querySelectorAll('[ctrl_type="dsf.label"],[ctrl_type^="dsf.title"]');
      if (item) {
        let {anchor, _name} = item;
        if (anchor) {
          return vm.$el.querySelector(`[anchor-key="${anchor}"]`);
        } else if (_name) {
          return _.find(domList, ({__vue__: v}) => v && v.value === _name);
        }
      } else {
        let currentAnchorList = [];
        _.forEach(this.findAnchorByPage(page), (item) => {
          let {_name, anchor, type} = item;
          if (type === 2) {
            let title = null;
            if (anchor) {
              title = vm.$el.querySelector(`[anchor-key="${anchor}"]`);
            } else if (_name) {
              title = _.find(domList, ({__vue__: v}) => v && v.value === _name);
            }
            if (title) {
              title.__anchorInfo = item;
              currentAnchorList.push(title);
            }
          }
        });
        return currentAnchorList;
      }
    },
    // 节点点击
    itemClick(item) {
      if (this.isDesign) {
        this.active = item;
        return;
      }
      // 空节点 或 禁用节点
      if (item.type == 0 || item.isDisabled) {
        return;
      }
      // 跳页面
      if (item.type == 1) {
        this.updateView(item);
      }
      // 跳锚点
      else if (item.type == 2) {
        let page = this.findPageByItem(item);
        // 如果item在当前页面内
        if (!this.page || page.url == this.page.url) {
          this.toAnchor(item);
        }
        // 跳页面再跳锚点
        else {
          this.updateView(page, item);
        }
      }
      this.active = item;
      this.rootNode = item.rootNode;
    },
    /**
     * 找到节点item的父页面节点
     * @param item
     * @returns {null|*}
     */
    findPageByItem(item) {
      if (item.type === 1) return item;
      let page = item;
      while (page.parentNode) {
        page = page.parentNode;
        if (page.type === 1) {
          return page;
        }
      }
      return page;
    },
    // 找到属于page页面节点的锚点
    findAnchorByPage(page) {
      let currentAnchorList = [];
      if (!page) {
        dsf.deepForEach(this.realList, (item) => {
          if (item.type === 1) return 1;
          if (item.type === 2) {
            currentAnchorList.push(item);
          }
        });
        return currentAnchorList;
      }
      let list = page;
      if (!page.level) {
        list = _.filter(this.realList, r => r.url == page.url);
      }
      dsf.deepForEach(list, item => {
        if (item.type === 1 && item.url !== page.url) return 1;
        if (item.type === 2) {
          currentAnchorList.push(item);
        }
      });
      return currentAnchorList;
    },
    // 跳页面
    updateView(page, anchor) {
      if (this.frames && page) {
        if (this.page?.url === page.url && !anchor) {
          clearTimeout(this.__timeout);
          this.__lock = true;
          this.__timeout = setTimeout(() => {
            this.__lock = false;
            this._onScroll();
          }, 500);
          this._scrollBox.scroll({
            top: 0,
            behavior: 'smooth'
          });
        } else {
          let url = this.$replace(page.url);
          this.frames.$emit("update:path", url);
          this.page = page;
          if (anchor) {
            this.__queueFn = () => {
              this.toAnchor(anchor, false);
            }
          }
        }
      }
    },
    // 跳锚点
    toAnchor(item, animation = true) {
      clearTimeout(this.__timeout);
      this.__lock = true;
      this.__timeout = setTimeout(() => {
        this.__lock = false;
      }, 1000);
      let vm = this.frames?.$refs?.view;
      if (vm) {
        let title = this.findAnchor(this.page, item);
        title?.scrollIntoView({
          behavior: animation ? "smooth" : "auto",
          block: "start",
          inline: "nearest"
        });
      }
    },
    /* 编辑模式 */
    loadScript(callback) {
      if (window.vuedraggable) {
        this.scriptLoaded = true;
        callback?.();
      } else {
        this.$http
          .importFiles([
            dsf.url.getWebPath("$/js/libs/vuedraggable/Sortable-1.13.0.js"),
            dsf.url.getWebPath("$/js/libs/vuedraggable/vuedraggable.umd.js"),
          ])
          .then(() => {
            this.scriptLoaded = true;
            callback?.();
          })
          .catch((err) => {
            console.error(err);
            dsf.layer.message("加载fabric文件报错", false);
          });
      }
    },
    // 启用编辑模式
    startEditor() {
      if (this.isDesign) return;
      this.listCopy = this.setRealList();
      this.isEditor = true;
      this.$emit("editor", true);
    },
    // 退出编辑吗模式
    endEditor() {
      if (this.isDesign) return;
      this.listCopy = [];
      this.isEditor = false;
      this.$emit("editor", false);
    },
    // 重置
    resetEditor() {
      if (this.isDesign) return;
      this.listCopy = this.setRealList();
    },
    // 编辑模式保存
    saveEditor() {
      if (this.isDesign) return;
      dsf.deepForEach(this.listCopy, it => {
        delete it.level;
        delete it.deepLevel;
        delete it.parentNode;
        delete it.nextNode;
        delete it.preNode;
        delete it.rootNode;
      });
      this.saveEditorData(this.listCopy);
      this.customList = this.listCopy;
      this.realList = this.setRealList();
      this.listCopy = [];
      this.isEditor = false;
      this.emitInitEvent();
    },
    // 用户编辑
    hideClick(item, _isHide) {
      if (item.required) {
        // if (_isHide === void 0) {
        //   dsf.layer.message("该项不能隐藏！", false);
        // }
        return;
      }
      if (_isHide === void 0) {
        this.$set(item, '_isHide', !item._isHide);
        dsf.deepForEach(item.children, it => {
          this.hideClick(it, item._isHide);
        });
        if (!item._isHide) {
          let parent = item.parentNode;
          while (parent) {
            this.$set(parent, '_isHide', false);
            parent = parent.parentNode;
          }
        }
      } else {
        this.$set(item, '_isHide', _isHide);
      }
    },
    // 保存个性化数据
    saveEditorData(data) {
      if (!this.editorSaveInterface) {
        dsf.error('个性化数据保存接口配置错误');
        return;
      }
      let url = this.$replace(this.editorSaveInterface);
      this.$http.post(url, {
        data: JSON.stringify(data, (k, v) => {
          if (k == 'parent') {
            return undefined;
          }
          return v;
        })
      }).done(({success, message}) => {
        if (success) {
          dsf.layer.message('保存成功');
        } else {
          dsf.layer.message(message || '请求异常', false);
        }
      }).error(err => {
        dsf.error(err);
        dsf.layer.message(err?.message || '请求异常', false);
      });
    },
    // 获取个性化数据
    getEditorData(callback) {
      if (!this.editorInitInterface) {
        dsf.error('个性化数据获取接口配置错误');
        this.customList = [];
        callback();
        return;
      }
      let url = this.$replace(this.editorInitInterface);
      this.$http.get(url)
        .done(({success, data, message}) => {
          if (success) {
            this.customList = data;
            callback();
          } else {
            dsf.layer.message(message || '请求异常', false);
          }
        })
        .error(err => {
          dsf.error(err);
          dsf.layer.message(err?.message || '请求异常', false);
        });
    }
  }
});
</script>