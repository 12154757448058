var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ds-page-home", style: { "min-width": _vm.minWidth } },
    [
      _vm.hasHeader
        ? _c(
            "div",
            {
              staticClass: "ds-page-home-header",
              style: { height: _vm.position.top },
            },
            [_vm._t("header")],
            2
          )
        : _vm._e(),
      _c("div", { staticClass: "ds-page-home-body" }, [
        _vm.hasLeft
          ? _c(
              "div",
              {
                staticClass: "ds-page-home-left",
                style: { width: _vm.position.left },
              },
              [_vm._t("left")],
              2
            )
          : _vm._e(),
        _c("div", { staticClass: "ds-page-home-main" }, [
          _vm.hasBreadcrumb
            ? _c(
                "div",
                {
                  staticClass: "ds-page-home-breadcrumb",
                  style: { height: _vm.position.breadcrumb },
                },
                [_vm._t("breadcrumb")],
                2
              )
            : _vm._e(),
          _vm.viewLoaded
            ? _c(
                "div",
                { staticClass: "ds-page-home-view" },
                [_vm._t("default")],
                2
              )
            : _vm._e(),
        ]),
        _vm.hasRight
          ? _c(
              "div",
              {
                staticClass: "ds-page-home-right",
                style: { width: _vm.position.right },
              },
              [_vm._t("right")],
              2
            )
          : _vm._e(),
      ]),
      _vm.hasFooter
        ? _c(
            "div",
            {
              staticClass: "ds-page-home-footer",
              style: { height: _vm.position.bottom },
            },
            [_vm._t("footer")],
            2
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }