var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "el-select super-search-tree-select",
      staticStyle: { width: "100%" },
    },
    [
      _c(
        "el-popover",
        {
          attrs: {
            width: _vm.getWidth(),
            transition: "el-zoom-in-top",
            "popper-class": "tree-select-popover",
          },
          on: { hide: _vm.hideHandler },
          model: {
            value: _vm.item.isExtend,
            callback: function ($$v) {
              _vm.$set(_vm.item, "isExtend", $$v)
            },
            expression: "item.isExtend",
          },
        },
        [
          _c(
            "div",
            { attrs: { slot: "reference" }, slot: "reference" },
            [
              !_vm.multipe
                ? _c(
                    "el-input",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { readonly: "", value: _vm.selectName },
                    },
                    [
                      _c("template", { slot: "suffix" }, [
                        _c("i", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !_vm.showClose,
                              expression: "!showClose",
                            },
                          ],
                          class: [
                            "el-select__caret",
                            "el-input__icon",
                            "el-icon-" + _vm.iconClass,
                          ],
                        }),
                        _vm.showClose
                          ? _c("i", {
                              staticClass:
                                "el-select__caret el-input__icon el-icon-circle-close",
                              on: { click: _vm.handleClearClick },
                            })
                          : _vm._e(),
                      ]),
                    ],
                    2
                  )
                : _vm._e(),
              _vm.multipe
                ? [
                    _c(
                      "el-input",
                      {
                        staticStyle: { width: "100%" },
                        attrs: { readonly: "" },
                      },
                      [
                        _c("template", { slot: "suffix" }, [
                          _c("i", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: !_vm.showClose,
                                expression: "!showClose",
                              },
                            ],
                            class: [
                              "el-select__caret",
                              "el-input__icon",
                              "el-icon-" + _vm.iconClass,
                            ],
                          }),
                          _vm.showClose
                            ? _c("i", {
                                staticClass:
                                  "el-select__caret el-input__icon el-icon-circle-close",
                                on: { click: _vm.handleClearClick },
                              })
                            : _vm._e(),
                        ]),
                      ],
                      2
                    ),
                    _c(
                      "div",
                      {
                        ref: "tags",
                        staticClass: "el-select__tags",
                        style: { width: "100%" },
                      },
                      [
                        _vm.collapseTags
                          ? _c(
                              "span",
                              [
                                _vm.item.treeNodeSelected.length > 0
                                  ? _c(
                                      "el-tag",
                                      {
                                        attrs: {
                                          closable: true,
                                          size: "small",
                                          hit: false,
                                          type: "info",
                                          "disable-transitions": "",
                                        },
                                        on: {
                                          close: function ($event) {
                                            return _vm.deleteTag(
                                              $event,
                                              _vm.item.treeNodeSelected[0]
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "el-select__tags-text",
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.item.treeNodeSelected[0]
                                                  ._name
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm.item.treeNodeSelected.length > 1
                                  ? _c(
                                      "el-tag",
                                      {
                                        attrs: {
                                          closable: false,
                                          size: "small",
                                          type: "info",
                                          "disable-transitions": "",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "el-select__tags-text",
                                          },
                                          [
                                            _vm._v(
                                              "+ " +
                                                _vm._s(
                                                  _vm.item.treeNodeSelected
                                                    .length - 1
                                                )
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            )
                          : _c(
                              "span",
                              _vm._l(_vm.item.treeNodeSelected, function (tag) {
                                return _c(
                                  "el-tag",
                                  {
                                    key: tag._id,
                                    attrs: {
                                      closable: true,
                                      size: "small",
                                      type: "info",
                                      "disable-transitions": "",
                                    },
                                    on: {
                                      close: function ($event) {
                                        return _vm.deleteTag($event, tag)
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "el-select__tags-text" },
                                      [_vm._v(_vm._s(tag._name))]
                                    ),
                                  ]
                                )
                              }),
                              1
                            ),
                      ]
                    ),
                  ]
                : _vm._e(),
            ],
            2
          ),
          [
            _c(
              "el-scrollbar",
              {
                staticStyle: { padding: "0px" },
                attrs: {
                  tag: "div",
                  "wrap-class": "el-select-dropdown__wrap",
                  "view-class": "el-select-dropdown__list",
                },
              },
              [
                _c("dropdown-tree", {
                  ref: "tree",
                  attrs: {
                    values: _vm.values,
                    item: _vm.item,
                    options: _vm.options,
                    multipe: _vm.multipe,
                  },
                  on: { change: _vm.treeNodeChange },
                }),
              ],
              1
            ),
          ],
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }