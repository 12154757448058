var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dsf-multiPage-home-view" }, [
    _vm.show
      ? _c("iframe", {
          ref: "iframe",
          attrs: {
            src: _vm.src,
            wid: _vm.page.menu_id,
            frameborder: "0",
            "dsf-spv": "",
          },
          on: { load: _vm.load },
        })
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }