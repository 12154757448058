<template>
  <div class="page-home-template1-header">
    <div class="page-home-template1-header-info">
      <div class="logo-box">
        <slot name="logo">
          <a :href="indexUrl">
            <img v-if="logo" :src="$logo | imgFormat" alt="" class="logo">
            <div v-if="title && title.length" class="site-name">{{ title }}</div>
          </a>
        </slot>
      </div>
      <div class="header-right">
        <div class="header-user">
          <div class="header-user-info">
            <div class="username">{{ dsf.cookies.get("user_name") }}, 你好</div>
          </div>
          <div v-if="!dropdownConfig.length" class="header-user-icon" :style="{'background-image': `url(${$replace(defaultHeader)})`}">
            <img v-show="showIcon" :src="headIcon | imgFormat" @error="headLoadError" @load="headLoadSuccess" />
          </div>
          <el-dropdown v-else :show-timeout="0" placement="bottom" @visible-change="showDropdown = $event" @command="handleCommand">
            <div class="header-user-icon" :style="{'background-image': `url(${$replace(defaultHeader)})`}">
              <img v-show="showIcon" :src="headIcon | imgFormat" @error="headLoadError" @load="headLoadSuccess" />
            </div>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item v-for="(item, index) in dropdownConfig" :key="(item.key || '') + '__' + index" :command="index">
                {{ item.name }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </div>
    <div class="page-home-template1-header-menu">
      <dsf-template1-home-menu :last-level-click="lastLevelClick"></dsf-template1-home-menu>
    </div>
  </div>
</template>

<script>
export default {
  name: "DsfTemplate1HomeHeader",
  inject: ["homeRoot"],
  props: {
    dropdownConfig: {
      type: Array,
      default() {
        return [];
      }
    },
    // 是否只响应最后一级菜单的点击事件
    lastLevelClick: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      logo: dsf.config.setting_public_logo_image,
      title: dsf.config.setting_public_logo_title,
      headIcon: "",
      defaultHeader: dsf.config.setting_public_user_default_header,
      showIcon: false,
      showDropdown: false,
      topMap: {
        bigFont: 140,
        bigFont_1: 144,
        bigFont_2: 148,
        bigFont_3: 152
      }
    };
  },
  computed: {
    indexUrl() {
      return location.href.replace(/#.*/, "");
    },
    $logo() {
      return this.$replace(this.logo);
    }
  },
  watch: {
    "dsf.themeConfig.setting.fontSize"(to) {
      this.homeRoot.setPosition("top", this.topMap[to] ? this.topMap[to] + "px" : "136px");
    }
  },
  created() {
    let top = this.topMap[dsf.themeConfig.setting.fontSize];
    this.homeRoot.setPosition("top", top ? top + "px" : "136px");
    this.initHeadIcon();
  },
  methods: {
    // 初始化头像，从cookie中获取
    initHeadIcon() {
      const headIcon = dsf.getCookie("photoRelativePath");
      if (headIcon) {
        this.headIcon = decodeURIComponent(headIcon);
      }
    },
    // 头像加载成功时
    headLoadSuccess() {
      this.showIcon = true;
    },
    // 头像加载失败时
    headLoadError() {
      this.showIcon = false;
    },
    // 用户头像下的菜单点击
    handleCommand(index) {
      const item = this.dropdownConfig[+index];
      this.$emit("dropdown-click", item);
    }
  }
};
</script>