var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "send-flow-show-result" }, [
    _c(
      "div",
      { staticClass: "userbox", class: { batch: _vm.height > 0 } },
      [
        _vm.list && _vm.list.length > 0
          ? _vm._l(_vm.list, function (item, index) {
              return _c(
                "span",
                { key: index, staticClass: "ds-button--icon-text" },
                [_vm._v(_vm._s(item))]
              )
            })
          : _vm._e(),
        _vm.msg
          ? [
              _c("span", { staticClass: "ds-button--icon-text" }, [
                _vm._v(_vm._s(_vm.msg)),
              ]),
            ]
          : _vm._e(),
      ],
      2
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showCountDown,
            expression: "showCountDown",
          },
        ],
        staticClass: "count-down",
      },
      [
        _c("span", [_vm._v("页面将在")]),
        _c("span", { staticClass: "ds-button--icon-text" }, [
          _vm._v(_vm._s(_vm.time)),
        ]),
        _c("span", [_vm._v("秒钟后自动关闭")]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }