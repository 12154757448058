var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "DsfHomePanel",
    {
      staticClass: "dsf-management-box-notice",
      attrs: {
        title: _vm.title,
        titleIcon: _vm.titleIcon,
        moreIcon: _vm.moreConfig.url ? "gengduo" : "",
        moreConfig: _vm.moreConfig,
        padding: _vm.padding,
        margin: _vm.margin,
        styleType: _vm.styleType,
        height: _vm.heightPanel,
        width: _vm.widthPanel,
        showHead: _vm.showHead,
      },
    },
    [
      _c(
        "div",
        { staticClass: "dsf-home-announcemen-mail", style: _vm.panelStyle },
        [
          _c(
            "DsfVirtualScroll",
            [
              _c(
                "DsfVirtualList",
                _vm._b(
                  {
                    ref: "vlist",
                    attrs: { isDesign: _vm.isDesign, url: _vm.url },
                    on: {
                      "list-change": _vm.listChangeFn,
                      "row-click": _vm.rowClick,
                    },
                    scopedSlots: _vm._u(
                      [
                        _vm.isDesign
                          ? {
                              key: "list-row",
                              fn: function () {
                                return _vm._l(3, function (index) {
                                  return _c(
                                    "div",
                                    {
                                      key: index,
                                      staticClass: "dsf-home-list-col2-row",
                                      style: { lineHeight: _vm.lineHeight },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "dsf-home-list-col2-item ellipsis",
                                          attrs: {
                                            title: "标题",
                                            type: "name",
                                          },
                                        },
                                        [_vm._v(" 周工作汇报 ")]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "dsf-home-list-col2-item",
                                          attrs: { type: "time" },
                                        },
                                        [_vm._v("2020-04-12")]
                                      ),
                                    ]
                                  )
                                })
                              },
                              proxy: true,
                            }
                          : {
                              key: "list-row",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "dsf-home-list-col2-row",
                                      style: { lineHeight: _vm.lineHeight },
                                      attrs: { read: scope.row.status != 0 },
                                    },
                                    [
                                      scope.row.isTop == 1
                                        ? _c("img", {
                                            staticClass:
                                              "dsf-home-list-col2-item",
                                            attrs: {
                                              type: "top",
                                              src: require("_platform/assets/styles/themes/img/home/top.png"),
                                            },
                                          })
                                        : _vm._e(),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "dsf-home-list-col2-item ellipsis",
                                          attrs: {
                                            title: scope.row.title || "",
                                            type: "name",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(scope.row.title || "") +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "dsf-home-list-col2-item",
                                          attrs: { type: "time" },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm._f("time")(
                                                  scope.row.sendDateTime
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                      ],
                      null,
                      true
                    ),
                  },
                  "DsfVirtualList",
                  {
                    rowPadding: _vm.rowPadding,
                    rowMargin: _vm.rowMargin,
                  },
                  false
                )
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }