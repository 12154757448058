const files = require.context('.', true, /\.vue$/);
export let modules = {};
files.keys().forEach(key => {
  let m = files(key).default;
  if (m.name) {
    let n = m.name;
    modules[n] = m;
  }
  return modules;
});
function install(Vue) {
  for (let [key, value] of Object.entries(modules)) {
    Vue.component(value.name, value);
  }
}
modules.install = install;
export default install;