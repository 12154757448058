<script>
import treeSelector from "./treeSelector";

export default dsf.component({
  name: "DsfCheckNode",
  mixins: [treeSelector],
  ctrlCaption: "部门人员选择页面",
  props:{
    nodeKey:{
      type: String,
      default: "_id",
    },
  },
  created(){
    this.isCheckNodeCtrl = true;
  }
});
</script>
