<template>
  <div class="ds-control ds-datagrid-cell-content ds-no-padding">
    <slot :name="slots[0].name"></slot>
  </div>
</template>
<script>
export default dsf.component({
  name: "DsfDataGridCellContent",
  mixins: [$mixins.layout],
  ctrlCaption: "表格单元格内容",
  inject: {
    $list: {
      default: null
    }
  },
  props: {
    treeProxy: {
      type: Boolean,
      default: true
    },
    // layoutClone: {
    //   type: Boolean,
    //   default: true
    // },
    slots: {
      type: Array,
      default() {
        return [{ name: "default", controls: [], textProxy: true }];
      }
    },
    columnId: {
      type: String,
      default: ""
    },
    // cloneProps: {
    //   type: Boolean,
    //   default: true
    // }
  },
  data() {
    return {
      // slotTemp:[this.slots],
      scope: null,
      singleFormControl: null,
      slotsClone: null
    };
  },
  updated() {
    // console.log("更新")
  },
  created() {
    // this.$watch(
    //   function () {
    //     return this.slots[0].controls.length;
    //   },
    //   function (v, ov) {
    //     let child = this.slots[0].controls[0];
    //     this.updateCustomerOrder(child);
    //   }
    // );
  },
  mounted() {
    // console.log("挂载")
  },
  methods: {
    updateCustomerOrder(child) {
      //为列表创建排序
      if (child && child.metadata) {
        let metadata = child.metadata;
        let field = metadata.valueAttributes.length == 1 ? metadata.code : [metadata.code, metadata.valueAttributes[0].code].join("_");
        let datagrid = this.$list;
        if (datagrid && datagrid.customerOrder) {
          let c_order = _.find(datagrid.customerOrder || [], (it) => {
            return this.columnId == it.column;
          });
          if (!c_order) {
            datagrid.customerOrder.push({
              column: this.columnId,
              field: field,
              fieldCaption: metadata.name,
              sortType: "",
              isUser: false
            });
          } else if (c_order && c_order.isUser === false) {
            c_order.field = field;
            c_order.fieldCaption = metadata.name;
            c_order.sortType = "";
          }
        }
      }
    },
    clearCustomerOrder() {
      let datagrid = this.$list;
      if (datagrid && datagrid.customerOrder) {
        let c_order = _.find(datagrid.customerOrder || [], (it) => {
          return this.columnId == it.column;
        });
        if (c_order && c_order.isUser === false) {
          dsf.array.remove(datagrid.customerOrder, c_order);
        }
      }
    },
    updateFilter(child) {
      if (child && child.metadata) {
        let metadata = child.metadata;
        if (!metadata.code || !metadata.at) {
          return;
        }
        let fields = dsf.metadata.getField(metadata);
        let field, type;
        if (fields && fields.length > 0) {
          field = fields[0].field;
          type = fields[0].type;
        }
        let datagrid = this.$list;
        let lastColumns = datagrid ? datagrid.getLastColumns() : [];
        if (datagrid && lastColumns) {
          let col = _.find(lastColumns || [], (it) => {
            return this.columnId == it.columnId;
          });
          if (col) {
            let filterInfo = col.filterInfo; //
            if (dsf.isEmptyObject(filterInfo) || !filterInfo.isUser) {
              let filterType = "string";
              if (type == "number") {
                filterType = "number";
              } else if (type == "date") {
                filterType = "ymd";
              }
              if (metadata.dataSource) {
                filterType = "enum";
              }
              col.isFilter = true;
              col.filterInfo = {
                isUser: false,
                field: field,
                type: filterType,
                currentFilter: null,
                dataSource: metadata.dataSource || undefined
              };
            }
          }
        }
      }
    },
    clearFilter() {
      let datagrid = this.$list;
      let lastColumns = datagrid ? datagrid.getLastColumns() : [];
      if (datagrid && lastColumns) {
        let col = _.find(lastColumns || [], (it) => {
          return this.columnId == it.columnId;
        });
        if (col) {
          let filterInfo = col.filterInfo; //
          if (!dsf.isEmptyObject(filterInfo) && !filterInfo.isUser) {
            col.filterInfo = {};
          }
        }
      }
    },
    updateColumn(child) {
      this.updateCustomerOrder(child);
      this.updateFilter(child);
    },
    clearColumn() {
      this.clearCustomerOrder();
      this.clearFilter();
    }
  }
  // watch:{
  //   "slot"
  // }
});
</script>
