<template>
  <div class="ds-schedule-list-setting">
    <dsf-virtual-scroll>
      <el-form ref="form" label-width="200px">
        <el-form-item label="显示模式：">
          <el-radio-group v-model="form.type">
            <el-radio label="week">周历</el-radio>
            <el-radio label="week2">双周历</el-radio>
            <el-radio label="month">月历</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="日程过多时显示省略号：">
          <el-switch v-model="form.ellipsis" class="inset" active-text="是" inactive-text="否" />
        </el-form-item>
        <el-form-item label="默认显示条数：">
          <el-input-number v-model="form.pageSize" :min="1" :max="200" :precision="0" step-strictly />
        </el-form-item>
      </el-form>
    </dsf-virtual-scroll>
  </div>
</template>

<script>
export default {
  name: "DsfScheduleListSetting",
  props: {
    settingConfig: {
      type: Object,
      default() {
        return {
          pageSize: 5,
          type: 'week',
          ellipsis: true
        }
      }
    }
  },
  data() {
    return{
      form: {
        pageSize: 5,
        type: 'week',
        ellipsis: true
      }
    }
  },
  created() {
    this.form = Object.assign({}, this.form, this.settingConfig);
  },
  methods: {
    yes() {
      return this.form;
    }
  }
}
</script>