var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "td",
    {
      staticClass: "ds-control ds-no-padding ds-table-layout-cell",
      class: _vm.getCss,
      style: [{ width: _vm.cellWidth }, _vm.cellStyle, _vm.cellBorder],
      attrs: {
        "slot-name": _vm.slots[0].name,
        "c-index": _vm.rowIndex + "," + _vm.cellIndex,
        rowspan: _vm.rowspan,
        colspan: _vm.colspan,
        align: _vm.cellAttr.align,
        valign: _vm.cellAttr.valign,
      },
      on: { dblclick: _vm.dblClickHandler },
    },
    [
      _vm._t(_vm.slots[0].name, function () {
        return [
          _vm.isDesign
            ? [
                _c("DsfTextContentEditable", {
                  staticStyle: { padding: "5px" },
                  attrs: { editable: _vm.conentEditable },
                  on: {
                    "update:editable": function ($event) {
                      _vm.conentEditable = $event
                    },
                    input: function ($event) {
                      return _vm.textChangeHandler()
                    },
                    blur: _vm.textChangeHandler,
                  },
                  model: {
                    value: _vm.text,
                    callback: function ($$v) {
                      _vm.text = $$v
                    },
                    expression: "text",
                  },
                }),
              ]
            : [
                _c("span", {
                  domProps: { innerHTML: _vm._s(_vm.dsf.safe.xss(_vm.text)) },
                }),
              ],
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }