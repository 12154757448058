var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { ref: "container", staticClass: "dsf-muti-ellipsis" }, [
    _c(
      "div",
      { staticClass: "dsf-muti-ellipsis-content" },
      [
        _c("span", { ref: "text", style: _vm.txtStyle }, [
          _vm._v(_vm._s(_vm.textFat.viewText)),
        ]),
        _c("transition", { attrs: { name: "ellipsis-slide" } }, [
          _vm.isComplate
            ? _c("span", { staticClass: "more", style: _vm.txtStyle }, [
                _vm._v(_vm._s(_vm.textFat.moreText)),
              ])
            : _vm._e(),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }