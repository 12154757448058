var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "dsf-platform-fixed-menu",
      class: _vm.rootClass,
      style: [_vm.getBackgroundStyle, _vm.rootStyle],
      attrs: { id: _vm.id },
    },
    [
      _c(
        "div",
        { staticClass: "dsf-platform-fixed-menu-list" },
        [
          _vm._l(_vm.menuList, function (menu) {
            return [
              _c(
                "nav-menu-item",
                { key: menu._id, attrs: { item: menu } },
                [
                  menu.type === "picture"
                    ? _c(
                        "el-popover",
                        {
                          attrs: {
                            width: menu.showPictureWidth || 200,
                            placement:
                              _vm.position == "right" ? "left" : "right",
                            trigger: "hover",
                          },
                        },
                        [
                          _c("div", {
                            staticClass: "dsf-platform-fixed-menu-popover",
                            attrs: { slot: "reference" },
                            slot: "reference",
                          }),
                          _c("el-image", {
                            style: {
                              height: menu.showPictureHeight || "200px",
                            },
                            attrs: {
                              src: _vm._f("imgFormat")(menu.showPicture),
                              fit: "cover",
                            },
                          }),
                        ],
                        1
                      )
                    : menu.type === "tips"
                    ? _c(
                        "el-popover",
                        {
                          attrs: {
                            width: 200,
                            placement:
                              _vm.position == "right" ? "left" : "right",
                            trigger: "hover",
                          },
                        },
                        [
                          _c("div", {
                            domProps: { innerHTML: _vm._s(_vm.getHtml(menu)) },
                          }),
                          _c("div", {
                            staticClass: "dsf-platform-fixed-menu-popover",
                            attrs: { slot: "reference" },
                            slot: "reference",
                          }),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]
          }),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }