<template>
  <div class="ds-control ds-html">
    <div v-if="isDesign" style="text-align:center;background-color:#fff;padding:3px 0px;font-style:italic;color:#c0c0c0">html片段</div>
    <div v-else style="text-align:center;background-color:#fff;padding:20px 0px;font-style:italic;color:#c0c0c0;">
      html组件只能在正式运行下查看
    </div>
  </div>
</template>
<script>

export default dsf.component({
  name: 'DsfHtml',
  ctrlCaption: 'Html',
  mixins: [$mixins.control],
  props: {
    html: {
      type: String,
      default: ""
    }
  }
});
</script>