var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.show
    ? _c(
        "el-popover",
        {
          attrs: {
            "popper-class": "dsf-page-home-header-user-info-panel",
            placement: "bottom",
            trigger: "hover",
          },
          scopedSlots: _vm._u(
            [
              {
                key: "reference",
                fn: function () {
                  return [_vm._t("reference")]
                },
                proxy: true,
              },
            ],
            null,
            true
          ),
          model: {
            value: _vm.showDropdown,
            callback: function ($$v) {
              _vm.showDropdown = $$v
            },
            expression: "showDropdown",
          },
        },
        [
          _vm._t("default", function () {
            return [
              _c("div", { staticClass: "content" }, [
                _c(
                  "div",
                  { staticClass: "userinfo" },
                  [
                    _c("dsf-page-home-header-user", [
                      _c("div", { staticClass: "basic" }, [
                        _c("span", { staticClass: "username" }, [
                          _vm._v(_vm._s(_vm.dsf.cookies.get("user_name"))),
                        ]),
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: false,
                                expression: "false",
                              },
                            ],
                            staticClass: "other-info",
                          },
                          [_vm._v("大数据中心")]
                        ),
                      ]),
                      _c("i", {
                        staticClass: "to-editor iconfont icon-tianxie",
                        on: { click: _vm.handleOpenUserInfoWindow },
                      }),
                    ]),
                  ],
                  1
                ),
                _c("div", { staticClass: "down-menu" }, [
                  _c("div", { staticClass: "down-menu-header" }, [
                    _c("div", { staticClass: "down-menu-bt" }, [
                      _c("i", {
                        staticClass: "iconfont icon-nav-colour",
                        staticStyle: { color: "#ff9900" },
                      }),
                      _c("span", [_vm._v("主题")]),
                      _c(
                        "div",
                        {
                          staticClass: "popover theme-radio-list",
                          staticStyle: { width: "160px" },
                        },
                        [
                          _vm._l(_vm.showThemes, function (key) {
                            return [
                              _c("div", {
                                key: key,
                                staticClass: "theme-radio-item",
                                style: _vm.radioStyle(
                                  _vm.dsf.themeConfig.themesConfig[key],
                                  key
                                ),
                                on: {
                                  click: function ($event) {
                                    return _vm.radioClick(key)
                                  },
                                },
                              }),
                            ]
                          }),
                        ],
                        2
                      ),
                    ]),
                    _c("div", { staticClass: "down-menu-bt" }, [
                      _c("i", {
                        staticClass: "iconfont icon-icon_user_zt",
                        staticStyle: { color: "#cc3399" },
                      }),
                      _c("span", [_vm._v("字号")]),
                      _c(
                        "div",
                        { staticClass: "popover" },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.fontSizeVal,
                                callback: function ($$v) {
                                  _vm.fontSizeVal = $$v
                                },
                                expression: "fontSizeVal",
                              },
                            },
                            _vm._l(_vm.fontSizeOptions, function (text, val) {
                              return _c(
                                "el-radio",
                                { key: val, attrs: { label: val } },
                                [_vm._v(_vm._s(text))]
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                    _vm.hasDefaultHeader || _vm.hasDefaultAside
                      ? _c("div", { staticClass: "down-menu-bt" }, [
                          _c("i", {
                            staticClass: "iconfont icon-zhinan",
                            staticStyle: { color: "#3399cc" },
                          }),
                          _c("span", [_vm._v("导航栏")]),
                          _c(
                            "div",
                            { staticClass: "popover" },
                            [
                              _vm.hasDefaultHeader
                                ? [
                                    _c("div", { staticClass: "title" }, [
                                      _vm._v("顶部"),
                                    ]),
                                    _c(
                                      "el-radio-group",
                                      {
                                        model: {
                                          value:
                                            _vm.themeConfig.defaultHeaderMini,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.themeConfig,
                                              "defaultHeaderMini",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "themeConfig.defaultHeaderMini",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-radio",
                                          { attrs: { label: false } },
                                          [_vm._v("宽条")]
                                        ),
                                        _c(
                                          "el-radio",
                                          { attrs: { label: true } },
                                          [_vm._v("窄条")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                : _vm._e(),
                              _vm.hasDefaultAside
                                ? [
                                    _c("div", { staticClass: "title" }, [
                                      _vm._v("侧边"),
                                    ]),
                                    _c(
                                      "el-radio-group",
                                      {
                                        model: {
                                          value:
                                            _vm.themeConfig.defaultAsideMini,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.themeConfig,
                                              "defaultAsideMini",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "themeConfig.defaultAsideMini",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-radio",
                                          { attrs: { label: false } },
                                          [_vm._v("宽条")]
                                        ),
                                        _c(
                                          "el-radio",
                                          { attrs: { label: true } },
                                          [_vm._v("窄条")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                : _vm._e(),
                            ],
                            2
                          ),
                        ])
                      : _vm._e(),
                  ]),
                  _c(
                    "div",
                    { staticClass: "down-menu-main" },
                    _vm._l(_vm.menuList, function (menu) {
                      return _c(
                        "div",
                        {
                          key: menu.key,
                          staticClass: "down-menu-bt",
                          on: {
                            click: function ($event) {
                              menu._clickHandler && menu._clickHandler(menu)
                            },
                          },
                        },
                        [
                          _c("span", [_vm._v(_vm._s(menu.name))]),
                          menu.children
                            ? [
                                _c("i", {
                                  staticClass: "iconfont icon-icon_more",
                                }),
                                _c(
                                  "div",
                                  { staticClass: "popover" },
                                  _vm._l(menu.children, function (it) {
                                    return _c(
                                      "div",
                                      {
                                        key: it.key,
                                        staticClass: "popover-menu-bt",
                                        on: {
                                          click: function ($event) {
                                            it._clickHandler &&
                                              it._clickHandler(it)
                                          },
                                        },
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(_vm._s(it.groupName)),
                                        ]),
                                        it.children.length
                                          ? [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "popover popover-second",
                                                },
                                                _vm._l(
                                                  it.children,
                                                  function (item) {
                                                    return _c(
                                                      "div",
                                                      {
                                                        key: item.unitId,
                                                        staticClass:
                                                          "popover-menu-bt",
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            item._clickHandler &&
                                                              item._clickHandler(
                                                                it.groupId,
                                                                item
                                                              )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              item.unitName
                                                            )
                                                          ),
                                                        ]),
                                                      ]
                                                    )
                                                  }
                                                ),
                                                0
                                              ),
                                            ]
                                          : _vm._e(),
                                      ],
                                      2
                                    )
                                  }),
                                  0
                                ),
                              ]
                            : _vm._e(),
                        ],
                        2
                      )
                    }),
                    0
                  ),
                  _c("div", { staticClass: "down-menu-footer" }, [
                    _c(
                      "div",
                      {
                        staticClass: "down-menu-bt",
                        on: {
                          click: function ($event) {
                            return _vm.dropdownClick(_vm.loginOutBt)
                          },
                        },
                      },
                      [_vm._v(" 退出登录 ")]
                    ),
                  ]),
                ]),
              ]),
            ]
          }),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }