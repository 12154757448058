export default {
  name: "DsfButton",
  functional: true,
  props: {
    tagName: {
      type: String,
      default: "a"
    },
    type: {
      //plain为次要按钮，warning警示按钮
      type: String,
      default() {
        return "";
      }
    },
    icon: {
      type: String,
      default() {
        return "";
      }
    },
    text: {
      type: String,
      default() {
        return "";
      }
    },
    title: {
      type: String,
      default() {
        return "";
      }
    },
    btnStyle: {
      type: String,
      default() {
        // 0:普通按钮 2：图标按钮 4:文字加图标 5：小图标
        return "";
      }
    },
    size: {
      type: String,
      default: ""
    },
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    hasChild: {
      type: Boolean,
      default: false
    }
  },
  render(h, context) {
    let c = context._c || h;
    let attrs = {
      title: context.props.title || "",
      disabled: context.props.disabled || context.props.loading
    };
    let notText = false;
    let span = null;
    let defaultSlot = context.slots().default;
    if ((defaultSlot?.[0]?.text || "").trim()) {
      span = c('span', defaultSlot);
    } else if (context.props.text) {
      span = c('span', context.props.text);
    } else {
      notText = true;
    }
    let child = [];
    if (context.props.loading) {
      let icon = c("i", {
        staticClass: "iconfont icon-icon_loading i-loading",
      })
      child.push(icon)
    }
    if (context.props.icon) {
      let icon = c("i", {
        staticClass: "iconfont",
        class: {
          ["icon-" + context.props.icon]: true
        }
      });
      child.push(icon);
    }
    child.push(span);

    if (context.props.hasChild) {
      let more = c("i", {
        staticClass: "iconfont icon-shuangjiantouxia",
        staticStyle: { 'margin-left': '5px' }
      });
      child.push(more);
    }

    let cls = context.data.class;
    if (cls) {
      if (dsf.type(cls) == 'string') {
        cls = [cls];
      } else if (dsf.type(cls) == 'object') {
        cls = _.map(cls, (v, k) => { return k })
      }
    } else {
      cls = [];
    }
    return c(context.props.tagName, {
      attrs: dsf.mix(context.data.attrs, attrs),
      key: context.data.key,
      staticStyle: context.data.staticStyle,
      staticClass: context.data.staticClass,
      style: context.data.style,
      directives: context.data.directives,
      class: [
        ...getCss(context),
        (notText ? 'no-text' : ''),
        ...cls
      ],
      on: !attrs.disabled ? context.listeners : {},
    }, child)
  }
};

function getCss(context) {
  let type = context.props.type;
  if (type == 'auxiliary') {
    type = 'plain'
  }
  return [
    'ds-button' + (context.props.btnStyle ? "--" + context.props.btnStyle : ''),
    context.props.type ? type : '',
    context.props.size,
    context.props.loading ? 'loading' : ''
  ]
}

function buttonStyle(context) {
  let s = context.props.btnStyle;
  return s;
}