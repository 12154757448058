var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ds-control ds-form-item ds-radio-box", class: _vm.getCss },
    [
      _vm.simple && _vm.readOnly && !_vm.isDesign
        ? [
            _c(
              "DsfTextProxy",
              _vm._b(
                {
                  attrs: { "show-label": false },
                  model: {
                    value: _vm.value,
                    callback: function ($$v) {
                      _vm.value = $$v
                    },
                    expression: "value",
                  },
                },
                "DsfTextProxy",
                _vm._self.$props,
                false
              )
            ),
            _vm._t("error", function () {
              return [
                _vm.errors.length
                  ? _c("div", { staticClass: "ds-error-text" }, [
                      _vm._v(_vm._s(_vm.errorsMsg)),
                    ])
                  : _vm._e(),
              ]
            }),
          ]
        : [
            _vm.showLabel
              ? _c(
                  "DsfFieldLabel",
                  {
                    style: _vm.getLabelWidthStyle(),
                    attrs: {
                      mode: _vm.showDataCaptionMode,
                      "show-icon": _vm.showDataCaptionIcon,
                      trigger: _vm.showDataCaptionTrigger,
                      "data-caption": _vm.dataCapion,
                      "is-design": _vm.isDesign,
                      owner: _vm._self,
                    },
                  },
                  [_vm._v(" " + _vm._s(_vm.$t(_vm.label)) + " ")]
                )
              : _vm._e(),
            _c(
              "div",
              {
                staticClass: "ds-form-block",
                class: { border: _vm.border || _vm.isButton },
                style: _vm.getFormItemBlockMarginLeft(),
              },
              [
                _vm.readOnly
                  ? _c(
                      "div",
                      { staticClass: "ds-form-readonly" },
                      [
                        _c(
                          "DsfTextProxy",
                          _vm._b(
                            {
                              attrs: { "show-label": false },
                              model: {
                                value: _vm.value,
                                callback: function ($$v) {
                                  _vm.value = $$v
                                },
                                expression: "value",
                              },
                            },
                            "DsfTextProxy",
                            _vm._self.$props,
                            false
                          )
                        ),
                      ],
                      1
                    )
                  : _vm.isButton
                  ? _c(
                      "el-radio-group",
                      {
                        attrs: { disabled: _vm.disabled },
                        on: { change: _vm.selectChange },
                        model: {
                          value: _vm.selectValue,
                          callback: function ($$v) {
                            _vm.selectValue = $$v
                          },
                          expression: "selectValue",
                        },
                      },
                      [
                        _vm._l(_vm.realItems, function (item, index) {
                          return [
                            _c(
                              "el-radio-button",
                              {
                                key: index,
                                ref: "radio",
                                refInFor: true,
                                attrs: {
                                  label: item[_vm.valueField],
                                  disabled:
                                    item.disabled ||
                                    _vm.disabledItemValues.indexOf(
                                      item[_vm.valueField]
                                    ) > -1,
                                },
                                nativeOn: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.itemClick(item, index)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(item[_vm.optionsTextField]) + " "
                                ),
                              ]
                            ),
                          ]
                        }),
                      ],
                      2
                    )
                  : !_vm.attachItemValues.length
                  ? _c(
                      "el-radio-group",
                      {
                        attrs: { disabled: _vm.disabled },
                        on: { change: _vm.selectChange },
                        model: {
                          value: _vm.selectValue,
                          callback: function ($$v) {
                            _vm.selectValue = $$v
                          },
                          expression: "selectValue",
                        },
                      },
                      [
                        _vm._l(_vm.realItems, function (item, index) {
                          return [
                            _c(
                              "el-radio",
                              {
                                key: index,
                                ref: "radio",
                                refInFor: true,
                                attrs: {
                                  label: item[_vm.valueField],
                                  border: _vm.border,
                                  disabled:
                                    item.disabled ||
                                    _vm.disabledItemValues.indexOf(
                                      item[_vm.valueField]
                                    ) > -1,
                                },
                                nativeOn: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.itemClick(item, index)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(item[_vm.optionsTextField]) + " "
                                ),
                              ]
                            ),
                          ]
                        }),
                      ],
                      2
                    )
                  : _c(
                      "el-radio-group",
                      {
                        attrs: { disabled: _vm.disabled },
                        on: { change: _vm.selectChange },
                        model: {
                          value: _vm.selectValue,
                          callback: function ($$v) {
                            _vm.selectValue = $$v
                          },
                          expression: "selectValue",
                        },
                      },
                      [
                        _vm._l(_vm.realItems, function (item, index) {
                          return [
                            _c(
                              "div",
                              { key: index, staticClass: "ds-radio-item" },
                              [
                                _c(
                                  "el-radio",
                                  {
                                    ref: "radio",
                                    refInFor: true,
                                    attrs: {
                                      label: item[_vm.valueField],
                                      border: _vm.border,
                                      disabled:
                                        item.disabled ||
                                        _vm.disabledItemValues.indexOf(
                                          item[_vm.valueField]
                                        ) > -1,
                                    },
                                    nativeOn: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.itemClick(item, index)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(item[_vm.optionsTextField]) +
                                        " "
                                    ),
                                  ]
                                ),
                                _vm._t("append-input", function () {
                                  return [
                                    _vm.attachOptions[item[_vm.valueField]] !==
                                    void 0
                                      ? _c("el-input", {
                                          staticClass: "ds-radio-input",
                                          class: { "is-bordered": _vm.border },
                                          style: { width: _vm.attachWidth },
                                          attrs: {
                                            value:
                                              _vm.attachOptions[
                                                item[_vm.valueField]
                                              ],
                                            size: "small",
                                            placeholder: "请输入内容",
                                          },
                                          on: {
                                            input: function ($event) {
                                              return _vm.setAttach(
                                                item[_vm.valueField],
                                                $event
                                              )
                                            },
                                          },
                                        })
                                      : _vm._e(),
                                  ]
                                }),
                              ],
                              2
                            ),
                          ]
                        }),
                      ],
                      2
                    ),
                _vm._t("error", function () {
                  return [
                    _vm.errors.length
                      ? _c("div", { staticClass: "ds-error-text" }, [
                          _vm._v(_vm._s(_vm.errorsMsg)),
                        ])
                      : _vm._e(),
                  ]
                }),
              ],
              2
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }