var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "label",
    {
      staticClass: "dsf-custom-checkbox",
      class: [
        _vm.styleType,
        _vm.size,
        { disabled: _vm.disabled, "is-checked": _vm.isChecked },
      ],
      on: {
        click: function ($event) {
          $event.stopPropagation()
        },
      },
    },
    [
      _vm.styleType == "style1"
        ? [_c("span", { staticClass: "iconfont icon-dui" })]
        : _vm.styleType == "style2"
        ? [_c("span", { staticClass: "iconfont" })]
        : _vm._e(),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.isChecked,
            expression: "isChecked",
          },
        ],
        attrs: {
          disabled: _vm.disabled,
          type: "checkbox",
          "aria-hidden": "false",
        },
        domProps: {
          checked: Array.isArray(_vm.isChecked)
            ? _vm._i(_vm.isChecked, null) > -1
            : _vm.isChecked,
        },
        on: {
          change: [
            function ($event) {
              var $$a = _vm.isChecked,
                $$el = $event.target,
                $$c = $$el.checked ? true : false
              if (Array.isArray($$a)) {
                var $$v = null,
                  $$i = _vm._i($$a, $$v)
                if ($$el.checked) {
                  $$i < 0 && (_vm.isChecked = $$a.concat([$$v]))
                } else {
                  $$i > -1 &&
                    (_vm.isChecked = $$a
                      .slice(0, $$i)
                      .concat($$a.slice($$i + 1)))
                }
              } else {
                _vm.isChecked = $$c
              }
            },
            function ($event) {
              return _vm.$emit("change", $event)
            },
          ],
        },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }