<template>
  <div class="ds-control ds-form-item ds-date-picker" :class="getCss">
    <template v-if="simple && readOnly && !isDesign">
      <span :title="getShowContent() | proxy(formatter)">{{ getShowContent() | proxy(formatter) }}</span>
      <slot name="error">
        <div v-if="errors.length>0" class="ds-error-text">{{ errorsMsg }}</div>
      </slot>
    </template>
    <template v-else>
      <!-- <label v-if="showLabel" class="ds-form-label" :style="getLabelWidthStyle()">
        {{ label }}
        <i v-if="dataCapion" class="iconfont icon-bangzhuzhongxin ds-control-helper" @click="showHelper"></i>
      </label> -->
      <DsfFieldLabel
        v-if="showLabel"
        :mode="showDataCaptionMode"
        :style="getLabelWidthStyle()"
        :show-icon="showDataCaptionIcon" 
        :trigger="showDataCaptionTrigger"
        :data-caption="dataCapion"
        :is-design="isDesign"
        :owner="_self">
        {{ $t(label) }}
      </DsfFieldLabel>
      <div class="ds-form-block" :class="{'ds-error-border':errors.length>0}" :style="getFormItemBlockMarginLeft()">
        <el-time-picker
          v-if="!readOnly && hourStep === 1"
          v-model="value2"
          :disabled="disabled"
          :editable="false"
          :clearable="clearable"
          :placeholder="placeholder"
          :picker-options="pickerOptions"
          :format="timeFormat"
          @change="changeHandler"
          value-format="HH:mm:ss" />
        <el-time-select
          v-else-if="!readOnly && hourStep > 1"
          v-model="value2"
          :disabled="disabled"
          :editable="false"
          :clearable="clearable"
          :placeholder="placeholder"
          :picker-options="pickerOptions"
          :format="timeFormat"
          @change="changeHandler"
          value-format="HH:mm:ss" />
        <!--只读状态只显示div-->
        <div v-else-if="readOnly" class="ds-form-readonly">
          <span :title="getShowContent() | proxy(formatter)">{{ getShowContent() | proxy(formatter) }}</span>
        </div>
        <slot name="error">
          <div v-if="errors.length>0" class="ds-error-text">{{ errorsMsg }}</div>
        </slot>
      </div>
    </template>
  </div>
</template>

<script>
export default dsf.component({
  name: "DsfTimePicker",
  mixins: [$mixins.formControl],
  ctrlCaption: "时间",
  props: {
    simple: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ""
    },
    value: {
      type: String,
      default: ""
    },
    label: {
      type: String,
      default: "时间选择"
    },
    // 时间格式
    timeFormat: {
      type: String,
      default: "HH:mm:ss"
    },
    // 是否显示清除按钮
    clearable: {
      type: Boolean,
      default: true
    },
    // 显示小时段
    timeInter: {
      type: Array,
      default() {
        return ["00:00:00", "23:59:59"];
      }
    },
    // 显示分钟步长
    hourStep: {
      type: Number,
      default: 1
    },
    metadata: {
      type: Object,
      default() {
        return dsf.metadata.get("time-picker-meta-data");
      }
    }
  },
  data() {
    return {
      value2: ""
    };
  },
  watch: {
    value(to) {
      this.initValue(to);
    }
    // 'value2' (to) {
    //   let v = to || null;
    //   this.emitValueChange(v);
    // },
  },
  computed: {
    pickerOptions() {
      if (this.hourStep > 1) {
        const ss = this.hourStep % 60;
        const mm = parseInt(this.hourStep / 60);
        return {
          start: this.timeInter[0].substr(0, 5),
          end: this.timeInter[1].substr(0, 5),
          step: mm + ":" + ss
        };
      }
      return {
        selectableRange: this.timeInter[0] + "-" + this.timeInter[1]
      };
    }
  },
  created() {
    this.initValue(this.value);
  },
  methods: {
    changeHandler() {
      this.emitValueChange(this.value2);
      this.$nextTick(() => {
        this.emitFormValidate(this.value);
        this.$dispatch("change", this.value);
      });
    },
    initValue(v) {
      if (v !== null && v !== undefined && v !== "" && v !== this.value2) {
        this.value2 = v;
      }
    }
  }
});
</script>