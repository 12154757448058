var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: !_vm.hasInfiniteScroll && _vm.loading,
          expression: "!hasInfiniteScroll && loading",
        },
      ],
      staticClass: "ds-control ds-datagrid",
      class: _vm.getCss,
    },
    [
      _c("DsfPanel", {
        staticClass: "ds-datagrid-panel",
        style: _vm.getListStyle,
        attrs: {
          "has-title": _vm.showTitle,
          "has-header": _vm.showHead,
          "background-config": _vm.backgroundConfig,
          "title-border": "",
        },
        scopedSlots: _vm._u(
          [
            {
              key: "title",
              fn: function () {
                return [
                  _vm._v(
                    " " +
                      _vm._s(_vm.isDesign ? _vm.label : _vm.getGridLabel()) +
                      " "
                  ),
                ]
              },
              proxy: true,
            },
            {
              key: "header-left",
              fn: function () {
                return [
                  _vm._t("header-left", function () {
                    return [
                      _c("div", { staticClass: "ds-datagrid-header-left" }, [
                        _c(
                          "span",
                          { staticClass: "ds-datagrid-header-left-showType" },
                          _vm._l(_vm.layoutInfoCopy, function (item) {
                            return _c("i", {
                              key: item.id,
                              class: [
                                "show-type-icon",
                                "iconfont",
                                item.icon,
                                { active: _vm.currentShowLayout.id == item.id },
                              ],
                              on: {
                                click: function ($event) {
                                  _vm.currentShowLayout = item
                                },
                              },
                            })
                          }),
                          0
                        ),
                      ]),
                    ]
                  }),
                  _vm._t("header-subtitle"),
                ]
              },
              proxy: true,
            },
            {
              key: "header",
              fn: function () {
                return [
                  _vm._t("header", function () {
                    return [
                      _c("div", { staticClass: "ds-datagrid-header" }, [
                        _vm.checkboxColumn.show
                          ? _c(
                              "div",
                              {
                                staticClass: "head-right-item",
                                staticStyle: { "margin-left": "10px" },
                              },
                              [
                                !_vm.isSingleChecked
                                  ? _c(
                                      "span",
                                      {
                                        staticClass:
                                          "ds-datagrid-checkednumber ds_font5",
                                        style: {
                                          cursor: _vm.$showSelectionDialog
                                            ? "pointer"
                                            : "default",
                                        },
                                        on: { click: _vm.checkSelection },
                                      },
                                      [
                                        _c("i", {
                                          staticClass:
                                            "icon-093info iconfont icon-color ds_icon_4",
                                          staticStyle: {
                                            "margin-right": "5px",
                                          },
                                        }),
                                        _vm._v(" 已选："),
                                        _c(
                                          "font",
                                          { staticStyle: { margin: "0 5px" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.multipleSelection.length
                                              ) + " "
                                            ),
                                          ]
                                        ),
                                        _vm._v("条 "),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ]
                            )
                          : _vm._e(),
                        _vm.showSearch
                          ? _c(
                              "div",
                              {
                                staticClass: "head-right-item",
                                staticStyle: { "margin-left": "10px" },
                              },
                              [
                                _c(
                                  "form",
                                  {
                                    on: {
                                      submit: function ($event) {
                                        $event.stopPropagation()
                                        $event.preventDefault()
                                        return _vm.search.apply(null, arguments)
                                      },
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      staticClass: "search-input",
                                      attrs: {
                                        placeholder: _vm.searchPlaceholder,
                                      },
                                      model: {
                                        value: _vm.searchStr,
                                        callback: function ($$v) {
                                          _vm.searchStr = $$v
                                        },
                                        expression: "searchStr",
                                      },
                                    }),
                                    _c(
                                      "button",
                                      { staticClass: "search-button" },
                                      [
                                        _c("i", {
                                          staticClass:
                                            "iconfont icon-fangdajing",
                                        }),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm.buttons.length
                          ? _c(
                              "div",
                              {
                                staticClass: "head-right-item",
                                staticStyle: { "margin-left": "10px" },
                              },
                              [
                                _c("DsfButtonBar", {
                                  ref: "buttonbar",
                                  class: { "ds-no-padding": true },
                                  attrs: {
                                    "is-design": _vm.isDesign,
                                    "more-button-style": _vm.buttonsMoreStyle,
                                    "more-button-text": _vm.buttonsMoreText,
                                    "max-number": _vm.buttonsMaxNumber,
                                    buttons: _vm.buttons,
                                    "render-button": _vm.renderButton,
                                    local: _vm.local,
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "div",
                          {
                            staticClass: "head-right-item",
                            attrs: { "slot-name": "header-attach" },
                          },
                          [_vm._t("header-attach")],
                          2
                        ),
                      ]),
                    ]
                  }),
                ]
              },
              proxy: true,
            },
            {
              key: "default",
              fn: function () {
                return [
                  _c(
                    "div",
                    { staticClass: "ds-datagrid-box" },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "ds-datagrid-search-wrap",
                          class: { extend: _vm.superSearchExtend },
                        },
                        [
                          _vm._t("search-bar", function () {
                            return [
                              _vm.superSearchInfo.length > 0
                                ? [
                                    _c("DsfSuperSearch", {
                                      ref: "superSearchBar",
                                      staticStyle: { "margin-bottom": "10px" },
                                      attrs: {
                                        "is-design": _vm.isDesign,
                                        "super-search-row-border":
                                          _vm.superSearchRowBorder,
                                        "show-fitler":
                                          _vm.showSuperSearchFilter,
                                        "search-item-setting":
                                          _vm.superSearchInfo,
                                        owner: _vm._self,
                                        "default-rows-number":
                                          _vm.superSearchDefaultRowsNumber,
                                        "value-change-trigger": !_vm.superSearchButtonTrigger,
                                        float: _vm.superSearchFloat,
                                        maxHeight: _vm.superSearchMaxHeight,
                                        titleMaxWidth:
                                          _vm.superSearchTitleMaxWidth,
                                        "extend-button-position":
                                          _vm.superSearchExtendButtonPosition,
                                      },
                                      on: {
                                        "change-height":
                                          _vm.superSearchChangeHeight,
                                        extend: _vm.superSearchExtendHandler,
                                        loaded: _vm.superSearchLoadedHandler,
                                        change: _vm.superSearchChangeHandler,
                                      },
                                    }),
                                  ]
                                : _vm._e(),
                            ]
                          }),
                        ],
                        2
                      ),
                      _vm._t(
                        "custom-order",
                        function () {
                          return [
                            _vm.customerOrder.length &&
                            _vm.currentShowLayout.type == "cardList"
                              ? _c(
                                  "dsf-custom-order",
                                  {
                                    attrs: {
                                      "custom-order": _vm.customerOrder,
                                    },
                                  },
                                  [
                                    _vm._t("custom-order-right", null, {
                                      data: _vm.data,
                                      count: _vm.pageCount,
                                    }),
                                  ],
                                  2
                                )
                              : _vm._e(),
                          ]
                        },
                        { customOrder: _vm.customerOrder }
                      ),
                      _vm.isDesign
                        ? [
                            _vm._l(_vm.layoutInfoCopy, function (item) {
                              return [
                                item.type == "table"
                                  ? _c(
                                      "div",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value:
                                              _vm.currentShowLayout.id ==
                                              item.id,
                                            expression:
                                              "currentShowLayout.id == item.id",
                                          },
                                        ],
                                        key: item.id,
                                        staticClass:
                                          "ds-datagrid-content-wrap table-mode",
                                        style: _vm.getContentWrapStyle(item),
                                      },
                                      [
                                        _c("DsfDataGridTable", {
                                          ref: "table",
                                          refInFor: true,
                                          attrs: {
                                            "is-design": _vm.isDesign,
                                            columns: _vm.columns,
                                            "checkbox-column":
                                              _vm.checkboxColumn,
                                            "index-column": _vm.indexColumn,
                                            "operate-column": _vm.operateColumn,
                                            "row-buttons": _vm.rowButtons,
                                            "render-row-button":
                                              _vm.renderRowButton,
                                            "is-single-checked":
                                              _vm.isSingleChecked,
                                            border: true,
                                            fit: true,
                                            "header-cell-class-name":
                                              _vm.getHeaderClass,
                                            data: _vm.templateData,
                                            height: _vm.tableHeight,
                                            "show-summary": _vm.showSummary,
                                            "customer-order": _vm.customerOrder,
                                            "is-re-draw-hander":
                                              _vm.isReDrawHander,
                                          },
                                          on: {
                                            "row-dblclick":
                                              _vm.tableRowDblClick,
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              _vm._l(
                                                _vm.getLastColumns(),
                                                function (column) {
                                                  return {
                                                    key: column.columnId,
                                                    fn: function (scope) {
                                                      return [
                                                        _vm._t(
                                                          column.columnId,
                                                          null,
                                                          null,
                                                          scope
                                                        ),
                                                      ]
                                                    },
                                                  }
                                                }
                                              ),
                                              {
                                                key: "row-buttons",
                                                fn: function () {
                                                  return [
                                                    _c("DsfButtonBar", {
                                                      staticClass:
                                                        "ds-no-padding ds-no-drop",
                                                      attrs: {
                                                        size: "small",
                                                        "max-number":
                                                          _vm.rowButtonsMaxNumber,
                                                        "more-button-text":
                                                          _vm.rowButtonsMoreText,
                                                        "more-button-style":
                                                          _vm.rowButtonsMoreStyle,
                                                        "is-design":
                                                          _vm.isDesign,
                                                        buttons: _vm.rowButtons,
                                                        align: "center",
                                                      },
                                                    }),
                                                  ]
                                                },
                                                proxy: true,
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        }),
                                      ],
                                      1
                                    )
                                  : item.type == "cardList"
                                  ? _c(
                                      "div",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value:
                                              _vm.currentShowLayout.id ==
                                              item.id,
                                            expression:
                                              "currentShowLayout.id == item.id",
                                          },
                                        ],
                                        key: item.id,
                                        staticClass:
                                          "ds-datagrid-content-wrap card-mode",
                                        style: _vm.getContentWrapStyle(item),
                                      },
                                      [
                                        _c("dsf-platform-card-box", {
                                          attrs: {
                                            data: _vm.templateData,
                                            "column-number":
                                              _vm.cardListColumnNumber,
                                            "column-width":
                                              _vm.cardListColumnWidth,
                                            "margin-top": _vm.cardListMarginTop,
                                            checkbox: _vm.checkboxColumn.show,
                                            "max-check-num": _vm.isSingleChecked
                                              ? 1
                                              : 0,
                                            "checkbox-style":
                                              _vm.cardListCheckboxStyle,
                                            "checkbox-size":
                                              _vm.cardListCheckboxSize,
                                            "checkbox-position":
                                              _vm.cardListCheckboxPosition,
                                            "is-design": "",
                                          },
                                          on: {
                                            "update:columnWidth": function (
                                              $event
                                            ) {
                                              _vm.cardListColumnWidth = $event
                                            },
                                            "update:column-width": function (
                                              $event
                                            ) {
                                              _vm.cardListColumnWidth = $event
                                            },
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function (scope) {
                                                  return [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "ds-datagrid-slot",
                                                        attrs: {
                                                          "slot-name": item.id,
                                                        },
                                                      },
                                                      [
                                                        _vm._t(
                                                          item.id,
                                                          null,
                                                          null,
                                                          scope
                                                        ),
                                                      ],
                                                      2
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        }),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "ds-datagrid-hide-slot ds-datagrid-slot",
                                            attrs: {
                                              "slot-name": "datagrid-hide",
                                            },
                                          },
                                          [_vm._t("datagrid-hide")],
                                          2
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ]
                            }),
                          ]
                        : [
                            _vm._l(_vm.layoutInfoCopy, function (item) {
                              return [
                                item.type == "table"
                                  ? _c(
                                      "div",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value:
                                              _vm.currentShowLayout.id ==
                                              item.id,
                                            expression:
                                              "currentShowLayout.id == item.id",
                                          },
                                        ],
                                        key: item.id,
                                        staticClass:
                                          "ds-datagrid-content-wrap table-mode",
                                        style: _vm.getContentWrapStyle(item),
                                      },
                                      [
                                        _c(
                                          "DsfDataGridTable",
                                          {
                                            ref: "table",
                                            refInFor: true,
                                            attrs: {
                                              columns: _vm.columns,
                                              data: _vm.data,
                                              height: _vm.tableHeight,
                                              "row-key": _vm.getRowKey,
                                              "checkbox-column":
                                                _vm.checkboxColumn,
                                              "index-column": _vm.indexColumn,
                                              "operate-column":
                                                _vm.operateColumn,
                                              "render-row-button":
                                                _vm.renderRowButton,
                                              "disabled-row-button":
                                                _vm.disabledRowButton,
                                              "is-single-checked":
                                                _vm.isSingleChecked,
                                              "header-cell-class-name":
                                                _vm.getHeaderClass,
                                              "span-method": _vm.spanMethod,
                                              "customer-order":
                                                _vm.customerOrder,
                                              "customer-column-widths":
                                                _vm.tableCustomerColumnWidths,
                                              "is-re-draw-hander":
                                                _vm.isReDrawHander,
                                              selectable: _vm.selectable,
                                              "filter-result":
                                                _vm.tableFilterResult,
                                              "render-header": _vm.renderHeader,
                                              "row-cursor-pointer":
                                                _vm.rowCursorPointer,
                                              "row-class-name":
                                                _vm.rowClassName,
                                              "is-design": false,
                                              "emit-text": _vm.emitText,
                                              "show-summary": _vm.showSummary,
                                              border: "",
                                              stripe: "",
                                              fit: "",
                                              "context-menu-buttons":
                                                _vm.rowButtons,
                                            },
                                            on: {
                                              "update:columns": function (
                                                $event
                                              ) {
                                                _vm.columns = $event
                                              },
                                              "row-dblclick":
                                                _vm.tableRowDblClick,
                                              "table-column-resize":
                                                _vm.tableColumnResize,
                                              select:
                                                _vm.handleSelectionByTable,
                                              "sort-change": _vm.tableSort,
                                              "select-all": function ($event) {
                                                return _vm.handleSelectionAll(
                                                  $event,
                                                  "table"
                                                )
                                              },
                                              "on-contextmenu":
                                                _vm.onContextmenu,
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                _vm._l(
                                                  _vm.getLastColumns(),
                                                  function (column) {
                                                    return {
                                                      key: column.columnId,
                                                      fn: function (scope) {
                                                        return [
                                                          _vm._t(
                                                            column.columnId,
                                                            null,
                                                            null,
                                                            scope
                                                          ),
                                                        ]
                                                      },
                                                    }
                                                  }
                                                ),
                                                {
                                                  key: "row-buttons",
                                                  fn: function (scope) {
                                                    return [
                                                      _c("DsfButtonBar", {
                                                        staticClass:
                                                          "ds-no-padding",
                                                        attrs: {
                                                          size: "small",
                                                          "render-button":
                                                            _vm.renderRowButton,
                                                          "disabled-button":
                                                            _vm.disabledRowButton,
                                                          "max-number":
                                                            _vm.rowButtonsMaxNumber,
                                                          "more-button-text":
                                                            _vm.rowButtonsMoreText,
                                                          "more-button-style":
                                                            _vm.rowButtonsMoreStyle,
                                                          buttons:
                                                            scope.row.$buttons,
                                                          "emit-prefix":
                                                            "row-button",
                                                          local: scope.row,
                                                          align: "center",
                                                        },
                                                      }),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          },
                                          [_vm._t("default")],
                                          2
                                        ),
                                      ],
                                      1
                                    )
                                  : item.type == "cardList"
                                  ? _c(
                                      "div",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value:
                                              _vm.currentShowLayout.id ==
                                              item.id,
                                            expression:
                                              "currentShowLayout.id == item.id",
                                          },
                                        ],
                                        key: item.id,
                                        staticClass:
                                          "ds-datagrid-content-wrap card-mode",
                                        style: _vm.getContentWrapStyle(item),
                                      },
                                      [
                                        _c("dsf-platform-card-box", {
                                          ref: "cardBox",
                                          refInFor: true,
                                          attrs: {
                                            data: _vm.data,
                                            "column-number":
                                              _vm.cardListColumnNumber,
                                            "column-width":
                                              _vm.cardListColumnWidth,
                                            "margin-top": _vm.cardListMarginTop,
                                            checkbox: _vm.checkboxColumn.show,
                                            "max-check-num": _vm.isSingleChecked
                                              ? 1
                                              : 0,
                                            "checkbox-style":
                                              _vm.cardListCheckboxStyle,
                                            "checkbox-size":
                                              _vm.cardListCheckboxSize,
                                            "checkbox-position":
                                              _vm.cardListCheckboxPosition,
                                            selectable: _vm.selectable,
                                          },
                                          on: {
                                            select: _vm.handleSelectionByCard,
                                            "select-all": function ($event) {
                                              return _vm.handleSelectionAll(
                                                $event,
                                                "cardList"
                                              )
                                            },
                                            "on-contextmenu": _vm.onContextmenu,
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function (scope) {
                                                  return [
                                                    _vm._t(
                                                      item.id,
                                                      null,
                                                      {
                                                        rowButtons:
                                                          scope.row.$buttons,
                                                      },
                                                      scope
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ]
                            }),
                          ],
                      _vm.showPagination
                        ? _c(
                            "div",
                            {
                              staticClass: "ds-datagrid-pager",
                              class: [
                                "ds-datagrid-pager-align_" +
                                  _vm.paginationPosition,
                              ],
                            },
                            [
                              _c(
                                "el-pagination",
                                {
                                  ref: "pagination",
                                  class: [_vm.paginationStyle],
                                  attrs: {
                                    "hide-on-single-page": false,
                                    total: _vm.pageCount,
                                    "page-size": _vm.pageSize,
                                    "page-sizes": _vm.limits,
                                    "current-page": _vm.currentPage,
                                    layout: "slot," + _vm.pagerLayout,
                                  },
                                  on: {
                                    "update:currentPage": function ($event) {
                                      _vm.currentPage = $event
                                    },
                                    "update:current-page": function ($event) {
                                      _vm.currentPage = $event
                                    },
                                    "size-change": _vm.pageSizeChange,
                                  },
                                },
                                [
                                  _vm.currentShowLayout.type == "table"
                                    ? _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            "margin-right": "10px",
                                          },
                                        },
                                        [
                                          _c(
                                            "a",
                                            {
                                              staticClass:
                                                "ds-datagrid-pager-button",
                                              attrs: { title: "还原列表" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.headerReset()
                                                },
                                              },
                                            },
                                            [
                                              _c("DsfIcon", {
                                                attrs: { name: "chehuisekuai" },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "a",
                                            {
                                              staticClass:
                                                "ds-datagrid-pager-button",
                                              attrs: { title: "刷新" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.reloadData()
                                                },
                                              },
                                            },
                                            [
                                              _c("DsfIcon", {
                                                attrs: { name: "shuaxin" },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      !_vm.operateColumn.show
                        ? _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: false,
                                  expression: "false",
                                },
                              ],
                            },
                            _vm._l(_vm.data, function (item) {
                              return _c("DsfButtonBar", {
                                key: _vm.getRowKey(item),
                                staticClass: "ds-no-padding ds-no-drop",
                                staticStyle: { display: "none" },
                                attrs: {
                                  "row-hash": item.$hash,
                                  size: "small",
                                  "max-number": _vm.rowButtonsMaxNumber,
                                  "more-button-text": _vm.rowButtonsMoreText,
                                  "more-button-style": _vm.rowButtonsMoreStyle,
                                  buttons: _vm._self.rowButtons,
                                  local: item,
                                  align: "center",
                                },
                              })
                            }),
                            1
                          )
                        : _vm._e(),
                    ],
                    2
                  ),
                ]
              },
              proxy: true,
            },
          ],
          null,
          true
        ),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }