<!--template>
  <span class="ds-text-content-editable" contenteditable="">

  </span>
</template-->
<script>
export default {
  model: {
    prop: "text",
    event: "text-change"
  },
  name: "DsfTextContentEditable",
  ctrlCaption: "设计状态文本编辑",
  functional: true,
  inject: {
    $designer: {
      default: null
    }
  },
  props: {
    tag: {
      type: String,
      default: "div"
    },
    text: {
      type: String,
      default: ""
    },
    editable: {
      type: Boolean,
      default: false
    }
  },
  render(h, content) {
    let _h = content._c || h;
    let text = content.props.text;
    let editable = content.props.editable;
    if (!editable && !text) {
      return content._e();
    }
    // let id=dsf.uuid()
    // content.id=id;
    return _h(content.props.tag, {
      staticClass: `ds-text-content${content.data.staticClass ? " " + content.data.staticClass : ""}`,
      class: {
        editing: editable
      },
      attrs: {
        // id:id,
        tabindex: 1,
        contenteditable: editable
      },
      staticStyle: content.data.staticStyle,
      domProps: {
        innerHTML: text
      },
      on: {
        focus(event) {
          // debugger
          event.stopPropagation();
          event.preventDefault();
        },
        input(event) {
          // watchInput(event, content);
          content?.data?.on?.input?.(event);
        },
        // select(event){
        //   watchInput(event, content);
        // },
        blur(event) {
          let el = event.target;
          let text = el.innerText.trim();
          let html = text ? el.innerHTML : "";
          if (content.listeners["text-change"]) {
            content.listeners["text-change"](html);
          } else {
            if (content.data.on["update:text"]) {
              content.data.on["update:text"](html);
            }
          }
          content?.listeners?.blur?.(event);
          if (content.data.on["update:editable"]) {
            content.data.on["update:editable"](false);
          }
          // componentinfoDestroy();
        },
        ...(content?.data?.nativeOn || {})
      }
    });
  }
};
function isDesign(content) {
  if (content.injections.$designer) {
    return true;
  }
  return false;
}

// let oldValue = "";
// let searchIdx = -1;
// let componentListOptions = {
//   top: "0px",
//   left: "0px",
//   searchTxt: ""
// };
// function watchInput(event, content) {
//   let input = event.target;
//   let text = input.innerText;
//   oldValue = text;
//   let startOffset = getCursorPos(input);
//   let cursorBeforeStr = text.substr(0, startOffset + 1);
//   let searchStr = text.substr(cursorBeforeStr, startOffset + 1);
//   componentListOptions.searchTxt = searchStr;
//   if (cursorBeforeStr[cursorBeforeStr.length - 1] == "@") {
//     componentinfoDestroy();
//     searchIdx = cursorBeforeStr.length - 1;
//     if (document.querySelector(".des-componentinfo") <= 0) {
//       let point = getCursorPoint(input);
//       let jqInput = $(input);
//       componentListOptions.top = jqInput.offset().top + point.top + 20;
//       componentListOptions.left = jqInput.offset().left + point.left;
//       openComponentList(content, componentListOptions);
//     }
//   } else {
//     if (searchIdx >= 0 && startOffset <= searchIdx) {
//       componentinfoDestroy();
//     }
//   }
// }
// function componentinfoDestroy() {
//   let v = document.querySelectorAll(".des-componentinfo");
//   for (let i = 0; i < v.length; i++) {
//     let c = v[i]?.__vue__;
//     if (c) {
//       let el = c.$el;
//       c.$destroy();
//       el.parentNode.removeChild(el);
//     }
//   }
//   searchIdx = -1;
//   oldValue = "";
//   componentListOptions.top = 0;
//   componentListOptions.left = 0;
//   componentListOptions.searchTxt = "";
// }
// function getCursorPoint(input) {
//   let c = input.cloneNode(true);
//   c = $(c);
//   c.css("display", "inline-block");
//   c.width(input.offsetWidth);
//   c.height(input.offsetHeight);
//   let cursor = $("<span class='cursor'></span>");
//   c.append(cursor);
//   document.body.appendChild(c.get(0));
//   let point = {
//     top: cursor.offset().top - c.offset().top,
//     left: cursor.offset().left - c.offset().left
//   };
//   c.remove();
//   return point;
// }

// function getCursorPos(input) {
//   input.range = window.getSelection().getRangeAt(0);
//   let startOffset = input.range.startOffset;
//   return startOffset;

//   // input.range.setStart(input.range.startContainer,2);
// }
// function openComponentList(content, op) {
//   let options = {
//     data() {
//       return {
//         options: componentListOptions
//       };
//     },
//     template: `<DesComponentInfoList  :top="options.top" :left="options.left" :search-txt="options.searchTxt"></DesComponentInfoList>`
//   };
//   const Dialog = Vue.extend(options);
//   let component = new Dialog().$mount();
//   document.body.appendChild(component.$el);
// }
// function updateComponentList(content, op) {}
</script>