var render = function (_h, _vm) {
  var _c = _vm._c
  return _c(
    "el-tab-pane",
    {
      key: _vm.props.name,
      attrs: {
        disable: _vm.disable,
        label: _vm.props.title,
        name: _vm.props.name,
        "slot-name": _vm.props.name,
      },
    },
    [_vm._t("content")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }