var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "ds-control", style: _vm.controlStyle }, [
    _c(
      "div",
      {
        staticClass: "dsf-home-pending-process",
        style: _vm.panelStyle,
        attrs: { isDesign: _vm.isDesign },
      },
      [
        _vm.isDesign
          ? [
              _c("DsfVirtualList", {
                attrs: {
                  isDesign: _vm.isDesign,
                  styleAttrs: { hoverBright: true },
                  rowPadding: [0, 20, 0, 20],
                  rowMargin: [0, 20, 0, 20],
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "list-row",
                      fn: function (scope) {
                        return _vm._l(3, function (index) {
                          return _c(
                            "div",
                            {
                              key: index,
                              staticClass: "dsf-home-pending-process-row",
                              attrs: { read: false },
                            },
                            [
                              _c("div", { staticClass: "dsf-uhppr-normal" }, [
                                _c(
                                  "div",
                                  { staticClass: "dsf-uhppr-name-box" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "dsf-uhppr-name ellipsis",
                                        attrs: {
                                          title: scope.row && scope.row.title,
                                        },
                                      },
                                      [_vm._v(" [费用申请流程]家具购置申请 ")]
                                    ),
                                    _c(
                                      "span",
                                      {
                                        staticClass: "dsf-uhppr-desc ellipsis",
                                        attrs: {
                                          title: "请尽快处理，后交张三疯审核",
                                        },
                                      },
                                      [_vm._v(" 描述 ")]
                                    ),
                                  ]
                                ),
                                _vm.nodeNameShow
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "dsf-uhppr-node ellipsis",
                                        attrs: { title: "审批中" },
                                      },
                                      [_vm._v("审批中")]
                                    )
                                  : _vm._e(),
                                _c(
                                  "div",
                                  {
                                    staticClass: "dsf-uhppr-ren ellipsis",
                                    attrs: { title: "管理员" },
                                  },
                                  [_vm._v("管理员")]
                                ),
                                _c("div", { staticClass: "dsf-uhppr-time" }, [
                                  _vm._v("2020-10-01"),
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "dsf-uhppr-operating" },
                                  [
                                    _vm.$slots["operating"]
                                      ? [_vm._t("operating", null, null, scope)]
                                      : [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "dsf-uhppr-operating-item",
                                              on: {
                                                click: function (e) {
                                                  return _vm.operatingClick(
                                                    e,
                                                    Object.assign(
                                                      {},
                                                      { type: "gz" },
                                                      scope
                                                    )
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("DsfIcon", {
                                                staticStyle: {
                                                  color: "#fc6a6a",
                                                },
                                                attrs: { name: "aixin" },
                                              }),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "dsf-uhppr-operating-item-text",
                                                },
                                                [_vm._v("关注")]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "dsf-uhppr-operating-item",
                                              on: {
                                                click: function (e) {
                                                  return _vm.operatingClick(
                                                    e,
                                                    Object.assign(
                                                      {},
                                                      { type: "hf" },
                                                      scope
                                                    )
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("DsfIcon", {
                                                staticStyle: {
                                                  color: "#007ef7",
                                                },
                                                attrs: { name: "xiaoxi" },
                                              }),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "dsf-uhppr-operating-item-text",
                                                },
                                                [_vm._v("回复")]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "dsf-uhppr-operating-item",
                                              on: {
                                                click: function (e) {
                                                  return _vm.operatingClick(
                                                    e,
                                                    Object.assign(
                                                      {},
                                                      { type: "zd" },
                                                      scope
                                                    )
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("DsfIcon", {
                                                staticStyle: {
                                                  color: "#efaf02",
                                                },
                                                attrs: { name: "zhiding2" },
                                              }),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "dsf-uhppr-operating-item-text",
                                                },
                                                [_vm._v("置顶")]
                                              ),
                                            ],
                                            1
                                          ),
                                          scope.row.type == 0
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "dsf-uhppr-operating-item",
                                                  on: {
                                                    click: function (e) {
                                                      return _vm.operatingClick(
                                                        e,
                                                        Object.assign(
                                                          {},
                                                          { type: "th" },
                                                          scope
                                                        )
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("DsfIcon", {
                                                    staticStyle: {
                                                      color: "#fd7911",
                                                    },
                                                    attrs: {
                                                      name: "chehuisekuai",
                                                    },
                                                  }),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "dsf-uhppr-operating-item-text",
                                                    },
                                                    [_vm._v("退回")]
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ],
                                  ],
                                  2
                                ),
                              ]),
                            ]
                          )
                        })
                      },
                    },
                  ],
                  null,
                  true
                ),
              }),
            ]
          : [
              _c("DsfVirtualList", {
                attrs: {
                  url: _vm.url,
                  list: _vm.list,
                  "is-design": _vm.isDesign,
                  "style-attrs": { hoverBright: true },
                  "row-padding": [0, 0, 0, 0],
                  "row-margin": [0, 20, 0, 20],
                  "url-query": _vm.urlQuery,
                },
                on: { "row-click": _vm.rowClick },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "list-row",
                      fn: function (scope) {
                        return [
                          _vm._t(
                            "row",
                            function () {
                              return [
                                _c(
                                  "div",
                                  {
                                    staticClass: "dsf-home-pending-process-row",
                                    attrs: { read: scope.row.status != 0 },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "dsf-uhppr-normal" },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "dsf-uhppr-name-box" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "dsf-uhppr-name ellipsis",
                                                attrs: {
                                                  title:
                                                    scope.row &&
                                                    scope.row.title,
                                                },
                                              },
                                              [
                                                scope.row.overDueState == 1
                                                  ? _c("img", {
                                                      staticClass:
                                                        "dsf-uhppr-ontop",
                                                      attrs: {
                                                        src: require("_platform/assets/styles/themes/img/home/db_danger.png"),
                                                      },
                                                    })
                                                  : _vm._e(),
                                                scope.row.isUrge == 1
                                                  ? _c("img", {
                                                      staticClass:
                                                        "dsf-uhppr-ontop",
                                                      attrs: {
                                                        src: require("_platform/assets/styles/themes/img/home/db_cui.png"),
                                                      },
                                                    })
                                                  : _vm._e(),
                                                scope.row.emergency > 0 &&
                                                scope.row.emergency < 3
                                                  ? _c("img", {
                                                      staticClass:
                                                        "dsf-uhppr-ontop",
                                                      attrs: {
                                                        src: require("_platform/assets/styles/themes/img/home/db_ji.png"),
                                                      },
                                                    })
                                                  : _vm._e(),
                                                scope.row.emergency > 2 &&
                                                scope.row.emergency < 5
                                                  ? _c("img", {
                                                      staticClass:
                                                        "dsf-uhppr-ontop",
                                                      attrs: {
                                                        src: require("_platform/assets/styles/themes/img/home/db_te.png"),
                                                      },
                                                    })
                                                  : _vm._e(),
                                                scope.row.isBack == 1
                                                  ? _c("img", {
                                                      staticClass:
                                                        "dsf-uhppr-ontop",
                                                      attrs: {
                                                        src: require("_platform/assets/styles/themes/img/home/db_tui.png"),
                                                      },
                                                    })
                                                  : _vm._e(),
                                                scope.row.secret > 1
                                                  ? _c("img", {
                                                      staticClass:
                                                        "dsf-uhppr-ontop",
                                                      attrs: {
                                                        src: require("_platform/assets/styles/themes/img/home/db_mi.png"),
                                                      },
                                                    })
                                                  : _vm._e(),
                                                scope.row.isTop == 1
                                                  ? _c("img", {
                                                      staticClass:
                                                        "dsf-uhppr-ontop",
                                                      attrs: {
                                                        src: require("_platform/assets/styles/themes/img/home/top.png"),
                                                      },
                                                    })
                                                  : _vm._e(),
                                                scope.row.signs.length
                                                  ? [
                                                      _vm._l(
                                                        scope.row.signs,
                                                        function (sign, index) {
                                                          return [
                                                            sign.icon
                                                              ? _c("img", {
                                                                  key: index,
                                                                  staticClass:
                                                                    "dsf-uhppr-ontop",
                                                                  attrs: {
                                                                    src: _vm.dsf.url.getWebPath(
                                                                      sign.icon
                                                                    ),
                                                                  },
                                                                })
                                                              : _vm._e(),
                                                          ]
                                                        }
                                                      ),
                                                    ]
                                                  : _vm._e(),
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      scope.row &&
                                                        scope.row.title
                                                    ) +
                                                    " "
                                                ),
                                              ],
                                              2
                                            ),
                                          ]
                                        ),
                                        _vm.nodeNameShow &&
                                        scope.row &&
                                        scope.row.nodeName
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "dsf-uhppr-node ellipsis",
                                                attrs: {
                                                  title:
                                                    scope.row &&
                                                    scope.row.nodeName,
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      scope.row &&
                                                        scope.row.nodeName
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        scope.row && scope.row.sendUserName
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "dsf-uhppr-ren ellipsis",
                                                attrs: {
                                                  title:
                                                    scope.row &&
                                                    scope.row.sendUserName,
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      scope.row &&
                                                        scope.row.sendUserName
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _c(
                                          "div",
                                          { staticClass: "dsf-uhppr-time" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm._f("time")(
                                                    scope.row &&
                                                      scope.row.sendDateTime
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "dsf-uhppr-operating",
                                          },
                                          [
                                            _vm._t(
                                              "operating",
                                              function () {
                                                return [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "dsf-uhppr-operating-item",
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          $event.stopPropagation()
                                                          return function (e) {
                                                            return _vm.operatingClick(
                                                              e,
                                                              Object.assign(
                                                                {},
                                                                { type: "gz" },
                                                                scope
                                                              )
                                                            )
                                                          }.apply(
                                                            null,
                                                            arguments
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      scope.row.isConcern == 1
                                                        ? [
                                                            _c("DsfIcon", {
                                                              staticStyle: {
                                                                color:
                                                                  "#fc6a6a",
                                                              },
                                                              attrs: {
                                                                name:
                                                                  "aixin_shixin",
                                                              },
                                                            }),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "dsf-uhppr-operating-item-text",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " 取消关注 "
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        : [
                                                            _c("DsfIcon", {
                                                              staticStyle: {
                                                                color:
                                                                  "#fc6a6a",
                                                              },
                                                              attrs: {
                                                                name: "aixin",
                                                              },
                                                            }),
                                                            scope.row
                                                              .isConcern == 0
                                                              ? _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "dsf-uhppr-operating-item-text",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " 关注 "
                                                                    ),
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                          ],
                                                    ],
                                                    2
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "dsf-uhppr-operating-item",
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          $event.stopPropagation()
                                                          return function (e) {
                                                            return _vm.operatingClick(
                                                              e,
                                                              Object.assign(
                                                                {},
                                                                { type: "zd" },
                                                                scope
                                                              )
                                                            )
                                                          }.apply(
                                                            null,
                                                            arguments
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      scope.row.isTop == 1
                                                        ? [
                                                            _c("DsfIcon", {
                                                              staticStyle: {
                                                                color:
                                                                  "#efaf02",
                                                                transform:
                                                                  "scaleY(-1)",
                                                              },
                                                              attrs: {
                                                                name:
                                                                  "zhiding2",
                                                              },
                                                            }),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "dsf-uhppr-operating-item-text",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " 取消置顶 "
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        : [
                                                            _c("DsfIcon", {
                                                              staticStyle: {
                                                                color:
                                                                  "#efaf02",
                                                              },
                                                              attrs: {
                                                                name:
                                                                  "zhiding2",
                                                              },
                                                            }),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "dsf-uhppr-operating-item-text",
                                                              },
                                                              [_vm._v("置顶")]
                                                            ),
                                                          ],
                                                    ],
                                                    2
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "dsf-uhppr-operating-item",
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          $event.stopPropagation()
                                                          return function (e) {
                                                            return _vm.operatingClick(
                                                              e,
                                                              Object.assign(
                                                                {},
                                                                { type: "cz" },
                                                                scope
                                                              )
                                                            )
                                                          }.apply(
                                                            null,
                                                            arguments
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c("DsfIcon", {
                                                        staticStyle: {
                                                          color: "#007ef7",
                                                        },
                                                        attrs: {
                                                          name: "xiaoxi",
                                                        },
                                                      }),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "dsf-uhppr-operating-item-text",
                                                        },
                                                        [_vm._v("操作")]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  scope.row.type == 0
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "dsf-uhppr-operating-item",
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              $event.stopPropagation()
                                                              return function (
                                                                e
                                                              ) {
                                                                return _vm.operatingClick(
                                                                  e,
                                                                  Object.assign(
                                                                    {},
                                                                    {
                                                                      type:
                                                                        "th",
                                                                    },
                                                                    scope
                                                                  )
                                                                )
                                                              }.apply(
                                                                null,
                                                                arguments
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c("DsfIcon", {
                                                            staticStyle: {
                                                              color: "#007ef7",
                                                            },
                                                            attrs: {
                                                              name:
                                                                "tuihuibeifen",
                                                            },
                                                          }),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "dsf-uhppr-operating-item-text",
                                                            },
                                                            [_vm._v("退回")]
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                ]
                                              },
                                              null,
                                              scope
                                            ),
                                          ],
                                          2
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            },
                            null,
                            scope
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              }),
            ],
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }