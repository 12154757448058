<template>
  <div class="dsf-multiPage-home-view">
    <iframe v-if="show" ref="iframe" :src="src" :wid="page.menu_id" frameborder="0" dsf-spv @load="load" />
  </div>
</template>

<script>
export default {
  name: "HomeView",
  props: {
    page: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      init: false,
      show: true
    };
  },
  computed: {
    src() {
      if (!this.init) return "about:blank";
      let url = this.page.menu_url;
      if (!url) return "about:blank";
      if (url.indexOf("#") == 0 && url.indexOf("#/") != 0) {
        url = url.replace("#", "#/");
      }
      if (url.indexOf("#") == 0) {
        url = "./page.html" + url;
      }
      return url;
    }
  },
  watch: {
    init: {
      handler(to, from) {
        if (from === undefined && this.$parent.activeTag?.menu_id === this.page.menu_id) {
          this.init = true;
        } else if (to) {
          this.$set(this.page, "loading", "加载中...");
        }
      },
      immediate: true
    },
    show(to) {
      if (to && this.$refs.iframe) {
        this.$nextTick(() => {
          this.$refs.iframe.args = this.page.dialogArgs;
          this.$refs.iframe.openerVm = this.page.openerVm || null;
        });
      }
    }
  },
  mounted() {
    let _this = this;
    if (this.show && this.$refs.iframe) {
      this.$refs.iframe.args = this.page.dialogArgs;
      Object.defineProperty(this.$refs.iframe, "openerVm", {
        get() {
          return _this.page.openerVm;
        }
      });
      Object.defineProperty(this.$refs.iframe, "opener", {
        get() {
          return _this.page.opener;
        }
      });
      this.$refs.iframe.setTitle = function () {
        this.$set(this.page, "menu_name", document.title);
      }.bind(this);
    }
  },
  beforeDestroy() {
    try {
      this?.$refs?.iframe?.setAttribute?.("src", "about:blank");
      this?.$refs?.iframe?.contentWindow?.document?.write?.("");
      this?.$refs?.iframe?.contentWindow?.document?.close?.();
    } catch (ex) {
      //
    }

    clearTimeout(this._timer);
  },
  methods: {
    // 当前窗口被激活
    active() {
      this.init = true;
      // 触发当前激活iframe的resize
      this.frameResize();
    },
    refresh() {
      let contentWindow = this.getFrameWindow();
      if (contentWindow) {
        contentWindow.location.reload(true);
        this.$set(this.page, "loading", "刷新中...");
      } else {
        this.show = false;
        this.$set(this.page, "loading", "刷新中...");
        this.$nextTick(() => {
          this.show = true;
        });
      }
    },
    load() {
      if (!this.page.menu_name) {
        let contentWindow = this.getFrameWindow();
        if (contentWindow) {
          let _this = this;
          let contentDocument = contentWindow.document;
          if (contentDocument.title) {
            this.$set(this.page, "loading", null);
          } else {
            clearTimeout(this._timer);
            this._timer = setTimeout(() => {
              _this.$set(_this.page, "menu_name", contentDocument.title);
              _this.$set(_this.page, "loading", null);
            }, 1000);
          }
          Object.defineProperty(contentDocument, "title", {
            get() {
              return _this.page.menu_name;
            },
            set(val) {
              _this.$set(_this.page, "menu_name", val);
              _this.$set(_this.page, "loading", null);
            }
          });
          this.page.callback?.(contentWindow);
        } else {
          let title = this.page.menu_url;
          title = title.length <= 6 ? title : title.substr(0, 6) + "...";
          this.$set(this.page, "menu_name", title);
          this.$set(this.page, "loading", null);
          this.page.callback?.(null);
        }
      } else {
        this.$set(this.page, "loading", null);
        this.page.callback?.(null);
      }
    },
    getFrameWindow() {
      return this.$refs.iframe?.contentWindow;
    },
    // 触发iframe的resize
    frameResize() {
      let contentWindow = this.getFrameWindow();
      if (contentWindow) {
        let evt = contentWindow.document.createEvent("HTMLEvents");
        evt.initEvent("resize", false, false);
        contentWindow.dispatchEvent(evt);
      }
    }
  }
};
</script>