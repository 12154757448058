var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.show,
          expression: "show",
        },
      ],
      staticClass: "ds-context-menu",
      style: _vm.rootStyle,
      attrs: { tabindex: "0" },
      on: {
        blur: _vm.blur,
        contextmenu: function ($event) {
          $event.preventDefault()
          $event.stopPropagation()
        },
      },
    },
    [
      _vm.show
        ? _c("dsf-context-menu-group", { attrs: { list: _vm.list } })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }