var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "ds-data-panel ds-data-panel-primary",
      style: _vm.panelStyle,
      attrs: { shadow: _vm.showShadow },
      on: { click: _vm.onClick },
    },
    [
      _vm._t(
        "item",
        function () {
          return [
            _vm.showIcon
              ? _c(
                  "div",
                  {
                    staticClass: "ds-data-panel-icon",
                    style: _vm.iconBoxStyle,
                  },
                  [
                    _vm._t(
                      "itemLeft",
                      function () {
                        return [
                          _c("DsfIcon", {
                            style: _vm.iconStyle,
                            attrs: {
                              name: _vm.$replace(_vm.iconName, _vm.panelData),
                            },
                          }),
                        ]
                      },
                      null,
                      _vm.$props
                    ),
                  ],
                  2
                )
              : _vm._e(),
            _c(
              "div",
              {
                staticClass: "ds-data-panel-primary-info",
                attrs: { align: !_vm.showDesc ? "center" : "" },
              },
              [
                _vm._t(
                  "itemRight",
                  function () {
                    return [
                      _c("div", { staticClass: "ds-data-panel-top" }, [
                        _vm.showTitle
                          ? _c(
                              "div",
                              {
                                staticClass: "ds-data-panel-primary-info-item",
                                attrs: { type: "name" },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$replace(_vm.title, _vm.panelData)
                                    ) +
                                    " "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _c(
                          "div",
                          {
                            staticClass: "ds-data-panel-primary-info-item",
                            attrs: { type: "number" },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$replace(_vm.number, _vm.panelData)
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ]),
                      _vm.showDesc
                        ? _c(
                            "div",
                            {
                              staticClass: "ds-data-panel-primary-info-item",
                              attrs: { type: "desc" },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$replace(_vm.desc, _vm.panelData)
                                  ) +
                                  " "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ]
                  },
                  null,
                  _vm.$props
                ),
              ],
              2
            ),
          ]
        },
        null,
        _vm.$props
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }