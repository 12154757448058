const batchSend = {
  flowSend(res, { targetButton, _self }, Objects) {
    const { line, flowSendOverEvent, completed } = targetButton;
    batchSend.flowSendOverEvent = flowSendOverEvent;
    const flowInfo = this.$vm.flows.flowInfo;
    if (!flowInfo) {
      dsf.layer.message("请先配置流程信息控件的'选择流程'", false);
      return;
    }
    // 流程发送按钮
    let id = flowInfo.chooseFlow.dsfa_rm_id;
    if (id) {
      // 增加 sExInfos
      let sExInfos = Object.assign({}, dsf.removePoint(this.$vm.$viewData), this.$route.query);
      // 开始发送
      let _this = this,
        url = "wfr/batchStartSend",
        param = {
          sObjects: JSON.stringify(Objects),
          sWFID: id,
          sCurNodeID: "",
          sExInfos: JSON.stringify(sExInfos),
        };

      //继续发送
      if (this.$route.query.sPID || _self.$vm.$dialog || this.queryString.sPID) {
        url = "wfr/batchSend";
        let sObjects = [];
        Objects.forEach(obj => {
          sObjects.push({
            PID: _self.$vm.$dialog ? obj.sPID : this.$route.query.sPID || this.queryString.sPID,
            LinkID: _self.$vm.$dialog ? obj.sCurLinkID : this.$route.query.sCurLinkID || this.queryString.sCurLinkID,
          });
        });
        param.sObjects = JSON.stringify(sObjects);
      }
      // 发送时有处理意见
      if (this.$vm?.flows?.disposeIdea?.sOpinion) {
        param.sOpinion = JSON.stringify(this.$vm.flows.disposeIdea.sOpinion);
      }
      // 发送时表单意见框有处理意见
      if (this.$vm?.flows?.sOpinion?.Content || this.$vm?.flows?.sOpinion?.Attachments?.length > 0) {
        param.sOpinion = JSON.stringify(this.$vm.flows.sOpinion);
      }
      if (line) {
        param.sLineIDs = line.LineID;
      }

      let config = {
        param: param,
        url: url,
      };

      this.$http
        .post(config.url, config.param)
        .then((response) => {
          this.handler(response, completed);
        })
        .catch((response) => {
          dsf.layer.message("发送异常", false);
          this._RequestID = null;
        })
        .finally(() => {
          // 防止网络请求很快的情况
          setTimeout(() => {
            targetButton.disabled = false;
          }, 200);
        });
    } else {
      dsf.layer.message("请先配置流程信息控件的'选择流程'", false);
    }
  },
  handler(response, completed) {
    if (response.data.state == 20000) {
      let data = response.data.data;
      this._RequestID = data.RequestID;
      if (
        data.Code == 20000 ||
        data.Code == 20001 ||
        data.Code == 20003 ||
        data.Code == 20004 ||
        data.Code == 20005
      ) {
        // 发送成功 || 发送到汇总节点 || 发送到汇总环节并激活汇总环节 || 办结当前节点 || 办结当前节点，并办结整个流程
        if (batchSend.flowSendOverEvent) {
          this.$dispatch(batchSend.flowSendOverEvent, data, completed);
        } else {
          this.directlySend(data, completed);
        }
      } else if (data.Code == 81000) {
        // 或选择
        this.chooseSendNode(data);
      } else if (data.Code == 82000) {
        // 与选择
        this.chooseSendTree(data);
      } else {
        let msg = data.Message;
        if (data.EsMessage) {
          msg = msg + '.' + data.EsMessage;
        }
        dsf.layer.message(msg || "未知流程类型发送", false);
      }
    } else {
      this._RequestID = null;
      let msg = data.Message;
      if (data.EsMessage) {
        msg = msg + '.' + data.EsMessage;
      }
      dsf.layer.message(msg || "发送异常", false);
    }
  },
  directlySend(data, completed) {
    let list = [];
    if (data.Code == 20000) {
      if (data.BatchResults) {
        dsf.layer.message("发送成功");
        this.closeOpener();
        return;
      }
      const Links = data.Links;
      Links.forEach((line, index) => {
        if (line.Status == 0 && line.LinkUser) {
          let linkUser = line.LinkUser;
          let type = line.LinkUser.Type;
          if (type == 1) {
            // 直接选人
            list.push(linkUser.UserName);
          } else if (type == 2) {
            let GlobalDeptName = linkUser.GlobalDeptName.split(",");
            let DeptName = GlobalDeptName.length >= 3 ? GlobalDeptName[2] : linkUser.DeptName;
            // 部门+角色
            list.push(`${DeptName}-${linkUser.RoleName}`);
          } else if (type == 3) {
            // 岗位
            list.push(linkUser.JobName);
          } else if (type == 4) {
            // 人+关系
            list.push(`${linkUser.UserName}-${linkUser.RelationName}`);
          }
        }
      });
      // 流程信息配置为-1  就不显示倒计时
      dsf.layer.openDialog({
        title: "流程已发送到以下人员：",
        width: batchSend.Size.width,
        height: batchSend.Size.height,
        content: "SendFlowDialog",
        showClose: false,
        footerAlign: 'center',
        params: {
          height: 420,
          list: _.uniq(list),
          showCountDown: this.$vm.flows.flowInfo?.countDown == -1 ? false : !this.$vm.$dialog,
          countDownTime: this.$vm.flows.flowInfo?.countDown
        },
        btns: [
          {
            text: "确定",
            class: "sendFlow",
            handler: (component) => {
              this.closeOpener();
              return true;
            },
          },
        ],
      });
    } else {
      let msg = "发送成功",
        code = data.Code;
      switch (code) {
      case 20004:
        msg = completed || "文件已办结";
        break;
      case 20005:
        msg = completed || "文件已办结";
        break;
      }
      dsf.layer.openDialog({
        title: "提示",
        width: "300px",
        height: "120px",
        content: "SendFlowDialog",
        footerAlign: "center",
        params: {
          msg: msg,
          countDownTime: this.$vm.flows.flowInfo?.countDown
        },
        btns: [
          {
            text: "确定",
            class: "sendFlow",
            handler: (component) => {
              this.closeOpener();
              return true;
            },
          },
        ],
      });
    }
  },
  chooseSendNode(data) {
    dsf.layer.openDialog({
      title: "请选择要发送的环节：",
      width: "300px",
      height: "auto",
      overflow: "auto",
      footerAlign: "center",
      content: "ChooseSendNode",
      params: {
        lines: data.SendParams.Lines,
        callback: this.handler.bind(this),
        requestID: this._RequestID,
        type: "next",
        isBatch: true
      },
      btns: [
        // {
        //   text: "确定",
        //   class: "hide choose-send-node",
        //   handler: (component) => {
        //     if (component.validate()) {
        //       const line = component.yes();
        //       let param = {
        //         sRequestID: this._RequestID,
        //         sLineID: line.LineID,
        //       };
        //       this.$http.post("wfr/batchSendToLine", param).then((res) => {
        //         if (res.data.state == 20000) {
        //           this.handler(res);
        //         }
        //       });
        //       return true;
        //     }
        //     return true;
        //   },
        // },
        {
          class: "close-send-node",
          text: "取消",
        },
      ],
    });
  },
  chooseSendTree(data) {
    dsf.layer.openDialog({
      title: "请选择办理人",
      width: "900px",
      height: "420px",
      overflow: "hidden",
      footerAlign: "center",
      content: "ChooseSendTree",
      params: {
        lines: data.SendParams.Lines,
      },
      btns: [
        {
          text: "确定",
          handler: (component) => {
            if (component.validate()) {
              const result = component.yes();
              let param = {
                sRequestID: this._RequestID,
                sReceivers: result,
              };
              const url = (this.$route.query.sPID || this.queryString.sPID) ? "wfr/batchSendToReceivers" : "wfr/batchStartSendToReceivers";
              this.$http.post(url, param).then((res) => {
                if (res.data.state == 20000) {
                  this.handler(res);
                }
              });
              const closebtn = document.querySelector(".close-send-node");
              closebtn && closebtn.click();
              return true;
            }
            return false;
          },
        },
        {
          text: "取消",
        },
      ],
    });
  },
  closeOpener() {
    if (this?.$vm?.$dialog) {
      this.$vm.$dialog?.openerVm?.reloadData?.(true);
      this.$vm.$dialog?.close?.();
    } else if (this?.$vm?.$openerVm) {
      this.$vm.$openerVm?.reloadData?.(true);
      this.$vm?.$closeWindow?.();
    } else if (window?.opener) {
      window?.opener?.reloadData?.(true);
      window.close();
    }
  },
  flowSendOverEvent: null,
  Size: {
    with: '720px',
    height: '420px'
  }
}

const system_batch_flow_send = async function (args) {
  if (args.targetButton.disabled) {
    return;
  }
  args.targetButton.disabled = true;
  let form = args._self.$form;
  if (form) {
    let options = {
      isSilent: true,
      isRefresh: false,
      savedClose: false,
      successMsg: "",
      errorMsg: ""
    }
    const res = await form.formSave(options);
    if (res && res.data) {
      let Objects = [];
      const data = args._self.$vm && args._self.$vm.$dialog && args._self.$vm.$dialog.dialogArgs && args._self.$vm.$dialog.dialogArgs.data || [];
      if (args._self.$vm.$dialog && data.length > 0) {
        Objects = data;
      } else {
        let [tableNames, ObjectIDs, ObjectNames, Size] = args.params;
        tableNames = tableNames?.split?.(',');
        ObjectIDs = ObjectIDs?.split?.(',');
        ObjectNames = ObjectNames?.split?.(',');
        if(Size) {
          batchSend.Size.width = Size?.split(',')?.[0];
          batchSend.Size.height = Size?.split(',')?.[1];
        }
        tableNames?.forEach((table, index) => {
          res.data[table]?.forEach(item => {
            Objects.push({
              ObjectID: item[ObjectIDs[index] || ObjectIDs[0]],
              ObjectName: item[ObjectNames[index] || ObjectNames[0]],
            });
          });
        });
      }
      Object.assign(args._self, batchSend);
      args._self.flowSend(res, args, Objects);
    }
  }
}

export default system_batch_flow_send;