import { render, staticRenderFns } from "./Personalization.vue?vue&type=template&id=adb5cc14&"
import script from "./Personalization.vue?vue&type=script&lang=js&"
export * from "./Personalization.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

component.options.__file = "pc/home/menu/Personalization.vue"
export default component.exports