var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "des-custom-order" }, [
    _c(
      "div",
      { staticClass: "left" },
      [
        _vm._l(_vm.customOrder, function (item, index) {
          return [
            _c(
              "div",
              {
                key: index,
                staticClass: "des-custom-order-item",
                on: {
                  click: function ($event) {
                    return _vm.itemClick(item)
                  },
                },
              },
              [
                _c("span", [_vm._v(_vm._s(item.fieldCaption))]),
                _c("i", { class: ["sort-bar", item.sortType] }),
              ]
            ),
          ]
        }),
      ],
      2
    ),
    _c("div", { staticClass: "right" }, [_vm._t("default")], 2),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }