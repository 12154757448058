/*
 * @Editor: chenqy
 * @Description: 拖拽
 * @Date: 2021-09-10 09:04:02
 * @LastEditors: Chenqy
 * @LastEditTime: 2021-12-23 14:08:50
 */
let isMobile = /Android|webOS|iPhone|iPod|BlackBerry/i.test(
  navigator.userAgent
);
let operationEvent = {
  mousedown: isMobile ? "touchstart" : "mousedown",
  mousemove: isMobile ? "touchmove" : "mousemove",
  mouseup: isMobile ? "touchend" : "mouseup",
};
export function draggable(element, options, root) {
  let isDragging = false;
  let fingers = isMobile ? 0 : 1; // 手指数目
  root = root || document;
  let timer = null;
  const moveFn = function (event) {
    if (isMobile && fingers < event.touches.length) {
      fingers = event.touches.length;
    }
    if (options.drag) {
      let e = isMobile ? event.changedTouches[0] : event;
      e.fingers = fingers;
      options.drag(e);
    }
  };
  const moveFnTh = throttle(moveFn, 50);
  const upFn = function (event) {
    if (timer) {
      clearTimeout(timer);
    }
    root.removeEventListener(operationEvent.mousemove, moveFnTh);
    root.removeEventListener(operationEvent.mouseup, upFn);
    root.onselectstart = null;
    root.ondragstart = null;

    isDragging = false;

    if (options.end) {
      let e = isMobile ? event.changedTouches[0] : event;
      e.fingers = fingers;
      options.end(e);
      if (isMobile) {
        fingers = 0;
      } else {
        fingers = 1;
      }
    }
  };
  const downFn = function (event) {
    if (!options.cancelDefault) {
      event.preventDefault();
    }

    if (isDragging) return;
    
    if (isMobile) {
      fingers += event.touches.length;
    }
    root.onselectstart = function () {
      return false;
    };
    root.ondragstart = function () {
      return false;
    };
    root.addEventListener(operationEvent.mousemove, moveFnTh);
    root.addEventListener(operationEvent.mouseup, upFn);
    isDragging = true;

    if (options.start) {
      let e = isMobile ? event.changedTouches[0] : event;
      e.fingers = fingers;
      options.start(e);
    }
  };
  element.addEventListener(operationEvent.mousedown, downFn);
  // 节流
  function throttle(fn, wait = 60) {
    let args = "";
    let preTime = 0;
    let context = null;
    let throttled = function (event) {
      args = arguments;
      let nowTime = +new Date();
      if (!preTime) {
        preTime = nowTime;
      }
      let longTime = wait - (nowTime - preTime);
      context = this;
      let res = "";
      if (longTime <= 0) {
        preTime = nowTime;
        if (timer) {
          clearTimeout(timer);
          timer = null;
        }
        res = fn.apply(context, args);
      } else {
        if (timer) {
          clearTimeout(timer);
          timer = null;
        }
        // 每次都会执行最后一次
        timer = setTimeout(() => {
          preTime = +new Date();
          timer = null;
          res = fn.apply(context, args);
          context = args = null;
        }, longTime);
      }
      return res;
    };
    return throttled;
  }
  return downFn;
}
