var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ds-meta-data-select-item" },
    [
      _c("dsf-tree-select", {
        attrs: {
          "tree-data": _vm.treeData,
          loading: _vm.loading,
          multiple: _vm.multiple,
          theme: _vm.theme,
          "default-checked-key": _vm.defaultCheckedKey,
          "node-key": _vm.idKey,
          "node-name": _vm.nameKey,
          "search-control": "",
          "default-expand-all": "",
        },
        on: { "choose-node": _vm.chooseNode },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }