<template>
  <div class="ds-control ds-form-item ds-system" :class="getCss">
    <template v-if="simple && !isDesign">{{ setVal }}</template>
    <template v-else>
      <!-- <label v-if="showLabel" class="ds-form-label" :style="getLabelWidthStyle()">{{ label }}</label> -->
      <DsfFieldLabel
        v-if="showLabel"
        :mode="showDataCaptionMode"
        :style="getLabelWidthStyle()"
        :show-icon="showDataCaptionIcon" 
        :trigger="showDataCaptionTrigger"
        :data-caption="dataCapion"
        :is-design="isDesign"
        :owner="_self">
        {{ $t(label) }}
      </DsfFieldLabel>
      <div class="ds-form-block" :style="getFormItemBlockMarginLeft()">
        {{ setVal }}
      </div>
    </template>
  </div>
</template>

<script>
export default dsf.component({
  name: "DsfDeletedStatus",
  mixins: [$mixins.formControl],
  ctrlCaption: "删除状态",
  props: {
    caption: {
      type: String,
      default: dsf.config.kw.deleted,
    },
    label: {
      type: String,
      default: "删除状态",
    },
    simple: {
      type: Boolean,
      default: false,
    },
    showLabel: {
      type: Boolean,
      default: true,
    },
    allowPost:{
      type:Boolean,
      default:false
    },
    metadata: {
      type: Object,
      default() {
        return dsf.metadata.get("system-meta-data", {
          code: dsf.config.kw.deleted,
          valueAttributes: [
            {
              name: "是否删除",
              code: dsf.config.kw.deleted,
              type: dsf.metadata.getDataType("string"),
              length: "2",
              defaultValue: null,
              unit: null,
            },
          ],
        });
      },
    },
  },
  computed: {
    setVal() {
      return Number(this.value) ? "是" : "否";
    },
  },
});
</script>
