<template>
  <div class="ds-personalization" :class="{show: show}">
    <div v-if="show" class="ds-personalization-editor">
      <div class="ds-personalization-editor-title">
        个性化设置
      </div>
      <el-form label-width="120px">
        <el-form-item v-if="showThemes.length" label="主题色：">
          <div class="theme-radio-list">
            <template v-for="key in showThemes">
              <div :key="key" class="theme-radio-item" :style="radioStyle(dsf.themeConfig.themesConfig[key], key)" @click="radioClick(key)"></div>
            </template>
          </div>
        </el-form-item>
        <el-form-item label="字体大小：">
          <slot name="font-size" v-bind="_self">
            <el-slider v-model="fontSizeVal" :step="2" :min="fontSizeMin" :max="fontSizeMax" :marks="fontSizeMarks" :format-tooltip="formatTooltip" show-stops />
          </slot>
        </el-form-item>
        <el-form-item v-if="hasDefaultHeader" label="顶部导航栏：">
          <el-radio-group v-model="themeConfig.defaultHeaderMini">
            <el-radio :label="false">宽条</el-radio>
            <el-radio :label="true">窄条</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item v-if="hasDefaultHeader" label="侧边导航栏：">
          <el-radio-group v-model="themeConfig.defaultAsideMini">
            <el-radio :label="false">宽条</el-radio>
            <el-radio :label="true">窄条</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div class="footer">
        <dsf-button text="确 定" size="small" @click="sure"></dsf-button>
        <dsf-button text="取 消" size="small" type="plain" @click="close"></dsf-button>
      </div>
    </div>
  </div>
</template>

<script>
const fontSizeMap = {
  normal: 14,
  bigFont: 16,
  bigFont_1: 18,
  bigFont_2: 20,
  bigFont_3: 22
};
const fontSizeOptions = {
  14: "normal",
  16: "bigFont",
  18: "bigFont_1",
  20: "bigFont_2",
  22: "bigFont_3"
};
const fontSizeMarks = {
  14: "正常",
  16: "中号",
  18: "大号",
  20: "特大",
  22: "最大"
};

export default {
  name: "DsfPersonalization",
  inject: {
    homeRoot: {
      default: null
    }
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    saveToServer:{
      type:Boolean,
      default:true
    },
    fontSizeOptions: {
      type: Object,
      default() {
        let size = parseInt(dsf.config.setting_public_show_fontsizebase);
        let steps = parseInt(dsf.config.setting_public_show_fontsizestep);
        let count = parseInt(dsf.config.setting_public_show_fontsizestepcount);
        let text = dsf.config.setting_public_show_fontsizemap.split(",");
        let arr = {
          [size]: text[0]
        };
        for (let i = 0; i < count; i++) {
          size = size + steps;
          arr[size] = text[i + 1];
        }
        return arr;
      }
    }
  },
  data() {
    return {
      left: window.innerWidth,
      top: window.innerHeight / 2,
      hasDefaultHeader: false,
      showThemes: [],
      fontSizeMarks: this.fontSizeOptions,
      fontSizeMax: 0,
      fontSizeMin: 0,
      themeConfig: {
        theme: null,
        fontSize: null,
        defaultHeaderMini: null,
        defaultAsideMini: null
      },
      lastTheme: null
    };
  },
  computed: {
    style() {
      return {
        top: this.top + "px",
        left: this.left + "px"
      };
    },
    fontSizeVal: {
      get() {
        return fontSizeMap[this.themeConfig.fontSize];
      },
      set(val) {
        this.$set(this.themeConfig, "fontSize", fontSizeOptions[val]);
      }
    }
  },
  watch: {
    show(to) {
      if (to) {
        this.lastTheme = _.cloneDeep(dsf.themeConfig.setting);
      }
    },
    "themeConfig.theme"(to) {
      dsf.themeConfig.setTheme(to);
      this.$nextTick(() => {
        this.dispatchResize();
      });
    },
    "themeConfig.fontSize"(to) {
      dsf.themeConfig.setFontSize(to);
      this.$nextTick(() => {
        this.dispatchResize();
      });
    },
    "themeConfig.defaultHeaderMini"(to) {
      dsf.themeConfig.setDefaultHeaderMini(to);
      this.$nextTick(() => {
        this.dispatchResize();
      });
    },
    "themeConfig.defaultAsideMini"(to) {
      dsf.themeConfig.setDefaultAsideMini(to);
      this.$nextTick(() => {
        this.dispatchResize();
      });
    }
  },
  created() {
    let showThemes = dsf.config.setting_public_show_themes || null;
    if (showThemes === "all") {
      this.showThemes = Object.keys(dsf.themeConfig.themesConfig);
    } else {
      showThemes = showThemes ? showThemes.split(",") : [];
      this.showThemes = _.filter(showThemes, (st) => !!dsf.themeConfig.themesConfig[st]);
    }
    this.$header = this.homeRoot?.$slots?.header?.[0];
    if (this.$header && this.$header.elm.classList.contains("page-home-default-header")) {
      this.hasDefaultHeader = true;
    }
    this.themeConfig = {
      theme: dsf.themeConfig.setting.theme,
      fontSize: dsf.themeConfig.setting.fontSize,
      defaultHeaderMini: dsf.themeConfig.setting.defaultHeaderMini,
      defaultAsideMini: dsf.themeConfig.setting.defaultAsideMini
    };
    let arr = [];
    for (let k in this.fontSizeOptions) {
      arr.push(parseInt(k));
    }
    arr = arr.sort((v, v1) => {
      if (v < v1) {
        return -1;
      } else {
        return 1;
      }
    });
    this.fontSizeMin = arr[0];
    this.fontSizeMax = arr[arr.length - 1];
  },
  mounted() {
    document.body.appendChild(this.$el);
  },
  methods: {
    radioStyle({ main, normal }, key) {
      let isActive = key == this.themeConfig.theme;
      return {
        "border-color": isActive ? normal : "#ccc",
        "background-image": `linear-gradient(45deg, ${main} 30%, ${normal} 70%)`
      };
    },
    radioClick(key) {
      this.$set(this.themeConfig, "theme", key);
    },
    sure() {
      this.$emit("update:show", false);
      if(this.saveToServer){
        dsf.custom.addSystem("themeConfig", this.themeConfig);
      }
      this.$nextTick(() => {
        this.dispatchResize();
      });
    },
    close() {
      this.$emit("update:show", false);
      this.$nextTick(() => {
        this.themeConfig = this.lastTheme;
      });
    },
    dispatchResize() {
      let evt = window.document.createEvent("HTMLEvents");
      evt.initEvent("resize", false, false);
      window.dispatchEvent(evt);
    },
    formatTooltip(val) {
      return fontSizeMarks[val];
    }
  }
};
</script>