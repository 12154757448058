<template>
  <DsfHomePanel
    :title="title"
    :titleIcon="titleIcon"
    :moreIcon="moreConfig.url ? 'gengduo' : ''"
    :moreConfig="moreConfig"
    :padding="padding"
    :margin="margin"
    class="dsf-management-box-notice"
    :styleType="styleType"
    :height="heightPanel"
    :width="widthPanel"
    :showHead="showHead"
  >
    <div class="dsf-home-announcemen-mail" :style="panelStyle">
      <DsfVirtualScroll>
        <DsfVirtualList
          ref="vlist"
          :isDesign="isDesign"
          :url="url"
          v-bind="{
            rowPadding,
            rowMargin,
          }"
          @list-change="listChangeFn"
          @row-click="rowClick"
        >
          <template v-if="isDesign" v-slot:list-row>
            <div
              v-for="index in 3"
              :key="index"
              class="dsf-home-list-col2-row"
              :style="{ lineHeight }"
            >
              <div
                class="dsf-home-list-col2-item ellipsis"
                title="标题"
                type="name"
              >
                周工作汇报
              </div>
              <div class="dsf-home-list-col2-item" type="time">2020-04-12</div>
            </div>
          </template>
          <template v-else v-slot:list-row="scope">
            <div
              class="dsf-home-list-col2-row"
              :read="scope.row.status != 0"
              :style="{ lineHeight }"
            >
              <img
                v-if="scope.row.isTop == 1"
                class="dsf-home-list-col2-item"
                type="top"
                :src="
                  require('_platform/assets/styles/themes/img/home/top.png')
                "
              />
              <div
                class="dsf-home-list-col2-item ellipsis"
                :title="scope.row.title || ''"
                type="name"
              >
                {{ scope.row.title || "" }}
              </div>
              <div class="dsf-home-list-col2-item" type="time">
                {{ scope.row.sendDateTime | time }}
              </div>
            </div>
          </template>
        </DsfVirtualList>
      </DsfVirtualScroll>
    </div>
  </DsfHomePanel>
</template>
<script>
export default dsf.component({
  name: "DsfInternalMail",
  ctrlCaption: "内部邮件",
  mixins: [$mixins.control],
  components: {},
  filters: {
    time(timeStr) {
      return dsf.date.format(timeStr, "mm-dd");
    },
  },
  props: {
    showHead: {
      default: true,
    },
    // 宽度
    width: {
      type: String,
      default: "100%",
    },
    // 高度
    height: {
      type: String,
      default: "100%",
    },
    // 面板宽度
    widthPanel: {
      type: String,
      default: "100%",
    },
    // 面板高度
    heightPanel: {
      type: String,
      default: "100%",
    },
    title: {
      type: String,
      default: "内部邮件",
    },
    titleIcon: {
      type: String,
      default: "",
    },
    // 行高
    lineHeight: {
      type: String,
      default: "45px",
    },
    // 边框
    margin: {
      type: Array,
      default() {
        return [10, 10, 10, 10];
      },
    },
    // 边框
    padding: {
      type: Array,
      default() {
        return [10, 0, 20, 0];
      },
    },
    // 边框
    rowPadding: {
      type: Array,
      default() {
        return [0, 20, 0, 20];
      },
    },
    // 边框
    rowMargin: {
      type: Array,
      default() {
        return [0, 0, 0, 0];
      },
    },
    // 列表数据
    list: {
      type: Array,
      default: () => [],
    },
    dataUrl: {
      type: String,
      default: "/email/receive/queryUserReceiveList?pageNum=1",
    },
    pageSize: {
      type: Number || String,
      default: 100,
    },
    toConfig: {
      type: Object,
      default: () => ({}),
    },
    moreConfig: {
      type: Object,
      default: () => ({}),
    },
    styleType: {
      type: String,
      default: "one",
    },
    viewedUrl: {
      type: String,
      default: "/message/info/markRead",
    },
  },

  computed: {
    controlStyle() {
      let { widthPanel, padding, height } = this;
      return { width: widthPanel, padding: padding.join("px ") + "px", height };
    },
    panelStyle() {
      let { height } = this;
      return { height };
    },
  },

  data() {
    return {
      url: "",
    };
  },

  watch: {},

  created() {
    if (!this.isDesign) {
      this.url = this.dataUrl + "&pageSize=" + this.pageSize;
    } else {
      this.$dispatch("design-height-change", "100%");
    }
  },

  methods: {
    reloadData() {
      this.$refs.vlist.reloadData();
    },
    listChangeFn(list) {
      this.$emit("list-change", list);
    },
    async rowClick({ args }) {
      let { toConfig } = this;
      let url = args.row.url;
      let title = args.row.title;
      await this.redRow(args.row._id);
      this.$open({ ...toConfig, url, title });
    },
    redRow(id) {
      return this.$http.post(this.viewedUrl, {
        id,
        type: "workrecord",
      });
    },
  },
});
</script>
