<template>
  <el-table
    v-if="isDesign"
    ref="table"
    :class="{disabledCheckHead: isSingleChecked}"
    :border="true"
    :fit="true"
    :data="data"
    :height="height"
    :show-summary="showSummary"
    :summary-method="$getSummaries"
    :header-cell-class-name="headerCellClassName"
    stripe>
    <el-table-column v-if="checkboxColumn.show" type="selection" label="" :width="checkboxColumn.width" header-align="center" align="center"></el-table-column>
    <el-table-column v-if="indexColumn.show" :type="'index'" :index="$list.indexMethod" :label="'序号'" :resizable="false" :width="indexColumnWidth" :header-align="'center'" :align="'center'"></el-table-column>
    <slot>
      <template v-if="!isReDrawHander">
        <DsfDataGridColumn v-for="(col, $index) in columns" header-cell-class-name="" ref="header" :key="$index" :column="col">
          <template v-for="column in getLastColumns(col)" v-slot:[column.columnId]="scope">
            <slot :name="column.columnId" v-bind="scope"></slot>
          </template>
        </DsfDataGridColumn>
      </template>
    </slot>
    <el-table-column v-if="operateColumn.show" label="操作" :width="operateColumn.width" header-align="center" align="center">
      <template v-slot:default="scope">
        <slot name="row-buttons" v-bind="scope"></slot>
        <!-- <DsfButtonBar size="small" :max-number="rowButtonsMaxNumber" :more-button-text="rowButtonsMoreText" :more-button-style="rowButtonsMoreStyle" class="ds-no-padding ds-no-drop" :is-design="isDesign" :buttons="scope._self.rowButtons" align="center"></DsfButtonBar> -->
      </template>
    </el-table-column>
  </el-table>
  <el-table
    v-else
    ref="table"
    :class="{disabledCheckHead: isSingleChecked}"
    :border="true"
    :fit="true"
    :data="data"
    :height="height"
    :row-key="rowKey"
    :header-cell-class-name="headerCellClassName"
    :span-method="spanMethod"
    stripe
    :show-summary="showSummary"
    :summary-method="$getSummaries"
    :row-class-name="getRowClassName"
    v-on="tableEvent">
    <template v-if="checkboxColumn.show">
      <el-table-column :selectable="selectable" :reserve-selection="true" :width="checkboxColumn.width" type="selection" header-align="center" align="center" fixed />
    </template>
    <el-table-column
      v-if="indexColumn.show"
      type="index"
      label="序号"
      :width="indexColumnWidth"
      :resizable="false"
      header-align="center"
      :index="$list.indexMethod"
      align="center"
      fixed>
      <template v-slot:default="scope">
        <div :title="$list.indexMethod(scope.$index)">{{$list.indexMethod(scope.$index)}}</div>
      </template>
    </el-table-column>
    <slot>
      <template v-if="!updateHeader">
        <template v-for="(col) in realFilterColumns">
          <!--<DsfDataGridColumn v-if="getColumnRenderResult(col,true)!==false" ref="header" :key="col.id" :column="col">-->
          <DsfDataGridColumn ref="header" :key="col.id" :column="col">
            <template v-for="(column) in getLastColumns(col)" v-slot:[column.columnId]="scope">
              <slot :name="column.columnId" v-bind="scope"></slot>
            </template>
          </DsfDataGridColumn>
        </template>
      </template>
    </slot>
    <el-table-column v-if="operateColumn.show" label="操作" :width="operateColumnWidth" header-align="center" align="center" :fixed="operateColumnFixed">
      <template v-slot:default="scope">
        <slot name="row-buttons" v-bind="scope"></slot>
      </template>
    </el-table-column>
    <template v-slot:empty>
      <slot>
        <dsf-empty-data :text="emitText"></dsf-empty-data>
      </slot>
    </template>
  </el-table>
</template>

<script>
function columnsFilter(filterResult, cols) {
  let arr = [];
  _.forEach(filterResult, (f) => {
    let c = _.find(cols, (c) => c.id === f.id);
    if (!c) return;
    let cwss = this.customerColumnWidths || {};
    let width = dsf.isDef(cwss[c.id]) ? cwss[c.id] : f.width;
    if (!f.children) {
      arr.push(Object.assign({}, c, { width }));
    } else {
      let children = columnsFilter.call(this, f.children, c.children);
      arr.push(Object.assign({}, c, { width, children }));
    }
  });
  return Vue.observable(arr);
}

function getRealFilterColumns(columns, fun) {
  let arr = [];
  _.forEach(columns, (col) => {
    if (fun(col, true) === false) {
      return;
    }
    if (col.isLast) {
      let obj = _.cloneDeep(col);
      let cwss = this.customerColumnWidths || {};
      let width = dsf.isDef(cwss[obj.id]) ? cwss[obj.id] : obj.width;
      delete obj.children;
      obj.width = width;
      arr.push(obj);
    } else if (col.children.length) {
      let children = getRealFilterColumns.call(this, col.children, fun, col.children);
      if (children.length) {
        let obj = { children };
        for (let k in col) {
          if (k !== "children") {
            obj[k] = col[k];
          }
        }
        // 对于锁定的列，计算其宽度为子列宽度之和
        if (obj.fixed) {
          let width = 0;
          dsf.deepForEach(
            obj,
            (ch) => {
              let cwss = this.customerColumnWidths || {};
              let width = dsf.isDef(cwss[ch.id]) ? cwss[ch.id] : ch.width;
              width += parseInt(width);
            },
            {
              isLast: true
            }
          );
          obj.width = width;
        }
        arr.push(obj);
      }
    }
  });
  return Vue.observable(arr);
}

export default dsf.component({
  name: "DsfDataGridTable",
  ctrlCaption: "数据列表表格",
  mixins: [$mixins.layout],
  inject: {
    $list: {
      default: null
    }
  },
  props: {
    selectable: {
      type: Function,
      required: false
    },
    columns: {
      type: Array,
      default() {
        return [];
      }
    },
    data: {
      type: Array,
      default() {
        return [];
      }
    },
    isReDrawHander: {
      type: Boolean,
      default: false
    },
    contextMenuButtons: {
      type: Array,
      default() {
        return [];
      }
    },
    renderRowButton: {
      type: Function,
      default: null
    },
    disabledRowButton: {
      type: Function,
      default: null
    },
    rowCursorPointer: {
      type: Boolean,
      default: false
    },
    rowClassName: {
      type: [String, Function],
      default: ""
    },
    height: {
      type: String,
      default() {
        return "100%";
      }
    },
    stripe: {
      type: Boolean,
      default: true
    },
    slots: {
      type: Array,
      default() {
        return [
          {
            name: "default",
            controls: []
          }
        ];
      }
    },
    checkboxColumn: {
      type: Object,
      default() {
        return {};
      }
    },
    indexColumn: {
      type: Object,
      default() {
        return {};
      }
    },
    operateColumn: {
      type: Object,
      default() {
        return {};
      }
    },
    border: {
      type: Boolean,
      default: true
    },
    fit: {
      type: Boolean,
      default: true
    },
    isSingleChecked: {
      type: Boolean,
      default: false
    },
    rowKey: {
      type: [String, Function],
      default: ""
    },
    headerCellClassName: {
      type: [String, Function],
      default: ""
    },
    // 合并行或列的计算方法。
    // Function({ row, column, rowIndex, columnIndex })
    spanMethod: {
      type: Function,
      required: false
    },
    customerOrder: {
      type: Array,
      default() {
        return [];
      }
    },
    // 列的隐藏
    renderHeader: {
      type: Function,
      default: dsf.noop
    },
    filterResult: {
      type: Array,
      default() {
        return [];
      }
    },
    // // 空状态类型: text, img
    // emitType: {
    //   type: String,
    //   default: "img"
    // },
    // 空状态文本
    emitText: {
      type: String,
      default: "暂无数据"
    },
    showSummary: {
      type: Boolean,
      default: false
    },
    getSummaries: {
      type: Function,
      default: null
    },
    customerColumnWidths: {
      type: Object,
      default() {
        return null;
      }
    }
  },
  data() {
    return {
      columnsBak: null,
      updateHeader: false,
      tableWidth: 0,
      tableClientWidth: 0,
      elTable: null,
      multipleSelectionCopy: null,
      operateColumnWidth: this.operateColumn.width,
      operateColumnFixed: false,
      indexWidthCoefficient: 1
    };
  },
  computed: {
    //不建议在此处调用getRealFilterColumns,这个属性在设计器里有使用
    lastColumns() {
      return this.getLastColumns();
    },
    //列表真实显示的列
    realColumns() {
      let columns = this.columns;
      return getRealFilterColumns.call(this, columns, this.getColumnRenderResult);
    },
    //用户通过列表列过滤后的结果
    filterColumns() {
      let cols = this.columns;
      let filterResult = this.filterResult;
      if (this.filterResult.length <= 0) {
        return cols;
      } else {
        return columnsFilter.call(this, filterResult, cols);
      }
    },
    //列表真实显示的列(带父子层级关系)
    realFilterColumns() {
      return getRealFilterColumns.call(this, this.filterColumns, this.getColumnRenderResult);
    },
    //列表真实显示的列(不带父子层级关系)
    realFilterLastColumns() {
      let columns = getRealFilterColumns.call(this, this.filterColumns, this.getColumnRenderResult);
      columns = this.getLastColumns(columns);
      return columns;
    },
    //获取列树形结构,仅排除掉隐藏列和二次开发的隐藏列
    columnsTree() {
      return getRealFilterColumns.call(this, this.columns, this.getColumnRenderResult);
    },
    multipleSelection: {
      get() {
        if (this.$list) {
          return this.$list.multipleSelection;
        } else {
          return this.multipleSelectionCopy;
        }
      },
      set(val) {
        if (this.$list) {
          this.$list.multipleSelection = val;
        } else {
          this.multipleSelectionCopy = val;
        }
      }
    },
    isTreeColumns() {
      let mark = false;
      let cols = this.realFilterColumns;
      for (let i = 0; i < cols.length; i++) {
        if (!cols[i].isLast) {
          mark = true;
          break;
        }
      }
      return mark;
    },
    indexColumnWidth() {
      return this.indexColumn.width * this.indexWidthCoefficient;
    },
    realRowButtons() {
      return dsf.mix(true, [], this.contextMenuButtons || []);
    },
    tableEvent() {
      return {
        select: this.handleSelection,
        "select-all": this.handleSelectionAll,
        "header-dragend": this.headerDragend,
        "row-dblclick": this.rowDblClickHandler,
        "sort-change": this.tableSort,
        "selection-change": this.handleSelectionChange,
        "row-contextmenu": this.rowContextmenu
      };
    }
  },
  created() {
    _.each(this.lastColumns, (it) => {
      it.designWidth = it.width || "auto";
    });
    if (dsf.isFunction(this.getSummaries)) {
      this.getSummariesProxy = _.debounce((param) => {
        return this.getSummaries(param);
      }, 100);
    }
  },
  mounted() {
    this.elTable = this.$refs.table;
    if (!this.$list.isDesign) {
      this.columnsBak = dsf.mix(true, [], this.columns);
    }
    // 设置空状态图片
    // if (!this.isDesign && this.emitType === "img") {
    //   this.setEmptyData();
    // }
  },
  updated() {
    this.doLayout();
  },
  beforeDestroy() {
    this._unwatch?.();
  },
  methods: {
    $getSummaries(param) {
      if (this.isDesign) {
        let sums = [];
        for (let i = 0; i < 100; i++) {
          if (i == 0) {
            sums.push("小计");
          } else {
            sums.push("--");
          }
        }
        return sums;
      }
      if (this?.$list?.isTriggerQuery && this.showSummary && param.columns.length > 0) {
        return this.$list.summaries;
      } else {
        return [];
      }
    },
    updateSummaries() {
      if (this.elTable) {
        if (!this.isDesign) {
          let child = this.elTable.$children;
          let footers = _.filter(child || [], (it) => it.$options.name == "ElTableFooter");
          if (footers && footers.length) {
            _.each(footers, (f) => {
              this.overrideTableFooterRender(f);
              f.$forceUpdate();
            });
          }
        }
        this.$nextTick(() => {
          this?.$list?.resize?.();
        });
      }
    },
    getRowClassName(scope) {
      if (!this.rowClassName) {
        if (this.rowCursorPointer) {
          return "ds-cursor-pointer";
        }
        return "";
      } else {
        if (dsf.isFunction(this.rowClassName)) {
          return this.rowClassName(scope);
        } else if (dsf.isString(this.rowClassName)) {
          return this.rowClassName;
        }
      }
    },
    // setEmptyData() {
    //   let bodyBlock = this.$el.querySelector(".el-table__body-wrapper");
    //   let emptyDiv = document.createElement("div");
    //   emptyDiv.classList.add("ds-empty-data");
    //   emptyDiv.setAttribute("text", this.emitText);
    //   bodyBlock.appendChild(emptyDiv);
    //   this._unwatch = this.$watch(
    //     "data.length",
    //     function (to) {
    //       if (!to) {
    //         let emptyBlock = this.$el.querySelector(".el-table__empty-block");
    //         emptyBlock.style.display = "none";
    //         emptyDiv.style.display = "flex";
    //       } else {
    //         emptyDiv.style.display = "none";
    //       }
    //     },
    //     {
    //       immediate: true
    //     }
    //   );
    // },
    isLastColumn(col) {
      return col.columnId == this.lastColumns[this.lastColumns.length - 1];
    },
    getColumnRenderResult(col) {
      let result = true;
      if (!col.hide) {
        result = this.renderHeader(col) !== false;
      } else {
        result = false;
      }
      return result;
    },
    //获取列头中各个单元格最大的单元格
    getLastColumns(column) {
      let arr = [];
      this.deep((c) => {
        if (!c.children || !c.children.length) {
          c.isLast = true;
          arr.push(c);
        } else {
          c.columnId = "";
          c.isLast = false;
        }
      }, column);
      return arr;
    },
    deep(callback, column) {
      var fn = function (columns, level) {
        let columnIndex = 0;
        _.each(columns, (c, i) => {
          callback && callback(c, i, columnIndex, level);
          fn(c.children, level + 1);
          columnIndex++;
        });
      };
      let cols = column ? column : this.columns;
      cols = dsf.type(cols) !== "array" ? [cols] : cols;
      fn(cols, 0);
    },
    doLayout() {
      this.elTable = this.$refs.table;
      this.elTable?.doLayout();
    },
    resize() {
      this.doLayout();
      if (!this.$list.isDesign) {
        this.$nextTick(() => {
          //判断如果有操作列，且数据列宽度综合小于表格总宽度则修改操作列的宽度填充，避免出现空白
          if (this.elTable) {
            this.resetTableWidth();
            let sumWidth = 0;
            let cols = _.map(this.lastColumns, "columnId");
            let tableEl = this.elTable.$el.querySelector(".el-table__header-wrapper>table");
            let thead = tableEl.querySelector("thead");
            _.each(this.elTable.columns, (it, i) => {
              if (cols.indexOf(it.columnKey) >= 0) {
                let width = it.realWidth || it.width;
                if (dsf.isNumber(width)) {
                  sumWidth += width;
                } else {
                  let colEl = thead.querySelector("." + it.id);
                  if (colEl) {
                    sumWidth += colEl.offsetWidth;
                  }
                }
              }
            });
            if (sumWidth > 0) {
              sumWidth += this.getIndexWidth();
              sumWidth += this.getCheckColumnWidth();
              if (this.operateColumn.show) {
                sumWidth += parseFloat(this.operateColumn.width || 100);
              }
              if (this.tableClientWidth > sumWidth) {
                this.operateColumnFixed = false;
              } else {
                this.operateColumnFixed = "right";
              }
            }
            //处理固定列宽度
            if (this.elTable.fixedColumns && this.elTable.fixedColumns.length > 0) {
              _.each(this.elTable.fixedColumns, (it, i) => {
                if (it.children && it.children.length > 0) {
                  let leaf_arr = _.filter(this.elTable.columns, (col) => col.id.indexOf(it.id + "_") == 0);
                  let s = 0;
                  _.each(leaf_arr, (it) => {
                    if (dsf.isNumber(it.realWidth || it.width)) {
                      s += it.realWidth || it.width;
                    }
                  });
                  it.realWidth = s;
                }
              });
            }
            this.$nextTick(() => {
              this.doLayout();
            });
          }
        });
      }
    },
    // elTable 多选监听(单个选项被勾选)
    handleSelection(val, row) {
      if (this.isSingleChecked) {
        let isChecked = !!_.find(val, (it) => it._id === row._id);
        let multipleSelection = isChecked ? [row] : [];
        this.multipleSelection = multipleSelection;
        this.$emit("select", multipleSelection, row);
      } else {
        this.$emit("select", val, row);
      }
    },
    // elTable 多选监听(全选)
    handleSelectionAll(val) {
      if (this.isSingleChecked) {
        if (!this.multipleSelection && this.data.length) {
          val = [this.data[0]];
          this.toggleRowSelection(this.data[0], true);
        } else {
          val = this.multipleSelection ? [this.multipleSelection] : [];
        }
      }
      this.$emit("select-all", val);
    },
    headerDragend(newWidth, oldWidth, column, event) {
      let col = _.find(this.lastColumns, (it) => {
        return it.columnId == column.columnKey;
      });
      let colsMap = _.keyBy(this.realFilterColumns, "columnId");
      // newWidth = newWidth > 60 ? newWidth : 60;
      if (dsf.isUnDef(col.designWidth)) {
        col.designWidth = "auto";
      }
      if (col.designWidth.endsWith("%")) {
        colsMap[column.columnKey].width = this.pxTopercentage(newWidth, this.tableClientWidth);
      } else {
        if (colsMap[column.columnKey]) {
          colsMap[column.columnKey].width = newWidth;
        }
      }
      this.$nextTick(() => {
        this.$emit("table-column-resize", this.realFilterColumns);
        this.resize();
      });
    },
    rowDblClickHandler(row, column, event) {
      // this.$emit("row-dblclick", row, column, event);
      let tr = event.currentTarget;
      let buttonbarEl = null;
      if (this.operateColumn.show) {
        buttonbarEl = $(tr).find("div[ctrl_type='dsf.buttonbar']");
      } else {
        buttonbarEl = $(this.$list.$el).find("div[ctrl_type='dsf.buttonbar'][row-hash='" + row.$hash + "']");
      }
      if (buttonbarEl && buttonbarEl.length > 0) {
        let buttonbar = buttonbarEl.get(0).__vue__;
        let dblClickButtons = _.filter(buttonbar.buttons, (btn) => {
          return btn.isDblClick;
        });
        if (dblClickButtons.length > 0) {
          buttonbar.triggerButtonClick(dblClickButtons[0]);
        }
      }
      this.$dispatch("row-dblclick", row);
    },
    tableSort(args) {
      this.$emit("sort-change", args);
    },
    clearSelection() {
      this.elTable?.clearSelection();
    },
    toggleRowSelection(row, selected) {
      this.elTable?.toggleRowSelection(row, selected);
      this.$nextTick(() => {
        this.handleSelection(this.elTable?.selection, row);
      });
    },
    toggleAllSelection() {
      this.elTable?.toggleAllSelection();
    },
    getColumnSort(column) {
      if (this.customerOrder) {
        let result = _.filter(this.customerOrder, (col) => {
          if (column.columnId == col.column) {
            return true;
          }
        });
        if (result.length > 0) {
          return "custom";
        }
      }
      return false;
    },
    handleSelectionChange(val) {
      if (this.isSingleChecked) {
        if (val.length > 1) {
          this.clearSelection();
        }
        if (this.multipleSelection) {
          this.toggleRowSelection(this.multipleSelection, true);
        }
      } else {
        this.multipleSelection = val;
      }
    },
    // 行右键
    rowContextmenu(row, column, event) {
      this.$emit("on-contextmenu", { row, event, $index: row.$index });
    },
    //获取索引列宽度
    getIndexWidth() {
      if (this.indexColumn.show) {
        if (this.elTable) {
          let indexColumnWidth = null,
            index = 1;
          if (!this.checkboxColumn.show) {
            index = 0;
          }
          indexColumnWidth = this.elTable.columns[index];
          return indexColumnWidth.width;
        } else {
          return this.indexColumnWidth;
        }
      } else {
        return 0;
      }
    },
    //获取选择列宽度
    getCheckColumnWidth() {
      if (this.checkboxColumn.show) {
        if (this.elTable) {
          let checkboxColumn = this.elTable.columns[0];
          return checkboxColumn.width;
        } else {
          return this.checkboxColumn.width;
        }
      } else {
        return 0;
      }
    },
    headerReset() {
      this.$list.$emit("update:columns", this.columnsBak);
      this.headerReload();
    },
    headerReload() {
      this.updateHeader = !this.updateHeader;
      this.$nextTick(() => {
        this.updateHeader = !this.updateHeader;
        this.resize();
      });
    },
    getContentWidth(total) {
      let indexColumnWidth = this.getIndexWidth();
      let checkColumnWidth = this.getCheckColumnWidth();
      let operateColumnWidth = this.operateColumn.show ? this.operateColumn.width : 0;
      let tw = total - indexColumnWidth - checkColumnWidth - operateColumnWidth;
      return tw;
    },
    percentageToPx(width, total, isMark) {
      let realWidth = width;
      if (dsf.type(realWidth) == "string" && realWidth && realWidth.endsWith("%")) {
        let w = parseFloat(realWidth.replace("%", "")) / 100;
        let tw = isMark ? this.getContentWidth(total) : total;
        realWidth = parseFloat(tw) * w;
      }
      return realWidth;
    },
    pxTopercentage(width, total) {
      return (width / total) * 100 + "%";
    },
    isPercentage(c) {
      let col = _.find(this.lastColumns, (it) => {
        return it.columnId == c.columnKey;
      });
      if (col) {
        //如果是百分比宽度
        if (col.designWidth.endsWith("%")) {
          return true;
        }
      }
      return false;
    },
    resetTableWidth() {
      let wrap = this.elTable.$el.querySelector(".el-table__body-wrapper");
      this.tableWidth = wrap.offsetWidth;
      this.tableClientWidth = wrap.clientWidth;
    },
    hasClick(evt, row) {
      row.$allowClick = evt.args;
    },
    //重写el-tablefooter组件，因为原功能不支持内容自定义，所以重写支持html
    overrideTableFooterRender(footer) {
      if (footer && !footer.$$overrideRender) {
        footer.$$overrideRender = true;
        let footerOldRender = footer._render;
        let footerDestroyed = footer.$destroy;
        footer.$destroy = function () {
          footerDestroyed && footerDestroyed.call(footer);
          //销毁掉footerOldRender和footerDestroyed函数的引用
          footerOldRender = footerDestroyed = null;
        };
        footer._render = function () {
          let vnodes = footerOldRender.call(footer);
          let tbody = vnodes.children[1];
          let tr = tbody.children[0];
          let cells = tr.children;
          _.each(cells, (it) => {
            let cell_div = it?.children?.[0];
            if (cell_div && cell_div?.children?.length) {
              cell_div.data.domProps = {
                innerHTML: cell_div.children[0].text
              };
              delete cell_div.children;
            }
          });
          return vnodes;
        };
      }
    }
  },
  watch: {
    "$system.setting.fontSize": {
      handler(v, ov) {
        let x = ["bigFont_1", "bigFont_2", "bigFont_3"];
        if (x.indexOf(v) >= 0) {
          this.indexWidthCoefficient = 80 / 60;
          // this.indexColumn.width = 80;
        } else {
          this.indexWidthCoefficient = 1;
          // this.indexColumn.width = 60;
        }
      },
      immediate: true
    },
    "elTable.bodyWidth": {
      handler(v, ov) {
        if (this.elTable) {
          this.resetTableWidth();
        }
      },
      immediate: true
    },
    filterColumns: {
      handler() {
        this.resize();
      },
      deep: true
    },
    showSummary: {
      handler(v, ov) {
        if (v && this.elTable) {
          this.$nextTick(() => {
            this.$list.updateSummaries();
          });
        }
      }
    }
  }
});
</script>