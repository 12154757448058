<template>
  <div class="page-home-dw-header">
    <div class="site-info">
      <a class="site-info-name" :href="indexUrl">
        <img :src="logoUrl"  alt=""/>
      </a>
      <!--      <svg class="site-info-name" width="200" height="86">-->
      <!--        <defs>-->
      <!--          <linearGradient id="dw-site-nme" x1="0" y1="0" x2="100%" y2="0">-->
      <!--            <stop offset="0%" stop-color="#FFE1A6"></stop>-->
      <!--            <stop offset="100%" stop-color="#E0B053"></stop>-->
      <!--          </linearGradient>-->
      <!--        </defs>-->
      <!--        <a style="text-decoration:none;" :href="indexUrl">-->
      <!--          <text x="0" y="55" fill="url(#dw-site-nme)">上海智慧组工</text>-->
      <!--        </a>-->
      <!--      </svg>-->
    </div>
    <div class="page-home-dw-header-box">
      <div class="page-home-dw-header-menu">
        <template v-for="menu in menuList">
          <div :key="menu.menu_id" class="page-home-dw-header-menu-item" :class="{active: menu.menu_id === activeKey}">
            <span @click="menuClick(menu)">{{ menu.menu_name }}</span>
          </div>
        </template>
      </div>
      <el-form v-show="showSearch" @submit.native.prevent>
        <div class="page-home-dw-header-search" :class="{focus: searchFocus}">
          <div class="page-home-dw-header-search-input">
            <input v-model.trim="searchText" :placeholder="searchPlaceholder" type="text" @focus="searchFocus = true" @blur="searchFocus = false" />
            <i class="iconfont icon-sousuo" @click="search"></i>
          </div>
          <div class="page-home-dw-header-search-bt" @click="$emit('super-search')">高级查询</div>
        </div>
        <button style="display: none" @click="search"></button>
      </el-form>
    </div>
    <div v-show="showLogout" class="page-home-dw-header-logout">
      <span class="logout-bt" @click="$emit('logout')">
        <i class="iconfont icon-weitongguo"></i>
        关闭
      </span>
    </div>
  </div>
</template>

<script>
import menuUtils from "_platform/pc/home/menu/utils";

export default {
  name: "DsfGwHomeHeader",
  inject: ["homeRoot"],
  props: {
    logoUrl: {
      type: String,
      default: ""
    },
    indexUrl: {
      type: String,
      default: ""
    },
    searchPlaceholder: {
      type: String,
      default: "请输入搜索内容"
    },
    showSearch: {
      type: Boolean,
      default: true
    },
    showLogout: {
      type: Boolean,
      default: true
    },
    // 菜单点击事件
    itemClick: {
      type: Function,
      required: false
    }
  },
  data() {
    return {
      active: -1,
      searchText: '',
      searchFocus: false
    };
  },
  computed: {
    menuList() {
      return this.homeRoot.$menuList || [];
    },
    activeKey() {
      if (this.homeRoot.menuKey.length > 0) {
        return this.homeRoot.menuKey[0];
      }
      return "";
    }
  },
  watch: {
    '$route.query.keyword':{
      handler(v) {
        this.searchText = v;
      },
      immediate:true
    },
    searchText: {
      handler(v) {
        this.$root.searchText = v;
      },
      immediate:true
    }
  },
  created() {
    this.homeRoot.setPosition("top", "86px");
  },
  mounted() {
    this.scrollBox = document.querySelector(".ds-page-home-view");
    this.searchText = this.$route.query['keyword']||""
  },
  methods: {
    async menuClick(menu) {
      if (this.itemClick) {
        let res = await this.itemClick(menu);
        if (res === false) return;
      }
      menuUtils.openPage.call(this.homeRoot, menuUtils.findMenu(menu));
      if (menu.menu_id !== this.activeKey) {
        this.scrollBox.scroll({top: 0});
      } else {
        this.scrollBox.scroll({
          top: 0,
          behavior: 'smooth'
        });
      }
    },
    search() {
      let text = this.searchText;
      this.$emit("search", text);
      // else {
      //   dsf.layer.message("请输入搜索内容");
      // }
    }
  }
};
</script>