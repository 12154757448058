var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "ds-control ds-form-item ds-upload",
      class: _vm.getCss,
      style: { padding: _vm.hideBtns ? "0" : "" },
    },
    [
      _vm.simple && _vm.readOnly && !_vm.isDesign && !_vm.textProxy
        ? [
            _vm.multipleFiles && _vm.multipleFiles.length > 0
              ? _c(
                  "div",
                  { staticClass: "simple-table-file" },
                  [
                    _vm._l(_vm.multipleFiles, function (data, index) {
                      return _c(
                        "p",
                        {
                          key: data.id,
                          class: { "hide-more": index > _vm.defaultRowsNumber },
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "file-name",
                              attrs: { title: data.originalFileName },
                              on: {
                                click: function ($event) {
                                  return _vm.handleColumnClick(
                                    "view",
                                    data,
                                    index
                                  )
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.substringFileName(data)))]
                          ),
                          _c("i", {
                            staticClass: "operation-icon el-icon-download",
                            on: {
                              click: function ($event) {
                                return _vm.handleColumnClick(
                                  "download",
                                  data,
                                  index
                                )
                              },
                            },
                          }),
                        ]
                      )
                    }),
                    _c(
                      "span",
                      {
                        staticClass: "more",
                        on: {
                          click: function ($event) {
                            return _vm.extendHandler(_vm.multipleFiles)
                          },
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.multipleFiles.length > 3 && !_vm.isShowMore
                              ? "展开更多"
                              : _vm.isShowMore
                              ? "收起更多"
                              : ""
                          )
                        ),
                      ]
                    ),
                  ],
                  2
                )
              : _vm._e(),
          ]
        : [
            _vm.showLabel && !_vm.hideBtns
              ? _c(
                  "DsfFieldLabel",
                  {
                    style: _vm.getLabelWidthStyle(),
                    attrs: {
                      mode: _vm.showDataCaptionMode,
                      "show-icon": _vm.showDataCaptionIcon,
                      trigger: _vm.showDataCaptionTrigger,
                      "data-caption": _vm.dataCapion,
                      "is-design": _vm.isDesign,
                      owner: _vm._self,
                    },
                  },
                  [_vm._v(" " + _vm._s(_vm.$t(_vm.label)) + " ")]
                )
              : _vm._e(),
            _c(
              "div",
              {
                staticClass: "ds-form-block",
                class: { "no-ds-form-label": _vm.hideBtns ? true : false },
                style: _vm.getFormItemBlockMarginLeft(),
              },
              [
                _c(
                  "el-upload",
                  {
                    ref: "upload",
                    class: [
                      "upload-demo",
                      {
                        "upload-one-file-button": _vm.uploadOneFile,
                        "simple-file-style": _vm.simpleFileStyle,
                      },
                    ],
                    style: {
                      display: _vm.hideBtns || _vm.readOnly ? "none" : "block",
                    },
                    attrs: {
                      accept: _vm.acceptMime,
                      multiple: _vm.multiple,
                      headers: _vm.headersParam,
                      drag: _vm.isDragable,
                      limit: parseInt(_vm.limitNumber()),
                      action: _vm.setRequestUrl,
                      "show-file-list": false,
                      "before-upload": _vm.beforeUpload,
                      "on-success": _vm.handleSuccess,
                      "on-error": _vm.handleError,
                      "on-exceed": _vm.handleExceed,
                      "on-progress": _vm.handleProgress,
                      "auto-upload": _vm.autoUpload,
                    },
                  },
                  [
                    _vm.isDragable
                      ? [
                          _c("i", { staticClass: "el-icon-upload" }),
                          _c("div", { staticClass: "el-upload__text" }, [
                            _vm._v("点击上传，或将文件拖拽到此处"),
                          ]),
                        ]
                      : [
                          _vm.uploadOneFile
                            ? [
                                _c("div", { staticClass: "one-file-button" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.showBtnName ? _vm.btnName : ""
                                      ) +
                                      " "
                                  ),
                                ]),
                                _c("div", [
                                  _c("span", { staticClass: "disabled-icon" }, [
                                    _c("i", {
                                      staticClass:
                                        "operation-icon el-icon-view",
                                    }),
                                    _c("i", {
                                      staticClass:
                                        "operation-icon el-icon-download",
                                    }),
                                    !_vm.readOnly
                                      ? _c("i", {
                                          staticClass:
                                            "operation-icon el-icon-delete",
                                        })
                                      : _vm._e(),
                                  ]),
                                  !_vm.readOnly
                                    ? _c("i", {
                                        staticClass:
                                          "operation-icon el-icon-upload2",
                                      })
                                    : _vm._e(),
                                ]),
                              ]
                            : _vm.simpleFileStyle
                            ? [
                                _vm.simple && !_vm.readOnly
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "scientific-research-file",
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.showBtnName ? _vm.btnName : ""
                                            ) +
                                            " "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            : _c(
                                "dsf-button",
                                { attrs: { icon: "yunduanshangchuan" } },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.showBtnName ? _vm.btnName : ""
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                        ],
                  ],
                  2
                ),
                _vm.multipleFiles &&
                _vm.multipleFiles.length > 0 &&
                !_vm.uploadOneFile &&
                !_vm.readOnly &&
                !_vm.simpleFileStyle
                  ? _c(
                      "dsf-button",
                      {
                        staticClass: "ds-button download-button",
                        style: { display: _vm.hideBtns ? "none" : "block" },
                        attrs: { icon: "yunduanxiazai" },
                        on: { click: _vm.downLoadFn },
                      },
                      [_vm._v(" 全部下载 ")]
                    )
                  : _vm._e(),
                _vm.showProgress && _vm.percentage > 0
                  ? _c("el-progress", {
                      attrs: {
                        "text-inside": true,
                        color: _vm.customColor,
                        "stroke-width": parseInt(_vm.strokeWidth),
                        percentage: _vm.percentage,
                      },
                    })
                  : _vm._e(),
                _vm.simple && !_vm.isDesign
                  ? [
                      _vm.multipleFiles.length
                        ? _c(
                            "div",
                            { staticClass: "simple-table-file" },
                            [
                              _vm._l(_vm.multipleFiles, function (data, index) {
                                return _c(
                                  "p",
                                  {
                                    key: data.id,
                                    class: {
                                      "hide-more":
                                        index > _vm.defaultRowsNumber,
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "file-name",
                                        attrs: { title: data.originalFileName },
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleColumnClick(
                                              "download",
                                              data,
                                              index
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.substringFileName(data))
                                        ),
                                      ]
                                    ),
                                    _vm.simple && !_vm.readOnly
                                      ? _c("i", {
                                          staticClass:
                                            "operation-icon el-icon-error",
                                          attrs: {
                                            title:
                                              "删除" + data.originalFileName,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleColumnClick(
                                                "delete",
                                                data,
                                                index
                                              )
                                            },
                                          },
                                        })
                                      : _vm._e(),
                                  ]
                                )
                              }),
                              _c(
                                "span",
                                {
                                  staticClass: "more",
                                  on: {
                                    click: function ($event) {
                                      return _vm.extendHandler(
                                        _vm.multipleFiles
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.multipleFiles.length > 3 &&
                                        !_vm.isShowMore
                                        ? "展开更多"
                                        : _vm.isShowMore
                                        ? "收起更多"
                                        : ""
                                    )
                                  ),
                                ]
                              ),
                            ],
                            2
                          )
                        : _c("div", { staticClass: "simple-table-file" }),
                    ]
                  : _vm._e(),
                _vm.uploadOneFile &&
                _vm.multipleFiles &&
                _vm.multipleFiles.length > 0
                  ? _c(
                      "div",
                      { staticClass: "one-file-style" },
                      [
                        _vm._l(_vm.multipleFiles, function (item) {
                          return [
                            _c(
                              "div",
                              {
                                key: item.id,
                                staticClass: "file-item upload-table",
                              },
                              [
                                _c("div", [
                                  _c("i", {
                                    staticClass: "icon",
                                    class: item.suffix,
                                  }),
                                  _vm._v(
                                    _vm._s(item.name) +
                                      "." +
                                      _vm._s(item.suffix)
                                  ),
                                ]),
                                _vm.showFileDate
                                  ? _c("div", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.dsf.date.format(
                                              item.uploadDate,
                                              "yyyy-mm-dd hh:ii"
                                            )
                                          ) +
                                          " "
                                      ),
                                    ])
                                  : _vm._e(),
                                _vm.showFileSize
                                  ? _c("div", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.computeFileSize(item.size)
                                          ) +
                                          " "
                                      ),
                                    ])
                                  : _vm._e(),
                                _c(
                                  "div",
                                  [
                                    _c("i", {
                                      staticClass:
                                        "operation-icon el-icon-view",
                                      attrs: { title: "预览" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleColumnClick(
                                            "view",
                                            item,
                                            0
                                          )
                                        },
                                      },
                                    }),
                                    _c("i", {
                                      staticClass:
                                        "operation-icon el-icon-download",
                                      attrs: { title: "下载" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleColumnClick(
                                            "download",
                                            item,
                                            0
                                          )
                                        },
                                      },
                                    }),
                                    !_vm.readOnly
                                      ? _c("i", {
                                          staticClass:
                                            "operation-icon el-icon-delete",
                                          attrs: { title: "删除" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleColumnClick(
                                                "delete",
                                                item,
                                                0
                                              )
                                            },
                                          },
                                        })
                                      : _vm._e(),
                                    _vm.showOnlineEdit(item)
                                      ? _c("i", {
                                          staticClass:
                                            "operation-icon el-icon-edit",
                                          attrs: { title: "在线编辑" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleColumnClick(
                                                "edit",
                                                item,
                                                0
                                              )
                                            },
                                          },
                                        })
                                      : _vm._e(),
                                    !_vm.readOnly
                                      ? _c(
                                          "el-upload",
                                          {
                                            staticClass: "list-upload",
                                            attrs: {
                                              accept: _vm.acceptMime,
                                              headers: _vm.headersParam,
                                              limit: parseInt(
                                                _vm.limitNumber()
                                              ),
                                              action: _vm.setRequestUrl,
                                              "show-file-list": false,
                                              "before-upload": _vm.beforeUpload,
                                              "on-success": _vm.handleSuccess,
                                              "on-error": _vm.handleError,
                                              "on-exceed": _vm.handleExceed,
                                              "auto-upload": _vm.autoUpload,
                                            },
                                          },
                                          [
                                            _c("i", {
                                              staticClass:
                                                "operation-icon el-icon-upload2",
                                              attrs: { title: "上传" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.handleColumnClick(
                                                    "upload2",
                                                    item,
                                                    0
                                                  )
                                                },
                                              },
                                            }),
                                          ]
                                        )
                                      : _vm._e(),
                                    item.suffix.toLowerCase() === "mp4"
                                      ? _c("i", {
                                          staticClass:
                                            "operation-icon el-icon-video-camera-solid",
                                          attrs: {
                                            title: "切片",
                                            "data-success": _vm.isCutSuccess,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.cutFile(item)
                                            },
                                          },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ]
                        }),
                      ],
                      2
                    )
                  : _vm._e(),
                _vm.multipleFiles &&
                _vm.multipleFiles.length > 0 &&
                !_vm.uploadOneFile &&
                !_vm.simple
                  ? _c(
                      "el-table",
                      {
                        staticClass: "upload-table",
                        attrs: {
                          data: _vm.multipleFiles,
                          "show-header": false,
                          "empty-text": "暂无选择",
                        },
                      },
                      [
                        _c("el-table-column", {
                          attrs: { type: "index", width: "40" },
                        }),
                        _c("el-table-column", {
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        class: {
                                          "view-name": _vm.clickPreview,
                                        },
                                        on: {
                                          click: function ($event) {
                                            _vm.clickPreview &&
                                              _vm.handleColumnClick(
                                                "view",
                                                scope.row,
                                                scope.$index
                                              )
                                          },
                                        },
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "icon",
                                          class: scope.row.suffix,
                                        }),
                                        _vm._v(
                                          _vm._s(scope.row.name) +
                                            "." +
                                            _vm._s(scope.row.suffix) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            1307584368
                          ),
                        }),
                        _vm.showFileDate
                          ? _c("el-table-column", {
                              attrs: { width: "160" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.dsf.date.format(
                                                scope.row.uploadDate,
                                                "yyyy.mm.dd"
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                158122017
                              ),
                            })
                          : _vm._e(),
                        _vm.showFileSize
                          ? _c("el-table-column", {
                              attrs: { width: "100" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.computeFileSize(
                                                scope.row.size
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                815445711
                              ),
                            })
                          : _vm._e(),
                        !_vm.readOnly
                          ? _c("el-table-column", {
                              attrs: {
                                label: "操作",
                                width: "260",
                                align: "right",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "div",
                                          { staticClass: "handle-table" },
                                          _vm._l(
                                            _vm.handleList,
                                            function (item, key) {
                                              return _c(
                                                "span",
                                                {
                                                  key: key,
                                                  staticStyle: { flex: "0" },
                                                },
                                                [
                                                  key !== "upload2" &&
                                                  key !== "video" &&
                                                  (key !== "edit" ||
                                                    _vm.showOnlineEdit(
                                                      scope.row
                                                    ))
                                                    ? _c("i", {
                                                        class: [
                                                          "operation-icon",
                                                          "el-icon-" + key,
                                                        ],
                                                        attrs: { title: item },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.handleColumnClick(
                                                              key,
                                                              scope.row,
                                                              scope.$index
                                                            )
                                                          },
                                                        },
                                                      })
                                                    : _vm._e(),
                                                  key === "upload2"
                                                    ? _c(
                                                        "el-upload",
                                                        {
                                                          staticClass:
                                                            "list-upload",
                                                          staticStyle: {
                                                            width: "auto",
                                                          },
                                                          attrs: {
                                                            accept:
                                                              _vm.acceptMime,
                                                            headers:
                                                              _vm.headersParam,
                                                            limit: parseInt(
                                                              _vm.limitNumber()
                                                            ),
                                                            action:
                                                              _vm.setRequestUrl,
                                                            "show-file-list": false,
                                                            "before-upload":
                                                              _vm.beforeUpload,
                                                            "on-success":
                                                              _vm.handleSuccess,
                                                            "on-error":
                                                              _vm.handleError,
                                                            "on-exceed":
                                                              _vm.handleExceed,
                                                            "auto-upload":
                                                              _vm.autoUpload,
                                                          },
                                                        },
                                                        [
                                                          _c("i", {
                                                            staticClass:
                                                              "operation-icon el-icon-upload2",
                                                            attrs: {
                                                              title: item,
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.handleColumnClick(
                                                                  key,
                                                                  scope.row,
                                                                  scope.$index
                                                                )
                                                              },
                                                            },
                                                          }),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  key === "video" &&
                                                  scope.row.suffix.toLowerCase() ===
                                                    "mp4"
                                                    ? _c("i", {
                                                        staticClass:
                                                          "operation-icon el-icon-video-camera-solid",
                                                        attrs: {
                                                          "data-success":
                                                            _vm.isCutSuccess,
                                                          title: item,
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.cutFile(
                                                              scope.row
                                                            )
                                                          },
                                                        },
                                                      })
                                                    : _vm._e(),
                                                ],
                                                1
                                              )
                                            }
                                          ),
                                          0
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                3279014891
                              ),
                            })
                          : _c("el-table-column", {
                              attrs: {
                                label: "操作",
                                width: "90",
                                align: "left",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c("i", {
                                          staticClass:
                                            "operation-icon el-icon-view",
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleColumnClick(
                                                "view",
                                                scope.row,
                                                scope.$index
                                              )
                                            },
                                          },
                                        }),
                                        _c("i", {
                                          staticClass:
                                            "operation-icon el-icon-download",
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleColumnClick(
                                                "download",
                                                scope.row,
                                                scope.$index
                                              )
                                            },
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                289263156
                              ),
                            }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.errors.length > 0 && _vm.multipleFiles.length <= 0
                  ? _c("div", { staticClass: "ds-error-text" }, [
                      _vm._v(" " + _vm._s(_vm.errorsMsg) + " "),
                    ])
                  : _vm._e(),
              ],
              2
            ),
          ],
      _c(
        "el-dialog",
        {
          attrs: {
            title: "修改文件名称",
            visible: _vm.editFileName,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.editFileName = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { attrs: { model: _vm.form } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "请输入新的名称", "label-width": "120px" } },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off", maxlength: "30" },
                    model: {
                      value: _vm.form.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "name", $$v)
                      },
                      expression: "form.name",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "dsf-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.editFileName = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "dsf-button",
                { attrs: { type: "primary" }, on: { click: _vm.editFile } },
                [_vm._v(" 确 定 ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.srcList && _vm.srcList.length
        ? _c("el-image", {
            ref: "fileView",
            staticClass: "file-image-view",
            attrs: { "preview-src-list": _vm.srcList },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }