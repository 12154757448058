<template>
  <tr :slot-name="slots[0].name" :style="{ height: height }" :class="getCss" :r-index="rowIndex">
    <slot :name="slots[0].name"></slot>
  </tr>
</template>
<script>
import DsfTableCell from "./tableCell";
export default dsf.component({
  name: "DsfTableRow",
  ctrlCaption: "表格布局单元行",
  mixins: [$mixins.control],
  components: {
    DsfTableCell
  },
  provide() {
    return {
      $tableRow: this
    };
  },
  props: {
    slots: {
      type: Array,
      default() {
        return [
          {
            name: "default",
            controls: []
          }
        ];
      }
    },
    height: {
      type: String,
      default: "57px"
    },
    rowIndex: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      tableCells: []
    };
  },
  computed: {
    getCss() {
      return [
        ...this.$getClass(),
        this.showBorder ? "" : "ds-no-border",
        !this.noPadding ? "" : "ds-no-padding"
      ]
    }
  },
  created() {
    if (!this.isDesign) {
      if (this.$vm && this.local) {
        this.$parent.tableRows.push(this);
      }
    }
  },
  beforeDestroy() {
    if (!this.isDesign) {
      dsf.array.remove(this.$parent.tableRows, this);
    }
  },
  methods: {
  }
});
</script>
