<template>
  <div class="header-menu">
    <template v-for="item in list">
      <div :key="item.menu_id" class="header-menu-item" :class="{active: homeRoot.menuKey.indexOf(item.menu_id) > -1}">
        <div class="info" @click="itemClick(item)">
          <i v-if="hasIcon" :class="[ item.menu_icon || 'icon-shezhi' ]" class="iconfont icon"></i>
          <span>{{ item.menu_name }}</span>
          <i class="iconfont more" :class="{'icon-icon_more': item.children && item.children.length}"></i>
        </div>
        <header-menu-children v-if="item.children && item.children.length" :list="item.children" is-children />
      </div>
    </template>
  </div>
</template>

<script>
import menuUtils from "../../utils";

export default {
  name: "HeaderMenuChildren",
  inject: ['homeRoot'],
  props: {
    list: {
      type: Array,
      default() {
        return []
      }
    },
    isChildren: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    hasIcon() {
      for (var i = 0, l = this.list.length; i < l; i++) {
        if (this.list[i].menu_icon?.trim()) {
          return true;
        }
      }
      return false;
    }
  },
  mounted() {
    if (this.isChildren) {
      this.$el.parentElement.onmouseenter = this.show;
      this.$el.parentElement.onmouseleave = this.hide;
    }
  },
  methods: {
    show() {
      this.$el.style.display = 'block';
      if (this.$el.style.visibility === 'visible') {
        return;
      }
      this.$el.style.visibility = 'hidden';
      let {width} = this.$el.getBoundingClientRect();
      let {left: pLeft, width: pWidth} = this.$el.parentElement.getBoundingClientRect();
      if (pWidth + width + pLeft + 5 > window.innerWidth) {
        this.$el.classList.remove('right');
        this.$el.classList.add('left');
      } else {
        this.$el.classList.remove('left');
        this.$el.classList.add('right');
      }
      this.$el.style.visibility = 'visible';
    },
    hide() {
      this.$el.style.display = 'none';
    },
    itemClick(item) {
      menuUtils.openPage.call(this.homeRoot, menuUtils.findMenu(item));
    }
  }
}
</script>