<template>
  <iframe
    ref="iframe"
    :style="{
      'width':'100%',
      'height':'100%',
      'border-style':'none',
      'display':'block'
    }"
    :src="iframeSrc"></iframe>
</template>
<script>
export default {
  name: "IframeLoader",
  props: {
    src: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      isMobile: dsf.global.$isMobile,
      iframeSrc: ""
    };
  },
  computed: {
    $src() {
      return this.src;
    }
  },
  methods: {
    getSrc() {
      let src = this.src;
      let urlInfo = dsf.url.parse(src); // replace(/http(s?):\/\//g);
      let result = _.find(dsf.config.setting_public_whitelist || [], (it) => {
        if (v.startsWith("http") || v.startsWith("https")) {
          return urlInfo.hostname == it;
        }
      });
      if (!result) {
        this.$message("加载非法资源", false);
        return "";
      }
      if (this.$refs.iframe) {
        let iframe = this.$refs.iframe;
        if (this.$router.params.state == "replace") {
          iframe.contentWindow.replace(v);
        } else {
          iframe.contentWindow.location.href = v;
        }
      }
    }
  },
  watch: {
    $src: {
      handler(v, ov) {
        let src = (this.src || "").trim();
        src = dsf.safe.url(src);
        if (!src) {
          dsf.error("非安全路径拒绝加载");
          return;
        }
        this.iframeSrc = this.src;
        if (this.$refs.iframe) {
          let iframe = this.$refs.iframe;
          if (this?.$router?.params?.state == "replace") {
            iframe.contentWindow.replace(this.iframeSrc);
          } else {
            iframe.contentWindow.location.href = this.iframeSrc;
          }
        }
      },
      immediate: true
    }
  }
};
</script>