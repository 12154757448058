/*
 * @Descripttion: 柱图默认的配置选项
 * @Author: zhanghang
 * @Date: 2021-07-21 15:00:22
 * @LastEditors: zhanghang
 * @LastEditTime: 2021-07-28 13:43:19
 */
import common from "./common"
import xaxis from "./xaxis"
import yaxis from "./yaxis"
export default dsf.mix(true, {
  //数据系配置
  series: [{
    type:'bar',
    
    name:'示例',
    //对应x轴下标
    yAxisIndex:0,
    //对应y轴下标
    xAxisIndex:0,
    //标签是否展示
    label:{
      show:false
    },
    // 柱体样式
    itemStyle:{
      color:common.color[0],
      // 柱体圆角
      barBorderRadius:[0,0,0,0],
      
      endColor:common.color[0],
      startColor:common.color[0],
      isshade:false,
      
    },
    // 不同柱系间隙
    barGap: '30%',
    // 相同柱系间隙
    barCategoryGap: '10%',
    // 柱体宽度
    barWidth:'',
    //柱子背景色
    showBackground:false,
    //数据
    data:[820, 932, 901, 934, 1290, 1330, 1320],
  }],
  //提示配置
  tooltip: {
    trigger: 'axis' ,
  },
}, common,xaxis,yaxis); 