const clickoutsideContext = '@@clickoutsideContext';
export default {
  bind(el, binding, vnode) {
    const documentHandler = (e) => {
      if (vnode.context && el !== e.target && !el.contains(e.target)) {
        vnode.context[el[clickoutsideContext].methodName]?.(binding.value);
      }
    };
    el[clickoutsideContext] = {
      documentHandler,
      methodName: binding.arg,
      arg: 'click',
    };
    document.addEventListener(el[clickoutsideContext].arg, documentHandler, false);
  },
  update(el, binding) {
    el[clickoutsideContext].methodName = binding.expression;
  },
  unbind(el) {
    document.removeEventListener(
      el[clickoutsideContext].arg,
      el[clickoutsideContext].documentHandler);
  },
  install(Vue) {
    Vue.directive('flowClickoutside', {
      bind: this.bind,
      unbind: this.unbind,
    });
  },
};
