<template>
  <div class="ds-control ds-form-item ds-numeration" :class="getCss">
    <template v-if="simple&&readOnly&&!isDesign">
      <span>{{ value }}</span>
      <slot name="error">
        <div v-if="errors.length>0" class="ds-error-text">{{ errorsMsg }}</div>
      </slot>
    </template>
    <template v-else>
      <!-- <label v-if="showLabel" class="ds-form-label" :style="getLabelWidthStyle()">
        {{ label }}
        <i v-if="dataCapion" class="iconfont icon-bangzhuzhongxin ds-control-helper" @click="showHelper"></i>
      </label> -->
      <DsfFieldLabel
        v-if="showLabel"
        :mode="showDataCaptionMode"
        :style="getLabelWidthStyle()"
        :show-icon="showDataCaptionIcon" 
        :trigger="showDataCaptionTrigger"
        :data-caption="dataCapion"
        :is-design="isDesign"
        :owner="_self">
        {{ $t(label) }}
      </DsfFieldLabel>
      <div class="ds-form-block" :style="getFormItemBlockMarginLeft()">
        <el-input
          v-if="!readOnly"
          v-model="inputValue"
          :class="{'ds-error-border':errors.length>0}"
          :placeholder="placeholder"
          type="text"
          :disabled="true"
        >
          <i
            v-if="showBtn"
            slot="suffix"
            :class="['iconfont', btnIcon ? btnIcon : 'icon-bianjisekuai']"
            @click="handleIconClick"
          ></i>
        </el-input>
        <!--只读状态只显示div-->
        <div v-else class="ds-form-readonly">
          <span>{{ value }}</span>
        </div>
        <slot name="error">
          <div v-if="errors.length>0" class="ds-error-text">{{ errorsMsg }}</div>
        </slot>
      </div>
    </template>
  </div>
</template>
<script>
export default dsf.component({
  name: "DsfNumeration",
  mixins: [$mixins.formControl],
  ctrlCaption: "编号",
  props: {
    simple: {
      type: Boolean,
      default: false,
    },
    showBtn: {
      type: Boolean,
      default: false,
    },
    btnIcon: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    rule: {
      type: String,
      default: "",
    },
    error_tipsPosition: {
      type: String,
      default: "left",
    },
    metadata: {
      type: Object,
      default() {
        return dsf.metadata.get("text-meta-data");
      },
    },
  },
  computed: {
    inputValue: {
      get() {
        return this.value;
      },
      set(v) {
        this.emitValueChange(v);
      },
    },
  },
  created() {
    if (!this.queryString.id&&!this.isDesign) {
      this.handleIconClick();
    }
  },
  methods: {
    inputChangeHandler() {
      this.$dispatch("change", this.value);
      this.$nextTick(() => {
        this.emitFormValidate(this.value);
      });
    },
    inputBlurHandler() {
      this.$dispatch("blur");
    },
    handleIconClick() {
      this.$http
        .get("number/get/" + this.rule)
        .done((res) => {
          if (res.success) {
            this.inputValue = res.data.value;
          } else {
            dsf.layer.message("请求异常！", false);
          }
        })
        .error((res) => {
          dsf.layer.message(res.message, false);
        });
    },
  },
});
</script>
