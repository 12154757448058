var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "ds-control ds-form-item ds-upload",
      class: _vm.getCss,
    },
    [
      _c(
        "el-upload",
        {
          ref: "upload",
          class: [
            { "avatar-uploader": _vm.switchMode },
            "upload-demo",
            _vm.classObject,
          ],
          style: {
            width: _vm.switchMode ? _vm.imgWidth + "px" : "",
            height: _vm.switchMode ? _vm.imgHeight + "px" : "",
          },
          attrs: {
            disabled: _vm.readOnly,
            accept: _vm.uploadFileExts,
            multiple: false,
            headers: _vm.headersParam,
            action: _vm.setRequestUrl,
            "show-file-list": false,
            "before-upload": _vm.beforeUpload,
            "on-success": _vm.handleSuccess,
            "on-error": _vm.handleError,
            "on-progress": _vm.handleProgress,
            "auto-upload": _vm.autoUpload,
          },
        },
        [
          _vm.switchMode
            ? [
                _vm.multipleFiles.length
                  ? _c("img", {
                      staticClass: "avatar",
                      attrs: {
                        src: _vm.dsf.url.getWebPath(
                          _vm.multipleFiles[0].relativePath
                        ),
                        fit: "cover",
                      },
                    })
                  : [
                      _c("img", {
                        staticClass: "avatar",
                        attrs: {
                          src: _vm.imageUrl[_vm.defaultImg],
                          fit: "cover",
                        },
                      }),
                      _vm.isShowTips
                        ? _c("p", { staticClass: "tips" }, [
                            _vm._v(
                              _vm._s(
                                "长 * 宽：" +
                                  _vm.imgWidth +
                                  "px * " +
                                  _vm.imgHeight +
                                  "px"
                              )
                            ),
                          ])
                        : _vm._e(),
                    ],
              ]
            : [
                _c(
                  "el-button",
                  { staticClass: "ds-button", attrs: { type: "primary" } },
                  [
                    _c("i", { staticClass: "el-icon-upload el-icon--left" }),
                    _vm._v(" " + _vm._s(_vm.btnName) + " "),
                  ]
                ),
              ],
        ],
        2
      ),
      _vm.showPhotoText
        ? _c("span", { staticStyle: { color: "red", "text-align": "left" } }, [
            _vm._v(_vm._s(_vm.tipsPhotoText)),
          ])
        : _vm._e(),
      _vm.errors.length > 0 && _vm.multipleFiles.length <= 0
        ? _c("div", { staticClass: "ds-error-text" }, [
            _vm._v(_vm._s(_vm.errorsMsg)),
          ])
        : _vm._e(),
      _c("crop-image", {
        attrs: {
          "show-tips-text": _vm.showTipsText,
          "tips-text": _vm.tipsText,
          "edit-img": _vm.editImg,
          "edit-file-img": _vm.editFileImg,
          "aspect-ratio": _vm.aspectRatio,
          "request-url": _vm.setRequestUrl,
        },
        on: { saveFile: _vm.editFile, handleClose: _vm.handleClose },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }