<template>
  <div class="el-select super-search-tree-select" style="width:100%">
    <el-popover v-model="item.isExtend" :width="getWidth()" transition="el-zoom-in-top" @hide="hideHandler" popper-class="tree-select-popover">
      <div slot="reference">
        <el-input v-if="!multipe" readonly style="width:100%" :value="selectName">
          <template slot="suffix">
            <i v-show="!showClose" :class="['el-select__caret', 'el-input__icon', 'el-icon-' + iconClass]"></i>
            <i v-if="showClose" class="el-select__caret el-input__icon el-icon-circle-close" @click="handleClearClick"></i>
          </template>
        </el-input>
        <template v-if="multipe">
          <el-input readonly style="width:100%">
            <template slot="suffix">
              <i v-show="!showClose" :class="['el-select__caret', 'el-input__icon', 'el-icon-' + iconClass]"></i>
              <i v-if="showClose" class="el-select__caret el-input__icon el-icon-circle-close" @click="handleClearClick"></i>
            </template>
          </el-input>
          <div ref="tags" class="el-select__tags" :style="{'width':'100%'}">
            <span v-if="collapseTags">
              <el-tag v-if="item.treeNodeSelected.length > 0" :closable="true" size="small" :hit="false" type="info" @close="deleteTag($event, item.treeNodeSelected[0])" disable-transitions>
                <span class="el-select__tags-text">{{ item.treeNodeSelected[0]._name }}</span>
              </el-tag>
              <el-tag v-if="item.treeNodeSelected.length > 1" :closable="false" size="small" type="info" disable-transitions>
                <span class="el-select__tags-text">+ {{ item.treeNodeSelected.length - 1 }}</span>
              </el-tag>
            </span>
            <span v-else>
              <el-tag v-for="tag in item.treeNodeSelected" :key="tag._id" :closable="true" size="small" type="info" @close="deleteTag($event, tag)" disable-transitions>
                <span class="el-select__tags-text">{{ tag._name }}</span>
              </el-tag>
            </span>
          </div>
        </template>

      </div>
      <template>
        <el-scrollbar style="padding:0px" tag="div" wrap-class="el-select-dropdown__wrap" view-class="el-select-dropdown__list">
          <dropdown-tree :values="values" :item="item" :options="options" :multipe="multipe" ref="tree" @change="treeNodeChange">
          </dropdown-tree>
        </el-scrollbar>
      </template>
    </el-popover>
  </div>
</template>

<script>
import DropdownTree from "./dropdownTree.vue";
export default {
  components: { DropdownTree },
  name: "DsfSuperSearchTreeSelect",
  data() {
    return {
      value: "",
      treeMap: {}
    };
  },
  props: {
    multipe: {
      type: Boolean,
      default: false
    },
    values: {
      type: [String, Array, Object],
      default() {
        return [];
      }
    },
    options: {
      type: Array,
      default() {
        return [];
      }
    },
    showClose: {
      type: Boolean,
      default: false
    },
    collapseTags: {
      type: Boolean,
      default: true
    },
    item: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  computed: {
    iconClass() {
      return this.item.isExtend ? "arrow-up is-reverse" : "arrow-up";
    },
    selectName() {
      return this.item.treeNodeSelected.length > 0 ? this.item.treeNodeSelected[0]._name : "";
    },
    currentNode() {
      if (!this.multipe && this.item.treeNodeSelected.length > 0) {
        return this.item.treeNodeSelected[0]._id;
      }
      return "";
    }
  },
  mounted() {},
  methods: {
    getWidth() {
      let width = this?.$el?.offsetWidth;
      return width || "auto";
    },
    focus() {
      this.$emit("focus");
    },
    change() {
      let values = null;
      if (this.multipe) {
        values = _.map(this.item.treeNodeSelected, "_id");
      } else {
        values = this.item.treeNodeSelected[0] ? [this.item.treeNodeSelected[0]._id] : [""];
      }
      this.$emit("update:values", values);
      this.$nextTick(() => {
        this.$emit("change");
      });
    },
    treeNodeChange(data, node) {
      if (!this.multipe) {
        this.item.treeNodeSelected = [];
        this.item.treeNodeSelected.push(this.$refs.tree.getSelectedData());
        this.item.isExtend = false;
        this.change();
      }
    },
    hideHandler() {
      if (this.multipe) {
        let nodes = this.$refs.tree.getSelectedData();
        this.item.treeNodeSelected = nodes;
        this.change();
      }
    },
    deleteTag(event, tag) {
      dsf.array.remove(this.item.values, tag._id);
      dsf.array.removeWith(this.item.treeNodeSelected,(v)=>{
        return v._id==tag._id;
      });
      this.change();
    }
  },
  watch: {
    "item.isExtend"(v) {
      if (v === true) {
        //打开面板时刷新树选中
        this.$refs.tree.setSelectedKeys(this.values);
      }
    }
  }
};
</script>