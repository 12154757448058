var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ds-control ds-form-item ds-switch", class: _vm.getCss },
    [
      _vm.simple && _vm.readOnly && !_vm.isDesign
        ? [
            _c(
              "DsfTextProxy",
              _vm._b(
                {
                  attrs: { "show-label": false },
                  model: {
                    value: _vm.value,
                    callback: function ($$v) {
                      _vm.value = $$v
                    },
                    expression: "value",
                  },
                },
                "DsfTextProxy",
                _vm._self.$props,
                false
              )
            ),
            _vm._t("error", function () {
              return [
                _vm.errors.length > 0
                  ? _c("div", { staticClass: "ds-error-text" }, [
                      _vm._v(" " + _vm._s(_vm.errorsMsg) + " "),
                    ])
                  : _vm._e(),
              ]
            }),
          ]
        : [
            _vm.showLabel
              ? _c(
                  "DsfFieldLabel",
                  {
                    style: _vm.getLabelWidthStyle(),
                    attrs: {
                      mode: _vm.showDataCaptionMode,
                      "show-icon": _vm.showDataCaptionIcon,
                      trigger: _vm.showDataCaptionTrigger,
                      "data-caption": _vm.dataCapion,
                      "is-design": _vm.isDesign,
                      owner: _vm._self,
                    },
                  },
                  [_vm._v(" " + _vm._s(_vm.$t(_vm.label)) + " ")]
                )
              : _vm._e(),
            _c(
              "div",
              {
                staticClass: "ds-form-block",
                style: _vm.getFormItemBlockMarginLeft(),
              },
              [
                _vm.readOnly
                  ? _c(
                      "div",
                      { staticClass: "ds-form-readonly" },
                      [
                        _c(
                          "DsfTextProxy",
                          _vm._b(
                            {
                              attrs: { "show-label": false },
                              model: {
                                value: _vm.value,
                                callback: function ($$v) {
                                  _vm.value = $$v
                                },
                                expression: "value",
                              },
                            },
                            "DsfTextProxy",
                            _vm._self.$props,
                            false
                          )
                        ),
                      ],
                      1
                    )
                  : _c(
                      "el-switch",
                      _vm._b(
                        {
                          class: { inset: _vm.showText && _vm.insetText },
                          on: { change: _vm.selectChange },
                          model: {
                            value: _vm.switchValue,
                            callback: function ($$v) {
                              _vm.switchValue = $$v
                            },
                            expression: "switchValue",
                          },
                        },
                        "el-switch",
                        _vm.bindAttr,
                        false
                      )
                    ),
                _vm._t("error", function () {
                  return [
                    _vm.errors.length > 0
                      ? _c("div", { staticClass: "ds-error-text" }, [
                          _vm._v(" " + _vm._s(_vm.errorsMsg) + " "),
                        ])
                      : _vm._e(),
                  ]
                }),
              ],
              2
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }