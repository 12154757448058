<!--
 * @Descripttion: 折线图表控件
 * @Author: zhanghang
 * @Date: 2021-07-21 14:27:15
 * @LastEditors: zhanghang
 * @LastEditTime: 2022-03-11 11:15:20
-->
<template>
  <div class="ds-control ds-chart" :style="{ width, height }">
    <div class="chart"  ref="chart">我是折线图</div>
    <template v-if="isDesign||(!isDesign&&slots[0].controls.length>0)">
      <div
        slot-name="default"
        class="ds-chart-slot"
      >
        <slot name="default"></slot>
      </div>
    </template>
  </div>
</template>
<script>
import defaultOptions from "../../output/chartDefault/polyline.js";
defaultOptions.legend.data = defaultOptions.series.map((item) => item.name);
const cloneDefault = _.cloneDeep(defaultOptions);
export default dsf.component({
  name: "DsfPlatformPolyline",
  ctrlCaption: "折线图表",
  mixins: [$mixins.layout,$mixins.chart],
  props: {
    //自定义配置总览
    chart: {
      type: Object,
      default() {
        return defaultOptions;
      },
    },
  },
  data(){
    return {
      cloneDefault,
    }
  },
  methods: {
    //数据源分析
    dataSourceAnalysis(data){
      let ydata = []
      let xdata = []
      const chart = _.cloneDeep(this.chart);
      data.forEach((v,i)=>{
        let index = Number(v.xIndex)
        // 依赖关系
        let o = {
          name:v.category,
          yAxisIndex: Number(v.yIndex),
          xAxisIndex: Number(v.xIndex),
          data:[]
        }
        const mapRelation =  chart.xAxis[index].mapRelation
        for(let key of mapRelation){
          o.data.push(v[key])
        }
        ydata.push(o)
      })
      this.analysis({
        ydata,
        xdata
      })
    },
    // 解析数据
    analysis(data) {
      let {xdata, ydata} = data
      const arr = ydata.reduce((arr, item) => {
        arr.push(item.name);
        return arr;
      }, []);
      this.chart.legend.data = arr;
      ydata.forEach((item, index) => {
        if (this.chart.series[index]) {
          let s = Object.assign(this.chart.series[index], item);
          this.$set(this.chart.series, index, s);
        } else {
          //多出数据项部分采用第一个数据项风格
          let data = _.cloneDeep(this.cloneDefault.series[0]);
          data.data = item.data;
          data.name = item.name;
          data.areaStyle.color = "rgba(255,255,255,0)";
          data.areaStyle.startColor = "rgba(255,255,255,0)";
          data.areaStyle.endColor = "rgba(255,255,255,0)";
          
          data.itemStyle.color = this.chart.color[index + 1];
          data.itemStyle.startColor = this.chart.color[index + 1];
          data.itemStyle.endColor = this.chart.color[index + 1];
          
          data.xAxisIndex=item.xAxisIndex||0;
          data.yAxisIndex=item.yAxisIndex||0;
          
          this.$set(this.chart.series, index, data);
        }
      });
      this.chart.series = this.chart.series.slice(0, ydata.length);

      if(xdata.length){
        this.chart.xAxis.forEach((v,i)=>{
          this.chart.xAxis[i].data = xdata[i];
        }) 
      }
      

      this.reset()
    },
  },
});
</script>
