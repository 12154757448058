var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ds-control ds-form-item ds-signature", class: _vm.getCss },
    [
      _vm.isDesign || _vm.readOnly || _vm.disabled
        ? [
            _vm.showLabel
              ? _c(
                  "DsfFieldLabel",
                  {
                    style: _vm.getLabelWidthStyle(),
                    attrs: {
                      mode: _vm.showDataCaptionMode,
                      "show-icon": _vm.showDataCaptionIcon,
                      trigger: _vm.showDataCaptionTrigger,
                      "data-caption": _vm.dataCapion,
                      "is-design": _vm.isDesign,
                      owner: _vm._self,
                    },
                  },
                  [_vm._v(" " + _vm._s(_vm.$t(_vm.label)) + " ")]
                )
              : _vm._e(),
            _c(
              "div",
              {
                staticClass: "signature",
                style: _vm.getFormItemBlockMarginLeft(),
              },
              [
                _vm.$value
                  ? _c("img", {
                      staticClass: "save-img",
                      attrs: { src: _vm.$value },
                    })
                  : _c("canvas"),
                !_vm.$value
                  ? _c("span", { staticClass: "signature-tip" }, [
                      _vm._v("请在此处签名"),
                    ])
                  : _vm._e(),
              ]
            ),
          ]
        : [
            _vm.showLabel
              ? _c(
                  "DsfFieldLabel",
                  {
                    style: _vm.getLabelWidthStyle(),
                    attrs: {
                      mode: _vm.showDataCaptionMode,
                      "show-icon": _vm.showDataCaptionIcon,
                      trigger: _vm.showDataCaptionTrigger,
                      "data-caption": _vm.dataCapion,
                      "is-design": _vm.isDesign,
                      owner: _vm._self,
                    },
                  },
                  [_vm._v(" " + _vm._s(_vm.$t(_vm.label)) + " ")]
                )
              : _vm._e(),
            _c(
              "div",
              {
                staticClass: "signature",
                style: _vm.getFormItemBlockMarginLeft(),
              },
              [
                _vm.$value
                  ? _c("img", {
                      staticClass: "save-img",
                      attrs: { src: _vm.$value },
                    })
                  : _c("div", { staticClass: "canvas-box" }, [
                      _vm.$value ? _c("canvas") : _vm._e(),
                      _c("canvas", {
                        ref: "canvas",
                        on: { mousedown: _vm.focus },
                      }),
                      !_vm.isFocus && !_vm.$value
                        ? _c("span", { staticClass: "signature-tip" }, [
                            _vm._v("请在此处签名"),
                          ])
                        : _vm._e(),
                    ]),
                !_vm.readOnly && !_vm.disabled && _vm.$value
                  ? _c("div", { staticClass: "save-signature" }, [
                      _vm._v(" 签名已保存，如需重新签名，请点击【重签】按钮 "),
                      _c(
                        "div",
                        { staticClass: "operation" },
                        [
                          _c(
                            "DsfButton",
                            {
                              staticClass: "save-btn",
                              attrs: { type: "plain" },
                              on: { click: _vm.reset },
                            },
                            [_vm._v(" 重签 ")]
                          ),
                        ],
                        1
                      ),
                    ])
                  : _c("div", { staticClass: "save-signature" }, [
                      _vm._v(" 完成签名后，请点击【完成】按钮 "),
                      !_vm.isFocus
                        ? _c("span", { staticClass: "save" }, [_vm._v("完成")])
                        : _c(
                            "div",
                            { staticClass: "operation" },
                            [
                              _c(
                                "span",
                                {
                                  staticClass: "clear",
                                  on: { click: _vm.clear },
                                },
                                [_vm._v("清空")]
                              ),
                              _c(
                                "DsfButton",
                                {
                                  staticClass: "save-btn",
                                  attrs: { type: "plain" },
                                  on: { click: _vm.getImg },
                                },
                                [_vm._v(" 完成 ")]
                              ),
                            ],
                            1
                          ),
                    ]),
                _vm._t("error", function () {
                  return [
                    _vm.errors.length > 0
                      ? _c("div", { staticClass: "ds-error-text" }, [
                          _vm._v(" " + _vm._s(_vm.errorsMsg) + " "),
                        ])
                      : _vm._e(),
                  ]
                }),
              ],
              2
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }