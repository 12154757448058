// 区划级别
export const gradeConfig = {
  0: {
    name: 'province',
    zhName: '省'
  },
  1: {
    name: 'city',
    zhName: '市'
  },
  2: {
    name: 'dist',
    zhName: '县/区'
  },
  3: {
    name: 'street',
    zhName: '街道'
  },
  4: {
    name: 'town',
    zhName: '村居'
  }
};

// 行政编码划分规则
const gradeRule = [
  [0, 2, '0000000000'],
  [0, 4, '00000000'],
  [0, 6, '000000'],
  [0, 9, '000'],
  [0, 12, '']
];

// 根据最后一级的value值推算前面几级的value
export const getValuesByLastValue = lastValue => {
  let ids = ['000000000000'];
  // todo 台湾、香港、澳门数据不对
  // todo 下面的处理可支持到一级二级筛选
  // todo 三级及以上前端暂时无解
  if (/^(71|81|82)/.test(lastValue)) {
    if (lastValue.substring(0,2) == '71') {
      ids.push('7100000000000');
    } else if (lastValue.substring(0,2) == '81') {
      ids.push('8100000000000');
    } else if (lastValue.substring(0,2) == '82') {
      ids.push('8200000000000');
    }
    if (lastValue.substring(2) != '00000000000') {
      ids.push(lastValue);
    }
  }
  // 其他数据正常
  else {
    for (let i = 0; i < gradeRule.length; i++) {
      let newVal = lastValue.substring(gradeRule[i][0], gradeRule[i][1]) + gradeRule[i][2];
      if (newVal == ids[i]) break;
      ids.push(newVal);
    }
  }
  return ids;
}

/**
 * 请求数据
 */
export function getOptions({ ids, endRange, startRange }, callback) {
  this.loading = true;
  this.$http.get("area/getArea", {
    TREEINFO_GLOBALID: ids,
    END_LEVEL: endRange,
    START_LEVEL: startRange,
  }, {
    cache: true,
    headers: {
      'Cache-Control': 'public'
    }
  })
    .done(({ success, data, message }) => {
      if (success) {
        callback && callback(data);
      } else {
        dsf.layer.message(message || '请求异常', false);
      }
    })
    .error(err => {
      dsf.layer.message(err?.message || '请求异常', false);
    })
    .always(() => {
      this.loading = false;
    });
}