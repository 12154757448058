<template>
  <div class="ds-control ds-navtab" :class="{ 'ds-no-padding': !showPadding }" :style="{ height: isDesign ? '100%' : tabHeight }">
    <el-tabs v-model="activeName" :tab-position="tabPosition" :type="tabStyle == 'customer-tab-pad' ? '' : tabStyle" :closable="enableDelete" @tab-click="tabClick" @tab-remove="tabRemove">
      <slot></slot>
      <el-tab-pane
        v-for="d in slots.filter(tab => {
          return isHide(tab) !== true;
        })"
        :key="d.name"
        :disabled="isdisable(d)"
        :label="d.title"
        :name="d.name"
        :slot-name="d.name"
        :lazy="isLazy(d)"
        :style="{'padding':$padding}"
      >
        <span slot="label">
          {{ d.title }}
          <span v-if="d.badge !== null" class="el-badge" v-html="dsf.safe.xss(d.badge)"></span>
        </span>
        <slot :name="d.name"></slot>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
export default dsf.component({
  name: 'DsfNavTab',
  ctrlCaption: '选项卡',
  mixins: [$mixins.tabControl],
  props: {},
  computed: {
    $padding() {
      let padding = this.contentPadding;
      return [
        dsf.isNumber(padding[0]) ? padding[0] + "px" : padding[0],
        dsf.isNumber(padding[1]) ? padding[1] + "px" : padding[1],
        dsf.isNumber(padding[2]) ? padding[2] + "px" : padding[2],
        dsf.isNumber(padding[3]) ? padding[3] + "px" : padding[3]
      ].join(" ")
    },
  },
});
</script>
