var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("el-date-picker", {
    staticStyle: { width: "100%" },
    attrs: {
      type: _vm.pickerType,
      "range-separator": "至",
      "start-placeholder": _vm.startPlaceholder,
      "end-placeholder": _vm.endPlaceholder,
    },
    on: {
      change: function ($event) {
        return _vm.change()
      },
    },
    model: {
      value: _vm.value,
      callback: function ($$v) {
        _vm.value = $$v
      },
      expression: "value",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }