var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "ds-control ds-section",
      class: _vm.getCss,
      staticStyle: { height: "100%" },
    },
    [
      _c("iframe", {
        ref: "iframe",
        attrs: {
          src: _vm.getPath(),
          width: "100%",
          height: "100%",
          frameborder: "0",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }