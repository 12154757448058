import { render, staticRenderFns } from "./personnelCard.vue?vue&type=template&id=0088cd11&"
import script from "./personnelCard.vue?vue&type=script&lang=js&"
export * from "./personnelCard.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

component.options.__file = "pc/bhc/personnelCard/personnelCard.vue"
export default component.exports