<template>
  <div v-show="isDesign || visible" :style="controlStyle" class="ds-control">
    <div class="ds-home-user-center" :is-design="isDesign" :style="panelStyle">
      <el-image
        v-if="info"
        :style="headStyle"
        class="ds-home-user-center-head-box"
        :src="(info.photo|| $replace(dsf.config.setting_public_user_default_header)) | imgFormat"
        fit="cover"
      >
      </el-image>
      <div v-if="info" class="ds-home-user-center-info">
        <div class="ds-home-user-center-name">{{ info.name || "" }}</div>
        <template v-for="(row, index) in info.exts || []">
          <div :key="index" class="ds-home-user-center-row">
            <DsfIcon
              class="ds-home-user-center-row-icon"
              :name="extsIconsList[index]"
            />
            <div class="ds-home-user-center-row-label">
              {{ row.text || "" }}：
            </div>
            <div
              class="ds-home-user-center-row-value ellipsis"
              :title="row.value || ''"
            >
              {{ row.value || "" }}
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
export default dsf.component({
  name: "DsfHomeUserCenter",
  ctrlCaption: "用户中心",
  mixins: [$mixins.control],
  provide() {
    return {
      $homePanel: this,
    };
  },
  design: {
    isMask: false,
  },
  props: {
    headHeight: {
      default: () => 208,
    },
    // 高度
    height: {
      type: String,
      default: "100%",
    },
    padding: {
      default: () => [0, 0, 0, 0],
    },
    info: {
      default: () => ({
        name: "名称",
        photo: "",
        exts: [
          {
            text: "学号",
            value: "122001",
          },
          {
            text: "单位",
            value: "单位名称",
          },
          {
            text: "电话",
            value: "1810xxxxxx",
          },
        ],
      }),
    },
    extsIcons: {
      default: () => "zuzhiqunzu,tab_AddressBook_d,xiaoxitongzhi",
    },
    url: {
      default: "",
    },
  },

  computed: {
    extsIconsList() {
      return this.extsIcons.split(",");
    },
    headStyle() {
      let { headHeight = 108 } = this;
      let style = {
        height: headHeight + "px",
        width: headHeight * 0.78 + "px",
      };
      return style;
    },
    controlStyle() {
      let { width, padding, height, isDesign, visible } = this;
      let style = {
        width,
        padding: padding.join("px ") + "px",
        height,
      };
      if (isDesign) {
        style.padding = "10px 10px 10px 10px";
        !visible && (style.opacity = "0.5");
      }
      return style;
    },
    panelStyle() {
      let { height } = this;
      return { height };
    },
    getMoreUrl() {
      if (this.isDesign) {
        return false;
      }
      let tab = this.getTabObj();
      let moreUrl = this.moreUrl;
      if (tab && tab.more != "") {
        moreUrl = tab.more;
      }
      return moreUrl;
    },
  },

  data() {
    return {};
  },

  watch: {
    url: {
      immediate: true,
      handler() {
        this.getData();
      },
    },
  },

  created() {},
  mounted() {},

  methods: {
    getData() {
      if (this.isDesign || !this.url) return;
      let params = {};
      this.$http
        .get(this.$replace(this.url, {}), params)
        .then(({ data }) => {
          if (data && data.success) {
            this.$emit("update:info", data.data || {});
          } else {
            dsf.layer.message(data.message, false);
          }
        })
        .catch(() => {
          dsf.layer.message("未知错误", false);
        });
    },
    reloadData() {
      this.getData();
    },
  },
});
</script>
