var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "header-menu" },
    [
      _vm._l(_vm.list, function (item) {
        return [
          _c(
            "div",
            {
              key: item.menu_id,
              staticClass: "header-menu-item",
              class: {
                active: _vm.homeRoot.menuKey.indexOf(item.menu_id) > -1,
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "info",
                  on: {
                    click: function ($event) {
                      return _vm.itemClick(item)
                    },
                  },
                },
                [
                  _vm.hasIcon
                    ? _c("i", {
                        staticClass: "iconfont icon",
                        class: [item.menu_icon || "icon-shezhi"],
                      })
                    : _vm._e(),
                  _c("span", [_vm._v(_vm._s(item.menu_name))]),
                  _c("i", {
                    staticClass: "iconfont more",
                    class: {
                      "icon-icon_more": item.children && item.children.length,
                    },
                  }),
                ]
              ),
              item.children && item.children.length
                ? _c("header-menu-children", {
                    attrs: { list: item.children, "is-children": "" },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }