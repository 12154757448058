<template>
  <div v-if="!isDesign" class="ds-control ds-viewpart ds-no-padding" :class="getCss" :path="getPath()" :style="{'background-color':backgroundColor}">
    <component
      :is="componentName"
      v-if="componentName"
      ref="view"
      :name-space="nameSpace"
      :page-name="pageName"
      :local-params="localParams"
      :query-string="params"
      v-bind="getProps"
      :page-props="$pageProps"
      @ready="viewReady"
      @yes="yes"></component>
  </div>
  <div v-else class="ds-control ds-viewpart ds-no-padding" :class="getCss">
    <div class="ds-viewparent-desgine-panel">
      <span>{{ path }}页面片段</span>
    </div>
  </div>
</template>
<script>
export default dsf.component({
  inheritAttrs: false,
  name: "DsfViewPart",
  ctrlCaption: "页面视图",
  mixins: [$mixins.control],
  provide() {
    return {
      $frame: this
    };
  },
  props: {
    path: {
      type: String,
      default: ""
    },
    showModel: {
      type: String,
      default: "none"
    },
    backgroundColor: {
      type: String,
      default: ""
    },
    forceUpdate: {
      type: Boolean,
      default: true
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    syncSave: {
      type: Boolean,
      default: false
    },
    //独立页面，表单保存时不会将参数提交到弹出框或者窗体的url中
    independent: {
      type: Boolean,
      default: false
    },
    setProps: {
      type: Function,
      default: null
    },
    isOpenDialog: {
      type: Boolean,
      default: false
    },
    isBlockControl: {
      type: Boolean,
      default: true
    },
    pageProps: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      componentName: "",
      nameSpace: "",
      pageName: "",
      params: {},
      localParams: {},
      isFormSave: false,
      watchPath:null
    };
  },
  beforeCreate() {},
  created() {
    if (this.$vm && this.$vm.frames) {
      this.$vm.frames[this.caption] = this;
    }
    if (this.$dialog && this.isOpenDialog) {
      this.$dialog.currentView = this;
    }
    //监听路径是否发生变化
    this.watchPath = this.$watch(
      function () {
        return this.getPath();
      },
      {
        handler(v, ov) {
          if (v != ov) {
            this.mappingComponent(v);
          }
        },
        immediate: true
      }
    );
  },
  computed: {
    getProps() {
      return !this.setProps ? this.$attrs : this.setProps() || {};
    },
    getCss() {
      return [...this.$getClass(), this.shadow ? "shadow" : "", this.showModel == "full" ? "full" : ""];
    },
    $pageProps() {
      let props = dsf.mix(
        {
          fit: this.showModel == "full"
        },
        this.pageProps || {}
      );
      return props;
    }
  },
  updated() {
    if (this.isDesign) {
      this.$dispatch("design-height-change", this.showModel == "full" ? "100%" : "auto");
    }
  },
  beforeDestroy() {
    if (this.$dialog && this.isOpenDialog) {
      delete this.$dialog.currentView;
    }
    this.watchPath&&this.watchPath();
  },
  methods: {
    viewReady() {
      this.$dispatch("ready", this);
      if (this.$vm) {
        this.$vm.$emit(this.caption.toLowerCase() + "-ready", this);
      }
    },
    getNameSpacePath(path) {
      return (path || "").replace(/^((\#\/)?pc\/|\/pc\/)/g, "");
    },
    getPath() {
      if (this.path) {
        let path2 = this.getNameSpacePath(this.path);
        // path2 = path2.replace(/^((\#\/)?pc\/|\/pc\/)/g, "");
        let urlInfo = dsf.url.parse(path2);
        let url = urlInfo.href.replace(urlInfo.search, "");
        let query = [];
        for (let k in urlInfo.searchQuery) {
          query.push(k + "=" + urlInfo.searchQuery[k]);
        }
        if (dsf.isUnDef(urlInfo.searchQuery.isview)) {
          if (this.readOnly) {
            query.push("isview=1");
          }
        }
        url += query.length > 0 ? "?" + query.join("&") : "";
        return this.$replace(url);
      }
      return "";
    },
    reset() {
      this.$refs?.view?.reset?.();
    },
    reloadData() {
      this.$refs?.view?.reloadData?.();
    },
    analysisUrl() {
      let currPath = this.getNameSpacePath(this.path);
      // currPath = currPath.replace(/^(\#\/pc\/|\/pc\/)/g, "");
      let paramsStart = currPath.indexOf("?");
      var arr = [currPath.substr(0, paramsStart < 0 ? currPath.length : paramsStart), paramsStart < 0 ? "" : currPath.substr(paramsStart + 1)];
      var path = arr[0];
      path = path.startsWith("/") ? path.substr(1) : path;
      var params = arr[1];
      let pathArr = path.split("/");
      let ns = path.replace(/\//g, ".");
      let nameSpace = pathArr.slice(0, pathArr.length - 1).join(".");
      let pageName = pathArr[pathArr.length - 1];
      let parseResult = dsf.url.queryStringParse(params);
      //动态替换表达式
      let localParams = {};
      _.each(parseResult, (param, i) => {
        localParams[i] = this.$replace(param);
      });
      let parentViewParams = this.getParentParams();
      let mergeParams = {};
      if (this.isOpenDialog || this.independent) {
        //id参数为表单特殊参数在弹出层时不会默认继承给子页面
        mergeParams = dsf.mix({}, this.$route?.query, parentViewParams, { id: null }, localParams);
      } else {
        mergeParams = dsf.mix({}, this.$route?.query, parentViewParams, localParams);
      }
      if (this.readOnly) {
        mergeParams.isview = "1";
      }
      //如果id为null或者undefined清除id参数
      if (dsf.isUnDef(mergeParams.id)) {
        delete mergeParams.id;
      }
      this.localParams = localParams;
      return {
        ns: ns,
        nameSpace: nameSpace,
        pageName: pageName,
        params: mergeParams,
        localParams: localParams
      };
    },
    urlChange(v, isFormSave) {
      let queryString = dsf.url.queryStringStringify(v.query || {});
      let newPath = v.nameSpace.replace(".", "/") + "/" + v.pageName + (queryString ? "?" + queryString : "");
      this.isFormSave = isFormSave;
      this.$emit("update:path", newPath);
    },
    async yes() {
      if (this.$refs.view.yes) {
        return await this.$refs.view.yes();
      }
    },
    mappingComponent(v) {
      if (v && !this.isDesign) {
        let urlInfo = this.analysisUrl();
        let ns = urlInfo.ns;
        this.nameSpace = urlInfo.nameSpace;
        this.pageName = urlInfo.pageName;
        this.params = urlInfo.params;
        dsf
          .dynamicComponent(ns, "pc")
          .then((result) => {
            if (this.componentName == result.name) {
              if (this.forceUpdate) {
                this.componentName = "";
                this.$nextTick(() => {
                  this.componentName = result.name;
                });
              } else {
                this.updateChildrenViewsParams();
                if (!this.isFormSave) {
                  this.reset();
                  this.reloadData();
                }
              }
            } else {
              this.componentName = "";
              this.$nextTick(() => {
                this.componentName = result.name;
              });
            }
          })
          .catch((error) => {
            dsf.error(error);
            dsf.layer.pc.message("无法加载组件", false);
          })
          .finally(() => {
            this.isFormSave && (this.isFormSave = false);
          });
      }
    },
    getParentParams() {
      if (this?.$vm?.$frame) {
        return this.$vm.$frame.params;
      }
      return {};
    },
    updateChildrenViewsParams() {
      _.each(this.$children || [], (it) => {
        dsf.deepForEach(it,function(c)  {
          if (c.$options._componentTag == "DsfViewPart") {
            let urlinfo = c.analysisUrl();
            c.params = urlinfo.params;
            c.localParams = urlinfo.localParams;
          }
        },
        {
          children: "$children"
        });
      });
    }
  },
  watch: {
    "$route.query"(to, from) {
      let urlInfo = this.analysisUrl();
      this.params = urlInfo.params;
    }
  }
});
</script>