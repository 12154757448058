<template>
  <div v-loading="loading" class="ds-control ds-form-item ds-upload" :class="getCss">
    <!-- <label v-if="showLabel" class="ds-form-label" :style="getLabelWidthStyle()">
      {{ label }}
      <i v-if="dataCapion" class="iconfont icon-bangzhuzhongxin ds-control-helper" @click="showHelper"> </i>
    </label> -->
    <DsfFieldLabel
      v-if="showLabel"
      :mode="showDataCaptionMode"
      :style="getLabelWidthStyle()"
      :show-icon="showDataCaptionIcon"
      :trigger="showDataCaptionTrigger"
      :data-caption="dataCapion"
      :is-design="isDesign"
      :owner="_self"
    >
      {{ $t(label) }}
    </DsfFieldLabel>
    <div class="ds-form-block" :style="getFormItemBlockMarginLeft()">
      <el-upload
        ref="upload"
        class="upload-demo"
        :accept="acceptMime"
        :multiple="multiple"
        :headers="headersParam"
        :limit="parseInt(uploadFileNum)"
        :action="setRequestUrl"
        :show-file-list="false"
        :before-upload="beforeUpload"
        :on-success="handleSuccess"
        :on-error="handleError"
        :on-exceed="handleExceed"
        :on-progress="handleProgress"
        :auto-upload="autoUpload"
        :style="{ visibility: readOnly ? 'hidden' : 'visible' }"
      >
        <dsf-button icon="yunduanshangchuan">
          {{ showBtnName ? btnName : "" }}
        </dsf-button>
      </el-upload>
      <dsf-button v-if="multipleFiles && multipleFiles.length > 0" class="ds-button download-button" icon="yunduanxiazai" @click="downLoadFn">
        全部下载
      </dsf-button>
      <template v-if="!isDesign && simple && formatter">
        {{ multipleFiles | proxy(formatter) }}
      </template>
      <div v-if="multipleFiles && multipleFiles.length > 0" class="img-container">
        <div v-for="(item, index) in multipleFiles" :key="item.id" class="pic">
          <el-image
            ref="elImage"
            style="width: 130px; height: 92px"
            :src="dsf.url.getWebPath(item.relativePath)"
            :alt="item.originalFileName"
            :preview-src-list="[dsf.url.getWebPath(item.relativePath)]"
            fit="cover"
            @click.native="clickHandler(index, $event)"
            :z-index="zIndex"
          ></el-image>
          <div class="title">
            <p class="file-name ellipsis" :title="item.originalFileName">
              {{ item.originalFileName }}
            </p>
            <template v-for="(items, key) of handleList">
              <i
                :key="key"
                v-if="key !== 'upload2' && key !== 'scissors' && !readOnly"
                :title="items"
                :class="['operation-icon', `el-icon-${key}`]"
                @click="handleColumnClick(key, item, index, multipleFiles)"
              ></i>
              <i
                :key="key"
                v-if="isCutPic && key === 'scissors' && !readOnly"
                :title="items"
                class="operation-icon el-icon-scissors"
                @click="handleColumnClick(key, item, index, multipleFiles)"
              ></i>
              <el-upload
                :key="key"
                v-if="key === 'upload2' && !readOnly"
                class="list-upload"
                :accept="acceptMime"
                :multiple="false"
                :headers="headersParam"
                :limit="1"
                :action="setRequestUrl"
                :show-file-list="false"
                :before-upload="beforeUpload"
                :on-success="handleSuccess"
                :on-error="handleError"
                :on-exceed="handleExceed"
                :auto-upload="autoUpload"
              >
                <i :title="items" class="operation-icon el-icon-upload2" @click="handleColumnClick(key, item, index, multipleFiles)"></i>
              </el-upload>
            </template>
          </div>
        </div>
      </div>
      <div v-if="description" class="ds-upload-description">{{ description }}</div>
      <div v-if="errors.length > 0 && multipleFiles.length <= 0" class="ds-error-text">
        {{ errorsMsg }}
      </div>
    </div>
    <crop-image
      :edit-img="editImg"
      :edit-file-img="editFileImg"
      :aspect-ratio="aspectRatio"
      :request-url="setRequestUrl"
      @saveFile="editFile"
      @handleClose="handleClose"
    />
  </div>
</template>

<script>
import defaultcover from "_platform/assets/styles/themes/upload/coverdefault.png";
import cropImage from "./cropImage";

export default dsf.component({
  name: "DsfUploadImg",
  mixins: [$mixins.formControl],
  ctrlCaption: "图片上传",
  props: {
    label: {
      type: String,
      default: "图片上传",
    },
    btnName: {
      type: String,
      default: "图片上传",
    },
    showBtnName: {
      type: Boolean,
      default: true,
    },
    multiple: {
      type: Boolean,
      default: true,
    },
    simple: {
      type: Boolean,
      default: false,
    },
    uploadFileNum: {
      type: [Number, String],
      default: 2,
    },
    maxFileSize: {
      type: String,
      default: "",
    },
    zipFileName: {
      type: String,
      default: "",
    },
    required: {
      type: Boolean,
      default: false,
    },
    isCutPic: {
      type: Boolean,
      default: true,
    },
    aspectRatio: {
      type: String,
      default: "",
    },
    requestUrl: {
      type: String,
      default: "",
    },
    extsSetting: {
      type: Object,
      default() {
        return {};
      },
    },
    description: {
      type: String,
      default: "",
    },
    metadata: {
      type: Object,
      default() {
        return dsf.metadata.get("photo-meta-data");
      },
    },
  },
  data() {
    return {
      loading: false,
      autoUpload: true,
      isShowDownloadBtn: false, // 是否显示下载按钮
      // multipleFiles: [], // 上传文件数组
      uploadFileType: "file", //默认上传类型
      uploadFileExts: ".bmp,.jpg,.jpeg,.png,.gif", //允许上传后缀名
      acceptMime: "", // 弹窗筛选条件MiMe类型
      headersParam: {
        "X-XSRF-TOKEN": dsf.getCookie("XSRF-TOKEN") || "",
        authorization_token: dsf.getToken(),
      },
      defaultcover: defaultcover,
      handleList: {
        back: "左移",
        right: "右移",
        delete: "删除",
        scissors: "裁剪",
        upload2: "重新上传",
      },
      isCutSuccess: false, // 视频切片
      editImg: false,
      editFileImg: {},
      cropper: "",
      uploadFile: {
        status: false, // 当前为重新上传
        index: 0, // 当前索引
      },
      zIndex: 99999,
    };
  },
  created() {
    this.rebuildDialogData();
  },
  computed: {
    setRequestUrl() {
      return dsf.url.getWebPath(this.requestUrl || "file/upload/");
    },
    multipleFiles: {
      get() {
        let files = [];
        if (this.value !== "" && dsf.isDef(this.value) && typeof this.value === "string") {
          files = JSON.parse(this.value);
        }
        return files;
      },
      set(v) {
        this.emitValueChange(JSON.stringify(v));
      },
    },
  },
  methods: {
    beforeUpload(file) {
      // 如果当前上传文件数量等于已上传数量
      if (this.uploadFileNum == this.multipleFiles.length && !this.uploadFile.status) {
        dsf.layer.message(`当前限制选择最多 ${this.uploadFileNum} 个文件`, false);
        return false;
      }
      // 判断文件格式
      if (this.uploadFileExts) {
        let dotIndex = file.name.lastIndexOf(".");
        let testmsg = "";
        if (dotIndex > 0) {
          testmsg = file.name.substring(dotIndex).toLowerCase();
        }
        let arr = _.filter(this?.uploadFileExts?.split?.(",") || [], (it) => it);
        if (!~arr.indexOf(testmsg)) {
          dsf.layer.message(`选择文件中包含不支持的文件格式,请选择包含这些格式的文件：${this.uploadFileExts}`, false);
          return false;
        }
      }
      // 判断文件大小
      let maxSize = this.maxFileSize ? this.getFileSize(this.maxFileSize) : "";
      let isMaxSize = maxSize ? file.size / 1024 < maxSize : true;
      if (!isMaxSize) {
        dsf.layer.message(`文件大小不能超过${this.maxFileSize}`, false);
        return false;
      }
      // 是否有重名
      if (_.some(this.multipleFiles, ["originalFileName", file.name])) {
        setTimeout(() => {
          dsf.layer.message(`已存在文件【${file.name}】，请修改后重新上传`, false);
        }, 0);
        return false;
      }
    },
    handleProgress() {
      this.loading = true;
      // console.log("进度条", file.percentage.toFixed(0));
    },
    //上传成功
    handleSuccess(response) {
      this.loading = false;
      if (response.success && response.state == 20000) {
        let files = response.data[0];
        // 重新上传 替换当前文件
        if (this.uploadFile.status) {
          this.multipleFiles.splice(this.uploadFile.index, 1, files);
          this.uploadFile.status = false;
          this.emitValueChange(JSON.stringify(this.multipleFiles));
        } else {
          if (this.uploadFileNum == this.multipleFiles.length) {
            dsf.layer.message(`当前限制选择最多 ${this.uploadFileNum} 个文件`, false);
            this.multipleFiles.slice(0, this.uploadFileNum);
          } else {
            this.multipleFiles.push(...response.data);
          }
          this.emitValueChange(JSON.stringify(this.multipleFiles));
        }
      }
    },
    //上传失败
    handleError(err, file, fileList) {
      this.loading = false;
      let errData = JSON.parse(JSON.stringify(err));
      if (errData.status == "404") {
        dsf.layer.message("服务器异常", false);
      } else {
        dsf.layer.message("上传异常", false);
      }
    },
    handleExceed(files, fileList) {
      dsf.layer.message(`当前限制选择最多 ${this.uploadFileNum} 个文件`, false);
    },
    //获取文件大小
    getFileSize(data) {
      let fileSize = parseInt(data);
      if (data.indexOf("M") > -1 || data.indexOf("m") > -1) {
        fileSize = parseInt(data) * 1024;
      }
      return fileSize;
    },
    handleColumnClick(type, row, index) {
      // console.log("row", row, index);
      const handler = {
        back: () => {
          if (index !== 0) {
            let upDate = this.multipleFiles[index - 1];
            this.multipleFiles.splice(index - 1, 1);
            this.multipleFiles.splice(index, 0, upDate);
            this.emitValueChange(JSON.stringify(this.multipleFiles));
          }
        },
        right: () => {
          if (index + 1 !== this.multipleFiles.length) {
            let downDate = this.multipleFiles[index + 1];
            this.multipleFiles.splice(index + 1, 1);
            this.multipleFiles.splice(index, 0, downDate);
            this.emitValueChange(JSON.stringify(this.multipleFiles));
          }
        },
        delete: () => {
          dsf.layer
            .confirm(`确定要删除【${row.originalFileName}】文件吗?`)
            .then(() => {
              // 删除重新再上传？ this.$refs.upload
              this.$refs.upload.uploadFiles.splice(index, 1);
              this.multipleFiles.splice(index, 1);
              this.emitValueChange(JSON.stringify(this.multipleFiles));
            })
            .catch((e) => e);
        },
        scissors: () => {
          this.editFileImg = "";
          this.editImg = true;
          this.editFileImg = row;
          this.uploadFile.index = index;
        },
        upload2: () => {
          this.uploadFile = {
            status: true, // 当前为重新上传
            index: index, // 当前索引
          };
        },
      };
      handler[type] && handler[type]();
    },
    editFile(files) {
      this.editImg = false;
      this.multipleFiles.splice(this.uploadFile.index, 1, files);
      this.uploadFile.status = false;
      this.emitValueChange(JSON.stringify(this.multipleFiles));
    },
    // 关闭裁剪
    handleClose() {
      this.editImg = false;
    },
    computeFileSize(fileSize) {
      let filesizeUnit = "";
      //1048576
      if (parseInt(fileSize) > 1050000) {
        filesizeUnit = (fileSize / Math.pow(1024, 2)).toFixed(2) + "M";
      } else {
        filesizeUnit = (fileSize / Math.pow(1024, 1)).toFixed(2) + "K";
      }
      return filesizeUnit;
    },
    downLoadFn() {
      let fileIds = [];
      let paramID = "";
      for (let i = 0; i < this.multipleFiles.length; i++) {
        let id = this.multipleFiles[i].id;
        let name = this.multipleFiles[i].name;
        let file = id + "^" + name;
        fileIds.push(file);
      }
      paramID = fileIds.join(",");
      let url = dsf.url.getWebPath("file/download");
      $(
        "<form action=" +
          url +
          ' method="get">' + // action请求路径及推送方法
          '<input type="text" name="files" value="' +
          paramID +
          '"/>' + // 文件id
          '<input type="text" name="zipName" value="' +
          this.zipFileName +
          '"/>' + // 文件名称
          "</form>"
      )
        .appendTo("body")
        .submit()
        .remove();
    },
    rebuildDialogData() {
      if (this.extsSetting.extStr) {
        let obj = this.extsSetting;
        // this.uploadFileType = 'file';//默认上传类型
        // this.uploadFileExts=""//允许上传后缀名
        // this.acceptMime =""//弹窗筛选条件MiMe类型
        this.uploadFileType = obj.fileType;
        this.isLinkeMine = obj.isLinkeMine;
        this.uploadFileExts = obj.extStr;
        this.acceptMime = obj.mineStr;
        // console.log("this.uploadFileExts", this.uploadFileExts);
        // console.log("this.acceptMime", this.acceptMime);
      }
    },
    clickHandler(index, event) {
      let $elImage = this.$refs.elImage[index];
      let $imageViewer = $elImage.$children.length ? $elImage.$children[0] : null;
      let target = event.target;
      if (target.className.indexOf("el-image__preview") > -1 && !$imageViewer.toBody) {
        document.body.appendChild($imageViewer.$el);
        $imageViewer.toBody = true;
      }
      // 点击层 关闭图片
      this.$nextTick(() => {
        $(".el-image-viewer__mask").click(() => {
          $elImage.closeViewer();
        });
      });
    },
  },
  components: {
    cropImage,
  },
});
</script>
