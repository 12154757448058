/*
 * @Descripttion: 雷达图默认配置项
 * @Author: zhanghang
 * @Date: 2021-07-30 17:05:15
 * @LastEditors: zhanghang
 * @LastEditTime: 2021-08-02 11:04:42
 */
import common from "./common"
export default dsf.mix(true, {
  //雷达图数据系
  radar: [
    {
      name: {
        // textStyle: {
        //   color: '#fff',
        //   backgroundColor: '#999',
        //   borderRadius: 3,
        //   padding: [3, 5]
        // }
      },
      //坐标轴线
      axisLine: {
        lineStyle: {
          color: '#666'
        }
      },
      //分割线
      splitLine: {
        lineStyle: {
          color: '#ccc'
        }
      },
      //分割区域
      splitArea:{
        areaStyle:{
          color: ['rgba(114, 172, 209, 0.2)',
            'rgba(114, 172, 209, 0.4)', 'rgba(114, 172, 209, 0.6)',
            'rgba(114, 172, 209, 0.8)', 'rgba(114, 172, 209, 1)']
        }
      },
      indicator: [
        { name: '销售' },
        { name: '管理' },
        { name: '信息技术' },
        { name: '客服' },
        { name: '研发' },
        { name: '市场' }
      ],
      // 圆点位置
      center: ['50%', '50%'],
      // 雷达大小
      radius: '75%',
      // 形状
      shape: 'circle',
      //开始角度
      startAngle: 90,
      // 分割份数
      splitNumber: 5,

    }
  ],
  //雷达图数据值
  series: [{
    type: 'radar',
    data: [
      {
        value: [430, 1000, 2800, 3500, 5000, 4000],
        name: '预算分配'
      },
      {
        value: [500, 1400, 2800, 3100, 4200, 3000],
        name: '实际开销'
      }
    ]
  }],
  //提示配置
  tooltip: {},
}, common);