<template>
  <div class="ds-control ds-page" :page-type="pageType" :class="getCss" :style="pageStyle" :route-url="routeUrl">
    <!--页头-->
    <div class="ds-page-head">
      <div ref="head" class="head-inner" :slot-name="slots[1].name">
        <slot :name="slots[1].name"></slot>
      </div>
    </div>
    <div :ref="'content'" class="ds-page-content" :style="getBackgroundStyle">
      <div class="ds-page-content-wrap" :style="pageContentStyle">
        <div class="ds-page-left" ref="left" :style="leftStyle" :slot-name="slots[3].name">
          <slot :name="slots[3].name"></slot>
        </div>
        <div class="ds-page-center" ref="center" :slot-name="slots[0].name" :style="contentStyle">
          <slot name="center-prepend"></slot>
          <slot :name="slots[0].name"></slot>
          <slot name="center-append"></slot>
        </div>
        <div class="ds-page-right" ref="right" :style="rightStyle" :slot-name="slots[4].name">
          <slot :name="slots[4].name"></slot>
        </div>
      </div>
    </div>
    <!--页脚-->
    <div v-if="hasFooter" class="ds-page-foot" :style="{'background-color':footerBackground}">
      <div ref="foot" class="foot-inner" :slot-name="slots[2].name">
        <slot :name="slots[2].name"></slot>
      </div>
    </div>
    <div style="display:none" v-html="customCss"></div>
    <dsf-to-designer ref="eleDesgin" v-show="!isDesign" v-if="gotoIcon" :title="title" :goto-preview="gotoPreview"></dsf-to-designer>
    <!-- <a v-show="!isDesign" v-if="gotoIcon" ref="eleDesgin" v-drag data-flag="true" :title="`进入${title}设计模式`" class="to_desgin" @click="gotoPreview">
      <i class="iconfont icon-shezhi"></i>
    </a> -->
  </div>
</template>
<script>
export default dsf.component({
  name: "DsfPage",
  ctrlCaption: "页面",
  mixins: [$mixins.view],
  data() {
    return {
      gotoIcon: (dsf.config.setting_public_gotoicon || dsf.config.setting_public_gotoIcon) == "1",
      headHeight: 0,
      footHeight: 0,
      centerHeight: "auto",
      headerStyle: {},
      footerStyle: {},
      contentHeight: 0,
      // show: true
      boxHeight: 0,
      leftViewShow: true,
      rightViewShow: true
    };
  },
  props: {
    formItemLabelWidth: {
      type: Number,
      default: 120
    },
    needLogin: {
      type: Boolean,
      default: true
    },
    slots: {
      type: Array,
      default() {
        return [
          { name: "center", controls: [] },
          { name: "head", controls: [] },
          { name: "foot", controls: [] },
          { name: "left", controls: [] },
          { name: "right", controls: [] }
        ];
      }
    },
    contentPadding: {
      type: Array,
      default: function () {
        return ["0px", "0px", "0px", "0px"];
      }
    },
    pageMaxWidth: {
      type: String,
      default: null
    },
    leftViewHeight: {
      type: String,
      default: "auto"
    },
    rightViewHeight: {
      type: String,
      default: "auto"
    }
  },
  beforeCreate() {
    // console.time("加载页面");
  },
  computed: {
    routeUrl() {
      // this?.$route?.fullPath||undefined;
      return ["/pc", this.context.B, this.context.M.replace(".", "/"), this.name].join("/") + "?" + dsf.url.queryStringStringify(this.queryString);
    },
    leftViewWidth() {
      let views = [];
      let vs = this.viewSplit;
      if (dsf.isString(vs)) {
        views = this.viewSplit.split(",");
      } else {
        views = vs;
      }
      let leftWidth = views[0] ? views[0] : "";
      return this.unitSize(leftWidth || "auto");
    },
    rightViewWidth() {
      let views = [];
      let vs = this.viewSplit;
      if (dsf.isString(vs)) {
        views = this.viewSplit.split(",");
      } else {
        views = vs;
      }
      let rightWidth = 0;
      rightWidth = views[views.length - 1] ? views[views.length - 1] : "";
      return this.unitSize(rightWidth || "auto");
    },
    centerViewWidth() {
      let views = [];
      let vs = this.viewSplit;
      if (dsf.isString(vs)) {
        views = this.viewSplit.split(",");
      } else {
        views = vs;
      }
      let centerWidth = 0;
      if (views.length > 2) {
        centerWidth = views[1] ? views[1] : 0;
      }
      if (!parseInt(centerWidth) || !centerWidth) {
        centerWidth = "auto";
      }
      centerWidth = this.unitSize(centerWidth);
      return centerWidth;
    },
    splitWidth() {
      if (this.leftRight == "split-small") {
        return 5;
      } else if (this.leftRight == "split-medium") {
        return 10;
      } else if (this.leftRight == "split-big") {
        return 15;
      } else if (this.leftRight == "split-highbig") {
        return 20;
      } else {
        return 0;
      }
    },
    splitWidthHorizontal() {
      if (this.topBottom == "split-small") {
        return 5;
      } else if (this.topBottom == "split-medium") {
        return 10;
      } else if (this.topBottom == "split-big") {
        return 15;
      } else if (this.topBottom == "split-highbig") {
        return 20;
      } else {
        return 0;
      }
    },
    getCss() {
      let fit = (this.fit === "1" || this.fit) === true ? true : false;
      return [
        this.isDesign ? "is-designer" : "",
        ...this.$getClass(),
        fit ? "fit" : "",
        //判断是否在模态窗口中，如果是附加fit样式
        this.$dialog && !fit ? (this.fitInModel == "1" || this.fitInModel === true ? "fit" : "") : ""
      ];
    },
    //组件更元素样式
    pageStyle() {
      let { headHeight, footHeight } = this.getHeadAndFootHeight();
      // let attachStyle = this.getAttachStyle();
      return {
        "padding-top": headHeight + "px",
        "padding-bottom": footHeight + "px"
        // visibility: this.show ? "visible" : "hidden"
      };
    },
    //内容区域样式
    pageContentStyle() {
      let style = {
        // width: this.pageWidth == "100%" || !this.pageWidth ? "auto" : this.unitSize(this.pageWidth),
        width: this.isDesign ? "auto" : this.pageWidth == "100%" || !this.pageWidth ? "auto" : this.unitSize(this.pageWidth),
        margin: "auto"
      };
      if (this.pageMaxWidth) {
        style["max-width"] = this.unitSize(this.pageMaxWidth);
      }
      if (this.isDesign) {
        style["max-width"] = "100%";
      }
      return style;
    },
    //正文样式
    contentStyle() {
      let splitWidth = this.splitWidth;
      let contentPadding = this.contentPadding;
      let top = parseFloat(contentPadding[0] || "0px");
      let right = parseFloat(contentPadding[1] || "0px");
      let bottom = parseFloat(contentPadding[2] || "0px");
      let left = parseFloat(contentPadding[3] || "0px");
      let widthStyle = {
        width: this.getViewWidth(this.centerViewWidth, [this.leftViewWidth, this.rightViewWidth])
      };
      // let cw_num = parseInt(this.centerViewWidth);
      // widthStyle["width"] = `calc(${this.unitSize(this.centerViewWidth)})`;

      return {
        // width: "calc(100% - " + (leftWidth + rightWidth) + "px" + ")",
        "padding-left": this.unitSize(parseFloat(splitWidth) + parseFloat(left)),
        "padding-right": this.unitSize(parseFloat(splitWidth) + parseFloat(right)),
        "padding-top": this.unitSize(top),
        "padding-bottom": this.unitSize(bottom),
        ...widthStyle
      };
    },
    //左侧视图区域样式
    leftStyle() {
      let style = {
        width: this.getViewWidth(this.leftViewWidth, [this.centerViewWidth, this.rightViewWidth])
      };
      style.height = this.unitSize(this.leftViewHeight || "auto");
      return style;
    },
    //右侧视图区域样式
    rightStyle() {
      let style = {
        width: this.getViewWidth(this.rightViewWidth, [this.leftViewWidth, this.centerViewWidth])
      };
      style.height = this.unitSize(this.rightViewHeight || "auto");
      return style;
    }
  },
  created() {
    //对老版本viewSplit做兼容
    if (this.isDesign && dsf.isString(this.viewSplit)) {
      //this.viewSplit=
      let vs = this.viewSplit.split(",");
      if (vs.length == 2) {
        vs = [vs[0], "auto", vs[1]];
      }
      this.viewSplit = vs;
    }
  },
  beforeDestroy() {},
  destroyed() {},
  mounted() {
    // console.timeEnd("加载页面");
    if (this.isDesign) {
      this.watchCenterHeight();
      this.resize();
    }
  },
  updated() {},
  methods: {
    getAttachStyle() {
      return {};
    },
    getContentHeight() {
      if (this.$el && this.$el.nodeType == 1) {
        let { headHeight, footHeight } = this.getHeadAndFootHeight();
        return this.$el.offsetHeight - (headHeight + footHeight);
      }
    },
    getHeadAndFootHeight() {
      var headHeight = this.headHeight;
      var footHeight = this.footHeight;
      if (!this.isDesign) {
        headHeight = headHeight + this.splitWidthHorizontal;
        footHeight = footHeight + this.splitWidthHorizontal;
      }
      return {
        headHeight,
        footHeight
      };
    },
    watchCenterHeight() {
      // this.resize();
      this.setCenterHeight();
      window.setTimeout(this.watchCenterHeight, 1000);
    },
    setCenterHeight() {
      let head = this.$refs.head ? this.$refs.head : null;
      let foot = this.$refs.foot ? this.$refs.foot : null;
      this.headHeight = head ? $(head).outerHeight() : 0;
      this.footHeight = foot ? $(foot).outerHeight() : 0;
    },
    getViewWidth(width, other) {
      if (width == "auto") {
        let autos = [];
        let widths = [];
        //将除本视图除外的视图尺寸分类存放，值为auto存放到autos数组，值为具体尺寸存放到widths数组
        _.each(other, (it) => {
          let arr = it == "auto" ? autos : widths;
          arr.push(it);
        });
        //将有具体值得数据进行css calc加法；
        let express1 = widths.join(" + ") || "";

        //将autos值进行均分
        let express2 = "";
        if (autos.length) {
          //用100%扣除有实际尺寸的部分
          express2 = `100%${express1 ? " - (" + express1 + ")" : ""}`;
          //如果存在auto列需要+1，因为当前视图本身也是auto,最后再将每块等分后的宽度乘以排除自身后的auto视图数量
          if (autos.length > 1) {
            express2 = `((${express2}) / ${autos.length + 1}) * ${autos.length}`;
          } else {
            express2 = `(${express2}) / ${autos.length + 1}`;
          }
        }
        let arr = [];
        express1 && arr.push(express1);
        express2 && arr.push(express2);
        let express = arr.join(" + ");
        express = express ? " - (" + express + ")" : "";
        let w = `calc(100%${express})`;
        return w;
      } else {
        return this.unitSize(width);
      }
    },
    resize() {
      // this.setContainerSize();
      this.setCenterHeight();
      this.sizeComplete = true;
      this.$nextTick(() => {
        if (this.$vm) {
          this.$vm.$emit("page-resize");
        }
      });
    },
    gotoPreview() {
      let isDrag = this.$refs?.eleDesgin?.$el?.getAttribute?.("data-flag");
      if (isDrag === "true") {
        let previewUrl = "";
        let baseUrl = "~/designer.html" + (this.pageType == "question" ? "#/question" : "#/pc/page");
        previewUrl = dsf.url.getWebPath(`${baseUrl}?B=${this.context.B}&M=${this.context.M}&pname=${this.name}&title=${this.title}&templateType=1`);
        window.open(previewUrl, "_blank");
      }
    }
  }
});
</script>
