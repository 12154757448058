var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "ds-control ds-steps-nav",
      class: [_vm.position, { click: _vm.isClick }],
      style: { height: _vm.ctrlHeight },
    },
    [
      _c(
        "div",
        { staticClass: "ds-steps-nav-head", style: _vm.headStyle },
        [
          _c(
            "el-steps",
            {
              attrs: {
                active: _vm.$heightLightActive,
                space: _vm.space,
                simple: _vm.simple,
                direction: _vm.direction,
                "align-center": _vm.simple ? false : _vm.alignCenter,
                "finish-status": _vm.finishStatus,
              },
            },
            [
              _vm._l(_vm.$items, function (item, index) {
                return [
                  _c(
                    "el-step",
                    {
                      key: item.value,
                      class: { active: index == _vm.$heightLightActive },
                      attrs: { status: _vm.getStatus(index) },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "ds-steps-nav-item-icon",
                          class: { active: index == _vm.$active },
                          attrs: { slot: "icon" },
                          on: {
                            click: function ($event) {
                              return _vm.stepClick(item, index)
                            },
                          },
                          slot: "icon",
                        },
                        [
                          _vm._t(
                            "icon",
                            function () {
                              return [
                                !_vm.simple
                                  ? _c(
                                      "div",
                                      { staticClass: "el-step__icon-inner" },
                                      [_vm._v(_vm._s(index + 1))]
                                    )
                                  : _vm._e(),
                              ]
                            },
                            { data: { item: item, index: index } }
                          ),
                        ],
                        2
                      ),
                      _c(
                        "template",
                        { slot: "title" },
                        [
                          _vm._t(
                            "title",
                            function () {
                              return [
                                _c(
                                  "div",
                                  {
                                    staticClass: "ds-steps-nav-item-title",
                                    attrs: { slot: "title" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.stepClick(item, index)
                                      },
                                    },
                                    slot: "title",
                                  },
                                  [_vm._v(_vm._s(item.text))]
                                ),
                              ]
                            },
                            { data: { item: item, index: index } }
                          ),
                        ],
                        2
                      ),
                      _c(
                        "template",
                        { slot: "description" },
                        [
                          _vm._t(
                            "description",
                            function () {
                              return [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "ds-steps-nav-item-description",
                                    attrs: { slot: "title" },
                                    slot: "title",
                                  },
                                  [_vm._v(_vm._s(item.description))]
                                ),
                              ]
                            },
                            { data: { item: item, index: index } }
                          ),
                        ],
                        2
                      ),
                    ],
                    2
                  ),
                ]
              }),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "ds-steps-nav-body" },
        [
          _vm._l(_vm.$items, function (item, index) {
            return [
              _vm.isDesign || !_vm.lazy || _vm.loadedItems[index]
                ? _c(
                    "div",
                    {
                      key: item.value,
                      staticClass: "ds-steps-nav-item ds-no-padding",
                      class: { active: index == _vm.$active },
                      attrs: { "slot-name": "default" + index },
                    },
                    [_vm._t("default" + index, null, { index: index })],
                    2
                  )
                : _vm._e(),
            ]
          }),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }