<template>
  <div class="des-custom-list" ref="custom">
    <ul
      class="content-ul"
      :style="{ 'background-color': bgColor }"
      v-if="formInline.list && formInline.list.length"
    >
      <li :class="{ 'list-title': listStyle, border: liBorder }">
        <div
          v-for="(item, i) in formInline.list"
          :key="item.text + i"
          :style="{ width: item.width }"
          :class="['text-' + item.text, { ellipsis: item.ellipsis == '1' }]"
        >
          {{ item.title }}
        </div>
      </li>
      <template v-if="!isDesign">
        <li
          :class="[
            {
              spot: listStyle,
              border: liBorder,
              'has-link': formInline.linkCode,
            },
            'has-link',
            'list-height-' + lineHeight,
          ]"
          v-for="content in dataList"
          :key="content.biz_id"
          @click="liClick(content)"
        >
          <div
            v-for="(item, i) in formInline.list"
            :key="item.text + i"
            :style="{ width: item.width }"
            :class="['text-' + item.text, { ellipsis: item.ellipsis == '1' }]"
          >
            {{ content[item.code] || item.des }}
          </div>
        </li>
      </template>
      <template v-else>
        <li
          :class="[
            {
              spot: listStyle,
              border: liBorder,
              'has-link': formInline.linkCode,
            },
            'has-link',
            'list-height-' + lineHeight,
          ]"
        >
          <div
            v-for="(content, i) in formInline.list"
            :key="content.text + i"
            :style="{ width: content.width }"
            :class="[
              'text-' + content.text,
              { ellipsis: content.ellipsis == '1' },
            ]"
          >
            {{ content.title }}
          </div>
        </li>
      </template>
    </ul>
    <div v-else class="empty-content">请配置列表行数据</div>
  </div>
</template>
<script>
export default dsf.component({
  name: "DsfCustomList",
  ctrlCaption: "自定义列表",
  mixins: [$mixins.control],
  inject: {
    $getTabObj: {
      default: null,
    },
  },
  props: {
    liBorder: {
      type: Boolean,
      default: false,
    },
    listStyle: {
      type: Boolean,
      default: false,
    },
    lineHeight: {
      type: String,
      default: "small",
    },
    bgColor: {
      type: String,
      default: "rgb(255, 255, 255, 0)",
    },
    url: {
      type: String,
      default: "",
    },
    formInline: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  data() {
    return {
      loading: false,
      dataList: [],
    };
  },

  components: {},

  computed: {},

  mounted() {},

  created() {
    if (!this.isDesign && this.url) {
      this.initData();
    }
  },
  methods: {
    liClick(params) {
      let url = params.url;
      if (this.formInline.linkType == "true") {
        this.$openWindow(url);
      } else {
        this.$openDialog({
          title: "代办提醒",
          content: "DsfIframe",
          width: "70vw",
          height: "60vh",
          params: {
            rmiframeUrl: url,
          },
          btns: [],
        });
      }
    },
    initData() {
      this.$http
        .get(this.url)
        .done(({ data }) => {
          if (data.length > 0) {
            this.dataList = data;
          }
          let tabId = $(this.$refs.custom)
            .parent(".tab-panel-content")
            .attr("slot-name");
          if (tabId) {
            let tab = this.$getTabObj(tabId);
            tab.url = this.formInline.moreUrl;
            tab.badge = data.length;
          }
        })
        .error((err) => {
          dsf.layer.message(err.message || "出错了，请重试", false);
        })
        .always(() => {});
    },
  },
});
</script>
