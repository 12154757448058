var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "ds-control ds-form-item ds-date-picker",
      class: _vm.getCss,
    },
    [
      _vm.simple && _vm.readOnly && !_vm.isDesign
        ? [
            _c(
              "span",
              {
                attrs: {
                  title: _vm._f("proxy")(_vm.getShowContent(), _vm.formatter),
                },
              },
              [
                _vm._v(
                  _vm._s(_vm._f("proxy")(_vm.getShowContent(), _vm.formatter))
                ),
              ]
            ),
            _vm._t("error", function () {
              return [
                _vm.errors.length > 0
                  ? _c("div", { staticClass: "ds-error-text" }, [
                      _vm._v(" " + _vm._s(_vm.errorsMsg) + " "),
                    ])
                  : _vm._e(),
              ]
            }),
          ]
        : [
            _vm.showLabel
              ? _c(
                  "DsfFieldLabel",
                  {
                    style: _vm.getLabelWidthStyle(),
                    attrs: {
                      mode: _vm.showDataCaptionMode,
                      "show-icon": _vm.showDataCaptionIcon,
                      trigger: _vm.showDataCaptionTrigger,
                      "data-caption": _vm.dataCapion,
                      "is-design": _vm.isDesign,
                      owner: _vm._self,
                    },
                  },
                  [_vm._v(" " + _vm._s(_vm.$t(_vm.label)) + " ")]
                )
              : _vm._e(),
            _c(
              "div",
              {
                staticClass: "ds-form-block",
                class: { "ds-error-border": _vm.errors.length > 0 },
                style: _vm.getFormItemBlockMarginLeft(),
              },
              [
                !_vm.readOnly
                  ? _c("el-date-picker", {
                      ref: "datePicker",
                      attrs: {
                        disabled: _vm.disabled,
                        type: _vm.$chooseType,
                        editable: _vm.isInput,
                        format: _vm.$timeFormat,
                        clearable: _vm.clearable,
                        placeholder: _vm.placeholder,
                        "picker-options": _vm.mPicker || _vm.pickerOptions,
                        "value-format": _vm.valueFormat,
                      },
                      on: { focus: _vm.pickerShow, change: _vm.dateChange },
                      model: {
                        value: _vm.value2,
                        callback: function ($$v) {
                          _vm.value2 = $$v
                        },
                        expression: "value2",
                      },
                    })
                  : _vm.readOnly
                  ? _c("div", { staticClass: "ds-form-readonly" }, [
                      _c(
                        "span",
                        {
                          attrs: {
                            title: _vm._f("proxy")(
                              _vm.getShowContent(),
                              _vm.formatter
                            ),
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm._f("proxy")(
                                _vm.getShowContent(),
                                _vm.formatter
                              )
                            )
                          ),
                        ]
                      ),
                    ])
                  : _vm._e(),
                _vm._t("error", function () {
                  return [
                    _vm.errors.length > 0
                      ? _c("div", { staticClass: "ds-error-text" }, [
                          _vm._v(" " + _vm._s(_vm.errorsMsg) + " "),
                        ])
                      : _vm._e(),
                  ]
                }),
              ],
              2
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }