var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "ds-designer-mobile-setting",
      staticStyle: {
        width: "100%",
        height: "100%",
        "padding-top": "50px",
        "box-sizing": "border-box",
      },
    },
    [
      _c(
        "div",
        { staticClass: "ds-designer-phone" },
        [
          _c("DsfDemoBrowserNavBar", {
            staticClass: "browser-nav-bar",
            attrs: { "show-back": false, title: _vm.title },
          }),
          _c("iframe", {
            attrs: {
              name: "appDemo",
              src: _vm.dsf.url.getWebPath(
                "~/designer.html#/pc/preview?mobile=1"
              ),
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }