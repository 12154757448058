<template>
  <div class="choose-send-line">
    <p
      v-for="(line, index) in lines"
      :key="index"
      class="item"
      :class="{ choosed: checked === line }"
      @click="chooseLine(line)"
    >
      {{
        type === "next"
          ? line.Name
          : `${line.NodeName}-${line.LinkUser.RUserName}`
      }}
    </p>
  </div>
</template>
<script>
export default {
  name: "ChooseSendNode",
  props: {
    lines: {
      type: Array,
      default() {
        return [];
      },
    },
    callback: {
      type: Function,
      default: dsf.noop,
    },
    requestID: {
      type: String,
      default() {
        return "";
      },
    },
    type: {
      type: String,
      default() {
        return "next";
      },
    },
    isBatch: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  data() {
    return {
      checked: null,
    };
  },
  created() {
    // this.initFlow();
  },
  mounted() {},
  methods: {
    yes() {
      return this.checked;
    },
    validate() {
      if (!this.checked) {
        dsf.layer.message("请选择要发送的环节", false);
        return false;
      }
      return true;
    },
    chooseLine(line) {
      if (this.type === "next") {
        this.checked = line;
        let param = {
          sRequestID: this.requestID,
          sLineID: line.LineID,
        };
        const url = this.isBatch ? "wfr/batchSendToLine" : "wfr/sendToLine";
        this.$http.post(url, param).then((res) => {
          if (res.data.state == 20000) {
            this.callback(res, true);
          }
        });
      } else {
        this.callback([line.PID, dsf.url.queryString("sCurLinkID")], line.ID);
      }
      document.querySelector(".close-send-node").click();
    },
  },
};
</script>
