<template>
  <div v-show="loaded" class="ds-super-search" :class="{'is-click':isClickExtend,'row-split-boder': superSearchRowBorder, 'ds-super-search__extend':isExtend,'float':float&&!isDesign }">
    <!--用于悬浮状态时支持高度-->
    <div class="ds-super-search-static" v-if="float&&!isDesign" :style="{height:unitSize(staticHeight)}">
    </div>
    <!--用户真实可操作的面板-->
    <div class="ds-super-search-real-box">
      <div class="el-row ds-super-search-fitler" v-if="showFitler">
        <div class="ds-super-search-item el-col-24 filter-bar">
          <label class="ds-super-search-item-text" :style="{width: unitSize(titleMaxWidth)}">筛选:</label>
          <p class="ds-super-search-tips" v-if="getFilterBar().length === 0">{{ searchTips }}</p>
          <div class="ds-super-search-item-block">
            <DsfSuperSearchFilter v-for="item in getFilterBar()" :key="item.id" class="ds-super-search-filter-item" :item="item" @change="valueChange($event, item)" @remove="removeFilter($event)"></DsfSuperSearchFilter>
          </div>
        </div>
      </div>
      <div class="ds-super-search-main">
        <div class="ds-super-search-main-left" :style="searchAreaHeight">
          <dsf-virtual-scroll>
            <template>
              <div class="el-row where-row">
                <template v-for="(cell) in layout">
                  <DsfSuperSearchItem
                    v-if="cell.type!='buttons'"
                    :key="cell.id"
                    :item-key="cell.id"
                    :item="cell"
                    :span="cell.layout"
                    :class="[
                      'seach-item',
                      'el-col-' + cell.layout,
                      cell.showModel == 'tile' || cell.showModel == 'treeTile' ? 'tile' : '',
                    ]"
                    :row-index="cell.rowIndex"
                    :titleMaxWidth="titleMaxWidth"
                    v-show="isExtend||cell.rowIndex<=initRowsNumber-1"
                    @focus="filterAllClose"
                    @change="valueChange($event, cell)">
                  </DsfSuperSearchItem>
                </template>
                <template v-if="!valueChangeTrigger">
                  <!--折叠状态下按钮-->
                  <template v-if="!isExtend">
                    <div v-if="buttonCell.type=='buttons'" :key="'button'" :span="buttonCell.layout" :class="['el-col-'+buttonCell.layout,'seach-item','search-button-bar','align-right']">
                      <DsfButton size="small" @click.stop.prevent="triggerSearch">查询</DsfButton>
                      <DsfButton size="small" type="plain" @click.stop.prevent="reset" style="margin-left:10px">重置</DsfButton>
                      <a v-if="hasExtend" href="javascript:void(0)" class="link-extend" @click.stop.prevent="extendHandler" style="margin-left:10px">
                        展开&nbsp;<DsfIcon name="arrow-up"></DsfIcon>
                      </a>
                    </div>
                  </template>
                  <!--展开状态下按钮-->
                  <template v-else>
                    <div :span="extendButtonLayout" :class="['el-col-'+extendButtonLayout,'search-button-bar',(extendButtonLayout==24?'align-center':'align-right')]" :key="'button'">
                      <DsfButton size="small" @click.stop.prevent="triggerSearch">查询</DsfButton>
                      <DsfButton size="small" type="plain" @click.stop.prevent="reset">重置</DsfButton>
                      <a v-if="hasExtend" href="javascript:void(0)" class="link-extend" @click.stop.prevent="extendHandler" style="margin-left:10px">
                        收起&nbsp;<DsfIcon name="arrow-up"></DsfIcon>
                      </a>
                    </div>
                  </template>
                </template>
              </div>
            </template>
          </dsf-virtual-scroll>
        </div>
        <div class="ds-super-search-main-right" v-if="showExtendButton&&!extendInBottom">
          <a href="javascript:void(0)" class="link-extend" @click.stop.prevent="extendHandler">
            {{isExtend?'收起':'展开'}}&nbsp;<DsfIcon name="arrow-up"></DsfIcon>
          </a>
        </div>
      </div>
      <div v-if="showExtendButton&&extendInBottom" ref="spanExtend" class="ds-super-search-extend">
        <a href="javascript:void(0)" class="link-extend" @click.stop.prevent="extendHandler">
          {{isExtend?'收起筛选条件':'展开筛选条件'}}&nbsp;<DsfIcon name="arrow-up"></DsfIcon>
        </a>
      </div>
    </div>
  </div>
</template>
<script>
import DsfSuperSearchFilter from "./superSearchFilter";
import DsfSuperSearchItem from "./superSearchItem";
export default {
  name: "DsfSuperSearch",
  inheritAttrs: false,
  components: {
    DsfSuperSearchFilter,
    DsfSuperSearchItem
  },
  inject: {
    $vm: {
      default: null
    }
  },
  provide() {
    return {
      $superSearch: this
    };
  },
  props: {
    isDesign: {
      type: Boolean,
      default: false
    },
    //检索值发生变化立即触发检索
    valueChangeTrigger: {
      type: Boolean,
      default: true
    },
    buttonBarPositionMode: {
      // normal  column
      type: String,
      default: () => "normal"
    },
    owner: {
      type: Object,
      default() {
        return null;
      }
    },
    firstHasButton: {
      type: Boolean,
      default: false
    },
    searchItemSetting: {
      type: Array,
      default() {
        return [];
      }
    },
    searchHeight: {
      type: Number,
      default: 0
    },
    defaultRowsNumber: {
      type: Number,
      default: 2
    },
    showFitler: {
      type: Boolean,
      default: false
    },
    superSearchRowBorder: {
      type: Boolean,
      default: true
    },
    queryValue: {
      type: Object,
      default() {
        return {};
      }
    },
    float: {
      type: Boolean,
      default: false
    },
    maxHeight: {
      type: String,
      default: "600"
    },
    titleMaxWidth: {
      type: String,
      default: ""
    },
    extendButtonPosition: {
      type: String,
      default: "bottom"
    },
  },
  data() {
    return {
      isExtend: false,
      searchItems: [],
      optionsMap: {},
      totalRowsNumber: 0,
      layout: [],
      loaded: false,
      isFirstLoad: false,
      rowHeight: 45,
      defaultHeight: 0,
      value: this.queryValue || {},
      config: dsf.mix(true, {}, dsf.config.component.superSearch),
      initRowsNumber: this.defaultRowsNumber,
      onChangeEvent: false,
      staticHeight: 0,
      realHeight: 0,
      mainHeight: 0,
      extendButtonLayout: 24,
      buttonCell: {
        type: "buttons",
        layout: 0
      },
      isClickExtend: false,
      searchTips: ""
    };
  },
  computed: {
    searchAreaHeight() {
      let style = {
        height: this.unitSize(this.mainHeight),
        overflow: this.isExtend ? "visible" : "hidden"
      };
      if (this.maxHeight) {
        style.maxHeight = this.unitSize(this.maxHeight);
      }
      return style;
    },
    hasExtend() {
      if (this.totalRowsNumber > this.initRowsNumber) {
        return true;
      }
      return false;
    },
    showExtendButton() {
      return this.hasExtend && this.valueChangeTrigger;
    },
    extendInBottom() {
      return this.extendButtonPosition == "bottom";
    }
  },
  watch: {
    searchItemSetting: {
      async handler(v) {
        await this.updateSearchItemSetting();
        this.resize();
      },
      immediate: true
    },
    showFitler: {
      handler(v) {
        this.$nextTick(() => {
          this.resize();
        });
      }
    },
    searchItems: {
      handler(v) {
        if (this.isDesign) {
          this.setLayout();
          this.$nextTick(() => {
            this.resize();
          });
        }
      }
    }
  },
  created() {
    this.searchTips = dsf.storage.local.get("searchTips") || "";

    // window.addEventListener("resize", this.resize);
    document.addEventListener("click", this.documentClick);
    // this.setLayout();
    this.unlayoutWatch = this.$watch(
      function () {
        return this.defaultRowsNumber + "-" + this.valueChangeTrigger;
      },
      {
        handler(v) {
          this.initRowsNumber = this.defaultRowsNumber;
          this.setLayout();
          this.$nextTick(() => {
            this.resize();
          });
        }
      }
    );
  },
  mounted() {
    // this.setInitHeight();
    // this.optionsMap = await this.updateData();
  },
  beforeDestroy() {
    // window.removeEventListener("resize", this.resize);
    document.removeEventListener("click", this.documentClick);
    this.unlayoutWatch && this.unlayoutWatch();
  },
  methods: {
    documentClick() {
      this.filterAllClose();
    },
    getFilterBar() {
      let filter = [];
      // let value = this.value;
      _.each(this.searchItems, (el) => {
        let val = el.values ? el.values : null; //value ? value[el.field] : null;
        if (dsf.isDef(val)) {
          let v = dsf.type(val) == "array" ? val : [val];
          v = _.filter(v, (el) => el);
          if (v.length > 0) {
            filter.push(el);
          }
        }
      });
      return filter;
    },
    removeFilter(item) {
      if (item.type == "enum") {
        item.values = [];
        item.customerBetween = [];
        item.treeNodeSelected = [];
      } else if (item.type == "datetime" || item.type == "number") {
        if (item.operator == "between") {
          item.values = [];
        } else {
          item.values = "";
        }
      } else if (item.type == "text") {
        item.values = "";
      }
      this.postSearch(true);
    },
    filterAllClose(exclude) {
      _.each(this.searchItems, (v) => {
        if (v !== exclude) {
          v.isFilterExtend = false;
        }
      });
    },
    filterClose(item) {
      item.isFilterExtend = false;
      item.multiple = false;
    },
    triggerSearch() {
      this.postSearch(true);
      this.$nextTick(() => {
        this.closeOrOpen(false);
      });
    },
    reset(evt) {
      try {
        _.each(this.layout, (it) => {
          it.values = it._defaultValue || null;
        });
      } catch (ex) {
        dsf.error(ex);
      }
      this.postSearch(true);
    },
    async updateSearchItemSetting() {
      this.searchItems = _.map(this.searchItemSetting, (el) => {
        //如果强制展开，isMore和isExtend强制赋值
        // if (el.isTileCollapse === false) {
        //   el.isExtend = true;
        //   el.isMore = true;
        // }
        let isExtend = false;
        let isMore = false;
        let multiple = false;
        if (el.model == "tile" || el.model == "treeTile") {
          if (el.isTileCollapse === false) {
            isExtend = true;
          }
          if (el.isTileForceMulti) {
            multiple = true;
          }
        }
        return {
          //元数据标识
          id: el.id,
          //字段名称
          field: el.field,
          //字段描述文本
          label: el.fieldCaption,
          //高级检索配置内容，比如数据字典等
          valueSource: el.setting,
          //操作符号
          operator: el.operator,
          //检索类型
          type: el.searchType,
          //绑定数据字典的key
          optionKey: el.id || "",
          //用户选择的值
          options: [],
          //树形结构选中存储
          treeNodeSelected: [],
          //默认值
          defaultValue: el.defaultValue,
          //提交给后端的值
          values: null,
          //显示方式，文本框、日期等
          showModel: el.model,
          //是否点击了更多展开
          isExtend: isExtend,
          //是否有更多按钮
          isMore: isMore,
          //是否点击多选后展开
          // isClickMultiple: false,
          //是否多选状态
          multiple: multiple,
          //是否允许多选按钮
          isAllowMultipe: el.searchType !== "enum" || ["between", "eq"].indexOf(el.operator) === -1,
          //布局
          layout: el.layout || 24,
          //区间选择是自定义输入内容
          customerBetween: [],
          //是否是筛选条展开
          isFilterExtend: false,
          //字段
          fields: _.filter(el.children, (c) => c.join == 1),
          // 是否断行
          isLineBreak: el.isLineBreak || false,
          // 级联子级字段名
          childField: el.childField || null,
          // 是否自动隐藏
          autoHide: el.autoHide || false,
          placeholder: el.placeholder || "",
          //平铺模式是否显示“全部”
          showTileAll: dsf.isDef(el.showTileAll) ? el.showTileAll : true,
          //平铺模式是否默认折叠
          isTileCollapse: dsf.isDef(el.isTileCollapse) ? el.isTileCollapse : true,
          //平铺模式是否强制只能多选
          isTileForceMulti: dsf.isDef(el.isTileForceMulti) ? el.isTileForceMulti : false
        };
      });
      this.optionsMap = await this.updateData();
      //将options选项值赋予到每个相关的item中
      _.each(this.searchItems, (el, key) => {
        if (this.optionsMap[el.id]) {
          el.options = this.optionsMap[el.id];
        }
        let defaultValue = null;
        if (dsf.isDef(el.defaultValue) && el.defaultValue != "") {
          if (!this.isDesign) {
            let varb = this.$expressParams(null,{
              item: el
            })
            try {
              defaultValue = this.owner.$eval(el.defaultValue, varb);
            } catch (ex) {
              dsf.error(ex);
              dsf.layer.message("默认值表达式【" + el.defaultValue + "】计算错误，请使用正确的js语法", false);
            }
          }
        }
        if (el.type == "enum") {
          if (dsf.isDef(defaultValue)) {
            defaultValue = dsf.type(defaultValue) != "array" ? [defaultValue] : defaultValue;
          } else {
            defaultValue = [];
          }
          defaultValue = _.map(defaultValue, (v) => v + "");
        } else if (el.type == "datetime") {
          if (el.operator == "between") {
            if (!dsf.isDef(defaultValue)) {
              defaultValue = [];
            } else if (dsf.isString(defaultValue)) {
              defaultValue = [defaultValue, defaultValue];
            } else if (!dsf.isArray(defaultValue)) {
              defaultValue = [];
            }
          }
        } else {
          defaultValue = dsf.isDef(defaultValue) ? defaultValue + "" : "";
        }
        el._defaultValue = defaultValue;
        el.values = defaultValue;
      });

      this.setLayout();
      this.loaded = true;
      this.$nextTick(() => {
        if (!this.isFirstLoad) {
          this.isFirstLoad = true;
          this.resize();
          let v = this.emitValue();
          this.$nextTick(() => {
            this.$emit("loaded", v);
          });
        }
      });
    },
    async updateData() {
      if (this.owner.isDesign) {
        return {};
      }
      let optionsMap = {};
      let optionsRequests = [];
      let dictArr = [];
      _.each(this.searchItems, (v) => {
        if (v.type == "enum") {
          if (v.valueSource?.type == "dict") {
            let params = {
              key: v.id,
              dictId: v.valueSource.value.key
            };
            dictArr.push(params);
          } else if (v.valueSource?.type == "dbsource") {
            let path = v.valueSource.value.key.replace(/\./g, "/");
            optionsRequests.push({
              id: v.id,
              request: (params) => {
                let p = {
                  ...(this.owner?.queryString || {}),
                  ...(params || {})
                };
                return this.$webAPI.dbSourceInterface(p, path, true);
              }
            });
          } else if (v.valueSource?.type == "interface") {
            optionsRequests.push({
              id: v.id,
              request: (params) => {
                let p = {
                  ...(this.owner?.queryString || {}),
                  ...(params || {})
                };
                return this.$http.get(this.$replace(v.valueSource.value), p, true);
              }
            });
          } else {
            if (v.valueSource && v.valueSource.value) {
              optionsMap[v.id] = v.valueSource.value;
            } else {
              optionsMap[v.id] = [];
            }
          }
        }
      });
      if (dictArr.length > 0) {
        let params = {
          dicts: dictArr
        };
        optionsRequests.unshift({
          id: "dicts",
          request: () => {
            return this.$webAPI.getBatchDict(params, true);
          }
        });
      }
      try {
        let optionsMap2 = await this.loopOptionsHttps(optionsRequests);
        return dsf.mix(optionsMap, optionsMap2, {});
      } catch (err) {
        // dsf.layer.message("查询检索选项出现错误", false);
        dsf.layer.pc.message("查询检索选项出现错误,页面无法创建", false);
      }
    },
    loopOptionsHttps(requests, callback) {
      let optionsMap = {};
      let p = new Promise((reslove, reject) => {
        let index = -1;
        let next = function () {
          index++;
          if (index >= requests.length) {
            reslove(optionsMap);
            return;
          }
          if (requests.length > 0) {
            exec();
          }
        };
        function exec() {
          let opts = requests[index];
          opts
            .request()
            .then(({ data }) => {
              if (opts.id == "dicts") {
                for (let k in data.data) {
                  optionsMap[k] = data.data[k];
                }
              } else {
                optionsMap[opts.id] = data.data;
              }
              if (callback) {
                callback(
                  {
                    request: requests[index],
                    data: data.data
                  },
                  next
                );
              } else {
                next();
              }
            })
            .catch((ex) => {
              reject();
            });
        }
        next();
      });
      return p;
    },
    getTotalRowsNumber() {
      let total = 0,
        breakLineNum = 0;
      _.each(this.searchItemSetting, (el, i) => {
        total += ~~el.layout;
        if (el.isLineBreak && i < this.searchItemSetting.length - 2) {
          breakLineNum++;
        }
      });
      let row_num = Math.ceil(total / 24);
      return row_num + breakLineNum;
    },
    //关闭检索项展开状态
    collapseItems(exclude) {
      _.each(this.searchItems, (item) => {
        if (exclude !== item) {
          // item.isExtend = true;
          item.multiple = false;
          // item.isExtend = false;
          // item.isClickMultiple = false;
        }
      });
    },
    //动态计算布局
    setLayout() {
      let curRowIndex = 0;
      let defaultRowsNumber = parseInt(this.defaultRowsNumber);
      this.totalRowsNumber = 0;
      this.initRowsNumber = defaultRowsNumber;
      let realInitRowsNumber = defaultRowsNumber;
      let rowMaxCells = 24;
      let temp = 0;
      let arr = [];
      let matrix = [];
      //汇总布局总占用单元格数量
      _.each(this.searchItems, (el) => {
        el.layout = ~~el.layout;
        temp += el.layout;
      });
      //计算布局占用的总航数
      let rowsNumber = Math.ceil(temp / rowMaxCells);
      if (rowsNumber <= defaultRowsNumber) {
        realInitRowsNumber = rowsNumber;
      }
      temp = 0;
      //将searchItems转化为二维数组来描述布局结构
      for (let i = 0; i < this.searchItems.length; i++) {
        let el = this.searchItems[i];
        if (temp + el.layout > rowMaxCells) {
          temp = el.layout;
          curRowIndex++;
        } else {
          temp += el.layout;
        }
        if (!matrix[curRowIndex]) {
          matrix[curRowIndex] = [];
        }
        matrix[curRowIndex].push(el);
      }
      //获取最后一行是否有间隙,计算展开状态下按钮占用宽度
      temp = 0;
      let lastRowsCells = matrix[matrix.length - 1];
      temp = _.sum(_.map(lastRowsCells, "layout"));
      if (temp >= rowMaxCells) {
        this.extendButtonLayout = 24;
      } else {
        this.extendButtonLayout = rowMaxCells - temp;
      }

      let gaps = [];
      for (let i = 0; i < realInitRowsNumber; i++) {
        let row = matrix[i];
        let layouts = _.map(row, "layout");
        let sum = _.sum(layouts);
        gaps[i] = sum >= rowMaxCells ? 0 : 1;
      }
      let isInsertButton = false;
      //是否有插入按钮的间隙
      let hasGap = _.findIndex(gaps, (it) => it == 1) >= 0 ? true : false;
      if (this.initRowsNumber <= realInitRowsNumber || hasGap) {
        isInsertButton = true;
      }
      if (isInsertButton) {
        //计算折叠状态下按钮的位置
        for (let c = 0; c < realInitRowsNumber; c++) {
          let row = matrix[c];
          let sum = 0;
          _.each(row, (it) => {
            sum += it.layout;
          });
          if (sum < rowMaxCells) {
            this.buttonCell.layout = rowMaxCells - sum;
            row.push(this.buttonCell);
            break;
          } else if (sum == rowMaxCells) {
            if (c >= realInitRowsNumber - 1) {
              //当前行有内容
              if (row.length > 1) {
                this.buttonCell.layout = row[row.length - 1].layout;
                row.splice(row.length - 1, 0, this.buttonCell);
              } else {
                this.buttonCell.layout = rowMaxCells;
                matrix.splice(c + 1, 0, [this.buttonCell]);
              }
              break;
            }
          }
        }
      } else {
        this.buttonCell.layout = 24;
        matrix.push([this.buttonCell]);
      }

      //拉平二位数组，将布局重新计算一次（包含了折叠状态下的按钮）
      arr = _.flatMap(matrix);
      //如果是按钮触发则需要插入一个按钮组
      temp = 0;
      curRowIndex = 0;
      let cells = [];
      for (let k = 0; k < arr.length; k++) {
        let el = arr[k];
        if (this.valueChangeTrigger && el.type == "buttons") {
          continue;
        }
        if (temp + el.layout > rowMaxCells) {
          curRowIndex++;
          temp = el.layout;
        } else {
          temp += el.layout;
        }
        el.rowIndex = curRowIndex;
        cells.push(el);
      }

      this.totalRowsNumber = curRowIndex + 1;
      if (this.totalRowsNumber < this.defaultRowsNumber) {
        this.initRowsNumber = this.totalRowsNumber;
      } else {
        this.initRowsNumber = this.defaultRowsNumber;
      }
      this.layout = cells;
    },
    valueChange(evt, item) {
      this.postSearch();
    },
    emitValue() {
      let value = {};
      _.each(this.searchItems, (el) => {
        if (el.type == "datetime") {
          let baseformat = "yyyy-mm-dd";
          const yearFormat = "yyyy";
          if (el.operator == "between") {
            let arr = dsf.type(el.values) == "array" ? el.values : [el.values];
            arr = _.filter(arr, (v) => v);
            value[el.field] = _.map(arr, (v, i) => {
              if (el.showModel == "ym") {
                if (i == 1) {
                  let m = v.getMonth() + 1;
                  let y = v.getFullYear();
                  let d = new Date(y, m, 0);
                  //将结束日期格式化到月最后一天
                  return dsf.date.format(d, baseformat);
                }
              } else if (el.showModel == "y") {
                if (i == 1) {
                  let y = v.getFullYear();
                  let d = new Date(y, 12, 0);
                  //将结束日期格式化到月最后一天
                  return dsf.date.format(d, baseformat);
                }
              }
              return dsf.date.format(v, baseformat);
            });
          } else if (el.operator == "gt" || el.operator == "lteq") {
            let val = dsf.type(el.values) == "array" ? el.values[0] : el.values;
            if (el.showModel == "ym") {
              if (val) {
                let m = val.getMonth() + 1;
                let y = val.getFullYear();
                let d = new Date(y, m, 0);
                value[el.field] = val ? dsf.date.format(d, baseformat) : "";
              } else {
                value[el.field] = "";
              }
            } else if (el.showModel == "y") {
              if (val) {
                let y = val.getFullYear();
                let d = new Date(y, 12, 0);
                value[el.field] = dsf.date.format(d, baseformat);
              } else {
                value[el.field] = "";
              }
            } else {
              value[el.field] = val ? dsf.date.format(val, baseformat) : "";
            }
          } else if (el.operator == "gteq" || el.operator == "lt") {
            if (el.showModel == "ym") {
              if (el.values) {
                let m = el.values.getMonth();
                let y = el.values.getFullYear();
                let d = new Date(y, m, 1);
                value[el.field] = el.values ? dsf.date.format(d, baseformat) : "";
              } else {
                value[el.field] = "";
              }
            } else if (el.showModel == "y") {
              if (el.values) {
                let y = el.values.getFullYear();
                let d = new Date(y, 0, 1);
                value[el.field] = dsf.date.format(d, baseformat);
              } else {
                value[el.field] = "";
              }
            } else {
              value[el.field] = el.values ? dsf.date.format(el.values, baseformat) : "";
            }
          } else if (el.operator == "eq") {
            let val = dsf.type(el.values) == "array" ? el.values[0] : el.values;
            if (el.showModel == "ym") {
              if (val) {
                let m = val.getMonth() + 1;
                let y = val.getFullYear();
                let d = new Date(y, m, 0);
                value[el.field] = val ? dsf.date.format(d, baseformat) : "";
              } else {
                value[el.field] = "";
              }
            } else if (el.showModel == "y") {
              value[el.field] = val ? dsf.date.format(val, yearFormat) : "";
            } else {
              value[el.field] = val ? dsf.date.format(val, baseformat) : "";
            }
          }
        } else if (el.type == "number") {
          if (el.operator == "between") {
            if (el.values[0] == dsf.enums.numberBoundary.MIN && el.values[1] == dsf.enums.numberBoundary.MAX) {
              value[el.field] = null;
            } else {
              value[el.field] = el.values;
            }
          } else {
            value[el.field] = el.values;
          }
        } else if (el.type == "enum") {
          if (el.operator == "between") {
            if (el.customerBetween.length > 0 && el.customerBetween[0] && el.customerBetween[1]) {
              value[el.field] = el.customerBetween;
            } else {
              let arr = el.values[0] ? el.values[0].split(";") : "";
              if (arr.length == 2) {
                value[el.field] = arr;
              } else if (arr.length == 1) {
                value[el.field] = [arr[0], arr[0]];
              } else {
                value[el.field] = [];
              }
            }
          } else if (el.operator == "like" || el.operator == "slike" || el.operator == "elike") {
            let arr = dsf.type(el.values) == "array" ? el.values : [el.values];
            value[el.field] = arr.join(" ");
          } else {
            let arr = dsf.type(el.values) == "array" ? el.values : [el.values];
            let result = _.filter(arr, (v) => {
              return dsf.isDef(v) && v !== "";
            });
            value[el.field] = result;
          }
          // if (el.showModel == 'treeselect') {
          //   let treePath = value[el.field];
          //   value[el.field] = _.map(treePath, (el) => {
          //     return el[el.length - 1];
          //   })
          // }
        } else if (el.type == "text") {
          let vstr = "";
          if (el.operator == "between") {
            vstr = (el.values || []).join("");
          } else {
            vstr = el.values;
          }
          value[el.field] = vstr ? el.values : "";
        } else {
          value[el.field] = el.values;
        }
      });
      for (let k in value) {
        if (dsf.isEmptyObject(value[k])) {
          delete value[k];
        }
      }
      return value;
    },
    postSearch(istrigger) {
      let value = this.emitValue();
      if (istrigger) {
        this.$nextTick(() => {
          this.$emit("change", value);
        });
      } else {
        if (this.valueChangeTrigger) {
          this.$nextTick(() => {
            this.$emit("change", value);
          });
        }
      }
    },
    getValue() {
      return this.emitValue();
    },
    emitHeight() {
      // let height = this.$el.offsetHeight;
      // this.$emit("change-height", height);
      let el = $(this.$el);
      if (el.length) {
        let filter = el.find(".ds-super-search-fitler");
        let extend = el.find(".ds-super-search-extend");
        let baseHeight = 0;
        this.staticHeight = 0;
        this.realHeight = 0;
        this.mainHeight = 0;
        if (filter) {
          baseHeight += filter.outerHeight() || 0;
        }
        if (extend) {
          baseHeight += extend.outerHeight() || 0;
        }
        let main = el.find(".ds-super-search-main");
        let rows = main.find(".where-row");
        // let buttonbar = main.find(".search-button-bar");
        if (rows.length) {
          let arr = [];
          let items = rows.find(".seach-item:visible");
          arr = _.map(items, (it, i) => items[i]);
          // for (let i = 0; i < items.length; i++) {
          //   arr.push(items[i]);
          // }
          let rowHeight = [];
          let max = 24;
          let temp = 0;
          let currRowIndex = 0;
          for (let i = 0; i < arr.length; i++) {
            let r = $(arr[i]);
            let span = parseInt(r.attr("span"));
            if (temp + span > max) {
              currRowIndex++;
              temp = 0;
            }
            temp += span;
            let h = r.outerHeight();
            let rowIndex = currRowIndex;//r.attr("row-index");
            if (dsf.isUnDef(rowHeight[rowIndex])) {
              rowHeight[rowIndex] = 0;
            }
            if (h > rowHeight[rowIndex]) {
              rowHeight[rowIndex] = h;
            }
          }
          _.each(rowHeight, (it, i) => {
            if (i <= this.initRowsNumber - 1) {
              this.staticHeight += it || 0;
            }
          });
          if (this.isExtend) {
            this.mainHeight = rows.outerHeight();
          } else {
            this.mainHeight = this.staticHeight;
          }
        }
        this.staticHeight += baseHeight;
      }
    },
    resize() {
      if (this.loaded) {
        _.each(this.searchItems, (el) => {
          let elm = this.$el.querySelector("[item-key='" + el.id + "']");
          if (elm) {
            let block = elm.querySelector(".ds-super-search-item-block");
            let optionbox = elm.querySelector(".ds-super-search-item-option-box");
            if (block && optionbox) {
              let blockHeight = block.offsetHeight;
              let optionHeight = optionbox.offsetHeight;
              if (!el.isExtend) {
                //如果是折叠
                el.isMore = optionHeight > blockHeight + 10 ? true : false;
              } else {
                //如果是展开
                el.isMore = optionHeight > this.rowHeight + 10 ? true : false;
              }
            }
          }
        });
        this.$nextTick(() => {
          this.emitHeight();
        });
      }
    },
    extendHandler() {
      this.isClickExtend = true;
      this.closeOrOpen(!this.isExtend);
    },
    closeOrOpen(isExtend) {
      if (!this.owner.isDesign) {
        this.isExtend = isExtend;
        this.setLayout();
        this.$emit("extend", this.isExtend);
        this.$nextTick(() => {
          this.resize();
          // let mark = $(this.$refs.mark);
          // if (mark.length) {
          //   let container = $(this.owner.$el);
          //   var top = mark.offset().top;
          //   var left = mark.offset().left;
          //   var height = container.innerHeight();
          //   var width = container.innerWidth();
          //   mark.css("height", `calc(${this.unitSize(height)} - ${this.unitSize(top)})`);
          // }
        });
      }
    },
    getRowLayoutSum(row) {
      let sum = 0;
      for (let i = 0; i < row.length; i++) {
        let el = row[i];
        sum += el.layout;
      }
      return sum;
    }
  }
};
</script>
