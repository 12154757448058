var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-home-template1-menu" },
    _vm._l(_vm.menuList, function (item) {
      return _c("home-menu-item", {
        key: item.menu_id,
        attrs: {
          menu: item,
          width: _vm.menuWidth,
          active: item.menu_id === _vm.activeKey,
          "last-level-click": _vm.lastLevelClick,
        },
        on: {
          click: function ($event) {
            return _vm.linkTo(item)
          },
        },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }