<template>
  <div class="dsf-linear-gradual-select">
    <div class="dsf-linear-gradual-select__main">
      <div class="dsf-linear-gradual-select__pane">
        <div :style="paneStyle"></div>
      </div>
      <dsf-angle-select class="dsf-linear-gradual-select__angle" v-model="angle" @change="updateValue" />
    </div>
    <color-slider v-model="point" class="dsf-linear-gradual-select__bar" @change="updateValue" />
  </div>
</template>

<script>
import ColorSlider from './ColorSlider';

// 线性渐变选择器
export default {
  name: "DsfLinearGradualSelect",
  components: {
    ColorSlider
  },
  props: {
    value: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      // 角度
      angle: 0,
      point: [
        {length: 0, color: 'rgba(255,255,255,0)'},
        {length: 100, color: '#000'},
      ]
    }
  },
  computed: {
    paneStyle() {
      let str = this.point.map(({length, color}) => `${color} ${length}%`).join(',');
      return {
        'background': `linear-gradient(${this.angle}deg, ${str})`
      }
    }
  },
  watch: {
    'value': {
      deep: true,
      handler(to) {
        this.init(to)
      }
    }
  },
  created() {
    this.init();
  },
  methods: {
    init(to = this.value) {
      if (!to) {
        to = {
          angle: 0,
          point: [
            {length: 0, color: 'rgba(255,255,255,0)'},
            {length: 100, color: '#000'},
          ]
        }
      }
      this.angle = to.angle || 0;
      this.point = to.point;
    },
    updateValue() {
      let {angle, point} = this;
      let value = {angle, point};
      this.$emit('input', value);
      this.$emit('change', value);
    }
  }
}
</script>