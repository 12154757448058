var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isDesign || (_vm.sPID && _vm.sCurLinkID)
    ? _c(
        "div",
        {
          staticClass: "ds-control ds-form-item ds-textbox",
          class: _vm.getCss,
        },
        [
          _vm.showLabel
            ? _c(
                "DsfFieldLabel",
                {
                  style: _vm.getLabelWidthStyle(),
                  attrs: {
                    mode: _vm.showDataCaptionMode,
                    "show-icon": _vm.showDataCaptionIcon,
                    trigger: _vm.showDataCaptionTrigger,
                    "data-caption": _vm.dataCapion,
                    "is-design": _vm.isDesign,
                    owner: _vm._self,
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t(_vm.label)) + " ")]
              )
            : _vm._e(),
          _c(
            "div",
            {
              staticClass: "ds-form-block",
              style: _vm.getFormItemBlockMarginLeft(),
            },
            [
              _c(
                "div",
                { staticClass: "dis-eara" },
                [
                  _c("el-input", {
                    class: {
                      "one-btns": _vm.visibleUpBtn || _vm.visibleOptionsBtn,
                      "two-btns": _vm.visibleUpBtn && _vm.visibleOptionsBtn,
                    },
                    attrs: {
                      placeholder: _vm.placeholder,
                      type: _vm.isTextarea ? "textarea" : "text",
                      rows: _vm.textareaRows,
                      disabled: _vm.disabled,
                    },
                    model: {
                      value: _vm.sOpinion.Content,
                      callback: function ($$v) {
                        _vm.$set(_vm.sOpinion, "Content", $$v)
                      },
                      expression: "sOpinion.Content",
                    },
                  }),
                  _c(
                    "div",
                    { staticClass: "dis-btns" },
                    [
                      _vm.visibleUpBtn
                        ? _c("i", {
                            staticClass: "iconfont icon-cloud-upload",
                            attrs: { title: "上传附件" },
                            on: { click: _vm.upload },
                          })
                        : _vm._e(),
                      _c(
                        "el-popover",
                        {
                          attrs: {
                            placement: "right",
                            width: "360",
                            trigger: "click",
                          },
                          model: {
                            value: _vm.showOptions,
                            callback: function ($$v) {
                              _vm.showOptions = $$v
                            },
                            expression: "showOptions",
                          },
                        },
                        [
                          _c(
                            "ul",
                            { staticClass: "temp-list" },
                            _vm._l(_vm.options, function (option, index) {
                              return _c(
                                "li",
                                {
                                  key: index,
                                  staticClass: "item",
                                  attrs: { title: option.key_word },
                                  on: {
                                    click: function ($event) {
                                      return _vm.setValue(option)
                                    },
                                  },
                                },
                                [_vm._v(" " + _vm._s(option.key_word) + " ")]
                              )
                            }),
                            0
                          ),
                          _vm.visibleOptionsBtn
                            ? _c("i", {
                                staticClass: "iconfont icon-detail temp-icon",
                                attrs: { slot: "reference", title: "一键模板" },
                                slot: "reference",
                              })
                            : _vm._e(),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("DsfUploadFile", {
                ref: "uploadFile",
                attrs: { "hide-btns": true },
                on: { "value-change": _vm.getValue },
              }),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }