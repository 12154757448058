<!--
 * @Descripttion: 折线柱图混合
 * @Author: zhanghang
 * @Date: 2021-07-28 17:25:17
 * @LastEditors: zhanghang
 * @LastEditTime: 2022-03-11 11:15:31
-->
<template>
  <div class="ds-control ds-chart" :style="{ width, height }">
    <div class="chart" ref="chart">我是折线柱图混合</div>
    <template v-if="isDesign||(!isDesign&&slots[0].controls.length>0)">
      <div
        slot-name="default"
        class="ds-chart-slot"
      >
        <slot name="default"></slot>
      </div>
    </template>
  </div>
</template>
<script>
import defaultOptions from "../../output/chartDefault/mixinAxis.js";
defaultOptions.legend.data = defaultOptions.series.map((item) => item.name);
const cloneDefault = _.cloneDeep(defaultOptions);

export default dsf.component({
  name: "DsfPlatformMixinAxis",
  ctrlCaption: "轴系混合图表",
  mixins: [$mixins.layout, $mixins.chart],
  props: {
    //自定义配置总览
    chart: {
      type: Object,
      default() {
        return defaultOptions;
      },
    },
  },
  data() {
    return {
      default: {
        line: cloneDefault.series.find((item) => item.type === "line"),
        bar: cloneDefault.series.find((item) => item.type === "bar"),
      },
    };
  },
  methods: {
    //数据源分析
    dataSourceAnalysis(data){
      let ydata = []
      let xdata = []
      const chart = _.cloneDeep(this.chart);
      data.forEach((v,i)=>{
        let index = Number(v.xIndex)
        // 依赖关系
        let o = {
          name:v.category,
          yAxisIndex: Number(v.yIndex),
          xAxisIndex: Number(v.xIndex),
          type:v.type,
          data:[]
        }
        const mapRelation =  chart.xAxis[index].mapRelation
        for(let key of mapRelation){
          o.data.push(v[key])
        }
        ydata.push(o)
      })
      this.analysis({
        ydata,
        xdata
      })
    },
    analysis(analysisdata) {
      let { xdata, ydata } = analysisdata;
      const arr = ydata.reduce((arr, item) => {
        arr.push(item.name);
        return arr;
      }, []);
      this.chart.legend.data = arr;

      let linedata = ydata.filter((item) => item.type === "line");
      let bardata = ydata.filter((item) => item.type === "bar");

      let seriesLine = this.chart.series.filter((item) => item.type === "line");
      let seriesBar = this.chart.series.filter((item) => item.type === "bar");

      linedata.forEach((item, index) => {
        if (seriesLine[index]) {
          let s = Object.assign(seriesLine[index], item);
          seriesLine[index] = s
        } else {
          let data = {};
          data = _.cloneDeep(this.default.line);
          data.areaStyle.color = "rgba(255,255,255,0)";
          data.areaStyle.startColor = "rgba(255,255,255,0)";
          data.areaStyle.endColor = "rgba(255,255,255,0)";
       
          data.data = item.data;
          data.name = item.name;
          data.itemStyle.color = this.chart.color[index + 1];
          data.itemStyle.startColor = this.chart.color[index + 1];
          data.itemStyle.endColor = this.chart.color[index + 1];

          data.xAxisIndex=item.xAxisIndex||0;
          data.yAxisIndex=item.yAxisIndex||0;

          seriesLine.push(data)
        }
      });
      seriesLine = seriesLine.slice(0,linedata.length);

      bardata.forEach((item, index) => {
        if (seriesBar[index]) {
          let s = Object.assign(seriesBar[index], item);
          seriesBar[index] = s
        } else {
          let data = {};
          data = _.cloneDeep(this.default.bar);

       
          data.data = item.data;
          data.name = item.name;
          data.itemStyle.color = this.chart.color[index + 1];
          data.itemStyle.startColor = this.chart.color[index + 1];
          data.itemStyle.endColor = this.chart.color[index + 1];


          data.xAxisIndex=item.xAxisIndex||0;
          data.yAxisIndex=item.yAxisIndex||0;

          seriesBar.push(data)
        }
      });
      seriesBar = seriesBar.slice(0,bardata.length);

      this.chart.series=[...seriesBar,...seriesLine]

      if(xdata.length){
        this.chart.xAxis.forEach((v,i)=>{
          this.chart.xAxis[i].data = xdata[i];
        }) 
      }
      this.reset();
    },
  },
});
</script>

