export default {
  install(Vue){
    Vue.filter('emptyFilter',(v, text = '')=>{
      if (typeof v === 'function' ||
      dsf.type(v) === 'date') return v;

      if (dsf.isUnDef(v) || dsf.isEmptyObject(v)) {
        return text;
      }
      return v;
    })
  }
}