// import DsfLayoutTree from "./layoutTree";
// import DsfLayoutTreeFn from "./layoutTreeFn";
// import DsfLayoutView from "./layoutView";
import DsfPreView from './layoutPreView.vue'
import DsfMobilePreView from './layoutMobilePreView.vue'
import DsfComponentInfo from './componentInfo.vue'
import DsfIcon from './icon.js';
import DsfTextProxy from './textProxy.js'
import SendFlowDialog from "./sendFlowDialog";
import textContentEditable from './textContentEditable.vue';
import DsfRepeatProxy from './repeatProxy.js';
import DsfToDesigner from './toDesigner.vue'
import DsfUserInfoWaterMark from './userInfoWaterMark.vue'
import DsfFieldLabel from './fieldCaption'
import DsfEmptyData from './emptyData.vue'

function install(Vue) {
  // Vue.component(DsfLayoutTree.name, DsfLayoutTree);
  Vue.component(DsfPreView.name, DsfPreView);
  Vue.component(DsfMobilePreView.name, DsfMobilePreView);
  Vue.component(DsfComponentInfo.name, DsfComponentInfo);
  Vue.component(DsfTextProxy.name, DsfTextProxy);
  Vue.component(DsfIcon.name, DsfIcon);
  Vue.component(SendFlowDialog.name, SendFlowDialog);
  Vue.component(textContentEditable.name, textContentEditable);
  Vue.component(DsfRepeatProxy.name, DsfRepeatProxy);
  Vue.component(DsfToDesigner.name, DsfToDesigner);
  Vue.component(DsfUserInfoWaterMark.name, DsfUserInfoWaterMark);
  Vue.component(DsfFieldLabel.name, DsfFieldLabel);
  Vue.component(DsfEmptyData.name, DsfEmptyData);
}

if (typeof window !== "undefined" && window.Vue) {
  install(window.Vue);
}

export {
  install,
  DsfIcon,
  DsfTextProxy,
  // DsfLayoutTree,
  DsfPreView,
  DsfMobilePreView,
  DsfComponentInfo,
  DsfToDesigner,
  DsfFieldLabel,
  DsfUserInfoWaterMark,
  DsfEmptyData,
};

export default {
  install
};