<template>
  <component
    class="dsf-div-editor"
    :is="tag"
    :placeholder="placeholder"
    contenteditable
    @click.stop
    @blur="onBlur"
    @focus="onFocus"
    @input="onInput"
  />
</template>

<script>
export default {
  name: "DsfDivEditor",
  props: {
    tag: {
      type: String,
      default: 'div'
    },
    value: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: '请输入'
    }
  },
  watch: {
    value(to) {
      if (this.$el && this.$el.innerHTML !== to) {
        this.$el.innerHTML = to;
      }
    }
  },
  mounted() {
    this.$el.innerHTML = this.value || '';
  },
  methods: {
    onInput() {
      let val = this.$el.innerHTML;
      if (!this.$el.innerText || this.$el.innerText === '\n') {
        val = '';
      }
      this.$emit('input', val);
    },
    onBlur(event) {
      let val = this.value;
      this.$emit('change', val);
      this.$emit('blur', {
        value: val,
        target: this.$el,
        vm: this,
        event
      });
    },
    onFocus(event) {
      let val = this.value;
      this.$emit('focus', {
        value: val,
        target: this.$el,
        vm: this,
        event
      });
    },
    focus() {
      setTimeout(() => {
        this.$el.focus();
      }, 0);
    },
    blur() {
      this.$el.blur();
    }
  }
}
</script>