<template>
  <div class="dsf-color-select">
    <div class="dsf-color-select-main">
      <panel ref="panel" />
      <hue ref="hue" />
    </div>
    <div class="dsf-color-select-main">
      <alpha ref="alpha" v-if="showAlpha" />
    </div>
    <div class="dsf-color-select-input">
      <label>
        <span>R</span>
        <el-input-number
          :value="rgba[0]"
          :min="0"
          :max="255"
          :controls="false"
          size="mini"
          step-strictly
          @change="setColor('r', $event)"
        />
      </label>
      <label>
        <span>G</span>
        <el-input-number
          :value="rgba[1]"
          :min="0"
          :max="255"
          :controls="false"
          size="mini"
          step-strictly
          @change="setColor('g', $event)"
        />
      </label>
      <label>
        <span>B</span>
        <el-input-number
          :value="rgba[2]"
          :min="0"
          :max="255"
          :controls="false"
          size="mini"
          step-strictly
          @change="setColor('b', $event)"
        />
      </label>
      <label>
        <span>A %</span>
        <el-input-number
          :value="rgba[3]"
          :min="0"
          :max="255"
          :controls="false"
          size="mini"
          step-strictly
          @change="setColor('a', $event)"
        />
      </label>
      <label class="str">
        <span>HEX</span>
        <el-input
          v-model="hex"
          size="mini"
          @change="setHex"
        />
      </label>
    </div>
  </div>
</template>

<script>
import Color from './color';
import Hue from './Hue';
import Panel from './Panel';
import Alpha from './Alpha';

// 颜色选择器
export default {
  name: "DsfColorSelect",
  components: {
    Hue,
    Panel,
    Alpha
  },
  props: {
    value: {
      type: String,
      default: 'rgba(255,255,255,0)'
    },
    showAlpha: {
      type: Boolean,
      default: true
    },
    // hsl / hsv / hex / rgb
    colorFormat: {
      type: String,
      default: 'hex'
    }
  },
  data() {
    return {
      color: null,
      valueCopy: '',
      rgba: [255,255,255,100],
      hex: '#FFFFFF'
    }
  },
  computed: {
    options() {
      return {
        enableAlpha: this.showAlpha,
        format: this.colorFormat
      }
    }
  },
  watch: {
    value(to) {
      if (!this.color) return;
      if (to !== this.valueCopy) {
        this.valueCopy = to;
        this.color.fromString(to);
        this.$refs.hue?.updatePoint()
        this.init();
      }
    },
  },
  created() {
    let color = new Color(this.options);
    color.fromString(this.value);
    this.color = color;
    this.valueCopy = this.value;
    this.init();
  },
  methods: {
    init() {
      this.$refs.hue?.updateBg?.();
      this.$refs.panel?.updatePoint()?.updateBg?.();
      this.$refs.alpha?.updatePoint()?.updateBg?.();
      let {r, g, b} = this.color.toRgb();
      this.rgba = [r, g, b, this.color._alpha];
      this.hex = this.color.toHex(r, g, b);
    },
    colorChange() {
      let {value} = this.color;
      this.valueCopy = value;
      this.$refs.panel.updateBg();
      this.$refs.alpha.updateBg();
      this.$emit('input', value);
      this.$emit('change', value);
      let {r, g, b} = this.color.toRgb();
      this.rgba = [r, g, b, this.color._alpha];
      this.hex = this.color.toHex(r, g, b);
    },
    setColor(k, v) {
      let [r, g, b, a] = this.rgba;
      let c = {r, g, b, a};
      c[k] = v;
      this.color.fromString(`rgba(${c.r},${c.g},${c.b},${c.a / 100})`);
      this.$refs.hue.updatePoint()
      this.init();
      let {value} = this.color;
      this.$emit('input', value);
      this.$emit('change', value);
    },
    setHex(hex) {
      if (!/^#(([0-9A-Fa-f]{3})|([0-9A-Fa-f]{6}))$/.test(hex)) {
        let [r,g,b] = this.rgba;
        this.hex = this.color.toHex({r,g,b});
        return;
      }
      this.color.fromString(hex);
      this.color.set('alpha', this.rgba[3]);
      this.$refs.hue?.updatePoint()
      this.init();
      let {value} = this.color;
      this.$emit('input', value);
      this.$emit('change', value);
    }
  }
}
</script>