var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { width: "100%" } },
    [
      _vm.multipe
        ? [
            _c(
              "el-select",
              {
                ref: "select",
                staticClass: "indiction-select tag-no-wrap",
                class: { "tag-focus": _vm.selectHoverFocus },
                staticStyle: { width: "100%", "line-height": "40px" },
                attrs: {
                  "collapse-tags": "",
                  filterable: "",
                  clearable: "",
                  multiple: "",
                  placeholder: "请选择",
                },
                on: {
                  focus: _vm.focus,
                  "visible-change": _vm.visibleChange,
                  change: _vm.change,
                },
                model: {
                  value: _vm.valueArr,
                  callback: function ($$v) {
                    _vm.valueArr = $$v
                  },
                  expression: "valueArr",
                },
              },
              _vm._l(_vm.options, function (op) {
                return _c("el-option", {
                  key: op.value,
                  attrs: { label: op.text, value: op.value },
                })
              }),
              1
            ),
            !_vm.isDesign
              ? _c(
                  "div",
                  {
                    ref: "selectHover",
                    staticClass: "indiction-select-hover",
                    style: _vm.selectHoverStyle,
                    attrs: { tabindex: "0", hidefocus: "true" },
                    on: {
                      focus: function ($event) {
                        _vm.selectHoverFocus = true
                      },
                      blur: function ($event) {
                        _vm.selectHoverFocus = false
                      },
                    },
                  },
                  _vm._l(_vm.selected, function (item) {
                    return _c(
                      "el-tag",
                      {
                        key: item.currentValue,
                        attrs: {
                          type: "info",
                          size: "small",
                          closable: "",
                          "disable-transitions": "",
                        },
                        on: {
                          close: function ($event) {
                            return _vm.deleteTag($event, item)
                          },
                        },
                        nativeOn: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.$selectHover.focus()
                          },
                        },
                      },
                      [
                        _c("span", { staticClass: "el-select__tags-text" }, [
                          _vm._v(_vm._s(item.currentLabel)),
                        ]),
                      ]
                    )
                  }),
                  1
                )
              : _vm._e(),
          ]
        : _c(
            "el-select",
            {
              staticClass: "indiction-select tag-no-wrap",
              staticStyle: { width: "100%", "line-height": "40px" },
              attrs: {
                "collapse-tags": "",
                filterable: "",
                clearable: "",
                placeholder: "请选择",
              },
              on: { focus: _vm.focus, change: _vm.change },
              model: {
                value: _vm.value,
                callback: function ($$v) {
                  _vm.value = $$v
                },
                expression: "value",
              },
            },
            [
              _c("el-option", { key: "", attrs: { label: "全部", value: "" } }),
              _vm._l(_vm.options, function (op) {
                return _c("el-option", {
                  key: op.value,
                  attrs: { label: op.text, value: op.value },
                })
              }),
            ],
            2
          ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }