<template>
  <div v-if="!disabled" class="ds-control ds-virtual-scroll ds-no-padding" :style="rootStyle">
    <div ref="box" class="ds-virtual-scroll-box" :style="boxStyle" slot-name="default" v-on="boxEvent">
      <slot name="default"></slot>
    </div>
    <div
      v-show="scrollX && showXBar"
      ref="xBar"
      class="ds-virtual-scroll-bar __x"
      :class="{down: xType}"
      :style="{width: xBarLength + 'px', transform: `translateX(${x}px)`}"
      @mousedown.self="xDown"
    />
    <div
      v-show="scrollY && showYBar"
      ref="yBar"
      class="ds-virtual-scroll-bar __y"
      :class="{down: yType}"
      :style="{height: yBarLength + 'px', transform: `translateY(${y}px)`}"
      @mousedown.self="yDown"
    />
  </div>
  <div v-else class="ds-control ds-virtual-scroll" :style="rootStyle">
    <div ref="box" class="ds-virtual-scroll-box" slot-name="default" style="overflow: auto">
      <slot name="default"></slot>
    </div>
  </div>
</template>

<script>
export default dsf.component({
  name: "DsfVirtualScroll",
  ctrlCaption: "虚拟滚动盒",
  mixins: [$mixins.layout],
  design: {
    isMask: false
  },
  props: {
    scrollX: {
      type: Boolean,
      default: false
    },
    scrollY: {
      type: Boolean,
      default: true
    },
    height: {
      type: String,
      default: "100%"
    },
    width: {
      type: String,
      default: "auto"
    },
    maxHeight: {
      type: String,
      default: "none"
    },
    slots: {
      type: Array,
      default() {
        return [{ name: "default", controls: [] }];
      }
    }
  },
  data() {
    return {
      disabled: dsf.config.setting_public_virtual_scroll || false,
      x: 0,
      y: 0,
      xType: false,
      yType: false,
      xBarLength: 0,
      yBarLength: 0,
      showXBar: true,
      showYBar: true
    };
  },
  computed: {
    rootStyle() {
      let res = {};
      let width = this.width;
      let height = this.height;
      let maxHeight = this.maxHeight;
      if (width && width !== 'auto' && width !== '100%') {
        res['width'] = width;
      }
      if (height && height !== 'auto' && height !== 'none') {
        res['height'] = height;
      }
      if (maxHeight && maxHeight !== 'auto' && maxHeight !== 'none') {
        res['max-height'] = maxHeight;
      }
      if (this.isDesign) {
        this.$dispatch("design-height-change", height);
        res.height = "100%";
      }
      return res;
    },
    boxStyle() {
      let res = {};
      let maxHeight = this.maxHeight;
      if (maxHeight && maxHeight !== 'auto' && maxHeight !== 'none') {
        res['max-height'] = maxHeight;
      }
      return res;
    },
    boxEvent() {
      let res = {
        scroll: this.onScroll
      };
      if ((this.scrollX || this.scrollY) && !this.xType && !this.yType) {
        // res['DOMMouseScroll'] = this.onDOMMouseScroll;
        // res['mousewheel'] = this.onMousewheel;
        res["wheel"] = this.onWheel;
      }
      return res;
    }
  },
  watch: {
    showXBar: {
      handler(to) {
        this.$emit('show-x-bar', to);
      },
      immediate: true
    },
    showYBar: {
      handler(to) {
        this.$emit('show-y-bar', to);
      },
      immediate: true
    }
  },
  created() {
    if (!this.disabled) {
      this.$updateBar = _.throttle(this.updateBar, 500, {
        leading: false,
        trailing: true
      });
    }
  },
  updated() {
    this.disabled || this.$updateBar();
  },
  mounted() {
    this.animateObjs = [];
    this.$box = this.$refs.box;
    window.addEventListener("mousemove", this.move, false);
    window.addEventListener("mouseup", this.up, false);
    if (dsf.client.type === 'IE10' || window.MutationObserver === void 0) {
      this.$el.onmouseenter = () => {
        this.updateBar();
      };
    } else {
      this.$el.onmouseenter = () => {
        this.$updateBar();
      };
      // 监听容器内部变化
      this.observer = new MutationObserver((mutationList) => {
        this.$updateBar();
      });
      this.observer.observe(this.$box, {
        childList: true,
        attributes: true,
        subtree: true
      });
    }
    this.$updateBar();
  },
  beforeDestroy() {
    window.removeEventListener("mousemove", this.move);
    window.removeEventListener("mouseup", this.up);
    if (dsf.client.type !== 'IE10') {
      this.observer.disconnect();
      this.observer = undefined;
    }
  },
  methods: {
    reloadData() {
      this.$childrenReloadData();
    },
    resize() {
      this.$updateBar();
    },
    updateBar() {
      if (!this.$box) return;
      if (this.scrollX && this.$refs.xBar) {
        this._updateBar("x", "X", "Width", "Left");
      }
      if (this.scrollY && this.$refs.yBar) {
        this._updateBar("y", "Y", "Height", "Top");
      }
    },
    _updateBar(y, Y, Height, Top) {
      let offsetHeight = this.$box["offset" + Height];
      let scrollHeight = this.$box["scroll" + Height];
      let scrollTop = this[y + "Type"] ? this["scroll" + Top] : this.$box["scroll" + Top];
      let yBarLength = this.$refs[y + "Bar"]["offset" + Height];
      let yScale = offsetHeight / scrollHeight;
      if (yScale >= 1) {
        this["show" + Y + "Bar"] = false;
        this[y] = 0;
      } else {
        this["show" + Y + "Bar"] = true;
        yBarLength = Math.floor(yScale * (offsetHeight - 10));
        this[y + "BarLength"] = yBarLength = yBarLength < 30 ? 30 : yBarLength;
        this[y] = Math.floor(((offsetHeight - yBarLength - 10) * scrollTop) / (scrollHeight - offsetHeight));
      }
    },
    onScroll() {
      if (this.scrollX && !this.xType && this.$refs.xBar) {
        this._onScroll("x", "Width", "Left");
      }
      if (this.scrollY && !this.yType && this.$refs.yBar) {
        this._onScroll("y", "Height", "Top");
      }
    },
    _onScroll(y, Height, Top) {
      let offsetHeight = this.$box["offset" + Height];
      let scrollHeight = this.$box["scroll" + Height];
      let scrollTop = this.$box["scroll" + Top];
      let yBarLength = this.$refs[y + "Bar"]["offset" + Height];
      this[y] = Math.floor(((offsetHeight - yBarLength - 10) * scrollTop) / (scrollHeight - offsetHeight));
    },
    xDown(event) {
      const e = event || window.event;
      this.xType = true;
      this.lastX = e.screenX;
      this.scrollLeft = this.$box.scrollLeft;
    },
    yDown(event) {
      const e = event || window.event;
      this.yType = true;
      this.lastY = e.screenY;
      this.scrollTop = this.$box.scrollTop;
    },
    move(event) {
      const e = event || window.event;
      if (this.xType && this.$refs.xBar) {
        this._move(e, "x", "X", "Width", "Left");
      }
      if (this.yType && this.$refs.yBar) {
        this._move(e, "y", "Y", "Height", "Top");
      }
    },
    _move(e, y, Y, Height, Top) {
      let offsetHeight = this.$box["offset" + Height];
      let scrollHeight = this.$box["scroll" + Height];
      let yBarLength = this.$refs[y + "Bar"]["offset" + Height];
      let maxY = offsetHeight - yBarLength - 10;
      let thisY = e["screen" + Y];
      let _y = this[y] + thisY - this["last" + Y];
      this["last" + Y] = thisY;
      if (_y < 0) {
        _y = 0;
      } else if (_y > maxY) {
        _y = maxY;
      }
      this[y] = _y;
      this["scroll" + Top] = this.$box["scroll" + Top] = (_y / maxY) * (scrollHeight - offsetHeight);
    },
    up() {
      this.xType = false;
      this.yType = false;
    },
    // // Firefox滚轮事件
    // onDOMMouseScroll(event) {
    //   const e = event || window.event;
    //   console.log(e)
    //   let delta = e.detail * -1;
    //   if (Math.abs(delta) == 3) {
    //     delta *= 40;
    //   }
    //   this._mouseWheel(delta, e.shiftKey, e);
    // },
    // // 其他浏览器滚轮事件
    // onMousewheel(event) {
    //   const e = event || window.event;
    //   const delta = e.wheelDelta || e.detail;
    //   this._mouseWheel(delta, e.shiftKey, e);
    // },

    // ie 下触摸板无解
    onWheel(e) {
      let deltaX = e.deltaX * -1, deltaY = e.deltaY * -1;
      if (e.shiftKey && deltaY) {
        deltaX = deltaY;
        deltaY = 0;
      }
      // 如果是火狐浏览器，并且不是由鼠标滚轮触发
      // 火狐浏览器e.deltaMode：0触摸板，e.deltaMode：1鼠标
      // 其他浏览器e.deltaMode始终为0
      // 火狐浏览器鼠标delta值3或-3
      // 其他浏览器鼠标delta值100或-100
      if (dsf.client.type === "FF" && e.deltaMode === 1) {
        if (deltaX) deltaX = deltaX < 0 ? -100 : 100;
        if (deltaY) deltaY = deltaY < 0 ? -100 : 100;
      }
      this._mouseWheel(deltaX, deltaY, e);
    },
    _mouseWheel(deltaX, deltaY, e) {
      if (deltaX) {
        this.__mouseWheel(deltaX, e, "X", "Width", "Left");
      }
      if (deltaY) {
        this.__mouseWheel(deltaY, e, "Y", "Height", "Top");
      }
    },
    __mouseWheel(zoom, e, Y, Height, Top) {
      let _sh = this.$box["scroll" + Height] - this.$box["offset" + Height];
      if ((zoom > 0 && this.$box["scroll" + Top] == 0) || (zoom < 0 && _sh == this.$box["scroll" + Top])) {
        this.animateObjs.forEach(obj => obj?.stop());
        this.animateObjs = [];
        return;
      }
      if (this["scroll" + Y] && this["show" + Y + "Bar"]) {
        e.stopPropagation();
        e.preventDefault();
      }

      // 往上
      if (zoom > 0) {
        if (this.$box["scroll" + Top] - zoom < 0) {
          zoom = this.$box["scroll" + Top];
        }
        if (this['direction' + Y] != 'up') {
          this.animateObjs.forEach(obj => obj?.stop());
          this.animateObjs = [];
        }
        this['direction' + Y] = 'up';
      }
      // 往下
      else if (zoom < 0) {
        if (this.$box["scroll" + Top] - zoom > _sh) {
          zoom = this.$box["scroll" + Top] - _sh;
        }
        if (this['direction' + Y] != 'down') {
          this.animateObjs.forEach(obj => obj?.stop());
          this.animateObjs = [];
        }
        this['direction' + Y] = 'down';
      }
      this.animateObjs.push(this._animate(zoom, (n) => {
        this.$box["scroll" + Top] -= n;
      }));
    },
    _animate(l, callback) {
      if (!l) return;
      let ls = 0;
      let obj = $({ n: 0 });
      let duration = Math.abs(l);
      duration = duration > 100 ? 100 : duration;
      if (duration < 50) {
        callback(l);
        return;
      }
      obj.animate(
        { n: l },
        {
          duration,
          easing: "linear",
          step: (s) => {
            let ns = s - ls;
            if (ns) {
              callback(ns);
              ls = s;
            }
          },
          done: () => {
            this.$updateBar();
            dsf.array.remove(this.animateObjs, obj);
            obj = null;
          }
        }
      );
      return obj;
    }
  }
});
</script>