var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "container",
      staticClass: "ds-control ds-project-config",
      style: _vm.boxStyle,
    },
    [
      _c(
        "div",
        { staticClass: "dpc-l", style: _vm.boxStyle },
        [
          _c(
            "dsf-virtual-scroll",
            [
              _c(
                "el-menu",
                {
                  attrs: {
                    "default-active": _vm.activeIndex,
                    "default-openeds": _vm.defaultOpeneds,
                  },
                  on: { select: _vm.handleSelect },
                },
                [
                  _vm._l(_vm.data, function (item) {
                    return [
                      item.type == "1"
                        ? _c(
                            "el-menu-item",
                            { key: item.formId, attrs: { index: item.formId } },
                            [_vm._v(" " + _vm._s(item.name) + " ")]
                          )
                        : _c(
                            "el-submenu",
                            { key: item.formId, attrs: { index: item.formId } },
                            [
                              _c("template", { slot: "title" }, [
                                _vm._v(_vm._s(item.name)),
                              ]),
                              _vm._l(item.children, function (item2) {
                                return [
                                  item2.type == "1"
                                    ? _c(
                                        "el-menu-item",
                                        {
                                          key: item2.formId,
                                          attrs: { index: item2.formId },
                                        },
                                        [_vm._v(" " + _vm._s(item2.name) + " ")]
                                      )
                                    : _c(
                                        "el-submenu",
                                        {
                                          key: item2.formId,
                                          attrs: { index: item2.formId },
                                        },
                                        [
                                          _c("template", { slot: "title" }, [
                                            _vm._v(_vm._s(item2.name)),
                                          ]),
                                          _vm._l(
                                            item2.children,
                                            function (item3) {
                                              return _c(
                                                "el-menu-item",
                                                {
                                                  key: item3.formId,
                                                  attrs: {
                                                    index: item3.formId,
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(item3.name) +
                                                      " "
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                        ],
                                        2
                                      ),
                                ]
                              }),
                            ],
                            2
                          ),
                    ]
                  }),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "dpc-r", style: _vm.boxStyle }, [
        _c("iframe", {
          style: _vm.boxStyle,
          attrs: { width: "100%", src: _vm.pageUrl, frameborder: "0" },
        }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }