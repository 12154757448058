<template>
  <div class="ds-control ds-form-item ds-ueditor" :class="getCss">
    <!-- <label v-if="hasLabel" class="ds-form-label" :style="getLabelWidthStyle()">
      {{ label }}
      <i v-if="dataCapion" class="iconfont icon-bangzhuzhongxin ds-control-helper" @click="showHelper"></i>
    </label> -->
    <DsfFieldLabel v-if="showLabel" :mode="showDataCaptionMode" :style="getLabelWidthStyle()" :show-icon="showDataCaptionIcon" :trigger="showDataCaptionTrigger" :data-caption="dataCapion" :is-design="isDesign" :owner="_self">
      {{ $t(label) }}
    </DsfFieldLabel>
    <div class="ds-form-block" :style="getFormItemBlockMarginLeft()">
      <div style="width: 100%" v-show="!readOnly">
        <div ref="UEditor"></div>
      </div>
      <div v-if="isDesign && readOnly" id="designReadOnly" :class="ueditorCss" v-text="msg"></div>
      <div v-if="!isDesign && readOnly" :class="ueditorCss">
        <div ref="uParse" class="ds-ueditor-readonly" v-html="dsf.safe.xss(editorValue)" :style="readOnlyUParseStyle"></div>
        <div v-if="errors.length > 0" class="ds-error-text">
          {{ errorsMsg }}
        </div>
      </div>
      <div v-if="errors.length > 0 && !readOnly" class="ds-error-text">
        {{ errorsMsg }}
      </div>
    </div>
    <picture-cut-dialog :src.sync="src" :dialog-visible="dialogVisible" @cut-img="cutImg" @dialog-close="dialogClose"></picture-cut-dialog>
  </div>
</template>
<script>
import pictureCutDialog from "_platform/pc/bhc/upload/pictureCutDialog";

export default dsf.component({
  name: "DsfUEditor",
  mixins: [$mixins.formControl],
  ctrlCaption: "富文本",
  components: {
    pictureCutDialog
  },
  props: {
    value: {
      type: String,
      default: ""
    },
    richTxtHeight: {
      type: Number,
      default: 400
    },
    placeholder: {
      type: String,
      default: ""
    },
    metadata: {
      type: Object,
      default() {
        let md = dsf.metadata.get("big-text-meta-data");
        md.valueIsHtml = true;
        return md;
      }
    },
    toolBar: {
      type: Array,
      default() {
        return [
          "fullscreen",
          "undo",
          "redo",
          "bold",
          "italic",
          "underline",
          "removeformat",
          "formatmatch",
          "pasteplain",
          "forecolor",
          "backcolor",
          "insertorderedlist",
          "insertunorderedlist",
          "selectall",
          "cleardoc",
          "rowspacingtop",
          "rowspacingbottom",
          "lineheight",
          "customstyle",
          "paragraph",
          "fontfamily",
          "fontsize",
          "indent",
          "justifyleft",
          "justifycenter",
          "justifyright",
          "justifyjustify",
          "link",
          "unlink",
          "anchor",
          "imagenone",
          "imageleft",
          "imageright",
          "imagecenter",
          "simpleupload",
          "insertimage",
          "insertvideo",
          "inserttable",
          "autotypeset"
        ];
      }
    },
    showLabel: {
      type: Boolean,
      default: true
    },
    maximumWords: {
      type: Number,
      default: 10000
    },
    wordCount: {
      // 是否开启字数统计
      type: Boolean,
      default: true
    },
    wordCountMsg: {
      // 字数统计提示
      type: String,
      default: "当前已输入 {#count} 个字符，您还可以输入{#leave} 个字符"
    },
    valueIsHtml: {
      type: Boolean,
      default: true
    },
    pasteImgByCut: {
      type: Boolean,
      default: false
    },
    // 只读状态下，是否自动高度
    readOnlyAutoHeight: {
      type: Boolean,
      default: false
    },
    // 只读状态下，是否显示名称
    readOnlyShowLabel: {
      type: Boolean,
      default: true
    },
    cssUrl: {
      type: String,
      default: ""
    }
  },
  data() {
    this.ue = null;
    return {
      // ue: null, //ueditor 实例化之后的对象
      ueId: null, //ueditor 的div Id
      uparseId: null, //uParse 容器的 Id
      upFileUrl: "file/upload/",
      //picture-cut-dialog组件的属性
      src: "",
      dialogVisible: false,
      editorValue: this.value,
      ueReady: false,
      setValueSuccess: true,
      v: null,
      timer: null
    };
  },
  computed: {
    hasLabel() {
      if (this.showLabel) {
        if (this.readOnly) {
          return this.readOnlyShowLabel;
        }
        return true;
      }
      return false;
    },
    ueditorCss() {
      return this.hasLabel ? "have-label" : "";
    },
    msg() {
      const txt = "已经启用只读模式，";
      return this.visible ? txt + "可见" : txt + "且不可见";
    },
    readOnlyUParseStyle() {
      if (this.readOnlyAutoHeight) {
        return {
          "line-height": "normal"
        };
      }
      return {
        height: this.unitSize(this.richTxtHeight),
        "line-height": "normal",
        overflow: "auto"
      };
    }
  },
  watch: {
    richTxtHeight(val) {
      this.ue?.setHeight(val);
    },
    //visible 只会在设计器模式下才会发生改变
    visible(val) {
      if (this.ue) {
        val ? this.ue.setEnabled() : this.ue.setDisabled();
      }
    },
    value(v) {
      this.v = v;
      if (!this.ueReady) return;
      let editorValue = this.ue.getContent();
      if (v !== editorValue) {
        this.v = null;
        this.setContent(v);
      }
    },
    //readOnly 只会在设计器模式下才会发生改变
    // "readOnly"(val) {
    //   val ? this.ue.setHide() : this.ue.setShow();
    // },
    toolBar() {
      this.renderUEditor();
    }
  },
  mounted() {
    const self = this;
    //当它是浏览页面且是只读状态
    if (!self.isDesign && self.readOnly) {
      const uparseId = "editor_" + dsf.uuid();
      self.$refs.uParse.id = uparseId;
      self.uparseId = uparseId;
      uparseContent.call(self);
    }

    const id = dsf.uuid();
    self.$refs.UEditor.id = id; //为div赋值id, 用于UEditor渲染
    self.ueId = id;

    if (window.UE) {
      self.renderUEditor();
    } else {
      const fileUrl = dsf.url.getWebPath("$/js/libs/UEditor/ueditor.config.js");
      const configUrl = dsf.url.getWebPath("$/js/libs/UEditor/ueditor.all.js");
      self.$http
        .importFiles([fileUrl, configUrl])
        .then(() => {
          if (self.timer) {
            clearInterval(self.timer);
          }
          self.timer = setInterval(() => {
            if (window.UE) {
              clearInterval(self.timer);
              self.renderUEditor();
            }
          }, 600);
        })
        .catch((err) => {
          console.error("加载UEditor.js文件出错");
        });
    }
  },
  beforeDestroy() {
    // console.log(this.ue.ui.id)
    try {
      if (this.ue) {
        this.ue.destroy();
      }
    } catch (ex) {
      //
    } finally {
      for (let k in window.$EDITORUI) {
        let ui = window.$EDITORUI[k];
        if(ui.className=='edui-dialog-modalmask'){
          continue;
        }
        if (ui.editor == this.ue) {
          ui.dispose();
          ui.editor=null;
          delete window.$EDITORUI[ui.id]
        }
        else if(_.isEmpty(ui.editor)){
          ui.dispose();
          ui.editor=null;
          delete window.$EDITORUI[ui.id]
        }
      }
      window.setTimeout(() => {
        this.timer && clearInterval(this.timer);
        this.ue = null;
        this.timer = null;
      }, 200);
    }
  },
  methods: {
    getFormItemBlockMarginLeft() {
      if (this.$labelWidth && this.hasLabel) {
        return {
          "margin-left": this.$labelWidth + "px"
        };
      }
      return {
        "margin-left": 0
      };
    },
    renderUEditor() {
      const self = this;
      if (self.ue) self.ue.destroy(); //如果已经存在UEditor实例，则先销毁
      if (window?.UE?.getEditor) {
        let options = {
          toolbars: [self.toolBar], //自定义工具栏
          zIndex: self.isDesign ? 9000 : 99999,
          wordCount: self.wordCount,
          maximumWords: self.maximumWords,
          wordCountMsg: self.wordCountMsg
          // iframeCssUrl: self.cssUrl || undefined
          // autoHeightEnabled:true,
          //修改ueditor源码后增加这个属性，创建了元素滚动条的监听，确保自增高度后，滚动页面工具栏位置错误，当autoHeightEnabled为true则必须加入
          // scrollBoxSelector:".ds-page-content"
        };
        if (this.cssUrl) {
          options.iframeCssUrl = this.cssUrl;
        }
        self.ue = window.UE.getEditor(self.ueId, options);
        self.ue.ready(() => {
          //初始化UEditor参数
          initUEditor.call(self);
        });
      }
    },
    dialogClose() {
      this.dialogVisible = false;
      URL.revokeObjectURL(this.src);
      this.src = "";
    },
    cutImg(file) {
      uploadFile.call(this, file);
    },
    setContent(v) {
      this.editorValue = v;
      if (this.ue) {
        this.ue.setContent(v || "");
        this.setValueSuccess = true;
      } else {
        if (!this.readOnly) {
          this.setValueSuccess = false;
        }
      }
    }
  }
});

function uparseContent() {
  const fileUrl = dsf.url.getWebPath("$/js/libs/UEditor/ueditor.parse.js");
  this.$http
    .importFiles(fileUrl)
    .then(() => {
      uParse(this.uparseId, [
        {
          rootPath: "static/js/libs/UEditor/", //ueditor所在的路径
          liiconpath: location.origin + "/static/js/libs/UEditor/themes/ueditor-list/", //自定义列表标号图片的地址
          listDefaultPaddingLeft: "20" //自定义列表标号与文字的横向间距
        }
      ]);
    })
    .catch((err) => {
      console.error("加载UEditor.parse.js文件出错");
    });
}

function initUEditor() {
  const self = this;
  $("#" + self.ueId).css("width", "100%");
  self.ue.setPlaceholder(self.placeholder);
  // self.ue.setHeight(self.richTxtHeight);
  self.editorValue && self.ue.setContent(self.editorValue); //给UEditor赋初始值

  self.ue.addListener("contentChange", function () {
    // self.emitValueChange(self.ue.getContent());
    this.editorValue = self.ue.getContent();
    self.$emit("editor-change", {
      data: this.editorValue,
      action: "contentChange"
    });
  });

  //input的时候也监听，以解决百度编辑器不能监听输入特殊字符的bug
  self.ue.body.addEventListener("input", function () {
    // self.emitValueChange(self.ue.getContent());
    this.editorValue = self.ue.getContent();
    self.$emit("editor-change", {
      data: this.editorValue,
      action: "input"
    });
  });

  //失去焦点的时候验证
  self.ue.body.addEventListener("blur", function () {
    this.editorValue = self.ue.getContent();
    if (self.value !== this.editorValue) {
      self.emitValueChange(this.editorValue);
      self.$nextTick(() => {
        self.emitFormValidate(this.editorValue);
      });
      self.$emit("editor-change", {
        data: this.editorValue,
        action: "blur"
      });
    }
  });

  //如果用户开启了“在粘贴图片之前先裁剪图片”
  if (self.pasteImgByCut) {
    self.ue.addListener("dsf-paste-img", function (event, file) {
      self.src = URL.createObjectURL(file);
      self.dialogVisible = true;
    });
  }

  if (self.isDesign) {
    self.visible ? self.ue.setEnabled() : self.ue.setDisabled();
    self.readOnly ? self.ue.setHide() : self.ue.setShow();
  } else {
    self.visible ? self.ue.setShow() : self.ue.setHide();
  }
  self.ueReady = true;
  if (!this.setValueSuccess) {
    this.setContent(this.value);
  } else if (this.v) {
    this.setContent(this.v);
  }
}

function uploadFile(file) {
  const self = this;
  const formData = new FormData();
  formData.append("file", file);
  const config = {
    headers: { "Content-Type": "multipart/form-data" },
    transformRequest: [
      function (data, headers) {
        return data;
      }
    ]
  };

  self.$http
    .post(self.upFileUrl, formData, config)
    .done((res) => {
      if (!res.success) {
        dsf.layer.message(res.message, false);
        return;
      }
      self.dialogVisible = false;
      const imgSrc = dsf.url.getWebPath(res.data[0]["relativePath"]);
      self.ue.execCommand("inserthtml", `<img src="${imgSrc}" _src="${imgSrc}" alt="image" />`);
    })
    .error((err) => {
      dsf.layer.message("上传图片失败", false);
    });
}
</script>
