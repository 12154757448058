<template>
  <div class="ds-control ds-navtree ds-tree ds-no-padding" :class="{'tree-loaded':!loading}">
    <DsfPanel :hasHeader="hasHeader">
      <template v-slot:title>
        {{ label }}
      </template>
      <template>
        <div class="searchbox">
          <el-input v-if="openSearchBox" v-model="filterText" placeholder="查询（多个关键词空格隔开）" />
          <slot name="searchboxSolt"></slot>
        </div>
        <DsfVirtualScroll class="ds-tree-box" scroll-y scroll-x>
          <!-- :lazy="async && !isDesign && !initDataSource !== '3'" -->
          <el-tree
            ref="tree"
            class="filter-tree ds-tree-select-tree-box"
            :empty-text="loading?'数据查询中...':'暂无数据'"
            :props="defaultProps"
            :default-expanded-keys="expandedKeys"
            :default-checked-keys="showCheckbox ? checkedKeys : []"
            :lazy="$async"
            :load="lazyLoad"
            :data="data"
            :highlight-current="true"
            :expand-on-click-node="showCheckbox ? true : false"
            :filter-node-method="filterNode"
            :show-checkbox="showCheckbox"
            :check-strictly="checkStrictly"
            node-key="_id"
            @check-change="checkChange"
            @current-change="currNodeChange"
            @node-expand="nodeExpand"
            @node-collapse="nodeCollapse">
            <!-- @node-contextmenu="nodeContextmenu" -->
            <div slot-scope="{ node, data }" class="custom-tree-node">
              <div class="tree-name" v-context-menu="{ params: node, data: treeMenu }" :id="`${data._id}_navtree_nodeid`">
                <span>
                  <template v-if="!icons">
                    <i
                      v-if="data[defaultProps.children] !== undefined && showIcon"
                      class="iconfont"
                      :class="[
                        node.expanded ? 'icon-dakai' : 'icon-wenjianjia',
                      ]" />
                    <i v-else-if="showIcon" class="iconfont icon-shiyongwendang" />
                    <span v-html="dsf.safe.xss(data[defaultProps.label])"></span>
                  </template>
                  <template v-else>
                    <i
                      v-for="(icon, iconI) in icons.filter((icon) => {
                        return getIconClass(icon, node)[0] != '';
                      })"
                      :key="iconI"
                      class="iconfont"
                      :class="getIconClass(icon, node)"
                      :style="{ color: icon.color }"></i>
                  </template>
                  <span v-html="dsf.safe.xss(data[defaultProps.label])"></span>
                </span>
                <DsfButtonBar
                  class="ds-no-padding edit-span"
                  :render-button="renderRowButton"
                  :max-number="rowButtonsMaxNumber"
                  :more-button-text="rowButtonsMoreText"
                  :more-button-style="rowButtonsMoreStyle"
                  :buttons="getRowButtons(rowButtons)"
                  :emit-prefix="'row-button'"
                  :local="data"
                  size="small"
                  align="center">
                </DsfButtonBar>
              </div>
            </div>
            <template v-slot:empty>
              <dsf-empty-data v-if="!loading"></dsf-empty-data>
              <span v-if="loading">数据查询中...</span>
            </template>
          </el-tree>
        </DsfVirtualScroll>
      </template>
    </DsfPanel>
  </div>
</template>

<script>
export default dsf.component({
  name: "DsfNavTree",
  ctrlCaption: "导航树",
  mixins: [$mixins.control, $mixins.tree, $mixins.buttonBar],
  provide() {
    return {
      $buttonOwner: this
    };
  },
  design: {
    //组件加载到设计器上时组件高度默认在父容器铺满
    fit: true,
    metadata: {
      isDeep: false,
      create(root) {
        let query = {};
        let searchBox = [],
          attachSearch = [];
        for (let i = 0; i < this.searchInfo.length; i++) {
          let s = this.searchInfo[i];
          let item = null;
          if (s.type == "0") {
            item = {
              code: s.code,
              operator: s.operation,
              valueKey: "searchValue"
            };
            searchBox.push(item);
          } else {
            item = {
              code: s.code,
              operator: s.operation,
              valueKey: s.code
            };
            attachSearch.push(item);
            s.metadata_fullcode = item.code;
          }
        }
        //添加将检索框条件
        let seachBoxitem = { logic: "or", items: [] };
        seachBoxitem.items.push(...searchBox);

        //添加默认附加条件
        let attachSearchItem = { logic: "and", items: [] };
        attachSearchItem.items.push(...attachSearch);

        let searchitems = { logic: "and", items: [] };
        searchitems.items.push(seachBoxitem);
        searchitems.items.push(attachSearchItem);
        // seachBoxitem.items.length > 0 && searchitems.items.push(seachBoxitem);
        // attachSearchItem.items.length > 0 && searchitems.items.push(attachSearchItem);
        query = searchitems;
        let metadata = {
          query: query,
          self: this,
          metadata: this.metadata,
          browseScope: this.browseScope
        };
        if (this.buildMetadata) {
          return [metadata];
        }
        return [];
      }
    }
  },
  props: {
    metadata: {
      type: Object,
      default() {
        return dsf.metadata.get("nav-tree-meta-data");
      },
      desc: "元数据"
    },
    label: {
      type: String,
      default: "导航树",
      desc: "标题"
    },
    hasHeader: {
      type: Boolean,
      default: true,
      desc: "显示标题"
    },
    showIcon: {
      type: Boolean,
      default: true,
      desc: "显示节点图标"
    },
    openSearchBox: {
      type: Boolean,
      default: true,
      desc: "启用搜索框"
    },
    openWebroot: {
      type: Boolean,
      default: false,
      desc: "启用虚拟根目录"
    },
    webrootName: {
      type: String,
      default: "",
      desc: "虚拟根目录的节点名称"
    },
    targetIframeName: {
      type: String,
      default: "",
      desc: "跳转iframe的NAME标识"
    },
    targetIframeSrc: {
      type: String,
      default: "",
      desc: "跳转iframe地址"
    },
    pId: {
      type: String,
      default: "",
      desc: "根节点数据"
    },
    globalId: {
      type: String,
      default: "",
      desc: "全局ID"
    },
    maxLevel: {
      type: String,
      default: "",
      desc: "最大层级"
    },
    defaultOpenSeries: {
      type: String,
      default: "1",
      desc: "初始化展开至第几级"
    },
    defaultOrder: {
      type: String,
      default: "",
      desc: "默认排序代码: xx desc,"
    },
    searchInfo: {
      type: Array,
      default() {
        return [];
      },
      desc: '查询条件,"dsfa_rm.type_value": "0"'
    },
    filterKeyInfo: {
      type: Array,
      default() {
        return [
          {
            code: "_name",
            operation: "like"
          },
          {
            code: "pinyin",
            operation: "slike"
          },
          {
            code: "py",
            operation: "slike"
          }
        ];
      }
    },
    initDataSource: {
      type: String,
      default: "1",
      desc: "初始化数据来源，1:代表页面元数据，2:代表可选择数据，3:代表自定义接口"
    },
    initDataSourceUrl: {
      type: String,
      default: "",
      desc: "自定义接口地址"
    },
    chooseMetaData: {
      type: Object,
      default() {
        return {
          attach: []
        };
      },
      desc: "初始化选择的数据来源及选择时的具体数据"
    },
    async: {
      type: Boolean,
      default: false,
      desc: "是否启用异步,在初始化数据来源为自定义接口的时候，就算启用也不会生效"
    },
    asyncDataSource: {
      type: String,
      default: "1",
      desc: "初始化数据来源，1:代表页面元数据，2:代表可选择数据"
    },
    asyncChooseMetaData: {
      type: Object,
      default() {
        return {};
      },
      desc: "异步请求选择的数据来源及选择时的具体数据"
    },
    metadata_fullcode: {
      type: String,
      default: ""
    },
    browseScope: {
      type: Object,
      default() {
        return {
          scope: "2",
          unit: []
        };
      },
      desc: "发送范围"
    },
    icons: {
      type: Array,
      default() {
        return [
          {
            code: "!@[children]",
            iconExpand: "icon-shiyongwendang",
            iconFold: "icon-shiyongwendang",
            color: "#6594dc"
          },
          {
            code: "!!@[children]",
            iconExpand: "icon-dakai",
            iconFold: "icon-wenjianjia",
            color: "#c78511"
          }
        ];
      },
      desc: "图标设置"
    },
    rowButtons: {
      type: Array,
      default() {
        return [];
      }
    },
    renderRowButton: {
      type: Function,
      default: null
    },
    // 数据行按钮最大数量
    rowButtonsMaxNumber: {
      type: Number,
      default: 4
    },
    // 数据行更多按钮形态
    rowButtonsMoreStyle: {
      type: String,
      default: "icon-text"
    },
    // 数据行更多按钮文字
    rowButtonsMoreText: {
      type: String,
      default: "更多"
    },
    menusButtons: {
      type: Array,
      default() {
        return [];
      },
      desc: "右键菜单按钮"
    },
    setIframePath: {
      type: Function,
      default: null
    },
    showCheckbox: {
      type: Boolean,
      default() {
        return false;
      }
    },
    checkedKeys: {
      type: Array,
      default() {
        return [];
      }
    },
    buildMetadata: {
      type: Boolean,
      default: true
    },
    checkStrictly: {
      type: Boolean,
      default: false
    },
    initSelectionNode: {
      type: Function,
      default: null
    }
  },
  data() {
    return {
      treeMenu: this.getMenuButtons(),
      expandedKeys: [],
      filterText: "",
      defaultProps: {
        children: "children",
        label: "_name",
        key: "_id"
      },
      data: [],
      emptyText: "数据加载中...",
      resolve: null,
      metadataFullcode: this.metadata_fullcode,
      loading: false,
      currNode: null,
      //右键弹框
      popoverStyle: {
        top: 0,
        left: 0
      }
    };
  },
  computed: {
    keywords() {
      const keyword = this.filterText.trim();
      if (keyword === "") {
        return [];
      }
      return keyword.split(" ");
    },
    $async() {
      return this.async && !this.isDesign;
    },
    initOpenLevel() {
      return !this.defaultOpenSeries || this.defaultOpenSeries <= 1 ? 1 : this.defaultOpenSeries;
    }
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    },
    // 自定义接口下拉
    initDataSourceUrl() {
      let sourceType = this.initDataSource * 1;
      if (sourceType === 3) {
        this.loadData(true);
      }
    }
  },
  mounted() {
    document.addEventListener("click", this.controlMenu);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.controlMenu);
  },
  created() {
    if (!this.isDesign) {
      this._updatePath = _.throttle(this.__updatePath, 50, {
        leading: false,
        trailing: true
      });
      this._selectedTree = _.throttle(this.__selectedTree, 50, {
        leading: false,
        trailing: true
      });
    }
    if (this.$vm && this.$vm.isPreView) {
      if (!this.$vm.nameSpace) {
        this.$vm.nameSpace = ""; //"dsfa.rm";
      }
      if (!this.$vm.pageName) {
        this.$vm.pageName = ""; //"main";
      }
      this.metadataFullcode = ""; //"dsfa_rm.NavTree4";
    }
    if (!this.$async) {
      this.loadData(true, false);
    }
  },
  methods: {
    getTreeNode(node) {
      return this.$refs.tree.getNode(node);
    },
    checkChange(data, isChecked, node) {
      const checkedNodes = this.$refs.tree.getCheckedNodes();
      let treeNodes = {};
      let parentIds = [];
      checkedNodes.forEach((node) => {
        // 父节点
        if (node?.children?.length > 0) {
          parentIds.push(node._id);
        }
      });
      // 选中了根目录
      if (checkedNodes?.[0]?.pid == "0") {
        const item = checkedNodes[0];
        Object.keys(item).forEach((key) => {
          if (key !== "children") {
            if (!treeNodes[key]) {
              treeNodes[key] = [item[key]];
            } else {
              treeNodes[key].push(item[key]);
            }
          }
        });
      } else {
        checkedNodes?.forEach((item) => {
          if (parentIds.indexOf(item.pid) === -1 || parentIds.indexOf(item._id) > -1) {
            Object.keys(item).forEach((key) => {
              if (key !== "children") {
                if (!treeNodes[key]) {
                  treeNodes[key] = [item[key]];
                } else {
                  treeNodes[key].push(item[key]);
                }
              }
            });
          }
        });
      }
      if (this.$vm && this.$vm.frames) {
        let view = this.$vm.frames[this.targetIframeName];
        let url = "";
        if (view) {
          if (!this.setIframePath) {
            url = decodeURIComponent(this.targetIframeSrc);
            url = this.$replace(url, treeNodes);
          } else {
            url = this.setIframePath(treeNodes, checkedNodes);
          }
          this._updatePath(view, url);
        }
      }
      // ids 如果子节点全部选中 则选中父节点 checkedNodes el-tree选中的数据
      this._selectedTree({
        treeNodes,
        checkedNodes,
        currentNode: data,
        isChecked
      });
    },
    __updatePath(view, url) {
      view.$emit("update:path", url);
    },
    __selectedTree(params) {
      this.$dispatch("selectedTree", params);
    },
    nodeExpand(data) {
      let key = this.defaultProps.key;
      dsf.array.ensure(this.expandedKeys, data[key]);
    },
    nodeCollapse(data, node) {
      let key = this.defaultProps.key;
      dsf.array.remove(this.expandedKeys, data[key]);
      _.each(node.childNodes, (it) => {
        this.nodeCollapse(it.data, it);
      });
    },
    loadData(isInit, isReload) {
      if (this.isDesign) {
        let root = loadDesignerDefaultData.call(this);
        let key = this.defaultProps.key;
        let label = this.defaultProps.label;
        let children = this.defaultProps.children;
        if (this.openWebroot && !!this.webrootName) {
          root = [
            {
              [key]: 0,
              [label]: this.webrootName,
              [children]: root
            }
          ];
        }
        this.data = this.initDataSource == "4" ? this.chooseMetaData.attach || [] : root;
        this.firstLoadedSuccess(false, this.data, isReload);
        _.each(this.data, (it) => {
          this.initExpand(it);
        });
      } else if (this.initDataSource == "4") {
        // 静态数据
        this.firstLoadedSuccess(false, this.chooseMetaData.attach || [], isReload);
      } else {
        if (isInit) {
          this.initLoadData(null, false);
        } else if (isReload) {
          this.initLoadData(null, true);
        }
      }
    },
    initExpand(node, initLevel) {
      // 初始化展开层级处理
      let key = this.defaultProps.key;
      let children = this.defaultProps.children;
      let nodeLevel = (node["_realLevel"] == undefined ? node["treeinfo_level"] : node["_realLevel"]) * 1;
      nodeLevel = parseInt(nodeLevel);
      initLevel = parseInt(initLevel);
      if (nodeLevel < initLevel) {
        dsf.array.ensure(this.expandedKeys, node[key]);
      }
      if (node[children] && node[children].length > 0) {
        for (let i = 0; i < node[children].length; i++) {
          this.initExpand(node[children][i], initLevel);
        }
      }
    },
    initLoadData(resolve, isReload) {
      const self = this;
      self.loading = true;
      let sourceType = this.initDataSource * 1;
      let sourceConfig = this.chooseMetaData;
      let url = "meta/tree";
      let args = {
        pid: self.pId,
        metaFullCode: self.metadataFullcode,
        namespace: self.$vm.nameSpace,
        pageName: self.$vm.pageName,
        globalIdValue: self.globalId,
        query: null,
        maxLevel: self.maxLevel || 10,
        order: []
      };
      // let openLevel=!this.defaultOpenSeries || this.defaultOpenSeries <= 1 ? 1 : this.defaultOpenSeries;
      if (self.$async) {
        args.openLevel = this.initOpenLevel;
      }
      if (sourceType === 2 && sourceConfig.type) {
        if (sourceConfig.type == "meta") {
          // 元数据获取树
          args.name = sourceConfig.code.id;
          url = "meta/tree/byName";
        } else if (sourceConfig.type == "static") {
          self.loading = false;
          return false;
        } else if (sourceConfig.type == "ds") {
          // 数据源获取树
          args = {
            rmId: sourceConfig.code
          };
          url = "meta/tree/dbSource";
        }
      } else if (sourceType === 3) {
        url = self.$replace(self.initDataSourceUrl, self.$vm);
        args = {};
      }

      // 整理查询条件
      let queryData = {
        searchValue: ""
      };
      let searchInfo = self.searchInfo;
      for (let i = 0; i < searchInfo.length; i++) {
        let item = searchInfo[i];
        if (item.type == "1") {
          let arr = item.code.split(".");
          if (arr.length == 2) {
            queryData[item.code] = item.valueExpress;
          } else {
            queryData[item.metadata_fullcode] = item.valueExpress;
          }
        }
      }

      // 整理排序代码
      let defaultOrder = $.trim(self.defaultOrder).split(",");
      for (let i = 0; i < defaultOrder.length; i++) {
        let o = defaultOrder[i];
        let reg = /(.+)\s+(desc|asc)/gim;
        let s = $.trim(o);
        if (s) {
          let result = reg.exec(s);
          if (result) {
            let obj = {
              code: result[1], //list.root.scope + "." + result[1],
              sort: result[2] || "ASC"
            };
            args.order.push(obj);
          }
        }
      }
      args.order = JSON.stringify(args.order);
      args.query = JSON.stringify(queryData);
      dsf.mix(args, this.queryString);
      if (url) {
        self.$http
          .get(url, args, sourceType !== 3)
          .done((res) => {
            if (res.success) {
              this.firstLoadedSuccess(resolve, res.data, isReload);
            } else {
              dsf.layer.message(res.message, false);
            }
          })
          .error((res) => {
            dsf.layer.message(res.message || res.error?.message || "程序异常", false);
          })
          .always(() => {
            self.loading = false;
          });
      } else {
        self.loading = false;
      }
    },
    firstLoadedSuccess(resolve, list, isReload) {
      let tree = [],
        virtualRoot = null,
        self = this;
      if (!Array.isArray(list)) {
        list = [list];
      }
      if (isReload) {
        self.expandedKeys = [];
      }
      list = loopSimpleNodes(list, this);
      if (self.openWebroot && !!self.webrootName) {
        virtualRoot = {
          [self.defaultProps.key]: "virtualRoot",
          [self.defaultProps.label]: self.webrootName,
          [self.defaultProps.children]: tree,
          _realLevel: 1,
          children: []
        };
      }
      deepCreateNodeData(list, (it, level, index) => {
        it._realLevel = level + 1 + (virtualRoot ? 1 : 0);
        let isChild = it.children && it.children.length > 0;
        it._loaded = !!isChild;
        if (self.$async) {
          it.isLeaf = false;
        }
        if (level == 0) {
          tree.push(it);
        }
      });
      if (virtualRoot) {
        virtualRoot.children.push(...tree);
        this.initExpand(virtualRoot, this.initOpenLevel);
        tree = [virtualRoot];
      } else {
        _.each(tree, (it) => {
          this.initExpand(it, this.initOpenLevel);
        });
      }
      self.data = tree;
      let choosedNode = tree[0] || {};
      self.$nextTick(() => {
        // 默认选中第一个节点
        // self.loadIframe(choosedNode);
        if (!isReload) {
          let nodeKey = "";
          if (this.initSelectionNode) {
            nodeKey = this.initSelectionNode(tree);
          } else {
            nodeKey = choosedNode[self.defaultProps.key];
          }
          let node = self.$refs.tree?.getNode(nodeKey);
          if (node) {
            this.setCurrentKey(nodeKey);
            self.loadIframe(node.data, node);
            self.currNode = node.data;
          } else {
            dsf.warn("节点无法再树结构中查询到，未能选中");
          }
        } else {
          self.$refs.tree?.setCurrentKey(this.currNode._id);
        }
        self.$dispatch("inited", tree);
      });
      if (resolve) {
        return resolve(tree);
      }
    },
    asyncLoadData(node, resolve, isReload) {
      const self = this;
      let args = {
        metaFullCode: self.metadataFullcode,
        namespace: self.$vm.nameSpace,
        pageName: self.$vm.pageName,
        maxLevel: self.maxLevel || 10,
        pid: node.data[self.defaultProps.key]
      };
      // args.namespace = this.asyncChooseMetaData.code.at;
      // args.pageName = "main"
      // let id = node.data.ID;
      // if(id && id.lastIndexOf('.')>0){
      //   let i = id.lastIndexOf('.');
      //   args.namespace = id.slice(0,i);
      //   args.pageName = id.slice(i+1);
      // }
      let currentParentNode = node;
      dsf.mix(args, this.queryString);
      self.$http
        .get("meta/tree/child", args, true)
        .done((res) => {
          if (res.success) {
            let list = res.data,
              tree = [];
            list = loopSimpleNodes(list, this);
            deepCreateNodeData(list, (it, level, index, parentNode) => {
              it._realLevel = parentNode ? parentNode._realLevel : currentParentNode._realLevel;
              let isChild = it.children && it.children.length > 0;
              it._loaded = !!isChild;
              it.isLeaf = false;
              if (level == 0) {
                tree.push(it);
              }
            });
            // for (let i = 0; i < list.length; i++) {
            //   let node = list[i];
            //   temp1[node[self.defaultProps.key]] = node;
            //   if (temp1[node.treeinfo_pid]) {
            //     if (!temp1[node.treeinfo_pid]["children"]) {
            //       temp1[node.treeinfo_pid]["children"] = [];
            //     }
            //     temp1[node.treeinfo_pid]["children"].push(node);
            //   } else {
            //     tree.push(node);
            //   }
            // }
            if (resolve) {
              resolve(tree);
            }
            this.$nextTick(() => {
              self.$dispatch("nodes-loaded", tree);
            });
          } else {
            dsf.layer.message(res.message, false);
          }
        })
        .error((res) => {
          dsf.layer.message(res.error.message, false);
        });
    },
    lazyLoad(node, resolve, isReload) {
      this.resolve = resolve;
      if (node.level === 0) {
        this.initLoadData(resolve, isReload);
      } else {
        if (!node.data._loaded) {
          node.isLeaf = false;
          this.asyncLoadData(node, resolve, isReload);
        } else {
          resolve(node.data.children);
        }
      }
    },
    filterNode(value, data, node) {
      if (!value) return true;
      for (let i = 0; i < this.keywords.length; i++) {
        let key = this.keywords[i]?.toLowerCase();
        for (let j = 0; j < this.filterKeyInfo.length; j++) {
          let state = false;
          let obj = this.filterKeyInfo[j];
          let val = data[obj.code];

          if (!dsf.isUnDef(val)) {
            val = (val + "").toLowerCase();
            let index = val.indexOf(key);
            let op = obj.operation;
            switch (op) {
            case "like": //模糊匹配
              state = index > -1;
              break;
            case "slike": //开头匹配
              state = index == 0;
              break;
            case "elike": //结尾匹配
              state = index + key.length == (val + "").length;
              break;
            case "eq": //等于
              state = val == key;
              break;
            case "noteq": //不等于
              state = val != key;
              break;
            case "lt": //小于
              state = val < key;
              break;
            case "gt": //大于
              state = val > key;
              break;
            case "lteq": //小于等于
              state = val <= key;
              break;
            case "gteq": //大于等于
              state = val >= key;
              break;
            }
          }
          if (state) return true;
        }
      }
      return false;
    },
    currNodeChange(data, node) {
      // 多选不切换iframe
      this.currNode = data;
      if (!this.showCheckbox) {
        this.loadIframe(data, node);
      }
      this.$dispatch("currNodeChange", { data, node });
    },
    loadIframe(data, node) {
      if (this.$vm && this.$vm.frames) {
        let view = this.$vm.frames[this.targetIframeName];
        let url = "";
        if (view) {
          if (!this.setIframePath) {
            url = decodeURIComponent(this.targetIframeSrc);
            url = this.$replace(url, data);
          } else {
            url = this.setIframePath(data, node);
          }

          view.$emit("update:path", url);
        }
      }
    },
    reloadData() {
      this.data = [];
      if (this.$async && this.resolve) {
        this.resolve([]);
        this.lazyLoad({ level: 0 }, this.resolve, true);
      } else {
        this.loadData(false, true);
      }
    },
    getIconClass(iconData, nodeData) {
      if (dsf.express.eval(iconData.code, nodeData.data)) {
        if (nodeData.expanded) {
          return [iconData.iconExpand || ""];
        } else {
          return [iconData.iconFold || ""];
        }
      }
      return [""];
    },
    getRowButtons(buttons) {
      return dsf.mix(true, [], buttons || []);
    },
    getButtonParams(button) {
      let owner = this.owner || this;
      let params = _.map(button.params, (p) => {
        let paramValue = null;
        if (dsf.type(p.value) == "string") {
          let v = p.value.trim();
          if (this.isFunctionCode(v)) {
            paramValue = this.execFunction(button, owner, v);
          } else {
            paramValue = v;
          }
        } else if (dsf.type(p.value) == "object") {
          if (p.value.type == "static") {
            let v = p.value.code || "";
            paramValue = v;
          } else if (p.value.type == "switch") {
            let v = this.local[p.value.code.field] || "";
            paramValue = v;
          } else {
            let v = p.value.code;
            if (this.isFunctionCode(v)) {
              paramValue = this.execFunction(button, owner, v);
            }
          }
        }
        if (paramValue == "" || dsf.isUnDef(paramValue)) {
          paramValue = p.default || null;
        }
        if (paramValue === "false") {
          paramValue = false;
        } else if (paramValue === "true") {
          paramValue = true;
        }
        return paramValue;
      });
      return params;
    },
    getMenuButtons() {
      if (this.menusButtons?.length > 0) {
        let _this = $components.platform.DsfButtonBar.methods;
        let menusButtons = [];
        _.forEach(this.menusButtons, (btn) => {
          let params = [];
          if (btn.isUpload) {
            params = this.getButtonParams(btn);
          }
          menusButtons.push({
            icon: "icon-" + btn.icon,
            name: btn.text || btn.caption,
            upload: btn.isUpload
              ? {
                action: dsf.url.getWebPath(params[0]),
                data: params[1],
                accept: params[2]
                  ?.split("|")
                  .map((it) => dsf.mime[it.toLowerCase()])
                  .join(",")
              }
              : false,
            hide: (res) => {
              let btnBarCtrl = res?.el?.lastElementChild?.__vue__;
              if (btnBarCtrl) {
                return !btnBarCtrl.showAndHide(btn);
              } else {
                return true;
              }
            },
            disabled: (res) => {
              let btnBarCtrl = res?.el?.lastElementChild?.__vue__;
              if (btnBarCtrl) {
                btnBarCtrl.disabledFactor(btn);
                return btn.disabled;
              } else {
                return true;
              }
            },
            handler: (res) => {
              res?.el?.lastElementChild?.__vue__?.clickHandler(btn, res.el, res.response?.data);
            }
          });
        });
        return menusButtons;
      }
    },
    extendSelectionNode(nodeKey) {
      let node = this?.$refs?.tree?.getNode?.(nodeKey);
      if (node) {
        let key = this.defaultProps.key;
        dsf.array.ensure(this.expandedKeys, node.data[key]);
        if (node.parent) {
          this.extendSelectionNode(node.parent.data[key]);
        }
      }
    },
    //勾选节点
    setCheckedKeys(node, checked, deep = true) {
      this?.$refs?.tree?.setCheckedKeys?.(node, checked, deep);
    },
    //选中节点
    setCurrentKey(key, isAutoExtend = true) {
      this.$refs.tree?.setCurrentKey?.(key);
      if (isAutoExtend) {
        this.extendSelectionNode(key);
      }
    }
  }
});

function loopSimpleNodes(list, vm) {
  let tree = [],
    temp1 = {};
  for (let i = 0; i < list.length; i++) {
    let node = list[i];
    temp1[node[vm.defaultProps.key]] = node;
    if (temp1[node.treeinfo_pid]) {
      if (!temp1[node.treeinfo_pid]["children"]) {
        temp1[node.treeinfo_pid]["children"] = [];
      }
      temp1[node.treeinfo_pid]["children"].push(node);
    } else {
      tree.push(node);
    }
  }
  return tree;
}

function deepCreateNodeData(data, callback) {
  dsf.deepForEach(
    data,
    (it, level, index, parentObj) => {
      if (self.$async) {
        it.isLeaf = true;
      }
      callback && callback(it, level, index, parentObj);
    },
    { children: "children" }
  );
}
function loadDesignerDefaultData() {
  let designerData = [
    {
      [this.defaultProps.key]: 1,
      [this.defaultProps.label]: "一级",
      [this.defaultProps.children]: [
        {
          [this.defaultProps.key]: 2,
          [this.defaultProps.label]: "二级 1-1",
          icon: "el-icon-info"
        },
        {
          [this.defaultProps.key]: 3,
          [this.defaultProps.label]: "二级 1-2",
          icon: "el-icon-info"
        }
      ]
    }
  ];
  return designerData;
}
</script>
