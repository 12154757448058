<template>
  <li class="ds-aside-menu-item" :class="{active: isActive, show: showChildren}" :menu-key="menu.menu_id" :menu-level="level">
    <div
      class="ds-aside-menu-item-box"
      :style="{'padding-left': level * 20 + 'px'}"
      :title="(!homeAside.mini || !!menu.children) ? menu.menu_name : ''"
      @click="clickEvent">
      <el-tooltip
        v-if="level === 1 && hasIcon"
        class="item"
        ref="tooltip"
        :disabled="!homeAside.mini || !!menu.children"
        :content="menu.menu_name"
        effect="light"
        placement="right"
        transition="">
        <i :class="[menu.menu_icon || 'icon-shezhi']" class="ds-aside-menu-item-icon iconfont" />
      </el-tooltip>
      <span class="ds-aside-menu-item-name ellipsis">{{ menu.menu_name }}</span>
      <i v-if="menu.children" :class="[{show: showChildren}, showChildren ? 'icon-xia1' : 'icon-zuo' ]" class="iconfont ds-aside-menu-item-show" />
      <span v-else v-show="badge" class="ds-page-home-badge" :class="[homeRoot.badgeStyle]">{{ badge }}</span>
    </div>
    <div v-if="!homeAside.mini && menu.children" v-show="showChildren">
      <dsf-default-home-menu :menu-list="menu.children" :level="level + 1"></dsf-default-home-menu>
    </div>
  </li>
</template>
<script>
import menuUtils from "../../utils";

export default {
  name: 'DsfDefaultHomeMenuItem',
  inject: ['homeRoot', 'homeAside'],
  props: {
    menu: {
      type: Object,
      required: true
    },
    level: {
      type: Number,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
    hasIcon: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isActive: false,
      showChildren: false,
      parent: {},
    }
  },
  computed: {
    // 角标
    badge() {
      let code = this.menu['menu_code'];
      if (!code) return 0;
      return this.homeRoot.menuSubscript[code] || 0;
    }
  },
  mounted() {
    this.parent = this.$parent;
    if (this.menu.children) {
      this.$el.onmouseenter = this.mouseenter;
      this.$el.onmouseleave = this.mouseleave;
    }
    if (this.$refs.tooltip) {
      this.$refs.tooltip.debounceClose = this.$refs.tooltip.handleClosePopper;
    }
  },
  methods: {
    clickEvent() {
      if (this.menu.children) {
        this.showChildren = !this.showChildren;
        if (this.showChildren) {
          this.homeAside.setShow(this, true);
        } else {
          this.homeAside.setShow(this, false);
          this.showChildren = false;
        }
      } else {
        this.linkTo(this.menu);
      }
    },
    linkTo(item) {
      menuUtils.openPage.call(this.homeRoot, menuUtils.findMenu(item));
    },
    mouseenter(e) {
      this.$emit('mouseenter', e, this.menu);
    },
    mouseleave(e) {
      this.$emit('mouseleave', e);
    }
  }
}
</script>