var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: !_vm.loading,
          expression: "!loading",
        },
      ],
      staticClass: "dsf-alter-password-control",
    },
    [
      !_vm.step
        ? _c(
            "div",
            { staticClass: "step-box" },
            [
              _c("p", { staticClass: "title" }, [
                _vm._v(_vm._s(_vm.title)),
                _c("span", [_vm._v(_vm._s(_vm.notice))]),
              ]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.newPwd,
                    expression: "newPwd",
                  },
                ],
                staticClass: "dsf-alter-password-input",
                attrs: { type: "password", placeholder: "请输入新密码" },
                domProps: { value: _vm.newPwd },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.newPwd = $event.target.value
                  },
                },
              }),
              _c("br"),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.confirmPwd,
                    expression: "confirmPwd",
                  },
                ],
                staticClass: "dsf-alter-password-input",
                attrs: { type: "password", placeholder: "请再次输入新密码" },
                domProps: { value: _vm.confirmPwd },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.confirmPwd = $event.target.value
                  },
                },
              }),
              _vm.alterResult
                ? _c("p", { staticClass: "error-msg" }, [
                    _c("i", { staticClass: "iconfont icon-tixingshixin" }),
                    _vm._v(" " + _vm._s(_vm.alterResult) + " "),
                  ])
                : _vm._e(),
              _c("br"),
              _c(
                "el-button",
                {
                  staticClass: "dsf-alter-password-btn",
                  class: {
                    disabled: !(_vm.newPwd && _vm.newPwd === _vm.confirmPwd),
                  },
                  attrs: {
                    type: "primary",
                    disabled: !(_vm.newPwd && _vm.newPwd === _vm.confirmPwd),
                  },
                  on: { click: _vm.nextStep },
                },
                [_vm._v(" 下一步 ")]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.step
        ? _c(
            "div",
            { staticClass: "step-box" },
            [
              _vm._m(0),
              _c("p", { staticClass: "success-tip" }, [
                _vm._v("登录密码修改成功"),
              ]),
              _c(
                "el-button",
                {
                  staticClass: "dsf-alter-password-success-btn",
                  attrs: { type: "primary" },
                  on: { click: _vm.reLogin },
                },
                [_vm._v(" 重新登录 ")]
              ),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "step-succss" }, [
      _c("i", { staticClass: "el-icon-check" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }