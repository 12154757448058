var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.scriptLoaded
    ? _c("div", { staticClass: "ds-export-setting" }, [
        _vm.allowList.length > 1
          ? _c("div", { staticClass: "ds-export-setting-box" }, [
              _c("div", { staticClass: "title" }, [_vm._v("导出文件类型")]),
              _c(
                "div",
                { staticClass: "content file-box" },
                [
                  _vm._l(_vm.allowList, function (item) {
                    return [
                      _c(
                        "div",
                        {
                          key: item.value,
                          staticClass: "file-radio",
                          class: { active: _vm.extName === item.value },
                          on: {
                            click: function ($event) {
                              _vm.extName = item.value
                            },
                          },
                        },
                        [
                          _c("i", {
                            staticClass: "iconfont",
                            class: item.icon,
                            style: { color: item.color },
                          }),
                          _c("span", [_vm._v(_vm._s(item.text))]),
                        ]
                      ),
                    ]
                  }),
                ],
                2
              ),
            ])
          : _vm._e(),
        _c("div", { staticClass: "ds-export-setting-row" }, [
          _c(
            "div",
            { staticClass: "ds-export-setting-box" },
            [
              _c("div", { staticClass: "title" }, [_vm._v("可选列")]),
              _c("dsf-virtual-scroll", [
                !_vm.allowList.length
                  ? _c("div", { staticClass: "no-data" }, [
                      _vm._v(" 暂无可导出的列 "),
                    ])
                  : _vm._e(),
                _c(
                  "div",
                  { staticClass: "el-checkbox-group" },
                  _vm._l(_vm.fields, function (field) {
                    return _c(
                      "el-checkbox",
                      {
                        key: field.id,
                        attrs: {
                          value: _vm.selectedIds.indexOf(field.id) > -1,
                        },
                        on: {
                          change: function ($event) {
                            return _vm.checkboxChange(field, $event)
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(field.caption) + " ")]
                    )
                  }),
                  1
                ),
              ]),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "ds-export-setting-box" },
            [
              _c("div", { staticClass: "title" }, [
                _vm._v(" 已选列 "),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.selectedIds.length,
                        expression: "selectedIds.length",
                      },
                    ],
                  },
                  [_vm._v("(" + _vm._s(_vm.selectedIds.length) + ")")]
                ),
                _c("span", { staticClass: "tips" }, [_vm._v("拖动列可排序")]),
              ]),
              _c(
                "dsf-virtual-scroll",
                [
                  !_vm.selectedIds.length
                    ? _c("div", { staticClass: "no-data" }, [
                        _vm._v(" 请从左侧选择需要导出的列 "),
                      ])
                    : _c(
                        "draggable",
                        _vm._b(
                          {
                            staticClass: "field-list",
                            model: {
                              value: _vm.selectedFields,
                              callback: function ($$v) {
                                _vm.selectedFields = $$v
                              },
                              expression: "selectedFields",
                            },
                          },
                          "draggable",
                          _vm.options,
                          false
                        ),
                        [
                          _vm._l(_vm.selectedFields, function (field) {
                            return [
                              _c(
                                "div",
                                { key: field.id, staticClass: "field-item" },
                                [
                                  _c("span", [_vm._v(_vm._s(field.caption))]),
                                  _c("i", {
                                    staticClass:
                                      "closeBt iconfont icon-guanbi2",
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.checkboxChange(field, false)
                                      },
                                    },
                                  }),
                                ]
                              ),
                            ]
                          }),
                        ],
                        2
                      ),
                ],
                1
              ),
            ],
            1
          ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }