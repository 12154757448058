<template>
  <div class="des-common-import-error-data-eidt" v-loading="loading">
    <div class="table-haed"></div>
    <div class="table-main">
      <el-table ref="table" :data="showTableData" style="width: 100%">
        <el-table-column
          v-for="header in headers"
          :key="header.property"
          :label="header.text"
          :min-width="getTableMinWidth(header) || '0px'"
        >
          <template slot-scope="{ row }">
            <div class="table-col">
              <template v-if="row[header.property]">
                <div class="table-col-main">
                  <el-input
                    v-model="row[header.property].value"
                    @change="inputChange(header, row, row._id)"
                    size="mini"
                  ></el-input>
                </div>
                <div
                  v-if="
                    row[header.property].isError == 1 &&
                      row[header.property].msg
                  "
                  class="table-col-msg"
                >
                  {{ row[header.property].msg }}
                </div>
              </template>
              <div v-else>
                {{ row[header.property] }}
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column fixed="right" width="60px" label="操作">
          <template slot-scope="{ row }">
            <div class="table-col">
              <dsf-button
                class="table-col-icon"
                title="删除"
                size="small"
                btn-style="icon-text"
                icon="guanbi2"
                @click.stop="remove(row)"
              ></dsf-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="table-footer">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[50, 100, 150, 200]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="count"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
export default {
  name: "DesCommonImportErrorDataEidt",
  mixins: [$mixins.propertyDialogSetting],
  inject: ["$dialog"],
  props: {
    fileData: {
      default: () => {},
    },
    servicePrefix: {
      default: "",
    },
  },
  computed: {
    showTableData() {
      let { tableData, currentPage, pageSize } = this;
      let start = (currentPage - 1) * pageSize;
      this.resize();
      return tableData.slice(start, start + pageSize);
    },
    isAllConform() {
      return this.tableData.some((ite) => {});
    },
  },
  data() {
    return {
      headers: [],
      tableData: [],
      count: 0,
      currentPage: 1,
      pageSize: 50,
      rowErrors: {},
      loading: true,
    };
  },
  created() {
    if (this.fileData) {
      this.headers = this.fileData.header;
      this.tableData = this.fileData.errorData;
      this.count = this.fileData.count;
      this.rowRules = {};
      this.dsfValidate = dsf.validate.map || {};
      for (let i = 0; i < this.fileData.rowRules.length; i++) {
        const element = this.fileData.rowRules[i];
        this.rowRules[element.fieldName] = element;
      }
      this.buildRowErrors();
      this.resize();
      setTimeout(() => {
        this.$nextTick(() => {
          this.loading = false;
        });
      });
    }
  },
  methods: {
    getTableMinWidth(header) {
      return this.rowRules[header.property].headWidth;
    },
    buildRowErrors() {
      let tableData = this.tableData;
      for (let i = 0; i < tableData.length; i++) {
        (() => {
          const element = tableData[i];
          for (const key in element) {
            if (element[key].isError) {
              return (this.rowErrors[element._id] = true);
            }
          }
          delete this.rowErrors[element._id];
        })();
      }
    },
    resize() {
      if (this.resizeSetTimeout) clearTimeout(this.resizeSetTimeout);
      this.resizeSetTimeout = setTimeout(() => {
        this.$nextTick(() => {
          this.doLayout();
        });
      }, 200);
    },
    doLayout() {
      this.elTable = this.$refs.table;
      if (this.elTable) {
        this.elTable.doLayout();
      }
    },
    remove(row) {
      dsf.array.remove(this.tableData, row);
    },
    inputChange(header, row, _id) {
      let rule = this.rowRules[header.property];
      let col = row[header.property];
      let validationMethod = rule.validationMethod || "";
      validationMethod = firstToUpper(validationMethod);
      validationMethod = this["validate" + validationMethod];
      if (rule.isRequired == 1) {
        this.validateIsRequired(col, rule, _id);
      } else if (validationMethod) {
        validationMethod(col, rule, _id);
      } else {
        col.isError = 0;
      }
      this.rowIsError(row);
    },
    rowIsError(row) {
      for (const key in row) {
        if (row.isError) {
          return (this.rowErrors[row._id] = true);
        }
      }
      delete this.rowErrors[row._id];
    },
    // 必填
    validateIsRequired(col, rule, _id) {
      let val = col.value;
      if (val == "" || val == null || val == undefined) {
        col.isError = 1;
      } else {
        col.isError = 0;
      }
      if (col.isError) {
        col.msg = "该字段是必填";
      }
    },
    // 唯一验证
    validateUnique(col, rule, _id) {
      col.isError = Number(
        !!this.tableData.some((ite) => {
          if (_id == ite._id) {
            return false;
          }
          return col.value === ite[rule.fieldName].value;
        })
      );
      if (col.isError) {
        col.msg = "该字段已重复";
      }
    },
    // 数字校验
    validateNumber(col, rule, _id) {
      let val = col.value;
      // 小于
      let isNumberUnder = this.dsfValidate["isNumberUnder"].validator;
      // 超出
      let isNumberOver = this.dsfValidate["isNumberOver"].validator;
      // 数字
      let isNumber = this.dsfValidate["isNumber"].validator;
      // 数字精度
      let isPrecision = this.dsfValidate["precision"].validator;
      let min = rule.min;
      let minIsNumber = dsf.type(min) == "number";
      let max = rule.max;
      let maxIsNumber = dsf.type(max) == "number";
      let scaleDecimal = rule.scaleDecimal;
      let scaleDecimalIsNumber = dsf.type(scaleDecimal) == "number";
      let msg = "该字段应该是";
      if (!minIsNumber & !maxIsNumber & !scaleDecimalIsNumber) {
        msg += "数字";
      } else {
        if (minIsNumber && maxIsNumber) {
          msg += `${min}-${max}之间`;
        } else if (minIsNumber) {
          msg += `大于或等于${min}`;
        } else if (maxIsNumber) {
          msg += `小于或等于${min}`;
        }
        if (scaleDecimalIsNumber) {
          msg += `,并且保留${scaleDecimal}位小数`;
        }
        msg += "的数字";
      }

      if (dsf.type(isNumber) == "function" && !isNumber(val)) {
        col.isError = 1;
      } else if (
        scaleDecimalIsNumber &&
        dsf.type(isPrecision) == "function" &&
        !isPrecision(val, scaleDecimal)
      ) {
        col.isError = 1;
      } else if (
        minIsNumber &&
        dsf.type(isNumberUnder) == "function" &&
        isNumberUnder(val, min)
      ) {
        col.isError = 1;
      } else if (
        maxIsNumber &&
        dsf.type(isNumberOver) == "function" &&
        isNumberOver(val, max)
      ) {
        col.isError = 1;
      } else {
        col.isError = 0;
      }
      if (col.isError) {
        col.msg = msg;
      }
    },
    // 中文
    validateChinese(col, rule, _id) {
      let val = col.value;
      let valLng = val.length;
      // 中文
      let isChinese = this.dsfValidate["isChinese"].validator;
      let min = rule.min;
      let minIsNumber = dsf.type(min) == "number";
      let max = rule.max;
      let maxIsNumber = dsf.type(max) == "number";
      let msg = "该字段应该是";
      if (minIsNumber && maxIsNumber) {
        msg += `长度在${min}-${max}之间的`;
      } else if (minIsNumber) {
        msg += `长度大于或等于${min}的`;
      } else if (maxIsNumber) {
        msg += `长度小于或等于${min}的`;
      }
      msg += "全中文字符";
      if (dsf.type(isChinese) == "function" && !isChinese(val)) {
        col.isError = 1;
      } else if (minIsNumber && valLng < min) {
        col.isError = 1;
      } else if (maxIsNumber && valLng > max) {
        col.isError = 1;
      } else {
        col.isError = 0;
      }
      if (col.isError) {
        col.msg = msg;
      }
    },
    // 邮箱
    validateEmail(col, rule, _id) {
      let val = col.value;
      let isEmail = this.dsfValidate["isEmail"].validator;
      if (dsf.type(isEmail) == "function" && !isEmail(val)) {
        col.isError = 1;
      } else {
        col.isError = 0;
      }
      if (col.isError) {
        col.msg = "该字段是电子邮箱";
      }
    },
    // 数字 字母
    validateLetterNumber(col, rule, _id) {
      let val = col.value;
      let isCombination = this.dsfValidate["isCombination"].validator;
      if (dsf.type(isCombination) == "function" && !isCombination(val)) {
        col.isError = 1;
      } else {
        col.isError = 0;
      }
      if (col.isError) {
        col.msg = "该字段只能包含数字或字母";
      }
    },
    // 邮政编码
    validatePostalCode(col, rule, _id) {
      let val = col.value;
      let isPostcode = this.dsfValidate["isPostcode"].validator;
      if (dsf.type(isPostcode) == "function" && !isPostcode(val)) {
        col.isError = 1;
      } else {
        col.isError = 0;
      }
      if (col.isError) {
        col.msg = "该字段是邮政编码";
      }
    },
    // 车牌号
    validateNumberPlate(col, rule, _id) {
      let val = col.value;
      let isPlateNumber = this.dsfValidate["isPlateNumber"].validator;
      if (dsf.type(isPlateNumber) == "function" && !isPlateNumber(val)) {
        col.isError = 1;
      } else {
        col.isError = 0;
      }
      if (col.isError) {
        col.msg = "该字段是车牌号";
      }
    },
    // 手机号/电话
    validateTelNumber(col, rule, _id) {
      let val = col.value;
      let isMobileOrTelPhone = this.dsfValidate["isMobileOrTelPhone"].validator;
      if (
        dsf.type(isMobileOrTelPhone) == "function" &&
        !isMobileOrTelPhone(val)
      ) {
        col.isError = 1;
      } else {
        col.isError = 0;
      }
      if (col.isError) {
        col.msg = "该字段是手机号或座机号";
      }
    },
    // 座机
    validatePhoneNumber(col, rule, _id) {
      let val = col.value;
      let isTelPhone = this.dsfValidate["isTelPhone"].validator;
      if (dsf.type(isTelPhone) == "function" && !isTelPhone(val)) {
        col.isError = 1;
      } else {
        col.isError = 0;
      }
      if (col.isError) {
        col.msg = "该字段是座机号";
      }
    },
    // 手机号
    validateMobileNumber(col, rule, _id) {
      let val = col.value;
      if (!/^1\d{10}$/.test(val)) {
        col.isError = 1;
      } else {
        col.isError = 0;
      }
      if (col.isError) {
        col.msg = "该字段是手机号";
      }
    },
    // 身份证（强）
    validateIdCardForce(col, rule, _id) {
      let val = col.value;
      let isIDCardFull = this.dsfValidate["isIDCardFull"].validator;
      if (dsf.type(isIDCardFull) == "function" && !isIDCardFull(val)) {
        col.isError = 1;
      } else {
        col.isError = 0;
      }
      if (col.isError) {
        col.msg = "该字段是15或18位身份证号";
      }
    },
    // 身份证
    validateIdCard(col, rule, _id) {
      let val = col.value;
      let isIDCard = this.dsfValidate["isIDCard"].validator;
      if (dsf.type(isIDCard) == "function" && !isIDCard(val)) {
        col.isError = 1;
      } else {
        col.isError = 0;
      }
      if (col.isError) {
        col.msg = "该字段是15或18位身份证号";
      }
    },
    // 字符串
    validateString(col, rule, _id) {
      let val = col.value;
      let valLng = val.length;
      let min = rule.min;
      let minIsNumber = dsf.type(min) == "number";
      let max = rule.max;
      let maxIsNumber = dsf.type(max) == "number";
      let msg = "该字段应该是";
      if (minIsNumber && maxIsNumber) {
        msg += `长度在${min}-${max}之间的`;
      } else if (minIsNumber) {
        msg += `长度大于或等于${min}的`;
      } else if (maxIsNumber) {
        msg += `长度小于或等于${min}的`;
      }
      msg += "字符串";
      if (dsf.type(val) == "string") {
        col.isError = 1;
      } else if (minIsNumber && valLng < min) {
        col.isError = 1;
      } else if (maxIsNumber && valLng > max) {
        col.isError = 1;
      } else {
        col.isError = 0;
      }
      if (col.isError) {
        col.msg = msg;
      }
    },
    handleSizeChange(v) {
      this.pageSize = v;
    },
    handleCurrentChange(v) {
      this.currentPage = v;
    },
    uplodError() {
      let { tableData } = this;
      let loading = dsf.layer.loading();
      return this.$http
        .post(
          dsf.url.getWebPath('/import/recheck', this.servicePrefix),
          {
            id: this.fileData.uuid,
            data: JSON.stringify(
              tableData.map((ite) => {
                let obj = {
                  _id: ite._id,
                };
                for (const key in ite) {
                  if (key != "_id") {
                    obj[key] = ite[key].value;
                  }
                }
                return obj;
              })
            ),
          },
          true
        )
        .then(({ data }) => {
          dsf.layer.closeLoading(loading);
          try {
            if (data && data.success && data.data) {
              let { count, errorData, header, status } = data.data || {};
              if (status == 1) {
                return Promise.resolve(data);
              } else {
                this.headers = header || [];
                this.tableData = errorData || [];
                this.count = count || 0;
                dsf.layer.message("有错误数据请修改", false);
                this.buildRowErrors();
                this.resize();
                return Promise.reject("有错误数据请修改");
              }
            } else if (data.state == 20126) {
              return dsf.layer.alert(data.message || "未知错误");
            } else {
              dsf.layer.message(data.message, false);
            }
            return Promise.reject("未知错误");
          } catch (error) {
            console.error(error);
            return Promise.reject(error);
          }
        })
        .catch((err) => {
          dsf.layer.closeLoading(loading);
          return Promise.reject(err);
        });
    },
    yes() {
      return this.uplodError();
    },
  },
};
// 首字母转大写
function firstToUpper(str) {
  if (!str) return "";
  return str.trim().toLowerCase().replace(str[0], str[0].toUpperCase());
}
</script>
