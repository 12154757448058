<template>
  <div v-if="!isDesign" class="ds-control ds-label-box pc" :class="getCss" :style="getStyle">
    <template v-if="hasHelper && dataCapionTarget == 'text'">
      <el-popover v-if="dataCapionType == 'hoverPop' || dataCapionType == 'clickPop'" class="ds_label-helper" placement="top" title="" width="200" :trigger="dataCapionType == 'hoverPop' ? 'hover' : 'click'">
        <span slot="reference" class="text">{{ value }}</span>
        <div v-html="dsf.safe.xss(dataCapion)"></div>
      </el-popover>
      <span v-else class="text" @click="showHelper">{{ value }}</span>
    </template>
    <span v-else class="text">{{ value }}</span>
    <slot name="helper">
      <template v-if="hasHelper && dataCapionTarget == 'icon'">
        <el-popover v-if="dataCapionType == 'hoverPop' || dataCapionType == 'clickPop'" class="ds_label-helper" placement="top" title="" width="200" :trigger="dataCapionType == 'hoverPop' ? 'hover' : 'click'">
          <i slot="reference" class="iconfont icon-bangzhuzhongxin"></i>
          <div v-html="dsf.safe.xss(dataCapion)"></div>
        </el-popover>
        <i v-else class="iconfont icon-bangzhuzhongxin ds_label-helper" @click="showHelper"></i>
      </template>
    </slot>
  </div>
  <div v-else-if="!editting" class="ds-control ds-label-box pc" :class="getCss" :style="getStyle" @dblclick="beginEdit">
    <span class="text">{{ value || "无内容" }}</span>
    <slot name="helper">
      <i v-if="dataCapion && dataCapionTarget == 'icon'" class="iconfont icon-bangzhuzhongxin ds_label-helper"></i>
    </slot>
  </div>
  <div v-else class="ds-control ds-label-box pc" :class="getCss" :style="getStyle">
    <el-input ref="contenteditable" v-model="value" type="textarea" autosize placeholder="请输入内容" resize="none" @blur="endEdit" />
  </div>
</template>

<script>
export default dsf.component({
  name: "DsfLabel",
  mixins: [$mixins.text],
  inheritAttrs: false,
  ctrlCaption: "文本",
  props: {
    value: {
      type: [String, Number],
      default: "文本标签"
    },
    // 是否块级元素
    isBlock: {
      type: Boolean,
      default: true
    },
    // 行高
    height: {
      type: String,
      default: ""
    },
    // 字体
    fontFamily: {
      type: String,
      default: ""
    },
    fontType: {
      type: [Number, String],
      default: ""
    },
    // 文字居中/居左/居右
    textAlign: {
      type: String,
      default: ""
    },
    // 是否只显示一行（废弃）
    // oneLine: {
    //   type: Boolean,
    //   default: false,
    // },
    // 最多显示几行（0表示无限制）
    ellipsis: {
      type: Number,
      default: 0
    },
    // 是否加粗
    isBold: {
      type: Boolean,
      default: false
    },
    // 是否斜体
    isItalic: {
      type: Boolean,
      default: false
    },
    //是否显示必填*号
    required: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      editting: false
    };
  },
  computed: {
    getCss() {
      const res = this.$getClass();
      if (this.textAlign) res.push(this.textAlign);
      if (this.fontFamily) res.push("ds_font_" + this.fontFamily);
      if (this.isBold) res.push("ds-bold");
      if (this.isItalic) res.push("ds-italic");
      if (this.$attrs["oneLine"] || this.ellipsis === 1) {
        res.push("ellipsis");
      } else if (this.ellipsis > 1) {
        res.push("ellipsis-2");
      }
      if (this.fontType) {
        res.push("ds-font" + this.fontType);
      }
      if (!this.editting) {
        if (this.required) {
          res.push("ds-required");
        }
      }
      return res;
    },
    getStyle() {
      let res = {};
      if (!this.isBlock) {
        res["display"] = "inline-block";
      }
      if (this.height) {
        res["line-height"] = this.height;
      }
      if (this.ellipsis > 1) {
        res["-webkit-line-clamp"] = this.ellipsis;
      }
      return res;
    },
    hasHelper() {
      return !!this.dataCapion;
    }
  },
  methods: {
    beginEdit() {
      if (this.isDesign) {
        this.editting = true;
        setTimeout(() => {
          this.$refs.contenteditable.focus();
        }, 0);
      }
    },
    endEdit(e) {
      if (this.isDesign) {
        this.editting = false;
        this.value = e.target.value;
      }
    }
  }
});
</script>