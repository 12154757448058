<template>
  <div class="ds-control ds-form-item ds-system" :class="getCss">
    <template v-if="simple && readOnly && !isDesign">
      {{ inputValue }}
      <slot name="error">
        <div v-if="errors.length>0" class="ds-error-text">{{ errorsMsg }}</div>
      </slot>
    </template>
    <template v-else>
      <!-- <label v-if="showLabel" class="ds-form-label" :style="getLabelWidthStyle()">{{ label }}</label> -->
      <DsfFieldLabel
        v-if="showLabel"
        :mode="showDataCaptionMode"
        :style="getLabelWidthStyle()"
        :show-icon="showDataCaptionIcon" 
        :trigger="showDataCaptionTrigger"
        :data-caption="dataCapion"
        :is-design="isDesign"
        :owner="_self">
        {{ $t(label) }}
      </DsfFieldLabel>
      <div class="ds-form-block" :style="getFormItemBlockMarginLeft()">
        <el-input v-if="!readOnly" v-model="inputValue" :class="{ 'ds-error-border': errors.length > 0 }" :placeholder="placeholder" type="text" :disabled="disabled" @change="inputChangeHandler" @blur="inputBlurHandler" />
        <span v-else-if="readOnly">{{ value }}</span>
        <slot name="error">
          <div v-if="errors.length>0" class="ds-error-text">{{ errorsMsg }}</div>
        </slot>
      </div>
    </template>
  </div>
</template>

<script>
export default dsf.component({
  name: "DsfOrder",
  mixins: [$mixins.formControl],
  ctrlCaption: "排序码",
  props: {
    caption: {
      type: String,
      default: dsf.config.kw.order,
    },
    simple: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "排序码",
    },
    placeholder: {
      type: String,
      default: "请输入排序编号",
    },
    showLabel: {
      type: Boolean,
      default: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    error_tipsPosition: {
      type: String,
      default: "left",
    },
    metadata: {
      type: Object,
      default() {
        return dsf.metadata.get("system-meta-data", {
          code: dsf.config.kw.order,
          valueAttributes: [
            {
              name: "排序码",
              code: dsf.config.kw.order,
              type: dsf.metadata.getDataType("number"),
              length: 10,
              defaultValue: 0,
              unit: null,
            },
          ],
        });
      },
    },
  },
  computed: {
    inputValue: {
      get() {
        return this.value;
      },
      set(v) {
        this.emitValueChange(v);
      },
    },
  },
  methods: {
    inputChangeHandler() {
      this.$dispatch("change");
      this.$nextTick(() => {
        this.emitFormValidate(this.value);
      })
    },
    inputBlurHandler() {
      this.$dispatch("blur");
    },
  },
});
</script>
