var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "bald-sync-screen-nav" },
    [
      _c(
        "div",
        { staticClass: "bald-sync-screen-nav--operations" },
        [
          _vm._l(_vm.operationBtns, function (item, index) {
            return _c(
              "div",
              {
                key: index,
                staticClass: "operation-item",
                class: {
                  "operation-item-tightness": item.type === "tightness",
                },
              },
              [
                item.actionType === "colorPanel"
                  ? _c(
                      "div",
                      { staticClass: "color-btn" },
                      [
                        _c("div", { staticClass: "color-btn-show" }, [
                          _c("div", { staticClass: "operation-item-icon" }, [
                            _c("i", {
                              staticClass: "icon iconfont",
                              class: item.icon,
                            }),
                          ]),
                          _c("div", { staticClass: "operation-item-title" }, [
                            _vm._v(_vm._s(item.name)),
                          ]),
                        ]),
                        _c("el-color-picker", {
                          staticClass: "color-btn-hide",
                          attrs: {
                            "show-alpha": "",
                            predefine: _vm.predefineColors,
                          },
                          on: {
                            change: function ($event) {
                              return _vm.handleColor(item)
                            },
                          },
                          model: {
                            value: _vm.color,
                            callback: function ($$v) {
                              _vm.color = $$v
                            },
                            expression: "color",
                          },
                        }),
                      ],
                      1
                    )
                  : item.actionType === "file"
                  ? _c("div", { staticClass: "file-btn" }, [
                      _c("div", { staticClass: "file-btn-show" }, [
                        _c("div", { staticClass: "operation-item-icon" }, [
                          _c("i", {
                            staticClass: "icon iconfont",
                            class: item.icon,
                          }),
                        ]),
                        _c("div", { staticClass: "operation-item-title" }, [
                          _vm._v(_vm._s(item.name)),
                        ]),
                      ]),
                      _c("div", { staticClass: "file-btn-hide" }, [
                        _c("input", {
                          attrs: { type: "file" },
                          on: {
                            change: function ($event) {
                              return _vm.handleEvent(item, $event)
                            },
                          },
                        }),
                      ]),
                    ])
                  : _c(
                      "div",
                      {
                        staticClass: "normal-brn",
                        on: {
                          click: function ($event) {
                            return _vm.handleEvent(item, item.params, index)
                          },
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "operation-item-icon",
                            class: { "operation-active": item.active },
                          },
                          [
                            _c("i", {
                              staticClass: "icon iconfont",
                              class: item.icon,
                            }),
                          ]
                        ),
                        _c("div", { staticClass: "operation-item-title" }, [
                          _vm._v(_vm._s(item.name)),
                        ]),
                      ]
                    ),
              ]
            )
          }),
          !_vm.isTeacher
            ? _c(
                "div",
                {
                  staticClass: "operation-item",
                  on: { click: _vm.handleQuit },
                },
                [_vm._m(0)]
              )
            : _vm._e(),
        ],
        2
      ),
      _c(
        "el-dialog",
        {
          staticClass: "material-dialog",
          attrs: { visible: _vm.show, title: "素材库", width: "12rem" },
          on: {
            "update:visible": function ($event) {
              _vm.show = $event
            },
          },
        },
        [
          _c("div", { staticClass: "material" }, [
            _c(
              "div",
              { staticClass: "material-header" },
              [
                _c(
                  "el-checkbox",
                  {
                    staticStyle: { "margin-top": "3px" },
                    on: { change: _vm.handleCheckedAll },
                    model: {
                      value: _vm.checkedAll,
                      callback: function ($$v) {
                        _vm.checkedAll = $$v
                      },
                      expression: "checkedAll",
                    },
                  },
                  [_vm._v("全选")]
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.showLoading,
                        expression: "showLoading",
                      },
                    ],
                  },
                  [
                    _vm._v("文件正在上传···"),
                    _c("i", { staticClass: "el-icon-loading" }),
                  ]
                ),
                _c(
                  "el-upload",
                  {
                    ref: "fileForm",
                    staticClass: "upload-demo",
                    attrs: {
                      action: "/syncscreen/source/upload",
                      multiple: "",
                      limit: 5,
                      "before-upload": _vm.handleBeforeUpload,
                      "on-success": _vm.handleSuccessUpload,
                      "on-exceed": _vm.handleExceed,
                      data: _vm.uploadParams,
                      "show-file-list": false,
                    },
                  },
                  [
                    _c(
                      "el-button",
                      { attrs: { size: "small", type: "primary" } },
                      [_vm._v("上传素材")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("div", { staticClass: "material-content" }, [
              _c(
                "div",
                { staticClass: "material-content-box" },
                _vm._l(_vm.fileList, function (item, index) {
                  return _c(
                    "div",
                    { key: index, staticClass: "material-content-item" },
                    [
                      _c(
                        "div",
                        { staticClass: "material-content-file" },
                        [
                          _c("el-checkbox", {
                            key: index,
                            model: {
                              value: item.checked,
                              callback: function ($$v) {
                                _vm.$set(item, "checked", $$v)
                              },
                              expression: "item.checked",
                            },
                          }),
                          _c(
                            "div",
                            { staticClass: "material-content-file-icon" },
                            [
                              item.fileType == "jpg"
                                ? _c(
                                    "svg",
                                    {
                                      staticClass: "icon",
                                      attrs: {
                                        t: "1634698530199",
                                        viewBox: "0 0 1024 1024",
                                        version: "1.1",
                                        xmlns: "http://www.w3.org/2000/svg",
                                        "p-id": "57279",
                                        width: "200",
                                        height: "200",
                                      },
                                    },
                                    [
                                      _c("path", {
                                        attrs: {
                                          d:
                                            "M202.625 62h450l225 225v618.75a56.25 56.25 0 0 1-56.25 56.25H202.625a56.25 56.25 0 0 1-56.25-56.25V118.25a56.25 56.25 0 0 1 56.25-56.25z",
                                          fill: "#F6AD00",
                                          "p-id": "57280",
                                        },
                                      }),
                                      _c("path", {
                                        attrs: {
                                          d:
                                            "M289.221875 714.21875l82.0125-120.54375a12.740625 12.740625 0 0 1 19.490625-1.9125l54.196875 53.1 118.9125-186.384375a12.740625 12.740625 0 0 1 21.796875 0.478125l147.740625 256.05a12.740625 12.740625 0 0 1-11.053125 19.125H299.76875a12.740625 12.740625 0 0 1-10.546875-19.9125z",
                                          fill: "#FFF7F7",
                                          "p-id": "57281",
                                        },
                                      }),
                                      _c("path", {
                                        attrs: {
                                          d:
                                            "M313.775 441.29375a64.29375 64.29375 0 1 0 128.5875 0 64.29375 64.29375 0 0 0-128.5875 0z",
                                          fill: "#FFFFFF",
                                          "p-id": "57282",
                                        },
                                      }),
                                      _c("path", {
                                        attrs: {
                                          d:
                                            "M652.625 62l225 225h-168.75a56.25 56.25 0 0 1-56.25-56.25V62z",
                                          fill: "#FBDE99",
                                          "p-id": "57283",
                                        },
                                      }),
                                    ]
                                  )
                                : item.fileType == "pptx"
                                ? _c(
                                    "svg",
                                    {
                                      staticClass: "icon",
                                      attrs: {
                                        t: "1634698589463",
                                        viewBox: "0 0 1024 1024",
                                        version: "1.1",
                                        xmlns: "http://www.w3.org/2000/svg",
                                        "p-id": "57409",
                                        width: "200",
                                        height: "200",
                                      },
                                    },
                                    [
                                      _c("path", {
                                        attrs: {
                                          d:
                                            "M202.625 62h450l225 225v618.75a56.25 56.25 0 0 1-56.25 56.25H202.625a56.25 56.25 0 0 1-56.25-56.25V118.25a56.25 56.25 0 0 1 56.25-56.25z",
                                          fill: "#FF9333",
                                          "p-id": "57410",
                                        },
                                      }),
                                      _c("path", {
                                        attrs: {
                                          d:
                                            "M438.0875 737v-138.0375h98.325C613.5875 598.9625 652.625 565.4375 652.625 498.78125 652.625 432.575 614.0375 399.5 536.890625 399.5H399.5v337.5h38.5875z m96.46875-171.5625h-96.46875v-132.384375h96.46875c26.803125 0 46.546875 5.203125 59.7375 16.565625 13.1625 9.9 19.74375 26.4375 19.74375 49.1625 0 22.66875-6.58125 39.20625-19.29375 50.090625-13.1625 10.85625-32.90625 16.5375-60.1875 16.5375z",
                                          fill: "#FFFFFF",
                                          "p-id": "57411",
                                        },
                                      }),
                                      _c("path", {
                                        attrs: {
                                          d:
                                            "M652.625 62l225 225h-168.75a56.25 56.25 0 0 1-56.25-56.25V62z",
                                          fill: "#FFD3AD",
                                          "p-id": "57412",
                                        },
                                      }),
                                    ]
                                  )
                                : item.fileType == "xls"
                                ? _c(
                                    "svg",
                                    {
                                      staticClass: "icon",
                                      attrs: {
                                        t: "1634698600847",
                                        viewBox: "0 0 1024 1024",
                                        version: "1.1",
                                        xmlns: "http://www.w3.org/2000/svg",
                                        "p-id": "57538",
                                        width: "200",
                                        height: "200",
                                      },
                                    },
                                    [
                                      _c("path", {
                                        attrs: {
                                          d:
                                            "M202.625 62h450l225 225v618.75a56.25 56.25 0 0 1-56.25 56.25H202.625a56.25 56.25 0 0 1-56.25-56.25V118.25a56.25 56.25 0 0 1 56.25-56.25z",
                                          fill: "#4CB050",
                                          "p-id": "57539",
                                        },
                                      }),
                                      _c("path", {
                                        attrs: {
                                          d:
                                            "M413.7875 737l100.771875-144.646875L615.865625 737H663.875l-126-175.359375L654.7625 399.5H606.78125l-92.221875 131.4L422.7875 399.5h-48.009375l116.015625 162.140625L365.75 737z",
                                          fill: "#FFFFFF",
                                          "p-id": "57540",
                                        },
                                      }),
                                      _c("path", {
                                        attrs: {
                                          d:
                                            "M652.625 62l225 225h-168.75a56.25 56.25 0 0 1-56.25-56.25V62z",
                                          fill: "#B7DFB9",
                                          "p-id": "57541",
                                        },
                                      }),
                                    ]
                                  )
                                : item.fileType == "docx"
                                ? _c(
                                    "svg",
                                    {
                                      staticClass: "icon",
                                      attrs: {
                                        t: "1634698607245",
                                        viewBox: "0 0 1024 1024",
                                        version: "1.1",
                                        xmlns: "http://www.w3.org/2000/svg",
                                        "p-id": "57667",
                                        width: "200",
                                        height: "200",
                                      },
                                    },
                                    [
                                      _c("path", {
                                        attrs: {
                                          d:
                                            "M202.625 62h450l225 225v618.75a56.25 56.25 0 0 1-56.25 56.25H202.625a56.25 56.25 0 0 1-56.25-56.25V118.25a56.25 56.25 0 0 1 56.25-56.25z",
                                          fill: "#4876F9",
                                          "p-id": "57668",
                                        },
                                      }),
                                      _c("path", {
                                        attrs: {
                                          d:
                                            "M438.2 737l67.78125-248.428125h1.2375L574.55 737h37.85625l85.21875-298.125h-38.25l-65.25 250.115625h-1.6875L525.078125 438.875H488.09375l-67.78125 250.115625h-1.6875L353.375 438.875H315.125L400.34375 737z",
                                          fill: "#FFFFFF",
                                          "p-id": "57669",
                                        },
                                      }),
                                      _c("path", {
                                        attrs: {
                                          d:
                                            "M652.625 62l225 225h-168.75a56.25 56.25 0 0 1-56.25-56.25V62z",
                                          fill: "#B5C8FC",
                                          "p-id": "57670",
                                        },
                                      }),
                                    ]
                                  )
                                : item.fileType == "video"
                                ? _c(
                                    "svg",
                                    {
                                      staticClass: "icon",
                                      attrs: {
                                        t: "1634698615315",
                                        viewBox: "0 0 1024 1024",
                                        version: "1.1",
                                        xmlns: "http://www.w3.org/2000/svg",
                                        "p-id": "57796",
                                        width: "200",
                                        height: "200",
                                      },
                                    },
                                    [
                                      _c("path", {
                                        attrs: {
                                          d:
                                            "M202.625 62h450l225 225v618.75a56.25 56.25 0 0 1-56.25 56.25H202.625a56.25 56.25 0 0 1-56.25-56.25V118.25a56.25 56.25 0 0 1 56.25-56.25z",
                                          fill: "#7C8EEE",
                                          "p-id": "57797",
                                        },
                                      }),
                                      _c("path", {
                                        attrs: {
                                          d:
                                            "M679.259375 571.146875l-262.40625 156.43125a26.325 26.325 0 0 1-39.853125-22.725V391.990625c0-20.08125 22.303125-32.765625 39.853125-22.725l262.40625 156.43125c16.9875 10.575 16.9875 35.409375 0 45.45z",
                                          fill: "#FFFFFF",
                                          "p-id": "57798",
                                        },
                                      }),
                                      _c("path", {
                                        attrs: {
                                          d:
                                            "M652.625 62l225 225h-168.75a56.25 56.25 0 0 1-56.25-56.25V62z",
                                          fill: "#CAD1F8",
                                          "p-id": "57799",
                                        },
                                      }),
                                    ]
                                  )
                                : item.fileType == "pdf"
                                ? _c(
                                    "svg",
                                    {
                                      staticClass: "icon",
                                      attrs: {
                                        t: "1634698623082",
                                        viewBox: "0 0 1024 1024",
                                        version: "1.1",
                                        xmlns: "http://www.w3.org/2000/svg",
                                        "p-id": "57925",
                                        width: "200",
                                        height: "200",
                                      },
                                    },
                                    [
                                      _c("path", {
                                        attrs: {
                                          d:
                                            "M202.625 62h450l225 225v618.75a56.25 56.25 0 0 1-56.25 56.25H202.625a56.25 56.25 0 0 1-56.25-56.25V118.25a56.25 56.25 0 0 1 56.25-56.25z",
                                          fill: "#FA4E4E",
                                          "p-id": "57926",
                                        },
                                      }),
                                      _c("path", {
                                        attrs: {
                                          d:
                                            "M658.165625 668.121875a143.325 143.325 0 0 1-83.25-33.834375c-46.096875 10.125-89.971875 24.80625-133.81875 42.8625-34.875 62.015625-67.5 93.6-95.625 93.6-5.625 0-12.375-1.125-16.875-4.5-12.375-5.625-19.096875-18.05625-19.096875-30.459375 0-10.153125 2.25-38.334375 109.096875-84.6 24.75-45.1125 43.875-91.35 59.625-139.8375-13.5-27.05625-42.75-93.6-22.5-127.434375 6.75-12.403125 20.25-19.18125 34.875-18.05625 11.25 0 22.5 5.625 29.25 14.68125 14.625 20.278125 13.5 63.140625-5.625 126.28125a340.03125 340.03125 0 0 0 69.721875 90.253125c23.625-4.5 47.25-7.903125 70.846875-7.903125 52.875 1.125 60.75 25.93125 59.625 40.6125 0 38.334375-37.125 38.334375-56.25 38.334375zM343.25 738.040625l3.375-1.125c15.75-5.625 28.125-16.903125 37.125-31.584375-16.875 6.75-30.375 18.05625-40.5 32.709375z m149.56875-338.34375h-3.375c-1.125 0-3.375 0-4.5 1.125-4.5 19.18125-1.125 39.4875 6.75 57.515625 6.75-19.153125 6.75-39.459375 1.125-58.640625z m7.875 163.546875l-1.125 2.25-1.125-1.125a932.90625 932.90625 0 0 1-33.75 76.696875l2.25-1.125v2.25a653.625 653.625 0 0 1 76.5-22.55625l-1.125-1.125h3.375c-16.875-16.93125-32.625-36.1125-45-55.265625z m152.971875 59.765625c-10.125 0-19.125 0-29.25 2.25 11.25 5.625 22.5 7.875 33.75 9.028125 7.875 1.125 15.75 0 22.5-2.25 0-3.375-4.5-9.028125-27-9.028125z",
                                          fill: "#FFFFFF",
                                          "p-id": "57927",
                                        },
                                      }),
                                      _c("path", {
                                        attrs: {
                                          d:
                                            "M652.625 62l225 225h-168.75a56.25 56.25 0 0 1-56.25-56.25V62z",
                                          fill: "#FDB8B8",
                                          "p-id": "57928",
                                        },
                                      }),
                                    ]
                                  )
                                : _c(
                                    "svg",
                                    {
                                      staticClass: "icon",
                                      attrs: {
                                        t: "1634698631267",
                                        viewBox: "0 0 1024 1024",
                                        version: "1.1",
                                        xmlns: "http://www.w3.org/2000/svg",
                                        "p-id": "58054",
                                        width: "200",
                                        height: "200",
                                      },
                                    },
                                    [
                                      _c("path", {
                                        attrs: {
                                          d:
                                            "M202.625 62h450l225 225v618.75a56.25 56.25 0 0 1-56.25 56.25H202.625a56.25 56.25 0 0 1-56.25-56.25V118.25a56.25 56.25 0 0 1 56.25-56.25z",
                                          fill: "#576A95",
                                          "p-id": "58055",
                                        },
                                      }),
                                      _c("path", {
                                        attrs: {
                                          d:
                                            "M573.875 615.05v122.23125a30.515625 30.515625 0 0 1-30.628125 30.54375H480.78125A30.65625 30.65625 0 0 1 450.125 737.28125v-122.23125h123.75z m-30.628125 70.03125H480.78125v61.36875h62.49375v-61.36875z m29.615625-192.234375V554.1875h-61.509375v-61.36875h61.509375zM512.1125 62v61.875H573.875v61.875h-61.7625v57.290625H573.875v61.875h-61.734375v61.115625H573.875v61.115625h-61.734375v61.115625H450.125v-61.115625h61.734375V366.03125H450.125V304.915625h61.734375v-61.875H450.125v-61.875h61.734375V123.875H450.125V62h62.015625z",
                                          fill: "#FFFFFF",
                                          "p-id": "58056",
                                        },
                                      }),
                                      _c("path", {
                                        attrs: {
                                          d:
                                            "M652.625 62l225 225h-168.75a56.25 56.25 0 0 1-56.25-56.25V62z",
                                          fill: "#BBC3D4",
                                          "p-id": "58057",
                                        },
                                      }),
                                    ]
                                  ),
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "material-content-file-name" },
                            [_c("div", [_vm._v(_vm._s(item.name))])]
                          ),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "metarial-content-operation" }, [
                        _c(
                          "span",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.handleFileView(item, item.fileType)
                              },
                            },
                          },
                          [_vm._v("查看")]
                        ),
                        _c(
                          "span",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.handleFileDown(item.id)
                              },
                            },
                          },
                          [_vm._v("下载")]
                        ),
                      ]),
                    ]
                  )
                }),
                0
              ),
            ]),
            _c("div", { staticClass: "material-footer" }, [
              _c(
                "div",
                {
                  staticClass:
                    "material-footer-btn material-footer-btn--normal",
                  on: { click: _vm.handleFileDel },
                },
                [_vm._v(" 批量删除 ")]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "material-footer-btn material-footer-btn--active",
                  on: { click: _vm.handleFileDown },
                },
                [_vm._v(" 批量下载 ")]
              ),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "normal-brn" }, [
      _c("div", { staticClass: "operation-item-icon" }, [
        _c("i", { staticClass: "icon iconfont icon-nav-exit" }),
      ]),
      _c("div", { staticClass: "operation-item-title" }, [_vm._v("退出主讲")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }