<template>
  <el-tree
    class="super-search-downdrop-tree"
    @node-click="nodeClickHandler"
    ref="tree"
    node-key="_id"
    :data="options"
    :show-checkbox="multipe"
    :expand-on-click-node="multipe"
    highlight-current
    :props="{
      'label':'_name',
      'children':'children'
    }">
    <span class="custom-tree-node" slot-scope="{ node,data }">
      <span :class="{'node-disabled':nodeDisabled(data)}">{{ node.label }}</span>
    </span>
  </el-tree>
</template>
<script>
export default {
  name: "DropdownTree",
  data() {
    return {
      treeMap: {}
    };
  },
  props: {
    multipe: {
      type: Boolean,
      default: false
    },
    values: {
      type: [String, Array, Object],
      default() {
        return [];
      }
    },
    options: {
      type: Array,
      default() {
        return [];
      }
    },
    item: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  mounted() {
    if (this.options && this.options.length > 0) {
      this.updateTree();
    }
    this.updateTreeNodeSelectedForValues();
  },
  methods: {
    updateTreeNodeSelectedForValues() {
      let value = dsf.type(this.values) == "array" ? this.values : [this.values];
      let nodes = this.findNodePath(value);
      this.item.treeNodeSelected = [];
      if (nodes.length > 0) {
        _.each(nodes, (v) => {
          this.item.treeNodeSelected.push(v);
        });
      }
    },
    change() {
      this.$emit("change");
    },
    nodeClickHandler(data, node) {
      if (!this.multipe) {
        // if (data.children && data.children.length > 0) {
        //   return;
        // }
        this.$nextTick(() => {
          this.change();
        });
      }
    },
    nodeDisabled(data) {
      // if (!this.multipe) {
      //   return data.children && data.children.length > 0;
      // }
    },
    getSelectedData() {
      if (this.multipe) {
        return this.$refs.tree.getCheckedNodes();
      } else {
        return this.$refs.tree.getCurrentNode();
      }
    },
    deepNode(arr, parent) {
      _.each(arr, (v) => {
        if (dsf.isUnDef(v.__pathId__)) {
          Object.defineProperty(v, "__pathId__", {
            value: "",
            enumerable: false,
            writable: true
          });
        }
        if (parent) {
          v.__pathId__ = parent.__pathId__ + "$$" + v._id;
        } else {
          v.__pathId__ = v._id;
        }
        this.treeMap[v._id] = v;
        if (v.children) {
          this.deepNode(v.children, v);
        }
      });
    },
    findNodePath(arr) {
      let nodes = _.map(arr, (v) => {
        return this.treeMap[v];
      });
      nodes = _.filter(nodes, (v) => dsf.isDef(v));
      return nodes;
    },
    updateTree(arr) {
      this.deepNode(arr || this.options);
    },
    setCheckedKeys(keys) {
      this.$refs.tree.setCheckedKeys(keys);
    },
    setCurrentKey(keys) {
      this.$refs.tree.setCurrentKey(keys);
    },
    setSelectedKeys(keys) {
      if (this.multipe) {
        keys = dsf.type(keys) == "array" ? keys : [keys];
        this.$refs.tree.setCheckedKeys(keys);
      } else {
        keys = dsf.type(keys) == "array" ? keys[0] : "";
        this.$refs.tree.setCurrentKey(keys);
      }
    }
  },
  watch: {
    options(v, ov) {
      if (v && ov !== v) {
        this.updateTree();
      }
    },
    values(v, ov) {
      this.updateTreeNodeSelectedForValues();
    }
  }
};
</script>