var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "ds-control ds-page",
      class: _vm.getCss,
      style: _vm.pageStyle,
      attrs: { "page-type": _vm.pageType, "route-url": _vm.routeUrl },
    },
    [
      _c("div", { staticClass: "ds-page-head" }, [
        _c(
          "div",
          {
            ref: "head",
            staticClass: "head-inner",
            attrs: { "slot-name": _vm.slots[1].name },
          },
          [_vm._t(_vm.slots[1].name)],
          2
        ),
      ]),
      _c(
        "div",
        {
          ref: "content",
          staticClass: "ds-page-content",
          style: _vm.getBackgroundStyle,
        },
        [
          _c(
            "div",
            {
              staticClass: "ds-page-content-wrap",
              style: _vm.pageContentStyle,
            },
            [
              _c(
                "div",
                {
                  ref: "left",
                  staticClass: "ds-page-left",
                  style: _vm.leftStyle,
                  attrs: { "slot-name": _vm.slots[3].name },
                },
                [_vm._t(_vm.slots[3].name)],
                2
              ),
              _c(
                "div",
                {
                  ref: "center",
                  staticClass: "ds-page-center",
                  style: _vm.contentStyle,
                  attrs: { "slot-name": _vm.slots[0].name },
                },
                [
                  _vm._t("center-prepend"),
                  _vm._t(_vm.slots[0].name),
                  _vm._t("center-append"),
                ],
                2
              ),
              _c(
                "div",
                {
                  ref: "right",
                  staticClass: "ds-page-right",
                  style: _vm.rightStyle,
                  attrs: { "slot-name": _vm.slots[4].name },
                },
                [_vm._t(_vm.slots[4].name)],
                2
              ),
            ]
          ),
        ]
      ),
      _vm.hasFooter
        ? _c(
            "div",
            {
              staticClass: "ds-page-foot",
              style: { "background-color": _vm.footerBackground },
            },
            [
              _c(
                "div",
                {
                  ref: "foot",
                  staticClass: "foot-inner",
                  attrs: { "slot-name": _vm.slots[2].name },
                },
                [_vm._t(_vm.slots[2].name)],
                2
              ),
            ]
          )
        : _vm._e(),
      _c("div", {
        staticStyle: { display: "none" },
        domProps: { innerHTML: _vm._s(_vm.customCss) },
      }),
      _vm.gotoIcon
        ? _c("dsf-to-designer", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.isDesign,
                expression: "!isDesign",
              },
            ],
            ref: "eleDesgin",
            attrs: { title: _vm.title, "goto-preview": _vm.gotoPreview },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }