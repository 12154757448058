/*
 * @Descripttion: y轴公用配置
 * @Author: zhanghang
 * @Date: 2021-07-27 14:17:09
 * @LastEditors: zhanghang
 * @LastEditTime: 2021-07-27 14:24:53
 */
export default {
  //y 轴配置
  yAxis: [{
    type: 'value' ,
    // 坐标轴线设置
    axisLine: {
      show: true,
      lineStyle:{
        color:'#333'
      }
    },
    // 坐标刻度设置
    axisTick: {
      show: true
    },
    // 坐标标签设置
    axisLabel: {
      show: true,
      fontSize:12,
      rotate:0,
      color:'#333'
    },
    //分割线设置
    splitLine: {
      show: true
    },
    //坐标轴名字
    name:'坐标轴名称',
    // 坐标轴名字位置
    nameLocation:'end',
    nameTextStyle:{
      color:'#333',
      fontSize:12
    },
    //名字与轴线距离
    nameGap: 18 ,
    //坐标轴位置
    position:'left',

    // 数值最小值
    min:0 ,
    // 数值最大值
    max: function (value) {
      return value.max + 80;
    },
  }],
}
