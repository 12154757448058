<template>
  <div class="ds-super-search-filter-item">
    <div class="ds-super-search-filter-item-tag" :class="{'tag-open':item.isFilterExtend}">
      <!-- @click.stop="filterOpen(item)" -->
      <!--下拉框-->
      <template v-if="item.type=='enum'&&item.showModel=='select'||item.showModel=='tile'">
        <span class="ds-super-search-filter-item-tag-text">{{ item.label }}:</span>
        <template v-if="item.operator!='between'">
          <span class="ds-super-search-filter-item-value" :title="itemValue = formatChecked(item)">{{ itemValue.join(",") }}</span>
          <el-tag v-show="itemValue.length > 1" class="ds-super-search-filter-item-num" type="info" size="mini" disable-transitions>{{ itemValue.length }}</el-tag>
        </template>
        <template v-else>
          <span class="ds-super-search-filter-item-value" :title="itemValue = formatBetween(item)">{{ itemValue }}</span>
        </template>
        <!-- <i class="iconfont icon-xia1" style="margin-left: 5px;"></i> -->
      </template>
      <!--树下拉框-->
      <template v-if="item.type=='enum'&&item.showModel=='treeselect'">
        <span class="ds-super-search-filter-item-tag-text">{{ item.label }}:</span>
        <template v-if="item.operator!='between'">
          <span class="ds-super-search-filter-item-value" :title="itemValue = formatTreeSelected(item)">{{ itemValue.join(",") }}</span>
          <el-tag v-show="itemValue.length > 1" class="ds-super-search-filter-item-num" type="info" size="mini" disable-transitions>{{ itemValue.length }}</el-tag>
        </template>
        <template v-else>
          <span class="ds-super-search-filter-item-value" :title="itemValue = formatTreeSelected(item)">{{ itemValue.join(",") }}</span>
          <el-tag v-show="itemValue.length > 1" class="ds-super-search-filter-item-num" type="info" size="mini" disable-transitions>{{ itemValue.length }}</el-tag>
        </template>
        <!-- <i class="iconfont icon-xia1" style="margin-left: 5px;"></i> -->
      </template>
      <!--树平铺-->
      <template v-if="item.showModel=='treeTile'">
        <span class="ds-super-search-filter-item-tag-text">{{ item.label }}:</span>
        <template v-if="item.operator!='between'">
          <span class="ds-super-search-filter-item-value" :title="itemValue = formatTreeTile(item)">{{ itemValue.join(",") }}</span>
          <el-tag v-show="itemValue.length > 1" class="ds-super-search-filter-item-num" type="info" size="mini" disable-transitions>{{ itemValue.length }}</el-tag>
        </template>
        <template v-else>
          <span class="ds-super-search-filter-item-value" :title="itemValue = formatTreeTile(item)">{{ itemValue.join(",") }}</span>
          <el-tag v-show="itemValue.length > 1" class="ds-super-search-filter-item-num" type="info" size="mini" disable-transitions>{{ itemValue.length }}</el-tag>
        </template>
        <!-- <i class="iconfont icon-xia1" style="margin-left: 5px;"></i> -->
      </template>
      <!--文本框-->
      <template v-if="item.type=='text'">
        <span class="ds-super-search-filter-item-tag-text">{{ item.label }}:</span>
        <span class="ds-super-search-filter-item-value" :title="item.values">{{ item.values }}</span>
        <!-- <i class="iconfont icon-xia1" style="margin-left: 5px;"></i> -->
      </template>
      <!--文本框-->
      <template v-if="item.type=='number'">
        <span class="ds-super-search-filter-item-tag-text">{{ item.label }}:</span>
        <template v-if="item.operator=='between'">
          <span class="ds-super-search-filter-item-value" :title="itemValue = formatNumbers(item)">{{ itemValue }}</span>
        </template>
        <template v-else>
          <span class="ds-super-search-filter-item-value" :title="item.values">{{ item.values }}</span>
        </template>
      </template>
      <!--日期框-->
      <template v-if="item.type=='datetime'">
        <span class="ds-super-search-filter-item-tag-text">{{ item.label }}:</span>
        <span class="ds-super-search-filter-item-value" :title="itemValue = formatDate(item)">
          {{ itemValue }}
        </span>
        <!-- <template v-if="item.operator=='between'">
          <el-date-picker v-model="item.values" :type="betweenTypes[item.showModel]" @change="change(item)">
          </el-date-picker>
        </template>
        <template v-else>
          <el-date-picker v-model="item.values" :type="types[item.showModel]" @change="change(item)">
          </el-date-picker>
        </template> -->
      </template>
    </div>
    <!--下拉框展开面板-->
    <!-- <div v-if="item.type=='enum'&&item.showModel=='select'||item.showModel=='tile'" v-show="item.isFilterExtend" class="ds-super-search-filter-dropdown" @click.stop>
      <el-checkbox-group v-model="item.values">
        <el-checkbox v-for="op in (item.options||[])" :key="op.value||op._id" :label="op.value||op._id" @change="checkboxChange($event,item,op,true)">{{ op.text||op._name }}</el-checkbox>
      </el-checkbox-group>
      <div v-if="item.isAllowMultipe" style="text-align:center">
        <dsf-button size="small" style="min-width:auto" @click="multipleSure(item)">确定</dsf-button>
        <dsf-button size="small" :type="'plain'" style="min-width:auto" @click="multipleCancel(item)">取消</dsf-button>
      </div>
    </div> -->
    <!-- <div v-if="item.type=='enum'&&item.showModel=='treeselect'" style="padding:0px" v-show="item.isFilterExtend" class="ds-super-search-filter-dropdown" @click.stop>
      <el-scrollbar style="padding:0px" tag="div" wrap-class="el-select-dropdown__wrap" view-class="el-select-dropdown__list">
        <dropdown-tree values="item.values" :options="item.options" :multipe="item.isAllowMultipe" ref="tree" @change="treeNodeChange">
        </dropdown-tree>
      </el-scrollbar>
    </div> -->
    <!--文本框展开面板-->
    <!-- <div v-if="item.type=='text'" v-show="item.isFilterExtend" class="ds-super-search-filter-dropdown" @click.stop>
      <el-input v-model="item.values" @change="textChange(item)"></el-input>
    </div> -->
    <span class="item-close" @click="removeFilter(item)"><i class="iconfont icon-shanchu1"></i></span>
  </div>
</template>
<script>
import DropdownTree from "./dropdownTree.vue";
export default {
  name: "DsfSuperSearchFilter",
  components: { DropdownTree },
  inject: {
    $superSearch: {
      default: null
    }
  },
  props: {
    item: {
      type: Object,
      default() {
        return {};
      }
    },
    searchItems: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      types: {
        ymd: "date",
        ym: "month",
        y: "year"
      },
      betweenTypes: {
        ymd: "daterange",
        ym: "monthrange",
        y: "yearrange"
      },
      formats: {
        ymd: "yyyy-mm-dd",
        ym: "yyyy-mm",
        y: "yyyy"
      }
    };
  },
  mounted() {},
  methods: {
    filterOpen(item) {
      if (item.type == "enum") {
        item.isExtend = false;
        // item.isClickMultiple = false;
        item.isFilterExtend = !item.isFilterExtend;
        if (item.isAllowMultipe) {
          item.values_bak = dsf.type(item.values) == "array" ? item.values.slice(0) : item.values;
          item.multiple = true;
        }
      } else if (item.type == "text") {
        item.isFilterExtend = !item.isFilterExtend;
      }
      this.$nextTick(() => {
        this.$superSearch.emitHeight();
      });
    },
    formatBetween(item) {
      let custom = _.filter(item.customerBetween, (v) => dsf.isDef(v));
      if (custom.length >= 2) {
        return custom[0] + "-" + custom[1];
      } else {
        let options = item.options;
        if (options) {
          let result = _.filter(options, (v) => item.values.indexOf(v.value) >= 0);
          return _.map(result, "text").join(",");
        }
        return "";
      }
    },
    formatDate(item) {
      let format = this.formats[item.showModel];
      if (item.operator == "between") {
        return dsf.date.format(item.values[0], format) + "~" + dsf.date.format(item.values[1], format);
      } else {
        return dsf.date.format(item.values, format);
      }
    },
    formatChecked(item) {
      let options = item.options;
      if (options) {
        let result = _.filter(options, (v) => item.values.indexOf(v.value) >= 0);
        return _.map(result, "text");
      }
      return [];
    },
    formatTreeSelected(item) {
      let selected = item.treeNodeSelected;
      let values = dsf.type(selected) == "array" ? selected : [selected];
      return _.map(values, "_name");
    },
    formatTreeTile(item) {
      let options = item.options;
      if (options) {
        let result = _.filter(options, (v) => item.values.indexOf(v._id) >= 0);
        return _.map(result, "_name");
      }
      return [];
    },
    formatNumbers(item) {
      let v1 = item.values[0];
      let v2 = item.values[1];
      if (v1 == dsf.enums.numberBoundary.MIN) {
        v1 = null;
      }
      if (v2 == dsf.enums.numberBoundary.MAX) {
        v2 = null;
      }
      if (dsf.isUnDef(v1) && dsf.isDef(v2)) {
        return "小于等于" + item.values[1];
      }
      if (dsf.isDef(v1) && dsf.isUnDef(v2)) {
        return "大于等于" + item.values[0];
      }
      return item.values[0] + "~" + item.values[1];
    },
    multipleSure(item) {
      item.isExtend = false;
      item.multiple = false;
      // item.isClickMultiple = false;
      item.isFilterExtend = false;
      this.$nextTick(() => {
        this.$superSearch.emitHeight();
        this.change();
      });
    },
    multipleCancel(item) {
      if (item.values_bak) {
        item.values = item.values_bak;
      }
      item.isExtend = false;
      item.multiple = false;
      // item.isClickMultiple = false;
      item.isFilterExtend = false;
      this.$superSearch.collapseItems();
      this.$nextTick(() => {
        this.$superSearch.emitHeight();
      });
    },
    checkboxChange(v, item, option, isFilter) {
      if (!isFilter) {
        // this.$superSearch.filterAllClose();
      }
      if (!item.multiple) {
        if (v) {
          item.values = [option.value];
        } else {
          item.values = [];
        }
        if (item.operator == "between") {
          item.customerBetween = [];
        }
        this.$nextTick(() => {
          this.change();
        });
      }
    },
    treeSelectedToValues() {
      let values = null;
      if (this.multipe) {
        values = _.map(this.item.treeNodeSelected, "_id");
      } else {
        values = this.item.treeNodeSelected[0] ? _.map(this.item.treeNodeSelected, "_id") : [""];
      }
      this.item.values = values;
      this.$nextTick(() => {
        this.change();
      });
    },
    treeNodeChange() {
      if (!this.item.multiple) {
        this.item.isExtend = false;
        this.item.multiple = false;
        this.item.isClickMultiple = false;
        this.item.isFilterExtend = false;
      }
      let nodes = this.$refs.tree.getSelectedData();
      this.item.treeNodeSelected = dsf.type(nodes) == "array" ? nodes : [nodes];
      this.treeSelectedToValues();
    },
    removeFilter(item) {
      this.$emit("remove", item);
    },
    change() {
      this.$emit("change");
    }
  },
  watch: {
    "item.isFilterExtend"(v) {
      if (v) {
        this.$superSearch.filterAllClose(this.item);
      }
      if (this.item.showModel == "treeselect") {
        if (v) {
          //展开时刷新树选中
          let values = this.item.values;
          this.$refs.tree.setSelectedKeys(values);
        } else {
          //关闭时提交树选中信息
          let nodes = this.$refs.tree.getSelectedData();
          this.item.treeNodeSelected = dsf.type(nodes) == "array" ? nodes : [nodes];
          let newValues = _.map(this.item.treeNodeSelected, "_id").join(",");
          let oldValues = this.item.values.join(",");
          if (newValues != oldValues) {
            this.treeSelectedToValues();
          }
        }
      }
    }
  }
};
</script>