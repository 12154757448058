<template>
  <div v-show="showBar" class="ds-control ds-button-bar" :class="getCss">
    <div class="bar-wrap">
      <template v-if="isDesign&&showButtonsReal.length==0">
        <div style="text-align:center;background-color:#fff;width:100%;padding:5px 0px;font-style:italic;color:#c0c0c0;">
          未配置按钮
        </div>
      </template>
      <template v-for="(button, $index) in showButtonsReal">
        <template v-if="hasChild(button)">
          <el-popover :key="`children_button_popover_${$index}`" :popper-class="'ds-childrenbutton-popover'" width="auto" :trigger="button.trigger" :class="['ds-button__more', size]" :visible-arrow="false">
            <div class="ds-childrenbuttons-menu">
              <!-- <template v-for="(child, $childindex) in button.children"> -->
              <DsfButtonBar
                :is-design="isDesign"
                ref="buttonbarchildrenbutton"
                :more-button-style="moreButtonStyle"
                :more-button-text="moreButtonText"
                :max-number="1000"
                :buttons="button.children"
                :render-button="renderButton"
                :local="local"
                :class="{ 'ds-no-padding': true }" />
            </div>
            <DsfButton
              slot="reference"
              :key="`reference_${$index}`"
              :icon="getIcon(button)"
              :title="button.title"
              :disabled="button.disabled"
              :size="size"
              :type="button.type"
              :btn-style="button.btnStyle"
              :style="button.style"
              :loading="button.loading"
              :hasChild="true"
              @click="clickHandler(button, $event)">
              {{ button.text }}
            </DsfButton>
          </el-popover>
        </template>
        <!--上传按钮-->
        <template v-else-if="button.isUpload">
          <el-upload
            :key="`upload_button_${$index}`"
            class="ds-button-update"
            :disabled="button.disabled"
            :headers="getHeaders()"
            :accept="getFileAccept(button)"
            :data="getUploadData(button)"
            :show-file-list="false"
            :action="uploadUrl(button)"
            :on-progress="handleProgress"
            :on-change="handleChange"
            :on-success="handleAvatarSuccess(button)"
            :before-upload="beforeAvatarUpload(button)">
            <DsfButton :icon="getIcon(button)" :title="button.title" :disabled="button.disabled" :size="size" :type="button.type" :btn-style="button.btnStyle" :loading="button.loading">
              {{ button.text }}
            </DsfButton>
          </el-upload>
        </template>
        <!--流程按钮-->
        <template v-else-if="button.isLinkSend && button.isLinkSendFold && (button.actionName === 'system_flow_send' || button.actionName === 'system_batch_flow_send')">
          <el-popover :key="`morebutton_${$index}`" v-model="button.popVisible" :popper-class="'ds-morebutton-popover'" trigger="manual" placement="top" :class="['ds-button__flow']">
            <template v-if="flowButtons.length > 0" v-slot:default>
              <el-row :style="{ width: flowButtons.length > 5 ? '240px' : '80px' }">
                <el-col v-for="(flow, index) in flowButtons" :key="`flowButtons_${index}`" :span="flowButtons.length > 5 ? 8 : 24" style="text-align: center">
                  <template>
                    <DsfButton
                      :key="`isLinkSend_${index}`"
                      style="width: 80px; margin-bottom: 5px; margin-top: 5px"
                      :icon="getIcon(flow)"
                      :disabled="flow.disabled"
                      :size="size"
                      :type="flow.type"
                      :title="flow.title"
                      :btn-style="'icon-text'"
                      :hasChild="hasChild(flow)"
                      @click="clickHandler(flow, $event)">
                      {{ flow.text }}
                    </DsfButton>
                  </template>
                </el-col>
              </el-row>
            </template>
            <DsfButton slot="reference" :icon="getIcon(button)" :title="button.title" :disabled="button.disabled" :size="size" :type="button.type" :btn-style="button.btnStyle" @click="addFlowBtns(button)">
              {{ button.text }}
            </DsfButton>
          </el-popover>
        </template>
        <!--普通按钮-->
        <template v-else>
          <DsfButton
            :key="`__${$index}`"
            :icon="getIcon(button)"
            :title="button.title"
            :disabled="button.disabled"
            :size="size"
            :type="button.type"
            :btn-style="button.btnStyle"
            :style="button.style"
            :loading="button.loading"
            @click="clickHandler(button, $event)">
            {{ button.text }}
          </DsfButton>
        </template>
      </template>
      <!--更多-->
      <el-popover v-if="moreButtonsReal.length" :popper-class="'ds-morebutton-popover'" width="auto" trigger="click" :class="['ds-button__more', size]" @show="popoverShow" @hide="popoverClose">
        <template v-slot:default>
          <el-row :style="{ width: moreButtonsReal.length > 5 ? '240px' : '80px' }">
            <el-col v-for="(button, $index) in moreButtonsReal" style="text-align: center" :key="`more_buttonsReal_${$index}`" :span="moreButtonsReal.length > 5 ? 8 : 24">
              <template v-if="button.isUpload">
                <el-upload
                  :key="`is_upload_${$index}`"
                  class="ds-button-update"
                  :disabled="button.disabled"
                  :headers="getHeaders()"
                  :accept="getFileAccept(button)"
                  :data="getUploadData(button)"
                  :show-file-list="false"
                  :action="uploadUrl(button)"
                  :on-progress="handleProgress"
                  :on-change="handleChange"
                  :on-success="handleAvatarSuccess(button)"
                  :before-upload="beforeAvatarUpload(button)">
                  <DsfButton :icon="getIcon(button)" :title="button.title" :disabled="button.disabled" :size="size" :type="button.type" :btn-style="'icon-text'" :loading="button.loading" style="width: 80px; margin-bottom: 5px; margin-top: 5px">
                    {{ button.text }}
                  </DsfButton>
                </el-upload>
              </template>
              <template v-else>
                <DsfButton
                  :key="`_${$index}`"
                  style="width: 80px; margin-bottom: 5px; margin-top: 5px"
                  :icon="getIcon(button)"
                  :disabled="button.disabled"
                  :size="size"
                  :type="button.type"
                  :title="button.title"
                  :btn-style="'icon-text'"
                  :loading="button.loading"
                  @click="clickHandler(button, $event)">
                  {{ button.text }}
                </DsfButton>
              </template>
            </el-col>
          </el-row>
        </template>
        <DsfButton v-if="moreButtonsReal.length && moreButtonStyle != 'icon-text'" slot="reference" :title="moreButtonText" :btn-style="moreButtonStyle" :icon="moreFold ? 'shuangjiantouxia' : 'xiangshangzhanhang'" :size="size" class="plain" :class="[moreButtonStyle]">
          {{ moreButtonText }}
        </DsfButton>
        <DsfButton v-else-if="moreButtonsReal.length && moreButtonStyle == 'icon-text'" slot="reference" :title="moreButtonText" :btn-style="moreButtonStyle" icon="gengduo1" :size="size" class="plain" :class="[moreButtonStyle]">
          {{ moreButtonText }}
        </DsfButton>
      </el-popover>
    </div>
  </div>
</template>
<script>
export default dsf.component({
  name: "DsfButtonBar",
  ctrlCaption: "按纽组",
  mixins: [$mixins.control, $mixins.buttonBar],
  computed: {
    showBar() {
      if (this.isDesign) {
        return true;
      } else {
        return this.visible ? this.validButtons.length > 0 : false;
      }
    },
    getCss() {
      return {
        ...this.$getClass(),
        ["align-" + this.align]: true
      };
    },
    validButtons() {
      let buttons = _.filter(this.buttons, (button, index) => {
        if (this.showAndHide(button)) {
          this.disabledFactor(button);
          return true;
        }
        return false;
      });
      return buttons;
    },
    moreButtonsReal() {
      return _.filter(this.validButtons, (button, index) => {
        return index >= this.maxNumber;
      });
    },
    showButtonsReal() {
      return _.filter(this.validButtons, (button, index) => {
        return index < this.maxNumber;
      });
    }
  },
  data() {
    return {
      showButtons: [],
      moreButtons: [],
      moreFold: true,
      flowButtons: [],
      buttonHandler: dsf.buttons.getButtonHander,
      client: "pc",
      flowCurInfo: null,
      loading: null
    };
  },
  created() {},
  updated() {
    // console.log(this.buttons)
  },
  beforeDestroy() {},
  methods: {
    hasChild(btn) {
      return btn?.children && Array.isArray(btn.children) && btn.children.length > 0;
    },
    reloadData() {
      this.$forceUpdate();
    },
    disabledFactor(button) {
      let result = false;
      if (!this.isDesign) {
        let data = this.$expressParams(this.local);
        //非设计模式下运行表达式
        if (button.disabledFactor) {
          result = this.$eval(button.disabledFactor, data) !== false;
        } else {
          result = false;
        }
        if (this.disabledButton) {
          result = this.disabledButton(button, result, data);
        }
      }
      button.disabled = result;
    },
    showAndHide(button) {
      let result = true;
      let data = null;
      if (!this.isDesign) {
        data = this.$expressParams(this.local);
        //非设计模式下运行表达式
        if (button.if) {
          result = this.$eval(button.if, data) !== false;
          if (!result) {
            // dsf.warn(`${button?.text}被表达式隐藏了`);
          }
        } else {
          if (button.syncRead && (this?.owner?.readOnly || this?.$vm?.$data?.$forceReadOnly)) {
            result = false;
            // dsf.warn(`${button?.text}只读状态`);
          } else {
            result = true;
          }
        }

        // 流程按钮需要判断
        if (result && button.isSystem && (button.actionName.indexOf("system_flow") > -1 || button.actionName.indexOf("system_batch_flow") > -1)) {
          result = this.hideFlowBtn(button);
          if (!result) {
            dsf.warn(`${button?.text}被流程隐藏了，检查sPID，STATUS`);
          }
        }
        if (button.visible === false) {
          result = false;
          dsf.warn(`${button?.text}，是否可见属性为否`);
        }
        if (this.renderButton) {
          result = this.renderButton(button, result, data);
        }
      }
      return result;
    },
    getIcon(button) {
      let icon = button.icon;
      if (icon && icon.startsWith("icon-")) {
        return icon.replace("icon-", "");
      }
      return icon;
    },
    popoverShow() {
      this.moreFold = false;
    },
    popoverClose() {
      this.moreFold = true;
    },
    //触发指定按钮的点击事件
    triggerButtonClick(button) {
      this.clickHandler(button);
    },
    //上传类按钮处理 start
    uploadUrl(button) {
      let params = this.getButtonParams(button);
      let url = params[0];
      //this.$replace(button.params[0].value || button.params[0].default);
      return dsf.url.getWebPath(url);
    },
    getFileAccept(button) {
      let params = this.getButtonParams(button);
      let allowList = params[2]?.split("|");
      let map = _.map(allowList, (el) => {
        return dsf.mime[el.toLowerCase()];
      });
      return map.join(",");
    },
    beforeAvatarUpload(button) {
      return ((file) => {
        let params = this.getButtonParams(button);
        let allowList = params[2]?.split("|");
        let mark = false;
        for (let i = 0; i < allowList.length; i++) {
          if (file.name.toLowerCase().endsWith("." + allowList[i].toLowerCase())) {
            mark = true;
            break;
          }
        }
        if (!mark) {
          dsf.layer.message("只能上传" + allowList.join(",") + "类型的文件", false);
          this.loading && dsf.layer.closeLoading(this.loading);
          return false;
        }
      }).bind(this);
    },
    handleProgress(e, file, fileList) {
      // 进度条
    },
    handleChange(file, fileList) {
      if (file.status === "ready") {
        this.loading = dsf.layer.loading();
      }
    },
    handleAvatarSuccess(button) {
      return ((res, file) => {
        dsf.layer.closeLoading(this.loading);
        this.clickHandler(button, undefined, res);
      }).bind(this);
    },
    getHeaders() {
      return dsf.getDefaultHttpHeader();
    },
    getUploadData(button) {
      let params = this.getButtonParams(button);
      let data = params[1];
      data = dsf.url.queryStringParse(data);
      return data;
    },
    hidePopVisible(button) {
      this.buttons.forEach((btn) => {
        if (btn.popVisible) {
          btn.popVisible = false;
        }
      });
      document.removeEventListener("click", this.hidePopVisible);
    }
  }
});
</script>
