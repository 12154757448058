<template>
  <div style="width:100%">
    <template v-if="!isBetween">
      <div class="ds-supersearch-input">
        <el-input ref="input" :controls="false" v-model.trim="value" @input.native="inputHandle()" :placeholder="$placeholder||'请输入'" @change="change(item)">
        </el-input>
      </div>
    </template>
    <template v-else>
      <div class="ds-supersearch-input">
        <div :class="{'ds-supersearch-input-range':isBetween,'ds-supersearch-input-focus':isFocus}">
          <el-input
            class="number-input"
            ref="sinput"
            :controls="false"
            @input="inputHandle(0)"
            @focus="inputFocus"
            @blur="inputBlur"
            v-model.trim="value[0]"
            :placeholder="$placeholder[0]||'请输入'"
            @mousedown.native="clickHandler"
            @change="change(item)" />
          <span @click.stop.prevent @mousedown.stop.prevent>至</span>
          <el-input
            autocomplete="off"
            ref="einput"
            class="number-input"
            :controls="false"
            @input="inputHandle(1)"
            @focus="inputFocus"
            @blur="inputBlur"
            v-model.trim="value[1]"
            :placeholder="$placeholder[1]||'请输入'"
            @mousedown.native="clickHandler"
            @change="change(item)" />
        </div>
      </div>
    </template>

  </div>
</template>
<script>
// const _min = -999999999999;
// const _max = 999999999999;
export default {
  name: "DsfSuperSearchNumber",
  props: {
    values: {
      type: [Array, String, Number],
      default() {
        return [];
      }
    },
    item: {
      type: Object,
      default() {
        return {};
      }
    },
    placeholder: {
      type: [String, Array],
      default() {
        return ["请输入"];
      }
    }
  },
  data() {
    return {
      value: null,
      isFocus: false,
      isActive: false
    };
  },
  computed: {
    $values() {
      let v = this.values;
      if (this.isBetween) {
        return dsf.type(v) == "array" ? v : [v];
      } else {
        return dsf.type(v) == "array" ? v.join("") : v;
      }
    },
    $placeholder() {
      let p = this.placeholder;
      if (this.isBetween) {
        p = dsf.type(p) == "array" ? p : p.split(";");
        if (this.isBetween) {
          if (!p[1]) {
            p[1] = p[0];
          }
        }
        return p;
      }
      return this.placeholder;
    },
    isBetween() {
      return this.item.operator == "between";
    }
  },
  beforeCreate() {},
  created() {
    // this.value = this.$values;
  },
  beforeMount() {},
  mounted() {},
  beforeDestroy() {},
  destroyed() {},
  methods: {
    clickHandler() {
      this.isActive = true;
    },
    inputHandle(index) {
      if (dsf.isDef(index)) {
        this.$set(this.value, index, this.clearNoNumber(this.value[index]));
      } else {
        this.value = this.clearNoNumber(this.value);
      }
    },
    inputFocus() {
      this.isFocus = true;
      this.isActive = true;
    },
    inputBlur() {
      this.isFocus = false;
      this.isActive = false;
    },
    change() {
      let v = this.value;
      if (this.isBetween) {
        _.each(v, (it, i) => {
          v[i] = dsf.isDef(v[i]) && dsf.isNumber(v[i]) ? parseFloat(v[i]) : "";
          if (dsf.isUnDef(v[i]) || v[i] === "") {
            v[i] = i == 0 ? dsf.enums.numberBoundary.MIN : dsf.enums.numberBoundary.MAX;
          }
        });
        if (v[0] > v[1]) {
          let temp = v[1];
          this.$set(this.value, 1, v[0]);
          this.$set(this.value, 0, temp);
        }
      }
      this.$emit("update:values", v);
      window.setTimeout(() => {
        if (this.isBetween) {
          if (this.isActive) {
            return;
          }
        }
        this.$nextTick(() => {
          this.$emit("change");
        });
      }, 100);
    },
    // isActive() {
    //   let sinput = this.$refs.sinput.$el.querySelector("input");
    //   let einput = this.$refs.einput.$el.querySelector("input");
    //   return document.activeElement == sinput || document.activeElement == einput;
    // },
    clearNoNumber(v) {
      v = v.replace(/^\.+|[^\d.]/g, (s1) => {
        if (s1 == "-") {
          return s1;
        }
        return "";
      });
      return v;
    }
  },

  watch: {
    values: {
      handler(v, ov) {
        if (this.isBetween) {
          this.value = [undefined, undefined];
          _.each(this.$values, (it, i) => {
            if (parseFloat(it) == dsf.enums.numberBoundary.MAX || parseFloat(it) == dsf.enums.numberBoundary.MIN) {
              this.$set(this.value, i, undefined);
            } else {
              if (dsf.isDef(it)) {
                this.$set(this.value, i, it);
              } else {
                this.$set(this.value, i, undefined);
              }
            }
          });
        } else {
          this.value = this.$values;
        }
      },
      immediate: true
    }
  }
};
</script>