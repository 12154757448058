var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "select-dept-tree" }, [
    _vm.showSearch
      ? _c(
          "div",
          { staticClass: "dsf-tree-search" },
          [
            _c(
              "el-input",
              {
                attrs: { placeholder: "输入名称" },
                on: { input: _vm.search },
                model: {
                  value: _vm.searchKey,
                  callback: function ($$v) {
                    _vm.searchKey = $$v
                  },
                  expression: "searchKey",
                },
              },
              [
                _c("i", {
                  staticClass: "iconfont el-input__icon icon-Magnifier",
                  attrs: { slot: "suffix" },
                  slot: "suffix",
                }),
              ]
            ),
          ],
          1
        )
      : _vm._e(),
    _c(
      "div",
      {
        staticClass: "dsf-select-tree",
        style: { height: _vm.showSearch ? "340px" : "100%" },
      },
      [
        _c("el-tree", {
          ref: "treeForm",
          attrs: {
            data: _vm.treeData,
            props: _vm.defaultProps,
            "node-key": "id",
            "show-checkbox": "",
            "default-expand-all": true,
            "default-checked-keys": _vm.checked,
            "check-strictly": _vm.multi ? false : true,
            "check-on-click-node": true,
            "expand-on-click-node": false,
            "filter-node-method": _vm.filterData,
          },
          on: { "check-change": _vm.selectTree },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function (ref) {
                var node = ref.node
                var data = ref.data
                return _c(
                  "div",
                  { staticClass: "custom-tree-node" },
                  [
                    data[_vm.defaultProps.children] !== undefined
                      ? [
                          _c("i", {
                            staticClass: "iconfont",
                            class: [
                              node.expanded ? "icon-dakai" : "icon-wenjianjia",
                            ],
                          }),
                          _c("span", {
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.dsf.safe.xss(data[_vm.defaultProps.label])
                              ),
                            },
                          }),
                        ]
                      : [
                          _c("i", {
                            staticClass: "iconfont icon-shiyongwendang",
                          }),
                          _c("span", {
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.dsf.safe.xss(data[_vm.defaultProps.label])
                              ),
                            },
                          }),
                        ],
                  ],
                  2
                )
              },
            },
          ]),
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }