var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ds-control ds-nav-catalogue ds-no-padding" },
    [
      _vm.canEditor
        ? _c(
            "div",
            { staticClass: "header", class: ["header-" + _vm.editorBtAlign] },
            [
              _vm._t(
                "buttons",
                function () {
                  return [
                    _vm.isEditor
                      ? _c(
                          "div",
                          { staticClass: "bts" },
                          [
                            _vm._t(
                              "button",
                              function () {
                                return [
                                  _c("dsf-button", {
                                    attrs: {
                                      text: "取消",
                                      size: "small",
                                      type: "plain",
                                    },
                                    on: { click: _vm.endEditor },
                                  }),
                                  _c("dsf-button", {
                                    attrs: { text: "重置", size: "small" },
                                    on: { click: _vm.resetEditor },
                                  }),
                                  _c("dsf-button", {
                                    attrs: { text: "保存", size: "small" },
                                    on: { click: _vm.saveEditor },
                                  }),
                                ]
                              },
                              { owner: _vm.owner }
                            ),
                          ],
                          2
                        )
                      : _c(
                          "div",
                          { staticClass: "bts" },
                          [
                            _c("dsf-button", {
                              attrs: { text: _vm.editorBtText, size: "small" },
                              on: { click: _vm.startEditor },
                            }),
                          ],
                          1
                        ),
                  ]
                },
                { owner: _vm.owner }
              ),
            ],
            2
          )
        : _vm._e(),
      _c("dsf-virtual-scroll", { attrs: { height: "0" } }, [
        _vm.isDesign && _vm.dataSource === "http"
          ? _c("div", [_vm._v(" 数据由接口返回 ")])
          : !_vm.isEditor
          ? _c(
              "div",
              { staticClass: "ds-nav-catalogue-list" },
              _vm._l(_vm.realList, function (item) {
                return _c("nav-catalogue-item", {
                  key: item._id,
                  attrs: { item: item },
                })
              }),
              1
            )
          : _vm.scriptLoaded
          ? _c(
              "div",
              { staticClass: "ds-nav-catalogue-list editor" },
              [
                _c(
                  "draggable",
                  _vm._b(
                    {
                      staticClass: "dragArea",
                      model: {
                        value: _vm.listCopy,
                        callback: function ($$v) {
                          _vm.listCopy = $$v
                        },
                        expression: "listCopy",
                      },
                    },
                    "draggable",
                    _vm.dragOptions,
                    false
                  ),
                  _vm._l(_vm.listCopy, function (item) {
                    return _c("nav-catalogue-item-for-editor", {
                      key: item._id,
                      attrs: { item: item },
                    })
                  }),
                  1
                ),
              ],
              1
            )
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }