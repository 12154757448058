const CascaderPanel = window.ELEMENT.CascaderPanel;
const CascaderMenu = CascaderPanel.components.CascaderMenu
const CascaderNode = CascaderMenu.components.CascaderNode;

CascaderNode.mounted = function() {
  this.node.vm = this;
}
CascaderNode.beforeDestroy = function() {
  this.node.vm = null;
}
CascaderNode.methods.handleExpand = function(e) {
  const { panel, node, isDisabled, config } = this;
  const { multiple, checkStrictly, lazy } = config;

  if (!checkStrictly && isDisabled || node.loading) return;

  if (e && checkStrictly) {
    let if1 = !lazy && !node.hasChildren;
    let if2 = lazy && (node.loaded && !node.hasChildren);
    if (if1 || if2) {
      if (multiple) {
        this.handleMultiCheckChange(!this.isChecked);
      } else {
        this.handleCheckChange();
      }
      return;
    }
  }

  if (config.lazy && !node.loaded) {
    panel.lazyLoad(node, () => {
      const { isLeaf } = this;
      if (!isLeaf) this.handleExpand();
      if (multiple) {
        const checked = isLeaf ? node.checked : false;
        this.handleMultiCheckChange(checked);
      } else if (isLeaf || !node.hasChildren) {
        this.handleCheckChange();
      }
    });
  } else {
    panel.handleExpand(node);
  }
}
