//<template functional>
//  <el-option-group v-if="props.item[_vm.parent.childrenField] && props.item[_vm.parent.childrenField].length" :label="_vm.parent._formatOptionText(props.item)">
//    <select-item v-for="item2 in props.item.children" :key="item2[_vm.parent.valueField]" :item="item2" />
//  </el-option-group>
//  <el-option :show="props.show" v-else :label="_vm.parent._formatSelectedText(props.item)" :value="props.item[_vm.parent.valueField]">
//    {{ _vm.parent._formatOptionText(props.item) }}
//  </el-option>
//</template> 

//<script> 
export default {
  name: "selectItem",
  functional: true,
  props: {
    item: {
      type: Object,
      required: true
    },
    show: {
      type: Boolean,
      default: true
    }
  },
  render(h, vm) {
    let props = vm.props;
    let showGroup = props.item[vm.parent.childrenField] && props.item[vm.parent.childrenField].length;
    if (showGroup) {
      let label = vm.parent._formatOptionText(props.item);
      return (
        <el-option-group label={label}>
          {
            _.map(props?.item?.children||[], (item2) => {
              return <select-item key={item2[vm.parent.valueField]} item={item2}></select-item>;
            })
          }
        </el-option-group>
      );
    } else {
      let label = vm.parent._formatSelectedText(props.item);
      let value = props.item[vm.parent.valueField];
      return <el-option show={props.show} label={label} value={value}></el-option>;
    }
  }
};
// </script>