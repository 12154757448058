const Tree = ELEMENT.Tree;

if (!Tree.render_override) {
  const render = Tree.render;
  Tree.render = function (h) {
    let vnodes = render.call(this, h);

    //替换el-tree的空数据节点,使其支持插槽
    if (this.isEmpty) {
      let emptySlot = this?.$slots?.empty;
      if (emptySlot && emptySlot.length > 0) {
        if (vnodes.children && vnodes.children.length > 0) {
          let max = 5, count = 0, emptyVNode = null;
          //从子节点底部往上找5个节点，从el-tree源码上来看空节点如果出现一定在倒数第二个；
          for (let i = vnodes.children.length - 1; i >= 0; i--) {
            let vnode = vnodes.children[i];
            if (vnode?.data?.staticClass == "el-tree__empty-block") {
              emptyVNode = vnode;
              break;
            }
            if (++count >= max) {
              break;
            }
          }
          //将empty插槽内的vnode替换掉原来的
          emptyVNode && (emptyVNode.children = emptySlot);
        }
      }
    }
    return vnodes;
  }
  Tree.render_override = true;
}
