var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ds-control ds-form-item ds-textbox", class: _vm.getCss },
    [
      _vm.simple && _vm.readOnly && !_vm.isDesign
        ? [
            _vm._v(" " + _vm._s(_vm.value) + " "),
            _vm._t("error", function () {
              return [
                _vm.errors.length > 0
                  ? _c("div", { staticClass: "ds-error-text" }, [
                      _vm._v(" " + _vm._s(_vm.errorsMsg) + " "),
                    ])
                  : _vm._e(),
              ]
            }),
          ]
        : [
            _vm.showLabel
              ? _c(
                  "DsfFieldLabel",
                  {
                    style: _vm.getLabelWidthStyle(),
                    attrs: {
                      mode: _vm.showDataCaptionMode,
                      "show-icon": _vm.showDataCaptionIcon,
                      trigger: _vm.showDataCaptionTrigger,
                      "data-caption": _vm.dataCapion,
                      "is-design": _vm.isDesign,
                      owner: _vm._self,
                    },
                  },
                  [_vm._v(" " + _vm._s(_vm.$t(_vm.label)) + " ")]
                )
              : _vm._e(),
            _c(
              "div",
              {
                staticClass: "ds-form-block",
                style: _vm.getFormItemBlockMarginLeft(),
              },
              [
                !_vm.readOnly
                  ? _c("el-autocomplete", {
                      ref: "auto",
                      class: { "ds-error-border": _vm.errors.length > 0 },
                      attrs: {
                        "fetch-suggestions": _vm.querySearchAsync,
                        placeholder: _vm.placeholder,
                        disabled: _vm.disabled,
                        "trigger-on-focus": _vm.triggerOnFocus,
                        debounce: _vm.delay,
                      },
                      on: {
                        select: _vm.handleSelect,
                        blur: _vm.handleBlur,
                        change: _vm.handleChange,
                        input: _vm.handleInput,
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (ref) {
                              var item = ref.item
                              return [
                                _vm._t(
                                  "default",
                                  function () {
                                    return [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            item[_vm.itemShowKey] ||
                                              item[_vm.valueKey]
                                          ) +
                                          " "
                                      ),
                                    ]
                                  },
                                  null,
                                  { item: item }
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                      model: {
                        value: _vm.inputValue,
                        callback: function ($$v) {
                          _vm.inputValue = $$v
                        },
                        expression: "inputValue",
                      },
                    })
                  : _vm.readOnly
                  ? _c("div", { staticClass: "ds-form-readonly" }, [
                      _c("span", [_vm._v(_vm._s(_vm.value))]),
                    ])
                  : _vm._e(),
                _vm._t("error", function () {
                  return [
                    _vm.errors.length > 0
                      ? _c("div", { staticClass: "ds-error-text" }, [
                          _vm._v(" " + _vm._s(_vm.errorsMsg) + " "),
                        ])
                      : _vm._e(),
                  ]
                }),
              ],
              2
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }