<template>
  <div class="dsf-control ds-nav-menu" :class="rootClass" :style="rootStyle">
    <div v-if="pages == 1" class="ds-nav-menu-group">
      <template v-for="item in menuList[0]">
        <nav-menu-item :key="item._id" :item="item"></nav-menu-item>
      </template>
    </div>
    <template v-else-if="menuList.length > 1">
      <el-carousel
        ref="carousel"
        :style="{ 'min-height': this.minHeight }"
        :loop="false"
        :autoplay="false"
        arrow="never"
        @change="activeIndex = $event"
      >
        <el-carousel-item v-for="(group, index) in menuList" :key="index">
          <div class="ds-nav-menu-group">
            <template v-for="item in group">
              <nav-menu-item :key="item._id" :item="item"></nav-menu-item>
            </template>
          </div>
        </el-carousel-item>
      </el-carousel>
      <template v-if="arrow && pages > 1">
        <div
          class="ds-nav-menu-arrow left"
          :class="{ disabled: activeIndex == 0 }"
        >
          <i
            class="iconfont icon-arrow-left2"
            @click="$refs.carousel.prev()"
          ></i>
        </div>
        <div
          class="ds-nav-menu-arrow right"
          :class="{ disabled: activeIndex == menuList.length - 1 }"
        >
          <i
            class="iconfont icon-arrow-right2"
            @click="$refs.carousel.next()"
          ></i>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import NavMenuItem from "./items/navMenuItem";

const defaultData = [
  {
    _id: 1,
    picture: "",
    icon: "icon-location-solid",
    color: "",
    foreColor: "",
    openType: "1",
    name: "坐标",
    url: "",
  },
  {
    _id: 2,
    picture: "",
    icon: "icon-xiaolianmanyifuwu",
    color: "",
    foreColor: "",
    openType: "1",
    name: "笑脸",
    url: "",
  },
  {
    _id: 3,
    picture: "",
    icon: "icon-tab-addressbook-fill",
    color: "",
    foreColor: "",
    openType: "1",
    name: "通讯录",
    url: "",
  },
  {
    _id: 4,
    picture: "",
    icon: "icon-tab_message_d",
    color: "",
    foreColor: "",
    openType: "1",
    name: "消息中心",
    url: "",
  },
  {
    _id: 5,
    picture: "",
    icon: "icon-location-solid",
    color: "",
    foreColor: "",
    openType: "1",
    name: "坐标",
    url: "",
  },
  {
    _id: 6,
    picture: "",
    icon: "icon-xiaolianmanyifuwu",
    color: "",
    foreColor: "",
    openType: "1",
    name: "笑脸",
    url: "",
  },
  {
    _id: 7,
    picture: "",
    icon: "icon-tab-addressbook-fill",
    color: "",
    foreColor: "",
    openType: "1",
    name: "通讯录",
    url: "",
  },
  {
    _id: 8,
    picture: "https://img.yzcdn.cn/vant/cat.jpeg",
    icon: "icon-tab_message_d",
    color: "",
    foreColor: "",
    openType: "1",
    name: "消息中心",
    url: "",
  },
  {
    _id: 9,
    picture: "",
    icon: "icon-tab_message_d",
    color: "",
    foreColor: "",
    openType: "1",
    name: "消息中心",
    url: "",
  },
];

export default dsf.component({
  name: "DsfNavMenu",
  mixins: [$mixins.control],
  ctrlCaption: "快捷菜单",
  design: {
    isMask: false,
  },
  components: {
    NavMenuItem,
  },
  props: {
    // 一行几列
    colNum: {
      type: Number,
      default: 5,
    },
    // 一页几行
    // 0表示不限行数，且页数将固定1
    rowNum: {
      type: Number,
      default: 0,
    },
    // 最多几页
    // 0表示无限制
    maxPages: {
      type: Number,
      default: 0,
    },
    // 组件高度
    height: {
      type: String,
      default: "100%",
    },
    // 组件最小高度
    minHeight: {
      type: String,
      default: "130px",
    },
    // 是否显示轮播切换箭头
    arrow: {
      type: Boolean,
      default: false,
    },
    // 是否强制使用默认样式
    useDefaultStyle: {
      type: Boolean,
      default: false,
    },
    // 是否显示更多按钮
    hasMore: {
      type: Boolean,
      default: false,
    },
    /*****更多按钮*****/
    // 更多按钮显示文字
    moreText: {
      type: String,
      default: "更多",
    },
    // 更多按钮显示图标
    moreIcon: {
      type: String,
      default: "icon-quanbu",
    },
    // 更多按钮跳转链接
    moreUrl: {
      type: String,
      default: "",
    },
    // 更多按钮跳转方式
    moreOpentype: {
      type: String,
      default: "0",
    },
    /*****样式*****/
    // 鼠标特效
    hoverAnimation: {
      type: String,
      default: "-",
    },
    // 默认图标
    defaultIcon: {
      type: String,
      default: "icon-icon_yingyongguanli",
    },
    // 展示优先级。img图片优先，icon图标优先
    showPriority: {
      type: String,
      default: "img",
    },
    menuHasBg: {
      type: Boolean,
      default: true,
    },
    menuBgColor: {
      type: String,
      default: "#fff",
    },
    menuBgShadow: {
      type: Boolean,
      default: true,
    },
    menuColor: {
      type: String,
      default: "theme",
    },
    menuShadow: {
      type: Boolean,
      default: true,
    },
    menuBorderRadius: {
      type: String,
      default: "5px",
    },
    menuBorderSize: {
      type: String,
      default: "50px",
    },
    menuIconSize: {
      type: String,
      default: "30px",
    },
    // 按钮尺寸
    menuBtSize: {
      type: String,
      default: "100px",
    },
    // 按钮圆角
    menuBtRadius: {
      type: String,
      default: "5px",
    },
    // 数据来源
    dataSource: {
      type: String,
      default: "http",
    },
    dataSourceUrl: {
      type: String,
      default: "",
    },
    dataSourceList: {
      type: Array,
      default() {
        return [];
      },
    },
    // 二次开发传入的方法。
    itemClick: {
      type: Function,
      required: false,
    },
    clickBefore: {
      type: Function,
      required: false,
    },
  },
  data() {
    return {
      // 请求状态
      loading: false,
      activeIndex: 0,
      isLoading: true,
      data: [],
    };
  },
  computed: {
    dataList() {
      if (this.isDesign) {
        if (this.dataSource == "http" || !this.dataSourceList.length) {
          return defaultData;
        }
      }
      if (this.dataSource == "http") {
        return this.data;
      }
      return this.dataSourceList;
    },
    rootClass() {
      let res = [this.hoverAnimation];
      if (this.menuHasBg) {
        res.push("hasBg");
        if (this.menuBgColor == "theme") {
          res.push("bgTheme");
        }
      }
      if (this.menuBgShadow) {
        res.push("bgShadow");
      }
      if (this.menuShadow) {
        res.push("iconShadow");
      }
      if (this.menuColor == "theme") {
        res.push("iconTheme");
      }
      if (this.arrow && this.pages > 1) {
        res.push("arrow");
      }
      return res;
    },
    rootStyle() {
      let res = { "min-height": this.minHeight };
      if (this.isDesign) {
        this.$dispatch("design-height-change", this.height);
        res.height = "100%";
      } else {
        res.height = this.height;
      }
      return res;
    },
    itemStyle() {
      let w = (100 / this.colNum).toFixed(2) + "%";
      return {
        width: w,
        flex: "0 0 " + w,
      };
    },
    itemBgStyle() {
      let res = {
        width: this.menuBorderSize,
        height: this.menuBorderSize,
        "border-radius": this.menuBorderRadius,
      };
      if (this.menuHasBg && this.menuBgColor != "theme") {
        res["background-color"] = this.menuBgColor;
      }
      return res;
    },
    itemBtStyle() {
      return {
        width: this.menuBtSize,
        height: this.menuBtSize,
        "border-radius": this.menuBtRadius,
      };
    },
    itemIconStyle() {
      let res = {
        "font-size": this.menuIconSize,
      };
      if (this.menuColor != "theme") {
        res["color"] = this.menuColor;
      }
      return res;
    },
    // 页数
    pages() {
      if (!this.rowNum) {
        return 1;
      }
      let pages = Math.ceil(this.dataList.length / this.pageSize);
      if (!this.maxPages) {
        return pages;
      }
      return Math.min(this.maxPages, pages);
    },
    // 一页几个
    pageSize() {
      return (this.colNum || 1) * this.rowNum;
    },
    moreInfo() {
      if (!this.hasMore || this.loading) return {};
      return {
        _id: "more",
        picture: "",
        icon: this.moreIcon,
        color: "",
        bgColor: "",
        url: this.moreUrl,
        openType: this.moreOpentype,
        name: this.moreText,
      };
    },
    menuList() {
      let res = [];
      if (!this.rowNum) {
        res = this.hasMore
          ? [[...this.dataList, this.moreInfo]]
          : [this.dataList];
      } else {
        let pageSize = this.pageSize;
        for (let i = 0; i < this.pages; i++) {
          res.push(this.dataList.slice(i * pageSize, i * pageSize + pageSize));
        }
        if (!res.length) {
          res = [[]];
        }
        if (this.hasMore) {
          let lastItem = res.length - 1 < 0 ? null : res[res.length - 1];
          if (lastItem) {
            if (this.maxPages == res.length && lastItem.length == pageSize) {
              lastItem.pop();
            }
            lastItem.push(this.moreInfo);
          } else {
            res.push([this.moreInfo]);
          }
        }
      }
      // 补位
      let lastPage = res[res.length - 1];
      if (this.rowNum && lastPage.length < this.pageSize) {
        for (let i = 0, l = this.pageSize - lastPage.length; i < l; i++) {
          lastPage.push({
            id: dsf.uuid(8),
            hide: true,
          });
        }
      }
      return res;
    },
  },
  watch: {
    dataSourceUrl() {
      this.init();
    },
  },
  created() {
    this.init();
  },
  methods: {
    reloadData() {
      this.init();
    },
    init() {
      if (this.isDesign || this.dataSource != "http") {
        return;
      }
      if (this.dataSourceUrl == "") {
        this.data = [];
        return;
      }
      this.loading = true;
      this.$http
        .get(this.dataSourceUrl)
        .done(({ success, data, message }) => {
          if (success) {
            this.data = data;
            this.loading = false;
          } else {
            dsf.layer.message(message, false);
          }
        })
        .error((err) => {
          dsf.layer.message(err.message, false);
        })
        .always(() => {
          this.isLoading = false;
        });
    },
    _clickBefore(next, ...params) {
      if (this.isDesign) return;
      let res = this.clickBefore?.(...params);
      if (res && res.then) {
        res.then((r) => {
          if (r !== false) {
            next(...params);
          }
        });
      } else if (res !== false) {
        next(...params);
      }
    },
    onClick(item) {
      if (this.itemClick) {
        let res = this.itemClick(item);
        if (res === false) return;
        if (res.then) {
          res.then((r) => {
            if (r !== false) {
              this.openPage(item);
            }
          });
          return;
        }
      }
      this.openPage(item);
    },
    getImgUrl({ picture, icon }) {
      if (this.showPriority == "img") {
        return picture;
      }
      if (!icon || icon == "") {
        return picture;
      }
      return "";
    },
    getItemStyle({ color }) {
      if (this.useDefaultStyle || !this.menuHasBg) {
        return {};
      }
      if (color != "") {
        return {
          background: color,
        };
      }
      return {};
    },
    getIconStyle({ foreColor }) {
      if (this.useDefaultStyle) {
        return {};
      }
      if (foreColor != "") {
        return {
          color: foreColor,
        };
      }
      return {};
    },
    getIcon(icon) {
      if (!icon || icon == "") {
        icon = this.defaultIcon || "icon-icon_yingyongguanli";
      }
      if (!icon) return "";
      return !icon.indexOf("icon-") ? icon : "icon-" + icon;
    },
    openPage(menu) {
      let url = menu.url;
      if (!url || url == "") {
        return;
      }
      if (url.indexOf("#") == 0 && url.indexOf("#/") != 0) {
        url = url.replace("#", "#/");
      }
      url = this.$replace(url, this.local);
      let opt = {
        url,
        state: menu.openType || menu.opentype,
      };
      if (opt.state == 3) {
        opt["title"] = menu.dialogTitle || menu.name || undefined;
        opt["width"] = menu.dialogWidth || "70vw";
        opt["height"] = menu.dialogHeight || "60vh";
      }
      this.$open(opt);
    },
  },
});
</script>