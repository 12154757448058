export default {
  mixins: [$mixins.control],
  components: {},
  filters: {
    time(timeStr) {
      return dsf.date.format(timeStr, "mm-dd");
    },
  },
  props: {
    // 宽度
    width: {
      type: String,
      default: "100%",
    },
    // 高度
    height: {
      type: String,
      default: "100%",
    },
    // 行高
    lineHeight: {
      type: String,
      default: "45px",
    },
    // 边框
    margin: {
      type: Array,
      default() {
        return [0, 0, 0, 0];
      },
    },
    // 边框
    padding: {
      type: Array,
      default() {
        return [0, 0, 0, 0];
      },
    },
    // 边框
    rowPadding: {
      type: Array,
      default() {
        return [0, 20, 0, 20];
      },
    },
    // 边框
    rowMargin: {
      type: Array,
      default() {
        return [0, 0, 0, 0];
      },
    },
    // 列表数据
    list: {
      type: Array,
      default: () => [],
    },
    toConfig: {
      type: Object,
      default: () => ({}),
    },
    pageSize: {
      default: 10
    }
  },

  computed: {
    controlStyle() {
      let { width, padding, height } = this;
      return { width, padding: padding.join("px ") + "px", height };
    },
    panelStyle() {
      let { height } = this;
      return { height };
    },
    urlQuery() {
      let { pageSize } = this
      return {
        pageSize
      }
    }
  },

  data() {
    return {};
  },

  watch: {},

  created() { },

  methods: {
    reloadData() {
      _.each(this.$children, (child) => {
        child?.reloadData && child.reloadData();
      });
    },
    listChangeFn(list) {
      this.$emit("list-change", list);
    },
    rowClick({ args }) {
      let { toConfig } = this;
      if (this.$listeners['row-click']) {
        return this.$dispatch("row-click", { args })
      }
      let url = this.$replace(args.row.url, args.row ?? {});
      let title = args.row.title;
      let _this = this;
      this.$open({
        ...toConfig,
        url,
        title,
        closed() {
          if (_this.$homePanel) {
            _this.$homePanel.reloadData();
          } else {
            _this.reloadData();
          }
        },
      });
    },
  },
}