var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "choose-send-line" },
    _vm._l(_vm.lines, function (line, index) {
      return _c(
        "p",
        {
          key: index,
          staticClass: "item",
          class: { choosed: _vm.checked === line },
          on: {
            click: function ($event) {
              return _vm.chooseLine(line)
            },
          },
        },
        [
          _vm._v(
            " " +
              _vm._s(
                _vm.type === "next"
                  ? line.Name
                  : line.NodeName + "-" + line.LinkUser.RUserName
              ) +
              " "
          ),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }