<template>
  <div v-loading="loading" class="ds-control ds-form-item ds-upload" :class="getCss">
    <el-upload
      ref="upload"
      :disabled="readOnly"
      :style="{ width: switchMode ? `${imgWidth}px` : '', height: switchMode ? `${imgHeight}px` : '' }"
      :class="[{ 'avatar-uploader': switchMode }, 'upload-demo', classObject]"
      :accept="uploadFileExts"
      :multiple="false"
      :headers="headersParam"
      :action="setRequestUrl"
      :show-file-list="false"
      :before-upload="beforeUpload"
      :on-success="handleSuccess"
      :on-error="handleError"
      :on-progress="handleProgress"
      :auto-upload="autoUpload"
    >
      <template v-if="switchMode">
        <img v-if="multipleFiles.length" :src="dsf.url.getWebPath(multipleFiles[0].relativePath)" class="avatar" fit="cover" />
        <template v-else>
          <img :src="imageUrl[defaultImg]" fit="cover" class="avatar" />
          <p v-if="isShowTips" class="tips">{{ `长 * 宽：${imgWidth}px * ${imgHeight}px` }}</p>
        </template>
      </template>
      <template v-else>
        <el-button type="primary" class="ds-button">
          <i class="el-icon-upload el-icon--left"></i>
          {{ btnName }}
        </el-button>
      </template>
    </el-upload>
    <span v-if="showPhotoText" style="color: red; text-align: left">{{ tipsPhotoText }}</span>
    <div v-if="errors.length > 0 && multipleFiles.length <= 0" class="ds-error-text">{{ errorsMsg }}</div>
    <crop-image
      :show-tips-text="showTipsText"
      :tips-text="tipsText"
      :edit-img="editImg"
      :edit-file-img="editFileImg"
      :aspect-ratio="aspectRatio"
      :request-url="setRequestUrl"
      @saveFile="editFile"
      @handleClose="handleClose"
    />
  </div>
</template>

<script>
import photodefault from "_platform/assets/styles/themes/upload/photodefault.jpg";
import coverdefault from "_platform/assets/styles/themes/upload/coverdefault.png";
import cropImage from "./cropImage";
export default dsf.component({
  name: "DsfUploadPhoto",
  mixins: [$mixins.formControl],
  ctrlCaption: "上传照片",
  props: {
    label: {
      type: String,
      default: "上传照片",
    },
    btnName: {
      type: String,
      default: "上传照片",
    },
    isShowTips: {
      type: Boolean,
      default: true,
    },
    isCutPic: {
      type: Boolean,
      default: true,
    },
    imgWidth: {
      type: [Number, String],
      default: "180",
    },
    imgHeight: {
      type: [Number, String],
      default: "230",
    },
    maxFileSize: {
      type: String,
      default: "2048",
    },
    minFileSize: {
      type: String,
      default: "0",
    },
    required: {
      type: Boolean,
      default: false,
    },
    defaultImg: {
      type: [Number, String],
      default: "0",
    },
    photoCard: {
      type: String,
      default: "",
    },
    hasPadding: {
      type: [Number, String],
      default: "1",
    },
    hasBorder: {
      type: [Number, String],
      default: "1",
    },
    aspectRatio: {
      type: String,
      default: "2/3",
    },
    switchMode: {
      type: Boolean,
      default: true,
    },
    requestUrl: {
      type: String,
      default: "",
    },
    showPhotoText: {
      type: Boolean,
      default: false,
    },
    tipsPhotoText: {
      type: String,
      default: "请上传0.5-2MB大小，JPG、JPEG、PNG格式的免冠证件照",
    },
    showTipsText: {
      type: Boolean,
      default: false,
    },
    tipsText: {
      type: String,
      default: "请将照片放于深色区域内",
    },
    metadata: {
      type: Object,
      default() {
        return dsf.metadata.get("photo-meta-data", {
          valueAttributes: [
            {
              name: "上传照片",
              code: "photo",
              type: dsf.metadata.getDataType("object"),
              length: 500,
              defaultValue: null,
              unit: null,
            },
          ],
        });
      },
    },
  },
  data() {
    return {
      loading: false,
      autoUpload: true,
      // multipleFiles: "", // 上传文件数组
      uploadFileExts: ".jpg,.jpeg,.png", //允许上传后缀名
      acceptMime: "", // 弹窗筛选条件MiMe类型
      headersParam: {
        "X-XSRF-TOKEN": dsf.getCookie("XSRF-TOKEN") || "",
        authorization_token: dsf.getToken(),
      },
      imageUrl: [],
      editImg: false,
      editFileImg: {},
    };
  },
  created() {
    this.imageUrl = [photodefault, coverdefault, dsf.config?.[this.photoCard]];
  },
  computed: {
    setRequestUrl() {
      return dsf.url.getWebPath(this.requestUrl || "file/upload/");
    },
    multipleFiles: {
      get() {
        let files = [];
        if (this.value !== "" && dsf.isDef(this.value) && typeof this.value === "string") {
          files = JSON.parse(this.value);
        }
        return files;
      },
      set(v) {
        this.emitValueChange(JSON.stringify(v));
      },
    },
    classObject: function () {
      return {
        no_padding: this.hasPadding == "1",
        border: this.hasBorder == "1",
      };
    },
  },
  methods: {
    beforeUpload(file) {
      // 判断文件格式
      if (this.uploadFileExts) {
        let dotIndex = file.name.lastIndexOf(".");
        let testmsg = "";
        if (dotIndex > 0) {
          testmsg = file.name.substring(dotIndex).toLowerCase();
        }
        let arr = _.filter(this?.uploadFileExts?.split?.(",") || [], (it) => it);
        if (!~arr.indexOf(testmsg)) {
          dsf.layer.message(`选择文件中包含不支持的文件格式,请选择包含这些格式的文件：${this.uploadFileExts}`, false);
          return false;
        }
      }
      // 判断文件大小
      let minSize = _.isNaN(parseInt(this.minFileSize)) ? 0 : parseInt(this.minFileSize) * 1024;
      let isMinSize = minSize ? (file.size > minSize ? true : false) : true;
      let fileSize =
        this.maxFileSize?.toLocaleLowerCase()?.indexOf("m") > -1 ? parseInt(this.maxFileSize) * 1024 * 1024 : parseInt(this.maxFileSize) * 1024;
      let maxSize = _.isNaN(parseInt(this.maxFileSize)) ? 0 : fileSize;
      let isMaxSize = maxSize ? (file.size < maxSize ? true : false) : true;
      if (!isMaxSize || !isMinSize) {
        let sizeInfo = !isMinSize ? ["小于", minSize] : ["大于", maxSize];
        dsf.layer.message(`文件大小不能${sizeInfo[0]}${this.formatBytes(sizeInfo[1])}`, false);
        return false;
      }
    },
    formatBytes(a, b) {
      // a 参数：表示要被转化的容量大小，以字节为单
      // b 参数：表示如果转换时出小数，四舍五入保留多少位 默认为2位小数
      if (0 == a) return "0 B";
      var c = 1024,
        d = b || 2,
        e = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
        f = Math.floor(Math.log(a) / Math.log(c));
      return parseFloat((a / Math.pow(c, f)).toFixed(d)) + " " + e[f];
    },
    handleProgress() {
      this.loading = true;
    },
    //上传成功
    handleSuccess(response) {
      this.loading = false;
      if (response.success && response.state == 20000) {
        if (this.isCutPic) {
          this.editImg = true;
          this.editFileImg = response.data[0];
        } else {
          this.multipleFiles.push(response.data[0]);
          if (this.multipleFiles.length > 1) {
            let files = this.multipleFiles.splice(1, 1);
            this.emitValueChange(JSON.stringify(files));
          } else {
            this.emitValueChange(JSON.stringify(this.multipleFiles));
          }
        }
      }
    },
    //上传失败
    handleError() {
      this.loading = false;
      dsf.layer.message("上传失败", false);
    },
    //获取文件大小
    getFileSize(data) {
      let fileSize = parseInt(data);
      if (data.indexOf("M") > -1 || data.indexOf("m") > -1) {
        fileSize = parseInt(data) * 1024;
      }
      return fileSize;
    },
    editFile(files) {
      this.editImg = false;
      this.multipleFiles.push(files);
      if (this.multipleFiles.length > 1) {
        let files = this.multipleFiles.splice(1, 1);
        this.emitValueChange(JSON.stringify(files));
      } else {
        this.emitValueChange(JSON.stringify(this.multipleFiles));
      }
    },
    handleClose() {
      this.editImg = false;
    },
    getPhoto(imgs) {
      if (!imgs || imgs === "[]" || _.isEqual(imgs, [])) {
        return [];
      }
      let _imgs = !_.isArray(imgs) ? JSON.parse(imgs) : imgs;
      return _imgs.map((v) => (v.relativePath ? dsf.url.getWebPath(v.relativePath) : v));
    },
  },
  components: {
    cropImage,
  },
});
</script>
