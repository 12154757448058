var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "ds-context-menu-group",
      class: [_vm.positionX, _vm.positionY],
    },
    [
      _vm._l(_vm.list, function (menu, index) {
        return [
          _c(
            "div",
            {
              key: index,
              staticClass: "ds-context-menu-item",
              class: {
                disabled: _vm.contextMenuRoot.isDisabled(menu),
                hide: _vm.contextMenuRoot.isHide(menu),
              },
              on: {
                mouseenter: function ($event) {
                  return _vm.$set(menu, "childrenShow", true)
                },
                mouseleave: function ($event) {
                  return _vm.$set(menu, "childrenShow", false)
                },
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "ds-context-menu-item-content",
                  class: { "has-icon": _vm.hasIcon },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.contextMenuRoot.itemClick(menu, $event)
                    },
                  },
                },
                [
                  menu.icon
                    ? _c("i", {
                        staticClass: "ds-context-menu-item-icon iconfont",
                        class: [menu.icon],
                        style: { color: menu.color },
                      })
                    : _vm._e(),
                  _c("span", { staticClass: "ds-context-menu-item-name" }, [
                    _vm._v(_vm._s(menu.name)),
                  ]),
                ]
              ),
              _c("i", {
                staticClass: "iconfont icon-right",
                attrs: { show: menu.children && menu.children.length },
              }),
              menu.children && menu.children.length
                ? [
                    menu.childrenShow
                      ? _c("dsf-context-menu-group", {
                          attrs: { list: menu.children },
                        })
                      : _vm._e(),
                  ]
                : _vm._e(),
            ],
            2
          ),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }