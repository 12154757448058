// //引入组件
// import ElementUI from "element-ui";
// import 'element-ui/lib/theme-chalk/index.css';

//引入el-scrollbar
// import ScrollBar from 'element-ui/lib/scrollbar';
// import 'element-ui/lib/theme-chalk/scrollbar.css';
// console.log(ScrollBar)

//引入PC端公共弹层
import layer from "../layer/layer";
dsf.mix(dsf.layer, { pc: layer });

//常用组件
import * as commonComponents  from "../common";
import * as buttonComponents from "./button";
import * as htmlComponents from "./html";
//表单组件
import * as inputComponents from "./input";
import * as itemsComponents from "./items";
import * as dateComponents from "./date";
// 文字组件
import * as textComponents from "./text";
//页面组件
import * as pageComponents from "./page";
//容器类组件
// import { DsfContainer } from "./container";
//布局类组件
import * as layoutComponents from "./layout";
//子表类
import * as subComponents from "./sub";
//数据类
import * as listComponents from "./list";
// 上传类
import * as uploadComponents from "./upload";
//弹出层
import * as dialogComponents from "./dialog";
//树类组件 树信息
import * as treeComponents from "./tree";
//选项卡类
import * as tabsComponents from "./tabs";
// 系统类
import * as systemComponents from "./system";
// 业务类
import * as navComponents from "./nav";
// 数据面板
import * as dataPanelComponents from "./dataPanel";
// 流程类
import * as flowComponents from "./flow";
// 项目配置
import * as projectConfigComponents from './projectConfig';
// 项目配置
import * as personnelCardComponents from './personnelCard';
// 设计类
import * as designComponents from './design';
// 其他类
import * as otherComponents from './other';
// 地图
import * as mapComponents from './map';
// 卡片
import * as cardComponents from './card';
// 同屏
import * as syncScreen from './syncScreen';

const components = {
  ...commonComponents,
  ...buttonComponents,
  ...htmlComponents,
  ...inputComponents,
  ...itemsComponents,
  ...dateComponents,
  ...textComponents,
  ...pageComponents,
  ...layoutComponents,
  ...subComponents,
  ...listComponents,
  ...uploadComponents,
  ...dialogComponents,
  ...treeComponents,
  ...tabsComponents,
  ...systemComponents,
  ...navComponents,
  ...dataPanelComponents,
  ...flowComponents,
  ...projectConfigComponents,
  ...personnelCardComponents,
  ...designComponents,
  ...otherComponents,
  ...mapComponents,
  ...cardComponents,
  ...syncScreen
};

function install(Vue) {
  Vue.config.performance = true;
  //按需引入element-ui组件
  Vue.prototype.$ELEMENT = {
    zIndex: 15000,
  };
  for (let key in components) {
    let it = components[key];
    Vue.component(it.name, it);
    if (!window.$components['platform']) {
      window.$components['platform'] = {}
    }
    window.$components['platform'][it.name] = it;
  }
}

export { install };
export default install;