<template functional>
  <div class="ds-nav-menu-item" :class="{hide: props.item.hide}" :style="_vm.parent.itemStyle">
    <div v-if="!props.item.hide" class="ds-nav-menu-item-bt" :style="_vm.parent.itemBtStyle" :title="props.item.name || ''" @click="props.item.hide || _vm.parent._clickBefore(_vm.parent.onClick, props.item)">
      <el-image
        :src="_vm.parent.getImgUrl(props.item) | imgFormat"
        :style="[_vm.parent.itemBgStyle, _vm.parent.getItemStyle(props.item)]">
        <template slot="error">
          <i
            class="iconfont"
            :class="[_vm.parent.getIcon(props.item.icon)]"
            :style="[_vm.parent.itemIconStyle, _vm.parent.getIconStyle(props.item)]" />
        </template>
      </el-image>
      <div class="ds-nav-menu-item-name" :style="_vm.parent.itemTextStyle || {}">
        <div class="ellipsis">{{ props.item.name || '' }}</div>
      </div>
      <slot></slot>
    </div>
    <div v-else class="ds-nav-menu-item-bt" :style="_vm.parent.itemBtStyle"></div>
  </div>
</template>

<script>
export default {
  name: "NavMenuItem",
  props: {
    item: {
      type: Object,
      required: true
    }
  }
}
</script>