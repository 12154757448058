<template>
  <el-date-picker v-model="value" style="width:100%" :type="pickerType" range-separator="至" :start-placeholder="startPlaceholder" :end-placeholder="endPlaceholder" @change="change()">
  </el-date-picker>
</template>
<script>
export default {
  name: "DsfSuperSearchDataPicker",
  props: {
    isRange: {
      type: Boolean,
      default: false
    },
    format: {
      type: String,
      default() {
        return "ymd"
      }
    },
    values: {
      type: [Date, Array, String],
      default() {
        return null
      }
    },
  },
  data() {
    return {
      value: null
    }
  },
  computed: {
    startPlaceholder() {
      let isRange = this.isRange;
      let format = this.format;
      if (isRange) {
        if (format == 'ymd') {
          return "开始日期"
        }
        else if (format == 'y') {
          return "开始年"
        }
        else {
          return "开始年月"
        }
      }
      else {
        return "请选择日期"
      }
    },
    endPlaceholder() {
      let isRange = this.isRange;
      let format = this.format;
      if (isRange) {
        if (format == 'ymd') {
          return "结束日期"
        }
        else if (format == 'y') {
          return "结束年"
        }
        else {
          return "结束年月"
        }
      }
      else {
        return "请选择日期"
      }
    },
    pickerType() {
      let isRange = this.isRange;
      let format = this.format;
      if (isRange) {
        if (format == 'ymd') {
          return "daterange"
        }
        else if (format == 'y') {
          return "yearrange"
        }
        else {
          return "monthrange"
        }
      }
      else {
        if (format == 'ymd') {
          return "date"
        }
        else if (format == 'y') {
          return "year"
        }
        else {
          return "month"
        }
      }
    }
  },
  methods: {
    focus() {
      this.$emit("focus");
    },
    change() {
      this.$emit("update:values", this.value);
      this.$nextTick(() => {
        this.$emit("change");
      });
    }
  },
  watch: {
    values: {
      handler(v, ov) {
        if (this.isRange) {
          this.value = this.values || [];
        }
        else {
          this.value = dsf.type(this.values) == 'array' ? this.values[0] : this.values;
        }
      },
      immediate: true
    }
  }
}
</script>