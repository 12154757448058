var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "menu-item",
      class: { active: _vm.active },
      style: { width: _vm.width },
      on: { mouseenter: _vm.mouseenter },
    },
    [
      _c(
        "div",
        {
          staticClass: "menu-item-box",
          on: {
            click: function ($event) {
              return _vm.linkTo(_vm.menu)
            },
          },
        },
        [
          _c("i", {
            staticClass: "iconfont",
            class: [_vm.menu.menu_icon || "icon-shezhi"],
          }),
          _c("span", [_vm._v(_vm._s(_vm.menu.menu_name))]),
        ]
      ),
      _vm.menu.children && _vm.menu.children.length
        ? _c(
            "div",
            {
              ref: "children",
              staticClass: "menu-item-children",
              style: { "margin-left": _vm.childrenLeft },
            },
            [
              _vm._l(_vm.menu.children, function (level1) {
                return [
                  _c(
                    "div",
                    {
                      key: level1.menu_id,
                      staticClass: "menu-item-children-item",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "menu-item-children-item-box",
                          on: {
                            click: function ($event) {
                              return _vm.linkTo(level1)
                            },
                          },
                        },
                        [
                          _c("i", {
                            staticClass: "iconfont",
                            class: [level1.menu_icon],
                          }),
                          _c("span", [_vm._v(_vm._s(level1.menu_name))]),
                        ]
                      ),
                      level1.children && level1.children.length
                        ? _c(
                            "div",
                            {
                              ref: "children2",
                              refInFor: true,
                              staticClass: "menu-item-children-level2",
                              class: [_vm.level2Position],
                            },
                            [
                              _vm._l(level1.children, function (level2) {
                                return [
                                  _c(
                                    "div",
                                    {
                                      key: level2.menu_id,
                                      staticClass:
                                        "menu-item-children-level2-item",
                                      on: {
                                        click: function ($event) {
                                          return _vm.linkTo(level2)
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "menu-item-children-level2-item-box",
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "iconfont",
                                            class: [level2.menu_icon],
                                          }),
                                          _c("span", [
                                            _vm._v(_vm._s(level2.menu_name)),
                                          ]),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              }),
                            ],
                            2
                          )
                        : _vm._e(),
                    ]
                  ),
                ]
              }),
            ],
            2
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }