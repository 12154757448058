<template>
  <div v-if="showItem" class="ds-super-search-item">
    <label class="ds-super-search-item-text" :style="{width: unitSize(titleMaxWidth)}" :title="item.label">{{ item.label }}:</label>
    <div class="ds-super-search-item-block" :class="{'open':item.isExtend}">
      <!--平铺选项-->
      <template v-if="isTile">
        <div class="ds-super-search-item-option">
          <div class="ds-super-search-item-option-box" @click.stop>
            <el-checkbox-group v-model="tileValues">
              <template v-if="item.showTileAll">
                <el-checkbox :key="'$$all$$'" v-show="item.showTileAll" :label="''" true-label="''" v-if="!item.multiple&&(item.options&&item.options.length>0)" @change="checkboxChange('',item,null,false)">
                  全部
                </el-checkbox>
              </template>
              <template v-if="item.showModel=='tile'">
                <el-checkbox v-for="op in (item.options||[])" :key="op.value" :label="op.value" @change="checkboxChange(op.value,item,op,false)">
                  {{ op.text }}
                </el-checkbox>
              </template>
              <template v-else-if="item.showModel=='treeTile'">
                <el-checkbox v-for="op in (item.options||[])" :key="op._id" :label="op._id" @change="checkboxTreeChange(op._id,item,op,false)">
                  {{ op._name }}
                </el-checkbox>
              </template>
              <el-form @submit.native.prevent class="ds-super-search-customer-between-form" v-if="item.operator==='between'">
                <label class="el-checkbox">
                  <el-input v-model="item.customerBetween[0]" class="small"></el-input>
                  <span style="margin-left:5px;margin-right:5px">~</span>
                  <el-input v-model="item.customerBetween[1]" class="small"></el-input>
                  <dsf-button style="margin-top:-2px;min-width:auto;margin-left:5px;" tag-name="button" size="small" @click="customerSure(item)">确定</dsf-button>
                </label>
              </el-form>
            </el-checkbox-group>
          </div>
          <div v-if="showMultipleButtons" class="multiple-buttons" style="text-align:center">
            <dsf-button size="small" style="min-width:auto" @click="multipleSure(item)">确定</dsf-button>
            <dsf-button size="small" :type="'plain'" style="min-width:auto" @click="multipleCancel(item)">取消</dsf-button>
          </div>
        </div>
      </template>
      <!--下拉选择，树选择-->
      <template v-if="isSelect">
        <DsfSuperSearchSelect :item="item" @click.native.stop :values.sync="item.values" v-if="item.showModel=='select'" :options="item.options||[]" :multipe="item.isAllowMultipe" @focus="focus" @change="change(item)"></DsfSuperSearchSelect>
        <DsfSuperSearchTreeSelect :item="item" @click.native.stop :values.sync="item.values" v-else-if="item.showModel=='treeselect'" :options="item.options||[]" :multipe="item.isAllowMultipe" @focus="focus" @change="change(item)"></DsfSuperSearchTreeSelect>
      </template>

      <!--文本框-->
      <template v-if="isText">
        <DsfSuperSearchInput :item="item" :values.sync="item.values" :placeholder="item.placeholder||'请输入'+(item.label)" @change="change(item)"></DsfSuperSearchInput>
        <!-- <el-input v-model="item.values" :placeholder="'请输入'+(item.label)" @change="change(item)"></el-input> -->
      </template>
      <!--数字-->
      <template v-if="isNumber">
        <DsfSuperSearchNumber :item="item" :values.sync="item.values" :placeholder="item.placeholder||'请输入'+(item.label)" @change="change(item)"></DsfSuperSearchNumber>
        <!-- <el-input v-model="item.values" :placeholder="'请输入'+(item.label)" @change="change(item)"></el-input> -->
      </template>
      <!--时间-->
      <template v-if="isDateTime">
        <DsfSuperSearchDataPicker v-if="item.operator==='between'" :values.sync="item.values" :format="item.showModel" :is-range="true" @focus="focus" @change="change(item)"></DsfSuperSearchDataPicker>
        <DsfSuperSearchDataPicker v-else :values.sync="item.values" :format="item.showModel" :is-range="false" @focus="focus" @change="change(item)"></DsfSuperSearchDataPicker>
      </template>
    </div>
    <!--平铺模式的按钮-->
    <div v-if="isTile" class="ds-super-search-item-buttons">
      <template v-if="(item.isMore)">
        <dsf-button v-if="!item.isExtend" class="more" style="min-width:auto" type="plain" size="small" icon="shuangjiantouxia" @click.stop="extend(item,true)">
          更多
        </dsf-button>
        <dsf-button v-else class="pack-up" style="min-width:auto" type="plain" size="small" icon="shuangjiantoushang" @click.stop="extend(item,false)">
          收起
        </dsf-button>
      </template>
      <template v-if="!item.isTileForceMulti">
        <dsf-button v-if="item.isAllowMultipe&&!item.multiple" icon="hao" class="multiple" style="min-width:auto" type="plain" size="small" @click.stop="multipleExtend(item)">
          多选
        </dsf-button>
      </template>
    </div>
  </div>
</template>
<script>
import DsfSuperSearchSelect from "./superSearchSelect";
import DsfSuperSearchTreeSelect from "./superSearchTreeSelect";
import DsfSuperSearchDataPicker from "./superSearchDataPicker";
import DsfSuperSearchInput from "./superSearchInput.vue";
import DsfSuperSearchNumber from "./superSearchNumber.vue";


export default {
  name: "DsfSuperSearchItem",
  components: {
    DsfSuperSearchSelect,
    DsfSuperSearchTreeSelect,
    DsfSuperSearchDataPicker,
    DsfSuperSearchInput,
    DsfSuperSearchNumber
  },
  inject: {
    $superSearch: {
      default: null
    },
  },
  props: {
    item: {
      type: Object,
      default() {
        return {};
      }
    },
    titleMaxWidth: {
      type: String,
      default: ""
    },
  },
  data() {
    return {
      // 树平铺组件，找到他的父级筛选项
      parentVm: null,
      // 树平铺组件，找到他的子级筛选项
      childVm: null
    };
  },
  computed: {
    tileValues: {
      get() {
        let val = this.item.values || [];
        if (!val.length) {
          return [""];
        } else {
          return this.item.values;
        }
      },
      set(v) {
        if (v.length == 0) {
          this.item.values = [];
        } else {
          v = _.filter(v, (it) => it);
          this.item.values = v;
        }
      }
    },
    // 是否显示
    showItem() {
      if (this.$superSearch.isDesign || !this.item.autoHide) {
        return true;
      }
      let { type, showModel } = this.item;
      if (type === "enum" && showModel === "treeTile") {
        return (this.item.options || []).length;
      }
      return true;
    },
    //平铺时是否显示多选状态
    showMultipleButtons() {
      //如果仅支持多选则多选状态下的确定、取消按钮不显示
      if (this.item.isTileForceMulti) {
        return false;
      } else if (this.item.isExtend && this.item.multiple) {
        return true;
      } else {
        return false;
      }
    },
    isTile() {
      return this.item.type == "enum" && (this.item.showModel == "tile" || this.item.showModel == "treeTile");
    },
    isSelect() {
      return this.item.type == "enum" && (this.item.showModel == "select" || this.item.showModel == "treeselect");
    },
    isDateTime() {
      return this.item.type == "datetime";
    },
    isNumber() {
      return this.item.type == "number";
    },
    isText() {
      return this.item.type == "text";
    },
    isItemMulitExtend() {
      return this.item.multiple && this.item.isExtend;
    }
  },
  created() {
    this.$set(this.item, "$vm", this);
  },
  mounted() {
    let { type, showModel, childField } = this.item;
    if (type === "enum" && showModel === "treeTile" && childField) {
      let childItem = _.find(this.$superSearch.searchItems, (it) => it.type === "enum" && it.showModel === "treeTile" && it.field === childField);
      if (childItem?.$vm) {
        childItem.$vm.parentVm = this;
        this.childVm = childItem.$vm;
      }
    }
  },
  methods: {
    focus() {
      this.$emit("focus");
    },
    change() {
      this.$emit("change");
    },
    extend(item, mark) {
      item.isExtend = mark;
      this.$superSearch.filterAllClose();
    },

    multipleExtend(item) {
      this.$superSearch.filterAllClose();
      this.$superSearch.collapseItems();
      // item.isClickMultiple = true;
      item.isExtend = true;
      item.values_bak = item.values.slice(0);
      item.multiple = true;
      this.$nextTick(() => {
        this.$superSearch.emitHeight();
      });
    },
    multipleSure(item) {
      item.multiple = false;
      // item.isClickMultiple = false;
      item.isExtend = false;
      // 如果是树平铺 且 配置了子级字段
      if (item.showModel == "treeTile" && item.childField) {
        let children = [];
        _.forEach(item.values, (val) => {
          let options = _.find(item.options, ({ _id }) => _id === val);
          options.children && children.push(...options.children);
        });
        this.setTreeTileChildOptions(item.childField, children);
      }
      this.$superSearch.filterAllClose();
      this.$nextTick(() => {
        this.$superSearch.emitHeight();
        this.change();
      });
    },
    multipleCancel(item) {
      if (item.values_bak) {
        item.values = item.values_bak;
      }
      item.isExtend = false;
      this.$superSearch.collapseItems();
      this.$superSearch.filterAllClose();
      this.$nextTick(() => {
        this.$superSearch.emitHeight();
      });
    },
    checkboxChange(v, item, option, isFilter) {
      this.$tileCheckboxChange("value", v, item, option, isFilter);
    },
    checkboxTreeChange(v, item, option, isFilter) {
      this.$tileCheckboxChange("_id", v, item, option, isFilter);
    },
    $tileCheckboxChange(key, v, item, option, isFilter) {
      // if (!isFilter) {
      //   this.$superSearch.filterAllClose();
      // }
      //如果强制多选
      if (item.isTileForceMulti) {
        if (item.showModel == "treeTile" && item.childField) {
          let children = [];
          _.forEach(item.values, (val) => {
            let options = _.find(item.options, ({ _id }) => _id === val);
            options.children && children.push(...options.children);
          });
          this.setTreeTileChildOptions(item.childField, children);
        }
        if (item.operator == "between") {
          item.customerBetween = [];
        }
        this.$nextTick(() => {
          this.change();
        });
      } else {
        if (!item.multiple) {
          if (v) {
            item.values = option ? [option[key]] : [];
          } else {
            item.values = [];
          }
          if (item.operator == "between") {
            item.customerBetween = [];
          }
          // 如果配置了子级字段
          if (item.childField) {
            let children = option?.children || [];
            this.setTreeTileChildOptions(item.childField, children);
          }
          this.$nextTick(() => {
            this.change();
          });
        }
      }
    },
    // 树平铺时为子级字段设置选项
    setTreeTileChildOptions(field, options) {
      let childVm = this.childVm;
      if (!childVm) return;
      if (field === this.item.field) {
        dsf.error(`高级检索：树平铺字段"${this.item.label}"配置的级联子字段名${field}不能与其本身字段名相同`);
        return;
      }
      if (options.length) {
        childVm.$set(childVm.item, "options", options);
        childVm.$set(childVm.item, "values", []);
      } else {
        while (childVm) {
          childVm.$set(childVm.item, "options", []);
          childVm.$set(childVm.item, "values", []);
          childVm = childVm.childVm;
        }
      }
    },
    customerSure(item) {
      let values = _.filter(item.customerBetween, (v) => v + "");
      if (values.length == 2) {
        item.values = values;
        this.change();
      } else {
        dsf.layer.message("请填写" + item.label + "的区间值", false);
      }
    }
  },
  watch: {
    "item.isExtend": {
      handler(v, ov) {
        if (v != ov) {
          this.$nextTick(() => {
            this.$superSearch.emitHeight();
          });
        }
      }
      // immediate:true
    }
  }
};
</script>