var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.columns.children && _vm.columns.children.length
    ? _c(
        "el-table-column",
        {
          attrs: {
            label: _vm.columns.label,
            resizable: !_vm.isDesign,
            width: _vm.columns.width,
            "min-width": _vm.columns.minWidth,
            "header-align": _vm.columns.headerAlign,
            align: _vm.columns.align,
            "class-name": "editor-td",
          },
        },
        _vm._l(_vm.columns.children, function (col) {
          return _c("dsf-static-list-by-table-column", {
            key: col.columnId,
            attrs: {
              "is-design": _vm.isDesign,
              columns: col,
              "get-column-sort": _vm.getColumnSort,
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function (scope2) {
                    return [_vm._t("default", null, { col: col }, scope2)]
                  },
                },
              ],
              null,
              true
            ),
          })
        }),
        1
      )
    : _vm.columns.columnType == "extend"
    ? _c("el-table-column", {
        attrs: {
          label: _vm.columns.label,
          prop: _vm.columns.columnId,
          resizable: !_vm.isDesign,
          width: _vm.columns.width,
          "min-width": _vm.columns.minWidth,
          "header-align": _vm.columns.headerAlign,
          align: "left",
          sortable: _vm.getColumnSort(_vm.columns),
          "class-name": "editor-td",
        },
        scopedSlots: _vm._u(
          [
            {
              key: "default",
              fn: function (scope) {
                return [_vm._t("default", null, { col: _vm.columns }, scope)]
              },
            },
          ],
          null,
          true
        ),
      })
    : _c("el-table-column", {
        attrs: {
          label: _vm.columns.label,
          prop: _vm.columns.columnId,
          resizable: !_vm.isDesign,
          width: _vm.columns.width,
          "min-width": _vm.columns.minWidth,
          "header-align": _vm.columns.headerAlign,
          align: _vm.columns.align,
          sortable: _vm.getColumnSort(_vm.columns),
          "class-name": "editor-td",
        },
        scopedSlots: _vm._u(
          [
            {
              key: "default",
              fn: function (scope) {
                return [_vm._t("default", null, { col: _vm.columns }, scope)]
              },
            },
          ],
          null,
          true
        ),
      })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }