<template>
  <ul class="ds-aside-menu" :class="['level' + level]">
    <template v-for="(item, index) in menuList">
      <dsf-default-home-menu-item
        :key="item.menu_id"
        :menu="item"
        :index="index"
        :level="level"
        :has-icon="hasIcon"
        @mouseenter="mouseenter"
        @mouseleave="mouseleave"
      />
    </template>
  </ul>
</template>

<script>
export default {
  name: 'DsfDefaultHomeMenu',
  props: {
    menuList: {
      type: Array,
      default() {
        return []
      }
    },
    level: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      showKey: ''
    }
  },
  computed: {
    hasIcon() {
      return !!_.find(this.menuList, it => !!it.menu_icon);
    }
  },
  methods: {
    mouseenter(e, menu) {
      this.$emit('mouseenter', e, menu);
    },
    mouseleave(e) {
      this.$emit('mouseleave', e);
    }
  }
}
</script>