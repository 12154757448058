export default {
  props: {
    /* 可选范围设置 */
    selectStartType: {
      type: Number,
      default: 0
    },
    selectEndType: {
      type: Number,
      default: 0
    },
    // 固定日期
    selectStartDate: {
      type: Number,
      default: Date.now()
    },
    selectEndDate: {
      type: Number,
      default: Date.now()
    },
    // 动态日期(根据天数)
    selectStartDateByDays: {
      type: Number,
      default: 0
    },
    selectEndDateByDays: {
      type: Number,
      default: 0
    },
    // 动态日期(根据月数)
    selectStartDateByMonths: {
      type: Number,
      default: 0
    },
    selectEndDateByMonths: {
      type: Number,
      default: 0
    },
    // 动态日期(根据年数)
    selectStartDateByYears: {
      type: Number,
      default: 0
    },
    selectEndDateByYears: {
      type: Number,
      default: 0
    },
    //只读状态下格式化
    formatter: {
      type: String,
      default: "dateTime^yyyy-mm-dd"
    },
  },
  computed: {
    pickerOptions() {
      let res = {
        // 限制日期选择范围
        disabledDate: d => {
          d = new Date(dsf.date.format(d, 'yyyy/mm/dd 00:00:00'));
          return this.pickerStartTime(d) || this.pickerEndTime(d);
        }
      };
      if (this.timeInter && (this.timeInter[0] !== "00:00:00" || this.timeInter[1] !== "23:59:59")) {
        res.selectableRange = this.timeInter[0] + '-' + this.timeInter[1];
      }
      return res;
    }
  },
  methods: {
    getDate(date, format = 'yyyy/mm/dd 00:00:00') {
      if (date === undefined) {
        date = new Date();
      }
      return new Date(dsf.date.format(date, format))
    },
    // 计算时间范围--起始
    pickerStartTime(d) {
      if (!this.selectStartType) return false;
      // 当前日期
      if (this.selectStartType === 1) {
        const now = this.getDate();
        return d.getTime() < now.getTime();
      }
      // 固定日期
      if (this.selectStartType === 2) {
        const _d = this.getDate(this.selectStartDate);
        return d.getTime() < _d.getTime();
      }
      // 动态日期(根据天数)
      if (this.selectStartType === 3) {
        let _d = this.getDate();
        _d = this.getDate(_d.getTime() + this.selectStartDateByDays * 86400000);
        return d.getTime() < _d.getTime();
      }
      // 动态日期(根据月数)
      if (this.selectStartType === 4) {
        let _d = new Date();
        let m = _d.getMonth() + this.selectStartDateByMonths + 1;
        let y = _d.getFullYear() + Math.floor(m / 12) + '/';
        m = m % 12;
        m = m.length === 1 ? '0' + m : m;
        _d = new Date(y + m + '/01 00:00:00');
        return d.getTime() < _d.getTime();
      }
      // 动态日期(根据年数)
      if (this.selectStartType === 5) {
        let _d = new Date();
        let y = _d.getFullYear() + this.selectStartDateByYears;
        _d = new Date(y + '/01/01 00:00:00');
        return d.getTime() < _d.getTime();
      }
      return false;
    },
    // 计算时间范围--结束
    pickerEndTime(d) {
      if (!this.selectEndType) return false;
      // 当前日期
      if (this.selectEndType === 1) {
        const now = this.getDate();
        return d.getTime() > now.getTime();
      }
      // 固定日期
      if (this.selectEndType === 2) {
        const _d = this.getDate(this.selectEndDate);
        return d.getTime() > _d.getTime();
      }
      // 动态日期(根据天数)
      if (this.selectEndType === 3) {
        let _d = this.getDate();
        _d = this.getDate(_d.getTime() + this.selectEndDateByDays * 86400000);
        return d.getTime() > _d.getTime();
      }
      // 动态日期(根据月数)
      if (this.selectEndType === 4) {
        let _d = new Date();
        let m = _d.getMonth() + this.selectEndDateByMonths + 2;
        let y = _d.getFullYear() + Math.floor(m / 12) + '/';
        m = m % 12;
        m = m.length === 1 ? '0' + m : m;
        _d = new Date(y + m + '/01 00:00:00');
        _d.setTime(_d.getTime() - 86400000);
        return d.getTime() > _d.getTime();
      }
      // 动态日期(根据年数)
      if (this.selectEndType === 5) {
        let _d = new Date();
        let y = _d.getFullYear() + this.selectEndDateByYears;
        _d = new Date(y + '/12/31 00:00:00');
        return d.getTime() > _d.getTime();
      }
      return false;
    }
  }
}