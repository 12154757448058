<template>
  <el-dialog ref="dialog" class="ds-dialog" v-bind.sync="$attrs" :class="elClass" :index="index" append-to-body v-on="$listeners">
    <template v-slot:title>
      <slot name="title"></slot>
    </template>
    <template v-slot:default>
      <slot></slot>
    </template>
    <template v-slot:footer>
      <slot name="footer"></slot>
    </template>
  </el-dialog>
</template>

<script>
export default {
  name: "DsfDialog",
  inheritAttrs: false,
  provide() {
    return {
      $dialog: this
    };
  },
  props: {
    index: {
      type: String,
      default: ""
    },
    noTitle: {
      type: Boolean,
      default: false
    },
    options: {
      type: Object,
      default() {
        return {};
      }
    },
    dialogArgs: {
      type: Object,
      default() {
        return null;
      }
    },
    openerVm: {
      type: Object,
      default() {
        return null;
      }
    },
    // 底部left居左,center居中,right居右
    footerAlign: {
      type: String,
      required: false
    },
    //内容区域是否存在边框线
    conentBorder: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      currentView: null
    };
  },
  computed: {
    elClass() {
      let res = [];
      let align = this.footerAlign || dsf.config.setting_public_default_dialog_footer_align;
      if (align && align != "right") {
        res.push("dialog-footer-" + align);
      }
      if (this.$attrs["show-close"]) {
        res.push("show-close");
      }
      if (this.noTitle) {
        res.push("no-title");
      }
      if(this.options.className){
        res.push(this.options.className)
      }
      // if (!this.$attrs['title']) {
      //   res.push("no-title");
      // }
      if (this.conentBorder) {
        res.push("ds-dialog-content-border");
      }
      return res;
    }
  },
  methods: {
    close() {
      this.$emit("update:visible", false);
    },
    btnLoading(index, value) {
      let btn = this.options.btns[index];
      if (btn) this.$set(btn, "loading", value);
    },
    dialogSure() {
      this.$emit("dialog-yes");
    }
  }
};
</script>
