var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "dsf-control ds-nav-menu-setting",
    },
    [
      _c(
        "div",
        { staticClass: "left" },
        [
          _c("div", { staticClass: "title" }, [_vm._v("可选菜单")]),
          _c(
            "dsf-virtual-scroll",
            {
              staticStyle: { flex: "auto" },
              attrs: { height: "0", "scroll-y": "" },
            },
            [
              !_vm.menuList.length
                ? _c("div", { staticClass: "no-data" }, [
                    _vm._v("暂时还没有数据~"),
                  ])
                : _vm._e(),
              _vm._l(_vm.menuList, function (group1) {
                return [
                  group1.children && group1.children.length > 0
                    ? _c("div", { key: group1._id, staticClass: "group" }, [
                        _c("div", { staticClass: "group-title" }, [
                          _c("span", [_vm._v(_vm._s(group1._name))]),
                        ]),
                        _c(
                          "div",
                          { staticClass: "group-content" },
                          [
                            _vm._l(group1.children, function (group2) {
                              return [
                                !group2.children
                                  ? _c(
                                      "el-checkbox",
                                      {
                                        key: group2._id,
                                        staticClass: "menu-item",
                                        attrs: {
                                          value:
                                            _vm.checkList.indexOf(group2._id) >
                                            -1,
                                          title: group2._name,
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.selectChange(
                                              group2,
                                              $event
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("div", { staticClass: "ellipsis" }, [
                                          _vm._v(_vm._s(group2._name)),
                                        ]),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._l(group2.children, function (menu) {
                                  return [
                                    _c(
                                      "el-checkbox",
                                      {
                                        key: menu._id,
                                        staticClass: "menu-item",
                                        attrs: {
                                          value:
                                            _vm.checkList.indexOf(menu._id) >
                                            -1,
                                          title: menu._name,
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.selectChange(
                                              menu,
                                              $event
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("div", { staticClass: "ellipsis" }, [
                                          _vm._v(_vm._s(menu._name)),
                                        ]),
                                      ]
                                    ),
                                  ]
                                }),
                              ]
                            }),
                          ],
                          2
                        ),
                      ])
                    : _vm._e(),
                ]
              }),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "div",
        { ref: "right", staticClass: "right" },
        [
          _c("div", { staticClass: "title" }, [
            _vm._v(" 已选菜单 "),
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.selectMenus.length,
                    expression: "selectMenus.length",
                  },
                ],
              },
              [_vm._v("(" + _vm._s(_vm.selectMenus.length) + ")")]
            ),
            _c("span", { staticClass: "tips" }, [_vm._v("拖动菜单可排序")]),
          ]),
          _c("dsf-virtual-scroll", { attrs: { "scroll-y": "" } }, [
            _vm.activeDom
              ? _c("div", {
                  ref: "move",
                  staticClass: "menu-item",
                  class: { down: _vm.showEffect },
                  style: _vm.moveDownStyle,
                  domProps: {
                    innerHTML: _vm._s(_vm.dsf.safe.xss(_vm.activeDom)),
                  },
                })
              : _vm._e(),
            !_vm.selectMenus.length
              ? _c("div", { staticClass: "no-data" }, [
                  _vm._v("请在左侧选择菜单"),
                ])
              : _vm._e(),
            _c(
              "div",
              { style: { height: _vm.selectMenus.length * 39 + "px" } },
              [
                _vm._l(_vm.selectMenus, function (menu, index) {
                  return [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              !_vm.activeMenu || _vm.activeMenu.id != menu.id,
                            expression:
                              "!activeMenu || activeMenu.id != menu.id",
                          },
                        ],
                        key: menu.id,
                        ref: "menu",
                        refInFor: true,
                        staticClass: "menu-item",
                        style: {
                          transform: "translateY(" + 39 * menu.order + "px)",
                        },
                        attrs: { index: index },
                        on: {
                          mousedown: function ($event) {
                            return _vm.mousedown(menu, index, $event)
                          },
                        },
                      },
                      [
                        _c("i", {
                          staticClass: "icon iconfont",
                          class: [menu.scicon],
                        }),
                        _c("span", [_vm._v(_vm._s(menu.name))]),
                        _c("i", {
                          staticClass: "closeBt iconfont icon-guanbi2",
                          on: {
                            mousedown: function ($event) {
                              $event.stopPropagation()
                            },
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.remove(index)
                            },
                          },
                        }),
                      ]
                    ),
                  ]
                }),
              ],
              2
            ),
          ]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }