<!--
 * @Editor: chenqy
 * @Description: 
 * @Date: 2021-07-20 10:46:07
 * @LastEditors: Chenqy
 * @LastEditTime: 2022-04-21 11:16:05
-->
<template>
  <!-- 搜索树 -->
  <div class="teas-body-tree">
    <div class="teas-body-tree-head">
      <el-input
        v-model="searchName"
        class="teas-body-tree-input"
        suffix-icon="el-icon-search"
        placeholder="请输入姓名查询"
      />
    </div>
    <div class="teas-vr-scroll">
      <DsfVirtualScroll class="ds-tree-box">
        <div>
          <el-tree
            ref="tree"
            class="filter-tree ds-tree-select-tree-box"
            show-checkbox
            :props="defaultProps"
            :default-expanded-keys="expandedKeys"
            :data="treeData"
            :filter-node-method="filterNode"
            node-key="id"
            @check="currNodeChange"
          >
            <div slot-scope="{ node, data }" class="custom-tree-node">
              <template v-if="!icons">
                <i
                  v-if="data[defaultProps.children] !== undefined && showIcon"
                  class="iconfont"
                  :class="[node.expanded ? 'icon-dakai' : 'icon-wenjianjia']"
                />
                <i v-else-if="showIcon" class="iconfont icon-shiyongwendang" />
                <span v-html="data[defaultProps.label]"></span>
              </template>
              <template v-else>
                <i
                  v-for="(icon, iconI) in icons.filter((icon) => {
                    return getIconClass(icon, node)[0] != '';
                  })"
                  :key="iconI"
                  class="iconfont"
                  :class="getIconClass(icon, node)"
                  :style="{ color: icon.color }"
                ></i>
                <span v-html="data[defaultProps.label]"></span>
              </template>
            </div>
          </el-tree>
        </div>
      </DsfVirtualScroll>
    </div>
  </div>
</template>
<script>
export default dsf.component({
  name: "DsfPlatformTeasWirteTree",
  ctrlCaption: "短信首页树",
  mixins: [$mixins.control],
  props: {
    name: {
      type: String,
      require: true,
    },
    apiAddress: {
      type: String,
      require: true,
    },
  },
  created() {
    // 获取树数据
    if (!this.apiAddress) {
      return;
    } else {
      if (this.apiAddress.length == 0) {
        return;
      }
    }
    this.$http
      .get(`${this.apiAddress}`)
      .done((re) => {
        if (re.success) {
          this.treeData = re.data;
        } else {
          dsf.layer.message(re.message, false);
        }
      })
      .error((re) => {
        dsf.layer.message(re.message, false);
      });
  },
  data() {
    return {
      defaultProps: { children: "children", label: "name" },
      expandedKeys: [], // 展开节点
      icons: [
        {
          code: "!@[children]",
          iconExpand: "icon-shiyongwendang",
          iconFold: "icon-shiyongwendang",
          color: "#6594dc",
        },
        {
          code: "!!@[children]",
          iconExpand: "icon-dakai",
          iconFold: "icon-wenjianjia",
          color: "#c78511",
        },
      ], // 树图标
      treeData: [], // 树数据
      searchName: "", // 搜索名字
    };
  },
  watch: {
    searchName(val) {
      this.$refs.tree.filter(val);
    },
  },
  methods: {
    // 筛选树中的内容
    filterNode(value, data) {
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    },
    // 选中树节点事件
    currNodeChange() {
      let checks = this.$refs.tree.getCheckedNodes();
      let peos = [];
      checks.forEach((item) => {
        //  && item.name.indexOf(this.searchName) !== -1
        if (!item.children && item.type == "user") {
          peos.push({
            name: item.name,
            phoneNumber: item.phoneNumber,
            userTheirValue: item.userTheirValue,
            userTheirText: item.userTheirText,
            type: item.type,
            id: item.id,
          });
        }
      });
      this.$emit("handleAdd", this.name, peos);
    },
    // 树图标
    getIconClass(iconData, nodeData) {
      if (dsf.express.eval(iconData.code, nodeData.data)) {
        if (nodeData.expanded) {
          return [iconData.iconExpand || ""];
        } else {
          return [iconData.iconFold || ""];
        }
      }
      return [""];
    },
  },
});
</script>
