<template>
  <DsfHomePanel
    :title="title"
    :titleIcon="titleIcon"
    moreIcon="shezhi"
    :moreConfig="moreConfig"
    :padding="padding"
    :margin="margin"
    class="dsf-management-box-notice"
    :styleType="styleType"
    :height="heightPanel"
    :borderRadius="borderRadius"
    :width="widthPanel"
    @moreBtn="moreBtn"
    :moreShow="true"
  >
    <DsfNavMenu
      :dataSourceUrl="dataUrl"
      :colNum="colNum"
      :rowNum="rowNum"
      :maxPages="maxPages"
      :height="height"
      :minHeight="minHeight"
      :arrow="arrow"
      :useDefaultStyle="useDefaultStyle"
      :hasMore="hasMore"
      :moreText="moreText"
      :moreIcon="moreIcon"
      :moreUrl="moreUrl"
      :moreOpentype="moreOpentype"
      :hoverAnimation="hoverAnimation"
      :defaultIcon="defaultIcon"
      :showPriority="showPriority"
      :menuHasBg="menuHasBg"
      :menuBgColor="menuBgColor"
      :menuBgShadow="menuBgShadow"
      :menuColor="menuColor"
      :menuShadow="menuShadow"
      :menuBorderRadius="menuBorderRadius"
      :menuBorderSize="menuBorderSize"
      :menuIconSize="menuIconSize"
      :menuBtSize="menuBtSize"
      :menuBtRadius="menuBtRadius"
      :dataSource="dataSource"
      :dataSourceList="dataSourceList"
      :itemClick="itemClick"
      :is-design="isDesign"
      ref="navmenu"
    >
    </DsfNavMenu>
    <el-dialog
      :append-to-body="true"
      v-if="menuDialog"
      title="自定义菜单"
      :visible.sync="menuDialog"
      width="1000px"
    >
      <div style="height: 400px">
        <DsfNavMenuSetting ref="menuSetting" :value="navValue">
        </DsfNavMenuSetting>
      </div>
      <div slot="footer" class="dialog-footer" style="text-align: right">
        <dsf-button @click="menuChoose"> 确 定 </dsf-button>
        <dsf-button type="plain" @click="menuDialog = false">取 消</dsf-button>
      </div>
    </el-dialog>
  </DsfHomePanel>
</template>

<script>
export default dsf.component({
  name: "DsfShortcutMenu",
  ctrlCaption: "快捷菜单",
  mixins: [$mixins.control],
  props: {
    // 面板宽度
    widthPanel: {
      type: String,
      default: "100%",
    },
    // 面板高度
    heightPanel: {
      type: String,
      default: "100%",
    },
    titleIcon: {
      type: String,
      default: "",
    },
    toConfig: {
      type: Object,
      default: () => ({}),
    },
    moreConfig: {
      type: Object,
      default: () => ({}),
    },
    styleType: {
      type: String,
      default: "one",
    },
    title: {
      type: String,
      default: "快捷菜单",
    },
    // 一行几列
    colNum: {
      type: Number,
      default: 5,
    },
    // 一页几行
    // 0表示不限行数，且页数将固定1
    rowNum: {
      type: Number,
      default: 0,
    },
    // 最多几页
    // 0表示无限制
    maxPages: {
      type: Number,
      default: 0,
    },
    // 组件高度
    height: {
      type: String,
      default: "100%",
    },
    // 组件最小高度
    minHeight: {
      type: String,
      default: "130px",
    },
    // 是否显示轮播切换箭头
    arrow: {
      type: Boolean,
      default: false,
    },
    // 是否强制使用默认样式
    useDefaultStyle: {
      type: Boolean,
      default: false,
    },
    // 是否显示更多按钮
    hasMore: {
      type: Boolean,
      default: false,
    },
    /*****更多按钮*****/
    // 更多按钮显示文字
    moreText: {
      type: String,
      default: "更多",
    },
    // 更多按钮显示图标
    moreIcon: {
      type: String,
      default: "icon-quanbu",
    },
    // 更多按钮跳转链接
    moreUrl: {
      type: String,
      default: "",
    },
    // 更多按钮跳转方式
    moreOpentype: {
      type: String,
      default: "0",
    },
    /*****样式*****/
    // 鼠标特效
    hoverAnimation: {
      type: String,
      default: "-",
    },
    // 默认图标
    defaultIcon: {
      type: String,
      default: "",
    },
    // 展示优先级。img图片优先，icon图标优先
    showPriority: {
      type: String,
      default: "img",
    },
    menuHasBg: {
      type: Boolean,
      default: true,
    },
    menuBgColor: {
      type: String,
      default: "#fff",
    },
    menuBgShadow: {
      type: Boolean,
      default: true,
    },
    menuColor: {
      type: String,
      default: "theme",
    },
    menuShadow: {
      type: Boolean,
      default: true,
    },
    menuBorderRadius: {
      type: String,
      default: "5px",
    },
    menuBorderSize: {
      type: String,
      default: "50px",
    },
    menuIconSize: {
      type: String,
      default: "30px",
    },
    // 按钮尺寸
    menuBtSize: {
      type: String,
      default: "100px",
    },
    // 按钮圆角
    menuBtRadius: {
      type: String,
      default: "5px",
    },
    // 数据来源
    dataSource: {
      type: String,
      default: "http",
    },
    dataSourceUrl: {
      type: String,
      default: "/scmenu/queryUserScMenu",
    },
    minNumber: {
      type: [Number, String],
      default: "6",
    },
    dataSourceList: {
      type: Array,
      default() {
        return [];
      },
    },
    // 边框
    margin: {
      type: Array,
      default() {
        return [10, 10, 10, 10];
      },
    },
    // 边框
    padding: {
      type: Array,
      default() {
        return [10, 20, 10, 20];
      },
    },
    // 二次开发传入的方法。
    itemClick: {
      type: Function,
      required: false,
    },
    // 圆角
    borderRadius: {
      type: Array,
      default() {
        return [0, 0, 0, 0];
      }
    },
  },
  data() {
    return {
      menuDialog: false,
      navValue: [],
      dataUrl: "",
    };
  },
  computed: {
  },
  created() {
    if (!this.isDesign) {
      this.dataUrl = this.dataSourceUrl + "?queryNum=" + this.minNumber;
    } else {
      this.$dispatch("design-height-change", "100%");
    }
  },
  methods: {
    moreBtn() {
      this.menuDialog = true;
      console.log(this.$refs.navmenu, 22);
      this.navValue = this.$refs.navmenu.data;
    },
    reloadData() {
      _.each(this.$children, (children) => {
        children?.reloadData && children.reloadData();
      });
    },
    menuChoose() {
      let that = this;
      this.loading = true;
      this.$http
        .post("/scmenu/saveUserScMenu", this.$refs.menuSetting.selectMenus, {
          headers: {
            "Content-Type": "application/json; charset=utf-8",
          },
        })
        .done(({ success, data, message }) => {
          if (success) {
            dsf.layer.message("保存成功");
            this.menuDialog = false;
            that.reloadData();
          } else {
            dsf.layer.message(message, false);
          }
        })
        .error((err) => {
          dsf.error(err);
          dsf.layer.message(err.message || "请求异常", false);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
});
</script>