<template>
  <div
    class="ds-control ds-form-item ds-datachoice_tag ds-no-padding"
    :class="getCss"
  >
    <DsfDataChoice
      ref="dataChoice"
      v-model="dataChoiceValue"
      :is-design="isDesign"
      v-bind="dataChoiceProps"
    />
    <div class="ds-datachoice_tag_auto ds-control">
      <label v-if="tagCtrlShowLabel" class="ds-form-label">
        {{ tagCtrlLabel }}
      </label>
      <div class="ds-form-block">
        <DsfButton
          :key="item.value"
          v-for="item in autoTags"
          type="plain"
          size="small"
          :title="item.text"
        >
          {{ item.text }}
        </DsfButton>
      </div>
    </div>
  </div>
</template>
<script>
import dataChoice from "./dataChoice";

export default dsf.component({
  name: "DsfTag",
  mixins: [$mixins.formControl],
  ctrlCaption: "标签",
  props: {
    ...dataChoice.props,
    tagCtrlGetSql: {
      type: String,
      default: "",
    },
    tagCtrlSetSql: {
      type: String,
      default: "",
    },
    tagCtrlLabel: {
      type: String,
      default: "自动识别标签",
    },
    tagCtrlShowLabel: {
      type: Boolean,
      default: true,
    },
    tagCtrlAutoSql: {
      type: String,
      default: "",
    },
    allowPost: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      autoTags: [],
      dataChoiceValue: [],
    };
  },
  watch:{
    dataChoiceValue:{
      handler(v){
        this.setV(v);
      },
      deep:true,
      immediate:true
    }
  },
  computed: {
    dataChoiceProps() {
      let res = {};
      for (let k in $mixins.formControl.props) {
        if (k !== "value") {
          res[k] = this[k];
        }
      }
      for (let k in dataChoice.props) {
        if (k !== "value") {
          res[k] = this[k];
        }
      }
      return res;
    },
  },
  created() {
    if (this.isDesign) {
      this.autoTags = [
        { text: "勤奋", value: "qf" },
        { text: "勇敢", value: "yg" },
        { text: "和谐", value: "hx" },
        { text: "富强", value: "fq" },
      ];
    } else {
      this.getAutoTag();
    }
    if (this.queryString.id) {
      this.getData();
    }
  },
  methods: {
    getData() {
      let url = this.$replace(this.tagCtrlGetSql || "", this.$vm);
      if (url != "") {
        this.$http
          .get(url, this.queryString)
          .done((res) => {
            if (res.success) {
              this.dataChoiceValue = res.data;
            } else {
              this.$message(res.message, false);
            }
          })
          .error((res) => {
            this.$message(res.message, false);
          });
      }
    },
    getAutoTag() {
      let url = this.$replace(this.tagCtrlAutoSql || "", this.$vm);
      if (url != "") {
        this.$http
          .get(url, this.queryString)
          .done((res) => {
            if (res.success) {
              this.autoTags = res.data || [];
            } else {
              this.$message(res.message, false);
            }
          })
          .error((res) => {
            this.$message(res.message, false);
          });
      }
    },
    postAfter() {
      let url = this.$replace(this.tagCtrlSetSql || "", this.$vm);
      if (url && url != "") {
        let i = url.indexOf("?");
        if (i != -1) {
          url =
            url.substr(0, i) +
            "?" +
            dsf.url.serialize(
              dsf.mix(dsf.url.parse(url).hashQuery, this.queryString)
            );
        } else {
          url = url + "?" + dsf.url.serialize(this.queryString);
        }
        this.$http
          .post(url, { data: JSON.stringify(this.dataChoiceValue) }, true)
          .done((res) => {
            if(!res.success){
              this.$message(res.message, false);
            }
            return res.success;
          })
          .error((res) => {
            this.$message(res.message, false);
          });
      }
    },
    setV() {
      let value = [].concat(this.autoTags, this.dataChoiceValue);
      value = _.unionBy(value, "value");
      this.emitValueChange(value);
    },
  },
});
</script>
