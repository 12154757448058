<template>
  <div
    class="ds-data-panel ds-data-panel-discrete"
    :style="panelStyle"
    :shadow="showShadow"
    @click="onClick"
  >
    <slot name="item" v-bind="$props">
      <div class="ds-data-panel-row">
        <div v-if="showIcon" class="ds-data-panel-icon" :style="iconBoxStyle">
          <DsfIcon
            :name="$replace(iconName, panelData)"
            :style="iconStyle"
          ></DsfIcon>
        </div>
        <div
          class="ds-data-panel-discrete-info"
          :align="!showDesc ? 'center' : ''"
        >
          <div
            v-if="showTitle"
            class="ds-data-panel-discrete-info-item"
            type="name"
          >
            {{ $replace(title, panelData) }}
          </div>
          <div class="ds-data-panel-discrete-info-item" type="number">
            {{ $replace(number, panelData) }}
          </div>
        </div>
      </div>
      <div v-if="showDesc || !desc" class="ds-data-panel-discrete-desc">
        {{ $replace(desc, panelData) }}
      </div>
    </slot>
  </div>
</template>

<script>
export default dsf.component({
  name: "DsfDataPanelDiscrete",
  ctrlCaption: "数据面板-主要",
  mixins: [$mixins.control],
  props: {
    // 面板圆角
    borderRadius: {
      type: String,
      default: () => "4px",
    },
    // 高度
    height: {
      type: String,
      default: () => "160px",
    },
    // 背景颜色
    backgroundColor: {
      type: String,
      default: () => "",
    },
    // 显示阴影
    showShadow: {
      type: Boolean,
      default: () => true,
    },
    // 图标名称
    iconName: {
      type: String,
      default: () => "gouxuan",
    },
    //图标颜色
    iconColor: {
      type: String,
      default: () => "#fff",
    },
    // 图标背景色
    iconBackgroundColor: {
      type: String,
      default: () => "",
    },
    // 显示图标
    showIcon: {
      type: Boolean,
      default: () => true,
    },
    // 标题
    title: {
      type: String,
      default: () => "标题",
    },
    // 显示标题
    showTitle: {
      type: Boolean,
      default: () => true,
    },
    // 描述
    desc: {
      type: String,
      default: () => "描述",
    },
    // 显示描述
    showDesc: {
      type: Boolean,
      default: () => true,
    },
    // 获取数据url
    dataUrl: {
      type: String,
      default: () => "",
    },
    // 点击跳转url
    toUrl: {
      type: String,
      default: () => "",
    },
    // 数据
    number: {
      type: String,
      default: () => "0",
    },
    // 面板数据
    panelData: {
      type: Object,
      default: () => {},
    },
    // 是否在组里
    isGroup: {
      type: Boolean,
      default: () => false,
    },
  },
  computed: {
    // 面板样式
    panelStyle() {
      let { backgroundColor, borderRadius, height } = this;
      let style = {
        borderRadius,
        height,
      };
      if (backgroundColor) style.backgroundColor = backgroundColor;
      return style;
    },
    // 图标盒子样式
    iconBoxStyle() {
      let { borderRadius, iconBackgroundColor, panelData = {} } = this;
      let style = {
        borderRadius,
      };
      if (iconBackgroundColor) style.backgroundColor = iconBackgroundColor;
      return style;
    },
    iconStyle() {
      let { iconColor } = this;
      let style = {};
      if (iconColor) style.color = iconColor;
      return style;
    },
  },
  data() {
    return {};
  },
  created() {
    !this.isDesign && this.getData();
  },
  methods: {
    onClick() {
      let { toUrl } = this;
      if (toUrl) this.$openWindow(toUrl);
    },
    getData() {
      let { dataUrl, isGroup } = this;
      if (!dataUrl || isGroup) return;
      this.$http
        .get(dataUrl)
        .then(({ data }) => {
          if (data && data.success) {
            this.$emit("update:panelData", data.data);
          } else {
            dsf.layer.message(data.message, false);
            this.$emit("update:panelData", {});
          }
        })
        .catch((err) => {
          dsf.layer.message("未知错误", false);
          this.$emit("update:panelData", {});
        })
        .finally();
    },
  },
});
</script>