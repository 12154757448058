var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "ds-platform-card-item-by-collection",
      style: _vm.controlStyle,
      on: {
        click: function ($event) {
          return _vm._clickBefore(_vm.click)
        },
      },
    },
    [
      _vm.isDesign
        ? [
            _c("div", { staticClass: "cover" }, [_c("dsf-image")], 1),
            _c(
              "div",
              {
                staticClass: "title",
                class: [_vm.lineNum < 2 ? "ellipsis" : "ellipsis-2"],
                style: _vm.titleStyle,
              },
              [
                _vm._v(
                  " 上海全力打造全国新一轮全面开放新高地的新举措上海全力打造全国新一轮 全面开放新高地的新举措上海全力打造全国新一轮全面开放新高地的新举措上海全力打造全国新一轮全面开放新高地的新举措上海全力打造 全国新一轮全面开放新高地的新举措上海全力打造全国新一轮全面开放新高地的新举措上海全力打造全国新一轮全面开放新高地的新举措上海全力打造 全国新一轮全面开放新高地的新举措上海全力打造全国新一轮全面开放新高地的新举措上海全力打造全国新一轮全面开放新高地的新举措上海全力打 造全国新一轮全面开放新高地的新举措上海全力打造全国新一轮全面开放新高地的新举措 "
                ),
              ]
            ),
          ]
        : [
            _c(
              "div",
              { staticClass: "cover" },
              [
                _c("dsf-image", {
                  attrs: {
                    src: _vm._f("imgFormat")(_vm.data["cover"]),
                    "error-src": _vm.errorSrc,
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "title",
                class: [_vm.lineNum < 2 ? "ellipsis" : "ellipsis-2"],
                style: _vm.titleStyle,
              },
              [_vm._v(" " + _vm._s(_vm.data["title"]) + " ")]
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }