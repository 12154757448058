<template>
  <div :style="{ width: width }" :class="{ active: active }" class="menu-item" @mouseenter="mouseenter">
    <div class="menu-item-box" @click="linkTo(menu)">
      <i :class="[ menu.menu_icon || 'icon-shezhi' ]" class="iconfont"></i>
      <span>{{ menu.menu_name }}</span>
    </div>
    <div v-if="menu.children && menu.children.length" ref="children" class="menu-item-children" :style="{'margin-left': childrenLeft}">
      <template v-for="level1 in menu.children">
        <div :key="level1.menu_id" class="menu-item-children-item">
          <div class="menu-item-children-item-box" @click="linkTo(level1)">
            <i :class="[ level1.menu_icon ]" class="iconfont"></i>
            <span>{{ level1.menu_name }}</span>
          </div>
          <div v-if="level1.children && level1.children.length" ref="children2" :class="[level2Position]" class="menu-item-children-level2">
            <template v-for="level2 in level1.children">
              <div :key="level2.menu_id" class="menu-item-children-level2-item" @click="linkTo(level2)">
                <div class="menu-item-children-level2-item-box">
                  <i :class="[ level2.menu_icon ]" class="iconfont"></i>
                  <span>{{ level2.menu_name }}</span>
                </div>
              </div>
            </template>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import menuUtils from "../../utils";

export default {
  name: 'HomeMenuItem',
  inject: ['homeRoot'],
  props: {
    width: {
      type: String,
      default: 'auto'
    },
    active: {
      type: Boolean,
      default: false
    },
    menu: {
      type: Object,
      required: true
    },
    // 是否只响应最后一级菜单的点击事件
    lastLevelClick: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      levelWidth: 160,
      // 二级菜单偏移量
      level2Offset: 75,
      childrenLeft: '-80px',
      level2Position: 'left'
    }
  },
  computed: {
    halfWidth () {
      return this.levelWidth / 2;
    },
    level2Param () {
      return this.level2Offset + this.levelWidth - this.levelWidth / 2;
    },
  },
  methods: {
    getClientPosition (el, p) {
      p.x += el.offsetLeft;
      p.y += el.offsetTop;
      if (el.tagName === 'HTML' || el.offsetParent === null) {
        return p;
      } else {
        return this.getClientPosition(el.offsetParent, p);
      }
    },
    mouseenter() {
      if (!this.$refs.children) {
        return;
      }
      this.levelWidth = this.$refs.children.offsetWidth;
      let position = this.getClientPosition(this.$el, {x: 0, y: 0});
      let width = this.$el.offsetWidth;
      let left = (this.levelWidth - width) / 2;
      if (position.x < left) {
        this.childrenLeft = left - position.x - this.halfWidth + 'px';
      } else if (position.x + width + left > window.innerWidth) {
        this.childrenLeft =  window.innerWidth - position.x - width / 2 - this.levelWidth + 'px';
      } else {
        this.childrenLeft = '-' + this.halfWidth + 'px';
      }
      if (position.x + width + this.level2Param > window.innerWidth) {
        this.level2Position = 'right';
      } else {
        this.level2Position = 'left';
      }
    },
    linkTo(item) {
      if (this.lastLevelClick) {
        if ((!item.children || !item.children.length) && item.menu_url) {
          menuUtils.openPage.call(this.homeRoot, item);
        }
      } else {
        menuUtils.openPage.call(this.homeRoot, menuUtils.findMenu(item));
      }
    },
  }
}
</script>