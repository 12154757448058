<template functional>
  <el-tab-pane :key="props.name" :disable="disable" :label="props.title" :name="props.name" :slot-name="props.name">
    <slot name="content"></slot>
  </el-tab-pane>
</template>
<script>
export default {
  name: 'DsfTabItem',
  ctrlCaption: '选项卡页签',
  mixins: [$mixins.layout],
  props: {
    title: {
      type: String,
      default: '',
      desc: '显示名称'
    },
    name: {
      type: String,
      default: '',
      desc: '标识'
    },
    disable: {
      type: Boolean,
      default: false,
      desc: '是否禁用'
    },
    slots: {
      type: Array,
      default() {
        return [{ name: 'default', controls: [] }];
      }
    },
    badge: {
      type: [String, Number],
      default: '',
      desc: '角标'
    }
  }
};
</script>
