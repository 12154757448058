var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "dsf-control ds-nav-menu",
      class: _vm.rootClass,
      style: _vm.rootStyle,
    },
    [
      _vm.pages == 1
        ? _c(
            "div",
            { staticClass: "ds-nav-menu-group" },
            [
              _vm._l(_vm.menuList[0], function (item) {
                return [
                  _c("nav-menu-item", { key: item._id, attrs: { item: item } }),
                ]
              }),
            ],
            2
          )
        : _vm.menuList.length > 1
        ? [
            _c(
              "el-carousel",
              {
                ref: "carousel",
                style: { "min-height": this.minHeight },
                attrs: { loop: false, autoplay: false, arrow: "never" },
                on: {
                  change: function ($event) {
                    _vm.activeIndex = $event
                  },
                },
              },
              _vm._l(_vm.menuList, function (group, index) {
                return _c("el-carousel-item", { key: index }, [
                  _c(
                    "div",
                    { staticClass: "ds-nav-menu-group" },
                    [
                      _vm._l(group, function (item) {
                        return [
                          _c("nav-menu-item", {
                            key: item._id,
                            attrs: { item: item },
                          }),
                        ]
                      }),
                    ],
                    2
                  ),
                ])
              }),
              1
            ),
            _vm.arrow && _vm.pages > 1
              ? [
                  _c(
                    "div",
                    {
                      staticClass: "ds-nav-menu-arrow left",
                      class: { disabled: _vm.activeIndex == 0 },
                    },
                    [
                      _c("i", {
                        staticClass: "iconfont icon-arrow-left2",
                        on: {
                          click: function ($event) {
                            return _vm.$refs.carousel.prev()
                          },
                        },
                      }),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "ds-nav-menu-arrow right",
                      class: {
                        disabled: _vm.activeIndex == _vm.menuList.length - 1,
                      },
                    },
                    [
                      _c("i", {
                        staticClass: "iconfont icon-arrow-right2",
                        on: {
                          click: function ($event) {
                            return _vm.$refs.carousel.next()
                          },
                        },
                      }),
                    ]
                  ),
                ]
              : _vm._e(),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }