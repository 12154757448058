<template>
  <div class="dsf-angle-select">
    <div ref="sliderBox" class="dsf-angle-select__pane" @mousedown="mousedown">
      <span class="slider" :style="thumbStyle"></span>
    </div>
    <div class="dsf-angle-select__editor">
      <el-input-number
        v-model.number="angle"
        :min="0"
        :max="360"
        size="mini"
        step-strictly
      />
    </div>
  </div>
</template>

<script>
import slider from '../slider';

// 角度选择器
export default {
  name: "DsfAngleSelect",
  mixins: [slider],
  props: {
    value: {
      type: Number,
      default: 0
    }
  },
  computed: {
    thumbStyle() {
      return {
        'transform': `translateX(-50%) translateZ(0) rotate(${this.value}deg)`
      }
    },
    angle: {
      get() {
        return this.value;
      },
      set(val) {
        val = Math.floor(val);
        this.$emit('input', val);
        this.$nextTick(() => {
          this.$emit('change', val);
        });
      }
    }
  },
  methods: {
    pointChange(x, y, ox, oy) {
      let {offsetWidth, offsetHeight} = this.$refs.sliderBox;
      let lengthX = ox - offsetWidth / 2;
      let lengthY = oy - offsetHeight / 2;
      let length = Math.sqrt(Math.pow(lengthX, 2) + Math.pow(lengthY, 2));
      let angle = Math.asin(lengthX / length) * 180 / Math.PI;
      if (lengthY > 0) {
        angle = 180 - angle;
      } else if (lengthX < 0) {
        angle = 360 + angle;
      }
      this.angle = angle;
    }
  }
}
</script>