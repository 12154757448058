var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isDesign
    ? _c("div", { staticClass: "ds-platform-card-item-by-train" }, [
        _c(
          "div",
          { staticClass: "cover" },
          [
            _c("dsf-image"),
            _c("div", { staticClass: "info" }, [
              _c("div", [
                _c("div", { staticClass: "title ellipsis-2" }, [
                  _vm._v(" 深入学习贯彻习近平新时代中国特色社会主义思想 "),
                ]),
                _c("div", { staticClass: "hours" }, [
                  _c("span", [
                    _vm._v(_vm._s(_vm.getPrefix("compulsoryHours")) + "20"),
                  ]),
                  _c("span", [
                    _vm._v(_vm._s(_vm.getPrefix("electivesHours")) + "58.5"),
                  ]),
                ]),
              ]),
            ]),
          ],
          1
        ),
        _vm._m(0),
        _vm._m(1),
      ])
    : _c(
        "div",
        {
          staticClass: "ds-platform-card-item-by-train",
          attrs: { title: _vm.data["title"] },
          on: {
            click: function ($event) {
              return _vm._clickBefore(_vm.click)
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "cover" },
            [
              _c("dsf-image", {
                attrs: {
                  src: _vm._f("imgFormat")(_vm.data["cover"]),
                  "error-src": _vm.errorSrc,
                },
              }),
              _c("div", { staticClass: "info" }, [
                _c("div", [
                  _c("div", { staticClass: "title ellipsis-2" }, [
                    _vm._v(" " + _vm._s(_vm.data["title"]) + " "),
                  ]),
                  _c("div", { staticClass: "hours" }, [
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.getPrefix("compulsoryHours")) +
                          _vm._s(_vm.data["compulsoryHours"])
                      ),
                    ]),
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.getPrefix("electivesHours")) +
                          _vm._s(_vm.data["electivesHours"])
                      ),
                    ]),
                  ]),
                ]),
              ]),
            ],
            1
          ),
          _c("div", { staticClass: "content" }, [
            _c("div", { staticClass: "date" }, [
              _vm._v(
                " " +
                  _vm._s(_vm.data["sDate"]) +
                  " ～ " +
                  _vm._s(_vm.data["eDate"]) +
                  " "
              ),
            ]),
          ]),
          _c("div", { staticClass: "footer" }, [
            _vm.data["stateValue"] == 1
              ? _c("span", { staticStyle: { color: "#D6310B" } }, [
                  _vm._v(_vm._s(_vm.data["stateText"])),
                ])
              : _c("span", [_vm._v(_vm._s(_vm.data["stateText"]))]),
            _c("span", [
              _c("i", {
                staticClass: "iconfont icon-wodexuanzhong",
                staticStyle: { "font-size": ".9em" },
              }),
              _vm._v(" " + _vm._s(_vm.data["views"])),
            ]),
          ]),
        ]
      )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "content" }, [
      _c("div", { staticClass: "date" }, [
        _vm._v(" 2019.07.24 ～ 2019.08.27 "),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "footer" }, [
      _c("span", { staticStyle: { color: "#D6310B" } }, [_vm._v("报名中")]),
      _c("span", [
        _c("i", { staticClass: "iconfont icon-wodexuanzhong" }),
        _vm._v(" "),
        _c("span", [_vm._v("1234")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }