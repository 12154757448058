<template>
  <main class="dsf-multiPage-home-main">
    <div class="dsf-multiPage-home-tags">
      <div v-show="showBt" class="dsf-multiPage-home-tags-bt __left" :disabled="disabledLeft" @click="doPagination(1)">
        <i class="iconfont icon-arrow-left2"></i>
      </div>
      <div ref="box" class="dsf-multiPage-home-tags-list" v-context-menu="{data: boxTreeMenu}">
        <draggable v-if="scriptLoaded" v-model="tagList" class="dsf-multiPage-home-tags-box" v-bind="options" @start="dragging = true" @end="dragging = false" @sort="saveStorage">
          <template v-for="tag in tagList">
            <div
              ref="tag"
              :key="tag.menu_id"
              v-context-menu="{params: tag, data: treeMenu }"
              class="dsf-multiPage-home-tags-item"
              :class="{
                active: activeTag.menu_id === tag.menu_id,
                loading: tag.loading
              }"
              :title="tag.loading"
              @click="showPage(tag)"
            >
              <!--<i v-if="tag.menu_icon" class="dsf-multiPage-home-tags-item-icon iconfont" :class="[tag.menu_icon || 'icon-wentijieda']" ></i>-->
              <span>{{ tag.menu_name || tag.loading }}</span>
              <i class="el-icon-loading"></i>
              <i class="dsf-multiPage-home-tags-item-close iconfont icon-guanbi2" @click.stop="remove({ params: tag })"></i>
            </div>
          </template>
        </draggable>
      </div>
      <div v-show="showBt" class="dsf-multiPage-home-tags-bt __right" :disabled="disabledRight" @click="doPagination(-1)">
        <i class="iconfont icon-arrow-right2"></i>
      </div>
    </div>
    <div class="dsf-multiPage-home-body" :class="{dragging: dragging}">
      <home-view ref="view" v-for="page in pageList" v-show="activeTag.menu_id === page.menu_id" :key="page.menu_id" :page="page" />
    </div>
  </main>
</template>

<script>
import HomeView from "./homeView";

let timer = false;

export default {
  name: "HomeMain",
  inject: ['homeRoot'],
  components: {
    HomeView
  },
  data() {
    return {
      dragging: false,
      showBt: true,
      scriptLoaded: false,
      disabledLeft: false,
      disabledRight: false,
      options: {
        animation: 300,
        delay: 100,
        forceFallback: true,
        draggable: ".dsf-multiPage-home-tags-item",
        ghostClass: "ghost",
        dragClass: "drag",
        chosenClass: "drag-chosen",
      },
      boxTreeMenu: [
        { icon: 'icon-delete', name: '关闭全部', handler: this.removeAll }
      ],
      treeMenu: [
        { icon: 'icon-shuaxin', name: '刷新', handler: this.refresh },
        { icon: 'icon-guanbi1', name: '关闭', handler: this.remove },
        { icon: 'icon-guanbi1', name: '关闭其他', handler: this.removeOther },
        { icon: 'icon-delete', name: '关闭全部', handler: this.removeAll }
      ],
      activeTag: null,
      tagList: [],
      pageList: [],
      tagNote: {}
    }
  },
  computed: {
    activeView() {
      if (!this.activeTag || !this.$refs.view) return null;
      let index = this.pageList.indexOf(this.activeTag);
      return this.$refs.view[index];
    }
  },
  watch: {
    'activeTag.menu_id'(to) {
      if (to && this.activeView) {
        this.$nextTick(() => {
          this.activeView.active();
        });
      }
    }
  },
  created() {
    // 读取storage中标签记录
    let tagNote = dsf.storage.session.get('__MultiPage.tagNote');
    if (tagNote && tagNote.pageList.length) {
      this.tagList = tagNote.pageList;
      this.pageList = _.clone(tagNote.pageList);
      this.activeTag = tagNote.active;
      this.tagNote = tagNote;
      if (this.activeTag._level) {
        this.homeRoot.initMenu(this.activeTag.menu_url);
      }
    } else {
      dsf.storage.session.remove('__MultiPage.tagNote');
      this.saveStorage();
      this.homeRoot.initMenu(window.location.href);
    }

    this.resize = _.throttle(this._resize, 500, {
      leading: true,
      trailing: true
    });
    this.loadScript(() => {
      this.$nextTick(() => this.resize())
    });
  },
  mounted() {
    if (!this.tagList.length) {
      let l = this.homeRoot.menuAction.length;
      if (l) {
        this.openTag(this.homeRoot.menuAction[l - 1]);
      } else {
        this.__unwatch = this.$watch('homeRoot.menuAction.length', (to) => {
          if (to) {
            this.openTag(this.homeRoot.menuAction[to - 1]);
            this.__unwatch();
          }
        })
      }
    }
    window.addEventListener('resize', this.resize);
  },
  beforeDestroy() {
    this.__unwatch?.();
    window.removeEventListener('resize', this.resize);
  },
  methods: {
    loadScript(callback) {
      if (window.vuedraggable) {
        this.scriptLoaded = true;
        callback?.();
      } else {
        this.$http
          .importFiles([
            dsf.url.getWebPath("$/js/libs/vuedraggable/Sortable-1.13.0.js"),
            dsf.url.getWebPath("$/js/libs/vuedraggable/vuedraggable.umd.js"),
          ])
          .then(() => {
            this.scriptLoaded = true;
            callback?.();
          })
          .catch((err) => {
            console.error(err);
            dsf.layer.message("加载fabric文件报错", false);
          });
      }
    },
    _resize() {
      let pageList = this.$el.querySelectorAll(".ds-page");
      pageList.forEach(p => dsf.resizeComponent(p));
      this.updateBtStatus();

      // 触发当前激活iframe的resize
      if (this.activeView) {
        this.$nextTick(() => {
          this.activeView.frameResize();
        });
      }
    },
    saveStorage() {
      this.$nextTick(() => {
        this.tagNote.pageList = _.map(this.tagList, t => {
          return {
            menu_id: t.menu_id,
            menu_name: t.menu_name,
            menu_url: t.menu_url,
            _level: t._level || null,
            dialogArgs: t.dialogArgs || null
          }
        });
        this.tagNote.active = this.activeTag ? {
          menu_id: this.activeTag.menu_id,
          menu_name: this.activeTag.menu_name,
          menu_url: this.activeTag.menu_url,
          _level: this.activeTag._level || null,
          dialogArgs: this.activeTag.dialogArgs || null
        } : null;
        dsf.storage.session.set('__MultiPage.tagNote', this.tagNote);
      });
    },
    // 激活标签页
    showPage(tag) {
      if (dsf.isString(tag)) {
        tag = _.find(this.tagList, ({menu_id}) => tag === menu_id);
      }
      if (tag && tag.menu_id) {
        this.activeTag = tag;
        this.saveStorage();
        if (tag._level) {
          this.homeRoot.initMenu(tag.menu_url);
        }
      }
    },
    /**
     * 标签页左右滑动
     * @param type 1(向左)/2(向右)
     */
    doPagination(type) {
      if ((type === 1 && this.disabledLeft) ||
        (type === -1 && this.disabledRight) || timer) {
        return
      }
      let $box = this.$refs.box;
      let $tagList = this.$refs.tag;
      let bw = $box.offsetWidth;
      let sl = $box.scrollLeft;
      let sR = sl + bw;
      let l = -1;
      if (type === 1) {
        let $tag = $tagList.find(it => it.offsetLeft <= sl && it.offsetWidth + it.offsetLeft > sl);
        if (!$tag) return;
        l = $tag.offsetLeft - (bw - $tag.offsetWidth);
      } else {
        let $tag = $tagList.find(it => it.offsetLeft < sR && it.offsetWidth + it.offsetLeft >= sR);
        if (!$tag) return;
        l = $tag.offsetLeft;
      }
      timer = true;
      $($box).animate({scrollLeft: l}, 300, () => {
        this.updateBtStatus();
        timer = false;
      })
    },
    /**
     * 打开一个标签页
     * @param item 菜单项
     */
    openTag(item){
      let index = _.findIndex(this.tagList, ({menu_id}) => item.menu_id == menu_id);
      if (index === -1) {
        index = this.tagList.length;
        this.tagList.push(item);
        this.pageList.push(item);
      } else {
        let index2 = _.findIndex(this.pageList, ({menu_id}) => item.menu_id == menu_id);
        this.$set(this.tagList, index, item);
        this.$set(this.pageList, index2, item);
      }
      this.activeTag = item;
      this.saveStorage();
      if (item._level) {
        this.homeRoot.initMenu(item.menu_url);
      }
      this.$nextTick(() => {
        if (!this.$refs.tag) return;
        let $tag = this.$refs.tag[index];
        let tw = $tag.offsetWidth;
        let tl = $tag.offsetLeft;
        let tr = tw + tl;
        let $box = this.$refs.box;
        let bw = $box.offsetWidth;
        let sl = $box.scrollLeft;
        let sR = sl + bw;
        if (tl >= sl && tr <= sR) {
          return;
        }
        let scrollLeft = tr > sR ? tl - (bw - tw) : tl;
        timer = true;
        $($box).animate({scrollLeft}, 300, () => {
          this.updateBtStatus();
          timer = false;
        })
      });
    },
    // 刷新选中标签页
    refresh({ params }) {
      let index = this.pageList.indexOf(params);
      let $view = this.$refs.view[index];
      $view.refresh();
    },
    // 移除选中标签页
    removeById(id) {
      let params = _.find(this.tagList, ({menu_id}) => menu_id === id);
      if (params) {
        this.remove({ params });
      }
    },
    // 移除选中标签页
    remove({ params }) {
      let index = this.tagList.indexOf(params);
      this.tagList.splice(index, 1);
      dsf.array.remove(this.pageList, params);
      this.saveStorage();
      if (this.activeTag === params) {
        if (!this.tagList.length) {
          this.activeTag = null;
        } else if (index > 0) {
          this.activeTag = this.tagList[index - 1];
        } else {
          this.activeTag = this.tagList[0];
        }
        if (this?.activeTag?._level) {
          this.homeRoot.initMenu(this.activeTag.menu_url);
        }
      }
      this.$nextTick(this.updateBtStatus);
    },
    // 移除其他标签页
    removeOther({ params }) {
      this.activeTag = params;
      this.tagList = [params];
      this.pageList = [params];
      this.saveStorage();
      if (params._level) {
        this.homeRoot.initMenu(params.menu_url);
      }
      this.$nextTick(this.updateBtStatus);
    },
    // 移除所有标签页
    removeAll() {
      this.activeTag = null;
      this.tagList = [];
      this.pageList = [];
      this.disabledLeft = true;
      this.disabledRight = true;
      this.saveStorage();
    },
    // 更新向左向右按钮的状态
    updateBtStatus() {
      let $box = this.$refs.box;
      let bw = $box.offsetWidth;
      let sw = $box.scrollWidth;
      let sl = $box.scrollLeft;
      this.disabledLeft = false;
      this.disabledRight = false;
      if (sl === 0) {
        this.disabledLeft = true;
      }
      if (bw + sl === sw) {
        this.disabledRight = true;
      }
    },
    test(res) {
      console.log(res);
    }
  }
}
</script>