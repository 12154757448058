var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.showBar,
          expression: "showBar",
        },
      ],
      staticClass: "ds-control ds-button-bar",
      class: _vm.getCss,
    },
    [
      _c(
        "div",
        { staticClass: "bar-wrap" },
        [
          _vm.isDesign && _vm.showButtonsReal.length == 0
            ? [
                _c(
                  "div",
                  {
                    staticStyle: {
                      "text-align": "center",
                      "background-color": "#fff",
                      width: "100%",
                      padding: "5px 0px",
                      "font-style": "italic",
                      color: "#c0c0c0",
                    },
                  },
                  [_vm._v(" 未配置按钮 ")]
                ),
              ]
            : _vm._e(),
          _vm._l(_vm.showButtonsReal, function (button, $index) {
            return [
              _vm.hasChild(button)
                ? [
                    _c(
                      "el-popover",
                      {
                        key: "children_button_popover_" + $index,
                        class: ["ds-button__more", _vm.size],
                        attrs: {
                          "popper-class": "ds-childrenbutton-popover",
                          width: "auto",
                          trigger: button.trigger,
                          "visible-arrow": false,
                        },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "ds-childrenbuttons-menu" },
                          [
                            _c("DsfButtonBar", {
                              ref: "buttonbarchildrenbutton",
                              refInFor: true,
                              class: { "ds-no-padding": true },
                              attrs: {
                                "is-design": _vm.isDesign,
                                "more-button-style": _vm.moreButtonStyle,
                                "more-button-text": _vm.moreButtonText,
                                "max-number": 1000,
                                buttons: button.children,
                                "render-button": _vm.renderButton,
                                local: _vm.local,
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "DsfButton",
                          {
                            key: "reference_" + $index,
                            style: button.style,
                            attrs: {
                              slot: "reference",
                              icon: _vm.getIcon(button),
                              title: button.title,
                              disabled: button.disabled,
                              size: _vm.size,
                              type: button.type,
                              "btn-style": button.btnStyle,
                              loading: button.loading,
                              hasChild: true,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.clickHandler(button, $event)
                              },
                            },
                            slot: "reference",
                          },
                          [_vm._v(" " + _vm._s(button.text) + " ")]
                        ),
                      ],
                      1
                    ),
                  ]
                : button.isUpload
                ? [
                    _c(
                      "el-upload",
                      {
                        key: "upload_button_" + $index,
                        staticClass: "ds-button-update",
                        attrs: {
                          disabled: button.disabled,
                          headers: _vm.getHeaders(),
                          accept: _vm.getFileAccept(button),
                          data: _vm.getUploadData(button),
                          "show-file-list": false,
                          action: _vm.uploadUrl(button),
                          "on-progress": _vm.handleProgress,
                          "on-change": _vm.handleChange,
                          "on-success": _vm.handleAvatarSuccess(button),
                          "before-upload": _vm.beforeAvatarUpload(button),
                        },
                      },
                      [
                        _c(
                          "DsfButton",
                          {
                            attrs: {
                              icon: _vm.getIcon(button),
                              title: button.title,
                              disabled: button.disabled,
                              size: _vm.size,
                              type: button.type,
                              "btn-style": button.btnStyle,
                              loading: button.loading,
                            },
                          },
                          [_vm._v(" " + _vm._s(button.text) + " ")]
                        ),
                      ],
                      1
                    ),
                  ]
                : button.isLinkSend &&
                  button.isLinkSendFold &&
                  (button.actionName === "system_flow_send" ||
                    button.actionName === "system_batch_flow_send")
                ? [
                    _c(
                      "el-popover",
                      {
                        key: "morebutton_" + $index,
                        class: ["ds-button__flow"],
                        attrs: {
                          "popper-class": "ds-morebutton-popover",
                          trigger: "manual",
                          placement: "top",
                        },
                        scopedSlots: _vm._u(
                          [
                            _vm.flowButtons.length > 0
                              ? {
                                  key: "default",
                                  fn: function () {
                                    return [
                                      _c(
                                        "el-row",
                                        {
                                          style: {
                                            width:
                                              _vm.flowButtons.length > 5
                                                ? "240px"
                                                : "80px",
                                          },
                                        },
                                        _vm._l(
                                          _vm.flowButtons,
                                          function (flow, index) {
                                            return _c(
                                              "el-col",
                                              {
                                                key: "flowButtons_" + index,
                                                staticStyle: {
                                                  "text-align": "center",
                                                },
                                                attrs: {
                                                  span:
                                                    _vm.flowButtons.length > 5
                                                      ? 8
                                                      : 24,
                                                },
                                              },
                                              [
                                                [
                                                  _c(
                                                    "DsfButton",
                                                    {
                                                      key:
                                                        "isLinkSend_" + index,
                                                      staticStyle: {
                                                        width: "80px",
                                                        "margin-bottom": "5px",
                                                        "margin-top": "5px",
                                                      },
                                                      attrs: {
                                                        icon: _vm.getIcon(flow),
                                                        disabled: flow.disabled,
                                                        size: _vm.size,
                                                        type: flow.type,
                                                        title: flow.title,
                                                        "btn-style":
                                                          "icon-text",
                                                        hasChild: _vm.hasChild(
                                                          flow
                                                        ),
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.clickHandler(
                                                            flow,
                                                            $event
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(flow.text) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                              ],
                                              2
                                            )
                                          }
                                        ),
                                        1
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                }
                              : null,
                          ],
                          null,
                          true
                        ),
                        model: {
                          value: button.popVisible,
                          callback: function ($$v) {
                            _vm.$set(button, "popVisible", $$v)
                          },
                          expression: "button.popVisible",
                        },
                      },
                      [
                        _c(
                          "DsfButton",
                          {
                            attrs: {
                              slot: "reference",
                              icon: _vm.getIcon(button),
                              title: button.title,
                              disabled: button.disabled,
                              size: _vm.size,
                              type: button.type,
                              "btn-style": button.btnStyle,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.addFlowBtns(button)
                              },
                            },
                            slot: "reference",
                          },
                          [_vm._v(" " + _vm._s(button.text) + " ")]
                        ),
                      ],
                      1
                    ),
                  ]
                : [
                    _c(
                      "DsfButton",
                      {
                        key: "__" + $index,
                        style: button.style,
                        attrs: {
                          icon: _vm.getIcon(button),
                          title: button.title,
                          disabled: button.disabled,
                          size: _vm.size,
                          type: button.type,
                          "btn-style": button.btnStyle,
                          loading: button.loading,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.clickHandler(button, $event)
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(button.text) + " ")]
                    ),
                  ],
            ]
          }),
          _vm.moreButtonsReal.length
            ? _c(
                "el-popover",
                {
                  class: ["ds-button__more", _vm.size],
                  attrs: {
                    "popper-class": "ds-morebutton-popover",
                    width: "auto",
                    trigger: "click",
                  },
                  on: { show: _vm.popoverShow, hide: _vm.popoverClose },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function () {
                          return [
                            _c(
                              "el-row",
                              {
                                style: {
                                  width:
                                    _vm.moreButtonsReal.length > 5
                                      ? "240px"
                                      : "80px",
                                },
                              },
                              _vm._l(
                                _vm.moreButtonsReal,
                                function (button, $index) {
                                  return _c(
                                    "el-col",
                                    {
                                      key: "more_buttonsReal_" + $index,
                                      staticStyle: { "text-align": "center" },
                                      attrs: {
                                        span:
                                          _vm.moreButtonsReal.length > 5
                                            ? 8
                                            : 24,
                                      },
                                    },
                                    [
                                      button.isUpload
                                        ? [
                                            _c(
                                              "el-upload",
                                              {
                                                key: "is_upload_" + $index,
                                                staticClass: "ds-button-update",
                                                attrs: {
                                                  disabled: button.disabled,
                                                  headers: _vm.getHeaders(),
                                                  accept: _vm.getFileAccept(
                                                    button
                                                  ),
                                                  data: _vm.getUploadData(
                                                    button
                                                  ),
                                                  "show-file-list": false,
                                                  action: _vm.uploadUrl(button),
                                                  "on-progress":
                                                    _vm.handleProgress,
                                                  "on-change": _vm.handleChange,
                                                  "on-success": _vm.handleAvatarSuccess(
                                                    button
                                                  ),
                                                  "before-upload": _vm.beforeAvatarUpload(
                                                    button
                                                  ),
                                                },
                                              },
                                              [
                                                _c(
                                                  "DsfButton",
                                                  {
                                                    staticStyle: {
                                                      width: "80px",
                                                      "margin-bottom": "5px",
                                                      "margin-top": "5px",
                                                    },
                                                    attrs: {
                                                      icon: _vm.getIcon(button),
                                                      title: button.title,
                                                      disabled: button.disabled,
                                                      size: _vm.size,
                                                      type: button.type,
                                                      "btn-style": "icon-text",
                                                      loading: button.loading,
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(button.text) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        : [
                                            _c(
                                              "DsfButton",
                                              {
                                                key: "_" + $index,
                                                staticStyle: {
                                                  width: "80px",
                                                  "margin-bottom": "5px",
                                                  "margin-top": "5px",
                                                },
                                                attrs: {
                                                  icon: _vm.getIcon(button),
                                                  disabled: button.disabled,
                                                  size: _vm.size,
                                                  type: button.type,
                                                  title: button.title,
                                                  "btn-style": "icon-text",
                                                  loading: button.loading,
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.clickHandler(
                                                      button,
                                                      $event
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(button.text) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ],
                                    ],
                                    2
                                  )
                                }
                              ),
                              1
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    false,
                    1052465934
                  ),
                },
                [
                  _vm.moreButtonsReal.length &&
                  _vm.moreButtonStyle != "icon-text"
                    ? _c(
                        "DsfButton",
                        {
                          staticClass: "plain",
                          class: [_vm.moreButtonStyle],
                          attrs: {
                            slot: "reference",
                            title: _vm.moreButtonText,
                            "btn-style": _vm.moreButtonStyle,
                            icon: _vm.moreFold
                              ? "shuangjiantouxia"
                              : "xiangshangzhanhang",
                            size: _vm.size,
                          },
                          slot: "reference",
                        },
                        [_vm._v(" " + _vm._s(_vm.moreButtonText) + " ")]
                      )
                    : _vm.moreButtonsReal.length &&
                      _vm.moreButtonStyle == "icon-text"
                    ? _c(
                        "DsfButton",
                        {
                          staticClass: "plain",
                          class: [_vm.moreButtonStyle],
                          attrs: {
                            slot: "reference",
                            title: _vm.moreButtonText,
                            "btn-style": _vm.moreButtonStyle,
                            icon: "gengduo1",
                            size: _vm.size,
                          },
                          slot: "reference",
                        },
                        [_vm._v(" " + _vm._s(_vm.moreButtonText) + " ")]
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }