<template>
  <div class="ds-control ds-form-item ds-area-select" :class="getCss">
    <template v-if="simple && readOnly && !isDesign">
      <DsfTextProxy v-model="value" v-bind="_self.$props"></DsfTextProxy>
      <slot name="error">
        <div v-if="errors.length>0" class="ds-error-text">{{ errorsMsg }}</div>
      </slot>
    </template>
    <template v-else>
      <!--<label v-if="showLabel" class="ds-form-label" :style="getLabelWidthStyle()">
        {{ label }}
        <i v-if="dataCapion" class="iconfont icon-bangzhuzhongxin ds-control-helper" @click="showHelper"></i>
      </label>-->
      <DsfFieldLabel
        v-if="showLabel"
        :mode="showDataCaptionMode"
        :style="getLabelWidthStyle()"
        :show-icon="showDataCaptionIcon"
        :trigger="showDataCaptionTrigger"
        :data-caption="dataCapion"
        :is-design="isDesign"
        :owner="_self">
        {{ $t(label) }}
      </DsfFieldLabel>
      <div class="ds-form-block" :class="{'ds-error-border':errors.length>0}" :style="getFormItemBlockMarginLeft()">
        <!--只读状态只显示div-->
        <div v-if="readOnly" class="ds-form-readonly">
          <DsfTextProxy v-model="value" v-bind="_self.$props" :show-label="false"></DsfTextProxy>
        </div>
        <!--非只读状态：级联-->
        <el-cascader
          v-else-if="showCascader"
          ref="cascader"
          class="ds-area-select-cascader"
          v-model="cascaderValue"
          :placeholder="placeholder"
          :props="options"
          :options="items"
          :show-all-levels="showAllLevels"
          clearable
          filterable
          @change="changeHandler">
          <template #default="{ node, data }">
            {{ data[optionsTextField] || data.name }}
          </template>
        </el-cascader>
        <slot name="error">
          <div v-if="errors.length>0" class="ds-error-text">{{ errorsMsg }}</div>
        </slot>
      </div>
    </template>
  </div>
</template>

<script>
import { getValuesByLastValue, getOptions } from '_platform/output/area/areaUtils';

export default dsf.component({
  name: "DsfAreaSelect",
  mixins: [$mixins.formControl],
  ctrlCaption: "行政区划",
  props: {
    value: {
      type: Object,
      default() {
        return {};
      }
    },
    placeholder: {
      type: String,
      default: "请选择"
    },
    formatter: {
      type: String,
      default: "textValue^text"
    },
    superCode: {
      type: [String, Number],
      default: "000000000000"
    },
    range: {
      type: Array,
      default() {
        return ["0", "4"];
      }
    },
    metadata: {
      type: Object,
      default() {
        return dsf.metadata.get("items-meta-data");
      }
    },
    // 输入框中是否显示选中值的完整路径
    showAllLevels: {
      type: Boolean,
      default: true
    },
    // 是否可选择任意一级
    checkStrictly: {
      type: Boolean,
      default: false
    },
    // 提交数据是否包含别名
    postHasAlias: {
      type: Boolean,
      default: false
    },
    // 选项中显示的文本字段
    optionsTextField: {
      type: String,
      default: "name"
    },
    //选中时显示的值
    selectedTextField: {
      type: String,
      default: "name"
    }
  },
  data() {
    return {
      isInit: false,
      options: {},
      items: [],
      open: false,
      isTriggerChange: false,
      showCascader: true,
    };
  },
  computed: {
    // 是否只有一级
    isSingle() {
      return this.range[0] == this.range[1];
    },
    // 对value值得value逆向解析出所有的key
    // 用于数据复现
    ids() {
      if (!this.value?.value) {
        return [];
      }
      return getValuesByLastValue(this.value.value);
    },
    cascaderValue: {
      get() {
        let arr = this.ids.slice(+this.range[0] + 1);
        arr.length && this.$nextTick(this.setInputText);
        return arr;
      },
      set(to) {
        let res = { text: "", value: "" };
        if (this.postHasAlias) {
          res.alias = "";
        }
        if (to?.length) {
          let lastId = to[to.length - 1];
          let text = [],
            alias = [];
          if (this.range[0] == this.range[1]) {
            let item = _.find(this.items, (it) => it["dsfa_area_id"] === lastId);
            if (item) {
              text.push(item.name);
              alias.push(item.alias);
            }
          } else {
            let panel = this.$refs.cascader.$refs.panel;
            if (!panel.activePath.length) {
              panel.activePath = _.map(to, (t, i) => {
                return _.find(panel.menus[i], p => p.value === t);
              });
            }
            _.each(panel.activePath, ({ data, children }, i) => {
              text.push(data.name);
              alias.push(data.alias);
              if (i == panel.activePath.length - 1) {
                let cc = children.find((c) => lastId === c.value);
                if (cc?.data) {
                  text.push(cc.data.name);
                  alias.push(cc.data.alias);
                }
              }
            });
          }
          res = {
            text: this.showAllLevels ? text.join("-") : _.last(text),
            value: lastId
          };
          if (this.postHasAlias) {
            res["alias"] = this.showAllLevels ? alias.join("-") : _.last(alias);
          }
        }
        // this.valueCopy = to.join('.');
        this.emitValueChange(res);
        this.$nextTick(() => {
          this.emitFormValidate(res);
        });
      }
    }
  },
  watch: {
    superCode() {
      this.cascaderValue = [];
      this.init();
      this.showCascader = false;
      setTimeout(() => {
        this.showCascader = true;
      }, 20);
    }
  },
  created() {
    this.init();
  },
  beforeDestroy() {
    this._watch?.();
  },
  methods: {
    init() {
      if (!this.isDesign) {
        this._watch?.();
        this.isInit = false;
        this.items = [];
        this.options = {
          value: "dsfa_area_id",
          label: this.selectedTextField,
          children: "children"
        };
        this.getData({ root: true }, (list) => {
          this.items = list;
          this.options = {
            value: "dsfa_area_id",
            label: this.selectedTextField,
            checkStrictly: this.checkStrictly,
            children: "children",
            lazy: true,
            lazyLoad: this.getData
          };
          // 值复现
          this._watch = this.$watch(
            "cascaderValue",
            (to) => {
              if (!this.isTriggerChange && to?.length > 1) {
                let i = 0;
                let fn = () => {
                  this._queue = () => {
                    let v = to?.[i++];
                    if (v) {
                      let cascader = this.$refs.cascader;
                      if (cascader?.panel) {
                        let node = findCascaderNode(cascader.panel, i - 1, v);
                        if (node) {
                          if (i === to.length) {
                            this.setInputText();
                          } else {
                            node.handleExpand();
                            fn();
                            return;
                          }
                        }
                      }
                    }
                    this._queue = null;
                  };
                };
                fn();
                this.$nextTick(() => {
                  this._queue();
                });
              } else {
                this.$nextTick(() => {
                  this.setInputText();
                });
              }
              this.isTriggerChange = false;
            },
            {
              deep: true,
              immediate: true
            }
          );
        });
      }
    },
    changeHandler(v) {
      this.isTriggerChange = true;
      //此函数为了改善，选择radio时且为懒加载时候,面板会显示暂无数据的问题
      let panel = this.$refs.cascader.$refs.panel;
      if (panel) {
        let nodes = panel.getCheckedNodes();
        let node = nodes[nodes.length - 1];
        //如果节点已经懒加载完成
        if (!node.loaded) {
          //调用懒加载方法
          panel.lazyLoad &&
            panel.lazyLoad(node, () => {
              //数据完成后展开节点
              panel.handleExpand(node, true);
            });
        } else {
          panel.handleExpand(node, true);
        }
      }
    },
    // 强制修改文本框的显示文字
    setInputText() {
      let field = { name: "text", alias: "alias" }[this.selectedTextField] || "text";
      let text = this.value[field];
      if (text) {
        let cascader = this.$refs.cascader;
        if (cascader) {
          cascader.presentText = cascader.inputValue = text.replace(/-/g, " / ");
        }
      }
    },
    getData(node, resolve) {
      if (this.isDesign) {
        resolve([]);
        this.isInit = true;
        return;
      }
      let { root, data } = node;
      let ids, level;
      if (root) {
        ids = this.superCode;
        level = +this.range[0] + 1;
      } else {
        let { treeinfo_globalid, treeinfo_level } = data;
        ids = treeinfo_globalid;
        level = treeinfo_level;
      }
      if (level > +this.range[1] + 1) {
        resolve([]);
        this.isInit = true;
        return;
      }
      getOptions.call(
        this,
        {
          ids,
          endRange: level + 1,
          startRange: level + 1
        },
        (list) => {
          if (level === +this.range[1] + 1) {
            list.forEach((l) => (l.leaf = true));
          }
          resolve(list);
          if (this.isInit) {
            this.$nextTick(() => {
              this._queue?.();
            });
          }
          this.isInit = true;
        }
      );
    }
  },
  design: {
    layout: {
      exclude: ["value", "items"]
    },
    metadata: {
      create(root) {
        let res = $mixins.formControl.design.metadata.create.call(this, root);
        let aliasIndex = _.findIndex(res.metadata.valueAttributes, (it) => it.code === "alias");
        if (this.postHasAlias && aliasIndex === -1) {
          res.metadata.valueAttributes.push({
            name: "别名",
            code: "alias",
            type: dsf.metadata.getDataType("string"),
            length: 100,
            defaultValue: null,
            encrypt: false,
            unit: null
          });
        } else if (!this.postHasAlias && aliasIndex > -1) {
          res.metadata.valueAttributes.splice(aliasIndex, 1);
        }
        return res;
      }
    }
  }
});

function findCascaderNode(panel, i, v) {
  let node = panel.getNodeByValue(v);
  if (!node) {
    return null;
  }
  return _.find(panel.$children[i].$children[0].$children, (it) => it.$vnode.key === node.uid);
}
</script>