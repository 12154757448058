var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "ds-control ds-section ds-no-padding",
      class: _vm.getCss,
      style: _vm.getStyle,
    },
    [
      _c("div", { staticClass: "ds-section-bg-wrap", style: _vm.wrapStyle }, [
        _c("div", { staticClass: "ds-section-bg", style: _vm.bgStyle }),
        _c("div", { staticClass: "ds-section-fg", style: _vm.fgStyle }),
      ]),
      _c("div", { staticClass: "ds-section-main", style: _vm.$margin }, [
        _c(
          "div",
          {
            staticClass: "ds-section-content",
            style: _vm.contentStyle,
            attrs: { "slot-name": "default" },
          },
          [_vm._t("default")],
          2
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }