var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "ds-control ds-gridlayout ds-no-padding",
      style: _vm.getStyle,
    },
    [
      _vm.isDesign
        ? _c(
            "div",
            { staticClass: "ds-gridlayout-tab" },
            [
              _vm._l(_vm.layout, function (obj, key) {
                return [
                  _c(
                    "div",
                    {
                      key: key,
                      class: { active: _vm.active === key },
                      on: {
                        click: function ($event) {
                          _vm.active = key
                        },
                      },
                    },
                    [_vm._v(_vm._s(key))]
                  ),
                ]
              }),
            ],
            2
          )
        : _vm._e(),
      _c(
        "div",
        { ref: "box", staticClass: "ds-gridlayout-box", style: _vm.boxStyle },
        [
          _c(
            "div",
            {
              ref: "boxInner",
              staticClass: "ds-gridlayout-box-inner",
              style: { height: _vm.unitSize(_vm.height) },
            },
            [
              _c(
                "div",
                { ref: "cols", staticClass: "ds-gridlayout-cols" },
                _vm._l(_vm.realWidths, function (col, index) {
                  return _c("div", {
                    key: index,
                    ref: "col",
                    refInFor: true,
                    style: { width: _vm.unitSize(_vm.realWidths[index]) },
                  })
                }),
                0
              ),
              _vm.loaded
                ? _vm._l(_vm.slots, function (slot) {
                    return _c(
                      "div",
                      {
                        key: slot.name,
                        staticClass: "ds-gridlayout-area",
                        attrs: { "slot-name": slot.name },
                      },
                      [_vm._t(slot.name)],
                      2
                    )
                  })
                : _vm._e(),
            ],
            2
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }