/*
 * @Descripttion: 饼状图默认配置项
 * @Author: zhanghang
 * @Date: 2021-07-28 13:48:57
 * @LastEditors: zhanghang
 * @LastEditTime: 2022-01-14 15:00:03
 */
import common from "./common"
export default dsf.mix(true, {
  //数据系配置
  series: [{
    name: '示例',
    type: 'pie',
    //饼图位置
    center: ['50%', '50%'] ,
    //饼图内外环
    radius: ['50%', '70%'],
    // 防止标签重叠
    avoidLabelOverlap: true,
    //是否展示成南丁格尔
    roseType:false,
    // 标签
    label: {
      show: true,
      fontSize:12,
      position: 'outside'
    },
    // 标签引导线
    labelLine: {
      show: true
    },
    data: [
      {value: 335, name: '直接访问',itemStyle:{color:common.color[0]}},
      {value: 310, name: '邮件营销',itemStyle:{color:common.color[1]}},
      {value: 234, name: '联盟广告',itemStyle:{color:common.color[2]}},
      {value: 135, name: '视频广告',itemStyle:{color:common.color[3]}},
      {value: 1548, name: '搜索引擎',itemStyle:{color:common.color[4]}}
    ]
  }],
  //提示配置
  tooltip: {
    trigger: 'item',
  },
}, common); 