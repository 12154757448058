var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page-home-dw-header" }, [
    _c("div", { staticClass: "site-info" }, [
      _c(
        "a",
        { staticClass: "site-info-name", attrs: { href: _vm.indexUrl } },
        [_c("img", { attrs: { src: _vm.logoUrl, alt: "" } })]
      ),
    ]),
    _c(
      "div",
      { staticClass: "page-home-dw-header-box" },
      [
        _c(
          "div",
          { staticClass: "page-home-dw-header-menu" },
          [
            _vm._l(_vm.menuList, function (menu) {
              return [
                _c(
                  "div",
                  {
                    key: menu.menu_id,
                    staticClass: "page-home-dw-header-menu-item",
                    class: { active: menu.menu_id === _vm.activeKey },
                  },
                  [
                    _c(
                      "span",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.menuClick(menu)
                          },
                        },
                      },
                      [_vm._v(_vm._s(menu.menu_name))]
                    ),
                  ]
                ),
              ]
            }),
          ],
          2
        ),
        _c(
          "el-form",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showSearch,
                expression: "showSearch",
              },
            ],
            nativeOn: {
              submit: function ($event) {
                $event.preventDefault()
              },
            },
          },
          [
            _c(
              "div",
              {
                staticClass: "page-home-dw-header-search",
                class: { focus: _vm.searchFocus },
              },
              [
                _c("div", { staticClass: "page-home-dw-header-search-input" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.trim",
                        value: _vm.searchText,
                        expression: "searchText",
                        modifiers: { trim: true },
                      },
                    ],
                    attrs: { placeholder: _vm.searchPlaceholder, type: "text" },
                    domProps: { value: _vm.searchText },
                    on: {
                      focus: function ($event) {
                        _vm.searchFocus = true
                      },
                      blur: [
                        function ($event) {
                          _vm.searchFocus = false
                        },
                        function ($event) {
                          return _vm.$forceUpdate()
                        },
                      ],
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.searchText = $event.target.value.trim()
                      },
                    },
                  }),
                  _c("i", {
                    staticClass: "iconfont icon-sousuo",
                    on: { click: _vm.search },
                  }),
                ]),
                _c(
                  "div",
                  {
                    staticClass: "page-home-dw-header-search-bt",
                    on: {
                      click: function ($event) {
                        return _vm.$emit("super-search")
                      },
                    },
                  },
                  [_vm._v("高级查询")]
                ),
              ]
            ),
            _c("button", {
              staticStyle: { display: "none" },
              on: { click: _vm.search },
            }),
          ]
        ),
      ],
      1
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showLogout,
            expression: "showLogout",
          },
        ],
        staticClass: "page-home-dw-header-logout",
      },
      [
        _c(
          "span",
          {
            staticClass: "logout-bt",
            on: {
              click: function ($event) {
                return _vm.$emit("logout")
              },
            },
          },
          [
            _c("i", { staticClass: "iconfont icon-weitongguo" }),
            _vm._v(" 关闭 "),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }