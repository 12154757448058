<template>
  <div class="ds-control ds-form-item ds-date-picker" :class="getCss">
    <template v-if="simple && readOnly && !isDesign">
      <span :title="readOnlyText">{{ readOnlyText }}</span>
      <slot name="error">
        <div v-if="errors.length > 0" class="ds-error-text">
          {{ errorsMsg }}
        </div>
      </slot>
    </template>
    <template v-else>
      <!-- <label
        v-if="showLabel"
        class="ds-form-label"
        :style="getLabelWidthStyle()"
      >
        {{ label }}
        <i
          v-if="dataCapion"
          class="iconfont icon-bangzhuzhongxin ds-control-helper"
          @click="showHelper"
        ></i>
      </label> -->
      <DsfFieldLabel
        v-if="showLabel"
        :mode="showDataCaptionMode"
        :style="getLabelWidthStyle()"
        :show-icon="showDataCaptionIcon" 
        :trigger="showDataCaptionTrigger"
        :data-caption="dataCapion"
        :is-design="isDesign"
        :owner="_self">
        {{ $t(label) }}
      </DsfFieldLabel>
      <div
        class="ds-form-block"
        :class="{ 'ds-error-border': errors.length > 0 }"
        :style="getFormItemBlockMarginLeft()"
      >
        <el-time-picker
          v-if="!readOnly"
          v-model="timeValue"
          :disabled="disabled"
          :editable="false"
          :clearable="clearable"
          :range-separator="rangeSeparator"
          :value-format="valueFormat"
          :picker-options="pickerOptions"
          :arrow-control="arrowControl"
          :start-placeholder="startPlaceholder"
          :end-placeholder="endPlaceholder"
          is-range
          @change="changeHandler"
        />
        <!--只读状态只显示div-->
        <div v-else-if="readOnly" class="ds-form-readonly">
          <span :title="readOnlyText">{{ readOnlyText }}</span>
        </div>
        <slot name="error">
          <div v-if="errors.length > 0" class="ds-error-text">
            {{ errorsMsg }}
          </div>
        </slot>
      </div>
    </template>
  </div>
</template>

<script>
export default dsf.component({
  name: "DsfTimeIntervalPicker",
  mixins: [$mixins.formControl],
  ctrlCaption: "时间区间",
  props: {
    placeholder: {
      type: String,
      default: "",
    },
    value: {
      type: Object,
      default() {
        return {
          stime: "",
          etime: "",
        };
      },
    },
    label: {
      type: String,
      default: "时间区间",
    },
    startPlaceholder: {
      type: String,
      default: "开始时间"
    },
    endPlaceholder: {
      type: String,
      default: "结束时间"
    },
    // 分隔符
    rangeSeparator: {
      type: String,
      default: "-",
    },
    // 时间显示格式
    timeFormat: {
      type: String,
      default: "HH:mm",
    },
    // 是否显示清除按钮
    clearable: {
      type: Boolean,
      default: true,
    },
    // 显示小时段
    timeInter: {
      type: Array,
      default() {
        return ["00:00:00", "23:59:59"];
      },
    },
    // 只读状态下格式化
    formatter: {
      type: String,
      default: "dateTime^hh:ii",
    },
    // 是否使用箭头进行时间选择
    arrowControl: {
      type: Boolean,
      default: false
    },
    metadata: {
      type: Object,
      default() {
        return dsf.metadata.get("time-interval-meta-data");
      },
    },
  },
  // watch: {
  //   value: {
  //     handler(to) {
  //       this.initValue(to);
  //     },
  //     immediate: true,
  //     deep: true
  //   }
  // },
  computed: {
    timeValue: {
      get() {
        if (!this.value || !this.value?.stime || !this.value?.etime) {
          return null;
        }
        let stime = this.value.stime.split(':'),
          etime = this.value.etime.split(':');
        let f = this.timeFormat;
        let res = [
          [stime?.[0] || '00', stime?.[1] || '00', '00'].join(':'),
          [etime?.[0] || '23', etime?.[1] || '59', '00'].join(':')
        ];
        if (f === 'HH:mm:ss' || f === 'H点m分s秒') {
          res[0] += stime?.[2] || '00';
          res[1] += etime?.[2] || '00';
        }
        return res;
      },
      set(v) {
        if (!v) {
          v = ['', ''];
        }
        if (this.value?.stime !== v[0] || this.value?.etime !== v[1]) {
          this.emitValueChange({
            stime: v[0],
            etime: v[1]
          });
        }
      }
    },
    readOnlyText() {
      let v = this.timeValue;
      if (!v) {
        return '';
      }
      let f = this.formatter.replace('dateTime^', '');
      return dsf.date.format(v[0], f) + ' - ' + dsf.date.format(v[1], f);
    },
    valueFormat() {
      return 'HH:mm:ss';
    },
    pickerOptions() {
      return {
        selectableRange: this.timeInter.join(' - '),
        format: this.timeFormat
      }
    }
  },
  methods: {
    changeHandler() {
      this.$nextTick(() => {
        this.$dispatch("change", this.value);
        this.emitFormValidate(this.value);
      });
    }
  }
});
</script>