function findMenuByList(menuList) {
  for (let i = 0; i < menuList.length; i++) {
    let item = menuList[i];
    let menu = findMenu(item);
    if (menu?.menu_url) {
      return menu;
    }
  }
  return null;
}

function findMenu(menu) {
  if (!menu) return null;
  if (dsf.type(menu) == "array") return findMenuByList(menu);
  if (menu.menu_url) return menu;
  if (menu.children) return findMenuByList(menu.children);
  menu.menu_url = '#/404/_' + Date.now();
  return null;
}

/**
 * 判断不同路由页面是否会复用
 * @param menu
 * @returns {boolean}
 */
function isSamePage(menu) {
  let to = menu?.__url_obj;
  let url = menu?.menu_url;
  if (!url) {
    return false;
  }
  if (!to) {
    let isRouter = false;
    if (url.startsWith("/#")) {
      url = url.substr(2);
      isRouter = true;
    } else if (url.indexOf("#") === 0) {
      url = url.substr(1);
      isRouter = true;
    }
    if (url.indexOf("/") !== 0) {
      url = '/' + url;
    }
    if (isRouter) {
      let currentUrl = dsf.url.parse(window.location.href);
      url = currentUrl.pathname + "#" + url;
    }
    to = menu.__url_obj = dsf.url.parse(url);
  }
  let from = dsf.url.parse(location.href);
  return to.hashKey == from.hashKey;
}

/**
 * 打开菜单页（路由模式）
 */
function openPageByDefault(menu, isReplace) {
  let opentype = menu.menu_opentype_value;
  let url = menu.menu_url;
  if (url.indexOf('#') == 0 && url.indexOf('#/') != 0) {
    url = url.replace('#', '#/');
  }
  switch (opentype) {
  // 主窗口打开
  // case '1':
  //   if (isSamePage(menu)) {
  //     this.viewLoaded = false;
  //     this.$nextTick(() => {
  //       this.viewLoaded = true;
  //     });
  //   }
  //   isReplace ? window.top.location.replace(url) : (window.top.location.href = url);
  //   break;
  // 新窗口打开
  case '2':
    dsf.layer.openWindow(url);
    break;
  // 对话框打开
  case '3':
    dsf.layer.openDialog({
      title: menu.menu_name,
      width: '950px',
      height: '540px',
      params: {
        path: url,
      }
    });
    break;
  // 当前窗口打开
  default:
    if (isSamePage(menu)) {
      this.viewLoaded = false;
      this.$nextTick(() => {
        this.viewLoaded = true;
      });
    }
    isReplace ? location.replace(url) : (location.href = url);
  }
}

/**
 * 打开菜单页（单页面标签页模式）
 */
function openPageByMultiPageVm(menu) {
  let opentype = menu.menu_opentype_value;
  switch (opentype) {
  // 对话框打开
  case '3':
    dsf.layer.openDialog({
      title: menu.menu_name,
      width: '950px',
      height: '540px',
      params: {
        path: url,
      }
    });
    break;
  // 当前窗口打开
  default:
    window.__multiPageVm__.$refs.main?.openTag(menu);
  }
}

/**
 * url2是否符合url1
 * 忽略下划线开头的参数
 * @param menu
 * @param currentUrl
 */
function urlsIsSame(menu, currentUrl) {
  let obj = menu.__url_obj || null;
  let url = menu.param || menu.menu_url;
  if (!obj && url) {
    let isRouter = false;
    if (url.startsWith("/#")) {
      url = url.substring(2);
      isRouter = true;
    } else if (url.indexOf("#") === 0) {
      url = url.substring(1);
      isRouter = true;
    }
    if (url.indexOf("/") !== 0) {
      url = '/' + url;
    }
    url = url.replace(/\?,/g, '%3F,').replace(/,\?/g, ',%3F');
    if (isRouter) {
      url = currentUrl.pathname + "#" + url;
    }
    obj = menu.__url_obj = dsf.url.parse(url);
  }
  if (!obj || obj.path !== currentUrl.path.split('?')[0]) {
    return false;
  }
  if (!obj || obj.hashKey !== currentUrl.hashKey) {
    return false;
  }
  let currentHashKeys = queryFilter(currentUrl.hashQuery).sort();
  let objHashKeys = queryFilter(obj.hashQuery).sort();
  if (currentHashKeys.length > objHashKeys.length) {
    return false;
  }
  for (let i = 0, l = objHashKeys.length; i < l; i++) {
    let k = objHashKeys[i];
    var rq = currentUrl.hashQuery[k];
    var iq = obj.hashQuery[k];
    if (!checkQuery(iq, rq)) {
      return false;
    }
  }
  for (let i = 0, l = currentHashKeys.length; i < l; i++) {
    let k = currentHashKeys[i];
    if (!obj.hashQuery[k]) {
      return false;
    }
  }
  return true;
}

function queryFilter(obj) {
  let res = [];
  _.forEach(obj, (v, k) => {
    if (!/^_/.test(k)) {
      res.push(k);
    }
  });
  return res;
}

/**
 * 校验url参数
 *    1 匹配参数必须为1
 *    [*] 匹配参数非空任意值
 *    [1,a,c] 匹配参数为1、a、c中任意一个
 *    [?,*] 匹配参数可有可无
 *    [?,a,c] 匹配参数为a、c中任意一个,或者为空值
 * @param menuV
 * @param urlV
 */
function checkQuery(menuV, urlV) {
  if (!/^\[.+]$/.test(menuV)) {
    return urlV != void 0 && menuV == urlV;
  }
  let res = false;
  let arr = menuV.replace(/(^\[)|(]$)/g, '').split(',');
  for (let i = 0, l = arr.length; i < l; i++) {
    let v = arr[i];
    if (v === '?' && urlV == void 0) {
      res = true;
      break;
    }
    if (v === '*' && urlV != void 0) {
      res = true;
      break;
    }
    if (v == urlV) {
      res = true;
      break;
    }
  }
  return res;
}

export default {
  findMenu,
  urlsIsSame,
  openPage(menu, isReplace) {
    if (!menu) return;
    if (!menu.menu_url) {
      menu.menu_url = '';
    }
    if (window.__multiPageVm__) {
      openPageByMultiPageVm.call(this, menu);
    } else {
      openPageByDefault.call(this, menu, isReplace);
    }
  }
};