<template>
  <div class="ds-panel" :class="[(!hasHeader?'no-header':'')]" :style="getBackgroundStyle">
    <div v-if="hasHeader" class="ds-panel-header">
      <div v-if="hasTitle" class="title">
        <label class="ds-font3" :style="{'border-right': titleBorder?'1px solid #eeeeee;':'0px none'}">
          <slot name="title"></slot>
        </label>
      </div>
      <div class="header-left">
        <slot name="header-left"></slot>
        <slot name="header-subtitle"></slot>
      </div>
      <div class="header-right">
        <slot name="header"></slot>
      </div>
    </div>
    <div class="ds-panel-body">
      <slot></slot>
    </div>
  </div>
</template>
<script>
export default {
  name: 'DsfPanel',
  props: {
    hasTitle: {
      type: Boolean,
      default: true
    },
    hasHeader: {
      type: Boolean,
      default: true
    },
    hasFooter: {
      type: Boolean,
      default: false
    },
    titleBorder: {
      type: Boolean,
      default: false
    },
    // 背景图片/背景色
    backgroundConfig: {
      type: Object,
      default: null
    },
  },
  computed: {
    getBackgroundStyle() {
      if (!this.backgroundConfig) {
        return {};
      }
      let { color, image, size, repeat, position } = this.backgroundConfig;
      image = this.$replace(image, this.local);
      return {
        'background-color': color,
        'background-image': `url('${dsf.url.getWebPath(image)}')`,
        'background-size': size,
        'background-repeat': repeat,
        'background-position': position,
      }
    }
  }
}
</script>