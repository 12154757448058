
export default {
  name: "DsfRepeatProxy",
  functional: true,
  inject: {
    $vm: {
      default: null
    }
  },
  props: {
    proxyProps:{
      type: Object,
      default(){
        return {}
      }
    },
    designId: {
      type: String,
      default: ""
    },
    hash: {
      type: String,
      default: ""
    }
  },
  render(h, content) {
    content.$vm = content.injections.$vm;
    content.$list = content.injections.$list;
    //如果相关组件对应的数据不在$controls中则线克隆对象返回一个空的虚拟dom
    if (dsf.isEmptyObject(content.props.proxyProps)) {
      var vnode=getDefaultSlot(content)
      //如果插槽中存在组件先不创建组件而是先克隆组件对应的属性数据
      if(vnode){
        cloneComponent.call(content);
      }
      return content._e();
    } else {
      var vnode=getDefaultSlot(content)
      if(vnode){
        return vnode;
      }
      return content._e();
    }
  }
};
function getDefaultSlot(content){
  let vnode=null;
  if(content?.scopedSlots?.default){
    vnode=content.scopedSlots.default();
  }
  return vnode;
}
function cloneComponent() {
  let key = this.props.designId + "-" + this.props.hash;
  if (this.$vm) {
    if (!this.$vm.$controls[key]) {
      let props = dsf.mix(true, {}, this.$vm.$controls[this.props.designId]);
      this.$vm.$set(this.$vm.$controls, key, props);
    }
  }
}


