var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isDesign || (_vm.sPID && _vm.sCurLinkID)
    ? _c(
        "div",
        {
          staticClass: "ds-control ds-form-item ds-textbox",
          class: _vm.getCss,
        },
        [
          _vm.showLabel
            ? _c(
                "DsfFieldLabel",
                {
                  style: _vm.getLabelWidthStyle(),
                  attrs: {
                    mode: _vm.showDataCaptionMode,
                    "show-icon": _vm.showDataCaptionIcon,
                    trigger: _vm.showDataCaptionTrigger,
                    "data-caption": _vm.dataCapion,
                    "is-design": _vm.isDesign,
                    owner: _vm._self,
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t(_vm.label)) + " ")]
              )
            : _vm._e(),
          _c(
            "div",
            {
              staticClass: "ds-form-block",
              style: _vm.getFormItemBlockMarginLeft(),
            },
            [
              _vm._l(_vm.data, function (item, index) {
                return [
                  _c(
                    "p",
                    { key: index + "0", staticClass: "form-comment-box-value" },
                    [
                      _c("img", {
                        staticClass: "icon",
                        attrs: {
                          src: require("_platform/assets/styles/themes/workFlow/ry1.png"),
                        },
                      }),
                      _c("span", { staticClass: "name" }, [
                        _vm._v(_vm._s(item.rusername)),
                      ]),
                      _c("span", { staticClass: "content" }, [
                        _vm._v(
                          _vm._s(item.opinionatts && item.opinionatts.Content)
                        ),
                      ]),
                      _c("span", { staticClass: "time" }, [
                        _vm._v(_vm._s(item.receivetime)),
                      ]),
                    ]
                  ),
                  item.opinionatts &&
                  item.opinionatts.Attachments &&
                  item.opinionatts.Attachments.length > 0
                    ? _c("DsfFlowFileList", {
                        key: index + "1",
                        attrs: { attachments: item.opinionatts.Attachments },
                      })
                    : _vm._e(),
                ]
              }),
              _vm.isShowAdd
                ? _c(
                    "p",
                    { staticClass: "form-comment-add" },
                    [
                      _c("DsfButton", { on: { click: _vm.addComment } }, [
                        _vm._v(_vm._s(_vm.addCommentText)),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }