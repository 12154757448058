/*
 * @Editor: chenqy
 * @Description: 实时语音Mixin
 * @Date: 2021-11-12 12:18:50
 * @LastEditors: Chenqy
 * @LastEditTime: 2022-01-14 15:34:58
 */
let self = null;
let count = 0;
export default {
  data() {
    return {
      voiceRecorder: null,
      durationTime: 300, // 音频切片间隔  单位ms
      sampleRate: 16000,
      v1: null, // audio标签1
      v2: null, // audio标签2
      audioContext1: null,
      audioContext2: null,
    };
  },
  created() {
    this.loadVoiceScript(this.handleInitTalking);
    let AudioContext = window.AudioContext || window.webkitAudioContext;
    this.audioContext1 = new AudioContext();
    this.audioContext2 = new AudioContext();
    self = this;
  },
  methods: {
    loadVoiceScript(callback) {
      if (window.Recorder) {
        dsf.layer.confirm("是否启用语音功能?").then((re) => {
          callback?.();
        });
      } else {
        this.$http
          .importFiles([
            dsf.url.getWebPath("$/js/libs/record/recorder-core.js"),
            dsf.url.getWebPath("$/js/libs/record/mp3.js"),
          ])
          .then(() => {
            dsf.layer.confirm("是否启用语音功能?").then((re) => {
              callback?.();
            });
          })
          .catch((err) => {
            dsf.error(err);
            dsf.layer.message("加载Recorder文件报错", false);
          });
      }
    },
    // 初始化试试通话环境
    handleInitTalking() {
      // 上次 录音buffer被清除的下标，因recorder返回的buffer不清除会一直增长
      let preClearBufferIndex = 0;
      let bufferChunkBytes = []; // 切换储存器
      let sendTime = +new Date();
      let that = this;
      // TODO 放弃当前播放音频方式/采用audioContext加载方式。 需要后台socket支持音频流传输
      // 生成audio标签轮询播放音频源
      // let audio1 = document.createElement("audio");
      // audio1.controls = true;
      // audio1.autoplay = "autoplay";
      // audio1.style.display = "none";
      // let audio2 = audio1.cloneNode();
      // audio1.id = "dsf-voice-v1";
      // audio2.id = "dsf-voice-v2";
      // this.v1 = audio1;
      // this.v2 = audio2;
      // document.body.appendChild(audio1);
      // document.body.appendChild(audio2);
      // // 手动开始播放(chrome已禁止自动播放)
      // audio1.play();
      that.socket.$on("syncscreen/ws/voice", that.syncParam, function (data) {
        that.handleListenVoice(data?.data?.voice);
      });
      // 实时转义mp3数据
      let sendVoiceData = function (chunkBytes) {
        if (chunkBytes.length) {
          bufferChunkBytes.push(chunkBytes);
        }
        let t = +new Date();
        if (t - sendTime < that.durationTime) {
          return;
        }
        sendTime = t;
        // 拼接(durationTime)ms之内的所有音频数据
        let len = 0;
        for (let i = 0; i < bufferChunkBytes.length; i++) {
          len += bufferChunkBytes[i].length;
        }
        let allChunks = new Uint8Array(len);
        for (let i = 0, l = 0; i < bufferChunkBytes.length; i++) {
          allChunks.set(bufferChunkBytes[i], l);
          l += bufferChunkBytes[i].length;
        }
        let blob = new Blob([allChunks], { type: "audio/mp3" });
        let fileReader = new FileReader();
        fileReader.onloadend = function (e) {
          that.socket.$send("syncscreen/ws/voice", {
            endurance: false,
            voice: fileReader.result,
          });
        };
        fileReader.readAsDataURL(blob);
        bufferChunkBytes = [];
      };
      // 启动录音功能
      this.voiceRecorder = Recorder({
        type: "mp3",
        audioTrackSet: {
          echoCancellation: true,
          noiseSuppression: true,
          autoGainControl: true
        },
        sampleRate: this.sampleRate,
        bitRate: 16,
        onProcess: function (
          buffers,
          powerLevel,
          bufferDuration,
          bufferSampleRate,
          newBufferIdx,
          asyncEnd
        ) {
          for (let i = preClearBufferIndex; i < newBufferIdx; i++) {
            buffers[i] = null;
          }
          preClearBufferIndex = newBufferIdx;
        },
        takeoffEncodeChunk: function (chunkBytes) {
          sendVoiceData(chunkBytes);
        },
      });
      if (this.isSperker) {
        this.handleStartVoice();
      } else {
        this.handleEndVoice()
      }
      // this.handleStartVoice();
    },
    // 播放音频
    handleListenVoice(data) {
      // base64 转流
      const binaryStr = window.atob(data.substr(22));
      const byteLength = binaryStr.length;
      const bytes = new Uint8Array(byteLength);
      for (let i = 0; i < byteLength; i++) {
        bytes[i] = binaryStr.charCodeAt(i);
      }
      /**pcm数据进行首尾1ms淡入淡出处理，播放时可以大幅减弱爆音**/
      let sr = (this.sampleRate / 1000) * 1;
      for (let i = 0; i < sr; i++) {
        bytes.buffer[i] *= i / sr;
      }
      for (let l = bytes.buffer.length, i = ~~(l - sr); i < l; i++) {
        bytes.buffer[i] *= (l - i) / sr;
      }
      // 转pcm
      this.audioContext1.decodeAudioData(
        bytes.buffer,
        function (buffer) {
          let context = null;
          if (count % 2 === 0) {
            context = self.audioContext1;
          } else {
            context = self.audioContext2;
          }
          let bufferSource = context.createBufferSource();
          bufferSource.buffer = buffer;
          bufferSource.connect(context.destination);
          count++;
          bufferSource.start(0);
        },
        function (err) {
          console.log(err);
        }
      );

      // let { v1, v2 } = this;
      // if (!v1.src && !v2.src) {
      //   v1.src = data;
      // } else if (v1.src) {
      //   v2.src = data;
      //   v1.removeAttribute("src");
      // } else if (v2.src) {
      //   v2.removeAttribute("src");
      //   v1.src = data;
      // }
    },
    // 开始收集音频数据
    handleStartVoice() {
      let that = this;
      if (this.voiceRecorder) {
        // 打开本地媒体相关资源
        this.voiceRecorder.open(
          function () {
            that.voiceRecorder.start();
          },
          function (msg, isUserNotAllow) {
            //用户拒绝未授权或不支持
            dsf.layer.message(msg, false);
          }
        );
      }
    },
    // 结束收集音频数据
    handleEndVoice() {
      if (this.voiceRecorder) {
        // 关闭音频收集
        this.voiceRecorder.stop();
      }
    },
  },
};
