<template>
  <el-dialog
    title="裁剪图片"
    class="ds-picture-cut-dialog"
    :visible.sync="dialogVisible"
    :before-close="closeDialog"
    :close-on-click-modal="false"
    width="60%"
    append-to-body
    @opened="dialogOpened">
    <el-row>
      <el-col :span="16" style="height: 500px">
        <vue-picture-cut
          ref="pictureCut"
          :mskOption="{width:width,height:height,resize:false}"
          :menu-thickness="0"
          :max-pixel="Math.min(Math.max(width,height),1920)"
          :src="src"
        />
      </el-col>
      <el-col :span="7" :offset="1">
        <el-form label-width="90px" size="small">
          <el-form-item label="裁剪形状：">
            <el-radio-group v-model="cut.shape">
              <el-radio label="rect">矩形</el-radio>
              <el-radio label="circle">椭圆</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="翻转镜像：">
            <el-button size="mini" type="info" round @click="reverLevel">
              <i class="el-icon-sort vertial"></i> 水平
            </el-button>
            <el-button size="mini" type="info" round @click="reverVertical">
              <i class="el-icon-sort"></i> 垂直
            </el-button>
          </el-form-item>
          <el-form-item label="裁剪比例：">
            <el-select v-model="cut.ratioType" class="ratio-select" @change="ratioTypeChange">
              <el-option label="拖动" value="drag"></el-option>
              <el-option label="输入" value="custom"></el-option>
              <el-option label="自适应" value="original"></el-option>
              <el-option label="1:1" value="1:1"></el-option>
              <el-option label="4:3" value="4:3"></el-option>
              <el-option label="3:4" value="3:4"></el-option>
              <el-option label="16:9" value="16:9"></el-option>
              <el-option label="9:16" value="9:16"></el-option>
              <el-option label="3:2" value="3:2"></el-option>
              <el-option label="2:3" value="2:3"></el-option>
            </el-select>
            <div v-if="cut.ratioType == 'custom'" class="ratio-input">
              <el-input-number v-model="cut.ratio[0]" :controls="false" :min="1" placeholder="宽" @change="changeRatio" />
              <span>/</span>
              <el-input-number v-model="cut.ratio[1]" :controls="false" :min="1" placeholder="高" @change="changeRatio" />
            </div>
          </el-form-item>
          <el-form-item label="旋转角度：">
            <el-input-number
              v-model="cut.angle"
              class="angle-number"
              :min="-180"
              :max="180"
              step-strictly
              size="small"
              @change="angleBlur"
            />
            <el-button class="angle-button" icon="el-icon-refresh-left" circle @click="angleReset"/>
            <el-slider
              v-model="cut.angle"
              class="angle-slider"
              :min="-180"
              :max="180"
              :show-tooltip="false"
              @input="angleBlur"
            />
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <template v-slot:footer>
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="cutImg">确 定</el-button>
    </template>
  </el-dialog>
</template>

<script>
import { VuePictureCut, createUtils } from "vue-picture-cut";

export default {
  name: "pictureCutDialog",
  props:{
    dialogVisible: {
      type: Boolean,
      default: false
    },
    src: {
      default: ""
    },
    width: {
      type: Number,
      default: 1
    },
    height: {
      type: Number,
      default: 1
    }
  },
  components:{
    VuePictureCut
  },
  data(){
    return{
      cut: {
        shape: "rect",
        ratioType: 'custom',
        badRatio: false, //判断比例的格式是否填写正确
        angle: 0,
        angleStep: 45, //每次旋转多少度
        ratio: [1, 1],
      },
      utils: null, //调用裁剪的工具
    }
  },
  watch:{
    "cut.shape"(val) {
      this.utils.setMaskRound(val === "circle");
    },
  },
  created() {
    this.$set(this.cut, 'ratio', [this.width, this.height]);
  },
  methods:{
    angleReset() {
      this.$set(this.cut, 'angle', 0);
      this.utils?.rotateTo(0, true);
    },
    angleBlur() {
      this.utils?.rotateTo(-this.cut.angle);
    },
    ratioTypeChange() {
      let ratioType = this.cut.ratioType;
      this.utils.setMaskResize(ratioType == 'drag');
      if (ratioType != 'drag') {
        let ratio = [];
        if (ratioType == 'original') {
          const main = this.$refs.pictureCut.photoRoot.getEventList('PhotoMain');
          ratio = [main.imgRect.w, main.imgRect.h]
        } else if (ratioType == 'custom') {
          ratio = this.cut.ratio;
        } else{
          ratio = ratioType.split(':').map(i => +i);
        }
        this.utils.setMaskSize(...ratio);
      }
    },
    changeRatio() {
      this.utils.setMaskSize(...this.cut.ratio);
    },
    reverLevel() {
      this.utils.setFlipH(true);
    },
    reverVertical() {
      this.utils.setFlipV(true);
    },
    cutImg() {
      const self = this;
      const cutData = self.utils.cut(undefined, 1, "image/png");
      self.$emit("cut-img", cutData.file)
    },
    dialogOpened() {
      this.utils = createUtils(this.$refs["pictureCut"]);
    },
    closeDialog(){
      this.$emit("dialog-close")
    }
  }
}
</script>