var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ds-control ds-list-status" },
    [
      _vm.isDesign
        ? _c(
            "div",
            {
              staticStyle: {
                "text-align": "center",
                "background-color": "#fff",
                "font-style": "italic",
                color: "#c0c0c0",
              },
            },
            [_vm._v("列表状态")]
          )
        : _vm.setHandleData.length > 0
        ? _vm._l(_vm.setHandleData, function (item, index) {
            return _c(
              "p",
              { key: index },
              [
                _vm.listStatus[item.value]
                  ? [
                      _c("i", {
                        class: ["iconfont", _vm.listStatus[item.value].icon],
                        style: { color: _vm.listStatus[item.value].color },
                      }),
                      _vm._v(" " + _vm._s(item.text) + " "),
                    ]
                  : [_vm._v(_vm._s(item.text))],
              ],
              2
            )
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }