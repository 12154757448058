export default {
  install(Vue) {
    Vue.directive("focus", {
      // 指令的定义
      inserted: function (el) {
        // 饿了么input组件
        if (el.tagName === "DIV" && el.className.indexOf("el-input") > -1) {
          setTimeout(() => {
            el.children[0].focus();
          }, 0);
          return;
        }
        setTimeout(() => {
          el.focus();
        }, 0);
      }
    });
  }
};
