<template>
  <el-container>
    <el-header>
      <div style="text-align: right; height: auto; margin: 0px -20px">
        <DsfButton size="small" type="plain" @click="toChoose('clear')">清除</DsfButton>
        <DsfButton size="small" type="plain" @click="toChoose('election')">反选</DsfButton>
        <DsfButton size="small" @click="toChoose('all')">全选</DsfButton>
      </div>
    </el-header>
    <el-main style="padding: 0px">
      <el-checkbox-group v-model="checkboxData" @change="handleCheckedChange">
        <el-checkbox v-for="item in listData" :label="item.key" :key="item.key">{{ item.alias }}</el-checkbox>
      </el-checkbox-group>
    </el-main>
  </el-container>
</template>
<script>
export default {
  name: "DesDataGridExpColChoose",
  mixins: [$mixins.propertyDialogSetting],
  data() {
    return {
      checkboxData: [],
      listData: this.$attrs["excelMetas"],
    };
  },
  created() {
    if(this.listData.length){
      this.checkboxData = _.map(this.listData, item => item.key)
    }
  },
  methods: {
    toChoose(type) {
      var that = this
      const handler = {
        clear: () => {
          this.checkboxData = []
        },
        election: () => {
          this.checkboxData =  _.map(_.filter(that.listData, item => !that.checkboxData.includes(item.key)), 'key')
        },
        all: () => {
          this.checkboxData = this.checkboxData.length < this.listData.length ? _.map(this.listData, item => item.key) : []
        },
      };
      handler[type] && handler[type]()
    },
    handleCheckedChange(val) {
      this.checkboxData =  val
    },
    yes() {
      let params = {
        cols: _.filter(this.listData, item => this.checkboxData.includes(item.key)) || [],
        keys: this.checkboxData || []
      }
      return params;
    }
  }
}
</script>
