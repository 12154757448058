import focus from "./focus";
import clickoutside from "./clickoutside";
import contextMenu from "./contextMenu";

function install(Vue){
  Vue.use(focus);
  Vue.use(clickoutside);
  Vue.use(contextMenu);
}

export {
  install,
  focus,
  clickoutside
}
export default install;