import IframeLoader from "_platform/output/iframeloader.vue";

let router = [
 
  {
    path: "/iframeloader/:state?",
    name: "iframeloader",
    component: IframeLoader,
    meta: {
      title: ""
    },
    props: route => {
      // let src = decodeURIComponent(route.fullPath)
      return {
        src: route.query.src
      };
    }
  }
];

export default router;