const { Message, Loading, MessageBox, Image: { components: { ImageViewer } } } = ELEMENT;

let dialogOpening = false;

let viewerVm = null;

export default {
  openWindow(isNew, options, callback) {
    if (!dsf.isBoolean(isNew)) {
      options = isNew;
      isNew = false;
    }
    if (dsf.type(options) == "string") {
      let url = "";
      url = options;
      options = {};
      options.url = url;
    }
    // 如果在多页签架构下
    if (_spv_ && !isNew) {
      multiPageWindowOpen.call(this, options, callback)
    }
    else {
      // 碰到钉钉PC客户端window.open的bug处理,
      if (window.dd) {
        dingdingWindowOpen.call(this, options, callback)
      }
      else {
        browserWindowOpen.call(this, options, callback)
      }
    }
  },
  openDialog(options, destroyed) {
    let defaultConent = "DsfViewPart"
    if (!dialogOpening) {
      options = options || {};
      dialogOpening = true;
      let openerVm = options.openerVm ? options.openerVm : (this._isVue ? this : null);
      let comp = null;
      if (options) {
        if (!options.template) {
          if (options.params && options.params.path && !options.content) {
            options.content = defaultConent;
            options.params.showModel = "full";
            options.params.isOpenDialog = true;
            options.params.forceUpdate = false;
          }
          if (options.content && dsf.type(options.content) !== 'string' && options.content.name) {
            comp = Object.create(options.content);
            comp.mixins = [{
              inject: {
                $dialog: {
                  default: null
                }
              }
            }];
            options.content = options.content.name + "DialogCopy";
          }
        }
        if (options && options.version == "4.0") {
          if (options.height.endsWith("%")) {
            options.height = "calc(" + options.height.replace("%", "vh") + " - " + (options.btns ? "94px" : "44px") + ")";
          }
        }
      }

      let app = window.document.querySelector("#app");
      let router = this.$router || app?.__vue__?.$options.router || options.router;
      let store = this.$store || app?.__vue__?.$options.store || options.store;
      let i18n = this.$i18n || app?.__vue__?.$options.i18n || options.i18n;
      let _self = window;
      const elConfig = {
        router,
        store: store,
        i18n: i18n,
        beforeCreate() {
          if (openerVm && openerVm._routerRoot) {
            this._routerRoot = openerVm._routerRoot;
          }
          else {
            this._routerRoot = this._routerRoot = (this.$parent && this.$parent._routerRoot) || this;
          }
        },
        data: function () {
          return {
            index: dsf.uuid(),
            dialogVisible: true,
            //标题
            title: dsf.isDef(options.title) ? options.title : "对话框",
            //组件名称
            content: options.content,
            noTitle: options.noTitle,
            width: options.width || "50%",
            height: options.height || "auto",
            destroyOnClose: true,
            params: options.params || {},
            overflow: options.overflow || 'auto',
            // 底部left居左,center居中,right居右
            footerAlign: options.footerAlign || 'right',
            btns: options.btns || [],
            events: options.event,
            fullscreen: options.fullscreen,
            openerVm: openerVm,
            dialogArgs: options.dialogArgs || null,
            top: options.top || "10vh",
            yes: options.yes || dsf.noop,
            showClose: dsf.isDef(options.showClose) ? options.showClose : true,
            modal: dsf.isDef(options.modal) ? options.modal : true,
            center: dsf.isDef(options.center) ? options.center : false,
            closed: options.closed || dsf.noop,
            conentBorder: options.conentBorder || null,
          };
        },
        computed: {
          options() {
            return options;
          },
        },
        created() {
          this.setViewLoadedButtonsState(true)
        },
        mounted() {
          if (options.content == defaultConent || this.template) {
            this.$nextTick(() => {
              options.dialogLoaded && options.dialogLoaded(options.content == defaultConent ? this.$refs.content : null)
            });
          }
        },
        destroyed() {
          for (let k in this._data) {
            this._data[k] = null;
          }
          options = null;
          openerVm = null;
          app = null;
          router = null;
          store = null;
          i18n = null;
          _self = null;
          destroyed && destroyed()
        },
        methods: {
          dialogSure(result) {
            options.yes?.(this.$refs.content);
            this.close();
          },
          async clickHandler(button) {
            if (!this.dialogVisible) {
              return;
            }
            let result = true;
            let vm = this;
            if (button.handler) {
              //如果是4.0版本老系统调用,必须要兼容，无奈 :-(
              try {
                if (options && options.version == "4.0") {
                  result = await button.handler(null, $(vm.$refs.content.$el), vm.$refs.content.contentWindow);
                } else {
                  result = await button.handler(vm.$refs.content);
                }
              } catch (err) {
                console.error(err);
                result = false;
              }
            }
            if (result !== false) {
              vm.close();
            }
          },
          close() {
            this.dialogVisible = false;
          },
          onClosed() {
            if (this.closed) {
              if (options && options.version == "4.0") {
                this.closed(this.index, $(this.$el))
              } else {
                this.closed(this.$refs.content);
              }
              options.onClosed?.();
            }
          },
          cancel() {
            let _this = this;
            if (component) {
              component = null;
            }
            window.setTimeout(() => {
              _this.$destroy();
            }, 0);
          },
          contentReady() {
            this.setViewLoadedButtonsState(false)
          },
          setViewLoadedButtonsState(state) {
            if (this.options.content == "DsfViewPart") {
              _.each(this.btns || [], (btn) => {
                this.$set(btn, 'disabled', state);
              })
            }
          }
        },
        template: `
        <DsfDialog
          ref="instance"
          :class="{'has-button-bar':btns.length>0,'ds-dialog-show-animation':true}"
          :no-title="noTitle"
          :conent-border="conentBorder"
          :title="title" 
          :top="top" 
          :index="index" 
          :options="options"
          :visible.sync="dialogVisible"
          :fullscreen="fullscreen" 
          :width="width" 
          :close-on-click-modal="false" 
          :append-to-body="true"
          :show-close="showClose" 
          :close-on-press-escape="false" 
          :dialog-args="dialogArgs"
          :modal="modal" 
          :center="center" 
          :opener-vm="openerVm" 
          :footer-align="footerAlign"
          @close="onClosed"
          @closed="cancel" >
          <template v-slot:title>
            <div v-html="dsf.safe.xss(title)" class="el-dialog__title"></div>
          </template>
          <div class="ds-dialog-content" :style="{'position':'relative','height':(fullscreen?'100%':height),'overflow':overflow}">
            <template>
            ${options.template ? options.template :
            `<component 
                :key="'dialog-content'"
                :is="content" 
                v-bind.sync="params" 
                ref="content" 
                v-on="events" 
                ${options.content == "DsfViewPart" ? '@ready="contentReady"' : ''}
                @dialog-yes="dialogSure">
              </component>`
          }
            </template>
          </div>
          <template v-slot:footer>
            <DsfButton 
              size="${options.btnSize || ''}"
              :disabled="button.disabled"
              :btn-index="$index"
              v-for="(button,$index) in btns" 
              :key="$index" 
              :class="button.class" 
              :text="button.text" 
              :type="dsf.isDef(button.type)?button.type:($index==0?'':'plain')"
              :icon="button.icon"
              :loading="button.loading"
              @click="clickHandler(button)"
              >
            </DsfButton>
          </template>
        </DsfDialog>`
      }
      if (comp) {
        elConfig.components = {
          [options.content]: comp
        };
      }
      if (options.btns) {
        _.each(options.btns, (btn) => {
          if (btn.handler) {
            btn.handler = btn.handler.bind(_self);
          }
        })
      }
      let top = dsf.getTopWindow();
      let component = null;
      const Dialog = Vue.extend(elConfig);
      component = new Dialog({ el: document.createElement("div") });
      if (dsf.isUnDef(options.isTopOpen)) {
        options.isTopOpen === dsf.config.component.topOpenDialog || false;
      }
      if (options.isTopOpen === true) {
        if (top != _self) {
          let el = component.$el;
          let modal = el.previousElementSibling;
          top.document.body.appendChild(modal);
          top.document.body.appendChild(el);
          // component.dialogVisible=true;
        }
      }
      dialogOpening = false;
      return component;
    }

  },
  closeWindow(win, vm) {
    //关闭窗口，兼容dialog,window.open，tab打开页面
    var dialog = vm?.$dialog
    if (dialog) {
      dialog.close();
    }
    // 如果在单页面模板中
    else if (_spv_) {
      let window_id = win.frameElement.getAttribute("wid");
      _spv_.closePage(window_id);
    }
    else {
      let _opener = window.fromWindow || window.opener
      if (_opener) {
        if (_opener?.openerVms?.[win.name]) {
          delete _opener.openerVms[win.name];
        }
        _opener.openerVm = null;
        window.dialogArgs = null;
        _opener = null;
        window.close();
      }
      else if (window.frameElement && window.frameElement.$dialog) {
        window.frameElement.$dialog.close();
      }
    }
  },
  loading: function (options) {
    let opts = {
      background: "rgba(255,255,255,0.5)",
      text: ""
    };
    let loadingInstance = Loading.service(dsf.mix(opts, options || {}));
    return loadingInstance;
  },
  closeLoading: function (loaaInstance) {
    loaaInstance && loaaInstance.close();
  },
  message: function (options, type) {
    let _self = window;
    let top = dsf.getTopWindow();
    if (_self != top) {
      _self = top
    }
    let _Message = _self.ELEMENT.Message;
    if (dsf.type(options) == "string") {
      options = {
        message: options
      };
    }
    if (dsf.isDef(options.type)) {
      _Message(options);
      return;
    }
    if (type === false) {
      type = dsf.config.message.error;
    } else if (_Message[type] === void 0) {
      type = dsf.config.message.success;
    }
    _Message[type](options);
  },
  alert: function (options) {
    if (dsf.type(options) == "string") {
      options = {
        message: options
      };
    }
    let defaultOptions = {
      confirmButtonText: "确定",
      type: "",
      top: "10vh",
      dangerouslyUseHTMLString: true,
      title: "提示",
      message: "",
      customClass: dsf.config.message.box.customClass.join(" ")
    };
    dsf.mix(defaultOptions, options);
    let _self = window;
    let top = dsf.getTopWindow();
    if (_self != top) {
      _self = top
    }
    let msgBox = _self.ELEMENT.MessageBox;
    return msgBox.alert(defaultOptions.message, defaultOptions.title, defaultOptions)
  },
  confirm: function (options) {
    if (dsf.type(options) == "string") {
      options = {
        message: options,
      };
    }
    let defaultOptions = {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "",
      top: "10vh",
      dangerouslyUseHTMLString: true,
      title: "提示",
      message: "",
      customClass: dsf.config.message.box.customClass.join(" ")
    };
    dsf.mix(defaultOptions, options);
    let _self = window;
    let top = dsf.getTopWindow();
    if (_self != top) {
      _self = top
    }
    let msgBox = _self.ELEMENT.MessageBox;
    return msgBox.confirm(defaultOptions.message, defaultOptions.title, defaultOptions);
  },
  prompt: function (options) {
    let defaultOptions = {
      message: options.message || "",
      title: options.title || "",
      confirmButtonText: options.confirmButtonText || '确定',
      cancelButtonText: options.cancelButtonText || '取消',
      inputPattern: options.inputPattern || undefined,
      inputErrorMessage: options.inputErrorMessage || '格式不正确',
      inputType: options.inputType || 'text',
      inputPlaceholder: options.inputPlaceholder || '',
      inputValidator: options.inputValidator || dsf.noop
    }
    let _self = window;
    let top = dsf.getTopWindow();
    if (_self != top) {
      _self = top
    }
    let msgBox = _self.ELEMENT.MessageBox;
    return msgBox.prompt(defaultOptions.message, defaultOptions.title, {
      confirmButtonText: defaultOptions.confirmButtonText,
      cancelButtonText: defaultOptions.cancelButtonText,
      inputPattern: defaultOptions.inputPattern,
      inputErrorMessage: defaultOptions.inputErrorMessage,
      inputType: defaultOptions.inputType,
      inputPlaceholder: defaultOptions.inputPlaceholder,
      inputValidator: defaultOptions.inputValidator
    });
  },
  /**
   * 预览图片
   * @param opt {String[] | {images: String[], startPosition: number, onClose: function}}
   */
  imagePreview: function (opt) {
    if (!opt) {
      dsf.error("imagePreview() 参数不能为空");
      return;
    }
    let images, startPosition = 0;
    if (dsf.isArray(opt)) {
      images = opt;
    } else if (dsf.isObject(opt) && opt.images) {
      images = opt.images;
      startPosition = opt.startPosition || 0;
    } else {
      dsf.error("imagePreview() 参数错误");
      return;
    }
    let top = dsf.getTopWindow();
    if (viewerVm) {
      top.document.body.removeChild(viewerVm.$el);
      viewerVm.$destroy();
      viewerVm = null;
    }
    let el = document.createElement("div");
    top.document.body.appendChild(el);
    const viewer = Vue.extend({
      name: "DsfImageViewer",
      components: { ImageViewer },
      template: `<image-viewer v-show="show" v-bind="options"/>`,
      data() {
        return {
          show: false,
          options: {
            urlList: images,
            initialIndex: startPosition,
            onClose: this.onClose
          }
        }
      },
      mounted() {
        this.show = true;
      },
      methods: {
        onClose() {
          top.document.body.removeChild(viewerVm.$el);
          viewerVm.$destroy();
          viewerVm = null;
          opt?.onClose?.();
        }
      }
    });
    viewerVm = new viewer({ el });
  }
};

let openVms = {};
//多tab页签模式下打开窗口
function multiPageWindowOpen(options, callback) {
  let defaultOptions = {
    openerVm: (this._isVue ? this : null),
    url: "",
    dialogArgs: null
  };
  options = dsf.mix(defaultOptions, options);
  if (options.url.startsWith("#/")) {
    options.url = './page.html' + options.url;
  }
  options.name = options.name?.trim();
  let windowName = !options.name ? dsf.uuid() : options.name;
  options.url = dsf.url.getWebPath(options.url);
  if (!window.dialogArgs) {
    window.dialogArgs = {};
  }
  if (!window.openerVms) {
    window.openerVms = {};
  }
  window.dialogArgs[windowName] = JSON.stringify(options.dialogArgs);
  window.openerVms[windowName] = options.openerVm;
  _spv_.openPage({
    name: windowName,
    url: options.url,
    dialogArgs: window.dialogArgs[windowName],
    openerVm: window.openerVms[windowName],
    opener: window,
    callback
  });
}
//dingding中打开窗口
function dingdingWindowOpen(options, callback) {
  options.name = options.name?.trim();
  let windowName = !options.name ? dsf.uuid() : options.name;
  let topAppElement = null;
  try {
    //如果top窗口有访问权限时
    topAppElement = window.top.document.querySelector("#app");
  }
  catch (ex) {
    //如果top窗口无访问权限时访问当前窗体本身
    topAppElement = window.document.querySelector("#app");
  }
  let app = topAppElement ? topAppElement?.__vue__ : null;
  let coefficient = 20;
  let $win = $(window);
  let width = $win.width() - coefficient;
  let height = $win.height() - coefficient;
  let offsetTop = ($win.height() - height) / 2;
  let win = app.$openDialog({
    content: 'DsfDialogIframe',
    title: "",
    params: {
      rmiframeUrl: dsf.url.getWebPath(options.url)
    },
    width: (width) + 'px',
    height: (height - (44 + 20)) + 'px',
    // fullscreen: true,
    dialogArgs: options.dialogArgs || null,
    openerVm: this,
    conentBorder: true,
    top: offsetTop + 'px'
  });
  callback?.(win);
  return win;
}

//浏览器原生window.open
function browserWindowOpen(options, callback) {
  let defaultOptions = {
    openerVm: (this._isVue ? this : null),
    url: "",
    dialogArgs: null,
    // opener: options.opener || window
  };

  options = dsf.mix(defaultOptions, options);
  if (options.url.startsWith("#/")) {
    options.url = "./page.html" + options.url;
  }
  options.name = options.name?.trim();
  options.url = dsf.url.getWebPath(options.url);
  let _self = window;
  try {
    if (window?.top?.dsf) {
      _self = window.top;
    }
  }
  catch (ex) {
    //
  }
  let windowName = (window.windowId ? window.windowId + "." : "") + (!options.name ? dsf.uuid() : options.name);
  let newWin = _self.open(options.url, windowName);
  if (!newWin) {
    console.error('弹窗被意外终止!');
    callback?.();
    return;
  }
  if (windowName === options.name && newWin.location.href !== 'about:blank') {
    newWin.location.reload(true);
  }
  if (!newWin.opener.dialogArgs) {
    newWin.opener.dialogArgs = {};
  }
  if (!newWin.opener.openerVms) {
    Object.defineProperty(newWin.opener, "openerVms", {
      get() {
        return openVms;
      }
    })
  }
  newWin.opener.dialogArgs[windowName] = JSON.stringify(options.dialogArgs);
  newWin.opener.openerVms[windowName] = options.openerVm;
  if (options?.openerVm?.$openedWindows) {
    options?.openerVm?.$openedWindows
  }
  watchOpenWindowClosed(newWin, windowName)
  callback?.(newWin);
  return newWin;
}

function watchOpenWindowClosed(newWin, windowName) {
  //监听窗口是否关闭，如果关闭了
  if (dsf.isDef(newWin.closed)) {
    let closeId = window.setInterval(() => {
      if (newWin.closed) {
        window.clearInterval(closeId)
        delete openVms[windowName];
        newWin = null;
      }
    }, 3000);
  }
}