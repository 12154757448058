<template>
  <div class="ds-meta-data-select-item">
    <dsf-tree-select
      :tree-data="treeData"
      :loading="loading"
      :multiple="multiple"
      :theme="theme"
      :default-checked-key="defaultCheckedKey"
      :node-key="idKey"
      :node-name="nameKey"
      search-control
      default-expand-all
      @choose-node="chooseNode"
    />
  </div>
</template>

<script>
import DsfTreeSelect from './treeSelect';

export default {
  name: 'DesMetaDataSelectItem',
  components: {
    DsfTreeSelect
  },
  props: {
    nameKey:{
      type:String,
      default:"name"
    },
    idKey:{
      type:String,
      default:"ID"
    },
    url: {
      type: String,
      required: true
    },
    multiple: {
      type: Boolean,
      default: true
    },
    theme: {
      type: String,
      default: 'theme2'
    },
    // 默认选中的节点，多选为Array，单选为String或Number
    defaultCheckedKey: {
      type: [Array, String, Number],
      default () {
        return []
      }
    },
  },
  data() {
    return {
      loading: false,
      treeData: [],
      checkList: []
    }
  },
  created() {
    this.postData();
  },
  methods: {
    yes() {
      return this.checkList;
    },
    chooseNode(val) {
      this.checkList = val;
      this.$emit('choose-node', val);
    },
    postData() {
      this.loading = true;
      this.$http.get(
        this.url, {
          namespace: ''
        }
      ).then(({ data }) => {
        if (data.success) {
          this.dataFormat(data.data);
          this.treeData = data.data;
        } else {
          dsf.layer.message('请求异常！', false);
        }
      }).finally(() => {
        this.loading = false;
      });
    },
    dataFormat(nodes) {
      for (let i = 0; i < nodes.length; i++) {
        const node = nodes[i];
        node.label = node.name;
        // if (node.type_value !== '3' && node.type_value !== '8') {
        //   node.nocheck = true;
        // }
        // if ((node.type_value !== '3' && node.type_value !== '8') && !node.children) {
        //   node.iconSkin = '_special';
        // }
        if (node.children) {
          this.dataFormat(node.children)
        }
      }
    }
  }
}
</script>