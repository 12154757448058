var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "teas-body-tree" }, [
    _c(
      "div",
      { staticClass: "teas-body-tree-head" },
      [
        _c("el-input", {
          staticClass: "teas-body-tree-input",
          attrs: {
            "suffix-icon": "el-icon-search",
            placeholder: "请输入姓名查询",
          },
          model: {
            value: _vm.searchName,
            callback: function ($$v) {
              _vm.searchName = $$v
            },
            expression: "searchName",
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "teas-vr-scroll" },
      [
        _c("DsfVirtualScroll", { staticClass: "ds-tree-box" }, [
          _c(
            "div",
            [
              _c("el-tree", {
                ref: "tree",
                staticClass: "filter-tree ds-tree-select-tree-box",
                attrs: {
                  "show-checkbox": "",
                  props: _vm.defaultProps,
                  "default-expanded-keys": _vm.expandedKeys,
                  data: _vm.treeData,
                  "filter-node-method": _vm.filterNode,
                  "node-key": "id",
                },
                on: { check: _vm.currNodeChange },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var node = ref.node
                      var data = ref.data
                      return _c(
                        "div",
                        { staticClass: "custom-tree-node" },
                        [
                          !_vm.icons
                            ? [
                                data[_vm.defaultProps.children] !== undefined &&
                                _vm.showIcon
                                  ? _c("i", {
                                      staticClass: "iconfont",
                                      class: [
                                        node.expanded
                                          ? "icon-dakai"
                                          : "icon-wenjianjia",
                                      ],
                                    })
                                  : _vm.showIcon
                                  ? _c("i", {
                                      staticClass:
                                        "iconfont icon-shiyongwendang",
                                    })
                                  : _vm._e(),
                                _c("span", {
                                  domProps: {
                                    innerHTML: _vm._s(
                                      data[_vm.defaultProps.label]
                                    ),
                                  },
                                }),
                              ]
                            : [
                                _vm._l(
                                  _vm.icons.filter(function (icon) {
                                    return _vm.getIconClass(icon, node)[0] != ""
                                  }),
                                  function (icon, iconI) {
                                    return _c("i", {
                                      key: iconI,
                                      staticClass: "iconfont",
                                      class: _vm.getIconClass(icon, node),
                                      style: { color: icon.color },
                                    })
                                  }
                                ),
                                _c("span", {
                                  domProps: {
                                    innerHTML: _vm._s(
                                      data[_vm.defaultProps.label]
                                    ),
                                  },
                                }),
                              ],
                        ],
                        2
                      )
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }