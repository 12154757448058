<template>
  <div class="ds-control ds-row ds-no-padding" :class="getCss" :style="{ 'height': rowHeight, 'margin': $margin,'overflow':overflow }">
    <div class="el-row" :style="elRowStyle">
      <slot></slot>
    </div>
  </div>
</template>
<script>
import DsfCol from "./col";

/**
 * @class DsfRow
 * @augments Layout
 * @description 24份栅格布局
 * @example
 * <DsfRow></DsfRow>
 */
export default dsf.component({
  name: "DsfRow",
  ctrlCaption: "栅格布局",
  mixins: [$mixins.layout],
  provide() {
    return {
      parentRow: this
    };
  },
  /**
   * @memberof Layout
   * @name Props 属性
   * @property {String} [minHeight = '0'] 最小高度
   * @property {String} [height = 'auto'] 高度
   * @property {Number} [gutter = 0] 两端间距
   */
  props: {
    overflow: {
      type: String,
      default: "hidden"
    },
    minHeight: {
      type: String,
      default: "0"
    },
    height: {
      type: String,
      default: "auto"
    },
    gutter: {
      type: Number,
      default: 0
    },
    slots: {
      type: Array,
      default() {
        return [
          {
            name: "default",
            controls: []
          }
        ];
      }
    },
    padding: {
      type: Array,
      default: () => [0, 0, 0, 0]
    },
    margin: {
      type: Array,
      default: () => [0, 0, 0, 0]
    }
  },
  data() {
    return {
      cols: []
    };
  },
  mounted() {
    createDesignerColumns.call(this);
  },
  computed: {
    $margin() {
      let m = this.margin;
      let _m = _.map(m, (it) => {
        return this.unitSize(it || 0);
      });
      return _m.join(" ");
    },
    $padding() {
      let p = this.padding;
      let _p = _.map(p, (it) => {
        return this.unitSize(it || 0);
      });
      return _p.join(" ");
    },
    rowHeight() {
      if (this.isDesign) {
        return "100%";
      } else {
        let height = this.height;
        if (dsf.isNumber(height)) {
          height += "px";
        }
        return height;
      }
    },
    elRowHeight() {
      if (this.height != "auto") {
        return "100%";
      }
      return "auto";
    },
    elRowStyle() {
      let style = {};
      // if (this.elRowHeight != "auto") {
      //   style["height"] = this.elRowHeight;
      // }
      style["height"] = this.elRowHeight;
      if (this.gutter) {
        style["margin-left"] = (this.gutter / 2) * -1 + "px";
        style["margin-right"] = (this.gutter / 2) * -1 + "px";
      }
      return style;
    },
    getCss() {
      return [this.$getClass(), this.fit ? "fit" : ""];
    }
  },
  methods: {
    getHeight() {
      let height = this.height;
      if (!this.height.endsWith("px") && !this.height.endsWith("%") && !this.height.endsWith("em") && !this.height.endsWith("rem")) {
        height += "px";
      }
      return height;
    },
    getColumnSize(col) {
      let obj = {};
      let arr = ["lg", "xl", "md", "sm", "xs"];
      _.each(arr, (el) => {
        if (col[el] && col[el].span) {
          let span = Number(col[el].span);
          span && (obj[el] = span);
        }
      });
      return obj;
    },
    getStyle() {
      let style = {};
      if (!this.isDesign) {
        style["min-height"] = this.minHeight + "px";
      }
      return style;
    }
  },
  watch: {
    height: {
      handler(v) {
        if (this.isDesign) {
          let height = v;
          if (!v) {
            height = "auto";
          }
          if (dsf.isNumber(height)) {
            height += "px";
          }
          this.$dispatch("design-height-change", height);
        }
      },
      immediate: true
    }
  }
});
function createDesignerColumns() {
  if (this.isDesign) {
    if (this.slots[0].controls.length == 0) {
      let size = 4;
      for (let i = 0; i < size; i++) {
        let span = 24 / size;
        let obj = {
          height: "auto",
          span: span
        };
        this.slots[0].controls.push(dsf.designer.createComponent(DsfCol, obj));
      }
    }
  }
}
</script>
