<template>
  <div v-if="catalogueRoot.isDesign || !isHide" class="ds-nav-catalogue-item" :level="item.level">
    <div
      class="label"
      :class="labelClass"
      :active="catalogueRoot.active === item || catalogueRoot.rootNode === item"
      @click="catalogueRoot.itemClick(item)">
      <span>{{ item['_name'] }}</span>
    </div>
    <div v-if="item['children'] && item['children'].length" class="children">
      <nav-catalogue-item v-for="item2 in item['children']" :key="item2._id" :item="item2" />
    </div>
  </div>
</template>

<script>
export default {
  name: "navCatalogueItem",
  inject: ['catalogueRoot'],
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  computed: {
    isHide() {
      let {isHide, _isHide} = this.item;
      return isHide || _isHide;
    },
    labelClass() {
      let res = [];
      if (this.item.isDisabled) res.push('disabled');
      if (this.catalogueRoot.isDesign && this.isHide) res.push('hide');
      return res;
    }
  }
};
</script>