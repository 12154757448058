var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dsf-linear-gradual-select" },
    [
      _c(
        "div",
        { staticClass: "dsf-linear-gradual-select__main" },
        [
          _c("div", { staticClass: "dsf-linear-gradual-select__pane" }, [
            _c("div", { style: _vm.paneStyle }),
          ]),
          _c("dsf-angle-select", {
            staticClass: "dsf-linear-gradual-select__angle",
            on: { change: _vm.updateValue },
            model: {
              value: _vm.angle,
              callback: function ($$v) {
                _vm.angle = $$v
              },
              expression: "angle",
            },
          }),
        ],
        1
      ),
      _c("color-slider", {
        staticClass: "dsf-linear-gradual-select__bar",
        on: { change: _vm.updateValue },
        model: {
          value: _vm.point,
          callback: function ($$v) {
            _vm.point = $$v
          },
          expression: "point",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }