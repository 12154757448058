<template>
  <div :class="[size, {theme: theme}]" class="page-home-default-breadcrumb">
    <slot>
      <span class="text"><i class="iconfont icon-position"></i> 当前页:</span>
    </slot>
    <ul v-if="onlyLast">
      <li v-if="lastMenu">
        <slot :item="lastMenu"><a>{{ lastMenu.menu_name }}</a></slot>
        <slot name="separator"><i>{{ separator }}</i></slot>
      </li>
    </ul>
    <ul v-else>
      <li v-for="item in menuAction" :key="item.menu_id">
        <slot :item="item"><a>{{ item.menu_name }}</a></slot>
        <slot name="separator"><i>{{ separator }}</i></slot>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'DsfDefaultHomeBreadcrumb',
  inject: ['homeRoot'],
  props: {
    // 控件尺寸 normal big small
    size: {
      type: String,
      default: 'normal'
    },
    // 字体色是否跟随主题
    theme: {
      type: Boolean,
      default: true
    },
    // 分隔符
    separator: {
      type: String,
      default: '/'
    },
    // 是否值显示最后一级菜单
    onlyLast: {
      type: Boolean,
      default: false
    },
  },
  data () {
    return {
    }
  },
  computed: {
    menuAction() {
      let list = this.homeRoot.menuAction.filter(v => v.menu_id !== undefined);
      if (list.length == 0) {
        let meta = this.$route ? this.$route.meta || {} : {};
        list = [{ menu_name: meta.title || '' }];
      }
      return list;
    },
    lastMenu () {
      let l = this.menuAction.length;
      if (!l) return null;
      return this.menuAction[l - 1];
    },
    height() {
      if (this.size == 'big') {
        return '50px';
      }
      if (this.size == 'small') {
        return '42px';
      }
      return '46px';
    }
  },
  watch: {
    'height'(to) {
      this.homeRoot.setPosition('breadcrumb', to);
    }
  },
  created() {
    this.homeRoot.setPosition('breadcrumb', this.height);
  }
}
</script>