<template>
  <div
    class="page-home-default-header-menu-children"
    :class="{show: show}"
    :style="style"
    @mouseenter="mouseenter"
    @mouseleave="mouseleave">
    <div class="box">
      <transition name="el-zoom-in-top">
        <header-menu-children v-show="show" :list="list" />
      </transition>
    </div>
  </div>
</template>

<script>
import HeaderMenuChildren from "./aside/headerMenuChildren.vue";

export default {
  name: "DsfDefaultHomeHeaderMenuChildren",
  components: {
    HeaderMenuChildren
  },
  data() {
    return {
      list: [],
      show: false,
      top: 0,
      right: 0
    }
  },
  computed: {
    style() {
      return {
        'transform': `translate(-${this.right}px, ${this.top}px)`
      }
    }
  },
  mounted() {
    document.querySelector("#app > .full-view").appendChild(this.$el);
    this.$parent.$on('show-menu-children', this.showMenuChildren);
    this.$parent.$on('hide-menu-children', this.hideMenuChildren);
  },
  beforeDestroy() {
    this.$el.remove();
    this.$parent.$off('show-menu-children', this.showMenuChildren);
    this.$parent.$off('hide-menu-children', this.hideMenuChildren);
  },
  methods: {
    showMenuChildren(item, e) {
      if (!item.children?.length) {
        this.hideMenuChildren();
        return;
      }
      clearTimeout(this._t1);
      clearTimeout(this._t2);
      let {left, top, width, height} = e.target.getBoundingClientRect();
      this.top = top + height;
      this.right = window.innerWidth - left - width / 2;
      this.list = item.children;
      if (!this.show) {
        this._t2 = setTimeout(() => {
          this.show = true;
        }, 10);
      }
    },
    hideMenuChildren() {
      clearTimeout(this._t1);
      this._t1 = setTimeout(() => {
        this.show = false;
        this._t1 = setTimeout(() => {
          this.list = [];
        }, 200);
      }, 200);
    },
    mouseenter() {
      clearTimeout(this._t1);
    },
    mouseleave() {
      this.hideMenuChildren();
    }
  }
}
</script>