<template>
  <div class="ds-control ds-design-img" :style="{'text-align':align}" @click="clickHandler">
    <el-image v-if="url" :style="{ width: width, height: height, display: 'inline-block' }" :src="url">
      <div slot="error" class="image-slot" style="text-align: center; font-size: 30px">
        <i class="el-icon-picture-outline"></i>
      </div>
    </el-image>
  </div>
</template>
<script>
export default dsf.component({
  name: "DsfUploadDesignImg",
  mixins: [$mixins.control],
  ctrlCaption: "上传设计图",
  data() {
    return {};
  },
  props: {
    url: {
      type: String,
      default: ""
    },
    width: {
      type: [Number, String],
      default: "100%"
    },
    height: {
      type: [Number, String],
      default: "auto"
    },
    align:{
      type:String,
      default:"left"
    },
    toUrl:{
      type:String,
      default:""
    }
  },
  created() {},
  methods: {
    clickHandler(){
      if(this.toUrl){
        this.$openWindow({
          url:dsf.url.getWebPath(this.toUrl),
        })
      }
    }
  },
  watch: {
   
    // height: {
    //   handler(v) {
    //     this.isDesign && this.$dispatch("design-height-change", v);
    //   },
    //   immediate: true
    // }
  }
});
</script>
