<template>
  <div class="ds-control ds-platform-card-box">
    <el-checkbox v-if="checkbox && showCheckAll && maxCheckNum !== 1" class="ds-platform-card-box-checkAll" :class="[checkboxSize]" :value="checkAll" :indeterminate="isIndeterminate" @change="handleCheckAllChange($event)">
      全选
    </el-checkbox>
    <div v-if="isDesign" class="ds-platform-card-list" :class="[checkboxPosition]">
      <div class="ds-platform-card-item no-margin-left no-margin-top" :style="itemStyle" slot-name="default">
        <div class="ds-platform-card-item-content">
          <slot v-bind="{ row: {}, $index: 0 }"></slot>
        </div>
        <dsf-custom-checkbox v-if="checkbox && checkboxStyle" :style-type="checkboxStyle" :size="checkboxSize" class="ds-platform-card-item-checkbox" />
      </div>
      <template v-for="$index in (columnNumber - 1)">
        <div :key="$index" class="ds-platform-card-item no-margin-top" :style="itemStyle">
          <dsf-custom-checkbox v-if="checkbox && checkboxStyle" :style-type="checkboxStyle" :size="checkboxSize" class="ds-platform-card-item-checkbox" />
        </div>
      </template>
    </div>
    <div v-else-if="!realData.length" class="ds-platform-card-list" :class="[checkboxPosition]">
      <!-- <div class="ds-empty-data" text="暂无数据" style="max-height: 500px"></div> -->
      <dsf-empty-data style="max-height: 500px"></dsf-empty-data>
    </div>
    <div v-else class="ds-platform-card-list" :class="[checkboxPosition]">
      <template v-for="(row, $index) in realData">
        <div
          :key="row[rowKey]"
          class="ds-platform-card-item"
          :class="{
            'no-margin-left': !($index % columnNumber),
            'no-margin-top': $index < columnNumber,
          }"
          :style="itemStyle">
          <div class="ds-platform-card-item-content" @contextmenu="contextmenu(row, $index, $event)">
            <slot v-bind="{ row, $index }"></slot>
          </div>
          <dsf-custom-checkbox v-if="checkbox && checkboxStyle" class="ds-platform-card-item-checkbox" :value="checkIdList.indexOf(row[rowKey]) > -1" :style-type="checkboxStyle" :size="checkboxSize" @input="checkChange(row, $event)" />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default dsf.component({
  name: "DsfPlatformCardBox",
  ctrlCaption: "卡片列表",
  mixins: [$mixins.cardList],
  props: {
    // big, medium, small
    checkboxSize: {
      type: String,
      default: "medium"
    },
    // 选择框位置
    checkboxPosition: {
      type: String,
      default: "top right"
    }
  },
  watch: {
    columnNumber(v) {
      if (this.isDesign) {
        if (v > 1) {
          this.columnWidth = Math.floor(100 / v) - 1 + "%";
        } else {
          this.columnWidth = "100%";
        }
      }
    }
  },
  methods: {
    contextmenu(row, $index, event) {
      this.$emit('on-contextmenu', {row, $index, event});
    }
  }
});
</script>