<template>
  <div class="multiple-selection-table">
    <div class="btns">
      <DsfButton type="plain" icon="shanchu" @click="deleteHandler('togetherDelete')" >批量删除</DsfButton>
    </div>
    <el-table
      :data="selectCopy"
      border
      fit
      stripe
      height="100%"
      @selection-change="handleSelectionChange"
    >
      <el-table-column
        align="center"
        type="selection"
        width="60">
      </el-table-column>
      <el-table-column
        type="index"
        label="序号"
        :width="80"
        :resizable="false"
        header-align="center"
        align="center"
        fixed
        :index="indexMethod"
      ></el-table-column>
      <el-table-column
        v-for="col in columnsCopy"
        :key="`multiple-selection-table_${col.id}`"
        :label="col.label"
        :width="col.width"
        align="center"
      >
        <template slot-scope="scope" v-if="col.children && col.children.length === 0">
          <p>{{ scope.row[`${col.prop}`] }}</p>
        </template>
        <el-table-column
          v-for="child in col.children"
          :key="`multiple-selection-table-children_${child.id}`"
          :label="child.label"
          :width="child.width"
          align="center"
        >
          <template slot-scope="scopeChild">
            <p>{{ scopeChild.row[`${child.prop}`] }}</p>
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column
        :key="`multiple-selection-table_delete`"
        label="操作"
        width="100"
        fixed="right"
      >
        <template slot-scope="scope">
          <p class="delete-button" @click.stop="deleteHandler(scope.row)">删除</p>
        </template>
      </el-table-column>
    </el-table>
    <div class="ds-datagrid-pager ds-datagrid-pager-align_flex-end">
      <el-pagination
        ref="pagination"
        class="style1"
        :hide-on-single-page="false"
        :total="pageCount"
        :page-size="pageSize"
        :page-sizes="pagesizes"
        :current-page.sync="currentPage"
        :layout="'slot,total,prev,pager,next,jumper,sizes'"
        @size-change="pageSizeChange"
        @current-change="currentChange"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import DsfDataGridTable from "./datagridTable";

export default {
  name: "DsfPlatformMultipleSelectionTable",
  mixins: [$mixins.control, $mixins.list, $mixins.dataResult],
  components: { DsfDataGridTable },
  props: {
    select: {
      type: Array,
      default: () => {
        return []
      }
    },
    columns: {
      type: Array,
      default: () => {
        return []
      }
    },
    params: {
      default: () => {
        return {}
      }
    },
    pagesizes: {
      type: Array,
      default: () => {
        return []
      }
    },
  },
  computed: {
    columnsCopy() {
    //   let columnsCopy = [];
    //   this.columns.forEach((col, index) => {
    //     if (col.prop && String(col.prop).indexOf(".") > -1) {
    //       col.prop = String(col.prop).split(".")[1];
    //     }
    //     columnsCopy.push(col);
    //   });
      return this.columns;
    },
    pageCount() {
      return this.select.length - this.deleteRows.length;
    },
    tableHeight() {
      if (this.isDesign) {
        return "100%";
      }
      let fit = this.fit;
      if (fit) {
        return "100%";
      }
      return "auto";
    }
  },
  data() {
    return {
      pageSize: 10,
      currentPage: 1,
      isReDrawHander: false,
      deleteRows: [],
      selection: [],
      selectCopy: []
    };
  },
  created() {},
  mounted() {
    this.renderTableData();
  },
  methods: {
    pageSizeChange(evt) {
      this.pageSize = evt;
      this.renderTableData(this.currentPage);
    },
    indexMethod(index) {
      return (this.currentPage - 1) * this.pageSize + index + 1;
    },
    currentChange(evt) {
      this.currentPage = evt;
      this.renderTableData(evt);
    },
    deleteHandler(row) {
      if (row === "togetherDelete") {
        if (this.selection.length === 0) {
          this.$message({
            message: '请选择至少一条数据！',
            type: 'warning'
          });
        } else {
          dsf.layer.confirm({
            message: `确认删除${this.selection.length}条数据吗？`,
            confirmButtonText: "确定",
            cancelButtonText: "取消",
          })
            .then(() => {
              for (let s of this.selection) {
                this.deleteRows.push(s);
              }
              this.deleteRowHandler();
            });
        }
      } else {
        dsf.layer.confirm({
          message: "是否要删除该数据",
          confirmButtonText: "确定",
          cancelButtonText: "取消",
        })
          .then(() => {
            this.deleteRows.push(row);
            this.deleteRowHandler();
          });
      }
    },
    deleteRowHandler() {
      const deleteids = this.deleteRows.map(row => row._id);
      this.selectCopy = this.selectCopy.filter(item => !deleteids.includes(item._id));
      if (this.selectCopy.length === 0) {
        this.currentPage = this.currentPage > 1 ? this.currentPage - 1 : 1;
      }

      if (this.pageCount > 0) {
        this.renderTableData(this.currentPage);
      }
    },
    renderTableData(pageNum = 1) {
      const _this = this;
      let params = JSON.parse(JSON.stringify(this.params));
      params.ids = [];

      for (let s of this.select) {
        if (!this.deleteRows.some(d => d._id === s._id)) {
          params.ids.push(s._id)
        }
      }
      params.ids = params.ids.toString();
      params.pageNum = pageNum;
      params.pageSize = this.pageSize;
      this.$http
        .get("meta/list/data/id", params, true)
        .then(({ data }) => {
          if (data.success && Array.isArray(data.data)) {
            this.selectCopy = [];
            for (let d of data.data) {
              this.selectCopy.push(d);
            }
          }

          let formatter = null;
          const formatterHandler = function(arr, item, index) {
            for (let col of arr) {
              if (col.formatter) {
                if (col.formatter.indexOf("dateTime") > -1) {
                  formatter = col.formatter.replace('dateTime^', '');
                  item[col.prop] = dsf.date.format(item[col.prop], formatter);
                } else if (col.formatter.indexOf("textValue") > -1) {
                  formatter = col.formatter.replace('textValue^', '');
                  item[col.prop] = item[col.prop][formatter];
                }
                _this.$set(_this.selectCopy, index, item);
              }

              if (Array.isArray(col.children) && col.children.length > 0) {
                formatterHandler(col.children, item, index);
              }
            }
          }
          this.selectCopy.forEach((item, index) => {
            formatterHandler(this.columnsCopy, item, index);
          });

          console.log(this.columnsCopy);
        })
        .catch((err) => {
          throw err;
        });
    },
    handleSelectionChange(selection) {
      this.selection = selection;
    }
  }
};
</script>