<template>
  <div class="ds-context-menu-group" :class="[positionX, positionY]">
    <template v-for="(menu, index) in list">
      <div
        :key="index"
        class="ds-context-menu-item"
        :class="{disabled: contextMenuRoot.isDisabled(menu), hide: contextMenuRoot.isHide(menu)}"
        @mouseenter="$set(menu, 'childrenShow', true)"
        @mouseleave="$set(menu, 'childrenShow', false)"
      >
        <div class="ds-context-menu-item-content" :class="{'has-icon': hasIcon}" @click.stop="contextMenuRoot.itemClick(menu, $event)">
          <i v-if="menu.icon" class="ds-context-menu-item-icon iconfont" :class="[menu.icon]" :style="{color: menu.color}"></i>
          <span class="ds-context-menu-item-name">{{ menu.name }}</span>
        </div>
        <i class="iconfont icon-right" :show="menu.children && menu.children.length"></i>
        <template v-if="menu.children && menu.children.length">
          <dsf-context-menu-group v-if="menu.childrenShow" :list="menu.children" />
        </template>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: "DsfContextMenuGroup",
  inject: ['contextMenuRoot'],
  props: {
    list: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      positionX: 'right',
      positionY: 'bottom',
    }
  },
  computed: {
    hasIcon() {
      return !!_.find(this.list, it => !!it.icon);
    }
  },
  mounted() {
    let { innerWidth, innerHeight } = window;
    let { top, bottom, left, right, width, height } = this.$el.getBoundingClientRect();
    if (right - innerWidth > 0 && left - width > 0) {
      this.positionX = 'left';
    }
    if (bottom - innerHeight > 0 && top - height > 0) {
      this.positionY = 'top';
    }
  }
}
</script>