<template>
  <label class="dsf-custom-checkbox" :class="[styleType, size, {disabled: disabled, 'is-checked': isChecked}]" @click.stop>
    <template v-if="styleType == 'style1'">
      <span class="iconfont icon-dui"></span>
    </template>
    <template v-else-if="styleType == 'style2'">
      <span class="iconfont"></span>
    </template>
    <input
      v-model="isChecked"
      :disabled="disabled"
      type="checkbox"
      aria-hidden="false"
      @change="$emit('change', $event)"
    />
  </label>
</template>

<script>
export default {
  name: "DsfCustomCheckbox",
  props: {
    value: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    styleType: {
      type: String,
      default: 'style1'
    },
    // big, medium, small
    size: {
      type: String,
      default: 'medium'
    }
  },
  computed: {
    isChecked: {
      get() {
        return this.value;
      },
      set(val) {
        if (this.disabled) return;
        this.$emit('input', val);
      }
    }
  }
}
</script>