import * as tabPanelCtrls from './tabPanel';
import * as virtualListCtrls from './virtualList';
import * as panelCtrls from './panel';
import * as userCenterCtrls from './userCenter';
import * as announcemenMailCtrls from './announcemenMail';
import * as fixedMenuCtrls from './fixedMenu';
import * as messageNoticeCtrls from './messageNotice';
import * as internalMailCtrls from './internalMail';
import * as shortcutMenuCtrls from './shortcutMenu';
import * as messageNotificationCtrls from './messageNotification';
import * as scheduleListCtrls from './scheduleList';
import * as pendingProcessCtrls from './pendingProcess';
import * as scheduleListItemCtrls from './scheduleListItem';
import * as navMenuSettingCtrls from './navMenuSetting';

const ctrls = {
  ...tabPanelCtrls,
  ...virtualListCtrls,
  ...panelCtrls,
  ...userCenterCtrls,
  ...announcemenMailCtrls,
  ...fixedMenuCtrls,
  ...messageNoticeCtrls,
  ...internalMailCtrls,
  ...shortcutMenuCtrls,
  ...messageNotificationCtrls,
  ...scheduleListCtrls,
  ...pendingProcessCtrls,
  ...scheduleListItemCtrls,
  ...navMenuSettingCtrls
};

function install(Vue) {
  for (let key in ctrls) {
    let it = ctrls[key];
    Vue.component(it.name, it);
    if (!window.$components['platform']) {
      window.$components['platform'] = {}
    }
    window.$components['platform'][it.name] = it;
  }
}

export { install };
export default install