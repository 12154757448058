var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "ds-control ds-collapse",
      class: { "ds-no-padding": !_vm.showPadding },
    },
    [
      _c(
        "el-collapse",
        {
          staticClass: "ds-collapse_control",
          attrs: { accordion: _vm.accordion },
          on: { change: _vm.handleChange },
          model: {
            value: _vm.activeNames,
            callback: function ($$v) {
              _vm.activeNames = $$v
            },
            expression: "activeNames",
          },
        },
        _vm._l(_vm.slots, function (item, index) {
          return _c(
            "el-collapse-item",
            {
              key: item.name,
              attrs: { name: item.name, disabled: _vm.$eval(item.disabled) },
            },
            [
              _c(
                "template",
                { slot: "title" },
                [
                  _vm._t(
                    "title",
                    function () {
                      return [
                        _c("span", { staticClass: "ds-font4" }, [
                          _vm._v(_vm._s(item.title)),
                        ]),
                      ]
                    },
                    null,
                    { item: item, $index: index }
                  ),
                ],
                2
              ),
              _c(
                "div",
                {
                  style: { padding: _vm.$padding },
                  attrs: { "slot-name": item.name },
                },
                [_vm._t(item.name)],
                2
              ),
            ],
            2
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }