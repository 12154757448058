var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "catalogue-options" }, [
    _c("div", { staticClass: "catalogue-options-center" }, [
      _c(
        "div",
        { staticClass: "catalogue-options-center-left" },
        [
          _c(
            "el-scrollbar",
            { staticClass: "catalogue-options-scroll" },
            [
              _c("el-tree", {
                ref: "tree",
                attrs: {
                  props: _vm.defaultProps,
                  "empty-text": _vm.loading ? "数据加载中..." : "暂无数据",
                  "highlight-current": true,
                  data: _vm.data,
                  "node-key": "importKey",
                  "default-expanded-keys": _vm.expandedKeys,
                  "expand-on-click-node": false,
                },
                on: { "current-change": _vm.currNodeChange },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var node = ref.node
                      var data = ref.data
                      return _c(
                        "span",
                        {
                          staticClass: "custom-tree-node",
                          class: { "disabeled-tree": data.importData === "0" },
                        },
                        [
                          data[_vm.defaultProps.children]
                            ? _c("i", {
                                staticClass: "iconfont",
                                class: [
                                  node.expanded
                                    ? "icon-dakai"
                                    : "icon-wenjianjia",
                                ],
                                staticStyle: { color: "#c78511" },
                              })
                            : _c("i", {
                                staticClass: "iconfont icon-shiyongwendang",
                                staticStyle: { color: "#c78511" },
                              }),
                          _vm._v("   "),
                          _c("span", {
                            staticClass: "custom-tree-node-name",
                            attrs: { title: data[_vm.defaultProps.label] },
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.dsf.safe.xss(data[_vm.defaultProps.label])
                              ),
                            },
                          }),
                          _vm._v("   "),
                          _vm.conflictList.includes(
                            data[_vm.defaultProps.nodeKey]
                          ) ||
                          _vm.undefinedList.includes(
                            data[_vm.defaultProps.nodeKey]
                          ) ||
                          data.error === true
                            ? _c("i", {
                                staticClass:
                                  "error iconfont icon--zhongdaweixian",
                              })
                            : _vm._e(),
                        ]
                      )
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "catalogue-options-center-right" }, [
        _c(
          "div",
          { staticClass: "catalogue-options-out-box" },
          [
            _c(
              "el-table",
              {
                ref: "multipleTable",
                staticClass: "catalogue-options-table",
                attrs: {
                  size: "small",
                  height: "100%",
                  "highlight-current-row": false,
                  "cell-class-name": _vm.tableRowClassName,
                  data: _vm.tableData,
                  border: "",
                },
              },
              [
                _c("el-table-column", {
                  attrs: { label: "名称", width: "180" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("el-input", {
                            staticClass: "table-input",
                            class: {
                              error:
                                scope.row["error"] === true &&
                                scope.row["errorType"] === "1",
                            },
                            attrs: {
                              size: "small",
                              disabled: scope.row["importData"] === "0",
                            },
                            on: {
                              change: function ($event) {
                                return _vm.changeName(scope)
                              },
                            },
                            model: {
                              value: scope.row[_vm.defaultProps.label],
                              callback: function ($$v) {
                                _vm.$set(scope.row, _vm.defaultProps.label, $$v)
                              },
                              expression: "scope.row[defaultProps.label]",
                            },
                          }),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: { label: "类型", width: "120" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("span", [_vm._v(_vm._s(scope.row["pageType"]))]),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: { label: "标识简称", width: "120" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("el-input", {
                            staticClass: "table-input",
                            class: {
                              error:
                                scope.row["error"] === true &&
                                scope.row["errorType"] != "1" &&
                                !scope.row["cover"],
                            },
                            attrs: {
                              size: "small",
                              disabled:
                                scope.row["importData"] === "0" ||
                                !!scope.row["cover"],
                            },
                            on: {
                              change: function ($event) {
                                return _vm.lintCode(scope)
                              },
                            },
                            model: {
                              value: scope.row["code"],
                              callback: function ($$v) {
                                _vm.$set(scope.row, "code", $$v)
                              },
                              expression: "scope.row['code']",
                            },
                          }),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: { label: "操作", width: "160" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          (scope.row["error"] === true || scope.row["cover"]) &&
                          scope.row["errorType"] != "1"
                            ? [
                                _c(
                                  "el-button",
                                  {
                                    staticClass: "btn plain",
                                    class: {
                                      normal: scope.row["cover"] === "1",
                                    },
                                    attrs: { size: "mini" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.replace(scope, "1")
                                      },
                                    },
                                  },
                                  [_vm._v("替换")]
                                ),
                                scope.row["pageValue"] !== "0"
                                  ? _c(
                                      "el-button",
                                      {
                                        staticClass: "btn plain",
                                        class: {
                                          normal: scope.row["cover"] === "0",
                                        },
                                        attrs: { size: "mini" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.replace(scope, "0")
                                          },
                                        },
                                      },
                                      [_vm._v("跳过")]
                                    )
                                  : _vm._e(),
                              ]
                            : _vm._e(),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: { label: "数据操作", width: "140" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          ["1", "5", "8"].includes(scope.row["pageValue"])
                            ? _c(
                                "el-button-group",
                                [
                                  _c(
                                    "el-button",
                                    {
                                      staticClass: "btn plain",
                                      class: {
                                        normal: scope.row["copyType"] != "0",
                                      },
                                      attrs: { size: "mini" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.copyRow(scope, "1")
                                        },
                                      },
                                    },
                                    [_vm._v(" 复制 ")]
                                  ),
                                  _c(
                                    "el-button",
                                    {
                                      staticClass: "btn plain",
                                      class: {
                                        normal: scope.row["copyType"] == "0",
                                      },
                                      attrs: { size: "mini" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.copyRow(scope, "0")
                                        },
                                      },
                                    },
                                    [_vm._v(" 引用 ")]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: { label: "错误信息" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          !scope.row["cover"]
                            ? _c("span", { staticClass: "error" }, [
                                _vm._v(_vm._s(scope.row["msg"])),
                              ])
                            : _vm._e(),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ]),
    _c("div", { staticClass: "catalogue-options-footer" }, [
      _c(
        "div",
        { staticClass: "catalogue-options-footer-left" },
        [
          _vm._v(" " + _vm._s(_vm.operaType) + "到目录下方   "),
          _c("el-cascader", {
            ref: "cascader",
            staticClass: "cascader",
            attrs: {
              size: "small",
              disabled: true,
              options: _vm.targetdata,
              props: Object.assign({}, _vm.defaultPropsCascader, {
                checkStrictly: true,
              }),
              clearable: "",
            },
            model: {
              value: _vm.targetKeys,
              callback: function ($$v) {
                _vm.targetKeys = $$v
              },
              expression: "targetKeys",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "btns" },
        [
          _vm.conflictList.length
            ? _c("span", { staticClass: "error" }, [
                _vm._v(" 存在重复标识，请修改标识在操作 "),
              ])
            : _vm.undefinedList.length
            ? _c("span", { staticClass: "error" }, [_vm._v(" 存在未填写数据 ")])
            : _vm._e(),
          _vm.operateType != "import"
            ? _c("DsfButton", { on: { click: _vm.preStep } }, [
                _vm._v(" 上一步 "),
              ])
            : _vm._e(),
          _vm.operateType === "import"
            ? _c(
                "DsfButton",
                {
                  on: {
                    click: function ($event) {
                      return _vm.operate("import")
                    },
                  },
                },
                [_vm._v(" 导入 ")]
              )
            : _vm._e(),
          _vm.operateType === "copy"
            ? _c(
                "DsfButton",
                {
                  on: {
                    click: function ($event) {
                      return _vm.operate("copy")
                    },
                  },
                },
                [_vm._v(" 复制 ")]
              )
            : _vm._e(),
          _vm.operateType === "move"
            ? _c(
                "DsfButton",
                {
                  on: {
                    click: function ($event) {
                      return _vm.operate("move")
                    },
                  },
                },
                [_vm._v(" 移动 ")]
              )
            : _vm._e(),
          _c("DsfButton", { staticClass: "plain", on: { click: _vm.cancel } }, [
            _vm._v("取消"),
          ]),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }