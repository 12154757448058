<template>
  <div style="width:100%">
    <template v-if="multipe">
      <el-select
        ref="select"
        v-model="valueArr"
        class="indiction-select tag-no-wrap"
        :class="{ 'tag-focus': selectHoverFocus }"
        style="width: 100%; line-height: 40px"
        collapse-tags
        filterable
        clearable
        multiple
        placeholder="请选择"
        @focus="focus"
        @visible-change="visibleChange"
        @change="change"
      >
        <el-option v-for="op in options" :key="op.value" :label="op.text" :value="op.value" />
      </el-select>
      <div
        ref="selectHover"
        v-if="!isDesign"
        class="indiction-select-hover"
        :style="selectHoverStyle"
        tabindex="0"
        hidefocus="true"
        @focus="selectHoverFocus = true"
        @blur="selectHoverFocus = false"
      >
        <el-tag
          v-for="item in selected"
          :key="item.currentValue"
          type="info"
          size="small"
          closable
          disable-transitions
          @click.native.stop="$selectHover.focus()"
          @close="deleteTag($event, item)"
        >
          <span class="el-select__tags-text">{{ item.currentLabel }}</span>
        </el-tag>
      </div>
    </template>
    <el-select
      v-else
      v-model="value"
      class="indiction-select tag-no-wrap"
      collapse-tags
      style="width: 100%; line-height: 40px"
      filterable
      clearable
      placeholder="请选择"
      @focus="focus"
      @change="change"
    >
      <el-option :key="''" :label="'全部'" :value="''" />
      <el-option v-for="op in options" :key="op.value" :label="op.text" :value="op.value"> </el-option>
    </el-select>
  </div>
</template>

<script>
export default {
  name: "DsfSuperSearchEnum",
  props: {
    isDesign: {
      type: Boolean,
      default: false,
    },
    multipe: {
      type: Boolean,
      default: false,
    },
    values: {
      type: [String, Array, Object],
      default() {
        return [];
      },
    },
    options: {
      type: Array,
      default() {
        return [];
      },
    },
    item: {
      type: Object,
      default() {
        return {};
      }
    },
  },
  data() {
    return {
      value: "",
      valueArr: [],
      selected: [],
      selectHoverStyle: {},
      selectHoverFocus: false,
      selectFocus: false,
    };
  },
  computed: {
    selectHoverShow() {
      return this.selected.length && !this.selectFocus;
    },
  },
  watch: {
    values: {
      handler(v) {
        if (this.multipe) {
          // let varr=_.filter(this.values,(it)=>{
          //   return _.find(this.options,(op)=>op.value==it);
          // })
          this.valueArr = this.values || [];
          this.$nextTick(() => {
            let [a, ...selected] = this.$select?.selected || [];
            this.selected = selected;
          });
        } else {
          this.value = dsf.type(this.values) == "array" ? this.values[0] : this.values;
        }
      },
      immediate: true,
    },
    "selected.length"(to) {
      if (to > 0 && !this.$more) {
        this.$more = this.$select.$el.querySelector("span.el-tag:last-child");
        this.$more.onmouseenter = () => {
          this.tagHover = true;
          if (this.selected.length && !this.selectFocus) {
            this.$selectHover.focus();
          }
        };
      } else {
        this.$more = null;
      }
      if (to == 0) {
        this.$selectHover.blur();
      }
    },
  },
  mounted() {
    if (this.multipe) {
      this.$select = this.$refs.select;
      this.$selectHover = this.$refs.selectHover;
      this.updateStyle();
      this.$scrollRoot = getScrollParent(this.$el);
      this.$scrollRoot.addEventListener("scroll", this.updateStyle);
      window.addEventListener("resize", this.updateStyle);
      document.body.appendChild(this.$selectHover);
    }
  },
  beforeDestroy() {
    if (this.multipe) {
      window.removeEventListener("resize", this.updateStyle);
      this.$scrollRoot.removeEventListener("scroll", this.updateStyle);
      $(this.$selectHover).remove()
    }
  },
  methods: {
    focus() {
      this.$emit("focus");
    },
    visibleChange(e) {
      this.selectFocus = e;
      if (e) {
        this.updateStyle();
      }
    },
    change() {
      this.$emit("update:values", this.multipe ? this.valueArr : [this.value]);
      this.$nextTick(() => {
        this.$emit("change");
      });
    },
    deleteTag($event, item) {
      this.$selectHover.focus();
      this.$select.deleteTag($event, item);
    },
    updateStyle() {
      let rect = this.$select.$el.getClientRects()[0];
      if (rect) {
        this.selectHoverStyle = {
          width: rect.width + "px",
          top: rect.top + rect.height + 11 + "px",
          left: rect.left + "px",
        };
      }
    },
  },
};
// 返回给定元素的滚动父级
function getScrollParent(element) {
  var parent = element.parentNode;
  if (!parent) {
    return element;
  }
  if (parent === window.document) {
    if (window.document.body.scrollTop || window.document.body.scrollLeft) {
      return window.document.body;
    } else {
      return window.document.documentElement;
    }
  }
  if (
    ["scroll", "auto"].indexOf(getStyleComputedProperty(parent, "overflow")) !== -1 ||
    ["scroll", "auto"].indexOf(getStyleComputedProperty(parent, "overflow-x")) !== -1 ||
    ["scroll", "auto"].indexOf(getStyleComputedProperty(parent, "overflow-y")) !== -1
  ) {
    return parent;
  }
  return getScrollParent(element.parentNode);
}
// 获取给定元素的CSS计算属性
function getStyleComputedProperty(element, property) {
  var css = window.getComputedStyle(element, null);
  return css[property];
}
</script>
