<template>
  <div class="page-home-default-footer">
    {{ text }}
  </div>
</template>

<script>
export default {
  name: 'DsfDefaultHomeFooter',
  inject: ['homeRoot'],
  props: {
    text: {
      type: String,
      default: ''
    }
  },
  created() {
    this.homeRoot.setPosition('bottom', '40px');
  }
}
</script>