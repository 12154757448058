var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dsf-college-home full-view" },
    [
      _c("home-header", {
        attrs: {
          "main-width": _vm.mainWidth,
          "dropdown-config": _vm.dropdownConfig,
          "custom-bts": _vm.customBts,
          "is-auto-hide-menu": _vm.isAutoHideMenu,
        },
        on: {
          "dropdown-click": function ($event) {
            return _vm.$emit("dropdown-click", $event)
          },
        },
      }),
      _c(
        "section",
        {
          staticClass: "dsf-college-home-section",
          style: { "min-width": _vm.mainWidth + 20 + "px" },
        },
        [
          _c(
            "div",
            {
              staticClass: "min-view",
              staticStyle: { "min-height": "calc(100vh - 160px)" },
            },
            [_c("router-view")],
            1
          ),
        ]
      ),
      _c(
        "footer",
        { staticClass: "dsf-college-home-footer" },
        [_vm._t("footer")],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }