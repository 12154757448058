import title from './title';

export default dsf.component({
  name: 'DsfTitle3',
  mixins: [title],
  ctrlCaption: "标题3",
  props: {
    value: {
      type: [String, Number],
      default: '标题3'
    },
    fontType: {
      type: Number,
      default: 3
    }
  }
});