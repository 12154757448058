var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "ds-control ds-form-item ds-platform-baidu-map",
      class: _vm.getCss,
    },
    [
      _vm.simple && _vm.readOnly && !_vm.isDesign
        ? [
            _c(
              "DsfTextProxy",
              _vm._b(
                {
                  attrs: { "show-label": false },
                  model: {
                    value: _vm.value,
                    callback: function ($$v) {
                      _vm.value = $$v
                    },
                    expression: "value",
                  },
                },
                "DsfTextProxy",
                _vm._self.$props,
                false
              )
            ),
            _vm._t("error", function () {
              return [
                _vm.errors.length > 0
                  ? _c("div", { staticClass: "ds-error-text" }, [
                      _vm._v(_vm._s(_vm.errorsMsg)),
                    ])
                  : _vm._e(),
              ]
            }),
          ]
        : [
            _vm.showLabel
              ? _c(
                  "DsfFieldLabel",
                  {
                    style: _vm.getLabelWidthStyle(),
                    attrs: {
                      mode: _vm.showDataCaptionMode,
                      "show-icon": _vm.showDataCaptionIcon,
                      trigger: _vm.showDataCaptionTrigger,
                      "data-caption": _vm.dataCapion,
                      "is-design": _vm.isDesign,
                      owner: _vm._self,
                    },
                  },
                  [_vm._v(" " + _vm._s(_vm.$t(_vm.label)) + " ")]
                )
              : _vm._e(),
            _c(
              "div",
              {
                staticClass: "ds-form-block",
                class: { border: _vm.border || _vm.isButton },
                style: _vm.getFormItemBlockMarginLeft(),
              },
              [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loading,
                        expression: "loading",
                      },
                    ],
                    staticClass: "ds-platform-baidu-map-box",
                    style: { width: _vm.width, height: _vm.height },
                  },
                  [
                    _c(
                      "el-input",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.readOnly,
                            expression: "!readOnly",
                          },
                        ],
                        staticClass: "ds-platform-baidu-map-search",
                        attrs: {
                          "validate-event": false,
                          disabled: _vm.disabled,
                          size: "mini",
                          placeholder: "请输入需要搜索的地名",
                        },
                        model: {
                          value: _vm.searchText,
                          callback: function ($$v) {
                            _vm.searchText = $$v
                          },
                          expression: "searchText",
                        },
                      },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { slot: "append", type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.doSearch()
                              },
                            },
                            slot: "append",
                          },
                          [_vm._v("搜索")]
                        ),
                      ],
                      1
                    ),
                    _vm.pointValue
                      ? _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: !_vm.loading,
                                expression: "!loading",
                              },
                            ],
                            staticClass: "ds-platform-baidu-map-info",
                          },
                          [
                            _c("div", [
                              _c("i", { staticClass: "el-icon-location" }),
                              _vm._v(" " + _vm._s(_vm.pointValue.address)),
                            ]),
                            _c("div", [
                              _vm._v("纬度：" + _vm._s(_vm.pointValue.lat)),
                            ]),
                            _c("div", [
                              _vm._v("经度：" + _vm._s(_vm.pointValue.lng)),
                            ]),
                          ]
                        )
                      : _vm._e(),
                    _c("div", {
                      ref: "bdMapMain",
                      staticClass: "ds-platform-baidu-map-main",
                      attrs: { id: "bdMapMain" },
                    }),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              !_vm.unedited &&
                              (_vm.isDesign || _vm.nearPois.length),
                            expression:
                              "!unedited && (isDesign || nearPois.length)",
                          },
                        ],
                        staticClass: "ds-platform-baidu-map-near",
                      },
                      [
                        _c(
                          "dsf-virtual-scroll",
                          [
                            _vm._l(_vm.nearPois, function (poi, index) {
                              return [
                                _c(
                                  "div",
                                  {
                                    key: index,
                                    staticClass:
                                      "ds-platform-baidu-map-near-poi",
                                    on: {
                                      click: function ($event) {
                                        return _vm.setPoi(poi)
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "ds-platform-baidu-map-near-poi-name",
                                      },
                                      [
                                        _c("span", [_vm._v(_vm._s(index + 1))]),
                                        _vm._v(_vm._s(poi.title)),
                                      ]
                                    ),
                                    poi.Yi
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "ds-platform-baidu-map-near-poi-info",
                                          },
                                          [
                                            _c("span", [_vm._v("类型：")]),
                                            _vm._v(_vm._s(poi.Yi)),
                                          ]
                                        )
                                      : _vm._e(),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "ds-platform-baidu-map-near-poi-info",
                                      },
                                      [
                                        _c("span", [_vm._v("地址：")]),
                                        _vm._v(_vm._s(poi.address)),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "ds-platform-baidu-map-near-poi-info",
                                      },
                                      [
                                        _c("span", [_vm._v("经度：")]),
                                        _vm._v(_vm._s(poi.point.lng)),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "ds-platform-baidu-map-near-poi-info",
                                      },
                                      [
                                        _c("span", [_vm._v("纬度：")]),
                                        _vm._v(_vm._s(poi.point.lat)),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            }),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._t("error", function () {
                  return [
                    _vm.errors.length > 0
                      ? _c("div", { staticClass: "ds-error-text" }, [
                          _vm._v(_vm._s(_vm.errorsMsg)),
                        ])
                      : _vm._e(),
                  ]
                }),
              ],
              2
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }