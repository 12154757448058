var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "ds-control ds-title-box",
      class: _vm.getCss,
      on: { dblclick: _vm.beginEdit },
    },
    [
      !_vm.isDesign
        ? _c(
            "label",
            { staticClass: "ds_label", class: _vm.getLabelCss },
            [
              _vm.hasHelper && _vm.dataCapionTarget == "text"
                ? [
                    _vm.dataCapionType == "hoverPop" ||
                    _vm.dataCapionType == "clickPop"
                      ? _c(
                          "el-popover",
                          {
                            staticClass: "ds_label-helper",
                            attrs: {
                              placement: "top",
                              title: "",
                              width: "200",
                              trigger:
                                _vm.dataCapionType == "hoverPop"
                                  ? "hover"
                                  : "click",
                            },
                          },
                          [
                            _c(
                              "span",
                              {
                                staticClass: "ds_label-text",
                                attrs: { slot: "reference" },
                                slot: "reference",
                              },
                              [_vm._v(_vm._s(_vm.value))]
                            ),
                            _c("div", {
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.dsf.safe.xss(_vm.dataCapion)
                                ),
                              },
                            }),
                          ]
                        )
                      : _c(
                          "span",
                          {
                            staticClass: "ds_label-text",
                            on: { click: _vm.showHelper },
                          },
                          [_vm._v(_vm._s(_vm.value))]
                        ),
                  ]
                : _c("span", { staticClass: "ds_label-text" }, [
                    _vm._v(_vm._s(_vm.value)),
                  ]),
              _vm._t("helper", function () {
                return [
                  _vm.hasHelper && _vm.dataCapionTarget == "icon"
                    ? [
                        _vm.dataCapionType == "hoverPop" ||
                        _vm.dataCapionType == "clickPop"
                          ? _c(
                              "el-popover",
                              {
                                staticClass: "ds_label-helper",
                                attrs: {
                                  placement: "top",
                                  title: "",
                                  width: "200",
                                  trigger:
                                    _vm.dataCapionType == "hoverPop"
                                      ? "hover"
                                      : "click",
                                },
                              },
                              [
                                _c("i", {
                                  staticClass: "iconfont icon-bangzhuzhongxin",
                                  attrs: { slot: "reference" },
                                  slot: "reference",
                                }),
                                _c("div", {
                                  domProps: {
                                    innerHTML: _vm._s(
                                      _vm.dsf.safe.xss(_vm.dataCapion)
                                    ),
                                  },
                                }),
                              ]
                            )
                          : _c("i", {
                              staticClass:
                                "iconfont icon-bangzhuzhongxin ds_label-helper",
                              on: { click: _vm.showHelper },
                            }),
                      ]
                    : _vm._e(),
                ]
              }),
            ],
            2
          )
        : !_vm.editting
        ? _c(
            "label",
            { staticClass: "ds_label ds-label-box pc", class: _vm.getLabelCss },
            [
              _c("span", { staticClass: "ds_label-text" }, [
                _vm._v(_vm._s(_vm.value || "无内容")),
              ]),
              _vm._t("helper", function () {
                return [
                  _vm.hasHelper && _vm.dataCapionTarget == "icon"
                    ? _c("i", {
                        staticClass:
                          "iconfont icon-bangzhuzhongxin ds_label-helper",
                      })
                    : _vm._e(),
                ]
              }),
            ],
            2
          )
        : _c("el-input", {
            ref: "contenteditable",
            class: [
              "ds-font" + this.fontType,
              this.showWeight ? "ds-bold" : "",
              "ds_font_" + this.fontFamily,
            ],
            attrs: {
              type: "textarea",
              autosize: "",
              placeholder: "请输入内容",
              resize: "none",
            },
            on: { blur: _vm.endEdit },
            model: {
              value: _vm.value,
              callback: function ($$v) {
                _vm.value = $$v
              },
              expression: "value",
            },
          }),
      _c("div", { staticClass: "ds-title-box-right" }, [_vm._t("default")], 2),
      _c(
        "div",
        {
          staticClass: "right-icon",
          class: ["ds-font" + this.fontType],
          style: _vm.rightIconStyle,
          on: { click: _vm.rightIconClick },
        },
        [
          _vm.rightText
            ? _c("span", [_vm._v(_vm._s(_vm.rightText))])
            : _vm._e(),
          _vm.rightIcon != ""
            ? _c("i", {
                staticClass: "ds-title-box-right-icon iconfont",
                class: [_vm.getIcon(_vm.rightIcon)],
              })
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }