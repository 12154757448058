import row from "./row";

/**
 * 12份栅格布局
 */
export default dsf.component({
  name: "DsfRowHalf",
  ctrlCaption: "栅格布局(12)",
  mixins: [row]
});
