<template>
  <div class="ds-control ds-form-item ds-customer-img" :class="getCss" :style="containerSty">
    <!-- <label v-if="showLabel" class="ds-form-label" :style="getLabelWidthStyle()">
      {{ label }}
      <i v-if="dataCapion" class="iconfont icon-bangzhuzhongxin ds-control-helper" @click="showHelper"></i>
    </label> -->
    <DsfFieldLabel
      v-if="showLabel"
      :mode="showDataCaptionMode"
      :style="getLabelWidthStyle()"
      :show-icon="showDataCaptionIcon" 
      :trigger="showDataCaptionTrigger"
      :data-caption="dataCapion"
      :is-design="isDesign"
      :owner="_self">
      {{ $t(label) }}
    </DsfFieldLabel>
    <template v-if="readOnly">
      <div class="left">
        <div class="canvas-box">
          <dsf-image ref="img" :src="readonlySrc" :style="canvasSty" />
          <div class="tips">宽 * 高：{{ width }}px * {{ height }}px</div>
        </div>
        <slot name="error">
          <div v-if="errors.length > 0" class="ds-error-text">
            {{ errorsMsg }}
          </div>
        </slot>
      </div>
    </template>
    <template v-else>
      <div class="left">
        <div class="canvas-box">
          <canvas ref="canvas" :width="`${width}px`" :height="`${height}px`" :style="canvasSty"></canvas>
          <div class="tips">宽 * 高：{{ width }}px * {{ height }}px</div>
        </div>
        <div v-if="!isCutPic" class="warning">请上传 {{ maxFileSize }}kb 大小以内的图片</div>
        <slot name="error">
          <div v-if="errors.length > 0" class="ds-error-text">
            {{ errorsMsg }}
          </div>
        </slot>
      </div>
      <div v-if="!readOnly" class="right">
        <input ref="uploadFile" type="file" accept="image/*" style="display: none" />
        <dsf-button :title="tip" icon="yunduanshangchuan" @click="preUpload">点击上传</dsf-button>
        <div class="right-item">
          <label>显示文字</label>
          <div class="input-box">
            <el-input v-model="tempValue.text" placeholder="请输入" @change="textChangeHandler"></el-input>
          </div>
        </div>
        <div class="right-item">
          <label>字体设置</label>
          <div class="select-box">
            <el-select v-model="tempValue.fontFamily">
              <el-option v-for="item in fontList" :key="item.val" :value="item.val" :label="item.txt"></el-option>
            </el-select>
          </div>
          <el-input-number v-model="tempValue.fontSize" :controls="false" :min="0" :precision="0"></el-input-number>
          <el-button :style="boldBtnSty" @click="setBold"><span class="icon iconfont">&#xec97;</span></el-button>
          <el-button :style="italicBtnSty" @click="setItalic"><span class="icon iconfont">&#xec9c;</span></el-button>
          <el-color-picker :value="tempValue.fill" @active-change="chooseTextColor" @change="changeTextColor"></el-color-picker>
        </div>
        <div class="right-item">
          <div class="group">
            <label>文字底色</label>
            <el-color-picker :value="tempValue.textBackgroundColor" @active-change="chooseTextBgColor" @change="changeTextBgColor"></el-color-picker>
          </div>
          <div class="group">
            <label>背景颜色</label>
            <el-color-picker :value="tempValue.backgroundColor" @active-change="chooseBgColor" @change="changeBgColor"></el-color-picker>
          </div>
        </div>
      </div>
    </template>
    <picture-cut-dialog :src.sync="src" :width="width" :height="height" :dialog-visible="dialogVisible" @cut-img="cutImg" @dialog-close="dialogClose"></picture-cut-dialog>
  </div>
</template>

<script>
import { Tool } from "vue-picture-cut";
import pictureCutDialog from "_platform/pc/bhc/upload/pictureCutDialog";

const defaultTempValue = {
  text: "",
  fontFamily: "SimSun",
  fontSize: 18,
  fontWeight: "normal",
  fontStyle: "normal",
  backgroundImage: "",
  fill: "#000",
  borderColor: "#FF0000", //控制框属性
  cornerStrokeColor: "#FF0000", //控制框属性
  transparentCorners: false, //控制框属性
  cornerSize: 10, //控制框属性
  originX: "center",
  originY: "center",
  backgroundColor: null,
  textBackgroundColor: null,
};

export default dsf.component({
  name: "DsfCustomerImg",
  mixins: [$mixins.formControl],
  ctrlCaption: "封面设置",
  components: {
    pictureCutDialog,
  },
  props: {
    simple: {
      type: Boolean,
      default: false,
    },
    width: {
      type: Number,
      default: 300,
    },
    height: {
      type: Number,
      default: 200,
    },
    maxFileSize: {
      type: Number,
      default: 2048,
    },
    metadata: {
      type: Object,
      default() {
        return dsf.metadata.get("customer-img-meta-data");
      },
    },
    isCutPic: {
      type: Boolean,
      default: true,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
      default: null
    },
  },
  data() {
    return {
      src: "",
      dialogVisible: false,
      upFileUrl: "file/upload/",
      upBase64Url: "file/base64Img",
      fabricCanvas: null,
      fabricText: null,
      tempValue: _.clone(defaultTempValue),
      fontList: [
        { val: "SimSun", txt: "宋体" },
        { val: "FangSong", txt: "仿宋" },
        { val: "KaiTi", txt: "楷书" },
        { val: "SimHei", txt: "黑体" },
        { val: "Microsoft YaHei", txt: "微软雅黑" },
        { val: "YouYuan", txt: "幼圆" },
      ],
      sizeList: [14, 16, 18, 22, 24],
      isFirst: true,
      readonlySrc: null
    };
  },
  computed: {
    canvasSty() {
      return `width: ${this.width}px; height: ${this.height}px`;
    },
    containerSty() {
      const marTop = this.isDesign ? "20px" : 0;
      return `margin-top: ${marTop}`;
    },
    tip() {
      return `只能上传图片类型的文件，大小不得超过${this.maxFileSize}kb`;
    },
    boldBtnSty() {
      if (this.tempValue.fontWeight === "bold") {
        return `background: #ff7200;color: #ffffff; border-color: #ff7200`;
      }
      return `background: #ffffff; color: #606266; border-color: #DCDFE6`;
    },
    italicBtnSty() {
      if (this.tempValue.fontStyle === "italic") {
        return `background: #ff7200;color: #ffffff; border-color: #ff7200`;
      }
      return `background: #ffffff; color: #606266; border-color: #DCDFE6`;
    }
  },
  watch: {
    value: {
      handler(val) {
        if (this.isFirst && val) this.initComponent(val);
        else this.$updateComponent?.(val);
      },
      immediate: true
    },
    tempValue: {
      handler() {
        window.fabric && this.renderText();
      },
      deep: true,
    }
  },
  created() {
    if (!this.isDesign) {
      this.$updateComponent = _.throttle(this.updateComponent, 500, {
        leading: false,
        trailing: true
      });
    }
  },
  mounted() {
    this.createFabric();
  },
  methods: {
    reset() {
      this.emitValueChange(null);
      this.$updateComponent();
    },
    textChangeHandler(val) {
      if (val && this.errors.length) {
        this.$vm.removeErrorsByTarget?.(this.$formName);
      }
    },
    cutImg(file){
      uploadFile.call(this, file);
    },
    preUpload() {
      const self = this;
      const inputEl = $(self.$refs.uploadFile);
      inputEl.on("change", function () {
        if (!$(this).val()) return;
        const file = $(this)[0].files[0];
        //如果需要裁剪图片
        if (self.isCutPic) {
          self.src = URL.createObjectURL(file);
          self.dialogVisible = true;
        } else {
          if (file.size / 1024 > self.maxFileSize) {
            dsf.layer.message(`文件大小超过${self.maxFileSize}kb!`, false);
            inputEl.val("");
            return false;
          }
          uploadFile.call(self, file);
        }
      });
      inputEl.click();
    },
    setBold() {
      this.tempValue.fontWeight = this.tempValue.fontWeight === "normal" ? "bold" : "normal";
    },
    setItalic() {
      this.tempValue.fontStyle = this.tempValue.fontStyle === "normal" ? "italic" : "normal";
    },
    createFabric(canvasInfo) {
      let fn = () => {
        this.fabricCanvas = this.fabricCanvas || new window.fabric.Canvas(this.$refs.canvas);
        //如果canvas 有历史记录则使用历史记录
        !canvasInfo ? this.renderByEmpty() : this.renderByHistory(canvasInfo);
      };
      if (window.fabric) {
        fn();
      } else {
        const url = dsf.url.getWebPath("$/js/libs/fabric/fabric.js");
        this.$http
          .importFiles([url])
          .then(fn)
          .catch((err) => {
            dsf.layer.message("加载fabric文件报错", false);
          });
      }
    },
    changeTextColor(color) {
      this.tempValue.fill = color;
    },
    changeTextBgColor(color) {
      this.tempValue.textBackgroundColor = color;
    },
    changeBgColor(color) {
      this.tempValue.backgroundColor = color;
    },
    renderByEmpty() {
      this.renderText();
    },
    renderByHistory(canvasInfo) {
      const self = this;
      self.fabricCanvas.loadFromJSON(canvasInfo, () => {
        self.fabricText = self.fabricCanvas.getObjects("text")[0];
        self.fabricCanvas.renderAll();
        self.$dispatch("get-history", canvasInfo);
      });
    },
    renderText() {
      const self = this;
      self.fabricCanvas.set("backgroundColor", self.tempValue.backgroundColor);
      const config = _.pickBy(self.tempValue, (value, key) => {
        return key !== "backgroundImage" && key !== "backgroundColor";
      });
      if (!self.tempValue.text) {
        self.fabricCanvas.remove(self.fabricText);
        self.fabricText = null;
      }
      if (!self.fabricText) {
        _.assign(config, {
          left: Math.floor(self.width / 2),
          top: Math.floor(self.height / 2),
        });
        self.fabricText = new window.fabric.Text(self.tempValue.text, config);
        self.fabricCanvas.add(self.fabricText);
      } else {
        self.fabricText.set(config);
      }
      self.fabricCanvas.renderAll();
    },
    postBefore() {
      //若是只读，则不上传图片，直接返回
      if (this.readOnly) {
        return;
      }
      if (!this.tempValue.text && !this.fabricCanvas.backgroundImage) {
        this.emitValueChange('');
        return;
      }
      return new Promise((resolve, reject) => {
        let baseStr = null;
        try {
          baseStr = this.fabricCanvas.toDataURL();
        } catch (e) {
          reject(e);
          console.error(e);
          return;
        }
        let blob = Tool.base64ToBlob(baseStr, 'image/png');
        blob.name = dsf.uuid(32) + '.png';
        this.$http.upload(this.upFileUrl, {
          files: blob
        }).done(({success, data, message}) => {
          if (!success) {
            dsf.layer.message(message || '上传封面失败', false);
            reject(message);
            return;
          }
          this.tempValue.outputImage = data[0].relativePath;
          const obj = {
            pageInfo: this.tempValue,
            canvasInfo: this.fabricCanvas.toJSON(),
          };
          this.emitValueChange(JSON.stringify(obj));
          this.$nextTick(() => {
            resolve();
          });
        }).error((err) => {
          dsf.layer.message("封面上传发生未知异常", false);
          reject(err);
        });
      });
    },
    chooseTextColor(color) {
      this.tempValue.fill = colorHex(color);
    },
    chooseBgColor(color) {
      this.tempValue.backgroundColor = colorHex(color);
    },
    chooseTextBgColor(color) {
      this.tempValue.textBackgroundColor = colorHex(color);
    },
    initComponent(val) {
      this.isFirst = false;
      if (this.isDesign) return;
      const result = val ? JSON.parse(val) || {} : {};
      if (!this.readOnly) {
        //如果没有历史画笔
        if (dsf.isEmptyObject(result)) {
          this.createFabric();
          return;
        }
        const { pageInfo, canvasInfo } = result;
        this.tempValue = _.assign({} ,this.tempValue, pageInfo);
        this.createFabric(canvasInfo);
      } else {
        const { pageInfo } = result;
        if (pageInfo && pageInfo.outputImage) this.readonlySrc = pageInfo.outputImage;
      }
    },
    updateComponent(val) {
      if (this.isDesign) return;
      const result = val ? JSON.parse(val) || {} : {};
      if (!this.readOnly) {
        if (dsf.isEmptyObject(result)) {
          this.tempValue = _.clone(defaultTempValue);
          this.fabricCanvas.setBackgroundImage(null);
          this.renderText();
          return;
        }
        const { pageInfo, canvasInfo } = result;
        this.tempValue = _.assign({} ,this.tempValue, pageInfo);
        this.renderByHistory(canvasInfo);
      }
    },
    dialogClose(){
      this.dialogVisible = false;
      URL.revokeObjectURL(this.src);
      this.src = ""
    }
  },
});

function colorHex(rgb) {
  const aColor = rgb.replace(/(?:\(|\)|rgb|RGB)*/g, "").split(",");
  let strHex = "#";
  for (let i = 0; i < aColor.length; i++) {
    let hex = Number(aColor[i]).toString(16);
    if (hex.length < 2) {
      hex = "0" + hex;
    }
    strHex += hex;
  }
  if (strHex.length !== 7) {
    strHex = rgb;
  }
  return strHex;
}

function uploadFile(file) {
  const self = this;
  const formData = new FormData();
  formData.append("file", file, '封面.png');
  const config = {
    headers: { "Content-Type": "multipart/form-data" },
    transformRequest: [
      function (data, headers) {
        return data;
      },
    ],
  };

  self.$http
    .post(self.upFileUrl, formData, config)
    .done((res) => {
      if (!res.success) {
        dsf.layer.message(res.message, false);
        return;
      }
      self.dialogVisible = false;
      self.tempValue.backgroundImage = res.data[0]["absolutePath"];
      const img = new Image();
      img.onload = () => {
        self.fabricCanvas.setBackgroundImage(img.src, self.fabricCanvas.renderAll.bind(self.fabricCanvas), {
          scaleX: self.width / img.width,
          scaleY: self.height / img.height,
        });
        if (self.errors.length) {
          self.$vm.removeErrorsByTarget?.(this.$formName);
        }
      };
      img.src = dsf.url.getWebPath(res.data[0]["relativePath"]);
    })
    .error((err) => {
      dsf.layer.message("上传图片失败", false);
    })
    .always(() => {
      $(self.$refs["uploadFile"]).val("");
    });
}
</script>
