<template>
  <div v-if="isDesign" class="ds-platform-card-item-by-train">
    <div class="cover">
      <dsf-image />
      <div class="info">
        <div>
          <div class="title ellipsis-2">
            深入学习贯彻习近平新时代中国特色社会主义思想
          </div>
          <div class="hours">
            <span>{{ getPrefix('compulsoryHours') }}20</span>
            <span>{{ getPrefix('electivesHours') }}58.5</span>
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="date">
        2019.07.24 ～ 2019.08.27
      </div>
    </div>
    <div class="footer">
      <span style="color: #D6310B">报名中</span>
      <span><i class="iconfont icon-wodexuanzhong"></i> <span>1234</span></span>
    </div>
  </div>
  <div v-else class="ds-platform-card-item-by-train" :title="data['title']" @click="_clickBefore(click)">
    <div class="cover">
      <dsf-image :src="data['cover'] | imgFormat" :error-src="errorSrc" />
      <div class="info">
        <div>
          <div class="title ellipsis-2">
            {{ data['title'] }}
          </div>
          <div class="hours">
            <span>{{ getPrefix('compulsoryHours') }}{{ data['compulsoryHours'] }}</span>
            <span>{{ getPrefix('electivesHours') }}{{ data['electivesHours'] }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="date">
        {{ data['sDate'] }} ～ {{ data['eDate'] }}
      </div>
    </div>
    <div class="footer">
      <span v-if="data['stateValue'] == 1" style="color: #D6310B">{{ data['stateText'] }}</span>
      <span v-else>{{ data['stateText'] }}</span>
      <span><i class="iconfont icon-wodexuanzhong" style="font-size: .9em"></i> {{ data['views'] }}</span>
    </div>
  </div>
</template>

<script>
export default dsf.component({
  name: "DsfPlatformCardItemByTrain",
  ctrlCaption: "专题培训",
  mixins: [$mixins.card],
  design: {
    isMask: false
  },
  props: {
    keysMap: {
      type: Array,
      default() {
        return [
          { from: 'cover', to: 'cover', type: 'text', desc: '封面' },
          { from: 'title', to: 'title', type: 'text', desc: '课题' },
          { from: 'sDate', to: 'sDate', type: 'text', desc: '开始时间' },
          { from: 'eDate', to: 'eDate', type: 'text', desc: '结束时间' },
          { from: 'compulsoryHours', to: 'compulsoryHours', type: 'text|number', desc: '必修学时', prefix: '必修学时：'  },
          { from: 'electivesHours', to: 'electivesHours', type: 'text|number', desc: '选修学时', prefix: '选修学时：'  },
          { from: 'stateValue', to: 'stateValue', type: 'text|number', desc: '状态值' },
          { from: 'stateText', to: 'stateText', type: 'text', desc: '状态文字' },
          { from: 'views', to: 'views', type: 'text|number', desc: '浏览量' }
        ];
      }
    },
    // 跳转方式
    clickConfig: {
      type: Object,
      default: null
    }
  },
  methods: {
    click() {
      if (!this.clickConfig) return;
      let { url, state } = this.clickConfig;
      url = this.$replace(url, this.local);
      this.$open({ url, state });
      this.clickAfter();
    }
  }
});
</script>