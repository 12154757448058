import title from './title';

export default dsf.component({
  name: 'DsfTitle6',
  mixins: [title],
  ctrlCaption: "标题6",
  props: {
    value: {
      type: [String, Number],
      default: '标题6'
    },
    fontType: {
      type: Number,
      default: 6
    }
  }
});