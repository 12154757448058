<template>
  <div
    class="ds-control ds-banner ds-no-padding"
    :id="id"
    :style="{
      'padding-bottom': rootHeight,
      'padding-top': `calc(100% - ${h})`,
    }"
  >
    <el-carousel
      ref="swipe"
      :arrow="showButton"
      :indicator-position="showIndicators"
      :direction="vertical ? 'vertical' : 'horizontal'"
      :autoplay="isAutoPlay"
      :interval="autoPlay"
      :trigger="indicatorTrigger"
      :type="type"
      :height="h"
      @change="
        (index) => {
          activeIndex = index;
        }
      "
    >
      <el-carousel-item
        v-for="(item, index) in images"
        :key="`ds-banner-item-${index}`"
      >
        <div
          :class="['ds-banner-item', `${index}`]"
          @click="(ev) => onSwipeClick(ev, item, index)"
        >
          <slot v-bind="{ row: item, $index: index }">
            <dsf-image
              class="el-carousel-item-image"
              :src="item.image"
              :style="{ 'border-radius': borderRadius }"
              :error-src="errorSrc"
            />
            <slot name="desc" v-bind="{ row: item, $index: index }"></slot>
          </slot>
        </div>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
import bannerdemo1 from "../../../assets/styles/themes/img/pc-baner1.jpg";
import bannerdemo2 from "../../../assets/styles/themes/img/pc-baner2.jpg";
import bannerdemo3 from "../../../assets/styles/themes/img/pc-baner3.jpg";
import bannerdemo4 from "../../../assets/styles/themes/img/pc-baner4.jpg";

export default dsf.component({
  name: "DsfBanner",
  ctrlCaption: "轮播图",
  mixins: [$mixins.control],
  props: {
    ratio: {
      type: String,
      default: "200px",
    },
    showButton: {
      //切换箭头的显示时机 always/hover/never
      type: String,
      default: "hover",
    },
    showIndicators: {
      //指示器的位置  inside/none/outside
      type: String,
      default: "inside",
    },
    vertical: {
      //垂直滚动
      type: Boolean,
      default: false,
    },
    indicatorColor: {
      //指示灯颜色 为空默认使用皮肤辅色
      type: String,
      default: "#fff",
    },
    //为空默认为高亮颜色的透明度0.48
    indicatorDefaultColor: {
      type: String,
      default: "",
    },
    indicatorWidth: {
      type: Number,
      default: 30,
    },
    indicatorHeight: {
      type: Number,
      default: 2,
    },
    indicatorRadius: {
      type: [String, Number],
      default: "0",
    },
    autoPlay: {
      type: [String, Number],
      default: 3000,
    },
    isAutoPlay: {
      type: Boolean,
      default: true,
    },
    loop: {
      type: Boolean,
      default: true,
    },
    borderRadius: {
      type: [String, Number],
      default: "0px",
    },
    indicatorTrigger: {
      type: String,
      default: "hover",
    },
    type: {
      type: String,
      default: "normal",
    },
    list: {
      type: Array,
      default() {
        return [
          { image: bannerdemo1 },
          { image: bannerdemo2 },
          { image: bannerdemo3 },
          { image: bannerdemo4 },
        ];
      },
    },
    url: {
      type: String,
      default: "",
    },
    toUrl: {
      type: String,
      default: "",
    },
    max: {
      type: Number,
      default: 0,
    },
    // 配置字段名
    imageKeyName: {
      type: String,
      default: "image",
    },
    titleKeyName: {
      type: String,
      default: "title",
    },
    descKeyName: {
      type: String,
      default: "desc",
    },
    jumpWayKeyName: {
      type: String,
      default: "jumpWay",
    },
    // 图片加载失败的替代图片
    errorSrc: {
      type: String,
      default: ''
    }
  },
  computed: {
    rootHeight() {
      let ratio = this.ratio;
      if (/^\d+\/\d+$/.test(ratio)) {
        ratio = ratio.split("/");
        ratio = (100 * ratio[1]) / ratio[0] + "%";
      }
      return ratio;
    },
    images() {
      let data = this.data;
      let max = this.max;
      if (max) {
        return (data || []).slice(0, max);
      } else {
        return data;
      }
    },
  },
  data() {
    return {
      id: "dsfBanner" + dsf.uuid(16),
      data: [],
      activeIndex: 0,
      h: "100%",
    };
  },
  watch: {
    indicatorWidth() {
      this.updateStyleDom();
    },
    showIndicators() {
      this.setH();
    },
    indicatorHeight() {
      this.setH();
      this.updateStyleDom();
    },
    indicatorColor() {
      this.updateStyleDom();
    },
    indicatorDefaultColor() {
      this.updateStyleDom();
    },
    indicatorRadius() {
      this.updateStyleDom();
    },
    list(v, ov) {
      if (!this.url) {
        this.data = dsf.mix(true, [], v);
      }
    },
  },
  created() {
    if (this.isDesign) {
      let data = [];
      if (!this.list.length) {
        data = [{ image: "" }, { image: "" }];
      } else {
        data = this.list;
      }
      this.data = dsf.mix(true, [], data);
    } else {
      if (!this.url) {
        this.data = dsf.mix(true, [], this.list);
      } else {
        this.loadData();
      }
    }

    this.setH();
    let styleDom = document.createElement("style");
    document.body.append(styleDom);
    this.styleDom = styleDom;
    this.updateStyleDom();
  },
  destroyed() {
    this.styleDom?.remove();
  },
  methods: {
    setH() {
      if (this.showIndicators == "outside" || this.type=="card") {
        this.h = `calc(100% - 24px - ${this.indicatorHeight}px)`;
      } else {
        this.h = "100%";
      }
    },
    updateStyleDom() {
      if (this.styleDom) {
        let bgColor = this.indicatorColor;
        let defaultColor = this.indicatorDefaultColor;
        let opacity = 1;
        if (bgColor === "theme") {
          bgColor = dsf.themeConfig.currentTheme.normal;
        }
        if (!defaultColor) {
          opacity = 0.48;
          defaultColor = bgColor;
        }
        this.styleDom.innerHTML = `
          #${this.id} .el-carousel__button{
            height: ${this.indicatorHeight}px;
            width: ${this.indicatorWidth}px;
            border-radius: ${this.indicatorRadius};
            background-color: ${defaultColor};
            opacity: ${opacity};
          }
          #${this.id} .el-carousel__indicator.is-active button{
            background-color: ${bgColor};
            opacity: 1;
          }
        `;
      }
    },
    formatApiData(data) {
      if (!Array.isArray(data)) {
        return [];
      }
      return data.map((it) => {
        return {
          url: "",
          ...it,
          image: dsf.getFilePath(it[this.imageKeyName]),
          title: it[this.titleKeyName],
          desc: it[this.descKeyName],
          jumpWay: it[this.jumpWayKeyName],
        };
      });
    },
    loadData() {
      let url = this.$replace(this.url, this);
      this.$http.get(url).then(({ data }) => {
        this.data = this.formatApiData(data.data);
      });
    },
    onSwipeClick(ev, item, index) {
      if (this.type === "normal" || index == this.activeIndex) {
        if (this.$listeners["click"]) {
          this.$dispatch("click", item);
        } else {
          let toUrl = "";
          if (item.url) {
            toUrl = item.url;
          } else if (this.toUrl) {
            toUrl = this.$replace(this.toUrl, item);
          }
          this.$open({
            url: toUrl+"",
            state: item.jumpWay,
          });
        }
      }
    },
    validateApiFiled(data) {
      if (!Array.isArray(data.data)) {
        console.error("数据源必须为数组");
      } else {
        const flag = data.data.every((it) => {
          return (
            Object.prototype.toString.call(it) === "[object Object]" &&
            Object.prototype.hasOwnProperty.call(it, "image")
          );
        });
        if (!flag) {
          console.error(`
            接口数据格式请参考: \n
            [{\n
              image: '',\n
              title: '',\n
              desc: ''\n
            }]
          `);
        }
      }
    },
  },
});
</script>
