var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ds-control ds-form-item", class: _vm.getCss },
    [
      _vm.showLabel
        ? _c(
            "DsfFieldLabel",
            {
              style: _vm.getLabelWidthStyle(),
              attrs: {
                mode: _vm.showDataCaptionMode,
                "show-icon": _vm.showDataCaptionIcon,
                trigger: _vm.showDataCaptionTrigger,
                "data-caption": _vm.dataCapion,
                "is-design": _vm.isDesign,
                owner: _vm._self,
              },
            },
            [_vm._v(" " + _vm._s(_vm.$t(_vm.label)) + " ")]
          )
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "ds-form-block",
          style: _vm.getFormItemBlockMarginLeft(),
        },
        [
          !_vm.readOnly
            ? _c("el-input", {
                attrs: { type: "text" },
                on: {
                  change: _vm.inputChangeHandler,
                  blur: _vm.inputBlurHandler,
                },
                model: {
                  value: _vm.inputValue.pid,
                  callback: function ($$v) {
                    _vm.$set(_vm.inputValue, "pid", $$v)
                  },
                  expression: "inputValue.pid",
                },
              })
            : _c("div", { staticClass: "ds-form-readonly" }, [
                _c("span", [_vm._v(_vm._s(_vm.inputValue.pid))]),
              ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }