var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ds-page-home-header-user" },
    [
      _c(
        "div",
        {
          staticClass: "user-icon",
          style: { "background-image": "url(" + _vm.defaultUserIcon + ")" },
        },
        [
          _c("img", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showIcon,
                expression: "showIcon",
              },
            ],
            attrs: { src: "/user/getUserPhoto", alt: "" },
            on: { error: _vm.headLoadError, load: _vm.headLoadSuccess },
          }),
        ]
      ),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }