var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "permission-setting" },
    [
      _c("div", { staticClass: "permission-setting-title" }, [
        _vm._v("模板名称：" + _vm._s(_vm.name)),
      ]),
      !_vm.isPartSetting
        ? [
            _c(
              "el-tabs",
              {
                staticClass: "permission-setting-content",
                model: {
                  value: _vm.activeName,
                  callback: function ($$v) {
                    _vm.activeName = $$v
                  },
                  expression: "activeName",
                },
              },
              _vm._l(_vm.data, function (item, index) {
                return _c(
                  "el-tab-pane",
                  {
                    key: item.name,
                    attrs: { label: item.name, name: index + "" },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "permission-setting-item" },
                      _vm._l(item.list, function (btns, i) {
                        return _c(
                          "div",
                          { key: i },
                          [
                            btns.selector === "checkbox"
                              ? [
                                  _c("h3", [_vm._v(_vm._s(btns.name))]),
                                  _c(
                                    "el-checkbox-group",
                                    {
                                      model: {
                                        value: btns.detailList[0].checkList,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            btns.detailList[0],
                                            "checkList",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "btns.detailList[0].checkList",
                                      },
                                    },
                                    _vm._l(
                                      _vm.filterTplSelections(
                                        _vm.type[btns.type].subDictVO.dict,
                                        1
                                      ),
                                      function (checkItem) {
                                        return _c(
                                          "el-checkbox",
                                          {
                                            key: checkItem.text,
                                            attrs: { label: checkItem.value },
                                          },
                                          [
                                            _vm._v(
                                              " " + _vm._s(checkItem.text) + " "
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                    1
                                  ),
                                ]
                              : [
                                  _c("h3", [_vm._v(_vm._s(btns.name))]),
                                  _vm._l(
                                    _vm.filterTplSelections(btns.detailList, 2),
                                    function (radioItem) {
                                      return _c(
                                        "div",
                                        {
                                          key: radioItem.text,
                                          staticClass:
                                            "permission-setting-radio-group",
                                        },
                                        [
                                          _c("label", [
                                            _vm._v(
                                              _vm._s(radioItem.subTypeName)
                                            ),
                                          ]),
                                          _c(
                                            "el-radio-group",
                                            {
                                              attrs: { fill: "#FFFFFF" },
                                              model: {
                                                value: radioItem.auth,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    radioItem,
                                                    "auth",
                                                    $$v
                                                  )
                                                },
                                                expression: "radioItem.auth",
                                              },
                                            },
                                            [
                                              _c(
                                                "el-radio",
                                                {
                                                  attrs: { label: "1" },
                                                  nativeOn: {
                                                    click: function ($event) {
                                                      $event.preventDefault()
                                                      return _vm.change(
                                                        "1",
                                                        radioItem
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v(" 全部 ")]
                                              ),
                                              _c(
                                                "el-radio",
                                                {
                                                  attrs: { label: "0" },
                                                  nativeOn: {
                                                    click: function ($event) {
                                                      $event.preventDefault()
                                                      return _vm.change(
                                                        "0",
                                                        radioItem
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v(" 禁用 ")]
                                              ),
                                              radioItem.subType != "10"
                                                ? _c(
                                                    "el-radio",
                                                    {
                                                      attrs: { label: "2" },
                                                      nativeOn: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          $event.preventDefault()
                                                          return _vm.change(
                                                            "2",
                                                            radioItem
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [_vm._v(" 部分 ")]
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    }
                                  ),
                                ],
                          ],
                          2
                        )
                      }),
                      0
                    ),
                  ]
                )
              }),
              1
            ),
          ]
        : [
            _c("MetaDataSelectItem", {
              ref: "MetaDataSelectItem",
              attrs: {
                nameKey: "_name",
                idKey: "_id",
                url: _vm.url,
                defaultCheckedKey: _vm.defaultCheckedKey,
              },
            }),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }