<!--
 * @Descripttion: 复制移动导入组件
 * @Author: zhanghang
 * @Date: 2021-08-05 14:10:59
 * @LastEditors: zhanghang
 * @LastEditTime: 2022-01-17 14:44:03
-->
<template>
  <div class="catalogue-options">
    <div class="catalogue-options-center">
      <div class="catalogue-options-center-left">
        <el-scrollbar class="catalogue-options-scroll">
          <el-tree
            ref="tree"
            :props="defaultProps"
            :empty-text="loading ? '数据加载中...' : '暂无数据'"
            :highlight-current="true"
            :data="data"
            node-key="importKey"
            :default-expanded-keys="expandedKeys"
            :expand-on-click-node="false"
            @current-change="currNodeChange"
          >
            <span
              :class="{ 'disabeled-tree': data.importData === '0' }"
              class="custom-tree-node"
              slot-scope="{ node, data }"
            >
              <i
                v-if="data[defaultProps.children]"
                class="iconfont"
                style="color: #c78511"
                :class="[
                  node.expanded ? 'icon-dakai' : 'icon-wenjianjia',
                ]"
              />
              <i
                v-else
                style="color: #c78511"
                class="iconfont icon-shiyongwendang"
              />
              &nbsp;
              <span class="custom-tree-node-name" :title="data[defaultProps.label]" v-html="dsf.safe.xss(data[defaultProps.label])"></span>
              &nbsp;
              <i
                v-if="conflictList.includes(data[defaultProps.nodeKey])||undefinedList.includes(data[defaultProps.nodeKey]) ||data.error === true"
                class="error iconfont icon--zhongdaweixian"
              />
            </span>
          </el-tree>
        </el-scrollbar>
      </div>
      <div class="catalogue-options-center-right">
        <div class="catalogue-options-out-box">
          <el-table
            size="small"
            ref="multipleTable"
            class="catalogue-options-table"
            height="100%"
            :highlight-current-row="false"
            :cell-class-name="tableRowClassName"
            :data="tableData"
            border
          >
            <el-table-column label="名称" width="180">
              <template slot-scope="scope">
                <el-input
                  class="table-input"
                  size="small"
                  :class="{error: scope.row['error'] === true&&scope.row['errorType']==='1'}"
                  @change="changeName(scope)"
                  :disabled="scope.row['importData'] === '0'"
                  v-model="scope.row[defaultProps.label]"
                ></el-input>
              </template>
            </el-table-column>
            <!-- -->
            <el-table-column label="类型" width="120">
              <template slot-scope="scope">
                <span>{{ scope.row["pageType"] }}</span>
              </template>
            </el-table-column>
            <el-table-column label="标识简称" width="120">
              <!--  -->
              <template slot-scope="scope">
                <el-input
                  class="table-input"
                  :class="{error: scope.row['error'] === true&&scope.row['errorType']!='1'&& !scope.row['cover']}"
                  size="small"
                  :disabled="scope.row['importData'] === '0'||!!scope.row['cover']"
                  @change="lintCode(scope)"
                  v-model="scope.row['code']"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="160">
              <template slot-scope="scope">
                <template
                  v-if="(scope.row['error'] === true || scope.row['cover'])&&scope.row['errorType']!='1'"
                >
                  <el-button
                    @click="replace(scope, '1')"
                    class="btn plain"
                    :class="{ normal: scope.row['cover'] === '1' }"
                    size="mini"
                  >替换</el-button>
                  <el-button
                    v-if="scope.row['pageValue'] !== '0'"
                    @click="replace(scope, '0')"
                    class="btn plain"
                    :class="{ normal: scope.row['cover'] === '0' }"
                    size="mini"
                  >跳过</el-button>
                </template>
              </template>
            </el-table-column>
            <el-table-column label="数据操作" width="140">
              <template slot-scope="scope">
                <el-button-group v-if="['1','5','8'].includes(scope.row['pageValue'])">
                  <el-button
                    class="btn plain"
                    :class="{normal:scope.row['copyType'] != '0'}"
                    @click="copyRow(scope, '1')"
                    size="mini"
                  >
                    复制
                  </el-button>
                  <el-button
                    @click="copyRow(scope, '0')"
                    :class="{normal:scope.row['copyType'] == '0',
                    }"
                    class="btn plain"
                    size="mini"
                  >
                    引用
                  </el-button>
                </el-button-group>
              </template>
            </el-table-column>
            <el-table-column label="错误信息">
              <template slot-scope="scope">
                <span class="error" v-if="!scope.row['cover']">{{ scope.row["msg"] }}</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <div class="catalogue-options-footer">
      <div class="catalogue-options-footer-left">
        {{operaType}}到目录下方&nbsp;&nbsp;
        <el-cascader
          size="small"
          ref="cascader"
          class="cascader"
          :disabled="true"
          :options="targetdata"
          v-model="targetKeys"
          :props="{ ...defaultPropsCascader, checkStrictly: true }"
          clearable
        ></el-cascader>
      </div>
      <div class="btns">
        <span class="error" v-if="conflictList.length">
          存在重复标识，请修改标识在操作
        </span>
        <span class="error" v-else-if="undefinedList.length">
          存在未填写数据
        </span>
        <DsfButton @click="preStep" v-if="operateType != 'import'">
          上一步
        </DsfButton>
        <DsfButton @click="operate('import')" v-if="operateType === 'import'">
          导入
        </DsfButton>
        <DsfButton @click="operate('copy')" v-if="operateType === 'copy'">
          复制
        </DsfButton>
        <DsfButton @click="operate('move')" v-if="operateType === 'move'">
          移动
        </DsfButton>
        <DsfButton class="plain" @click="cancel">取消</DsfButton>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "OperateList",
  /*operateType [操作类型 [copy]复制 [move]移动 [export]导出 [import] 导入]
   *importData 导入数据
   *targetKeys 移动、复制、导入目录
   */
  inject: ["operateType"],
  props:{
    importData:{
      type:Object,
      default(){
        return {}
      }
    },
    targetKeys:{
      type:String,
      default:''
    }
  },
  data() {
    return {
      tableData: [],
      data: this.importData.rmDataTrees,
      loading: false,
      targetdata: [],
      defaultProps: {
        children: "children",
        label: "name",
        nodeKey:"importKey",
        value: "_id",
        disabled(data) {
          return data.importData === "0";
        },
      },
      defaultPropsCascader: {
        children: "children",
        label: "_name",
        value: "_id",
      },
      //当前选中节点
      currentNodeId: "",
      // 默认展开
      expandedKeys: [],
      //冲突数组列表
      conflictList: [],
      //未填写列表
      undefinedList:[],
      //第一个冲突对象
      conflictItem: null,
    };
  },
  computed:{
    operaType(){
      let s = ''
      switch(this.operateType){
      case 'copy':
        s='复制'
        break;
      case 'move':
        s='移动'
        break;
      case 'import':
        s='导入'
        break;
      }
      return s
    }
  },
  created() {
    //初始化目标目录数据
    this.initTargetData();
    this.initTable()
  },
  methods: {
    initTable(){
      //创建数据做一次比较
      this.compareTree();
     
      if(this.tableData.length){
        return false
        // 存在第一次冲突对象加载该冲突对象
      }else if (this.conflictItem) {
        const { children } = this.conflictItem;
        this.currentNodeId = this.conflictItem[this.defaultProps.value]
        this.tableData = [this.conflictItem, ...(children ? children : [])];
      }else {
        let stack = [...this.data];
        while (stack.length) {
        // 出队列
          let head = stack.shift();
        // 推入队列
        head.children?.forEach((item) => {
          stack.push(item);
        });
        // 如果当前节点id等于选中id 清空队列
        if (head.importData==='1') {
          this.expandedKeys = [head.importKey];
          //清空队列
          stack = [];
        }
        }
      }
    },
    initTargetData() {
      let loading = dsf.layer.loading();
      this.$http.get("rm/getRmTree").done((res) => {
        if (res.success) {
          let list = [];
          if (!Array.isArray(res.data)) {
            list = [res.data];
          } else {
            list = res.data;
          }
          this.targetdata = list;
        } else {
          dsf.layer.message(res.message, false);
        }
      }).error((res) => {
        dsf.layer.message(
          res.message || res.error?.message || "查询目录失败",
          false
        );
      }).always(() => {
        this.loading = true;
        dsf.layer.closeLoading(loading);
      });
    },
    // 比较树列表
    compareTree() {
      // 做深度优先遍历给父节点添加冲突标识
      this.conflictList = this.conflictDsf(this.data);
  
      //未填写列表
      this.undefinedList = this.undefinedDsf(this.data);

      //默认展开冲突目录
      this.conflictList.length&& (this.expandedKeys = this.conflictList)

    },
    // 节点点击
    currNodeChange(currentRow) {
      this.currentNodeId = currentRow[this.defaultProps.value]
      const { children } = currentRow;
      this.tableData = [currentRow, ...(children ? children : [])];
    },
    //深度优先遍历，过滤出目标数据，第一次会筛选出第一个冲突对象
    conflictDsf(data) {
      let list = [];
      let defaultProps = this.defaultProps
      for (let i = 0; i < data.length; i++) {
        let item = data[i];
        if (item.children && item.children.length > 0) {
          if (item.error&&item[defaultProps.label]&&item.code) {
            // 筛选第一个冲突对象
            if (!this.conflictItem) this.conflictItem = item;
            list.push(item[defaultProps.nodeKey]);
          } else {
            let r = this.conflictDsf(item.children);
            if (r.length > 0) {
              list = [...list, item[defaultProps.nodeKey], ...r];
            }
          }
        } else {
          if (item.error&&item[this.defaultProps.label]&&item.code) {
            if (!this.conflictItem) this.conflictItem = item;
            list.push(item[defaultProps.nodeKey]);
          }
        }
      }
      return list;
    },
    //深度优先遍历，过滤出未填写
    undefinedDsf(data) {
      let list = [];
      let defaultProps = this.defaultProps
      for (let i = 0; i < data.length; i++) {
        let item = data[i];
        if (item.children && item.children.length > 0) {
          if (!item[this.defaultProps.label]||item.code==='') {
            list.push(item[defaultProps.nodeKey]);
          } else {
            let r = this.undefinedDsf(item.children);
            if (r.length > 0) {
              list = [...list, item[defaultProps.nodeKey], ...r];
            }
          }
        } else {
          if (!item[this.defaultProps.label]||item.code==='') {
            list.push(item[defaultProps.nodeKey]);
          }
        }
      }
      return list;
    },
    //表格行禁用样式
    tableRowClassName({ row }) {
      return row.importData === "0" ? "disabled" : "";
    },
    //错误信息设置
    errorSeting(row,error,errorType,msg){
      row.error = error;
      row.errorType=errorType
      row.msg = msg
    },
    //名称改变
    changeName(scope){
      const row = scope.row;
      if(!row[this.defaultProps.label]){
        this.errorSeting(row,true,'1','请输入名称')
        this.compareTree();
        return false
        // 如果用户已选择 覆盖或者忽略则 表示验证成功
      }else if(row.cover){
        this.errorSeting(row,false)
        this.compareTree();
      }else if(row.errorType=='1'){
        this.lintCode(scope)
      } 
    },
    //展开树结构
    extendTree(data){
      let allList = []
      let {children,...item} = row
      allList.push(item)
      if(children) return [...allList,...children.forEach(extendTree)]
      return allList
    },
    checkChildren(row){

      let code = row.code;
      let gidarr = row.gid.split(".");
      gidarr[gidarr.length - 1] = code;
      row.gid = gidarr.join(".");

      let loading = dsf.layer.loading();
      this.$http.post("rm/repetitionRmTree",JSON.stringify({
        tagId: this.importData.tagId,
        targetId: this.targetKeys,
        dataTrees:[row]
      }),{
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        }
      }).done((res)=>{
        if (res.success) {
          let rmDataTrees = res.data.rmDataTrees[0]
          let stack = [...this.data];

          while (stack.length) {
            // 出队列
            let head = stack.shift();
     
            // 如果当前节点id等于选中id 清空队列
            if (head[this.defaultProps.nodeKey] === rmDataTrees[this.defaultProps.nodeKey]) {
   
              if(rmDataTrees.msg){
                rmDataTrees.cover = null
                rmDataTrees.error = true
              }
              const { children } = rmDataTrees;

              Object.assign(head,rmDataTrees)
              this.tableData = [head, ...(children ? children : [])];
            }
            // 推入队列
             head.children?.forEach((item) => {
               stack.push(item);
             });
          }
          this.initTable()
        }else {
          this.errorSeting(row,true,'2','该标识已存在')
        }
      }).error((res) => {
        dsf.layer.message(res.message, false);
      })
        .always(() => {
          dsf.layer.closeLoading(loading);
        });

    },
    // 校验code
    lintCode(scope) {
      const row = scope.row;
      let code = row.code;
      if(!row[this.defaultProps.label]){
        this.errorSeting(row,true,'1','请输入名称')
        return false
      }else if(!code){
        this.errorSeting(row,true,'2','请输入标识')
        this.compareTree();
        return false
        // 如果用户已选择 覆盖或者忽略则返回
      }else if (row.cover) {
        row.error = false;
        row.errorType = undefined;
        row.msg = "";
        return false;
        // 如果是目录情况
      }else if (row.pageValue==='0') {
        this.checkChildren(row)
        return false
      }
      //需要调用接口校验
      let loading = dsf.layer.loading();
      let gidarr = row.gid.split(".");
      gidarr[gidarr.length - 1] = code;
      row.gid = gidarr.join(".");
      this.$http.post("rm/checkRepetition",{
        gId: row.gid,
        pageValue: row.pageValue,
        targetId: this.targetKeys,
      },null).done((res) => {
        if (res.success) {
          this.errorSeting(row,false)
        } else {
          this.errorSeting(row,true,'2','该标识已存在')
        }
        //刷新左侧树
        this.compareTree();
      })
        .error((res) => {
          dsf.layer.message(res.message, false);
        })
        .always(() => {
          dsf.layer.closeLoading(loading);
        });
      
    },
    //替换数据
    replace(scope, type,ischildren) {
      const row = scope.row;
      if(!row.cover&&!row['error']) return false
     
      if(!row[this.defaultProps.label]){
        this.errorSeting(row,true,'1','请输入名称')
        this.compareTree();
        return false
      }else if(!row.code){
        this.errorSeting(row,true,'2','请输入标识')
        this.compareTree();
        return false
      }else if (!row.cover || row.cover != type||ischildren) {
        row.cover = type;
        row.error = false;
        row.msg = "";

        row.children?.forEach((v)=>this.replace({row:v},type,true))
        this.compareTree();

   
      } else {
        row.cover = null;
        //校验code
        this.lintCode(scope);
      }
    },
    // 复制引用数据类型
    copyRow(scope, type) {
      const row = scope.row;
      row.copyType = type;
    },
    // 上一步
    preStep() {
      this.$emit("changeStep", {step:1});
    },
    // 取消
    cancel(refresh) {
      this.$emit("close",refresh);
    },
    //操作
    operate(type) {
      let cut = 0;
      let typeStr = "导入";
      if (this.conflictList.length){
        dsf.layer.message('有重复标识',false)
        return false;
      }else if(this.undefinedList.length){
        dsf.layer.message('有未填写数据',false)
        return false;
      }
       
      switch (type) {
      case "copy":
        typeStr = "复制";
        break;
      case "move":
        cut = 1;
        typeStr = "移动";
        break;
      }
      let loading = dsf.layer.loading();
      this.$http.post("rm/importRm",JSON.stringify({
        tagId: this.importData.tagId,
        targetRmId: this.targetKeys,
        cut,
        importData: this.data,
      }),{headers: {"Content-Type": "application/json; charset=utf-8",}})
        .done((res) => {
          if (res.success) {
            dsf.layer.message(`${typeStr}成功`);
            this.cancel(true)
          } else {
            dsf.layer.message(`${typeStr}失败`, false);
          }
        })
        .error((res) => {
          dsf.layer.message(
            res.message || res.error?.message || "请求异常",
            false
          );
        })
        .always(() => {
          dsf.layer.closeLoading(loading);
        });
    },
  },
};
</script>
