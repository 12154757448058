import title from './title';

export default dsf.component({
  name: 'DsfTitle4',
  mixins: [title],
  ctrlCaption: "标题4",
  props: {
    value: {
      type: [String, Number],
      default: '标题4'
    },
    fontType: {
      type: Number,
      default: 4
    }
  }
});