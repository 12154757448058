var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dsf-forgot-password-control" },
    [
      _c("div", { staticClass: "dsf-forgot-password-step" }, [
        _c(
          "div",
          {
            staticClass: "forgot-password-step",
            class: { "current-step": _vm.step >= 1 },
          },
          [
            _c("div", { staticClass: "step-cricle" }, [
              _c("div", { staticClass: "cricle normal" }, [_vm._v("1")]),
              _vm.forget_pwd_setting === "phone"
                ? _c("p", [_vm._v("手机验证")])
                : _vm.forget_pwd_setting === "mail"
                ? _c("p", [_vm._v("邮箱验证")])
                : _c("p", [_vm._v("手机、邮箱验证")]),
            ]),
            _c("div", { staticClass: "right-line" }),
          ]
        ),
        _c(
          "div",
          {
            staticClass: "forgot-password-step",
            class: { "current-step": _vm.step >= 2 },
          },
          [
            _c("div", { staticClass: "left-line" }),
            _vm._m(0),
            _c("div", { staticClass: "right-line" }),
          ]
        ),
        _c(
          "div",
          {
            staticClass: "forgot-password-step",
            class: { "current-step": _vm.step >= 3 },
          },
          [_c("div", { staticClass: "left-line" }), _vm._m(1)]
        ),
      ]),
      _vm.forget_pwd_setting === "all" && _vm.step === 1
        ? _c("div", { staticClass: "dsf-forgot-password-tab" }, [
            _c(
              "div",
              {
                staticClass: "dsf-forgot-password-tab-item",
                class: { "password-tab-item-active": _vm.tabActive === 0 },
                on: {
                  click: function ($event) {
                    return _vm.tabClick(0)
                  },
                },
              },
              [_c("p", [_vm._v("手机号验证")])]
            ),
            _c(
              "div",
              {
                staticClass: "dsf-forgot-password-tab-item",
                class: { "password-tab-item-active": _vm.tabActive === 1 },
                on: {
                  click: function ($event) {
                    return _vm.tabClick(1)
                  },
                },
              },
              [_c("p", [_vm._v("邮箱验证")])]
            ),
          ])
        : _vm._e(),
      _c(
        "div",
        { staticClass: "dsf-forgot-password-content" },
        [
          _vm.forget_pwd_setting === "phone" ||
          (_vm.forget_pwd_setting === "all" && _vm.tabActive === 0)
            ? [
                _vm.step == 1
                  ? _c(
                      "div",
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.phone,
                              expression: "phone",
                            },
                          ],
                          staticClass: "dsf-forgot-password-input",
                          attrs: {
                            type: "text",
                            maxlength: "11",
                            placeholder: "请输入手机号",
                          },
                          domProps: { value: _vm.phone },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.phone = $event.target.value
                            },
                          },
                        }),
                        _c(
                          "div",
                          {
                            staticClass: "dsf-forgot-password-btn",
                            on: { click: _vm.showDialog },
                          },
                          [_vm._v(" 请点击进行验证 ")]
                        ),
                        _c(
                          "el-button",
                          {
                            staticClass: "dsf-forgot-password-next-btn",
                            class: { disabled: _vm.disabled },
                            attrs: { type: "primary", disabled: _vm.disabled },
                            on: { click: _vm.checkEmailCode },
                          },
                          [_vm._v(" 下一步 ")]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            : [
                _vm.step == 1
                  ? _c(
                      "div",
                      { staticClass: "dsf-forgot-password-email" },
                      [
                        _c(
                          "div",
                          { staticClass: "dsf-forgot-password-input-e" },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.email,
                                  expression: "email",
                                },
                              ],
                              staticClass: "dsf-forgot-password-input",
                              attrs: {
                                type: "text",
                                placeholder: "请输入邮箱地址",
                              },
                              domProps: { value: _vm.email },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.email = $event.target.value
                                },
                              },
                            }),
                            _c("p", { on: { click: _vm.sendEmailCode } }, [
                              _vm._v(_vm._s(_vm.mailTimeMsg)),
                            ]),
                          ]
                        ),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.emailcode,
                              expression: "emailcode",
                            },
                          ],
                          staticClass: "dsf-forgot-password-input",
                          attrs: {
                            type: "text",
                            placeholder: "请输入邮箱验证码",
                          },
                          domProps: { value: _vm.emailcode },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.emailcode = $event.target.value
                            },
                          },
                        }),
                        _vm.codeAuthResult && _vm.emailcode
                          ? _c("p", { staticClass: "error-msg" }, [
                              _c("i", {
                                staticClass: "iconfont icon-tixingshixin",
                              }),
                              _vm._v(" " + _vm._s(_vm.codeAuthResult) + " "),
                            ])
                          : _vm._e(),
                        _c(
                          "el-button",
                          {
                            staticClass: "dsf-forgot-password-next-btn",
                            class: { disabled: _vm.disabled },
                            attrs: { type: "primary", disabled: _vm.disabled },
                            on: { click: _vm.checkEmailCode },
                          },
                          [_vm._v(" 下一步 ")]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
          _vm.step === 2
            ? _c(
                "div",
                [
                  _c("p", { staticClass: "step-title" }, [
                    _vm._v("设置新密码"),
                  ]),
                  _c("div", { staticClass: "dsf-forgot-password-input-btn" }, [
                    _vm.type === "checkbox"
                      ? _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.newpwd,
                              expression: "newpwd",
                            },
                          ],
                          attrs: {
                            placeholder: "设置新密码",
                            type: "checkbox",
                          },
                          domProps: {
                            checked: Array.isArray(_vm.newpwd)
                              ? _vm._i(_vm.newpwd, null) > -1
                              : _vm.newpwd,
                          },
                          on: {
                            change: function ($event) {
                              var $$a = _vm.newpwd,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 && (_vm.newpwd = $$a.concat([$$v]))
                                } else {
                                  $$i > -1 &&
                                    (_vm.newpwd = $$a
                                      .slice(0, $$i)
                                      .concat($$a.slice($$i + 1)))
                                }
                              } else {
                                _vm.newpwd = $$c
                              }
                            },
                          },
                        })
                      : _vm.type === "radio"
                      ? _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.newpwd,
                              expression: "newpwd",
                            },
                          ],
                          attrs: { placeholder: "设置新密码", type: "radio" },
                          domProps: { checked: _vm._q(_vm.newpwd, null) },
                          on: {
                            change: function ($event) {
                              _vm.newpwd = null
                            },
                          },
                        })
                      : _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.newpwd,
                              expression: "newpwd",
                            },
                          ],
                          attrs: { placeholder: "设置新密码", type: _vm.type },
                          domProps: { value: _vm.newpwd },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.newpwd = $event.target.value
                            },
                          },
                        }),
                    _c("i", {
                      staticClass: "icon iconfont",
                      class: {
                        "icon-icon-invisible": _vm.type === "password",
                        "icon-icon-visible": _vm.type === "text",
                      },
                      on: { click: _vm.changeIcon },
                    }),
                  ]),
                  _c("div", { staticClass: "dsf-forgot-password-input-btn" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.confirmNewpwd,
                          expression: "confirmNewpwd",
                        },
                      ],
                      attrs: { type: "password", placeholder: "确认新密码" },
                      domProps: { value: _vm.confirmNewpwd },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.confirmNewpwd = $event.target.value
                        },
                      },
                    }),
                  ]),
                  _c("p", { staticClass: "step-tip" }, [
                    _vm._v(_vm._s(_vm.notice)),
                  ]),
                  _c(
                    "el-button",
                    {
                      staticClass: "dsf-forgot-password-next-btn",
                      class: {
                        disabled:
                          !_vm.newpwd ||
                          (_vm.newpwd && _vm.newpwd !== _vm.confirmNewpwd),
                      },
                      attrs: {
                        type: "primary",
                        disabled:
                          !_vm.newpwd ||
                          (_vm.newpwd && _vm.newpwd !== _vm.confirmNewpwd),
                      },
                      on: {
                        click: function ($event) {
                          return _vm.nextStep(3)
                        },
                      },
                    },
                    [_vm._v(" 下一步 ")]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.step === 3
            ? _c(
                "div",
                { staticClass: "step-three" },
                [
                  _vm._m(2),
                  _c("p", { staticClass: "success-tip" }, [
                    _vm._v("登录密码更新成功"),
                  ]),
                  _c(
                    "el-button",
                    {
                      staticClass: "dsf-forgot-password-success-btn",
                      attrs: { type: "primary" },
                      on: { click: _vm.reLogin },
                    },
                    [_vm._v(" 确 定 ")]
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        2
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            "modal-append-to-body": false,
            visible: _vm.dialogVisible,
            width: "480px",
            "close-on-click-modal": false,
            "close-on-press-escape": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "forgot-password-dialog" },
            [
              _c("div", { staticClass: "title" }, [_vm._v("手机短信验证码")]),
              _c("div", { staticClass: "phone" }, [
                _vm._v("当前手机号：" + _vm._s(_vm.hiddenPhone)),
              ]),
              _c(
                "div",
                { staticClass: "dsf-forgot-password-input-2" },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.code,
                        expression: "code",
                      },
                    ],
                    attrs: { type: "text", placeholder: "请输入验证码" },
                    domProps: { value: _vm.code },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.code = $event.target.value
                      },
                    },
                  }),
                  _c(
                    "el-button",
                    {
                      staticClass: "dsf-forgot-password-next-btn",
                      class: { disabled: _vm.interval },
                      attrs: {
                        type: "primary",
                        disabled: Boolean(_vm.interval),
                      },
                      on: { click: _vm.getCode },
                    },
                    [_vm._v(" " + _vm._s(_vm.timeMsg) + " ")]
                  ),
                ],
                1
              ),
              _vm.codeAuthResult && _vm.code
                ? _c("p", { staticClass: "error-msg" }, [
                    _c("i", { staticClass: "iconfont icon-tixingshixin" }),
                    _vm._v(" " + _vm._s(_vm.codeAuthResult) + " "),
                  ])
                : _vm._e(),
              _c(
                "el-button",
                {
                  staticClass: "dsf-forgot-password-dialog-next-btn",
                  class: { disabled: !_vm.code },
                  attrs: { type: "primary", disabled: !_vm.code },
                  on: {
                    click: function ($event) {
                      return _vm.nextStep(2)
                    },
                  },
                },
                [_vm._v(" 下一步 ")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "step-cricle" }, [
      _c("div", { staticClass: "cricle" }, [_vm._v("2")]),
      _c("p", [_vm._v("设置操作")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "step-cricle" }, [
      _c("div", { staticClass: "cricle" }, [_vm._v("3")]),
      _c("p", [_vm._v("完成")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "step-succss" }, [
      _c("i", { staticClass: "el-icon-check" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }