// import { formatDate, parseDate, isDateObject, getWeekNumber } from 'element-ui/src/utils/date-util';
import YearRange from "./YearRange";
const HAVE_TRIGGER_TYPES = [
  'date',
  'datetime',
  'time',
  'time-select',
  'week',
  'month',
  'year',
  'daterange',
  'monthrange',
  'timerange',
  'datetimerange',
  'yearrange',
  'dates'
];
let DatePicker = window.ELEMENT.DatePicker;
let dateMixins = DatePicker.mixins[0];
DatePicker.methods={
  handleFocus(){
    if (this.type === 'yearrange') {
      this.panel = YearRange;
    }
    const type = this.type;
    if (HAVE_TRIGGER_TYPES.indexOf(type) !== -1 && !this.pickerVisible) {
      this.pickerVisible = true;
    }
    this.$emit('focus', this);
  },
  handleRangeClick(){
    this.handleFocus();
  }
}
DatePicker.computed = {
  displayValue(){
    const values = dateMixins.computed.displayValue.call(this);
    if (this.type === 'yearrange' && this.parsedValue?.length > 0) {
      const parsedValue = typeof this.parsedValue === 'string' ? this.parsedValue.split(',') : this.parsedValue;
      let before = parsedValue[0] === '' ? '' : new Date(parsedValue[0]).getFullYear();
      let after = parsedValue[0] === '' ? '' : new Date(parsedValue[1]).getFullYear();
      if (before && after && this.format) {
        before = dsf.date.format(before.toString(), this.format.toLowerCase());
        let edate = new Date(after, 12, 0, 23, 59, 59);
        after = dsf.date.format(edate, this.format.toLowerCase());
      }
      return [before, after];
    }
    return values;
  }
}