import title from './title';

export default dsf.component({
  name: 'DsfTitle5',
  mixins: [title],
  ctrlCaption: "标题5",
  props: {
    value: {
      type: [String, Number],
      default: '标题5'
    },
    fontType: {
      type: Number,
      default: 5
    }
  }
});