<template functional>
  <div class="dsf-convenient-menu-item pc" :class="[props.displayStyle]">
    <div class="icon" :style="_vm.parent.iconStyle">
      <el-image :src="_vm.parent.getImgUrl(props.item) | imgFormat">
        <template slot="error">
          <i
            class="iconfont"
            :class="[_vm.parent.getIcon(props.item.icon)]" />
        </template>
      </el-image>
      <span
        v-if="_vm.parent.showMark(props.item)"
        class="angle-mark"
        :class="[_vm.parent.enableMark]"
        :style="_vm.parent.markStyle">
        {{ _vm.parent.markVal(props.item) }}
      </span>
    </div>
    <div class="info" :style="_vm.parent.infoStyle">
      <div class="name ellipsis">{{ props.item.name || '' }}</div>
      <div v-if="_vm.parent.hasDesc" class="desc ellipsis-2">{{ props.item.desc || '' }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DsfConvenientMenuItem",
  props: {
    item: {
      type: Object,
      required: true
    },
    displayStyle: {
      type: String,
      required: 'style1'
    }
  }
}
</script>