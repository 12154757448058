// let select = window.ELEMENT.Select;
let option = window.ELEMENT.Option;
// select.methods.navigateOptions = function (direction) {
//   if (!this.visible) {
//     this.visible = true;
//     return;
//   }
//   if (this.options.length === 0 || this.filteredOptionsCount === 0) return;
//   if (!this.optionsAllDisabled) {
//     if (direction === 'next') {
//       this.hoverIndex++;
//       if (this.hoverIndex === this.options.length) {
//         this.hoverIndex = 0;
//       }
//     } else if (direction === 'prev') {
//       this.hoverIndex--;
//       if (this.hoverIndex < 0) {
//         this.hoverIndex = this.options.length - 1;
//       }
//     }
//     const option = this.options[this.hoverIndex];
//     if (option.disabled === true || option.groupDisabled === true || !option.visible) {
//       this.navigateOptions(direction);
//     }
//     this.$nextTick(() => this.scrollToOption(this.hoverOption));
//   }
// }

option.props['show'] = {
  type: Boolean,
  default: true
}
option.watch['show'] = {
  handler(v) {
    this.visible = v
  }
}