export default {
  install(Vue) {
    Vue.filter('textValue', (v, textOrValue) => {
      if (v) {
        if (/^(\S+)\[(\S+)]$/.test(textOrValue)) {
          let res = textOrValue.match(/^(\S+)\[(\S+)]$/);
          if (dsf.type(v) == "object") {
            let attach = v['attach'] ? `(${v['attach']})` : '';
            return `${v[res[1]]} [${v[res[2]]}] ${attach}`;
          } else if (dsf.type(v) == "array") {
            return _.map(v, function (it) {
              let attach = it['attach'] ? `(${it['attach']})` : '';
              return `${it[res[1]]} [${it[res[2]]}] ${attach}`;
            }).join("，")
          }
        } else {
          if (dsf.type(v) == "object") {
            return v['attach'] ? `${v[textOrValue]} (${v['attach']})` : v[textOrValue];
          } else if (dsf.type(v) == "array") {
            return _.map(v, function(it) {
              return it['attach'] ? `${it[textOrValue]} (${it['attach']})` : it[textOrValue];
            }).join("，")
          }
        }
        return v;
      } else {
        return ""
      }
    })
  }
}