var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "ds-control ds-chart",
      style: { width: _vm.width, height: _vm.height },
    },
    [
      _c("div", { ref: "chart", staticClass: "chart" }, [
        _vm._v("我是折线柱图混合"),
      ]),
      _vm.isDesign || (!_vm.isDesign && _vm.slots[0].controls.length > 0)
        ? [
            _c(
              "div",
              {
                staticClass: "ds-chart-slot",
                attrs: { "slot-name": "default" },
              },
              [_vm._t("default")],
              2
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }