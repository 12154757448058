var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("main", { staticClass: "dsf-multiPage-home-main" }, [
    _c("div", { staticClass: "dsf-multiPage-home-tags" }, [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showBt,
              expression: "showBt",
            },
          ],
          staticClass: "dsf-multiPage-home-tags-bt __left",
          attrs: { disabled: _vm.disabledLeft },
          on: {
            click: function ($event) {
              return _vm.doPagination(1)
            },
          },
        },
        [_c("i", { staticClass: "iconfont icon-arrow-left2" })]
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "context-menu",
              rawName: "v-context-menu",
              value: { data: _vm.boxTreeMenu },
              expression: "{data: boxTreeMenu}",
            },
          ],
          ref: "box",
          staticClass: "dsf-multiPage-home-tags-list",
        },
        [
          _vm.scriptLoaded
            ? _c(
                "draggable",
                _vm._b(
                  {
                    staticClass: "dsf-multiPage-home-tags-box",
                    on: {
                      start: function ($event) {
                        _vm.dragging = true
                      },
                      end: function ($event) {
                        _vm.dragging = false
                      },
                      sort: _vm.saveStorage,
                    },
                    model: {
                      value: _vm.tagList,
                      callback: function ($$v) {
                        _vm.tagList = $$v
                      },
                      expression: "tagList",
                    },
                  },
                  "draggable",
                  _vm.options,
                  false
                ),
                [
                  _vm._l(_vm.tagList, function (tag) {
                    return [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "context-menu",
                              rawName: "v-context-menu",
                              value: { params: tag, data: _vm.treeMenu },
                              expression: "{params: tag, data: treeMenu }",
                            },
                          ],
                          key: tag.menu_id,
                          ref: "tag",
                          refInFor: true,
                          staticClass: "dsf-multiPage-home-tags-item",
                          class: {
                            active: _vm.activeTag.menu_id === tag.menu_id,
                            loading: tag.loading,
                          },
                          attrs: { title: tag.loading },
                          on: {
                            click: function ($event) {
                              return _vm.showPage(tag)
                            },
                          },
                        },
                        [
                          _c("span", [
                            _vm._v(_vm._s(tag.menu_name || tag.loading)),
                          ]),
                          _c("i", { staticClass: "el-icon-loading" }),
                          _c("i", {
                            staticClass:
                              "dsf-multiPage-home-tags-item-close iconfont icon-guanbi2",
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.remove({ params: tag })
                              },
                            },
                          }),
                        ]
                      ),
                    ]
                  }),
                ],
                2
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showBt,
              expression: "showBt",
            },
          ],
          staticClass: "dsf-multiPage-home-tags-bt __right",
          attrs: { disabled: _vm.disabledRight },
          on: {
            click: function ($event) {
              return _vm.doPagination(-1)
            },
          },
        },
        [_c("i", { staticClass: "iconfont icon-arrow-right2" })]
      ),
    ]),
    _c(
      "div",
      {
        staticClass: "dsf-multiPage-home-body",
        class: { dragging: _vm.dragging },
      },
      _vm._l(_vm.pageList, function (page) {
        return _c("home-view", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.activeTag.menu_id === page.menu_id,
              expression: "activeTag.menu_id === page.menu_id",
            },
          ],
          key: page.menu_id,
          ref: "view",
          refInFor: true,
          attrs: { page: page },
        })
      }),
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }