const iconList = [
  ['ppt^pptx', require('../assets/styles/themes/img/fileTypeIcon/file_type_ppt_icon.png')],
  ['pdf^pdfx', require('../assets/styles/themes/img/fileTypeIcon/file_type_pdf_icon.png')],
  ['mp3^cda^wav^aif^aiff^mid^wma^ra^vqf^ape', require('../assets/styles/themes/img/fileTypeIcon/file_type_mp3_icon.png')],
  ['doc^docx', require('../assets/styles/themes/img/fileTypeIcon/file_type_word_icon.png')],
  ['avi^mp4^wmv^mpg^mov^mpeg^ram^flv^rm^swf', require('../assets/styles/themes/img/fileTypeIcon/file_type_mp4_icon.png')],
  ['xlsx^xls^csv', require('../assets/styles/themes/img/fileTypeIcon/file_type_excel_icon.png')],
  ['png^jpg^bmp^jpeg', require('../assets/styles/themes/img/fileTypeIcon/file_type_png_icon.png')],
]
const defaultPng = require('../assets/styles/themes/img/fileTypeIcon/file_type_df_icon.png')

export default {
  install(Vue) {
    Vue.filter('fileSuffixIcon', fileSuffixIcon)
    Vue.prototype.$fileSuffixIcon = fileSuffixIcon
  }
}
function fileSuffixIcon(v,) {
  if (dsf.isString(v)) {
    v = JSON.parse(v)
  }
  if (dsf.isArray(v)) {
    v = v[0]
  }
  if (dsf.isObject(v)) {
    let icon = iconList.find(([suffix]) => {
      return suffix.includes(v.suffix)
    })
    if (icon) {
      icon = icon[1]
    } else {
      icon = defaultPng
    }
    return icon
  }
  return ''
}