<template>
  <div class="dsf-control ds-convenient-menu pc" :class="rootClass" :style="rootStyle">
    <dsf-convenient-menu-group v-if="menuList.length === 1" :group="menuList[0]">
      <template #default="{ item, rowIndex, colIndex }">
        <slot :item="item" :page-index="0" :row-index="rowIndex" :col-index="colIndex">
          <dsf-convenient-menu-item :display-style="displayStyle" :item="item" />
        </slot>
      </template>
    </dsf-convenient-menu-group>
    <template v-else-if="menuList.length > 1">
      <el-carousel
        ref="carousel"
        :loop="false"
        :autoplay="false"
        height="100%"
        arrow="never"
        @change="activeIndex = $event">
        <el-carousel-item v-for="(group, index) in menuList" :key="index">
          <dsf-convenient-menu-group :group="group">
            <template #default="{ item, rowIndex, colIndex }">
              <slot :item="item" :page-index="index" :row-index="rowIndex" :col-index="colIndex">
                <dsf-convenient-menu-item :display-style="displayStyle" :item="item" />
              </slot>
            </template>
          </dsf-convenient-menu-group>
        </el-carousel-item>
      </el-carousel>
      <template v-if="arrow && menuList.length > 1">
        <div class="ds-convenient-menu-arrow left" :class="{disabled: activeIndex == 0}">
          <i class="iconfont icon-arrow-left2" @click="$refs.carousel.prev()"></i>
        </div>
        <div class="ds-convenient-menu-arrow right" :class="{disabled: activeIndex == menuList.length - 1}">
          <i class="iconfont icon-arrow-right2" @click="$refs.carousel.next()"></i>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import DsfConvenientMenuGroup from "./items/convenientMenuGroup";
import DsfConvenientMenuItem from "./items/convenientMenuItem";

export default dsf.component({
  name: "DsfConvenientMenu",
  mixins: [$mixins.convenientMenu],
  ctrlCaption: "快捷菜单",
  design: {
    isMask: false
  },
  components: {
    DsfConvenientMenuGroup,
    DsfConvenientMenuItem
  },
  props: {
    // 是否显示轮播切换箭头
    arrow: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    rootClass() {
      let res = [];
      if (this.arrow && this.menuList.length > 1) {
        res.push('arrow')
      }
      if (this.innerHeightAuto) {
        res.push('inner-auto')
      }
      return res;
    },
    rootStyle() {
      let res = {'min-height': this.minHeight};
      if (this.isDesign) {
        this.$dispatch("design-height-change", this.height);
        res.height = "100%";
      } else {
        res.height = this.height;
      }
      return res;
    },
    colStyle() {
      return {
        'padding': this.cellMargin.join(' ')
      };
    },
    boxStyle() {
      return {
        'padding': this.cellPadding.join(' '),
        'background-color': this.cellColor,
        'width': this.cellWidth,
        'border-radius': this.cellRadius
      };
    },
    iconStyle() {
      let res = {
        'width': this.iconWidth,
        'height': this.iconHeight,
        'border-radius': this.iconRadius,
        'background-color': dsf.themeConfig.getThemeColor(this.iconBgColor) || this.iconBgColor,
        'color': dsf.themeConfig.getThemeColor(this.iconFontColor) || this.iconFontColor,
        'font-size': this.iconFontSize,
        'font-weight': this.iconFontBold ? 'bold' : 'normal'
      };
      if (this.iconBgShadow) {
        res['box-shadow'] = '0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04)';
      }
      if (this.iconFontShadow) {
        res['text-shadow'] = '0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04)';
      }
      return res;
    },
    infoStyle() {
      let res = {};
      if (this.displayStyle === 'style1') {
        res['margin-top'] = this.iconAndInfoOfSpacing;
      } else if (this.displayStyle === 'style2') {
        res['margin-left'] = this.iconAndInfoOfSpacing;
      }
      return res;
    },
    markStyle() {
      let n = `calc(0.25 * ${this.iconRadius})`
      return {
        top: n,
        right: n
      }
    }
  },
});
</script>