<template>
  <div :style="controlStyle" class="ds-virtual-list ds-control ds-no-padding">
    <div
      class="ds-virtual-list-warp"
      :is-design="isDesign"
      :style="panelStyle"
      :pointer="pointer"
      v-bind="{
        hoverBright: styleAttrs.hoverBright,
        dividerLine: styleAttrs.dividerLine,
      }">
      <template v-if="isDesign">
        <div class="ds-virtual-list-design" slot-name="list-row">
          <slot name="list-row" v-bind="{ row: {}, $index: 0, $hash: 1 }"></slot>
        </div>
      </template>
      <template v-else>
        <dsf-empty-data v-if="loaded&&(!listData || !listData.length)"></dsf-empty-data>
        <template v-else>
          <template v-for="(row, index) in fliterList">
            <div
              :key="index"
              class="ds-virtual-list-row"
              :style="{
                padding: rowPadding.join('px ') + 'px',
                margin: rowMargin.join('px ') + 'px',
              }"
              @click="rowClick({ row, $index: index, $hash: row.$hash })">
              <!-- {{ JSON.stringify({ row, $index: index, $hash: row.$hash }) }} -->
              <slot name="list-row" v-bind="{ row, $index: index, $hash: row.$hash }"></slot>
            </div>
          </template>
        </template>
      </template>
    </div>
  </div>
</template>
<script>
export default dsf.component({
  name: "DsfVirtualList",
  ctrlCaption: "标签面板",
  mixins: [$mixins.control],
  design: {
    isMask: false
  },
  props: {
    slots: {
      type: Array,
      default() {
        return [
          {
            name: "list-row",
            controls: [],
            scope: "scope",
            repeat: true
          }
        ];
      }
    },
    // 宽度
    width: {
      type: String,
      default: "100%"
    },
    // 高度
    height: {
      type: String,
      default: "auto"
    },
    // 风格
    styleType: {
      type: String,
      default: "one"
    },
    // 列表数据
    list: {
      type: Array,
      default: () => []
    },
    // url
    url: {
      type: String,
      default: ""
    },
    urlQuery: {
      type: Object,
      default: () => {}
    },
    padding: {
      type: Array,
      default: () => [0, 0, 0, 0]
    },
    margin: {
      type: Array,
      default: () => [0, 0, 0, 0]
    },
    rowPadding: {
      type: Array,
      default: () => [0, 0, 0, 0]
    },
    rowMargin: {
      type: Array,
      default: () => [0, 0, 0, 0]
    },
    styleAttrs: {
      type: Object,
      default: () => ({
        hoverBright: false,
        dividerLine: false
      })
    },
    max: {
      type: Number,
      default: null
    },
    pointer: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    fliterList() {
      let { listData, max } = this;
      if (!max) return listData || [];
      return listData.slice(0, max);
    },
    controlStyle() {
      let { width, margin, height, isDesign, listData } = this;
      let style = {
        width,
        height,
        padding: margin.join("px ") + "px"
      };
      if (isDesign) {
        style.padding = "10px 10px 10px 10px";
      }
      if (height == "auto" && (!listData || !listData.length)) {
        style.height = "100%";
      }
      return style;
    },
    panelStyle() {
      let { height, padding, listData } = this;
      let style = {
        height,
        padding: padding.join("px ") + "px"
      };
      if (height == "auto" && (!listData || !listData.length)) {
        style.height = "100%";
      }
      return style;
    }
  },

  data() {
    return {
      loaded: false,
      listData: []
    };
  },

  watch: {
    listData: {
      immediate: true,
      handler(v = []) {
        _.each(v, (it) => {
          !("$hash" in it) && this.$set(it, "$hash", dsf.uuid());
        });
        this.$emit("list-change", v);
      }
    },
    list: {
      immediate: true,
      handler(v = []) {
        this.listData = v;
      }
    },
    url: {
      immediate: true,
      handler() {
        this.getDataUrl();
      }
    },
    urlQuery() {
      this.getDataUrl();
    }
  },

  created() {},

  methods: {
    getDataUrl() {
      let { url } = this;
      if (this.isDesign || !url) return;
      if (this.getDataUrlSetTimeout) clearTimeout(this.getDataUrlSetTimeout);
      this.getDataUrlSetTimeout = setTimeout(() => {
        let param = {
          ...(this.urlQuery || {})
        };
        this.$http
          .get(url, param)
          .then(({ data }) => {
            if (data && data.success) {
              this.listData = data.data || [];
            } else {
              dsf.layer.message(data.message, false);
            }
          })
          .catch((err) => {
            dsf.layer.message("未知错误", false);
          })
          .finally(() => {
            this.loaded = true;
          });
      }, 400);
    },
    reloadData() {
      this.getDataUrl();
      _.each(this.$children, (child) => {
        child?.reloadData && child.reloadData();
      });
    },
    rowClick(args) {
      this.$dispatch("row-click", args);
    }
  }
});
</script>
