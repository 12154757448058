<template>
  <div class="ds-column-filter-data" style="height:100%">
    <!--文本-->
    <div v-if="type=='string'" tabindex="0" key="string" class="filter-dialog-content" style="height:100%" @keydown="keyDownHander">
      <el-form @submit.native.prevent="sureQuery($event)">
        <el-form-item style="margin-bottom:10px">
          <el-input v-model="filterTxt" placeholder="请输入" @input="textInputHandler">
            <template v-slot:prefix>
              <i class="el-icon-search"></i>
            </template>
          </el-input>
        </el-form-item>
      </el-form>
      <div style="height:calc(100% - 50px);overflow:auto" class="box" v-loading="loading">
        <el-row>
          <el-col :span="24" v-for="(item,idx) in data" :key="idx" @click.native="clickItem(item,idx)" class="result-item" :class="{'is-focus':idx==reaultActive}" style="line-height:1.9em" v-html="highKeyword(item,filterTxt)">
          </el-col>
        </el-row>
      </div>
    </div>
    <!--数字-->
    <div v-else-if="type=='number'" key="number" tabindex="0" class="filter-dialog-content" style="height:100%">
      <el-form @submit.prevent>
        <el-form-item style="margin-bottom:10px">
          <div class="number-box">
            <el-select v-model="operation" class="operation-type" slot="prepend" placeholder="请选择" style="width:110px">
              <el-option v-for="op in operationOptions" :key="op.value" :label="op.text" :value="op.value"></el-option>
            </el-select>
            <div class="number-box-input" :class="{'is-foucs':boxFoucs}">
              <el-input v-model="values[0]" placeholder="请输入" @input="inputInput(0)" @focus="inputFocus" @blur="inputBlur">
              </el-input>
              <template v-if="operation=='between'||operation=='gtlt'">
                <span>~</span>
                <el-input v-model="values[1]" placeholder="请输入" @input="inputInput(1)" @focus="inputFocus" @blur="inputBlur">
                </el-input>
              </template>
            </div>
          </div>
        </el-form-item>
      </el-form>
    </div>
    <!--日期-->
    <div v-else-if="type=='ymd'||type=='ym'" key="date" tabindex="0" class="filter-dialog-content" style="height:100%">
      <el-form @submit.prevent>
        <el-form-item style="margin-bottom:10px">
          <div class="date-box">
            <el-select v-model="operation" class="operation-type" slot="prepend" placeholder="请选择" style="width:110px">
              <el-option v-for="op in operationOptions" :key="op.value" :label="op.text" :value="op.value"></el-option>
            </el-select>
            <div class="date-box-input" v-if="type=='ymd'">
              <el-date-picker key="dates" v-if="operation=='between'||operation=='gtlt'" v-model="values" type="daterange" range-separator="~" start-placeholder="开始日期" end-placeholder="结束日期">
              </el-date-picker>
              <el-date-picker key="date" v-else v-model="values[0]" type="date" placeholder="请选择日期" style="width:100%">
              </el-date-picker>
            </div>
            <div class="date-box-input" v-if="type=='ym'">
              <el-date-picker format="yyyy-MM" key="dates" v-if="operation=='between'||operation=='gtlt'" v-model="values" type="monthrange" range-separator="~" start-placeholder="开始日期" end-placeholder="结束日期">
              </el-date-picker>
              <el-date-picker format="yyyy-MM" key="date" v-else v-model="values[0]" type="month" placeholder="请选择日期" style="width:100%">
              </el-date-picker>
            </div>
          </div>
        </el-form-item>
      </el-form>
    </div>
    <!--枚举-->
    <div v-else-if="type=='enum'" key="enum" tabindex="0" class="filter-dialog-content" style="height:100%">
      <el-form @submit.prevent>
        <el-form-item style="margin-bottom:10px">
          <el-input v-model="filterTxt" placeholder="请输入">
            <template v-slot:prefix>
              <i class="el-icon-search"></i>
            </template>
          </el-input>
        </el-form-item>
      </el-form>
      <div style="height:calc(100% - 50px);overflow:auto" class="box" v-loading="loading">
        <el-row>
          <el-col :span="24" class="result-item" style="line-height:1.9em">
            <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="checkeAllHandler">
              全选
            </el-checkbox>
          </el-col>
          <el-col :span="24" v-for="(item) in enumFilterData" :key="item.value" class="result-item" style="line-height:1.9em">
            <el-checkbox :label="item.value" :key="item.value" v-model="item.$checked">
              {{item.text}}
            </el-checkbox>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>
<script>
export default dsf.component({
  name: "DsfColumnDataFileter",
  ctrlCaption: "过滤",
  mixins: [$mixins.control],
  props: {
    list: {
      type: Object,
      default() {
        return {};
      }
    },
    type: {
      type: String,
      default: "string"
    },
    field: {
      type: String,
      default: ""
    },
    dataSource: {
      type: Object,
      default() {
        return {};
      }
    },
    currentFilter: {
      type: Object,
      default() {
        return null;
      }
    }
  },
  data() {
    return {
      operationOptions: [
        { text: "等于", value: "eq" },
        { text: "大于", value: "gt" },
        { text: "大于等于", value: "gteq" },
        { text: "小于", value: "lt" },
        { text: "小于等于", value: "lteq" },
        { text: "区间", value: "between" },
        { text: "介于", value: "gtlt" }
      ],
      enumData: [],
      loading: false,
      filterTxt: "",
      operation: "eq",
      data: [],
      reaultActive: -1,
      boxFoucs: false,
      values: [],
      checkAll: true,
      isIndeterminate: false
    };
  },
  computed: {
    enumFilterData() {
      let data = this.enumData;
      let text = this.filterTxt;
      if (text) {
        return _.filter(data, (it) => {
          return it.text ? it.text.indexOf(text) >= 0 : false;
        });
      }
      return data;
    }
  },
  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {
    if (this.type == "string") {
      this.operation = "like";
      if (this.currentFilter) {
        this.operation = this.currentFilter.operation;
        this.filterTxt = this.currentFilter.value;
      }
      if (this.filterTxt) {
        this.listQueryData(this.currentFilter);
      } else {
        this.listQueryData(this.currentFilter, this.list.currentPage, this.list.pageSize);
      }

      // this.setResultData(this.list.data || []);
      this.reaultActive = this.data.indexOf(this.filterTxt);
    } else if (this.type == "number") {
      this.operation = "eq";
      if (this.currentFilter) {
        this.operation = this.currentFilter.operation;
        if (dsf.type(this.currentFilter.value) == "array") {
          this.values = this.currentFilter.value;
        } else {
          this.values = [this.currentFilter.value, null];
        }
      }
    } else if (this.type == "ymd" || this.type == "ym") {
      this.operation = "eq";
      if (this.currentFilter) {
        this.operation = this.currentFilter.operation;
        if (dsf.type(this.currentFilter.value) == "array") {
          let vs = _.map(this.currentFilter.value, (it) => {
            let d = dsf.date.parse(it, "yyyy-mm-dd");
            if (this.type == "ym") {
              d = d.setDate(1);
            }
            return it ? d : null;
          });
          this.values = vs;
        } else {
          let d = dsf.date.parse(this.currentFilter.value, "yyyy-mm-dd");
          if (this.type == "ym") {
            d = d.setDate(1);
          }
          this.values = [d, d];
        }
      }
    } else if (this.type == "enum") {
      this.operation = "like";
      this.getDataSource();
      if (this.currentFilter) {
        this.operation = this.currentFilter.operation;
        this.filterTxt = this.currentFilter?.value?.text || "";
      }
    }
    this.dialogFocus();
  },
  methods: {
    textInputHandler: _.debounce(function () {
      this.operation = "like";
      this.reaultActive = -1;
      let field = this.field;
      let index = field.indexOf(".");
      if (index >= 0) {
        field = field.substr(field.indexOf(".") + 1);
      }
      if (this.filterTxt) {
        this.listQueryData([
          {
            field: field,
            operation: "like",
            value: this.filterTxt,
            dataType: "string",
            format: ""
          }
        ]);
      } else {
        this.listQueryData();
      }
    }, 300),
    listQueryData(filter, currentPage = 1, size = 100) {
      this.loading = true;
      let queryInfo = this.list.getQueryInfo();
      let params = {
        pageNum: currentPage,
        pageSize: size,
        query: JSON.stringify(queryInfo.query),
        order: JSON.stringify(queryInfo.order),
        namespace: this.list.$vm.nameSpace,
        pageName: this.list.$vm.pageName,
        filter: JSON.stringify(filter)
      };
      let url = "meta/list/data";
      dsf.mix(params, this?.list?.queryString || {});
      this.$http
        .get(url, params, true)
        .then(({ data }) => {
          let res = data;
          this.setResultData(res.data || [], this.filterTxt);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    setResultData(data = [], queryTxt) {
      let d = _.map(data || [], (it) => it[this.field]);
      d = _.uniq(d);
      d = _.filter(d || [], (it) => {
        it = dsf.isString(it) ? it : it ? it.toString() : "";
        if (queryTxt) {
          let queryTxtArr = queryTxt.split(" ");
          queryTxtArr = _.filter(queryTxtArr, (it) => it);
          if (queryTxtArr.length > 1) {
            queryTxtArr.unshift(queryTxt);
          }
          let index = _.findIndex(queryTxtArr, (q) => {
            return it.indexOf(q) >= 0;
          });
          return index >= 0;
        } else {
          return true;
        }
      });
      d = d.slice(0, 100);
      // d = _.map(d, (it) => {
      //   return it.replace(queryTxt, "<span class='high'>" + queryTxt + "</span>");
      // });
      this.data = d;
    },
    sureQuery(evt) {
      let item = this.$el.querySelector(".is-focus");
      if (item) {
        this.filterTxt = item.innerText;
        this.operation = "eq";
        this.$emit("dialog-yes", {
          field: this.field,
          operation: this.operation,
          value: this.filterTxt
        });
      } else {
        this.operation = "like";
        this.$emit("dialog-yes", {
          field: this.field,
          operation: "like",
          value: this.filterTxt
        });
      }
      evt.preventDefault();
    },
    keyDownHander(evt) {
      this.directionKeyDownHandler(evt.keyCode);
      if (evt.keyCode == "13") {
        this.sureQuery(evt);
        evt.preventDefault();
      }
    },
    directionKeyDownHandler(keyCode) {
      if (keyCode == 40) {
        //下
        this.reaultActive++;
        if (this.reaultActive >= this.data.length) {
          this.reaultActive = 0;
        }
      } else if (keyCode == 38) {
        //上
        this.reaultActive--;
        if (this.reaultActive < 0) {
          this.reaultActive = 0;
        }
      }
      this.$nextTick(() => {
        let item = this.$el.querySelector(".is-focus");
        if (item) {
          let box = this.$el.querySelector(".box");
          let rect = item.getBoundingClientRect();
          let boxrect = box.getBoundingClientRect();
          if (rect.bottom > boxrect.bottom) {
            let top = box.scrollTop;
            box.scrollTo(0, top + (rect.bottom - boxrect.bottom));
          } else if (rect.top < boxrect.top) {
            let top = box.scrollTop;
            box.scrollTo(0, top + (rect.top - boxrect.top));
          }
        }
      });
    },
    clickItem(item, index) {
      this.filterTxt = item;
      this.reaultActive = index;
      this.operation = "eq";
      this.dialogFocus();
    },
    highKeyword(text = "", keyword) {
      text = dsf.isDef(text) ? text.toString() : text;
      keyword = keyword.trim();
      if (!keyword) {
        return text;
      }
      let keywordArr = keyword.split(" ");
      keywordArr = _.filter(keywordArr, (it) => it);
      if (keywordArr.length > 1) {
        keywordArr.unshift(keyword);
      }
      let index = _.findIndex(keywordArr, (it) => {
        return text.indexOf(it) >= 0;
      });
      if (index >= 0) {
        return dsf.safe.xss((text || "").replace(keywordArr[index], "<span class='high'>" + keywordArr[index] + "</span>"));
      } else {
        return text;
      }
      // return dsf.safe.xss((text || "").replace(keyword, "<span class='high'>" + keyword + "</span>"));
    },
    dialogFocus() {
      let d = this.$el.querySelector(".filter-dialog-content");
      d && d.focus();
    },
    inputFocus() {
      this.boxFoucs = true;
    },
    inputBlur() {
      this.boxFoucs = false;
    },
    inputInput(index) {
      let reg = /^\.+|[^\d.]/g;
      if (dsf.isDef(index)) {
        this.$set(this.values, index, this.values[index].replace(reg, ""));
      } else {
        this.values = this.values.replace(reg, "");
      }
    },
    async getDataSource() {
      let list = [];
      if (this.dataSource.type == "static") {
        list = await this.dataSource.code;
      } else if (this.dataSource.type == "db") {
        await this.$http
          .get("meta/tree/dbSource?rmId=" + this.dataSource.code)
          .then(({ data }) => {
            list = data.data;
          })
          .catch(() => {});
      } else if (this.dataSource.type == "dict") {
        await this.$http
          .get("dict/getList/" + this.dataSource.code)
          .then(({ data }) => {
            list = data.data;
          })
          .catch(() => {});
      }
      this.enumData = list || [];
      _.each(this.enumData, (it) => {
        let value = this?.currentFilter?.value;
        value = dsf.isDef(value) ? (value || "").split(" ") : [];
        if (value && value.length > 0) {
          this.$set(it, "$checked", value.indexOf(it.value) >= 0);
        } else {
          this.$set(it, "$checked", true);
        }
      });
    },
    checkeAllHandler() {
      // this.checkAll=true;
      _.each(this.enumFilterData, (it) => {
        it.$checked = this.checkAll;
      });
    },
    yes() {
      if (this.type == "string") {
        if (this.filterTxt) {
          return {
            operation: this.operation,
            value: this.filterTxt,
            dataType: "string"
          };
        }
        return null;
      } else if (this.type == "number") {
        let vs = _.map(this.values, (it) => {
          return it ? it : null;
        });
        if (!vs[0] && !vs[1]) {
          return null;
        }
        if (this.operation != "between" && this.operation != "gtlt") {
          return {
            operation: this.operation,
            value: vs[0],
            dataType: "number"
          };
        } else {
          return {
            operation: this.operation,
            value: vs,
            dataType: "number"
          };
        }
      } else if (this.type == "ymd" || this.type == "ym") {
        let vs = _.map(this.values, (it) => {
          return it ? dsf.date.format(it, "yyyy-mm-dd") : null;
        });
        if (!vs[0] && !vs[1]) {
          return null;
        }
        if (this.type == "ym") {
          if (this.operation == "between" || this.operation == "gtlt") {
            if (vs[1]) {
              let d = dsf.date.parse(vs[1], "yyyy-mm-dd");
              let newDate = new Date(d.getFullYear(), d.getMonth() + 1, 0);
              vs[1] = dsf.date.format(newDate, "yyyy-mm-dd");
            }
          } else if (this.operation == "lteq" || this.operation == "gt") {
            if (vs[0]) {
              let d = dsf.date.parse(vs[0], "yyyy-mm-dd");
              let newDate = new Date(d.getFullYear(), d.getMonth() + 1, 0);
              vs[0] = dsf.date.format(newDate, "yyyy-mm-dd");
            }
          }
        }
        if (this.operation != "between" && this.operation != "gtlt") {
          return {
            operation: this.operation,
            value: vs[0],
            dataType: "datetime"
          };
        } else {
          return {
            operation: this.operation,
            value: vs,
            dataType: "datetime"
          };
        }
      } else if (this.type == "enum") {
        let values = _.filter(this.enumFilterData, (it) => {
          return it.$checked;
        });
        if (values.length <= 0 && !this.filterTxt) {
          return null;
        } else {
          return {
            operation: this.operation,
            value: _.map(values, (it) => it.value).join(" "),
            dataType: "enum"
          };
        }
      }
    }
  },
  beforeDestroy() {},
  destroyed() {},
  watch: {
    enumFilterData: {
      handler(v) {
        if (v) {
          let checkNum = _.filter(v, (it) => {
            return it.$checked;
          }).length;
          if (checkNum == v.length) {
            this.checkAll = true;
          } else {
            this.checkAll = false;
          }
          this.isIndeterminate = checkNum > 0 && checkNum < v.length;
        }
      },
      immediate: true,
      deep: true
    }
  }
});
</script>