<template>
  <div class="ds-control ds-section ds-no-padding" :class="getCss" style="height: 100%;">
    <iframe ref="iframe" :src="getPath()" width="100%" height="100%" frameborder="0">
    </iframe>
  </div>
</template>
<script>
export default dsf.component({
  name: "DsfIframe",
  ctrlCaption: "IFrame",
  mixins: [$mixins.control],
  data() {
    return {
      iframeUrl: ""
    };
  },
  props: {
    rmiframeUrl: {
      type: String,
      default: ""
    },
    $lazyLoad: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    getCss() {
      return [...this.$getClass(), this.showBorder ? "" : "ds-no-border", this.noPadding ? "" : "ds-no-padding"];
    }
  },
  watch: {
    // '$route.fullPath'() {
    //   let v = this.getPath();
    //   if (v != this.iframeUrl) {
    //     this.iframeUrl = v;
    //   }
    // }
  },
  created() {
    // if (!this._isRender && !this.isDesign) {
    //   if (!this.$lazyLoad) {
    //     this.iframeUrl = this.getPath();
    //   }
    // }
  },
  mounted() {
    // this.$refs.iframe.contentWindow.location.href=this.iframeUrl
    // this.$refs.iframe.setAttribute("src",this.iframeUrl);
    let iframeEl = this.$refs.iframe;
    if (iframeEl.attachEvent) {
      iframeEl.attachEvent("onload", () => {
        this.iframeLoaded(iframeEl);
      });
    } else {
      iframeEl.addEventListener("load", () => {
        this.iframeLoaded(iframeEl);
      });
    }
  },
  methods: {
    iframeLoaded(iframeel) {
      this.$dispatch("iframe-load", iframeel);
    },
    getPath() {
      if (!this.isDesign) {
        let v = this.$replace(this.rmiframeUrl);
        v = dsf.url.getWebPath(v);
        return this.analysis(v);
      }
      return "";
    },
    analysis(url) {
      var searchStr = url.substr(url.indexOf("?"));
      var oRegex = /[\?&](\w+)=([^&]+)?/gi;
      url = url.replace(oRegex, function (a, b, c, d) {
        var s = a.substr(0, 1);
        s += b + "=" + encodeURI(c || "");
        return s;
      });
      return url;
    }
  }
});
</script>
