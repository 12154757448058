<template>
  <div class="page-home-template1-menu">
    <home-menu-item
      v-for="item in menuList"
      :key="item.menu_id"
      :menu="item"
      :width="menuWidth"
      :active="item.menu_id === activeKey"
      :last-level-click="lastLevelClick"
      @click="linkTo(item)"
    ></home-menu-item>
  </div>
</template>

<script>
import HomeMenuItem from "./menu/homeMenuItem";

export default {
  name: 'DsfTemplate1HomeMenu',
  inject: ['homeRoot'],
  components: {
    HomeMenuItem
  },
  props: {
    // 是否只响应最后一级菜单的点击事件
    lastLevelClick: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {

    }
  },
  computed: {
    menuList() {
      return this.homeRoot.$menuList || [];
    },
    activeKey() {
      if (this.homeRoot.menuKey.length > 0) {
        return this.homeRoot.menuKey[0];
      }
      return '';
    },
    menuWidth() {
      return (100 / this.menuList.length).toFixed(3) + '%';
    }
  }
}
</script>