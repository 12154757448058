import DsfHome from './home';
import DsfPageHomeHeaderUser from './headerUser';
import DsfPageHomeHeaderUserInfoPanel from './headerUserInfoPanel';
import DsfPersonalization from './Personalization';
import homeDefault from './default';
import homeTemplate1 from './template1';
import homeGw from './gw';
import DsfMultiPageHome from './multiPage/home';
import DsfCollegeHome from './college/home';

export default {
  install (Vue) {
    Vue.component(DsfHome.name, DsfHome);
    Vue.component(DsfPageHomeHeaderUser.name, DsfPageHomeHeaderUser);
    Vue.component(DsfPageHomeHeaderUserInfoPanel.name, DsfPageHomeHeaderUserInfoPanel);
    Vue.component(DsfPersonalization.name, DsfPersonalization);
    Vue.use(homeDefault);
    Vue.use(homeTemplate1);
    Vue.use(homeGw);
    Vue.component(DsfMultiPageHome.name, DsfMultiPageHome);
    Vue.component(DsfCollegeHome.name, DsfCollegeHome);
  }
};