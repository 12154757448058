<template>
  <div class="ds-control ds-qrcode">
    <div class="ds-qrcode-box" :style="boxStyle" :border="border" :shadow="shadow">
      <el-image :src="qrSrc | imgFormat" @load="loadSuccess">
        <template v-slot:placeholder>
          <div class="ds-qrcode-box-error">加载中...</div>
        </template>
        <template v-slot:error>
          <div class="ds-qrcode-box-error">
            {{ loading ? '加载中...' : '加载失败' }}
          </div>
        </template>
      </el-image>
      <div class="ds-qrcode-box-message" :class="[qrStatus]">
        <i v-if="qrStatus === 'SUCCESS'" class="iconfont icon-gouxuan"></i>
        <i v-else-if="qrStatus === 'FAIL'" class="iconfont icon-shanchu1"></i>
        <span>{{ qrTips }}</span>
      </div>
    </div>
    <slot name="tips">
      <div v-show="tips" class="ds-qrcode-tips">{{ tips }}</div>
    </slot>
  </div>
</template>

<script>
export default dsf.component({
  name: "DsfQrcode",
  mixins: [$mixins.control],
  ctrlCaption: "二维码",
  props: {
    // 二维码来源
    source: {
      type: String,
      default: 'img'
    },
    // 图片地址
    imgSrc: {
      type: String,
      default: ''
    },
    // 接口地址
    sourceSrc: {
      type: String,
      default: ''
    },
    // 轮询地址
    querySrc: {
      type: String,
      default: ''
    },
    // 图片宽高
    size: {
      type: String,
      default: '220px'
    },
    // 提示语
    tips: {
      type: String,
      default: ''
    },
    // 空白边距
    padding: {
      type: String,
      default: '0px'
    },
    // 圆角
    radius: {
      type: String,
      default: '4px'
    },
    // 边框
    border: {
      type: Boolean,
      default: true
    },
    // 阴影
    shadow: {
      type: Boolean,
      default: true
    },
    // 轮询时间间隔(ms)
    queryTimes: {
      type: Number,
      default: 2000
    }
  },
  data() {
    return {
      loading: false,
      qrSrc: '',
      // 轮询状态 WAITING、SUCCESS、FAIL
      qrStatus: 'WAITING',
      // 轮询结果的提示
      qrTips: '支付成功'
    }
  },
  computed: {
    boxStyle() {
      return {
        'width': this.size,
        'height': this.size,
        'padding': this.padding,
        'border-radius': this.radius
      }
    }
  },
  created() {
    if (!this.isDesign) {
      if (this.source == 'img') {
        this.qrSrc = this.$replace(this.imgSrc, this);
      } else {
        this.doSource();
      }
    }
  },
  beforeDestroy() {
    clearTimeout(this.timer);
  },
  methods: {
    doSource() {
      if (!this.sourceSrc) {
        return;
      }
      this.loading = true;
      let src = this.$replace(this.sourceSrc, this);
      this.$http.get(src)
        .done(({ success, data, message }) => {
          if (success) {
            this.qrSrc = data.img;
            delete data.img;
            for (let k in data) {
              this[k] = data[k];
            }
          } else {
            dsf.layer.message(message || '请求异常', false);
          }
        }).error(err => {
          dsf.error(err);
          dsf.layer.message(err.message || '请求异常', false);
        }).always(() => {
          this.loading = false;
        })
    },
    loadSuccess() {
      if (!this.isDesign && this.querySrc) {
        this.doQuery();
      }
    },
    doQuery() {
      let src = this.$replace(this.querySrc, this);
      this.$http.get(src).done(({ success, data, message }) => {
        if (success) {
          this.qrStatus = data;
          this.qrTips = message;
          // 等待中
          if (data === 'WAITING') {
            this.timer = setTimeout(() => {
              this.doQuery();
            }, this.queryTimes);
          }
          // 扫码操作成功
          else if (data === 'SUCCESS') {
            this.$dispatch("success");
          }
          // 扫码操作失败
          else if (data === 'FAIL') {
            this.$dispatch("fail");
          }
        } else {
          dsf.layer.message(message, false);
        }
      }).error(err => {
        dsf.layer.message(err.message || '请求异常', false);
      });
    }
  }
});
</script>