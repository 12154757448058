<template>
  <el-popover
    v-if="show"
    v-model="showDropdown"
    popper-class="dsf-page-home-header-user-info-panel"
    placement="bottom"
    trigger="hover"
  >
    <template #reference>
      <slot name="reference"></slot>
    </template>
    <slot>
      <div class="content">
        <div class="userinfo">
          <dsf-page-home-header-user>
            <div class="basic">
              <span class="username">{{ dsf.cookies.get("user_name") }}</span>
              <span v-show="false" class="other-info">大数据中心</span>
            </div>
            <i
              class="to-editor iconfont icon-tianxie"
              @click="handleOpenUserInfoWindow"
            ></i>
          </dsf-page-home-header-user>
        </div>
        <div class="down-menu">
          <div class="down-menu-header">
            <div class="down-menu-bt">
              <i class="iconfont icon-nav-colour" style="color: #ff9900"></i>
              <span>主题</span>
              <div class="popover theme-radio-list" style="width: 160px">
                <template v-for="key in showThemes">
                  <div :key="key" class="theme-radio-item" :style="radioStyle(dsf.themeConfig.themesConfig[key], key)" @click="radioClick(key)"></div>
                </template>
              </div>
            </div>
            <div class="down-menu-bt">
              <i class="iconfont icon-icon_user_zt" style="color: #cc3399"></i>
              <span>字号</span>
              <div class="popover">
                <el-radio-group v-model="fontSizeVal">
                  <el-radio
                    v-for="(text, val) in fontSizeOptions"
                    :key="val"
                    :label="val"
                  >{{ text }}</el-radio
                  >
                </el-radio-group>
              </div>
            </div>
            <div
              class="down-menu-bt"
              v-if="hasDefaultHeader || hasDefaultAside"
            >
              <i class="iconfont icon-zhinan" style="color: #3399cc"></i>
              <span>导航栏</span>
              <div class="popover">
                <template v-if="hasDefaultHeader">
                  <div class="title">顶部</div>
                  <el-radio-group v-model="themeConfig.defaultHeaderMini">
                    <el-radio :label="false">宽条</el-radio>
                    <el-radio :label="true">窄条</el-radio>
                  </el-radio-group>
                </template>
                <template v-if="hasDefaultAside">
                  <div class="title">侧边</div>
                  <el-radio-group v-model="themeConfig.defaultAsideMini">
                    <el-radio :label="false">宽条</el-radio>
                    <el-radio :label="true">窄条</el-radio>
                  </el-radio-group>
                </template>
              </div>
            </div>
          </div>

          <div class="down-menu-main">
            <div
              v-for="menu in menuList"
              :key="menu.key"
              class="down-menu-bt"
              @click="menu._clickHandler && menu._clickHandler(menu)"
            >
              <span>{{ menu.name }}</span>
              <template v-if="menu.children">
                <i class="iconfont icon-icon_more"></i>
                <div class="popover">
                  <div
                    v-for="it in menu.children"
                    :key="it.key"
                    class="popover-menu-bt"
                    @click="it._clickHandler && it._clickHandler(it)"
                  >
                    <span>{{ it.groupName }}</span>
                    <template v-if="it.children.length">
                      <div class="popover popover-second">
                        <div
                          v-for="item in it.children"
                          :key="item.unitId"
                          class="popover-menu-bt"
                          @click="
                            item._clickHandler &&
                              item._clickHandler(it.groupId, item)
                          "
                        >
                          <span>{{ item.unitName }}</span>
                        </div>
                      </div>
                    </template>
                  </div>
                </div>
              </template>
            </div>
          </div>
          <div class="down-menu-footer">
            <div class="down-menu-bt" @click="dropdownClick(loginOutBt)">
              退出登录
            </div>
          </div>
        </div>
      </div>
    </slot>
  </el-popover>
</template>

<script>
import themeSetting from "./themeSetting";

export default {
  name: "DsfPageHomeHeaderUserInfoPanel",
  mixins: [themeSetting],
  props: {
    dropdownConfig: {
      type: Array,
      default() {
        return [
          { name: "切换组", key: "groupChange" },
          { name: "修改密码", key: "alterPwd" },
        ];
      },
    },
    changeUserInfo: {
      type: [Function,Boolean],
      default(){
        return false
      }
    }
  },
  data() {
    return {
      showDropdown: false,
      loginOutBt: null,
      groups: {},
    };
  },
  created() {
    this.handleGetGroups();
  },
  computed: {
    menuList() {
      let res = [];
      _.forEach(this.dropdownConfig, (it) => {
        it = _.clone(it);
        if (!it.handler && this[it.key]) {
          this[it.key]?.(it);
        } else {
          it._clickHandler = this.dropdownClick;
        }
        if (it.key !== "loginOut") {
          res.push(it);
        } else {
          this.loginOutBt = it;
        }
      });
      return res;
    },
  },
  methods: {
    dropdownClick(obj) {
      this.$parent.$emit("dropdown-click", obj);
    },

    groupChange(obj) {
      // console.log(obj, 111);
      // obj.children = [
      //   { key: "group1", name: "校内教师" },
      //   { key: "group2", name: "导师" },
      //   { key: "group3", name: "培训师" },
      // ];
    },
    // 切换组改变子项
    groupChangeItem(groupId, obj) {
      this.handleGetMenus(groupId, obj.unitGlobalId);
      // 把当前选中值传出去
      this.dropdownClick(obj);
    },
    alterPwd(obj) {
      obj._clickHandler = () => {
        dsf.layer.openDialog({
          title: "修改密码",
          width: "600px",
          height: "500px",
          params: {
            path: "pc/dsfa/platformhome/alterPassword",
          },
        });
      };
    },
    // 打开用户详情窗口
    handleOpenUserInfoWindow() {
      if(this.changeUserInfo && dsf.isFunction(this.changeUserInfo)){
        this.changeUserInfo();
      }else{
        // 获取信息编辑地址
        this.$http.get("/user/quickEdit").done((res) => {
          if (res.success) {
            this.$openDialog({
              title: "信息编辑",
              showClose: true,
              top: "5vh",
              height: "820px",
              width: "1230px",
              params: {
                path: res.data,
              },
            });
          } else {
            dsf.layer.message("获取地址失败", false);
          }
        });
      }
    },
    // 获取当前角色的所有分组
    handleGetGroups() {
      this.$http.get("/oua2/groupList").done((res) => {
        if (res.success) {
          // 添加事件类型
          this.groups.groupChange = res.data.map((item) => {
            item.children = item.unitInfoList;
            item.children.forEach((it) => {
              it._clickHandler = this.groupChangeItem;
            });
            return item;
          });
          this.menuList.forEach((item) => {
            if (item.key === "groupChange") {
              item.children = res.data;
            }
          });
        } else {
          dsf.layer.message("获取角色组失败", false);
        }
      });
    },
    // 根据切换组获取新菜单
    // ps: 直接刷新页面就好，后端通过获取菜单方法更新当前分组菜单
    handleGetMenus(groupId, unitGlobalId) {
      dsf.layer
        .confirm({
          message: `该操作会刷新当前页面，是否继续该操作？`,
          confirmButtonText: "是",
          cancelButtonText: "否",
        })
        .then(() => {
          let oldMeut = dsf.storage.session.get("homeMenu");
          dsf.storage.session.remove("homeMenu");
          this.$http
            .post("/oua2/switchGroup", { groupId, unitGlobalId })
            .done((res) => {
              if (res.success) {
                setTimeout(function () {
                  location.reload();
                }, 500);
              } else {
                dsf.storage.session.set("homeMenu", oldMeut);
                dsf.layer.message(res.message, false);
              }
            });
        });
    },
  },
};
</script>