var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ds-control ds-form-item ds-system", class: _vm.getCss },
    [
      _vm.simple && !_vm.isDesign
        ? [
            _vm._v(
              " " +
                _vm._s(_vm._f("proxy")(_vm.getValueString, _vm.formatter)) +
                " "
            ),
          ]
        : [
            _vm.showLabel
              ? _c(
                  "DsfFieldLabel",
                  {
                    style: _vm.getLabelWidthStyle(),
                    attrs: {
                      mode: _vm.showDataCaptionMode,
                      "show-icon": _vm.showDataCaptionIcon,
                      trigger: _vm.showDataCaptionTrigger,
                      "data-caption": _vm.dataCapion,
                      "is-design": _vm.isDesign,
                      owner: _vm._self,
                    },
                  },
                  [_vm._v(" " + _vm._s(_vm.$t(_vm.label)) + " ")]
                )
              : _vm._e(),
            _c(
              "div",
              {
                staticClass: "ds-form-block",
                style: _vm.getFormItemBlockMarginLeft(),
              },
              [
                _vm.isDesign
                  ? [_vm._v(" [修改时间] ")]
                  : [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm._f("proxy")(_vm.getValueString, _vm.formatter)
                          ) +
                          " "
                      ),
                    ],
              ],
              2
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }