<template>
  <div class="des-custom-order">
    <div class="left">
      <template v-for="(item, index) in customOrder">
        <div :key="index" class="des-custom-order-item" @click="itemClick(item)">
          <span>{{ item.fieldCaption }}</span>
          <i :class="['sort-bar', item.sortType]"></i>
        </div>
      </template>
    </div>
    <div class="right">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default dsf.component({
  name: "DsfCustomOrder",
  ctrlCaption: "自定排序",
  mixins: [$mixins.control],
  inject: {
    $list: {
      default: null
    }
  },
  props: {
    customOrder: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  methods: {
    itemClick(item) {
      if (this.$list.isDesign) return;
      let type;
      if (item.sortType === '') {
        type = 'desc';
      } else if (item.sortType === 'desc') {
        type = 'asc';
      } else {
        type = '';
      }
      this.$set(item, 'sortType', type);
      this.$list.loadData();
    }
  }
});
</script>