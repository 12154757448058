<!--
 * @Editor: chenqy
 * @Description: 外壳 --- 学生端
 * @Date: 2021-10-15 15:05:19
 * @LastEditors: Chenqy
 * @LastEditTime: 2021-12-24 14:31:16
-->
<template comments>
  <div class="dsf-sync-box">
    <!--hidden-->
    <div class="dsf-sync-header">
      <div class="dsf-sync-header-content" ref="header">
        <div class="dsf-sync-header-right">
          <operation-nav
            v-show="isSperker"
            :isTeacher="false"
            :isSperker="isSperker"
            :fileViewObj="fileView"
            ref="fileComponent"
          ></operation-nav>
          <div class="dsf-sync-header-student" v-show="!isSperker">
            <div class="operation-item"  @click="handleBeMain">
              <div class="operation-item-icon">
                <i class="icon iconfont icon-sidebar-speaker"></i>
              </div>
              <div class="operation-item-title">申请主讲</div>
            </div>
            <div class="operation-item">
              <div class="operation-item-icon">
                <i class="icon iconfont icon-nav-exit"></i>
              </div>
              <div class="operation-item-title">退出同屏</div>
            </div>
          </div>
        </div>
      </div>
      <div class="dsf-full-drop" @click="handleDropTool"><div class="icon-box" ref="dropIcon"><i class="icon iconfont icon-arrow-down" ref="dropIcon"></i></div></div>
    </div>
    <div class="dsf-sync-body">
      <div class="dsf-sync-right">
        <div class="dsf-sync-content">
          <iframe
            :src="syncParam.url"
            width="1694"
            height="847"
            frameborder="0"
            id="syncScreen"
          ></iframe>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import operationNav from "./operationNav.vue";
import FileReview from "./createPreFile.js";
import voiceMixin from "./voiceMixin.js";
import ratioMixin from "./ratioMixin.js";
export default {
  name: "DsfSyncScreenStudent",
  components: { operationNav },
  mixins: [voiceMixin, ratioMixin],
  computed: {
    isHeaderSlots() {
      return !!this.$slots.header;
    },
  },
  created() {
    let { roomId, url, personId } = this.$route.query;
    this.syncParam.roomId = roomId;
    this.syncParam.url = url;
    this.syncParam.personId = personId || "111";
    // this.socket.$on("/syncscreen/ws/voice", this.syncParam, function (re) {
    //   console.log(re)
    // });
  },
  mounted() {
    let that = this;
    this.socket = dsf.ws();
    this.$nextTick(() => {
      // 获取用户列表
      that.socket.$on(
        "/syncscreen/ws/personList",
        that.syncParam,
        function (re) {
          if (dsf.type(re.success) === "boolean" && !re.success) {
            dsf.layer.message(re.message, false);
          } else if (re.data) {
            let isSperker = false;
            re.data.forEach((element) => {
              if (
                element.status.value == 3 &&
                element.personId == that.syncParam.personId
              ) {
                isSperker = true;
              }
              if (element.organizer) {
                that.managerId = element.personId;
              }
            });
            that.isSperker = isSperker;
          }
        }
      );
    });
  },
  data() {
    return {
      socket: null,
      syncParam: {
        endurance: false, // 是否记录视频
      },
      iframeStatus: "unload",
      player: null,
      parentDom: null, // iframe对象
      isSperker: false, // 当前用户是否为主讲人
      fileView: null,
      managerId: "", // 管理员ID
    };
  },
  watch: {
    isSperker: {
      handler: function (value) {
        this.$nextTick(() => {
          if (value) {
            this.handleSyncSocket("startSync");
            this.handleStartVoice()
          } else {
            this.handleSyncSocket("closeRecord");
            this.handleEndVoice()
          }
        });
      },
      immediate: true,
    },
  },
  methods: {
    /**
     *  开启同屏推送
     *  {string} status 同屏状态 [startSync, reviewSync, closeRecord, authTranslate]
     */
    handleSyncSocket(status, option = {}) {
      // 发送被同屏页面连接socket必要参数
      let parentDom = document.querySelector("#syncScreen");
      this.parentDom = parentDom;
      let that = this;
      let params = Object.assign({}, this.syncParam, option);
      if (parentDom instanceof HTMLIFrameElement) {
        if (this.iframeStatus === "unload") {
          parentDom.onload = function () {
            that.iframeStatus = "completed";
            // 添加文件预览组件
            that.fileView = new FileReview("file", {
              context: parentDom.contentWindow,
            });
            // 添加画板功能菜单状态观察器
            that.handleWatchFileComponent();
            // 添加播放器
            let width = parentDom.width;
            let height = parentDom.height;
            let player = document.createElement("div");
            player.style.minWidth = "100%";
            player.style.minHeight = "100%";
            player.style.width = `${width}px`;
            player.style.height = `${height}px`;
            player.id = "teacher-content";
            player.style.position = "absolute";
            player.style.top = "0";
            player.style.left = "0";
            player.style.zIndex = "-1";
            player.style.background = "#000";
            player.style.opacity = "0";
            player.style.overflow = "hidden";
            let playerContent = document.createElement("div");
            playerContent.style.width = "100%";
            playerContent.style.height = "100%";
            playerContent.id = "teacher-player";
            player.appendChild(playerContent);
            parentDom.contentWindow.document.documentElement.appendChild(player);
            parentDom.contentWindow.postMessage({
              type: status,
              params: params,
            });
            // 用户状态变更
            that.socket.$on(
              "/syncscreen/ws/personStatus",
              that.syncParam,
              function (data) {
                if(data.data.data && data.data.data.type === 'translate') {
                  that.isSperker = false;
                  // 文件展示列表参数
                  let {
                    total,
                    currentPage,
                    sourceList,
                    sourceType,
                    isShowFilePreview,
                  } = that.fileView;
                  let fileViewParam = {
                    total,
                    currentPage,
                    sourceList,
                    sourceType,
                    isShowFilePreview,
                  };
                  let paramss = {
                    needFrame: true,
                    fileViewParam,
                    pushPersonIds: [data.data.data.sendPersonId],
                    data: [{status: 3, personId: data.data.data.sendPersonId}]
                  };
                  that.handleSyncSocket("authTranslate", paramss);
                } else if (data?.data?.persons) {
                  let thisIsSperker = false; // 当前用户是否为主讲人
                  let hasSperker = false; // 状态变更用户是否含有主讲人
                  let datas = data.data.persons;
                  let joinOnline = false; // 新加入人员是否含有在线人员
                  for (let i = 0; i < datas.length; i++) {
                    let element = datas[i];
                    if (element.status.value == "3") {
                      hasSperker = true;
                      if (element.personId === that.syncParam.personId) {
                        thisIsSperker = true;
                      }
                    } else if (element.status.value == "1") {
                      joinOnline = true;
                    } else if (
                      that.isSperker &&
                      (element.status.value == "5" || element.status.value == "2") &&
                      element.personId === that.syncParam.personId
                    ) {
                      hasSperker = true;
                      thisIsSperker = false;
                    }
                  }
                  if (thisIsSperker) {
                    let tdata = data.data.data;
                    that.isSperker = true;
                    if (tdata.dataUrl && Object.keys(tdata.dataUrl).length) {
                      window.canvasDraw.utilsRestoreCanvas(tdata.dataUrl);
                    }
                    if (tdata.sourceType) {
                      let render = async function () {
                        let {
                          currentPage,
                          sourceList,
                          sourceType,
                          scrollTop,
                          timeLine,
                        } = tdata;
                        await that.fileView.renderContent(
                          sourceList,
                          tdata.dataFile,
                          {
                            type: sourceType,
                            currentPage,
                            scrollTop,
                            timeLine,
                          }
                        );
                        // 赋于画板操作
                        await that.$refs.fileComponent.handleFileView(false, sourceType, currentPage - 1);
                        if (tdata.dataFile && Object.keys(tdata.dataFile).length) {
                          window.fileCanvasDraw.utilsRestoreCanvas(
                            tdata.dataFile,
                            currentPage - 1
                          );
                        }
                      };
                      render();
                    }
                  } 
                  // else if (hasSperker && !thisIsSperker) {
                  //   that.isSperker = false;
                  //   // 文件展示列表参数
                  //   let {
                  //     total,
                  //     currentPage,
                  //     sourceList,
                  //     sourceType,
                  //     isShowFilePreview,
                  //   } = that.fileView;
                  //   let fileViewParam = {
                  //     total,
                  //     currentPage,
                  //     sourceList,
                  //     sourceType,
                  //     isShowFilePreview,
                  //   };
                  //   let paramss = {
                  //     fileViewParam,
                  //     pushPersonIds: [that.managerId],
                  //     data: params,
                  //   };
                  //   that.handleSyncSocket("authTranslate", paramss);
                  // }
                  // 当新加入人员为在线且当前用户为主讲人且本次推送没有主讲人 => 重新发送页面数据
                  if (joinOnline && that.isSperker && !hasSperker) {
                    that.handleSyncSocket("reviewSync");
                  }
                }
              }
            );
          };
        } else {
          parentDom.contentWindow.postMessage({
            type: status,
            params: params,
          });
        }
      }
    },
    /**
     * 人员状态变更
     * {String} personId 状态变更ID
     * {Boolean}} isCancel 是否为取消主讲
     */
    handleChangeStatus(personId, isCancel) {
      this.handleSyncSocket("authTranslate", paramss);
    },
    // 申请主讲/退出主讲
    handleBeMain(type) {
      let that = this;
      if (type === "esc") {
        this.isSperker = false;
        // 文件展示列表参数
        let {
          total,
          currentPage,
          sourceList,
          sourceType,
          isShowFilePreview,
          scrollTop,
          timeLine,
        } = this.fileView;
        let fileViewParam = {
          total,
          currentPage,
          sourceList,
          sourceType,
          isShowFilePreview,
          scrollTop,
          timeLine,
        };
        let paramss = {
          // fileViewParam,
          pushPersonIds: [this.managerId],
          needFrame: true,
          data: [
            { personId: this.managerId, status: 3 },
            { personId: this.syncParam.personId, status: 1 },
          ],
        };
        if(isShowFilePreview) {
          paramss.fileViewParam = fileViewParam
        }
        this.handleSyncSocket("authTranslate", paramss);
      } else {
        this.socket.$send("syncscreen/ws/personStatus", {
          data: [],
          pushPersonIds: [that.managerId],
          persons: [
            {
              personId: that.syncParam.personId,
              status: 4,
            },
          ],
        });
      }
    },
    // 监听文件展示组件状态
    handleWatchFileComponent() {
      let that = this;
      let frameWindow = document.getElementById("syncScreen").contentWindow;
      const config = { attributes: true }; // 监听属性变化
      let targetDom = frameWindow.document.querySelector(".dsf-pre");
      // 创建观察器
      const observer = new MutationObserver(function (mutationsList) {
        let target = mutationsList[0].target
        let opacity = getComputedStyle(target).opacity
        if(opacity == 0) {
          that.$refs.fileComponent.handleChangeMenuStatus();
        }
      });
      observer.observe(targetDom, config);
    },
    // 全屏状态下拉出工具条
    handleDropTool() {
      let height = this.$refs.header.clientHeight;
      if(height) {
        this.$refs.header.style.height = "0"
        this.$refs.dropIcon.style.transform = 'rotate(0deg)'
      } else {
        this.$refs.header.style.height = "66px"
        this.$refs.dropIcon.style.transform = 'rotate(180deg)'
      }
    }
  },
};
</script>
