export default {
  install(Vue){
    Vue.filter('proxy',function(v){
      if (dsf.isDef(v)) {
        let args = Array.prototype.slice.call(arguments, 0);
        if (args[1]) {
          let arr = args[1].split("^");
          if (Vue.filter(arr[0])) {
            // if (arr[0].startsWith('html')) {
            //   return ""
            // }
            if (v.edate && v.sdate) {
              return [
                Vue.filter(arr[0]).apply(this, [v.sdate, ...arr.slice(1)]),
                Vue.filter(arr[0]).apply(this, [v.edate, ...arr.slice(1)])
              ].join(" 至 ");
            }
            let format = Vue.filter(arr[0]).apply(this, [v, ...arr.slice(1)]);
            return format;
          } else {
            return v;
          }
        } else {
          return v;
        }
      } else {
        return ""
      }
    })
  }
}
