var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.isDesign || _vm.visible,
          expression: "isDesign || visible",
        },
      ],
      staticClass: "ds-control ds-no-padding",
      style: _vm.controlStyle,
    },
    [
      _c(
        "div",
        {
          class:
            "ds-home-panel " +
            (_vm.styleType ? "ds-home-panel-" + _vm.styleType : ""),
          style: _vm.panelStyle,
          attrs: { "is-design": _vm.isDesign, showShadow: _vm.showShadow },
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showHead,
                  expression: "showHead",
                },
              ],
              staticClass: "ds-home-panel-header",
            },
            [
              _c(
                "div",
                { staticClass: "ds-home-panel-header-left" },
                [
                  _vm._t("header-title", function () {
                    return [
                      _vm.titleIcon
                        ? _c(
                            "div",
                            { staticClass: "ds-home-panel-icon" },
                            [_c("DsfIcon", { attrs: { name: _vm.titleIcon } })],
                            1
                          )
                        : _vm._e(),
                      _vm.title
                        ? _c("div", { staticClass: "ds-home-panel-title" }, [
                            _vm._v(_vm._s(_vm.title)),
                          ])
                        : _vm._e(),
                    ]
                  }),
                  _vm._t("header-left"),
                ],
                2
              ),
              _c(
                "div",
                { staticClass: "ds-home-panel-header-center" },
                [_vm._t("header-center")],
                2
              ),
              _c(
                "div",
                { staticClass: "ds-home-panel-header-right" },
                [
                  _vm._t("header-right"),
                  _vm.addShow
                    ? _c(
                        "div",
                        {
                          staticClass: "ds-home-panel-more",
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.panelIconBtnClick("add")
                            },
                          },
                        },
                        [
                          _vm.addText
                            ? [_vm._v(_vm._s(_vm.addText))]
                            : _vm._e(),
                          _vm.addIcon
                            ? _c("DsfIcon", { attrs: { name: _vm.addIcon } })
                            : _vm._e(),
                        ],
                        2
                      )
                    : _vm._e(),
                  _vm.moreShow
                    ? _c(
                        "div",
                        {
                          staticClass: "ds-home-panel-more",
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.panelIconBtnClick("more")
                            },
                          },
                        },
                        [
                          _vm.moreText
                            ? [_vm._v(_vm._s(_vm.moreText))]
                            : _vm._e(),
                          _vm.moreIcon
                            ? _c("DsfIcon", { attrs: { name: _vm.moreIcon } })
                            : _vm._e(),
                        ],
                        2
                      )
                    : _vm._e(),
                  _vm.settingShow
                    ? _c(
                        "div",
                        {
                          staticClass: "ds-home-panel-more",
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.panelIconBtnClick("setting")
                            },
                          },
                        },
                        [
                          _vm.settingText
                            ? [_vm._v(_vm._s(_vm.settingText))]
                            : _vm._e(),
                          _vm.settingIcon
                            ? _c("DsfIcon", {
                                attrs: { name: _vm.settingIcon },
                              })
                            : _vm._e(),
                        ],
                        2
                      )
                    : _vm._e(),
                ],
                2
              ),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "ds-home-panel-content",
              style: { padding: _vm.padding.join("px ") + "px" },
              attrs: { "slot-name": "default" },
            },
            [
              _vm.isDesign
                ? [_vm._t("default")]
                : [
                    _c(
                      "DsfVirtualScroll",
                      { attrs: { isDesign: _vm.isDesign } },
                      [_vm._t("default")],
                      2
                    ),
                  ],
            ],
            2
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }