var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "ds-control ds-form-item ds-datachoice_tag ds-no-padding",
      class: _vm.getCss,
    },
    [
      _c(
        "DsfDataChoice",
        _vm._b(
          {
            ref: "dataChoice",
            attrs: { "is-design": _vm.isDesign },
            model: {
              value: _vm.dataChoiceValue,
              callback: function ($$v) {
                _vm.dataChoiceValue = $$v
              },
              expression: "dataChoiceValue",
            },
          },
          "DsfDataChoice",
          _vm.dataChoiceProps,
          false
        )
      ),
      _c("div", { staticClass: "ds-datachoice_tag_auto ds-control" }, [
        _vm.tagCtrlShowLabel
          ? _c("label", { staticClass: "ds-form-label" }, [
              _vm._v(" " + _vm._s(_vm.tagCtrlLabel) + " "),
            ])
          : _vm._e(),
        _c(
          "div",
          { staticClass: "ds-form-block" },
          _vm._l(_vm.autoTags, function (item) {
            return _c(
              "DsfButton",
              {
                key: item.value,
                attrs: { type: "plain", size: "small", title: item.text },
              },
              [_vm._v(" " + _vm._s(item.text) + " ")]
            )
          }),
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }