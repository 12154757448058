export default {
  install(Vue) {
    // 下拉级联的值是多级串联，某些情况只显示最后一级
    Vue.filter("textValueByLast", (v, textOrValue, s) => {
      if (v) {
        if (dsf.type(v) == "object") {
          return v[textOrValue].split(s).pop();
        } else if (dsf.type(v) == "array") {
          return _.map(v, (v2) => {
            return v2[textOrValue].split(s).pop();
          }).join(",");
        }
        return v;
      } else {
        return "";
      }
    });
  }
};
