<template>
  <div class="ds-control ds-form-item ds-textbox" :class="getCss">
    <template v-if="simple&&readOnly&&!isDesign">
      <DsfTextProxy v-model="value" v-bind="_self.$props"></DsfTextProxy>
      <span v-if="value != ''">{{ append }}</span>
      <slot name="error">
        <div v-if="errors.length>0" class="ds-error-text">{{ errorsMsg }}</div>
      </slot>
    </template>
    <template v-else>
      <DsfFieldLabel v-if="showLabel" :mode="showDataCaptionMode" :style="getLabelWidthStyle()" :show-icon="showDataCaptionIcon" :trigger="showDataCaptionTrigger" :data-caption="dataCapion" :is-design="isDesign" :owner="_self">
        {{$t(label)}}
      </DsfFieldLabel>
      <!-- <label v-if="showLabel" class="ds-form-label" :style="getLabelWidthStyle()">
        <i v-if="dataCapion" class="iconfont icon-bangzhuzhongxin ds-control-helper" @click="showHelper"></i>
      </label> -->
      <div class="ds-form-block" :style="getFormItemBlockMarginLeft()">
        <el-input
          v-if="!readOnly"
          @keyup.native="keyupHandler($event)"
          v-model="inputValue"
          :class="{'ds-error-border':errors.length>0}"
          :placeholder="$t(placeholder)"
          :type="inputType"
          :disabled="disabled"
          @change="inputChangeHandler"
          @blur="inputBlurHandler">
          <template v-slot:prefix>
            <slot name="prefix"></slot>
          </template>
          <template v-slot:suffix>
            <slot name="suffix"></slot>
          </template>
          <template v-slot:prepend>
            <slot name="prepend"></slot>
          </template>
          <template v-slot:append>
            <slot name="append"><template v-if="append">{{ $t(append) }}</template></slot>
          </template>
        </el-input>
        <!--只读状态只显示div-->
        <div v-else-if="readOnly" class="ds-form-readonly">
          <DsfTextProxy v-model="value" v-bind="_self.$props" :show-label="false"></DsfTextProxy>
          <span v-if="value != ''">{{ append }}</span>
        </div>
        <slot name="error">
          <div v-if="errors.length>0" class="ds-error-text">{{ errorsMsg }}</div>
        </slot>
      </div>
    </template>
  </div>
</template>
<script>
import textFilterRule from "./textFilterRule";
export default dsf.component({
  name: "DsfTextbox",
  mixins: [$mixins.formControl, textFilterRule],
  ctrlCaption: "文本框",
  props: {
    simple: {
      type: Boolean,
      default: false
    },
    ishideCon: {
      type: Boolean,
      default: false
    },
    openInputGrade: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: "请输入"
    },
    metadata: {
      type: Object,
      default() {
        return dsf.metadata.get("text-meta-data");
      }
    },
    append: {
      type: String,
      default: ""
    },
    //只允许数字键
    onlyAllowNumberKey: {
      type: Boolean,
      default: false
    }

    // //组件在列表中变化的属性
    // gridRowChangeKey: {
    //   type: String,
    //   default: "change"
    // }
  },
  computed: {
    inputType() {
      return this.ishideCon ? "password" : "text";
    },
    inputValue: {
      get() {
        return this.value;
      },
      set(v) {
        this.emitValueChange(v);
      }
    }
  },
  methods: {
    keyupHandler(evt) {
      if (this.onlyAllowNumberKey) {
        var value = this.value.replace(/[^\d\.]/g, "");
        this.$emitValueChange(value);
        this.$dispatch("keyup", value);
      }
    },
    inputChangeHandler() {
      let value = this.filterFormat(this.inputValue);
      this.inputValue = value;
      this.$nextTick(() => {
        this.emitFormValidate(value);
        this.$dispatch("change", value);
      });
    },
    inputBlurHandler() {
      this.$dispatch("blur");
    }
  }
});
</script>
