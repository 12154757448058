var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-home-default-header", class: { mini: _vm.mini } },
    [
      _c(
        "div",
        { ref: "logoBox", staticClass: "logo-box" },
        [
          _vm._t("logo", function () {
            return [
              _c("a", { attrs: { href: _vm.indexUrl } }, [
                _vm.logo
                  ? _c("img", {
                      ref: "logo",
                      staticClass: "logo",
                      attrs: { src: _vm._f("imgFormat")(_vm.$logo), alt: "" },
                      on: {
                        error: function ($event) {
                          _vm.logoLoadSuccess = true
                        },
                        load: _vm.logoLoaded,
                      },
                    })
                  : _vm._e(),
                _vm.title && _vm.title.length
                  ? _c("div", { staticClass: "site-name" }, [
                      _vm._v(_vm._s(_vm.title)),
                    ])
                  : _vm._e(),
              ]),
            ]
          }),
        ],
        2
      ),
      _c(
        "div",
        { staticClass: "header-right" },
        [
          !_vm.noMenu
            ? _c(
                "DsfDefaultHomeMenuBar",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showMenu,
                      expression: "showMenu",
                    },
                  ],
                  attrs: {
                    mini: _vm.mini,
                    "menu-list": _vm.menuList,
                    "active-key": _vm.activeKey,
                    width: _vm.menuWidth,
                  },
                },
                [_vm._t("default")],
                2
              )
            : _vm._e(),
          _c(
            "div",
            {
              ref: "headerUser",
              staticStyle: { flex: "none", height: "100%" },
            },
            [
              _vm._t("home-menu-right", function () {
                return [
                  _c(
                    "div",
                    { staticClass: "header-right-user" },
                    [
                      !_vm.dropdownConfig.length
                        ? _c(
                            "div",
                            [
                              _c("dsf-page-home-header-user", [
                                _c("div", { staticClass: "username" }, [
                                  _vm._v(
                                    " 欢迎您," +
                                      _vm._s(_vm.dsf.cookies.get("user_name")) +
                                      " "
                                  ),
                                  _vm.dropdownConfig.length
                                    ? _c("i", {
                                        staticClass: "iconfont",
                                        class: [
                                          _vm.showDropdown
                                            ? "icon-shangjiantou"
                                            : "icon-xiajiantou",
                                        ],
                                      })
                                    : _vm._e(),
                                ]),
                              ]),
                            ],
                            1
                          )
                        : _c(
                            "dsf-page-home-header-user-info-panel",
                            {
                              attrs: {
                                "dropdown-config": _vm.dropdownConfig,
                                "change-user-info": _vm.changeUserInfo,
                              },
                            },
                            [
                              _c(
                                "dsf-page-home-header-user",
                                {
                                  attrs: { slot: "reference" },
                                  slot: "reference",
                                },
                                [
                                  _c("div", { staticClass: "username" }, [
                                    _vm._v(
                                      " 欢迎您," +
                                        _vm._s(
                                          _vm.dsf.cookies.get("user_name")
                                        ) +
                                        " "
                                    ),
                                    _vm.dropdownConfig.length
                                      ? _c("i", {
                                          staticClass: "iconfont",
                                          class: [
                                            _vm.showDropdown
                                              ? "icon-shangjiantou"
                                              : "icon-xiajiantou",
                                          ],
                                        })
                                      : _vm._e(),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                    ],
                    1
                  ),
                ]
              }),
            ],
            2
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }