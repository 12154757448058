<template>
  <div class="ds-control ds-section ds-no-padding" :class="getCss" :style="getStyle">
    <div class="ds-section-bg-wrap" :style="wrapStyle">
      <div class="ds-section-bg" :style="bgStyle"></div>
      <div class="ds-section-fg" :style="fgStyle"></div>
    </div>
    <div class="ds-section-main" :style="$margin">
      <div slot-name="default" class="ds-section-content" :style="contentStyle">
        <slot name="default"></slot>
      </div>
    </div>
  </div>
</template>
<script>
export default dsf.component({
  name: "DsfSection",
  ctrlCaption: "页面段落",
  mixins: [$mixins.layout],
  data() {
    return {
      sizeLevel: {
        small: 1,
        medium: 5,
        big: 8
      },
      fixedStyle: {},
      minHeightReal: null
    };
  },
  props: {
    isBlockControl: {
      type: Boolean,
      default: true
    },
    slots: {
      type: Array,
      default() {
        return [{ name: "default", controls: [] }];
      }
    },
    shadow: {
      type: Boolean,
      default: false
    },
    showModel: {
      type: String,
      default: "none"
    },
    contentOverflow: {
      type: String,
      default: "visible"
    },
    mainWidth: {
      type: String,
      default: "100%"
    },
    minHeight: {
      type: String,
      default: ""
    },
    margin: {
      type: Array,
      default() {
        return [0, 0, 0, 0];
      }
    },
    padding: {
      type: Array,
      default() {
        return [10, 10, 10, 10];
      }
    },
    foregroundConfig: {
      type: Object,
      default: null
    },
    fixed: {
      type: Boolean,
      default: false
    },

    /*过时废弃属性 start------------*/
    marginTop: {
      type: Boolean,
      default: false
    },
    marginBottom: {
      type: Boolean,
      default: false
    },
    marginLeft: {
      type: Boolean,
      default: false
    },
    marginRight: {
      type: Boolean,
      default: false
    },
    paddingTop: {
      type: Boolean,
      default: true
    },
    paddingBottom: {
      type: Boolean,
      default: true
    },
    paddingLeft: {
      type: Boolean,
      default: true
    },
    paddingRight: {
      type: Boolean,
      default: true
    },

    marginArr: {
      type: Array,
      default() {
        return ["0px", "0px", "0px", "0px"];
      }
    },
    paddingArr: {
      type: Array,
      default() {
        return ["0px", "0", "0px", "0"];
      }
    },
    marginSize: {
      type: String,
      default: "small"
    }
    /*过时废弃属性 end------------*/
  },

  computed: {
    getStyle() {
      let style = {
        ...this.fixedStyle
      };
      if (this.minHeight && !this.isFull) {
        style["min-height"] = this.unitSize(this.minHeight);
      }
      return style;
    },
    getCss() {
      return [...this.$getClass(), this.shadow ? "shadow" : "", this.isFull ? "full" : ""];
    },
    isFull() {
      return this.showModel == "full";
    },
    $margin() {
      let style = {
        padding: this.unitSize(this.margin).join(" ")
      };
      return style;
    },
    wrapStyle() {
      let style = {
        margin: this.unitSize(this.margin).join(" ")
      };
      return style;
    },
    bgStyle() {
      let style = {
        ...this.getBackgroundStyle
      };
      return style;
    },
    fgStyle() {
      let style = {
        ...this.$colorFormat(this.foregroundConfig),
        width: this.mainWidth,
        "margin-left": "auto",
        "margin-right": "auto"
      };
      return style;
    },
    contentStyle() {
      let paddings = this.padding;
      paddings = [
        dsf.isNumber(this.padding[0]) ? this.padding[0] + "px" : this.padding[0],
        dsf.isNumber(this.padding[1]) ? this.padding[1] + "px" : this.padding[1],
        dsf.isNumber(this.padding[2]) ? this.padding[2] + "px" : this.padding[2],
        dsf.isNumber(this.padding[3]) ? this.padding[3] + "px" : this.padding[3]
      ];
      let style = {
        width: this.mainWidth,
        "margin-left": "auto",
        "margin-right": "auto",
        padding: paddings.join(" ")
      };
      if (this.isFull) {
        style["overflow-y"] = this.contentOverflow;
      }
      return style;
    }
  },
  updated() {
    if (this.isDesign) {
      this.$dispatch("design-height-change", this.isFull ? "100%" : "auto");
    }
  },
  created() {

  },
  mounted() {
    if (!this.isDesign && this.$vm) {
      this.$vm.$on("page-resize", this.resize);
    }
  },
  methods: {
    resize() {
      if (this.fixed && !this.isDesign) {
        this.fixedStyle = {};
        let style = {};
        if (this.$el && this.$el.nodeType == 1) {
          let pel = this.$el.parentNode;
          style.position = "fixed";
          style.width = $(pel).width() + "px";
          if (this.showModel == "full") {
            style.height = $(pel).height() + "px";
          } else {
            style.height = "auto";
          }
        }
        this.fixedStyle = style;
      } else {
        this.fixedStyle = {};
      }
    }
  }
});
</script>