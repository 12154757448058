/*
 * @Editor: chenqy
 * @Description: 文件预览组件
 * @Date: 2021-10-18 15:54:53
 * @LastEditors: Chenqy
 * @LastEditTime: 2022-01-14 16:24:27
 */
import { draggable } from "./draggble";
let isMobile = /Android|webOS|iPhone|iPod|BlackBerry/i.test(
  navigator.userAgent
);
let clickEvent = "click";
let dbclickEvent = "dblclick"; // 模拟双击
class FilePre {
  FilePreFrame = null; // 整体外壳
  total = 1; // 总页数
  currentPage = 1; // 当前页数
  drawCanvas = []; // 画板
  sourceList = []; // 资源列表
  sourceType = ""; // 资源展示类型
  isShowFilePreview = false; // 是否展示文件预览框
  scrollTop = 0; // 展示内容框滚动条位置
  parentStyle = {}; //全屏之前外层iframe样式
  showMode = "normal"; // 文件展示模式 normal  full
  autoTimer = null; // 自动播放定时器
  isMobile = isMobile;
  socket = dsf.ws();
  iconPaths = [
    ["left", "Right-alignment", "quanping", "yunhang", "icon-close", "right"],
    [],
  ]; // 图标路径 index = 0 -> 相对, 1 -> 绝对
  destoryListenEvent = {
    full: [], // 全屏模式监听事件
    init: [], // 初始化事件
  }; // 待被销毁监听事件
  //width:100%;height:calc(100% - 52px)
  styleTemplate = `video::-webkit-media-controls-start-playback-button {
    display:none;
  }.dsf-pre{position:absolute;top:0;left:0;width:100%;z-index:-1;opacity:0;height:100%;background:#2E2F30;}.dsf-file-pre{width:100%;height:100%}.dsf-file-pre-header{display:flex;justify-content:space-between;align-items:center;width:100%;height:52px;padding:0 11px;box-sizing:border-box;background:#3F4041;color:#fff;font-size:14px}
  .dsf-file-pre-shrink{width:32px;cursor:pointer}.dsf-file-pre-pages{display:flex;justify-content:space-between;align-items:center;width:400px;height:32px}.dsf-file-pre-nums{width:124px;height:32px;background:#2E2F30;border:1px solid#555557;border-radius:4px;text-align:center;line-height:32px;margin:0 4px}.dsf-file-pre-left,.dsf-file-pre-right{width:36px;height:32px;line-height:32px;text-align:center}
  .dsf-file-play{margin-left: 58px; display:flex;align-item:center;}.dsf-file-play > div{width: 36px;height:32px;background:#4D4E50;color:#fff;text-align:center;line-height:32px; margin-left:14px;cursor:pointer;}.dsf-file-play i{font-size: 16px;}.dsf-file-pre-left:hover,.dsf-file-pre-right:hover{background:#4D4E50;border-radius:4px;cursor:pointer}
  .dsf-file-pre-close{cursor:pointer;}.dsf-file-pre--back{display:flex;width:100%;height:100%;overflow:auto;}.dsf-file-pre--content{position:relative;flex:1;text-align:center;height:100%;box-sizing:border-box;display:flex;justify-content:center;align-items:center;overflow:auto;flex-wrap: wrap;}.dsf-file-pre-type{width: 100%;}.dsf-file-pre--content-ppt>img{transition: opacity linear .75s;background-color: #fff;user-select: none}
  .dsf-file-pre--content-ppt{position:relative; width:100%;height:100%;}.dsf-file-pre--content-ppt>img{position:absolute; top:50%;left:50%;transform: translate(-50%, -50%)}.dsf-file-pre--content-word>img{display: block;margin: 0 auto; width: 85%;user-select: none}.dsf-file-pre--catalogua{width:200px;height:100%;overflow:auto;box-sizing:border-box;padding:10px 0;margin-right:8px;transition: width ease 0.5s;}.dsf-file-pre--item{margin:0 auto 16px auto;text-align:center;cursor:pointer}
  .dsf-file-pre--item img{width:160px;border:4px solid transparent}.dsf-file-pre--item p{text-align:center;font-size:14px;color:#E2E3E3}.dsf-file-pre--item-active p{color:#027AFF}.dsf-file-pre--item-active img{border:4px solid#027AFF}.dsf-video-pre{width:100%;height:100%;}.dsf-video-pre>video{width:100%;}.dsf-file-pre--content::-webkit-scrollbar,.dsf-file-pre--catalogua::-webkit-scrollbar,.dsf-file-pre--back::-webkit-scrollbar{width:5px;height:5px;background-color:transparent}
  .dsf-file-pre--content::-webkit-scrollbar-thumb,.dsf-file-pre--catalogua::-webkit-scrollbar-thumb,.dsf-file-pre--back::-webkit-scrollbar-thumb{background-color:black;border-radius:5px}`;
  constructor(type, option) {
    this.type = type || "file"; // 文件类型
    this.context = option.context || window;
    this.currentPage = option.currentPage || 1; // 页数
    this.timeLine = option.timeLine || 0; // 播放进度
    this.createFileFrame();
    this.syncFullScreen();
    this.watchScreen();
    this.rtoa().then((data) => {
      this.iconPaths[1] = data;
    });
  }
  // 构建文件展示页面结构
  createFileFrame() {
    this.FilePreFrame = this.context.document.createElement("div");
    this.FilePreFrame.className = "dsf-pre";
    if (this.type === "file") {
      this.FilePreFrame.innerHTML = this.fileTemplate();
    } else if (this.type === "video") {
      this.FilePreFrame.innerHTML = this.videoTemplate();
    } else {
      alert("展示文件格式错误");
      return;
    }
    this.context.document.body.append(this.FilePreFrame);
    this.context.document.head.append(this.createStyle());
  }
  // 展示样式
  createStyle() {
    let style = this.context.document.createElement("style");
    style.setAttribute("type", "text/css");
    style.innerHTML = this.styleTemplate;
    return style;
  }
  // 同步页面全屏
  syncFullScreen() {
    let docEmt = document.documentElement;
    let exitscreenFullEvent =
      document.exitFullscreen ||
      document.msExitFullscreen ||
      document.mozCancelFullScreen ||
      document.webkitExitFullscreen;
    let requestFullscreenEvent =
      docEmt.requestFullscreen ||
      docEmt.msRequestFullscreen ||
      docEmt.mozRequestFullScreen ||
      docEmt.webkitRequestFullScreen;
    var url = parent.window.location.href;
    var params = new URLSearchParams("?" + url.split("?")[1]);
    this.socket.$on(
      "syncscreen/ws/operation",
      {
        roomId: params.get("roomId"),
        personId: params.get("personId"),
      },
      function ({ data }) {
        if (data.eventType === "full") {
          if (data.data === "toBeFull") {
            // 无法自动全屏
            // requestFullscreenEvent();
            document
              .querySelector(".dsf-sync-header")
              .classList.add("dsf-sync-header-full");
            document.querySelector(
              ".dsf-sync-header"
            ).childNodes[0].style.height = "0";
            if (document.querySelector(".dsf-sync-left")) {
              document.querySelector(".dsf-sync-left").style.visibility =
                "hidden";
            }
          } else {
            exitscreenFullEvent();
            document
              .querySelector(".dsf-sync-header")
              .classList.remove("dsf-sync-header-full");
            document.querySelector(
              ".dsf-sync-header"
            ).childNodes[0].style.height = "66px";
            if (document.querySelector(".dsf-sync-left")) {
              document.querySelector(".dsf-sync-left").style.visibility =
                "visible";
            }
          }
        }
      }
    );
  }
  // 监听全屏状态变换
  watchScreen() {
    let that = this;
    // 退出全屏
    function exitFullMouse() {
      if (that.showMode == "full") {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen();
        }
        // 解除绑定事件
        that.destroy("full");
      }
    }

    let fullEvents = [
      "webkitfullscreenchange",
      "fullscreenchange",
      "mozfullscreenchange",
      "msfullscreenchange",
    ];
    function screenChange() {
      let isFull =
        document.webkitIsFullScreen ||
        document.fullscreen ||
        document.mozFullScreen ||
        document.msFullscreenElement;
      if (isFull === undefined) {
        isFull = false;
      }
      let context = that.context;
      if (!isFull) {
        if (that.autoTimer) {
          clearInterval(that.autoTimer);
        }
        that.showMode = "normal";
        let catalogua = context.document.querySelector(
          ".dsf-file-pre--catalogua"
        );
        if (catalogua) {
          catalogua.style.width = "200px";
        }
        context.document.querySelector(".dsf-file-pre-header").style.opacity =
          "1";
        document
          .querySelector(".dsf-sync-header")
          .classList.remove("dsf-sync-header-full");
        document.querySelector(".dsf-sync-header").childNodes[0].style.height =
          "66px";
        document.querySelector(".dsf-sync-left").style.visibility = "visible";
        let frameDom = document.getElementById("syncScreen");
        frameDom.classList.remove("dsf-file-full-mode");
        Object.keys(that.parentStyle).forEach((item) => {
          frameDom.style[item] = that.parentStyle[item];
        });
        that.socket.$send("syncscreen/ws/operation", {
          eventType: "full",
          data: "toBeNormal",
        });
      } else {
        // 扩充文件显示比列
        let frameDom = document.getElementById("syncScreen");
        let frameWidth = frameDom.width && frameDom.width.split("px")[0];
        let scale = 1;
        // 移动端兼容处理
        if (that.isMobile) {
          scale = window.screen.width / frameWidth;
        } else {
          scale = document.documentElement.offsetWidth / frameWidth;
        }
        frameDom.className = "dsf-file-full-mode";
        that.parentStyle = {
          transform: frameDom.style.transform,
          top: frameDom.style.top,
          left: frameDom.style.left,
        };
        frameDom.style.transform = `scale(${scale}) translate(-50%, -50%)`;
        // 收缩侧边导航栏
        let catalogua = context.document.querySelector(
          ".dsf-file-pre--catalogua"
        );
        document
          .querySelector(".dsf-sync-header")
          .classList.add("dsf-sync-header-full");
        document.querySelector(".dsf-sync-header").childNodes[0].style.height =
          "0";
        document.querySelector(".dsf-sync-left").style.visibility = "hidden";
        if (catalogua) {
          catalogua.style.width = "0px";
        }
        // 收缩头部操作栏
        context.document.querySelector(".dsf-file-pre-header").style.opacity =
          "0";
        that.showMode = "full";
        // 添加移动手势
        that.handleAddGesture.bind(that)(exitFullMouse);
        that.addKeyboard.bind(that)(exitFullMouse);
        that.socket.$send("syncscreen/ws/operation", {
          eventType: "full",
          data: "toBeFull",
        });
      }
    }
    fullEvents.forEach((item) => {
      document.addEventListener(item, screenChange);
      // this.destoryListenEvent.init.push(() => {document.removeEventListener(item, exitFullKeyboard)})
    });
  }
  // 图片相对路径转绝对路径
  rtoa() {
    let imgsPromises = [];
    let context = require.context(
      "_platform/assets/styles/themes/img/sync/",
      false,
      /.png$/
    );
    for (let i = 0; i < this.iconPaths[0].length; i++) {
      let promise = new Promise((resolve) => {
        let img = new Image();
        img.src = context(`./${this.iconPaths[0][i]}.png`);
        img.onload = function (data) {
          resolve(data.path[0].currentSrc);
        };
      });
      imgsPromises.push(promise);
    }
    return Promise.all(imgsPromises);
  }
  /**
   * 构造ppt文件展示内容
   * @param {*} page 当前页数
   * @param {*} counts 总页数
   * @param {*} nodeOfcatalogue 导航栏内容
   * @param {*} nodeOfview 中间展示内容
   */
  fileTemplate(page, counts, nodeOfcatalogue, nodeOfview) {
    let fileTemplate = `<div class="dsf-file-pre"><div class="dsf-file-pre-header"><div class="dsf-file-pre-shrink"><img class="dsf-iconfont" src="${
      this.iconPaths[1][1]
    }" /></div><div class="dsf-file-pre-pages"><div class="dsf-file-pre-left"><img class="dsf-iconfont" src="${
      this.iconPaths[1][0]
    }" /></div><div class="dsf-file-pre-nums"><span id="dsf-file-current-page">${
      page || 1
    }</span><span>/</span><span id="dsf-file-total-page">${
      counts || 1
    }</span></div><div class="dsf-file-pre-right"><img class="dsf-iconfont" src="${
      this.iconPaths[1][5]
    }" /></div><div class="dsf-file-play"><div id="dsf-file-full"><img class="dsf-iconfont" title="全屏" src="${
      this.iconPaths[1][2]
    }" /></div><div id="dsf-file-auto"><img class="dsf-iconfont" title="自动播放" src="${
      this.iconPaths[1][3]
    }" /></div></div></div><div class="dsf-file-pre-close"><img class="dsf-iconfont" src="${
      this.iconPaths[1][4]
    }" /></div></div><div class="dsf-file-pre--back" style="height: calc(100% - 52px)"><div class="dsf-file-pre--catalogua">${
      nodeOfcatalogue || ""
    }</div><div class="dsf-file-pre--content"><div class="dsf-file-pre-type"><div class="dsf-file-pre--content-ppt">${nodeOfview}</div></div></div></div></div>`;
    return fileTemplate;
  }
  /**
   * 构造视频展示内容
   * @param {*} content 视频标签内容
   * @returns
   */
  videoTemplate() {
    let videoTemplate = `<div class="dsf-video-pre"><div class="dsf-file-pre-header" style="justify-content: flex-end"><div class="dsf-file-pre-close"><img class="dsf-iconfont" src="${this.iconPaths[1][4]}" /></div></div></div>`;
    return videoTemplate;
  }
  /**
   * 构造word,excel等文件展示内容
   * @param {*} content 视频标签内容
   * @returns
   */
  wordTemplate(content) {
    let wordTemplate = `<div class="dsf-file-pre"><div class="dsf-file-pre-header"><div class="dsf-file-play" style="margin-left: 10px"><div id="dsf-file-full"><img class="dsf-iconfont" title="全屏" src="${
      this.iconPaths[1][2]
    }" /></div></div><div class="dsf-file-pre-close"><img class="dsf-iconfont" src="${
      this.iconPaths[1][4]
    }" /></div></div><div class="dsf-file-pre" style="height:calc(100% - 52px)"><div class="dsf-file-pre--back" style="display: block;"><div class="dsf-file-pre--content"><div class="dsf-file-pre-type"><div class="dsf-file-pre--content-word">${
      content || ""
    }</div></div></div></div></div>`;
    return wordTemplate;
  }
  /**
   * 重新加载预览组件内容
   * @param {Array} data 被加载的资源数据
   * @param {string} canvasData 加载资源数据类型
   * @param {Object} option 渲染文件类型参数
   */
  async renderContent(data, canvasData = "", option) {
    let typeMap = {
      pptx: "ppt",
      ppt: "ppt",
      pdf: "ppt",
      xls: "word",
      xlsx: "word",
      doc: "word",
      docx: "word",
      word: "word",
      mp4: "video",
      video: "video",
    };
    let { type = "ppt", currentPage = 1, scrollTop = 0 } = option;
    this.scrollTop = scrollTop;
    let renderString = "";
    if (data) {
      let sourceList = data;
      let sourseType = typeMap[type];
      this.sourceType = typeMap[type];
      this.sourceList = sourceList;
      if (sourseType === "ppt") {
        let nodeOfcatalogue = "";
        let nodeOfview = "";
        for (let i = 0; i < sourceList.length; i++) {
          if (currentPage - 1 === i) {
            nodeOfcatalogue += `<div class="dsf-file-pre--item dsf-file-pre--item-active"><div><img src="${
              sourceList[i]
            }"></div><p>${i + 1}</p></div>`;
            nodeOfview = `<img src="${sourceList[i]}">`;
          } else {
            nodeOfcatalogue += `<div class="dsf-file-pre--item"><div><img src="${
              sourceList[i]
            }"></div><p>${i + 1}</p></div>`;
          }
        }
        this.total = sourceList.length;
        renderString = this.fileTemplate(
          currentPage,
          sourceList.length,
          nodeOfcatalogue,
          nodeOfview
        );
        this.FilePreFrame.innerHTML = renderString;
        await this.appendDrawCanvas();
        this.setImgWidthHeight();
        this.addListener();
      } else if (sourseType === "word") {
        let node = "";
        for (let i = 0; i < sourceList.length; i++) {
          node += `<img src="${sourceList[i]}">`;
        }
        renderString = this.wordTemplate(node);
        this.FilePreFrame.innerHTML = renderString;
        await this.appendDrawCanvas();
        this.addListenerWord();
      } else {
        let timeLine = option.timeLine || 0;
        this.timeLine = timeLine;
        renderString = this.videoTemplate();
        this.FilePreFrame.innerHTML = renderString;
        // 必须使用标签创建video节点
        let video = this.context.document.createElement("video");
        video.controls = true;
        video.src = sourceList[0];
        video.className = "recoed-video";
        video.id = "file-video";
        video.preload = "auto";
        let parent = this.context.document.querySelector(".dsf-video-pre");
        video.style.height = "calc(100% - 52px)";
        parent.appendChild(video);
        let that = this;
        video.onloadstart = function () {
          this.currentTime = timeLine;
          that.addListenerVideo(this);
        };
      }
      this.FilePreFrame.style.zIndex = 3;
      this.FilePreFrame.style.opacity = 1;
      this.isShowFilePreview = true;
      this.context.document.body.style.overflow = "hidden";
    }
    this.context.document.body.scrollTop = 0;
    this.destroy();
  }
  // 追加事件 -> ppt pdf
  addListener() {
    let context = this.context;
    let that = this;
    let content = context.document.querySelector(".dsf-file-pre--content");
    content.scrollTop = this.scrollTop || 0;
    // 追加全屏功能
    this.addFullScreenOperation();

    function handleChangePage(e) {
      var e = e || context.event;
      e.preventDefault();
      if (e.target.tagName.toLowerCase() === "img") {
        let allItem = context.document.querySelectorAll(".dsf-file-pre--item");
        for (var i = 0; i < allItem.length; i++) {
          allItem[i].className = "dsf-file-pre--item";
        }
        e.path[2].className = "dsf-file-pre--item dsf-file-pre--item-active";
        var imgCopy = e.target.cloneNode();
        var showDom = context.document.querySelector(".dsf-file-pre--content")
          .childNodes[0].childNodes[0];
        showDom.removeChild(showDom.childNodes[0]);
        that.setImgWidthHeight(imgCopy);
        showDom.append(imgCopy);
        context.document.getElementById("dsf-file-current-page").innerHTML =
          e.path[2].innerText;
        that.currentPage = e.path[2].innerText;
        // 通过父级暴露的canvas画板对象修改canvasIndex
        if (window.parent.canvasDraw) {
          window.parent.fileCanvasDraw.canvasChangePathIndex(
            +e.path[2].innerText - 1
          );
        }
      }
    }
    function pageChange(e, isPrev = false) {
      e.stopPropagation();
      var page = +context.document.getElementById("dsf-file-current-page")
        .innerText;
      var total = +context.document.getElementById("dsf-file-total-page")
        .innerText;
      var cuttent = 0;
      if (!isPrev) {
        cuttent = page + 1 <= total ? page + 1 : page;
      } else {
        cuttent = page - 1 >= 1 ? page - 1 : page;
      }
      that.currentPage = cuttent;
      if (cuttent === page) {
        return;
      }
      context.document.getElementById(
        "dsf-file-current-page"
      ).innerText = cuttent;
      var allItem = context.document.querySelectorAll(".dsf-file-pre--item");
      var itemActive = allItem[cuttent - 1];
      itemActive.className = "dsf-file-pre--item dsf-file-pre--item-active";
      var itemNormal = allItem[page - 1];
      itemNormal.className = "dsf-file-pre--item";
      var showDom = context.document.querySelector(".dsf-file-pre--content")
        .childNodes[0].childNodes[0];
      let imgDom = showDom.childNodes[0];
      // 动画效果
      imgDom.style.opacity = "0";
      var imgCopy = itemActive.children[0].children[0].cloneNode();
      imgCopy.style.width = showDom.childNodes[0].style.width;
      imgCopy.style.height = showDom.childNodes[0].style.height;
      showDom.insertBefore(imgCopy, imgDom);
      // 根据页数显示之前的历史记录
      if (window.parent.fileCanvasDraw) {
        window.parent.fileCanvasDraw.canvasChangePathIndex(cuttent - 1);
      }

      // !isPrev ? (imgDom.style.opacity = "0") : (imgDom.style.opacity = "1");
      setTimeout(() => {
        showDom.removeChild(imgDom);
      }, 800);
    }
    function handleshrink() {
      var dom = context.document.querySelector(".dsf-file-pre--catalogua");
      var rect = dom.getBoundingClientRect();
      if (rect.width) {
        dom.style.width = "0px";
      } else {
        dom.style.width = "200px";
      }
      // 结束动画效果
      setTimeout(() => {
        that.setImgWidthHeight();
      }, 500);
    }
    function pageChangeLeft(e) {
      pageChange(e, true);
    }
    function closeFilePreview() {
      let node = context.document.querySelector(".dsf-pre");
      node.style.opacity = 0;
      node.style.zIndex = -1;
      that.isShowFilePreview = false;
      that.sourceType = ""
      context.document.body.style.overflow = "auto";
      that.destroy();
    }
    function recordScrollTop(e) {
      that.scrollTop = e.target.scrollTop;
    }
    context.document
      .querySelector(".dsf-file-pre--catalogua")
      .removeEventListener(clickEvent, handleChangePage);
    context.document
      .querySelector(".dsf-file-pre-left")
      .removeEventListener(clickEvent, pageChangeLeft);
    context.document
      .querySelector(".dsf-file-pre-right")
      .removeEventListener(clickEvent, pageChange);
    context.document
      .querySelector(".dsf-file-pre-shrink")
      .removeEventListener(clickEvent, handleshrink);
    context.document
      .querySelector(".dsf-file-pre-close")
      .removeEventListener(clickEvent, closeFilePreview);
    context.document
      .querySelector(".dsf-file-pre--content")
      .removeEventListener(clickEvent, recordScrollTop);

    context.document
      .querySelector(".dsf-file-pre--catalogua")
      .addEventListener(clickEvent, handleChangePage);
    context.document
      .querySelector(".dsf-file-pre-left")
      .addEventListener(clickEvent, pageChangeLeft);
    context.document
      .querySelector(".dsf-file-pre-right")
      .addEventListener(clickEvent, pageChange);
    context.document
      .querySelector(".dsf-file-pre-shrink")
      .addEventListener(clickEvent, handleshrink);
    context.document
      .querySelector(".dsf-file-pre-close")
      .addEventListener(clickEvent, closeFilePreview);
    context.document
      .querySelector(".dsf-file-pre--content")
      .addEventListener("scroll", recordScrollTop);
  }
  // 追加事件 -> word excel
  addListenerWord() {
    let that = this;
    let context = this.context;
    // 追加全屏功能
    this.addFullScreenOperation();
    let content = context.document.querySelector(".dsf-file-pre--content");
    content.scrollTop = this.scrollTop || 0;
    function closeFilePreview() {
      let node = context.document.querySelector(".dsf-pre");
      node.style.opacity = 0;
      node.style.zIndex = -1;
      that.isShowFilePreview = false;
      that.sourceType = ""
      context.document.body.style.overflow = "auto";
      that.destroy();
    }
    function recordScrollTop(e) {
      that.scrollTop = e.target.scrollTop;
    }
    context.document
      .querySelector(".dsf-file-pre-close")
      .removeEventListener(clickEvent, closeFilePreview);
    context.document
      .querySelector(".dsf-file-pre-close")
      .addEventListener(clickEvent, closeFilePreview);
    context.document
      .querySelector(".dsf-file-pre--content")
      .removeEventListener("scroll", recordScrollTop);
    context.document
      .querySelector(".dsf-file-pre--content")
      .addEventListener("scroll", recordScrollTop);
  }
  // 追加事件 -> video
  addListenerVideo(node) {
    let that = this;
    let context = this.context;
    let isFirst = true;
    function timeProcess(element) {
      if (isFirst) {
        this.currentTime = that.timeLine;
        isFirst = false;
      }
      let currentTime = element.target.currentTime;
      that.timeLine = currentTime;
    }
    function closeFilePreview() {
      let node = context.document.querySelector(".dsf-pre");
      node.style.opacity = 0;
      node.style.zIndex = -1;
      that.isShowFilePreview = false;
      that.sourceType = ""
      context.document.body.style.overflow = "auto";
      node.innerHTML = "";
    }
    context.document
      .querySelector(".dsf-file-pre-close")
      .removeEventListener(clickEvent, closeFilePreview);
    context.document
      .querySelector(".dsf-file-pre-close")
      .addEventListener(clickEvent, closeFilePreview);
    node.removeEventListener("timeupdate", timeProcess);
    node.addEventListener("timeupdate", timeProcess);
  }
  // 全屏操作
  addFullScreenOperation() {
    let that = this;
    let context = this.context;
    let fullBtn = context.document.getElementById("dsf-file-full");
    let autoBtn = context.document.getElementById("dsf-file-auto");
    // 全屏
    let fullFn = function (e) {
      e = e || window.event;
      e.stopPropagation();
      var docElm = document.documentElement;
      if (that.showMode == "full") {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen();
        }
      } else {
        if (docElm.requestFullscreen) {
          docElm.requestFullscreen();
        } else if (docElm.mozRequestFullScreen) {
          docElm.mozRequestFullScreen();
        } else if (docElm.webkitRequestFullScreen) {
          docElm.webkitRequestFullScreen();
        } else if (docElm.msRequestFullscreen) {
          docElm.msRequestFullscreen();
        }
      }
    };
    // 自动播放
    let autoPlay = function (params) {
      fullFn();
      if (that.showMode === "full") {
        let btn = that.context.document.querySelector(".dsf-file-pre-right");
        that.autoTimer = setInterval(() => {
          btn.click();
        }, 2000);
      } else {
        clearInterval(that.autoTimer);
      }
    };
    fullBtn.removeEventListener(clickEvent, fullFn);
    fullBtn.addEventListener(clickEvent, fullFn);
    if (autoBtn) {
      autoBtn.removeEventListener(clickEvent, autoPlay);
      autoBtn.addEventListener(clickEvent, autoPlay);
    }
  }
  /**
   * 全屏操作 键盘控制
   * esc/双击屏幕 退出全屏
   * 空格/→/单击 下一页
   * ← 上一页
   */
  addKeyboard(handleExit) {
    let that = this;
    let documentNode = this.context.document.documentElement;
    // 双击退出全屏
    documentNode.addEventListener(dbclickEvent, handleExit);
    this.destoryListenEvent.full.push(() => {
      documentNode.removeEventListener(dbclickEvent, handleExit);
    });
    // 下一页
    let startTime = 0;
    let clickTimer = null;
    function nextPage() {
      // 500ms内再次触发视为双击事件
      if (that.showMode === "full") {
        let endTime = +new Date();
        if (!clickTimer) {
          startTime = +new Date();
          clickTimer = setTimeout(() => {
            that.context.document.querySelector(".dsf-file-pre-right").click();
            clickTimer = null;
          }, 500);
        } else if (endTime - startTime <= 500) {
          clearTimeout(clickTimer);
          clickTimer = null;
        }
      }
    }
    // documentNode.addEventListener("click", nextPage);
    // this.destoryListenEvent.full.push(() => {
    //   documentNode.removeEventListener("click", nextPage);
    // });
    // 上一页
    function prevPage() {
      if (that.showMode === "full") {
        that.context.document.querySelector(".dsf-file-pre-left").click();
      }
    }
    function keyDown(e) {
      switch (e.keyCode) {
      case 32:
      case 39:
        nextPage();
        break;
      case 37:
        prevPage();
        break;
      default:
        break;
      }
    }
    documentNode.addEventListener("keydown", keyDown);
    this.destoryListenEvent.full.push(() => {
      documentNode.removeEventListener("keydown", keyDown);
    });
  }
  // 文件预览添加画板
  appendDrawCanvas() {
    let that = this;
    return new Promise((resolve) => {
      let targetDom = this.context.document.querySelector(
        ".dsf-file-pre--content"
      );
      let imgs = [];
      imgs = targetDom.childNodes[0].childNodes[0].childNodes;
      // if (this.sourceType === "word") {
      //   imgs = targetDom.childNodes[0].childNodes;
      // } else {
      //   imgs = targetDom.childNodes;
      // }
      let allPromise = [];
      for (let i = 0; i < imgs.length; i++) {
        if (imgs[i].tagName && imgs[i].tagName.toLowerCase() === "img") {
          allPromise.push(
            new Promise((resolve, reject) => {
              imgs[i].onload = function () {
                if (!that.sourceType === "word") {
                  that.setImgWidthHeight(this);
                }
                // 处理andriod机上无法第一时间获取元素高度bug。  具体原因还未知:(
                setTimeout(() => {
                  resolve(imgs[i]);
                }, 0);
              };
            })
          );
        }
      }
      Promise.all(allPromise)
        .then((re) => {
          if (this.sourceType === "word") {
            let rect1 = targetDom.getBoundingClientRect();
            let tops = 0;
            re.forEach((item, index) => {
              let canvas = this.context.document.createElement("canvas");
              let rect = item.getBoundingClientRect();
              canvas.width = rect.width;
              canvas.height = Math.max(rect.height, rect1.height);
              canvas.style.position = "absolute";
              canvas.style.top = `${tops}px`;
              canvas.style.left = "50%";
              canvas.style.transform = "translateX(-50%)";
              canvas.id = `dsf-file-preview-${index}`;
              tops += Math.max(rect.height, rect1.height);
              targetDom.childNodes[0].appendChild(canvas);
              if (index === 0) {
                this.drawCanvas = [canvas];
              } else {
                this.drawCanvas.push(canvas);
              }
            });
          } else {
            let canvas = this.context.document.createElement("canvas");
            let rect1 = targetDom.getBoundingClientRect();
            canvas.width = rect1.width;
            canvas.height = rect1.height;
            canvas.style.position = "absolute";
            canvas.style.top = "0";
            canvas.style.left = "50%";
            canvas.style.transform = "translateX(-50%)";
            canvas.id = "dsf-file-preview";
            targetDom.childNodes[0].appendChild(canvas);
            this.drawCanvas = [canvas];
          }

          resolve(true);
        })
        .catch((e) => {
          console.log(e);
        });
    }, 0);
  }
  // 手动设置被展示图片宽高比列
  setImgWidthHeight(node) {
    if (!node) {
      node = this.context.document.querySelector(".dsf-file-pre--content")
        .childNodes[0].childNodes[0].childNodes[0];
    }
    let p = this.context.document
      .querySelector(".dsf-file-pre--content")
      .getBoundingClientRect();
    let scaleNode = node.width / node.height;
    let scaleP = p.width / p.height;
    if (node.width) {
      if (scaleNode < scaleP) {
        node.style.height = "100%";
        node.style.width = "auto";
      } else {
        node.style.height = "auto";
        node.style.width = "100%";
      }
    } else {
      node.onload = function () {
        let scaleNode = node.width / node.height;
        if (scaleNode < scaleP) {
          node.style.height = "100%";
          node.style.width = "auto";
        } else {
          node.style.height = "auto";
          node.style.width = "100%";
        }
      };
    }
  }
  /**
   *
   * 添加手势事件
   * 移动端全屏模式下
   * ppt系类 -> 双指左右翻页(300ms内滑动距离超过150则视为有效距离)
   * word系列 -> 双指上下滚动
   * @param {function} handleExit 退出全屏
   */
  handleAddGesture(handleExit) {
    let mode = this.sourceType;
    let distanceX = 0;
    let distanceY = 0;
    let startTime = 0;
    let prevNode = this.context.document.querySelector(".dsf-file-pre-left");
    let nextNode = this.context.document.querySelector(".dsf-file-pre-right");
    let contentNode = this.context.document.querySelector(
      ".dsf-file-pre--content"
    );
    let hasScroll =
      contentNode.scrollHeight > contentNode.clientHeight ||
      contentNode.offsetHeight > contentNode.clientHeight;
    let lastY = 0;
    let option = {
      start: function (event) {
        startTime = +new Date();
        distanceX = event.clientX;
        distanceY = event.clientY;
      },
      drag: function (event) {
        if (hasScroll && mode === "word" && event.fingers === 2) {
          if (lastY === 0) {
            lastY = event.clientY;
          } else {
            let scrollY = event.clientY - lastY;
            contentNode.scrollTop -= scrollY;
            lastY = event.clientY;
          }
        }
      },
      end: function (event) {
        if (event.fingers === 2) {
          lastY = 0;
          distanceX = event.clientX - distanceX;
          distanceY = event.clientY - distanceY;
          if (mode === "ppt") {
            if (+new Date() - startTime <= 300 && Math.abs(distanceX) >= 150) {
              distanceX > 0 ? prevNode.click() : nextNode.click();
            }
          }
        } else if (event.fingers === 3) {
          if (+new Date() - startTime <= 300 && Math.abs(distanceY) >= 200) {
            setTimeout(() => {
              handleExit();
            }, 50);
          }
        }
      },
      cancelDefault: true,
    };
    let node = this.context.document.querySelector(".dsf-file-pre-type");
    let downFn = draggable(node, option, this.context.document.documentElement);
    this.destoryListenEvent.full.push(() => {
      node.removeEventListener(isMobile ? "touchstart" : "mousedown", downFn);
    });
  }
  /**
   * 解除绑定事件
   * @param {string} key full/init
   */
  destroy(key) {
    if (key) {
      let eventList = this.destoryListenEvent[key];
      for (let i = 0; i < eventList.length; i++) {
        eventList[i]();
      }
      this.destoryListenEvent[key] = [];
    } else {
      let keys = Object.keys(this.destoryListenEvent);
      for (let i = 0; i < keys.length; i++) {
        for (let j = 0; j < this.destoryListenEvent[keys[i]].length; j++) {
          this.destoryListenEvent[keys[i]][j]();
        }
        this.destoryListenEvent[keys[i]] = [];
      }
    }
  }
}
export default FilePre;
