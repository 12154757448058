<template>
  <div
    :class="{ 'ds-no-padding': !showPadding }"
    class="ds-control ds-collapse"
  >
    <el-collapse
      v-model="activeNames"
      @change="handleChange"
      :accordion="accordion"
      class="ds-collapse_control"
    >
      <el-collapse-item
        v-for="(item, index) in slots"
        :key="item.name"
        :name="item.name"
        :disabled="$eval(item.disabled)"
      >
        <template slot="title">
          <slot name="title" v-bind="{ item: item, $index: index }">
            <span class="ds-font4">{{ item.title }}</span>
          </slot>
        </template>
        <div :slot-name="item.name" :style="{ padding: $padding }">
          <slot :name="item.name"></slot>
        </div>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>
export default dsf.component({
  name: "DsfCollapse",
  ctrlCaption: "折叠面板",
  mixins: [$mixins.layout],
  props: {
    showPadding: {
      type: Boolean,
      default: true,
    },
    accordion: {
      type: Boolean,
      default: false,
    },
    slots: {
      type: Array,
      default: function () {
        return [];
      },
    },
    contentPadding: {
      type: Array,
      default: function () {
        return ["0", "0", "0", "0"];
      },
    },
  },
  data() {
    return {
      activeNames: "",
    };
  },
  computed: {
    $padding() {
      let padding = this.contentPadding;
      return [
        dsf.isNumber(padding[0]) ? padding[0] + "px" : padding[0],
        dsf.isNumber(padding[1]) ? padding[1] + "px" : padding[1],
        dsf.isNumber(padding[2]) ? padding[2] + "px" : padding[2],
        dsf.isNumber(padding[3]) ? padding[3] + "px" : padding[3]
      ].join(" ")
    },
  },
  created() {
    if (this.isDesign && this.slots.length == 0) {
      let v = [];
      for (let i = 0; i < 3; i++) {
        v.push({
          title: "面板" + (i + 1),
          activated: "true",
          name: dsf.uuid(),
          disabled: "false",
          controls: [],
        });
      }
      this.slots = v;
    }
    this.$nextTick(() => {
      this.changeActive();
    });
  },
  methods: {
    changeActive() {
      let activeNames = [];
      for (let i in this.slots) {
        let block = this.slots[i],
          name = block.name;
        if (this.$eval(block.activated)) {
          activeNames.push(name);
        }
      }
      if (this.accordion) {
        activeNames = activeNames["lastItem"] || "";
      }
      this.activeNames = activeNames;
    },
    handleChange(val) {
      // val为标识
      this.$dispatch("change",val);
    },
  },
});
</script>
