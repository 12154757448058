import DsfPlatformLogin from './login.vue'
import DsfForgotPassword from './forgotPassword.vue'
import DsfPlatformAlterPassword from './alterPassword.vue'

export default {
  install (Vue) {
    Vue.component(DsfPlatformLogin.name, DsfPlatformLogin);
    Vue.component(DsfForgotPassword.name, DsfForgotPassword);
    Vue.component(DsfPlatformAlterPassword.name, DsfPlatformAlterPassword);
  }
};