var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "ds-control", style: _vm.controlStyle }, [
    _c(
      "div",
      {
        staticClass: "ds-announcemen-mail dsf-home-notice-announcement-list",
        style: _vm.panelStyle,
      },
      [
        _c(
          "DsfVirtualScroll",
          [
            _c(
              "DsfVirtualList",
              _vm._b(
                {
                  ref: "vlist",
                  attrs: { isDesign: _vm.isDesign, url: _vm.dataUrl },
                  on: {
                    "list-change": _vm.listChangeFn,
                    "row-click": _vm.rowClick,
                  },
                  scopedSlots: _vm._u(
                    [
                      _vm.isDesign
                        ? {
                            key: "list-row",
                            fn: function () {
                              return [
                                _c(
                                  "div",
                                  {
                                    staticClass: "ds-list-col2-row",
                                    style: { lineHeight: _vm.lineHeight },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "ds-list-col2-item ellipsis",
                                        attrs: { title: "标题", type: "name" },
                                      },
                                      [_vm._v(" 标题 ")]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "ds-list-col2-item",
                                        attrs: { type: "time" },
                                      },
                                      [_vm._v("时间")]
                                    ),
                                  ]
                                ),
                              ]
                            },
                            proxy: true,
                          }
                        : {
                            key: "list-row",
                            fn: function (scope) {
                              return [
                                _c(
                                  "div",
                                  {
                                    staticClass: "ds-list-col2-row",
                                    style: { lineHeight: _vm.lineHeight },
                                    attrs: { read: scope.row.state == 1 },
                                  },
                                  [
                                    scope.row.isTop == 1
                                      ? _c("img", {
                                          staticClass: "ds-list-col2-item",
                                          attrs: {
                                            type: "top",
                                            src: _vm.dsf.url.getWebPath(
                                              "/static/img/home/top.png"
                                            ),
                                          },
                                        })
                                      : _vm._e(),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "ds-list-col2-item ellipsis",
                                        attrs: {
                                          title: scope.row.title || "",
                                          type: "name",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(scope.row.title || "") +
                                            " "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "ds-list-col2-item",
                                        attrs: { type: "time" },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm._f("time")(
                                                scope.row.createTime
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            },
                          },
                    ],
                    null,
                    true
                  ),
                },
                "DsfVirtualList",
                {
                  rowPadding: _vm.rowPadding,
                  rowMargin: _vm.rowMargin,
                  urlQuery: _vm.urlQuery,
                },
                false
              )
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }