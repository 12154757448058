var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page-home-template1-header" }, [
    _c("div", { staticClass: "page-home-template1-header-info" }, [
      _c(
        "div",
        { staticClass: "logo-box" },
        [
          _vm._t("logo", function () {
            return [
              _c("a", { attrs: { href: _vm.indexUrl } }, [
                _vm.logo
                  ? _c("img", {
                      staticClass: "logo",
                      attrs: { src: _vm._f("imgFormat")(_vm.$logo), alt: "" },
                    })
                  : _vm._e(),
                _vm.title && _vm.title.length
                  ? _c("div", { staticClass: "site-name" }, [
                      _vm._v(_vm._s(_vm.title)),
                    ])
                  : _vm._e(),
              ]),
            ]
          }),
        ],
        2
      ),
      _c("div", { staticClass: "header-right" }, [
        _c(
          "div",
          { staticClass: "header-user" },
          [
            _c("div", { staticClass: "header-user-info" }, [
              _c("div", { staticClass: "username" }, [
                _vm._v(_vm._s(_vm.dsf.cookies.get("user_name")) + ", 你好"),
              ]),
            ]),
            !_vm.dropdownConfig.length
              ? _c(
                  "div",
                  {
                    staticClass: "header-user-icon",
                    style: {
                      "background-image":
                        "url(" + _vm.$replace(_vm.defaultHeader) + ")",
                    },
                  },
                  [
                    _c("img", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.showIcon,
                          expression: "showIcon",
                        },
                      ],
                      attrs: { src: _vm._f("imgFormat")(_vm.headIcon) },
                      on: {
                        error: _vm.headLoadError,
                        load: _vm.headLoadSuccess,
                      },
                    }),
                  ]
                )
              : _c(
                  "el-dropdown",
                  {
                    attrs: { "show-timeout": 0, placement: "bottom" },
                    on: {
                      "visible-change": function ($event) {
                        _vm.showDropdown = $event
                      },
                      command: _vm.handleCommand,
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "header-user-icon",
                        style: {
                          "background-image":
                            "url(" + _vm.$replace(_vm.defaultHeader) + ")",
                        },
                      },
                      [
                        _c("img", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.showIcon,
                              expression: "showIcon",
                            },
                          ],
                          attrs: { src: _vm._f("imgFormat")(_vm.headIcon) },
                          on: {
                            error: _vm.headLoadError,
                            load: _vm.headLoadSuccess,
                          },
                        }),
                      ]
                    ),
                    _c(
                      "el-dropdown-menu",
                      { attrs: { slot: "dropdown" }, slot: "dropdown" },
                      _vm._l(_vm.dropdownConfig, function (item, index) {
                        return _c(
                          "el-dropdown-item",
                          {
                            key: (item.key || "") + "__" + index,
                            attrs: { command: index },
                          },
                          [_vm._v(" " + _vm._s(item.name) + " ")]
                        )
                      }),
                      1
                    ),
                  ],
                  1
                ),
          ],
          1
        ),
      ]),
    ]),
    _c(
      "div",
      { staticClass: "page-home-template1-header-menu" },
      [
        _c("dsf-template1-home-menu", {
          attrs: { "last-level-click": _vm.lastLevelClick },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }