var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-image",
    _vm._b(
      {
        staticClass: "dsf-image",
        on: {
          load: function ($event) {
            return _vm.$emit("load", $event)
          },
          error: function ($event) {
            return _vm.$emit("error", $event)
          },
        },
        scopedSlots: _vm._u(
          [
            {
              key: "placeholder",
              fn: function () {
                return [
                  _vm._t("placeholder", function () {
                    return [_c("i", { staticClass: "el-icon-loading" })]
                  }),
                ]
              },
              proxy: true,
            },
            {
              key: "error",
              fn: function () {
                return [
                  _vm._t("error", function () {
                    return [
                      _vm.defaultImg
                        ? _c("img", {
                            staticClass: "dsf-image-fail",
                            attrs: { src: _vm.defaultImg, alt: "" },
                          })
                        : _c("div", { staticClass: "el-image__error" }, [
                            _vm._v("暂无图片"),
                          ]),
                    ]
                  }),
                ]
              },
              proxy: true,
            },
          ],
          null,
          true
        ),
      },
      "el-image",
      _vm.$props,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }