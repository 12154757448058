var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "flow-add-comment" }, [
    _c("div", { staticClass: "content" }, [
      _c(
        "div",
        { staticClass: "left" },
        [
          _c("el-input", {
            attrs: { type: "textarea", rows: 9, max: 1000 },
            model: {
              value: _vm.sOpinion.Content,
              callback: function ($$v) {
                _vm.$set(_vm.sOpinion, "Content", $$v)
              },
              expression: "sOpinion.Content",
            },
          }),
        ],
        1
      ),
      _vm.tpls.length > 0
        ? _c(
            "div",
            { staticClass: "template" },
            _vm._l(_vm.tpls, function (tpl, i) {
              return _c(
                "div",
                {
                  key: i,
                  staticClass: "item",
                  on: {
                    click: function ($event) {
                      _vm.sOpinion.Content = tpl.key_word
                    },
                  },
                },
                [_vm._v(" " + _vm._s(tpl.key_word) + " ")]
              )
            }),
            0
          )
        : _vm._e(),
    ]),
    _c("div", { staticClass: "upload-file" }, [
      _c("div", { staticClass: "title" }, [
        _c("span", { staticClass: "tip" }, [_vm._v("附件")]),
        _c(
          "span",
          { staticClass: "ds-button--icon-text", on: { click: _vm.upload } },
          [_c("i", { staticClass: "el-icon-upload2" }), _vm._v(" 上传附件")]
        ),
      ]),
      _c(
        "div",
        { staticClass: "file-list" },
        [
          _vm.loaded
            ? _c("DsfUploadFile", {
                ref: "uploadFile",
                attrs: { "hide-btns": true, files: _vm.sOpinion.Attachments },
                on: { "value-change": _vm.getValue },
              })
            : _vm._e(),
          !_vm.sOpinion ||
          (_vm.sOpinion &&
            (!_vm.sOpinion.Attachments ||
              _vm.sOpinion.Attachments.length === 0))
            ? _c("p", { staticClass: "no-file" }, [_vm._v(" 暂无附件～ ")])
            : _vm._e(),
        ],
        1
      ),
    ]),
    _c(
      "div",
      { staticClass: "btns" },
      [
        _c(
          "span",
          {
            staticClass: "ds-button--icon-text addcomment-delete",
            on: { click: _vm.clearAll },
          },
          [_c("i", { staticClass: "iconfont icon-delete" }), _vm._v(" 清 空")]
        ),
        _c(
          "DsfButton",
          { staticClass: "addcomment-sure", on: { click: _vm.sure } },
          [_vm._v("确 定")]
        ),
        _c(
          "DsfButton",
          { staticClass: "plain addcomment-cancel", on: { click: _vm.cancel } },
          [_vm._v("取 消")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }