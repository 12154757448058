var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isDesign
    ? _c(
        "el-table",
        {
          ref: "table",
          staticClass: "dsf-static-list-by-table",
          class: { disabledCheckHead: _vm.isSingleChecked },
          attrs: {
            border: true,
            fit: true,
            data: _vm.data,
            "row-key": "_id",
            "tree-props": _vm.$list.treeProps,
            height: _vm.tableHeight,
            "header-cell-class-name": _vm.headerCellClassName,
            stripe: "",
          },
        },
        [
          !_vm.isReDrawHander
            ? [
                _vm.checkboxColumn.show || _vm.isEditable
                  ? _c("el-table-column", {
                      attrs: {
                        width: _vm.checkboxColumn.width,
                        type: "selection",
                        "header-align": "center",
                        align: "center",
                        fixed: "left",
                      },
                    })
                  : _vm._e(),
                _vm.indexColumn.show
                  ? _c("el-table-column", {
                      attrs: {
                        index: _vm.$list.indexMethod,
                        resizable: false,
                        width: _vm.indexColumnWidth,
                        type: "index",
                        label: "序号",
                        "header-align": "center",
                        align: "center",
                        fixed: "left",
                      },
                    })
                  : _vm._e(),
                _vm._l(_vm.getColumns(), function (col) {
                  return _c("dsf-static-list-by-table-column", {
                    key: col.columnId,
                    attrs: {
                      "is-design": _vm.isDesign,
                      columns: col,
                      "get-column-sort": _vm.getColumnSort,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            var col = ref.col
                            return [
                              _c(
                                "div",
                                { staticClass: "editor-td-box" },
                                [
                                  _c("dsf-div-editor", {
                                    class: [col.align],
                                    staticStyle: {
                                      width: "100%",
                                      height: "100%",
                                    },
                                    attrs: { tag: "div" },
                                    model: {
                                      value: row[col.columnId],
                                      callback: function ($$v) {
                                        _vm.$set(row, col.columnId, $$v)
                                      },
                                      expression: "row[col.columnId]",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  })
                }),
                _vm.isEditable
                  ? _c("el-table-column", {
                      attrs: {
                        label: "操作",
                        "header-align": "center",
                        align: "center",
                        width: "180",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "dsf-static-list-by-table-button-row",
                                  },
                                  [
                                    _c(
                                      "a",
                                      {
                                        on: {
                                          click: function ($event) {
                                            return _vm.insertRow(scope.$index)
                                          },
                                        },
                                      },
                                      [_vm._v("插入")]
                                    ),
                                    _vm.$list.isTreeTable
                                      ? _c(
                                          "a",
                                          {
                                            on: {
                                              click: function ($event) {
                                                return _vm.insertChildRow(
                                                  scope.row
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v(" 插入子项 ")]
                                        )
                                      : _vm._e(),
                                    _c(
                                      "a",
                                      {
                                        on: {
                                          click: function ($event) {
                                            return _vm.removeRow(scope.row)
                                          },
                                        },
                                      },
                                      [_vm._v("删除")]
                                    ),
                                  ]
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        1705412763
                      ),
                    })
                  : _vm.operateColumn.show
                  ? _c("el-table-column", {
                      attrs: {
                        label: "操作",
                        width: _vm.operateColumn.width,
                        "header-align": "center",
                        align: "center",
                        fixed: "right",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("DsfButtonBar", {
                                  staticClass: "ds-no-padding ds-no-drop",
                                  attrs: {
                                    size: "small",
                                    "max-number": _vm.rowButtonsMaxNumber,
                                    "more-button-text": _vm.rowButtonsMoreText,
                                    "more-button-style":
                                      _vm.rowButtonsMoreStyle,
                                    "is-design": _vm.isDesign,
                                    buttons: scope._self.rowButtons,
                                    align: "center",
                                  },
                                }),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        445715671
                      ),
                    })
                  : _vm._e(),
              ]
            : _vm._e(),
        ],
        2
      )
    : _c(
        "el-table",
        {
          ref: "table",
          staticClass: "dsf-static-list-by-table",
          class: { disabledCheckHead: _vm.isSingleChecked },
          attrs: {
            "row-key": "_id",
            border: true,
            fit: true,
            data: _vm.data,
            "tree-props": _vm.$list.treeProps,
            height: _vm.tableHeight,
            "default-expand-all": _vm.$list.extendDefaultAll,
            "header-cell-class-name": _vm.headerCellClassName,
            stripe: "",
          },
        },
        [
          _vm.checkboxColumn.show
            ? _c("el-table-column", {
                attrs: {
                  width: _vm.checkboxColumn.width,
                  type: "selection",
                  "header-align": "center",
                  align: "center",
                  fixed: "left",
                },
              })
            : _vm._e(),
          _vm.indexColumn.show
            ? _c("el-table-column", {
                attrs: {
                  index: _vm.$list.indexMethod,
                  resizable: false,
                  width: _vm.indexColumnWidth,
                  type: "index",
                  label: "序号",
                  "header-align": "center",
                  align: "center",
                  fixed: "left",
                },
              })
            : _vm._e(),
          _vm._l(_vm.getColumns(), function (col, $index) {
            return _c("dsf-static-list-by-table-column", {
              key: $index,
              attrs: { columns: col, "get-column-sort": _vm.getColumnSort },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      var col = ref.col
                      return [
                        _c("span", {
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.filterHtml(row[col.columnId])
                            ),
                          },
                        }),
                      ]
                    },
                  },
                ],
                null,
                true
              ),
            })
          }),
          _vm.operateColumn.show
            ? _c("el-table-column", {
                attrs: {
                  label: "操作",
                  width: _vm.operateColumn.width,
                  "header-align": "center",
                  align: "center",
                  fixed: "right",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("DsfButtonBar", {
                            staticClass: "ds-no-padding ds-no-drop",
                            attrs: {
                              size: "small",
                              "max-number": _vm.rowButtonsMaxNumber,
                              "more-button-text": _vm.rowButtonsMoreText,
                              "more-button-style": _vm.rowButtonsMoreStyle,
                              "is-design": _vm.isDesign,
                              buttons: scope._self.rowButtons,
                              align: "center",
                            },
                          }),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  445715671
                ),
              })
            : _vm._e(),
        ],
        2
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }