import { render, staticRenderFns } from "./numeration.vue?vue&type=template&id=c6f5a912&"
import script from "./numeration.vue?vue&type=script&lang=js&"
export * from "./numeration.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

component.options.__file = "pc/bhc/input/numeration.vue"
export default component.exports