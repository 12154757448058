var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ds-personalization", class: { show: _vm.show } },
    [
      _vm.show
        ? _c(
            "div",
            { staticClass: "ds-personalization-editor" },
            [
              _c("div", { staticClass: "ds-personalization-editor-title" }, [
                _vm._v(" 个性化设置 "),
              ]),
              _c(
                "el-form",
                { attrs: { "label-width": "120px" } },
                [
                  _vm.showThemes.length
                    ? _c("el-form-item", { attrs: { label: "主题色：" } }, [
                        _c(
                          "div",
                          { staticClass: "theme-radio-list" },
                          [
                            _vm._l(_vm.showThemes, function (key) {
                              return [
                                _c("div", {
                                  key: key,
                                  staticClass: "theme-radio-item",
                                  style: _vm.radioStyle(
                                    _vm.dsf.themeConfig.themesConfig[key],
                                    key
                                  ),
                                  on: {
                                    click: function ($event) {
                                      return _vm.radioClick(key)
                                    },
                                  },
                                }),
                              ]
                            }),
                          ],
                          2
                        ),
                      ])
                    : _vm._e(),
                  _c(
                    "el-form-item",
                    { attrs: { label: "字体大小：" } },
                    [
                      _vm._t(
                        "font-size",
                        function () {
                          return [
                            _c("el-slider", {
                              attrs: {
                                step: 2,
                                min: _vm.fontSizeMin,
                                max: _vm.fontSizeMax,
                                marks: _vm.fontSizeMarks,
                                "format-tooltip": _vm.formatTooltip,
                                "show-stops": "",
                              },
                              model: {
                                value: _vm.fontSizeVal,
                                callback: function ($$v) {
                                  _vm.fontSizeVal = $$v
                                },
                                expression: "fontSizeVal",
                              },
                            }),
                          ]
                        },
                        null,
                        _vm._self
                      ),
                    ],
                    2
                  ),
                  _vm.hasDefaultHeader
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "顶部导航栏：" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.themeConfig.defaultHeaderMini,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.themeConfig,
                                    "defaultHeaderMini",
                                    $$v
                                  )
                                },
                                expression: "themeConfig.defaultHeaderMini",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: false } }, [
                                _vm._v("宽条"),
                              ]),
                              _c("el-radio", { attrs: { label: true } }, [
                                _vm._v("窄条"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.hasDefaultHeader
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "侧边导航栏：" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.themeConfig.defaultAsideMini,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.themeConfig,
                                    "defaultAsideMini",
                                    $$v
                                  )
                                },
                                expression: "themeConfig.defaultAsideMini",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: false } }, [
                                _vm._v("宽条"),
                              ]),
                              _c("el-radio", { attrs: { label: true } }, [
                                _vm._v("窄条"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "footer" },
                [
                  _c("dsf-button", {
                    attrs: { text: "确 定", size: "small" },
                    on: { click: _vm.sure },
                  }),
                  _c("dsf-button", {
                    attrs: { text: "取 消", size: "small", type: "plain" },
                    on: { click: _vm.close },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }