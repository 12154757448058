import system_batch_flow_send from "./flowBatchSend";

export default {
  //公共类 start
  //表单保存
  async system_form_save(args) {
    let form = args._self.$form;
    if (form) {
      let options = {
        isRefresh: args.params[0] === true, //是否要刷新父页面
        customParams: args.params[1] || "",
        savedClose: args.params[2] === true,
        successMsg: args.params[3] || "保存成功",
        errorMsg: args.params[4] || "保存失败"
      };
      if (!form.$isPrototype) {
        await form.formSave(options);
        // if (options.savedClose) {
        //   args.owner.$set(args.targetButton, 'disabled', true);
        // }
        // let res = await form.formSave(options);
        // if (options.savedClose && (!res || !res?.success || !res.data._id)) {
        //   args.owner.$set(args.targetButton, 'disabled', false);
        // }
      }
      else {
        dsf.layer.message(options.successMsg)
      }
    }
  },
  async system_form_data(args) {

    let form = args._self.$form;
    if (form) {
      if (!form.$isPrototype) {
        let data = await form.yes();
        return data;
      }
      else {
        return null
      }
    }
  },
  //打开新窗口
  system_open_window(args) {
    let url = args.params[0];
    let name = args.params[1];
    if (url) {
      args._self.$openWindow({
        url,
        name
      });
    }
  },
  //关闭窗口（对话框）
  system_close_window(args) {
    if (args._self.$vm) {
      args._self.$vm.$closeWindow();
    }
  },
  //弹出模态框
  system_pop_modal(args) {
    let [title, url, size, saveName, otherSet, footerAlign, isTopOpen] = args.params;
    let width, height;
    if (size) {
      [width, height] = size.split(",");
    } else {
      [width, height] = ["80%", "500px"];
    }

    otherSet = _.isObject(otherSet) ? otherSet : {};

    if (footerAlign !== 'left' && footerAlign !== 'center') {
      footerAlign = 'right';
    }

    if (url) {
      this.$openDialog({
        title: title || "",
        width: width,
        height: height,
        footerAlign: footerAlign,
        isTopOpen: isTopOpen,
        params: {
          path: url
        },
        btns: [
          {
            text: "确定",
            handler: async (res) => {
              const _name = saveName || "system-dialog-save";
              const data = await res.yes();
              args.owner.$dispatch(_name, data);
              if (!data) {
                return false;
              }
            }
          },
          {
            text: "取消"
          }
        ],
        ...otherSet
      });
    }
  },
  // 关闭模态框
  system_close_dialog(args) {
    if (this.$dialog) {
      this.$dialog.$parent.close();
    }
  },
  //接口调用
  system_interface_exec(args) {
    let params = args.params;
    let url = params[0];
    let method = params[1];
    let postParams = params[2];
    let successMsg = params[3];
    let errorMsg = params[4];
    let callbakcName = params[5];
    let ppm = postParams ? dsf.url.queryStringParse(postParams) : {};
    let loading = dsf.layer.loading();
    args._self.$http[method](url, ppm)
      .then(({ data }) => {
        if (data.success && data.state == "20000") {
          dsf.layer.message(successMsg, true);
          args._self.$dispatch("system-interface-exec-callback", data);
          args._self.$vm[callbakcName] && args._self.$vm[callbakcName](data, args);
        } else {
          dsf.layer.message(data.message, false);
        }
      })
      .catch((err) => {
        dsf.layer.message(errorMsg, false);
      })
      .finally(() => {
        dsf.layer.closeLoading(loading);
      });
  },
  // 模块下载
  system_template_download(args) {
    let file = args.params[0] + '^' + args.params[1];
    let fileName = args.params[1];
    if (file) {
      let url = dsf.url.getWebPath("file/downloadFromBlob", args.owner.$servicePrefix);
      $("<form action=" + url + ' method="get">' + '<input type="text" name="files" value="' + file + '"/>' + '<input type="text" name="zipName" value="' + fileName + '"/>' + "</form>")
        .appendTo("body")
        .submit()
        .remove();
    } else {
      dsf.layer.message("暂无上传模板", false);
    }
  },
  //公共类 end

  //列表 start
  //清空筛选条件
  system_datagrid_clear_condition(args) {
    console.log(args.owner);
    args.owner.searchStr = "";
    const handlerFunc = async function () {
      let superValue = args.owner?.superValue || {};
      let searchItems = args.owner.$refs?.superSearchBar?.searchItems || [];
      if (JSON.stringify(superValue) != "{}") {
        searchItems = searchItems.filter(item => Object.keys(superValue).includes(item.field));
        for await (let item of searchItems) {
          args.owner.$refs?.superSearchBar.removeFilter(item);
        }
      } else {
        args.owner.search();
      }
    };
    handlerFunc();
    args.owner.$dispatch("clearCondition", args);
  },
  //批量删除
  system_datagrid_batch_delete(args) {
    let rows = args.owner.multipleSelection;
    if (rows.length === 0) {
      args.owner.$message("请选择至少一条数据", false);
      return;
    }
    args.owner
      .$confirm("是否要删除选中的" + rows.length + "条数据？")
      .then((result) => {
        let data = args.data;
        if (data) {
          let params = args.params;
          let key = params[0] || "_id";
          let isTree = params[1] === true;
          let callback = params[2] || "";
          let deleteRows = args.owner.multipleSelection
          let ids = _.map(deleteRows, (row) => {
            return row[key];
          });
          let post = {
            namespace: args.owner.$vm.nameSpace,
            pageName: args.owner.$vm.pageName,
            isTree: isTree,
            data: JSON.stringify(ids)
          };
          args._self.$http
            .post("/meta/list/delete", post, true)
            .then(({ data }) => {
              if (data.state == 20000) {
                args.owner.$message("删除成功", true);
                args.owner.clearSelect();
                args.owner.$nextTick(function () {
                  args.owner.loadData();
                  if (callback && args.owner.$vm[callback]) {
                    args.owner.$vm[callback](deleteRows);
                  }
                });
              } else {
                args.owner.$message(data.message || "删除失败", false);
              }
            })
            .catch((ex) => {
              args.owner.$message("删除失败", false);
            });
        }
      })
      .catch((ex) => {
        throw ex;
      });
  },
  //列表行删除数据
  system_datagrid_delete(args) {
    args.owner
      .$confirm("是否要删除该数据")
      .then((result) => {
        let data = args.data;
        if (data) {
          let params = args.params;
          let key = params[0] || "_id";
          let isTree = params[1] === true;
          let callback = params[2] || "";
          let deleteRows = [data];
          let ids = [data[key]];
          let post = {
            namespace: args.owner.$vm.nameSpace,
            pageName: args.owner.$vm.pageName,
            isTree: isTree,
            data: JSON.stringify(ids)
          };
          args._self.$http.post("/meta/list/delete", post, true)
            .then(({ data }) => {
              if (data && data.success) {
                args.owner.$message("删除成功", true);
                if (args.owner) {
                  args.owner.toggleSelect(args.data, false);
                  args.owner.$nextTick(function () {
                    args.owner.loadData();
                    if (callback && args.owner.$vm[callback]) {
                      args.owner.$vm[callback](deleteRows);
                    }
                  });
                }
              } else {
                args.owner.$message(data.message, false);
              }
            })
            .catch((ex) => {
              args.owner.$message("删除失败", false);
            });
        }
      })
      .catch((ex) => {
        throw ex;
      });
  },
  //列表导入(上传文件成功后执行该函数)
  system_datagrid_import(args) {
    let datagrid = args.owner;
    let res = args.response;
    let [, , , completeKey] = args.params;
    if (datagrid) {
      datagrid.reloadData(1);
      if (datagrid.$listeners[completeKey]) {
        datagrid.$dispatch(completeKey, res)
      } else if (res.success) {
        args.owner.$message("导入完成", true);
      } else {
        args.owner.$message(res.message || "导入失败", false);
      }
    }
  },
  //列表行上传按钮
  system_datagrid_row_upload(args) {
    let datagrid = args.owner;
    let response = args.response
    let [, , , namespace, pageName, primaryColumnName, fileColumnName, isCheckbox, beforeUpdate] = args.params;
    if (!response || !response.success) {
      return datagrid.$message('上传失败', false);
    }
    if (beforeUpdate || isCheckbox) {
      if (isCheckbox && !datagrid.multipleSelection.length) return args.owner.$message('请选择需要上传到哪些行数据中', false);
      if (!beforeUpdate) {
        return datagrid.$message('请配置二次开发方法', false)
      }
      if (dsf.type(datagrid.$listeners[`upload-${beforeUpdate}`]) != 'function') {
        return datagrid.$message(`请绑定upload-${beforeUpdate}上传二次开发方法`, false)
      }
      return datagrid.$dispatch(`upload-${beforeUpdate}`, args)
    }

    if (!namespace || !pageName || !fileColumnName) {
      return datagrid.$message(`请检查命名空间、页名称、主键列字段名字、文件字段在表单中的名字是否配置`, false)
    }

    datagrid.$http.post("/datagrid/row/upload", {
      namespace,
      pageName,
      primaryColumnName,
      fileColumnName,
      file: JSON.stringify(response.data),
      rowData: JSON.stringify(args.data)
    }).then(({ data }) => {
      if (data && data.success) {
        datagrid.$message('上传成功')
        datagrid.reloadData()
      } else {
        datagrid.$message(data.message, false)
      }
    }).catch(() => {
      datagrid.$message('上传失败', false)
    })
  },
  //列表预导入(上传文件成功后执行该函数)
  system_datagrid_preimport(args) {
    let datagrid = args.owner;
    let [, , , path, url, callback] = args.params;
    const res = args.response;
    const dispatchEvent2DataGrid = (data) => {
      args.owner.$dispatch("system-datagrid-preimport-saved", data);
      args.owner.reloadData();
    };
    if (!res.success) {
      datagrid.$message(res.message, false);
      return;
    }

    if (!path) {
      datagrid.$message("导入文件成功", true);
      dispatchEvent2DataGrid(res.data);
      return;
    }

    //如果有path参数则打开模态框
    this.$openDialog({
      title: "预导入文件编辑",
      width: "80%",
      height: "500px",
      center: true,
      params: {
        path: path,
        fileData: res.data
      },
      btns: [
        {
          text: "确定",
          handler: async (res) => {
            let btnLoading = res.$dialog.btnLoading
            return new Promise((resolve, reject) => {
              btnLoading(0, true);
              res.yes().then((data) => {
                //当没有提供url时直接关闭dialog
                if (!url) {
                  dispatchEvent2DataGrid(data);
                  return resolve();
                }
                if (!data || dsf.isEmptyObject(data) || (dsf.type(data) == 'array' && !data.length)) {
                  dsf.layer.message('请不要导入空数据', false)
                  return reject()
                }
                if (data) {
                  this.$http
                    .post(url, JSON.stringify(data), {
                      headers: {
                        "Content-Type": "application/json",
                        authorization_token: dsf.getToken()
                      }
                    })
                    .done((result) => {
                      if (!result.success) {
                        dsf.layer.message(result.message, false);
                        setTimeout(() => {
                          btnLoading(0, false);
                        }, 400)
                        return reject();
                      }
                      callback = callback || "submitCallBack";
                      //如果dialog里面的页面没有定义callback 方法
                      if (!_.isFunction(res.content[callback])) {
                        dispatchEvent2DataGrid(result.data);
                        return resolve();
                      }
                      //若定义了callback则调用
                      res.content[callback](result.data, (status) => {
                        if (status == false) {
                          setTimeout(() => {
                            btnLoading(0, false);
                          }, 400)
                          return reject();
                        } else {
                          return resolve();
                        }
                      });
                    })
                    .error((err) => {
                      dsf.layer.message("服务器异常", false);
                    });
                }
              }).catch(() => {
                setTimeout(() => {
                  btnLoading(0, false);
                }, 400)
                return reject()
              })
            })
          }
        },
        {
          text: "关闭"
        }
      ]
    });
  },
  //列通用预导入(上传文件成功后执行该函数)
  system_datagrid_common_import(args) {
    let datagrid = args.owner;
    let importOptions = datagrid.importOptions
    let [tableName, sheetIndex, rowIndex, columnIndex, callback] = args.params;
    if (!tableName) {
      return dsf.layer.message('预导入按钮tableName未配置！', false)
    }
    let that = this;
    $('<input type="file"></input>').on('change', function (e) {
      upload(this.files[0])
      $(this).off('change').remove()
    }).appendTo($(document.body)).click()

    function upload(file) {
      var formData = new FormData();
      formData.append("file", file);  //上传一个files对象
      var importAttr = {}
      for (const key in datagrid.queryString) {
        formData.append(key, datagrid.queryString[key])
      }
      if (tableName) importAttr.tableName = tableName
      importAttr.code = datagrid.$vm.nameSpace + '.' + datagrid.$vm.pageName + '.' + tableName
      if (sheetIndex) importAttr.sheetIndex = sheetIndex
      if (rowIndex) importAttr.rowIndex = rowIndex
      if (columnIndex) importAttr.columnIndex = columnIndex
      if (importOptions) importAttr.rowRules = importOptions
      importAttr && formData.append("importAttr", JSON.stringify(importAttr));

      const loading = dsf.layer.loading()
      $.ajax({//jQuery方法，此处可以换成其它请求方式
        url: dsf.url.getWebPath('/import/resolver', datagrid.$servicePrefix),
        dataType: "json",
        type: "post",
        data: formData,
        processData: false,//不去处理发送的数据
        contentType: false,//不去设置Content-Type请求头
        headers: { ...(dsf.getDefaultHttpHeader() || {}) },
        error: function (res) {
          dsf.layer.closeLoading(loading)
        },
        success: function (res) {
          dsf.layer.closeLoading(loading)
          showErrorTable.call(that, res)
        }
      })

      function showErrorTable(res) {
        if (!res.success) {
          return dsf.layer.message(res.message, false);
        }
        if (res.data.status == 1) {
          if (callback) {
            datagrid.$dispatch(callback, res)
          } else {
            datagrid.reloadData && datagrid.reloadData()
            dsf.layer.message('导入成功')
          }
          return
        }
        this.$openDialog({
          title: "预导入错误数据编辑",
          width: "80%",
          height: "500px",
          content: "DesCommonImportErrorDataEidt",
          center: true,
          params: {
            fileData: res.data,
            servicePrefix: datagrid.$servicePrefix
          },
          btns: [
            {
              text: "确定",
              handler: (res) => {
                let btnLoading = res.$dialog.btnLoading
                btnLoading(0, true);
                return res.yes().then(() => {
                  if (callback) {
                    datagrid.$dispatch(callback)
                  } else {
                    datagrid.reloadData && datagrid.reloadData()
                  }
                  dsf.layer.message('导入成功')
                  setTimeout(() => {
                    btnLoading(0, false);
                  })
                  return Promise.resolve();
                }).catch((err) => {
                  btnLoading(0, false);
                  return Promise.reject(err);
                })
              }
            },
            {
              text: '取消',
              handler: () => {
                let laoding = dsf.layer.loading()
                return dsf.http.get('/import/destroyCache', {
                  id: res.data.uuid
                }, true).always(() => {
                  dsf.layer.closeLoading(laoding)
                })
              }
            }
          ]
        })
      }
    }
  },
  //列表导出
  system_datagrid_export(args) {
    let isOpenExportSetting = args.params[0];
    let allowList = dsf.express.eval(args.params[1]);
    let exportFileName = args.params[2] || (isOpenExportSetting ? "导出数据" : "导出数据.xlsx");
    let exportRule = args.params[3];
    let url = args.params[4] || "";
    let exportParams = dsf.url.queryStringParse(args.params[5]) || {};
    let isAll = args.params[6] !== false;
    let serialNum = !!args.params[7];
    let nameSpace = args.owner?.$vm?.nameSpace || "";
    let pageName = args.owner?.$vm?.pageName || "";
    if (!url) {
      dsf.layer.pc.message("未查找到可用的导出接口地址", false);
      return;
    }
    let exportOptions = args.owner.exportOptions[exportRule];
    if (!exportOptions || exportOptions.length == 0) {
      //将表格列默认导出
      let options = [];
      _.each(args.owner?.$vm?.metadataDict, (dict) => {
        let name = dict.id.split(".");
        if (name.length >= 2) {
          let table = name[0];
          let field = name[1];
          if (!dict.hidden) {
            _.each(dict.valueAttributes, (va) => {
              if (va != 'value') {
                options.push({
                  id: table + "." + field + (dict.valueAttributes.length > 1 ? "_" + va : ''),
                  code: field,
                  caption: dict.name,
                  currentValueAttribute: va,
                  format: "",
                  table: table,
                  valueAttributes: dict.valueAttributes
                });
              }
            });
          }
        }
      });
      exportOptions = options;
    }
    function execExport(exportRule, fileName, extName, queryInfo, fields, exportParams) {
      let multipleSelection = args.owner.multipleSelection;
      if (!isAll && multipleSelection.length > 0) {
        let ids = ""
        dsf.layer.confirm({
          message: "是否只导出被选中的数据?",
          confirmButtonText: "是",
          cancelButtonText: "否",
        }).then(() => {
          let multipleSelection = args.owner.multipleSelection;
          ids = _.map(multipleSelection, "_id");
        }).catch(() => {

        }).finally(() => {
          download(exportRule, fileName, extName, queryInfo, fields, exportParams, ids);
        })
      }
      else {
        download(exportRule, fileName, extName, queryInfo, fields, exportParams, null);
      }
    }
    if (!exportOptions || exportOptions.length <= 0) {
      dsf.layer.pc.message("未查找到可用的导出信息配置", false);
      return;
    }
    if (isOpenExportSetting) {
      let tableExportResult = args.owner.tableExportResult || [];
      this.$openDialog({
        title: "导出设置",
        width: "700px",
        height: "500px",
        content: 'DsfExportSetting',
        params: {
          fileName: exportFileName,
          allow: allowList,
          fields: exportOptions,
          fieldIds: tableExportResult
        },
        btns: [
          {
            text: "确定",
            handler(com) {
              if (com && com.yes) {
                let { extName, fields, fieldIds } = com.yes();
                if (fields.length <= 0) {
                  dsf.layer.pc.message("请选择要导出的数据字段", false);
                  return false;
                }
                args.owner.$emit("update:tableExportResult", fieldIds);
                execExport(exportRule, exportFileName, extName, args.owner.getQueryInfo(), fields, exportParams);
                args.owner.$addCustom(args.owner.caption, {
                  'tableExportResult': fieldIds
                });
              }
            }
          }, {
            text: "取消"
          }
        ]
      })
    }
    else {
      let arr = exportFileName.split(".");
      let fileName, extName;
      if (arr.length == 1) {
        fileName = arr[0];
        extName = "";
      }
      else if (arr.length > 0) {
        fileName = arr.slice(0, arr.length - 1).join(".");
        extName = arr[arr.length - 1];
      }
      execExport(exportRule, fileName, extName, args.owner.getQueryInfo(), exportOptions, exportParams)
    }
    function download(exportRule, fileName, extName, query, fields, attachParams, ids) {
      // todo isAll为false时，提取导出的行的参数
      let exportUrl = dsf.url.getWebPath(url, args.owner.$servicePrefix);
      let queryInfo = args.owner.getQueryInfo();
      let params = {
        rule: args.owner.caption + "." + exportRule,
        query: JSON.stringify(queryInfo.query),
        order: JSON.stringify(queryInfo.order),
        filter: JSON.stringify(queryInfo.filter),
        data: null,
        fileName: fileName,
        extName: extName,
        fields: JSON.stringify(_.map(fields, "id")),
        params: JSON.stringify(attachParams),
        nameSpace: nameSpace,
        pageName: pageName,
        serialNum: serialNum ? 1 : 0
      };
      let queryStringForamt = dsf.url.queryStringStringify(args.owner.queryString);
      let exprotFormAction = exportUrl + (exportUrl.indexOf("?") > 0 ? "&" : "?") + queryStringForamt
      let fromHTML = `<form method="post">
                          <input type="text" name="rule">
                          <input type="text" name="query">
                          <input type="text" name="order">
                          <input type="text" name="fileName">
                          <input type="text" name="extName">
                          <input type="text" name="fields">
                          <input type="text" name="params">
                          <input type="text" name="namespace">
                          <input type="text" name="pageName">
                          <input type="text" name="filter">
                          <input type="text" name="ids">
                          <input type="text" name="serialNum">
                    </form>`;
      let formElement = $(fromHTML);
      formElement.attr("action", exprotFormAction)
      //导出规则
      formElement.find("input[name=rule]").val(params.rule);
      //查询条件
      formElement.find("input[name=query]").val(params.query);
      //排序
      formElement.find("input[name=order]").val(params.order);
      //导出文件名
      formElement.find("input[name=fileName]").val(params.fileName);
      //导出文件扩展名
      formElement.find("input[name=extName]").val(params.extName);
      //导出字段
      formElement.find("input[name=fields]").val(params.fields);
      //附加参数
      formElement.find("input[name=params]").val(params.params);
      //命名空间
      formElement.find("input[name=namespace]").val(params.nameSpace);
      //页面名称
      formElement.find("input[name=pageName]").val(params.pageName);
      //数据过滤
      formElement.find("input[name=filter]").val(params.filter);
      formElement.find("input[name=ids]").val(ids || "");
      formElement.find("input[name=serialNum]").val(params.serialNum);
      formElement.appendTo("body").submit().remove();
      // }
    }
  },
  //触发列表检索
  system_datagrid_search(args) {
    args.owner.$refs?.superSearchBar?.closeOrOpen?.(false);
    args.owner.search()
  },
  //设置列显示
  system_datagrid_columns_filter(args) {
    let elTable = args.owner.getElTable()
    // let $columns = elTable.realColumns;
    // let $filter = _.map(elTable.filterColumns, "columnId")
    this.$openDialog({
      title: "数据列过滤",
      width: "650px",
      height: "450px",
      content: 'DsfDataGridColumnsFilter',
      params: {
        columns: elTable.columnsTree,
        filter: args.owner.tableFilterResult || [],
      },
      btns: [
        {
          text: "确定",
          handler(com) {
            let cols = [];
            if (com && com.yes) {
              cols = com.yes();
            }
            if (!cols || cols.length == 0) {
              dsf.layer.message("请选择至少一列", false);
              return false;
            }
            else if (cols == 'all') {
              cols = [];
            }
            // args.owner.loading = true;
            args.owner.$nextTick(() => {
              args.owner.$emit("update:tableFilterResult", cols);
              args.owner.$addCustom(args.owner.caption, {
                'tableFilterResult': cols
              });
              elTable.headerReload();
              args.owner.$nextTick(() => {
                args.owner.reloadData()
              })
            });
          }
        }, {
          text: "取消"
        }
      ]
    })
  },

  //列表 end

  //子表 start
  // 子表插入行
  system_subtable_insert(args, $event) {
    let $table = args.owner;
    let firstRowInser = args.params[0];
    if (!$table.isUseDialogEdit) {
      let row = {};
      if (firstRowInser === true || firstRowInser === 'true') {
        $table.insertRow(row, 0, true);
      }
      else {
        $table.pushRow(row, true);
      }
    }
    else {
      $table.openDialogEdit({
        isAddNew: true,
        firstRowInser: firstRowInser
      })
    }
    $event.stopPropagation();
  },
  // 子表清空
  system_subtable_clear(args) {
    dsf.layer
      .confirm("是否要清空当前所有行？")
      .then(() => {
        args.owner.clearRows();
      })
      .catch(() => { });
  },
  // 子表选择
  system_subtable_datachoice(args) {
    let path = args.params[0];
    let title = args.params[1] || "数据选择";
    let [width, height] = args.params[2] ? args.params[2].split("/") : ["1000px", "70vh"];
    let getValueFn = args.params[3] ? args.owner.$vm[args.params[3]] : null;
    let selectValue = getValueFn?.(args.owner) || [];
    // args.owner.isOneToMany&&args.owner.isFormControl;
    args._self.$openDialog({
      title,
      width,
      height,
      params: {
        path,
        selectValue,
        choiceParams: {
          value: selectValue
        },
      },
      btns: [
        {
          text: "确定",
          handler: async (res) => {
            const data = await res.yes();
            if (data === false) {
              return false;
            }
            args.owner.$dispatch("datachoice-result", data);
            //名字不是很规范，选择并不一定是树结构，暂时先兼容，后需要取消掉
            args.owner.$dispatch("handleTreeInfo", data);
          }
        },
        {
          text: "取消"
        }
      ]
    });
  },
  // 子表导入
  system_subtable_import(args) {
    let subtable = args.owner;
    let response = args.response;
    let params = args.params[3];
    let listeners = args.params[4];
    if (subtable && response) {
      if (response.success) {
        let data = response?.data?.array;
        let keys = subtable.childMetaDataCodes;
        if (dsf.type(data) == "array") {
          const newData = [];
          for (let i = 0; i < data.length; i++) {
            let item = data[i];
            if (dsf.type(item) != "array") {
              dsf.layer.message("导入数据格式错误", false);
              return;
            } else {
              const newChildData = {
                // id: dsf.uuid(32)
              };
              for (let j = 0; j < keys.length; j++) {
                if (item.length > j) {
                  newChildData[keys[j]] = item[j];
                }
              }
              newData.push(newChildData);
            }
          }
          let addRows = (data, clear) => {
            if (clear) {
              subtable.clearRows();
            }
            subtable.pushRow(data);
            listeners && args.owner.$dispatch(listeners, data);
          }
          if (params == 1) {
            addRows(newData, true);
          } else if (params == 2) {
            addRows(newData);
          } else {
            dsf.layer
              .confirm({
                message: "导入数据是否覆盖现有数据?",
                confirmButtonText: "覆盖",
                cancelButtonText: "追加"
              })
              .then(() => {
                addRows(newData, true);
              })
              .catch(() => {
                addRows(newData);
              });
          }
          dsf.layer.message("导入完成", true);
        } else {
          dsf.layer.message("接口返回数据格式异常", false);
        }
      } else {
        dsf.layer.message("导入异常", false);
      }
    }
  },
  // 子表导出
  system_subtable_export(args) {
    // let isUseFrontEndData = args.params[0] || false;
    let isOpenExportSetting = args.params[0];
    let allowList = dsf.express.eval(args.params[1]);
    let exportFileName = args.params[2] || (isOpenExportSetting ? "导出数据" : "导出数据.xlsx");
    let exportRule = args.params[3];
    let url = args.params[4] || "";
    let exportParams = dsf.url.queryStringParse(args.params[5]) || {};
    let nameSpace = args.owner?.$vm?.nameSpace || "";
    let pageName = args.owner?.$vm?.pageName || "";
    // let data = null;
    // if (isUseFrontEndData) {
    //   data = args.owner.value;
    // }
    if (!url) {
      dsf.layer.pc.message("未查找到可用的导出接口地址", false);
      return;
    }
    let exportOptions = args.owner.exportOptions[exportRule];
    if (!exportOptions || exportOptions.length <= 0) {
      dsf.layer.pc.message("未查找到可用的导出信息配置", false);
      return;
    }
    if (isOpenExportSetting) {
      this.$openDialog({
        title: "导出设置",
        width: "700px",
        height: "500px",
        content: 'DsfExportSetting',
        params: {
          fileName: exportFileName,
          allow: allowList,
          fields: exportOptions
        },
        btns: [
          {
            text: "确定",
            handler(com) {
              if (com && com.yes) {
                let result = com.yes();
                if (result.fields.length <= 0) {
                  dsf.layer.pc.message("请选择要导出的数据字段", false);
                  return false;
                }
                download(exportRule, exportFileName, result.extName, null, result.fields, exportParams);
              }
            }
          }, {
            text: "取消"
          }
        ]
      })
    }
    else {
      let arr = fileName.split(".");
      let fileName, extName;
      if (arr.length == 1) {
        fileName = arr[0];
        extName = "";
      }
      else if (arr.length > 0) {
        fileName = arr.slice(0, arr.length - 1).join(".");
        extName = arr[arr.length - 1];
      }
      download(exportRule, fileName, extName, null, exportOptions, exportParams);
    }
    function download(exportRule, fileName, extName, query, fields, attachParams) {
      let exportUrl = dsf.url.getWebPath(url)
      let params = {
        // isUseFrontEndData: isUseFrontEndData,
        rule: args.owner.caption + "." + exportRule,
        query: "",
        data: "",
        fileName: fileName,
        extName: extName,
        fields: JSON.stringify(_.map(fields, "id")),
        params: JSON.stringify(attachParams),
        nameSpace: nameSpace,
        pageName: pageName
      };
      let fromHTML = `<form action="${exportUrl}" method="get">
                        <input type="text" name="rule">
                        <input type="text" name="query" >
                        <input type="text" name="fileName">
                        <input type="text" name="extName">
                        <input type="text" name="fields">
                        <input type="text" name="params">
                        <input type="text" name="namespace">
                        <input type="text" name="pageName">
                      </form>`;
      let formElement = $(fromHTML);
      formElement.find("input[name=isUseFrontEndData]").val(params.isUseFrontEndData ? "1" : "0");
      formElement.find("input[name=rule]").val(params.rule);
      formElement.find("input[name=query]").val(params.query);
      formElement.find("input[name=data]").val(params.data);
      formElement.find("input[name=fileName]").val(params.fileName);
      formElement.find("input[name=extName]").val(params.extName);
      formElement.find("input[name=fields]").val(params.fields);
      formElement.find("input[name=params]").val(params.params);
      formElement.find("input[name=namespace]").val(params.nameSpace);
      formElement.find("input[name=pageName]").val(params.pageName);
      formElement.appendTo("body").submit().remove();
    }
  },

  //子表 end

  //问卷 start
  // 问卷保存
  // system_qi_save(evt) {
  //   let form = evt._self.$form;
  //   if (form) {
  //     form.formSave();
  //   }
  // },
  //问卷 end

  //流程 start
  async system_flow_send(args) {
    let disposeIdea;
    if (args.targetButton.disabled) {
      return;
    }
    if (args._self && args._self.$vm && args._self.$vm.flows && args._self.$vm.flows.disposeIdea) {
      disposeIdea = args._self.$vm.flows.disposeIdea;
      disposeIdea.setDoSave(false);
    }
    let form = args._self.$form;
    args.targetButton.disabled = true;
    if (form) {
      let options = {
        isSilent: true,
        isRefresh: false,
        savedClose: false,
      };
      try {
        const res = await form.formSave(options);
        if (res && res.data) {
          const $dialog = args._self.$vm && args._self.$vm.$dialog;
          let Objects;
          if ($dialog && $dialog.dialogArgs && $dialog.dialogArgs.data) {
            Objects = $dialog.dialogArgs.data[0];
          }
          this.$refs.wfinfo && (await this.$refs.wfinfo.flowSend(res.data, args.targetButton, args.owner, Objects));
        } else {
          setTimeout(() => {
            args.targetButton.disabled = false;
          }, 200);
        }
        if (disposeIdea) {
          disposeIdea.setDoSave(true);
        }
      } catch (e) {
        console.log(e);
        // 防止网络请求很快的情况
        setTimeout(() => {
          args.targetButton.disabled = false;
        }, 200);
      }
    } else {
      dsf.layer.message("此页面不是表单", false);
    }
  },
  //流程退回
  async system_flow_sendBack(args) {
    if (args.targetButton.disabled) {
      return;
    }
    args.targetButton.disabled = true;
    this.$refs.wfinfo && (await this.$refs.wfinfo.getSendBackLink(args.params, args.targetButton));
  },
  //流程 end
  system_batch_flow_send,
  //弹出模态框
  system_flow_openDialog(args) {
    if (args.targetButton.disabled) {
      return;
    }
    let rows = args.owner.multipleSelection;
    if (rows && rows.length === 0 && Object.keys(args.data).length === 0) {
      dsf.layer.message("请选择至少一条数据", false);
      return;
    }
    let [title, url, id, sCurLinkID, sPID, size] = args.params;
    let width, height;
    if (size) {
      [width, height] = size.split(",");
    }

    let data = [];
    if (Object.keys(args.data).length > 0) {
      if (args.data[sPID || "sPID"] || this.$route.query.sCurLinkID) {
        data.push({
          // id: args.data[id || 'id'],
          sPID: args.data[sPID || "sPID"] || this.$route.query.sPID,
          sCurLinkID: args.data[sCurLinkID || "sCurLinkID"] || this.$route.query.sCurLinkID
        });
      }
    } else {
      rows.forEach((row) => {
        data.push({
          // id: row[id || 'id'],
          sPID: row[sPID || "sPID"],
          sCurLinkID: row[sCurLinkID || "sCurLinkID"]
        });
      });
    }
    args.targetButton.disabled = true;
    this.$openDialog({
      title: title || "",
      width: width || "80%",
      height: height || "500px",
      params: {
        path: url || "dsfa/wf/opion"
      },
      dialogArgs: {
        data: data,
        rowData: args.data
      },
      btns: []
    });
    // 防止网络请求很快的情况
    setTimeout(() => {
      args.targetButton.disabled = false;
    }, 200);
  },
  // 销毁
  system_flow_destroy(args) {
    if (args.targetButton.disabled) {
      return;
    }
    let [sPID] = args.params;
    const param = {
      sPID: args.data[sPID || 'sPID'] || this.$route.query.sPID,
    }
    args.targetButton.disabled = true;
    this.$http.post("wfr/destroy", param).then((res) => {
      if (res.data.state == 20000) {
        if (res.data.data && (res.data.data.Code === 20000 || res.data.data.Code === 22000)) {
          args._self.$vm.reloadData();
          dsf.layer.message("操作成功", true);
        } else {
          dsf.layer.message(res.data.data.Message || "操作失败", false);
        }
      } else {
        dsf.layer.message(res.data.message, false);
      }
    })
      .finally(() => {
        // 防止网络请求很快的情况
        setTimeout(() => {
          args.targetButton.disabled = false;
        }, 200);
      });
  },
  // 回收 强制办结
  system_flow_recovery(args) {
    if (args.targetButton.disabled) {
      return;
    }
    let [sPID, sCurLinkID] = args.params;
    const param = {
      sPID: args.data[sPID || 'sPID'] || this.$route.query.sPID,
      sCurLinkID: args.data[sCurLinkID || "sCurLinkID"] || this.$route.query.sCurLinkID,
    }
    if (args.targetButton.actionName === "system_flow_forceRecovery") {
      param.bForce = true;
    }
    args.targetButton.disabled = true;
    const url = args.targetButton.actionName === "system_flow_forceClose" ? "wfr/forceClose" : "wfr/recovery"
    this.$http.post(url, param).then((res) => {
      if (res.data.state == 20000) {
        if (res.data.data && (res.data.data.Code === 20000 || res.data.data.Code === 22000 || res.data.data.Code === 20006 || res.data.data.Code === 20007)) {
          args._self.$vm.reloadData();
          dsf.layer.message("操作成功", true);
        } else {
          dsf.layer.message(res.data.data.Message || "操作失败", false);
        }
      } else {
        dsf.layer.message(res.data.message, false);
      }
    })
      .finally(() => {
        // 防止网络请求很快的情况
        setTimeout(() => {
          args.targetButton.disabled = false;
        }, 200);
      });
  },
  system_flow_add_comment(args) {
    if (args.targetButton.disabled) {
      return;
    }
    args.targetButton.disabled = true;
    if (this.$listeners["click"]) {
      this.$dispatch("click", args._self);
    } else {
      this.$openDialog({
        title: "处理意见",
        width: dsf.global.$isMobile ? "100vw" : "800px",
        height: dsf.global.$isMobile ? "100vw" : "490px",
        content: dsf.global.$isMobile ? "DsfMobileFlowAddComment" : "DsfFlowAddComment",
        hasFooter: false,
        footerAlign: "center",
        params: {
          $vm: args._self.$vm,
          flow: {
            sPID: args.owner?.sPID || null,
            sCurLinkID: args.owner?.sCurLinkID || null,
          },
        },
        dialogArgs: {},
        btns: [],
      });
    }
    // 防止网络请求很快的情况
    setTimeout(() => {
      args.targetButton.disabled = false;
    }, 200);
  },
  system_navtree_delete(args) {
    let deleteData = args.data,
      navTreeCtrl = args.owner,
      tree = navTreeCtrl.$refs.tree;

    function loadOtherNode(setCk) {
      if (deleteData._id === navTreeCtrl.currNode._id) {
        let node = tree.getNode(deleteData);
        let nextNode = node.nextSibling || node.previousSibling || node.parent;
        if (nextNode) {
          navTreeCtrl.currNode = nextNode.data;
          navTreeCtrl.loadIframe(nextNode.data);

          if (setCk) {
            navTreeCtrl.$nextTick(function () {
              tree.setCurrentKey(nextNode.data._id);
            })
          }
        }
      }
    }

    args.owner
      .$confirm("是否要删除该数据")
      .then((result) => {
        if (deleteData) {
          if (navTreeCtrl.initDataSource == "4") {
            loadOtherNode(true);
            tree.remove(deleteData);
          } else if (navTreeCtrl?.$vm?.frames) {
            let params = args.params;
            let key = params[0] || "_id";
            let isTree = params[1] === true;
            let callback = params[2] || "";
            let view = navTreeCtrl?.$vm?.frames[navTreeCtrl.targetIframeName] || {};
            let post = {
              namespace: view.nameSpace,
              pageName: view.pageName,
              isTree: isTree,
              data: JSON.stringify([deleteData[key]]),
            };
            args._self.$http
              .post("/meta/list/delete", post, true)
              .then(({ data }) => {
                if (data && data.success) {
                  args.owner.$message("删除成功", true);
                  if (args.owner) {
                    loadOtherNode();
                    args.owner.reloadData();
                    args.owner.$nextTick(function () {
                      if (callback && args.owner.$vm[callback]) {
                        args.owner.$vm[callback](deleteData);
                      }
                    });
                  }
                } else {
                  args.owner.$message(data.message, false);
                }
              })
              .catch((ex) => {
                args.owner.$message("删除失败", false);
              });
          }
        }
      })
      .catch((ex) => {
        throw ex;
      });
  }
};
