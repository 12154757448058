var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "ds-control ds-form-item ds-datachoice",
      class: [_vm.getCss, { omitOpen: _vm.omitOpen }],
      attrs: { id: _vm.id },
    },
    [
      _vm.simple && _vm.readOnly && !_vm.isDesign
        ? [
            _c(
              "DsfTextProxy",
              _vm._b(
                {
                  attrs: { "show-label": false },
                  on: { click: _vm.omitChange },
                  model: {
                    value: _vm.$omitValue,
                    callback: function ($$v) {
                      _vm.$omitValue = $$v
                    },
                    expression: "$omitValue",
                  },
                },
                "DsfTextProxy",
                _vm._self.$props,
                false
              )
            ),
            _c(
              "span",
              { staticClass: "omitText", on: { click: _vm.omitChange } },
              [_vm._v(_vm._s(_vm.$omitText))]
            ),
            _vm._t("error", function () {
              return [
                _vm.errors.length > 0
                  ? _c("div", { staticClass: "ds-error-text" }, [
                      _vm._v(" " + _vm._s(_vm.errorsMsg) + " "),
                    ])
                  : _vm._e(),
              ]
            }),
          ]
        : [
            _vm.showLabel
              ? _c(
                  "DsfFieldLabel",
                  {
                    style: _vm.getLabelWidthStyle(),
                    attrs: {
                      mode: _vm.showDataCaptionMode,
                      "show-icon": _vm.showDataCaptionIcon,
                      trigger: _vm.showDataCaptionTrigger,
                      "data-caption": _vm.dataCapion,
                      "is-design": _vm.isDesign,
                      owner: _vm._self,
                    },
                  },
                  [_vm._v(" " + _vm._s(_vm.$t(_vm.label)) + " ")]
                )
              : _vm._e(),
            _c(
              "div",
              {
                staticClass: "ds-form-block",
                class: { hideAutoInput: !_vm.showAutoInput },
                style: _vm.getFormItemBlockMarginLeft(),
                on: { click: _vm.handleFocus },
              },
              [
                !_vm.readOnly
                  ? _c(
                      "div",
                      {
                        staticClass: "ds-datachoice-input",
                        class: { "is-disabled": _vm.disabled },
                        on: { click: _vm.clickHandler },
                      },
                      [
                        _vm.omitOpen && _vm.value.length
                          ? _c(
                              "div",
                              [
                                _c(
                                  "DsfTextProxy",
                                  _vm._b(
                                    {
                                      attrs: { "show-label": false },
                                      model: {
                                        value: _vm.$omitValue,
                                        callback: function ($$v) {
                                          _vm.$omitValue = $$v
                                        },
                                        expression: "$omitValue",
                                      },
                                    },
                                    "DsfTextProxy",
                                    _vm._self.$props,
                                    false
                                  )
                                ),
                                _c("span", { staticClass: "omitText" }, [
                                  _vm._v(_vm._s(_vm.$omitText)),
                                ]),
                              ],
                              1
                            )
                          : _c(
                              "div",
                              {
                                ref: "tagsInput",
                                staticClass: "ds-datachoice-tags",
                                style: { width: "calc(100% - 26px)" },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.value.length,
                                        expression: "value.length",
                                      },
                                    ],
                                    ref: "tags",
                                    staticClass: "tags_div",
                                  },
                                  _vm._l(_vm.value, function (el) {
                                    return _c(
                                      "el-tag",
                                      {
                                        key: el.value,
                                        staticClass: "el-tag--info",
                                        attrs: {
                                          size: "small",
                                          closable: true,
                                          "disable-transitions": true,
                                        },
                                        on: {
                                          click: function ($event) {
                                            $event.stopPropagation()
                                          },
                                          close: function ($event) {
                                            return _vm.removeTag(el)
                                          },
                                        },
                                      },
                                      [_vm._v(" " + _vm._s(el.text) + " ")]
                                    )
                                  }),
                                  1
                                ),
                                _vm.autoComplete
                                  ? _c("el-autocomplete", {
                                      ref: "input",
                                      style: { width: "100%" },
                                      attrs: {
                                        "highlight-first-item": "",
                                        "popper-class": [_vm.popupClass].join(
                                          " "
                                        ),
                                        "trigger-on-focus": _vm.triggerOnFocus,
                                        debounce: _vm.delay,
                                        "fetch-suggestions": _vm.querySearch,
                                      },
                                      on: {
                                        select: _vm.selectHandler,
                                        focus: _vm.handleFocus,
                                        blur: _vm.handleBlur,
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (ref) {
                                              var item = ref.item
                                              return [
                                                _vm._t(
                                                  "autocompletelist",
                                                  function () {
                                                    return [
                                                      _c("div", [
                                                        _vm._v(
                                                          _vm._s(item.text)
                                                        ),
                                                      ]),
                                                    ]
                                                  },
                                                  { item: item }
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                      model: {
                                        value: _vm.inputStr,
                                        callback: function ($$v) {
                                          _vm.inputStr = $$v
                                        },
                                        expression: "inputStr",
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                        _vm.isDesign || _vm.dialogUrl
                          ? _c(
                              "span",
                              {
                                staticClass: "el-input__suffix",
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.openDialog.apply(null, arguments)
                                  },
                                },
                              },
                              [_vm._m(0)]
                            )
                          : _vm._e(),
                      ]
                    )
                  : _vm.readOnly
                  ? _c(
                      "div",
                      {
                        staticClass: "ds-form-readonly",
                        on: { click: _vm.omitChange },
                      },
                      [
                        _c(
                          "DsfTextProxy",
                          _vm._b(
                            {
                              attrs: { "show-label": false },
                              model: {
                                value: _vm.$omitValue,
                                callback: function ($$v) {
                                  _vm.$omitValue = $$v
                                },
                                expression: "$omitValue",
                              },
                            },
                            "DsfTextProxy",
                            _vm._self.$props,
                            false
                          )
                        ),
                        _c("span", { staticClass: "omitText" }, [
                          _vm._v(_vm._s(_vm.$omitText)),
                        ]),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._t("error", function () {
                  return [
                    _vm.errors.length > 0
                      ? _c("div", { staticClass: "ds-error-text" }, [
                          _vm._v(" " + _vm._s(_vm.errorsMsg) + " "),
                        ])
                      : _vm._e(),
                  ]
                }),
              ],
              2
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "el-input__suffix-inner" }, [
      _c("i", { staticClass: "el-input__icon el-icon-search" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }