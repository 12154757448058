var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("iframe", {
    ref: "iframe",
    style: {
      width: "100%",
      height: "100%",
      "border-style": "none",
      display: "block",
    },
    attrs: { src: _vm.iframeSrc },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }