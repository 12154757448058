<template>
  <div v-if="isDesign" class="ds-control ds-form-item ds-design-hide ds-textbox" :class="getCss">
    <template>
      <label v-if="showLabel" class="ds-form-label">流程信息</label>
      <div class="ds-form-block">
        <el-input v-model="chooseFlow.name" type="text" :disabled="true" />
      </div>
    </template>
  </div>
</template>
<script>
export default dsf.component({
  name: "DsfFlowInfo",
  ctrlCaption: "流程信息",
  mixins: [$mixins.formControl, $mixins.flowInfo],
});
</script>
