<template>
  <div class="page-home-default-header" :class="{ mini: mini }">
    <div ref="logoBox" class="logo-box">
      <slot name="logo">
        <a :href="indexUrl">
          <img
            ref="logo"
            v-if="logo"
            :src="$logo | imgFormat"
            alt=""
            class="logo"
            @error="logoLoadSuccess = true"
            @load="logoLoaded"
          />
          <div v-if="title && title.length" class="site-name">{{ title }}</div>
        </a>
      </slot>
    </div>
    <div class="header-right">
      <DsfDefaultHomeMenuBar
        v-if="!noMenu"
        v-show="showMenu"
        :mini="mini"
        :menu-list="menuList"
        :active-key="activeKey"
        :width="menuWidth"
      >
        <slot></slot>
      </DsfDefaultHomeMenuBar>
      <div ref="headerUser" style="flex: none; height: 100%">
        <slot name="home-menu-right">
          <div class="header-right-user">
            <div v-if="!dropdownConfig.length">
              <dsf-page-home-header-user>
                <div class="username">
                  欢迎您,{{ dsf.cookies.get("user_name") }}
                  <i
                    v-if="dropdownConfig.length"
                    :class="[
                      showDropdown ? 'icon-shangjiantou' : 'icon-xiajiantou',
                    ]"
                    class="iconfont"
                  />
                </div>
              </dsf-page-home-header-user>
            </div>
            <dsf-page-home-header-user-info-panel
              v-else
              :dropdown-config="dropdownConfig"
              :change-user-info = "changeUserInfo"
            >
              <dsf-page-home-header-user slot="reference">
                <div class="username">
                  欢迎您,{{ dsf.cookies.get("user_name") }}
                  <i
                    v-if="dropdownConfig.length"
                    :class="[
                      showDropdown ? 'icon-shangjiantou' : 'icon-xiajiantou',
                    ]"
                    class="iconfont"
                  />
                </div>
              </dsf-page-home-header-user>
            </dsf-page-home-header-user-info-panel>
          </div>
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
import DsfDefaultHomeMenuBar from "./aside/menuBar";

export default {
  name: "DsfDefaultHomeHeader",
  inject: ["homeRoot"],
  components: {
    DsfDefaultHomeMenuBar,
  },
  props: {
    // true 头部不放菜单，菜单全部居于侧边栏
    noMenu: {
      type: Boolean,
      default: false,
    },
    dropdownConfig: {
      type: Array,
      default() {
        return [];
      },
    },
    title: {
      default: () => dsf.config.setting_public_logo_title,
    },
    changeUserInfo: {
      type: [Function,Boolean],
      default(){
        return false
      }
    }
  },
  data() {
    return {
      logo: dsf.config.setting_public_logo_image,
      // title: dsf.config.setting_public_logo_title,
      headIcon: "",
      defaultHeader: dsf.config.setting_public_user_default_header,
      showIcon: false,
      showDropdown: false,
      menuWidth: 0,
      logoLoadSuccess: false,
    };
  },
  computed: {
    indexUrl() {
      return location.href.replace(/#.*/, "");
    },
    menuList() {
      return this.homeRoot.$menuList || [];
    },
    activeKey() {
      if (this.homeRoot.menuKey.length > 0) {
        return this.homeRoot.menuKey[0];
      }
      return "";
    },
    showMenu() {
      // return this.menuList.length && (!this.logo || this.logoLoadSuccess);
      return this.menuList.length;
    },
    mini() {
      return dsf.themeConfig.setting.defaultHeaderMini;
    },
    $logo() {
      return this.$replace(this.logo);
    },
  },
  watch: {
    noMenu: {
      handler(to) {
        this.homeRoot.isEncircleMenu = to;
      },
      immediate: true,
    },
    mini(to) {
      this.homeRoot.setPosition("top", to ? "60px" : "94px");
      this.$nextTick(() => {
        this.updateMenuWidth();
      });
    },
    showMenu() {
      this.$nextTick(() => {
        this.updateMenuWidth();
      });
    },
  },
  created() {
    this.homeRoot.setPosition("top", this.mini ? "60px" : "94px");
    this.initHeadIcon();
  },
  mounted() {
    this.updateMenuWidth();
    this.onresize = () => {
      this.updateMenuWidth();
    };
    window.addEventListener("resize", this.onresize);
  },
  destroyed() {
    window.removeEventListener("resize", this.onresize);
  },
  methods: {
    // 初始化头像，从cookie中获取
    initHeadIcon() {
      const headIcon = dsf.getCookie("photoRelativePath");
      if (headIcon) {
        this.headIcon = decodeURIComponent(headIcon);
      }
    },
    // 头像加载成功时
    headLoadSuccess() {
      this.showIcon = true;
    },
    // 头像加载失败时
    headLoadError() {
      this.showIcon = false;
    },
    // 用户头像下的菜单点击
    handleCommand(index) {
      const item = this.dropdownConfig[+index];
      this.$emit("dropdown-click", item);
    },
    // logo载入成功，重新计算菜单宽度
    logoLoaded(e) {
      this.$nextTick(() => {
        // ie下首次载入logo时，图片宽度无法撑开父级div，但只要稍稍改变图片属性即可正常渲染。
        e.target.style.display = "block";

        this.logoLoadSuccess = true;
        this.updateMenuWidth();
      });
    },
    // 更新菜单宽
    updateMenuWidth() {
      if (this.showMenu) {
        this.$nextTick(() => {
          let logoWidth = (this.$refs.logoBox?.offsetWidth || 0) + 20;
          let userWidth = this.$refs.headerUser?.offsetWidth || 0;
          this.menuWidth = this.$el.offsetWidth - logoWidth - userWidth;
        });
      } else {
        this.menuWidth = 0;
      }
    },
  },
};
</script>