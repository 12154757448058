var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "page-home-default-aside-menu-children",
      class: { show: _vm.show },
      style: _vm.style,
      on: { mouseenter: _vm.mouseenter, mouseleave: _vm.mouseleave },
    },
    [
      _c(
        "div",
        { staticClass: "box" },
        [
          _c(
            "transition",
            { attrs: { name: "el-zoom-in-left" } },
            [
              _c("header-menu-children", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.show,
                    expression: "show",
                  },
                ],
                attrs: { list: _vm.list },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }