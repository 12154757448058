<!--
 * @Descripttion: 雷达图
 * @Author: zhanghang
 * @Date: 2021-07-30 17:04:00
 * @LastEditors: zhanghang
 * @LastEditTime: 2022-03-11 11:15:15
-->
<template>
  <div class="ds-control ds-chart" :style="{ width, height }">
    <div class="chart" ref="chart">我是雷达图</div>
  </div>
</template>
<script>
import defaultOptions from "../../output/chartDefault/radar.js";
// 需要处理legend data
defaultOptions.legend.data = defaultOptions.series.reduce((arr, item) => [...arr, ...item.data], []).map((item) => item.name);
const cloneDefault = _.cloneDeep(defaultOptions);
export default dsf.component({
  name: "DsfPlatformRadar",
  ctrlCaption: "雷达图表",
  mixins: [$mixins.layout, $mixins.chart],
  props: {
    //自定义配置总览
    chart: {
      type: Object,
      default() {
        return defaultOptions;
      }
    }
  },
  data() {
    return {
      cloneDefault
    };
  },
  methods: {
    // 解析数据
    analysis(data) {

      let {indicator,radar} = data

      let series = _.cloneDeep(this.chart.series[0]);
      this.chart.radar[0].indicator = indicator

      const arr = radar.map((item) => item.name);
      this.chart.legend.data = arr;

      series.data = radar;

      this.$set(this.chart.series, 0, series);
    }
  }
});
</script>
