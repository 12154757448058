<template>
  <div class="select-dept-tree">
    <div v-if="showSearch" class="dsf-tree-search">
      <el-input v-model="searchKey" placeholder="输入名称" @input="search">
        <i slot="suffix" class="iconfont el-input__icon icon-Magnifier"></i>
      </el-input>
    </div>
    <div
      class="dsf-select-tree"
      :style="{ height: showSearch ? '340px' : '100%' }"
    >
      <el-tree
        ref="treeForm"
        :data="treeData"
        :props="defaultProps"
        node-key="id"
        show-checkbox
        :default-expand-all="true"
        :default-checked-keys="checked"
        :check-strictly="multi ? false : true"
        :check-on-click-node="true"
        :expand-on-click-node="false"
        :filter-node-method="filterData"
        @check-change="selectTree"
      >
        <div slot-scope="{ node, data }" class="custom-tree-node">
          <template v-if="data[defaultProps.children] !== undefined">
            <i
              class="iconfont"
              :class="[node.expanded ? 'icon-dakai' : 'icon-wenjianjia']"
            />
            <span v-html="dsf.safe.xss(data[defaultProps.label])"></span>
          </template>
          <template v-else>
            <i class="iconfont icon-shiyongwendang" />
            <span v-html="dsf.safe.xss(data[defaultProps.label])"></span>
          </template>
        </div>
      </el-tree>
    </div>
  </div>
</template>
<script>
export default {
  name: "SelectTree",
  props: {
    treeData: {
      type: Array,
      default() {
        return [];
      },
    },
    select: {
      type: [Object, Array],
      default() {
        return {};
      },
    },
    checked: {
      type: Array,
      default() {
        return [];
      },
    },
    showSearch: {
      type: Boolean,
      default() {
        return false;
      },
    },
    multi: {
      type: Boolean,
      default() {
        return false;
      },
    },
    formSearchKey: {
      type: String,
      default() {
        return "";
      },
    },
  },
  data() {
    return {
      searchKey: "",
      defaultProps: {
        children: "children",
        label: "label",
      },
    };
  },
  watch: {
    formSearchKey(val) {
      if (!this.showSearch) {
        this.$refs.treeForm.filter(val);
      }
    },
    checked(val) {
      this.$refs.treeForm.setCheckedKeys(val);
    },
  },
  created() {},
  mounted() {},
  methods: {
    selectTree(data, checked, node) {
      // 单选 必选 不能取消选中
      if (!this.multi && this.select[0] && this.select[0].Selected === 2) {
        this.$refs.treeForm.setCheckedKeys([this.select[0].id]);
      } else if (checked == true && !this.multi) {
        this.$refs.treeForm.setCheckedNodes([data]);
      } else if (data.Selected === 2) {
        const keys = this.$refs.treeForm.getCheckedKeys();
        keys.push(data.id);
        this.$refs.treeForm.setCheckedKeys(keys);
      }
      let nodes = this.$refs.treeForm.getCheckedNodes();
      nodes = nodes.filter((node) => !node.disabled);
      this.$emit("update:select", nodes);
    },
    filterData(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },
    search() {
      this.$refs.treeForm.filter(this.searchKey);
    },
  },
};
</script>
