var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ds-control ds-qrcode" },
    [
      _c(
        "div",
        {
          staticClass: "ds-qrcode-box",
          style: _vm.boxStyle,
          attrs: { border: _vm.border, shadow: _vm.shadow },
        },
        [
          _c("el-image", {
            attrs: { src: _vm._f("imgFormat")(_vm.qrSrc) },
            on: { load: _vm.loadSuccess },
            scopedSlots: _vm._u([
              {
                key: "placeholder",
                fn: function () {
                  return [
                    _c("div", { staticClass: "ds-qrcode-box-error" }, [
                      _vm._v("加载中..."),
                    ]),
                  ]
                },
                proxy: true,
              },
              {
                key: "error",
                fn: function () {
                  return [
                    _c("div", { staticClass: "ds-qrcode-box-error" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.loading ? "加载中..." : "加载失败") +
                          " "
                      ),
                    ]),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
          _c(
            "div",
            { staticClass: "ds-qrcode-box-message", class: [_vm.qrStatus] },
            [
              _vm.qrStatus === "SUCCESS"
                ? _c("i", { staticClass: "iconfont icon-gouxuan" })
                : _vm.qrStatus === "FAIL"
                ? _c("i", { staticClass: "iconfont icon-shanchu1" })
                : _vm._e(),
              _c("span", [_vm._v(_vm._s(_vm.qrTips))]),
            ]
          ),
        ],
        1
      ),
      _vm._t("tips", function () {
        return [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.tips,
                  expression: "tips",
                },
              ],
              staticClass: "ds-qrcode-tips",
            },
            [_vm._v(_vm._s(_vm.tips))]
          ),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }