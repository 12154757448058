<template>
  <el-image class="dsf-image" v-bind="$props" @load="$emit('load', $event)" @error="$emit('error', $event)">
    <template #placeholder>
      <slot name="placeholder">
        <i class="el-icon-loading"></i>
      </slot>
    </template>
    <template #error>
      <slot name="error">
        <img v-if="defaultImg" class="dsf-image-fail" :src="defaultImg" alt="">
        <div v-else class="el-image__error">暂无图片</div>
      </slot>
    </template>
  </el-image>
</template>

<script>
export default {
  name: "DsfImage",
  props: {
    // 图片源，同原生
    src: {
      type: String,
      required: false
    },
    // 同原生 object-fit
    // fill / contain / cover / none / scale-down
    fit: {
      type: String,
      default: 'cover'
    },
    // 原生 alt
    alt: {
      type: String,
      required: false
    },
    // 原生 referrerPolicy
    referrerPolicy: {
      type: String,
      required: false
    },
    // 是否开启懒加载
    lazy: {
      type: Boolean,
      default: false
    },
    // 开启懒加载后，监听 scroll 事件的容器
    // 最近一个 overflow 值为 auto 或 scroll 的父元素
    scrollContainer: {
      type: [String, HTMLElement],
      required: false
    },
    // 开启图片预览功能
    previewSrcList: {
      type: Array,
      required: false
    },
    zIndex: {
      type: Number,
      default: 2000
    },
    /**************/
    // 加载失败的图片
    errorSrc: {
      type: String,
      required: false
    }
  },
  computed: {
    defaultImg() {
      return this.errorSrc || dsf.config.setting_public_card_default_img;
    }
  }
}
</script>
