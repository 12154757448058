var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "ds-data-grid-columns-filter" }, [
    _c(
      "div",
      { staticClass: "left" },
      [
        _c("div", { staticClass: "title" }, [_vm._v("可选列")]),
        _c(
          "dsf-virtual-scroll",
          [
            _c("el-tree", {
              ref: "tree",
              attrs: {
                data: _vm.columns,
                "default-checked-keys": _vm.defaultCheckedKeys,
                "node-key": "id",
                "show-checkbox": "",
                "default-expand-all": "",
              },
              on: { "check-change": _vm.treeChange },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (ref) {
                    var node = ref.node
                    var data = ref.data
                    return _c("span", {}, [
                      _c("span", [_vm._v(_vm._s(node.label))]),
                      !node.parent.label && data.fixed
                        ? _c(
                            "span",
                            { staticStyle: { "margin-left": "5px" } },
                            [_vm._v("[锁定]")]
                          )
                        : _vm._e(),
                    ])
                  },
                },
              ]),
            }),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "right" },
      [
        _vm._m(0),
        _c(
          "dsf-virtual-scroll",
          [
            _c("el-tree", {
              attrs: {
                data: _vm.selectedFields,
                "node-key": "id",
                "empty-text": "请在左侧选择列",
                draggable: "",
                "default-expand-all": "",
                "allow-drop": _vm.allowDrop,
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (ref) {
                    var node = ref.node
                    var data = ref.data
                    return _c("span", {}, [
                      _c("span", [_vm._v(_vm._s(node.label))]),
                      !node.parent.level && data.fixed
                        ? _c(
                            "span",
                            { staticStyle: { "margin-left": "5px" } },
                            [_vm._v("[锁定]")]
                          )
                        : _vm._e(),
                      _c("i", {
                        staticClass: "closeBt iconfont icon-guanbi2",
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.close(node)
                          },
                        },
                      }),
                    ])
                  },
                },
              ]),
            }),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title" }, [
      _vm._v(" 已选列 "),
      _c("span", { staticClass: "tips" }, [_vm._v("拖动列可排序")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }