<!--
 * @Descripttion: 
 * @Author: zhanghang
 * @Date: 2022-01-21 17:36:32
 * @LastEditors: zhanghang
 * @LastEditTime: 2022-03-30 14:37:45
-->
<template>
  <div class="ds-control ds-chart" :style="{ width, height }">
    <div class="chart" ref="chart">我是地图图表</div>
    <template v-if="isDesign || (!isDesign && slots[0].controls.length > 0)">
      <div slot-name="default" class="ds-chart-slot">
        <slot name="default"></slot>
      </div>
    </template>
  </div>
</template>

<script>
import defaultOptions from "../../output/chartDefault/mapChart.js";
defaultOptions.legend.data = defaultOptions.series.map((item) => item.name);
const cloneDefault = _.cloneDeep(defaultOptions);
export default dsf.component({
  name: "DsfPlatformMapChart",
  ctrlCaption: "地图图表",
  mixins: [$mixins.layout, $mixins.chart],
  props: {
    //自定义配置总览
    chart: {
      type: Object,
      default() {
        return defaultOptions;
      },
    },
    width: {
      type: String,
      default: "700px",
    },
    height: {
      type: String,
      default: "600px",
    },
  },
  data() {
    return {
      cloneDefault,
    };
  },
  methods: {
    reset() {
      // 暂满足四川党校用，现忙后续再开发
      let mapChart = this.chartObj;
      let url = "$/js/libs/echarts/mapCoord/sichuan.json";
      this.dsf.http.get(url, {}).done((scJson) => {
        mapChart.hideLoading();
        let charts = window.$echarts || window.echarts;
        charts.registerMap("coord", scJson);
        this.chartObj && this.chartObj.setOption(this.chart, true);
      });
    },
    analysis(data) {
      this.chart.series[0].data = data;
      /**
       * setOption 二开是用于：
       * 处理地图的formatter啥的
       * params.args.options.tooltip.formatter = function (item) {
       *  if (item && item.data && !dsf.isEmptyObject(item.data)) {
       *     return "<div class='tooltipBox'>" + item.name + "：<br/>已办班次：" + (item.data.classNum || 0) + "<br/>录取学员：" + (item.data.matriculateNum || 0) + "人<br/>在校：" + (item.data.readingNum || 0) + "人<br/>毕业：" + (item.data.finishNum || 0) + "人</div>"
       *   } else {
       *     return "<div class='tooltipBox'>" + item.name + "：<br/>暂无数据</div>"
       *   }
       * }
       * 或者高亮颜色为主题色 this.$root.currentTheme.normal
       */
      this.$dispatch("setOption", {
        options: this.chart,
        vm: this,
        data: data,
      });
      this.reset();
    },
  },
});
</script>
