// 转换平台后端默认的上传图片返回值
export default {
  install(Vue) {
    Vue.filter('imgFormat', function (v, isAbsolute = false) {
      if (dsf.isUnDef(v) || v == '') {
        return '';
      }
      let obj = v;
      if (dsf.type(obj) == 'string') {
        if (obj.indexOf('relativePath') == -1 || obj.indexOf('absolutePath') == -1) {
          return v;
        }
        return dsf.getFilePath(obj, isAbsolute);
      }
      return '';
    })
  }
}