<template>
  <div
    class="dsf-platform-fixed-menu"
    :class="rootClass"
    :id="id"
    :style="[getBackgroundStyle, rootStyle]"
  >
    <div class="dsf-platform-fixed-menu-list">
      <template v-for="menu in menuList">
        <nav-menu-item :key="menu._id" :item="menu">
          <el-popover
            v-if="menu.type === 'picture'"
            :width="menu.showPictureWidth || 200"
            :placement="position == 'right' ? 'left' : 'right'"
            trigger="hover">
            <div slot="reference" class="dsf-platform-fixed-menu-popover"></div>
            <el-image
              :src="menu.showPicture | imgFormat"
              :style="{
                height: menu.showPictureHeight || '200px'
              }"
              fit="cover"
            />
          </el-popover>
          <el-popover
            v-else-if="menu.type === 'tips'"
            :width="200"
            :placement="position == 'right' ? 'left' : 'right'"
            trigger="hover">
            <div v-html="getHtml(menu)"></div>
            <div slot="reference" class="dsf-platform-fixed-menu-popover"></div>
          </el-popover>
        </nav-menu-item>
      </template>
    </div>
  </div>
</template>

<script>
import NavMenuItem from "_platform/pc/bhc/nav/items/navMenuItem";

const defaultData = [
  {
    _id: 1,
    picture: '',
    icon: 'icon-zhinan',
    color: '',
    foreColor: '',
    openType: '1',
    name: '操作指南',
    url: ''
  },
  {
    _id: 2,
    picture: '',
    icon: 'icon-erweima',
    color: '',
    foreColor: '',
    openType: '1',
    name: '移动二维码',
    url: ''
  },
  {
    _id: 3,
    picture: '',
    icon: 'icon-lianxifangshi',
    color: '',
    foreColor: '',
    openType: '1',
    name: '联系方式',
    type: 'backTop',
    url: ''
  },
  {
    _id: 4,
    picture: '',
    icon: 'icon-fankui',
    color: '',
    foreColor: '',
    openType: '1',
    name: '用户反馈',
    url: ''
  },
  {
    _id: 5,
    picture: '',
    icon: 'icon-fanhuidingbu1',
    color: '',
    foreColor: '#999',
    openType: '1',
    name: '返回顶部',
    type: 'backTop',
    url: ''
  },
];

export default dsf.component({
  name: "DsfPlatformFixedMenu",
  ctrlCaption: "悬浮菜单",
  mixins: [$mixins.control],
  components: {
    NavMenuItem
  },
  props: {
    // 页面宽度
    pageWidth: {
      type: String,
      default: '100%'
    },
    // 位置：left / right
    position: {
      type: String,
      default: 'right'
    },
    // 是否强制使用默认样式
    useDefaultStyle: {
      type: Boolean,
      default: false
    },
    // 请求来源url
    dataSourceUrl: {
      type: String,
      default: ''
    },
    // 配置追加
    dataSourceList: {
      type: Array,
      default() {
        return defaultData
      }
    },
    /*****样式*****/
    // 鼠标特效
    hoverAnimation: {
      type: String,
      default: '-'
    },
    // 默认图标
    defaultIcon: {
      type: String,
      default: ''
    },
    // 展示优先级。img图片优先，icon图标优先
    showPriority: {
      type: String,
      default: 'img'
    },
    menuHasBg: {
      type: Boolean,
      default: true
    },
    menuBgColor: {
      type: String,
      default: 'transparent'
    },
    menuBgShadow: {
      type: Boolean,
      default: false
    },
    menuColor: {
      type: String,
      default: 'theme'
    },
    menuShadow: {
      type: Boolean,
      default: true
    },
    menuIconSize: {
      type: String,
      default: '40px'
    },
    menuTextSize: {
      type: String,
      default: '15px'
    },
    // 按钮尺寸
    menuBtSize: {
      type: String,
      default: '100px'
    },
    // 按钮圆角
    menuBtRadius: {
      type: String,
      default: '0'
    },
    // 二次开发传入的方法。
    itemClick: {
      type: Function,
      required: false
    },
    clickBefore: {
      type: Function,
      required: false
    }
  },
  data() {
    return {
      id: 'FixedMenu-' + dsf.uuid(16)
    }
  },
  computed: {
    rootClass() {
      let res = [this.hoverAnimation];
      if (!this.isDesign) {
        res.push('fixed');
      }
      if (this.menuHasBg) {
        res.push('hasBg')
        if (this.menuBgColor == 'theme') {
          res.push('bgTheme')
        }
      }
      if (this.menuBgShadow) {
        res.push('bgShadow')
      }
      if (this.menuShadow) {
        res.push('iconShadow')
      }
      if (this.menuColor == 'theme') {
        res.push('iconTheme')
      }
      return res;
    },
    rootStyle() {
      return {
        width: this.menuBtSize
      };
    },
    itemStyle() {
      return {
        width: '100%'
      };
    },
    itemBgStyle() {
      let res = {
        'width': this.menuIconSize,
        'height': this.menuIconSize
      };
      if (this.menuHasBg && this.menuBgColor != 'theme') {
        res['background-color'] = this.menuBgColor;
      }
      return res;
    },
    itemBtStyle() {
      return {
        'width': this.menuBtSize,
        'height': this.menuBtSize,
        'border-radius': this.menuBtRadius
      }
    },
    itemIconStyle() {
      let res = {
        'font-size': this.menuIconSize
      };
      if (this.menuColor != 'theme') {
        res['color'] = this.menuColor;
      }
      return res;
    },
    itemTextStyle() {
      return {
        'font-size': this.menuTextSize
      };
    },
    menuList() {
      return this.dataSourceList;
    },
    realPageWidth() {
      let w = this.pageWidth;
      if (!w || w === '100%' || w === '100vw') {
        return '100%';
      }
      return w;
    }
  },
  watch: {
    'realPageWidth'() {
      if (!this.isDesign) {
        this.updateStyle();
      }
    },
    'position'(to) {
      if (!this.isDesign) {
        this.updateStyle();
      } else if (this.$el) {
        $(this.$el.parentElement).css({
          'width': 'auto',
          'overflow': 'visible',
          'position': 'fixed',
          'top': '50%',
          [to]: '260px',
          [to === 'left' ? 'right' : 'left']: 'auto',
          'z-index': '2000',
          'transform': 'translate(0, -50%)'
        });
      }
    }
  },
  mounted() {
    if (!this.isDesign) {
      document.body.appendChild(this.$el);
      let styleDom = document.createElement('style');
      this.styleDom = styleDom;
      document.body.appendChild(styleDom);
      this.$nextTick(() => {
        this.updateStyle();
      });
    } else {
      $(this.$el.parentElement).css({
        'width': 'auto',
        'overflow': 'visible',
        'position': 'fixed',
        'top': '50%',
        [this.position]: '260px',
        'z-index': '2000',
        'transform': 'translate(0, -50%)'
      });
    }
  },
  destroyed() {
    this.$el?.remove();
    this.styleDom?.remove();
  },
  methods: {
    reloadData() {
      this.init();
    },
    init() {
      if (this.isDesign) {
        return;
      }
      if (!this.dataSourceUrl) {
        this.data = [];
        return;
      }
      this.loading = true;
      this.$http.get(this.dataSourceUrl).done(({success, data, message}) => {
        if (success) {
          this.data = data;
          this.loading = false;
        } else {
          dsf.layer.message(message, false);
        }
      }).error(err => {
        dsf.layer.message(err.message, false);
      }).always(() => {
        this.isLoading = false;
      });
    },
    getImgUrl({ picture, icon }) {
      if (this.showPriority == 'img') {
        return picture;
      }
      if (!icon || icon == '') {
        return picture;
      }
      return '';
    },
    getItemStyle({ color }) {
      if (this.useDefaultStyle || !this.menuHasBg) {
        return {};
      }
      if (color != '') {
        return {
          'background': color
        }
      }
      return {};
    },
    getIconStyle({ foreColor }) {
      if (this.useDefaultStyle) {
        return {};
      }
      if (foreColor != '') {
        return {
          'color': foreColor
        };
      }
      return {};
    },
    getIcon(icon) {
      if (!icon || icon == '') {
        icon= this.defaultIcon
      }
      if (!icon) return '';
      return !icon.indexOf('icon-') ? icon : 'icon-' + icon;
    },
    getHtml({ showTemplate, showContent }) {
      return dsf.safe.xss(showTemplate.replace('[text]', `<span style="color: #2af;">${showContent}</span>`));
    },
    openPage(menu) {
      let url = menu.url;
      if (!url || url == '') {
        return;
      }
      if (url.indexOf('#') == 0 && url.indexOf('#/') != 0) {
        url = url.replace('#', '#/');
      }
      url = this.$replace(url);
      let opt = {
        url,
        state: menu.openType || menu.opentype,
      };
      if (opt.state == 3) {
        opt['title'] = menu.dialogTitle || menu.name || undefined;
        opt['width'] = menu.dialogWidth || '70vw';
        opt['height'] = menu.dialogHeight || '60vh';
      }
      this.$open(opt);
    },
    backTop({ scrollDom }) {
      document.querySelector(scrollDom).scrollTo({
        top: 0,
        behavior: "smooth"
      });
    },
    activeClick(item) {
      switch(item.type) {
      case 'backTop':
        this.backTop(item);
        break;
      case 'default':
        this.openPage(item);
        break;
      }
    },
    _clickBefore(next, ...params) {
      if (this.isDesign) return;
      let res = this.clickBefore?.(...params);
      if (res && res.then) {
        res.then(r => {
          if (r !== false) {
            next(...params);
          }
        });
      } else if (res !== false) {
        next(...params);
      }
    },
    onClick(item) {
      if (this.itemClick) {
        let res = this.itemClick(item);
        if (res === false) return;
        if (res.then) {
          res.then(r => {
            if (r !== false) {
              this.activeClick(item);
            }
          });
          return;
        }
      }
      this.activeClick(item);
    },
    updateStyle() {
      if (this.realPageWidth !== '100%') {
        let width = this.$el.offsetWidth;
        width = `${this.realPageWidth} + ${width * 2}px + 40px`;
        let translateX = this.position === 'left' ? '-100% - 10px' : '100% + 10px';
        this.styleDom.innerHTML = `
          @media (min-width: calc(${width})) {
            #${this.id} {
              ${this.position}: calc(50% - ${this.realPageWidth} / 2);
              transform: translate(calc(${translateX}), -50%);
            }
          }
          @media (max-width: calc(${width})) {
            #${this.id} {
              ${this.position}: 20px;
              transform: translate(0, -50%);
            }
          }
        `;
      } else {
        this.styleDom.innerHTML = `
          #${this.id} {
            ${this.position}: 20px;
            transform: translate(0, -50%);
          }
        `;
      }
    }
  }
});
</script>