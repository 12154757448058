<template>
  <div ref="container" class="ds-control ds-project-config" :style="boxStyle">
    <div class="dpc-l" :style="boxStyle">
      <dsf-virtual-scroll>
        <el-menu
          :default-active="activeIndex"
          :default-openeds="defaultOpeneds"
          @select="handleSelect"
        >
          <template v-for="item in data">
            <el-menu-item
              v-if="item.type == '1'"
              :key="item.formId"
              :index="item.formId"
            >
              {{ item.name }}
            </el-menu-item>
            <el-submenu v-else :key="item.formId" :index="item.formId">
              <template slot="title">{{ item.name }}</template>
              <template v-for="item2 in item.children">
                <el-menu-item
                  v-if="item2.type == '1'"
                  :key="item2.formId"
                  :index="item2.formId"
                >
                  {{ item2.name }}
                </el-menu-item>
                <el-submenu v-else :key="item2.formId" :index="item2.formId">
                  <template slot="title">{{ item2.name }}</template>
                  <el-menu-item
                    v-for="item3 in item2.children"
                    :key="item3.formId"
                    :index="item3.formId"
                  >
                    {{ item3.name }}
                  </el-menu-item>
                </el-submenu>
              </template>
            </el-submenu>
          </template>
        </el-menu>
      </dsf-virtual-scroll>
    </div>
    <div class="dpc-r" :style="boxStyle">
      <iframe
        :style="boxStyle"
        width="100%"
        :src="pageUrl"
        frameborder="0"
      ></iframe>
    </div>
  </div>
</template>
<script>
export default dsf.component({
  name: "DsfProjectConfig",
  mixins: [$mixins.control],
  ctrlCaption: "项目配置",
  data() {
    return {
      data: [],
      activeIndex: "",
      defaultOpeneds: [],
      pageUrl: "",
    };
  },
  props: {
    height: {
      type: [Number, String],
      default: "100%",
    },
  },
  computed: {
    boxStyle() {
      return {
        height: dsf.isNumber(this.height) ? `${this.height}px` : this.height,
      };
    },
  },
  created() {
    this.loadData();
  },
  methods: {
    handleSelect(index) {
      this.activeIndex = index;
      this.setPageUrl();
    },
    setDefaultIndex(data) {
      let stack = [...data];
      while (stack.length) {
        let target = stack.pop();
        let children = target.children;
        if (children && children.length) {
          let i = children.length;
          while (i) {
            i--;
            stack.push(children[i]);
          }
        }
        if (target.type == "1") {
          this.activeIndex = target.formId;
          //清栈
          stack = []
        }
      }
    },
    setPageUrl(d) {
      console.log("globalId", this.queryString.globalId);
      const fn = (arr) => {
        let index = 0;
        while (index < arr.length) {
          const target = arr[index];
          if (target.formId === this.activeIndex && target.type == "1") {
            let id = this.queryString.globalId
              ? this.queryString.globalId
              : target.formId;
            let query = dsf.url.queryStringStringify(this.queryString);
            this.pageUrl = dsf.url.getWebPath(
              `~/page.html#/pc/${target.path}?id=${id}&${query}`
            );
            break;
          } else if (target.children && target.children.length) {
            fn(target.children);
          }
          index += 1;
        }
      };
      fn(d || this.data);
    },
    loadData() {
      this.$http.get("/setting/directory").then(({ data }) => {
        if (Array.isArray(data?.data) && data.data.length) {
          this.setDefaultIndex(data.data);
          this.setPageUrl(data.data);
          const arr = [];
          const fn = (d) => {
            d.forEach((it) => {
              if (it.type == "0") {
                arr.push(it.formId);
                fn(it.children);
              }
            });
          };
          fn(data.data);
          this.defaultOpeneds = arr;
          this.data = data.data;
        }
      });
    },
  },
  watch: {
    height: {
      handler(v) {
        if (this.isDesign) {
          let height = v;
          if (!v) {
            height = "auto";
          }
          if (dsf.isNumber(height)) {
            height += "px";
          }
          this.$dispatch("design-height-change", height);
        }
      },
      immediate: true,
    },
  },
});
</script>
