var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ds-control ds-platform-card-box dsf-static-list-by-card" },
    [
      _vm.checkbox && _vm.showCheckAll && _vm.maxCheckNum !== 1
        ? _c(
            "el-checkbox",
            {
              staticClass: "ds-platform-card-box-checkAll",
              class: [_vm.checkboxSize],
              attrs: {
                value: _vm.checkAll,
                indeterminate: _vm.isIndeterminate,
              },
              on: {
                change: function ($event) {
                  return _vm.handleCheckAllChange($event)
                },
              },
              nativeOn: {
                click: function ($event) {
                  $event.stopPropagation()
                },
              },
            },
            [_vm._v(" 全选 ")]
          )
        : _vm._e(),
      _vm.isDesign
        ? _c(
            "div",
            {
              staticClass: "ds-platform-card-list",
              class: [_vm.checkboxPosition],
            },
            [
              _vm._l(_vm.realData, function (row, $index) {
                return [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "context-menu",
                          rawName: "v-context-menu",
                          value: {
                            params: { row: row, index: $index },
                            data: _vm.treeMenu,
                          },
                          expression:
                            "{params: {row, index: $index}, data: treeMenu }",
                        },
                      ],
                      key: row[_vm.rowKey],
                      staticClass: "ds-platform-card-item",
                      class: _vm.getItemClass($index),
                      style: _vm.itemStyle,
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "ds-platform-card-item-content" },
                        [
                          _vm._t("default", null, null, {
                            row: row,
                            $index: $index,
                          }),
                        ],
                        2
                      ),
                      _vm.checkbox && _vm.checkboxStyle
                        ? _c("dsf-custom-checkbox", {
                            staticClass: "ds-platform-card-item-checkbox",
                            attrs: {
                              value:
                                _vm.checkIdList.indexOf(row[_vm.rowKey]) > -1,
                              "style-type": _vm.checkboxStyle,
                              size: _vm.checkboxSize,
                            },
                            on: {
                              input: function ($event) {
                                return _vm.checkChange(row, $event)
                              },
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              }),
              _c(
                "div",
                {
                  staticClass:
                    "ds-platform-card-item dsf-static-list-by-card-add",
                  class: _vm.getItemClass(_vm.realData.length),
                  style: _vm.itemStyle,
                  attrs: { title: "新增" },
                  on: { click: _vm.addRow },
                },
                [_c("i", { staticClass: "iconfont icon-add" })]
              ),
            ],
            2
          )
        : _c(
            "div",
            {
              staticClass: "ds-platform-card-list",
              class: [_vm.checkboxPosition],
            },
            [
              _vm._l(_vm.realData, function (row, $index) {
                return [
                  _c(
                    "div",
                    {
                      key: row[_vm.rowKey],
                      staticClass: "ds-platform-card-item",
                      class: _vm.getItemClass($index),
                      style: _vm.itemStyle,
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "ds-platform-card-item-content" },
                        [
                          _vm._t("default", null, null, {
                            row: row,
                            $index: $index,
                          }),
                        ],
                        2
                      ),
                      _vm.checkbox && _vm.checkboxStyle
                        ? _c("dsf-custom-checkbox", {
                            staticClass: "ds-platform-card-item-checkbox",
                            attrs: {
                              value:
                                _vm.checkIdList.indexOf(row[_vm.rowKey]) > -1,
                              "style-type": _vm.checkboxStyle,
                              size: _vm.checkboxSize,
                            },
                            on: {
                              input: function ($event) {
                                return _vm.checkChange(row, $event)
                              },
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              }),
            ],
            2
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }