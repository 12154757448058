
export default {
  install(Vue) {
    // 下拉级联的值是多级串联，某些情况只显示最后一级
    Vue.filter("multipleFiles", (v, textOrValue) => {
      if  (v)  {
        if (dsf.type(v) == "string") {
          return _.map(JSON.parse(v), textOrValue).join(",")
        } else if (dsf.type(v) == "array") {
          return _.map(v, textOrValue).join(",")
        }
        return v;
      } else {
        return ""
      }
    });
  }
};
