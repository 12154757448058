<template>
  <el-table-column v-if="columns.children && columns.children.length" :label="columns.label" :resizable="!isDesign" :width="columns.width" :min-width="columns.minWidth" :header-align="columns.headerAlign" :align="columns.align" class-name="editor-td">
    <dsf-static-list-by-table-column v-for="col in columns.children" :key="col.columnId" :is-design="isDesign" :columns="col" :get-column-sort="getColumnSort">
      <template #default="scope2">
        <slot v-bind="scope2" :col="col"></slot>
      </template>
    </dsf-static-list-by-table-column>
  </el-table-column>
  <el-table-column
    v-else-if="columns.columnType=='extend'"
    :label="columns.label"
    :prop="columns.columnId"
    :resizable="!isDesign"
    :width="columns.width"
    :min-width="columns.minWidth"
    :header-align="columns.headerAlign"
    :align="'left'"
    :sortable="getColumnSort(columns)"
    class-name="editor-td">
    <template #default="scope">
      <slot v-bind="scope" :col="columns"></slot>
    </template>
  </el-table-column>
  <el-table-column
    v-else
    :label="columns.label"
    :prop="columns.columnId"
    :resizable="!isDesign"
    :width="columns.width"
    :min-width="columns.minWidth"
    :header-align="columns.headerAlign"
    :align="columns.align"
    :sortable="getColumnSort(columns)"
    class-name="editor-td">
    <template #default="scope">
      <slot v-bind="scope" :col="columns"></slot>
    </template>
  </el-table-column>
 
</template>

<script>
export default {
  name: "DsfStaticListByTableColumn",
  props: {
    isDesign: {
      type: Boolean,
      default: false
    },
    columns: {
      type: Object,
      default() {
        return {};
      }
    },
    getColumnSort: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      columnType: ""
    };
  },
  mounted() {
    // if (this.columns.index == 0 && this.columns.isLast) {
    //   this.columnType = "expand";
    // }
    console.log(this.columns)
    // if(this.columns)
  }
};
</script>