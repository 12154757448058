var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(_vm.tag, {
    tag: "component",
    staticClass: "dsf-div-editor",
    attrs: { placeholder: _vm.placeholder, contenteditable: "" },
    on: {
      click: function ($event) {
        $event.stopPropagation()
      },
      blur: _vm.onBlur,
      focus: _vm.onFocus,
      input: _vm.onInput,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }