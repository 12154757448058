<template>
  <div class="ds-control" :style="controlStyle">
    <div
      class="ds-flex-column"
      :isDesign="isDesign"
      :style="{
        padding: padding.join('px ') + 'px',
      }"
    >
      <div
        v-for="(item, index) in slots"
        :key="index"
        :slot-name="item.name"
        class="ds-flex-column-row"
        :style="{
          width: item.width,
          height: !isDesign && (item.flexShrink ? '0' : item.height),
          flex: !isDesign && (item.flexShrink ? '1 1 auto' : '0 0 auto'),
          marginTop: gutter && index > 0 ? `${gutter}px` : '0',
        }"
      >
        <slot :name="item.name"></slot>
      </div>
    </div>
  </div>
</template>
<script>
/**
 * @class DsfFlexColumn
 * @augments Layout
 * @description 弹性列
 */
export default dsf.component({
  name: "DsfFlexColumn",
  ctrlCaption: "栅格列",
  mixins: [$mixins.layout],
  props: {
    slots: {
      type: Array,
      default: () => [
        {
          name: "row1",
          flexShrink: 1,
          width: "100%",
          height: "atuo",
          controls: [],
        },
        {
          name: "row2",
          flexShrink: 0,
          width: "100%",
          height: "250px",
          controls: [],
        },
      ],
    },
    width: {
      type: String,
      default: () => "100%",
    },
    height: {
      type: String,
      default: () => "atuo",
    },
    margin: {
      type: Array,
      default: () => [0, 0, 0, 0],
    },
    padding: {
      type: Array,
      default: () => [0, 0, 0, 0],
    },
    gutter: {
      type: Number,
      default: () => 0,
    },
  },
  computed: {
    controlStyle() {
      let { width, margin, height, isDesign } = this;
      let style = {
        width,
        height,
        padding: margin.join("px ") + "px",
      };
      if (isDesign) {
        style.height = "auto";
        style.minHeight = height;
        style.padding = "10px 10px 10px 10px";
      }
      return style;
    },
  },
});
</script>