<template>
  <div v-loading="!hasInfiniteScroll && loading" class="ds-control ds-datagrid" :class="getCss">
    <DsfPanel class="ds-datagrid-panel" :style="getListStyle" :has-title="showTitle" :has-header="showHead" :background-config="backgroundConfig" title-border>
      <template v-slot:title>
        {{ isDesign ? label : getGridLabel() }}
      </template>
      <template v-slot:header-left>
        <slot name="header-left">
          <div class="ds-datagrid-header-left">
            <span class="ds-datagrid-header-left-showType">
              <i v-for="item in layoutInfoCopy" :key="item.id" :class="['show-type-icon','iconfont',item.icon,{ active: currentShowLayout.id == item.id }]" @click="currentShowLayout = item"></i>
            </span>
          </div>
        </slot>
        <slot name="header-subtitle"></slot>
      </template>
      <template v-slot:header>
        <slot name="header">
          <!--列表头部 start-->
          <div class="ds-datagrid-header">
            <div v-if="checkboxColumn.show" class="head-right-item" style="margin-left: 10px;">
              <span v-if="!isSingleChecked" class="ds-datagrid-checkednumber ds_font5" @click="checkSelection" :style="{ 'cursor': $showSelectionDialog ? 'pointer' : 'default' }">
                <i class="icon-093info iconfont icon-color ds_icon_4" style="margin-right: 5px"></i>
                已选：<font style="margin: 0 5px">{{ multipleSelection.length }} </font>条
              </span>
            </div>
            <div v-if="showSearch" class="head-right-item" style="margin-left: 10px">
              <form @submit.stop.prevent="search">
                <el-input v-model="searchStr" :placeholder="searchPlaceholder" class="search-input"></el-input>
                <button class="search-button">
                  <i class="iconfont icon-fangdajing"></i>
                </button>
              </form>
            </div>
            <div v-if="buttons.length" class="head-right-item" style="margin-left: 10px">
              <DsfButtonBar
                :is-design="isDesign"
                ref="buttonbar"
                :more-button-style="buttonsMoreStyle"
                :more-button-text="buttonsMoreText"
                :max-number="buttonsMaxNumber"
                :buttons="buttons"
                :render-button="renderButton"
                :local="local"
                :class="{ 'ds-no-padding': true }"></DsfButtonBar>
            </div>
            <div class="head-right-item" slot-name="header-attach">
              <slot name="header-attach"></slot>
            </div>
          </div>
          <!--列表头部 end-->
        </slot>
      </template>
      <template v-slot:default>
        <div class="ds-datagrid-box">
          <div class="ds-datagrid-search-wrap" :class="{ extend: superSearchExtend }">
            <slot name="search-bar">
              <template v-if="superSearchInfo.length > 0">
                <!--高级检索-->
                <DsfSuperSearch
                  style="margin-bottom: 10px"
                  ref="superSearchBar"
                  :is-design="isDesign"
                  :super-search-row-border="superSearchRowBorder"
                  :show-fitler="showSuperSearchFilter"
                  :search-item-setting="superSearchInfo"
                  :owner="_self"
                  :default-rows-number="superSearchDefaultRowsNumber"
                  :value-change-trigger="!superSearchButtonTrigger"
                  :float="superSearchFloat"
                  :maxHeight="superSearchMaxHeight"
                  :titleMaxWidth="superSearchTitleMaxWidth"
                  :extend-button-position="superSearchExtendButtonPosition"
                  @change-height="superSearchChangeHeight"
                  @extend="superSearchExtendHandler"
                  @loaded="superSearchLoadedHandler"
                  @change="superSearchChangeHandler">
                </DsfSuperSearch>
              </template>
            </slot>
          </div>
          <slot name="custom-order" :custom-order="customerOrder">
            <dsf-custom-order
              v-if="
                customerOrder.length && currentShowLayout.type == 'cardList'
              "
              :custom-order="customerOrder">
              <slot name="custom-order-right" :data="data" :count="pageCount"></slot>
            </dsf-custom-order>
          </slot>
          <!--设计状态-->
          <template v-if="isDesign">
            <template v-for="item in layoutInfoCopy">
              <div v-if="item.type == 'table'" :key="item.id" v-show="currentShowLayout.id == item.id" class="ds-datagrid-content-wrap table-mode" :style="getContentWrapStyle(item)">
                <DsfDataGridTable
                  ref="table"
                  :is-design="isDesign"
                  :columns="columns"
                  :checkbox-column="checkboxColumn"
                  :index-column="indexColumn"
                  :operate-column="operateColumn"
                  :row-buttons="rowButtons"
                  :render-row-button="renderRowButton"
                  :is-single-checked="isSingleChecked"
                  :border="true"
                  :fit="true"
                  :header-cell-class-name="getHeaderClass"
                  :data="templateData"
                  :height="tableHeight"
                  :show-summary="showSummary"
                  :customer-order="customerOrder"
                  :is-re-draw-hander="isReDrawHander"
                  @row-dblclick="tableRowDblClick">
                  <template v-slot:[column.columnId]="scope" v-for="column in getLastColumns()">
                    <slot :name="column.columnId" v-bind="scope"></slot>
                  </template>
                  <template v-slot:row-buttons>
                    <DsfButtonBar size="small" :max-number="rowButtonsMaxNumber" :more-button-text="rowButtonsMoreText" :more-button-style="rowButtonsMoreStyle" class="ds-no-padding ds-no-drop" :is-design="isDesign" :buttons="rowButtons" align="center"></DsfButtonBar>
                  </template>
                </DsfDataGridTable>
              </div>
              <div v-else-if="item.type == 'cardList'" :key="item.id" v-show="currentShowLayout.id == item.id" class="ds-datagrid-content-wrap card-mode" :style="getContentWrapStyle(item)">
                <dsf-platform-card-box
                  :data="templateData"
                  :column-number="cardListColumnNumber"
                  :column-width.sync="cardListColumnWidth"
                  :margin-top="cardListMarginTop"
                  :checkbox="checkboxColumn.show"
                  :max-check-num="isSingleChecked ? 1 : 0"
                  :checkbox-style="cardListCheckboxStyle"
                  :checkbox-size="cardListCheckboxSize"
                  :checkbox-position="cardListCheckboxPosition"
                  is-design>
                  <template v-slot:default="scope">
                    <div class="ds-datagrid-slot" :slot-name="item.id">
                      <slot :name="item.id" v-bind="scope"></slot>
                    </div>
                  </template>
                </dsf-platform-card-box>
                <div class="ds-datagrid-hide-slot ds-datagrid-slot" slot-name="datagrid-hide">
                  <slot name="datagrid-hide"></slot>
                </div>
              </div>
            </template>
          </template>
          <!--非设计状态-->
          <template v-else>
            <template v-for="item in layoutInfoCopy">
              <div v-if="item.type == 'table'" :key="item.id" v-show="currentShowLayout.id == item.id" class="ds-datagrid-content-wrap table-mode" :style="getContentWrapStyle(item)">
                <DsfDataGridTable
                  ref="table"
                  :columns.sync="columns"
                  :data="data"
                  :height="tableHeight"
                  :row-key="getRowKey"
                  :checkbox-column="checkboxColumn"
                  :index-column="indexColumn"
                  :operate-column="operateColumn"
                  :render-row-button="renderRowButton"
                  :disabled-row-button="disabledRowButton"
                  :is-single-checked="isSingleChecked"
                  :header-cell-class-name="getHeaderClass" 
                  :span-method="spanMethod"
                  :customer-order="customerOrder"
                  :customer-column-widths="tableCustomerColumnWidths"
                  :is-re-draw-hander="isReDrawHander"
                  :selectable="selectable"
                  :filter-result="tableFilterResult"
                  :render-header="renderHeader"
                  :row-cursor-pointer="rowCursorPointer"
                  :row-class-name="rowClassName"
                  :is-design="false"
                  :emit-text="emitText"
                  :show-summary="showSummary"
                  border
                  stripe
                  fit
                  :context-menu-buttons="rowButtons" 
                  @row-dblclick="tableRowDblClick"
                  @table-column-resize="tableColumnResize"
                  @select="handleSelectionByTable"
                  @sort-change="tableSort"
                  @select-all="handleSelectionAll($event, 'table')"
                  @on-contextmenu="onContextmenu">
                  <slot></slot>
                  <template v-slot:[column.columnId]="scope" v-for="column in getLastColumns()">
                    <slot :name="column.columnId" v-bind="scope"></slot>
                  </template>
                  <template v-slot:row-buttons="scope">
                    <DsfButtonBar
                      size="small"
                      :render-button="renderRowButton"
                      :disabled-button="disabledRowButton"
                      :max-number="rowButtonsMaxNumber"
                      :more-button-text="rowButtonsMoreText"
                      :more-button-style="rowButtonsMoreStyle"
                      :buttons="scope.row.$buttons"
                      :emit-prefix="'row-button'"
                      :local="scope.row"
                      class="ds-no-padding"
                      align="center">
                    </DsfButtonBar>
                  </template>
                </DsfDataGridTable>
              </div>
              <div v-else-if="item.type == 'cardList'" :key="item.id" v-show="currentShowLayout.id == item.id" class="ds-datagrid-content-wrap card-mode" :style="getContentWrapStyle(item)">
                <dsf-platform-card-box
                  ref="cardBox"
                  :data="data"
                  :column-number="cardListColumnNumber"
                  :column-width="cardListColumnWidth"
                  :margin-top="cardListMarginTop"
                  :checkbox="checkboxColumn.show"
                  :max-check-num="isSingleChecked ? 1 : 0"
                  :checkbox-style="cardListCheckboxStyle"
                  :checkbox-size="cardListCheckboxSize"
                  :checkbox-position="cardListCheckboxPosition"
                  :selectable="selectable"
                  @select="handleSelectionByCard"
                  @select-all="handleSelectionAll($event, 'cardList')"
                  @on-contextmenu="onContextmenu">
                  <template v-slot:default="scope">
                    <slot :name="item.id" v-bind="scope" :row-buttons="scope.row.$buttons"></slot>
                  </template>
                </dsf-platform-card-box>
              </div>
            </template>
          </template>
          <div v-if="showPagination" class="ds-datagrid-pager" :class="['ds-datagrid-pager-align_'+paginationPosition]">
            <el-pagination
              ref="pagination"
              :class="[paginationStyle]"
              :hide-on-single-page="false"
              :total="pageCount"
              :page-size="pageSize"
              :page-sizes="limits"
              :current-page.sync="currentPage"
              :layout="'slot,' + pagerLayout"
              @size-change="pageSizeChange">
              <span v-if="currentShowLayout.type == 'table'" style="margin-right: 10px">
                <a title="还原列表" class="ds-datagrid-pager-button" @click="headerReset()">
                  <DsfIcon name="chehuisekuai"></DsfIcon>
                </a>
                <a title="刷新" class="ds-datagrid-pager-button" @click="reloadData()">
                  <DsfIcon name="shuaxin"></DsfIcon>
                </a>
              </span>
            </el-pagination>
          </div>
          <!--如果列表操作栏没有配置，行按钮不显示的情况下无法绑定双击、和单击操作-->
          <div v-if="!operateColumn.show" v-show="false">
            <DsfButtonBar
              v-for="item in data"
              :key="getRowKey(item)"
              :row-hash="item.$hash"
              size="small"
              style="display: none"
              :max-number="rowButtonsMaxNumber"
              :more-button-text="rowButtonsMoreText"
              :more-button-style="rowButtonsMoreStyle"
              class="ds-no-padding ds-no-drop"
              :buttons="_self.rowButtons"
              :local="item"
              align="center">
            </DsfButtonBar>
          </div>
        </div>
      </template>
    </DsfPanel>
  </div>
</template>
<script>
import { contextMenuClick } from "_platform/pc/directives/contextMenu";
import DsfDataGridTable from "./datagridTable";
import DsfSuperSearch from "./superSearch";
import validate from "./validate";
const key = "_id";
import { createDesignDefaultColumns, getLastColumns, updateWidthToFilterColumns, createFilterResult, updateSlots, headerSetting, createDataGridMetadata, openDuplicateMessage } from "./datagridHelper";

export default dsf.component({
  name: "DsfDataGrid",
  ctrlCaption: "数据列表",
  mixins: [$mixins.control, $mixins.list, $mixins.dataResult],
  components: { DsfSuperSearch, DsfDataGridTable },
  provide() {
    return {
      $dataPrefix: this.dataPrefix
    };
  },
  props: {
    getAttachRequestParams: {
      type: Function,
      ddefault: null
    },
    /********基础属性*********/
    // 标题
    label: {
      type: String,
      default: "数据列表"
    },
    showSelectionDialog: {
      type: Boolean,
      default: false
    },
    // 检索框提示文字
    searchPlaceholder: {
      type: String,
      default: "请输入检索关键字"
    },
    // 高度是否自适应
    fit: {
      type: Boolean,
      default: true
    },
    renderButton: {
      type: Function,
      default: null
    },
    // 按钮栏设置
    buttons: {
      type: Array,
      default() {
        return [];
      }
    },
    // 最大按钮数量
    buttonsMaxNumber: {
      type: Number,
      default: 4
    },
    // 按钮栏更多按钮形态
    buttonsMoreStyle: {
      type: String,
      default: ""
    },
    // 按钮栏更多按钮文字
    buttonsMoreText: {
      type: String,
      default: "更多"
    },
    // 按钮栏位置
    buttonBarPosition: {
      type: String,
      default: "head"
    },
    // 按钮高级检索右侧显示模式
    buttonBarPositionMode: {
      type: String,
      default: "normal"
    },
    // 是否显示头部
    showHead: {
      type: Boolean,
      default: true
    },
    // 是否显示标题
    showTitle: {
      type: Boolean,
      default: true
    },
    // 是否显示检索框
    showSearch: {
      type: Boolean,
      default: true
    },
    // 是否显示checkbox列
    checkboxColumn: {
      type: Object,
      default() {
        return {
          show: true,
          width: dsf.config.list.checkColumn.width
        };
      }
    },
    // 是否仅单选
    isSingleChecked: {
      type: Boolean,
      default: false
    },

    /********数据查询属性*********/
    // 挂载后立即请求数据
    mountedRequestData: {
      type: Boolean,
      default: true
    },
    // 查询条件配置
    searchInfo: {
      type: Array,
      default() {
        return [];
      }
    },
    // 未选筛选条件时tips信息
    searchTips: {
      type: String,
      default: ""
    },
    // 高级查询设置
    superSearchInfo: {
      type: Array,
      default() {
        return [];
      }
    },
    // 高级查询行间隔线
    superSearchRowBorder: {
      type: Boolean,
      default: true
    },
    // 高级查询默认初始化行数
    superSearchDefaultRowsNumber: {
      type: Number,
      default: 2
    },
    //高级检索悬浮悬浮于列表上
    superSearchFloat: {
      type: Boolean,
      default: true
    },
    //高级检索展开按钮位置是否再底部
    superSearchExtendButtonPosition: {
      type: String,
      default: "bottom"
    },
    superSearchMaxHeight: {
      type: String,
      default: "500"
    },
    superSearchTitleMaxWidth: {
      type: String,
      default: ""
    },
    // 默认排序
    defaultOrder: {
      type: Array,
      default() {
        return [];
      }
    },
    // 自定义排序
    customerOrder: {
      type: Array,
      default() {
        return [];
      }
    },
    // 是否显示分页(最多显示100条)
    showPagination: {
      type: Boolean,
      default: true
    },
    // 分页位置
    paginationPosition: {
      type: String,
      default: "flex-end"
    },
    // 分页样式
    paginationStyle: {
      type: String,
      default: "style1"
    },
    // 每页显示条数(逗号分隔)
    limits: {
      type: Array,
      default() {
        return [15, 20, 30, 40, 50, 100];
      }
    },
    // 分页组建的布局,自定义分页排版(逗号分隔)
    pagerLayout: {
      type: String,
      default: "total,prev,pager,next,jumper,sizes"
    },
    // 是否滚动加载
    scrollLoad: {
      type: Boolean,
      default: false
    },
    scrollPageSize: {
      type: Number,
      default: 15
    },

    /********通用属性*********/
    // 数据展示方式
    layoutInfo: {
      type: Array,
      default() {
        return [
          {
            id: "default",
            name: "表格",
            icon: "icon-biaodanzujian-biaoge",
            type: "table",
            typeName: "表格",
            backgroundColor: "transparent"
          }
        ];
      }
    },
    rowButtons: {
      type: Array,
      default() {
        return [];
      }
    },
    renderRowButton: {
      type: Function,
      default: null
    },
    rowCursorPointer: {
      type: Boolean,
      default: false
    },
    rowClassName: {
      type: [String, Function],
      default: ""
    },
    disabledRowButton: {
      type: Function,
      default: null
    },
    // 数据行按钮最大数量
    rowButtonsMaxNumber: {
      type: Number,
      default: 4
    },
    // 数据行更多按钮形态
    rowButtonsMoreStyle: {
      type: String,
      default: "icon-text"
    },
    // 数据行更多按钮文字
    rowButtonsMoreText: {
      type: String,
      default: "更多"
    },

    /********table属性*********/
    // 是否显示索引列
    indexColumn: {
      type: Object,
      default() {
        return {
          show: true,
          width: 60
        };
      }
    },
    // 是否显示操作按钮
    operateColumn: {
      type: Object,
      default() {
        return {
          show: true,
          width: 100
        };
      }
    },
    // 表头设置
    columns: {
      type: Array,
      default() {
        return [];
      }
    },
    // 空状态文本
    emitText: {
      type: String,
      default: "暂无数据"
    },
    /********卡片列表属性*********/
    /**
     * 一行N列
     */
    cardListColumnNumber: {
      type: Number,
      default: 3
    },
    // 列宽
    cardListColumnWidth: {
      type: String,
      default: "30%"
    },
    // 上下间隙
    cardListMarginTop: {
      type: String,
      default: "15px"
    },
    // 选择框尺寸
    cardListCheckboxStyle: {
      type: String,
      default: "style1"
    },
    // 选择框尺寸  big, medium, small
    cardListCheckboxSize: {
      type: String,
      default: "medium"
    },
    // 选择框位置
    cardListCheckboxPosition: {
      type: String,
      default: "top right"
    },
    /********高级属性*********/
    // 浏览范围
    browseScope: {
      type: Object,
      default() {
        return {
          scope: "2",
          unit: []
        };
      }
    },
    //导出配置
    exportOptions: {
      type: [Object, Array],
      default() {
        return {
          "rule-1": []
        };
      }
    },
    //预导入配置
    importOptions: {
      type: [Array],
      default() {
        return [];
      }
    },

    /********其他*********/
    // 数据字段前缀
    dataPrefix: {
      type: String,
      default: ""
    },
    superSearchButtonTrigger: {
      type: Boolean,
      default() {
        return false;
      }
    },
    showSuperSearchFilter: {
      type: Boolean,
      default: false
    },
    // 列的隐藏
    renderHeader: {
      type: Function,
      default: dsf.noop
    },
    //分页页码按钮最多显示个数
    pagerCount: {
      type: Number,
      default: 5
    },
    //一页显示多少条记录
    pageSize: {
      type: Number,
      default: 15
    },
    //设计模式下的模板
    templateData: {
      type: Array,
      default() {
        return [{}];
      }
    },
    //数据列表
    data: {
      type: Array,
      default() {
        return [];
      }
    },
    slots: {
      type: Array,
      default() {
        return [
          {
            name: "default",
            controls: []
          }
        ];
      }
    },
    getSecrchStr: {
      type: Function,
      default: null
    },
    // 仅对 type=selection 的列有效，类型为 Function，Function 的返回值用来决定这一行的 CheckBox 是否可以勾选
    // Function(row, index)
    selectable: {
      type: Function,
      default: null
    },
    contentWrapMargin: {
      type: String,
      default: "0px"
    },
    // 针对表格，合并行或列的计算方法。
    // Function({ row, column, rowIndex, columnIndex })
    spanMethod: {
      type: Function,
      required: false
    },
    getDefaultSelected: {
      type: Function,
      default: null
    },
    tableFilterResult: {
      type: Array,
      default() {
        return [];
      }
    },
    //表格自定义列宽
    tableCustomerColumnWidths: {
      type: Object,
      default() {
        return null;
      }
    },
    // 导出配置
    tableExportResult: {
      type: Array,
      default() {
        return [];
      }
    },
    isRowDataChangePost: {
      type: Boolean,
      default: true
    },
    rowDataChangePostUrl: {
      type: String,
      default: "meta/persistData"
    },
    rowDataChangePostNameSpace: {
      type: String,
      default: ""
    },
    //行数据更新成功后是否刷新列表
    rowDataUpdatedCallReload: {
      type: Boolean,
      default: false
    },
    getFilter: {
      type: Function,
      default() {
        return {};
      }
    },
    isSearchResetSelect: {
      type: Boolean,
      default: true
    },
    showSummary: {
      type: Boolean,
      default: false
    },
    getSummaries: {
      type: Function,
      default: null
    }
  },
  data() {
    return {
      isShow: false,
      currentPage: 1,
      pageCount: 0,
      isReDrawHander: false,
      searchStr: "",
      removeTable: false,
      loading: false,
      // 多选选中值
      multipleSelectionCopy: [],
      //高级检索高度
      superSearchHeight: 0,
      superSearchExtend: false,
      superValue: {},
      // 默认的展示形式
      currentShowLayout: {},
      elTable: null,
      loaddataing: false,
      loaddataList: [],
      indexColumnWidth: this.indexColumn.width,
      isTriggerQuery: false,
      summaries: [],
      dsfTextbox: []
    };
  },
  computed: {
    $showSelectionDialog() {
      return this.showSelectionDialog && this.multipleSelection.length > 0;
    },
    $errors() {
      return this.$data._errors;
    },
    // 当前页数据所有的id
    dataIds() {
      return this.data.map(({ _id }) => _id);
    },
    // 多选/单选选中值
    multipleSelection: {
      get() {
        let sel = this.multipleSelectionCopy;
        if (this.isSingleChecked) {
          return (sel && sel.length && sel[0]) || null;
        } else {
          return sel || [];
        }
      },
      set(to) {
        if (this.isSingleChecked) {
          if (dsf.type(to) == "array") {
            this.multipleSelectionCopy = to.length ? [to[0]] : [];
          } else {
            this.multipleSelectionCopy = to ? [to] : [];
          }
        } else {
          this.multipleSelectionCopy = to || [];
        }
      }
    },
    // 是否有滚动加载场景
    hasInfiniteScroll() {
      return !this.isDesign && !this.showPagination && this.layoutInfo.length > 1 && this.scrollLoad;
    },
    // 是否禁用滚动加载
    infiniteScrollDisabled() {
      if (!this.hasInfiniteScroll) {
        return true;
      }
      return this.loading || this.data.length >= this.pageCount;
    },
    layoutInfoCopy() {
      if (this.isDesign || this.layoutInfo.length < 2) {
        return this.layoutInfo;
      }
      return this.layoutInfo.filter((info) => {
        return info.type != "table" || (!this.hasInfiniteScroll && !info.hide);
      });
    },
    contentWrapHeight() {
      if (this.fit) {
        let paginationHeight = this.showPagination ? 57 : 0;
        let customOrderHeight = 0;
        if (this.currentShowLayout.type == "cardList") {
          if (this.customerOrder.length) {
            customOrderHeight = 41;
          }
          if (paginationHeight) {
            paginationHeight += 15;
          }
        }
        return `calc(100% - ${this.tableMarginTop}px - ${paginationHeight + customOrderHeight}px - ${this.contentWrapMargin})`;
      }
      return "auto";
    },
    tableMarginTop() {
      return this.superSearchHeight > 0 ? this.superSearchHeight : 0;
    },
    tableHeight() {
      if (this.isDesign) {
        return "100%";
      }
      let fit = this.fit;
      if (fit) {
        return "100%";
      }
      return "auto";
    },
    panelHeight() {
      let fit = this.fit;
      if (this.isDesign) {
        return "100%";
      } else {
        return fit ? "100%" : "auto";
      }
    },
    getCss() {
      return [
        this.$getClass(),
        {
          fit: this.fit,
          loading: !this.hasInfiniteScroll && this.loading,
          "has-pagination": this.showPagination,
          "has-checkbox": this.checkboxColumn.show,
          "card-model": this.currentShowLayout.type == "cardList"
        }
      ];
    },
    getListStyle() {
      return {
        visibility: this.isDesign || this.isShow ? "visible" : "hidden",
        height: this.panelHeight
      };
    },
    checkedNumber() {
      if (!checkboxColumn.show) {
        return 0;
      }
      if (!isSingleChecked) {
        return this.multipleSelection.length;
      }
      return this.multipleSelection ? 1 : 0;
    },
    superSearchBar() {
      return this.$refs.superSearchBar;
    },
    metadataDict() {
      let dict = this?.$vm?.metadataDict;
      if (dict) {
        let arr = {};
        _.each(dict, (it, k) => {
          let cm = this.childMetaDataCodes;
          let index = _.findIndex(cm, (it1) => it1 == it.id);
          if (index >= 0) {
            arr[k] = it;
          }
        });
        return arr;
      }
      return {};
    },
    searchWarpStyle() {
      if (this.superSearchFloat) {
        let res = {
          height: this.superSearchHeight + "px"
        };
        return res;
      }
      return {};
      // let res = {
      //   height: this.superSearchHeight + "px"
      // };
      // if (this.currentShowLayout.type == "cardList" && this.customerOrder.length) {
      //   res["height"] = this.superSearchHeight + 41 + "px";
      // }
      // return res;
    },
    headerPageCount() {
      let pageCount = this.pageCount;
      return this.$replace(this.pageCountFormat, { pageCount });
    },
    // 右键菜单
    contextmenuList() {
      let menus = [];
      _.forEach(this.rowButtons, (b) => {
        if (b.isContextmenu) {
          menus.push(b);
        }
      });
      return menus;
    }
  },
  created() {
    dsf.storage.local.set("searchTips", this.searchTips);
    if (this.isDesign) {
      if (!this.rowDataChangePostNameSpace) {
        this.rowDataChangePostNameSpace = `${this.$designer.page.context.B}.${this.$designer.page.context.M}.${this.$designer.page.name}`;
      }
    }
    if (this.checkboxColumn && dsf.isUnDef(this.checkboxColumn.width)) {
      this.checkboxColumn.width = dsf.config.list.checkColumn.width;
    }

    createDesignDefaultColumns.call(this);
    updateSlots.call(this, this.layoutInfo);
    let pSize = _.find(this.limits, (s) => s == this.pageSize);
    if (!pSize) {
      this.$emit("update:pageSize", this.limits[0]);
    }
    //监听当前页面和页码发生变化后数据
    this.watchCurrentPageAndPageSize();

    this.currentShowLayout = this.layoutInfo[0];
  },
  beforeMount() {},
  mounted() {
    if (this.slots && Array.isArray(this.slots)) {
      try {
        let slots = this.slots.map((s) => s.controls).filter((s) => Array.isArray(s) && s.length > 0);
        let controls = [];
        for (let slot of slots) {
          controls = controls.concat(slot);
        }
        const dfsHandler = function (arr, result) {
          if (Array.isArray(arr)) {
            for (let item of arr) {
              if (Array.isArray(item.slots) || Array.isArray(item.controls)) {
                Array.isArray(item.slots) ? dfsHandler(item.slots, result) : dfsHandler(item.controls, result);
              }

              if (item?.ctrlName && ["DsfTextbox", "DsfSelect", "DsfDatePicker"].includes(item.ctrlName)) {
                result.push(item);
              }
            }
          }
          return result;
        };
        let nodes = dfsHandler(controls, []);
        if (Array.isArray(nodes) && nodes.length > 0) {
          this.dsfTextbox = nodes;
        }
      } catch (e) {
        console.log(e);
      }
    }

    this.elTable = this.getElTable();
    if (!this.isDesign) {
      if (this.mountedRequestData) {
        this.$nextTick(() => {
          //如果没有高级检索则直接查询数据，否则需要等待高级检索组件加载loaded事件中调用
          if (!this.superSearchInfo || this.superSearchInfo.length <= 0) {
            this.isShow = true;
            this.currentPage = 1;
            this.loadData();
          }
        });
      }
      this.defaultSelected();
    }
  },
  updated() {
    this.doLayout();
  },
  beforeDestroy() {
    this.unwatch && this.unwatch();
    if (this.$refs.pagination) {
      this.$refs.pagination.$destroy();
    }
    this.data.splice(0, this.data.length);
  },
  destroyed() {},
  methods: {
    checkSelection() {
      if (!this.$showSelectionDialog) return;
      const _this = this;
      let columns = JSON.parse(JSON.stringify(this.columns)).filter((col) => !col.hide);
      let fitNode = null;

      const propHandler = function (arr) {
        arr.forEach((col, index) => {
          fitNode = _this.dsfTextbox.find((t) => t.label === col.label);
          if (fitNode && fitNode?.metadata_fullcode) {
            if (fitNode.formatter) {
              col.formatter = fitNode.formatter;
            }
            col.prop = fitNode.metadata_fullcode;
          }
          if (Array.isArray(col.children) && col.children.length > 0) {
            propHandler(col.children);
          }
        });
      };
      propHandler(columns);

      let queryInfo = this.getQueryInfo();
      let params = {
        query: JSON.stringify(queryInfo.query),
        order: JSON.stringify(queryInfo.order),
        filter: JSON.stringify(queryInfo.filter),
        namespace: this.$vm.nameSpace,
        pageName: this.$vm.pageName
      };
      dsf.mix(params, this.queryString);
      // params.ids = JSON.stringify(ids);

      this.$openDialog({
        title: "已选列表",
        content: "DsfPlatformMultipleSelectionTable",
        width: "calc(100% - 350px)",
        height: "60vh",
        params: {
          select: this.multipleSelectionCopy,
          columns: columns,
          params: params,
          pagesizes: this.limits
        },
        btns: [
          {
            text: "确定",
            async handler(res) {
              if (res?.deleteRows && res.deleteRows.length > 0) {
                let currentRow = null;
                for (let row of res.deleteRows) {
                  currentRow = _this.multipleSelectionCopy.find((m) => m._id === row._id);
                  if (currentRow) {
                    _this.$refs.table[0].toggleRowSelection(currentRow, false);
                  }
                }
              }
            }
          },
          {
            text: "关闭"
          }
        ]
      });
    },
    ...validate,
    tableRowDblClick(evt) {
      this.$dispatch("table-row-dblclick", evt.args);
    },
    getGridLabel() {
      return this.$replace(this.label);
    },
    getElTable() {
      return this.$refs.table?.[0];
    },
    //监听页码和显示行数变化
    watchCurrentPageAndPageSize() {
      function fn() {
        return this.currentPage + "," + this.pageSize;
      }
      if (!this.hasInfiniteScroll) {
        this.unwatch = this.$watch(fn, (v, ov) => {
          let pageNum = Math.ceil(this.pageCount / this.pageSize);
          if (this.currentPage > pageNum) {
            this.currentPage = pageNum <= 0 ? 1 : pageNum;
            return;
          }
          this.loadData();
        });
      }
    },
    //默认选中
    defaultSelected() {
      //组件加载时选中
      if (this.getDefaultSelected) {
        let _this = this;
        this.getDefaultSelected({
          clearSelection() {
            _this.$refs.cardBox?.[0]?.clearSelection();
            _this.$refs.table?.[0]?.clearSelection();
          },
          toggleRowSelection(row, selected) {
            _this.$refs.cardBox?.[0]?.toggleRowSelection(row, selected);
            _this.$refs.table?.[0]?.toggleRowSelection(row, selected);
          },
          toggleAllSelection() {
            _this.$refs.cardBox?.[0]?.toggleAllSelection();
            _this.$refs.table?.[0]?.toggleAllSelection();
          }
        });
      } else {
        this.loadSelected();
      }
    },
    //获取columns的最底层列
    getLastColumns(column) {
      return getLastColumns.call(this, column);
    },
    reset() {
      this.clearSelect();
      // this.pageCount = 0;
      this.currentPage = 1;
    },
    //更新索引
    indexMethod(index) {
      return (this.currentPage - 1) * this.pageSize + index + 1;
    },
    //检索框查询
    search() {
      if (this.isDesign) {
        return;
      }
      return new Promise((resolve, reject) => {
        setTimeout(async () => {
          await this.reloadData(1);
          this.$emit("search");
          resolve();
        }, 200);
      });
    },
    resize() {
      if (this.currentShowLayout.id == "default") {
        this.doLayout();
      } else if (!this.isDesign) {
        let $layoutBox = this.$refs["layout-" + this.currentShowLayout.id];
        if ($layoutBox) {
          $layoutBox = $layoutBox.length ? $layoutBox[0] : $layoutBox;
          let $layoutItem = $layoutBox?.querySelector(".ds-datagrid-layout-item");
          if ($layoutItem && $layoutItem.offsetWidth > 0) {
            let columns = Math.round(window.innerWidth / $layoutItem.offsetWidth);
            if (!isNaN(columns) && columns > 2 && (this.currentShowLayout.alternateNum || 0) < columns - 1) {
              this.$set(this.currentShowLayout, "alternateNum", columns - 1);
            }
          }
        }
      }
      if (this.superSearchBar) {
        this.superSearchBar.resize();
      }
    },
    pageSizeChange(evt) {
      this.$emit("update:pageSize", evt);
    },
    pageIndexChange(evt) {
      this.currentPage = evt;
      this.loadData();
    },
    // 某些时候el-table行高计算不会被触发，导致其内部行错位，此时调用此方法触发其内部的行高计算
    doLayout() {
      let table = this.getElTable();
      if (table && table.doLayout) {
        table.doLayout();
      }
    },
    getRowKey(row) {
      if (row.$duplicateKey) {
        return row[key] + "_" + row["$hash"];
      } else {
        return row[key];
      }
    },
    getQueryInfo() {
      let orderArr = [];
      if (this.getSecrchStr) {
        this.searchStr = this.getSecrchStr() || "";
      }
      let queryData = {
        searchValue: (this.searchStr || "").split(/\s+/g).join(" ")
      };
      if (this.searchInfo) {
        _.each(this.searchInfo, (item) => {
          if (item.type == "1") {
            queryData[item.code] = item.valueExpress;
          }
        });
      }
      if (this.superValue) {
        _.each(this.superValue, (item, i) => {
          queryData[i] = item;
        });
      }
      if (this.defaultOrder) {
        let customOrderArr = _.map(this.customerOrder, (order) => {
          return order.sortType ? { code: order.field, sort: order.sortType } : undefined;
        });
        customOrderArr = _.filter(customOrderArr, (order) => order);
        let defaultOrderArr = _.map(this.defaultOrder, (order) => {
          let index = _.findIndex(customOrderArr, (f) => f.code == f.field);
          if (index < 0) {
            return {
              code: order.field,
              sort: order.sortType
            };
          }
        });
        orderArr = [...customOrderArr, ...defaultOrderArr];
      }
      let filterInfo = [];
      if (this.elTable) {
        let lastCols = this.getLastColumns(this.elTable.realFilterColumns);
        _.each(lastCols, (it) => {
          if (it?.filterInfo?.value) {
            let field = it.filterInfo.field;
            let index = field.indexOf(".");
            if (index >= 0) {
              field = field.substr(field.indexOf(".") + 1);
            }
            let format = "";
            if (it.filterInfo.value.dataType == "datetime") {
              if (it.filterInfo.type == "ymd") {
                format = "yyyy-MM-dd";
              } else if (it.filterInfo.type == "ym") {
                format = "yyyy-MM";
              }
            }
            filterInfo.push({
              ...(it?.filterInfo?.value || {}),
              field: field,
              format: format
            });
          }
        });
        let customFilter = this?.getFilter?.() || [];
        filterInfo = _.unionBy(customFilter, filterInfo, "field");
      }
      return {
        order: orderArr,
        query: queryData,
        filter: filterInfo
      };
    },
    async loadData(isAppendData = false, pageIndex) {
      this.loading = true;
      let fn = this.$isPrototype ? this.loadStaticData : this.loadServerData;
      if (this.loaddataing) {
        if (!isAppendData) {
          //如果上一次请求没有完成,则将本次请求推送到队列
          this.loaddataList.push({
            isAppendData,
            currentPage: this.currentPage
          });
        }
      } else {
        this.loaddataing = true;
        try {
          await fn(isAppendData, pageIndex);
          if (this.loaddataList.length > 0) {
            let last = this.loaddataList[this.loaddataList.length - 1];
            this.loaddataList = [];
            await this.loadData(last.isAppendData);
          }
        } catch (err) {
          dsf.httpError(err);
        } finally {
          this.isTriggerQuery = true;
          this.loaddataing = false;
          this.$nextTick(() => {
            window.setTimeout(() => {
              this.doLayout();
              this.loading = false;
            });
          });
        }
      }
    },
    loadStaticData(isAppendData) {
      return new Promise((resolve, reject) => {
        try {
          let data = this.$getRealStaticValue(this.staticValue || []); //this.$getDataFromMatrixData(this.staticValue);
          this.pageCount = data.length;
          let listData = this.$getPageList(data, this.currentPage, this.pageSize);
          if (!isAppendData) {
            this.$emit("update:data", listData);
          } else {
            this.$emit("update:data", this.data.concat(listData));
          }
          resolve(listData);
        } catch (err) {
          reject(err);
        }
      });
    },
    async loadServerData(isAppendData, pageIndex) {
      if (!this.$vm) {
        return;
      }
      let listData = [];
      let queryInfo = this.getQueryInfo();
      // let getFilter = this?.getFilter?.() || [];
      let pageNum = dsf.isDef(pageIndex) ? pageIndex : this.currentPage || 1;
      let pageSize = this.hasInfiniteScroll ? this.scrollPageSize : !this.showPagination ? 100 : this.pageSize;
      let params = {
        pageNum: pageNum,
        pageSize,
        query: JSON.stringify(queryInfo.query),
        order: JSON.stringify(queryInfo.order),
        filter: JSON.stringify(queryInfo.filter),
        namespace: this.$vm.nameSpace,
        pageName: this.$vm.pageName
      };
      // let params2 = {
      //   pageNum: pageNum,
      //   pageSize,
      //   query: JSON.stringify(queryInfo.query),
      //   filter: JSON.stringify(queryInfo.filter),
      //   namespace: this.$vm.nameSpace,
      //   pageName: this.$vm.pageName
      // };
      if (this.getAttachRequestParams) {
        params = await this.getAttachRequestParams(params);
      }

      dsf.mix(params, this.queryString);
      return this.$http
        .get("meta/list/data", params, true)
        .then(({ data }) => {
          if (data.state != 20000) {
            throw data;
          }
          listData = data.data;
          let ids = _.filter(listData, (it) => {
            return it._id;
          });
          if (ids.length != listData.length) {
            throw "结果数据未发现“_id”";
          }
          //将列表数据填充到表格
          if (!isAppendData) {
            this.$emit("update:data", listData);
          } else {
            this.$emit("update:data", this.data.concat(listData));
          }
          let params2 = dsf.mix({}, params, { order: undefined });
          return this.$http.get("meta/list/count", params2, true);
        })
        .then(({ data }) => {
          this.pageCount = data.data.count;
        })
        .then((res) => {
          return this.updateSummaries();
        })
        .catch((err) => {
          throw err;
        })
        .finally(() => {
          listData = null;
          this.$dispatch("data-loaded", { data: listData, count: this.pageCount });
          this.hasInfiniteScroll && this.currentPage++;
        });
    },
    scrollLoadData() {
      this.loadData(true);
    },
    //刷新数据，不变更页面等信息只重新当前页面的查询数据
    refreshData() {
      this.loadData();
    },
    async reloadData(resetPageIndex) {
      //是否清空选中项,
      if (this.isSearchResetSelect) {
        this.clearSelect();
      }
      if (dsf.isBoolean(resetPageIndex) && resetPageIndex === true) {
        resetPageIndex = 1;
      }
      if (this.$refs.buttonbar) {
        this.$refs.buttonbar.$forceUpdate();
      }
      if (this.$refs.superSearchBar) {
        this.$refs.superSearchBar.$forceUpdate();
      }
      if (this.hasInfiniteScroll) {
        resetPageIndex = 1;
      }
      if (resetPageIndex && dsf.isNumber(resetPageIndex) && this.currentPage != resetPageIndex) {
        this.currentPage = resetPageIndex;
      }
      await this.loadData();
    },
    updateSubControls(ctrlProps, ctrl) {
      return ctrlProps;
    },
    getSelectedRows() {
      return this.multipleSelection;
    },
    handleSelectionByTable(val, row) {
      this.handleSelection(val, row, "table");
    },
    handleSelectionByCard(val, row) {
      this.handleSelection(val, row, "cardList");
    },
    // 多选监听(单个选项被勾选)
    handleSelection(val, row, from) {
      if (this.currentShowLayout.type !== from) return;

      if (this.isSingleChecked) {
        this.multipleSelection = val.length ? row : null;
      } else {
        this.multipleSelection = val;
      }

      var isChecked = _.find(val, row);
      // 如果选择动作由表格触发，则需要同步卡片模式的选择状态
      if (from == "table" && this.$refs.cardBox?.length) {
        this.$refs.cardBox[0].toggleRowSelection(row, isChecked);
      }
      // 如果选择动作由卡片触发，则需要同步表格模式的选择状态
      else if (from == "cardList" && this.$refs.table?.length) {
        this.$refs.table[0].toggleRowSelection(row, isChecked);
      }
      this.$emit("handleSelection", isChecked, val, row);
    },
    // 多选监听(全选)
    handleSelectionAll(val, from) {
      // 如果选择动作由表格触发，则需要同步卡片模式的选择状态
      if (from == "table" && this.currentShowLayout.type == "table" && this.$refs.cardBox?.length) {
        this.$refs.cardBox[0].toggleAllSelection();
      }
      // 如果选择动作由卡片触发，则需要同步表格模式的选择状态
      else if (from == "cardList" && this.currentShowLayout.type == "cardList" && this.$refs.table?.length) {
        this.$refs.table[0].toggleAllSelection();
      }

      if (this.isSingleChecked) {
        this.multipleSelection = val?.[0] || null;
      } else {
        this.multipleSelection = val;
      }
      this.$emit("handleSelectionAll", this.multipleSelection, this.$refs.table[0].data);
    },
    // row 当前行数据 selected 是否选中
    toggleSelect(row, selected) {
      if (this.elTable && row) {
        if (this.isSingleChecked) {
          this.$refs.cardBox?.[0]?.toggleRowSelection(row, selected);
          this.$refs.table?.[0]?.toggleRowSelection(row, selected);
        } else {
          let arr = !selected ? this.multipleSelection : this.data;
          for (let i = 0; i < arr.length; i++) {
            const v = arr[i];
            if (v[key] == row || v[key] == row[key]) {
              this.$refs.cardBox?.[0]?.toggleRowSelection(v, selected);
              this.$refs.table?.[0]?.toggleRowSelection(v, selected);
              break;
            }
          }
        }
      }
    },
    // 多选时：清空用户的选择
    clearSelect() {
      this.$refs.cardBox?.[0]?.clearSelection();
      this.$refs.table?.[0]?.clearSelection();
      this.multipleSelection = null;
    },
    getHeaderClass({ row, column, rowIndex, columnIndex }) {
      let classList = [];
      if (column.columnKey) {
        let result = _.filter(this.customerOrder, (order) => order.column == column.columnKey);
        if (result.length > 0) {
          let order = result[0];
          if (order.sortType) {
            let sort = order.sortType + "ending";
            column.order = sort;
            classList.push(sort);
          } else {
            column.order = null;
          }
        }
      }
      return classList.join(" ");
    },
    tableSort({ column, prop, order }) {
      //排序互斥
      _.each(this.customerOrder, (it) => {
        it.sortType = "";
      });
      let result = _.filter(this.customerOrder, (colOrder) => {
        return colOrder.column == column.columnKey;
      });
      if (result.length > 0) {
        let columnOrder = result[0];
        let sort = order ? order.replace("ending", "") : null;
        columnOrder.sortType = sort;
        this.loadData();
      }
    },
    loadSelected() {
      if (this.$choiceParams) {
        let selectedData = this.$choiceParams.value || [];
        let rows = _.map(selectedData, (it) => {
          return {
            [key]: it.value || it._value || it[key] || it.id,
            name: it.text || it._text || it.name || it._name || it.label || it._label,
            $makeup: true
          };
        });
        let table = this.elTable;
        _.each(rows, (it) => {
          table.toggleRowSelection(it, true);
        });
      }
    },
    getReturnValue() {
      return this.getSelectedRows();
    },
    headerReset() {
      this.$emit("update:tableFilterResult", []);
      this.$emit("update:tableCustomerColumnWidths", null);
      this.$clearCustom(this.caption);
      this.elTable.headerReset();
    },
    //表格列尺寸拖动事件
    tableColumnResize(realFilterColumns) {
      let filterColumns = this.tableFilterResult;
      // 如果this.tableFilterResult不存在，则生成一份这样的数据
      if (!filterColumns?.length) {
        let columnsTree = this.$refs.table[0].columnsTree;
        filterColumns = createFilterResult(columnsTree);
      }
      // 将宽度从realFilterColumns中赋值到filterColumns
      updateWidthToFilterColumns(realFilterColumns, filterColumns);
      let ccws = {};
      _.each(realFilterColumns, (col) => {
        dsf.deepForEach(col, (it) => {
          if (it.isLast) {
            ccws[it.id] = it.width;
          }
        });
      });
      this.$emit("update:tableCustomerColumnWidths", ccws);
      this.$addCustom(this.caption, {
        tableCustomerColumnWidths: ccws
        // tableFilterResult: filterColumns
      });
    },
    getContentWrapStyle(item) {
      let res = {
        // height: this.contentWrapHeight,
        "background-color": item.backgroundColor
      };
      // if (this.currentShowLayout.type == "cardList") {
      //   res["padding"] = this.fit ? "20px 20px 0" : "20px 0 0";
      // }
      return res;
    },
    async rowDataChange(row) {
      let validateResult = [];
      try {
        validateResult = await this.validate(row);
      } catch (ex) {
        if (dsf.type(ex) == "array") {
          this.$vm.pushError(ex);
        } else {
          dsf.warn(ex);
        }
        return;
      }
      if (this.$listeners["row-data-change"]) {
        this.$dispatch("row-data-change", row);
      } else {
        this.updateRowDataChange(row);
      }
    },
    /** 默认的更新行数据的方法 */
    updateRowDataChange(row) {
      let sourceType = "metadata";
      //判断列表数据来源是数据源还是元数据
      if (this.childMetaDataCodes && this.childMetaDataCodes[0]) {
        if (this.metadataDict[this.childMetaDataCodes[0]].rmId) {
          sourceType = "datasrouce";
        }
        //如果是元数据则自动调用表单保存接口
        if (sourceType == "metadata" && this.isRowDataChangePost) {
          let arr = this.rowDataChangePostNameSpace.split(".");
          let nameSpace = arr.slice(0, arr.length - 1);
          this.loading = true;
          let params = $mixins.form.methods.$postDataToJson.call(this, row);
          let isSuccess = false, res = null;
          this.$http
            .post(
              this.rowDataChangePostUrl,
              {
                namespace: nameSpace.join("."),
                pageName: arr[arr.length - 1],
                data: params
              },
              true
            )
            .then(({ data }) => {
              const state = data.state;
              if (state != 20000) {
                throw data;
              } else {
                _.each(this.metadataDict, (it, key) => {
                  if (dsf.hasOwn(data.data, key)) {
                    row[key] = data.data[key];
                  }
                });
                isSuccess = true;
                res = data;
              }
            })
            .catch((ex) => {
              let message = "更新失败";
              if (dsf.isDef(ex.state) && dsf.isDef(ex.message) && dsf.isDef(ex.success)) {
                message = ex.message || message;
                dsf.layer.message(message, false);
              } else {
                dsf.error(ex);
              }
              res = {
                data: null,
                success: false,
                message: message
              };
            })
            .finally(() => {
              this.loading = false;
              if (this.$listeners["row-data-change-complete"]) {
                this.$dispatch("row-data-change-complete", res);
              }
              if (isSuccess && this.rowDataUpdatedCallReload) {
                this.reloadData();
              }
            });
        }
      }
    },
    /**高级检索相关事件 start */
    superSearchLoadedHandler(v) {
      this.isShow = true;
      if (!this.isDesign && this.mountedRequestData) {
        this.superValue = v;
        this.currentPage = 1;
        this.loadData();
        this.$nextTick(() => {
          if (this.superSearchBar) {
            this.superSearchBar.emitHeight();
          }
        });
      }
    },
    //高级检索值变化
    superSearchChangeHandler(v) {
      // this.$emit("update:superValue", v);
      this.superValue = v;
      this.search();
    },
    //高级检索区域高度发生变化
    superSearchChangeHeight(v) {
      let h = this.superSearchHeight;
      let e = this.superSearchExtend;
      if (!e) {
        this.superSearchHeight = v;
      }
    },
    //高级检索展开
    superSearchExtendHandler(v) {
      this.superSearchExtend = v;
    },
    /**高级检索相关事件 end */
    hasClick(evt, row) {
      row.$allowClick = evt.args;
    },
    async updateSummaries() {
      let table = this.$refs.table?.[0];
      if (table && this.showSummary && this.getSummaries) {
        this.summaries = [];
        let columns = [],
          lastColumns = [];
        if (this.checkboxColumn.show) {
          let checkCol = {
            label: "",
            code: "$selectionColumn"
          };
          columns.push(checkCol);
          lastColumns.push(checkCol);
        }
        if (this.indexColumn.show) {
          let indexCol = {
            label: "序号",
            code: "$indexColumn"
          };
          columns.push(indexCol);
          lastColumns.push(indexCol);
        }
        columns.push(...table.realFilterColumns);
        lastColumns.push(...table.realFilterLastColumns);
        let sums = await this.getSummaries({
          columns,
          lastColumns,
          data: this.data
        });
        this.summaries = sums;
        table.updateSummaries();
      }
    },
    // 右键菜单
    onContextmenu({ row, event, $index }) {
      let inject = {};
      ["$app", "$vm", "$page", "$form", "$dialog"].forEach((it) => {
        inject[it] = {
          default: this[it]
        };
      });
      inject["$list"] = {
        default: this
      };
      let DsfButtonBar = Vue.extend({
        mixins: [window.$components["platform"]["DsfButtonBar"]],
        inject
      });
      let buttonBar = new DsfButtonBar({
        propsData: {
          isDesign: false,
          owner: this,
          local: row,
          renderButton: this.renderRowButton,
          disabledButton: this.disabledRowButton,
          buttons: this.contextmenuList,
          emitPrefix: "row-button"
        }
      });
      let menus = [];
      _.forEach(buttonBar.validButtons, (b) =>
        menus.push({
          icon: b.icon,
          name: b.text || b.title,
          disabled: b.disabled,
          handler: b.isUpload
            ? async function () {
              let params = buttonBar.getButtonParams(b);
              let url = dsf.url.getWebPath(params[0]);
              let data = dsf.url.queryStringParse(params[1]);
              let accept = _.map(params[2]?.split("|"), (el) => {
                return dsf.mime[el.toLowerCase()];
              }).join(",");
              data.file = await dsf.file.load(accept);
              let loading = dsf.layer.loading();
              dsf.http
                .upload(url, data)
                .done((res) => {
                  buttonBar.clickHandler(b, event, res);
                })
                .error((err) => {
                  dsf.layer.message(err?.message || "上传文件失败", false);
                })
                .finally(() => {
                  dsf.layer.closeLoading(loading);
                });
            }
            : function () {
              buttonBar.clickHandler(b, event);
            }
        })
      );
      if (menus.length) {
        event.preventDefault();
        event.stopPropagation();
        contextMenuClick(
          {
            x: event.clientX,
            y: event.clientY
          },
          row,
          menus,
          event.target,
          event
        );
      }
    }
  },
  watch: {
    columns: {
      handler(v, ov) {
        if (this.isDesign) {
          updateSlots.call(this);
          this.isReDrawHander = true;
          this.$nextTick(() => {
            this.isReDrawHander = false;
          });
        }
      },
      deep: true
    },
    data: {
      handler(v) {
        let checkedDatas = [],
          ids = [],
          isduplicate = false;
        for (let i = 0; i < v.length; i++) {
          !("$editing" in v[i]) && this.$set(v[i], "$editing", false);
          !("$hash" in v[i]) && this.$set(v[i], "$hash", dsf.uuid());
          !("$allowClick" in v[i]) && this.$set(v[i], "$allowClick", false);
          //克隆行按钮数据
          !("$buttons" in v[i]) && this.$set(v[i], "$buttons", dsf.mix(true, [], this.rowButtons || []));
          if ("$order" in v[i]) {
            this.$set(v[i], "$order", i);
          } else {
            v[i]["$order"] = i;
          }

          v[i].$index = i;
          //校验数据_id是否重复
          let id = v[i][key];
          if (ids.indexOf(id) < 0) {
            ids.push(id);
          } else {
            isduplicate = true;
            // this.$set(v[i], "$duplicateKey", true);
          }
          // 复现多选
          // if (!this.isSingleChecked && this.layoutInfo.length > 1) {
          //   if (this.multipleSelection.find((item) => item[key] == v[i][key])) {
          //     checkedDatas.push(v[i][key]);
          //   }
          // }
        }
        // if (!this.isSingleChecked && this.layoutInfo.length > 1) {
        //   this.checkedDatas = checkedDatas;
        //   this.checkAll = checkedDatas.length == v.length;
        // }
        this.$nextTick(() => {
          this.resize();
          isduplicate && openDuplicateMessage();
        });
      },
      deep: false
    },
    tableMarginTop() {
      this.$nextTick(() => {
        this.doLayout();
      });
    },
    layoutInfo(to) {
      updateSlots.call(this, to);
    },
    superSearchInfo(v) {
      if (v.length == 0) {
        this.superSearchHeight = 0;
      }
    },
    "currentShowLayout.id"(to) {
      if (to) {
        this.$nextTick(() => {
          this.resize();
        });
      }
    },
    fit() {
      this.$nextTick(() => {
        this.resize();
      });
    }
  },
  design: {
    isMask: false,
    fit: true,
    layout: {
      exclude: ["data"]
    },
    metadata: {
      isDeep: false,
      create: createDataGridMetadata
    },
    settingMenus: [
      ...($mixins.list.design.settingMenus || []),
      {
        text: "表头设置",
        command: "header-setting",
        handler: headerSetting
      }
    ]
  }
});
</script>