<template>
  <div v-if="isDesign" class="ds-control ds-platform-personnel-card" :class="[imgPosition]" :style="{ 'fontFamily': fontFamily, 'border-color': borderColor }">
    <div class="davatar">
      <dsf-image class="avatar-pic"></dsf-image>
      <dsf-icon :name="iconName" :style="{ color: iconDefaultColor }" class="avatar-icon" v-if="showIcon"></dsf-icon>
      <dsf-icon :name="iconName" :style="{ color: iconActiveColor }" class="avatar-icon active-icon" v-if="showIcon"></dsf-icon>
      <p
        v-if="showTag"
        class="avatar-tag"
        :style="{
          'backgroundColor': tagBgcolor,
          'borderRadius': tagRadius,
          'bottom': $bottom,
          'width': $width
        }"
      >
        标签内容
      </p>
    </div>
    <div class="info">
      <div class="name">
        <p class="namep dotted-line">张三小哥</p>
        <div class="age dotted-line">
          <p :style="{ 'color': birthFontColor }">1991.04</p>
          <p :style="{ 'color': birthFontColor }">31岁</p>
        </div>
      </div>
      <div class="desc dotted-line" :class="[descClass]">
        <el-popover
          placement="top-start"
          width="240"
          trigger="hover"
          content="四川省成都市锦江区;四川省成都市锦江区;四川省成都市锦江区;四川省成都市锦江区;四川省成都市锦江区;四川省成都市锦江区;"
        >
          <p slot="reference" ref="desc" :style="{ 'color': descFontColor }">"四川省成都市锦江区;四川省成都市锦江区;四川省成都市锦江区;四川省成都市锦江区;四川省成都市锦江区;四川省成都市锦江区;"</p>
        </el-popover>
      </div>
    </div>
  </div>
  <div
    v-else
    class="ds-control ds-platform-personnel-card"
    :class="[imgPosition]"
    :style="{ 'fontFamily': fontFamily, 'border-color': borderColor  }"
    @click="cardClickHandler"
  >
    <div class="davatar">
      <dsf-image class="avatar-pic" :src="avatar | imgFormat" :error-src="errorSrc"></dsf-image>
      <dsf-icon
        v-if="showIcon"
        :name="iconName"
        :style="{ color: data['isCollect'] ? iconDefaultColor : iconDefaultColor }"
        class="avatar-icon"
      ></dsf-icon>
      <p
        v-if="showTag"
        class="avatar-tag"
        :style="{
          'backgroundColor': tagBgcolor,
          'borderRadius': tagRadius,
          'bottom': $bottom,
          'width': $width
        }"
      >
        {{ data['tag'] }}
      </p>
    </div>
    <div class="info">
      <div class="name">
        <p class="namep">{{ data['name'] }}</p>
        <div class="age">
          <p :style="{ 'color': birthFontColor }">{{ birthday }}</p>
          <p :style="{ 'color': birthFontColor }">{{ age }}</p>
        </div>
      </div>
      <div class="desc" :class="[descClass]">
        <el-popover
          v-if="overflowdiv"
          placement="top-start"
          width="240"
          trigger="hover"
          :content="data['jobDesc']"
        >
          <p slot="reference" ref="desc" :style="{ 'color': descFontColor }">{{ data['jobDesc'] }}</p>
        </el-popover>
        <p v-else ref="desc" :style="{ 'color': descFontColor }">{{ data['jobDesc'] }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default dsf.component({
  name: "DsfPlatformPersonnelCard",
  ctrlCaption: "人员信息卡片",
  mixins: [$mixins.card],
  data() {
    return {
      overflowdiv: false
    }
  },
  props: {
    // 图片加载失败的替代图片
    errorSrc: {
      type: String,
      default: ''
    },
    keysMap: {
      type: Array,
      default() {
        return [
          { from: 'avatar', to: 'avatar', type: 'text', desc: '头像' },
          { from: 'tag', to: 'tag', type: 'text', desc: '人物标签' },
          { from: 'name', to: 'name', type: 'text', desc: '姓名' },
          { from: 'birthday', to: 'birthday', type: 'text', desc: '出生年月' },
          { from: 'age', to: 'age', type: 'text|number', desc: '年纪' },
          { from: 'jobDesc', to: 'jobDesc', type: 'text', desc: '职位描述' },
          { from: 'isCollect', to: 'isCollect', type: 'boolean', desc: '是否收藏' },
          { from: '_id', to: '_id', type: 'text|number', desc: '唯一标识' }
        ];
      }
    },
    borderColor: {
      type: String,
      default: "#f1f1f1"
    },
    imgPosition: {
      type: String,
      default: "img-left"
    },
    showIcon: {
      type: Boolean,
      default: false
    },
    iconName: {
      type: String,
      default: "wujiaoxing1"
    },
    iconDefaultColor: {
      type: String,
      default: "#999999"
    },
    iconActiveColor: {
      type: String,
      default: "#E6A23C"
    },
    showTag: {
      type: Boolean,
      default: false
    },
    tagBgcolor: {
      type: String,
      default: "#E6A23C"
    },
    tagRadius: {
      type: String,
      default: "0px"
    },
    tagPadding: {
      type: Array,
      default: () => {
        return ["6px", "6px"]
      } 
    },
    fontFamily: {
      type: String,
      default: "Microsoft YaHei"
    },
    birthFontColor: {
      type: String,
      default: "#666666"
    },
    descFontColor: {
      type: String,
      default: "#666666"
    },
    jobdescPosition: {
      type: String,
      default: "left"
    },
    isCenter: {
      type: Boolean,
      default: false
    },
    birthformatter: {
      type: String,
      default: "yyyy.mm"
    }
  },
  computed: {
    descClass() {
      return this.isCenter ? `vertical-center ${this.jobdescPosition}` : this.jobdescPosition;
    },
    $bottom() {
      let padding = this.tagPadding;
      return dsf.isNumber(padding[1]) ? padding[1] + "px" : padding[1];
    },
    $width() {
      let padding = this.tagPadding;
      return `calc(98px - (2 * ${dsf.isNumber(padding[0]) ? `${padding[0]}px` : padding[0]}))`;
    },
    // $font_size() {
    //   let font_szie = this.fontSize.split(",").map(f => {
    //     return Number(String(f).replace("px", ""));
    //   });
    //   return font_szie;
    // },
    birthday() {
      let birthday = this.data?.birthday || "";
      return dsf.date.format(birthday, this.birthformatter);
    },
    age() {
      let age = this.data?.age || "";
      return String(age).indexOf("岁") > -1 ? age : `${Number(age)}岁`;
    },
    avatar() {
      let avatar = this.data?.avatar || "";
      try {
        avatar = JSON.parse(avatar)?.[0]?.relativePath;
      }
      catch(e) {
        avatar = "";
      }
      return avatar;
    }
  },
  watch: {
    multipleSelection(v) {
      console.log(v);
    },
  },
  created() {},
  mounted() {
    this.overflowdiv = this.$refs.desc.clientHeight < this.$refs.desc.scrollHeight;
  },
  methods: {
    cardClickHandler() {
      const selectRows = this.$vm.$refs?.gblb.getSelectedRows();
      console.log(this.$vm.$refs?.gblb);
      let ids = [];
      try {
        for (let item of selectRows) {
          ids.push(item?._id);
        }
      }
      catch(e) {
        ids = [];
      }
      this.$dispatch("card-item-click", { ids: ids, row: this.data });
    }
  },
});
</script>