var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-container",
    [
      _c("el-header", [
        _c(
          "div",
          {
            staticStyle: {
              "text-align": "right",
              height: "auto",
              margin: "0px -20px",
            },
          },
          [
            _c(
              "DsfButton",
              {
                attrs: { size: "small", type: "plain" },
                on: {
                  click: function ($event) {
                    return _vm.toChoose("clear")
                  },
                },
              },
              [_vm._v("清除")]
            ),
            _c(
              "DsfButton",
              {
                attrs: { size: "small", type: "plain" },
                on: {
                  click: function ($event) {
                    return _vm.toChoose("election")
                  },
                },
              },
              [_vm._v("反选")]
            ),
            _c(
              "DsfButton",
              {
                attrs: { size: "small" },
                on: {
                  click: function ($event) {
                    return _vm.toChoose("all")
                  },
                },
              },
              [_vm._v("全选")]
            ),
          ],
          1
        ),
      ]),
      _c(
        "el-main",
        { staticStyle: { padding: "0px" } },
        [
          _c(
            "el-checkbox-group",
            {
              on: { change: _vm.handleCheckedChange },
              model: {
                value: _vm.checkboxData,
                callback: function ($$v) {
                  _vm.checkboxData = $$v
                },
                expression: "checkboxData",
              },
            },
            _vm._l(_vm.listData, function (item) {
              return _c(
                "el-checkbox",
                { key: item.key, attrs: { label: item.key } },
                [_vm._v(_vm._s(item.alias))]
              )
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }