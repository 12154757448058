<template>
  <div class="ds-control ds-form-item ds-checkbox" :class="getCss">
    <template v-if="simple && readOnly && !isDesign">
      <DsfTextProxy v-model="value" v-bind="_self.$props" :show-label="false" />
      <slot name="error">
        <div v-if="errors.length" class="ds-error-text">
          {{ errorsMsg }}
        </div>
      </slot>
    </template>
    <template v-else>
      <!--<label v-if="showLabel" class="ds-form-label" :style="getLabelWidthStyle()">
        {{ label }}
        <i v-if="dataCapion" class="iconfont icon-bangzhuzhongxin ds-control-helper" @click="showHelper"></i>
      </label>-->
      <DsfFieldLabel v-if="showLabel" :mode="showDataCaptionMode" :style="getLabelWidthStyle()" :show-icon="showDataCaptionIcon" :trigger="showDataCaptionTrigger" :data-caption="dataCapion" :is-design="isDesign" :owner="_self">
        {{ $t(label) }}
      </DsfFieldLabel>
      <div class="ds-form-block" :class="{ border: border || isButton }" :style="getFormItemBlockMarginLeft()">
        <!--只读状态只显示div-->
        <div v-if="readOnly" class="ds-form-readonly">
          <DsfTextProxy v-model="value" v-bind="_self.$props" :show-label="false" />
        </div>
        <template v-else>
          <slot name="before"></slot>
          <el-checkbox-group v-if="isButton" v-model="selectValue" :disabled="disabled" v-bind="options" @change="selectChange">
            <template v-for="(item, index) in realItems">
              <el-checkbox-button :key="index" :label="item[valueField]" :disabled="item.disabled || disabledItemValues.indexOf(item[valueField]) > -1">
                {{ item[optionsTextField] }}
              </el-checkbox-button>
            </template>
          </el-checkbox-group>
          <el-checkbox-group v-else v-model="selectValue" :disabled="disabled" v-bind="options" @change="selectChange">
            <template v-for="(item, index) in realItems">
              <div :key="index" class="ds-checkbox-item">
                <el-checkbox :key="index" :label="item[valueField]" :border="border" :disabled="item.disabled || disabledItemValues.indexOf(item[valueField]) > -1">
                  {{ item[optionsTextField] }}
                </el-checkbox>
                <slot name="append-input">
                  <el-input
                    v-if="attachOptions[item[valueField]] !== void 0"
                    :value="attachOptions[item[valueField]]"
                    size="small"
                    class="ds-checkbox-input"
                    :class="{'is-bordered': border}"
                    :style="{width: attachWidth}"
                    placeholder="请输入内容"
                    @input="setAttach(item[valueField], $event)"
                  />
                </slot>
               
              </div>
            </template>
          </el-checkbox-group>
        </template>

        <slot name="error">
          <div v-if="errors.length" class="ds-error-text">
            {{ errorsMsg }}
          </div>
        </slot>
      </div>
    </template>
  </div>
</template>

<script>
export default dsf.component({
  name: "DsfCheckBox",
  mixins: [$mixins.formControl, $mixins.itemsMixins],
  ctrlCaption: "多选",
  props: {
    simple: {
      type: Boolean,
      default: false
    },
    // 需要禁用选项的value值得集合
    disabledItemValues: {
      type: Array,
      default() {
        return [];
      }
    },
    type: {
      validator: function (value) {
        // 0: 普通；1：带边框；2：按钮
        return [0, 1, 2].indexOf(parseInt(value)) !== -1;
      },
      default: 0
    },
    maxChooseNum: {
      type: Number,
      default: -1
    },
    minChooseNum: {
      type: Number,
      default: -1
    }
  },
  data() {
    return {
      multiple: true
    };
  },
  computed: {
    border() {
      return this.type === 1;
    },
    isButton() {
      return this.type === 2;
    },
    options() {
      const opt = {};
      if (this.maxChooseNum >= 0) {
        opt.max = this.maxChooseNum;
      }
      if (this.minChooseNum >= 0) {
        opt.min = this.minChooseNum;
      }
      return opt;
    }
  }
});
</script>
