var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "ds-control ds-form-item ds-upload",
      class: _vm.getCss,
    },
    [
      _vm.showLabel
        ? _c(
            "DsfFieldLabel",
            {
              style: _vm.getLabelWidthStyle(),
              attrs: {
                mode: _vm.showDataCaptionMode,
                "show-icon": _vm.showDataCaptionIcon,
                trigger: _vm.showDataCaptionTrigger,
                "data-caption": _vm.dataCapion,
                "is-design": _vm.isDesign,
                owner: _vm._self,
              },
            },
            [_vm._v(" " + _vm._s(_vm.$t(_vm.label)) + " ")]
          )
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "ds-form-block",
          style: _vm.getFormItemBlockMarginLeft(),
        },
        [
          _c(
            "el-upload",
            {
              ref: "upload",
              staticClass: "upload-demo",
              style: { visibility: _vm.readOnly ? "hidden" : "visible" },
              attrs: {
                accept: _vm.acceptMime,
                multiple: _vm.multiple,
                headers: _vm.headersParam,
                limit: parseInt(_vm.uploadFileNum),
                action: _vm.setRequestUrl,
                "show-file-list": false,
                "before-upload": _vm.beforeUpload,
                "on-success": _vm.handleSuccess,
                "on-error": _vm.handleError,
                "on-exceed": _vm.handleExceed,
                "on-progress": _vm.handleProgress,
                "auto-upload": _vm.autoUpload,
              },
            },
            [
              _c("dsf-button", { attrs: { icon: "yunduanshangchuan" } }, [
                _vm._v(" " + _vm._s(_vm.showBtnName ? _vm.btnName : "") + " "),
              ]),
            ],
            1
          ),
          _vm.multipleFiles && _vm.multipleFiles.length > 0
            ? _c(
                "dsf-button",
                {
                  staticClass: "ds-button download-button",
                  attrs: { icon: "yunduanxiazai" },
                  on: { click: _vm.downLoadFn },
                },
                [_vm._v(" 全部下载 ")]
              )
            : _vm._e(),
          !_vm.isDesign && _vm.simple && _vm.formatter
            ? [
                _vm._v(
                  " " +
                    _vm._s(_vm._f("proxy")(_vm.multipleFiles, _vm.formatter)) +
                    " "
                ),
              ]
            : _vm._e(),
          _vm.multipleFiles && _vm.multipleFiles.length > 0
            ? _c(
                "div",
                { staticClass: "img-container" },
                _vm._l(_vm.multipleFiles, function (item, index) {
                  return _c(
                    "div",
                    { key: item.id, staticClass: "pic" },
                    [
                      _c("el-image", {
                        ref: "elImage",
                        refInFor: true,
                        staticStyle: { width: "130px", height: "92px" },
                        attrs: {
                          src: _vm.dsf.url.getWebPath(item.relativePath),
                          alt: item.originalFileName,
                          "preview-src-list": [
                            _vm.dsf.url.getWebPath(item.relativePath),
                          ],
                          fit: "cover",
                          "z-index": _vm.zIndex,
                        },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.clickHandler(index, $event)
                          },
                        },
                      }),
                      _c(
                        "div",
                        { staticClass: "title" },
                        [
                          _c(
                            "p",
                            {
                              staticClass: "file-name ellipsis",
                              attrs: { title: item.originalFileName },
                            },
                            [_vm._v(" " + _vm._s(item.originalFileName) + " ")]
                          ),
                          _vm._l(_vm.handleList, function (items, key) {
                            return [
                              key !== "upload2" &&
                              key !== "scissors" &&
                              !_vm.readOnly
                                ? _c("i", {
                                    key: key,
                                    class: ["operation-icon", "el-icon-" + key],
                                    attrs: { title: items },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleColumnClick(
                                          key,
                                          item,
                                          index,
                                          _vm.multipleFiles
                                        )
                                      },
                                    },
                                  })
                                : _vm._e(),
                              _vm.isCutPic &&
                              key === "scissors" &&
                              !_vm.readOnly
                                ? _c("i", {
                                    key: key,
                                    staticClass:
                                      "operation-icon el-icon-scissors",
                                    attrs: { title: items },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleColumnClick(
                                          key,
                                          item,
                                          index,
                                          _vm.multipleFiles
                                        )
                                      },
                                    },
                                  })
                                : _vm._e(),
                              key === "upload2" && !_vm.readOnly
                                ? _c(
                                    "el-upload",
                                    {
                                      key: key,
                                      staticClass: "list-upload",
                                      attrs: {
                                        accept: _vm.acceptMime,
                                        multiple: false,
                                        headers: _vm.headersParam,
                                        limit: 1,
                                        action: _vm.setRequestUrl,
                                        "show-file-list": false,
                                        "before-upload": _vm.beforeUpload,
                                        "on-success": _vm.handleSuccess,
                                        "on-error": _vm.handleError,
                                        "on-exceed": _vm.handleExceed,
                                        "auto-upload": _vm.autoUpload,
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass:
                                          "operation-icon el-icon-upload2",
                                        attrs: { title: items },
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleColumnClick(
                                              key,
                                              item,
                                              index,
                                              _vm.multipleFiles
                                            )
                                          },
                                        },
                                      }),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  )
                }),
                0
              )
            : _vm._e(),
          _vm.description
            ? _c("div", { staticClass: "ds-upload-description" }, [
                _vm._v(_vm._s(_vm.description)),
              ])
            : _vm._e(),
          _vm.errors.length > 0 && _vm.multipleFiles.length <= 0
            ? _c("div", { staticClass: "ds-error-text" }, [
                _vm._v(" " + _vm._s(_vm.errorsMsg) + " "),
              ])
            : _vm._e(),
        ],
        2
      ),
      _c("crop-image", {
        attrs: {
          "edit-img": _vm.editImg,
          "edit-file-img": _vm.editFileImg,
          "aspect-ratio": _vm.aspectRatio,
          "request-url": _vm.setRequestUrl,
        },
        on: { saveFile: _vm.editFile, handleClose: _vm.handleClose },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }