/*
 * @Descripttion: 折线柱图混合配置选项
 * @Author: zhanghang
 * @Date: 2021-07-28 17:34:40
 * @LastEditors: zhanghang
 * @LastEditTime: 2021-09-07 14:15:30
 */
import common from "./common"
import xaxis from "./xaxis"
import yaxis from "./yaxis"
export default dsf.mix(true, {
  //数据系配置
  series: [{
    type: 'bar',
    name: '示例_bar',
    //对应x轴下标
    yAxisIndex: 0,
    //对应y轴下标
    xAxisIndex: 0,
    //标签是否展示
    label: {
      show: false
    },
    // 柱体样式
    itemStyle: {
      color: common.color[0],
      // 柱体圆角
      barBorderRadius: [0, 0, 0, 0],

      endColor: common.color[0],
      startColor: common.color[0],
      isshade: false,

    },
    // 不同柱系间隙
    barGap: '30%',
    // 相同柱系间隙
    barCategoryGap: '10%',
    // 柱体宽度
    barWidth: '',
    //柱子背景色
    showBackground: false,
    //数据
    data: [420, 532, 501, 534, 690, 830, 820],
  },{
    type: 'line',
    name: '示例_line',
    //对应x轴下标
    yAxisIndex: 0,
    //对应y轴下标
    xAxisIndex: 0,
    // 标记图形
    symbol: 'emptyCircle',
    //标记大小
    symbolSize: 4,

    //是否展示标记
    showSymbol: false,
    //标签是否展示
    label: {
      show: false
    },
    //是否平滑
    smooth: true,
    // 区域颜色
    areaStyle: {
      color: 'rgba(255,255,255,0)',
      endColor:'rgba(255,255,255,0)',
      startColor: 'rgba(255,255,255,0)',
      isshade: false
    },
    // 线条样式
    itemStyle: {
      color: common.color[1],
      endColor: common.color[1],
      startColor: common.color[1],
      isshade: false
    },
    lineStyle: {
      width: 2,
      type: 'solid'
    },
    //数据
    data: [820, 932, 901, 934, 1290, 1330, 1320],
  }],
  //提示配置
  tooltip: {
    trigger: 'axis',
  },
}, common, xaxis, yaxis);