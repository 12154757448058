<script>
import dataChoice from "./dataChoice";

export default dsf.component({
  name: "DsfOuaSelect",
  mixins: [dataChoice],
  ctrlCaption: "部门人员",
  props: {
    dialogUrl: {
      type: String,
      default: "dsfa/oua/select",
    },
    dialogTitle: {
      type: String,
      default: "部门人员选择",
    },
    dataConfig: {
      type: Array,
      default: function () {
        return [];
      },
    },
    sort: {
      type: Boolean,
      default: false,
      desc: "是否可排序",
    },
  },
  methods: {
    getChoiceParams() {
      return {
        slots: this.dataConfig,
        sort: this.sort,
        maxLength: this.maxTags,
      };
    },
  },
});
</script>
