var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a",
    {
      directives: [{ name: "drag", rawName: "v-drag" }],
      ref: "eleDesgin",
      staticClass: "to_desgin",
      attrs: { "data-flag": "true", title: "进入" + _vm.title + "设计模式" },
      on: {
        click: _vm.gotoPreview,
        mouseenter: _vm.mouseenter,
        mouseleave: _vm.mouseleave,
      },
    },
    [_c("i", { staticClass: "iconfont icon-shezhi" })]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }