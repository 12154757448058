var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "DsfHomePanel",
    {
      staticClass: "dsf-management-box-notice",
      attrs: {
        title: _vm.title,
        titleIcon: _vm.titleIcon,
        moreIcon: "shezhi",
        moreConfig: _vm.moreConfig,
        padding: _vm.padding,
        margin: _vm.margin,
        styleType: _vm.styleType,
        height: _vm.heightPanel,
        borderRadius: _vm.borderRadius,
        width: _vm.widthPanel,
        moreShow: true,
      },
      on: { moreBtn: _vm.moreBtn },
    },
    [
      _c("DsfNavMenu", {
        ref: "navmenu",
        attrs: {
          dataSourceUrl: _vm.dataUrl,
          colNum: _vm.colNum,
          rowNum: _vm.rowNum,
          maxPages: _vm.maxPages,
          height: _vm.height,
          minHeight: _vm.minHeight,
          arrow: _vm.arrow,
          useDefaultStyle: _vm.useDefaultStyle,
          hasMore: _vm.hasMore,
          moreText: _vm.moreText,
          moreIcon: _vm.moreIcon,
          moreUrl: _vm.moreUrl,
          moreOpentype: _vm.moreOpentype,
          hoverAnimation: _vm.hoverAnimation,
          defaultIcon: _vm.defaultIcon,
          showPriority: _vm.showPriority,
          menuHasBg: _vm.menuHasBg,
          menuBgColor: _vm.menuBgColor,
          menuBgShadow: _vm.menuBgShadow,
          menuColor: _vm.menuColor,
          menuShadow: _vm.menuShadow,
          menuBorderRadius: _vm.menuBorderRadius,
          menuBorderSize: _vm.menuBorderSize,
          menuIconSize: _vm.menuIconSize,
          menuBtSize: _vm.menuBtSize,
          menuBtRadius: _vm.menuBtRadius,
          dataSource: _vm.dataSource,
          dataSourceList: _vm.dataSourceList,
          itemClick: _vm.itemClick,
          "is-design": _vm.isDesign,
        },
      }),
      _vm.menuDialog
        ? _c(
            "el-dialog",
            {
              attrs: {
                "append-to-body": true,
                title: "自定义菜单",
                visible: _vm.menuDialog,
                width: "1000px",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.menuDialog = $event
                },
              },
            },
            [
              _c(
                "div",
                { staticStyle: { height: "400px" } },
                [
                  _c("DsfNavMenuSetting", {
                    ref: "menuSetting",
                    attrs: { value: _vm.navValue },
                  }),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  staticStyle: { "text-align": "right" },
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c("dsf-button", { on: { click: _vm.menuChoose } }, [
                    _vm._v(" 确 定 "),
                  ]),
                  _c(
                    "dsf-button",
                    {
                      attrs: { type: "plain" },
                      on: {
                        click: function ($event) {
                          _vm.menuDialog = false
                        },
                      },
                    },
                    [_vm._v("取 消")]
                  ),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }