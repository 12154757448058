<template>
  <div class="ds-control ds-title-box" :class="getCss" @dblclick="beginEdit">
    <label v-if="!isDesign" class="ds_label" :class="getLabelCss">
      <template v-if="hasHelper && dataCapionTarget == 'text'">
        <el-popover
          v-if="dataCapionType == 'hoverPop' || dataCapionType == 'clickPop'"
          class="ds_label-helper"
          placement="top"
          title=""
          width="200"
          :trigger="dataCapionType == 'hoverPop' ? 'hover' : 'click'">
          <span slot="reference" class="ds_label-text">{{ value }}</span>
          <div v-html="dsf.safe.xss(dataCapion)"></div>
        </el-popover>
        <span v-else class="ds_label-text" @click="showHelper">{{ value }}</span>
      </template>
      <span v-else class="ds_label-text">{{ value }}</span>
      <slot name="helper">
        <template v-if="hasHelper && dataCapionTarget == 'icon'">
          <el-popover
            v-if="dataCapionType == 'hoverPop' || dataCapionType == 'clickPop'"
            class="ds_label-helper"
            placement="top"
            title=""
            width="200"
            :trigger="dataCapionType == 'hoverPop' ? 'hover' : 'click'">
            <i slot="reference" class="iconfont icon-bangzhuzhongxin"></i>
            <div v-html="dsf.safe.xss(dataCapion)"></div>
          </el-popover>
          <i v-else class="iconfont icon-bangzhuzhongxin ds_label-helper" @click="showHelper"></i>
        </template>
      </slot>
    </label>
    <label v-else-if="!editting" class="ds_label ds-label-box pc" :class="getLabelCss">
      <span class="ds_label-text">{{ value || '无内容' }}</span>
      <slot name="helper">
        <i v-if="hasHelper && dataCapionTarget == 'icon'" class="iconfont icon-bangzhuzhongxin ds_label-helper"></i>
      </slot>
    </label>
    <!-- <div v-else ref="contenteditable" class="contenteditable" :class="getLabelCss" style="display: block" contenteditable @blur="endEdit">
      {{ value }}
    </div> -->
    <el-input
      v-else
      ref="contenteditable"
      :class="['ds-font' + this.fontType,this.showWeight ? 'ds-bold':'','ds_font_' + this.fontFamily]" 
      v-model="value"
      type="textarea"
      autosize
      placeholder="请输入内容"
      resize="none"
      @blur="endEdit" />
    <div class="ds-title-box-right">
      <slot></slot>
    </div>
    <div class="right-icon" :class="['ds-font' + this.fontType]" :style="rightIconStyle" @click="rightIconClick">
      <span v-if="rightText">{{ rightText }}</span>
      <i
        v-if="rightIcon != ''"
        class="ds-title-box-right-icon iconfont"
        :class="[getIcon(rightIcon)]"
      />
    </div>
  </div>
</template>

<script>
export default dsf.component({
  name: 'DsfTitle',
  mixins: [$mixins.text],
  inheritAttrs: false,
  ctrlCaption: "标题",
  props: {
    value: {
      type: [String, Number],
      default: '标题'
    },
    fontType: {
      type: Number,
      default: 1
    },
    showColorLump: {
      type: Boolean,
      default: false
    },
    showUnderline: {
      type: Boolean,
      default: false
    },
    showWeight: {
      type: Boolean,
      default: false
    },
    textAlign: {
      type: String,
      default: ''
    },
    fontFamily: {
      type: String,
      default: ''
    },
    // 右侧图标
    rightIcon: {
      type: String,
      default: ''
    },
    rightText: {
      type: String,
      default: ''
    },
    rightIconColor: {
      type: String,
      default: '#888'
    },
    rightIconBold: {
      type: Boolean,
      default: false
    },
    rightIconConfig: {
      type: Object,
      default: () => ({}),
    },
    //是否是标题
    isTitleControl:{
      type:Boolean,
      default:true
    }
  },
  data() {
    return {
      editting: false,
      $required: false
    }
  },
  computed: {
    getCss() {
      const $class = this.$getClass();
      $class.push(
        this.textAlign,
        this.showColorLump ? 'ds-showColorLump' : '',
        'ds_font_' + this.fontFamily,
        this.$required ? 'ds_required' : ''
      );
      return $class;
    },
    getLabelCss() {
      return [
        'ds-font' + this.fontType,
        this.showUnderline ? 'underline' : '',
        this.showWeight ? 'ds-bold' : ''
      ];
    },
    rightIconStyle() {
      return {
        'color': this.rightIconColor == 'theme' ? dsf.themeConfig.currentTheme.normal : this.rightIconColor,
        'font-weight': this.rightIconBold ? 'bold' : 'normal'
      }
    },
    hasHelper() {
      return !!this.dataCapion;
    }
  },
  methods: {
    beginEdit () {
      this.editting = true;
      this.$refs.contenteditable && setTimeout(() => {
        this.$refs.contenteditable.focus();
      }, 0);
    },
    endEdit (e) {
      if (this.isDesign) {
        this.editting = false;
        this.value = e.target.value;
      }
    },
    getIcon(icon) {
      if (!icon || icon == '') return '';
      return !icon.indexOf('icon-') ? icon : 'icon-' + icon;
    },
    rightIconClick() {
      if (this.rightIconConfig && !this.rightIconConfig.title) {
        this.rightIconConfig.title = this.title;
      }
      this.$open({ ...this.rightIconConfig }).closed = () => {
        this.reloadData();
      };
    },
  }
});
</script>