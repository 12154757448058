var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dsf-angle-select" }, [
    _c(
      "div",
      {
        ref: "sliderBox",
        staticClass: "dsf-angle-select__pane",
        on: { mousedown: _vm.mousedown },
      },
      [_c("span", { staticClass: "slider", style: _vm.thumbStyle })]
    ),
    _c(
      "div",
      { staticClass: "dsf-angle-select__editor" },
      [
        _c("el-input-number", {
          attrs: { min: 0, max: 360, size: "mini", "step-strictly": "" },
          model: {
            value: _vm.angle,
            callback: function ($$v) {
              _vm.angle = _vm._n($$v)
            },
            expression: "angle",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }