<template>
  <div class="ds-control ds-form-item ds-cascader" :class="getCss">
    <template v-if="simple && readOnly && !isDesign">
      <DsfTextProxy v-model="value" v-bind="_self.$props" />
      <slot name="error">
        <div v-if="errors.length" class="ds-error-text">{{ errorsMsg }}</div>
      </slot>
    </template>
    <template v-else>
      <!-- <label v-if="showLabel" class="ds-form-label" :style="getLabelWidthStyle()">
        {{ label }}
        <i v-if="dataCapion" class="iconfont icon-bangzhuzhongxin ds-control-helper" @click="showHelper"></i>
      </label> -->
      <DsfFieldLabel
        v-if="showLabel"
        :mode="showDataCaptionMode"
        :style="getLabelWidthStyle()"
        :show-icon="showDataCaptionIcon" 
        :trigger="showDataCaptionTrigger"
        :data-caption="dataCapion"
        :is-design="isDesign"
        :owner="_self">
        {{ $t(label) }}
      </DsfFieldLabel>
      <div class="ds-form-block" :style="getFormItemBlockMarginLeft()">
        <el-cascader
          v-if="!readOnly"
          ref="elCascader"
          v-model="selectValue"
          v-bind="bindAttr"
          :options="realItems"
          :disabled="disabled"
          :filterable="filterable"
          :placeholder="placeholder"
          :clearable="clearable"
          :props="myProps"
          :separator="separator"
          :show-all-levels="showAllLevels"
          :class="{'ds-error-border': errors.length}"
          @change="selectChange"
          @blur="blur"
          @focus="focus"
          @visible-change="visibleChange"
          @remove-tag="removeTag">
          <template #default="{node, data}">
            {{ data[optionsTextField] }}
          </template>
        </el-cascader>
        <!--只读状态只显示div-->
        <div v-else-if="readOnly" class="ds-form-readonly">
          <DsfTextProxy v-model="value" v-bind="_self.$props" :show-label="false"></DsfTextProxy>
        </div>
        <slot name="error">
          <div v-if="errors.length" class="ds-error-text">{{ errorsMsg }}</div>
        </slot>
      </div>
    </template>
  </div>
</template>

<script>
export default dsf.component({
  name: "DsfCascader",
  mixins: [$mixins.formControl],
  ctrlCaption: "下拉框级联",
  props: {
    separator: {
      type: String,
      default: "/"
    },
    simple: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: "请选择"
    },
    // 值
    value: {
      type: [Object, Array],
      required: false
    },
    // 选项
    items: {
      type: Array,
      default() {
        return [];
      }
    },
    // 是否可搜索
    filterable: {
      type: Boolean,
      default: false
    },
    // 自定义搜索方法
    filterMethod: {
      type: Function,
      required: false
    },
    // 是否支持选项清空
    clearable: {
      type: Boolean,
      default: true
    },
    // 输入框中是否显示选中值的完整路径
    showAllLevels: {
      type: Boolean,
      default: true
    },
    // 多选模式下是否折叠Tag
    collapseTags: {
      type: Boolean,
      default: false
    },
    // 次级菜单的展开方式 click / hover
    expandTrigger: {
      type: String,
      default: "click"
    },
    // 是否多选
    multiple: {
      type: Boolean,
      default: false
    },
    // 是否可选择任意一级
    checkStrictly: {
      type: Boolean,
      default: false
    },
    // 是否懒加载
    lazy: {
      type: Boolean,
      default: false
    },
    // 加载动态数据的方法
    lazyLoad: {
      type: Function,
      required: false
    },
    metadata: {
      type: Object,
      default() {
        return dsf.metadata.get("items-meta-data");
      }
    },
    formatter: {
      type: String,
      default: "textValue^text"
    },
    // 值字段
    valueField: {
      type: String,
      default: "value"
    },
    // 文本字段
    textField: {
      type: String,
      default: "text"
    },
    // 别名字段
    aliasField: {
      type: String,
      default: "alias"
    },
    // 子级字段
    childrenField: {
      type: String,
      default: "children"
    },
    // 选项中显示的文本字段
    optionsTextField: {
      type: String,
      default: "text"
    },
    // 选中显示的字段
    selectedTextField: {
      type: String,
      default: "text"
    },
    // 提交数据是否包含别名
    postHasAlias: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isFocus: false
    };
  },
  computed: {
    bindAttr() {
      let res = {};
      if (this.filterMethod) {
        res.filterMethod = this.filterMethod.bind(this);
      }
      return res;
    },
    myProps() {
      let res = {
        expandTrigger: this.expandTrigger === "click" ? "click" : "hover",
        checkStrictly: this.checkStrictly,
        multiple: this.multiple,
        value: this.valueField,
        label: this.selectedTextField,
        children: this.childrenField,
        lazy: this.lazy
      };
      if (this.lazyLoad) {
        res.lazyLoad = this.lazyLoad.bind(this);
      }
      return res;
    },
    realItems() {
      if (this.isDesign) {
        return [];
      }
      return this.items || [];
    },
    selectValue: {
      get() {
        let val = this.value;
        if (dsf.isObject(val) && val.text === "" && val.value === "" && !val.alias) val = null;
        if (dsf.isUnDef(val) || dsf.isEmptyObject(val)) {
          return this.multiple ? [] : null;
        }
        if (this.multiple) {
          if (dsf.type(val) == "array") {
            return val.map((v) => this.getValuePath(v.value));
          } else if (dsf.type(val) == "object") {
            return [this.getValuePath(val.value)];
          } else {
            return [];
          }
        } else {
          if (dsf.type(val) == "array") {
            return this.getValuePath(val[0].value);
          } else if (dsf.type(val) == "object") {
            return this.getValuePath(val.value);
          } else {
            return null;
          }
        }
      },
      set(v) {
        if (!v || !v.length) {
          let item = { text: "", value: "" };
          if (this.postHasAlias) {
            item.alias = "";
          }
          this.emitValueChange(item);
          return;
        }
        if (!this.multiple) {
          let val = this.getValueByPath(v);
          this.emitValueChange(val);
        } else {
          let val = v.map((v2) => this.getValueByPath(v2));
          this.emitValueChange(val);
        }
      }
    }
  },
  watch: {
    readOnly(to) {
      if (!to) {
        this.bindInputChange();
      } else {
        this.input && this.input.removeEventListener("input", this.inputChange);
      }
    }
  },
  mounted() {
    if (!this.isDesign && !this.simple && !this.readOnly) {
      this.bindInputChange();
    }
  },
  beforeDestroy() {
    clearTimeout(this.timeout);
    this.input?.removeEventListener("input", this.inputChange);
  },
  methods: {
    bindInputChange() {
      this.$nextTick(() => {
        this.input = this.$refs.elCascader?.$el.querySelector(".el-input__inner");
        this.input?.addEventListener("input", this.inputChange);
      });
    },
    inputChange() {
      if (this.timeout != undefined) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(() => {
        this.$dispatch("input-change", this.input.value);
      }, 300);
    },
    getValuePath(val) {
      let res = [];
      dsf.deepForEach(
        this.realItems,
        (item, level) => {
          let value = item[this.valueField];
          res.length = level + 1;
          res[level] = value;
          if (val === value) return -1;
        },
        {
          children: this.childrenField
        }
      );
      return res;
    },
    getValueByPath(path) {
      path = _.cloneDeep(path);
      let items = this.realItems;
      if (items == null || !path.length) {
        return null;
      }
      let text = [],
        alias = [],
        value = "",
        n = 0;
      dsf.deepForEach(
        this.realItems,
        (item, level) => {
          let tex = item[this.textField];
          let ali = item[this.aliasField];
          let val = item[this.valueField];
          if (path[level] === val) {
            text.push(tex);
            alias.push(ali);
            value = val;
            n++;
          }
          if (path.length === n) return -1;
        },
        {
          children: this.childrenField
        }
      );
      let res = {
        text: this.showAllLevels ? text.join(this.separator) : text.pop(),
        value
      };
      if (this.postHasAlias) {
        res["alias"] = this.showAllLevels ? alias.join(this.separator) : alias.pop();
      }
      return res;
    },
    selectChange() {
      this.$nextTick(() => {
        this.$dispatch("change", this.value);
        this.emitFormValidate(this.value);
      });
    },
    blur(...arg) {
      this.isFocus = false;
      this.$dispatch("blur", ...arg);
    },
    focus(...arg) {
      this.isFocus = true;
      this.$dispatch("focus", ...arg);
    },
    // 下拉框隐藏时
    visibleChange(arg) {
      this.$dispatch("visible-change", arg);
    },
    // 在多选模式下，移除Tag时触发
    removeTag(...arg) {
      this.$dispatch("remove-tag", ...arg);
    }
  },
  design: {
    layout: {
      //#开头表示该属性在预览模式下会被保留
      exclude: ["value", "#items"]
    },
    settingMenus: [
      ...($mixins.formControl.design.settingMenus || []),
      {
        text: "选项设置",
        command: "datasource",
        handler() {
          dsf.designer.triggerDialogPropty("metadata.dataSource");
        }
      }
    ],
    metadata: {
      create(root) {
        let res = $mixins.formControl.design.metadata.create.call(this, root);
        let aliasIndex = _.findIndex(res.metadata.valueAttributes, (it) => it.code === "alias");
        if (this.postHasAlias && aliasIndex === -1) {
          res.metadata.valueAttributes.push({
            name: "别名",
            code: "alias",
            type: dsf.metadata.getDataType("string"),
            length: 100,
            defaultValue: null,
            encrypt: false,
            unit: null
          });
        } else if (!this.postHasAlias && aliasIndex > -1) {
          res.metadata.valueAttributes.splice(aliasIndex, 1);
        }
        return res;
      }
    }
  }
});
</script>
