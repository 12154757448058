var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ds-control ds-form-item ds-textbox", class: _vm.getCss },
    [
      _vm.simple && _vm.readOnly && !_vm.isDesign
        ? [
            _c(
              "DsfTextProxy",
              _vm._b(
                {
                  model: {
                    value: _vm.value,
                    callback: function ($$v) {
                      _vm.value = $$v
                    },
                    expression: "value",
                  },
                },
                "DsfTextProxy",
                _vm._self.$props,
                false
              )
            ),
            _vm.value != ""
              ? _c("span", [_vm._v(_vm._s(_vm.append))])
              : _vm._e(),
            _vm._t("error", function () {
              return [
                _vm.errors.length > 0
                  ? _c("div", { staticClass: "ds-error-text" }, [
                      _vm._v(_vm._s(_vm.errorsMsg)),
                    ])
                  : _vm._e(),
              ]
            }),
          ]
        : [
            _vm.showLabel
              ? _c(
                  "DsfFieldLabel",
                  {
                    style: _vm.getLabelWidthStyle(),
                    attrs: {
                      mode: _vm.showDataCaptionMode,
                      "show-icon": _vm.showDataCaptionIcon,
                      trigger: _vm.showDataCaptionTrigger,
                      "data-caption": _vm.dataCapion,
                      "is-design": _vm.isDesign,
                      owner: _vm._self,
                    },
                  },
                  [_vm._v(" " + _vm._s(_vm.$t(_vm.label)) + " ")]
                )
              : _vm._e(),
            _c(
              "div",
              {
                staticClass: "ds-form-block",
                style: _vm.getFormItemBlockMarginLeft(),
              },
              [
                !_vm.readOnly
                  ? _c("el-input", {
                      class: { "ds-error-border": _vm.errors.length > 0 },
                      attrs: {
                        placeholder: _vm.$t(_vm.placeholder),
                        type: _vm.inputType,
                        disabled: _vm.disabled,
                      },
                      on: {
                        change: _vm.inputChangeHandler,
                        blur: _vm.inputBlurHandler,
                      },
                      nativeOn: {
                        keyup: function ($event) {
                          return _vm.keyupHandler($event)
                        },
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "prefix",
                            fn: function () {
                              return [_vm._t("prefix")]
                            },
                            proxy: true,
                          },
                          {
                            key: "suffix",
                            fn: function () {
                              return [_vm._t("suffix")]
                            },
                            proxy: true,
                          },
                          {
                            key: "prepend",
                            fn: function () {
                              return [_vm._t("prepend")]
                            },
                            proxy: true,
                          },
                          {
                            key: "append",
                            fn: function () {
                              return [
                                _vm._t("append", function () {
                                  return [
                                    _vm.append
                                      ? [_vm._v(_vm._s(_vm.$t(_vm.append)))]
                                      : _vm._e(),
                                  ]
                                }),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        true
                      ),
                      model: {
                        value: _vm.inputValue,
                        callback: function ($$v) {
                          _vm.inputValue = $$v
                        },
                        expression: "inputValue",
                      },
                    })
                  : _vm.readOnly
                  ? _c(
                      "div",
                      { staticClass: "ds-form-readonly" },
                      [
                        _c(
                          "DsfTextProxy",
                          _vm._b(
                            {
                              attrs: { "show-label": false },
                              model: {
                                value: _vm.value,
                                callback: function ($$v) {
                                  _vm.value = $$v
                                },
                                expression: "value",
                              },
                            },
                            "DsfTextProxy",
                            _vm._self.$props,
                            false
                          )
                        ),
                        _vm.value != ""
                          ? _c("span", [_vm._v(_vm._s(_vm.append))])
                          : _vm._e(),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._t("error", function () {
                  return [
                    _vm.errors.length > 0
                      ? _c("div", { staticClass: "ds-error-text" }, [
                          _vm._v(_vm._s(_vm.errorsMsg)),
                        ])
                      : _vm._e(),
                  ]
                }),
              ],
              2
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }