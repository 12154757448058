var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ds-schedule-list-setting" },
    [
      _c(
        "dsf-virtual-scroll",
        [
          _c(
            "el-form",
            { ref: "form", attrs: { "label-width": "200px" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "显示模式：" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.form.type,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "type", $$v)
                        },
                        expression: "form.type",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: "week" } }, [
                        _vm._v("周历"),
                      ]),
                      _c("el-radio", { attrs: { label: "week2" } }, [
                        _vm._v("双周历"),
                      ]),
                      _c("el-radio", { attrs: { label: "month" } }, [
                        _vm._v("月历"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "日程过多时显示省略号：" } },
                [
                  _c("el-switch", {
                    staticClass: "inset",
                    attrs: { "active-text": "是", "inactive-text": "否" },
                    model: {
                      value: _vm.form.ellipsis,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "ellipsis", $$v)
                      },
                      expression: "form.ellipsis",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "默认显示条数：" } },
                [
                  _c("el-input-number", {
                    attrs: {
                      min: 1,
                      max: 200,
                      precision: 0,
                      "step-strictly": "",
                    },
                    model: {
                      value: _vm.form.pageSize,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "pageSize", $$v)
                      },
                      expression: "form.pageSize",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }