<template>
  <div class="ds-control ds-flexible-box ds-no-padding" :class="boxClass" :style="boxStyle">
    <i v-if="!isDesign && !hiddenBt && canResize" ref="drag" class="ds-flexible-box-drag" :class="{dragging: isDragging}" :style="dragStyle" @mousedown.stop="dragStart"></i>
    <div ref="barBox" class="ds-flexible-box-bar-box" :style="labelStyle" @transitionend="transitionend">
      <div class="ds-flexible-box-bar-box-content" slot-name="bar">
        <slot name="bar"></slot>
      </div>
      <div v-if="!hiddenBt" class="ds-flexible-box-bar-bt">
        <div @click="close" :title="isClosed ? '展开' : '折叠'"></div>
      </div>
    </div>
    <div ref="bar" class="ds-flexible-box-bar" :style="barStyle"></div>
    <div class="ds-flexible-box-main">
      <div v-if="isDesign" class="ds-flexible-box-main-box" slot-name="default">
        <slot name="default"></slot>
      </div>
      <template v-else>
        <slot name="default"></slot>
      </template>
    </div>
  </div>
</template>

<script>
export default dsf.component({
  name: "DsfFlexibleBox",
  ctrlCaption: "伸缩盒",
  mixins: [$mixins.layout],
  design: {
    isMask: false,
  },
  props: {
    // 布局方式
    layout: {
      type: String,
      default: "row",
    },
    boxHeight: {
      type: String,
      default: "100%",
    },
    barWidth: {
      type: String,
      default: "250px",
    },
    barHeight: {
      type: String,
      default: "250px",
    },
    margin: {
      type: String,
      default: "5px",
    },
    padding: {
      type: Boolean,
      default: false,
    },
    // 是否隐藏折叠按钮
    hiddenBt: {
      type: Boolean,
      default: false,
    },
    // 是否默认折叠
    isClosed: {
      type: Boolean,
      default: false,
    },
    // 是否允许拖动改变尺寸
    canResize: {
      type: Boolean,
      default: false,
    },
    slots: {
      type: Array,
      default: function () {
        return [
          {
            name: "bar",
            controls: [],
          },
          {
            name: "default",
            controls: [],
          },
        ];
      },
    },
  },
  data() {
    return {
      // 是否正在拖拽
      isDragging: false,
      // 是否禁用拖拽
      disabledDrag: false,
      // 拖动距离
      dragSize: 0,
      // 是否动画
      animation: true,
      // 动画是否进行中
      animating: false,
    };
  },
  computed: {
    boxClass() {
      let res = [this.layout];
      if (!this.isDesign && this.isClosed) {
        res.push("close");
      }
      if (this.animation) {
        res.push("is-transition");
      }
      if (this.animating) {
        res.push("animating");
      }
      return res;
    },
    boxStyle() {
      let res = {
        "flex-direction": this.layout,
        height: this.boxHeight,
      };
      if (this.isDesign) {
        this.$dispatch("design-height-change", this.boxHeight);
        res.height = "100%";
      }
      if (!this.padding && !this.isDesign) {
        res.padding = "0";
      }
      return res;
    },
    barStyle() {
      let res = {};
      let { barWidth, barHeight, margin } = this;
      barWidth = barWidth.trim();
      barHeight = barHeight.trim();
      switch (this.layout) {
      case "row":
        res["width"] = barWidth;
        if (["0", "0px", "0%"].indexOf(barWidth) == -1) {
          res["margin-right"] = margin;
        }
        break;
      case "row-reverse":
        res["width"] = barWidth;
        if (["0", "0px", "0%"].indexOf(barWidth) == -1) {
          res["margin-left"] = margin;
        }
        break;
      case "column":
        res["height"] = barHeight;
        if (["0", "0px", "0%"].indexOf(barWidth) == -1) {
          res["margin-bottom"] = margin;
        }
        break;
      case "column-reverse":
        res["height"] = barHeight;
        if (["0", "0px", "0%"].indexOf(barWidth) == -1) {
          res["margin-top"] = margin;
        }
        break;
      }
      return res;
    },
    labelStyle() {
      let res = { ...this.barStyle }
      if (this.isDesign && (this.layout === 'row' || this.layout === 'row-reverse')) {
        res.width = `calc(${res.width} - 5px)`
      }
      return res;
    },
    // 是否配置的%
    isPercentage() {
      if (this.layout === 'row' || this.layout === 'row-reverse') {
        return /%$/.test(this.barWidth);
      }
      return /%$/.test(this.barHeight);
    },
    dragStyle() {
      let res = {}, size = this.isPercentage ? this.dragSize : this.dragSize + 'px';
      switch (this.layout) {
      case "row":
        res["left"] = size;
        break;
      case "row-reverse":
        res["right"] = size;
        break;
      case "column":
        res["top"] = size;
        break;
      case "column-reverse":
        res["bottom"] = size;
        break;
      }
      return res;
    }
  },
  created() {
    if (!this.isDesign) {
      if (dsf.client.type.indexOf("IE") >= 0) {
        this.animation = false;
      }
      window.addEventListener('mousemove', this.dragMove);
      window.addEventListener('mouseup', this.dragEnd);
    }
  },
  mounted() {
    this.isClosed || this.updateDragPosition();
  },
  beforeDestroy() {
    if (!this.isDesign) {
      window.removeEventListener('mousemove', this.dragMove);
      window.removeEventListener('mouseup', this.dragEnd);
      clearTimeout(this.timeout);
    }
  },
  methods: {
    close() {
      if (this.isDesign) return;
      let isClosed = !this.isClosed;
      if (this.animation) {
        this.animating = true;
      }
      this.$emit("update:isClosed", isClosed);
      this.$dispatch("close", isClosed);
      if (!this.animation || isClosed) {
        this.$nextTick(() => {
          this.$emit("resize", isClosed);
          this.$page && dsf.resizeComponent(this.$page);
        });
      }
    },
    transitionend(evt) {
      if (
        !this.isDesign &&
        this.animation &&
        evt.target === this.$refs.barBox &&
        evt.propertyName == "transform"
      ) {
        this.animating = false;
        this.$nextTick(() => {
          setTimeout(() => {
            this.$emit("resize", this.isClosed);
            this.$page && dsf.resizeComponent(this.$page);
            this.isClosed || this.updateDragPosition();
          }, 100);
        });
      }
    },
    reloadData() {
      _.each(this.$children, (children) => {
        children?.reloadData && children.reloadData();
      });
    },
    // 更新drag位置
    updateDragPosition() {
      if (!this.isDesign) {
        let bar = this.$refs.bar, size;
        if (this.layout === 'row' || this.layout === 'row-reverse') {
          size = bar?.offsetWidth || 0;
        } else {
          size = bar?.offsetHeight || 0;
        }
        this.setDragSize(size);
      }
    },
    // 计算dragSize，区分是否使用%
    setDragSize(size) {
      if (!this.isPercentage) {
        this.dragSize = size;
      } else {
        let w = this.layout === 'row' || this.layout === 'row-reverse' ? this.$el.offsetWidth : this.$el.offsetHeight;
        this.dragSize = (size * 100 / w).toFixed(3) + '%';
      }
    },
    // 开始拖动
    dragStart(e) {
      if (this.disabledDrag) return;
      this.isDragging = true;
      let s = e.pageY;
      this.bodyCursor = document.body.style.cursor;
      if (this.layout === 'row' || this.layout === 'row-reverse') {
        s = e.pageX;
        this._realDragSize = this.$refs.bar.offsetWidth;
        document.body.style.cursor = 'col-resize';
      } else {
        this._realDragSize = this.$refs.bar.offsetHeight;
        document.body.style.cursor = 'row-resize';
      }
      this.lastS = s;
    },
    // 拖动进行中
    dragMove(e) {
      if (!this.isDragging) return;
      let s = e.pageY, W = this.$el.offsetHeight;
      if (this.layout === 'row' || this.layout === 'row-reverse') {
        s = e.pageX;
        W = this.$el.offsetWidth;
      }
      if (this.layout === 'row' || this.layout === 'column') {
        this._realDragSize += s - this.lastS;
      } else {
        this._realDragSize += this.lastS - s;
      }
      if (this._realDragSize < 50) {
        this.realDragSize = 50;
      } else if (this._realDragSize > W - 50) {
        this.realDragSize = W - 50;
      } else {
        this.realDragSize = this._realDragSize;
      }
      this.lastS = s;
      this.dragSize = this.isPercentage ? this.realDragSize + 'px' : this.realDragSize;
    },
    // 拖动结束
    dragEnd() {
      if (!this.isDragging) return;
      this.disabledDrag = true;
      document.body.style.cursor = this.bodyCursor;
      this.lastS = 0;
      this.setDragSize(this.realDragSize);
      let dragSize = this.isPercentage ? this.dragSize : this.dragSize + 'px';
      if (this.layout === 'row' || this.layout === 'row-reverse') {
        this.$emit('update:barWidth', dragSize);
      } else {
        this.$emit('update:barHeight', dragSize);
      }
      let animation = this.animation;
      this.animation = false;
      this.$nextTick(() => {
        this.$page && dsf.resizeComponent(this.$page);
        this.isDragging = false;
        this.timeout = setTimeout(() => {
          this.disabledDrag = false;
          this.animation = animation;
        }, 300);
      })
    },
  },
});
</script>