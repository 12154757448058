<template>
  <div class="ds-control ds-section" :class="getCss" style="height: 100%">
    <iframe ref="iframe" :src="getPath()" width="100%" height="100%" frameborder="0">
    </iframe>
  </div>
</template>
<script>
export default dsf.component({
  name: "DsfDialogIframe",
  ctrlCaption: "采用iframe的弹窗",
  mixins: [$mixins.control],
  data() {
    return {
      iframeUrl: ""
    };
  },
  props: {
    rmiframeUrl: {
      type: String,
      default: ""
    }
  },
  computed: {
    getCss() {
      return [...this.$getClass(), this.showBorder ? "" : "ds-no-border", this.noPadding ? "" : "ds-no-padding"];
    },
    contentWindow() {
      if (this.$refs.iframe) {
        return this.$refs.iframe.contentWindow;
      }
      return null;
    }
  },
  watch: {},
  created() {},
  mounted() {
    //针对弹出层中使用iframe组件时向内部页面传递参数
    if (this.$refs.iframe) {
      this.$refs.iframe.$dialog = this.$dialog;
      let frameDom = this.$refs.iframe;
      if (frameDom.attachEvent) {
        frameDom.attachEvent("onload", () => {
          this.iframeLoaded(frameDom);
        });
      } else {
        frameDom.onload = () => {
          this.iframeLoaded(frameDom);
        };
      }
    }
  },
  methods: {
    getPath() {
      if (!this.isDesign) {
        let v = this.$replace(this.rmiframeUrl);
        // v = dsf.url.getWebPath(v);
        //这里的iframe不加getWebPath了,兼容一下已经配好的:/开头的
        if (v.startsWith(":/")) {
          v = v.substr(1);
        }
        return this.analysis(v);
      }
      return "";
    },
    analysis(url) {
      var searchStr = url.substr(url.indexOf("?"));
      var oRegex = /[\?&](\w+)=([^&]+)?/gi;
      url = url.replace(oRegex, function (a, b, c, d) {
        var s = a.substr(0, 1);
        s += b + "=" + encodeURI(c || "");
        return s;
      });
      return decodeURIComponent(url);
    },
    iframeLoaded(iframe) {
      let win = iframe.contentWindow;
      // console.log(win,win.dsf)
      //如果是弹出框中，向弹出框传递参数
      if (win && this.$dialog) {
        iframe.$dialog = this.$dialog;
        //兼容4.0代码 start==============
        if (this.$dialog?.options?.version == "4.0" || !dsf.isDsf5(win.dsf)) {
          iframe.dsfVersion = "4.0";
          iframe.args = this.$dialog.dialogArgs;
          iframe.dialogLoaded = this.$dialog?.options?.dialogLoaded;
          iframe.closeWindow = this.$dialog.close.bind(this.$dialog);
          iframe.dialogIndex = this.$dialog?.index;
          iframe.ownerWin = window;
          iframe.opener = this.$dialog?.options?.currentWindow;
          this.dsf4IframeDialogCallBack(iframe);
        }
        //兼容4.0代码 end=============
        else {
          iframe.dsfVersion = "5.0";
          iframe.args = this.$dialog.dialogArgs ? JSON.stringify(this.$dialog.dialogArgs) : null;
          iframe.openerVm = this.$dialog.openerVm;
        }
      }
      this.$dispatch("loaded", this);
    },
    dsf4IframeDialogCallBack(iframe) {
      let _this = this;
      let win = iframe.contentWindow;
      // console.log("hahah ", win.page)
      if (!win.page) {
        iframe?.dialogLoaded && iframe.dialogLoaded($(_this.$dialog.$el), _this.$dialog.index, win);
        win?.dialogInit && win.dialogInit(iframe.args);
      } else {
        if (iframe.dialogLoaded) {
          let state = win.page._isRender;
          let count = 0,
            max = 50;
          if (!state) {
            //如果当前页面没有加载完成则每100毫秒监控一下
            var closeTime = window.setInterval(() => {
              state = win.page._isRender;
              count++;
              if (state || count > 50) {
                window.clearInterval(closeTime);
                iframe?.dialogLoaded && iframe.dialogLoaded($(_this.$dialog.$el), _this.$dialog.index, win);
                win?.dialogInit && win.dialogInit(iframe.args);
              }
            }, 100);
          } else {
            iframe?.dialogLoaded && iframe.dialogLoaded($(_this.$dialog.$el), _this.$dialog.index, win);
            win?.dialogInit && win.dialogInit(iframe.args);
          }
        }
      }
    }
  },
  beforeDestroy() {
    if (this.$refs.iframe) {
      let frameDom = this.$refs.iframe;
      frameDom.ownerWin = null;
      frameDom.dialogIndex = null;
      frameDom.opener = null;
      frameDom.args = null;
      frameDom.$dialog = null;
      frameDom.closeWindow = null;
      frameDom.dialogLoaded = null;
    }
  }
});
</script>
