import { render, staticRenderFns } from "./convenientMenuItem.vue?vue&type=template&id=627329fc&functional=true&"
import script from "./convenientMenuItem.vue?vue&type=script&lang=js&"
export * from "./convenientMenuItem.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  null,
  null
  
)

component.options.__file = "pc/bhc/nav/items/convenientMenuItem.vue"
export default component.exports