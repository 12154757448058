var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "sliderBox",
      staticClass: "dsf-color-select-panel",
      style: _vm.hueStyle,
      on: { mousedown: _vm.mousedown },
    },
    [
      _c("span", {
        ref: "thumb",
        staticClass: "slider",
        style: _vm.sliderStyle,
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }