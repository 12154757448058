<template>
  <div
    class="ds-data-panel-primary-group"
    :style="{ padding: padding.join('px ') + 'px' }"
  >
    <div
      class="ds-data-panel-primary-group-main"
      :style="{
        margin: `-${spacing / 2}px`,
      }"
    >
      <template v-for="(item, index) in itemConfig">
        <slot
          v-bind="{
            style: {
              width: `calc((100% - (${spacing}px * ${justify})) / ${justify})`,
            },
            spacing: spacing,
            justify: justify,
            item: item,
            data: filterData(item.key) || {},
            $index: index,
          }"
        >
          <component
            :is="panelModel"
            :style="{
              width: `calc((100% - (${spacing}px * ${justify})) / ${justify})`,
              margin: `${spacing / 2}px`,
            }"
            :size="size"
            :panel-data="filterData(item.key) || {}"
            v-bind="item"
            :showShadow="showShadow"
            :height="height"
            :isGroup="true"
            :textAlign="textAlign"
            :key="index"
            :isDesign="isDesign"
          >
            <template slot="item" slot-scope="scope">
              <slot name="item" v-bind="scope"></slot>
            </template>
            <template slot="itemLeft" slot-scope="scope">
              <slot name="itemLeft" v-bind="scope"></slot>
            </template>
            <template slot="itemRight" slot-scope="scope">
              <slot name="itemRight" v-bind="scope"></slot>
            </template>
          </component>
        </slot>
      </template>
    </div>
  </div>
</template>

<script>
export default dsf.component({
  name: "DsfDataPanelGroup",
  ctrlCaption: "数据面板-组-主要",
  mixins: [$mixins.control],
  props: {
    // 面板圆角
    borderRadius: {
      type: String,
      default: () => "4px",
    },
    // 间距
    spacing: {
      type: Number,
      default: () => 20,
    },
    padding: {
      type: Array,
      default: () => [0, 0, 0, 0],
    },
    // 横向个数
    justify: {
      type: Number,
      default: () => 4,
    },
    // 高度
    height: {
      type: String,
      default: () => "88px",
    },
    // 背景颜色
    backgroundColor: {
      type: String,
      default: () => "",
    },
    // 显示阴影
    showShadow: {
      type: Boolean,
      default: () => true,
    },
    // 显示图标
    showIcon: {
      type: Boolean,
      default: () => true,
    },
    // 显示标题
    showTitle: {
      type: Boolean,
      default: () => true,
    },
    // 显示描述
    showDesc: {
      type: Boolean,
      default: () => true,
    },
    // 获取数据url
    dataUrl: {
      type: String,
      default: () => "",
    },
    // 点击跳转url
    toUrl: {
      type: String,
      default: () => "",
    },
    // 组设置
    itemConfig: {
      type: Array,
      default: () => [
        {
          key: `key1`,
          iconName: `gouxuan`,
          title: `title1`,
          desc: `@[desc]`,
          number: `@[number]`,
        },
      ],
    },
    // 面板类型
    panelModel: {
      type: String,
      default: () => "DsfDataPanelPrimary",
    },
    // 大小
    size: {
      type: String,
      default: "",
    },
    textAlign: {
      type: String,
      default: "center",
    },
  },
  computed: {
    dataList() {
      let { borderRadius, height, backgroundColor, itemConfig } = this;
      return itemConfig.map((item) => {
        if (height) item.height = height;
        if (borderRadius) item.borderRadius = borderRadius;
        if (backgroundColor) item.backgroundColor = backgroundColor;
        return item;
      });
    },
  },
  data() {
    return {
      list: [],
    };
  },
  created() {
    // console.log(this.itemConfig)
    if (this.isDesign) {
      this.list = [
        {
          key: "key1",
          title: "统计学",
          number: "30",
          iconName: "icon-file",
          desc: "描述+1",
        },
        {
          key: "key2",
          title: "谈文学",
          number: "25",
          iconName: "icon-file",
          desc: "描述+2",
        },
        {
          key: "key3",
          title: "语文",
          number: "105",
          iconName: "icon-file",
          desc: "描述+3",
        },
      ];
    } else this.getData();
  },
  watch: {
    panelModel: {
      immediate: true,
      handler(model) {
        if (model == "DsfDataPanelPrimary") {
          this.$emit("update:height", "88px");
        } else if (model == "DsfDataPanelDiscrete") {
          this.$emit("update:height", "160px");
        }
      },
    },
  },
  methods: {
    getData() {
      let { dataUrl } = this;
      if (!dataUrl) return;
      this.$http
        .get(dataUrl)
        .then(({ data }) => {
          if (data && data.success) {
            this.list = data.data || [];
          } else {
            this.list = [];
            dsf.layer.message(data.message, false);
          }
        })
        .catch((err) => {
          this.list = [];
          dsf.layer.message("请求错误", false);
        })
        .finally();
    },
    filterData(key) {
      return this.list.filter((item) => item.key == key)[0];
    },
  },
});
</script>
