var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dsf-sync-box" }, [
    _vm._e(
      ' <el-button @click="handleStartVoice">开始</el-button>\n  <el-button @click="handleEndVoice">停止</el-button> '
    ),
    _vm._e("hidden"),
    _c("div", { staticClass: "dsf-sync-header" }, [
      _c("div", { ref: "header", staticClass: "dsf-sync-header-content" }, [
        _c("div", { staticClass: "dsf-sync-header-left" }, [
          _c("div", { staticClass: "title" }, [
            _vm._v("会议名单 (" + _vm._s(_vm.peopleList.length) + "人)"),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "dsf-sync-header-right" },
          [
            _c("operation-nav", {
              ref: "fileComponent",
              attrs: { fileViewObj: _vm.fileView },
            }),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "dsf-full-drop", on: { click: _vm.handleDropTool } },
        [
          _c("div", { ref: "dropIcon", staticClass: "icon-box" }, [
            _c("i", { staticClass: "icon iconfont icon-arrow-down" }),
          ]),
        ]
      ),
    ]),
    _c("div", { staticClass: "dsf-sync-body" }, [
      _vm._e("hidden"),
      _c("div", { staticClass: "dsf-sync-left" }, [
        _vm.isHeaderSlots
          ? _c(
              "div",
              { staticClass: "dsf-sync-left-slot" },
              [_c("DsfVirtualScroll", [_vm._t("header")], 2)],
              1
            )
          : _vm._e(),
        _c(
          "div",
          {
            staticClass: "dsf-sync-left-item",
            style: {
              height: _vm.isHeaderSlots ? "calc(100% - 300px)" : "100%",
            },
          },
          [
            _c("div", { staticClass: "dsf-sync-left-item--title" }, [
              _c(
                "div",
                {
                  class: { "left-active": _vm.chooseOnline },
                  on: {
                    click: function ($event) {
                      _vm.chooseOnline = true
                    },
                  },
                },
                [_vm._v(" 在线（" + _vm._s(_vm.onlineList.length) + "） ")]
              ),
              _c(
                "div",
                {
                  class: { "left-active": !_vm.chooseOnline },
                  on: {
                    click: function ($event) {
                      _vm.chooseOnline = false
                    },
                  },
                },
                [_vm._v(" 离线（" + _vm._s(_vm.offlineList.length) + "） ")]
              ),
            ]),
            _c(
              "div",
              { staticClass: "dsf-sync-left-item--search" },
              [
                _c("el-input", {
                  staticClass: "dsf-sync-seach",
                  attrs: {
                    placeholder: "请输入姓名",
                    "prefix-icon": "el-icon-search",
                  },
                  on: { blur: _vm.handleSearch },
                  nativeOn: {
                    keyup: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.handleSearch.apply(null, arguments)
                    },
                  },
                  model: {
                    value: _vm.searchName,
                    callback: function ($$v) {
                      _vm.searchName = $$v
                    },
                    expression: "searchName",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "dsf-sync-left-item--list" },
              [
                _c(
                  "DsfVirtualScroll",
                  _vm._l(
                    _vm.chooseOnline ? _vm.onlineList : _vm.offlineList,
                    function (item, index) {
                      return _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !item.hide,
                              expression: "!item.hide",
                            },
                          ],
                          key: index,
                          staticClass: "people-single",
                        },
                        [
                          _c("div", { staticClass: "people-box" }, [
                            _c("div", { staticClass: "people-img" }, [
                              item.photo
                                ? _c("img", {
                                    staticClass: "people-img-box",
                                    attrs: { crossorigin: "", src: item.photo },
                                  })
                                : _c(
                                    "div",
                                    {
                                      staticClass: "people-img-box",
                                      style: {
                                        backgroundColor:
                                          _vm.randomColors[index % 10],
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(item.name.substring(0, 1)) +
                                          " "
                                      ),
                                    ]
                                  ),
                            ]),
                            _c("div", { staticClass: "people-name" }, [
                              _vm._v(_vm._s(item.name)),
                            ]),
                          ]),
                          _c("div", { staticClass: "people-status" }, [
                            _c("i", {
                              staticClass: "iconfont",
                              class: {
                                "icon-sidebar-speaker":
                                  item.status.value === "3" ||
                                  item.status.value === "4",
                                "icon-flash": item.status.value === "4",
                              },
                            }),
                          ]),
                          item.status.value === "3" || item.status.value === "4"
                            ? _c(
                                "div",
                                {
                                  staticClass: "people-main",
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleChangeStatus(
                                        item.personId,
                                        item.status.value == "3"
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        item.status.value == "3"
                                          ? "取消主讲"
                                          : "设为主讲"
                                      ) +
                                      " "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]
                      )
                    }
                  ),
                  0
                ),
              ],
              1
            ),
          ]
        ),
      ]),
      _c("div", { staticClass: "dsf-sync-right" }, [
        _c("div", { staticClass: "dsf-sync-content" }, [
          _c("iframe", {
            attrs: {
              src: _vm.syncParam.url,
              width: "1694",
              height: "847",
              frameborder: "0",
              id: "syncScreen",
            },
          }),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }