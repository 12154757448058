<template>
  <div class="ds-component-info">
    <p v-if="info.caption"><label>控件类型：</label>{{ info.caption }}</p>
    <p v-if="info.id"><label>元数据标识：</label>{{ info.id }}</p>
    <p v-if="info.name"><label>元数据名称：</label>{{ info.name }}</p>
    <p v-if="info.typeText || info.typeValue"><label>元数据类型：</label>{{ info.typeText || '' }} | {{ info.typeValue || '' }}</p>
  </div>
</template>

<script>
export default {
  name: "DsfComponentInfo",
  props: {
    info: {
      type: Object,
      default() {
        return {}
      }
    }
  }
}
</script>