<template>
  <div class="page-home-default-aside" :class="{mini: mini}">
    <DsfVirtualScroll class="content" width="100%" scroll-y>
      <dsf-default-home-menu
        ref="menu"
        :menu-list="menuList"
        @mouseenter="mouseenter"
        @mouseleave="mouseleave"
      />
    </DsfVirtualScroll>
    <home-aside-menu-children v-if="mini" ref="children" />
  </div>
</template>

<script>
import HomeAsideMenuChildren from './homeAsideMenuChildren';

export default {
  name: 'DsfDefaultHomeAsideMenu',
  inject: ['homeRoot'],
  provide() {
    return {
      homeAside: this
    };
  },
  components: {
    HomeAsideMenuChildren
  },
  props: {
    position: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      show: null,
    }
  },
  computed: {
    menuList () {
      let ma = this.homeRoot.menuAction;
      ma = ma && ma.length ? ma[0] : {};
      let list = [];
      if (ma && ma.children) {
        list = ma.children;
      }
      return list;
    },
    width() {
      return this.mini ? '62px' : '205px';
    },
    mini() {
      return dsf.themeConfig.setting.defaultAsideMini;
    },
  },
  watch: {
    'mini'(to) {
      if (this.menuList.length) {
        this.homeRoot.setPosition(this.position, this.width);
        if (!to) {
          this.$nextTick(() => {
            this.init(this.homeRoot.menuKey);
          });
        }
      }
    },
    'menuList.length' (to) {
      if (!to) {
        this.homeRoot.setPosition(this.position, '0');
      } else {
        this.homeRoot.setPosition(this.position, this.width);
      }
    },
    'homeRoot.menuKey'(to) {
      this.$nextTick(() => {
        this.init(to);
      });
    }
  },
  created() {
    if (!this.menuList.length) {
      this.homeRoot.setPosition(this.position, '0');
    } else {
      this.homeRoot.setPosition(this.position, this.width);
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.init(this.homeRoot.menuKey);
    });
  },
  methods: {
    init (menuKey) {
      let {$el} = this;
      let activeEls = $el.querySelectorAll('.ds-aside-menu-item.active');
      _.each(activeEls, it => {
        it.__vue__.isActive = false;
      });
      for (let i = 1; i < menuKey.length; i++) {
        let key = menuKey[i];
        let menuEl = $el.querySelector(`.ds-aside-menu-item.show[menu-level="${i}"]`);
        if (menuEl) {
          menuEl.__vue__.showChildren = false;
        }
        let activeEl = $el.querySelector(`.ds-aside-menu-item[menu-key="${key}"]`);
        if (activeEl) {
          activeEl.__vue__.isActive = true;
          if (activeEl.__vue__.menu.children) {
            activeEl.__vue__.showChildren = true;
            this.show = activeEl.__vue__;
          }
        }
      }
    },
    /**
     * 设置菜单是否展开
     * @param show    点击的菜单项
     * @param isShow  true展开，false关闭
     */
    setShow(show, isShow = false) {
      let parent = this.show;
      if (isShow) {
        while (parent && parent.$options._componentTag === 'dsf-default-home-menu-item') {
          parent.showChildren = false;
          parent = parent.$parent;
          if (parent) {
            parent = parent.$parent;
          } else {
            break;
          }
        }
      }
      parent = show;
      while (parent && parent.$options._componentTag === 'dsf-default-home-menu-item') {
        parent.showChildren = true;
        parent = parent.$parent;
        if (parent) {
          parent = parent.$parent;
        } else {
          break;
        }
      }
      this.show = show;
    },
    /*****设置窄条子菜单显示隐藏*****/
    mouseenter(e, menu) {
      this.$refs.children?.showMenuChildren(menu, e);
    },
    mouseleave(e) {
      this.$refs.children?.hideMenuChildren(e);
    }
  }
}
</script>