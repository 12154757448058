<template>
  <div class="ds-control ds-form-item ds-date-picker" :class="getCss">
    <template v-if="simple && readOnly && !isDesign">
      <span :title="readOnlyText">{{ readOnlyText }}</span>
      <slot name="error">
        <div v-if="errors.length > 0" class="ds-error-text">
          {{ errorsMsg }}
        </div>
      </slot>
    </template>
    <template v-else>
      <!-- <label
        v-if="showLabel"
        class="ds-form-label"
        :style="getLabelWidthStyle()"
      >
        {{ label }}
        <i
          v-if="dataCapion"
          class="iconfont icon-bangzhuzhongxin ds-control-helper"
          @click="showHelper"
        ></i>
      </label> -->
      <DsfFieldLabel
        v-if="showLabel"
        :mode="showDataCaptionMode"
        :style="getLabelWidthStyle()"
        :show-icon="showDataCaptionIcon"
        :trigger="showDataCaptionTrigger"
        :data-caption="dataCapion"
        :is-design="isDesign"
        :owner="_self"
      >
        {{ $t(label) }}
      </DsfFieldLabel>
      <div
        class="ds-form-block"
        :class="{ 'ds-error-border': errors.length > 0 }"
        :style="getFormItemBlockMarginLeft()"
      >
        <el-date-picker
          v-if="!readOnly"
          v-model="value2"
          :disabled="disabled"
          :type="$chooseType"
          :editable="false"
          :format="$timeFormat"
          :clearable="clearable"
          :range-separator="rangeSeparator"
          :placeholder="placeholder"
          :value-format="valueFormat"
          :picker-options="pickerOptions"
          :default-time="defaultTime"
          @change="dateChange"
        />
        <!--只读状态只显示div-->
        <div v-else-if="readOnly" class="ds-form-readonly">
          <span :title="readOnlyText">{{ readOnlyText }}</span>
        </div>
        <slot name="error">
          <div v-if="errors.length > 0" class="ds-error-text">
            {{ errorsMsg }}
          </div>
        </slot>
      </div>
    </template>
  </div>
</template>

<script>
import dateMixin from "./dateMixin";

export default dsf.component({
  name: "DsfDateIntervalPicker",
  mixins: [$mixins.formControl, dateMixin],
  ctrlCaption: "日期区间",
  props: {
    simple: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "",
    },
    value: {
      type: Object,
      default() {
        return {
          sdate: "",
          edate: "",
        };
      },
    },
    label: {
      type: String,
      default: "日期区间",
    },
    rangeSeparator: {
      type: String,
      default: "-",
    },
    // 时间显示格式
    timeFormat: {
      type: String,
      default: "yyyy-MM-dd",
    },
    // 日期选择器类型
    chooseType: {
      type: String,
      default: "daterange",
    },
    // 是否显示清除按钮
    clearable: {
      type: Boolean,
      default: true,
    },
    // 显示小时段
    timeInter: {
      type: Array,
      default() {
        return ["00:00:00", "23:59:59"];
      },
    },
    //只读状态下格式化
    formatter: {
      type: String,
      default: "dateTime^yyyy-mm-dd",
    },
    metadata: {
      type: Object,
      default() {
        return dsf.metadata.get("date-interval-meta-data");
      },
    },
    // 选中日期后的默认具体时刻 非范围选择时：string / 范围选择时：string[]
    defaultTime: {
      type: [String, Array],
      default(){
        return ["00:00:00", "00:00:00"];
      },
    },
  },
  data() {
    return {
      value2: null,
    };
  },
  watch: {
    value(to) {
      this.initValue(to);
    },
    value2(to) {
      const res = {
        sdate: null,
        edate: null,
      };
      if (to && to?.length === 2 && to[0] && to[1]) {
        res.sdate = to[0];
        if (this.chooseType == "daterange") {
          res.sdate = to[0].split(" ")[0] + " 00:00:00";
          res.edate = to[1].split(" ")[0] + " 23:59:59";
        } else if (this.chooseType == "monthrange") {
          res.sdate = to[0].split(" ")[0] + " 00:00:00";
          let d = dsf.date.parse(to[1].split(" ")[0], "yyyy-mm-dd");
          let edate = new Date(d.getFullYear(), d.getMonth() + 1, 0, 23, 59, 59);
          res.edate = dsf.date.format(edate, "yyyy-mm-dd hh:ii:ss");
          // } else if (this.chooseType == "yearrange") {
          //   if (!to[0] && !to[1]) {
          //     res.sdate = dsf.date.format(new Date(), "yyyy-mm-dd hh:ii:ss");
          //     let d = dsf.date.format(to[1], "yyyy");
          //     let edate = new Date(d, 12, 0, 23, 59, 59);
          //     res.edate = dsf.date.format(edate, "yyyy-mm-dd hh:ii:ss");
          //   } else {
          //     res.sdate = dsf.date.format(to[0], "yyyy-mm-dd hh:ii:ss");
          //   }
        } else {
          res.edate = to[1];
        }
      }
      if (typeof to === "string" && this.chooseType == "yearrange") {
        res.sdate = dsf.date.format(to.split(",")[0], "yyyy-mm-dd hh:ii:ss");
        let d = dsf.date.format(to.split(",")[1], "yyyy");
        let edate = new Date(d, 12, 0, 23, 59, 59);
        res.edate = dsf.date.format(edate, "yyyy-mm-dd hh:ii:ss");
      }
      if (res.sdate === this.value?.sdate && res.edate === this.value?.edate) {
        return;
      }
      this.emitValueChange(res);
    },
  },
  computed: {
    $chooseType() {
      return this.chooseType === "datetimerange_s" ? "datetimerange" : this.chooseType;
    },
    $timeFormat() {
      if (this.chooseType === "datetimerange") {
        return this.timeFormat + " HH:mm";
      }
      if (this.chooseType === "datetimerange_s") {
        return this.timeFormat + " HH:mm:ss";
      }
      return this.timeFormat;
    },
    valueFormat() {
      return "yyyy-MM-dd HH:mm:ss";
    },
    readOnlyText() {
      let proxy = Vue.filter("proxy");
      // let proxy = dsf.global.Vue.filter("proxy");
      if (
        dsf.isDef(this.value) &&
        dsf.type(this.value) == "object" &&
        this.value.sdate != "" &&
        this.value.edate != ""
      ) {
        return (
          proxy(this.value.sdate, this.formatter) +
          " ~ " +
          proxy(this.value.edate, this.formatter)
        );
      }
      return "";
    },
  },
  created() {
    this.initValue(this.value);
  },
  methods: {
    initValue(v) {
      let sdate =
        v && v.sdate
          ? dsf.date.format(v.sdate.replace(/\-/g, "/"), "yyyy-mm-dd hh:ii:ss")
          : "";
      let edate =
        v && v.edate
          ? dsf.date.format(v.edate.replace(/\-/g, "/"), "yyyy-mm-dd hh:ii:ss")
          : "";
      this.value2 = [sdate, edate];
    },
    dateChange() {
      this.$nextTick(() => {
        this.$dispatch("change", this.value);
        this.emitFormValidate(this.value);
      });
    },
  },
});
</script>
