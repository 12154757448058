<template>
  <div v-if="isDesign || (sPID && sCurLinkID)" class="ds-control ds-form-item ds-textbox" :class="getCss">
    <!-- <label v-if="showLabel" class="ds-form-label" :style="getLabelWidthStyle()">
      {{ label }}
      <i v-if="dataCapion" class="iconfont icon-bangzhuzhongxin ds-control-helper" @click="showHelper"></i>
    </label> -->
    <DsfFieldLabel
      v-if="showLabel"
      :mode="showDataCaptionMode"
      :style="getLabelWidthStyle()"
      :show-icon="showDataCaptionIcon" 
      :trigger="showDataCaptionTrigger"
      :data-caption="dataCapion"
      :is-design="isDesign"
      :owner="_self">
      {{ $t(label) }}
    </DsfFieldLabel>
    <div class="ds-form-block" :style="getFormItemBlockMarginLeft()">
      <div class="dis-eara">
        <el-input
          v-model="sOpinion.Content"
          :placeholder="placeholder"
          :type="isTextarea ? 'textarea' : 'text'"
          :rows="textareaRows"
          :disabled="disabled"
          :class="{'one-btns': visibleUpBtn || visibleOptionsBtn, 'two-btns': visibleUpBtn && visibleOptionsBtn}"
        />
        <div class="dis-btns">
          <i v-if="visibleUpBtn" class="iconfont icon-cloud-upload" title="上传附件" @click="upload"></i>
          <el-popover v-model="showOptions" placement="right" width="360" trigger="click">
            <ul class="temp-list">
              <li v-for="(option, index) in options" :key="index" :title="option.key_word" class="item" @click="setValue(option)">
                {{ option.key_word }}
              </li>
            </ul>
            <i v-if="visibleOptionsBtn" slot="reference" class="iconfont icon-detail temp-icon" title="一键模板"></i>
          </el-popover>
        </div>
      </div>
      <DsfUploadFile ref="uploadFile" :hide-btns="true" @value-change="getValue"></DsfUploadFile>
    </div>
  </div>
</template>
<script>
export default dsf.component({
  name: "DsfDisposeIdea",
  mixins: [$mixins.formControl, $mixins.disposeIdea],
});
</script>
