var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "catalogue-warp" },
    [
      _vm.step === 1
        ? [
            _c("SelectList", {
              attrs: {
                selectKeys: _vm.selectKeys,
                operateType: _vm.operateType,
              },
              on: { close: _vm.cancel, changeStep: _vm.changeStep },
            }),
          ]
        : _vm._e(),
      _vm.step === 2
        ? [
            _c("OperateList", {
              attrs: {
                importData: _vm.importDataUpdate,
                targetKeys: _vm.targetKeysUpdate,
              },
              on: { close: _vm.cancel, changeStep: _vm.changeStep },
            }),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }