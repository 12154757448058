import DsfDefaultHomeHeader from './homeHeader';
import DsfDefaultHomeFooter from './homeFooter';
import DsfDefaultHomeAsideMenu from './homeAsideMenu';
import DsfDefaultHomeMenu from './aside/menu';
import DsfDefaultHomeMenuItem from './aside/menuItem';
import DsfDefaultHomeBreadcrumb from './homeBreadcrumb';
import DsfDefaultHomeHeaderMenuChildren from './homeHeaderMenuChildren';

export {
  DsfDefaultHomeMenu,
  DsfDefaultHomeMenuItem,
  DsfDefaultHomeAsideMenu,
  DsfDefaultHomeHeader,
  DsfDefaultHomeFooter,
  DsfDefaultHomeBreadcrumb,
  DsfDefaultHomeHeaderMenuChildren
};

export default {
  install (Vue) {
    Vue.component(DsfDefaultHomeMenu.name, DsfDefaultHomeMenu);
    Vue.component(DsfDefaultHomeMenuItem.name, DsfDefaultHomeMenuItem);
    Vue.component(DsfDefaultHomeAsideMenu.name, DsfDefaultHomeAsideMenu);
    Vue.component(DsfDefaultHomeHeader.name, DsfDefaultHomeHeader);
    Vue.component(DsfDefaultHomeFooter.name, DsfDefaultHomeFooter);
    Vue.component(DsfDefaultHomeBreadcrumb.name, DsfDefaultHomeBreadcrumb);
    Vue.component(DsfDefaultHomeHeaderMenuChildren.name, DsfDefaultHomeHeaderMenuChildren);
  }
};