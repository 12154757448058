<template>
  <div class="userinfo-water-mark" :style="waterMark" v-show="show"></div>
</template>
<script>
export default {
  name: "DsfUserInfoWaterMark",
  props: {
    text: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      textString: "",
      observer: null,
      show: true
    };
  },
  computed: {
    waterMark() {
      let style = {
        "background-image": `url(${dsf.safe.getWaterMark(this.textString)})`
      };
      return style;
    }
  },
  beforeCreate() {},
  created() {
    if(dsf.client.lessThenIE('11')){
      this.show = false;
    }
  },
  beforeMount() {},
  mounted() {
    // this.testDeletedDom();
  },
  beforeDestroy() {},
  destroyed() {},
  methods: {
    createImage() {
      let arr = this.text.split("\n");
      arr = _.map(arr, (it) => {
        return this.$replace(it);
      });
      this.textString = arr.join("\n");
    }
  },
  watch: {
    text: {
      handler(v) {
        this.createImage();
      },
      immediate: true
    }
  }
};
</script>