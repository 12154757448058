var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { ref: "custom", staticClass: "des-custom-list" }, [
    _vm.formInline.list && _vm.formInline.list.length
      ? _c(
          "ul",
          {
            staticClass: "content-ul",
            style: { "background-color": _vm.bgColor },
          },
          [
            _c(
              "li",
              { class: { "list-title": _vm.listStyle, border: _vm.liBorder } },
              _vm._l(_vm.formInline.list, function (item, i) {
                return _c(
                  "div",
                  {
                    key: item.text + i,
                    class: [
                      "text-" + item.text,
                      { ellipsis: item.ellipsis == "1" },
                    ],
                    style: { width: item.width },
                  },
                  [_vm._v(" " + _vm._s(item.title) + " ")]
                )
              }),
              0
            ),
            !_vm.isDesign
              ? _vm._l(_vm.dataList, function (content) {
                  return _c(
                    "li",
                    {
                      key: content.biz_id,
                      class: [
                        {
                          spot: _vm.listStyle,
                          border: _vm.liBorder,
                          "has-link": _vm.formInline.linkCode,
                        },
                        "has-link",
                        "list-height-" + _vm.lineHeight,
                      ],
                      on: {
                        click: function ($event) {
                          return _vm.liClick(content)
                        },
                      },
                    },
                    _vm._l(_vm.formInline.list, function (item, i) {
                      return _c(
                        "div",
                        {
                          key: item.text + i,
                          class: [
                            "text-" + item.text,
                            { ellipsis: item.ellipsis == "1" },
                          ],
                          style: { width: item.width },
                        },
                        [
                          _vm._v(
                            " " + _vm._s(content[item.code] || item.des) + " "
                          ),
                        ]
                      )
                    }),
                    0
                  )
                })
              : [
                  _c(
                    "li",
                    {
                      class: [
                        {
                          spot: _vm.listStyle,
                          border: _vm.liBorder,
                          "has-link": _vm.formInline.linkCode,
                        },
                        "has-link",
                        "list-height-" + _vm.lineHeight,
                      ],
                    },
                    _vm._l(_vm.formInline.list, function (content, i) {
                      return _c(
                        "div",
                        {
                          key: content.text + i,
                          class: [
                            "text-" + content.text,
                            { ellipsis: content.ellipsis == "1" },
                          ],
                          style: { width: content.width },
                        },
                        [_vm._v(" " + _vm._s(content.title) + " ")]
                      )
                    }),
                    0
                  ),
                ],
          ],
          2
        )
      : _c("div", { staticClass: "empty-content" }, [
          _vm._v("请配置列表行数据"),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }