var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "ds-control" }, [
    _vm.isDesign || _vm.sPID
      ? _c(
          "div",
          {
            staticClass: "ds-control ds-form-item ds-flowprocess",
            class: {
              hideYj: _vm.isDesign ? _vm.hideYj : _vm.isHideYj,
              hideNode: _vm.isDesign ? _vm.hideNode : _vm.isHideNode,
            },
          },
          [
            _c("div", { staticClass: "head" }, [
              _vm._m(0),
              !_vm.hideCheckbox
                ? _c(
                    "div",
                    { staticClass: "set" },
                    [
                      _vm.isDesign
                        ? _c("el-checkbox", {
                            attrs: { label: "隐藏异常环节" },
                            model: {
                              value: _vm.hideNode,
                              callback: function ($$v) {
                                _vm.hideNode = $$v
                              },
                              expression: "hideNode",
                            },
                          })
                        : _vm._e(),
                      _vm.isDesign
                        ? _c("el-checkbox", {
                            attrs: { label: "隐藏无意见环节" },
                            model: {
                              value: _vm.hideYj,
                              callback: function ($$v) {
                                _vm.hideYj = $$v
                              },
                              expression: "hideYj",
                            },
                          })
                        : _vm._e(),
                      !_vm.isDesign
                        ? _c("el-checkbox", {
                            attrs: { label: "隐藏异常环节" },
                            model: {
                              value: _vm.isHideNode,
                              callback: function ($$v) {
                                _vm.isHideNode = $$v
                              },
                              expression: "isHideNode",
                            },
                          })
                        : _vm._e(),
                      !_vm.isDesign
                        ? _c("el-checkbox", {
                            attrs: { label: "隐藏无意见环节" },
                            model: {
                              value: _vm.isHideYj,
                              callback: function ($$v) {
                                _vm.isHideYj = $$v
                              },
                              expression: "isHideYj",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
            ]),
            _c(
              "div",
              { staticClass: "content", attrs: { handler: "content" } },
              _vm._l(_vm.data, function (item, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass: "item",
                    class: {
                      unusual: item.STATUS == "-4",
                      noyj:
                        !item.opinionatts ||
                        (item.opinionatts &&
                          !item.opinionatts.Content &&
                          !item.opinionatts.Attachments),
                    },
                  },
                  [
                    _c("div", { staticClass: "fl-box" }, [
                      _c("span", [_vm._v(_vm._s(item.reachtime))]),
                      _c("img", {
                        staticClass: "tx",
                        attrs: {
                          src: require("_platform/assets/styles/themes/workFlow/head_logo.png"),
                        },
                      }),
                      _c("img", {
                        staticClass: "icon",
                        attrs: {
                          src: require("_platform/assets/styles/themes/workFlow/status" +
                            _vm.getStatus(item.STATUS) +
                            ".png"),
                        },
                      }),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "fr-box",
                        class: { end: item.nodetype === "299" },
                      },
                      [
                        _c("div", { staticClass: "des" }, [
                          _c("span", [
                            _vm._v("处理环节：" + _vm._s(item.nodename)),
                          ]),
                          item.nodetype !== "299"
                            ? _c("span", [
                                _vm._v("处理人：" + _vm._s(item.rusername)),
                              ])
                            : _vm._e(),
                          item.nodetype !== "299"
                            ? _c("span", [
                                _vm._v("签收时间：" + _vm._s(item.receivetime)),
                              ])
                            : _vm._e(),
                        ]),
                        _vm.showResult && item.nodetype !== "299"
                          ? _c("div", { staticClass: "result" }, [
                              _vm._v(
                                " 处理结果： " +
                                  _vm._s(_vm.results[item.EXSTATUS]) +
                                  " "
                              ),
                            ])
                          : _vm._e(),
                        _vm.showOpinion && _vm.showOpinion(item)
                          ? _c("div", { staticClass: "yj" }, [
                              _vm._v(
                                " 处理意见： " +
                                  _vm._s(
                                    (item.opinionatts &&
                                      item.opinionatts.Content) ||
                                      _vm.processingOpinion
                                  ) +
                                  " "
                              ),
                            ])
                          : _vm._e(),
                        item.opinionatts &&
                        item.opinionatts.Attachments &&
                        item.opinionatts.Attachments.length > 0
                          ? _c("DsfFlowFileList", {
                              attrs: {
                                attachments: item.opinionatts.Attachments,
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                )
              }),
              0
            ),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title" }, [
      _c("label", { staticClass: "ds-font-3", attrs: { handler: "title" } }, [
        _vm._v("办理情况"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }