var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.catalogueRoot.isDesign || !_vm.isHide
    ? _c(
        "div",
        {
          staticClass: "ds-nav-catalogue-item",
          attrs: { level: _vm.item.level },
        },
        [
          _c(
            "div",
            {
              staticClass: "label",
              class: _vm.labelClass,
              attrs: {
                active:
                  _vm.catalogueRoot.active === _vm.item ||
                  _vm.catalogueRoot.rootNode === _vm.item,
              },
              on: {
                click: function ($event) {
                  return _vm.catalogueRoot.itemClick(_vm.item)
                },
              },
            },
            [_c("span", [_vm._v(_vm._s(_vm.item["_name"]))])]
          ),
          _vm.item["children"] && _vm.item["children"].length
            ? _c(
                "div",
                { staticClass: "children" },
                _vm._l(_vm.item["children"], function (item2) {
                  return _c("nav-catalogue-item", {
                    key: item2._id,
                    attrs: { item: item2 },
                  })
                }),
                1
              )
            : _vm._e(),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }