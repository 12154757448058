<template>
  <div v-if="isDesign" class="ds-platform-card-item-by-teacherlist">
    <div class="avatar-wrap">
      <dsf-image class="avatar" />
    </div>
    <div class="info">
      <p class="name">张三</p>
      <p class="line-2">中央党校（国家行政学院）副校长</p>
      <p class="course">15门精品课程 ></p>
    </div>
  </div>
  <div v-else class="ds-platform-card-item-by-teacherlist"  @click.stop="_clickBefore(courseClick)">
    <div class="avatar-wrap">
      <dsf-image class="avatar" :src="data['avatar'] | imgFormat" :error-src="errorSrc" />
    </div>
    <div class="info">
      <p class="name" :title="data['name']">{{ data["name"] }}</p>
      <p class="line-1" :title="data['unit']">{{ data["unit"] }}</p>
      <p class="line-1" :title="data['position']">{{ data["position"] }}</p>
      <p class="course">
        {{ data["courseNum"] }}{{ getSuffix("courseNum") }} >
      </p>
    </div>
  </div>
</template>
<script>
export default dsf.component({
  name: "DsfPlatformCardItemByTeacherList",
  ctrlCaption: "教师列表卡片",
  mixins: [$mixins.card],
  props: {
    keysMap: {
      type: Array,
      default() {
        return [
          { from: "avatar", to: "avatar", type: "text", desc: "头像" },
          { from: "name", to: "name", type: "text", desc: "名称" },
          { from: "unit", to: "unit", type: "text", desc: "任职单位" },
          { from: "position", to: "position", type: "text", desc: "职位" },
          {
            from: "courseNum",
            to: "courseNum",
            type: "text|number",
            desc: "课程数量",
            suffix: "门精品课程",
          },
        ];
      },
    },
    // 点击课程跳转设置
    clickConfig: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  methods: {
    courseClick() {
      if (this.$listeners.courseClick) {
        this.$dispath("courseClick", this.local);
      } else {
        if (!this.clickConfig) return;
        let { url, state } = this.clickConfig;
        url = this.$replace(url, this.local);
        this.$open({ url, state });
      }
      this.clickAfter();
    },
  },
});
</script>
