const fontSizeMap = {
  normal: '14',
  bigFont: '16',
  bigFont_1: '18',
  bigFont_2: '20',
  bigFont_3: '22'
};
const fontSizeOptions = {
  14: "normal",
  16: "bigFont",
  18: "bigFont_1",
  20: "bigFont_2",
  22: "bigFont_3"
};

export default{
  inject: {
    homeRoot: {
      default: null
    }
  },
  props: {
    show: {
      type: Boolean,
      default: true
    },
    saveToServer:{
      type: Boolean,
      default: true
    },
    fontSizeOptions: {
      type: Object,
      default() {
        let size = parseInt(dsf.config.setting_public_show_fontsizebase);
        let steps = parseInt(dsf.config.setting_public_show_fontsizestep);
        let count = parseInt(dsf.config.setting_public_show_fontsizestepcount);
        let text = dsf.config.setting_public_show_fontsizemap.split(",");
        let arr = {
          [size]: text[0]
        };
        for (let i = 0; i < count; i++) {
          size = size + steps;
          arr[size] = text[i + 1];
        }
        return arr;
      }
    },
  },
  data() {
    return {
      showThemes: [],
      hasDefaultHeader: false,
      hasDefaultAside: false,
      fontSizeMax: 0,
      fontSizeMin: 0,
      themeConfig: {
        theme: null,
        fontSize: null,
        defaultHeaderMini: null,
        defaultAsideMini: null
      }
    }
  },
  computed: {
    fontSizeVal: {
      get() {
        return fontSizeMap[this.themeConfig.fontSize];
      },
      set(val) {
        this.$set(this.themeConfig, "fontSize", fontSizeOptions[val]);
      }
    }
  },
  watch: {
    "themeConfig.theme"(to, from) {
      dsf.themeConfig.setTheme(to);
      if (from === null) return;
      this.dispatchResize();
    },
    "themeConfig.fontSize"(to, from) {
      dsf.themeConfig.setFontSize(to);
      if (from === null) return;
      this.dispatchResize();
    },
    "themeConfig.defaultHeaderMini"(to, from) {
      dsf.themeConfig.setDefaultHeaderMini(to);
      if (from === null) return;
      this.dispatchResize();
    },
    "themeConfig.defaultAsideMini"(to, from) {
      dsf.themeConfig.setDefaultAsideMini(to);
      if (from === null) return;
      this.dispatchResize();
    }
  },
  created() {
    let showThemes = dsf.config.setting_public_show_themes || null;
    if (showThemes === "all") {
      this.showThemes = Object.keys(dsf.themeConfig.themesConfig);
    } else {
      showThemes = showThemes ? showThemes.split(",") : [];
      this.showThemes = _.filter(showThemes, (st) => !!dsf.themeConfig.themesConfig[st]);
    }
    this.$header = this.homeRoot?.$slots?.header?.[0];
    if (this.$header?.componentOptions?.tag == "dsf-default-home-header") {
      this.hasDefaultHeader = true;
    }
    this.$aside = this.homeRoot?.$slots?.left?.[0];
    if (this.$aside?.componentOptions?.tag == "dsf-default-home-aside-menu") {
      this.hasDefaultAside = true;
    }
    this.themeConfig = {...dsf.themeConfig.setting};
    let arr = [];
    for (let k in this.fontSizeOptions) {
      arr.push(parseInt(k));
    }
    arr = arr.sort((v, v1) => {
      if (v < v1) {
        return -1;
      } else {
        return 1;
      }
    });
    this.fontSizeMin = arr[0];
    this.fontSizeMax = arr[arr.length - 1];
    this.dispatchResize = _.throttle(this._dispatchResize, 500, {
      leading: false,
      trailing: true
    });
  },
  mounted() {
    this.$header = this.homeRoot?.$slots?.header?.[0];
    if (this.$header && this.$header.elm.classList.contains("page-home-default-header")) {
      this.hasDefaultHeader = true;
    }
  },
  methods: {
    radioStyle({ main, normal }, key) {
      let isActive = key === this.themeConfig.theme;
      return {
        "border-color": isActive ? normal : "#ccc",
        "background-image": `linear-gradient(45deg, ${main} 30%, ${normal} 70%)`
      };
    },
    radioClick(key) {
      this.$set(this.themeConfig, "theme", key);
    },
    _dispatchResize() {
      if(this.saveToServer){
        dsf.custom.addSystem("themeConfig", this.themeConfig);
      }
      let evt = window.document.createEvent("HTMLEvents");
      evt.initEvent("resize", false, false);
      window.dispatchEvent(evt);
    }
  }
}