import { render, staticRenderFns } from "./Panel.vue?vue&type=template&id=111fe510&"
import script from "./Panel.vue?vue&type=script&lang=js&"
export * from "./Panel.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

component.options.__file = "pc/common/utils/colorSelect/Panel.vue"
export default component.exports