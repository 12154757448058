<template>
  <td
    :slot-name="slots[0].name"
    :class="getCss"
    class="ds-control ds-no-padding ds-table-layout-cell"
    :style="[{ width: cellWidth }, cellStyle, cellBorder]"
    :c-index="rowIndex + ',' + cellIndex"
    :rowspan="rowspan"
    :colspan="colspan"
    :align="cellAttr.align"
    :valign="cellAttr.valign"
    @dblclick="dblClickHandler">
    <slot :name="slots[0].name">
      <template v-if="isDesign">
        <DsfTextContentEditable style="padding:5px" v-model="text" :editable.sync="conentEditable" @input="textChangeHandler()" @blur="textChangeHandler"></DsfTextContentEditable>
      </template>
      <template v-else>
        <span v-html="dsf.safe.xss(text)"></span>
      </template>
    </slot>
  </td>
</template>
<script>
export default dsf.component({
  name: "DsfTableCell",
  ctrlCaption: "表格布局单元格",
  mixins: [$mixins.layout],
  inject: {
    $tableLayout: {
      default: null
    },
    $tableRow: {
      default: null
    }
  },
  props: {
    slots: {
      type: Array,
      default() {
        return [{ name: "default", controls: [] }];
      }
    },
    width: {
      type: [Number, String],
      default() {
        return "auto";
      }
    },
    rowspan: {
      type: Number,
      default: 1
    },
    colspan: {
      type: Number,
      default: 1
    },
    cellIndex: {
      type: Number,
      default: 0
    },
    rowIndex: {
      type: Number,
      default: 0
    },
    cellAttr: {
      type: Object,
      default() {
        return {
          align: "", // 水平对齐
          valign: "" // 垂直对齐
        };
      }
    },
    cellStyle: {
      type: Object,
      default() {
        return {};
      }
    },
    /**废弃 */
    cellBorderStyle: {
      type: Object,
      default() {
        return {};
      }
    },
    text: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      conentEditable: false
    };
  },
  computed: {
    cellBorder() {
      let style = {};
      if (this.$tableLayout.borderWidth) {
        style["border-right-width"] = this.unitSize(this.$tableLayout.borderWidth);
        style["border-bottom-width"] = this.unitSize(this.$tableLayout.borderWidth);
        if (this.rowIndex == 0) {
          style["border-top-width"] = this.unitSize(this.$tableLayout.borderWidth);
        } 
        if (this.cellIndex == 0) {
          style["border-left-width"] = this.unitSize(this.$tableLayout.borderWidth);
        }
      }
      if (this.$tableLayout.borderStyle) {
        if (this.$tableLayout.borderStyle != "none") {
          style["border-right-style"] = this.$tableLayout.borderStyle;
          style["border-bottom-style"] = this.$tableLayout.borderStyle;
          if (this.rowIndex == 0) {
            style["border-top-style"] = this.$tableLayout.borderStyle;
          } 
          if (this.cellIndex == 0) {
            style["border-left-style"] = this.$tableLayout.borderStyle;
          }
        }
      }
      if (this.$tableLayout.borderColor) {
        style["border-right-color"] = this.$tableLayout.borderColor;
        style["border-bottom-color"] = this.$tableLayout.borderColor;
        if (this.rowIndex == 0) {
          style["border-top-color"] = this.$tableLayout.borderColor;
        } 
        if (this.cellIndex == 0) {
          style["border-left-color"] = this.$tableLayout.borderColor;
        }
      }
      return style;
    },
    cellWidth() {
      let w = this.width;
      if (this.colspan > 1) {
        return "auto";
      } else {
        return w === "number" ? w + "px" : w || "auto";
      }
    }
  },
  created() {
    if (!this.isDesign) {
      if (this.$vm && this.local) {
        this.$parent.tableCells.push(this);
      }
    }
  },
  beforeDestroy() {
    if (!this.isDesign) {
      dsf.array.remove(this.$parent.tableCells, this);
    }
  },
  methods: {
    dblClickHandler(event) {
      if (this.isDesign) {
        if (!this.conentEditable) {
          this.conentEditable = true;
          this.$nextTick(() => {
            dsf.designer.beginEditTextConent(event.currentTarget);
          });
        }
      }
    },
    textChangeHandler() {
      if (this.isDesign) {
        this.$nextTick(() => {
          this.$tableLayout.updateRangeWrap();
        });
      }
    }
  }
});
</script>
