var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "ds-data-panel ds-data-panel-two",
      style: _vm.panelStyle,
      attrs: { shadow: _vm.showShadow, size: _vm.size },
      on: { click: _vm.onClick },
    },
    [
      _vm._t(
        "item",
        function () {
          return [
            _vm.showIcon
              ? _c(
                  "div",
                  {
                    staticClass: "ds-data-panel-two-icon",
                    style: _vm.iconBoxStyle,
                  },
                  [
                    _vm._t(
                      "itemLeft",
                      function () {
                        return [
                          _c("DsfIcon", {
                            style: _vm.iconStyle,
                            attrs: {
                              name: _vm.$replace(_vm.iconName, _vm.panelData),
                            },
                          }),
                          _c(
                            "div",
                            { staticClass: "ds-data-panel-two-icon-text" },
                            [_vm._v(_vm._s(_vm.iconText))]
                          ),
                        ]
                      },
                      null,
                      _vm.$props
                    ),
                  ],
                  2
                )
              : _vm._e(),
            _c(
              "div",
              {
                staticClass: "ds-data-panel-two-info",
                attrs: { textalign: _vm.textAlign },
              },
              [
                _vm._t(
                  "itemRight",
                  function () {
                    return [
                      _c(
                        "div",
                        { staticClass: "ds-data-panel-two-info-wrap" },
                        [
                          _c(
                            "div",
                            { staticClass: "ds-data-panel-two-info-number" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$replace(_vm.number, _vm.panelData) || 0
                                  )
                              ),
                              _vm.unit
                                ? _c(
                                    "span",
                                    {
                                      staticClass:
                                        "ds-data-panel-two-info-unit",
                                    },
                                    [_vm._v(_vm._s(_vm.unit))]
                                  )
                                : _vm._e(),
                            ]
                          ),
                          _vm.showTitle
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "ds-data-panel-two-info-name ellipsis",
                                  attrs: { title: _vm.title },
                                },
                                [_vm._v(" " + _vm._s(_vm.title || "") + " ")]
                              )
                            : _vm._e(),
                        ]
                      ),
                    ]
                  },
                  null,
                  _vm.$props
                ),
              ],
              2
            ),
          ]
        },
        null,
        _vm.$props
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }