/*
 * @Editor: chenqy
 * @Description: 画板
 * @Date: 2021-09-09 17:02:15
 * @LastEditors: Chenqy
 * @LastEditTime: 2022-03-22 14:42:33
 */
import { draggable } from "./draggble";
import domtoimageFn from "./domtoimg";
export default class CanvasDraw {
  canvas = []; // 主画板
  canvasContext2d = {};
  historyDraw = []; // 历史画板
  historyContext2d = {};
  // 展示历史画板所有操作
  historyCanvas = {};
  // 收集画板所有操作 -> 图片形式 -> formatter {pathIndex: base64}
  recordCanvasImg = {};
  modeDocument = document; // canvas所处的document对象
  syncDom = HTMLElement;
  layers = []; // 可拖动图层信息
  // 画布操作储存器 (20步之内)
  canvasGeoPath = [
    {
      // 撤销记录索引
      historyIndexs: [],
      // 当前画布内容集
      canvasGeoPath: [],
    },
  ];
  // 储存器index (需求： ppt每一页操作的内容与下一页分开)
  pathIndex = 0;
  // 兼容ipad & phone
  isMobile = /Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent);
  operationEvent = {
    mousedown: this.isMobile ? "touchstart" : "mousedown",
    mousemove: this.isMobile ? "touchmove" : "mousemove",
    mouseup: this.isMobile ? "touchend" : "mouseup",
  };
  // 操作栏功能
  canvasFunction = {
    back: "back",
    restore: "restore",
    pencil: "pencil",
    colorPanel: "colorPanel",
    width: "width",
    font: "font",
    circle: "circle",
    rect: "rect",
    table: "table",
    picture: "picture",
    hide: "hide",
    show: "show",
    clear: "clear",
    save: "save",
    record: "record",
    material: "material",
    whiteBoard: "whiteBoard",
  };
  // 画板设置
  canvasConfig = {
    color: "#000",
    lineWidth: 1,
    fontSize: 14,
    fontBlod: "",
  };
  canvasMode = ""; // 当前canvas处于什么功能区
  eventMap = new Map(); // 储存canvas事件
  /**
   *
   * @param {string} id 创建canvas对象的domId
   * @param {element} canvas canvas对象
   * @param {number}} pathIndex 初始化页数
   */
  constructor(id, canvas, pathIndex = 0) {
    try {
      this.pathIndex = pathIndex;
      if (pathIndex !== 0) {
        this.canvasGeoPath[pathIndex] = {
          // 撤销记录索引
          historyIndexs: [],
          // 当前画布内容集
          canvasGeoPath: [],
        };
      }
      let parentDom = document.querySelector(`#${id}`);
      let that = this;
      if (parentDom instanceof HTMLIFrameElement) {
        if (canvas) {
          let arrayCanvas = [];
          this.modeDocument = parentDom.contentWindow.document;
          if (canvas instanceof HTMLCanvasElement) {
            arrayCanvas.push(canvas);
          } else {
            arrayCanvas = canvas;
          }
          this.canvas = arrayCanvas;
          arrayCanvas.forEach((canvasNode, index) => {
            this.canvasContext2d[canvasNode.id] = canvasNode.getContext("2d");
            let historyDraw = this.modeDocument.createElement("canvas");
            let uuid = `file-historyDraw-${index}`;
            historyDraw.id = uuid;
            historyDraw.width = canvasNode.width;
            historyDraw.height = canvasNode.height;
            historyDraw.setAttribute("style", canvasNode.getAttribute("style"));
            canvasNode.parentNode.insertBefore(historyDraw, canvasNode);
            this.historyDraw.push(historyDraw);
            this.historyContext2d[uuid] = historyDraw.getContext("2d");
          });
        } else {
          parentDom.addEventListener("load", function () {
            that.modeDocument = parentDom.contentWindow.document;
            // 处理平台内部和外部提供sdk兼容性
            let bodyNode = parentDom.contentWindow.document.querySelector(
              "body"
            );
            if (bodyNode.getBoundingClientRect().height === 0) {
              that.syncDom = parentDom.contentWindow.document.querySelector(
                ".full-view"
              );
            } else {
              that.syncDom = bodyNode;
            }
            let screenCanvas = that.createCanvas("screenCanvas");
            let historyDraw = that.createCanvas("historyDraw");
            that.canvas = [screenCanvas];
            that.historyDraw = [historyDraw];
            let canvasContext2d = screenCanvas.getContext("2d");
            canvasContext2d.fillStyle = "#fff";
            canvasContext2d.fillRect(0, 0, 1, 1);
            canvasContext2d.lineWidth = that.canvasConfig.lineWidth;
            canvasContext2d.strokeStyle = that.canvasConfig.color;
            that.canvasContext2d["screenCanvas"] = canvasContext2d;
            that.historyContext2d["historyDraw"] = historyDraw.getContext("2d");
            that.syncDom.appendChild(historyDraw);
            that.syncDom.appendChild(screenCanvas);
          });
        }
      } else if (parentDom instanceof HTMLCanvasElement) {
        this.modeDocument = document;
        this.canvas = parentDom;
        this.canvasContext2d = parentDom.getContext("2d");
      }
    } catch (e) {
      console.error(e);
    }
  }
  // 修改当前canvas的index
  canvasChangePathIndex(index = 0) {
    this.utilsMakeHistory();
    this.pathIndex = index;
    // this.canvasContext2d.clearRect(0, 0, this.canvas.width, this.canvas.height);
    let keys = Object.keys(this.canvasContext2d);
    keys.forEach((item, index) => {
      this.canvasContext2d[item].clearRect(
        0,
        0,
        this.canvas[index].width,
        this.canvas[index].height
      );
    });
    if (this.canvasGeoPath[this.pathIndex]) {
      this.utilsReAppearPath();
    } else {
      this.canvasGeoPath[this.pathIndex] = {
        // 历史记录索引
        historyIndexs: [],
        canvasGeoPath: [],
      };
    }
    this.utilsRestoreCanvas("", "", 0);
  }
  // 创建canvasDom到被同步的页面
  createCanvas(id) {
    let rect = this.syncDom.getBoundingClientRect();
    let canvas = this.modeDocument.createElement("canvas");
    canvas.id = id;
    canvas.width = rect.width || 1;
    canvas.height = rect.height || 1;
    canvas.style.position = "absolute";
    canvas.style.top = "0px";
    canvas.style.left = "0px";
    canvas.style.zIndex = "2";
    // canvas.style.bottom = "0px";
    // canvas.style.right = "0px";
    return canvas;
  }
  /**
   * 导航栏事件统一分发
   * @param {string} eventName 事件名称
   * @param {any} param 事件参数
   */
  handleEventDistribute(eventName, param) {
    let withoutEventClean = [
      "colorPanel",
      "width",
      "clear",
      "back",
      "restore",
      "show",
      "hide",
    ];
    if (!withoutEventClean.includes(eventName)) {
      this.clearEvent();
      if (this.canvasMode === eventName) {
        this.canvasMode = "";
        return;
      }
    }
    this.canvasMode = eventName;
    if (this.canvasFunction[eventName]) {
      this[`handle${eventName}`] && this[`handle${eventName}`](param);
    } else {
      console.error(`该功能${eventName}暂未提供，敬请期待`);
    }
  }
  // 消除canvas鼠标事件
  clearEvent() {
    // if (!this.eventMap.get("start")) {
    //   return;
    // }
    this.canvas.forEach((item) => {
      item.removeEventListener(
        this.operationEvent.mousedown,
        this.eventMap.get(item.id)
      );
    });
    this.eventMap.clear();
  }
  /**
   * 计算坐标
   * @param {HTMLElement} event 当前拖动元素
   * @param {HTMLElement} sourceNode 当前拖动元素的参考节点
   * @returns
   */
  getAbsoluteXY(event, sourceNode) {
    let rect = sourceNode
      ? sourceNode.getBoundingClientRect()
      : event.target.getBoundingClientRect();
    let { clientX, clientY, fingers, pageX, pageY } = event;
    return {
      x: clientX - rect.left,
      y: clientY - rect.top,
      pageX,
      pageY,
      fingers,
    };
  }
  // 铅笔模式
  handlepencil() {
    let path = {
      x: [],
      y: [],
    };
    let option = {
      start: function (event) {
        let { x, y } = this.getAbsoluteXY(event);
        path.x = [];
        path.y = [];
        path.x.push(x);
        path.y.push(y);
      },
      drag: function (event) {
        let { x, y, fingers } = this.getAbsoluteXY(event);
        if (fingers !== 1) {
          return;
        }
        path.x.push(x);
        path.y.push(y);
        path.id = event.target.id;
        this.utilspencil(Object.assign({}, path));
      },
      end: function (event) {
        this.currentCanvas = null;
        this.utilsSavaGeo("pencil", Object.assign({}, path), event.fingers);
      },
    };
    this.utilsBindThis(option);
  }
  // 加粗
  handlewidth() {
    this.canvasConfig.fontBlod = this.canvasConfig.fontBlod
      ? ""
      : "normal bold";
  }
  // 颜色
  handlecolorPanel(color) {
    this.canvasConfig.color = color;
  }
  // 画圆
  handlecircle() {
    let circleObj = {
      x: 0,
      y: 0,
      radiusX: 0,
      radiusY: 0,
      rotation: 0,
      startAngle: 0,
      endAngle: 2 * Math.PI,
      anticlockwise: true,
    };
    let option = {
      start: function (event) {
        let { x, y } = this.getAbsoluteXY(event);
        circleObj.x = x;
        circleObj.y = y;
        this.canvasContext2d[event.target.id].beginPath();
      },
      drag: function (event) {
        this.handleclear();
        this.utilsReAppearPath();
        let { x, y, fingers } = this.getAbsoluteXY(event);
        if (fingers !== 1) {
          return;
        }
        circleObj.radiusX = Math.abs(x - circleObj.x);
        circleObj.radiusY = Math.abs(y - circleObj.y);
        circleObj.derectionY = y - circleObj.y <= 0 ? -1 : 1;
        circleObj.derectionX = x - circleObj.x <= 0 ? -1 : 1;
        circleObj.id = event.target.id;
        this.utilscircles(circleObj);
      },
      end: function (event) {
        this.utilsSavaGeo(
          "circles",
          Object.assign({}, circleObj),
          event.fingers
        );
      },
    };
    this.utilsBindThis(option);
  }
  // 画矩形
  handlerect() {
    let rectObj = {
      x: 0,
      y: 0,
      width: 0,
      height: 0,
    };
    let option = {
      start: function (event) {
        let { x, y } = this.getAbsoluteXY(event);
        rectObj.x = x;
        rectObj.y = y;
        this.canvasContext2d[event.target.id].beginPath();
      },
      drag: function (event) {
        this.handleclear();
        this.utilsReAppearPath();
        let { x, y, fingers } = this.getAbsoluteXY(event);
        if (fingers !== 1) {
          return;
        }
        rectObj.width = x - rectObj.x;
        rectObj.height = y - rectObj.y;
        rectObj.id = event.target.id;
        this.utilsrects(rectObj);
      },
      end: function (event) {
        this.utilsSavaGeo("rects", Object.assign({}, rectObj), event.fingers);
      },
    };
    this.utilsBindThis(option);
  }
  // 文字添加
  handlefont() {
    let isWriting = false;
    let option = {
      start: function (event) {
        if (isWriting) {
          isWriting = false;
          // 第二次点击input之外的元素则直接添加文字
          let dom = this.modeDocument.getElementById("canvas-font-input");
          if (dom) {
            dom.blur();
          }
        } else {
          isWriting = true;
          let { pageX, pageY } = this.getAbsoluteXY(event);
          this.utilsCreateText(pageX, pageY, event.target);
        }
      },
    };
    this.utilsBindThis(option);
  }
  /**
   * 清除画板
   * @param {object} type 是否为按钮点击触发
   * @param {string} id   需要清除的canvasId
   */
  handleclear(type = {}, id = "") {
    if (id) {
      this.canvasContext2d[id].clearRect(
        0,
        0,
        this.canvas.width,
        this.canvas.height
      );
    } else {
      for (let k in this.canvasContext2d) {
        this.canvasContext2d[k].clearRect(
          0,
          0,
          this.canvas[0].width,
          this.canvas[0].height
        );
      }
    }
    if (type.isBtnClear) {
      Object.keys(this.canvasContext2d).forEach((item, index) => {
        this.canvasContext2d[item].clearRect(
          0,
          0,
          this.canvas[index].width,
          this.canvas[index].height
        );
      });
      Object.keys(this.historyContext2d).forEach((item, index) => {
        this.historyContext2d[item].clearRect(
          0,
          0,
          this.canvas[index].width,
          this.canvas[index].height
        );
      });
      this.utilsSavaGeo("clear", {}, 1);
    }
  }
  // 显示画板
  handleshow() {
    this.canvas.forEach((item) => {
      item.style.opacity = 1;
      item.style.zIndex = 2;
    });
    this.historyDraw.forEach((item) => {
      item.style.zIndex = 2;
    });
  }
  // 隐藏画板
  handlehide() {
    this.canvas.forEach((item) => {
      item.style.opacity = 0;
      item.style.zIndex = -1;
    });
    this.historyDraw.forEach((item) => {
      item.style.zIndex = -1;
    });
  }
  // 撤销
  handleback() {
    let historyCanvas = this.canvasGeoPath[this.pathIndex];
    // 根据记录内容往前装箱
    if (historyCanvas.canvasGeoPath.length) {
      let indexOfBack = historyCanvas.canvasGeoPath.length - 1;
      let flag = true;
      while (flag) {
        if (indexOfBack < 0) {
          flag = false;
        } else if (historyCanvas.historyIndexs.includes(indexOfBack)) {
          indexOfBack -= 1;
        } else {
          historyCanvas.historyIndexs.push(indexOfBack);
          this.handleclear();
          this.utilsReAppearPath(true);
          flag = false;
        }
      }
    }
  }
  // 还原
  handlerestore() {
    let historyCanvas = this.canvasGeoPath[this.pathIndex];
    if (historyCanvas.historyIndexs.length) {
      historyCanvas.historyIndexs.pop();
      this.handleclear();
      this.utilsReAppearPath(true);
    }
  }
  // 上传图片
  handlepicture(file) {
    let imgSupport = ["image/jpeg", "image/png", "image/gif", "image/bmp"];
    let files = file.target.files;
    let that = this;
    for (let i = 0; i < files.length; i++) {
      let t = files[i].type.toLowerCase();
      if (!imgSupport.includes(t)) {
        dsf.layer.message("请上传jpg/png/gif/bmp格式的图片", false);
        return;
      } else {
        let reader = new FileReader();
        reader.readAsDataURL(files[i]);
        let img = new Image();
        reader.onload = function name() {
          img.src = reader.result;
          let addElement = that.addLayer(img);
          that.canvasContext2d.drawImage(addElement, 0, 0);
        };
      }
    }
  }
  // 保存操作
  handlesave() {
    let status = window.parent.fileViewObj.sourceType;
    let beSavedNode = "";
    if (status === "word") {
      beSavedNode = this.modeDocument.querySelector(".dsf-file-pre-type");
    } else if (status === "ppt" || status === "video") {
      beSavedNode = this.modeDocument.querySelector(".dsf-pre");
    } else {
      beSavedNode = this.modeDocument.querySelector(".full-view");
    }
    let domToImageRoot = document.getElementById("syncScreen").contentWindow;
    // 储存页面为图片
    domtoimageFn(domToImageRoot)
      .toJpeg(beSavedNode, {
        quality: 0.6,
        bgcolor: "#fff",
        imagePlaceholder:
          "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyAQMAAAAk8RryAAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAANQTFRFAAAAp3o92gAAAAF0Uk5TAEDm2GYAAAAOSURBVHicY2AYBYMJAAABkAAB07sQ/gAAAABJRU5ErkJggg==",
      })
      .then((dataUrl) => {
        let a = document.createElement("a");
        a.href = dataUrl;
        a.download = `${window.parent.dsf.uuid()}.jpg`;
        a.click();
      })
      .catch((err) => {
        console.log(err);
      });
  }
  addLayer(element) {
    if (
      element instanceof HTMLCanvasElement ||
      element instanceof HTMLImageElement
    ) {
      let canvas = document.createElement("canvas");
      let rect = element.getBoundingClientRect();
      document.body.appendChild(canvas);
      canvas.width = rect.width;
      canvas.height = rect.height;
      let context = canvas.getContext("2d");
      context.drawImage(element, 0, 0);
      this.layers.push(canvas);
      return canvas;
    }
  }
  // ***********工具类**********

  /**
   * 重现之前画板内容
   * @param {boolean} isBack  是否为撤销、返回重绘 true是 false不是
   * @param {string} id 指定操作canvas的id
   */
  utilsReAppearPath(isBack, id) {
    let startIndex = 0;
    let endIndex = 0;
    let historyCanvas = this.canvasGeoPath[this.pathIndex];
    let beBacks = historyCanvas.historyIndexs;
    id = id || this.historyDraw[0].id;
    if (isBack) {
      endIndex = historyCanvas.canvasGeoPath.length;
      // 历史操作中有清除操作
      let restoreHistoty = true;
      for (let i = 0; i < endIndex; i++) {
        if (
          historyCanvas.canvasGeoPath[i].type.includes("clear") &&
          !beBacks.includes(i)
        ) {
          restoreHistoty = false;
        }
      }
      if (restoreHistoty) {
        // 还原之前用户的历史记录
        let hs = this.historyCanvas[this.pathIndex];
        if (hs) {
          let that = this;
          let image = new Image();
          image.src = hs;
          image.onload = function () {
            that.historyContext2d[id].drawImage(
              image,
              0,
              0,
              that.historyDraw[0].width,
              that.historyDraw[0].height
            );
          };
        }
      }
    } else {
      // 从最后一次删除的节点开始重绘
      endIndex = historyCanvas.canvasGeoPath.length;
      for (let i = 0; i < endIndex; i++) {    
        if (historyCanvas.canvasGeoPath[i].type === "clear") {
          startIndex = i;
        }
      }
    }
    for (let i = startIndex; i < endIndex; i++) {
      if (beBacks.includes(i)) {
        continue;
      }
      let type = historyCanvas.canvasGeoPath[i].type;
      let funcName = `utils${type}`;
      this[funcName](
        historyCanvas.canvasGeoPath[i].path,
        historyCanvas.canvasGeoPath[i].config
      );
    }
  }
  /**
   * 储存当前画板信息
   * @param {string} type
   * @param {object} path
   * @param {number} fingers
   * @param {string} canvasId
   */
  utilsSavaGeo(type, path, fingers) {
    if (fingers !== 1) {
      return;
    }
    let baseObj = {
      type: type,
      path: path,
      config: {
        ...this.canvasConfig,
      },
    };
    let historyCanvas = this.canvasGeoPath[this.pathIndex];
    if (historyCanvas.canvasGeoPath.length > 20) {
      historyCanvas.canvasGeoPath.shift();
    }
    historyCanvas.canvasGeoPath.push(baseObj);
  }
  /**
   * 自动绑定this作用域
   * @param {object} eventMap
   */
  utilsBindThis(eventMap) {
    for (let key in eventMap) {
      eventMap[key] = eventMap[key].bind(this);
    }
    this.canvas.forEach((item) => {
      let downFn = draggable(item, eventMap, this.modeDocument);
      this.eventMap.set(item.id, downFn);
    });
  }
  // 生成线
  utilspencil(path, config) {
    let length = path.x.length;
    let contextCanvas = this.canvasContext2d[path.id];
    contextCanvas.beginPath();
    for (let i = 0; i < length; i++) {
      let x = path.x[i];
      let y = path.y[i];
      if (!i) {
        contextCanvas.moveTo(x, y);
      }
      contextCanvas.strokeStyle = config?.color || this.canvasConfig.color;
      contextCanvas.lineWidth =
        config?.lineWidth || this.canvasConfig.lineWidth;
      contextCanvas.lineTo(x, y);
      contextCanvas.stroke();
    }
  }
  // 生成圆
  utilscircles(path, config) {
    let {
      x,
      y,
      radiusX,
      radiusY,
      rotation,
      startAngle,
      endAngle,
      anticlockwise,
      derectionX,
      derectionY,
      id,
    } = path;
    let canvasContext2d = this.canvasContext2d[id];
    canvasContext2d.beginPath();
    canvasContext2d.strokeStyle = config?.color || this.canvasConfig.color;
    canvasContext2d.lineWidth =
      config?.lineWidth || this.canvasConfig.lineWidth;
    canvasContext2d.ellipse(
      x + (radiusX / 2) * derectionX,
      y + (radiusY / 2) * derectionY,
      radiusX / 2,
      radiusY / 2,
      rotation,
      startAngle,
      endAngle,
      anticlockwise
    );
    canvasContext2d.stroke();
    canvasContext2d.closePath();
  }
  // 生成矩形
  utilsrects(path, config) {
    let canvasContext2d = this.canvasContext2d[path.id];
    canvasContext2d.beginPath();
    canvasContext2d.moveTo(path.x, path.y);
    canvasContext2d.lineTo(path.x + path.width, path.y);
    canvasContext2d.lineTo(path.x + path.width, path.y + path.height);
    canvasContext2d.lineTo(path.x, path.y + path.height);
    canvasContext2d.lineTo(path.x, path.y);
    canvasContext2d.strokeStyle = config?.color || this.canvasConfig.color;
    canvasContext2d.lineWidth =
      config?.lineWidth || this.canvasConfig.lineWidth;
    canvasContext2d.stroke();
    canvasContext2d.closePath();
  }
  // 生成文字
  utilsfonts(data, config) {
    let canvasContext2d = this.canvasContext2d[data.id];
    canvasContext2d.beginPath();
    if (this.canvasConfig.fontBlod) {
      canvasContext2d.font =
        this.canvasConfig.fontBlod + canvasContext2d.font.substring(3);
    } else {
      canvasContext2d.font = data.font;
    }
    canvasContext2d.fillStyle = config?.color;
    // 处理换行问题
    let texts = data.text.split("\n");
    for (let i = 0; i < texts.length; i++) {
      canvasContext2d.fillText(
        texts[i],
        data.x,
        data.y + i * 1.2 * config?.fontSize
      );
    }
  }
  // 生成textarea
  utilsCreateText(pageX, pageY, canvsaNode) {
    let that = this;
    // 绘制输入框
    let content = document.createElement("div");
    let input = document.createElement("div");
    content.style.minWidth = "130px";
    content.style.minHeight = "17px";
    content.style.position = "absolute";
    content.style.left = `${pageX}px`;
    content.style.top = `${pageY}px`;
    content.style.zIndex = 100;
    content.style.overflow = "hidden";
    content.style.padding = "5px";
    content.style.color = this.canvasConfig.color;
    content.style.border = `1px solid ${this.canvasConfig.color}`;
    content.style.backgroundColor = "transparent";
    content.style.cursor = "move";
    input.contentEditable = true;
    input.id = "canvas-font-input";
    input.style.outline = "none";
    input.style.fontSize = this.canvasConfig.fontSize + "px";
    input.style.cursor = "text";
    input.style.lineHeight = 1.2;
    content.appendChild(input);
    this.modeDocument.body.appendChild(content);
    // 自动获焦
    setTimeout(() => {
      input.focus();
    }, 20);
    // 添加移动事件
    let distanceX = 0;
    let distanceY = 0;
    let option = {
      start: function (event) {
        let { clientX, clientY } = event;
        distanceX = clientX;
        distanceY = clientY;
      },
      drag: function (event) {
        let { clientX, clientY } = event;
        content.style.transform = `translate(${clientX - distanceX + "px"}, ${
          clientY - distanceY + "px"
        })`;
      },
      end: function () {},
    };
    for (let key in option) {
      option[key] = option[key].bind(this);
    }
    let downFn = draggable(content, option, this.modeDocument);
    // 销毁text并清除绑定事件
    input.onblur = function (event) {
      let text = event.target.innerText;
      let font = getComputedStyle(input).font;
      const rect = this.getBoundingClientRect();
      const pRect = canvsaNode.getBoundingClientRect();
      let fontObj = {
        text,
        font,
        x: rect.left - pRect.left,
        y: rect.top - pRect.top + 13,
        id: canvsaNode.id,
      };
      content.removeEventListener("movedown", downFn);
      that.utilsfonts(fontObj, that.canvasConfig);
      that.utilsSavaGeo("fonts", Object.assign({}, fontObj), 1);
      that.modeDocument.body.removeChild(content);
    };
  }
  // 清除面板内容
  utilsclear() {
    Object.keys(this.canvasContext2d).forEach((item, index) => {
      this.canvasContext2d[item].clearRect(
        0,
        0,
        this.canvas[index].width,
        this.canvas[index].height
      );
    });
  }
  // 把所有操作以图片方式记录
  utilsMakeHistory() {
    let newHistory = {};
    newHistory[this.pathIndex] = {};
    this.canvas.forEach((item, index) => {
      let canvasNode = item;
      let canvasBox = canvasNode.cloneNode();
      let context = canvasBox.getContext("2d");
      context.drawImage(canvasNode, 0, 0, canvasNode.width, canvasNode.height);
      context.drawImage(
        this.historyDraw[index],
        0,
        0,
        canvasNode.width,
        canvasNode.height
      );
      // 是否为空白canvas
      var blank = document.createElement("canvas");
      blank.width = canvasNode.width;
      blank.height = canvasNode.height;
      let img = canvasBox.toDataURL();
      if (img !== blank.toDataURL()) {
        newHistory[this.pathIndex][
          index + "#" + this.historyDraw[index].id
        ] = img;
      } else {
        newHistory[this.pathIndex][index + "#" + this.historyDraw[index].id] =
          "";
      }
    });
    this.recordCanvasImg = Object.assign({}, this.recordCanvasImg, newHistory);
    return this.recordCanvasImg;
  }
  /**
   * 还原之前的操作(上一个人)
   * @param {historyCanvas} data 上个人的历史数据
   * @param {number} pathIndex 页数
   * @param {number} clearIndex 需要清空历史canvas下标
   */
  utilsRestoreCanvas(data, pathIndex, clearIndex) {
    if (data) {
      this.historyCanvas = data;
      this.recordCanvasImg = data;
    }
    if (clearIndex !== undefined) {
      let clearCanvas = this.historyDraw[clearIndex];
      this.historyContext2d[clearCanvas.id].clearRect(
        0,
        0,
        clearCanvas.width,
        clearCanvas.height
      );
    }
    let hsPathImgs = this.historyCanvas[pathIndex || this.pathIndex];
    for (let key in hsPathImgs) {
      let hsPathImg = hsPathImgs[key];
      let canvasId = key.split("#")[1];
      let canvasIndex = key.split("#")[0];
      this.historyContext2d[canvasId].clearRect(
        0,
        0,
        this.historyDraw[canvasIndex].width,
        this.historyDraw[canvasIndex].height
      );
      if (hsPathImg) {
        let that = this;
        let image = new Image();
        image.src = hsPathImg;
        image.onload = function () {
          that.historyContext2d[canvasId].drawImage(
            image,
            0,
            0,
            that.historyDraw[canvasIndex].width,
            that.historyDraw[canvasIndex].height
          );
        };
      }
    }
  }
}
