var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.loaded,
          expression: "loaded",
        },
      ],
      staticClass: "full-view",
    },
    [
      !_vm.mobile
        ? [
            _vm.loaded
              ? _c("PreviewLayout", {
                  tag: "component",
                  staticClass: "page-head-font-fixed",
                })
              : _vm._e(),
          ]
        : [
            _c("div", { staticClass: "mobile-view demo" }, [
              _c(
                "div",
                { staticClass: "mobile-view-content" },
                [
                  _vm.loaded
                    ? _c("PreviewLayout", {
                        tag: "component",
                        staticClass: "page-head-font-fixed",
                      })
                    : _vm._e(),
                ],
                1
              ),
            ]),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }