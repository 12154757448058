/*
 * @Descripttion: 
 * @Author: zhanghang
 * @Date: 2021-07-21 14:31:58
 * @LastEditors: zhanghang
 * @LastEditTime: 2021-07-30 17:39:36
 */
import DsfPlatformPolyline from './polyline.vue'
import DsfPlatformBar from './bar.vue'
import DsfPlatformPie from './pie.vue'
import DsfPlatformMixinAxis from './mixinAxis.vue'
import DsfPlatformRadar from './radar.vue'
import DsfPlatformMapChart from './mapChart.vue'


function install(Vue){
  Vue.component(DsfPlatformPolyline.name, DsfPlatformPolyline);
  Vue.component(DsfPlatformBar.name, DsfPlatformBar);
  Vue.component(DsfPlatformPie.name, DsfPlatformPie);
  Vue.component(DsfPlatformMixinAxis.name, DsfPlatformMixinAxis);
  Vue.component(DsfPlatformRadar.name, DsfPlatformRadar);
  Vue.component(DsfPlatformMapChart.name, DsfPlatformMapChart);

}

export default {
  install
}