export default {
  //行数据校验
  async validate(row) {
    let vm = this?.$vm;
    if (vm) {
      let arrs = [];
      let allKey = [];
      _.each(this.metadataDict, (it, key) => {
        allKey.push(key);
      });
      let metadataDict = {};
      _.each(this.$vm.metadataDict || [], (it, key) => {
        if (allKey.indexOf(it.id) >= 0) {
          metadataDict[key] = it;
        }
      });
      for (let i = 0; i < allKey.length; i++) {
        let data = row;
        let it = allKey[i];
        let formName = it + "-" + data.$hash;
        let meta = metadataDict[it];
        let validateFn = this.$vm.$getValidateFunction(it, metadataDict, formName);
        let fn = validateFn ? validateFn(data[it], data, formName, meta) : null;
        fn && arrs.push(fn);
      }
      let errors = await this.$vm.$execValidateFns(arrs);
      return errors;
    }
  }
}