var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "DsfRow",
    {
      ref: "row",
      class: _vm.getCss,
      attrs: {
        gutter: _vm.gutter,
        overflow: _vm.overflow,
        "min-height": _vm.minHeight,
      },
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }