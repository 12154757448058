<template>
  <div v-if="scriptLoaded" class="ds-export-setting">
    <div v-if="allowList.length > 1" class="ds-export-setting-box">
      <div class="title">导出文件类型</div>
      <div class="content file-box">
        <template v-for="item in allowList">
          <div class="file-radio" :key="item.value" :class="{active: extName === item.value}" @click="extName = item.value">
            <i class="iconfont" :class="item.icon" :style="{color: item.color}"></i>
            <span>{{ item.text }}</span>
          </div>
        </template>
      </div>
    </div>
    <div class="ds-export-setting-row">
      <div class="ds-export-setting-box">
        <div class="title">可选列</div>
        <dsf-virtual-scroll>
          <div v-if="!allowList.length" class="no-data">
            暂无可导出的列
          </div>
          <div class="el-checkbox-group">
            <el-checkbox
              v-for="field in fields"
              :key="field.id"
              :value="selectedIds.indexOf(field.id) > -1"
              @change="checkboxChange(field, $event)"
            >
              {{ field.caption }}
            </el-checkbox>
          </div>
        </dsf-virtual-scroll>
      </div>
      <div class="ds-export-setting-box">
        <div class="title">
          已选列
          <span v-show="selectedIds.length">({{ selectedIds.length }})</span>
          <span class="tips">拖动列可排序</span>
        </div>
        <dsf-virtual-scroll>
          <div v-if="!selectedIds.length" class="no-data">
            请从左侧选择需要导出的列
          </div>
          <draggable v-else v-model="selectedFields" class="field-list" v-bind="options">
            <template v-for="field in selectedFields">
              <div :key="field.id" class="field-item">
                <span>{{ field.caption }}</span>
                <i class="closeBt iconfont icon-guanbi2" @click.stop="checkboxChange(field, false)"></i>
              </div>
            </template>
          </draggable>
        </dsf-virtual-scroll>
      </div>
    </div>
  </div>
</template>
<script>
const defaultIcon = {
  ppt: ["icon-ppt", "#BF3919"],
  word: ["icon-word1", "#1554B8"],
  excel: ["icon-excel", "#0F773D"],
  pdf: ["icon-pdf", "#B70807"],
};

export default {
  name: "DsfExportSetting",
  props: {
    allow: {
      type: Array,
      default() {
        return [];
      }
    },
    fields: {
      type: Array,
      default() {
        return [];
      }
    },
    fileName: {
      type: String,
      default: ""
    },
    fieldIds: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      scriptLoaded: false,
      options: {
        animation: 300,
        forceFallback: true,
        draggable: ".field-item",
        filter: ".icon-guanbi2",
        ghostClass: "ghost",
        chosenClass: "active"
      },
      allowList: [],
      extName: "",
      selectedIds: [],
      selectedFields: []
    };
  },
  created() {
    this.loadScript();
    _.each(this.allow, (v) => {
      let v1 = v.split("|");
      let text = "",
        value = "",
        color = "#999",
        icon = null;
      if (v1.length == 1) {
        text = v1[0];
        value = v1[0];
      } else if (v1.length == 2) {
        text = v1[0];
        value = v1[1];
      } else if (v1.length == 3) {
        text = v1[0];
        value = v1[1];
        if (v1[2] == 1) {
          this.extName = value;
        } else if (v1[2].indexOf('icon-') > -1) {
          icon = v1[2];
        }
      } else if (v1.length == 4) {
        text = v1[0];
        value = v1[1];
        icon = v1[2];
        if (v1[3] == 1) {
          this.extName = value;
        }
      } else if (v1.length == 5) {
        text = v1[0];
        value = v1[1];
        icon = v1[2];
        color = v1[3];
        if (v1[4] == 1) {
          this.extName = value;
        }
      }
      if (!icon) {
        let d = defaultIcon[text.toLocaleLowerCase()];
        if (d) {
          icon = d[0];
          color = d[1];
        } else {
          icon = "icon-shiyongwendang";
        }
      }
      var obj = {text, value, icon, color};
      this.allowList.push(obj);
    });
    if (!this.extName && this.allowList.length >= 0) {
      this.extName = this.allowList[0].value;
    }
    let selectedIds = [], selectedFields = [];
    if (this.fieldIds?.length) {
      _.forEach(this.fieldIds, id => {
        let row = _.find(this.fields, r => r.id === id);
        if (row) {
          selectedIds.push(row.id);
          selectedFields.push(row);
        }
      });
    } else if (this.fields?.length) {
      selectedIds = _.map(this.fields, 'id');
      selectedFields = _.cloneDeep(this.fields);
    }
    this.selectedIds = selectedIds;
    this.selectedFields = selectedFields;
  },
  methods: {
    yes() {
      return {
        extName: this.extName,
        fields: this.selectedFields,
        fieldIds: _.map(this.selectedFields, 'id')
      };
    },
    loadScript(callback) {
      if (window.vuedraggable) {
        this.scriptLoaded = true;
        callback?.();
      } else {
        this.$http
          .importFiles([
            dsf.url.getWebPath("$/js/libs/vuedraggable/Sortable-1.13.0.js"),
            dsf.url.getWebPath("$/js/libs/vuedraggable/vuedraggable.umd.js"),
          ])
          .then(() => {
            this.scriptLoaded = true;
            callback?.();
          })
          .catch((err) => {
            console.error(err);
            dsf.layer.message("加载fabric文件报错", false);
          });
      }
    },
    checkboxChange(field, isChecked){
      if (isChecked) {
        this.selectedIds.push(field.id);
        this.selectedFields.push(field);
      } else {
        dsf.array.remove(this.selectedIds, field.id);
        dsf.array.remove(this.selectedFields, (f) => f.id === field.id);
      }
    }
  }
};
</script>