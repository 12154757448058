<template>
  <dsf-home-tab-panel
    ref="panel"
    class="ds-schedule-box"
    v-bind="panelConfig"
    :slots="realConfig"
    add-show
    more-show
    setting-show
    @setting-click="settingClick"
  >
    <template v-for="config in realConfig" :slot="config.name">
      <dsf-schedule-list-item
        :key="config.name"
        :url="config.url"
        :is-design="isDesign"
        :is-switch="isSwitch"
        :setting-config="settingConfig"
        classInfo=""
        is-parameter
      >
        <template #list="{ list }">
          <slot name="list" :list="list"></slot>
        </template>
        <template #default="{ item, index }">
          <slot :item="item" :index="index"></slot>
        </template>
        <template #list-bottom="{ item, index }">
          <slot
            :name="`list-bottom-${config.name}`"
            :config="config"
            :item="item"
            :index="index"
          ></slot>
        </template>
      </dsf-schedule-list-item>
    </template>
  </dsf-home-tab-panel>
</template>

<script>
export default dsf.component({
  name: "DsfScheduleList",
  mixins: [$mixins.control],
  ctrlCaption: "个人日程",
  design: {
    isMask: false,
  },
  props: {
    // 宽度
    width: {
      type: String,
      default: "100%",
    },
    // 高度
    height: {
      type: String,
      default: "100%",
    },
    // 显示阴影
    showShadow: {
      type: Boolean,
      default: true,
    },
    // 边框
    padding: {
      type: Array,
      default() {
        return [0, 20, 0, 20];
      },
    },
    // 边框
    margin: {
      type: Array,
      default() {
        return [10, 10, 10, 10];
      },
    },
    // 风格
    styleType: {
      type: String,
      default: "compact",
    },
    // 是否允许切换周
    isSwitch: {
      type: Boolean,
      default: false,
    },
    active: {
      type: String,
      default: "",
    },
    schedulePanelConfig: {
      type: Array,
      default() {
        return [
          {
            title: "我的日程",
            name: "mySchedule",
            hide: false,
            badge: null,
            lazy: false,
            more: {},
            url: "/agenda/info/queryUserAgendaByDate",
          },
          {
            title: "领导日程",
            name: "leaderSchedule",
            hide: false,
            badge: null,
            lazy: false,
            more: {},
            url: "/agenda/info/queryUserAgendaByDate",
          },
        ];
      },
    },
    // 圆角
    borderRadius: {
      type: Array,
      default() {
        return [0, 0, 0, 0];
      },
    },
    // 个性化配置
    settingConfig: {
      type: Object,
      default() {
        return {
          pageSize: 5,
          type: "week",
          ellipsis: true,
        };
      },
    },
  },
  data() {
    return {
      classInfo: {},
      // 当前选中班级的索引
      activeClassIndex: 0,
    };
  },
  computed: {
    realConfig() {
      return _.filter(this.schedulePanelConfig, (config) => !config.hide);
    },
    panelConfig() {
      return {
        title: "",
        moreIcon: "gengduo",
        titleIcon: "",
        width: this.width,
        height: this.height,
        padding: this.padding,
        margin: this.margin,
        isDesign: this.isDesign,
        styleType: this.styleType,
        borderRadius: this.borderRadius,
        active: this.active,
        singleTabAyncTitle: true,
      };
    },
  },
  created() {
    if (this.isDesign) {
      this.$dispatch("design-height-change", "100%");
    }
  },
  methods: {
    settingClick() {
      this.$openDialog({
        title: "个性化设置",
        content: "DsfScheduleListSetting",
        height: "500px",
        width: "800px",
        params: {
          settingConfig: this.settingConfig,
        },
        btns: [
          {
            text: "重置",
            handler: () => {
              let settingConfig = {
                pageSize: 5,
                type: "week",
                ellipsis: true,
              };
              this.$emit("update:settingConfig", settingConfig);
              this.$addCustom(this.caption, { settingConfig });
            },
          },
          {
            text: "确定",
            type: "",
            handler: (res) => {
              let settingConfig = res.yes();
              this.$emit("update:settingConfig", settingConfig);
              this.$addCustom(this.caption, { settingConfig });
            },
          },
          {
            text: "取消",
          },
        ],
      });
    },
  },
});
</script>