var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "ds-panel",
      class: [!_vm.hasHeader ? "no-header" : ""],
      style: _vm.getBackgroundStyle,
    },
    [
      _vm.hasHeader
        ? _c("div", { staticClass: "ds-panel-header" }, [
            _vm.hasTitle
              ? _c("div", { staticClass: "title" }, [
                  _c(
                    "label",
                    {
                      staticClass: "ds-font3",
                      style: {
                        "border-right": _vm.titleBorder
                          ? "1px solid #eeeeee;"
                          : "0px none",
                      },
                    },
                    [_vm._t("title")],
                    2
                  ),
                ])
              : _vm._e(),
            _c(
              "div",
              { staticClass: "header-left" },
              [_vm._t("header-left"), _vm._t("header-subtitle")],
              2
            ),
            _c("div", { staticClass: "header-right" }, [_vm._t("header")], 2),
          ])
        : _vm._e(),
      _c("div", { staticClass: "ds-panel-body" }, [_vm._t("default")], 2),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }