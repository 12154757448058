// 内置规则
const systemRule = {
  // 去除首尾空白
  trim(val) {
    return val.replace(/(^[\n\t\s]+)|([\n\t\s]+$)/g, '');
  },
  trimAll(val) {
    return val.replace(/[\n\t\s]+/g, '');
  }
};

// 正则
function regExpFormat(regs) {
  regs = regs.split('\n');
  let res = [];
  regs.forEach(r => {
    r = systemRule.trim(r);
    let modifiers = 'g';
    let pattern = null;
    if (/^\/[\S\s]+(\/g*)$/.test(r)) {
      pattern = r.replace(/(^\/)|((\/g*)$)/g, '');
    } else if (/^\/[\S\s]+(\/i)$/.test(r)) {
      modifiers = 'i';
      pattern = r.replace(/(^\/)|((\/i)$)/g, '');
    }
    if (pattern) {
      res.push(val => {
        return val.replace(new RegExp(pattern, modifiers), '');
      });
    }
  });
  return res;
}

// 关键词
function keywordsFormat(words) {
  words = words.split('\n');
  let filterChars = words.map(c => c.replace(/\|/g, '\\|'));
  return val => {
    return val.replace(new RegExp(filterChars.join('|'), 'g'), '');
  };
}

export default {
  props: {
    // 过滤规则
    filterRule: {
      type: Array,
      default(){
        return []
      }
    }
  },
  computed: {
    _filterRule() {
      let res = [];
      this.filterRule.forEach(({ type, key, value }) => {
        if (type === 'system') {
          if (systemRule[key]) {
            res.push(systemRule[key])
          }
        } else if (key === 'regExp') {
          res = res.concat(regExpFormat(value));
        } else if (key === 'keywords') {
          res.push(keywordsFormat(value));
        }
      });
      return res;
    }
  },
  methods: {
    filterFormat(val) {
      if (!this._filterRule.length) {
        return val;
      }
      let newVal = val;
      while(true) {
        this._filterRule.forEach(fun => {
          newVal = fun(newVal);
        });
        if (newVal == val) {
          break;
        }
        val = newVal;
      }
      return newVal;
    }
  }
};