var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "li",
    {
      staticClass: "ds-aside-menu-item",
      class: { active: _vm.isActive, show: _vm.showChildren },
      attrs: { "menu-key": _vm.menu.menu_id, "menu-level": _vm.level },
    },
    [
      _c(
        "div",
        {
          staticClass: "ds-aside-menu-item-box",
          style: { "padding-left": _vm.level * 20 + "px" },
          attrs: {
            title:
              !_vm.homeAside.mini || !!_vm.menu.children
                ? _vm.menu.menu_name
                : "",
          },
          on: { click: _vm.clickEvent },
        },
        [
          _vm.level === 1 && _vm.hasIcon
            ? _c(
                "el-tooltip",
                {
                  ref: "tooltip",
                  staticClass: "item",
                  attrs: {
                    disabled: !_vm.homeAside.mini || !!_vm.menu.children,
                    content: _vm.menu.menu_name,
                    effect: "light",
                    placement: "right",
                    transition: "",
                  },
                },
                [
                  _c("i", {
                    staticClass: "ds-aside-menu-item-icon iconfont",
                    class: [_vm.menu.menu_icon || "icon-shezhi"],
                  }),
                ]
              )
            : _vm._e(),
          _c("span", { staticClass: "ds-aside-menu-item-name ellipsis" }, [
            _vm._v(_vm._s(_vm.menu.menu_name)),
          ]),
          _vm.menu.children
            ? _c("i", {
                staticClass: "iconfont ds-aside-menu-item-show",
                class: [
                  { show: _vm.showChildren },
                  _vm.showChildren ? "icon-xia1" : "icon-zuo",
                ],
              })
            : _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.badge,
                      expression: "badge",
                    },
                  ],
                  staticClass: "ds-page-home-badge",
                  class: [_vm.homeRoot.badgeStyle],
                },
                [_vm._v(_vm._s(_vm.badge))]
              ),
        ],
        1
      ),
      !_vm.homeAside.mini && _vm.menu.children
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showChildren,
                  expression: "showChildren",
                },
              ],
            },
            [
              _c("dsf-default-home-menu", {
                attrs: { "menu-list": _vm.menu.children, level: _vm.level + 1 },
              }),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }