var render = function (_h, _vm) {
  var _c = _vm._c
  return _c(
    "div",
    {
      staticClass: "dsf-convenient-menu-item pc",
      class: [_vm.props.displayStyle],
    },
    [
      _c(
        "div",
        { staticClass: "icon", style: _vm.parent.iconStyle },
        [
          _c(
            "el-image",
            {
              attrs: {
                src: _vm._f("imgFormat")(_vm.parent.getImgUrl(_vm.props.item)),
              },
            },
            [
              _c("template", { slot: "error" }, [
                _c("i", {
                  staticClass: "iconfont",
                  class: [_vm.parent.getIcon(_vm.props.item.icon)],
                }),
              ]),
            ],
            2
          ),
          _vm.parent.showMark(_vm.props.item)
            ? _c(
                "span",
                {
                  staticClass: "angle-mark",
                  class: [_vm.parent.enableMark],
                  style: _vm.parent.markStyle,
                },
                [_vm._v(" " + _vm._s(_vm.parent.markVal(_vm.props.item)) + " ")]
              )
            : _vm._e(),
        ],
        1
      ),
      _c("div", { staticClass: "info", style: _vm.parent.infoStyle }, [
        _c("div", { staticClass: "name ellipsis" }, [
          _vm._v(_vm._s(_vm.props.item.name || "")),
        ]),
        _vm.parent.hasDesc
          ? _c("div", { staticClass: "desc ellipsis-2" }, [
              _vm._v(_vm._s(_vm.props.item.desc || "")),
            ])
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }