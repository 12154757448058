var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "ds-login-page" }, [
    _c("div", { staticClass: "login-header" }, [
      _vm.logo
        ? _c("img", {
            ref: "logo",
            staticClass: "logo",
            attrs: { src: _vm._f("imgFormat")(_vm.logo), alt: _vm.title },
          })
        : _vm._e(),
      _vm.title
        ? _c("div", { staticClass: "site-name" }, [_vm._v(_vm._s(_vm.title))])
        : _vm._e(),
    ]),
    _c(
      "div",
      {
        staticClass: "login-section",
        style: {
          "background-image": _vm.carouselItem
            ? ""
            : "url(" + _vm.backgroundImage + ")",
        },
      },
      [
        _c(
          "div",
          { staticClass: "login-box" },
          [
            _c(
              "el-form",
              {
                nativeOn: {
                  submit: function ($event) {
                    $event.preventDefault()
                  },
                },
              },
              [
                _vm._t("form", function () {
                  return [
                    _c("div", { staticClass: "login-box-title" }, [
                      _vm._v("登 录"),
                    ]),
                    _c(
                      "div",
                      { staticClass: "login-box-info" },
                      [
                        _c("el-input", {
                          attrs: {
                            disabled: _vm.loading,
                            placeholder: _vm.userNamePlaceholder,
                            size: "medium",
                            "prefix-icon": "el-icon-user-solid",
                          },
                          model: {
                            value: _vm.username,
                            callback: function ($$v) {
                              _vm.username =
                                typeof $$v === "string" ? $$v.trim() : $$v
                            },
                            expression: "username",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "login-box-info" },
                      [
                        _c("el-input", {
                          attrs: {
                            disabled: _vm.loading,
                            type: "password",
                            placeholder: _vm.passwordPlaceholder,
                            size: "medium",
                            "prefix-icon": "el-icon-s-goods",
                          },
                          model: {
                            value: _vm.password,
                            callback: function ($$v) {
                              _vm.password =
                                typeof $$v === "string" ? $$v.trim() : $$v
                            },
                            expression: "password",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm.isShowCode
                      ? _c(
                          "div",
                          { staticClass: "login-box-info login-code" },
                          [
                            _c("el-input", {
                              attrs: {
                                disabled: _vm.loading,
                                type: "text",
                                placeholder: _vm.codePlaceholder,
                                size: "medium",
                                "prefix-icon": "iconfont icon-yanzhengma1",
                              },
                              model: {
                                value: _vm.code,
                                callback: function ($$v) {
                                  _vm.code =
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                },
                                expression: "code",
                              },
                            }),
                            _c("img", {
                              staticClass: "img-code",
                              attrs: { src: _vm.codeUrl },
                              on: { click: _vm.updateCodeUrl },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "div",
                      { staticClass: "login-box-info" },
                      [
                        _c(
                          "el-checkbox",
                          {
                            model: {
                              value: _vm.remember,
                              callback: function ($$v) {
                                _vm.remember = $$v
                              },
                              expression: "remember",
                            },
                          },
                          [_vm._v("记住密码")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "login-box-button" },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              loading: _vm.loading,
                              type: "info",
                              "native-type": "submit",
                              size: "small",
                            },
                            on: { click: _vm.submit },
                          },
                          [_vm._v("登 录")]
                        ),
                      ],
                      1
                    ),
                    _vm.isForgotPassword
                      ? _c(
                          "div",
                          { staticClass: "login-box-forgot-password" },
                          [
                            _c(
                              "span",
                              { on: { click: _vm.toForgotPassword } },
                              [_vm._v("忘记密码?")]
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]
                }),
              ],
              2
            ),
          ],
          1
        ),
        _vm.carouselItem
          ? _c(
              "el-carousel",
              {
                staticClass: "login-carousel",
                attrs: { interval: _vm.carouselInterval },
              },
              _vm._l(_vm.carouselItem, function (item) {
                return _c("el-carousel-item", { key: item }, [
                  _c("img", { attrs: { src: item } }),
                ])
              }),
              1
            )
          : _vm._e(),
      ],
      1
    ),
    _c("div", { staticClass: "login-footer" }, [_vm._t("footer")], 2),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }