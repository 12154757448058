<template>
  <div v-if="isDesign" class="ds-platform-card-item-by-course">
    <div class="cover">
      <dsf-image />
    </div>
    <div class="content">
      <div class="title ellipsis-2">
        <span style="color: #FF3600;font-weight: bold;margin-right: 5px">NEW</span>
        <span>课题课题课题课题课题课题课题课题课题课题课题课题课题课题课题课题课题</span>
      </div>
    </div>
    <div class="footer">
      <span>张三</span>
      <span><i class="iconfont icon-guankanbeifen4" style="font-size: .9em"></i> 1234</span>
    </div>
  </div>
  <div v-else class="ds-platform-card-item-by-course" :title="data['title']" @click="_clickBefore(click)">
    <div class="cover">
      <dsf-image :src="data['cover'] | imgFormat" :error-src="errorSrc" />
    </div>
    <div class="content">
      <div class="title ellipsis-2">
        <span v-if="data['isNew']" style="color: #FF3600;font-weight: bold;margin-right: 5px">NEW</span>
        <span>{{ data['title'] }}</span>
      </div>
    </div>
    <div class="footer">
      <span class="ellipsis" :title="data['teacher']">{{ data['teacher'] ? data['teacher'].replace(/\^/g, ',') : '' }}</span>
      <span><i class="iconfont icon-guankanbeifen4" style="font-size: .9em"></i> {{ data['views'] || 0 }}</span>
    </div>
  </div>
</template>

<script>
export default dsf.component({
  name: "DsfPlatformCardItemByCourse",
  ctrlCaption: "课程卡片",
  mixins: [$mixins.card],
  props: {
    keysMap: {
      type: Array,
      default() {
        return [
          { from: 'cover', to: 'cover', type: 'text', desc: '封面' },
          { from: 'isNew', to: 'isNew', type: 'boolean', desc: '标签' },
          { from: 'title', to: 'title', type: 'text', desc: '课题' },
          { from: 'teacher', to: 'teacher', type: 'text', desc: '授课教师' },
          { from: 'views', to: 'views', type: 'text|number', desc: '浏览量' }
        ];
      }
    },
    // 跳转方式
    clickConfig: {
      type: Object,
      default: null
    }
  },
  methods: {
    click() {
      if (!this.clickConfig) return;
      let { url, state } = this.clickConfig;
      url = this.$replace(url, this.local);
      this.$open({ url, state });
      this.clickAfter();
    }
  }
});
</script>