<template>
  <el-table
    v-if="isDesign"
    ref="table"
    class="dsf-static-list-by-table"
    :class="{ disabledCheckHead: isSingleChecked }"
    :border="true"
    :fit="true"
    :data="data"
    row-key="_id"
    :tree-props="$list.treeProps"
    :height="tableHeight"
    :header-cell-class-name="headerCellClassName"
    stripe
  >
    <template v-if="!isReDrawHander">
      <el-table-column
        v-if="checkboxColumn.show || isEditable"
        :width="checkboxColumn.width"
        type="selection"
        header-align="center"
        align="center"
        fixed="left"
      />
      <el-table-column
        v-if="indexColumn.show"
        :index="$list.indexMethod"
        :resizable="false"
        :width="indexColumnWidth"
        type="index"
        label="序号"
        header-align="center"
        align="center"
        fixed="left"
      />
      <dsf-static-list-by-table-column
        v-for="col in getColumns()"
        :key="col.columnId"
        :is-design="isDesign"
        :columns="col"
        :get-column-sort="getColumnSort"
      >
        <template #default="{ row, col }">
          <div class="editor-td-box">
            <dsf-div-editor
              v-model="row[col.columnId]"
              tag="div"
              style="width: 100%; height: 100%"
              :class="[col.align]"
            />
          </div>
        </template>
      </dsf-static-list-by-table-column>
      <el-table-column
        v-if="isEditable"
        label="操作"
        header-align="center"
        align="center"
        width="180"
      >
        <template v-slot:default="scope">
          <div class="dsf-static-list-by-table-button-row">
            <a @click="insertRow(scope.$index)">插入</a>
            <a v-if="$list.isTreeTable" @click="insertChildRow(scope.row)">
              插入子项
            </a>
            <a @click="removeRow(scope.row)">删除</a>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        v-else-if="operateColumn.show"
        label="操作"
        :width="operateColumn.width"
        header-align="center"
        align="center"
        fixed="right"
      >
        <template v-slot:default="scope">
          <DsfButtonBar
            size="small"
            :max-number="rowButtonsMaxNumber"
            :more-button-text="rowButtonsMoreText"
            :more-button-style="rowButtonsMoreStyle"
            class="ds-no-padding ds-no-drop"
            :is-design="isDesign"
            :buttons="scope._self.rowButtons"
            align="center"
          ></DsfButtonBar>
        </template>
      </el-table-column>
    </template>
  </el-table>
  <el-table
    v-else
    ref="table"
    class="dsf-static-list-by-table"
    :class="{ disabledCheckHead: isSingleChecked }"
    :row-key="'_id'"
    :border="true"
    :fit="true"
    :data="data"
    :tree-props="$list.treeProps"
    :height="tableHeight"
    :default-expand-all="$list.extendDefaultAll"
    :header-cell-class-name="headerCellClassName"
    stripe
  >
    <el-table-column
      v-if="checkboxColumn.show"
      :width="checkboxColumn.width"
      type="selection"
      header-align="center"
      align="center"
      fixed="left"
    />
    <el-table-column
      v-if="indexColumn.show"
      :index="$list.indexMethod"
      :resizable="false"
      :width="indexColumnWidth"
      type="index"
      label="序号"
      header-align="center"
      align="center"
      fixed="left"
    />
    <dsf-static-list-by-table-column
      v-for="(col, $index) in getColumns()"
      :key="$index"
      :columns="col"
      :get-column-sort="getColumnSort"
    >
      <template #default="{ row, col }">
        <span v-html="filterHtml(row[col.columnId])"></span>
      </template>
    </dsf-static-list-by-table-column>
    <el-table-column
      v-if="operateColumn.show"
      label="操作"
      :width="operateColumn.width"
      header-align="center"
      align="center"
      fixed="right"
    >
      <template v-slot:default="scope">
        <DsfButtonBar
          size="small"
          :max-number="rowButtonsMaxNumber"
          :more-button-text="rowButtonsMoreText"
          :more-button-style="rowButtonsMoreStyle"
          class="ds-no-padding ds-no-drop"
          :is-design="isDesign"
          :buttons="scope._self.rowButtons"
          align="center"
        ></DsfButtonBar>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import DsfStaticListByTableColumn from "./staticListByTableColumn";

function columnsFilter(filterResult, cols) {
  let arr = [];
  _.forEach(filterResult, (f) => {
    let c = _.find(cols, (c) => c.id === f.id);
    if (!c) return;
    let width = f.width;
    if (!f.children) {
      arr.push(Object.assign({}, c, { width }));
    } else {
      let children = columnsFilter.call(this, f.children, c.children);
      arr.push(Object.assign({}, c, { width, children }));
    }
  });
  return Vue.observable(arr);
}

function getRealFilterColumns(columns, fun) {
  let arr = [];
  _.forEach(columns, (col) => {
    if (fun(col, true) === false) {
      return;
    }
    if (col.isLast) {
      let obj = _.cloneDeep(col);
      delete obj.children;
      arr.push(obj);
    } else if (col.children.length) {
      let children = getRealFilterColumns.call(
        this,
        col.children,
        fun,
        col.children
      );
      if (children.length) {
        let obj = { children };
        for (let k in col) {
          if (k !== "children") {
            obj[k] = col[k];
          }
        }
        // 对于锁定的列，计算其宽度为子列宽度之和
        if (obj.fixed) {
          let width = 0;
          dsf.deepForEach(
            obj,
            (ch) => {
              width += parseInt(ch.width);
            },
            {
              isLast: true,
            }
          );
          obj.width = width;
        }
        arr.push(obj);
      }
    }
  });
  return Vue.observable(arr);
}
const htmlFitlerEl = document.createElement("div");
export default {
  name: "DsfStaticListByTable",
  components: {
    DsfStaticListByTableColumn,
  },
  mixins: [$mixins.layout],
  inject: {
    $list: {
      default: null,
    },
  },
  props: {
    // 设计器中，对于表格是否启用编辑状态
    isEditable: {
      type: Boolean,
      default: false,
    },
    columns: {
      type: Array,
      default() {
        return [];
      },
    },
    data: {
      type: Array,
      default() {
        return [];
      },
    },
    isReDrawHander: {
      type: Boolean,
      default: false,
    },
    rowButtons: {
      type: Array,
      default() {
        return [];
      },
    },
    renderRowButton: {
      type: Function,
      default: null,
    },
    rowButtonsMaxNumber: {
      type: [Number, String],
      default: 4,
    },
    // 数据行更多按钮形态
    rowButtonsMoreStyle: {
      type: String,
      default: "icon-text",
    },
    // 数据行更多按钮文字
    rowButtonsMoreText: {
      type: String,
      default: "更多",
    },
    tableHeight: {
      type: String,
      default() {
        return "100%";
      },
    },
    stripe: {
      type: Boolean,
      default: true,
    },
    slots: {
      type: Array,
      default() {
        return [
          {
            name: "default",
            controls: [],
          },
        ];
      },
    },
    checkboxColumn: {
      type: Object,
      default() {
        return {};
      },
    },
    indexColumn: {
      type: Object,
      default() {
        return {};
      },
    },
    operateColumn: {
      type: Object,
      default() {
        return {};
      },
    },
    border: {
      type: Boolean,
      default: true,
    },
    fit: {
      type: Boolean,
      default: true,
    },
    isSingleChecked: {
      type: Boolean,
      default: false,
    },
    rowKey: {
      type: [String, Function],
      default: "",
    },
    headerCellClassName: {
      type: [String, Function],
      default: "",
    },
    // 合并行或列的计算方法。
    // Function({ row, column, rowIndex, columnIndex })
    spanMethod: {
      type: Function,
      required: false,
    },
    customerOrder: {
      type: Array,
      default() {
        return [];
      },
    },
    filterResult: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      columnsBak: null,
      updateHeader: false,
      tableWidth: 0,
      tableClientWidth: 0,
      elTable: null,
      multipleSelectionCopy: null,
      operateColumnWidth: this.operateColumn.width,
      operateColumnFixed: false,
      indexWidthCoefficient: 1,
    };
  },
  computed: {
    //不建议在此处调用getRealFilterColumns,这个属性在设计器里有使用
    lastColumns() {
      return this.getLastColumns();
    },
    //列表真实显示的列(只带最后一级层级)
    realColumns() {
      let columns = this.columns;
      return getRealFilterColumns.call(
        this,
        columns,
        this.getColumnRenderResult
      );
    },
    //用户通过列表列过滤后的结果
    filterColumns() {
      let cols = this.columns;
      let filterResult = this.filterResult;
      if (this.filterResult.length <= 0) {
        return cols;
      } else {
        return columnsFilter.call(this, filterResult, cols);
      }
    },
    //列表真实显示的列(带父子层级关系)
    realFilterColumns() {
      return getRealFilterColumns.call(
        this,
        this.filterColumns,
        this.getColumnRenderResult
      );
    },
    //获取列树形结构,仅排除掉隐藏列和二次开发的隐藏列
    columnsTree() {
      return getRealFilterColumns.call(
        this,
        this.columns,
        this.getColumnRenderResult
      );
    },
    multipleSelection: {
      get() {
        if (this.$list) {
          return this.$list.multipleSelection;
        } else {
          return this.multipleSelectionCopy;
        }
      },
      set(val) {
        if (this.$list) {
          this.$list.multipleSelection = val;
        } else {
          this.multipleSelectionCopy = val;
        }
      },
    },
    isTreeColumns() {
      let mark = false;
      let cols = this.realFilterColumns;
      for (let i = 0; i < cols.length; i++) {
        if (!cols[i].isLast) {
          mark = true;
          break;
        }
      }
      return mark;
    },
    indexColumnWidth() {
      return this.indexColumn.width * this.indexWidthCoefficient;
    },
  },
  created() {
    _.each(this.lastColumns, (it) => {
      it.designWidth = it.width;
    });
  },
  mounted() {
    this.elTable = this.$refs.table;
    if (!this.$list.isDesign) {
      this.columnsBak = dsf.mix(true, [], this.columns);
    }
  },
  updated() {
    this.doLayout();
  },
  methods: {
    insertRow(index) {
      this.data.splice(index + 1, 0, {
        _id: dsf.uuid(8),
      });
    },
    insertChildRow(row) {
      // row = this.data[index];
      if (row) {
        if (!row.children) {
          this.$set(row, "children", []);
        }
        row.children.push({
          _id: dsf.uuid(8),
        });
      }
    },
    removeRow(row) {
      // this.data.splice(index, 1);
      dsf.deepForEach(this.data, (a, b, c, d) => {
        if (a === row) {
          if (d) {
            dsf.array.remove(d.children, a);
          } else {
            dsf.array.remove(this.data, a);
          }
        }
      });
    },
    getColumnRenderResult(col) {
      let result = true;
      if (!col.hide) {
        result = this.renderHeader(col) !== false;
      } else {
        result = false;
      }
      return result;
    },
    //获取列头中各个单元格最大的单元格
    getLastColumns(column) {
      let arr = [];
      this.deep((c) => {
        if (!c.children || !c.children.length) {
          c.isLast = true;
          arr.push(c);
        } else {
          c.columnId = "";
          c.isLast = false;
        }
      }, column);
      return arr;
    },
    deep(callback, column) {
      var fn = function (columns, level) {
        let columnIndex = 0;
        _.each(columns, (c, i) => {
          callback && callback(c, i, columnIndex, level);
          fn(c.children, level + 1);
          columnIndex++;
        });
      };
      let cols = column ? column : this.columns;
      cols = dsf.type(cols) !== "array" ? [cols] : cols;
      fn(cols, 0);
    },
    doLayout() {
      this.elTable = this.$refs.table;
      this.elTable?.doLayout();
    },
    resize() {
      this.doLayout();
      if (!this.$list.isDesign) {
        this.$nextTick(() => {
          //判断如果有操作列，且数据列宽度综合小于表格总宽度则修改操作列的宽度填充，避免出现空白
          if (this.elTable) {
            this.resetTableWidth();
            let sumWidth = 0;
            let cols = _.map(this.lastColumns, "columnId");
            let tableEl = this.elTable.$el.querySelector(
              ".el-table__header-wrapper>table"
            );
            let thead = tableEl.querySelector("thead");
            _.each(this.elTable.columns, (it, i) => {
              if (cols.indexOf(it.columnKey) >= 0) {
                let width = it.realWidth || it.width;
                if (dsf.isNumber(width)) {
                  sumWidth += width;
                } else {
                  let colEl = thead.querySelector("." + it.id);
                  if (colEl) {
                    sumWidth += colEl.offsetWidth;
                  }
                }
              }
            });
            if (sumWidth > 0) {
              sumWidth += this.getIndexWidth();
              sumWidth += this.getCheckColumnWidth();
              if (this.operateColumn.show) {
                sumWidth += parseFloat(this.operateColumn.width || 100);
              }
              if (this.tableClientWidth > sumWidth) {
                this.operateColumnFixed = false;
              } else {
                this.operateColumnFixed = "right";
              }
            }
            //处理固定列宽度
            if (
              this.elTable.fixedColumns &&
              this.elTable.fixedColumns.length > 0
            ) {
              _.each(this.elTable.fixedColumns, (it, i) => {
                if (it.children && it.children.length > 0) {
                  let leaf_arr = _.filter(
                    this.elTable.columns,
                    (col) => col.id.indexOf(it.id + "_") == 0
                  );
                  let s = 0;
                  _.each(leaf_arr, (it) => {
                    if (dsf.isNumber(it.realWidth || it.width)) {
                      s += it.realWidth || it.width;
                    }
                  });
                  it.realWidth = s;
                }
              });
            }
            this.$nextTick(() => {
              this.doLayout();
            });
          }
        });
      }
    },
    // elTable 多选监听(单个选项被勾选)
    handleSelection(val, row) {
      if (this.isSingleChecked) {
        let isChecked = !!_.find(val, (it) => it._id === row._id);
        let multipleSelection = isChecked ? [row] : [];
        this.multipleSelection = multipleSelection;
        this.$emit("select", multipleSelection, row);
      } else {
        this.$emit("select", val, row);
      }
    },
    // elTable 多选监听(全选)
    handleSelectionAll(val) {
      if (this.isSingleChecked) {
        if (!this.multipleSelection && this.data.length) {
          val = [this.data[0]];
          this.toggleRowSelection(this.data[0], true);
        } else {
          val = this.multipleSelection ? [this.multipleSelection] : [];
        }
      }
      this.$emit("select-all", val);
    },
    headerDragend(newWidth, oldWidth, column, event) {
      let col = _.find(this.lastColumns, (it) => {
        return it.columnId == column.columnKey;
      });
      let colsMap = _.keyBy(this.realFilterColumns, "columnId");
      // newWidth = newWidth > 60 ? newWidth : 60;
      if (col.designWidth.endsWith("%")) {
        colsMap[column.columnKey].width = this.pxTopercentage(
          newWidth,
          this.tableClientWidth
        );
      } else {
        if (colsMap[column.columnKey]) {
          colsMap[column.columnKey].width = newWidth;
        }
      }
      this.$nextTick(() => {
        this.$emit("table-column-resize", this.realFilterColumns);
        this.resize();
      });
    },
    rowDblClickHandler(row, column, event) {
      // this.$emit("row-dblclick", row, column, event);
      let tr = event.currentTarget;
      let buttonbarEl = $(tr).find("td:last div[ctrl_type='dsf.buttonbar']");
      if (buttonbarEl.length > 0) {
        let buttonbar = buttonbarEl.get(0).__vue__;
        let dblClickButtons = _.filter(buttonbar.buttons, (btn) => {
          return btn.isDblClick;
        });
        if (dblClickButtons.length > 0) {
          buttonbar.triggerButtonClick(dblClickButtons[0]);
        }
      }
    },
    tableSort(args) {
      this.$emit("sort-change", args);
    },
    getRowButtons(buttons) {
      return dsf.mix(true, [], buttons || []);
    },
    clearSelection() {
      this.elTable?.clearSelection();
    },
    toggleRowSelection(row, selected) {
      this.elTable?.toggleRowSelection(row, selected);
      this.$nextTick(() => {
        this.handleSelection(this.elTable?.selection, row);
      });
    },
    toggleAllSelection() {
      this.elTable?.toggleAllSelection();
    },
    getColumnSort(column) {
      if (this.customerOrder) {
        let result = _.filter(this.customerOrder, (col) => {
          if (column.columnId == col.column) {
            return true;
          }
        });
        if (result.length > 0) {
          return true;
        }
      }
      return false;
    },
    handleSelectionChange(val) {
      if (this.isSingleChecked) {
        if (val.length > 1) {
          this.clearSelection();
        }
        if (this.multipleSelection) {
          this.toggleRowSelection(this.multipleSelection, true);
        }
      } else {
        this.multipleSelection = val;
      }
    },
    //获取索引列宽度
    getIndexWidth() {
      if (this.indexColumn.show) {
        if (this.elTable) {
          let indexColumnWidth = null,
            index = 1;
          if (!this.checkboxColumn.show) {
            index = 0;
          }
          indexColumnWidth = this.elTable.columns[index];
          return indexColumnWidth.width;
        } else {
          return this.indexColumnWidth;
        }
      } else {
        return 0;
      }
    },
    //获取选择列宽度
    getCheckColumnWidth() {
      if (this.checkboxColumn.show) {
        if (this.elTable) {
          let checkboxColumn = this.elTable.columns[0];
          return checkboxColumn.width;
        } else {
          return this.checkboxColumn.width;
        }
      } else {
        return 0;
      }
    },
    headerReset() {
      this.$list.$emit("update:columns", this.columnsBak);
      this.headerReload();
    },
    headerReload() {
      this.updateHeader = !this.updateHeader;
      this.$nextTick(() => {
        this.updateHeader = !this.updateHeader;
        this.resize();
      });
    },
    getContentWidth(total) {
      let indexColumnWidth = this.getIndexWidth();
      let checkColumnWidth = this.getCheckColumnWidth();
      let operateColumnWidth = this.operateColumn.show
        ? this.operateColumn.width
        : 0;
      let tw = total - indexColumnWidth - checkColumnWidth - operateColumnWidth;
      return tw;
    },
    percentageToPx(width, total, isMark) {
      let realWidth = width;
      if (
        dsf.type(realWidth) == "string" &&
        realWidth &&
        realWidth.endsWith("%")
      ) {
        let w = parseFloat(realWidth.replace("%", "")) / 100;
        let tw = isMark ? this.getContentWidth(total) : total;
        realWidth = parseFloat(tw) * w;
      }
      return realWidth;
    },
    pxTopercentage(width, total) {
      return (width / total) * 100 + "%";
    },
    isPercentage(c) {
      let col = _.find(this.lastColumns, (it) => {
        return it.columnId == c.columnKey;
      });
      if (col) {
        //如果是百分比宽度
        if (col.designWidth.endsWith("%")) {
          return true;
        }
      }
      return false;
    },
    resetTableWidth() {
      let wrap = this.elTable.$el.querySelector(".el-table__body-wrapper");
      this.tableWidth = wrap.offsetWidth;
      this.tableClientWidth = wrap.clientWidth;
    },
    getColumns() {
      let arr = [];
      if (this.$list.isTreeTable) {
        dsf.deepForEach(this.columns, (col) => {
          if (col.isLast) {
            arr.push(col);
          }
        });
        if (arr.length > 0) {
          this.$set(arr[0], "columnType", "extend");
        }
      }
      return this.columns;
    },
    filterHtml(html) {
      htmlFitlerEl.innerHTML = html || "";
      return htmlFitlerEl.innerText || "";
    },
  },
  watch: {
    "$system.setting.fontSize": {
      handler(v, ov) {
        let x = ["bigFont_1", "bigFont_2", "bigFont_3"];
        if (x.indexOf(v) >= 0) {
          this.indexWidthCoefficient = 1.33333;
        } else {
          this.indexWidthCoefficient = 1;
        }
      },
      immediate: true,
    },
    "elTable.bodyWidth": {
      handler(v, ov) {
        if (this.elTable) {
          this.resetTableWidth();
        }
      },
      immediate: true,
    },
    // tableClientWidth: {
    //   handler(v, ov) {
    //     this.resize();
    //   }
    // },
    // "elTable.columns": {
    //   handler(v, ov) {
    //     this.resize();
    //   }
    //   // deep:true,
    // },
    filterColumns: {
      handler() {
        this.resize();
      },
      deep: true,
    },
  },
};
</script>