var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "ds-control ds-banner ds-no-padding",
      style: {
        "padding-bottom": _vm.rootHeight,
        "padding-top": "calc(100% - " + _vm.h + ")",
      },
      attrs: { id: _vm.id },
    },
    [
      _c(
        "el-carousel",
        {
          ref: "swipe",
          attrs: {
            arrow: _vm.showButton,
            "indicator-position": _vm.showIndicators,
            direction: _vm.vertical ? "vertical" : "horizontal",
            autoplay: _vm.isAutoPlay,
            interval: _vm.autoPlay,
            trigger: _vm.indicatorTrigger,
            type: _vm.type,
            height: _vm.h,
          },
          on: {
            change: function (index) {
              _vm.activeIndex = index
            },
          },
        },
        _vm._l(_vm.images, function (item, index) {
          return _c("el-carousel-item", { key: "ds-banner-item-" + index }, [
            _c(
              "div",
              {
                class: ["ds-banner-item", "" + index],
                on: {
                  click: function (ev) {
                    return _vm.onSwipeClick(ev, item, index)
                  },
                },
              },
              [
                _vm._t(
                  "default",
                  function () {
                    return [
                      _c("dsf-image", {
                        staticClass: "el-carousel-item-image",
                        style: { "border-radius": _vm.borderRadius },
                        attrs: { src: item.image, "error-src": _vm.errorSrc },
                      }),
                      _vm._t("desc", null, null, { row: item, $index: index }),
                    ]
                  },
                  null,
                  { row: item, $index: index }
                ),
              ],
              2
            ),
          ])
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }