<template>
  <div v-show="loaded" class="full-view">
    <template v-if="!mobile">
      <component :is="'PreviewLayout'" v-if="loaded" class="page-head-font-fixed" />
    </template>
    <template v-else>
      <div class="mobile-view demo">
        <div class="mobile-view-content">
          <component :is="'PreviewLayout'" v-if="loaded" class="page-head-font-fixed" />
        </div>
      </div>
    </template>
  </div>
</template>
<script>
export default {
  name: "DsfPreView",
  props: {
    mobile: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      layout: {},
      loaded: false,
      templateStr: "",
      domTree: null
    };
  },
  created() {
    // this.loginTeas();
  },
  mounted() {
    dsf.global.$isMobile = this.mobile;
    this.loadLayout();
  },
  destroyed() {
    if (this.domTree) {
      this.domTree.$destroy();
    }
  },
  methods: {
    loadLayout() {
      let context = dsf.storage.session.get("layoutContent"); // window.localStorage.getItem("layoutContent");
      let dom = $("<context>" + context + "</context>");
      let scriptNode = dom.children("script[id='layout']");
      let code = ["var layout=" + $.trim(scriptNode.html()) + ";", "return layout"];
      let getData = new Function(code.join("\n"));
      let layoutData = dsf.fillComponentDefaultProps(getData());
      layoutData["module-name"] = layoutData.context.B + "." + layoutData.context.M + "." + layoutData.name;
      let result = dsf.designer.convertTemplateAndControlData(layoutData, false, true);
      let tpl = result.tpl;
      let pageRender = Vue.compile(tpl);
      // let pageRender = dsf.global.Vue.compile(tpl);
      let options = getViewMixin.call(this, layoutData, result.props, pageRender.render);
      // let DomTree = Vue.extend(options);
      // this.domTree = new DomTree().$mount(this.$refs.pageloader);
      // Vue.component('PreviewLayout',options)
      this.$options.components["PreviewLayout"] = options;
      this.loaded = true;
      this.$nextTick(() => {
        //重置页面尺寸
        dsf.resizeComponent();
      });
    }
  }
};

function getViewMixin(layout, props, renderFn) {
  let base = {
    render: renderFn,
    name: "PreviewLayout",
    inject: {
      $parentView: {
        default: null
      },
      $frame: {
        default: null
      }
    },
    provide() {
      return {
        $vm: this,
        $parentView: this,
        $isPreView:true
      };
    },
    mixins: [],
    data() {
      return {
        ...$mixins.layoutView.data.call(this),
        $$controls: props,
        isPreView: true,
        _isLoaded: true,
        loading: false
      };
    },
    props: {
      nameSpace: {
        type: String,
        default: layout.context.B + "." + layout.context.M
      },
      pageName: {
        type: String,
        default: layout.name
      },
      queryString: {
        type: Object,
        default() {
          return {};
        }
      },
      choiceParams: {
        type: Object,
        default() {
          return null;
        }
      }
    },
    computed: {
      $staticValue() {
        return this?.context?.$$staticValue || {};
      },
      $page() {
        return this?.$refs?.page;
      },
      $viewData() {
        return this?.context?.$$viewData || {};
      },
      $viewInitData() {
        return this?.context?.$$viewInitData;
      },
      $enum() {
        return this?.context?.$$enum;
      },
      $dataKey() {
        let params = this.queryString;
        return params ? params.id : "";
      },
      $controls() {
        return this._data.$$controls;
      },
      $isLoaded() {
        return this.$data._isLoaded;
      },
      $keepAlive() {
        return false;
      }
    },
    beforeCreate() {},
    created() {
      if (this.$parentView && this.$frame) {
        let name = this.$frame.caption || this.$frame.$attrs.id;
        if (name) {
          this.$parentView.frames[this.$frame.caption].content = this;
        }
      }
    },
    mounted() {
      this.$data._isLoaded = true;
      this.$nextTick(() => {
        window.preViewRef = this.$refs;
        window.__preView = this;
        let win = window?.frameElement?.contentWindow || window;
        if (win && win.name == "appDemo") {
          win.parent.postMessage({
            title: this.$page.title,
            type: "postTitle"
          });
        }
      });
    },
    beforeDestroy() {
      delete window.preViewRef;
      delete window.__preView;
    },
    methods: {
      $clone(obj) {
        return JSON.parse(JSON.stringify(obj));
      },
      $getDefaultValue() {},
      $dataFormat(k, params, v) {
        if (params) {
          let filter = dsf.global.Vue.filter(k);
          if (filter) {
            return filter(v, params);
          }
        }
        return v;
      },
      $addFiled() {}
    }
  };
  if (dsf.designer.isFormPage(layout)) {
    base.mixins.push(window.$mixins.form);
  }
  if (window.$mixins[layout.pageType]) {
    base.mixins.push(window.$mixins[layout.pageType]);
  }
  return base;
}
</script>
