<template>
  <div class="header-menu" :style="menuStyle">
    <div v-show="showBt" class="header-menu-bt __left" :disabled="disabledLeft" @click="doPagination(1)">
      <i class="iconfont icon-arrow-left2"></i>
    </div>
    <div ref="box" class="header-menu-box">
      <div ref="content" class="header-menu-content">
        <div
          v-for="item in menuList"
          :key="item.menu_id"
          :class="{ active: item.menu_id === activeKey }"
          class="header-menu-item"
          @click="linkTo(item)"
          @mouseenter="$emit('show-menu-children', item, $event)"
          @mouseleave="$emit('hide-menu-children', item, $event)">
          <div class="header-menu-item-box">
            <i :class="[ item.menu_icon || 'icon-shezhi' ]" class="iconfont"></i>
            <div>{{ item.menu_name }}</div>
            <span v-if="(!item.children || !item.children.length) && item['menu_code']" v-show="badge[item['menu_code']]" class="ds-page-home-badge" :class="[homeRoot.badgeStyle]">{{ badge[item['menu_code']] }}</span>
          </div>
        </div>
      </div>
    </div>
    <div v-show="showBt" class="header-menu-bt __right" :disabled="disabledRight" @click="doPagination(-1)">
      <i class="iconfont icon-arrow-right2"></i>
    </div>
    <slot></slot>
  </div>
</template>

<script>
import menuUtils from "_platform/pc/home/menu/utils";

let timer = false;

export default {
  name: 'DsfDefaultHomeMenuBar',
  inject: ['homeRoot'],
  props: {
    menuList: {
      type: Array,
      default() {
        return []
      }
    },
    activeKey: {
      type: String,
      default: null
    },
    width: {
      type: Number,
      default: 0
    },
    mini: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showBt: false,
      disabledLeft: false,
      disabledRight: false,
    }
  },
  computed: {
    // 角标
    badge() {
      return this.homeRoot.menuSubscript;
    },
    menuStyle() {
      let res = {
        'max-width': this.width + 'px'
      };
      if (!this.showBt) {
        res.padding = 0;
      }
      return res;
    }
  },
  watch: {
    'dsf.themeConfig.setting.fontSize'() {
      this.reSize();
    },
    'width'() {
      this.reSize();
    }
  },
  methods: {
    reSize(){
      this.$nextTick(() => {
        let boxw = this.$refs.box.offsetWidth;
        let conw = this.$refs.content.scrollWidth;
        this.showBt = conw > boxw;
        if (this.showBt) {
          this.updateBtStatus();
        }
      });
    },
    linkTo(item) {
      if (item.menu_id === this.activeKey) return;
      menuUtils.openPage.call(this.homeRoot, menuUtils.findMenu(item));
    },
    doPagination(type) {
      if (timer) {
        return;
      }
      let itemList = this.$refs.content.querySelectorAll(".header-menu-item");
      let itemw = itemList[0].offsetWidth;
      let boxw = this.$refs.box.offsetWidth;
      let conw = this.$refs.content.scrollWidth;
      let scrollLeft = this.$refs.box.scrollLeft;
      let xx = conw - boxw - scrollLeft;
      let indexRight = (scrollLeft + boxw) / itemw;
      let indexLeft = scrollLeft / itemw;
      // 向右
      if (type < 0 && xx > 0) {
        timer = true;
        let inline = "start";
        let index = Math.floor(indexRight);
        if (index === indexLeft) index++;
        if (index >= itemList.length) {
          inline = "end";
          index = itemList.length - 1;
        }
        itemList[index].scrollIntoView({
          behavior: "smooth",
          inline
        });
        setTimeout(() => {
          this.updateBtStatus();
          timer = false;
        }, 500);
      }
      // 向左
      else if (type > 0 && scrollLeft > 0) {
        timer = true;
        let inline = "end";
        let index = Math.floor(indexLeft);
        if (index + 1 === indexRight) {
          index--;
        }
        if (index < 0) {
          inline = "start";
          index = 0;
        }
        itemList[index].scrollIntoView({
          behavior: "smooth",
          inline
        });
        setTimeout(() => {
          this.updateBtStatus();
          timer = false;
        }, 500);
      }
    },
    updateBtStatus() {
      let boxw = this.$refs.box.offsetWidth;
      let conw = this.$refs.content.scrollWidth - 2;
      let scrollLeft = this.$refs.box.scrollLeft;
      this.disabledLeft = false;
      this.disabledRight = false;
      if (scrollLeft < 2) {
        this.disabledLeft = true;
      } else if (boxw + scrollLeft >= conw) {
        this.disabledRight = true;
      }
    }
  }
}
</script>