<template>
  <div
    v-if="!item.isHide"
    class="ds-nav-catalogue-item"
    :class="{choose: !item._isHide}"
    :level="item.level">
    <div
      class="label"
      :class="{required: item.required}"
      :active="catalogueRoot.active === item || catalogueRoot.rootNode === item"
      :title="item.required ? '必选项' : ''"
      @click="catalogueRoot.hideClick(item)">
      <span class="ellipsis">{{ item['_name'] }}</span>
      <i class="iconfont icon-order" @click.stop></i>
    </div>
    <div v-if="item['children'] && item['children'].length" class="children">
      <draggable v-model="item['children']" class="dragArea" v-bind="catalogueRoot.dragOptions">
        <nav-catalogue-item-for-editor v-for="item2 in item['children']" :key="item2._id" :item="item2" />
      </draggable>
    </div>
  </div>
</template>

<script>
export default {
  name: "navCatalogueItemForEditor",
  inject: ['catalogueRoot'],
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data() {
    return {

    }
  },
  methods: {
  }
};
</script>