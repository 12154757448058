<!--
 * @Editor: chenqy
 * @Description: 外壳 --- 老师端
 * @Date: 2021-09-07 16:03:49
 * @LastEditors: Chenqy
 * @LastEditTime: 2022-01-14 16:20:57
-->
<template comments>
  <div class="dsf-sync-box">
    <!-- <el-button @click="handleStartVoice">开始</el-button>
    <el-button @click="handleEndVoice">停止</el-button> -->
    <!--hidden-->
    <div class="dsf-sync-header">
      <div class="dsf-sync-header-content" ref="header">
        <div class="dsf-sync-header-left">
          <div class="title">会议名单 ({{ peopleList.length }}人)</div>
        </div>
        <div class="dsf-sync-header-right">
          <operation-nav
            :fileViewObj="fileView"
            ref="fileComponent"
          ></operation-nav>
        </div>
      </div>
      <div class="dsf-full-drop" @click="handleDropTool"><div class="icon-box" ref="dropIcon"><i class="icon iconfont icon-arrow-down"></i></div></div>
    </div>
    <div class="dsf-sync-body">
      <!--hidden-->
      <div class="dsf-sync-left">
        <div class="dsf-sync-left-slot" v-if="isHeaderSlots">
          <DsfVirtualScroll>
            <slot name="header"></slot>
          </DsfVirtualScroll>
        </div>
        <div
          class="dsf-sync-left-item"
          :style="{ height: isHeaderSlots ? 'calc(100% - 300px)' : '100%' }"
        >
          <div class="dsf-sync-left-item--title">
            <div
              :class="{ 'left-active': chooseOnline }"
              @click="chooseOnline = true"
            >
              在线（{{ onlineList.length }}）
            </div>
            <div
              :class="{ 'left-active': !chooseOnline }"
              @click="chooseOnline = false"
            >
              离线（{{ offlineList.length }}）
            </div>
          </div>
          <div class="dsf-sync-left-item--search">
            <el-input
              v-model="searchName"
              class="dsf-sync-seach"
              placeholder="请输入姓名"
              prefix-icon="el-icon-search"
              @keyup.enter.native="handleSearch"
              @blur="handleSearch"
            />
          </div>
          <div class="dsf-sync-left-item--list">
            <DsfVirtualScroll>
              <div
                v-for="(item, index) in chooseOnline ? onlineList : offlineList"
                :key="index"
                class="people-single"
                v-show="!item.hide"
              >
                <div class="people-box">
                  <div class="people-img">
                    <img
                      class="people-img-box"
                      crossorigin
                      :src="item.photo"
                      v-if="item.photo"
                    />
                    <div
                      class="people-img-box"
                      v-else
                      :style="{ backgroundColor: randomColors[index % 10] }"
                    >
                      {{ item.name.substring(0, 1) }}
                    </div>
                  </div>
                  <div class="people-name">{{ item.name }}</div>
                </div>
                <div class="people-status">
                  <i
                    class="iconfont"
                    :class="{
                      'icon-sidebar-speaker':
                        item.status.value === '3' || item.status.value === '4',
                      'icon-flash': item.status.value === '4',
                    }"
                  ></i>
                </div>
                <div
                  v-if="item.status.value === '3' || item.status.value === '4'"
                  class="people-main"
                  @click="
                    handleChangeStatus(item.personId, item.status.value == '3')
                  "
                >
                  {{ item.status.value == "3" ? "取消主讲" : "设为主讲" }}
                </div>
              </div>
            </DsfVirtualScroll>
          </div>
        </div>
      </div>
      <div class="dsf-sync-right">
        <div class="dsf-sync-content">
          <iframe
            :src="syncParam.url"
            width="1694"
            height="847"
            frameborder="0"
            id="syncScreen"
          ></iframe>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import operationNav from "./operationNav.vue";
import FileReview from "./createPreFile.js";
import ratioMixin from "./ratioMixin.js";
import voiceMixin from "./voiceMixin.js";
export default {
  name: "DsfSyncScreenTeacher",
  components: { operationNav },
  mixins: [ratioMixin, voiceMixin],
  computed: {
    isHeaderSlots() {
      return !!this.$slots.header;
    },
    isSperker() {
      return this.syncParam.personId === this.speakerId;
    },
  },
  created() {
    let { roomId, url, personId } = this.$route.query;
    this.handleRandomColor();
    this.syncParam.roomId = roomId;
    this.syncParam.url = url;
    this.syncParam.personId = personId || "1";
    this.managerId = personId;
    this.socket = dsf.ws();
    // this.socket.$on("/syncscreen/ws/data", this.syncParam, function (re) {});
  },
  mounted() {
    let that = this;
    this.$nextTick(() => {
      // 获取用户列表
      that.socket.$on(
        "/syncscreen/ws/personList",
        that.syncParam,
        function (re) {
          if (dsf.type(re.success) === "boolean" && !re.success) {
            dsf.layer.message(re.message, false);
          } else if (re.data) {
            if (that.peopleList.length) {
              that.peopleList.push(...re.data);
            } else {
              that.peopleList = re.data;
            }
            let off = [];
            let on = [];
            that.peopleList.forEach((item) => {
              if (item.status.value == 2) {
                off.push(item);
              } else {
                on.push(item);
              }
            });
            that.offlineList = off;
            that.onlineList = on;
            that.handleGetInfo(re.data);
            let obj = that.handleUpdatePersonList(re.data);
            if (obj.hasOnline.length && that.isSperker && !obj.hasSperker) {
              that.handleSyncSocket("reviewSync", {pushPersonIds: obj.hasOnline});
            }
          }
        }
      );
    });
  },
  data() {
    return {
      socket: null,
      syncParam: {
        endurance: false, // 是否记录视频
      },
      searchName: "", // 搜索名字
      // 人员列表
      peopleList: [],
      iframeStatus: "unload",
      // 随机颜色
      randomColors: [],
      managerId: "", // 管理员ID
      speakerId: "", // 主讲人ID
      player: null,
      parentDom: null, // iframe对象
      fileView: null,
      chooseOnline: true, // 在线人员列表
      onlineList: [],
      offlineList: [],
    };
  },
  watch: {
    isSperker: {
      handler: function (value) {
        this.$nextTick(() => {
          if (value) {
            this.handleSyncSocket("startSync");
            this.handleStartVoice();
          } else {
            this.handleSyncSocket("closeRecord");
            this.handleEndVoice();
          }
        });
      },
      immediate: true,
    },
  },
  methods: {
    // 生成随即色
    handleRandomColor() {
      for (let i = 0; i < 10; i++) {
        let temp = "000000";
        let color = `#${(~~(Math.random() * 0xffffff)).toString(16)}`;
        color += color.length < 7 ? temp.substr(0, 7 - color.length) : "";
        this.randomColors.push(color);
      }
    },
    // 根据姓名检索人员
    handleSearch(ele) {
      let name = ele.target.value;
      let soueceList = this.chooseOnline ? this.onlineList : this.offlineList;
      let f = soueceList.map((item) => {
        if (name === "") {
          item.hide = false;
        } else if (item.name.includes(name)) {
          item.hide = false;
        } else {
          item.hide = true;
        }
        return item;
      });
      if (this.chooseOnline) {
        this.onlineList = f;
      } else {
        this.offlineList = f;
      }
    },
    // 获取主讲人/管理员信息
    handleGetInfo(data) {
      for (let i = 0; i < data.length; i++) {
        if (data[i].status.value === "3") {
          this.speakerId = data[i].personId;
        }
      }
    },
    handleUpdatePersonList(data) {
      let hasOnLine = []; // 在线人员
      let escSperker = []; // 退出人员
      let hasSperker = false; // 是否含有主讲人
      for (let i = 0; i < data.length; i++) {
        if (data[i].status.value === "1") {
          hasOnLine.push(data[i].personId);
        }
        // 主动申请退出
        if (data[i].status.value === "5") {
          escSperker.push({
            personId: data[i].personId,
            status: "1",
          });
        }
        // 主讲人状态变更
        if (
          (data[i].status.value === "2" || data[i].status.value === "1") &&
          data[i].personId === this.speakerId
        ) {
          escSperker.push({
            personId: data[i].personId,
            status: "2",
          });
        }
        if (data[i].status.value === "3") {
          hasSperker = true;
        }
      }
      if (escSperker.length) {
        escSperker.push({
          personId: this.managerId,
          status: "3",
        });
        this.speakerId = this.managerId;
        // this.handleUpdateState(this.managerId, "3");
      }
      return {
        hasOnline: hasOnLine,
        hasSperker,
      };
    },
    // 更新人员状态
    handleUpdateState(personId, status) {
      for (let i = 0; i < this.peopleList.length; i++) {
        if (personId === this.peopleList[i].personId) {
          // 退出的人为主讲人 -> 权限交给教师端
          if (this.peopleList[i].status.value === "3" && status === "2") {
            this.peopleList[0].status.value = "3";
          }
          let obj = Object.assign({}, this.peopleList[i]);
          obj.status.value = status;
          this.$set(this.peopleList, i, obj);
        }
      }
      let on = [];
      let off = [];
      this.peopleList.forEach((item) => {
        if (item.status.value == 2) {
          off.push(item);
        } else {
          on.push(item);
        }
      });
      this.offlineList = off;
      this.onlineList = on;
    },
    /**
     *  开启同屏推送
     *  {string} status 同屏状态 [startSync, reviewSync, closeRecord, authTranslate]
     */
    handleSyncSocket(status, option = {}) {
      // 发送被同屏页面连接socket必要参数
      let parentDom = document.querySelector("#syncScreen");
      this.parentDom = parentDom;
      let that = this;
      let params = Object.assign({}, this.syncParam, option);
      if (parentDom instanceof HTMLIFrameElement) {
        if (this.iframeStatus === "unload") {
          parentDom.onload = function () {
            that.iframeStatus = "completed";
            // 添加文件预览组件
            that.fileView = new FileReview("file", {
              context: parentDom.contentWindow,
            });
            window.fileViewObj = that.fileView
            // 添加画板功能菜单状态观察器
            that.handleWatchFileComponent();
            // 添加播放器
            let rect = parentDom.getBoundingClientRect();
            let player = document.createElement("div");
            player.style.minWidth = "100%";
            player.style.minHeight = "100%";
            player.style.width = `${rect.width}px`;
            player.style.height = `${rect.height}px`;
            player.id = "teacher-content";
            player.style.position = "absolute";
            player.style.top = "0";
            player.style.left = "0";
            player.style.zIndex = "-1";
            player.style.background = "#fff";
            player.style.opacity = "0";
            player.style.overflow = "hidden";

            let playerContent = document.createElement("div");
            playerContent.style.width = "100%";
            playerContent.style.height = "100%";
            playerContent.id = "teacher-player";
            player.appendChild(playerContent);
            parentDom.contentWindow.document.documentElement.appendChild(
              player
            );
            parentDom.contentWindow.postMessage({
              type: status,
              params: params,
            });
            // 用户状态变更
            that.socket.$on(
              "/syncscreen/ws/personStatus",
              that.syncParam,
              function (re) {
                if (re?.data?.persons) {
                  // 根据状态判断是否要重新推送数据
                  let obj = that.handleUpdatePersonList(re.data.persons);
                  if (obj.hasOnline.length && that.isSperker && !obj.hasSperker) {
                    that.handleSyncSocket("reviewSync", {pushPersonIds: obj.hasOnline});
                  }
                  // 更改列表状态
                  re.data.persons.forEach((item) => {
                    that.handleUpdateState(item.personId, item.status.value);
                  });
                  // 复现之前用户操作的内容
                  if (that.isSperker && re.data?.data?.dataUrl) {
                    let tdata = re.data?.data;
                    if (tdata.dataUrl && Object.keys(tdata.dataUrl).length) {
                      window.canvasDraw.utilsRestoreCanvas(tdata.dataUrl);
                    }
                    if (tdata.sourceType) {
                      let render = async function () {
                        let {
                          currentPage,
                          sourceList,
                          sourceType,
                          scrollTop,
                          timeLine,
                        } = tdata;
                        await that.fileView.renderContent(
                          sourceList,
                          tdata.dataFile,
                          {
                            type: sourceType,
                            currentPage,
                            scrollTop,
                            timeLine,
                          }
                        );
                        // 赋于画板操作
                        that.$refs.fileComponent.handleFileView(
                          false,
                          sourceType,
                          currentPage - 1
                        );
                        if (
                          tdata.dataFile &&
                          Object.keys(tdata.dataFile).length
                        ) {
                          window.fileCanvasDraw.utilsRestoreCanvas(
                            tdata.dataFile,
                            currentPage - 1
                          );
                        }
                      };
                      render();
                    }
                  }
                }
              }
            );
          };
        } else {
          parentDom.contentWindow.postMessage({
            type: status,
            params: params,
          });
        }
      }
    },
    /**
     * 人员状态变更
     * {String} personId 状态变更ID
     * {Boolean}} isCancel 是否为取消主讲
     */
    handleChangeStatus(personId, isCancel) {
      let prevSpeakerId = this.speakerId;
      if (this.speakerId === personId && !isCancel) {
        return;
      }
      let params = [
        {
          personId,
          status: isCancel ? "1" : "3",
        },
      ];
      if (this.speakerId && !isCancel) {
        params.push({
          personId: this.speakerId,
          status: "1",
        });
        this.speakerId = personId;
      } else {
        params.push({
          personId: this.managerId,
          status: "3",
        });
        this.speakerId = this.managerId;
      }
      params.forEach((item) => {
        this.handleUpdateState(item.personId, item.status);
      });

      let paramss = {
        pushPersonIds: personId,
        data: params,
      };
      let fn = function () {
        let f = false;
        for (let i = 0; i < params.length; i++) {
          if (params[i].personId === this.managerId) {
            f = true;
          }
        }
        return f;
      };
      // 主讲人由教师权限 => 学生权限 则发送最后操作信息
      if (personId !== this.managerId && !isCancel && fn.bind(this)()) {
        paramss.needFrame = true;
        // 文件展示列表参数
        let {
          total,
          currentPage,
          sourceList,
          sourceType,
          isShowFilePreview,
          scrollTop,
          timeLine,
        } = this.fileView;
        let fileViewParam = {
          total,
          currentPage,
          sourceList,
          sourceType,
          isShowFilePreview,
          scrollTop,
          timeLine,
          viewWidth: document
            .getElementById("syncScreen")
            .getBoundingClientRect().width,
        };
        if(isShowFilePreview) {
          paramss.fileViewParam = fileViewParam;
        }
      } else {
        // 若讲师端不为主讲人 则通知主讲人端转发权限
        paramss = {
          data: [{personId: prevSpeakerId}],
          message: {
            sendPersonId: personId,
            type: "translate",
          }
        };
      }
      this.handleSyncSocket("authTranslate", paramss);
    },

    // 是否开启录制功能
    handlerecord(data) {
      this.syncParam.endurance = !!data;
      this.handleSyncSocket("reviewSync");
    },
    // 监听文件展示组件状态
    handleWatchFileComponent() {
      let that = this;
      let frameWindow = document.getElementById("syncScreen").contentWindow;
      const config = { attributes: true }; // 监听属性变化
      let targetDom = frameWindow.document.querySelector(".dsf-pre");
      // 创建观察器
      const observer = new MutationObserver(function (mutationsList) {
        let target = mutationsList[0].target;
        let opacity = getComputedStyle(target).opacity;
        if (opacity == 0) {
          that.$refs.fileComponent.handleChangeMenuStatus();
        }
      });
      observer.observe(targetDom, config);
    },
    // 全屏状态下拉出工具条
    handleDropTool() {
      let height = this.$refs.header.clientHeight;
      if(height) {
        this.$refs.header.style.height = "0"
        this.$refs.dropIcon.style.transform = 'rotate(0deg)'
      } else {
        this.$refs.header.style.height = "66px"
        this.$refs.dropIcon.style.transform = 'rotate(180deg)'
      }
    }
  },
};
</script>
