var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.disabled
    ? _c(
        "div",
        {
          staticClass: "ds-control ds-virtual-scroll ds-no-padding",
          style: _vm.rootStyle,
        },
        [
          _c(
            "div",
            _vm._g(
              {
                ref: "box",
                staticClass: "ds-virtual-scroll-box",
                style: _vm.boxStyle,
                attrs: { "slot-name": "default" },
              },
              _vm.boxEvent
            ),
            [_vm._t("default")],
            2
          ),
          _c("div", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.scrollX && _vm.showXBar,
                expression: "scrollX && showXBar",
              },
            ],
            ref: "xBar",
            staticClass: "ds-virtual-scroll-bar __x",
            class: { down: _vm.xType },
            style: {
              width: _vm.xBarLength + "px",
              transform: "translateX(" + _vm.x + "px)",
            },
            on: {
              mousedown: function ($event) {
                if ($event.target !== $event.currentTarget) {
                  return null
                }
                return _vm.xDown.apply(null, arguments)
              },
            },
          }),
          _c("div", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.scrollY && _vm.showYBar,
                expression: "scrollY && showYBar",
              },
            ],
            ref: "yBar",
            staticClass: "ds-virtual-scroll-bar __y",
            class: { down: _vm.yType },
            style: {
              height: _vm.yBarLength + "px",
              transform: "translateY(" + _vm.y + "px)",
            },
            on: {
              mousedown: function ($event) {
                if ($event.target !== $event.currentTarget) {
                  return null
                }
                return _vm.yDown.apply(null, arguments)
              },
            },
          }),
        ]
      )
    : _c(
        "div",
        { staticClass: "ds-control ds-virtual-scroll", style: _vm.rootStyle },
        [
          _c(
            "div",
            {
              ref: "box",
              staticClass: "ds-virtual-scroll-box",
              staticStyle: { overflow: "auto" },
              attrs: { "slot-name": "default" },
            },
            [_vm._t("default")],
            2
          ),
        ]
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }