/*
 * @Descripttion: x轴公用配置
 * @Author: zhanghang
 * @Date: 2021-07-27 14:17:01
 * @LastEditors: zhanghang
 * @LastEditTime: 2021-09-14 17:55:34
 */
export default {
  //x 轴配置
  xAxis: [{
    type: 'category' ,
    // 坐标轴线设置
    axisLine: {
      show: true,
      lineStyle:{
        color:'#333'
      }
    },
    // 坐标刻度设置
    axisTick: {
      show: true,
      //刻度对齐数据系
      alignWithLabel:false,
    },
    // 坐标标签设置
    axisLabel: {
      show: true,
      fontSize:12,
      rotate:0,
      color:'#333'
  
    },
    //分割线设置
    splitLine: {
      show: true
    },
    //坐标轴名字
    name:'坐标轴名称',
    // 坐标轴名字位置
    nameLocation:'center',
    nameTextStyle:{
      color:'#333',
      fontSize:12
    },
    //名字与轴线距离
    nameGap: 30 ,
    //两边留白策略
    boundaryGap: true,
    //坐标轴位置
    position:'bottom',
    //强制分割坐标轴
    interval: 0,
    // 动态映射
    mapRelation:['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
    //数据设置
    data:['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
  }],
}
