<template>
  <div
    class="ds-platform-card-item-by-collection"
    :style="controlStyle"
    @click="_clickBefore(click)"
  >
    <template v-if="isDesign">
      <div class="cover">
        <dsf-image />
      </div>
      <div
        class="title"
        :class="[lineNum < 2 ? 'ellipsis' : 'ellipsis-2']"
        :style="titleStyle"
      >
        上海全力打造全国新一轮全面开放新高地的新举措上海全力打造全国新一轮
        全面开放新高地的新举措上海全力打造全国新一轮全面开放新高地的新举措上海全力打造全国新一轮全面开放新高地的新举措上海全力打造
        全国新一轮全面开放新高地的新举措上海全力打造全国新一轮全面开放新高地的新举措上海全力打造全国新一轮全面开放新高地的新举措上海全力打造
        全国新一轮全面开放新高地的新举措上海全力打造全国新一轮全面开放新高地的新举措上海全力打造全国新一轮全面开放新高地的新举措上海全力打
        造全国新一轮全面开放新高地的新举措上海全力打造全国新一轮全面开放新高地的新举措
      </div>
      <!-- </div> -->
    </template>
    <template v-else>
      <div class="cover">
        <dsf-image :src="data['cover'] | imgFormat" :error-src="errorSrc" />
      </div>
      <div
        class="title"
        :class="[lineNum < 2 ? 'ellipsis' : 'ellipsis-2']"
        :style="titleStyle"
      >
        {{ data["title"] }}
      </div>
    </template>
  </div>
</template>

<script>
export default dsf.component({
  name: "DsfPlatformCardItemByCollection",
  ctrlCaption: "收藏卡片",
  mixins: [$mixins.card],
  props: {
    //组件边距
    padding: {
      default: () => [0, 0, 0, 0],
    },
    //静态数据
    keysMap: {
      type: Array,
      default() {
        return [
          { from: "cover", to: "cover", type: "text", desc: "封面" },
          { from: "title", to: "title", type: "text", desc: "课题" },
        ];
      },
    },
    //显示行数
    lineNum: {
      type: Number,
      default: 2,
    },
    clickConfig: {
      type: Object,
      default: null,
    },
  },
  computed: {
    controlStyle() {
      let { padding, isDesign } = this;
      let style = {
        padding: padding.join("px ") + "px",
      };
      if (isDesign) {
        style.padding = "10px 10px 10px 10px";
      }
      return style;
    },
    titleStyle() {
      if (this.lineNum < 2) return {};
      return {
        "-webkit-line-clamp": this.lineNum,
        height: this.lineNum * 22 + "px",
      };
    },
  },
  methods: {
    click() {
      if (this.$listeners.itemClick) {
        this.$dispatch("itemClick", item);
      } else {
        if (!this.clickConfig) return;
        let { url, state } = this.clickConfig;
        url = this.$replace(url, this.local);
        this.$open({ url, state });
      }
      this.clickAfter();
    },
  },
});
</script>
