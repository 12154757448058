var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isDesign
    ? _c(
        "div",
        {
          staticClass: "ds-control ds-platform-personnel-card",
          class: [_vm.imgPosition],
          style: {
            fontFamily: _vm.fontFamily,
            "border-color": _vm.borderColor,
          },
        },
        [
          _c(
            "div",
            { staticClass: "davatar" },
            [
              _c("dsf-image", { staticClass: "avatar-pic" }),
              _vm.showIcon
                ? _c("dsf-icon", {
                    staticClass: "avatar-icon",
                    style: { color: _vm.iconDefaultColor },
                    attrs: { name: _vm.iconName },
                  })
                : _vm._e(),
              _vm.showIcon
                ? _c("dsf-icon", {
                    staticClass: "avatar-icon active-icon",
                    style: { color: _vm.iconActiveColor },
                    attrs: { name: _vm.iconName },
                  })
                : _vm._e(),
              _vm.showTag
                ? _c(
                    "p",
                    {
                      staticClass: "avatar-tag",
                      style: {
                        backgroundColor: _vm.tagBgcolor,
                        borderRadius: _vm.tagRadius,
                        bottom: _vm.$bottom,
                        width: _vm.$width,
                      },
                    },
                    [_vm._v(" 标签内容 ")]
                  )
                : _vm._e(),
            ],
            1
          ),
          _c("div", { staticClass: "info" }, [
            _c("div", { staticClass: "name" }, [
              _c("p", { staticClass: "namep dotted-line" }, [
                _vm._v("张三小哥"),
              ]),
              _c("div", { staticClass: "age dotted-line" }, [
                _c("p", { style: { color: _vm.birthFontColor } }, [
                  _vm._v("1991.04"),
                ]),
                _c("p", { style: { color: _vm.birthFontColor } }, [
                  _vm._v("31岁"),
                ]),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "desc dotted-line", class: [_vm.descClass] },
              [
                _c(
                  "el-popover",
                  {
                    attrs: {
                      placement: "top-start",
                      width: "240",
                      trigger: "hover",
                      content:
                        "四川省成都市锦江区;四川省成都市锦江区;四川省成都市锦江区;四川省成都市锦江区;四川省成都市锦江区;四川省成都市锦江区;",
                    },
                  },
                  [
                    _c(
                      "p",
                      {
                        ref: "desc",
                        style: { color: _vm.descFontColor },
                        attrs: { slot: "reference" },
                        slot: "reference",
                      },
                      [
                        _vm._v(
                          '"四川省成都市锦江区;四川省成都市锦江区;四川省成都市锦江区;四川省成都市锦江区;四川省成都市锦江区;四川省成都市锦江区;"'
                        ),
                      ]
                    ),
                  ]
                ),
              ],
              1
            ),
          ]),
        ]
      )
    : _c(
        "div",
        {
          staticClass: "ds-control ds-platform-personnel-card",
          class: [_vm.imgPosition],
          style: {
            fontFamily: _vm.fontFamily,
            "border-color": _vm.borderColor,
          },
          on: { click: _vm.cardClickHandler },
        },
        [
          _c(
            "div",
            { staticClass: "davatar" },
            [
              _c("dsf-image", {
                staticClass: "avatar-pic",
                attrs: {
                  src: _vm._f("imgFormat")(_vm.avatar),
                  "error-src": _vm.errorSrc,
                },
              }),
              _vm.showIcon
                ? _c("dsf-icon", {
                    staticClass: "avatar-icon",
                    style: {
                      color: _vm.data["isCollect"]
                        ? _vm.iconDefaultColor
                        : _vm.iconDefaultColor,
                    },
                    attrs: { name: _vm.iconName },
                  })
                : _vm._e(),
              _vm.showTag
                ? _c(
                    "p",
                    {
                      staticClass: "avatar-tag",
                      style: {
                        backgroundColor: _vm.tagBgcolor,
                        borderRadius: _vm.tagRadius,
                        bottom: _vm.$bottom,
                        width: _vm.$width,
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.data["tag"]) + " ")]
                  )
                : _vm._e(),
            ],
            1
          ),
          _c("div", { staticClass: "info" }, [
            _c("div", { staticClass: "name" }, [
              _c("p", { staticClass: "namep" }, [
                _vm._v(_vm._s(_vm.data["name"])),
              ]),
              _c("div", { staticClass: "age" }, [
                _c("p", { style: { color: _vm.birthFontColor } }, [
                  _vm._v(_vm._s(_vm.birthday)),
                ]),
                _c("p", { style: { color: _vm.birthFontColor } }, [
                  _vm._v(_vm._s(_vm.age)),
                ]),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "desc", class: [_vm.descClass] },
              [
                _vm.overflowdiv
                  ? _c(
                      "el-popover",
                      {
                        attrs: {
                          placement: "top-start",
                          width: "240",
                          trigger: "hover",
                          content: _vm.data["jobDesc"],
                        },
                      },
                      [
                        _c(
                          "p",
                          {
                            ref: "desc",
                            style: { color: _vm.descFontColor },
                            attrs: { slot: "reference" },
                            slot: "reference",
                          },
                          [_vm._v(_vm._s(_vm.data["jobDesc"]))]
                        ),
                      ]
                    )
                  : _c(
                      "p",
                      { ref: "desc", style: { color: _vm.descFontColor } },
                      [_vm._v(_vm._s(_vm.data["jobDesc"]))]
                    ),
              ],
              1
            ),
          ]),
        ]
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }