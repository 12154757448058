<template>
  <a
    ref="eleDesgin"
    data-flag="true"
    :title="`进入${title}设计模式`"
    class="to_desgin"
    v-drag
    @click="gotoPreview"
    @mouseenter="mouseenter"
    @mouseleave="mouseleave">
    <i class="iconfont icon-shezhi"></i>
  </a>
</template>
<script>
export default {
  name: "DsfToDesigner",
  mixins: [$mixins.vDrag],
  props: {
    title: {
      type: String,
      default: ""
    },
    gotoPreview: {
      type: Function,
      default: dsf.noop
    }
  },
  data() {
    return {};
  },
  mounted() {
    if(this.$el){
      document.body.appendChild(this.$el);
    }

  },
  methods: {
    mouseenter() {
      this.$parent.$el?.classList.add('show-desgin');
    },
    mouseleave() {
      this.$parent.$el?.classList.remove('show-desgin');
    }
  },
  beforeDestroy() {
    if (this.$el) {
      this.$el.parentNode && this.$el.parentNode.removeChild(this.$el);
    }
  }
};
</script>