/*
 * @Descripttion: 公共配置：[grid] [title] [legend]
 * @Author: zhanghang
 * @Date: 2021-07-27 14:16:51
 * @LastEditors: zhanghang
 * @LastEditTime: 2022-01-14 15:34:00
 */
const color = ['#5470c6', '#91cc75', '#fac858', '#ee6666', '#73c0de', '#3ba272', '#fc8452', '#9a60b4', '#ea7ccc', '#546570', '#c4ccd3']
export default {
  //颜色系配置
  color: [...color,...color],
  //标题配置
  title: {
    show: true,
    //主标题
    text: '示例标题',
    //副标题
    subtext: '',
    //主标题与副标题间距
    itemGap: 10,
    //位置
    left: 'auto',
    top: 'auto',
    right: 'auto',
    bottom: 'auto',
    // 标题大小设置
    textStyle: {
      color:'#333',
      fontSize:18
    }

  },
  //图例配置
  legend: {
    show: true,
    //位置
    left: 'auto',
    top: 'auto',
    right: 'center',
    bottom: 'auto',
    //朝向
    orient: 'horizontal',
    //图例形状
    icon: '',
    //图例数据对应数据系name属性
    data: []
  },
  //区域配置
  grid: {
    //可视区域间距
    left: 30,
    top: 60,
    right: 30,
    bottom: 30,
    //是否包含坐标轴
    containLabel: true,
    //背景色
    backgroundColor: 'transparent',
    //网格的边框颜色
    borderColor: '#ccc',
    //网格的边框宽度
    borderWidth: 1,

    show: false,
  },
}
