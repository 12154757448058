var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "ds-control ds-flexible-box ds-no-padding",
      class: _vm.boxClass,
      style: _vm.boxStyle,
    },
    [
      !_vm.isDesign && !_vm.hiddenBt && _vm.canResize
        ? _c("i", {
            ref: "drag",
            staticClass: "ds-flexible-box-drag",
            class: { dragging: _vm.isDragging },
            style: _vm.dragStyle,
            on: {
              mousedown: function ($event) {
                $event.stopPropagation()
                return _vm.dragStart.apply(null, arguments)
              },
            },
          })
        : _vm._e(),
      _c(
        "div",
        {
          ref: "barBox",
          staticClass: "ds-flexible-box-bar-box",
          style: _vm.labelStyle,
          on: { transitionend: _vm.transitionend },
        },
        [
          _c(
            "div",
            {
              staticClass: "ds-flexible-box-bar-box-content",
              attrs: { "slot-name": "bar" },
            },
            [_vm._t("bar")],
            2
          ),
          !_vm.hiddenBt
            ? _c("div", { staticClass: "ds-flexible-box-bar-bt" }, [
                _c("div", {
                  attrs: { title: _vm.isClosed ? "展开" : "折叠" },
                  on: { click: _vm.close },
                }),
              ])
            : _vm._e(),
        ]
      ),
      _c("div", {
        ref: "bar",
        staticClass: "ds-flexible-box-bar",
        style: _vm.barStyle,
      }),
      _c(
        "div",
        { staticClass: "ds-flexible-box-main" },
        [
          _vm.isDesign
            ? _c(
                "div",
                {
                  staticClass: "ds-flexible-box-main-box",
                  attrs: { "slot-name": "default" },
                },
                [_vm._t("default")],
                2
              )
            : [_vm._t("default")],
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }