var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dsf-sync-box" }, [
    _vm._e("hidden"),
    _c("div", { staticClass: "dsf-sync-header" }, [
      _c("div", { ref: "header", staticClass: "dsf-sync-header-content" }, [
        _c(
          "div",
          { staticClass: "dsf-sync-header-right" },
          [
            _c("operation-nav", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isSperker,
                  expression: "isSperker",
                },
              ],
              ref: "fileComponent",
              attrs: {
                isTeacher: false,
                isSperker: _vm.isSperker,
                fileViewObj: _vm.fileView,
              },
            }),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.isSperker,
                    expression: "!isSperker",
                  },
                ],
                staticClass: "dsf-sync-header-student",
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "operation-item",
                    on: { click: _vm.handleBeMain },
                  },
                  [
                    _vm._m(0),
                    _c("div", { staticClass: "operation-item-title" }, [
                      _vm._v("申请主讲"),
                    ]),
                  ]
                ),
                _vm._m(1),
              ]
            ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "dsf-full-drop", on: { click: _vm.handleDropTool } },
        [
          _c("div", { ref: "dropIcon", staticClass: "icon-box" }, [
            _c("i", {
              ref: "dropIcon",
              staticClass: "icon iconfont icon-arrow-down",
            }),
          ]),
        ]
      ),
    ]),
    _c("div", { staticClass: "dsf-sync-body" }, [
      _c("div", { staticClass: "dsf-sync-right" }, [
        _c("div", { staticClass: "dsf-sync-content" }, [
          _c("iframe", {
            attrs: {
              src: _vm.syncParam.url,
              width: "1694",
              height: "847",
              frameborder: "0",
              id: "syncScreen",
            },
          }),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "operation-item-icon" }, [
      _c("i", { staticClass: "icon iconfont icon-sidebar-speaker" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "operation-item" }, [
      _c("div", { staticClass: "operation-item-icon" }, [
        _c("i", { staticClass: "icon iconfont icon-nav-exit" }),
      ]),
      _c("div", { staticClass: "operation-item-title" }, [_vm._v("退出同屏")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }