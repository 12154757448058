/*
 * @Descripttion: 
 * @Author: zhanghang
 * @Date: 2021-08-03 13:53:55
 * @LastEditors: zhanghang
 * @LastEditTime: 2021-08-03 17:04:57
 */
//注册平台级api
import "../api";
//注册系统模态框页面
import sysButtonSetting from "./sysButton/setting";
//注册pc端按钮
import "./sysButton";

import bhc from "./bhc";

// 首页菜单页面组件
import Home from "./home/menu";
// index页面组件
import HomeCtrl from "./home/ctrl";

//注入平台全局过滤器
import filters from "../filters";
//注入平台全局自定义指令
import directives from "../directives";
//注入平台PC端自定义指令
import pcDirectives from "./directives";

//用于加载配置完成的页面,预览页面
import output from "../output";

import login from './login';

//平台图表组件
import charts from './charts';
// 资源目录操作
import catalogue from './catalogue';

// 扩展element-ui
import './elementui';


function install(Vue) {
  Vue.use(bhc);
  Vue.use(Home);
  Vue.use(HomeCtrl);
  // Vue.use(HomeChart);
  Vue.use(filters);
  Vue.use(directives);
  Vue.use(pcDirectives);
  Vue.use(output);
  Vue.use(login);
  Vue.use(charts);
  Vue.use(catalogue);
  Vue.use(sysButtonSetting);
}

export default install;

export { install, bhc, filters, directives, output, login };
