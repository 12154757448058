<template>
  <div class="ds-control ds-calendar-nav ds-no-padding">
    <div v-if="isSwitch" class="ds-calendar-nav-switch">
      <i class="iconfont icon-fanyezuobeifen8" @click="switchRange(-1)"></i>
      <el-date-picker
        v-model="pickerValue"
        :editable="false"
        :clearable="false"
        :type="type === 'month' ? 'month' : 'week'"
        :format="type === 'month' ? 'yyyy年MM月' : 'yyyy年MM月 (第WW周)'"
        size="mini"
        prefix-icon=" "
        value-format="yyyy-MM-dd"
        placeholder="选择周"
      />
      <i class="iconfont icon-fanyezuobeifen5" @click="switchRange(1)"></i>
    </div>
    <div class="ds-calendar-nav-body">
      <div class="ds-calendar-nav-body-header">
        <span>周一</span>
        <span>周二</span>
        <span>周三</span>
        <span>周四</span>
        <span>周五</span>
        <span>周六</span>
        <span>周日</span>
      </div>
      <div class="ds-calendar-nav-body-content">
        <div class="ds-calendar-nav-body-row">
          <template v-for="day in range">
            <div
              :key="day.id"
              class="ds-calendar-nav-body-cell"
              :class="{ offset: day.offset }">
              <div
                class="ds-calendar-nav-body-cell-bt"
                :class="{ active: active == day }"
                :title="getTitle(lunarRange[day.id])"
                @click="active = day">
                <span class="day">{{ day.day }}</span>
                <span class="zhDay">{{ getZhDay(lunarRange[day.id]) }}</span>
                <i
                  class="tips"
                  :class="{ hide: !isDesign && !scheduleInfo[day.id] }"
                ></i>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const oneDay = 86400000;
const oneWeek = 604800000;

const lunarRange = {};

export default dsf.component({
  name: "DsfCalendarNav",
  mixins: [$mixins.control],
  ctrlCaption: "日历导航",
  design: {
    isMask: false,
  },
  props: {
    url: {
      type: String,
      default: "",
    },
    // 是否允许切换周
    isSwitch: {
      type: Boolean,
      default: false
    },
    dataLoadedFun: {
      type: Function,
      required: false,
    },
    isParameter: {
      type: Boolean,
      default: false,
    },
    // 时间
    toDay: {
      type: [String, Date],
      default: null
    },
    // 展示方式
    // week 周，week2 双周，month 月
    type: {
      type: String,
      default: 'week'
    }
  },
  data() {
    return {
      pickerValue: '',
      active: null,
      range: [],
      lunarRange: {},
      // 日程信息
      scheduleInfo: {}
    };
  },
  watch: {
    active(to) {
      if (to) {
        this.$dispatch("change", to);
      }
    },
    url() {
      this.reloadData();
    },
    type() {
      this.reloadData();
    },
    toDay: {
      handler(to) {
        if (!to) {
          this.pickerValue = dsf.date.format(new Date(), 'yyyy-mm-dd');
          this.reloadData();
          return;
        }
        if (dsf.isString(to)) {
          to = dsf.date.parse(to, 'yyyy-mm-dd');
        }
        if (dsf.type(to) !== 'date') {
          to = new Date();
        }
        this.pickerValue = dsf.date.format(to, 'yyyy-mm-dd');
        this.reloadData();
      },
      immediate: true
    },
    pickerValue() {
      this.reloadData();
    }
  },
  created() {
    this.loadScript(() => {
      this.setLunarRange();
    });
  },
  methods: {
    // 切换显示范围
    switchRange (n) {
      if (!n) return;
      if (this.type === 'week') {
        this.switchWeek(n);
      } else if (this.type === 'week2') {
        this.switchWeek(n * 2);
      } else if (this.type === 'month') {
        this.switchMonth(n);
      }
    },
    // 切换周
    switchWeek(n) {
      let to = dsf.date.parse(this.pickerValue, 'yyyy-mm-dd');
      to = new Date(to.getTime() + n * oneWeek);
      this.pickerValue = dsf.date.format(to, 'yyyy-mm-dd');
    },
    // 切换月
    switchMonth(n) {
      let to = dsf.date.parse(this.pickerValue, 'yyyy-mm-dd');
      to.setDate(0);
      to = new Date(to.getTime() + (n * 4 + 2) * oneWeek);
      this.pickerValue = dsf.date.format(to, 'yyyy-mm-01');
    },
    // 显示农历号/节日
    getZhDay(day) {
      if (!day) {
        return '';
      }
      return day.jieRi.length ? day.jieRi[0] : (day.lunarJieQi || day.lunarDay);
    },
    // 提示信息
    getTitle(info) {
      if (!info) return '';
      let {day,lunarYear,lunarMonth,lunarDay,lunarJieQi, jieRi, otherJieRi} = info;
      let res = [day, `农历${lunarYear}年${lunarMonth}月${lunarDay}`];
      if (lunarJieQi) res.push(`节气：${lunarJieQi}`);
      let jr = [...jieRi, ...otherJieRi];
      if (jr.length) res.push(`今天是${jr.join('、')}。`);
      return res.join('\n');
    },
    // 加载农历插件
    loadScript(callback) {
      this.$http
        .importFiles(dsf.url.getWebPath("$/js/libs/lunar/lunarDay.js"))
        .then(([{ state }]) => {
          if (state) {
            callback?.();
          } else {
            dsf.error("加载lunarDay.js文件出错");
          }
        })
        .catch((err) => {
          dsf.error(err);
        });
    },
    reloadData() {
      this.scheduleInfo = {};
      let toDay = dsf.date.parse(this.pickerValue, 'yyyy-mm-dd');
      toDay = toDay?.getTime() || dsf.date.toDay();
      let { range, active } = this.getRange(toDay);
      this.range = range;
      this.active = active;
      let etime = dsf.date.format(
        new Date(range[range.length - 1].date.getTime() + oneDay),
        "yyyy-mm-dd"
      );
      this.isDesign || this.getScheduleInfo(range[0].id, etime);
      this.setLunarRange();
    },
    // 计算时间范围
    getRange(day = dsf.date.toDay()) {
      let current = new Date(day);
      let month = current.getMonth();
      let type = this.type;
      let isMonth = type === 'month';
      if (isMonth) {
        day = current.setDate(1);
      }
      let range = [];
      let toDay = dsf.date.toDay(), active = null;
      let week = current.getDay() || 7;
      let l = 7 - week;
      if (type === 'week2') {
        l += 7;
      } else if (isMonth) {
        l += 35;
      }
      for (let i = 1 - week; i <= l; i++) {
        let t = day + oneDay * i;
        let d = new Date(t);
        let dd = d.getDate();
        let item = {
          id: dsf.date.format(d, 'yyyy-mm-dd'),
          offset: !isMonth || d.getMonth() === month,
          day: dd < 10 ? ('0' + dd) : dd,
          date: d
        };
        range.push(item);
        if (t == toDay) {
          active = item;
        }
      }
      if (!active) {
        active = range[0];
      }
      return { range, active };
    },
    // 计算农历信息
    setLunarRange() {
      if (!window.Solar) return;
      let newRange = {};
      _.forEach(this.range, ({id, date}) => {
        if (!lunarRange[id]) {
          let solar = window.Solar.fromDate(date);
          let lunar = solar.getLunar();
          lunarRange[id] = {
            day: dsf.date.format(date, 'yyyy年mm月dd日'),
            lunarYear: lunar.getYearInGanZhi(),
            lunarMonth: lunar.getMonthInChinese(),
            lunarDay: lunar.getDayInChinese(),
            lunarJieQi: lunar.getJieQi(),
            jieRi: [...lunar.getFestivals(), ...solar.getFestivals()],
            otherJieRi: solar.getOtherFestivals()
          };
        }
        newRange[id] = lunarRange[id];
      });
      this.lunarRange = newRange;
    },
    // 获取日程信息
    getScheduleInfo(stime, etime) {
      if (!this.url || this.url == "") {
        this.scheduleInfo = {};
        return;
      }
      var reqData = {};
      stime += ' 00:00:00';
      etime += ' 23:59:59';
      if (this.isParameter) {
        reqData = {
          startDate: stime,
          endDate: etime,
        };
      } else {
        reqData = {
          stime: stime,
          etime: etime,
        };
      }
      this.$http
        .get(this.url, reqData)
        .done(({ success, data, message }) => {
          if (success) {
            this.scheduleInfo = data;
            this.dataLoadedFun?.(data, this);
          } else {
            dsf.layer.message(message, false);
          }
        })
        .error((err) => {
          dsf.error(err);
          dsf.layer.message(err.message || "请求异常", false);
        });
    },
  },
});
</script>