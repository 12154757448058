<template>
  <div class="dsf-radial-gradual-select">
    <div ref="sliderBox" class="dsf-radial-gradual-select__pane" @mousedown="mousedown">
      <div :style="paneStyle"></div>
      <span ref="thumb" class="slider" :style="sliderStyle"></span>
    </div>
    <div class="dsf-radial-gradual-select__item">
      <span>渐变形状</span>
      <el-select v-model="shape" size="mini" @change="updateValue">
        <el-option v-for="item in config.shape" :key="item.value" :value="item.value" :label="item.text" />
      </el-select>
    </div>
    <div class="dsf-radial-gradual-select__item">
      <span>渐变边界</span>
      <el-select v-model="size" size="mini" @change="updateValue">
        <el-option v-for="item in config.size" :key="item.value" :value="item.value" :label="item.text" />
      </el-select>
    </div>
    <div class="dsf-radial-gradual-select__size"></div>
    <color-slider v-model="point" class="dsf-radial-gradual-select__bar" @change="updateValue" />
  </div>
</template>

<script>
import slider from '../slider';
import ColorSlider from './ColorSlider';

// 径向渐变选择器
export default {
  name: "DsfRadialGradualSelect",
  mixins: [slider],
  components: {
    ColorSlider
  },
  props: {
    value: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      config: {
        shape: [
          {text: '椭圆', value: 'ellipse'},
          {text: '圆', value: 'circle'},
        ],
        size: [
          {text: '离圆心最远的角', value: 'farthest-corner'},
          {text: '离圆心最近的角', value: 'closest-corner'},
          {text: '离圆心最远的边', value: 'farthest-side'},
          {text: '离圆心最近的边', value: 'closest-side'},
        ],
      },
      // 形状。ellipse 椭圆；circle 圆
      shape: 'circle',
      // 大小
      // farthest-corner (默认) : 指定径向渐变的半径长度为从圆心到离圆心最远的角
      // closest-side: 指定径向渐变的半径长度为从圆心到离圆心最近的边
      // closest-corner: 指定径向渐变的半径长度为从圆心到离圆心最近的角
      // farthest-side: 指定径向渐变的半径长度为从圆心到离圆心最远的边
      size: 'farthest-corner',
      // 定义渐变的位置 center top bottom
      position: [50, 50],
      point: [
        {length: 0, color: 'rgba(255,255,255,0)'},
        {length: 100, color: '#000'},
      ]
    }
  },
  computed: {
    paneStyle() {
      let str = this.point.map(({length, color}) => `${color} ${length}%`).join(',');
      let position = this.position.map(l => l + '%').join(' ');
      return {
        'background': `radial-gradient(${this.shape} ${this.size} at ${position}, ${str})`
      }
    },
    sliderStyle() {
      let p = this.position;
      return {
        top: p[1] + '%',
        left: p[0] + '%'
      }
    }
  },
  watch: {
    'value': {
      deep: true,
      handler(to) {
        this.init(to)
      }
    }
  },
  created() {
    this.init();
  },
  methods: {
    init(to = this.value) {
      if (!to) {
        to = {
          shape: 'circle',
          size: 'farthest-corner',
          position: [50, 50],
          point: [
            {length: 0, color: 'rgba(255,255,255,0)'},
            {length: 100, color: '#000'},
          ]
        }
      }
      this.shape = to.shape;
      this.size = to.size;
      this.position = to.position;
      this.point = to.point;
    },
    pointChange(_x, _y, x, y) {
      let {offsetWidth, offsetHeight} = this.$refs.sliderBox;
      x = Math.floor(x * 100 / offsetWidth);
      y = Math.floor(y * 100 / offsetHeight);
      this.position = [x, y];
      this.updateValue();
    },
    updateValue() {
      let {size , position, point, shape} = this;
      let value = {size , position, point, shape};
      this.$emit('input', value);
      this.$emit('change', value);
    }
  }
}
</script>