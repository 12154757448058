let popoverId=0;

export default {
  name: "DsfFieldLabel",
  functional: true,
  inject: {
    $vm: {
      default: null
    },
    $page: {
      default: null
    }
  },
  props: {
    isDesign: {
      type: Boolean,
      default: false
    },
    owner: {
      type: Object,
      default() {
        return null
      }
    },
    mode: {
      type: String,
      default: "popover"
    },
    showIcon: {
      type: Boolean,
      default: false
    },
    dataCaption: {
      type: String,
      default: ""
    },
    //hover,click
    trigger: {
      type: String,
      default: "click"
    }
  },
  render(h, _vm) {
    _vm.popoverId='popover-'+(++popoverId)
    return renderLabel(h, _vm)
  }
}

function renderPopover(h, _vm) {
  return h('el-popover', {
    staticClass: "ds_label-helper",
    attrs: {
      'popperClass': 'helper-popover',
      'placement': "right",
      'trigger': _vm.props.trigger
    },
    ref: _vm.popoverId,
    scopedSlots: {
      default: () => {
        return h('div', {
          staticClass: "data-caption-popover",
          domProps: {
            innerHTML: dsf.safe.xss(_vm.props.dataCaption)
          }
        })
      }
    }
  })
}
function renderLabel(h, _vm) {
  let props = _vm.props;
  let dc = props.dataCaption;
  let sdi = props.showIcon;
  let textNode = _vm?.scopedSlots?.default ? _vm.scopedSlots.default() : h();
  if (textNode && textNode.length > 0) {
    for (let i = 0; i < textNode.length; i++) {
      if (!textNode[i].tag && textNode[i].text) {
        textNode[i].text = textNode[i].text.trim()
      }
    }
  }
  let owner = _vm.props.owner || {};
  // let iconNode = renderIcon(h, _vm);
  let isCaptionOnLabel = (dc && !sdi && !owner.isDesign);
  let isCaptionOnIcon = (dc && sdi && !owner.isDesign);
  return h('label', {
    ..._vm.data,
    staticClass: "ds-form-label",
    class: {
      'has-datacaption': isCaptionOnLabel
    }
  }, [
    renderText(h, _vm, isCaptionOnLabel),
    (sdi && dc) ? renderIcon(h, _vm) : h(),
    (dc && getMode(_vm) == 'popover' && !props.isDesign) ? renderPopover(h, _vm) : h()
  ]);
}
function renderCaptionPanel(h, _vm) {
  let mode = "popover";
  if (_vm.props.trigger == 'click') {
    mode = _vm.props.mode;
  }
  if (mode == 'popover') {
    return renderPopover(h, _vm, h('span', {}))
  }
  else {
    return h('span', {
      on: {
        'click': (evt) => {
          _vm?.props?.owner?.showHelper?.()
        }
      }
    }, [child]);
  }
}

function renderIcon(h, _vm) {
  let data = {};
  if(_vm.props.showIcon){
    if (getMode(_vm) == 'dialog'){
      if(!data.on){
        data.on={};
      }
      data.on.click = function () {
        _vm?.props?.owner?.showHelper?.()
      }
    }
    else if (getMode(_vm) == 'popover') {
      data.directives = [
        {
          name: 'popover',
          // value:_vm?.props?.owner?.$formName,
          arg: _vm.popoverId
        }
      ]
    }
  }
  return h("i", {
    ...data,
    staticClass: "iconfont icon-bangzhuzhongxin ds-control-helper"
  });
}

function renderText(h, _vm, isCaptionOnLabel) {
  let textNode = _vm?.scopedSlots?.default ? _vm.scopedSlots.default() : h();
  let data = {};
  if (!_vm.props.showIcon) {
    if (getMode(_vm) == 'dialog') {
      if(!data.on){
        data.on={};
      }
      data.on.click = function () {
        _vm?.props?.owner?.showHelper?.()
      }
    }
    if (getMode(_vm) == 'popover') {
      data.directives = [
        {
          name: 'popover',
          arg: _vm.popoverId
        }
      ]
    }
  }
  return h("span", data, [textNode])
}

function getMode(vm) {
  let mode = "popover";
  if (vm.props.trigger == 'click') {
    mode = vm.props.mode;
  }
  return mode;
}