var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "header-menu", style: _vm.menuStyle },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showBt,
              expression: "showBt",
            },
          ],
          staticClass: "header-menu-bt __left",
          attrs: { disabled: _vm.disabledLeft },
          on: {
            click: function ($event) {
              return _vm.doPagination(1)
            },
          },
        },
        [_c("i", { staticClass: "iconfont icon-arrow-left2" })]
      ),
      _c("div", { ref: "box", staticClass: "header-menu-box" }, [
        _c(
          "div",
          { ref: "content", staticClass: "header-menu-content" },
          _vm._l(_vm.menuList, function (item) {
            return _c(
              "div",
              {
                key: item.menu_id,
                staticClass: "header-menu-item",
                class: { active: item.menu_id === _vm.activeKey },
                on: {
                  click: function ($event) {
                    return _vm.linkTo(item)
                  },
                  mouseenter: function ($event) {
                    return _vm.$emit("show-menu-children", item, $event)
                  },
                  mouseleave: function ($event) {
                    return _vm.$emit("hide-menu-children", item, $event)
                  },
                },
              },
              [
                _c("div", { staticClass: "header-menu-item-box" }, [
                  _c("i", {
                    staticClass: "iconfont",
                    class: [item.menu_icon || "icon-shezhi"],
                  }),
                  _c("div", [_vm._v(_vm._s(item.menu_name))]),
                  (!item.children || !item.children.length) && item["menu_code"]
                    ? _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.badge[item["menu_code"]],
                              expression: "badge[item['menu_code']]",
                            },
                          ],
                          staticClass: "ds-page-home-badge",
                          class: [_vm.homeRoot.badgeStyle],
                        },
                        [_vm._v(_vm._s(_vm.badge[item["menu_code"]]))]
                      )
                    : _vm._e(),
                ]),
              ]
            )
          }),
          0
        ),
      ]),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showBt,
              expression: "showBt",
            },
          ],
          staticClass: "header-menu-bt __right",
          attrs: { disabled: _vm.disabledRight },
          on: {
            click: function ($event) {
              return _vm.doPagination(-1)
            },
          },
        },
        [_c("i", { staticClass: "iconfont icon-arrow-right2" })]
      ),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }