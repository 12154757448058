<template>
  <div class="flow-add-comment">
    <div class="content">
      <div class="left">
        <el-input
          type="textarea"
          v-model="sOpinion.Content"
          :rows="9"
          :max="1000"
        ></el-input>
      </div>
      <div v-if="tpls.length > 0" class="template">
        <div
          class="item"
          v-for="(tpl, i) in tpls"
          :key="i"
          @click="sOpinion.Content = tpl.key_word"
        >
          {{ tpl.key_word }}
        </div>
      </div>
    </div>
    <div class="upload-file">
      <div class="title">
        <span class="tip">附件</span>
        <span
          class="ds-button--icon-text"
          @click="upload"
        ><i class="el-icon-upload2"></i> 上传附件</span
        >
      </div>
      <div class="file-list">
        <DsfUploadFile
          v-if="loaded"
          ref="uploadFile"
          :hide-btns="true"
          @value-change="getValue"
          :files="sOpinion.Attachments"
        ></DsfUploadFile>
        <p v-if="!sOpinion || (sOpinion && (!sOpinion.Attachments || sOpinion.Attachments.length === 0))" class="no-file">
          暂无附件～
        </p>
      </div>
    </div>
    <div class="btns">
      <span
        class="ds-button--icon-text addcomment-delete"
        @click="clearAll"
      ><i class="iconfont icon-delete"></i> 清 空</span
      >
      <DsfButton class="addcomment-sure" @click="sure">确 定</DsfButton>
      <DsfButton
        class="plain addcomment-cancel"
        @click="cancel"
      >取 消</DsfButton
      >
    </div>
  </div>
</template>
<script>
export default {
  name: "DsfFlowAddComment",
  inject: {
    $dialog: {
      default: null,
    },
  },
  props: {
    $vm: {
      type: [Object, null],
      default() {
        return null;
      },
    },
    flow: {
      type: [Object, null],
      default() {
        return null;
      },
    },
  },
  data() {
    return {
      loaded: false,
      tpls: [],
      sOpinion: {
        Content: "",
        Attachments: null,
      },
    };
  },
  created() {
    this.initFlow();
  },
  methods: {
    yes() {
      return this.sOpinion;
    },
    validate() {
      if (!this.sOpinion.Content) {
        dsf.layer.message("处理意见不能为空", false);
        return false;
      }
      return true;
    },
    // 获取表单
    initFlow() {
      if (this.$vm?.flows?.sOpinion) {
        this.sOpinion = _.cloneDeep(this.$vm.flows.sOpinion);
      }
      this.$http.get("wfr/getOpinionKeyWord", {}).done((res) => {
        if (res.success) {
          this.tpls = res.data || [];
        }
        this.loaded = true;
      });
    },
    getValue(val) {
      this.sOpinion.Attachments = JSON.parse(val.args);
    },
    upload() {
      this.$refs.uploadFile.openSelectFile();
    },
    clearAll() {
      this.sOpinion = {
        Content: "",
        Attachments: [],
      };
      this.$refs.uploadFile.clearAll();
    },
    sure() {
      if (!this.validate()) {
        return;
      }
      this.$vm.flows?.flowProcess.forEach((item) => {
        if (item.linkid === this.flow.sCurLinkID) {
          item.opinionatts = JSON.stringify(_.clone(this.sOpinion));
          item.rusername = dsf.cookies.get("user_name");
          item.ruserid = '';
        }
      });

      if (this.$vm?.flows) {
        this.$vm.flows.sOpinion = this.sOpinion;
      }
      const param = {
        sPID: this.flow.sPID,
        sCurLinkID: this.flow.sCurLinkID,
        sOpinion: JSON.stringify(this.sOpinion),
      };
      this.$http
        .get("wfr/opinion", param)
        .then((res) => {
          if (res.data.success) {
            this.$dialog.close();
          } else {
            dsf.layer.message(res.data.message, false);
          }
        })
        .catch((res) => {
          dsf.layer.message("处理意见异常", false);
        });
    },
    cancel() {
      this.$dialog.close();
    },
  },
};
</script>
