<template>
  <div class="ds-control ds-form-item ds-system" :class="getCss">
    <template v-if="simple && !isDesign">
      {{ getValueString | proxy(formatter) }}
    </template>
    <template v-else>
      <!-- <label v-if="showLabel" class="ds-form-label" :style="getLabelWidthStyle()">{{ label }}</label> -->
      <DsfFieldLabel
        v-if="showLabel"
        :mode="showDataCaptionMode"
        :style="getLabelWidthStyle()"
        :show-icon="showDataCaptionIcon" 
        :trigger="showDataCaptionTrigger"
        :data-caption="dataCapion"
        :is-design="isDesign"
        :owner="_self">
        {{ $t(label) }}
      </DsfFieldLabel>
      <div class="ds-form-block" :style="getFormItemBlockMarginLeft()">
        <template v-if="isDesign">
          [修改时间]
        </template>
        <template v-else>
          {{ getValueString | proxy(formatter) }}
        </template>
      </div>
    </template>
  </div>
</template>

<script>
export default dsf.component({
  name: "DsfUpdateTime",
  mixins: [$mixins.formControl],
  ctrlCaption: "修改时间",
  props: {
    caption: {
      type: String,
      default: dsf.config.kw.updatetime,
    },
    label: {
      type: String,
      default: "修改时间",
    },
    formatter: {
      type: String,
      default: "dateTime^yyyy-mm-dd hh:ii",
    },
    simple: {
      type: Boolean,
      default: false,
    },
    showLabel: {
      type: Boolean,
      default: true,
    },
    visible: {
      type: Boolean,
      default: true,
    },
    allowPost:{
      type:Boolean,
      default:false
    },
    metadata: {
      type: Object,
      default() {
        return dsf.metadata.get("system-meta-data", {
          code: dsf.config.kw.updatetime,
          valueAttributes: [
            {
              name: "修改时间",
              code: dsf.config.kw.updatetime,
              type: dsf.metadata.getDataType("date"),
              length: "",
              defaultValue: null,
              unit: null,
            },
          ],
        });
      },
    },
  },
  computed: {
    getValueString: {
      get() {
        return this.value;
      },
      set(v) {
        this.emitValueChange(v);
      },
    },
  },
});
</script>
