<template>
  <div
    class="page-home-default-aside-menu-children"
    :class="{show: show}"
    :style="style"
    @mouseenter="mouseenter"
    @mouseleave="mouseleave">
    <div class="box">
      <transition name="el-zoom-in-left">
        <header-menu-children v-show="show" :list="list" />
      </transition>
    </div>
  </div>
</template>

<script>
import HeaderMenuChildren from "./aside/headerMenuChildren.vue";

export default {
  name: "HomeAsideMenuChildren",
  components: {
    HeaderMenuChildren
  },
  data() {
    return {
      list: [],
      show: false,
      top: 0,
      left: 0
    }
  },
  computed: {
    style() {
      return {
        'transform': `translate(${this.left}px, ${this.top}px)`
      }
    }
  },
  mounted() {
    document.querySelector("#app > .full-view").appendChild(this.$el);
  },
  beforeDestroy() {
    this.$el.remove();
  },
  methods: {
    showMenuChildren(item, e) {
      if (!item.children?.length) {
        this.hideMenuChildren();
        return;
      }
      clearTimeout(this._t1);
      clearTimeout(this._t2);
      let {left, top, width} = e.target.getBoundingClientRect();
      this.top = top;
      this.left = left + width + 5;
      this.list = item.children;
      if (!this.show) {
        this._t2 = setTimeout(() => {
          this.show = true;
        }, 10);
      }
    },
    hideMenuChildren() {
      clearTimeout(this._t1);
      this._t1 = setTimeout(() => {
        this.show = false;
        this._t1 = setTimeout(() => {
          this.list = [];
        }, 200);
      }, 200);
    },
    mouseenter() {
      clearTimeout(this._t1);
    },
    mouseleave() {
      this.hideMenuChildren();
    }
  }
}
</script>