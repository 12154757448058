<script>
window.$$mulitRoutes = {};
const defaultName = "default";
var START = "";

var NavigationFailureType = {
  redirected: 2,
  aborted: 4,
  cancelled: 8,
  duplicated: 16
};
export default {
  name: "DsfMultiRouteView",
  inheritAttrs: false,
  ctrlCaption: "内嵌多页路由组件",
  multiView: true,
  props: {},
  data() {
    return {
      path: "",
      // name: "default",
      currentIndex: 0
    };
  },
  computed: {
    // history() {
    //   return this.vueRoute.history;
    // }
  },
  beforeCreate() {
    if (!this.multiViewInstalled) {
      dsf.error("多路由插件没有安装");
      return;
    }
    Object.defineProperty(this, "history", {
      get() {
        return this._router.history;
      }
    });
    Object.defineProperty(this, "current", {
      get() {
        return this.history.current;
      },
      set(v) {
        this.history.current = v;
      }
    });
    Object.defineProperty(this, "$$views", {
      get() {
        return this.$dsfRouters.views;
      }
    });
    this.historyPaths = [];
    // this.defaultPath = this.$attrs.path || "/";
    this.name = this.$attrs.name || defaultName;
    this.history.current = START = this._router.matcher.match("/");
    this.$options.methods.overrideNav.call(this);
  },
  created() {
    if (this.multiViewInstalled) {
      let _this = this;
      if (!this.$$views[this.name]) {
        this.$dsfRouters.addView(this.name, this.history.current);
      }
      delete this.$$views[this.name].route;
      Object.defineProperty(this.$$views[this.name], "router", {
        get() {
          return _this._router;
        }
      });
    }
  },
  mounted() {
    if (this.multiViewInstalled) {
      if (this.$$views[this.name].initRoute) {
        let initRoute = this.$$views[this.name].initRoute;
        this.$$views[this.name].push(initRoute);
      }
    }
  },
  methods: {
    transitionTo(path, onComplete) {
      var route;
      var _this = this;
      try {
        route = this.matched(path);
      } catch (e) {
        this.history.errorCbs.forEach(function (cb) {
          cb(e);
        });
        throw e;
      }
      this.history.ready = false;
      var prev = this.history.current;
      this.confirmTransition(route, () => {
        _this.updateRoute(route);
        onComplete && onComplete(route);
        this._router.afterHooks.forEach(function (hook) {
          hook && hook(route, prev);
        });
        if (!_this.history.ready) {
          _this.history.ready = true;
          _this._router.history.readyCbs.forEach(function (cb) {
            cb(route);
          });
        }
      });
    },
    confirmTransition(route, onComplete, onAbort) {
      var _this = this;
      var current = this.history.current;

      this.history.pending = route;
      var abort = function (err) {
        // changed after adding errors with
        // https://github.com/vuejs/vue-router/pull/3047 before that change,
        // redirect and aborted navigation would produce an err == null
        if (!isNavigationFailure(err) && dsf.isError(err)) {
          if (_this.history.errorCbs.length) {
            _this.history.errorCbs.forEach(function (cb) {
              cb(err);
            });
          } else {
            if (true) {
              warn(false, "uncaught error during route navigation:");
            }
            dsf.error(err);
          }
        }
        onAbort && onAbort(err);
      };
      var lastRouteIndex = route.matched.length - 1;
      var lastCurrentIndex = current.matched.length - 1;
      if (isSameRoute(route, current) && lastRouteIndex === lastCurrentIndex && route.matched[lastRouteIndex] === current.matched[lastCurrentIndex]) {
        return abort(createNavigationDuplicatedError(current, route));
      }

      let ref = resolveQueue(this.history.current.matched, route.matched);
      var updated = ref.updated;
      var deactivated = ref.deactivated;
      var activated = ref.activated;

      var queue = [].concat(
        // in-component leave guards
        extractLeaveGuards(deactivated),
        // global before hooks
        _this._router.beforeHooks,
        // in-component update hooks
        extractUpdateHooks(updated),
        // in-config enter guards
        activated.map(function (m) {
          return m.beforeEnter;
        })
        // async components
        // resolveAsyncComponents(activated)
      );
      queue = _.filter(queue, (it) => it);

      var iterator = function (hook, next) {
        if (_this.history.pending !== route) {
          return abort(createNavigationCancelledError(current, route));
        }
        try {
          hook(route, current, function (to) {
            if (to === false) {
              //next(false);
              abort(createNavigationAbortedError(current, route));
            } else if (dsf.isError(to)) {
              abort(to);
            } else if (dsf.isString(to) || (dsf.isObject(to) && (dsf.isString(to.path) || dsf.isString(to.name)))) {
              abort(createNavigationRedirectedError(current, route));
              if (dsf.isObject(to) && to.replace) {
                _this.replace(to);
              } else {
                _this.push(to);
              }
            } else {
              // confirm transition and pass on the value
              next(to);
            }
          });
        } catch (e) {
          dsf.error(e);
          // abort(e);
        }
      };
      // console.log("执行守卫",route.fullPath);
      _this.runQueue(queue, iterator, function () {
        var enterGuards = extractEnterGuards(activated);
        var queue = enterGuards.concat(_this._router.resolveHooks);
        _this.runQueue(queue, iterator, function () {
          if (_this.history.pending !== route) {
            return abort(createNavigationCancelledError(current, route));
          }
          _this.history.pending = null;
          onComplete(route);
          if (_this._router.app) {
            _this._router.app.$nextTick(function () {
              handleRouteEntered(route);
            });
          }
        });
      });
    },
    matched(path) {
      return this._router.matcher.match(path);
    },
    runQueue(queue, fn, cb) {
      var step = function (index) {
        if (index >= queue.length) {
          cb();
        } else {
          if (queue[index]) {
            fn(queue[index], function () {
              step(index + 1);
            });
          } else {
            step(index + 1);
          }
        }
      };
      step(0);
    },
    updateRoute(route) {
      this.current = this._route = this.history.route = route;
      this.history.cb && this.history.cb(route);
    },
    overrideNav() {
      let _this = this;
      this.history.push = function (location, onComplete, onAbort) {
        var ref = this;
        var fromRoute = ref.current;
        _this.transitionTo(
          location,
          function (route) {
            let record = dsf.mix({}, route);
            delete record.matched;
            let lastHistoryRecord = _this.historyPaths[_this.historyPaths.length - 1];
            if (!lastHistoryRecord || lastHistoryRecord.fullPath != record.fullPath) {
              _this.historyPaths.splice(_this.currentIndex + 1, _this.historyPaths.length, record);
              _this.currentIndex = _this.historyPaths.length - 1;
            }
            onComplete && onComplete(route);
          },
          onAbort
        );
      };
      this.history.replace = function (location, onComplete, onAbort) {
        var ref = this;
        var fromRoute = ref.current;
        _this.transitionTo(
          location,
          function (route) {
            let record = dsf.mix({}, route);
            delete record.matched;
            let currentHistoryRecord = _this.historyPaths[_this.currentIndex];
            if (currentHistoryRecord) {
              if (currentHistoryRecord.fullPath != record.fullPath) {
                _this.historyPaths[_this.currentIndex] = record;
              }
            } else {
              _this.historyPaths.push(record);
              _this.currentIndex = _this.historyPaths.length - 1;
            }
            onComplete && onComplete(route);
          },
          onAbort
        );
      };
      this.history.go = function (n) {
        var ref = this;
        var fromRoute = ref.current;
        let curr = _this.currentIndex;
        curr += n;
        if (curr <= 0) {
          curr = 0;
        } else if (curr >= _this.historyPaths.length - 1) {
          curr = _this.historyPaths.length - 1;
        }
        let location = _this.historyPaths[curr];
        if (location) {
          _this.transitionTo(
            location,
            function (route) {
              let record = dsf.mix({}, route);
              delete record.matched;
              _this.currentIndex = curr;
              _this.historyPaths[_this.currentIndex] = record;
            },
            dsf.noop
          );
        }
      };
      this.history.back = function () {
        _this.history.go(-1);
      };
      this.history.forward = function () {
        _this.history.go(1);
      };
    },
    go: function (n) {
      this.history.go(n);
    },
    back: function () {
      this.history.go(-1);
    },
    forward: function () {
      this.history.go(1);
    },
    replace: function (location, onComplete, onAbort) {
      this.history.replace(location, onComplete, onAbort);
    },
    push: function (location, onComplete, onAbort) {
      this.history.push(location, onComplete, onAbort);
    }
  },
  destroyed() {
    this.historyPaths = undefined;
    for (k in this.$$view[this.name]) {
      obj[k] = undefined;
      delete obj[k];
    }
    delete this.$$view[this.name];
    this._route = undefined;
    this._router = undefined;
  },
  render(h) {
    if(!this.multiViewInstalled){
      return h()
    }
    if (!this?._route?.matched?.length > 0) {
      return h();
    } else {
      let name = "default";
      let matched = this._route.matched[0];
      var component = matched && matched.components[name];
      var configProps = matched.props && matched.props[name];
      let data = this.$vnode.data;
      let _this = this;
      data.registerRouteInstance = function (vm, val) {
        var current = matched.instances[name];
        if ((val && current !== vm) || (!val && current === vm)) {
          matched.instances[name] = val;
        }
      };
      data.hook.init = function (vnode) {
        if (vnode.data.keepAlive && vnode.componentInstance && vnode.componentInstance !== matched.instances[name]) {
          matched.instances[name] = vnode.componentInstance;
        }
        handleRouteEntered(_this._route);
      };
      // data._currentRouterView = this;
      data.attrs["route-url"] = _this._route.fullPath;
      if (configProps) {
        // dsf.mix(cache['default'], {
        //   route: route,
        //   configProps: configProps
        // });
        fillPropsinData(component, data, this._route, configProps);
      }
      return h(component, data, []);
    }
  }
};

/*
路由加载，来自于vue-router
作者：Evan You
修改：Wengwy 2022-02-17
 */
var propertiesToLog = ["params", "query", "hash"];
function stringifyRoute(to) {
  if (typeof to === "string") {
    return to;
  }
  if ("path" in to) {
    return to.path;
  }
  var location = {};
  propertiesToLog.forEach(function (key) {
    if (key in to) {
      location[key] = to[key];
    }
  });
  return JSON.stringify(location, null, 2);
}

function resolveQueue(current, next) {
  var i;
  var max = Math.max(current.length, next.length);
  for (i = 0; i < max; i++) {
    if (current[i] !== next[i]) {
      break;
    }
  }
  return {
    updated: next.slice(0, i),
    activated: next.slice(i),
    deactivated: current.slice(i)
  };
}

function fillPropsinData(component, data, route, configProps) {
  // resolve props
  var propsToPass = (data.props = resolveProps(route, configProps));
  if (propsToPass) {
    // clone to prevent mutation
    propsToPass = data.props = extend({}, propsToPass);
    // pass non-declared props as attrs
    var attrs = (data.attrs = data.attrs || {});
    for (var key in propsToPass) {
      if (!component.props || !(key in component.props)) {
        attrs[key] = propsToPass[key];
        delete propsToPass[key];
      }
    }
  }
}

function resolveProps(route, config) {
  switch (typeof config) {
  case "undefined":
    return;
  case "object":
    return config;
  case "function":
    return config(route);
  case "boolean":
    return config ? route.params : undefined;
  default:
    if (true) {
      warn(false, 'props in "' + route.path + '" is a ' + typeof config + ", " + "expecting an object, function or boolean.");
    }
  }
}

function extend(a, b) {
  for (var key in b) {
    a[key] = b[key];
  }
  return a;
}

function extractLeaveGuards(deactivated) {
  return extractGuards(deactivated, "beforeRouteLeave", bindGuard, true);
}

function extractEnterGuards(activated) {
  return extractGuards(activated, "beforeRouteEnter", function (guard, _, match, key) {
    return bindEnterGuard(guard, match, key);
  });
}

function extractUpdateHooks(updated) {
  return extractGuards(updated, "beforeRouteUpdate", bindGuard);
}

function bindGuard(guard, instance) {
  if (instance) {
    return function boundRouteGuard() {
      return guard.apply(instance, arguments);
    };
  }
}

function extractGuards(records, name, bind, reverse) {
  var guards = flatMapComponents(records, function (def, instance, match, key) {
    var guard = extractGuard(def, name);
    if (guard) {
      return Array.isArray(guard)
        ? guard.map(function (guard) {
          return bind(guard, instance, match, key);
        })
        : bind(guard, instance, match, key);
    }
  });
  return flatten(reverse ? guards.reverse() : guards);
}

function extractGuard(def, key) {
  if (typeof def !== "function") {
    // extend now so that global mixins are applied.
    def = Vue.extend(def);
  }
  return def.options[key];
}

function bindEnterGuard(guard, match, key) {
  return function routeEnterGuard(to, from, next) {
    return guard(to, from, function (cb) {
      if (typeof cb === "function") {
        if (!match.enteredCbs[key]) {
          match.enteredCbs[key] = [];
        }
        match.enteredCbs[key].push(cb);
      }
      next(cb);
    });
  };
}

function handleRouteEntered(route) {
  for (var i = 0; i < route.matched.length; i++) {
    var record = route.matched[i];
    for (var name in record.instances) {
      var instance = record.instances[name];
      var cbs = record.enteredCbs[name];
      if (!instance || !cbs) {
        continue;
      }
      delete record.enteredCbs[name];
      for (var i$1 = 0; i$1 < cbs.length; i$1++) {
        if (!instance._isBeingDestroyed) {
          cbs[i$1](instance);
        }
      }
    }
  }
}

function flatten(arr) {
  return Array.prototype.concat.apply([], arr);
}

function flatMapComponents(matched, fn) {
  return flatten(
    matched.map(function (m) {
      return Object.keys(m.components).map(function (key) {
        return fn(m.components[key], m.instances[key], m, key);
      });
    })
  );
}

function isNavigationFailure(err, errorType) {
  return dsf.isError(err) && err._isRouter && (errorType == null || err.type === errorType);
}
function createRouterError(from, to, type, message) {
  var error = new Error(message);
  error._isRouter = true;
  error.from = from;
  error.to = to;
  error.type = type;
  return error;
}

function createNavigationDuplicatedError(from, to) {
  var error = createRouterError(from, to, NavigationFailureType.duplicated, 'Avoided redundant navigation to current location: "' + from.fullPath + '".');
  // backwards compatible with the first introduction of Errors
  error.name = "NavigationDuplicated";
  return error;
}
function createNavigationCancelledError(from, to) {
  return createRouterError(from, to, NavigationFailureType.cancelled, 'Navigation cancelled from "' + from.fullPath + '" to "' + to.fullPath + '" with a new navigation.');
}

var trailingSlashRE = /\/?$/;
function isSameRoute(a, b, onlyPath) {
  if (b === START) {
    return a === b;
  } else if (!b) {
    return false;
  } else if (a.path && b.path) {
    return a.path.replace(trailingSlashRE, "") === b.path.replace(trailingSlashRE, "") && (onlyPath || (a.hash === b.hash && isObjectEqual(a.query, b.query)));
  } else if (a.name && b.name) {
    return a.name === b.name && (onlyPath || (a.hash === b.hash && isObjectEqual(a.query, b.query) && isObjectEqual(a.params, b.params)));
  } else {
    return false;
  }
}
function isObjectEqual(a, b) {
  if (a === void 0) a = {};
  if (b === void 0) b = {};

  // handle null value #1566
  if (!a || !b) {
    return a === b;
  }
  var aKeys = Object.keys(a).sort();
  var bKeys = Object.keys(b).sort();
  if (aKeys.length !== bKeys.length) {
    return false;
  }
  return aKeys.every(function (key, i) {
    var aVal = a[key];
    var bKey = bKeys[i];
    if (bKey !== key) {
      return false;
    }
    var bVal = b[key];
    // query values can be null and undefined
    if (aVal == null || bVal == null) {
      return aVal === bVal;
    }
    // check nested equality
    if (typeof aVal === "object" && typeof bVal === "object") {
      return isObjectEqual(aVal, bVal);
    }
    return String(aVal) === String(bVal);
  });
}

function createNavigationRedirectedError(from, to) {
  return createRouterError(from, to, NavigationFailureType.redirected, 'Redirected when going from "' + from.fullPath + '" to "' + stringifyRoute(to) + '" via a navigation guard.');
}
</script>