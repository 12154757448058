var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    _vm._g(
      _vm._b(
        {
          ref: "dialog",
          staticClass: "ds-dialog",
          class: _vm.elClass,
          attrs: { index: _vm.index, "append-to-body": "" },
          scopedSlots: _vm._u(
            [
              {
                key: "title",
                fn: function () {
                  return [_vm._t("title")]
                },
                proxy: true,
              },
              {
                key: "default",
                fn: function () {
                  return [_vm._t("default")]
                },
                proxy: true,
              },
              {
                key: "footer",
                fn: function () {
                  return [_vm._t("footer")]
                },
                proxy: true,
              },
            ],
            null,
            true
          ),
        },
        "el-dialog",
        _vm.$attrs,
        false,
        true
      ),
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }