var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "ds-control ds-row ds-no-padding",
      class: _vm.getCss,
      style: {
        height: _vm.rowHeight,
        margin: _vm.$margin,
        overflow: _vm.overflow,
      },
    },
    [
      _c(
        "div",
        { staticClass: "el-row", style: _vm.elRowStyle },
        [_vm._t("default")],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }