import DsfGwHomeHeader from './homeHeader';

export {
  DsfGwHomeHeader
};

export default {
  install (Vue) {
    Vue.component(DsfGwHomeHeader.name, DsfGwHomeHeader);
  }
};