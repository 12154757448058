var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ds-control ds-form-item ds-ueditor", class: _vm.getCss },
    [
      _vm.showLabel
        ? _c(
            "DsfFieldLabel",
            {
              style: _vm.getLabelWidthStyle(),
              attrs: {
                mode: _vm.showDataCaptionMode,
                "show-icon": _vm.showDataCaptionIcon,
                trigger: _vm.showDataCaptionTrigger,
                "data-caption": _vm.dataCapion,
                "is-design": _vm.isDesign,
                owner: _vm._self,
              },
            },
            [_vm._v(" " + _vm._s(_vm.$t(_vm.label)) + " ")]
          )
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "ds-form-block",
          style: _vm.getFormItemBlockMarginLeft(),
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.readOnly,
                  expression: "!readOnly",
                },
              ],
              staticStyle: { width: "100%" },
            },
            [_c("div", { ref: "UEditor" })]
          ),
          _vm.isDesign && _vm.readOnly
            ? _c("div", {
                class: _vm.ueditorCss,
                attrs: { id: "designReadOnly" },
                domProps: { textContent: _vm._s(_vm.msg) },
              })
            : _vm._e(),
          !_vm.isDesign && _vm.readOnly
            ? _c("div", { class: _vm.ueditorCss }, [
                _c("div", {
                  ref: "uParse",
                  staticClass: "ds-ueditor-readonly",
                  style: _vm.readOnlyUParseStyle,
                  domProps: {
                    innerHTML: _vm._s(_vm.dsf.safe.xss(_vm.editorValue)),
                  },
                }),
                _vm.errors.length > 0
                  ? _c("div", { staticClass: "ds-error-text" }, [
                      _vm._v(" " + _vm._s(_vm.errorsMsg) + " "),
                    ])
                  : _vm._e(),
              ])
            : _vm._e(),
          _vm.errors.length > 0 && !_vm.readOnly
            ? _c("div", { staticClass: "ds-error-text" }, [
                _vm._v(" " + _vm._s(_vm.errorsMsg) + " "),
              ])
            : _vm._e(),
        ]
      ),
      _c("picture-cut-dialog", {
        attrs: { src: _vm.src, "dialog-visible": _vm.dialogVisible },
        on: {
          "update:src": function ($event) {
            _vm.src = $event
          },
          "cut-img": _vm.cutImg,
          "dialog-close": _vm.dialogClose,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }