<template>
  <div v-show="isDesign || visible" :style="controlStyle" class="ds-control ds-no-padding">
    <div :class="`ds-home-panel ${styleType ? `ds-home-panel-${styleType}` : ''}`" :is-design="isDesign" :showShadow="showShadow" :style="panelStyle">
      <div v-show="showHead" class="ds-home-panel-header">
        <div class="ds-home-panel-header-left">
          <slot name="header-title">
            <div v-if="titleIcon" class="ds-home-panel-icon">
              <DsfIcon :name="titleIcon"></DsfIcon>
            </div>
            <div v-if="title" class="ds-home-panel-title">{{ title }}</div>
          </slot>
          <slot name="header-left"></slot>
        </div>
        <div class="ds-home-panel-header-center">
          <slot name="header-center"></slot>
        </div>
        <div class="ds-home-panel-header-right">
          <slot name="header-right"></slot>
          <div v-if="addShow" class="ds-home-panel-more" @click.stop="panelIconBtnClick('add')">
            <template v-if="addText">{{ addText }}</template>
            <DsfIcon v-if="addIcon" :name="addIcon"></DsfIcon>
          </div>
          <div v-if="moreShow" class="ds-home-panel-more" @click.stop="panelIconBtnClick('more')">
            <template v-if="moreText">{{ moreText }}</template>
            <DsfIcon v-if="moreIcon" :name="moreIcon"></DsfIcon>
          </div>
          <div v-if="settingShow" class="ds-home-panel-more" @click.stop="panelIconBtnClick('setting')">
            <template v-if="settingText">{{ settingText }}</template>
            <DsfIcon v-if="settingIcon" :name="settingIcon"></DsfIcon>
          </div>
        </div>
      </div>
      <div slot-name="default" class="ds-home-panel-content" :style="{ padding: padding.join('px ') + 'px' }">
        <template v-if="isDesign">
          <slot></slot>
        </template>
        <template v-else>
          <DsfVirtualScroll :isDesign="isDesign">
            <slot></slot>
          </DsfVirtualScroll>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default dsf.component({
  name: "DsfHomePanel",
  ctrlCaption: "面板",
  mixins: [$mixins.control],
  provide() {
    return {
      $homePanel: this
    };
  },
  props: {
    showHead: {
      default: true
    },
    slots: {
      type: Array,
      default() {
        return [{ name: "default", controls: [] }];
      }
    },
    // 标题
    title: {
      type: String,
      default: "面板标题"
    },
    // 标题Icon
    titleIcon: {
      type: String,
      default: "qiapianmoshi_kuai"
    },
    // 显示阴影
    showShadow: {
      type: Boolean,
      default: true
    },
    // 圆角
    borderRadius: {
      type: Array,
      default() {
        return [0, 0, 0, 0];
      }
    },
    // 宽度
    width: {
      type: String,
      default: "100%"
    },
    // 高度
    height: {
      type: String,
      default: "100%"
    },
    // 更多图标 icon名称
    moreIcon: {
      type: String,
      default: "gengduo"
    },
    // 更多图标 icon名称
    moreText: {
      type: String,
      default: ""
    },
    // 更多图标 icon名称
    moreShow: {
      default: false
    },
    // 更多图标 icon名称
    addIcon: {
      type: String,
      default: "add"
    },
    // 更多图标 icon名称
    addText: {
      type: String,
      default: ""
    },
    // 更多图标 icon名称
    addShow: {
      default: false
    },
    // 更多图标 icon名称
    settingIcon: {
      type: String,
      default: "shezhi"
    },
    // 更多图标 icon名称
    settingText: {
      type: String,
      default: ""
    },
    // 更多图标 icon名称
    settingShow: {
      default: false
    },
    // 内边框
    padding: {
      type: Array,
      default() {
        return [0, 20, 0, 20];
      }
    },
    // 外边框
    margin: {
      type: Array,
      default() {
        return [0,0,0,0];
      }
    },
    // 风格
    styleType: {
      type: String,
      default: "one"
    },
    // 是否可见
    visible: {
      type: Boolean,
      default: true
    },
    // 更多按钮跳转方式
    moreConfig: {
      type: Object,
      default() {
        return {};
      }
    },
    // 新增按钮跳转方式
    addConfig: {
      type: Object,
      default() {
        return {};
      }
    },
    backgroundColor: {
      type: String,
      default: "#fff"
    }
  },
  computed: {
    controlStyle() {
      let { width, height, isDesign, visible } = this;
      let style = {
        width,
        padding: this.isDesign ? undefined : this.unitSize(this.margin).join(" "),
        height
      };
      if (isDesign) {
        // style.padding = "5px 5px 5px 5px";
        !visible && (style.opacity = "0.5");
      }
      return style;
    },
    panelStyle() {
      let { height, borderRadius, isDesign, backgroundColor } = this;
      let style = {
        borderRadius: borderRadius.join("px ") + "px",
        height: "100%",
        backgroundColor
      };
      let isBorderRadius = borderRadius.some((radius) => radius);
      if (isBorderRadius) style.overflow = "hidden";
      // if (isDesign) {
      //   if (height == "100%") {
      //     style.height = `calc(${height} - 10px)`;
      //   } else {
      //     style.height = `calc(${height} - 20px)`;
      //   }
      // }
      return style;
    }
  },
  data() {
    return {};
  },
  watch: {
    height: {
      immediate: true,
      handler(height) {
        let { isDesign } = this;
        if (isDesign) {
          this.$dispatch("design-height-change", height);
        }
      }
    }
  },
  methods: {
    panelIconBtnClick(type) {
      if (type == "more") {
        this.more();
      } else if (type == "add") {
        this.add();
      } else if (type == "setting") {
        if (this.$listeners["setting-click"]) {
          return this.$emit("setting-click", this);
        }
      }
    },
    more() {
      if (this.moreConfig && !this.moreConfig.title) {
        this.moreConfig.title = this.title;
      }
      if (this.$listeners["more-click"]) {
        return this.$emit("more-click", this);
      } else if (this.$listeners["moreBtn"]) {
        return this.$emit("moreBtn", this);
      }
      this.$open({ ...this.moreConfig }).closed = () => {
        this.reloadData();
      };
    },
    add() {
      if (this.$listeners["add-click"]) {
        return this.$emit("add-click", this);
      }
      this.$open({ ...this.addConfig }).closed = () => {
        this.reloadData();
      };
    },
    reloadData() {
      this.$childrenReloadData();
    }
  },
  design: {
    isMask: false
  }
});
</script>
