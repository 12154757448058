var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ds-control ds-form-item  ds-carnumber", class: _vm.getCss },
    [
      _vm.simple && _vm.readOnly && !_vm.isDesign
        ? [
            _c(
              "DsfTextProxy",
              _vm._b(
                {
                  model: {
                    value: _vm.value,
                    callback: function ($$v) {
                      _vm.value = $$v
                    },
                    expression: "value",
                  },
                },
                "DsfTextProxy",
                _vm._self.$props,
                false
              )
            ),
            _vm.value != ""
              ? _c("span", [_vm._v(_vm._s(_vm.append))])
              : _vm._e(),
            _vm._t("error", function () {
              return [
                _vm.errors.length > 0
                  ? _c("div", { staticClass: "ds-error-text" }, [
                      _vm._v(_vm._s(_vm.errorsMsg)),
                    ])
                  : _vm._e(),
              ]
            }),
          ]
        : [
            _vm.showLabel
              ? _c(
                  "DsfFieldLabel",
                  {
                    style: _vm.getLabelWidthStyle(),
                    attrs: {
                      mode: _vm.showDataCaptionMode,
                      "show-icon": _vm.showDataCaptionIcon,
                      trigger: _vm.showDataCaptionTrigger,
                      "data-caption": _vm.dataCapion,
                      "is-design": _vm.isDesign,
                      owner: _vm._self,
                    },
                  },
                  [_vm._v(" " + _vm._s(_vm.$t(_vm.label)) + " ")]
                )
              : _vm._e(),
            _c(
              "div",
              {
                staticClass: "ds-form-block",
                style: _vm.getFormItemBlockMarginLeft(),
              },
              [
                !_vm.readOnly
                  ? _c(
                      "el-popover",
                      {
                        staticClass: "ds-car-number-popper",
                        attrs: {
                          width: _vm.getWidth(),
                          transition: "el-zoom-in-top",
                        },
                        on: { hide: _vm.hideHandler },
                        model: {
                          value: _vm.isExtend,
                          callback: function ($$v) {
                            _vm.isExtend = $$v
                          },
                          expression: "isExtend",
                        },
                      },
                      [
                        [
                          _c("div", { ref: "keyboard" }, [
                            _c("div", { staticClass: "keyTitle" }),
                            _c("div", {
                              ref: "keyContent",
                              staticClass: "keyContent",
                              on: {
                                click: function ($event) {
                                  return _vm.keyboardClickHandler($event)
                                },
                                mousedown: function ($event) {
                                  $event.preventDefault()
                                  $event.stopPropagation()
                                },
                              },
                            }),
                          ]),
                        ],
                        _c(
                          "ul",
                          {
                            ref: "input",
                            staticClass: "clearfix ul_input",
                            attrs: { slot: "reference" },
                            slot: "reference",
                          },
                          [
                            _vm._l(_vm.size, function (index) {
                              return [
                                index < _vm.size ||
                                (index == _vm.size && _vm.isNewEnergy)
                                  ? _c(
                                      "li",
                                      {
                                        key: index,
                                        staticClass: "ds-carnumber-char",
                                        class: {
                                          focus: _vm.focusIndex == index,
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.focusHander(
                                              index,
                                              $event
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.inputs[index - 1]) +
                                              " "
                                          ),
                                        ]),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            }),
                            _c(
                              "li",
                              {
                                staticClass: "new-energy",
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    $event.preventDefault()
                                    return _vm.newEnergyChangeHander.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                              },
                              [
                                _c("span", [
                                  !_vm.isNewEnergy
                                    ? _c("i", {
                                        staticClass: "iconfont icon-hao",
                                      })
                                    : _c("i", {
                                        staticClass: "iconfont icon-guanbi2",
                                      }),
                                  _c(
                                    "span",
                                    { staticStyle: { display: "block" } },
                                    [_vm._v("新能源")]
                                  ),
                                ]),
                              ]
                            ),
                          ],
                          2
                        ),
                      ],
                      2
                    )
                  : _vm.readOnly
                  ? _c(
                      "div",
                      { staticClass: "ds-form-readonly" },
                      [
                        _c(
                          "DsfTextProxy",
                          _vm._b(
                            {
                              attrs: { "show-label": false },
                              model: {
                                value: _vm.value,
                                callback: function ($$v) {
                                  _vm.value = $$v
                                },
                                expression: "value",
                              },
                            },
                            "DsfTextProxy",
                            _vm._self.$props,
                            false
                          )
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._t("error", function () {
                  return [
                    _vm.errors.length > 0
                      ? _c("div", { staticClass: "ds-error-text" }, [
                          _vm._v(_vm._s(_vm.errorsMsg)),
                        ])
                      : _vm._e(),
                  ]
                }),
              ],
              2
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }