import DsfDataGridCellContent from "./datagridCellContent";
let tableColumnSlotName = "table-column";
let cardSlotName = "card";
/**
 * 私有方法
 */
function addColumn(column, index, parentColumn) {
  let defaultColumn = {
    label: "",
    columnId: "",
    level: 0,
    isLast: false,
    hide: false,
    headerAlign: "center",
    align: "center",
    resizable: false,
    width: "auto",
    children: []
  };
  let col = dsf.mix({}, defaultColumn, column);
  if (parentColumn) {
    if (dsf.isUnDef(index)) {
      parentColumn.children.push(col);
      col.level = parentColumn.level + 1;
    } else {
      parentColumn.children.splice(index, 0, col);
    }
  } else {
    dsf.isUnDef(index) ? this.columns.push(col) : this.columns.splice(index, 0, col);
  }
  return col;
}
function createDesignDefaultColumns() {
  if (!this.isDesign) {
    return;
  }
  if (this.columns.length == 0) {
    let size = 4;
    for (let i = 0; i < size; i++) {
      addColumn.call(this, {
        id: dsf.uuid(8),
        label: `列${i}`,
        level: 0,
        columnId: tableColumnSlotName + "-" + dsf.math.numToEnglishLetter(i + 1)
      });
    }
  }
}
//获取列头中各个单元格最大的单元格
function getLastColumns(column) {
  let arr = [];
  deep.call(
    this,
    (c) => {
      if (!c.children || !c.children.length) {
        c.isLast = true;
        arr.push(c);
      } else {
        c.columnId = "";
        c.isLast = false;
      }
    },
    column
  );
  return arr;
}
function deep(callback, column) {
  var fn = function (columns, level) {
    let columnIndex = 0;
    _.each(columns, (c, i) => {
      callback && callback(c, i, columnIndex, level);
      fn(c.children, level + 1);
      columnIndex++;
    });
  };
  let cols = column ? column : this.columns;
  cols = dsf.type(cols) !== "array" ? [cols] : cols;
  fn(cols, 0);
}
// 将宽度从realColumns中赋值到filterColumns
function updateWidthToFilterColumns(realColumns, filterColumns) {
  _.forEach(realColumns, (rc) => {
    let fc = _.find(filterColumns, (c) => c.id === rc.id);
    if (!fc) return;
    fc.width = rc.width;
    if (rc.children?.length) {
      updateWidthToFilterColumns(rc.children, fc.children);
    }
  });
}

// 按照列过滤的规则生成一份tableFilterResult
function createFilterResult(columns) {
  return _.map(columns, (rc) => {
    let { id, columnId, label, width, fixed, children } = rc;
    let obj = { id, columnId, label, width, fixed };
    if (children?.length) {
      obj.children = createFilterResult(children);
    }
    return obj;
  });
}

// 更新slots datagrid-hide
function updateSlots() {
  if (!this.isDesign) {
    return;
  }
  let slots = [getSlots.call(this, { id: "datagrid-hide" })];
  this.layoutInfo.forEach((l) => {
    if (l.type == "table") {
      slots = slots.concat(getSlotsByList.call(this));
    } else {
      slots.push(getSlots.call(this, l));
    }
  });
  this.$emit("update:slots", slots);
  if (!_.find(this.layoutInfo, (l) => l.id === this.currentShowLayout.id)) {
    this.currentShowLayout = this.layoutInfo[0];
  }
}
// 更新列表slots, 更新表头
function getSlotsByList() {
  let arr = this.getLastColumns();
  let slots = [];
  _.each(arr, (c, i) => {
    let columnId = c.columnId;
    // columnId = `${tableColumnSlotName}-${i}`;
    let slot = _.find(this.slots, (s) => s.name == columnId);
    if (!slot) {
      let newSlot = (slot = {
        name: columnId,
        controls: [
          //此处默认追加一层组件,el-table貌似没有行数据加载过程中的控制事件，所以需要控制单元格中的子元素只能通过追加的一层组件完成
          dsf.designer.createComponent(DsfDataGridCellContent, {
            "slot-name": "default"
          })
        ]
      });
      this.slots.push(newSlot);
      slot = newSlot;
    } else {
      slot.name = columnId;
    }
    c.columnId = columnId;
    if (slot.controls?.length && slot.controls[0].ctrlName == "DsfDataGridCellContent") {
      slot.controls[0].columnId = columnId;
    }
    slot.controls[0];
    slot.scope = "scope";
    slot.repeat = true;
    // slot.textProxy = this.tableTextProxy;
    slot.dataKey = "row";
    slots.push(slot);
  });
  slots = slots.sort((v1, v2) => {
    let n1 = _.findIndex(arr, (it) => it.columnId == v1.name);
    let n2 = _.findIndex(arr, (it) => it.columnId == v2.name);
    return n1 > n2 ? 1 : -1;
  });
  return slots;
}
// 更新slots
function getSlots({ id: name, type }) {
  let slot = _.find(this.slots, (s) => s.name == name);
  if (!slot) {
    slot = {
      name,
      controls: [],
      scope: "scope",
      dataKey: "row",
      repeat: true,
      textProxy: true
    };
  }
  return slot;
}

function headerSetting(item, proxy) {
  dsf.designer.triggerDialogPropty("columns");
}

function createDataGridMetadata(root) {
  let childMetaData = [];
  let currCellSlot = "";
  dsf.array.removeAll(this.childMetaDataCodes);
  dsf.designer.recursionLayoutTree(this, null, null, (child, parent, slot) => {
    if (child != this) {
      if (parent == this) {
        currCellSlot = slot;
      }
      let designOptions = child.$options.design || {};
      let metadataNode = designOptions.metadata || {};
      let result = metadataNode.create && metadataNode.create.call(child, root);
      if (result && result.metadata) {
        result.metadata.inColumn = currCellSlot;
        //判断当前列是否被隐藏
        let cell = $(child.$el).closest(".ds-datagrid-column-hide");
        if (cell.is(".ds-datagrid-column-hide")) {
          result.metadata.hidden = true;
        }
        childMetaData.push(result);
        let isDeep = dsf.isDef(metadataNode.isDeep) ? metadataNode.isDeep : true;
        if (!isDeep) {
          return false;
        }
      }
    }
  });
  let query = {};
  let searchBox = [],
    attachSearch = [];
  for (let i = 0; i < this.searchInfo.length; i++) {
    let s = this.searchInfo[i];
    let item = null;
    let format="";
    if(s.dataType=="datetime"){
      format="yyyy-MM-dd HH:mm:ss";
      if(s.format=='ymd'){
        format = "yyyy-MM-dd";
      }
      else if(s.format=='ym'){
        format = "yyyy-MM";
      }
      else if(s.format=='y'){
        format = "yyyy";
      }
    }
    if (s.type == "0") {
      item = {
        code: s.code,
        operator: s.operation,
        valueKey: "searchValue",
        from: "searchInput",
        dataType:s.dataType||"string",
        format: format
      };
      searchBox.push(item);
    } else {
      item = {
        code: s.code,
        operator: s.operation,
        valueKey: s.code,
        from: "default",
        dataType:s.dataType||"string",
        format: format
      };
      attachSearch.push(item);
      s.metadata_fullcode = item.code;
    }
  }
  //添加将检索框条件
  let seachBoxitem = { logic: "or", items: [] };
  seachBoxitem.items.push(...searchBox);

  //添加默认附加条件
  let attachSearchItem = { logic: "and", items: [] };
  attachSearchItem.items.push(...attachSearch);

  let searchitems = { logic: "and", items: [] };
  searchitems.items.push(seachBoxitem);
  searchitems.items.push(attachSearchItem);
  // seachBoxitem.items.length > 0 && searchitems.items.push(seachBoxitem);
  // attachSearchItem.items.length > 0 && searchitems.items.push(attachSearchItem);
  let superSearchItem = { logic: "and", items: [] };
  let superSearchArr = [];
  _.each(this.superSearchInfo, (el) => {
    let code = null;
    let operator = "";
    let valueKey = null;
    if (el.searchType == "enum") {
      let useFields = [];
      if (el?.children?.length > 0) {
        useFields = _.map(el.children, (f) => f.join == 1);
        useFields = useFields.slice(0, 1);
      } else {
        useFields = [el];
      }
      if (useFields.length == 1) {
        code = useFields[0].field;
        operator = useFields[0].operator;
        valueKey = useFields[0].field;
        if (operator == "in" || operator == "eq") {
          operator = "in";
        }
        let item = {
          code: code,
          operator: operator == "in" || operator == "eq" ? "in" : operator,
          valueKey: code,
          dataType: operator == "like" ? "enum" : "string",
          format: "",
          from: "superSearch"
        };
        superSearchArr.push(item);
      }
    } else if (el.searchType == "text") {
      code = el.field;
      operator = el.operator;
      valueKey = el.field;
      superSearchArr.push({
        code: code,
        operator: operator,
        valueKey: valueKey,
        dataType: "string",
        format: "",
        from: "superSearch"
      });
    } else if (el.searchType == "number") {
      code = el.field;
      operator = el.operator;
      valueKey = el.field;
      superSearchArr.push({
        code: code,
        operator: operator,
        valueKey: valueKey,
        dataType: "number",
        format: "",
        from: "superSearch"
      });
    } else if (el.searchType == "datetime") {
      let useFields = [];
      if (el?.children?.length > 0) {
        useFields = _.map(el.children, (f) => f.join == 1);
        useFields = useFields.slice(0, 1);
      } else {
        useFields = [el];
      }
      if (useFields.length == 1) {
        let format = "yyyy-MM-dd HH:mm:ss";
        if (el.model == "ymd") {
          format = "yyyy-MM-dd";
        } else if (el.model == "ym") {
          format = "yyyy-MM";
        } else if (el.model == "y") {
          format = "yyyy-MM-dd";
        }
        superSearchArr.push({
          code: useFields[0].field,
          operator: useFields[0].operator,
          valueKey: useFields[0].field,
          dataType: "datetime",
          format: format
        });
      }
    }
  });
  superSearchItem.items = superSearchArr;
  searchitems.items.push(superSearchItem);
  query = searchitems;
  let exportRules = {};
  //创建默认导出规则
  if (this.exportOptions["rule-1"] && this.exportOptions["rule-1"].length == 0) {
    let fields = [];
    for (let i = 0; i < childMetaData.length; i++) {
      let cm = childMetaData[i].metadata;
      if (cm.hidden) {
        continue;
      }
      _.each(cm.valueAttributes, (v) => {
        let table = cm.at.replace(/\./g, "_");
        let obj = {
          caption: cm.name + (cm.valueAttributes.length > 1 ? "(" + v.name + ")" : ""),
          code: cm.code,
          currentValueAttribute: v.code,
          format: "",
          id: table + "." + (cm.valueAttributes.length == 1 ? cm.code : cm.code + "_" + v.code),
          table: table,
          valueAttributes: _.map(cm.valueAttributes, "code"),
          style: {
            align: "center",
            headerAlign: "center"
          }
        };
        fields.push(obj);
      });
      this.exportOptions["rule-1"] = fields;
    }
  }
  _.each(this.exportOptions, (v, k) => {
    if (v && v.length > 0) {
      exportRules[this.caption + "." + k] = v;
    }
  });
  let metadata = {
    //查询条件
    query: query,
    //可以导出列的配置
    export: {
      ...exportRules
    },
    //浏览范围
    browseScope: this.browseScope,
    _self: this
  };
  dsf.array.removeAll(this.childMetaDataCodes);
  if (childMetaData.length) {
    _.each(childMetaData, (it) => {
      dsf.array.ensure(this.childMetaDataCodes, it.metadata_fullcode);
    });
    this.$emit("update:dataPrefix", childMetaData[0].metadata.at.replace(/\./g, "_"));
  }
  return [metadata, ...childMetaData];
}

let openDuplicateMessage = _.debounce(() => {
  dsf.layer.message("数据中_id的值存在重复，会导致页面出现未知的bug,请及时处理", false);
}, 100)

export {
  tableColumnSlotName,
  cardSlotName,
  addColumn,
  createDesignDefaultColumns,
  getLastColumns,
  deep,
  updateWidthToFilterColumns,
  createFilterResult,
  updateSlots,
  getSlotsByList,
  getSlots,
  headerSetting,
  createDataGridMetadata,
  openDuplicateMessage
}