<template>
  <DsfRow ref="row" :gutter="gutter" :overflow="overflow" :min-height="minHeight" :class="getCss">
    <slot></slot>
  </DsfRow>
  <!-- <el-row class="ds-control ds-row" :gutter="(isDesign?0:gutter)" :class="[isDesign ? '' : 'ds-no-padding', ...classList]">
    <el-col v-for="(slot, $index) in slots" :key="$index" :style="getStyle()" :md="size" :slot-name="slot.name">
      <slot :name="slot.name"></slot>
    </el-col>
  </el-row> -->
</template>
<script>
/**
 * @class DsfRow13
 * @augments Layout
 * @description 一行三列  
 * @example 
 * <DsfRow12></DsfRow12>
 */
export default dsf.component({
  name: "DsfRow13",
  ctrlCaption: "一行三列",
  mixins: [$mixins.layout],
  data() {
    return {};
  },
  /**
   * @memberof DsfRow13
   * @name Props 属性
   * @property {String} [minHeight = "0"] 最小高度
   * @property {Number} [gutter = "0"] 两端间距
   * @property {Array} [slots = "[{ name: 'default', controls: [] }]"] 插槽
   */
  props: {
    overflow:{
      type:String,
      default:"hidden"
    },
    minHeight: {
      type: String,
      default: "0"
    },
    gutter: {
      type: Number,
      default: 0
    },
    slots: {
      type: Array,
      default() {
        return [
          { name: 'default', controls: [] }
        ];
      }
    }
  },
  computed: {
    cols() {
      return this.$refs.row.cols;
    }
  },
  mounted() {
    createDesignerColumns.call(this);
  },
  methods: {
  }
});
function createDesignerColumns() {
  if (this.isDesign) {
    if (this.slots[0].controls.length == 0) {
      let size = 3
      for (let i = 0; i < size; i++) {
        let span = 24 / size;
        let obj = {
          'height': 'auto',
          'span': span
        }
        this.slots[0].controls.push(dsf.designer.createComponent('dsf.col', obj));
      }
    }
  }
}
</script>
