<template>
  <div class="ds-empty-data">
    <DsfIcon :name="icon"></DsfIcon>
    <span>{{text}}</span>
  </div>
</template>
<script>
export default {
  name: "DsfEmptyData",
  props: {
    text: {
      type: String,
      default: "暂无数据"
    },
    icon: {
      type: String,
      default: "no-data"
    }
  },
  data() {
    return {};
  },
  computed: {},
  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {},
  methods: {},
  beforeDestroy() {},
  destroyed() {}
};
</script>