var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "dsf-control ds-convenient-menu pc",
      class: _vm.rootClass,
      style: _vm.rootStyle,
    },
    [
      _vm.menuList.length === 1
        ? _c("dsf-convenient-menu-group", {
            attrs: { group: _vm.menuList[0] },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function (ref) {
                    var item = ref.item
                    var rowIndex = ref.rowIndex
                    var colIndex = ref.colIndex
                    return [
                      _vm._t(
                        "default",
                        function () {
                          return [
                            _c("dsf-convenient-menu-item", {
                              attrs: {
                                "display-style": _vm.displayStyle,
                                item: item,
                              },
                            }),
                          ]
                        },
                        {
                          item: item,
                          pageIndex: 0,
                          rowIndex: rowIndex,
                          colIndex: colIndex,
                        }
                      ),
                    ]
                  },
                },
              ],
              null,
              true
            ),
          })
        : _vm.menuList.length > 1
        ? [
            _c(
              "el-carousel",
              {
                ref: "carousel",
                attrs: {
                  loop: false,
                  autoplay: false,
                  height: "100%",
                  arrow: "never",
                },
                on: {
                  change: function ($event) {
                    _vm.activeIndex = $event
                  },
                },
              },
              _vm._l(_vm.menuList, function (group, index) {
                return _c(
                  "el-carousel-item",
                  { key: index },
                  [
                    _c("dsf-convenient-menu-group", {
                      attrs: { group: group },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (ref) {
                              var item = ref.item
                              var rowIndex = ref.rowIndex
                              var colIndex = ref.colIndex
                              return [
                                _vm._t(
                                  "default",
                                  function () {
                                    return [
                                      _c("dsf-convenient-menu-item", {
                                        attrs: {
                                          "display-style": _vm.displayStyle,
                                          item: item,
                                        },
                                      }),
                                    ]
                                  },
                                  {
                                    item: item,
                                    pageIndex: index,
                                    rowIndex: rowIndex,
                                    colIndex: colIndex,
                                  }
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    }),
                  ],
                  1
                )
              }),
              1
            ),
            _vm.arrow && _vm.menuList.length > 1
              ? [
                  _c(
                    "div",
                    {
                      staticClass: "ds-convenient-menu-arrow left",
                      class: { disabled: _vm.activeIndex == 0 },
                    },
                    [
                      _c("i", {
                        staticClass: "iconfont icon-arrow-left2",
                        on: {
                          click: function ($event) {
                            return _vm.$refs.carousel.prev()
                          },
                        },
                      }),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "ds-convenient-menu-arrow right",
                      class: {
                        disabled: _vm.activeIndex == _vm.menuList.length - 1,
                      },
                    },
                    [
                      _c("i", {
                        staticClass: "iconfont icon-arrow-right2",
                        on: {
                          click: function ($event) {
                            return _vm.$refs.carousel.next()
                          },
                        },
                      }),
                    ]
                  ),
                ]
              : _vm._e(),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }