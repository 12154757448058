var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.isDesign
    ? _c(
        "div",
        {
          staticClass: "ds-control ds-label-box pc",
          class: _vm.getCss,
          style: _vm.getStyle,
        },
        [
          _vm.hasHelper && _vm.dataCapionTarget == "text"
            ? [
                _vm.dataCapionType == "hoverPop" ||
                _vm.dataCapionType == "clickPop"
                  ? _c(
                      "el-popover",
                      {
                        staticClass: "ds_label-helper",
                        attrs: {
                          placement: "top",
                          title: "",
                          width: "200",
                          trigger:
                            _vm.dataCapionType == "hoverPop"
                              ? "hover"
                              : "click",
                        },
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "text",
                            attrs: { slot: "reference" },
                            slot: "reference",
                          },
                          [_vm._v(_vm._s(_vm.value))]
                        ),
                        _c("div", {
                          domProps: {
                            innerHTML: _vm._s(_vm.dsf.safe.xss(_vm.dataCapion)),
                          },
                        }),
                      ]
                    )
                  : _c(
                      "span",
                      { staticClass: "text", on: { click: _vm.showHelper } },
                      [_vm._v(_vm._s(_vm.value))]
                    ),
              ]
            : _c("span", { staticClass: "text" }, [_vm._v(_vm._s(_vm.value))]),
          _vm._t("helper", function () {
            return [
              _vm.hasHelper && _vm.dataCapionTarget == "icon"
                ? [
                    _vm.dataCapionType == "hoverPop" ||
                    _vm.dataCapionType == "clickPop"
                      ? _c(
                          "el-popover",
                          {
                            staticClass: "ds_label-helper",
                            attrs: {
                              placement: "top",
                              title: "",
                              width: "200",
                              trigger:
                                _vm.dataCapionType == "hoverPop"
                                  ? "hover"
                                  : "click",
                            },
                          },
                          [
                            _c("i", {
                              staticClass: "iconfont icon-bangzhuzhongxin",
                              attrs: { slot: "reference" },
                              slot: "reference",
                            }),
                            _c("div", {
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.dsf.safe.xss(_vm.dataCapion)
                                ),
                              },
                            }),
                          ]
                        )
                      : _c("i", {
                          staticClass:
                            "iconfont icon-bangzhuzhongxin ds_label-helper",
                          on: { click: _vm.showHelper },
                        }),
                  ]
                : _vm._e(),
            ]
          }),
        ],
        2
      )
    : !_vm.editting
    ? _c(
        "div",
        {
          staticClass: "ds-control ds-label-box pc",
          class: _vm.getCss,
          style: _vm.getStyle,
          on: { dblclick: _vm.beginEdit },
        },
        [
          _c("span", { staticClass: "text" }, [
            _vm._v(_vm._s(_vm.value || "无内容")),
          ]),
          _vm._t("helper", function () {
            return [
              _vm.dataCapion && _vm.dataCapionTarget == "icon"
                ? _c("i", {
                    staticClass:
                      "iconfont icon-bangzhuzhongxin ds_label-helper",
                  })
                : _vm._e(),
            ]
          }),
        ],
        2
      )
    : _c(
        "div",
        {
          staticClass: "ds-control ds-label-box pc",
          class: _vm.getCss,
          style: _vm.getStyle,
        },
        [
          _c("el-input", {
            ref: "contenteditable",
            attrs: {
              type: "textarea",
              autosize: "",
              placeholder: "请输入内容",
              resize: "none",
            },
            on: { blur: _vm.endEdit },
            model: {
              value: _vm.value,
              callback: function ($$v) {
                _vm.value = $$v
              },
              expression: "value",
            },
          }),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }