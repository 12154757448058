import { render, staticRenderFns } from "./tabItem.vue?vue&type=template&id=81a3dab2&functional=true&"
import script from "./tabItem.vue?vue&type=script&lang=js&"
export * from "./tabItem.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  null,
  null
  
)

component.options.__file = "pc/bhc/tabs/tabItem.vue"
export default component.exports