<template>
  <div class="choose-send-tree">
    <div class="fl-box ds_tree_box">
      <div class="dsf-tree-search">
        <el-input v-model="searchKey" placeholder="输入名称">
          <i slot="suffix" class="iconfont el-input__icon icon-Magnifier"></i>
        </el-input>
      </div>
      <el-collapse v-model="actives">
        <el-collapse-item
          v-for="(line, index) in linesData"
          :key="index"
          :title="line.Name"
          :name="index"
        >
          <SelectTree
            v-if="line.Receivers && line.Receivers.Dept"
            :tree-data="line.Receivers.Dept"
            :select.sync="line.selectDepts"
            :form-search-key="searchKey"
            :checked="line.checked"
            :multi="line.MultiplayerEnabled"
          ></SelectTree>
        </el-collapse-item>
      </el-collapse>
    </div>
    <div class="fl-box">
      <div class="top">
        <span>共{{ count }}人</span>
        <a class="ds-button" @click="deleteAll()">清空</a>
      </div>
      <el-collapse v-model="isActives">
        <el-collapse-item
          v-for="(line, index) in linesData"
          :key="index"
          :title="getTitle(line)"
          :name="index"
        >
          <el-table
            v-if="line && line.selectDepts.length > 0"
            class="table"
            :data="line.selectDepts"
            :show-header="false"
          >
            <el-table-column>
              <template slot-scope="scope">{{ scope.row.parentName }}</template>
            </el-table-column>
            <el-table-column>
              <template slot-scope="scope">{{ scope.row.Name }}</template>
            </el-table-column>
            <el-table-column
              label="操作"
              width="100"
              align="left"
              class="operation"
            >
              <template slot-scope="scope">
                <i
                  class="iconfont icon-shang"
                  @click="move(scope.$index, line.selectDepts, 'up')"
                ></i>
                <i
                  class="iconfont icon-xia"
                  @click="move(scope.$index, line.selectDepts, 'down')"
                ></i>
                <i
                  class="iconfont icon-guanbi1"
                  @click="deleteRow(scope.$index, line)"
                ></i>
              </template>
            </el-table-column>
          </el-table>
        </el-collapse-item>
      </el-collapse>
    </div>
  </div>
</template>
<script>
import SelectTree from "./selectTree";
export default {
  name: "ChooseSendTree",
  components: {
    SelectTree,
  },
  props: {
    lines: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      linesData: [],
      actives: [],
      isActives: [],
      searchKey: "",
      param: [],
    };
  },
  computed: {
    count() {
      let count = 0;
      this.linesData.forEach((line, index) => {
        count += line.selectDepts.length;
      });
      return count;
    },
  },
  created() {
    this.initData();
  },
  mounted() {},
  methods: {
    yes() {
      return this.param.join(";");
    },
    validate() {
      let param = [];
      let required = [];
      this.linesData.forEach((line) => {
        if (line.Required && (!line?.selectDepts || line?.selectDepts?.length === 0)) {
          required.push(line.Name);
        }
        if (line.selectDepts && line.selectDepts.length > 0) {
          let id = line.LineID + ":";
          line.selectDepts.forEach((c, cI) => {
            id += (cI > 0 ? "," : "") + c.ID;
          });
          param.push(id);
        }
      });
      if (required.length > 0) {
        console.log(required)
        dsf.layer.message(required.join("，") + "为必选环节，请选择发送对象", false);
        return false;
      }
      if (param.length == 0) {
        dsf.layer.message("请选择办理人", false);
        return false;
      }
      this.param = param;
      return true;
    },
    initData() {
      const lines = this.lines.filter((line) => line.Receivers);
      const linesData = dsf.mix(true, [], lines);
      linesData.forEach((line, index) => {
        this.actives.push(index);
        this.isActives.push(index);
        line.selectDepts = [];
        line.checked = [];
        if (line.Receivers && line.Receivers.Dept) {
          line.Receivers.Dept = this.getTreeData(line.Receivers.Dept, line);
        }
      });
      this.linesData = linesData;
    },
    getTreeData(Dept, line) {
      // Dept.selectDepts = [];
      // Dept.checked = [];
      const data = this.format(Dept, null, line);
      return [data];
    },
    format(Dept, parent, line) {
      Dept.label = Dept.Name;
      Dept.id = Dept.ID;
      Dept.disabled = Dept.Type !== 1 && Dept.Type !== 2;
      if (parent) {
        Dept.parentName = parent.Name;
        Dept.parentID = parent.ID;
      }
      // 能否多选
      const multi = line.MultiplayerEnabled;

      if (multi) {
        // Selected !== 0
        if (Dept.Selected) {
          line.checked.push(Dept.ID);
          line.selectDepts.push(Dept);
        }
      } else {
        if (Dept.Selected && Dept.Selected === 1) {
          if (
            (line.selectDepts && line.selectDepts.length === 0) ||
            (line.selectDepts && line.selectDepts[0].Selected !== 2)
          ) {
            line.checked = [Dept.ID];
            line.selectDepts = [Dept];
          }
        }
        if (Dept.Selected && Dept.Selected === 2) {
          line.checked = [Dept.ID];
          line.selectDepts = [Dept];
        }
      }
      let parentNode = {};
      if (Dept.ChildDepts && Dept.ChildDepts.length === 0) {
        // 特别处理 子节点 只有一个角色情况下 显示父节点
        const Objs = (Dept.Objs && Dept.Objs[0]) || {};
        if (!Objs.Objs && !Objs.ChildDepts && Objs.Type === 2) {
          Dept.disabled = false;
          Dept.ID = Objs.ID;
        } else {
          parentNode.Name = Dept.Name;
          parentNode.ID = Dept.ID;
          Dept.children = Dept.Objs;
          Dept.children.forEach((item) => {
            this.format(item, parentNode, line);
          });
        }
      } else if (Dept.ChildDepts) {
        parentNode.Name = Dept.Name;
        parentNode.ID = Dept.ID;
        Dept.children = Dept.ChildDepts;
        if (Dept.Objs) {
          Dept.children.push(...Dept.Objs);
        }
        Dept.children.forEach((item) => {
          this.format(item, parentNode, line);
        });
      }
      return Dept;
    },
    getTitle(line) {
      return `${line.Name}(${line.selectDepts.length})`;
    },
    deleteAll() {
      this.linesData.forEach((line, index) => {
        line.selectDepts = [];
        line.checked = [];
      });
    },
    deleteRow(index, line) {
      line.selectDepts.splice(index, 1);
      line.checked = [];
      line.selectDepts.forEach((item) => {
        line.checked.push(item.id);
      });
    },
    move(index, data, dir) {
      if (
        (index === 0 && dir === "up") ||
        (index === data.length - 1 && dir === "down")
      ) {
        return;
      }
      const changeIndex = dir === "up" ? index - 1 : index + 1;
      let upDate = data[changeIndex];
      data.splice(changeIndex, 1);
      data.splice(index, 0, upDate);
    },
  },
};
</script>
