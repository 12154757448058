<template>
  <div class="ds-control ds-table-layout" :class="getCss">
    <div class="ds-table-layout-box">
      <table
        ref="table"
        v-resize.x.y="{
          colList: 'columnsInfo',
          rowList: 'rows',
          onChangeCol: onChangeCol,
          onChangeRow: onChangeRow,
        }">
        <thead>
          <tr v-if="columnsInfo && rows && rows.length > 0">
            <th v-for="(col, $index) in columnsInfo" :key="$index" :style="{ width: col.width || 'auto' }"></th>
          </tr>
        </thead>
        <tbody>
          <slot name="prepend"></slot>
          <slot name="default"></slot>
          <slot name="append"></slot>
        </tbody>
      </table>
      <div
        v-if="isDesign"
        v-show="rangeWrap.show"
        :style="{
          height: rangeWrap.height + 'px',
          width: rangeWrap.width + 'px',
          top: rangeWrap.top + 'px',
          left: rangeWrap.left + 'px',
        }"
        class="selected-range"></div>
    </div>
  </div>
</template>
<script>
import directives from "./tableResize";
import DsfTableRow from "./tableRow";
import DsfTableCell from "./tableCell";

/**
 * @class DsfTable
 * @augments Control
 * @description 表格布局
 * @example
 * <DsfTable></DsfTable>
 */
export default dsf.component({
  name: "DsfTable",
  ctrlCaption: "表格布局",
  mixins: [$mixins.control],
  provide() {
    return {
      $tableLayout: this
    };
  },
  components: {
    DsfTableRow,
    DsfTableCell
  },
  directives,
  data() {
    return {
      rangeWrap: {
        show: false,
        width: 0,
        height: 0,
        top: 0,
        left: 0,
        selected: []
      },
      allCells: {},
      matrix: [],
      isDown: false,
      downCell: null,
      cellNums: 0,
      tableRows: [],
      cellZIndex: 1
    };
  },
  computed: {
    rows() {
      return this.getRows();
    },
    getCss() {
      return [
        ...this.$getClass(),
        // this.showBorder ? "" : "ds-no-border",
        this.borderStyle == "none" ? "ds-no-border" : "",
        // !this.visible ? 'ds-hide' : '',
        !this.noPadding ? "" : "ds-no-padding",
        this.fixedLayout ? "ds-table-fixed" : "",
        ...this.classList
      ];
    },
    $border() {
      let style = {};
      if (this.borderWidth) {
        style["border-top-width"] = this.unitSize(this.borderWidth);
        style["border-left-width"] = this.unitSize(this.borderWidth);
      }
      if (this.borderColor) {
        style["border-top-color"] = this.unitSize(this.borderColor);
        style["border-left-color"] = this.unitSize(this.borderColor);
      }
      if (this.borderStyle) {
        style["border-top-style"] = this.unitSize(this.borderStyle);
        style["border-left-style"] = this.unitSize(this.borderStyle);
      }
      return style;
    }
  },
  props: {
    // 复制粘贴进来的html片段
    clipboardString: {
      type: String,
      default: ""
    },
    slots: {
      type: Array,
      default() {
        return [
          {
            name: "default",
            controls: []
          }
        ];
      }
    },
    columnsInfo: {
      type: Array,
      default() {
        return [];
      }
    },
    rowsInfo: {
      type: Array,
      default() {
        return [];
      }
    },
    showBorder: {
      type: Boolean,
      default() {
        return true;
      }
    },
    fixedLayout: {
      type: Boolean,
      default() {
        return true;
      }
    },
    isBlockControl: {
      type: Boolean,
      default: true
    },
    borderWidth: {
      type: Number,
      default: 1
    },
    borderStyle: {
      type: String,
      default: "solid"
    },
    borderColor: {
      type: String,
      default: ""
    }
  },
  created() {
    if (this.isDesign && this.slots[0].controls.length === 0) {
      // 如果是粘贴进来的table
      if (this.clipboardString) {
        fromClipboardString.call(this);
      } else {
        // 设计模式下 默认情况
        let size = 4;
        for (let i = 0; i < size; i++) {
          addColumn.call(this);
        }
        //设计模式下默认生成4行
        for (let i = 0; i < size; i++) {
          let row = addRow.call(this);
          for (let n = 0; n < size; n++) {
            addCell.call(this, row);
          }
        }
      }
    }
  },
  mounted() {
    if (this.isDesign) {
      parseMatrix.call(this);
    }
  },
  updated() {
    if (this.isDesign) {
      updateRangeWrap.call(this, this.rangeWrap.selected);
    }
  },
  methods: {
    updateRangeWrap() {
      updateRangeWrap.call(this, this.rangeWrap.selected);
    },
    getRows() {
      if (this.isDesign) {
        return this.slots[0].controls;
      } else {
        return this.tableRows;
      }
    },
    getCells(row) {
      return row.slots[0].controls;
    },
    getCellWidth(cell, index) {
      let width;
      if (cell) {
        if (cell.colspan === 1) {
          width = this.columnsInfo[index].width;
        }
      } else {
        width = this.columnsInfo[index].width;
      }
      return width || "auto";
    },
    isSelected(rindex, cindex) {
      let result = _.find(this.rangeWrap.selected, (c) => {
        return rindex === c.rowIndex && cindex === c.cellIndex;
      });
      return result ? true : false;
    },
    // 列宽被改变
    onChangeCol(colList) {
      this.$emit("update:columnsInfo", colList);
    },
    // 行高被改变
    onChangeRow(index, height) {
      this.rows[index].height = height;
    }
  },
  design: {
    isMask: false,
    //右键菜单处理
    contextMenuHandler(evt) {
      let target = evt.target;
      target = $(evt.target).closest("TD");
      target = filterSelfCell.call(this, target);
      if (target && target.length && target.get(0).tagName === "TD") {
        target = $(target);
        let pos = target.attr("c-index").split(",");
        let rowIndex = Number(pos[0]);
        let cellIndex = Number(pos[1]);
        //如果右键的单元格不再已经框选的范围内则重新以右键的单元格为当前选中单元格
        let result = _.findIndex(this.rangeWrap.selected, (c) => {
          return c.rowIndex === rowIndex && c.cellIndex === cellIndex;
        });
        if (result < 0) {
          this.downCell = target;
          this.rangeWrap.show = true;
          let cells = getRangeSelectedCells.call(this, this.downCell, null);
          updateRangeWrap.call(this, cells);
        }
        return [
          { text: "合并单元格", hide: true, handler: mergeCellsHanlder },
          {
            text: "拆分单元格",
            disabled: getMergedCells.call(this).length <= 0,
            handler: splitCellHandler
          },
          "-",
          { text: "插入行(前)", disabled: false, handler: insertRowBefore },
          { text: "插入行(后)", disabled: false, handler: insertRowAfter },
          { text: "插入列(前)", disabled: false, handler: insertColumnBefore },
          { text: "插入列(后)", disabled: false, handler: insertColumnAfter },
          "-",
          { text: "删除整行", disabled: false, handler: deleteRows },
          { text: "删除整列", disabled: false, handler: deleteColumns },
          "-",
          { text: "重置列宽", disabled: false, handler: resetColWidth },
          { text: "重置行高", disabled: false, handler: resetRowHeight },
          "-",
          { text: "单元格设置", disabled: false, handler: setTableCell },
          { text: "清除选中格式", disabled: false, handler: clearChooseCell },
          { text: "清除所有格式", disabled: false, handler: clearAllCell }
        ];
      }
    },
    //清空右键菜单弹出过的痕迹
    clearContextMemuHandler() {
      this.isDown = false;
      clearRangeWrap.call(this);
    },
    //设计器全局的mousedown事件，设计器会向下级所有组件触发,可用于清理当前触发过mousedown事件留下的痕迹
    clearMouseDownHandler() {
      this.isDown = false;
      clearRangeWrap.call(this);
    },
    //鼠标down事件处理
    mouseDownHandler(evt, designer, target) {
      if (this.isDesign && evt.button === 0) {
        this.isDown = true;
        this.downCell = $(target);
        this.rangeWrap.show = true;
        parseMatrix.call(this);
        let cells = getRangeSelectedCells.call(this, this.downCell, null);
        updateRangeWrap.call(this, cells);
        evt.stopPropagation();
      }
    },
    //鼠标移动事件处理
    mouseMoveHandler(evt) {
      if (this.isDown) {
        let overCell = $(evt.target).closest("TD");
        let target = null;
        do {
          target = filterSelfCell.call(this, overCell);
          if (!target) {
            overCell = overCell.parents("TD");
            if (overCell.length === 0) {
              return;
            }
          }
        } while (!target);
        if (overCell) {
          let cells = getRangeSelectedCells.call(this, this.downCell, overCell);
          updateRangeWrap.call(this, cells);
          evt.stopPropagation();
        }
      }
    },
    //鼠标up事件处理
    mouseUpHandler() {
      this.isDown = false;
      parseMatrix.call(this);
    }
  }
});

function fromClipboardString() {
  let clipboardTable = $(this.clipboardString).appendTo("body");
  const trs = clipboardTable.find("tr");
  let columns = [];
  for (let i = 0; i < trs.length; i++) {
    let tr = $(trs[i]);
    const tds = tr.find("td");
    let columnNum = 0;
    _.each(tds, (td) => {
      td = $(td);
      const colspan = ~~td.attr("colspan") || 1;
      columnNum += colspan;
      if (colspan <= 1);
      {
        columns[columnNum - 1] = {
          width: td.innerWidth()
        };
      }
    });
    let row = addRow.call(this);
    for (let j = 0; j < tds.length; j++) {
      const td = $(tds[j]);
      if (td) {
        const rowspan = ~~td.attr("rowspan") || 1;
        const colspan = ~~td.attr("colspan") || 1;
        const text = td.html();
        addCell.call(this, row, { rowspan, colspan, text });
      }
    }
  }
  let sum = 0;
  _.each(columns, (it) => {
    sum += ~~it.width;
  });
  for (let c = 0; c < columns.length; c++) {
    let w = (~~columns[c].width / sum) * 100;
    addColumn.call(this, null, w + "%");
  }
  clipboardTable.remove();
}

function addRow() {
  let row = createRow();
  this.slots[0].controls.push(row);
  return row;
}
function addCell(row, slots) {
  let cell = createCell(slots);
  row.slots[0].controls.push(cell);
  return cell;
}
function addColumn(index, width = "") {
  if (dsf.isUnDef(index) || index < 0) {
    this.columnsInfo.push({ width: width });
  } else {
    this.columnsInfo.splice(index, 0, { width: width });
  }
}
function removeColumn(index) {
  dsf.array.removeAt(this.columnsInfo, index);
}
//获取被框选的单元格
function getRangeSelectedCells(start, end) {
  let selected = [];
  if (end && start.get(0) != end.get(0)) {
    if (!start.attr("c-index") || !end.attr("c-index")) {
      return;
    }
    let pos_s = start.attr("c-index").split(",");
    let ri_s = Number(pos_s[0]);
    let ci_s = Number(pos_s[1]);
    let pos_e = end.attr("c-index").split(",");
    let ri_e = Number(pos_e[0]);
    let ci_e = Number(pos_e[1]);
    let min_c = Math.min(ci_s, ci_e);
    let max_c = Math.max(ci_s, ci_e);
    let min_r = Math.min(ri_s, ri_e);
    let max_r = Math.max(ri_s, ri_e);
    let _selected = [];
    //进行第一遍过滤，得出选中的单元格索引，因为有合并单元格的情况，第一遍并不完整
    for (let i = min_r; i <= max_r; i++) {
      let rowIndex = i;
      for (let n = min_c; n <= max_c; n++) {
        let cellIndex = n;
        let cell = this.matrix[rowIndex][cellIndex]; // getCellByIndex.call(this, rowIndex, cellIndex);
        // cell = { 'rowIndex': cell.rowIndex, 'cellIndex': cell.cellIndex }
        if (cell) {
          _selected.push(cell);
        }
      }
    }
    min_c = 99999;
    max_c = 0;
    min_r = 99999;
    max_r = 0;
    //进行第二遍过滤
    for (let i = 0; i < _selected.length; i++) {
      let rowIndex = _selected[i].rowIndex;
      let cellIndex = _selected[i].cellIndex;
      let cell = this.matrix[rowIndex][cellIndex];
      if (cell.isSpan) {
        cell = this.matrix[cell.rowIndex][cell.cellIndex];
      }
      min_r = Math.min(min_r, cell.rowIndex);
      max_r = Math.max(max_r, cell.rowIndex + cell.rowspan - 1);
      min_c = Math.min(min_c, cell.cellIndex);
      max_c = Math.max(max_c, cell.cellIndex + cell.colspan - 1);
    }
    for (let i = min_r; i <= max_r; i++) {
      let rowIndex = i;
      for (let n = min_c; n <= max_c; n++) {
        let cellIndex = n;
        let cell = this.matrix[rowIndex][cellIndex];
        if (cell.isSpan) {
          cell = this.matrix[cell.rowIndex][cell.cellIndex];
        }
        if (cell) {
          dsf.array.ensure(selected, cell);
        }
      }
    }
  } else {
    if (!start.attr("c-index")) {
      return;
    }
    let pos_s = start.attr("c-index").split(",");
    let rowIndex = Number(pos_s[0]);
    let cellIndex = Number(pos_s[1]);
    let cell = getCellByIndex.call(this, rowIndex, cellIndex);
    if (cell) {
      dsf.array.ensure(selected, cell);
    }
  }
  return selected;
}

/*获取框选矩形范围 */
function getRangeSelectedRect() {
  let cells = this.rangeWrap.selected;
  let sr = 9999,
    er = 0,
    sc = 9999,
    ec = 0;
  _.each(cells, (c) => {
    let y = c.rowIndex;
    let x = c.cellIndex;
    if (sr > y) {
      sr = y;
    }
    if (er < y + c.rowspan - 1) {
      er = y + c.rowspan - 1;
    }
    if (sc > x) {
      sc = x;
    }
    if (ec < x + c.colspan - 1) {
      ec = x + c.colspan - 1;
    }
  });
  return {
    maxRowIndex: er,
    minRowIndex: sr,
    maxCellIndex: ec,
    minCellIndex: sc
  };
}

//创建列
function createCell(slots = {}) {
  return dsf.designer.createComponent(DsfTableCell, slots);
}
//创建行
function createRow() {
  return dsf.designer.createComponent(DsfTableRow);
}
//计算合并单元格
function mergeCellsHanlder() {
  let cells = this.rangeWrap.selected;
  let range = getRangeSelectedRect.call(this);
  let r = cells[0].rowIndex;
  let c = cells[0].cellIndex;
  let rows = this.getCells(this.rows[r]);

  let cell = _.find(rows, (ce) => ce.cellIndex === c);
  mergeCells.call(this, cell, range.maxRowIndex - range.minRowIndex + 1, range.maxCellIndex - range.minCellIndex + 1);
}

//拆分单元格
function splitCellHandler() {
  let cells = getMergedCells.call(this);
  splitCell.call(this, cells);
}

//获取被合并的单元格
function getMergedCells() {
  let selected = this.rangeWrap.selected;
  let cells = [];
  for (let i = 0; i < selected.length; i++) {
    let c = selected[i];
    if (c.rowspan > 1 || c.colspan > 1) {
      cells.push(c);
    }
  }
  return cells;
}

//将表格转换为矩阵数组
function parseMatrix() {
  let matrix = []; //let offsetArray = [];
  let rows = this.getRows();
  if (rows && rows.length) {
    let allCells = {};
    _.each(rows, () => {
      matrix.push([]);
      // offsetArray.push(0);
    });
    for (let i = 0; i < rows.length; i++) {
      let row = rows[i];
      let cells = this.getCells(row);
      let offset_cell = 0;
      for (let n = 0; n < cells.length; n++) {
        let cell = cells[n];
        cell.rowIndex = i;
        cell.cellIndex = n;
        let rs = cell.rowspan || 1;
        let cs = cell.colspan || 1;
        while (matrix[i][offset_cell] !== undefined) {
          offset_cell++;
        }
        for (let r = i; r < i + rs; r++) {
          for (let c = offset_cell; c < offset_cell + cs; c++) {
            if (r === i && c === offset_cell) {
              matrix[r][c] = cell;
              cell.rowIndex = i;
              cell.cellIndex = c;
              allCells[cell.rowIndex + "," + cell.cellIndex] = cell;
            } else {
              matrix[r][c] = {
                isSpan: true,
                rowIndex: i,
                cellIndex: offset_cell
              };
            }
          }
        }
      }
    }
    this.allCells = allCells;
  }
  this.matrix = matrix;
  let columnLength = this.matrix[0] ? this.matrix[0].length : 0;
  _.eachRight(this.columnsInfo, (c, i) => {
    if (i > columnLength - 1) {
      dsf.array.remove(c);
    }
  });
  return matrix;
}

//通过单元格获取索引
function getIndexByCellProp(prop) {
  for (let k in this.allCells) {
    if (this.allCells[k] === prop) {
      let pos = k.split(",");
      return {
        rowIndex: Number(pos[0]),
        cellIndex: Number(pos[1])
      };
    }
  }
  return null;
}

//通过索引号获取单元格对象
function getCellByIndex(rowIndex, cellIndex) {
  return this.allCells[rowIndex + "," + cellIndex];
}

//通过矩阵数组更新表格数据
function updateRowsAndCells(m) {
  // let arr = [];
  for (let i = 0; i < m.length; i++) {
    let row = m[i];
    let arrs = [];
    for (let c = 0; c < row.length; c++) {
      let cell = row[c];
      if (cell && !cell.isSpan) {
        arrs.push(cell);
      }
    }
    this.rows[i].slots[0].controls = arrs;
  }
  parseMatrix.call(this);
  this.$designer?.recordPageChange?.();
}

//更新选中区域
function updateRangeWrap(cells) {
  if (!this.isDesign) {
    return;
  }
  if (!cells) {
    return;
  }
  let top = Number.MAX_VALUE,
    left = Number.MAX_VALUE,
    right = Number.MIN_VALUE,
    bottom = Number.MIN_VALUE;
  for (let i = 0; i < cells.length; i++) {
    let rowIndex = cells[i].rowIndex;
    let cellIndex = cells[i].cellIndex;
    let cell = $(this.$el).find("td[c-index='" + rowIndex + "," + cellIndex + "']");
    cell = filterSelfCell.call(this, cell);
    if (cell) {
      let cellOffset = cell.eq(0).position();
      let _top = cell.parent().position().top;
      let _left = cellOffset.left;
      let isBorderLeftWidth = cell.css("border-left-width") ? parseFloat(cell.css("border-left-width")) > 0 : false;
      let isBorderTopWidth = cell.css("border-top-width") ? parseFloat(cell.css("border-top-width")) > 0 : false;
      if (isBorderLeftWidth) {
        _left = _left + parseFloat(cell.css("border-left-width"));
      }
      if(isBorderTopWidth){
        _top = _top + parseFloat(cell.css("border-top-width"));
      }
      top = Math.min(top, _top);
      left = Math.min(left, _left);
      right = Math.max(right, _left + cell.innerWidth());
      bottom = Math.max(bottom, _top + cell.innerHeight());
    }
  }
  let width = right - left;
  let height = bottom - top;

  this.rangeWrap.width = width - 4;
  this.rangeWrap.height = height - 4;
  this.rangeWrap.left = left - 2;
  this.rangeWrap.top = top - 2;
  this.rangeWrap.selected = cells;
}

//清空框选
function clearRangeWrap() {
  this.rangeWrap.show = false;
  this.rangeWrap.width = 0;
  this.rangeWrap.height = 0;
  this.rangeWrap.left = -10;
  this.rangeWrap.top = -10;
  this.rangeWrap.selected = [];
}

//过滤自身的td
function filterSelfCell(cells) {
  for (let i = 0; i < cells.length; i++) {
    let cell = cells.eq(i);
    let table = cell.closest("table");
    if (this.$refs.table === table.get(0)) {
      return cell;
    }
  }
  return null;
}

//合并单元格
function mergeCells(cell, rowSpan, cellSpan) {
  rowSpan = rowSpan || 1;
  cellSpan = cellSpan || 1;
  let pos = getIndexByCellProp.call(this, cell);
  let m = parseMatrix.call(this);
  for (let i = pos.rowIndex; i < pos.rowIndex + rowSpan; i++) {
    for (let n = pos.cellIndex; n < pos.cellIndex + cellSpan; n++) {
      if (i === pos.rowIndex && n === pos.cellIndex) {
        m[i].splice(n, 1, cell);
        cell.rowspan = rowSpan;
        cell.colspan = cellSpan;
      } else {
        m[i].splice(n, 1, {
          isSpan: true,
          rowIndex: cell.rowIndex,
          cellIndex: cell.cellIndex
        });
      }
    }
  }
  updateRowsAndCells.call(this, m);
}

//拆分单元格
function splitCell(cells) {
  parseMatrix.call(this);
  _.each(cells, (c) => {
    for (let i = c.rowIndex; i < c.rowIndex + c.rowspan; i++) {
      for (let n = c.cellIndex; n < c.cellIndex + c.colspan; n++) {
        let cell = this.matrix[i][n];
        if (cell.isSpan) {
          this.matrix[i][n] = createCell();
        }
      }
    }
    c.rowspan = 1;
    c.colspan = 1;
  });
  updateRowsAndCells.call(this, this.matrix);
}

//插入行（前）
function insertRowBefore() {
  parseMatrix.call(this);
  let _temp = [];
  let range = getRangeSelectedRect.call(this);
  let currIndex = range.minRowIndex;
  let insertRow = this.matrix[currIndex];
  let row = createRow();
  for (let i = 0; i < insertRow.length; i++) {
    let cell = insertRow[i];
    if (cell.isSpan) {
      cell = this.matrix[cell.rowIndex][cell.cellIndex];
    }
    if (cell.rowspan > 1 && currIndex > cell.rowIndex) {
      dsf.array.ensure(_temp, cell);
      continue;
    }
    let newCell = createCell();
    row.slots[0].controls.push(newCell);
  }
  _.each(_temp, (cell) => {
    cell.rowspan++;
  });
  this.rows.splice(currIndex, 0, row);
  parseMatrix.call(this);
}
//插入行（后）
function insertRowAfter() {
  parseMatrix.call(this);
  let _temp = [];
  let range = getRangeSelectedRect.call(this);
  let currIndex = range.maxRowIndex;
  let insertRow = this.matrix[currIndex];
  let row = createRow();
  for (let i = 0; i < insertRow.length; i++) {
    let cell = insertRow[i];
    if (cell.isSpan) {
      cell = this.matrix[cell.rowIndex][cell.cellIndex];
    }
    if (cell.rowspan > 1 && currIndex < cell.rowIndex + cell.rowspan - 1) {
      dsf.array.ensure(_temp, cell);
      continue;
    }
    let newCell = createCell();
    row.slots[0].controls.push(newCell);
  }
  _.each(_temp, (cell) => {
    cell.rowspan++;
  });
  this.rows.splice(currIndex + 1, 0, row);
  parseMatrix.call(this);
}

//插入列（前）
function insertColumnBefore() {
  parseMatrix.call(this);
  let _temp = [];
  let range = getRangeSelectedRect.call(this);
  let currIndex = range.minCellIndex;
  for (let i = 0; i < this.rows.length; i++) {
    let row = this.rows[i];
    let mrow = this.matrix[i];
    let cell = mrow[currIndex];
    if (cell.isSpan) {
      cell = this.matrix[cell.rowIndex][cell.cellIndex];
    }
    if (cell.colspan > 1 && currIndex > cell.cellIndex) {
      dsf.array.ensure(_temp, cell);
      continue;
    }
    let newCell = createCell();
    row.slots[0].controls.splice(currIndex, 0, newCell);
  }
  _.each(_temp, (cell) => {
    cell.colspan++;
  });
  addColumn.call(this, currIndex);
  parseMatrix.call(this);
}

//插入列（后）
function insertColumnAfter() {
  parseMatrix.call(this);
  let _temp = [];
  let range = getRangeSelectedRect.call(this);
  let currIndex = range.minCellIndex;
  for (let i = 0; i < this.rows.length; i++) {
    let row = this.rows[i];
    let mrow = this.matrix[i];
    let cell = mrow[currIndex];
    if (cell.isSpan) {
      cell = this.matrix[cell.rowIndex][cell.cellIndex];
    }
    if (cell.colspan > 1 && currIndex < cell.cellIndex + cell.colspan - 1) {
      dsf.array.ensure(_temp, cell);
      continue;
    }
    let newCell = createCell();
    row.slots[0].controls.splice(currIndex + 1, 0, newCell);
  }
  _.each(_temp, (cell) => {
    cell.colspan++;
  });
  addColumn.call(this, currIndex + 1);
  parseMatrix.call(this);
}

//删除行
function deleteRows() {
  parseMatrix.call(this);
  let range = getRangeSelectedRect.call(this);
  // let currIndex = range.minRowIndex;
  for (let i = range.maxRowIndex; i >= range.minRowIndex; i--) {
    const row = this.matrix[i];
    let n = row.length - 1;
    while (n >= 0) {
      let cell = row[n];
      if (cell.isSpan) {
        cell = this.matrix[cell.rowIndex][cell.cellIndex];
      }
      if (cell.rowspan > 1) {
        if (i > cell.rowIndex) {
          cell.rowspan--;
          n -= cell.colspan;
        } else {
          //补齐单元格
          if (cell.rowIndex === i && cell.cellIndex === n) {
            let newCell = createCell();
            newCell.rowspan = cell.rowspan - 1 > 1 ? cell.rowspan - 1 : 1;
            newCell.colspan = cell.colspan;
            let pos = _.findIndex(this.rows[i + 1].slots[0].controls, (c) => {
              return c.cellIndex > cell.cellIndex;
            });
            if (pos >= 0) {
              this.rows[i + 1].slots[0].controls.splice(pos, 0, newCell);
            } else {
              this.rows[i + 1].slots[0].controls.push(newCell);
            }
          }
          n--;
        }
      } else {
        n--;
      }
    }
    this.rows.splice(i, 1);
  }
  parseMatrix.call(this);
}
//删除列
function deleteColumns() {
  parseMatrix.call(this);
  let range = getRangeSelectedRect.call(this);
  for (let n = range.maxCellIndex; n >= range.minCellIndex; n--) {
    let i = this.matrix.length - 1;
    while (i >= 0) {
      let row = this.matrix[i];
      let cells = this.rows[i].slots[0].controls;
      let cell = row[n];
      if (cell.isSpan) {
        cell = this.matrix[cell.rowIndex][cell.cellIndex];
      }
      if (cell.colspan > 1) {
        if (n > cell.cellIndex) {
          cell.colspan--;
          i -= cell.rowspan;
        } else {
          //删除了colspan>1的列，需要在填补一个空位
          if (cell.rowIndex === i && cell.cellIndex === n) {
            let newCell = createCell();
            newCell.colspan = cell.colspan - 1 > 1 ? cell.colspan - 1 : 1;
            newCell.rowspan = cell.rowspan;
            let index = _.findIndex(cells, (cc) => cc === cell);
            cells.splice(index, 1, newCell);
          }
          i--;
        }
      } else {
        if (i === cell.rowIndex) {
          dsf.array.remove(cells, cell);
        }
        i--;
      }
    }
    removeColumn.call(this, n);
  }
  parseMatrix.call(this);
}

// 重置列宽
function resetColWidth() {
  const newWidth = (100 / this.columnsInfo.length).toFixed(3) + "%";
  this.onChangeCol(
    this.columnsInfo.map(() => {
      return { width: newWidth };
    })
  );
}
// 重置行高
function resetRowHeight() {
  this.rows.forEach((vm) => {
    vm.height = "57px";
  });
}

// 单元格格式
function setTableCell() {
  dsf.layer.openDialog({
    title: "单元格设置",
    fullscreen: false,
    content: "DesTableCellSet",
    width: "600px",
    btns: [
      {
        text: "确定",
        handler: (vm) => {
          const cellStyle = vm.yes();
          setTableCellStyle.call(this, cellStyle);
          this.$forceUpdate();
        }
      },
      {
        text: "取消"
      }
    ]
  });
}
function setTableCellStyle({ cellStyle, cellAttr, borderPosition }) {
  let m = parseMatrix.call(this);
  let { maxRowIndex, minRowIndex, maxCellIndex, minCellIndex } = getRangeSelectedRect.call(this);
  // 处理选中区域中间单元格
  let border;
  for (let i = minRowIndex; i <= maxRowIndex; i++) {
    for (let j = minCellIndex; j <= maxCellIndex; j++) {
      const vm = m[i][j];
      if (!vm.isSpan) {
        vm.cellAttr = _.merge(vm.cellAttr, cellAttr);
        vm.cellStyle = _.merge(vm.cellStyle, cellStyle);
        // 处理选中区域上边界
        /*border = borderPosition.t["border-top"];
        if (i === minRowIndex && border) {
          vm.cellBorderStyle["border-top"] = border;
        }
        // 处理选中区域左边界
        border = borderPosition.l["border-left"];
        if (j === minCellIndex && border) {
          vm.cellBorderStyle["border-left"] = border;
        }
        // 处理选中区域右边界
        border = borderPosition.r["border-right"];
        if (j + vm.colspan - 1 === maxCellIndex && border) {
          vm.cellBorderStyle["border-right"] = border;
        }
        // 处理选中区域下边界
        border = borderPosition.b["border-bottom"];
        if (i + vm.rowspan - 1 === maxRowIndex && border) {
          vm.cellBorderStyle["border-bottom"] = border;
        }*/
        // 处理选中区域中间单元格
        // border = borderPosition.cb["border-bottom"];
        // if (border) {
        //   let w = parseInt(border.split(" ")[0]);
        //   let _w = parseInt(w / 2);
        //   if (i > minRowIndex) {
        //     vm.cellBorderStyle["border-top"] = border.replace(w + "px", _w + "px");
        //   }
        //   if (i + vm.rowspan - 1 < maxRowIndex) {
        //     if (_w < w / 2) {
        //       _w++;
        //     }
        //     vm.cellBorderStyle["border-bottom"] = border.replace(w + "px", _w + "px");
        //   }
        // }
        // border = borderPosition.cr["border-right"];
        // if (border) {
        //   let w = parseInt(border.split(" ")[0]);
        //   let _w = parseInt(w / 2);
        //   if (j > minCellIndex) {
        //     vm.cellBorderStyle["border-left"] = border.replace(w + "px", _w + "px");
        //   }
        //   if (j + vm.colspan - 1 < maxCellIndex) {
        //     if (_w < w / 2) {
        //       _w++;
        //     }
        //     vm.cellBorderStyle["border-right"] = border.replace(w + "px", _w + "px");
        //   }
        // }
      }
    }
  }
}

// 清除选中单元格格式
function clearChooseCell() {
  let m = parseMatrix.call(this);
  let { maxRowIndex, minRowIndex, maxCellIndex, minCellIndex } = getRangeSelectedRect.call(this);
  for (let i = minRowIndex; i <= maxRowIndex; i++) {
    for (let j = minCellIndex; j <= maxCellIndex; j++) {
      const vm = m[i][j];
      if (!vm.isSpan) {
        vm.cellAttr = {
          align: "",
          valign: ""
        };
        vm.cellStyle = {};
        // vm.cellBorderStyle = {};
      }
    }
  }
}

// 清除所有单元格格式
function clearAllCell() {
  let m = parseMatrix.call(this);
  for (let i = 0; i < m.length; i++) {
    for (let j = 0; j < m[i].length; j++) {
      const vm = m[i][j];
      if (!vm.isSpan) {
        vm.cellAttr = {
          align: "",
          valign: ""
        };
        vm.cellStyle = {};
        // vm.cellBorderStyle = {};
      }
    }
  }
}
</script>
