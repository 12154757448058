var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "ds-control ds-form-item ds-customer-img",
      class: _vm.getCss,
      style: _vm.containerSty,
    },
    [
      _vm.showLabel
        ? _c(
            "DsfFieldLabel",
            {
              style: _vm.getLabelWidthStyle(),
              attrs: {
                mode: _vm.showDataCaptionMode,
                "show-icon": _vm.showDataCaptionIcon,
                trigger: _vm.showDataCaptionTrigger,
                "data-caption": _vm.dataCapion,
                "is-design": _vm.isDesign,
                owner: _vm._self,
              },
            },
            [_vm._v(" " + _vm._s(_vm.$t(_vm.label)) + " ")]
          )
        : _vm._e(),
      _vm.readOnly
        ? [
            _c(
              "div",
              { staticClass: "left" },
              [
                _c(
                  "div",
                  { staticClass: "canvas-box" },
                  [
                    _c("dsf-image", {
                      ref: "img",
                      style: _vm.canvasSty,
                      attrs: { src: _vm.readonlySrc },
                    }),
                    _c("div", { staticClass: "tips" }, [
                      _vm._v(
                        "宽 * 高：" +
                          _vm._s(_vm.width) +
                          "px * " +
                          _vm._s(_vm.height) +
                          "px"
                      ),
                    ]),
                  ],
                  1
                ),
                _vm._t("error", function () {
                  return [
                    _vm.errors.length > 0
                      ? _c("div", { staticClass: "ds-error-text" }, [
                          _vm._v(" " + _vm._s(_vm.errorsMsg) + " "),
                        ])
                      : _vm._e(),
                  ]
                }),
              ],
              2
            ),
          ]
        : [
            _c(
              "div",
              { staticClass: "left" },
              [
                _c("div", { staticClass: "canvas-box" }, [
                  _c("canvas", {
                    ref: "canvas",
                    style: _vm.canvasSty,
                    attrs: {
                      width: _vm.width + "px",
                      height: _vm.height + "px",
                    },
                  }),
                  _c("div", { staticClass: "tips" }, [
                    _vm._v(
                      "宽 * 高：" +
                        _vm._s(_vm.width) +
                        "px * " +
                        _vm._s(_vm.height) +
                        "px"
                    ),
                  ]),
                ]),
                !_vm.isCutPic
                  ? _c("div", { staticClass: "warning" }, [
                      _vm._v(
                        "请上传 " +
                          _vm._s(_vm.maxFileSize) +
                          "kb 大小以内的图片"
                      ),
                    ])
                  : _vm._e(),
                _vm._t("error", function () {
                  return [
                    _vm.errors.length > 0
                      ? _c("div", { staticClass: "ds-error-text" }, [
                          _vm._v(" " + _vm._s(_vm.errorsMsg) + " "),
                        ])
                      : _vm._e(),
                  ]
                }),
              ],
              2
            ),
            !_vm.readOnly
              ? _c(
                  "div",
                  { staticClass: "right" },
                  [
                    _c("input", {
                      ref: "uploadFile",
                      staticStyle: { display: "none" },
                      attrs: { type: "file", accept: "image/*" },
                    }),
                    _c(
                      "dsf-button",
                      {
                        attrs: { title: _vm.tip, icon: "yunduanshangchuan" },
                        on: { click: _vm.preUpload },
                      },
                      [_vm._v("点击上传")]
                    ),
                    _c("div", { staticClass: "right-item" }, [
                      _c("label", [_vm._v("显示文字")]),
                      _c(
                        "div",
                        { staticClass: "input-box" },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入" },
                            on: { change: _vm.textChangeHandler },
                            model: {
                              value: _vm.tempValue.text,
                              callback: function ($$v) {
                                _vm.$set(_vm.tempValue, "text", $$v)
                              },
                              expression: "tempValue.text",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c(
                      "div",
                      { staticClass: "right-item" },
                      [
                        _c("label", [_vm._v("字体设置")]),
                        _c(
                          "div",
                          { staticClass: "select-box" },
                          [
                            _c(
                              "el-select",
                              {
                                model: {
                                  value: _vm.tempValue.fontFamily,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.tempValue, "fontFamily", $$v)
                                  },
                                  expression: "tempValue.fontFamily",
                                },
                              },
                              _vm._l(_vm.fontList, function (item) {
                                return _c("el-option", {
                                  key: item.val,
                                  attrs: { value: item.val, label: item.txt },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                        _c("el-input-number", {
                          attrs: { controls: false, min: 0, precision: 0 },
                          model: {
                            value: _vm.tempValue.fontSize,
                            callback: function ($$v) {
                              _vm.$set(_vm.tempValue, "fontSize", $$v)
                            },
                            expression: "tempValue.fontSize",
                          },
                        }),
                        _c(
                          "el-button",
                          { style: _vm.boldBtnSty, on: { click: _vm.setBold } },
                          [
                            _c("span", { staticClass: "icon iconfont" }, [
                              _vm._v(""),
                            ]),
                          ]
                        ),
                        _c(
                          "el-button",
                          {
                            style: _vm.italicBtnSty,
                            on: { click: _vm.setItalic },
                          },
                          [
                            _c("span", { staticClass: "icon iconfont" }, [
                              _vm._v(""),
                            ]),
                          ]
                        ),
                        _c("el-color-picker", {
                          attrs: { value: _vm.tempValue.fill },
                          on: {
                            "active-change": _vm.chooseTextColor,
                            change: _vm.changeTextColor,
                          },
                        }),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "right-item" }, [
                      _c(
                        "div",
                        { staticClass: "group" },
                        [
                          _c("label", [_vm._v("文字底色")]),
                          _c("el-color-picker", {
                            attrs: { value: _vm.tempValue.textBackgroundColor },
                            on: {
                              "active-change": _vm.chooseTextBgColor,
                              change: _vm.changeTextBgColor,
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "group" },
                        [
                          _c("label", [_vm._v("背景颜色")]),
                          _c("el-color-picker", {
                            attrs: { value: _vm.tempValue.backgroundColor },
                            on: {
                              "active-change": _vm.chooseBgColor,
                              change: _vm.changeBgColor,
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ],
                  1
                )
              : _vm._e(),
          ],
      _c("picture-cut-dialog", {
        attrs: {
          src: _vm.src,
          width: _vm.width,
          height: _vm.height,
          "dialog-visible": _vm.dialogVisible,
        },
        on: {
          "update:src": function ($event) {
            _vm.src = $event
          },
          "cut-img": _vm.cutImg,
          "dialog-close": _vm.dialogClose,
        },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }