var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "page-home-default-breadcrumb",
      class: [_vm.size, { theme: _vm.theme }],
    },
    [
      _vm._t("default", function () {
        return [_vm._m(0)]
      }),
      _vm.onlyLast
        ? _c("ul", [
            _vm.lastMenu
              ? _c(
                  "li",
                  [
                    _vm._t(
                      "default",
                      function () {
                        return [
                          _c("a", [_vm._v(_vm._s(_vm.lastMenu.menu_name))]),
                        ]
                      },
                      { item: _vm.lastMenu }
                    ),
                    _vm._t("separator", function () {
                      return [_c("i", [_vm._v(_vm._s(_vm.separator))])]
                    }),
                  ],
                  2
                )
              : _vm._e(),
          ])
        : _c(
            "ul",
            _vm._l(_vm.menuAction, function (item) {
              return _c(
                "li",
                { key: item.menu_id },
                [
                  _vm._t(
                    "default",
                    function () {
                      return [_c("a", [_vm._v(_vm._s(item.menu_name))])]
                    },
                    { item: item }
                  ),
                  _vm._t("separator", function () {
                    return [_c("i", [_vm._v(_vm._s(_vm.separator))])]
                  }),
                ],
                2
              )
            }),
            0
          ),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "text" }, [
      _c("i", { staticClass: "iconfont icon-position" }),
      _vm._v(" 当前页:"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }