import { render, staticRenderFns } from "./navCatalogueItemForEditor.vue?vue&type=template&id=16bfd422&"
import script from "./navCatalogueItemForEditor.vue?vue&type=script&lang=js&"
export * from "./navCatalogueItemForEditor.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

component.options.__file = "pc/bhc/nav/items/navCatalogueItemForEditor.vue"
export default component.exports