var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "label",
    {
      staticClass: "dsf-college-home-header-search",
      class: { show: _vm.show },
    },
    [
      _c("i", {
        staticClass: "iconfont icon-sousuo",
        on: { click: _vm.submit },
      }),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model.trim",
            value: _vm.keywords,
            expression: "keywords",
            modifiers: { trim: true },
          },
        ],
        ref: "input",
        attrs: { placeholder: _vm.placeholder, type: "text" },
        domProps: { value: _vm.keywords },
        on: {
          focus: _vm.focus,
          blur: [
            _vm.blur,
            function ($event) {
              return _vm.$forceUpdate()
            },
          ],
          keypress: function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            ) {
              return null
            }
            return _vm.doSearch.apply(null, arguments)
          },
          input: function ($event) {
            if ($event.target.composing) {
              return
            }
            _vm.keywords = $event.target.value.trim()
          },
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }